import ButtonComponent from "../generic/ButtonComponent";

interface SettingsSectionToggleProps {
  toggleTextOn?: string | undefined;
  toggleTextOff?: string | undefined;
  isOn: boolean;
  toggle: (val: boolean) => void;
  disabled?: boolean;
  compact?: boolean;
  toggleOnFunction?: () => void;
  toggleOffFunction?: () => void;
}

const SettingsSectionToggle = ({
  toggleTextOn,
  toggleTextOff,
  isOn,
  toggle,
  disabled = false,
  compact = false,
  toggleOnFunction,
  toggleOffFunction,
}: SettingsSectionToggleProps) => {
  return !isOn ? (
    <ButtonComponent
      primary
      data-testid="settings-section-toggle"
      onClick={() => {
        toggle(true);
        toggleOnFunction?.();
      }}
      disabled={disabled}
      compact={compact}
    >
      {toggleTextOn || "Edit settings"}
    </ButtonComponent>
  ) : (
    <ButtonComponent
      data-testid="settings-section-toggle"
      onClick={() => {
        toggle(false);
        toggleOffFunction?.();
      }}
      disabled={disabled}
      compact={compact}
    >
      {toggleTextOff || "Cancel"}
    </ButtonComponent>
  );
};

export default SettingsSectionToggle;
