import { PreviousProductImport } from "../types/productImport";
import { TimeUtils } from "../utils";
import { capitalizeFirstLetter } from "../utils/formatting";

export function mapPreviousCsvUploads(data: PreviousProductImport) {
  const { createdAt, status, fileType, updatedAt, ...rest } = data;
  return {
    createdAt: TimeUtils.convertDateToHumanReadable(createdAt),
    status: capitalizeFirstLetter(status),
    fileType: capitalizeFirstLetter(fileType),
    updatedAt: TimeUtils.convertDateToHumanReadable(updatedAt),
    ...rest,
  };
}
