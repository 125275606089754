import { action } from "mobx";
import TimeUtils from "../../utils/TimeUtils";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import ButtonComponent from "../generic/ButtonComponent";
import Modal from "../generic/Modal";
import "./FloatModal.scss";
import { ItemList } from "../../pos/ItemStore";

interface FloatModalProps extends RouteComponentProps {
  posStore?: ItemList;
}

interface FloatModalState {
  floatAdjustmentOpen: boolean;
  submittingAdjustment: boolean;
}

@inject("posStore")
@observer
class FloatModal extends Component<FloatModalProps, FloatModalState> {
  constructor(props) {
    super(props);
    this.state = {
      submittingAdjustment: false,
      floatAdjustmentOpen: false,
    };
  }

  viewFloatAdjustmentMenu = (visible) => {
    this.setState({ floatAdjustmentOpen: visible });
  };

  @action
  updateTenderClose(event, tenderIndex) {
    const { value } = event.target;
    if (this.props.posStore.tenderClose.length <= tenderIndex)
      this.props.posStore.tenderClose.length = tenderIndex + 1;
    let tenderValue;
    if (isNaN(value) && value != "-") {
      tenderValue = value.replace(/[^0-9.-]/g, "");
      // TODO:: Is this next state even possible?
      if (isNaN(tenderValue)) {
        tenderValue = "";
      }
    } else {
      tenderValue = value;
    }
    this.props.posStore.setTenderClose(tenderValue, tenderIndex);
  }

  updateAdjustment = (event) => {
    const { value: adjustment } = event.target;
    let amount;
    if (isNaN(adjustment) && adjustment != "-") {
      amount = adjustment.replace(/[^0-9.-]/g, "");
      // TODO:: Is this next state even possible?
      if (isNaN(amount)) {
        amount = "";
      }
    } else {
      amount = adjustment;
    }
    this.props.posStore.setAdjustAmount(amount);
  };

  changeAdjustTender = (event) => {
    const { value: tenderIndex } = event.target;
    this.props.posStore.setAdjustTender(
      this.props.posStore.tenders[tenderIndex].type
    );
  };

  closeModal = () => {
    this.props.posStore.closeFloatModal();
  };

  formatTenderType = (tenderType) => {
    if (tenderType === "Cash") {
      return (
        <>
          <i className="fas fa-money-bill-wave" /> Cash
        </>
      );
    }
    if (tenderType == "Credit") {
      return (
        <>
          <i className="fas fa-credit-card-front" /> Credit Card
        </>
      );
    }
    if (tenderType === "EFTPOS") {
      return (
        <>
          <i className="fas fa-credit-card" /> EFTPOS
        </>
      );
    }
    return (
      <>
        <i className="fas fa-money-bill-wave" /> {tenderType}
      </>
    );
  };

  unsetTillAndFloat = () => {
    const store = this.props.posStore;
    store.unsetTill(false);
    store.closeFloatModal();
    setTimeout(() => this.props.history.push("/dashboard"), 100);
  };

  render() {
    const store = this.props.posStore;
    const currentTill =
      store.tillList.find((till) => till.id == store.tillId)?.name || "till";

    if (this.state.floatAdjustmentOpen) {
      return (
        <Modal onClose={this.closeModal} small className="FloatModal">
          <Modal.Header>
            <div className="FloatModal__toggle">
              <ButtonComponent
                onClick={() => this.viewFloatAdjustmentMenu(false)}
              >
                Close Till
              </ButtonComponent>
              <ButtonComponent
                primary
                onClick={() => this.viewFloatAdjustmentMenu(true)}
              >
                Float Adjustment
              </ButtonComponent>
            </div>
          </Modal.Header>
          <Modal.Content>
            <h2 className="FloatModal__title">Float adjustment</h2>
            <div className="FloatModal__description">
              Use the form below to adjust your ledgers
            </div>
            <label htmlFor="tillSelector" className="label">
              Type:
            </label>
            <select
              id="tillSelector"
              className="FloatModal__select"
              onChange={this.changeAdjustTender}
              disabled={!store.floatStatus}
              data-testid="change-adjust"
              //value={store.tillId ? store.tillId : -1}
            >
              {store.tenders.map((till, index) => {
                if (till.type == "Store Credit") return null;
                return (
                  <option value={index} key={index}>
                    {till.type}
                  </option>
                );
              })}
            </select>
            <label htmlFor="adjustmentAmount" className="label">
              Adjustment Amount:
            </label>
            <input
              id="adjustmentAmount"
              className="input"
              value={store.adjustAmount}
              type="textbox"
              placeholder="e.g, 50.00 or -50.00"
              onChange={this.updateAdjustment}
              data-testid="adjustment-amount"
            />

            <label htmlFor="note" className="label">
              Notes:
            </label>
            <input
              id="note"
              className="input"
              type="textbox"
              placeholder="Type to add a note"
              onChange={(e) => {
                store.updateNote(e.target.value);
              }}
              value={store.adjustNote}
            />
          </Modal.Content>
          <Modal.Actions>
            <ButtonComponent
              primary
              disabled={!store.floatStatus || this.state.submittingAdjustment}
              onClick={async () => {
                try {
                  this.setState({ submittingAdjustment: true });
                  await store.adjustFloat();
                } finally {
                  this.setState({ submittingAdjustment: false });
                }
              }}
              icon="fas fa-caret-right"
              iconPosition="right"
            >
              {this.state.submittingAdjustment
                ? "Submitting Adjustment..."
                : "Submit Adjustment"}
            </ButtonComponent>
          </Modal.Actions>
        </Modal>
      );
    }

    return (
      <Modal onClose={this.closeModal} small className="FloatModal">
        <Modal.Header>
          <div className="FloatModal__toggle">
            <ButtonComponent
              primary
              onClick={() => this.viewFloatAdjustmentMenu(false)}
            >
              Close Till
            </ButtonComponent>
            <ButtonComponent onClick={() => this.viewFloatAdjustmentMenu(true)}>
              Float Adjustment
            </ButtonComponent>
          </div>
        </Modal.Header>
        <Modal.Content>
          <h2 className="FloatModal__title">Close {currentTill}</h2>
          <div className="FloatModal__description">
            ** Till opened on the{" "}
            {TimeUtils.convertDateToHumanReadable(store.float.dateOpened)}
            **
          </div>

          <table className="FloatModal__table">
            <thead>
              <tr>
                <td>Payment Type</td>
                <td>Expected</td>
                <td>Difference</td>
                <td>End of Day Count</td>
              </tr>
            </thead>
            <tbody>
              {store.tenders.map((tender, index) => {
                if (tender.type == "Store Credit") return null;
                return (
                  <tr key={index}>
                    <td>{this.formatTenderType(tender.type)}</td>
                    <td>
                      {store.float.float &&
                        store.float.float.length >= index &&
                        store.float.float
                          .find(
                            (float) =>
                              float.name?.toUpperCase() ===
                              tender.type?.toUpperCase()
                          )
                          ?.currentAmount.toFixed(2)}
                    </td>
                    <td>{store.tenderDiff[index] ?? null}</td>
                    <td>
                      <input
                        type="textbox"
                        className="input"
                        key={index}
                        placeholder="0.00"
                        value={store.tenderClose[index] ?? ""}
                        onChange={(e) => this.updateTenderClose(e, index)}
                        data-testid="tender-close"
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Modal.Content>
        <Modal.Actions>
          <div className="FloatModal__actionButtons">
            <ButtonComponent
              fullWidth
              primary
              onClick={store.openTillWarning}
              disabled={!store.floatStatus}
              icon="fas fa-power-off"
              iconPosition="left"
              data-testid="closeTillButton"
            >
              Close Till
            </ButtonComponent>
            <ButtonComponent
              fullWidth
              onClick={this.unsetTillAndFloat}
              disabled={!store.floatStatus}
              icon="fal fa-door-open"
              iconPosition="left"
            >
              Or leave till without closing it
            </ButtonComponent>
          </div>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withRouter(FloatModal);
