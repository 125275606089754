import React, { useState } from "react";
import { ProductTag } from "../../generated/graphql";
import ButtonComponent from "../generic/ButtonComponent";
import IconButton from "../generic/IconButton";
import "./ProductTags.scss";

interface ProductTagsProps {
  tags: ProductTag[];
  setFieldValue: SetFieldValue;
}

const ProductTags = (props: ProductTagsProps) => {
  const { tags, setFieldValue } = props;
  const [tag, setTag] = useState<string>("");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setTag(event.target.value);

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === "Enter") {
      handleSubmitNewTag();
    }
  };

  const handleSubmitNewTag = () => {
    if (tag !== "") {
      const newTagsArray = [...tags, { tag: tag.trim() }];
      setFieldValue("ProductTag", newTagsArray);
      setTag("");
    }
  };

  const handleRemoveTag = (tagValue: string) => {
    const newTagsArray = [
      ...tags.filter(
        (productTag) => productTag.tag.toLowerCase() !== tagValue.toLowerCase()
      ),
    ];
    setFieldValue("ProductTag", newTagsArray);
  };

  return (
    <>
      <div className="product-tags">
        <input
          id="productTags"
          aria-label="Product tags"
          type="string"
          placeholder=""
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          value={tag}
          className="input"
          autoComplete="off"
        />
        <ButtonComponent
          primary
          onClick={handleSubmitNewTag}
          type="button"
          className="product-tags__button"
        >
          Add
        </ButtonComponent>
      </div>
      <div className="product-tags__tag-container">
        {tags.map((productTag) => (
          <div
            className="product-tags__tag-entry"
            key={productTag.tag}
            data-testid="productTag"
          >
            {productTag.tag}{" "}
            <IconButton
              title={`Delete tag: ${productTag.tag}`}
              icon="fas fa-times-circle"
              onClick={() => handleRemoveTag(productTag.tag)}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default ProductTags;
