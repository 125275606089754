import React from "react";
import CartNoteModel from "./cart-note.model";
import SubSectionHeaderLayout from "../layout/SubSectionHeaderLayout";
import Panel from "../layout/Panel";

export default function CartNotes({ rawNotes }: { rawNotes?: string }) {
  const parsedNotes: CartNoteModel | null = rawNotes && JSON.parse(rawNotes);

  return (
    parsedNotes && (
      <>
        <SubSectionHeaderLayout title="Notes" />
        <Panel>
          <span>{parsedNotes.note}</span>
          {parsedNotes.customFields ? (
            <>
              <br />
              {Object.entries(parsedNotes.customFields).map(([name, value]) => (
                <>
                  <em key={name}>
                    {name}: {value}
                  </em>
                  <br />
                </>
              ))}
            </>
          ) : null}
        </Panel>
        <br />
      </>
    )
  );
}
