import { useContext } from "react";
import { GlobalSettingsContext } from "../providers/GlobalSettingsProvider";

export const useFeatureFlag = (featureFlag: string) => {
  const context = useContext(GlobalSettingsContext);
  if (!context) {
    throw new Error(
      "useFeatureFlag hook must be used within GlobalSettingsProvider"
    );
  }
  return context.featureFlags?.[featureFlag] === "on";
};

export const useActiveFeatureFlags = () => {
  const context = useContext(GlobalSettingsContext);
  if (!context) {
    throw new Error(
      "useActiveFeatureFlags hook must be used within GlobalSettingsProvider"
    );
  }
  return Object.entries(context.featureFlags ?? []).reduce(
    (activeFlags, [flagName, flagValue]) =>
      flagValue === "on" ? [...activeFlags, flagName] : activeFlags,
    []
  );
};
