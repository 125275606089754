import TimeUtils from "../utils/TimeUtils";

export const totalSalesByCategoryCSVHeaders = [
  { name: "Category", key: "productType" },
  {
    name: "Buys",
    key: "buys",
    formatter: (number: number) => number?.toFixed(2),
  },
  {
    name: "Sales",
    key: "sales",
    formatter: (number: number) => number?.toFixed(2),
  },
  {
    name: "Total",
    key: "total",
    formatter: (number: number) => number?.toFixed(2),
  },
];

export const salesByOrderCSVHeaders1 = [
  {
    name: "Date",
    key: "closedAt",
    formatter: (date) => TimeUtils.convertDateToHumanReadable(date),
  },
  { name: "Order Id", key: "orderId" },
  { name: "Source", key: "source" },
  { name: "Items", key: "lineItems" },
];
export const salesByOrderCSVHeaders2 = [
  {
    name: "Subtotal",
    key: "subTotal",
    formatter: (number) => number?.toFixed(2),
  },
  {
    name: "Shipping",
    key: "shipping",
    formatter: (number) => number?.toFixed(2),
  },
  {
    name: "Discount",
    key: "discount",
    formatter: (number) => number?.toFixed(2),
  },
  {
    name: "Tax",
    key: "tax",
    formatter: (number) => number?.toFixed(2),
  },
  {
    name: "Total",
    key: "total",
    formatter: (number) => number?.toFixed(2),
  },
];

export const salesByTenderBetaCSVHeaders = [
  {
    name: "Date",
    key: "dateCreated",
    formatter: (date) => TimeUtils.convertDateToHumanReadable(date),
  },
  { name: "Order Id", key: "orderId" },
  { name: "Source", key: "source" },
  { name: "Tender", key: "tenderType" },
  {
    name: "Sales",
    key: "sale",
    formatter: (number) => number?.toFixed(2),
  },
  {
    name: "Buys",
    key: "buy",
    formatter: (number) => number?.toFixed(2),
  },
  {
    name: "Refunds",
    key: "refund",
    formatter: (number) => number?.toFixed(2),
  },
  {
    name: "Total",
    key: "total",
    formatter: (number) => number?.toFixed(2),
  },
];

export const salesByOrderBetaCSVHeaders1 = [
  {
    name: "Date",
    key: "createdAt",
    formatter: (date) => TimeUtils.convertDateToHumanReadable(date),
  },
  { name: "Order Id", key: "orderId" },
  { name: "Source", key: "source" },
];

export const salesByOrderBetaCSVHeaders2 = [
  {
    name: "Sales",
    key: "sale",
    formatter: (number) => number?.toFixed(2),
  },
  {
    name: "Refunds",
    key: "refund",
    formatter: (number) => number?.toFixed(2),
  },
  {
    name: "Tax",
    key: "totalTax",
    formatter: (number) => number?.toFixed(2),
  },
  {
    name: "Sales Before Tax",
    key: "beforeTax",
    formatter: (number) => number?.toFixed(2),
  },
  {
    name: "Buys",
    key: "buy",
    formatter: (number) => number?.toFixed(2),
  },
  {
    name: "Total (Incl. Tax)",
    key: "total",
    formatter: (number) => number?.toFixed(2),
  },
];

export const submittedCartsCSVHeaders = [
  { name: "Cart Id", key: "id" },
  { name: "Shopify Order", key: "shopifyOrderId" },
  {
    name: "Date Submitted",
    key: "dateSubmitted",
    formatter: (date) => TimeUtils.convertDateToHumanReadable(date),
  },
  { name: "Till", key: "till.name" },
  {
    name: "First Name",
    key: "customer.firstName",
    formatter: (name) => name || "Guest",
  },
  {
    name: "Last Name",
    key: "customer.lastName",
    formatter: (name) => name || "",
  },
  { name: "Processed By", key: "submittedBy" },
];

export const cartNotesCSVHeaders = (customFields) => [
  { name: "Cart Id", key: "id" },
  { name: "Shopify Order", key: "shopifyOrderId" },
  {
    name: "Date Submitted",
    key: "dateSubmitted",
    formatter: (date) => TimeUtils.convertDateToHumanReadable(date),
  },
  {
    name: "Customer",
    key: "customer.firstName",
    formatter: (name) => name || "Guest",
  },
  {
    name: "Last Name",
    key: "customer.lastName",
    formatter: (name) => name || "",
  },
  {
    name: "Cart Notes",
    key: "cartNotes",
    formatter: (notes) => {
      try {
        return JSON.parse(notes)?.note;
      } catch (err) {
        return "";
      }
    },
  },
  ...[customFields ?? []].map((customField) => ({
    name: customField,
    key: "cartNotes",
    additionalData: customField,
    formatter: (notes, fieldName) => {
      try {
        return JSON.parse(notes)?.customFields?.[fieldName];
      } catch (err) {
        return "";
      }
    },
  })),
];

export const floatReportCSVHeaders = (availableTenders: string[] = []) => [
  { name: "Till Name", key: "name" },
  {
    name: "Opening Time",
    key: "openedDate",
    formatter: (date) => TimeUtils.convertDateToHumanReadable(date),
  },
  {
    name: "Closing Time",
    key: "closedDate",
    formatter: (date) =>
      date ? TimeUtils.convertDateToHumanReadable(date) : "",
  },
  ...availableTenders
    ?.filter((tender) => tender !== "Store_Credit")
    ?.map((tender) => ({
      name: `${tender.replace("_", " ")} Opening`,
      key: `${tender}.openingAmount`,
      formatter: (number) => (number || 0)?.toFixed(2),
    })),
  ...availableTenders?.map((tender) => ({
    name: `${tender.replace("_", " ")} Current`,
    key: `${tender}.currentAmount`,
    formatter: (number) => (number || 0)?.toFixed(2),
  })),
  ...availableTenders
    ?.filter((tender) => tender !== "Store_Credit")
    ?.map((tender) => ({
      name: `${tender.replace("_", " ")} Diff`,
      key: `${tender}.difference`,
      formatter: (number) => (number || 0)?.toFixed(2),
    })),
  ...availableTenders
    ?.filter((tender) => tender !== "Store_Credit")
    ?.map((tender) => ({
      name: `${tender.replace("_", " ")} Closing`,
      key: `${tender}.closingAmount`,
      formatter: (number) => number?.toFixed(2),
    })),
];

export const EndOfDayCSVHeaders = (availableTenders: string[] = []) => [
  { name: "Till", key: "name" },
  { name: "Opening Time", key: "openedDate" },
  { name: "Closing Time", key: "closedDate" },
  ...availableTenders
    ?.filter((tender) => tender !== "Store_Credit")
    ?.map((tender) => ({
      name: `${tender.replace("_", " ")} Opening`,
      key: `${tender}.openingAmount`,
      formatter: (number) => (number || 0)?.toFixed(2),
    })),
  ...availableTenders?.map((tender) => ({
    name: `${tender.replace("_", " ")} Current`,
    key: `${tender}.currentAmount`,
    formatter: (number) => (number || 0)?.toFixed(2),
  })),
  ...availableTenders
    ?.filter((tender) => tender !== "Store_Credit")
    ?.map((tender) => ({
      name: `${tender.replace("_", " ")} Diff`,
      key: `${tender}.difference`,
      formatter: (number) => (number || 0)?.toFixed(2),
    })),
  ...availableTenders
    ?.filter((tender) => tender !== "Store_Credit")
    ?.map((tender) => ({
      name: `${tender.replace("_", " ")} Closing`,
      key: `${tender}.closingAmount`,
      formatter: (number) => number?.toFixed(2),
    })),
];

export const WPNReportCSVHeaders = (currencyCode) => [
  { name: "Transaction Time", key: "transactionTime" },
  { name: "Transaction Id", key: "transactionId" },
  { name: "UPC", key: "UPC" },
  { name: "Product Description", key: "description" },
  {
    name: "Individual Unit Price",
    key: "unitPrice",
    formatter: (number) => number && number.toFixed(2),
  },
  { name: "Quantity Sold", key: "quantity" },
  {
    name: "Total Sale Price",
    key: "totalPrice",
    formatter: (number) => number && number.toFixed(2),
  },
  {
    name: "Currency",
    key: "currency",
    formatter: () => currencyCode,
  },
];

export const stockValueCSVHeaders = [
  { name: "Product Type", key: "productType" },
  { name: "Count", key: "count" },
  {
    name: "Value",
    key: "totalValue",
    formatter: (number) => number?.toFixed(2),
  },
  {
    name: "Cost",
    key: "totalCost",
    formatter: (number) => number?.toFixed(2),
  },
];

export const buylistCSVHeaders = [
  { name: "Buylist Id", key: "buylistId" },
  { name: "Customer First Name", key: "firstName" },
  { name: "Customer Last Name", key: "lastName" },
  { name: "Customer Email", key: "email" },
  { name: "Payment Type", key: "paymentType" },
  { name: "Total Items", key: "totalItems" },
  {
    name: "Total Buy Price",
    key: "totalBuyPrice",
    formatter: (number) => number?.toFixed(2),
  },
  { name: "Status", key: "status" },
  {
    name: "Date Submitted",
    key: "dateSubmitted",
    formatter: (date) =>
      date ? TimeUtils.convertDateToHumanReadable(Number(date)) : "",
  },
  {
    name: "Date Completed",
    key: "dateCompleted",
    formatter: (date) =>
      date ? TimeUtils.convertDateToHumanReadable(Number(date)) : "",
  },
];

export const completedBuylistCSVHeaders = [
  { name: "Buylist Id", key: "buylistId" },
  { name: "Customer First Name", key: "firstName" },
  { name: "Customer Last Name", key: "lastName" },
  { name: "Customer Email", key: "email" },
  { name: "Payment Type", key: "paymentType" },
  { name: "Total Items", key: "totalItems" },
  {
    name: "Total Buy Price",
    key: "totalBuyPrice",
    formatter: (number) => number?.toFixed(2),
  },
  {
    name: "Date Submitted",
    key: "dateSubmitted",
    formatter: (date) =>
      date ? TimeUtils.convertDateToHumanReadable(Number(date)) : "",
  },
  {
    name: "Date Completed",
    key: "dateCompleted",
    formatter: (date) =>
      date ? TimeUtils.convertDateToHumanReadable(Number(date)) : "",
  },
];

export const storeCreditTransactionsCSVHeaders = [
  {
    name: "Date",
    key: "updatedDate",
    formatter: (date) => TimeUtils.convertDateToHumanReadable(date),
  },
  { name: "Actioned By", key: "actionedBy" },
  {
    name: "Credit Before",
    key: "amountBeforeChange",
    formatter: (number) => number?.toFixed(2),
  },
  {
    name: "Credit After",
    key: "amountAfterChange",
    formatter: (number) => number?.toFixed(2),
  },
  {
    name: "Credit Difference",
    key: "amountChanged",
    formatter: (number) => number?.toFixed(2),
  },
  { name: "Shopify Order Number", key: "invoiceNumber" },
  { name: "BinderPOS Cart", key: "cartId" },
  { name: "Customer First Name", key: "firstName" },
  { name: "Customer Last Name", key: "lastName" },
  { name: "Customer Email", key: "email" },
  { name: "Action Notes", key: "actionNotes" },
  { name: "Public Notes", key: "publicNotes" },
  { name: "Private Notes", key: "privateNotes" },
];

export const storeCreditByCustomerCSVHeaders = [
  {
    name: "Date",
    key: "updatedDate",
    formatter: (date) => TimeUtils.convertDateToHumanReadable(date),
  },
  { name: "Actioned By", key: "actionedBy" },
  {
    name: "Credit Before",
    key: "amountBeforeChange",
    formatter: (number) => number?.toFixed(2),
  },
  {
    name: "Credit After",
    key: "amountAfterChange",
    formatter: (number) => number?.toFixed(2),
  },
  {
    name: "Credit Difference",
    key: "amountChanged",
    formatter: (number) => number?.toFixed(2),
  },
  { name: "Shopify Order Number", key: "invoiceNumber" },
  { name: "BinderPOS Cart", key: "cartId" },
  { name: "Action Notes", key: "actionNotes" },
  { name: "Public Notes", key: "publicNotes" },
  { name: "Private Notes", key: "privateNotes" },
];

export const storeCreditOutstandingCSVHeaders = [
  { name: "Customer First Name", key: "firstName" },
  { name: "Customer Last Name", key: "lastName" },
  { name: "Customer Email", key: "email" },
  { name: "Customer Phone", key: "phone" },
  {
    name: "Store Credit",
    key: "storeCredit",
    formatter: (number) => number?.toFixed(2),
  },
];
