import React, { MutableRefObject } from "react";
import { Link } from "react-router-dom";
import "./IconButton.scss";
import { SegmentEventProps, segmentAnalytics } from "../services/Analytics";

type LinkProps =
  | {
      isLink: true;
      to: string;
    }
  | {
      isLink?: false;
      to?: string;
    };

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon: string;
  className?: string;
  disabled?: boolean;
  secondary?: boolean;
  small?: boolean;
  light?: boolean;
  danger?: boolean;
  tooltip?: boolean;
}

type IconButtonProps = LinkProps & ButtonProps & SegmentEventProps;

const getClassName = (
  light?: boolean,
  secondary?: boolean,
  small?: boolean,
  danger?: boolean,
  tooltip?: boolean,
  className?: string
) =>
  [
    "IconButton",
    light ? "IconButton--light" : null,
    secondary ? "IconButton--secondary" : null,
    small ? "IconButton--small" : null,
    danger ? "IconButton--danger" : null,
    tooltip ? "IconButton--tooltip" : null,
    className || null,
  ].join(" ");

function IconButton(
  props: IconButtonProps,
  ref: MutableRefObject<HTMLElement>
) {
  const {
    className,
    icon,
    small = false,
    disabled = false,
    secondary = false,
    light = false,
    danger,
    tooltip,
    isLink,
    to,
    segmentEventName,
    segmentEventProperties,
    onClick,
    type = "button",
    ...rest
  } = props;

  const mergedClassName = getClassName(
    light,
    secondary,
    small,
    danger,
    tooltip,
    className
  );

  const clickHandler = (
    event: React.MouseEvent<HTMLButtonElement & HTMLAnchorElement>
  ) => {
    if (segmentEventName) {
      segmentAnalytics.track(segmentEventName, segmentEventProperties);
    }
    onClick?.(event);
  };

  if (isLink)
    return (
      // @ts-ignore
      <Link
        to={to}
        className={mergedClassName}
        onClick={clickHandler}
        {...rest}
      >
        <i className={icon} />
      </Link>
    );

  return (
    <button
      type={type}
      className={mergedClassName}
      disabled={disabled}
      onClick={clickHandler}
      {...rest} // @ts-ignore
      ref={ref}
    >
      <i className={icon} />
    </button>
  );
}

export default React.forwardRef(IconButton);
