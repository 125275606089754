import React, { MutableRefObject, useState } from "react";
import { doCompleteBuylist } from "../../api/rest/buylist";
import { segmentAnalytics } from "../../components/services/Analytics";
import { BUYLIST_COMPLETED } from "../../constants/eventsTracked";
import ButtonComponent from "../generic/ButtonComponent";
import CheckboxComponent from "../generic/CheckboxComponent";
import Modal from "../generic/Modal";
import { useShowError } from "../../hooks/errorHooks";
import { BuylistDetails } from "../../types/buylist";

interface CompleteBuylistModalProps {
  zeroQuantityDetected: boolean;
  paymentType: string;
  pushProducts: boolean;
  applyStoreCredit: boolean;
  buylistDetail: BuylistDetails;
  handleClose: () => void;
}

function CompleteBuylistModal(
  props: CompleteBuylistModalProps,
  ref: MutableRefObject<any>
) {
  const {
    zeroQuantityDetected,
    paymentType,
    pushProducts: buylistPushProducts,
    applyStoreCredit: buylistApplyStoreCredit,
    buylistDetail,
    handleClose,
  } = props;
  const [pushProducts, setPushProducts] = useState(false);
  const [applyStoreCredit, setApplyStoreCredit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const showError = useShowError();

  const handleChangePushProducts = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => setPushProducts(event.target.checked);

  const handleChangeApplyStoreCredit = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => setApplyStoreCredit(event.target.checked);

  const completeBuylist = async () => {
    setIsLoading(true);
    const updatedBuylistDetail = JSON.parse(JSON.stringify(buylistDetail));
    updatedBuylistDetail.pushProducts = buylistPushProducts || pushProducts;
    updatedBuylistDetail.applyStoreCredit =
      buylistApplyStoreCredit || applyStoreCredit;

    return doCompleteBuylist(updatedBuylistDetail)
      .then(() => {
        segmentAnalytics.track(BUYLIST_COMPLETED, {
          buylist_type: "Approved buylist",
        });
        setIsLoading(false);
        setShowConfirmationModal(true);
      })
      .catch((error) => {
        showError(
          error,
          "Failed to complete buylist",
          "There was an error submitting your complete buylist. Please try again"
        );
      });
  };

  if (showConfirmationModal) {
    return (
      <Modal onClose={handleClose} ref={ref}>
        <Modal.Header>Buylist Completed!</Modal.Header>
        <Modal.Content>
          You have completed the buylist from
          {buylistDetail.shopifyCustomer.firstName}
          {buylistDetail.shopifyCustomer.lastName}
        </Modal.Content>
        <Modal.Actions>
          <ButtonComponent primary isLink to="/buylists/approved">
            Ok
          </ButtonComponent>
        </Modal.Actions>
      </Modal>
    );
  }

  return (
    <Modal onClose={handleClose} ref={ref} isLoading={isLoading}>
      <Modal.Header>Complete buylist request</Modal.Header>
      <Modal.Content>
        {zeroQuantityDetected && (
          <div className="warning">
            <i className="fas fa-exclamation-triangle" />
            One of more cards in this buylist have no quantity set. To go back
            and correct this, click <em>Cancel</em>.
          </div>
        )}
        <p>Are you sure you want to complete this buylist?</p>
        {!buylistPushProducts ? (
          <div>
            <CheckboxComponent
              name="pushStock"
              label="Push stock automatically"
              value={pushProducts}
              onChange={handleChangePushProducts}
            />
          </div>
        ) : null}
        {!buylistApplyStoreCredit && paymentType === "Store Credit" ? (
          <div>
            <CheckboxComponent
              name="applyStoreCredit"
              label="Apply store credit"
              value={applyStoreCredit}
              onChange={handleChangeApplyStoreCredit}
            />
          </div>
        ) : null}
      </Modal.Content>
      <Modal.Actions>
        <ButtonComponent secondary onClick={handleClose} disabled={isLoading}>
          Cancel
        </ButtonComponent>
        <ButtonComponent
          primary
          disabled={isLoading}
          onClick={completeBuylist}
          data-testid="confirm-complete"
        >
          {isLoading ? "Processing" : "Complete"}
        </ButtonComponent>
      </Modal.Actions>
    </Modal>
  );
}

export default React.forwardRef(CompleteBuylistModal);
