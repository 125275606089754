import React, { MutableRefObject, useRef, useState } from "react";
import { postCustomerCsv } from "../../api/rest/customers";
import "./CustomerCsvUploadModal.scss";
import { segmentAnalytics } from "../services/Analytics";
import {
  CLOSE_STORE_CREDIT_CLICKED,
  STORE_CREDIT_CSV_UPLOADED,
} from "../../constants/eventsTracked";
import Modal from "../generic/Modal";
import ButtonComponent from "../generic/ButtonComponent";

export interface CustomerCsvUploadModalProps {
  handleClose: () => void;
}

function CustomerCsvUploadModal(
  props: CustomerCsvUploadModalProps,
  ref: MutableRefObject<any>
) {
  const { handleClose } = props;
  const fileRef = useRef<HTMLInputElement>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [response, setResponse] = useState<string>();

  const uploadCSVFile = () => {
    const file = fileRef?.current?.files?.[0];
    if (file) {
      setIsProcessing(true);
      const data = new FormData();
      data.append("file", file);
      data.append("name", file.name);
      postCustomerCsv(data)
        .then((result) => {
          segmentAnalytics.track(STORE_CREDIT_CSV_UPLOADED);
          setResponse(result.message);
        })
        .catch(() => {
          setResponse(
            "There was an issue uploading your CSV, please try again."
          );
        })
        .finally(() => setIsProcessing(false));
    }
  };

  const onCloseClick = () => {
    segmentAnalytics.track(CLOSE_STORE_CREDIT_CLICKED);
    handleClose();
  };

  if (response) {
    return (
      <Modal ref={ref} onClose={onCloseClick}>
        <Modal.Header>Upload a CSV</Modal.Header>
        <Modal.Content>
          <pre>{response}</pre>
        </Modal.Content>
      </Modal>
    );
  }

  return (
    <Modal ref={ref} onClose={onCloseClick}>
      <Modal.Header>Upload a CSV</Modal.Header>
      <Modal.Content>
        Upload a CSV of customers with the following headers to process:
        <ul>
          <li>First Name</li>
          <li>Last Name</li>
          <li>Email</li>
          <li>Phone</li>
        </ul>
        <p>To overwrite add the header:</p>
        <ul>
          <li>Overwrite Credit</li>
        </ul>
        <p>To adjust add the header:</p>
        <ul>
          <li>Adjust Credit</li>
        </ul>
        <label
          htmlFor="csvFileUpload"
          className="CustomerCsvUploadModal__label"
          data-testid="CustomerCsvUploadModal"
        >
          <input
            ref={fileRef}
            id="csvFileUpload"
            type="file"
            className="CustomerCsvUploadModal__input"
            tabIndex={-1}
          />
          <button
            className="CustomerCsvUploadModal__file-upload"
            onClick={() => fileRef.current?.click()}
          >
            <i className="far fa-paperclip" />
            Choose file
            <i className="fas fa-caret-right" />
          </button>
        </label>
      </Modal.Content>
      <Modal.Actions>
        <ButtonComponent
          primary
          onClick={uploadCSVFile}
          disabled={isProcessing}
        >
          Upload!
        </ButtonComponent>
      </Modal.Actions>
    </Modal>
  );
}

export default React.forwardRef(CustomerCsvUploadModal);
