import {
  loadSettingsFromLocalStorage,
  saveSettingsToLocalStorage,
} from "../helpers/settingHelpers";

export const useLocalSetting = (settingName: string): any => {
  const settings = loadSettingsFromLocalStorage();
  return settings[settingName];
};

export const useLocalSettingUpdate = () => (param: string, value: unknown) => {
  const newSettings = {
    ...loadSettingsFromLocalStorage(),
    [param]: value,
  };
  saveSettingsToLocalStorage(newSettings);
};
