import React from "react";
import "./ToggleSwitch.scss";

interface ToggleSwitchProps {
  currentValue: boolean;
  onChange: () => void;
  trueLabel: string;
  falseLabel: string;
  disabled?: boolean;
  label?: string;
}

const ToggleSwitch = (props: ToggleSwitchProps) => {
  const {
    label,
    currentValue,
    onChange,
    trueLabel,
    falseLabel,
    disabled = false,
  } = props;

  const maxLabelSize = Math.max(trueLabel.length, falseLabel.length);

  return (
    <div className="ToggleSwitch">
      <label
        className="ToggleSwitch__switch"
        style={{ width: `${maxLabelSize * 0.5 + 4}rem` }}
      >
        <input
          type="checkbox"
          checked={currentValue}
          onChange={onChange}
          disabled={disabled}
          aria-label={label}
        />
        <span
          className="ToggleSwitch__slider"
          data-testid="slider"
          data-truelabel={trueLabel}
          data-falselabel={falseLabel}
        />
        <span className="sr-only">{label}</span>
      </label>
    </div>
  );
};

export default ToggleSwitch;
