import debounce from "lodash/debounce";
import TimeUtils from "../../../utils/TimeUtils";
import React, { useEffect, useRef, useState } from "react";
import { EbayOrder, fetchOrders } from "../../../api/rest/ebay";
import Modal from "../../../components/generic/Modal";
import Loader from "../../../components/generic/Loader";
import Paging from "../../../components/generic/Paging";
import { useStoreDetails } from "../../../hooks/storeHooks";
import { formatLocalCurrency } from "../../../utils/formatting";
import EbayOrderDetailsContainer from "./EbayOrderDetailsContainer";
import "./EbayOrdersView.scss";
import { useQueryState } from "../../../hooks/stateHooks";

const ITEMS_PER_PAGE = 25;

function EbayOrdersView() {
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useQueryState("pagination", {
    offset: 0,
    sortBy: "createOn",
  });
  const [orders, setOrders] = useState<EbayOrder[]>([]);
  const [activeId, setActiveId] = useState<number>();
  const { currencyCode = "USD" } = useStoreDetails();

  const loadResults = (pageOffset: number, sort: string) => {
    setLoading(true);

    fetchOrders(pageOffset, ITEMS_PER_PAGE, sort)
      .then((data: EbayOrder[]) => setOrders(data))
      .finally(() => setLoading(false));
  };

  const loadResultsDebounced = useRef(debounce(loadResults, 500));

  const handleChangeSort = (sort: string) => {
    setPagination({ sortBy: sort, offset: 0 });
    loadResultsDebounced.current(0, sort);
  };

  const getNextPage = () => {
    const newOffset = pagination?.offset + ITEMS_PER_PAGE;
    setPagination({ offset: newOffset, sortBy: pagination?.sortBy });
    loadResults(newOffset, pagination?.sortBy);
  };

  const getPrevPage = () => {
    const newOffset = pagination?.offset - ITEMS_PER_PAGE;
    if (newOffset < 0) return;
    setPagination({ offset: newOffset, sortBy: pagination?.sortBy });
    loadResults(newOffset, pagination?.sortBy);
  };

  useEffect(() => loadResults(pagination?.offset, pagination?.sortBy), []);

  return (
    <div className="ebay-orders-view">
      <h3 className="ebay-orders-view__title">Stock Change History</h3>
      <div className="ebay-orders-view__filters">
        <div className="ebay-orders-view__filter">
          <span className="ebay-orders-view__label">Sort by</span>
          <select onChange={(event) => handleChangeSort(event.target.value)}>
            <option value="createOn">Created Date</option>
            <option value="status">Status</option>
          </select>
        </div>
      </div>
      <div className="ebay-orders-view__results">
        {loading ? (
          <Loader />
        ) : (
          <>
            <table className="ebay-orders-view__table">
              <thead>
                <tr>
                  <th>Order Id</th>
                  <th>Customer Email</th>
                  <th>Total</th>
                  <th>Created on</th>
                  <th>Last Updated</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {orders.map((order: EbayOrder) => (
                  <tr className="ebay-orders-view__result" key={order.orderId}>
                    <td>{order.orderId}</td>
                    <td>{order.customerEmail}</td>
                    <td>{formatLocalCurrency(order.total, currencyCode)}</td>
                    <td>
                      {TimeUtils.convertDateToHumanReadable(
                        order.createOn * 1000
                      )}
                    </td>
                    <td>
                      {TimeUtils.convertDateToHumanReadable(order.lastUpdated)}
                    </td>
                    <td>{order.status ? "Success" : "Error"}</td>
                    <td>
                      <button
                        className="btn1 ebay-orders-view__button"
                        onClick={() => setActiveId(order.id)}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Paging
              pageOffset={pagination?.offset ?? 0}
              currentPageItemCount={orders.length}
              maxItemsPerPage={ITEMS_PER_PAGE}
              getNextPage={getNextPage}
              getPrevPage={getPrevPage}
            />
          </>
        )}
      </div>
      {activeId ? (
        <Modal onClose={() => setActiveId(undefined)}>
          <Modal.Header>Order Details</Modal.Header>
          <Modal.Content>
            <EbayOrderDetailsContainer id={activeId} />
          </Modal.Content>
        </Modal>
      ) : null}
    </div>
  );
}

export default EbayOrdersView;
