import { ShopifyCustomer } from "../types/order";

export function mapCustomer(data: any): ShopifyCustomer {
  return (
    data.ShopifyCustomer && {
      email: data.ShopifyCustomer.email,
      firstName: data.ShopifyCustomer.firstName,
      lastName: data.ShopifyCustomer.lastName,
      storeCredit: data.ShopifyCustomer.storeCredit
        ? Number(data.ShopifyCustomer.storeCredit)
        : 0,
      id: data.ShopifyCustomer.id,
      notes: data.ShopifyCustomer.notes,
      phone: data.ShopifyCustomer.phone,
    }
  );
}
