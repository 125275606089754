import { GetBuylistReportQuery } from "../../generated/reporting";
import { BuylistReportData } from "../../types/reports";

export const formatBuylistReport = (
  report?: GetBuylistReportQuery
): BuylistReportData[] => {
  const buylistDetails = report?.Metrics?.BuylistDetails;
  if (!buylistDetails) {
    return [];
  }
  return buylistDetails.map((detail) => ({
    ...detail,
    customerName: `${detail.firstName} ${detail.lastName}`,
    dateCompleted: Number(detail.dateCompleted),
    dateSubmitted: Number(detail.dateSubmitted),
  }));
};
