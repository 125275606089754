import { QueryKey, UseQueryOptions } from "react-query";
import {
  GetSubmittedCartsQuery,
  SortOrder,
  useGetSubmittedCartsQuery,
} from "../generated/graphql";
import { formatSubmittedCarts } from "../transformers/formatSubmittedCarts";
import { SubmittedCart } from "../types/order";

export interface CartListParams {
  cartId?: string;
  shopifyOrderId?: string;
  firstName?: string;
  lastName?: string;
  containsNote?: string;
  startDate?: string;
  toDate?: string;
  timeZone?: string;
  offset?: number;
  limit?: number;
  tillId?: number;
  orderBy?: string;
  desc?: boolean;
  oldestFirst?: boolean;
}

function getOrderByField(orderBy: string, desc: boolean) {
  const direction: SortOrder = desc ? SortOrder.Desc : SortOrder.Asc;
  switch (orderBy) {
    case "till.name":
      return [
        {
          BinderCustomerTill: {
            name: direction,
          },
        },
      ];
    case "firstName":
    case "lastName":
    case "email":
      return [
        {
          ShopifyCustomer: {
            [orderBy]: direction,
          },
        },
      ];
    default:
      return [
        {
          [orderBy]: direction,
        },
      ];
  }
}

export const useFetchSubmittedCarts = (
  params: CartListParams,
  options: UseQueryOptions<
    GetSubmittedCartsQuery,
    unknown,
    SubmittedCart[],
    QueryKey
  > = {}
) => {
  const {
    offset: skip = 0,
    limit: take = 20,
    timeZone: _timeZone,
    toDate: endDate,
    orderBy: orderBy = "dateSubmitted",
    desc = true,
    ...rest
  } = params;
  const orderByField = getOrderByField(orderBy, desc);
  return useGetSubmittedCartsQuery(
    {
      orderBy: orderByField,
      skip,
      take,
      where: {
        ...rest,
        endDate,
      },
    },
    {
      staleTime: 60_000,
      refetchOnWindowFocus: false,
      refetchOnMount: "always",
      keepPreviousData: true,
      select: ({ getAllCarts: orders }) =>
        orders.map<SubmittedCart>((order) => formatSubmittedCarts(order)),
      ...options,
    }
  );
};
