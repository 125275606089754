import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import ButtonComponent from "../generic/ButtonComponent";
import Modal from "../generic/Modal";
import "./OpenTillModal.scss";
import { ItemList } from "../../pos/ItemStore";

interface OpenTillModalProps extends RouteComponentProps {
  posStore?: ItemList;
}

@inject("posStore")
@observer
class OpenTillModal extends Component<OpenTillModalProps> {
  componentDidMount() {
    if (
      this.props.posStore.previousTillId &&
      this.props.posStore.previousTillId != -1
    ) {
      this.selectTill(this.props.posStore.previousTillId);
    }
  }

  floatUpdate = (event) => {
    const { value } = event.target;
    if (isNaN(value) && value != "-") {
      this.props.posStore.setFloatOpenAmount(value.replace(/[^0-9.-]/g, ""));
    } else {
      this.props.posStore.setFloatOpenAmount(value);
    }
  };

  validateFormat = (event) => {
    const { value } = event.target;
    if (value === "" || isNaN(value)) {
      this.props.posStore.setFloatOpenAmount("0.00");
    } else {
      this.props.posStore.setFloatOpenAmount(parseFloat(value).toFixed(2));
    }
  };

  selectAll = (event) => {
    event.target.select();
  };

  selectTill = (selectedTill) => {
    if (selectedTill != this.props.posStore.tillId) {
      this.props.posStore.setTill(selectedTill);
    }
    if (
      this.props.posStore.tillList.find((till) => till.id == selectedTill)?.open
    ) {
      this.props.posStore.setForceTill(true);
    } else {
      this.props.posStore.setForceTill(false);
    }
  };

  changeTill = (event) => this.selectTill(event.target.value);

  leavePos = () => {
    this.props.posStore.unsetTill(false);
    setTimeout(() => this.props.history.push("/dashboard"), 100);
  };

  render() {
    const store = this.props.posStore;

    return (
      <Modal small className="OpenTillModal">
        <Modal.Header>
          <span className="OpenTillModal__header">
            BinderPOS - Point of Sale
            <button
              className="OpenTillModal__leave-button"
              aria-label="Leave POS"
              onClick={this.leavePos}
            >
              <i className="fal fa-door-open"></i> Leave POS
            </button>
          </span>
        </Modal.Header>
        <Modal.Content>
          <label htmlFor="till" className="label">
            Select till:
          </label>
          <select
            id="till"
            className="OpenTillModal__till-select"
            onChange={this.changeTill}
            value={store.tillId ?? store.previousTillId}
            data-testid="till-dropdown"
          >
            <option value={-1} key="-1">
              No Till Selected
            </option>
            {store.tillList.map((till) => (
              <option value={till.id} key={till.id}>
                {till.name +
                  "\t-\t" +
                  till.description +
                  (till.open ? " (Opened)" : "")}
              </option>
            ))}
          </select>
          <hr />
          <label htmlFor="openingFloat" className="label">
            Opening float:
          </label>
          <input
            id="openingFloat"
            className="input"
            value={
              this.props.posStore.forceTill ? "Opened" : store.floatOpenAmount
            }
            onChange={this.floatUpdate}
            onFocus={this.selectAll}
            disabled={store.forceTill}
            onBlur={this.validateFormat}
            type="textbox"
            data-testid="opening-float"
          />
        </Modal.Content>
        <Modal.Actions>
          <ButtonComponent
            primary
            onClick={store.submitFloat}
            icon="fas fa-caret-right"
            iconPosition="right"
          >
            {store.forceTill ? "Force Login to Open Till" : "Set Opening Float"}
          </ButtonComponent>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withRouter(OpenTillModal);
