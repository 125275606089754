import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { TimeUtils } from "../../utils";
import { getRecurringDescription } from "../../helpers/eventHelpers";
import { parseNumericParam } from "../../helpers/routeHelpers";
import { useShowError } from "../../hooks/errorHooks";
import { useFetchEventInfo } from "../../hooks/eventHooks";
import { useFormatCurrency } from "../../hooks/storeHooks";
import { EventMenuStructure } from "../../menuStructures";
import SetActiveMenu from "../../menuStructures/SetActiveMenu";
import HtmlContent from "../../components/generic/HtmlContent";
import Loader from "../../components/generic/Loader";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";
import "./ViewEvent.scss";
import ActionButtonsLayout from "../../components/layout/ActionButtonsLayout";
import ButtonComponent from "../../components/generic/ButtonComponent";

type RouteParams = {
  eventId?: string;
};

function Event() {
  const { eventId: eventIdParam } = useParams<RouteParams>();

  const eventId = parseNumericParam(eventIdParam);
  const { data: eventInfo, isLoading, error } = useFetchEventInfo(eventId);
  const showError = useShowError();
  const formatCurrency = useFormatCurrency();

  useEffect(() => {
    if (error) {
      showError(
        error as DetailedError,
        "Unable to load event details",
        "Please refresh and try again"
      );
    }
  }, [error]);

  if (isLoading) return <Loader />;

  if (!eventInfo?.id) {
    return <p>No matching event found</p>;
  }

  return (
    <>
      <SetActiveMenu menuStructure={EventMenuStructure} />
      <SectionHeaderLayout title={eventInfo?.title}>
        <ActionButtonsLayout>
          <ButtonComponent primary isLink to={`/events/update/${eventInfo.id}`}>
            Edit
          </ButtonComponent>
        </ActionButtonsLayout>
      </SectionHeaderLayout>
      <div className="ViewEvent">
        <div className="ViewEvent__formSection ViewEvent__formSection--top">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-4">
                <label className="label">Game</label>
                <p>{eventInfo.game}</p>
              </div>
              <div className="col-lg-4">
                <label className="label">Event type</label>
                <p>{eventInfo.type}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="ViewEvent__formSection">
          <div className="container-fluid">
            <p className="ViewEvent__formSectionHeader">Schedule</p>
            <div className="row">
              <div className="col-lg-4">
                <label className="label">Event date</label>
                <p>
                  {TimeUtils.convertDateToHumanReadableNoTime(eventInfo?.date)}
                </p>
              </div>
              <div className="col-lg-4">
                <label className="label">Start time</label>
                <p>{eventInfo.time}</p>
              </div>
              <div className="col-lg-4">
                <label className="label">Event frequency</label>
                <p>{getRecurringDescription(eventInfo)}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="ViewEvent__formSection">
          <div className="container-fluid">
            <p className="ViewEvent__formSectionHeader">Location</p>
            <div className="row">
              <div className="col-lg-4">
                <label className="label">Building Name</label>
                <p>{eventInfo.buildingName}</p>
              </div>
              <div className="col-lg-4">
                <label className="label">Street Address</label>
                <p>{eventInfo.streetAddress}</p>
              </div>
              <div className="col-lg-4">
                <label className="label">City</label>
                <p>{eventInfo.city}</p>
              </div>
              <div className="col-lg-4">
                <label className="label">State</label>
                <p>{eventInfo.state}</p>
              </div>
              <div className="col-lg-4">
                <label className="label">Zip / Postal code</label>
                <p>{eventInfo.zipCode}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="ViewEvent__formSection">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <label className="label">Prize pool</label>
                <p>{eventInfo.prizePool}</p>
              </div>
              <div className="col-lg-12">
                <label className="label">Description</label>
                <HtmlContent content={eventInfo.description} />
              </div>
              <div className="col-lg-12">
                <label className="label">Prize structure</label>
                <HtmlContent content={eventInfo.prizeStructure} />
              </div>
              <div className="col-lg-12">
                <label className="label">Event results</label>
                <HtmlContent content={eventInfo.results} />
              </div>
            </div>
          </div>
        </div>

        <div className="ViewEvent__formSection">
          <div className="container-fluid">
            <p className="ViewEvent__formSectionHeader">Event images</p>
            <div className="row">
              <div className="col-md-12 col-lg-4">
                <label className="label">Calendar Icon</label>
                <p>
                  <img
                    src={eventInfo?.calendarIcon}
                    className="ViewEvent__image"
                    alt={`${eventInfo?.title} icon`}
                  />
                </p>
              </div>
              <div className="col-md-12 col-lg-4">
                <label className="label">Banner image</label>
                <img
                  src={eventInfo?.banner}
                  className="ViewEvent__image"
                  role="presentation"
                />
              </div>
              <div className="col-md-12 col-lg-4">
                <label className="label">Ticket image</label>
                <img
                  src={eventInfo?.ticketImage}
                  className="ViewEvent__image"
                  alt={`${eventInfo?.title} ticket`}
                />
              </div>
            </div>
          </div>
        </div>
        {eventInfo.isTicketed ? (
          <div className="ViewEvent__formSection">
            <div className="container-fluid">
              <p className="ViewEvent__formSectionHeader">Ticketing</p>
              <div className="row">
                <div className="col-lg-4">
                  <label className="label">Ticket price</label>
                  <p>{formatCurrency(eventInfo?.ticketPrice)}</p>
                  <p className="ViewEvent__helpText">
                    A price of 0 will mean the ticket is free
                  </p>
                </div>
                <div className="col-lg-4">
                  <label className="label">Maximum participants</label>
                  <p>{eventInfo.maxParticipants}</p>
                  <p className="ViewEvent__helpText">
                    0 indicates that there are unlimited tickets available
                  </p>
                </div>
                <div className="col-lg-4">
                  <label className="label">Charge tax on event ticket</label>
                  <p>{eventInfo.taxable ? "Yes" : "No"}</p>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {eventInfo.additionalInfoRequired ? (
          <div className="ViewEvent__formSection">
            <div className="container-fluid">
              <p className="ViewEvent__formSectionHeader">
                Additional information fields
              </p>
              <div className="row">
                {eventInfo.additionalInfo.map((additionalInfo, index) => (
                  <div className="col-md-12 col-lg-4" key={index}>
                    <p>{additionalInfo.header}</p>
                    <p className="ViewEvent__helpText">
                      {additionalInfo.headerDescription}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default Event;
