import React from "react";
import { Heading } from "../../../types/elements";

interface ModalHeaderProps {
  as?: Heading;
  children: React.ReactNode;
}

function ModalHeader(props: ModalHeaderProps) {
  const { as: HeadingElement = "h2", children } = props;

  return (
    <header className="NewModal__header">
      <HeadingElement className="NewModal__title">{children}</HeadingElement>
    </header>
  );
}

ModalHeader.isModalHeader = true;

export default ModalHeader;
