import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { _fetch } from "../../api";
import ButtonComponent from "../../components/generic/ButtonComponent";
import { API_V2_BASE_URL } from "../../constants/api";
import useTitle from "../../hooks/useTitle";
import { useStoreDetails } from "../../hooks/storeHooks";
import "./ShopifyAuth.scss";

function ShopifyAuth() {
  const { appType } = useParams<{ appType: string }>();
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<string>();
  const { customerSiteUrl } = useStoreDetails();
  useTitle("Shopify Auth");

  const useQuery = () => {
    const query = window.location.href.split("?")[1];
    const queryWithoutHash = query.split("#")[0];
    const params = new URLSearchParams(queryWithoutHash);
    const searchParams = new URLSearchParams(params);
    return searchParams;
  };

  const query = useQuery();

  const history = useHistory();

  const shop = query.get("shop");

  //If the shop param passed from Shopify is the same as the current store's siteUrl, then we know the user is already linked
  const alreadyLinked = customerSiteUrl === shop;

  const submitValidation = async () => {
    setIsProcessing(true);
    return _fetch({
      endpoint: `${API_V2_BASE_URL}/shopifyApps/${appType}/validate?${query}`,
      method: "POST",
      credentials: "include",
    })
      .then((response) => {
        if (response.status === "ok") {
          setIsProcessing(false);
          //redirect to main page
          window.history.replaceState({}, document.title, "/");
          history.push("/");
          return response;
        } else {
          setIsProcessing(false);
          setError(
            "Error linking with Shopify please contact BinderPOS support."
          );
          console.error(response);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsProcessing(false);
        setError("Error linking with Shopify please contact BinderPOS support");
        if (error.error) {
          setError(error.error + " Please contact BinderPOS support.");
        }
      });
  };

  useEffect(() => {
    if (alreadyLinked) {
      submitValidation();
    }
  }, [alreadyLinked]);

  if (alreadyLinked) {
    return (
      <div className="ShopifyAuth__background">
        <div className="ShopifyAuth__wrapper">
          <div className="shopify-auth__content__body">
            <p>Verifying Shopify connection and redirecting to portal...</p>
            {error ? (
              <div className="ShopifyAuth__error">
                <span className="ShopifyAuth__errorHeader">Error: </span>
                {error}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="ShopifyAuth__background">
      <div className="ShopifyAuth__wrapper">
        <div>
          <h2>Connecting to BinderPOS</h2>
        </div>
        <div className="shopify-auth__content__body">
          <p>
            Click the button below to link{" "}
            <a className="ShopifyAuth__anchorBadge">{shop}</a> to your BinderPOS
            account with the
            {appType == "portal"
              ? " BinderPOS Integration App"
              : " BinderPOS Catalog App"}{" "}
          </p>
          {error ? (
            <div className="ShopifyAuth__error">
              <span className="ShopifyAuth__errorHeader">Error: </span>
              {error}
            </div>
          ) : null}

          <ButtonComponent
            primary
            className="ShopifyAuth__button"
            onClick={() => submitValidation()}
            disabled={isProcessing}
          >
            Link
          </ButtonComponent>
        </div>
      </div>
    </div>
  );
}

export default ShopifyAuth;
