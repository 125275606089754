import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import FocusTrap from "focus-trap-react";
import OutsideClickHandler from "react-outside-click-handler";
import CloseButton from "./CloseButton";
import ModalActions from "./ModalActions";
import ModalContent from "./ModalContent";
import ModalHeader from "./ModalHeader";
import ButtonComponent from "../ButtonComponent";
import Overlay from "../Overlay";
import Spinner from "../Spinner";
import { getChildByType } from "../../../helpers/componentHelpers";
import { joinClasses } from "../../../utils/styling";
import "./Modal.scss";

interface ModalProps {
  isLoading?: boolean;
  small?: boolean;
  large?: boolean;
  plain?: boolean;
  onClose?: () => void;
  className?: string;
  testId?: string;
  children: React.ReactNode;
}

interface ModalComponent
  extends React.ForwardRefExoticComponent<
    ModalProps & React.RefAttributes<HTMLDivElement>
  > {
  Header: typeof ModalHeader;
  Actions: typeof ModalActions;
  Content: typeof ModalContent;
}

const forwardRef = React.forwardRef<HTMLElement, ModalProps>(
  (props: ModalProps, ref: React.MutableRefObject<any>) => {
    const {
      onClose = undefined,
      isLoading = false,
      plain = false,
      small = false,
      large = false,
      className,
      testId,
      children,
    } = props;
    const containerRef = useRef<HTMLDivElement>();
    const modalHeader = getChildByType("isModalHeader", children);
    const modalContent = getChildByType("isModalContent", children);
    const modalActions = getChildByType("isModalActions", children);

    const handleClose = () => (onClose ? onClose() : null);

    useEffect(() => {
      const closeOnEscape = (event: KeyboardEvent) =>
        event.code === "Escape" ? handleClose() : null;

      document.addEventListener("keydown", closeOnEscape);
      return () => document.removeEventListener("keydown", closeOnEscape);
    }, []);

    const modalClass = joinClasses([
      "NewModal__modal",
      plain ? "NewModal__modal--plain" : "NewModal__modal--styled",
      small ? "NewModal__modal--small" : null,
      large ? "NewModal__modal--large" : null,
      className,
    ]);

    const defaultClose = onClose ? (
      <Modal.Actions>
        <ButtonComponent primary fullWidth onClick={onClose}>
          Close
        </ButtonComponent>
      </Modal.Actions>
    ) : null;

    return ReactDOM.createPortal(
      <Overlay fade showOverlay noAppear dismissHandler={handleClose}>
        <FocusTrap>
          <div className="NewModal__container" ref={containerRef}>
            <OutsideClickHandler
              onOutsideClick={(e: React.MouseEvent & React.KeyboardEvent) => {
                if (e.target === containerRef.current || e.key === "Escape") {
                  handleClose();
                }
              }}
            >
              <div
                className={modalClass}
                aria-modal="true"
                role="dialog"
                data-testid={testId}
              >
                <CloseButton plain={plain} onClose={onClose} ref={ref} />
                {modalHeader}
                <Spinner isLoading={isLoading}>
                  <div className="NewModal__content">{modalContent}</div>
                  {modalActions ?? defaultClose}
                </Spinner>
              </div>
            </OutsideClickHandler>
          </div>
        </FocusTrap>
      </Overlay>,
      document.getElementById("app-modal")
    );
  }
);

const Modal = {
  ...forwardRef,
  Header: ModalHeader,
  Actions: ModalActions,
  Content: ModalContent,
} as ModalComponent;

export default Modal;
