import React from "react";
import { Formik, Form, Field } from "formik";
import { TillSetting } from "../../types/settings";
import Input from "../form/Input";
import ButtonComponent from "../generic/ButtonComponent";
import CheckboxComponent from "../generic/CheckboxComponent";
import SubSectionHeaderLayout from "../layout/SubSectionHeaderLayout";
import { paxSettingsSchema } from "../../schemas/pointOfSaleSettings";
import { notify } from "../../helpers/notificationHelpers";
import { tillSettingsToObject } from "../../helpers/settingHelpers";
import { useCreateTerminalSettings } from "../../hooks/paymentTerminalHooks";
import PAX from "../../pos/PAX.js";
import "./PaxSettings.scss";
import { useShowError } from "../../hooks/errorHooks";
import {
  SAVE_PAX_SETTINGS_CLICKED,
  TEST_TERMINAL_CONNECTION_CLICKED,
} from "../../constants/eventsTracked";

type FormValues = {
  paymentProvider: string;
  paymentTerminalEnabled: boolean;
  paxIP: string;
  dnsResolver: string;
  paxEnabled: string;
  paxSerial: string;
};

interface PaxSettingsProps {
  tillId: number;
  tillSettings: TillSetting[];
}

function PaxSettings(props: PaxSettingsProps) {
  const { tillId, tillSettings } = props;
  const showError = useShowError();
  const { mutateAsync } = useCreateTerminalSettings();
  const fieldNames = ["paxIP", "dnsResolver", "paxEnabled", "paxSerial"];

  const initialValues = {
    paymentProvider: "PAX",
    paymentTerminalEnabled: true,
    paxIP: "",
    paxSerial: "",
    paxEnabled: "false",
    dnsResolver: "false",
    ...tillSettingsToObject(tillSettings, fieldNames),
  } as FormValues;

  const handleSubmit = (settings: typeof initialValues) =>
    mutateAsync({ tillId, settings })
      .then(() => notify.info("Settings updated"))
      .catch((error) => showError(error));

  const testTerminalSettings = async (paxIP: string, dnsResolver: string) => {
    let newPaxIP = paxIP;
    if (dnsResolver === "true") {
      newPaxIP = `${tillId}.dns.binderpos.com:10009`;
    }
    const pax = new PAX(`https://${newPaxIP}`);
    pax.creditSale(0.01);
  };

  return (
    <div className="PaxSettings">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={paxSettingsSchema}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <SubSectionHeaderLayout title="PAX Terminal Settings" />
            <Field
              component={Input}
              name="paxSerial"
              label="Serial Number"
              helperText="The serial number of the unit you want to bond with this till. On the back of an S300 it looks like S/N: xxxxxxxx"
              placeholder="S/N: xxxxxxxx"
            />
            <Field
              component={Input}
              name="paxIP"
              label="IP Address"
              helperText="The Local IP Address of your payment terminal including port. This should auto-update when the PAX unit is plugged in"
              placeholder="Example: 192.168.1.200:10009"
            />
            <div className="PaxSettings__smallInputs">
              <CheckboxComponent
                name="paxEnabled"
                label="Payment terminal enabled"
                checked={values.paxEnabled === "true"}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setFieldValue(
                    "paxEnabled",
                    event.target.checked ? "true" : "false"
                  )
                }
              />
              <CheckboxComponent
                name="dnsResolver"
                label="Use DNS resolver"
                checked={values.dnsResolver === "true"}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setFieldValue(
                    "dnsResolver",
                    event.target.checked ? "true" : "false"
                  )
                }
              />
              <ButtonComponent
                primary
                onClick={() =>
                  testTerminalSettings(values.paxIP, values.dnsResolver)
                }
                segmentEventName={TEST_TERMINAL_CONNECTION_CLICKED}
              >
                Test terminal communication
              </ButtonComponent>
            </div>
            <ButtonComponent
              primary
              fullWidth
              type="submit"
              disabled={isSubmitting}
              segmentEventName={SAVE_PAX_SETTINGS_CLICKED}
            >
              Save Changes
            </ButtonComponent>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default PaxSettings;
