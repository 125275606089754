import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import ReceiptOptionsContainer from "../generic/ReceiptOptionsContainer";
import { ItemList } from "../../pos/ItemStore";

interface ReceiptModalProps {
  posStore?: ItemList;
}

@inject("posStore")
@observer
class ReceiptModal extends Component<ReceiptModalProps> {
  render() {
    const store = this.props.posStore;
    const isModalOpen = store.receiptModal;

    return isModalOpen ? (
      <ReceiptOptionsContainer
        cartId={store.cartId}
        changeDue={store.changeDue}
        closeReceipt={store.closeReceipt}
        completedReceipt={store.completedReceipt}
        isModalOpen={isModalOpen}
        title="Receipt Preview"
      />
    ) : (
      <></>
    );
  }
}

export default ReceiptModal;
