import { GetSalesByOrderQuery } from "../../generated/reporting";

export const formatSalesByOrder = (report?: GetSalesByOrderQuery) => {
  let salesByOrder = report?.Metrics?.SalesByOrder;
  if (!salesByOrder) salesByOrder = [];

  const tenderTypes = [
    ...new Set(
      salesByOrder
        .filter((order) => order.tenders)
        .map((order) => order.tenders.map((tender) => tender.type))
        .flat()
    ),
  ];
  tenderTypes.sort();

  // Split out tenders and add an ID field so the table can use it as a key
  const rows = salesByOrder.map((row, index) => {
    tenderTypes.forEach((tender) => {
      const currentTender = row.tenders?.find((value) => value.type === tender);
      row[tender] = currentTender?.amount || 0;
    });
    return {
      ...row,
      id: index,
    };
  });

  const sources = [...new Set(salesByOrder.map((order) => order.source))];

  return {
    rows,
    tenderTypes,
    sources,
  };
};
