import { EventInfo } from "../../types/events";
import { Column } from "../../types/table";
import { TimeUtils } from "../../utils";
import IconButton from "../../components/generic/IconButton";

const ParentEventColumns: Column[] = [
  { header: "Title", accessorKey: "title" },
  { header: "Game", accessorKey: "game" },
  { header: "Type", accessorKey: "type" },
  {
    header: "Start date & time",
    accessorKey: "date",
    cell: (_value, _column, parentEvent: EventInfo) =>
      `${TimeUtils.convertDateToHumanReadableNoTime(
        parentEvent.date
      )} at ${TimeUtils.convertTime(parentEvent.time)}`,
  },
  {
    header: "",
    accessorKey: "id",
    cell: (
      id,
      _column,
      parentEvent: EventInfo,
      _index,
      { setEventToView, setEventToDelete }
    ) => (
      <>
        <IconButton
          icon="fal fa-eye"
          title="View event details."
          onClick={() => setEventToView(parentEvent)}
        />
        <IconButton
          icon="fal fa-edit"
          isLink
          title="Update master event information."
          to={`/events/update/${id}`}
        />
        <IconButton
          danger
          icon="fal fa-trash-alt"
          title="Delete the event including recurring events."
          onClick={() => setEventToDelete(parentEvent)}
        />
      </>
    ),
  },
];

export default ParentEventColumns;
