import React from "react";
import { Formik, Form, Field } from "formik";
import Input from "../../components/form/Input";
import ButtonComponent from "../../components/generic/ButtonComponent";
import CheckboxComponent from "../../components/generic/CheckboxComponent";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";
import { useFetchSetting, useUpdateSetting } from "../../hooks/settingHooks";
import SetActiveMenu from "../../menuStructures/SetActiveMenu";
import POSMenuStructure from "../../menuStructures/POSMenuStructure";
import { kioskSettingsSchema } from "../../schemas/pointOfSaleSettings";
import { Loader } from "../../components";
import { segmentAnalytics } from "../../components/services/Analytics";
import {
  ENABLE_KIOSK_BUY_MODE_TOGGLED,
  SAVE_KIOSK_SETTINGS_CLICKED,
} from "../../constants/eventsTracked";
import { notify } from "../../helpers/notificationHelpers";

function KisokSettings() {
  const { data: kioskBuyModeEnabled, isLoading: isLoadingEnabled } =
    useFetchSetting("kioskBuyModeEnabled");
  const { data: kioskBuyModeLabel, isLoading: isLoadingBuyLabel } =
    useFetchSetting("kioskBuyModeLabel");
  const { data: kioskSellModeLabel, isLoading: isLoadingSellLabel } =
    useFetchSetting("kioskSellModeLabel");
  const { mutateAsync } = useUpdateSetting();

  const initialValues = {
    // Enable kioskBuyModeEnabled unless explicitly disabled
    kioskBuyModeEnabled: kioskBuyModeEnabled === "false" ? false : true,
    kioskBuyModeLabel: kioskBuyModeLabel || "",
    kioskSellModeLabel: kioskSellModeLabel || "",
  };

  type Setting = typeof initialValues;

  const saveFunction = (
    fieldName: keyof Setting,
    initial: Setting,
    submitted: Setting
  ) => {
    if (initial[fieldName] === submitted[fieldName]) {
      return false;
    }
    return mutateAsync({
      settingName: fieldName,
      settingValue: submitted[fieldName],
    });
  };

  const onSubmit = async (values: typeof initialValues) => {
    const saveSettings = [
      saveFunction("kioskBuyModeEnabled", initialValues, values),
      saveFunction("kioskBuyModeLabel", initialValues, values),
      saveFunction("kioskSellModeLabel", initialValues, values),
    ];
    return Promise.all(saveSettings.filter(Boolean))
      .then(() => notify.info("Kiosk settings updated"))
      .catch(() => notify.warn("Failed to save settings. Please try again"));
  };

  const isLoading = isLoadingEnabled || isLoadingBuyLabel || isLoadingSellLabel;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <SetActiveMenu menuStructure={POSMenuStructure} />
      <SectionHeaderLayout title="Kiosk Settings" />
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={kioskSettingsSchema}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <CheckboxComponent
                  name="kioskBuyModeEnabled"
                  label="Enable kiosk buy mode"
                  checked={values.kioskBuyModeEnabled}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    segmentAnalytics.track(ENABLE_KIOSK_BUY_MODE_TOGGLED, {
                      enable_kiosk_buy_mode: event.target.checked,
                    });
                    setFieldValue("kioskBuyModeEnabled", event.target.checked);
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <Field
                  component={Input}
                  name="kioskBuyModeLabel"
                  label="Buy mode label"
                  disabled={!values.kioskBuyModeEnabled}
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={Input}
                  name="kioskSellModeLabel"
                  label="Sell mode label"
                  disabled={!values.kioskBuyModeEnabled}
                />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-12">
                <ButtonComponent
                  primary
                  type="submit"
                  disabled={isSubmitting}
                  segmentEventName={SAVE_KIOSK_SETTINGS_CLICKED}
                  segmentEventProperties={{
                    enable_kiosk_buy_mode: values.kioskBuyModeEnabled,
                  }}
                >
                  Save Changes
                </ButtonComponent>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default KisokSettings;
