import "firebase/auth";
import React from "react";

function SignInServiceStatus(props) {
  const { signOut } = props;

  return (
    <div>
      <p className="title recover">Unable to Complete Sign In</p>
      <p className="errorDescription">
        Sorry, we are experiencing issues at the moment, which may be preventing
        users from sigining in.
      </p>
      <button className="signin" onClick={() => signOut()}>
        Retry
      </button>
    </div>
  );
}

export default SignInServiceStatus;
