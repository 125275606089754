import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { TimeUtils } from "../../utils";

@inject("store")
@observer
class PrintBuylist extends Component {
  render() {
    const { BuylistRulesStore, MainStore } = this.props.store;
    const fC = MainStore.currencyBuilder;
    const isPaidInCash =
      BuylistRulesStore.buylistDetails.paymentType === "Cash";
    return (
      <div className="buylistPrint">
        <div className="buylistPrint__details">
          <p>Buylist #{BuylistRulesStore.buylistDetails.id}</p>
          <p>
            {TimeUtils.convertDateToHumanReadable(
              BuylistRulesStore.buylistDetails.readableSubmittedDate
            )}
          </p>
          <p>Payment type: {BuylistRulesStore.buylistDetails.paymentType}</p>
        </div>
        <table>
          <thead>
            <tr>
              <td></td>
              <td className="buylistPrint__header">Quantity</td>
              <td className="buylistPrint__header">Card</td>
              <td className="buylistPrint__header--secondary">Set</td>
              <td className="buylistPrint__header--secondary">Condition</td>
              <td>Buy Price</td>
              <td className="buylistPrint__header--sell">Sell Price</td>
            </tr>
          </thead>
          <tbody>
            {BuylistRulesStore.buylistDetails.cards.map((buylistDetail) => {
              const { imageUrl, cardName, type, setName, variants } =
                buylistDetail;
              return Object.values(variants).map((variant, j) => {
                const {
                  cashBuyPrice,
                  storeCreditBuyPrice,
                  storeSellPrice,
                  variantName,
                  quantity,
                } = variant;
                const buyPrice = isPaidInCash
                  ? cashBuyPrice
                  : storeCreditBuyPrice;
                if (variant.quantity > 0) {
                  return (
                    <tr key={j}>
                      <td>
                        <img
                          className="buylistPrint__cardImage"
                          src={imageUrl}
                        />
                      </td>
                      <td>{quantity}</td>
                      <td className="buylistPrint__card">{`${cardName} ${
                        type ? "- " + type : ""
                      }`}</td>
                      <td className="buylistPrint__card buylistPrint__card--secondary">
                        {setName}
                      </td>
                      <td className="buylistPrint__card buylistPrint__card--secondary">
                        {variantName}
                      </td>
                      <td className="buylistPrint__buyPrice">
                        {buyPrice && buyPrice > 0
                          ? `${fC(quantity * parseFloat(buyPrice))} (${fC(
                              parseFloat(buyPrice)
                            )})`
                          : fC(parseFloat(0.0))}
                      </td>
                      <td className="buylistPrint__sellPrice">
                        {storeSellPrice && storeSellPrice > 0
                          ? `${fC(quantity * parseFloat(storeSellPrice))} (${fC(
                              parseFloat(storeSellPrice)
                            )})`
                          : "N/A"}
                      </td>
                    </tr>
                  );
                }
              });
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default PrintBuylist;
