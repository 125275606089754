import React from "react";
import { Route, Switch } from "react-router-dom";
import Loader from "../components/generic/Loader";
import CfbMarketplaceConfigureView from "../views/integrations/cfbmarket/CfbMarketplaceConfigureView";
import AuthoriseCallbackView from "../views/integrations/ebay/AuthoriseCallbackView";
import AuthoriseContainer from "../views/integrations/ebay/AuthoriseContainer";
import ProvisioningAndAuthCheckContainer from "../views/integrations/ebay/ProvisioningAndAuthCheckContainer";
import ProvisioningContainer from "../views/integrations/ebay/ProvisioningContainer";
import IntegrationProvider from "../providers/IntegrationProvider";
import SelectIntegration from "../views/integrations/SelectIntegration";
import IntegtrationPlatformRouter from "./IntegrationPlatformRouter";

function IntegtrationRouter() {
  return (
    <IntegrationProvider>
      <Switch>
        <Route exact path="/integrations">
          <SelectIntegration />
        </Route>
        <Route exact path="/integrations/:integration/authorise">
          <AuthoriseCallbackView />
        </Route>
        <Route exact path="/integrations/:integration/reauthorise">
          <AuthoriseContainer />
        </Route>
        <Route exact path="/integrations/:integration/configure">
          <CfbMarketplaceConfigureView />
        </Route>
        <Route exact path="/integrations/:integration/forceAuth">
          <AuthoriseContainer />
        </Route>
        <Route path="/integrations/:integration">
          <ProvisioningAndAuthCheckContainer>
            {(
              provisionCheckCompleted: boolean,
              isProvisioned: boolean,
              isAuthorised: boolean,
              setIsProvisioned: Function
            ) =>
              !provisionCheckCompleted ? (
                <Loader />
              ) : !isProvisioned ? (
                <ProvisioningContainer setIsProvisioned={setIsProvisioned} />
              ) : !isAuthorised ? (
                <AuthoriseContainer />
              ) : (
                <IntegtrationPlatformRouter />
              )
            }
          </ProvisioningAndAuthCheckContainer>
        </Route>
      </Switch>
    </IntegrationProvider>
  );
}

export default IntegtrationRouter;
