import React, { useState } from "react";
import { doPushStock } from "../../api/rest/buylist";
import { notify } from "../../helpers/notificationHelpers";
import ConfirmationModal from "../generic/ConfirmationModal";
import ButtonComponent from "../generic/ButtonComponent";

interface PushStockProps {
  buylistId: number;
  pushProducts?: boolean;
}

function PushStock(props: PushStockProps) {
  const { buylistId, pushProducts } = props;
  const [isPushing, setIsPushing] = useState(false);
  const [hasPushed, setHasPushed] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleConfirmClick = () => {
    setShowConfirmation(false);
    setIsPushing(true);
    doPushStock(buylistId)
      .then(() => {
        setIsPushing(false);
        setHasPushed(true);
        notify.info("Stock has been pushed successfully");
      })
      .catch(() => {
        notify.error("Failed to push stock");
        setIsPushing(false);
      });
  };

  const handleCancelClick = () => setShowConfirmation(false);

  const handleUncompleteClick = () => setShowConfirmation(true);

  if (pushProducts !== false) {
    return null;
  }

  return (
    <>
      <ButtonComponent
        secondary
        onClick={() => handleUncompleteClick()}
        disabled={isPushing || hasPushed}
      >
        {isPushing ? "Pushing..." : "Push Stock"}
      </ButtonComponent>
      {showConfirmation ? (
        <ConfirmationModal
          cancelAction={handleCancelClick}
          confirmAction={handleConfirmClick}
          title="Push Stock"
        >
          Are you sure you want to push the stock for this buylist
        </ConfirmationModal>
      ) : null}
    </>
  );
}

export default PushStock;
