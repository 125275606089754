import { Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Game, Setting } from "../../api/rest/integrations";
import ButtonComponent from "../../components/generic/ButtonComponent";
import CheckboxComponent from "../../components/generic/CheckboxComponent";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";
import { useStoreDetails } from "../../hooks/storeHooks";
import { validateRequiredFields } from "../../utils/forms";
import { decimalToPercent, percentToDecimal } from "../../utils/integrations";
import { joinClasses } from "../../utils/styling";
import EbayListingConfirmationComponent from "./EbayListingConfirmationComponent";
import EbayListingForm from "./EbayListingForm";
import "./IntegrationSettingForm.scss";

const initialValues = {
  game: "",
  set: "all",
  productType: "",
  syncEnabled: false,
  priceMarkup: 0,
  reserveStock: "",
  maxToList: "",
  minPrice: "",
  maxPrice: "",
  ebayTitleTemplate: "",
  ebayMobileTemplate: "",
  ebayTemplate: "",
  categoryId: "",
  fulfilmentPolicyId: "",
  merchantLocationKey: "",
  paymentPolicyId: "",
  returnPolicyId: "",
  ebayAttributes: null,
  applyTax: false,
  vatPercentage: "",
} as Setting;

const requiredFields = [] as string[];

const requiredEbayFields = [
  "ebayTitleTemplate",
  "ebayTemplate",
  "fulfilmentPolicyId",
  "merchantLocationKey",
  "paymentPolicyId",
  "returnPolicyId",
  "categoryName",
];

const getValidator = (integration: string) => (values: Setting) => {
  if (integration === "ebay") {
    return validateRequiredFields(
      [...requiredFields, ...requiredEbayFields],
      values
    );
  }
  if (["cfbmarket", "tcgplayer"].includes(integration.toLowerCase())) {
    return genericValidator(requiredFields, values);
  }
  return validateRequiredFields(requiredFields, values);
};

const genericValidator = (_requiredFields: string[], values: Setting) => {
  const errors = validateRequiredFields(_requiredFields, values) as any;
  if (
    values.reserveStock !== "" &&
    !Number.isInteger(Number(values.reserveStock))
  ) {
    errors.reserveStock = "Reserve stock should be a valid integer";
  }
  if (values.maxToList !== "" && !Number.isInteger(Number(values.maxToList))) {
    errors.maxToList = "Maximum list should be a valid integer";
  }
  // @ts-ignore
  if (values.maxToList && values.maxToList !== "" && values.maxToList < 0) {
    errors.maxToList = "Maximum list should be greater than or equal to zero";
  }
  if (values.priceMarkup !== "" && values.priceMarkup <= -99) {
    errors.priceMarkup =
      "Negative markups can result in $0 price. Try a positive or higher number.";
  }
  return errors;
};

const getListingType = (game?: string) => {
  if (game) {
    return game.endsWith("s") && game.length > 3 ? "sealed" : "single";
  }
  return "other";
};

const getDuplicateDescription = (
  game?: string,
  setName?: string,
  productType?: string
) => {
  if (productType) return "A rule already exists for this product type";
  if (!game) return "A rule already exists for all games.";
  if (!setName) return "A rule already exists for this game and all sets.";
  return "A rule already exists for this combination of game and set.";
};

const getInitialIsSingles = (setting?: Setting) => {
  if (!setting?.id) return true;
  if (!setting.productType) return true;
  return false;
};

const maySubmit = (dirty: boolean, isValid: boolean) => {
  if (!dirty) return true;
  return isValid;
};

interface IntegrationSettingProps {
  integration: string;
  integrationSyncFriendlyName: string;
  games: Game[];
  sets: string[];
  productTypes: string[];
  setting?: Setting;
  loadSets: Function;
  checkDuplicates: Function;
  saveSetting: Function;
}

function IntegrationSettingForm(props: IntegrationSettingProps) {
  const {
    integration,
    integrationSyncFriendlyName,
    setting,
    games,
    sets,
    productTypes,
    loadSets,
    checkDuplicates,
    saveSetting,
  } = props;
  const formRef = useRef<HTMLElement>();
  const isExistingSetting = Number.isFinite(setting?.id);
  const { currencySymbol } = useStoreDetails();
  const [isDuplicate, setIsDuplicate] = useState(
    checkDuplicates(
      setting?.id,
      setting?.game,
      setting?.setName,
      setting?.productType
    )
  );

  // TODO Double check if this logic can be reused for singles/sealed/others
  const [isSingles, setIsSingles] = useState(getInitialIsSingles(setting));
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    if (!showConfirmation) {
      formRef.current?.focus();
    }
  }, [showConfirmation]);

  const handleGameChange = (game: string) => {
    setIsDuplicate(checkDuplicates(setting?.id, game, null, null));
    if (game) loadSets(game);
  };

  const handleSetChange = (game: string, setName: string) => {
    setIsDuplicate(checkDuplicates(setting?.id, game, setName, null));
  };

  const handleProductTypeChange = (productType: string) => {
    setIsDuplicate(checkDuplicates(setting?.id, null, null, productType));
  };

  const handleIsSinglesChange = (
    newIsSingles: boolean,
    setFieldValue: Function
  ) => {
    setIsSingles(newIsSingles);
    setFieldValue("game", "");
    setFieldValue("setName", "");
    setFieldValue("productType", "");
    if (newIsSingles) {
      setIsDuplicate(checkDuplicates(setting?.id, "", "", null));
    } else {
      setFieldValue("productType", productTypes[0]);
      setIsDuplicate(checkDuplicates(setting?.id, null, null, productTypes[0]));
    }
  };

  const validate = getValidator(integration);

  return (
    <div className="integration-setting-form">
      <Formik
        initialValues={decimalToPercent(setting) || initialValues}
        validate={validate}
        onSubmit={(values, { setSubmitting }) => {
          setShowConfirmation(false);
          saveSetting(percentToDecimal(values), setSubmitting);
        }}
      >
        {({ values, setFieldValue, isSubmitting, dirty, isValid, errors }) => (
          <Form id="integrationSettingForm">
            <SectionHeaderLayout
              title={setting?.id ? "Update Setting" : "Add Setting"}
            >
              <ButtonComponent
                primary
                type={integration === "ebay" ? "button" : "submit"}
                icon="fas fa-caret-right"
                iconPosition="right"
                disabled={
                  isSubmitting || isDuplicate || Object.keys(errors).length
                }
                onClick={() => {
                  if (integration === "ebay") setShowConfirmation(true);
                }}
              >
                {isExistingSetting ? "Update rule" : "Save rule"}
              </ButtonComponent>
            </SectionHeaderLayout>
            <div className="integration-setting-form__inputs">
              <h2 className="integration-setting-form__subheading">
                Basic Rules
              </h2>
              {!isExistingSetting &&
              !["cfbmarket", "tcgplayer"].includes(
                integration.toLowerCase()
              ) ? (
                <div className="integration-setting-form__criteria">
                  <div className="integration-setting-form__criterion">
                    <div className="integration-setting-form__apply-selector">
                      Filter by:
                      <label className="integration-setting-form__apply-option">
                        <input
                          className="integration-setting-form__apply-input"
                          type="radio"
                          name="appyTo"
                          checked={isSingles}
                          onChange={() =>
                            handleIsSinglesChange(true, setFieldValue)
                          }
                        />
                        <span className="integration-setting-form__apply-label">
                          BinderPOS Category
                        </span>
                      </label>
                      <label className="integration-setting-form__apply-option">
                        <input
                          className="integration-setting-form__apply-input"
                          type="radio"
                          name="appyTo"
                          checked={!isSingles}
                          onChange={() =>
                            handleIsSinglesChange(false, setFieldValue)
                          }
                        />
                        <span className="integration-setting-form__apply-label">
                          Product Type
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              ) : null}
              {isSingles ? (
                <>
                  <div className="integration-setting-form__criteria">
                    <div className="integration-setting-form__criterion">
                      <label htmlFor="game">Game</label>
                      {isExistingSetting ? (
                        <div className="integration-setting-form__unchangeable">
                          {
                            games.find((game) => game.gameId === values.game)
                              ?.gameName
                          }
                        </div>
                      ) : (
                        <Field
                          aria-label="game selector"
                          name="game"
                          as="select"
                          className="integration-setting-form__select"
                          onChange={(
                            event: React.ChangeEvent<HTMLSelectElement>
                          ) => {
                            const { value } = event.target;
                            setFieldValue("game", value);
                            handleGameChange(value);
                          }}
                        >
                          <option value={""} disabled>
                            Select game
                          </option>
                          {games
                            .filter((game) => Boolean(game.gameName))
                            .map((game, i) => {
                              return (
                                <option key={i} value={game.gameId}>
                                  {game.gameName}
                                </option>
                              );
                            })}
                        </Field>
                      )}
                    </div>
                    <div className="integration-setting-form__criterion">
                      <label className="label">Set</label>

                      {isExistingSetting ? (
                        <div className="integration-setting-form__unchangeable">
                          {values.setName}
                        </div>
                      ) : (
                        <Field
                          aria-label="set selector"
                          name="setName"
                          as="select"
                          className="integration-setting-form__select"
                          onChange={(
                            event: React.ChangeEvent<HTMLSelectElement>
                          ) => {
                            const { value } = event.target;
                            setFieldValue("setName", value);
                            handleSetChange(values.game, value);
                          }}
                          disabled={!values?.game}
                        >
                          {values?.game ? (
                            <option value="">All sets</option>
                          ) : (
                            <option value="">No game selected</option>
                          )}
                          {sets?.map((set, i) => {
                            return (
                              <option key={i} value={set}>
                                {set}
                              </option>
                            );
                          })}
                        </Field>
                      )}
                    </div>
                  </div>
                  <p className="integration-setting-form__update-warning">
                    {(!values.game || !values.setName) &&
                    ["cfbmarket", "tcgplayer"].includes(
                      integration.toLowerCase()
                    )
                      ? "Please note that listings containing large numbers of products will take a long time to appear. For best results, select a game and set combination."
                      : null}
                  </p>
                </>
              ) : (
                <div className="integration-setting-form__criteria">
                  <div className="integration-setting-form__criterion">
                    <label className="label">Product Type</label>
                    {isExistingSetting ? (
                      <div className="integration-setting-form__unchangeable">
                        {values.productType}
                      </div>
                    ) : (
                      <Field
                        aria-label="product type selector"
                        name="productType"
                        as="select"
                        className="integration-setting-form__select"
                        onChange={(
                          event: React.ChangeEvent<HTMLSelectElement>
                        ) => {
                          const { value } = event.target;
                          setFieldValue("productType", value);
                          handleProductTypeChange(value);
                        }}
                        disabled={values?.game}
                      >
                        {/* @ts-ignore */}
                        <option value={null}> -- None Selected --</option>
                        {productTypes?.map((productType, i) => {
                          return (
                            <option key={i} value={productType}>
                              {productType}
                            </option>
                          );
                        })}
                      </Field>
                    )}
                  </div>
                </div>
              )}
              {isDuplicate ? (
                <div className="integration-setting-form__error">
                  {getDuplicateDescription(
                    values.game,
                    values.setName,
                    values.productType
                  )}
                </div>
              ) : null}
              <div className="integration-setting-form__criterion">
                <Field
                  name="syncEnabled"
                  as={CheckboxComponent}
                  label="Enable Synchronization"
                  checked={values.syncEnabled}
                />
              </div>
              <div
                className={joinClasses([
                  "integration-setting-form__sync-settings",
                  !values.syncEnabled
                    ? "integration-setting-form__sync-settings--disabled"
                    : undefined,
                ])}
              >
                <div className="integration-setting-form__common-settings">
                  <div className="integration-setting-form__field-block">
                    <label htmlFor="priceMarkup">Price markup percentage</label>
                    <span>
                      <Field
                        name="priceMarkup"
                        className="integration-setting-form__input integration-setting-form__input--with-symbol"
                        type="number"
                        min={-100}
                        placeholder="E.g. 10%"
                      />
                      %
                    </span>
                  </div>
                  <div className="integration-setting-form__field-block">
                    <label htmlFor="reserveStock">Reserve Stock</label>
                    <Field
                      name="reserveStock"
                      className="integration-setting-form__input"
                      type="number"
                      min={0}
                      placeholder="E.g. 10"
                    />
                  </div>
                  <div className="integration-setting-form__field-block">
                    <label htmlFor="maxToList">
                      Maximum to list on {integrationSyncFriendlyName}
                    </label>
                    <Field
                      name="maxToList"
                      className="integration-setting-form__input"
                      type="number"
                      min={0}
                      placeholder="E.g. 10"
                    />
                  </div>

                  <div
                    className={`integration-setting-form__field-block ${
                      !["cfbmarket", "tcgplayer"].includes(
                        integration.toLowerCase()
                      )
                        ? "integration-setting-form__field-block--with-limit-text"
                        : ""
                    }`}
                  >
                    <label htmlFor="minPrice">Minimum Price</label>
                    <p className="integration-setting-form__help-text">
                      All prices under will not be listed
                    </p>
                    <span>
                      {currencySymbol}
                      <Field
                        name="minPrice"
                        className="integration-setting-form__input integration-setting-form__input--with-symbol"
                        type="number"
                        min={0}
                        step={0.01}
                        placeholder="E.g. $1.00"
                      />
                    </span>
                    {!["cfbmarket", "tcgplayer"].includes(
                      integration.toLowerCase()
                    ) ? (
                      <span className="integration-setting-form__limit-text">
                        Minimum price to list is 0.99
                      </span>
                    ) : null}
                  </div>
                  <div className="integration-setting-form__field-block">
                    <label htmlFor="maxPrice">Maximum Price</label>
                    <p className="integration-setting-form__help-text">
                      All prices over will not be listed
                    </p>
                    <span>
                      {currencySymbol}
                      <Field
                        name="maxPrice"
                        className="integration-setting-form__input integration-setting-form__input--with-symbol"
                        type="number"
                        min={0}
                        step={0.01}
                        placeholder="E.g. $1.00"
                      />
                    </span>
                  </div>
                  {values.maxToList === 0 ? (
                    <p className="integration-setting-form__update-warning">
                      <br />
                      Warning: by setting a maximum number of products to list
                      of 0, all products that fit this rule will not be
                      synchronized
                    </p>
                  ) : null}
                </div>
                <EbayListingForm
                  game={values.game}
                  integration={integration}
                  applyTax={values.applyTax}
                  vatPercentage={values.vatPercentage}
                  setFieldValue={setFieldValue}
                  listingType={getListingType(values.game)}
                />
              </div>
            </div>
            <div className="integration-setting-form__actions">
              <ButtonComponent
                primary
                type={integration === "ebay" ? "button" : "submit"}
                icon="fas fa-caret-right"
                iconPosition="right"
                disabled={
                  isSubmitting || isDuplicate || Object.keys(errors).length
                }
                onClick={(event) => {
                  formRef.current = event.currentTarget;
                  if (integration === "ebay") setShowConfirmation(true);
                }}
              >
                {isExistingSetting ? "Update rule" : "Save rule"}
              </ButtonComponent>
            </div>
            {maySubmit(dirty, isValid) && showConfirmation ? (
              <EbayListingConfirmationComponent
                setting={values}
                isExistingSetting={isExistingSetting}
                setShowConfirmation={setShowConfirmation}
              />
            ) : null}
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default IntegrationSettingForm;
