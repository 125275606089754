import React from "react";
import Spinner from "../generic/Spinner";
import { notify } from "../../helpers/notificationHelpers";
import { useFetchSetting, useUpdateSetting } from "../../hooks/settingHooks";
import CheckboxComponent from "../generic/CheckboxComponent";
import { INCLUDE_DATE_IN_EVENT_TITLE_TOGGLED } from "../../constants/eventsTracked";
import { segmentAnalytics } from "../services/Analytics";

const settingName = "includeDateInEventTitle";

function EventDateTitleSetting() {
  const { data, isLoading } = useFetchSetting(settingName);
  const { mutateAsync, isMutating } = useUpdateSetting();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    segmentAnalytics.track(INCLUDE_DATE_IN_EVENT_TITLE_TOGGLED, {
      include_event_date: event.target.checked,
    });
    mutateAsync({
      settingName,
      settingValue: event.target.checked,
    })
      .then(() => notify.info("Setting updated"))
      .catch((_err) => {
        notify.error("Failed to save setting. Please try again");
      });
  };

  return (
    <Spinner isLoading={isLoading}>
      <CheckboxComponent
        name={settingName}
        label="Include date in event title"
        disabled={isMutating}
        defaultChecked={data === "true"}
        onChange={onChange}
      />
    </Spinner>
  );
}

export default EventDateTitleSetting;
