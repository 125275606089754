import React from "react";

function PreviousImportsTooltip() {
  return (
    <aside>
      <h5>Previous Imports Table</h5>
      <p>
        This table shows the status of previous files uploaded to manage your
        store's product inventory.
      </p>
      <dl>
        <dt>Id</dt>
        <dd>
          This is a unique identifier assigned to the file you uploaded. Please
          mention this Id when reporting issues to the support team.
        </dd>

        <dt>Date uploaded</dt>
        <dd>
          The date and time the file was uploaded. This will be displayed in
          your local timezone.
        </dd>

        <dt>Status</dt>
        <dd>
          This will be one of: Queued (waiting to be processed), Processing,
          Completed or Failed.
        </dd>

        <dt>Type</dt>
        <dd>
          The type of file uploaded (for example: Binder). This is auto detected
          from the file headers.
        </dd>

        <dt>Total rows</dt>
        <dd>The number of detected products in your uploaded file.</dd>

        <dt>Failed rows</dt>
        <dd>The number of rows that failed to be processed.</dd>

        <dt>Last updated</dt>
        <dd>The last time the processing status of your file was updated.</dd>

        <dt>Original file</dt>
        <dd>A link to download the file that was originally uploaded.</dd>

        <dt>Status File</dt>
        <dd>
          A link to download a file containing information about the updates
          made to products after the upload file was processed.
        </dd>

        <dt>Report issue</dt>
        <dd>Get help if you encounter problems with your uploaded file.</dd>
      </dl>
    </aside>
  );
}

export default PreviousImportsTooltip;
