import { UseQueryOptions, QueryKey } from "react-query";
import {
  GetStoreCreditTransactionSummaryQuery,
  GetStoreCreditTransactionSummaryQueryVariables,
  useGetStoreCreditTransactionSummaryQuery,
} from "../../generated/reporting";
import { formatStoreCreditTransactionSummary } from "../../transformers/reports/formatStoreCreditTransactions";
import { StoreCreditTransactionsData } from "../../types/reports";

export const useFetchStoreCreditTransactions = (
  variables: GetStoreCreditTransactionSummaryQueryVariables,
  options: UseQueryOptions<
    GetStoreCreditTransactionSummaryQuery,
    unknown,
    StoreCreditTransactionsData[],
    QueryKey
  > = {}
) =>
  useGetStoreCreditTransactionSummaryQuery(variables, {
    select: (report) => formatStoreCreditTransactionSummary(report),
    keepPreviousData: true,
    staleTime: 60_000,
    ...options,
  });
