import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { useCreateTerminalSettings } from "../../hooks/paymentTerminalHooks";
import { paymentGatewayApiSettingsSchema } from "../../schemas/pointOfSaleSettings";
import Input from "../form/Input";
import ButtonComponent from "../generic/ButtonComponent";
import SubSectionHeaderLayout from "../layout/SubSectionHeaderLayout";
import { notify } from "../../helpers/notificationHelpers";
import { useShowError } from "../../hooks/errorHooks";
import "./PaymentGatewayApiSettings.scss";
import { PaymentProvider } from "../../types/paymentTerminal";

type FormValues = {
  apiKey: string;
  apiPin: string;
  apiSecret: string;
  merchantId: string;
  paymentProvider: string;
};

interface PaymentGatewayApiSettingsProps {
  gatewayApiConfigured: boolean;
  merchantId?: string;
  onApiConfigured: () => void;
  paymentProvider: PaymentProvider;
  tillId: number;
}

function PaymentGatewayApiSettings(props: PaymentGatewayApiSettingsProps) {
  const {
    gatewayApiConfigured,
    merchantId,
    onApiConfigured,
    paymentProvider,
    tillId,
  } = props;
  const [showApiSettings, setShowApiSettings] = useState(!gatewayApiConfigured);
  const showError = useShowError();
  const { mutateAsync } = useCreateTerminalSettings();

  const initialValues: FormValues = {
    apiKey: "",
    apiPin: "",
    apiSecret: "",
    merchantId: merchantId ?? "",
    paymentProvider,
  };

  const handleSubmit = (values: FormValues) =>
    mutateAsync({
      tillId,
      settings: {
        apiKey: values.apiKey,
        apiSecret: values.apiSecret,
        apiPin: String(values.apiPin) || null,
        merchantId: Number(values.merchantId),
        paymentProvider,
      },
    })
      .then(() => {
        notify.info("Settings saved");
        setShowApiSettings(false);
        onApiConfigured();
      })
      .catch((error) =>
        showError(error, "Failed to save API settings", "Please try again")
      );

  if (!showApiSettings) {
    return (
      <div className="PaymentGatewayApiSettings">
        <SubSectionHeaderLayout title="Payment Gateway Settings" />
        <p>
          Your Payment Gateway API details have already been entered for this
          till. If you would like to update them, please click the button below.
        </p>
        <ButtonComponent primary onClick={() => setShowApiSettings(true)}>
          Edit API Settings
        </ButtonComponent>
      </div>
    );
  }

  return (
    <div className="PaymentGatewayApiSettings">
      <SubSectionHeaderLayout title="Payment Gateway Settings" />
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={paymentGatewayApiSettingsSchema}
      >
        {({ isSubmitting }) => (
          <Form>
            <Field
              component={Input}
              name="apiKey"
              label="API Key"
              placeholder={
                gatewayApiConfigured ? "Existing key hidden for security" : null
              }
            />
            {paymentProvider === "USAePay" ? (
              <Field
                component={Input}
                name="apiPin"
                label="API Pin"
                placeholder={
                  gatewayApiConfigured
                    ? "Existing pin hidden for security"
                    : "1234"
                }
              />
            ) : null}
            {paymentProvider === "mxMerchant" ? (
              <Field
                component={Input}
                name="apiSecret"
                label="API Secret"
                placeholder={
                  gatewayApiConfigured
                    ? "Existing value hidden for security"
                    : null
                }
              />
            ) : null}
            {paymentProvider === "mxMerchant" ? (
              <Field
                component={Input}
                name="merchantId"
                label="Merchant Id"
                placeholder={
                  gatewayApiConfigured
                    ? "Existing value hidden for security"
                    : null
                }
              />
            ) : null}
            <ButtonComponent
              primary
              type="submit"
              halfWidth={gatewayApiConfigured}
              fullWidth={!gatewayApiConfigured}
              disabled={isSubmitting}
            >
              Save
            </ButtonComponent>
            {gatewayApiConfigured ? (
              <ButtonComponent
                halfWidth
                onClick={() => setShowApiSettings(false)}
              >
                Cancel
              </ButtonComponent>
            ) : null}
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default PaymentGatewayApiSettings;
