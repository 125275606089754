import { useStoreDetails } from "./storeHooks";

export const useOpenFreshworksTicket = () => {
  const { customerName, customerSiteUrl } = useStoreDetails();
  return (
    subject: string,
    ticketType: string,
    description: string,
    resource: any
  ) => {
    window.FreshworksWidget("prefill", "ticketForm", {
      subject,
      type: ticketType,
      description,
      custom_fields: {
        cf_affected_resource: resource,
        cf_store_name: customerName,
        cf_shopify_url: customerSiteUrl,
      },
    });
    window.FreshworksWidget("open", "ticketForm");
  };
};
