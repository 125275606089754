export const currencies = [
  {
    country: "Afghanistan",
    name: "Afghan afghani",
    isoCode: "AFN",
    symbol: "Af",
    nativeSymbol: "؋",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 0,
    groupSeparator: "comma",
    decimalSeparator: "none",
  },
  {
    country: "Albania",
    name: "Albanian lek",
    isoCode: "ALL",
    symbol: "ALL",
    nativeSymbol: "Lek",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 0,
    groupSeparator: "comma",
    decimalSeparator: "period",
  },
  {
    country: "Algeria",
    name: "Algerian dinar",
    isoCode: "DZD",
    symbol: "DA",
    nativeSymbol: "د.ج.‏",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 2,
    groupSeparator: "comma",
    decimalSeparator: "period",
  },
  {
    country: "Andorra",
    name: "Euro",
    isoCode: "EUR",
    symbol: "EUR",
    nativeSymbol: "€",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 2,
    groupSeparator: "period",
    decimalSeparator: "comma",
  },
  {
    country: "Angola",
    name: "Angolan kwanza",
    isoCode: "AOA",
    symbol: "AOA",
    nativeSymbol: "Kz",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 2,
    groupSeparator: "comma",
    decimalSeparator: "period",
  },
  {
    country: "Antigua and Barbuda",
    name: "East Caribbean dollar",
    isoCode: "XCD",
    symbol: "EC$",
    nativeSymbol: "$",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 2,
    groupSeparator: "comma",
    decimalSeparator: "period",
  },
  {
    country: "Argentina",
    name: "Argentine peso",
    isoCode: "ARS",
    symbol: "AR$",
    nativeSymbol: "$",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
    groupSeparator: "comma",
    decimalSeparator: "period",
  },
  {
    country: "Armenia",
    name: "Armenian dram",
    isoCode: "AMD",
    symbol: "AMD",
    nativeSymbol: "դր.",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 0,
    groupSeparator: "comma",
    decimalSeparator: "period",
  },
  {
    country: "Australia",
    name: "Australian dollar",
    isoCode: "AUD",
    symbol: "AU$",
    nativeSymbol: "$",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
    groupSeparator: "comma",
    decimalSeparator: "period",
  },
  {
    country: "Austria",
    name: "Euro",
    isoCode: "EUR",
    symbol: "EUR",
    nativeSymbol: "€",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 2,
    groupSeparator: "period",
    decimalSeparator: "comma",
  },
  {
    country: "Azerbaijan",
    name: "Azerbaijani manat",
    isoCode: "AZN",
    symbol: "man.",
    nativeSymbol: "ман.",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 2,
    groupSeparator: "comma",
    decimalSeparator: "period",
  },
  {
    country: "Bahamas",
    name: "Bahamian dollar",
    isoCode: "BSD",
    symbol: "B$",
    nativeSymbol: "$",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 2,
    groupSeparator: "comma",
    decimalSeparator: "period",
  },
  {
    country: "Bahrain",
    name: "Bahraini dinar",
    isoCode: "BHD",
    symbol: "BD",
    nativeSymbol: "د.ب.‏",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 3,
  },
  {
    country: "Bangladesh",
    name: "Bangladeshi taka",
    isoCode: "BDT",
    symbol: "Tk",
    nativeSymbol: "৳",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 0,
  },
  {
    country: "Barbados",
    name: "Barbadian dollar",
    isoCode: "BBD",
    symbol: "BBD",
    nativeSymbol: "$",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
    groupSeparator: "comma",
    decimalSeparator: "period",
  },
  {
    country: "Belarus",
    name: "Belarusian ruble",
    isoCode: "BYN",
    symbol: "Br",
    nativeSymbol: "руб.",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
    groupSeparator: "comma",
    decimalSeparator: "period",
  },
  {
    country: "Belgium",
    name: "Euro",
    isoCode: "EUR",
    symbol: "EUR",
    nativeSymbol: "€",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 2,
    groupSeparator: "space",
    decimalSeparator: "comma",
  },
  {
    country: "Belize",
    name: "Belize dollar",
    isoCode: "BZD",
    symbol: "BZ$",
    nativeSymbol: "$",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
    groupSeparator: "comma",
    decimalSeparator: "period",
  },
  {
    country: "Benin",
    name: "West African CFA franc",
    isoCode: "XOF",
    symbol: "CFA",
    nativeSymbol: "CFA",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 0,
    groupSeparator: "comma",
    decimalSeparator: "period",
  },
  {
    country: "Bhutan",
    name: "Bhutanese ngultrum",
    isoCode: "BTN",
    symbol: "Nu.",
    nativeSymbol: "Nu.",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
  },
  {
    country: "Bolivia",
    name: "Bolivian boliviano",
    isoCode: "BOB",
    symbol: "Bs",
    nativeSymbol: "Bs",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 2,
  },
  {
    country: "Bosnia and Herzegovina",
    name: "Bosnia and Herzegovina convertible mark",
    isoCode: "BAM",
    symbol: "KM",
    nativeSymbol: "KM",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 2,
  },
  {
    country: "Botswana",
    name: "Botswana pula",
    isoCode: "BWP",
    symbol: "BWP",
    nativeSymbol: "P",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 2,
  },
  {
    country: "Brazil",
    name: "Brazilian real",
    isoCode: "BRL",
    symbol: "R$",
    nativeSymbol: "R$",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
    groupSeparator: "comma",
  },
  {
    country: "Brunei",
    name: "Brunei dollar",
    isoCode: "BND",
    symbol: "BN$",
    nativeSymbol: "$",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
  },
  {
    country: "Bulgaria",
    name: "Bulgarian lev",
    isoCode: "BGN",
    symbol: "BGN",
    nativeSymbol: "лв.",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 2,
    groupSeparator: "comma",
  },
  {
    country: "Burkina Faso",
    name: "West African CFA franc",
    isoCode: "XOF",
    symbol: "CFA",
    nativeSymbol: "CFA",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 0,
  },
  {
    country: "Burundi",
    name: "Burundian franc",
    isoCode: "BIF",
    symbol: "FBu",
    nativeSymbol: "FBu",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 0,
  },
  {
    country: "Cambodia",
    name: "Cambodian riel",
    isoCode: "KHR",
    symbol: "KHR",
    nativeSymbol: "៛",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 2,
  },
  {
    country: "Cameroon",
    name: "Central African CFA franc",
    isoCode: "XAF",
    symbol: "FCFA",
    nativeSymbol: "FCFA",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 0,
  },
  {
    country: "Canada (English)",
    name: "Canadian dollar",
    isoCode: "CAD",
    symbol: "CA$",
    nativeSymbol: "$",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
  },
  {
    country: "Canada (French)",
    name: "Canadian dollar",
    isoCode: "CAD",
    symbol: "CA$",
    nativeSymbol: "$",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 2,
    groupSeparator: "comma",
  },
  {
    country: "Cape Verde",
    name: "Cape Verdean escudo",
    isoCode: "CVE",
    symbol: "CV$",
    nativeSymbol: "CV$",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
  },
  {
    country: "Central African Republic",
    name: "Central African CFA franc",
    isoCode: "XAF",
    symbol: "FCFA",
    nativeSymbol: "FCFA",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 0,
  },
  {
    country: "Chad",
    name: "Central African CFA franc",
    isoCode: "XAF",
    symbol: "FCFA",
    nativeSymbol: "FCFA",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 0,
  },
  {
    country: "Chile",
    name: "Chilean peso",
    isoCode: "CLP",
    symbol: "CL$",
    nativeSymbol: "$",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 0,
    groupSeparator: "comma",
  },
  {
    country: "China",
    name: "Chinese yuan",
    isoCode: "CNY",
    symbol: "CN¥",
    nativeSymbol: "CN¥",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
  },
  {
    country: "Colombia",
    name: "Colombian peso",
    isoCode: "COP",
    symbol: "CO$",
    nativeSymbol: "$",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 0,
  },
  {
    country: "Comoros",
    name: "Comorian franc",
    isoCode: "KMF",
    symbol: "CF",
    nativeSymbol: "FC",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 0,
  },
  {
    country: "Costa Rica",
    name: "Costa Rican colón",
    isoCode: "CRC",
    symbol: "₡",
    nativeSymbol: "₡",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 0,
  },
  {
    country: "Croatia",
    name: "Croatian kuna",
    isoCode: "HRK",
    symbol: "kn",
    nativeSymbol: "kn",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 2,
    groupSeparator: "comma",
  },
  {
    country: "Cuba",
    name: "Cuban peso",
    isoCode: "CUP",
    symbol: "$MN",
    nativeSymbol: "$",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 2,
  },
  {
    country: "Cyprus",
    name: "Euro",
    isoCode: "EUR",
    symbol: "EUR",
    nativeSymbol: "€",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
    groupSeparator: "comma",
    decimalSeparator: "period",
  },
  {
    country: "Czech Republic",
    name: "Czech koruna",
    isoCode: "CZK",
    symbol: "Kč",
    nativeSymbol: "Kč",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 2,
    groupSeparator: "comma",
  },
  {
    country: "Democratic Republic of Congo",
    name: "Congolese franc",
    isoCode: "CDF",
    symbol: "CDF",
    nativeSymbol: "FrCD",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 2,
  },
  {
    country: "Denmark",
    name: "Danish krone",
    isoCode: "DKK",
    symbol: "Dkr",
    nativeSymbol: "kr",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 2,
    groupSeparator: "comma",
  },
  {
    country: "Djibouti",
    name: "Djiboutian franc",
    isoCode: "DJF",
    symbol: "Fdj",
    nativeSymbol: "Fdj",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 0,
  },
  {
    country: "Dominica",
    name: "East Caribbean dollar",
    isoCode: "XCD",
    symbol: "EC$",
    nativeSymbol: "$",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 2,
    groupSeparator: "comma",
    decimalSeparator: "period",
  },
  {
    country: "Dominican Republic",
    name: "Dominican peso",
    isoCode: "DOP",
    symbol: "RD$",
    nativeSymbol: "RD$",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
  },
  {
    country: "East Timor",
    name: "United States dollar",
    isoCode: "USD",
    symbol: "$",
    nativeSymbol: "$",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
  },
  {
    country: "Ecuador",
    name: "United States dollar",
    isoCode: "USD",
    symbol: "$",
    nativeSymbol: "$",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
  },
  {
    country: "Egypt",
    name: "Egyptian pound",
    isoCode: "EGP",
    symbol: "EGP",
    nativeSymbol: "ج.م.‏",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 2,
  },
  {
    country: "El Salvador",
    name: "United States dollar",
    isoCode: "USD",
    symbol: "$",
    nativeSymbol: "$",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
  },
  {
    country: "Equatorial Guinea",
    name: "Central African CFA franc",
    isoCode: "XAF",
    symbol: "FCFA",
    nativeSymbol: "FCFA",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 0,
  },
  {
    country: "Eritrea",
    name: "Eritrean nakfa",
    isoCode: "ERN",
    symbol: "Nfk",
    nativeSymbol: "Nfk",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 2,
  },
  {
    country: "Estonia",
    name: "Euro",
    isoCode: "EUR",
    symbol: "EUR",
    nativeSymbol: "€",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 2,
    groupSeparator: "space",
    decimalSeparator: "comma",
  },
  {
    country: "Eswatini",
    name: "Swazi lilangeni",
    isoCode: "SZL",
    symbol: "E",
    nativeSymbol: "E",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
  },
  {
    country: "Ethiopia",
    name: "Ethiopian birr",
    isoCode: "ETB",
    symbol: "Br",
    nativeSymbol: "Br",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 2,
  },
  {
    country: "Fiji",
    name: "Fijian dollar",
    isoCode: "FJD",
    symbol: "FJ$",
    nativeSymbol: "$",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
  },
  {
    country: "Finland",
    name: "Euro",
    isoCode: "EUR",
    symbol: "EUR",
    nativeSymbol: "€",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 2,
    groupSeparator: "space",
    decimalSeparator: "comma",
  },
  {
    country: "France",
    name: "Euro",
    isoCode: "EUR",
    symbol: "EUR",
    nativeSymbol: "€",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 2,
    groupSeparator: "space",
    decimalSeparator: "comma",
  },
  {
    country: "Gabon",
    name: "Central African CFA franc",
    isoCode: "XAF",
    symbol: "FCFA",
    nativeSymbol: "FCFA",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 0,
  },
  {
    country: "Gambia",
    name: "Gambian dalasi",
    isoCode: "GMD",
    symbol: "D",
    nativeSymbol: "D",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 2,
  },
  {
    country: "Georgia",
    name: "Georgian lari",
    isoCode: "GEL",
    symbol: "GEL",
    nativeSymbol: "GEL",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 2,
  },
  {
    country: "Germany",
    name: "Euro",
    isoCode: "EUR",
    symbol: "EUR",
    nativeSymbol: "€",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 2,
    groupSeparator: "period",
    decimalSeparator: "comma",
  },
  {
    country: "Ghana",
    name: "Ghanaian cedi",
    isoCode: "GHS",
    symbol: "GH₵",
    nativeSymbol: "GH₵",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 2,
  },
  {
    country: "Greece",
    name: "Euro",
    isoCode: "EUR",
    symbol: "EUR",
    nativeSymbol: "€",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
    groupSeparator: "comma",
    decimalSeparator: "period",
  },
  {
    country: "Grenada",
    name: "East Caribbean dollar",
    isoCode: "XCD",
    symbol: "EC$",
    nativeSymbol: "$",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 2,
    groupSeparator: "comma",
    decimalSeparator: "period",
  },
  {
    country: "Guatemala",
    name: "Guatemalan quetzal",
    isoCode: "GTQ",
    symbol: "GTQ",
    nativeSymbol: "Q",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 2,
  },
  {
    country: "Guinea",
    name: "Guinean franc",
    isoCode: "GNF",
    symbol: "FG",
    nativeSymbol: "FG",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 0,
  },
  {
    country: "Guinea-Bissau",
    name: "West African CFA franc",
    isoCode: "XOF",
    symbol: "CFA",
    nativeSymbol: "FCFA",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 0,
  },
  {
    country: "Guyana",
    name: "Guyanese dollar",
    isoCode: "GYD",
    symbol: "GY$",
    nativeSymbol: "$",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
  },
  {
    country: "Haiti",
    name: "Haitian gourde",
    isoCode: "HTG",
    symbol: "G",
    nativeSymbol: "G",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 2,
  },
  {
    country: "Honduras",
    name: "Honduran lempira",
    isoCode: "HNL",
    symbol: "HNL",
    nativeSymbol: "L",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 2,
  },
  {
    country: "Hong Kong",
    name: "Honk Kong dollar",
    isoCode: "HKD",
    symbol: "HK$",
    nativeSymbol: "$",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
  },
  {
    country: "Hungary",
    name: "Hungarian forint",
    isoCode: "HUF",
    symbol: "Ft",
    nativeSymbol: "Ft",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 0,
  },
  {
    country: "Iceland",
    name: "Icelandic króna",
    isoCode: "ISK",
    symbol: "Ikr",
    nativeSymbol: "kr",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 0,
  },
  {
    country: "India",
    name: "Indian rupee",
    isoCode: "INR",
    symbol: "Rs",
    nativeSymbol: "টকা",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 2,
  },
  {
    country: "Indonesia",
    name: "Indonesian rupiah",
    isoCode: "IDR",
    symbol: "Rp",
    nativeSymbol: "Rp",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 0,
  },
  {
    country: "Iran",
    name: "Iranian rial",
    isoCode: "IRR",
    symbol: "IRR",
    nativeSymbol: "﷼",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 0,
  },
  {
    country: "Iraq",
    name: "Iraqi dinar",
    isoCode: "IQD",
    symbol: "IQD",
    nativeSymbol: "د.ع.‏",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 0,
  },
  {
    country: "Ireland",
    name: "Euro",
    isoCode: "EUR",
    symbol: "EUR",
    nativeSymbol: "€",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
    groupSeparator: "comma",
    decimalSeparator: "period",
  },
  {
    country: "Israel",
    name: "Israeli new shekel",
    isoCode: "ILS",
    symbol: "₪",
    nativeSymbol: "₪",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 2,
  },
  {
    country: "Italy",
    name: "Euro",
    isoCode: "EUR",
    symbol: "EUR",
    nativeSymbol: "€",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 2,
    groupSeparator: "period",
    decimalSeparator: "comma",
  },
  {
    country: "Ivory Coast",
    name: "West African CFA franc",
    isoCode: "XOF",
    symbol: "CFA",
    nativeSymbol: "FCFA",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 0,
  },
  {
    country: "Jamaica",
    name: "Jamaican dollar",
    isoCode: "JMD",
    symbol: "J$",
    nativeSymbol: "$",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
  },
  {
    country: "Japan",
    name: "Japanese yen",
    isoCode: "JPY",
    symbol: "¥",
    nativeSymbol: "￥",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 0,
  },
  {
    country: "Jordan",
    name: "Jordanian dinar",
    isoCode: "JOD",
    symbol: "JD",
    nativeSymbol: "د.أ.‏",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 3,
  },
  {
    country: "Kazakhstan",
    name: "Kazakhstani tenge",
    isoCode: "KZT",
    symbol: "KZT",
    nativeSymbol: "тңг.",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 2,
  },
  {
    country: "Kenya",
    name: "Kenyan shilling",
    isoCode: "KES",
    symbol: "Ksh",
    nativeSymbol: "Ksh",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 2,
  },
  {
    country: "Kiribati",
    name: "Australian dollar",
    isoCode: "AUD",
    symbol: "AU$",
    nativeSymbol: "$",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
    groupSeparator: "comma",
    decimalSeparator: "period",
  },
  {
    country: "Korea, South",
    name: "South Korean won",
    isoCode: "KRW",
    symbol: "₩",
    nativeSymbol: "₩",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 0,
  },
  {
    country: "Kosovo",
    name: "Euro",
    isoCode: "EUR",
    symbol: "EUR",
    nativeSymbol: "€",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
    groupSeparator: "comma",
    decimalSeparator: "period",
  },
  {
    country: "Kuwait",
    name: "Kuwaiti dinar",
    isoCode: "KWD",
    symbol: "KD",
    nativeSymbol: "د.ك.‏",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 3,
  },
  {
    country: "Kyrgyzstan",
    name: "Kyrgyzstani som",
    isoCode: "KGS",
    symbol: "SOM",
    nativeSymbol: "Лв",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 0,
  },
  {
    country: "Laos",
    name: "Lao kip",
    isoCode: "LAK",
    symbol: "KN",
    nativeSymbol: "₭",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 0,
  },
  {
    country: "Latvia",
    name: "Euro",
    isoCode: "EUR",
    symbol: "EUR",
    nativeSymbol: "€",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 2,
    groupSeparator: "space",
    decimalSeparator: "comma",
  },
  {
    country: "Lebanon",
    name: "Lebanese pound",
    isoCode: "LBP",
    symbol: "L.L.",
    nativeSymbol: "ل.ل.‏",
    symbolPosition: "right",
    symbolSpace: false,
    minorUnits: 0,
  },
  {
    country: "Lesotho",
    name: "Lesotho loti",
    isoCode: "LSL",
    symbol: "M",
    nativeSymbol: "M",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
  },
  {
    country: "Liberia",
    name: "Liberian dollar",
    isoCode: "LRD",
    symbol: "L$",
    nativeSymbol: "$",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
  },
  {
    country: "Libya",
    name: "Libyan dinar",
    isoCode: "LYD",
    symbol: "LD",
    nativeSymbol: "د.ل.‏",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 3,
  },
  {
    country: "Liechtenstein",
    name: "Swiss franc",
    isoCode: "CHF",
    symbol: "CHF",
    nativeSymbol: "CHF",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 2,
  },
  {
    country: "Lithuania",
    name: "Euro",
    isoCode: "EUR",
    symbol: "EUR",
    nativeSymbol: "€",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 2,
    groupSeparator: "space",
    decimalSeparator: "comma",
  },
  {
    country: "Luxembourg",
    name: "Euro",
    isoCode: "EUR",
    symbol: "EUR",
    nativeSymbol: "€",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 2,
    groupSeparator: "period",
    decimalSeparator: "comma",
  },
  {
    country: "Madagascar",
    name: "Malagasy ariary",
    isoCode: "MGA",
    symbol: "Ar",
    nativeSymbol: "Ar",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 0,
  },
  {
    country: "Malawi",
    name: "Malawian kwacha",
    isoCode: "MWK",
    symbol: "MWK",
    nativeSymbol: "K",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 0,
  },
  {
    country: "Malaysia",
    name: "Malaysian ringgit",
    isoCode: "MYR",
    symbol: "RM",
    nativeSymbol: "RM",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
  },
  {
    country: "Maldives",
    name: "Maldivian rufiyaa",
    isoCode: "MVR",
    symbol: "MRf",
    nativeSymbol: "Rf",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 2,
  },
  {
    country: "Mali",
    name: "West African CFA franc",
    isoCode: "XOF",
    symbol: "CFA",
    nativeSymbol: "FCFA",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 0,
  },
  {
    country: "Malta",
    name: "Euro",
    isoCode: "EUR",
    symbol: "EUR",
    nativeSymbol: "€",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
    groupSeparator: "comma",
    decimalSeparator: "period",
  },
  {
    country: "Marshall Islands",
    name: "United States dollar",
    isoCode: "USD",
    symbol: "$",
    nativeSymbol: "$",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
  },
  {
    country: "Mauritania",
    name: "Mauritanian ouguiya",
    isoCode: "MRO",
    symbol: "UM",
    nativeSymbol: "UM",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 0,
  },
  {
    country: "Mauritius",
    name: "Mauritian rupee",
    isoCode: "MUR",
    symbol: "MURs",
    nativeSymbol: "Rs",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 0,
  },
  {
    country: "Mexico",
    name: "Mexican peso",
    isoCode: "MXN",
    symbol: "MX$",
    nativeSymbol: "$",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
  },
  {
    country: "Micronesia",
    name: "United States dollar",
    isoCode: "USD",
    symbol: "$",
    nativeSymbol: "$",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
  },
  {
    country: "Moldova",
    name: "Moldovan leu",
    isoCode: "MDL",
    symbol: "MDL",
    nativeSymbol: "L",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 2,
  },
  {
    country: "Monaco",
    name: "Euro",
    isoCode: "EUR",
    symbol: "EUR",
    nativeSymbol: "€",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 2,
    groupSeparator: "space",
    decimalSeparator: "comma",
  },
  {
    country: "Mongolia",
    name: "Mongolian tögrög",
    isoCode: "MNT",
    symbol: "MNT",
    nativeSymbol: "₮",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 0,
  },
  {
    country: "Montenegro",
    name: "Euro",
    isoCode: "EUR",
    symbol: "EUR",
    nativeSymbol: "€",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 2,
    groupSeparator: "period",
    decimalSeparator: "comma",
  },
  {
    country: "Morocco",
    name: "Moroccan dirham",
    isoCode: "MAD",
    symbol: "MAD",
    nativeSymbol: "د.م.‏",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 2,
  },
  {
    country: "Mozambique",
    name: "Mozambican metical",
    isoCode: "MZN",
    symbol: "MTn",
    nativeSymbol: "MTn",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 2,
  },
  {
    country: "Myanmar",
    name: "Burmese kyat",
    isoCode: "MMK",
    symbol: "MMK",
    nativeSymbol: "K",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 0,
  },
  {
    country: "Namibia",
    name: "Namibian dollar",
    isoCode: "NAD",
    symbol: "N$",
    nativeSymbol: "N$",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
  },
  {
    country: "Nauru",
    name: "Australian dollar",
    isoCode: "AUD",
    symbol: "AU$",
    nativeSymbol: "$",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
    groupSeparator: "comma",
    decimalSeparator: "period",
  },
  {
    country: "Nepal",
    name: "Nepalese rupee",
    isoCode: "NPR",
    symbol: "NPRs",
    nativeSymbol: "नेरू",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 2,
  },
  {
    country: "Netherlands",
    name: "Euro",
    isoCode: "EUR",
    symbol: "EUR",
    nativeSymbol: "€",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 2,
    groupSeparator: "period",
    decimalSeparator: "comma",
  },
  {
    country: "New Zealand",
    name: "New Zealand dollar",
    isoCode: "NZD",
    symbol: "NZ$",
    nativeSymbol: "$",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
  },
  {
    country: "Nicaragua",
    name: "Nicaraguan córdoba",
    isoCode: "NIO",
    symbol: "C$",
    nativeSymbol: "C$",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
  },
  {
    country: "Niger",
    name: "West African CFA franc",
    isoCode: "XOF",
    symbol: "CFA",
    nativeSymbol: "FCFA",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 0,
  },
  {
    country: "Nigeria",
    name: "Nigerian naira",
    isoCode: "NGN",
    symbol: "₦",
    nativeSymbol: "₦",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 2,
    groupSeparator: "comma",
    decimalSeparator: "period",
  },
  {
    country: "North Macedonia",
    name: "Macedonian denar",
    isoCode: "MKD",
    symbol: "MKD",
    nativeSymbol: "ден",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 2,
  },
  {
    country: "Norway",
    name: "Norwegian krone",
    isoCode: "NOK",
    symbol: "Nkr",
    nativeSymbol: "kr",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 2,
  },
  {
    country: "Oman",
    name: "Omani rial",
    isoCode: "OMR",
    symbol: "OMR",
    nativeSymbol: "ر.ع.‏",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 3,
  },
  {
    country: "Pakistan",
    name: "Pakistani rupee",
    isoCode: "PKR",
    symbol: "PKRs",
    nativeSymbol: "₨",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 0,
  },
  {
    country: "Palau",
    name: "United States dollar",
    isoCode: "USD",
    symbol: "$",
    nativeSymbol: "$",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
  },
  {
    country: "Palestine",
    name: "Israeli new shekel",
    isoCode: "ILS",
    symbol: "₪",
    nativeSymbol: "₪",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
  },
  {
    country: "Panama",
    name: "Panamanian balboa",
    isoCode: "PAB",
    symbol: "B/",
    nativeSymbol: "B/",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
  },
  {
    country: "Papua New Guinea",
    name: "Papua New Guinean kina",
    isoCode: "PGK",
    symbol: "K",
    nativeSymbol: "Kr",
    symbolPosition: "right",
    symbolSpace: false,
    minorUnits: 2,
  },
  {
    country: "Paraguay",
    name: "Paraguayan guaraní",
    isoCode: "PYG",
    symbol: "₲",
    nativeSymbol: "₲",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 0,
  },
  {
    country: "Peru",
    name: "Peruvian sol",
    isoCode: "PEN",
    symbol: "S/",
    nativeSymbol: "S/",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
  },
  {
    country: "Philippines",
    name: "Philippine peso",
    isoCode: "PHP",
    symbol: "₱",
    nativeSymbol: "₱",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 2,
  },
  {
    country: "Poland",
    name: "Polish zloty",
    isoCode: "PLN",
    symbol: "zł",
    nativeSymbol: "zł",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 2,
  },
  {
    country: "Portugal",
    name: "Euro",
    isoCode: "EUR",
    symbol: "EUR",
    nativeSymbol: "€",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 2,
    groupSeparator: "space",
    decimalSeparator: "comma",
  },
  {
    country: "Qatar",
    name: "Qatari riyal",
    isoCode: "QAR",
    symbol: "QR",
    nativeSymbol: "ر.ق.‏",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
  },
  {
    country: "Republic of the Congo",
    name: "Central African CFA franc",
    isoCode: "XAF",
    symbol: "FCFA",
    nativeSymbol: "FCFA",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 0,
  },
  {
    country: "Romania",
    name: "Romanian leu",
    isoCode: "RON",
    symbol: "RON",
    nativeSymbol: "RON",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 2,
  },
  {
    country: "Russia",
    name: "Russian ruble",
    isoCode: "RUB",
    symbol: "RUB",
    nativeSymbol: "₽.",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 2,
  },
  {
    country: "Rwanda",
    name: "Rwandan franc",
    isoCode: "RWF",
    symbol: "RWF",
    nativeSymbol: "FRw",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 0,
  },
  {
    country: "Saint Kitts and Nevis",
    name: "East Caribbean dollar",
    isoCode: "XCD",
    symbol: "EC$",
    nativeSymbol: "$",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 2,
    groupSeparator: "comma",
    decimalSeparator: "period",
  },
  {
    country: "Saint Lucia",
    name: "East Caribbean dollar",
    isoCode: "XCD",
    symbol: "EC$",
    nativeSymbol: "$",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 2,
    groupSeparator: "comma",
    decimalSeparator: "period",
  },
  {
    country: "Saint Vincent and the Grenadines",
    name: "East Caribbean dollar",
    isoCode: "XCD",
    symbol: "EC$",
    nativeSymbol: "$",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 2,
    groupSeparator: "comma",
    decimalSeparator: "period",
  },
  {
    country: "Samoa",
    name: "Samoan tala",
    isoCode: "WST",
    symbol: "SAT",
    nativeSymbol: "$",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
  },
  {
    country: "San Marino",
    name: "Euro",
    isoCode: "EUR",
    symbol: "EUR",
    nativeSymbol: "€",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 2,
    groupSeparator: "period",
    decimalSeparator: "comma",
  },
  {
    country: "São Tomé and Príncipe",
    name: "São Tomé and Príncipe dobra",
    isoCode: "STD",
    symbol: "STN",
    nativeSymbol: "Db",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 2,
  },
  {
    country: "Saudi Arabia",
    name: "Saudi riyal",
    isoCode: "SAR",
    symbol: "SR",
    nativeSymbol: "ر.س.‏",
    symbolPosition: "right",
    symbolSpace: false,
    minorUnits: 2,
  },
  {
    country: "Senegal",
    name: "West African CFA franc",
    isoCode: "XOF",
    symbol: "CFA",
    nativeSymbol: "FCFA",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 0,
  },
  {
    country: "Serbia",
    name: "Serbian dinar",
    isoCode: "RSD",
    symbol: "din.",
    nativeSymbol: "дин.",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 0,
  },
  {
    country: "Seychelles",
    name: "Seychellois rupee",
    isoCode: "SCR",
    symbol: "Rs",
    nativeSymbol: "₨",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 2,
  },
  {
    country: "Sierra Leone",
    name: "Sierra Leonean leone",
    isoCode: "SLL",
    symbol: "Le",
    nativeSymbol: "Le",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 2,
  },
  {
    country: "Singapore",
    name: "Singapore dollar",
    isoCode: "SGD",
    symbol: "S$",
    nativeSymbol: "$",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
  },
  {
    country: "Slovakia",
    name: "Euro",
    isoCode: "EUR",
    symbol: "EUR",
    nativeSymbol: "€",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 2,
    groupSeparator: "space",
    decimalSeparator: "comma",
  },
  {
    country: "Slovenia",
    name: "Euro",
    isoCode: "EUR",
    symbol: "EUR",
    nativeSymbol: "€",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 2,
    groupSeparator: "period",
    decimalSeparator: "comma",
  },
  {
    country: "Solomon Islands",
    name: "Solomon Islands dollar",
    isoCode: "SBD",
    symbol: "Si$",
    nativeSymbol: "$",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
  },
  {
    country: "Somalia",
    name: "Somali shilling",
    isoCode: "SOS",
    symbol: "Ssh",
    nativeSymbol: "Ssh",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 0,
  },
  {
    country: "South Africa",
    name: "South African rand",
    isoCode: "ZAR",
    symbol: "R",
    nativeSymbol: "R",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
  },
  {
    country: "South Sudan",
    name: "South Sudanese pound",
    isoCode: "SSP",
    symbol: "SS£",
    nativeSymbol: "£",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
  },
  {
    country: "Spain",
    name: "Euro",
    isoCode: "EUR",
    symbol: "EUR",
    nativeSymbol: "€",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 2,
    groupSeparator: "period",
    decimalSeparator: "comma",
  },
  {
    country: "Sri Lanka",
    name: "Sri Lankan rupee",
    isoCode: "LKR",
    symbol: "SLRs",
    nativeSymbol: "SL Re",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 2,
  },
  {
    country: "Sudan",
    name: "Sudanese pound",
    isoCode: "SDG",
    symbol: "SDG",
    nativeSymbol: "£SD",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
  },
  {
    country: "Suriname",
    name: "Surinamese dollar",
    isoCode: "SRD",
    symbol: "$",
    nativeSymbol: "$",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
  },
  {
    country: "Sweden",
    name: "Swedish krona",
    isoCode: "SEK",
    symbol: "Skr",
    nativeSymbol: "kr",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 2,
  },
  {
    country: "Switzerland",
    name: "Swiss franc",
    isoCode: "CHF",
    symbol: "CHF",
    nativeSymbol: "CHF",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 2,
  },
  {
    country: "Syria",
    name: "Syrian pound",
    isoCode: "SYP",
    symbol: "SY£",
    nativeSymbol: "ل.س.‏",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 0,
  },
  {
    country: "Taiwan",
    name: "New Taiwan dollar",
    isoCode: "TWD",
    symbol: "NT$",
    nativeSymbol: "$",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
  },
  {
    country: "Tajikistan",
    name: "Tajikistani somoni",
    isoCode: "TJS",
    symbol: "SM",
    nativeSymbol: "SM",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 2,
  },
  {
    country: "Tanzania",
    name: "Tanzanian shilling",
    isoCode: "TZS",
    symbol: "TSh",
    nativeSymbol: "TSh",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 0,
  },
  {
    country: "Thailand",
    name: "Thai baht",
    isoCode: "THB",
    symbol: "฿",
    nativeSymbol: "฿",
    symbolPosition: "right",
    symbolSpace: false,
    minorUnits: 2,
  },
  {
    country: "Togo",
    name: "West African CFA franc",
    isoCode: "XOF",
    symbol: "CFA",
    nativeSymbol: "FCFA",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 0,
  },
  {
    country: "Tonga",
    name: "Tongan pa'anga",
    isoCode: "TOP",
    symbol: "T$",
    nativeSymbol: "T$",
    symbolPosition: "left",
    symbolSpace: true,
    minorUnits: 2,
  },
  {
    country: "Trinidad and Tobago",
    name: "Trinidad and Tobago dollar",
    isoCode: "TTD",
    symbol: "TT$",
    nativeSymbol: "$",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
  },
  {
    country: "Tunisia",
    name: "Tunisian dinar",
    isoCode: "TND",
    symbol: "DT",
    nativeSymbol: "د.ت.‏",
    symbolPosition: "right",
    symbolSpace: false,
    minorUnits: 3,
  },
  {
    country: "Turkey",
    name: "Turkish lira",
    isoCode: "TRY",
    symbol: "TL",
    nativeSymbol: "TL",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 2,
  },
  {
    country: "Turkmenistan",
    name: "Turkmenistan manat",
    isoCode: "TMT",
    symbol: "T",
    nativeSymbol: "T",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 2,
  },
  {
    country: "Tuvalu",
    name: "Australian dollar",
    isoCode: "AUD",
    symbol: "AU$",
    nativeSymbol: "$",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
    groupSeparator: "comma",
    decimalSeparator: "period",
  },
  {
    country: "Uganda",
    name: "Ugandan shilling",
    isoCode: "UGX",
    symbol: "USh",
    nativeSymbol: "USh",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 0,
  },
  {
    country: "Ukraine",
    name: "Ukrainian hryvnia",
    isoCode: "UAH",
    symbol: "₴",
    nativeSymbol: "₴",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 2,
  },
  {
    country: "United Arab Emirates",
    name: "United Arab Emirates dirham",
    isoCode: "AED",
    symbol: "AED",
    nativeSymbol: "د.إ.‏",
    symbolPosition: "right",
    symbolSpace: false,
    minorUnits: 2,
  },
  {
    country: "United Kingdom",
    name: "British pound",
    isoCode: "GBP",
    symbol: "£",
    nativeSymbol: "£",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
  },
  {
    country: "United States",
    name: "United States dollar",
    isoCode: "USD",
    symbol: "$",
    nativeSymbol: "$",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
  },
  {
    country: "Uruguay",
    name: "Uruguayan peso",
    isoCode: "UYU",
    symbol: "$U",
    nativeSymbol: "$",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
  },
  {
    country: "Uzbekistan",
    name: "Uzbekistani som",
    isoCode: "UZS",
    symbol: "UZS",
    nativeSymbol: "so'm",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 0,
  },
  {
    country: "Vanuatu",
    name: "Vanuatu vatu",
    isoCode: "VUV",
    symbol: "VT",
    nativeSymbol: "VT",
    symbolPosition: "right",
    symbolSpace: false,
    minorUnits: 0,
  },
  {
    country: "Venezuela",
    name: "Venezuelan bolívar",
    isoCode: "VEF",
    symbol: "Bs.F.",
    nativeSymbol: "Bs.F.",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 2,
  },
  {
    country: "Vietnam",
    name: "Vietnamese dong",
    isoCode: "VND",
    symbol: "₫",
    nativeSymbol: "₫",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 0,
  },
  {
    country: "Yemen",
    name: "Yemeni rial",
    isoCode: "YER",
    symbol: "YR",
    nativeSymbol: "ر.ي.‏",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 0,
  },
  {
    country: "Zambia",
    name: "Zambian kwacha",
    isoCode: "ZMW",
    symbol: "ZK",
    nativeSymbol: "ZK",
    symbolPosition: "right",
    symbolSpace: true,
    minorUnits: 2,
  },
  {
    country: "Zimbabwe",
    name: "United States dollar",
    isoCode: "USD",
    symbol: "$",
    nativeSymbol: "$",
    symbolPosition: "left",
    symbolSpace: false,
    minorUnits: 2,
  },
];
