import React from "react";
import { Column } from "../../types/table";

const renderHeader = (value: string | number | Function) => {
  if (typeof value === "function") {
    return value();
  }
  return value;
};

interface TableHeaderProps {
  columns: Column[];
}

function TableHeader(props: TableHeaderProps) {
  const { columns } = props;

  return (
    <thead>
      <tr>
        {columns.map((column) => (
          <th
            key={`${column.accessorKey}_${column.header}`}
            className={column.headerClassName}
          >
            {renderHeader(column.header)}
          </th>
        ))}
      </tr>
    </thead>
  );
}

export default TableHeader;
