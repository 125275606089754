import React from "react";
import { useReturnCartMutation } from "../../generated/graphql";
import { useShowError } from "../../hooks/errorHooks";
import { mapCart } from "../../transformers/cart";
import { ReturnCartPaymentData } from "../../types/pos";
import ButtonComponent from "../generic/ButtonComponent";
import IconButton from "../generic/IconButton";

interface ReturnCartButtonProps {
  cartId: BigInt;
  small?: boolean;
  onComplete: (response: any, paymentData: ReturnCartPaymentData[]) => void;
}

function ReturnCartButton(props: ReturnCartButtonProps) {
  const { cartId, onComplete, small = false } = props;
  const { mutateAsync, isLoading } = useReturnCartMutation();
  const showError = useShowError();

  const createReturn = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    mutateAsync({ returnCartId: Number(cartId) })
      .then((data) => {
        if (data.ReturnCart) {
          onComplete(
            mapCart(data.ReturnCart),
            data.ReturnCart.Cart?.CartIntegratedPayment
          );
        }
      })
      .catch((error) => {
        showError(
          error,
          "Unable to return cart",
          "We were unable to return the items from this cart. Please try again"
        );
      });
  };

  if (small) {
    return (
      <IconButton
        small
        title="Undo"
        onClick={createReturn}
        icon="fas fa-undo-alt"
      />
    );
  }

  return (
    <ButtonComponent primary disabled={isLoading} onClick={createReturn}>
      Return Order
    </ButtonComponent>
  );
}

export default ReturnCartButton;
