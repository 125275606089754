/**
 * Reformats buylist details so that cards of different grouped together under
 * the same parent card.
 *
 * @param {object} buylistDetails
 *
 * @returns {object}
 */

const generateKey = (variant) => {
  return [
    variant.cardName,
    variant.cardId,
    variant.setName,
    variant.type,
    variant.variantId,
  ].join("---");
};

export function groupBuylistVariants(buylistDetails) {
  const newBuylistDetails = { ...buylistDetails };
  const { completed, finalBuylistDetails, shopifyCustomerBuylistDetails } =
    newBuylistDetails;
  const cardsHash = {};
  let buylistItems;

  if (completed && finalBuylistDetails && finalBuylistDetails.length) {
    buylistItems = finalBuylistDetails;
  } else {
    buylistItems = shopifyCustomerBuylistDetails;
  }

  buylistItems.forEach((item) => {
    const {
      id,
      cardName,
      cardId,
      setName,
      rarity,
      type,
      quantity,
      foil,
      gameId,
      game,
      imageUrl,
      shopifyCustomerBuylistId,
      variantName,
      variantId,
      productVariantId,
      cashBuyPrice,
      storeCreditBuyPrice,
      storeCreditSellPrice,
    } = item;

    if (!("isOverStock" in item)) {
      const itemKey = generateKey(item);
      const matchingVariants = buylistItems.filter((variant) => {
        const variantKey = generateKey(variant);
        return variantKey === itemKey;
      });

      if (matchingVariants.length === 2) {
        if (
          matchingVariants[0].cashBuyPrice < matchingVariants[1].cashBuyPrice
        ) {
          matchingVariants[0].isOverStock = true;
          matchingVariants[1].isOverStock = false;
        } else {
          matchingVariants[1].isOverStock = true;
          matchingVariants[0].isOverStock = false;
        }
      } else {
        matchingVariants[0].isOverStock = false;
      }
    }

    const variant = {
      cashBuyPrice,
      id,
      quantity,
      storeCreditBuyPrice,
      storeCreditSellPrice,
      variantId,
      variantName,
      isOverStock: item.isOverStock,
    };

    const key = [cardName, cardId, setName, type, item.isOverStock].join("---");

    if (!Object.keys(cardsHash).includes(key)) {
      const _card = {
        cardId,
        cardName,
        foil,
        gameId,
        game,
        imageUrl,
        setName,
        rarity,
        productVariantId,
        shopifyCustomerBuylistId,
        type,
        isOverStock: item.isOverStock,
        variants: {
          [variant.variantId]: variant,
        },
      };

      cardsHash[key] = _card;
    } else {
      cardsHash[key].variants[variant.variantId] = variant;
    }
  });

  newBuylistDetails.cards = Object.values(cardsHash);
  return newBuylistDetails;
}

/**
 * Reformats buylist details so that each variant has its own entry, as
 * expected by the API
 *
 * @param {object} buylistDetails
 *
 * @returns {object}
 */
export function splitBuylistVariants(buylistDetails = {}) {
  const shopifyCustomerBuylistDetails = [];
  buylistDetails.cards.forEach((card) => {
    Object.values(card.variants).forEach((variant) => {
      // destructuring used to remove variants
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { variants, ...cardDetails } = card;

      const _variant = {
        ...cardDetails,
        ...variant,
      };

      delete _variant.isOverStock;

      if (variant.quantity > 0) {
        shopifyCustomerBuylistDetails.push(_variant);
      }
    });
  });
  const response = {
    ...buylistDetails,
    shopifyCustomerBuylistDetails,
  };
  delete response.cards;
  return response;
}

/**
 * Adds up the total buy price of all the selected variants of a card
 *
 * @param {object} variants
 * @param {string} paymentType
 *
 * @returns {float}
 */
export function getTotalBuyPrice(variants, paymentType) {
  const isPaidInCash = paymentType === "Cash";
  return Object.values(variants).reduce(
    (sum, variant) =>
      sum +
      parseFloat(variant.quantity) *
        parseFloat(
          isPaidInCash ? variant.cashBuyPrice : variant.storeCreditBuyPrice
        ),
    0
  );
}

/**
 * Adds up the total sell price of all the selected variants of a card
 *
 * @param {object} variants
 *
 * @returns {float}
 */
export function getTotalSellPrice(variants) {
  return Object.values(variants).reduce(
    (sum, variant) =>
      sum +
      (variant.quantity > 0
        ? parseFloat(variant.quantity) * parseFloat(variant.storeSellPrice)
        : 0),
    0
  );
}

/**
 * Adds up the total quantities of all the selected variants of a card
 *
 * @param {object} variants
 *
 * @returns {number}
 */
export function getTotalQuantity(variants) {
  return Object.values(variants).reduce(
    (sum, variant) => sum + parseInt(variant.quantity, 10),
    0
  );
}

/**
 * Returns the appropriate compare function to be used in the sorting of
 * buylist cards
 *
 * @param {string} field
 * @param {boolean} ascending
 * @param {string} paymentType
 *
 * @returns {function}
 */
export function getSortFunction(field, ascending, paymentType) {
  const cmp1 = ascending ? -1 : 1;
  const cmp2 = ascending ? 1 : -1;
  switch (field) {
    case "game":
      return (a, b) => {
        const gameA = a.gameId ? a.gameId : a.game;
        const gameB = b.gameId ? b.gameId : b.game;
        if (gameA < gameB) return cmp1;
        if (gameA > gameB) return cmp2;
        return 0;
      };
    case "set":
      return (a, b) => {
        if (a.setName < b.setName) return cmp1;
        if (a.setName > b.setName) return cmp2;
        return 0;
      };
    case "buy":
      return (a, b) => {
        const priceA = getTotalBuyPrice(a.variants, paymentType);
        const priceB = getTotalBuyPrice(b.variants, paymentType);
        if (priceA < priceB) return cmp1;
        if (priceA > priceB) return cmp2;
        return 0;
      };
    case "sell":
      return (a, b) => {
        const priceA = getTotalSellPrice(a.variants);
        const priceB = getTotalSellPrice(b.variants);
        if (priceA < priceB) return cmp1;
        if (priceA > priceB) return cmp2;
        return 0;
      };
    case "qty":
      return (a, b) => {
        const qtyA = getTotalQuantity(a.variants);
        const qtyB = getTotalQuantity(b.variants);
        if (qtyA < qtyB) return cmp1;
        if (qtyA > qtyB) return cmp2;
        return 0;
      };
    case "name":
    default:
      return (a, b) => {
        if (a.cardName < b.cardName) return cmp1;
        if (a.cardName > b.cardName) return cmp2;
        return 0;
      };
  }
}

/**
 * Splits a list of cards into multiple arrays, each of "size" cards
 *
 * @param {Array} list
 * @param {number} size
 *
 * @returns {Array}
 */
export const chunkCards = (list, size) =>
  list.reduce(
    (r, v) =>
      (!r.length || r[r.length - 1].length === size
        ? r.push([v])
        : r[r.length - 1].push(v)) && r,
    []
  );
