import { UseQueryOptions, QueryKey } from "react-query";
import {
  GetStockInventoryValueQuery,
  useGetStockInventoryValueQuery,
} from "../../generated/reporting";
import { formatStockInventoryValue } from "../../transformers/reports/formatStockInventoryValue";
import { StockValueData } from "../../types/reports";

export const useFetchStockValueReport = (
  options: UseQueryOptions<
    GetStockInventoryValueQuery,
    unknown,
    StockValueData[],
    QueryKey
  > = {}
) =>
  useGetStockInventoryValueQuery(
    {},
    {
      select: (report) => formatStockInventoryValue(report),
      keepPreviousData: true,
      staleTime: 60_000,
      ...options,
    }
  );
