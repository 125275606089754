import React from "react";
import "./EbayErrorComponent.scss";

interface EbayErrorComponentProps {
  errors: string[];
}

function EbayErrorComponent(props: EbayErrorComponentProps) {
  const { errors } = props;

  if (!errors || errors.length < 1) {
    return null;
  }

  return (
    <div className="ebay-error">
      <div className="ebay-error__title">
        The following {errors.length > 1 ? "errors" : "error"} occurred:
      </div>
      <ul className="ebay-error__list">
        {errors.map((error: string, index: number) => (
          <li key={`error_${index}`} className="ebay-error__message">
            {error}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default EbayErrorComponent;
