import EventMenuStructure from "./EventMenuStructure";
import CustomerMenuStructure from "./CustomerMenuStructure";
import ProductMenuStructure from "./ProductMenuStructure";
import BuylistMenuStructure from "./BuylistMenuStructure";
import POSMenuStructure from "./POSMenuStructure";
import ReportsMenuStructure from "./ReportsMenuStructure";
import LegacyIntegrationsMenuStructure, {
  IntegrationsMenuStructure,
} from "./IntegrationsMenuStructure";
import LegacySettingsMenuStructure, {
  SettingsMenuStructure,
} from "./SettingsMenuStructure";
import { POS_SHOPIFY_THEMES } from "../constants/featureFlags";
import { RootMenuItem } from "../types/nav";

export const mainMenuStructure: RootMenuItem[] = [
  {
    screenSetting: null,
    target: "/dashboard",
    title: "Dashboard",
    iconClass: "fas fa-home",
    menuStructure: null,
  },
  {
    screenSetting: "events",
    target: "/events",
    title: "Events",
    iconClass: "fas fa-calendar-alt",
    menuStructure: EventMenuStructure,
    subMenu: EventMenuStructure,
  },
  {
    screenSetting: "customers",
    target: "/customers",
    title: "Customers",
    iconClass: "fas fa-users",
    menuStructure: CustomerMenuStructure,
    subMenu: CustomerMenuStructure,
  },
  {
    screenSetting: "inventoryManagement",
    target: "/products",
    title: "Products",
    iconClass: "fas fa-inventory",
    menuStructure: ProductMenuStructure,
    subMenu: ProductMenuStructure,
  },
  {
    screenSetting: "buylist",
    target: "/buylists",
    title: "Online Buylist",
    iconClass: "fas fa-shopping-cart",
    menuStructure: BuylistMenuStructure,
    subMenu: BuylistMenuStructure,
  },
  {
    screenSetting: "pos",
    target: "/pointOfSale",
    title: "POS Settings",
    iconClass: "fas fa-store-alt",
    menuStructure: POSMenuStructure,
    subMenu: POSMenuStructure,
  },
  {
    // TODO:: Replace this with a proper permissions check for reports
    screenSetting: undefined,
    target: "/reports",
    title: "Reports",
    iconClass: "fas fa-chart-bar",
    menuStructure: ReportsMenuStructure,
    subMenu: ReportsMenuStructure,
  },
  {
    screenSetting: "integrations",
    target: "/integrations",
    title: "Integrations",
    iconClass: "fas fa-vector-square",
    menuStructure: LegacyIntegrationsMenuStructure([]),
    subMenu: IntegrationsMenuStructure,
    appendDynamicItems: "integrations",
  },
  {
    screenSetting: null,
    target: "/themes",
    title: "Themes",
    iconClass: "fas fa-palette",
    menuStructure: null,
    featureFlag: POS_SHOPIFY_THEMES,
  },
  // TODO:: Hide this class on mobile devices
  {
    screenSetting: "pos",
    target: "/pos",
    title: "Point of Sale",
    iconClass: "fas fa-store-alt",
    menuStructure: null,
    classNameOverride: "DesktopMenu__link DesktopMenu__link--pos",
  },
  {
    screenSetting: null,
    target: "/settings",
    title: "Settings",
    iconClass: "fas fa-cogs",
    menuStructure: LegacySettingsMenuStructure,
    subMenu: SettingsMenuStructure,
    classNameOverride: "DesktopMenu__link DesktopMenu__link--settings",
  },
];

export const legacyMainMenuStructure = mainMenuStructure.filter(
  (menuStructure) => !Object.keys(menuStructure).includes("featureFlag")
);

export const optionalMenuItems: Record<string, RootMenuItem> = {
  shopifyThemes: {
    screenSetting: null,
    target: "/themes",
    title: "Themes",
    iconClass: "fas fa-palette",
    menuStructure: null,
  },
};
