import React, { useEffect, useState } from "react";
import { PaymentProvider } from "../../types/paymentTerminal";
import { TillSetting } from "../../types/settings";
import { getTerminalId } from "../../helpers/paymentTerminalHelpers";
import { findTillSettingValue } from "../../helpers/settingHelpers";
import { useFetchDevices } from "../../hooks/paymentTerminalHooks";
import ButtonComponent from "../generic/ButtonComponent";
import Paging from "../generic/Paging";
import Spinner from "../generic/Spinner";
import SubSectionHeaderLayout from "../layout/SubSectionHeaderLayout";
import PaymentGatewayRegisterDevice from "./PaymentGatewayRegisterDevice";
import PaymentGatewayDevice from "./PaymentGatewayDevice";
import "./PaymentGatewayDeviceList.scss";
import { useShowError } from "../../hooks/errorHooks";

const DEFAULT_DEVICES_PER_PAGE = 20;
const MAX_DEVICES_PER_PAGE = 999999;

interface PaymentGatewayDeviceListProps {
  paymentProvider: PaymentProvider;
  tillId: number;
  tillSettings: TillSetting[];
}

function PaymentGatewayDeviceList(props: PaymentGatewayDeviceListProps) {
  const { paymentProvider, tillId, tillSettings } = props;
  const devicesPerPage =
    paymentProvider === "USAePay"
      ? DEFAULT_DEVICES_PER_PAGE
      : MAX_DEVICES_PER_PAGE;
  const [currentPage, setCurrentPage] = useState(0);
  const [showRegisterDeviceModal, setShowRegisterDeviceModal] = useState(false);
  const showError = useShowError();
  const currentDevice = findTillSettingValue(
    tillSettings,
    "paymentTerminalDeviceKey"
  );
  const { data, isLoading, error, refetch } = useFetchDevices(
    tillId,
    currentPage * devicesPerPage,
    devicesPerPage
  );

  const handleAddDevice = () => setShowRegisterDeviceModal(true);

  const onDeviceAdded = () => {
    setShowRegisterDeviceModal(false);
    refetch();
  };

  useEffect(() => {
    if (error) {
      showError(
        error as DetailedError,
        "Failed to load gateway devices",
        "Please refresh the page and try again."
      );
    }
  }, [error]);

  return (
    <div className="PaymentGatewayDeviceList">
      <SubSectionHeaderLayout title="Payment Gateway Devices">
        <ButtonComponent primary onClick={() => refetch()} icon="fas fa-sync">
          Refresh
        </ButtonComponent>
      </SubSectionHeaderLayout>
      <Spinner isLoading={isLoading}>
        <div className="PaymentGatewayDeviceList__deviceList">
          {(data?.data || []).map((terminal) => (
            <PaymentGatewayDevice
              key={getTerminalId(terminal)}
              tillId={tillId}
              currentDevice={currentDevice}
              details={terminal}
              paymentProvider={paymentProvider}
            />
          ))}
          {currentPage === 0 && data?.data && data.data.length === 0 ? (
            <p>You currently have no devices configured</p>
          ) : null}
          {currentPage > 0 && data?.data && data.data.length === 0 ? (
            <p>No more devices to show</p>
          ) : null}

          <ButtonComponent
            primary
            onClick={handleAddDevice}
            icon="fas fa-plus-circle"
            className="PaymentGatewayDeviceList__addButton"
          >
            Add new device
          </ButtonComponent>
        </div>
      </Spinner>
      {data?.data &&
      (currentPage > 0 || data.data.length === devicesPerPage) ? (
        <Paging
          pageOffset={currentPage}
          currentPageItemCount={data.data.length}
          maxItemsPerPage={devicesPerPage}
          getPrevPage={() => setCurrentPage((page) => Math.max(page - 1, 0))}
          getNextPage={() => setCurrentPage((page) => page + 1)}
        />
      ) : null}

      {showRegisterDeviceModal ? (
        <PaymentGatewayRegisterDevice
          tillId={tillId}
          closeModal={onDeviceAdded}
          paymentProvider={paymentProvider}
        />
      ) : null}
    </div>
  );
}

export default PaymentGatewayDeviceList;
