import React from "react";
import { getCurrentUser } from "../../helpers/authHelpers";
import "./UserAvatar.scss";
import DefaultImage from "../../../assets/img/default.png";

function UserAvatar() {
  const currentUser = getCurrentUser();
  const imageUrl = currentUser?.photoURL ?? DefaultImage;
  const name = currentUser?.displayName;

  return (
    <div className="UserAvatar">
      {name ? (
        <div
          className="UserAvatar__greeting"
          data-bindercustomeremail={currentUser?.email}
        >
          <span className="UserAvatar__salutation">Hi </span>
          {name}
        </div>
      ) : null}
      <div className="UserAvatar__image">
        <img src={imageUrl} alt={`${name ? name : "User"}'s Avatar`} />
      </div>
    </div>
  );
}

export default UserAvatar;
