import React, { useState } from "react";
import { useDeleteCartMutation } from "../../generated/graphql";
import { useShowError } from "../../hooks/errorHooks";
import ButtonComponent from "../generic/ButtonComponent";
import Modal from "../generic/Modal";

interface DeleteCartModalProps {
  cartId: number;
  onCancel: () => void;
  onComplete?: () => void;
}

function DeleteCartModal(props: DeleteCartModalProps) {
  const { cartId, onCancel, onComplete } = props;
  const { mutateAsync } = useDeleteCartMutation();
  const showError = useShowError();
  const [disabled, setDisabled] = useState(false);

  const handleDelete = async (
    deleteCartByPkId: number,
    removeStock: boolean
  ) => {
    try {
      await mutateAsync(
        {
          deleteCartByPkId,
          removeStock,
        },
        {
          onSuccess: onComplete,
        }
      );
    } catch (error) {
      showError(error);
    } finally {
      setDisabled(false);
    }
  };
  return (
    <Modal onClose={onCancel}>
      <Modal.Header>Cart Deletion</Modal.Header>
      <Modal.Content>
        Please choose how you want to delete cart #{cartId}
      </Modal.Content>
      <Modal.Actions>
        <ButtonComponent
          primary
          disabled={disabled}
          onClick={async () => {
            setDisabled(true);
            await handleDelete(cartId, false);
          }}
        >
          Delete
        </ButtonComponent>
        <ButtonComponent
          secondary
          disabled={disabled}
          onClick={async () => {
            setDisabled(true);
            await handleDelete(cartId, true);
          }}
        >
          Delete and Return Stock
        </ButtonComponent>
        <ButtonComponent onClick={onCancel}>Cancel</ButtonComponent>
      </Modal.Actions>
    </Modal>
  );
}

export default DeleteCartModal;
