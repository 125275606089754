import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { customerSchema } from "../../schemas/customer";
import CustomerMenuStructure from "../../menuStructures/CustomerMenuStructure";
import {
  fetchCustomerById,
  addCustomer,
  updateCustomer,
} from "../../api/rest/customers";
import SetActiveMenu from "../../menuStructures/SetActiveMenu";
import Input from "../../components/form/Input";
import TextArea from "../../components/form/TextArea";
import Loader from "../../components/generic/Loader";
import ButtonComponent from "../../components/generic/ButtonComponent";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";
import CustomerConfirmationModal from "../../components/customers/CustomerConfirmationModal";
import AdjustStoreCreditModal from "../../components/customers/AdjustStoreCreditModal";
import { useShowError } from "../../hooks/errorHooks";
import { useFormatCurrency } from "../../hooks/storeHooks";
import { getCustomerTitle } from "../../helpers/customerHelpers";
import { replaceNullWithDefault } from "../../helpers/formHelpers";
import "./Customer.scss";
import {
  SAVE_AND_ADD_NEW_CUSTOMER_CLICKED,
  SAVE_CUSTOMER_CLICKED,
} from "../../constants/eventsTracked";

interface CustomerForm {
  firstName: string;
  lastName?: string;
  email?: string;
  phone?: string;
  notes?: string;
}

const initialValues: CustomerForm = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  notes: "",
};

const errors = {
  update: {
    title: "Failed to update customer",
    description:
      "There was an error submitting the customer details. Please try again",
  },
  add: {
    title: "Failed to add customer",
    description:
      "There was an error submitting the customer details. Please try again",
  },
};

interface CustomerProps {
  isPos?: boolean;
  setShowAddCustomer?: (value: boolean) => void;
  setSelectedCustomerForPOS?: (customer: Customer) => void;
}

interface RouteParams {
  customerId: string;
}

function Customer(props: CustomerProps) {
  const { isPos, setShowAddCustomer, setSelectedCustomerForPOS } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [confirmationModalVisible, setConfirmationModalVisible] =
    useState(false);
  const [adjustStoreCreditModalVisible, setAdjustStoreCreditModalVisible] =
    useState(false);
  const [customer, setCustomer] = useState<Customer>();
  const { customerId } = useParams<RouteParams>();
  const formatCurrency = useFormatCurrency();
  const showError = useShowError();

  useEffect(() => {
    if (customerId) {
      document.title = "Update Customer | BinderPOS";
      loadCustomer(customerId);
    } else {
      document.title = "Add Customer | BinderPOS";
    }
  }, [customerId]);

  const closeParentModal = () => {
    setShowAddCustomer(false);
  };

  const loadCustomer = (id: string) => {
    setIsLoading(true);
    fetchCustomerById(id)
      .then((result: Customer) => {
        setCustomer(result);
      })
      .catch((error: DetailedError) => {
        showError(
          error,
          "Unable to load user",
          "There was an error loading the customer details. Please refresh and try again"
        );
      })
      .finally(() => setIsLoading(false));
  };

  const onAdjustStoreCredit = () => {
    if (customerId) {
      loadCustomer(customerId);
    }
    setAdjustStoreCreditModalVisible(false);
  };

  const saveCustomer = (
    values: CustomerForm,
    setSubmitting: ISetSubmitting
  ) => {
    setSubmitting(true);
    const action = customerId ? "update" : "add";
    const apiRequest = customerId ? updateCustomer : addCustomer;
    apiRequest(values)
      .then((_customer: Customer) => {
        setConfirmationModalVisible(true);
        if (isPos) {
          setSelectedCustomerForPOS(_customer);
        }
      })
      .catch((error: DetailedError) => {
        const errorMsg = errors[action];
        setConfirmationModalVisible(false);
        showError(error, errorMsg.title, errorMsg.description);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const isUpdate = !!customer;
  const customerTitle = getCustomerTitle(customer);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <SetActiveMenu menuStructure={CustomerMenuStructure} />
      <Formik
        initialValues={replaceNullWithDefault(customer, initialValues)}
        validationSchema={customerSchema}
        onSubmit={(values, { setSubmitting }) =>
          saveCustomer(values, setSubmitting)
        }
      >
        {({ isSubmitting }) => (
          <Form>
            {!isPos ? (
              <SectionHeaderLayout
                title={
                  isUpdate ? `Update ${customerTitle}` : "Add new customer"
                }
              >
                <ButtonComponent
                  primary
                  disabled={isSubmitting}
                  type="submit"
                  segmentEventName={SAVE_CUSTOMER_CLICKED}
                >
                  {isUpdate ? "Update" : "Save"}
                </ButtonComponent>
              </SectionHeaderLayout>
            ) : null}
            <div className="Customer" data-testid="customer">
              <div className="container-fluid">
                <div className="row">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <Field
                          component={Input}
                          name="firstName"
                          type="text"
                          label="First name"
                        />
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <Field
                          component={Input}
                          name="lastName"
                          type="text"
                          label="Last name"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <Field
                      component={Input}
                      name="email"
                      placeholder="E.g. example@binderpos.com"
                      type="text"
                      label="Email address"
                    />
                  </div>
                  <div className="col-lg-12">
                    <Field
                      component={Input}
                      name="phone"
                      placeholder="E.g. +1 123 456 7890"
                      type="tel"
                      label="Phone number"
                    />
                  </div>
                </div>
                {isUpdate ? (
                  <div className="row">
                    <div className="col-lg-12">
                      <label className="label" htmlFor="storeCredit">
                        Store Credit
                      </label>
                      <div className="row">
                        <div className="col-lg-3">
                          Current value: {formatCurrency(customer.storeCredit)}
                        </div>
                        <div className="col-lg-3">
                          <ButtonComponent
                            primary
                            onClick={() =>
                              setAdjustStoreCreditModalVisible(true)
                            }
                          >
                            Adjust
                          </ButtonComponent>
                        </div>
                      </div>
                      <br />
                    </div>
                  </div>
                ) : null}
                <Field
                  component={TextArea}
                  name="notes"
                  placeholder="Customer notes"
                  label="Customer notes"
                />
                <ButtonComponent
                  primary
                  fullWidth
                  disabled={isSubmitting}
                  type="submit"
                  segmentEventName={SAVE_AND_ADD_NEW_CUSTOMER_CLICKED}
                >
                  {isUpdate
                    ? "Save and update customer"
                    : "Save and add new customer"}
                </ButtonComponent>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      {confirmationModalVisible ? (
        <CustomerConfirmationModal
          isUpdate={isUpdate}
          customerTitle={customerTitle}
          isPos={isPos}
          handleClose={closeParentModal}
        />
      ) : null}
      {adjustStoreCreditModalVisible ? (
        <AdjustStoreCreditModal
          customer={customer}
          onComplete={() => onAdjustStoreCredit()}
          handleClose={() => setAdjustStoreCreditModalVisible(false)}
        />
      ) : null}
    </>
  );
}

export default Customer;
