import React from "react";
import { remapTenderName, getTenderIcon } from "../../utils/tenders";
import "./TenderSelect.scss";
import Modal from "../generic/Modal";

interface TenderSelectProps {
  tenders: string[];
  storeCreditAvailable: boolean;
  onTenderSelect;
  onClose: () => void;
}

function TenderSelect(props: TenderSelectProps) {
  const { tenders, storeCreditAvailable, onTenderSelect, onClose } = props;

  return (
    <Modal onClose={onClose} small>
      <Modal.Header>Select Payment Method</Modal.Header>
      <Modal.Content>
        <div className="tenderSelect">
          {tenders?.map((tender) => (
            <button
              className="tenderSelect__tender"
              key={tender}
              disabled={tender === "Store Credit" && !storeCreditAvailable}
              onClick={() => onTenderSelect(tender)}
            >
              <span className="tenderSelect__label">
                <i className={getTenderIcon(tender)} />
                {remapTenderName(tender)}
              </span>
            </button>
          ))}
        </div>
      </Modal.Content>
      <Modal.Actions> </Modal.Actions>
    </Modal>
  );
}

export default TenderSelect;
