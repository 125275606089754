import React, { useState } from "react";
import ConfirmationModal from "../generic/ConfirmationModal";
import "./KioskCartSubmitModal.scss";

interface KioskCartSubmitModalProps {
  confirmAction: (cartName: string) => void;
  cancelAction: () => void;
}

function KioskCartSubmitModal(props: KioskCartSubmitModalProps) {
  const { confirmAction, cancelAction } = props;
  const [cartName, setCartName] = useState<string>("");

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setCartName(event.target.value);

  return (
    <ConfirmationModal
      cancelAction={cancelAction}
      confirmAction={() => confirmAction(cartName)}
      title="Ready to Submit?"
    >
      <div className="KioskCartSubmitModal">
        <label htmlFor="cartName" className="label">
          Please enter your first name so our staff can easily find your cart.
        </label>
        <input
          id="cartName"
          name="cartName"
          className="input"
          value={cartName}
          onChange={handleNameChange}
        />
      </div>
    </ConfirmationModal>
  );
}

export default KioskCartSubmitModal;
