import React, { MutableRefObject, useState } from "react";
import { Formik, Form, Field } from "formik";
import { bulkUpdateSchema } from "../../schemas/product";
import { capitalizeFirstLetter } from "../../utils/formatting";
import { bulkUpdateFields } from "./bulkUpdateModalFields";
import { useShowError } from "../../hooks/errorHooks";
import { bulkUpdate } from "../../api/rest/products";
import BulkUpdateConfirmationModal from "./BulkUpdateConfirmationModal";
import Modal from "../generic/Modal";
import ButtonComponent from "../generic/ButtonComponent";
import Input from "../form/Input";
import { PUSH_BULK_UPDATE_CLICKED } from "../../constants/eventsTracked";
import "./BulkUpdateModal.scss";

interface BulkUpdateModalProps {
  search: Record<string, string | string[] | null>;
  productCount: number;
  submitType?: string;
  variants?: unknown[];
  cancelAction: () => void;
  getStockUpdateType: () => string;
}

function BulkUpdateModal(
  props: BulkUpdateModalProps,
  ref: MutableRefObject<any>
) {
  const { search, productCount, cancelAction, getStockUpdateType } = props;

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string>();
  const [selectedFields, setSelectedFields] = useState<string[]>([]);
  const [removeValues, setRemoveValues] = useState<string[]>([]);
  const [formData, setFormData] = useState<Record<string, string | number>>({});
  const showError = useShowError();

  const initialValues = {
    stockUpdateType: getStockUpdateType(),
    priceOverride: "",
    stock: "",
    cashBuyPrice: "",
    cashBuyPercentage: "",
    creditBuyPrice: "",
    creditBuyPercentage: "",
    buyLimit: "",
    maxInstockBuyPrice: "",
    maxInstockBuyPercentage: "",
    maxInstockCreditBuyPrice: "",
    maxInstockCreditBuyPercentage: "",
  };

  const toggleField = (field: string) =>
    selectedFields.includes(field)
      ? setSelectedFields(
          selectedFields.filter((fieldName) => fieldName !== field)
        )
      : setSelectedFields([...selectedFields, field]);

  const toggleRemoveValue = (field: string) =>
    removeValues.includes(field)
      ? setRemoveValues(removeValues.filter((fieldName) => fieldName !== field))
      : setRemoveValues([...removeValues, field]);

  const pushBulkUpdate = () => {
    const fieldsToUpdate = {} as Record<string, unknown>;
    selectedFields.forEach((field) => {
      const updateKey = `update${capitalizeFirstLetter(field)}`;
      fieldsToUpdate[updateKey] = true;
      if (removeValues.includes(field)) {
        const removeKey = `${field}Remove`;
        fieldsToUpdate[removeKey] = true;
      } else {
        fieldsToUpdate[field] = formData[field];
      }
      if (field === "stock") {
        fieldsToUpdate.stockUpdateType = formData.stockUpdateType;
      }
    });
    const bulkInventoryUpdate = {
      fieldsToUpdate: fieldsToUpdate,
      searchToUpdate: search,
    };
    bulkUpdate(bulkInventoryUpdate)
      .then((result) => {
        setSuccessMessage(result.message);
      })
      .catch((err) => {
        showError(
          err,
          "Failed to push bulk update",
          "There was an error submitting your bulk update. Please try again"
        );
        cancelAction();
      });
  };

  if (successMessage) {
    return (
      <Modal
        small
        onClose={() => {
          setSuccessMessage(undefined);
          cancelAction();
        }}
        ref={ref}
      >
        <Modal.Header>Bulk Update Queued</Modal.Header>
        <Modal.Content>{successMessage}</Modal.Content>
      </Modal>
    );
  }

  if (showConfirmationModal) {
    return (
      <BulkUpdateConfirmationModal
        formData={formData}
        selectedFields={selectedFields}
        removeValues={removeValues}
        productCount={productCount}
        cancelAction={cancelAction}
        pushBulkUpdate={pushBulkUpdate}
        ref={ref}
      />
    );
  }
  return (
    <Modal onClose={cancelAction} ref={ref}>
      <Modal.Header>Bulk update {productCount} records</Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={initialValues}
          validationSchema={bulkUpdateSchema}
          onSubmit={(values) => {
            setFormData(values);
            setShowConfirmationModal(true);
          }}
        >
          <Form id="BulkUpdateModal" className="BulkUpdateModal">
            <p>
              Select the fields below that you would like to update and apply to
              the {productCount} products found in this search
            </p>
            <label className="checkbox" htmlFor="priceOverrideCheckbox">
              <input
                id="priceOverrideCheckbox"
                type="checkbox"
                onChange={() => toggleField("priceOverride")}
              />
              <span className="checkmark"></span>
              &nbsp; Price override
            </label>
            {selectedFields.includes("priceOverride") ? (
              <div className="BulkUpdateModal__subSettings">
                <Field
                  component={Input}
                  name="priceOverride"
                  label="Set price override to"
                  type="number"
                  min={0.01}
                  placeholder="E.g. 29.95"
                  step={0.01}
                />
              </div>
            ) : null}
            <label className="checkbox" htmlFor="stockUpdateCheckbox">
              <input
                id="stockUpdateCheckbox"
                type="checkbox"
                onChange={() => toggleField("stock")}
              />
              <span className="checkmark"></span>
              &nbsp; Stock quantity
            </label>
            {selectedFields.includes("stock") ? (
              <div className="BulkUpdateModal__subSettings input-group input-group--inline">
                <div className="input-group-prepend">
                  <Field as="select" className="input" name="stockUpdateType">
                    <option value="set">Set to</option>
                    <option value="add">Add</option>
                    <option value="remove">Remove</option>
                  </Field>
                </div>
                <Field
                  component={Input}
                  data-testid="stockInput"
                  name="stock"
                  type="number"
                  min={0}
                  placeholder="E.g. 10"
                  step={1}
                />
              </div>
            ) : null}
            {bulkUpdateFields.map((field) => (
              <React.Fragment key={field.fieldName}>
                <label
                  className="checkbox"
                  htmlFor={`${field.fieldName}Checkbox`}
                >
                  <input
                    id={`${field.fieldName}Checkbox`}
                    type="checkbox"
                    onChange={() => toggleField(field.fieldName)}
                  />
                  <span className="checkmark"></span>
                  &nbsp; {field.optionLabel}
                </label>
                {selectedFields.includes(field.fieldName) ? (
                  <div className="BulkUpdateModal__subSettings">
                    <label
                      className="checkbox"
                      htmlFor={`${field.fieldName}RemoveCheckbox`}
                    >
                      <input
                        id={`${field.fieldName}RemoveCheckbox`}
                        type="checkbox"
                        onChange={() => toggleRemoveValue(field.fieldName)}
                        checked={removeValues.includes(field.fieldName)}
                      />
                      <span className="checkmark"></span>
                      &nbsp; {field.removeLabel}
                    </label>
                    {!removeValues.includes(field.fieldName) ? (
                      <>
                        <Field
                          component={Input}
                          name={field.fieldName}
                          label={field.inputLabel}
                          type="number"
                          min={field.min}
                          placeholder={field.placeholder}
                          step={field.step}
                        />
                        {field.helpText ? <p>{field.helpText}</p> : null}
                      </>
                    ) : null}
                  </div>
                ) : null}
              </React.Fragment>
            ))}
          </Form>
        </Formik>
      </Modal.Content>
      <Modal.Actions>
        <ButtonComponent
          primary
          type="submit"
          form="BulkUpdateModal"
          icon="fas fa-caret-right"
          iconPosition="right"
          segmentEventName={PUSH_BULK_UPDATE_CLICKED}
        >
          Push bulk update
        </ButtonComponent>
      </Modal.Actions>
    </Modal>
  );
}

export default React.forwardRef(BulkUpdateModal);
