import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import QuickItem from "./QuickItem";
import { action } from "mobx";
import { isKioskMode } from "../../helpers/posModeHelpers";
import { segmentAnalytics } from "../services/Analytics";
import { ADD_FOLDER_CLICKED } from "../../constants/eventsTracked";
import { ItemList } from "../../pos/ItemStore";
import "./QuickLinks.scss";

interface QuickLinksProps {
  posStore?: ItemList;
  menu?: any[];
}

@inject("posStore")
@observer
class QuickLinks extends Component<QuickLinksProps> {
  constructor(props: QuickLinksProps) {
    super(props);
    this.state = {};
  }
  @action
  navUp = () => {
    this.props.posStore.quickLinkNav.pop();
  };

  @action
  addQuickItem = () => {
    const view = this.props.posStore.currentNav;
    view.push({
      color: "grey",
      icon: "fas fa- folder",
      type: "folder",
      value: {
        title: "New Folder",
        items: [],
      },
    });
    this.props.posStore.updateQuickLinks();
  };

  render() {
    return (
      <React.Fragment>
        {this.props.posStore.quickLinkNav.length ? (
          <div className="QuickLinks__upNav" onClick={this.navUp}>
            {this.props.posStore.currentNavName}
          </div>
        ) : null}
        <div className="QuickLinks">
          {this.props.posStore.currentNav.length
            ? this.props.posStore.currentNav.map((item, index) => (
                <QuickItem menu={item} key={index} navIndex={index} />
              ))
            : null}
          {!isKioskMode() ? (
            <button
              className={
                "QuickLinks__quickItem addItem" +
                (this.props.posStore.cartLoading ? " disabled" : "")
              }
              onClick={() => {
                segmentAnalytics.track(ADD_FOLDER_CLICKED);
                this.addQuickItem();
              }}
            >
              <div className="dot">
                <i className={"fas fa-plus fa-3x"}></i>
              </div>
              <div className="itemDesc">
                <h2>Add Folder</h2>
              </div>
            </button>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

export default QuickLinks;
