import React, { useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import { fetchAuthUrl } from "../../../api/rest/ebay";
import Loader from "../../../components/generic/Loader";
import { useStoreDetails } from "../../../hooks/storeHooks";

const redirectToAuthUrl = () => {
  fetchAuthUrl()
    .then((data) => {
      if (data.url) {
        window.location.replace(data.url);
      } else {
        throw new Error("No URL received");
      }
    })
    .catch((err) => {
      console.log(err);
      // TODO:: What do now?
    });
};

interface RouteParams {
  integration: string;
}

function AuthoriseContainer() {
  const { customerId } = useStoreDetails();
  const { integration } = useParams<RouteParams>();

  useEffect(() => {
    if (integration === "ebay" && customerId) {
      redirectToAuthUrl();
    }
  }, [customerId]);

  if (integration === "cfbmarket") {
    return <Redirect to="/integrations/cfbmarket/configure" />;
  }
  return <Loader />;
}

export default AuthoriseContainer;
