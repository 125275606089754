import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import Modal from "../generic/Modal";
import { ItemList } from "../../pos/ItemStore";

interface TaxErrorModalProps {
  posStore?: ItemList;
}

@inject("posStore")
@observer
class TaxErrorModal extends Component<TaxErrorModalProps> {
  clearError = () => {
    this.props.posStore.setShowTaxErrorModal(false);
  };

  render() {
    return (
      <Modal small onClose={this.clearError}>
        <Modal.Header>Missing Tax Settings</Modal.Header>
        <Modal.Content>
          <p>
            No tax rate has been entered for your store. Until a tax rate is
            set, the checkout functionality will be disabled.
          </p>
          <p>
            Your tax rate can be changed on the{" "}
            <Link to="/pointOfSale/settings/tax">
              Point of Sales &gt; Tax Settings
            </Link>{" "}
            page.
          </p>
        </Modal.Content>
      </Modal>
    );
  }
}

export default TaxErrorModal;
