import React from "react";
import { segmentAnalytics, SegmentEventProps } from "../services/Analytics";
import { joinClasses } from "../../utils/styling";
import "./NavToggleButton.scss";

interface NavToggleButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  iconDefault: string;
  iconAlternative: string;
  labelDefault: string;
  labelAlternative: string;
  useAlternative: boolean;
  className?: string;
}

type NavToggleButtonComponentProps = NavToggleButtonProps & SegmentEventProps;

function NavToggleButton(props: NavToggleButtonComponentProps) {
  const {
    iconDefault,
    iconAlternative,
    labelDefault,
    labelAlternative,
    useAlternative,
    segmentEventName,
    segmentEventProperties,
    className = "",
    onClick,
    ...rest
  } = props;

  const clickHandler = (
    event: React.MouseEvent<HTMLButtonElement & HTMLAnchorElement>
  ) => {
    if (segmentEventName) {
      segmentAnalytics.track(segmentEventName, segmentEventProperties);
    }
    onClick?.(event);
  };

  const label = useAlternative ? labelAlternative : labelDefault;
  const icon = useAlternative ? iconAlternative : iconDefault;

  return (
    <button
      className={joinClasses(["NavToggleButton", className])}
      type="button"
      onClick={clickHandler}
      {...rest}
    >
      <i className={icon}></i> {label}
    </button>
  );
}

export default NavToggleButton;
