import { action, observable, runInAction } from "mobx";
import { fetchAvailableIntegrations } from "../api/rest/integrations";

class IntegrationSyncStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable availableIntegrations = [];

  @action setAvailableIntegrations = (integrations) => {
    this.availableIntegrations = integrations;
  };

  @observable integrationSyncName;

  @action setIntegrationSyncName = (syncName) => {
    this.integrationSyncName = syncName;
  };

  @observable integrationSyncFriendlyName;

  @action setIntegrationSyncFriendlyName = (friendlyName) => {
    this.integrationSyncFriendlyName = friendlyName;
  };

  loadAvailableIntegrations = () =>
    fetchAvailableIntegrations()
      .then((result) => {
        // TODO:: Remove
        const filteredResult = result.filter(
          (integration) => integration.syncName !== "cfbmarket"
        );
        runInAction(() => {
          this.setAvailableIntegrations(filteredResult);
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.rootStore.MainStore.setError(
            error,
            "Failed to integrations",
            "There was an error retrieving the list of available integrations. Please refresh and try again"
          );
        });
      });

  setCurrentIntegration = (syncName) => {
    this.availableIntegrations.forEach((integration) => {
      if (integration.syncName === syncName) {
        const { syncFriendlyName } = integration;
        this.setIntegrationSyncName(syncName);
        this.setIntegrationSyncFriendlyName(syncFriendlyName);
      }
    });
  };
}

export default IntegrationSyncStore;
