import React from "react";
import { GraphQLError as GraphQLErrorType } from "../../types/error";

interface GraphQLErrorProps {
  errorDetails: {
    message: string;
    data: GraphQLErrorType[];
  };
}

function GraphQLError(props: GraphQLErrorProps) {
  const { errorDetails } = props;

  return (
    <>
      {errorDetails.data.map((error, index) => (
        <div className="field" key={index}>
          {errorDetails.data.length > 1 ? error.extensions.title : null}
          <p>{error.extensions.detail}</p>
        </div>
      ))}
    </>
  );
}

export default GraphQLError;
