import React from "react";
import LinkComponent from "../../../components/generic/LinkComponent";
import "./SettingsMenu.scss";
import { segmentAnalytics } from "../../../components/services/Analytics";
import {
  MANAGE_EBAY_FULFILLMENT_POLICIES_CLICKED,
  MANAGE_EBAY_LISTING_TEMPLATES_CLICKED,
  MANAGE_EBAY_LOCATION_POLICIES_CLICKED,
  MANAGE_EBAY_PAYMENT_POLICIES_CLICKED,
  MANAGE_EBAY_RETURNS_POLICIES_CLICKED,
  MISC_EBAY_SETTINGS_CLICKED,
} from "../../../constants/eventsTracked";

function SettingsMenu() {
  return (
    <div className="ebay-settings">
      <div className="ebay-settings__navigation">
        <div className="ebay-settings__navigation-item">
          <h2 className="ebay-settings__navigation-title">Location</h2>
          <p className="ebay-settings__navigation-description">
            Record the address for your store and or warehouse, as well as
            contact details and website address.
          </p>
          <LinkComponent
            to="/integrations/ebay/policies/location"
            onClick={() =>
              segmentAnalytics.track(MANAGE_EBAY_LOCATION_POLICIES_CLICKED)
            }
          >
            Manage Location Policies &gt;
          </LinkComponent>
        </div>
        <div className="ebay-settings__navigation-item">
          <h2 className="ebay-settings__navigation-title">Payment</h2>
          <p className="ebay-settings__navigation-description">
            Define your payment policies, including accepted credit cards and
            Paypal email address.
          </p>
          <LinkComponent
            to="/integrations/ebay/policies/payment"
            onClick={() =>
              segmentAnalytics.track(MANAGE_EBAY_PAYMENT_POLICIES_CLICKED)
            }
          >
            Manage Payment Policies &gt;
          </LinkComponent>
        </div>
        <div className="ebay-settings__navigation-item">
          <h2 className="ebay-settings__navigation-title">Returns</h2>
          <p className="ebay-settings__navigation-description">
            Set what kind of returns your return periods for both demoestic and
            international buyers.
          </p>
          <LinkComponent
            to="/integrations/ebay/policies/returns"
            onClick={() =>
              segmentAnalytics.track(MANAGE_EBAY_RETURNS_POLICIES_CLICKED)
            }
          >
            Manage Returns Policies &gt;
          </LinkComponent>
        </div>
        <div className="ebay-settings__navigation-item">
          <h2 className="ebay-settings__navigation-title">Fulfilment</h2>
          <p className="ebay-settings__navigation-description">
            Provide different shipping options for buyers, including shipping
            services, international shopping and handling times.
          </p>
          <LinkComponent
            to="/integrations/ebay/policies/fulfillment"
            onClick={() =>
              segmentAnalytics.track(MANAGE_EBAY_FULFILLMENT_POLICIES_CLICKED)
            }
          >
            Manage Fulfilment Policies &gt;
          </LinkComponent>
        </div>
        <div className="ebay-settings__navigation-item">
          <h2 className="ebay-settings__navigation-title">Listing Templates</h2>
          <p className="ebay-settings__navigation-description">
            Create templates that can be used to quickly populate the listings
            fields for each product.
          </p>
          <LinkComponent
            to="/integrations/ebay/policies/templates"
            onClick={() =>
              segmentAnalytics.track(MANAGE_EBAY_LISTING_TEMPLATES_CLICKED)
            }
          >
            Manage Templates &gt;
          </LinkComponent>
        </div>
        <div className="ebay-settings__navigation-item">
          <h2 className="ebay-settings__navigation-title">Misc Settings</h2>
          <p className="ebay-settings__navigation-description">
            All other settings used to control how your listings are published
            on Ebay.
          </p>
          <LinkComponent
            to="/integrations/ebay/policies/misc"
            onClick={() => segmentAnalytics.track(MISC_EBAY_SETTINGS_CLICKED)}
          >
            Manage Misc Settings &gt;
          </LinkComponent>
        </div>
      </div>
    </div>
  );
}

export default SettingsMenu;
