import { FieldProps } from "formik";
import React from "react";
import Select, { OptionsType } from "react-select";
import { Option } from "react-select/src/filters";
import "../../../css/react-select-overwrites.scss";

interface OptionWithDisable extends Option {
  disabled?: boolean;
}

interface CustomSelectProps extends FieldProps {
  options: OptionsType<Option>;
  isMulti?: boolean;
  defaultValue: Option;
  placeholder?: string;
  onChangeValue?: (value: string | string[]) => void;
}

function SelectComponent({
  field,
  form,
  options,
  defaultValue,
  placeholder,
  onChangeValue,
  isMulti = false,
  ...props
}: CustomSelectProps) {
  const onChange = (option: Option | Option[]) => {
    const value = isMulti
      ? ((option as Option[]) || []).map((item: Option) => item.value)
      : (option as Option).value;
    form.setFieldValue(field.name, value);
    if (onChangeValue) onChangeValue(value);
  };

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter(
            (option: Option) => field.value.indexOf(option.value) >= 0
          )
        : options.find((option: Option) => option.value === field.value);
    } else {
      return isMulti ? [] : ("" as unknown);
    }
  };

  return (
    // @ts-ignore
    <Select
      classNamePrefix="react-select"
      name={field.name}
      value={getValue()}
      onChange={onChange}
      options={options}
      isMulti={isMulti}
      isOptionDisabled={(option: OptionWithDisable) => option.disabled}
      defaultValue={defaultValue}
      placeholder={placeholder}
      {...props}
    />
  );
}

SelectComponent.defaultValues = {
  isMulti: false,
  placeholder: null,
  onChangeValue: null,
};

export default SelectComponent;
