import { ReportsMenuStructure } from "../../menuStructures";
import React, { Fragment, useEffect } from "react";
import { betaCustomers } from "../../constants/reports";
import SetActiveMenu from "../../menuStructures/SetActiveMenu";
import { useStoreDetails } from "../../hooks/storeHooks";
import ButtonComponent from "../../components/generic/ButtonComponent";
import { VIEW_REPORT_CLICKED } from "../../constants/eventsTracked";

function Sales() {
  const storeDetails = useStoreDetails();
  const storeId = storeDetails.customerId;

  useEffect(() => {
    document.title = "Reports | BinderPOS";
  }, []);

  return (
    <Fragment>
      <SetActiveMenu menuStructure={ReportsMenuStructure} />
      <div className="app-header">
        <h1 className="title">
          <span className="header-text">Sales Reports</span>
        </h1>
      </div>
      <div className="reports-menu row">
        <div className="col-sm-6">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title">Sales By Tender</h2>
              <p className="card-text">
                View sales figures for each tender type.
              </p>
              <ButtonComponent
                primary
                to="/reports/sales/tender"
                segmentEventName={VIEW_REPORT_CLICKED}
                segmentEventProperties={{ report_type: "Sales By Tender" }}
                fullWidth
                compact
                isLink
              >
                View Report
              </ButtonComponent>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title">Sales By Category</h2>
              <p className="card-text">
                View sales figures for different categories of products.
              </p>
              <ButtonComponent
                primary
                to="/reports/sales/category"
                segmentEventName={VIEW_REPORT_CLICKED}
                segmentEventProperties={{ report_type: "Sales By Category" }}
                fullWidth
                compact
                isLink
              >
                View Stock Reports
              </ButtonComponent>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title">Till Floats</h2>
              <p className="card-text">
                View details of float adjustments made in tills along with a
                ledger history for each float.
              </p>
              <ButtonComponent
                primary
                to="/reports/sales/float"
                segmentEventName={VIEW_REPORT_CLICKED}
                segmentEventProperties={{ report_type: "Till Floats" }}
                fullWidth
                compact
                isLink
              >
                View Float Reports
              </ButtonComponent>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title">WPN Premium Reports</h2>
              <p className="card-text">
                Generate a report for the Wizards Play Network.
              </p>
              <ButtonComponent
                primary
                to="/reports/sales/wpn"
                segmentEventName={VIEW_REPORT_CLICKED}
                segmentEventProperties={{ report_type: "WPN Premium" }}
                fullWidth
                isLink
                compact
              >
                View Report
              </ButtonComponent>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title">End of Day Reports</h2>
              <p className="card-text">
                Opening and closing amounts for tills, broken down by tender.
              </p>
              <ButtonComponent
                primary
                to="/reports/sales/end-of-day"
                segmentEventName={VIEW_REPORT_CLICKED}
                segmentEventProperties={{ report_type: "End Of Day" }}
                fullWidth
                compact
                isLink
              >
                View Report
              </ButtonComponent>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title">Submitted Carts Report</h2>
              <p className="card-text">
                See details of transactions between selected dates.
              </p>
              <ButtonComponent
                primary
                to="/reports/sales/carts"
                segmentEventName={VIEW_REPORT_CLICKED}
                segmentEventProperties={{ report_type: "Submitted Carts" }}
                fullWidth
                compact
                isLink
              >
                View Report
              </ButtonComponent>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title">Cart Notes</h2>
              <p className="card-text">
                View and export notes that have been saved against specific
                carts.
              </p>
              <ButtonComponent
                primary
                to="/reports/sales/cart-notes"
                segmentEventName={VIEW_REPORT_CLICKED}
                segmentEventProperties={{ report_type: "Cart Notes" }}
                fullWidth
                compact
                isLink
              >
                View Report
              </ButtonComponent>
            </div>
          </div>
        </div>
        {betaCustomers.includes(storeId) ? (
          <>
            <div className="col-sm-6">
              <div className="card">
                <div className="card-body">
                  <h2 className="card-title">Sales By Order - Beta</h2>
                  <p className="card-text">
                    Test report. Please do not use unless instructed.
                  </p>
                  <ButtonComponent
                    primary
                    to="/reports/sales/order-beta"
                    segmentEventName={VIEW_REPORT_CLICKED}
                    segmentEventProperties={{
                      report_type: "Sales By Order Beta",
                    }}
                    fullWidth
                    compact
                    isLink
                  >
                    View Report
                  </ButtonComponent>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="card">
                <div className="card-body">
                  <h2 className="card-title">Sales By Tender - Beta</h2>
                  <p className="card-text">
                    Test report. Please do not use unless instructed.
                  </p>
                  <ButtonComponent
                    primary
                    to="/reports/sales/tender-beta"
                    segmentEventName={VIEW_REPORT_CLICKED}
                    segmentEventProperties={{
                      report_type: "Sales By Tender Beta",
                    }}
                    fullWidth
                    compact
                    isLink
                  >
                    View Report
                  </ButtonComponent>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </Fragment>
  );
}

export default Sales;
