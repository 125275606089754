import React from "react";
import FullLogo from "../../../assets/img/fullLogo.png";
import Loader from "../generic/Loader";
import "../../views/auth/LoginPage.scss";

interface AuthStoreLoaderProps {
  authStoreLoaded: boolean;
  children: React.ReactNode;
}

function AuthStoreLoader(props: AuthStoreLoaderProps) {
  const { authStoreLoaded, children } = props;

  if (!authStoreLoaded) {
    return (
      <div className="LoginPage">
        <img className="LoginPage__logo" src={FullLogo} />
        <div className="LoginPage__wrapper">
          <Loader />
        </div>
      </div>
    );
  }

  return <>{children}</>;
}

export default AuthStoreLoader;
