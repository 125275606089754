module.exports = {
  Brands: [
    "fab fa-xbox",
    "fab fa-d-and-d-beyond",
    "fab fa-d-and-d",
    "fab fa-playstation",
    "fab fa-critical-role",
    "fab fa-earlybirds",
    "fab fa-fort-awesome",
    "fab fa-fort-awesome-alt",
    "fab fa-fantasy-flight-games",
    "fab fa-galactic-senate",
    "fab fa-optin-monster",
    "fab fa-old-republic",
    "fab fa-ebay",
    "fab fa-wizards-of-the-coast",
  ],
  "Food & Drinks": [
    "fas fa-candy-corn",
    "far fa-pizza-slice",
    "fad fa-sandwich",
    "fas fa-sausage",
    "far fa-sausage",
    "fal fa-sausage",
    "fas fa-salad",
    "far fa-salad",
    "fal fa-salad",
    "fas fa-popcorn",
    "far fa-popcorn",
    "fal fa-popcorn",
    "fas fa-pie",
    "far fa-pie",
    "fal fa-pie",
    "fas fa-mug-tea",
    "far fa-mug-tea",
    "fal fa-mug-tea",
    "fas fa-mug-marshmallows",
    "far fa-mug-marshmallows",
    "fal fa-mug-marshmallows",
    "fas fa-mug-hot",
    "far fa-mug-hot",
    "fal fa-mug-hot",
    "fas fa-meat",
    "far fa-meat",
    "fal fa-meat",
    "fas fa-jug",
    "far fa-jug",
    "fal fa-jug",
    "fas fa-ice-cream",
    "far fa-ice-cream",
    "fal fa-ice-cream",
    "fas fa-hotdog",
    "far fa-hotdog",
    "fal fa-hotdog",
    "fas fa-hamburger",
    "far fa-hamburger",
    "fal fa-hamburger",
    "fas fa-glass-whiskey-rocks",
    "far fa-glass-whiskey-rocks",
    "fal fa-glass-whiskey-rocks",
    "fas fa-glass-martini-alt",
    "far fa-glass-martini-alt",
    "fal fa-glass-martini-alt",
    "fas fa-french-fries",
    "far fa-french-fries",
    "fal fa-french-fries",
    "fas fa-gingerbread-man",
    "far fa-gingerbread-man",
    "fal fa-gingerbread-man",
    "fas fa-drumstick-bite",
    "far fa-drumstick-bite",
    "fal fa-drumstick-bite",
    "fas fa-croissant",
    "far fa-croissant",
    "fal fa-croissant",
    "fas fa-corn",
    "far fa-corn",
    "fal fa-corn",
    "fas fa-cookie",
    "far fa-cookie",
    "fal fa-cookie",
    "fas fa-coffee-togo",
    "far fa-coffee-togo",
    "fal fa-coffee-togo",
    "fas fa-cheese-swiss",
    "far fa-cheese-swiss",
    "fal fa-cheese-swiss",
    "fas fa-cheeseburger",
    "far fa-cheeseburger",
    "fal fa-cheeseburger",
    "fas fa-candy-cane",
    "far fa-candy-cane",
    "fal fa-candy-cane",
    "fas fa-burrito",
    "far fa-burrito",
    "fal fa-burrito",
    "fas fa-burger-soda",
    "far fa-burger-soda",
    "fal fa-burger-soda",
    "fas fa-beer",
    "far fa-beer",
    "fal fa-beer",
  ],
  Games: [
    "fas fa-joystick",
    "far fa-joystick",
    "fal fa-joystick",
    "fas fa-puzzle-piece",
    "far fa-puzzle-piece",
    "fal fa-puzzle-piece",
    "fas fa-gamepad-alt",
    "far fa-gamepad-alt",
    "fal fa-gamepad-alt",
    "fad fa-gamepad",
    "fas fa-gamepad",
    "far fa-gamepad",
    "fal fa-gamepad",
    "fas fa-game-console-handheld",
    "far fa-game-console-handheld",
    "fal fa-game-console-handheld",
    "fad fa-dice-d8",
    "fas fa-dice-d8",
    "far fa-dice-d8",
    "fal fa-dice-d8",
    "fas fa-dice-d20",
    "far fa-dice-d20",
    "fal fa-dice-d20",
    "fas fa-dice",
    "far fa-dice",
    "fal fa-dice",
    "fas fa-chess-board",
    "far fa-chess-board",
    "fal fa-chess-board",
    "fas fa-chess",
    "far fa-chess",
    "fal fa-chess",
    "fas fa-hat-wizard",
    "far fa-hat-wizard",
    "fal fa-hat-wizard",
  ],
  Fantasy: [
    "fas fa-wand-magic",
    "far fa-wand-magic",
    "fal fa-wand-magic",
    "fas fa-tombstone",
    "far fa-tombstone",
    "fal fa-tombstone",
    "fas fa-spider-black-widow",
    "far fa-spider-black-widow",
    "fal fa-spider-black-widow",
    "fas fa-spider",
    "far fa-spider",
    "fal fa-spider",
    "fas fa-skull-crossbones",
    "far fa-skull-crossbones",
    "fal fa-skull-crossbones",
    "fas fa-scythe",
    "far fa-scythe",
    "fal fa-scythe",
    "fas fa-scarecrow",
    "far fa-scarecrow",
    "fal fa-scarecrow",
    "fas fa-knife-kitchen",
    "far fa-knife-kitchen",
    "fal fa-knife-kitchen",
    "fas fa-key-skeleton",
    "far fa-key-skeleton",
    "fal fa-key-skeleton",
    "fas fa-jack-o-lantern",
    "far fa-jack-o-lantern",
    "fal fa-jack-o-lantern",
    "fas fa-hockey-mask",
    "far fa-hockey-mask",
    "fal fa-hockey-mask",
    "fas fa-hat-witch",
    "far fa-hat-witch",
    "fal fa-hat-witch",
    "fas fa-coffin",
    "far fa-coffin",
    "fal fa-coffin",
    "fas fa-cauldron",
    "far fa-cauldron",
    "fal fa-cauldron",
    "fas fa-claw-marks",
    "far fa-claw-marks",
    "fal fa-claw-marks",
    "far fa-book-spells",
    "far fa-book-spells",
    "fal fa-book-spells",
  ],
  "MTG Set": [
    "ms ms-b",
    "ms ms-g",
    "ms ms-w",
    "ms ms-r",
    "ms ms-u",
    "ms ms-c",
    "ms ms-e",
    "ms ms-tap",
    "ms ms-land",
    "ms ms-planeswalker",
    "ms ms-creature",
    "ms ms-sorcery",
    "ms ms-dfc-ignite",
    "ms ms-guild-boros",
    "ms ms-dfc-spark",
    "ms ms-dfc-enchantment",
    "ms ms-guild-simic",
    "ms ms-chaos",
    "ms ms-untap",
    "ms ms-dfc-moon",
    "ms ms-toughness",
    "ms ms-guild-boros",
    "ms ms-artifact",
  ],
  "Science Fiction": [
    "fas fa-jedi",
    "far fa-jedi",
    "fal fa-jedi",
    "fas fa-drone-alt",
    "far fa-drone-alt",
    "fal fa-drone-alt",
    "fas fa-drone",
    "far fa-drone",
    "fal fa-drone",
    "fas fa-user-astronaut",
    "far fa-user-astronaut",
    "fal fa-user-astronaut",
    "fas fa-stars",
    "far fa-stars",
    "fal fa-stars",
    "fas fa-watch-calculator",
    "far fa-watch-calculator",
    "fal fa-watch-calculator",
  ],
};
