import React, { useEffect, useRef, useState } from "react";
import CustomerMenuStructure from "../../menuStructures/CustomerMenuStructure";
import { useShowError } from "../../hooks/errorHooks";
import { useFormatCurrency } from "../../hooks/storeHooks";
import { useDebounce } from "../../hooks/inputHooks";
import useTitle from "../../hooks/useTitle";
import {
  useFetchCustomerList,
  useRefreshCustomerList,
} from "../../hooks/customerHooks";
import {
  SEARCH_CUSTOMER_FIELD_CLICKED,
  UPLOAD_STORE_CREDIT_CLICKED,
  REFRESH_CUSTOMERS_LIST_CLICKED,
} from "../../constants/eventsTracked";
import { segmentAnalytics } from "../../components/services/Analytics";
import ButtonComponent from "../../components/generic/ButtonComponent";
import Paging from "../../components/generic/Paging";
import Spinner from "../../components/generic/Spinner";
import ActionButtonsLayout from "../../components/layout/ActionButtonsLayout";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";
import SetActiveMenu from "../../menuStructures/SetActiveMenu";
import AdjustStoreCreditModal from "../../components/customers/AdjustStoreCreditModal";
import StoreCreditHistory from "../../components/customers/StoreCreditHistory";
import CustomerCsvUploadModal from "../../components/customers/CustomerCsvUploadModal";
import TableComponent from "../../components/table/TableComponent";
import CustomerColumns from "./CustomerColumns";
import "./CustomerList.scss";

const WAIT_INTERVAL = 625;
const LIMIT = 10;

const rowProps = (customer: Customer) => ({
  className:
    customer.storeCredit > 0
      ? "CustomerList__row--credit"
      : "CustomerList__row--cash",
});

function CustomerList() {
  const listRef = useRef<HTMLElement>();
  const modalRef = useRef<HTMLElement>();
  const [offset, setOffset] = useState(0);
  const [searchString, setSearchString] = useState<string>();
  const debouncedSearchString: string = useDebounce<string>(
    searchString,
    WAIT_INTERVAL
  );
  const [adjustCreditModalVisible, setAdjustCreditModalVisible] =
    useState(false);
  const [customerToAdjust, setCustomerToAdjust] = useState<Customer>();
  const [uploadFileModalVisible, setUploadFileModalVisible] = useState(false);
  const [showHistoryForCustomer, setShowHistoryForCustomer] =
    useState<Customer>();
  const showError = useShowError();
  const formatCurrency = useFormatCurrency();

  const {
    data: customers = [],
    isLoading,
    error,
    refetch: refetchCustomers,
    isPreviousData,
  } = useFetchCustomerList(offset, LIMIT, debouncedSearchString);

  const { mutateAsync: refreshCustomers, isLoading: isRefreshing } =
    useRefreshCustomerList();
  useTitle("Customers");

  useEffect(() => {
    if (error) showError(error as DetailedError);
  }, [error]);

  useEffect(() => {
    if (offset > 0) {
      setOffset(0);
    }
  }, [debouncedSearchString]);

  useEffect(() => {
    if (
      adjustCreditModalVisible ||
      uploadFileModalVisible ||
      showHistoryForCustomer
    ) {
      modalRef.current?.focus();
    } else {
      listRef.current?.focus();
    }
  }, [
    adjustCreditModalVisible,
    uploadFileModalVisible,
    showHistoryForCustomer,
  ]);

  const onAdjustStoreCreditComplete = () => {
    refetchCustomers();
    setAdjustCreditModalVisible(false);
    setCustomerToAdjust(undefined);
  };

  const viewHistory = (customer: Customer) => {
    setShowHistoryForCustomer(customer);
  };

  const showAdjustModal = (customer: Customer) => {
    setCustomerToAdjust(customer);
    setAdjustCreditModalVisible(true);
  };

  const getNextCustomers = () => {
    const newOffset = offset + LIMIT;
    setOffset(newOffset);
  };

  const getPrevCustomers = () => {
    const newOffset = Math.max(offset - LIMIT, 0);
    setOffset(newOffset);
  };

  return (
    <div className="CustomerList">
      <SetActiveMenu menuStructure={CustomerMenuStructure} />
      <SectionHeaderLayout title="My Customers">
        <div>
          <i className="far fa-search CustomerList__searchIcon" />
          <input
            autoComplete="off"
            className="CustomerList__search"
            type="text"
            placeholder="Search customer"
            onChange={(event) => setSearchString(event.target.value)}
            onFocus={() =>
              segmentAnalytics.track(SEARCH_CUSTOMER_FIELD_CLICKED)
            }
            value={searchString || ""}
          />
        </div>
      </SectionHeaderLayout>
      <ActionButtonsLayout alignment="right">
        <ButtonComponent
          primary
          to="/customers/add"
          isLink
          icon="fa fa-plus"
          iconPosition="left"
        >
          Add Customer
        </ButtonComponent>
        <ButtonComponent
          primary
          onClick={(event) => {
            listRef.current = event.currentTarget;
            setUploadFileModalVisible(true);
          }}
          icon="fal fa-upload"
          iconPosition="left"
          segmentEventName={UPLOAD_STORE_CREDIT_CLICKED}
        >
          Upload Store Credit
        </ButtonComponent>
        <ButtonComponent
          onClick={() => refreshCustomers()}
          disabled={isRefreshing}
          icon="fas fa-sync-alt"
          iconPosition="left"
          segmentEventName={REFRESH_CUSTOMERS_LIST_CLICKED}
        >
          Refresh
        </ButtonComponent>
      </ActionButtonsLayout>
      <Spinner isLoading={isLoading}>
        <TableComponent
          data={customers}
          columns={CustomerColumns}
          listRef={listRef}
          showAdjustModal={showAdjustModal}
          viewHistory={viewHistory}
          formatCurrency={formatCurrency}
          getTrProps={rowProps}
        />
        <br />
        <Paging
          pageOffset={offset}
          currentPageItemCount={customers.length}
          maxItemsPerPage={LIMIT}
          disabled={isPreviousData}
          getPrevPage={getPrevCustomers}
          getNextPage={getNextCustomers}
        />
      </Spinner>
      {showHistoryForCustomer ? (
        <StoreCreditHistory
          customer={showHistoryForCustomer}
          handleClose={() => setShowHistoryForCustomer(undefined)}
          ref={modalRef}
        />
      ) : null}
      {adjustCreditModalVisible ? (
        <AdjustStoreCreditModal
          customer={customerToAdjust}
          onComplete={onAdjustStoreCreditComplete}
          handleClose={() => setAdjustCreditModalVisible(false)}
          ref={modalRef}
        />
      ) : null}
      {uploadFileModalVisible ? (
        <CustomerCsvUploadModal
          handleClose={() => setUploadFileModalVisible(false)}
          ref={modalRef}
        />
      ) : null}
    </div>
  );
}

export default CustomerList;
