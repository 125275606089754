import { _fetch, _fetchWithFile } from "../../api";
import { API_V2_BASE_URL, BASE_URL } from "../../constants/api";
import { forceDownloadFromURL } from "../../helpers/fileHelpers";
import { CSVExportReponse } from "../../types/productExport";
import {
  CsvUploadResult,
  PreviousProductImport,
  ProcessedCsvDownloadUrl,
} from "../../types/productImport";

type BulkUpdateRequest = {
  fieldsToUpdate: unknown;
  searchToUpdate: Record<string, string | string[] | null>;
};

type MessageResponse = {
  message: string;
};

type CSVExportFileResponse = {
  csvExportLink: string;
};

export const bulkUpdate = (
  bulkUpdateRequest: BulkUpdateRequest
): Promise<MessageResponse> =>
  _fetch({
    method: "POST",
    endpoint: `${BASE_URL}/products/bulkUpdate`,
    payload: bulkUpdateRequest,
  });

export const uploadCsv = (payload: FormData): Promise<CsvUploadResult> =>
  _fetchWithFile({
    endpoint: `${API_V2_BASE_URL}/upload-csv`,
    payload,
  });

export const fetchCsvUploads = (
  offset: number,
  limit = 20
): Promise<PreviousProductImport[]> =>
  _fetch({
    method: "GET",
    endpoint: `${API_V2_BASE_URL}/upload-csv/list`,
    query: { limit, offset },
  });

export const fetchCsvDownloadUrl = (
  csvId: number
): Promise<ProcessedCsvDownloadUrl> =>
  _fetch({
    method: "GET",
    endpoint: `${API_V2_BASE_URL}/upload-csv/csvId/${csvId}`,
  });

export const fetchCSVExportHistory = (
  offset = 0,
  limit = 10
): Promise<Array<CSVExportReponse>> =>
  _fetch({
    method: "GET",
    endpoint:
      `${API_V2_BASE_URL}/export-csv/list?` +
      `limit=${limit}` +
      `&offset=${offset}`,
  });

export const fetchCSVExportFile = async (csvId: string): Promise<void> => {
  if (!csvId) return;
  return await _fetch({
    method: "GET",
    endpoint: `${API_V2_BASE_URL}/export-csv/csvId/${csvId}`,
  }).then((response: CSVExportFileResponse) => {
    if (response?.csvExportLink) forceDownloadFromURL(response?.csvExportLink);
  });
};

export const fetchProductPricingInfo = async (
  productId: number
): Promise<ProductPricing[]> => {
  return await _fetch({
    method: "GET",
    endpoint: `${API_V2_BASE_URL}/products/${productId}/pricing`,
  });
};
