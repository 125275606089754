import React, { useState } from "react";
import { DISPLAY_OVERSTOCK_WARNING_TOGGLED } from "../../constants/eventsTracked";
import { BinderCustomerTillSettings } from "../../generated/graphql";
import { notify } from "../../helpers/notificationHelpers";
import { useUpdateCustomerTillSetting } from "../../hooks/settingHooks";
import CheckboxComponent from "../generic/CheckboxComponent";
import Panel from "../layout/Panel";
import { segmentAnalytics } from "../services/Analytics";

type SavedSetting = Partial<BinderCustomerTillSettings>;

const getSavedSettingValue = (
  savedSettings: SavedSetting[],
  settingName: string
) =>
  savedSettings.find((setting) => setting.settingName === settingName)
    ?.settingValue;

interface PerTillSettingsProps {
  tillId: number;
  savedSettings: SavedSetting[];
}

function PerTillSettings(props: PerTillSettingsProps) {
  const { tillId, savedSettings } = props;
  const [isSavingSettings, setIsSavingSettings] = useState<string[]>([]);
  const { mutateAsync } = useUpdateCustomerTillSetting();

  const handleChange = async (name: string, value: string) => {
    if (!isSavingSettings.includes(name)) {
      setIsSavingSettings((prev) => [...prev, name]);
    }
    try {
      await mutateAsync({
        settingName: name,
        settingValue: value,
        binderCustomerTillId: tillId,
      });
      notify.info("Setting updated");
    } catch (error) {
      notify.error("Failed to update setting. Please try again");
    }
    setIsSavingSettings((prev) => prev.filter((n) => n !== name));
  };

  if (!tillId) {
    return null;
  }

  return (
    <Panel key={tillId}>
      <CheckboxComponent
        name="buylimitWarning"
        label="Display overstock warnings on buy items"
        disabled={isSavingSettings.includes("posBuylimitWarning")}
        onChange={() => {
          const newValue =
            getSavedSettingValue(savedSettings, "posBuylimitWarning") !== "true"
              ? "true"
              : "false";
          segmentAnalytics.track(DISPLAY_OVERSTOCK_WARNING_TOGGLED, {
            display_overstock_warning: newValue,
          });
          handleChange("posBuylimitWarning", newValue);
        }}
      />
      <p>
        When enabled, warnings will be shown in till when the purchase of a
        particular item reaches or exceeds the desired buy limit.
      </p>
    </Panel>
  );
}

export default PerTillSettings;
