import { UseQueryOptions, QueryKey } from "react-query";
import {
  GetStoreCreditOutstandingQuery,
  useGetStoreCreditOutstandingQuery,
} from "../../generated/reporting";
import { formatStoreCreditOutstanding } from "../../transformers/reports/formatStoreCreditOutstanding";
import { StoreCreditOutstandingData } from "../../types/reports";

export const useFetchStoreCreditOutstanding = (
  options: UseQueryOptions<
    GetStoreCreditOutstandingQuery,
    unknown,
    StoreCreditOutstandingData[],
    QueryKey
  > = {}
) =>
  useGetStoreCreditOutstandingQuery(
    {},
    {
      select: (report) => formatStoreCreditOutstanding(report),
      keepPreviousData: true,
      staleTime: 60_000,
      ...options,
    }
  );
