export const normalizeVariantTypes = (variantTypes: VariantType[]) => {
  return variantTypes
    ? variantTypes.reduce((acc: any, val: VariantType) => {
        acc[val.name] = val.multiplier;
        return acc;
      }, {})
    : {};
};

export const convertMultiplierToPercentage = (
  dataSet: object,
  keysToModify: Array<string> | null
) => {
  if (!dataSet) return null;
  const modifiedDataSet = { ...dataSet };
  Object.entries(dataSet).forEach(([key, value]: any) => {
    if (
      ((keysToModify && keysToModify.includes(key)) || keysToModify === null) &&
      value !== "" // Prevent empty values from being translated into 0.
    ) {
      modifiedDataSet[key] = (value * 100).toFixed(0);
    }
  });
  return modifiedDataSet;
};
