import React from "react";
import { useHistory } from "react-router-dom";
import CookieSettingsModal from "../../components/settings/CookieSettings";

function CookieSettings() {
  const history = useHistory();

  return <CookieSettingsModal onClose={history.goBack} />;
}

export default CookieSettings;
