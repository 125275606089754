import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "react-query";
import { _fetch } from "../api";
import {
  fetchCustomerSetting,
  updateCustomerSetting,
  fetchStoreDetails,
  updateStoreDetails,
  fetchStoreSettings,
  updateCustomerTillSetting,
  fetchCatalogs,
  updateCatalogEnabled,
  fetchScreenSettings,
  fetchLocations,
  updateLocations,
} from "../api/rest/settings";
import { BASE_URL } from "../constants/api";
import { BinderCustomerTillSettings } from "../generated/graphql";
import { CatalogCategory } from "../types/catalogSettings";
import { LocationSettings, StoreSettings } from "../types/settings";

const LONG_LIFE_DATA_STALE_TIME = 5 * 60 * 1000; // ms

export const mapCustomerSettings = (
  settings: CustomerSettingsResponse
): CustomerSettings => {
  const mapped = settings.reduce((acc, entry) => {
    const parseValue = (entry: CustomerSetting) => {
      switch (entry.settingName) {
        case "taxRate":
          return Number(entry.settingValue);
        case "taxIncluded":
        case "tradeInTax":
          return entry.settingValue?.toLowerCase() === "true";
        default:
          return entry.settingValue;
      }
    };
    return {
      ...acc,
      [entry.settingName]: parseValue(entry),
    };
  }, {}) as CustomerSettings;

  return {
    ...mapped,
    currencySymbol: "$",
  };
};

export async function getCustomerSettings() {
  const customerSettings = await Promise.all<CustomerSetting>([
    _fetch({
      endpoint: `${BASE_URL}/settings/taxRate/forMe`,
    }),
    _fetch({
      endpoint: `${BASE_URL}/settings/taxIncluded/forMe`,
    }),
    _fetch({
      endpoint: `${BASE_URL}/settings/tradeInTax/forMe`,
    }),
    _fetch({
      endpoint: `${BASE_URL}/settings/taxNumber/forMe`,
    }),
    _fetch({
      endpoint: `${BASE_URL}/settings/taxWording/forMe`,
    }),
    _fetch({
      endpoint: `${BASE_URL}/settings/currencySymbol/forMe`,
    }),
    _fetch({
      endpoint: `${BASE_URL}/settings/currency/forMe`,
    }),
  ]);
  return customerSettings;
}

export const useGetCustomerSettings = () =>
  useQuery(["GetCustomerSettings"], getCustomerSettings, {
    staleTime: LONG_LIFE_DATA_STALE_TIME,
    select: mapCustomerSettings,
  });

export const useGetStoreInfo = () =>
  useQuery<StoreSettings>(["GetStoreInfo"], fetchStoreSettings, {
    staleTime: LONG_LIFE_DATA_STALE_TIME,
  });

export const useFetchSetting = (
  settingName: string,
  options?: UseQueryOptions<any>
) =>
  useQuery(
    ["customerSettings", { settingName }],
    () =>
      fetchCustomerSetting(settingName).then(
        (response) => response?.settingValue
      ),
    {
      staleTime: LONG_LIFE_DATA_STALE_TIME,
      ...options,
    }
  );

type UpdateSettingVariables = {
  settingName: string;
  settingValue: unknown;
};

export const useUpdateSetting = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    ({ settingName, settingValue }: UpdateSettingVariables) =>
      updateCustomerSetting({
        settingName: settingName,
        settingValue: settingValue,
      }),
    {
      onSuccess: (response: UserSetting, variables: UpdateSettingVariables) => {
        const { settingName, settingValue } = variables;
        queryClient.setQueryData<string>(
          ["customerSettings", { settingName }],
          () => settingValue as string
        );
        queryClient.setQueryData<UpdateSettingVariables[]>(
          "GetCustomerSettings",
          (prev = []) =>
            prev.map((setting) => {
              if (setting.settingName === settingName) {
                return {
                  ...setting,
                  settingValue,
                };
              }
              return setting;
            })
        );
      },
    }
  );
  return { isMutating, ...rest };
};

export const useGetStoreSettings = () =>
  useQuery("storeSettings", fetchStoreSettings, {
    staleTime: LONG_LIFE_DATA_STALE_TIME,
  });

export const useGetStoreDetails = () =>
  useQuery(["storeDetails"], fetchStoreDetails, {
    staleTime: LONG_LIFE_DATA_STALE_TIME,
  });

export const useUpdateStoreDetails = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    ({ storeDetails }: any) => updateStoreDetails(storeDetails),
    {
      onSuccess: (_response, variables) => {
        const { storeDetails } = variables;
        queryClient.setQueryData(["storeDetails"], () => storeDetails);
      },
    }
  );
  return { isMutating, ...rest };
};

type UpdateTillSettingVariables = {
  settingName: string;
  settingValue: string;
  binderCustomerTillId: number;
};

type TillSettingsCache = {
  BinderCustomerTillSettings: BinderCustomerTillSettings[];
};

export const useUpdateCustomerTillSetting = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    ({
      settingName,
      settingValue,
      binderCustomerTillId,
    }: UpdateTillSettingVariables) =>
      updateCustomerTillSetting({
        settingName,
        settingValue,
        binderCustomerTillId,
      }),
    {
      onSuccess: (
        response: BinderCustomerTillSettings,
        variables: UpdateTillSettingVariables
      ) => {
        const { settingName, settingValue, binderCustomerTillId } = variables;
        queryClient.setQueryData<TillSettingsCache>(
          ["GetTillSettings", { tillId: binderCustomerTillId }],
          (oldData) => {
            let settingFound = false;
            if (oldData?.BinderCustomerTillSettings) {
              const updatedSettings = {
                BinderCustomerTillSettings: (
                  oldData?.BinderCustomerTillSettings || []
                ).map((setting) => {
                  if (
                    setting.settingName === settingName &&
                    Number(setting.tillId) === Number(binderCustomerTillId)
                  ) {
                    settingFound = true;
                    return {
                      ...setting,
                      settingValue,
                    };
                  }
                  return setting;
                }),
              };
              if (settingFound) {
                return updatedSettings;
              }
              return {
                BinderCustomerTillSettings: [
                  ...oldData?.BinderCustomerTillSettings,
                  response,
                ],
              };
            } else {
              return {
                BinderCustomerTillSettings: [response],
              };
            }
          }
        );
      },
    }
  );
  return { isMutating, ...rest };
};

export const useFetchCatalogs = () =>
  useQuery(["Catalogs"], () => fetchCatalogs(true));

type UseUpdateCatalogEnabledVariables = {
  gameCode: string;
  enabled: boolean;
};

export const useUpdateCatalogEnabled = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    ({ gameCode, enabled }: UseUpdateCatalogEnabledVariables) =>
      updateCatalogEnabled(gameCode, enabled),
    {
      onSuccess: (
        _repsonse: unknown,
        variables: UseUpdateCatalogEnabledVariables
      ) => {
        const { gameCode, enabled } = variables;
        queryClient.setQueryData<CatalogCategory[]>(
          ["Catalogs"],
          (oldData = []) => {
            return oldData.map((catalog) => {
              if (catalog.binderGameType !== gameCode) {
                return catalog;
              }
              return {
                ...catalog,
                enabled,
              };
            });
          }
        );
      },
    }
  );

  return { isMutating, ...rest };
};

export const useFetchScreenSettings = () =>
  useQuery(["screenSettings"], () => fetchScreenSettings(), {
    staleTime: LONG_LIFE_DATA_STALE_TIME,
  });

export const useFetchLocations = () =>
  useQuery(["locations"], fetchLocations, {
    staleTime: LONG_LIFE_DATA_STALE_TIME,
  });

type UseUpdateLocationVariables = {
  id: number;
};

export const useUpdateLocations = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    (location: UseUpdateLocationVariables) => updateLocations(location),
    {
      onSuccess: (
        _response: unknown,
        variables: UseUpdateLocationVariables
      ) => {
        const selectedLocationId = variables.id;
        const selectedLocation = (
          queryClient.getQueryData<LocationSettings>(["locations"])?.shopify ||
          []
        ).find((location) => location.shopifyId === selectedLocationId);
        queryClient.setQueryData<LocationSettings | undefined>(
          ["locations"],
          (prevData) => {
            if (!prevData) return undefined;
            return { ...prevData, binder: [selectedLocation] };
          }
        );
      },
    }
  );
  return { isMutating, ...rest };
};
