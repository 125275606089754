import React, { Component } from "react";
import { SearchBar, ResultsGrid, Cart } from "..";
import SwipeableViews from "react-swipeable-views";
import KioskCart from "./KioskCart";
import { isKioskMode } from "../../helpers/posModeHelpers";
import "./ViewSwipePOS.scss";
class ViewSwipePOS extends Component {
  render() {
    return (
      <>
        <h1 className="sr-only">POS and Cart view</h1>
        <SwipeableViews
          className="ViewSwipePOS__container"
          enableMouseEvents={true}
        >
          <div className="bodyPanel" style={Object.assign({})}>
            <div className="wrapper">
              <SearchBar />
              <ResultsGrid />
            </div>
          </div>
          <div className="cartPanel" style={Object.assign({})}>
            {isKioskMode() ? <KioskCart /> : <Cart />}
          </div>
        </SwipeableViews>
      </>
    );
  }
}

export default ViewSwipePOS;
