import { _fetch, _fetchWithFormData } from "..";
import { BASE_URL, API_V2_BASE_URL } from "../../constants/api";
import { DEFAULT_EVENTS_PER_PAGE } from "../../constants/events";
import { PortalApiResult } from "../../types/api";
import {
  EventAdditionalInfo,
  EventInfo,
  EventParticipant,
  EventParticipantsAndCustomers,
} from "../../types/events";

export const fetchPotentialExistingMainEvents = (
  eventId: number
): Promise<EventInfo[]> =>
  _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/events/${eventId}/potentialSubEvents`,
  });

export const fetchPotentialNewMainEvents = (): Promise<EventInfo[]> =>
  _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/events/potentialSubEvents`,
  });

type FetchEventParams = {
  limit?: number;
  offset?: number;
};

export const fetchEvents = (
  params: FetchEventParams = {}
): Promise<EventInfo[]> => {
  // Set some defaults for pagination
  if (!params?.limit) {
    params.limit = DEFAULT_EVENTS_PER_PAGE;
  }
  if (!params?.offset) {
    params.offset = 0;
  }
  const queryString = Object.entries(params)
    .map(
      ([param, value]) =>
        `${encodeURIComponent(param)}=${encodeURIComponent(value)}`
    )
    .join("&");
  return _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/events?${queryString}`,
  });
};

export const fetchEventInfo = (eventId: number): Promise<EventInfo> =>
  _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/events/${eventId}`,
  });

export const fetchFailedToCreateEvents = (
  offset: number,
  limit: number
): Promise<EventInfo[]> =>
  _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/events/failedToCreate?offset=${offset}&limit=${limit}`,
  });

export const repushFailedEvent = (id: number): Promise<PortalApiResult> =>
  _fetch({
    method: "POST",
    endpoint: `${BASE_URL}/events/${id}/repush`,
  });

export const fetchFailedToDeleteEvents = (
  offset: number,
  limit: number
): Promise<EventInfo[]> =>
  _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/events/failedToDelete?offset=${offset}&limit=${limit}`,
  });

export const retryFailedToDelete = (id: number): Promise<PortalApiResult> =>
  _fetch({
    method: "POST",
    endpoint: `${BASE_URL}/events/${id}/retry/failedToDelete`,
  });

export const addEvent = (event: FormData): Promise<[]> =>
  _fetchWithFormData({
    method: "POST",
    endpoint: `${BASE_URL}/events/add`,
    payload: event,
  });

export const enableEvent = (eventId: number): Promise<[]> =>
  _fetchWithFormData({
    method: "POST",
    endpoint: `${BASE_URL}/events/${eventId}/enable`,
  });

export const disableEvent = (eventId: number): Promise<[]> =>
  _fetchWithFormData({
    method: "POST",
    endpoint: `${BASE_URL}/events/${eventId}/disable`,
  });

export const updateEvent = (
  id: string | number,
  event: FormData
): Promise<EventInfo> =>
  _fetchWithFormData({
    method: "PUT",
    endpoint: `${BASE_URL}/events/${id}/update`,
    payload: event,
  });

export const deleteEvent = (eventId: number): Promise<[]> =>
  _fetch({
    method: "DELETE",
    endpoint: `${BASE_URL}/events/${eventId}`,
  });

export const fetchEventGamesForClient = (): Promise<string[]> =>
  _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/events/games`,
  });

export const fetchEventTypesForClient = (): Promise<string[]> =>
  _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/events/types`,
  });

export const fetchEventAdditionalInfo = (
  eventId: number
): Promise<EventAdditionalInfo[]> =>
  _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/events/${eventId}/additionalInfo`,
  });

export const fetchAllEventParticipants = (
  eventId: number | string
): Promise<EventParticipantsAndCustomers> =>
  _fetch({
    method: "GET",
    endpoint: `${API_V2_BASE_URL}/event/${eventId}/participant/all`,
  });

export const fetchEventParticipant = (
  eventId: number | string,
  participantId: number | string
): Promise<EventParticipant> =>
  _fetch({
    method: "GET",
    endpoint: `${API_V2_BASE_URL}/event/${eventId}/participant/${participantId}`,
  });

export const addEventParticipant = (
  eventId: number | string,
  participant: EventParticipant
): Promise<EventParticipant> =>
  _fetch({
    method: "POST",
    endpoint: `${API_V2_BASE_URL}/event/${eventId}/participant`,
    payload: participant,
  });

export const removeEventParticipant = (
  eventId: number | string,
  participantId: number | string
): Promise<[]> =>
  _fetch({
    method: "DELETE",
    endpoint: `${API_V2_BASE_URL}/event/${eventId}/participant/${participantId}`,
  });

export const updateEventParticipant = (
  eventId: number | string,
  participantId: number | string,
  participant: EventParticipant
): Promise<EventParticipant> =>
  _fetch({
    method: "PUT",
    endpoint: `${API_V2_BASE_URL}/event/${eventId}/participant/${participantId}`,
    payload: participant,
  });
