import React, { createContext, useState } from "react";
import { useFetchAvailableIntegrations } from "../hooks/integrationHooks";

export interface IntegrationContextProps {
  availableIntegrations: IntegrationDetails[];
  ebayDefaultMarketplace: string | undefined;
  setEbayDefaultMarketplace: React.Dispatch<React.SetStateAction<string>>;
}

export const IntegrationContext = createContext<IntegrationContextProps>(null);

interface IntegrationContextProviderProps {
  children: React.ReactChild | React.ReactNodeArray;
}

function IntegrationProvider(props: IntegrationContextProviderProps) {
  const { children } = props;
  const { data: availableIntegrations } = useFetchAvailableIntegrations();

  const [ebayDefaultMarketplace, setEbayDefaultMarketplace] =
    useState<string>();

  return (
    <IntegrationContext.Provider
      value={{
        availableIntegrations: availableIntegrations ?? [],
        ebayDefaultMarketplace,
        setEbayDefaultMarketplace,
      }}
    >
      {children}
    </IntegrationContext.Provider>
  );
}

export default IntegrationProvider;
