import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { action } from "mobx";
import CustomerSelect from "./CustomerSelect";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { notify } from "../../helpers/notificationHelpers";
import { isKioskMode } from "../../helpers/posModeHelpers";
import { AdaBotNavbarButton } from "./AdaBotNavbarButton";
import NavButton from "./NavButton";
import NavToggleButton from "./NavToggleButton";
import {
  CREDIT_PRICE_TOGGLED,
  OPEN_CLOSE_TILL_CLICKED,
  NEW_CART_CLICKED,
  LOAD_CART_CLICKED,
  SAVE_CART_CLICKED,
  HISTORY_AND_REFUNDS_CLICKED,
  ALL_PRODUCTS_SEARCH_CLICKED,
  EXCLUDING_SINGLES_CLICKED,
  IN_STOCK_ONLY_TOGGLED,
  EXIT_POS_CLICKED,
} from "../../constants/eventsTracked";
import "./Navbar.scss";
import { ItemList } from "../../pos/ItemStore";

interface NavbarProps extends RouteComponentProps {
  posStore?: ItemList;
  menuState?: boolean;
  closeMenu?: () => void;
}

@inject("posStore")
@observer
class Navbar extends Component<NavbarProps> {
  @action
  toggleFloatModal = () => {
    this.props.posStore.floatModal = true;
    this.props.posStore.fetchFloat();
  };

  createNewCart() {
    this.props.posStore.newCart();
    notify.info("You have created a new cart!");
  }

  saveCart() {
    if (this.props.posStore.cart.length > 0) {
      this.props.posStore.saveCart();
    } else {
      notify.info("Your cart appears to be empty...");
    }
  }

  toggleSingles() {
    this.props.posStore.toggleSingle();
    notify.info(
      this.props.posStore.includeSingles
        ? "Your searches now include singles"
        : "Your searches will now exclude singles"
    );
  }

  toggleInStockOnly = () => {
    this.props.posStore.toggleInStockOnly();
  };

  closeMenu() {
    if (this.props.closeMenu) {
      this.props.closeMenu();
    }
  }

  render() {
    const { inStockOnly } = this.props.posStore;

    if (isKioskMode()) {
      return (
        <div className="Navbar">
          <div className="Navbar__posMenu Navbar__posMenu--kiosk">
            <div
              className={
                this.props.menuState
                  ? "Navbar__mobileMenu show"
                  : "Navbar__mobileMenu Navbar__mobileMenu--kiosk"
              }
            >
              <NavButton icon="far fa-lock-alt" />
              <NavToggleButton
                labelDefault="Cash Price"
                labelAlternative="Credit Price"
                iconDefault="fal fa-money-bill-alt"
                iconAlternative="far fa-credit-card-front"
                useAlternative={!this.props.posStore.cashPrice}
                onClick={() => this.props.posStore.toggleCashPrice()}
                className={this.props.posStore.buyMode ? undefined : "hidden"}
                title="Show prices for cash or store credit"
              />
              <NavButton
                onClick={() => {
                  this.createNewCart();
                  this.closeMenu();
                }}
                title="Create a cart"
                icon="far fa-cart-plus"
                segmentEventName={NEW_CART_CLICKED}
              >
                New Cart
              </NavButton>
              <NavToggleButton
                labelDefault="All Products"
                labelAlternative="Excluding Singles"
                iconDefault="far fa-search-plus"
                iconAlternative="far fa-search-plus"
                useAlternative={!this.props.posStore.includeSingles}
                onClick={() => this.toggleSingles()}
                title="Display all products or exclude singles?"
                segmentEventName={
                  this.props.posStore.includeSingles
                    ? EXCLUDING_SINGLES_CLICKED
                    : ALL_PRODUCTS_SEARCH_CLICKED
                }
              />
              <NavToggleButton
                labelDefault="In stock only"
                labelAlternative="In stock only"
                iconDefault="far fa-square"
                iconAlternative="far fa-check-square"
                useAlternative={inStockOnly}
                onClick={() => this.toggleInStockOnly()}
                title="Hide out of stock products"
                segmentEventName={IN_STOCK_ONLY_TOGGLED}
                segmentEventProperties={{ in_stock_only: inStockOnly }}
              />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="Navbar">
        <div className="Navbar__posMenu">
          <div
            className={
              this.props.menuState
                ? "Navbar__mobileMenu show"
                : "Navbar__mobileMenu"
            }
          >
            <NavButton
              title="Open or close a till"
              onClick={() => {
                this.toggleFloatModal();
                this.closeMenu();
              }}
              icon="far fa-power-off"
              segmentEventName={OPEN_CLOSE_TILL_CLICKED}
            >
              Open/Close
            </NavButton>
            <NavButton
              onClick={() => {
                this.createNewCart();
                this.closeMenu();
              }}
              title="Create a cart"
              icon="far fa-cart-plus"
              segmentEventName={NEW_CART_CLICKED}
            >
              New
            </NavButton>
            <NavButton
              onClick={() => {
                this.props.posStore.openCartModal();
                this.closeMenu();
              }}
              title="Load a previous cart"
              icon="far fa-file-download"
              segmentEventName={LOAD_CART_CLICKED}
            >
              Load
            </NavButton>
            <NavButton
              onClick={() => {
                this.saveCart();
                this.closeMenu();
              }}
              title="Save your current cart"
              icon="far fa-file-upload"
              segmentEventName={SAVE_CART_CLICKED}
            >
              Save
            </NavButton>
            <NavButton
              onClick={() => {
                this.props.posStore.openOrderModal();
                this.closeMenu();
              }}
              title="View history and do refunds"
              icon="far fa-history"
              segmentEventName={HISTORY_AND_REFUNDS_CLICKED}
            >
              History & Refunds
            </NavButton>
            <NavToggleButton
              labelDefault="All Products"
              labelAlternative="Excluding Singles"
              iconDefault="far fa-search-plus"
              iconAlternative="far fa-search-plus"
              useAlternative={!this.props.posStore.includeSingles}
              onClick={() => this.toggleSingles()}
              title="Display all products or exclude singles?"
              segmentEventName={
                this.props.posStore.includeSingles
                  ? EXCLUDING_SINGLES_CLICKED
                  : ALL_PRODUCTS_SEARCH_CLICKED
              }
            />
            <NavToggleButton
              labelDefault="Cash Price"
              labelAlternative="Credit Price"
              iconDefault="fal fa-money-bill-alt"
              iconAlternative="far fa-credit-card-front"
              useAlternative={!this.props.posStore.cashPrice}
              onClick={() => this.props.posStore.toggleCashPrice()}
              className={this.props.posStore.buyMode ? undefined : "hidden"}
              title="Show prices for cash or store credit"
              segmentEventName={CREDIT_PRICE_TOGGLED}
              segmentEventProperties={{
                credit_prices: !this.props.posStore.cashPrice,
              }}
            />
            <NavToggleButton
              labelDefault="In stock only"
              labelAlternative="In stock only"
              iconDefault="far fa-square"
              iconAlternative="far fa-check-square"
              useAlternative={inStockOnly}
              onClick={() => this.toggleInStockOnly()}
              title="Hide out of stock products"
              segmentEventName={IN_STOCK_ONLY_TOGGLED}
              segmentEventProperties={{ in_stock_only: inStockOnly }}
            />
            <AdaBotNavbarButton />
            <NavButton
              onClick={() => {
                this.props.history.push("/dashboard");
                this.closeMenu();
              }}
              title="Exit the Point of Sale"
              icon="far fa-times"
              segmentEventName={EXIT_POS_CLICKED}
            >
              EXIT
            </NavButton>
          </div>
          <CustomerSelect />
        </div>
      </div>
    );
  }
}

export default withRouter(Navbar);
