import { SortType } from "rsuite-table";
import { SortOrder } from "../../generated/graphql";

export const getSortOptions = (dataKey: string, order: SortType) => {
  const sortOrder = order === "asc" ? SortOrder.Asc : SortOrder.Desc;
  if (dataKey === "customer") {
    return [{ ShopifyCustomer: { firstName: sortOrder, lastName: sortOrder } }];
  }
  if (dataKey === "ShopifyCustomer.email") {
    return [{ ShopifyCustomer: { email: sortOrder } }];
  }
  if (dataKey === "dateSubmitted") {
    return [{ dateSubmitted: sortOrder }];
  }
  if (dataKey === "dateCompleted") {
    return [{ dateCompleted: sortOrder }];
  }
  if (dataKey === "dateApproved") {
    return [{ dateApproved: sortOrder }];
  }
  if (dataKey === "paymentType") {
    return [{ paymentType: sortOrder }];
  }
  return [{ id: sortOrder }];
};

const isSet = (value: string) => value !== undefined && value !== "";

type BuylistFilters = Record<string, string>;

export const buylistStatusMap = {
  pending: {
    name: "pending",
    searchFields: {
      approved: { equals: false },
      completed: { equals: false },
      dateSubmitted: { not: null },
    },
  },
  approved: {
    name: "approved",
    searchFields: {
      approved: { equals: true },
      completed: { equals: false },
    },
  },
  completed: {
    name: "completed",
    searchFields: {
      completed: { equals: true },
    },
  },
};

export const getFilterOptions = (buylistFilters: BuylistFilters) => {
  const filters = {};
  if (!buylistFilters) {
    return filters;
  }
  if (isSet(buylistFilters.id)) {
    filters["id"] = { equals: buylistFilters.id };
  }
  if (isSet(buylistFilters.paymentType)) {
    filters["paymentType"] = { equals: buylistFilters.paymentType };
  }
  if (
    isSet(buylistFilters.submittedStartDate) ||
    isSet(buylistFilters.submittedEndDate)
  ) {
    const dateSubmittedFilter = {};
    if (isSet(buylistFilters.submittedStartDate)) {
      dateSubmittedFilter["gte"] = buylistFilters.submittedStartDate;
    }
    if (isSet(buylistFilters.submittedEndDate)) {
      dateSubmittedFilter["lte"] = buylistFilters.submittedEndDate;
    }
    filters["dateSubmitted"] = dateSubmittedFilter;
  }
  if (
    isSet(buylistFilters.completedStartDate) ||
    isSet(buylistFilters.completedEndDate)
  ) {
    const dateCompletedFilter = {};
    if (isSet(buylistFilters.completedStartDate)) {
      dateCompletedFilter["gte"] = buylistFilters.completedStartDate;
    }
    if (isSet(buylistFilters.completedEndDate)) {
      dateCompletedFilter["lte"] = buylistFilters.completedEndDate;
    }
    filters["dateCompleted"] = dateCompletedFilter;
  }
  if (
    isSet(buylistFilters.approvedStartDate) ||
    isSet(buylistFilters.approvedEndDate)
  ) {
    const dateApprovedFilter = {};
    if (isSet(buylistFilters.approvedStartDate)) {
      dateApprovedFilter["gte"] = buylistFilters.approvedStartDate;
    }
    if (isSet(buylistFilters.approvedEndDate)) {
      dateApprovedFilter["lte"] = buylistFilters.approvedEndDate;
    }
    filters["dateApproved"] = dateApprovedFilter;
  }
  if (isSet(buylistFilters.customer) || isSet(buylistFilters.email)) {
    const customerFilters = {};
    if (isSet(buylistFilters.email)) {
      customerFilters["email"] = { contains: buylistFilters.email };
    }
    if (isSet(buylistFilters.customer)) {
      if (buylistFilters.customer.includes(" ")) {
        const [firstName, lastName] = buylistFilters.customer.split(" ");
        customerFilters["AND"] = [
          { firstName: { contains: firstName } },
          { lastName: { contains: lastName } },
        ];
      } else {
        customerFilters["OR"] = [
          { firstName: { contains: buylistFilters.customer } },
          { lastName: { contains: buylistFilters.customer } },
        ];
      }
    }
    filters["ShopifyCustomer"] = { is: { ...customerFilters } };
  }
  return filters;
};
