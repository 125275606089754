import React, { useEffect, useRef, useState } from "react";
import { Loader, ButtonComponent } from "../../components";
import ActionButtonsLayout from "../../components/layout/ActionButtonsLayout";
import GridLayout from "../../components/layout/GridLayout";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";
import { AddTill, TillListFilters } from "../../components/posSettings";
import { segmentAnalytics } from "../../components/services/Analytics";
import { ADD_NEW_TILL_CLICKED } from "../../constants/eventsTracked";
import { DEFAULT_TILLS_PER_PAGE } from "../../constants/pos";
import { notify } from "../../helpers/notificationHelpers";
import { useShowError } from "../../hooks/errorHooks";
import { useDebounce } from "../../hooks/inputHooks";
import {
  useAddNewTill,
  useFetchTills,
  AddNewTillParams,
} from "../../hooks/tillHooks";
import useTitle from "../../hooks/useTitle";
import { POSMenuStructure } from "../../menuStructures";
import SetActiveMenu from "../../menuStructures/SetActiveMenu";
import { TillFilters } from "../../types/pos";
import TillComponent from "./Till";

const getTillListParams = (tillFilters: TillFilters, showDisabled = false) => ({
  offset: 0,
  limit: DEFAULT_TILLS_PER_PAGE,
  showDisabled,
  // The line at the end removes parameters where a value is not set
  ...Object.fromEntries(
    Object.entries(tillFilters).filter(([, value]) => !!value)
  ),
});

function TillList() {
  const [addNewTillModalVisible, setAddNewTillModalVisible] = useState(false);
  const [showDisabled, setShowDisabled] = useState(false);
  const [tillFilters, setTillFilters] = useState<TillFilters>({});
  const debouncedTillFilters = useDebounce(tillFilters, 1000);
  const showError = useShowError();
  useTitle("Point of Sale Tills");
  const listRef = useRef<HTMLButtonElement>();
  const modalRef = useRef<HTMLDivElement>();
  const {
    data: tills,
    isLoading,
    error: fetchTillsError,
  } = useFetchTills(getTillListParams(debouncedTillFilters, showDisabled));
  const { mutateAsync: addTill, isMutating: isAddingNewTill } = useAddNewTill();

  const resetTillFilters = () => setTillFilters({});

  const updateTillFilter = <K extends keyof TillFilters>(
    parameter: K,
    value: TillFilters[K]
  ) =>
    setTillFilters((prev) => ({
      ...prev,
      [parameter]: value,
    }));

  const addNewTill = (settings: AddNewTillParams) => {
    addTill(settings)
      .then(() => {
        setAddNewTillModalVisible(false);
        notify.info(`Till ${settings.name} created`);
      })
      .catch((error) => {
        showError(
          error,
          "Failed to create till",
          "There was an error submitting your new till. Please try again"
        );
      });
  };

  useEffect(() => {
    if (fetchTillsError) {
      showError(
        fetchTillsError as DetailedError,
        "Failed to load tills",
        "There was an error retrieving your till list. Please refresh and try again"
      );
    }
  }, [fetchTillsError]);

  return (
    <>
      <SetActiveMenu menuStructure={POSMenuStructure} />
      <SectionHeaderLayout title="Tills / Registers">
        <ActionButtonsLayout>
          <ButtonComponent
            primary
            onClick={(event) => {
              listRef.current = event.currentTarget;
              setAddNewTillModalVisible(true);
              segmentAnalytics.track(ADD_NEW_TILL_CLICKED);
            }}
            icon="fas fa-caret-right"
            iconPosition="right"
          >
            Add new Till / Register
          </ButtonComponent>
        </ActionButtonsLayout>
      </SectionHeaderLayout>
      <p className="tillInfo">
        Feel free to add as many tills as required for your stores needs. Each
        till becomes usable with our POS system and has its own sales ledger.
      </p>
      <TillListFilters
        tillFilters={tillFilters}
        showDisabled={showDisabled}
        setShowDisabled={setShowDisabled}
        resetTillFilters={resetTillFilters}
        updateTillFilter={updateTillFilter}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <GridLayout>
          {(tills || []).map((till) => (
            <TillComponent key={till.id} till={till} />
          ))}
        </GridLayout>
      )}
      {addNewTillModalVisible ? (
        <AddTill
          isLoading={isAddingNewTill}
          addNewTill={addNewTill}
          setAddNewTillModalVisible={setAddNewTillModalVisible}
          ref={modalRef}
        />
      ) : null}
    </>
  );
}

export default TillList;
