import React, { MutableRefObject } from "react";
import { Game, Setting } from "../../api/rest/integrations";
import { useFormatCurrency } from "../../hooks/storeHooks";
import Modal from "../generic/Modal";
import ViewEbaySettings from "./ViewEbaySettings";
import "./ViewSettingModal.scss";

interface ViewSettingModalProps {
  visible?: boolean;
  setting: Setting;
  games: Game[];
  integration: string;
  integrationSyncFriendlyName: string;
  handleClose: () => void;
}

function ViewSettingModal(
  props: ViewSettingModalProps,
  ref: MutableRefObject<any>
) {
  const {
    visible = false,
    games,
    setting,
    integration,
    integrationSyncFriendlyName,
    handleClose,
  } = props;
  const formatCurrency = useFormatCurrency();

  if (!visible) return null;

  const gameName = games.find((game) => game.gameId === setting.game)?.gameName;

  return (
    <Modal onClose={handleClose} ref={ref}>
      <Modal.Header>View setting</Modal.Header>
      <Modal.Content>
        <div className="ViewIntegrationSettingModal">
          {gameName ? (
            <>
              <span className="label">Game</span>
              <span className="value">{gameName}</span>
              <span className="label">Set</span>
              <span className="value">{setting.setName || "All Sets"}</span>
            </>
          ) : null}
          {setting?.productType ? (
            <>
              <span className="label">Product</span>
              <span className="value">{setting.productType}</span>
            </>
          ) : null}
          <span className="label">Sync enabled</span>
          <span className="value">
            {setting.syncEnabled ? "Enabled" : "Disabled"}
          </span>
          <span className="label">Price markup percentage</span>
          <span className="value">
            {Number.isFinite(setting.priceMarkup)
              ? `${Number(setting.priceMarkup) * 100}%`
              : null}
          </span>
          <span className="label">Reserve Stock</span>
          <span className="value">{setting.reserveStock}</span>
          <span className="label">
            Maximum to list on {integrationSyncFriendlyName}
          </span>
          <span className="value">{setting.maxToList}</span>
          <span className="label">Minimum Price</span>
          <span className="value">
            {Number.isFinite(setting.minPrice)
              ? formatCurrency(setting.minPrice)
              : null}
          </span>
          <span className="label">Maximum Price</span>
          <span className="value">
            {Number.isFinite(setting.maxPrice)
              ? formatCurrency(setting.maxPrice)
              : null}
          </span>
          {integration === "ebay" ? (
            <ViewEbaySettings setting={setting} />
          ) : null}
        </div>
      </Modal.Content>
    </Modal>
  );
}

export default React.forwardRef(ViewSettingModal);
