import React, { useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import "./ReceiptOptionsContainer.scss";
import ReceiptComponent from "./ReceiptComponent";
import ButtonComponent from "./ButtonComponent";
import Loader from "./Loader";
import Modal from "./Modal";
import { ReceiptLanguage, ReceiptSettings } from "../../types/settings";
import { useFetchCartById } from "../../hooks/useFetchCartById";
import { STORE_SETTINGS_KEY_RECEIPT } from "../../constants/settings";
import { CartWithTotals, ShopifyCustomer, StoreInfo } from "../../types/order";

interface ReceiptOptionsContainerProps {
  title: string;
  cartId: number;
  isModalOpen: boolean;
  closeReceipt: () => void;
  completedReceipt: boolean;
  changeDue?: string;
  giftReceipt?: boolean;
  initialValues?: {
    storeInfo?: StoreInfo;
    selectedCustomer?: ShopifyCustomer;
    customerSettings?: CustomerSettings;
    cart?: CartWithTotals;
  };
}

enum LanguageMode {
  Single,
  Dual,
}

const getLanguageMode = (receiptOptions: ReceiptSettings): LanguageMode => {
  if (
    receiptOptions &&
    receiptOptions.defaultLanguageLabel &&
    receiptOptions.additionalLanguageLabel &&
    receiptOptions.additionalLanguageFields
  ) {
    return LanguageMode.Dual;
  }
  return LanguageMode.Single;
};

const getReceiptSettings = (customerSettings: CustomerSettings) => {
  const value = customerSettings?.[STORE_SETTINGS_KEY_RECEIPT];
  return value ? JSON.parse(value) || undefined : undefined;
};

const ReceiptOptionsContainer = ({
  cartId,
  changeDue,
  closeReceipt,
  completedReceipt,
  giftReceipt,
  title,
  initialValues,
}: ReceiptOptionsContainerProps) => {
  const [selectedLanguage, setSelectedLanguage] = useState<ReceiptLanguage>(
    ReceiptLanguage.First
  );
  const shouldLoadCart =
    !initialValues.cart ||
    !initialValues.customerSettings ||
    !initialValues.selectedCustomer === undefined;
  const printRef = useRef(null);
  const { data: cartData, isLoading } = useFetchCartById(cartId, {
    enabled: shouldLoadCart && cartId > 0,
    refetchOnWindowFocus: false,
    staleTime: 1_000,
  });

  const receiptSettings = getReceiptSettings(
    initialValues?.customerSettings || cartData?.customerSettings
  );
  const languageMode = getLanguageMode(receiptSettings);

  const toggleLanguage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event?.currentTarget;
    setSelectedLanguage(
      value === ReceiptLanguage.Second
        ? ReceiptLanguage.Second
        : ReceiptLanguage.First
    );
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Modal
      onClose={closeReceipt}
      isLoading={isLoading}
      className="receipt-modal"
    >
      <Modal.Header as="h1">
        {title}
        {languageMode === LanguageMode.Dual ? (
          <div className="receipt-options__select-language">
            <label htmlFor="selectLanguage">
              <span className="label">Receipt Language: </span>
              <select
                id="selectLanguage"
                data-testid="language-selector"
                defaultValue={selectedLanguage}
                onChange={toggleLanguage}
              >
                <option value={ReceiptLanguage.First}>
                  {receiptSettings.defaultLanguageLabel}
                </option>
                <option value={ReceiptLanguage.Second}>
                  {receiptSettings.additionalLanguageLabel}
                </option>
              </select>
            </label>
          </div>
        ) : null}
      </Modal.Header>
      <Modal.Content>
        <div className="receipt-options__receipt" ref={printRef}>
          <ReceiptComponent
            receiptLanguage={selectedLanguage}
            receiptSettings={receiptSettings}
            customerSettings={
              cartData?.customerSettings ?? initialValues?.customerSettings
            }
            selectedCustomer={
              cartData?.selectedCustomer ?? initialValues?.selectedCustomer
            }
            completedReceipt={completedReceipt}
            cart={cartData?.cart ?? initialValues?.cart}
            changeDue={changeDue}
            giftReceipt={giftReceipt}
          />
        </div>
      </Modal.Content>
      <Modal.Actions>
        <ReactToPrint
          bodyClass="printed-receipt"
          trigger={() => <ButtonComponent primary>Print</ButtonComponent>}
          content={() => printRef.current}
        />
      </Modal.Actions>
    </Modal>
  );
};

ReceiptOptionsContainer.defaultProps = {
  changeDue: undefined,
  isGiftReceipt: false,
  initialValues: {
    storeInfo: undefined,
    receiptSettings: undefined,
    selectedCustomer: undefined,
    customerSettings: undefined,
    cart: undefined,
  },
};

export default ReceiptOptionsContainer;
