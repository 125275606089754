import React from "react";
import { inject } from "mobx-react";
import { useFeatureFlag } from "../../hooks/globalSettingsHooks";
import { POS_CART_IDLE_CHECK } from "../../constants/featureFlags";

function CartIdleCheck() {
  const cartIdleCheckEnabled = useFeatureFlag(POS_CART_IDLE_CHECK);
  if (!cartIdleCheckEnabled) {
    return null;
  }
  return <CartIdleCheckEnable />;
}

interface CartIdleCheckEnableProps {
  posStore?: {
    setCartIdleCheckActive: (active: boolean) => void;
  };
}

@inject("posStore")
class CartIdleCheckEnable extends React.Component<CartIdleCheckEnableProps> {
  componentDidMount() {
    this.props.posStore.setCartIdleCheckActive(true);
  }

  render() {
    return null;
  }
}

export default CartIdleCheck;
