import { _fetch } from "../api";
import Big from "big.js";
import { BASE_URL } from "../constants/api";
import Dexie from "dexie";
import "firebase/auth";
import { action, computed, observable, runInAction } from "mobx";
import Cookie from "mobx-cookie";
import Store from "../store/index";
import PAX from "./PAX.js";
import firebase from "firebase/app";
import { notify } from "../helpers/notificationHelpers";
import { isKioskMode } from "../helpers/posModeHelpers";
import { calculateBuyTotal, calculateSaleTotal } from "../utils/pos/cart";
import {
  calculateDiscountAmount,
  calculateDiscountValue,
  getAutomaticDiscounts,
} from "../utils/pos/discount";
import {
  isTaxRateValid,
  getSpecialTaxName,
  determineLineItemTaxRate,
  calculateLineItemTotalTax,
  calculateSaleTaxTotal,
  calculateTaxRates,
  calculateDiscountTaxAmount,
  calculateNegatedTaxTotal,
  calculateTaxTotal,
} from "../utils/pos/tax";
import { formatCurrency, roundCents } from "../utils/currencyHelpers";
import { fetchSearchResults, fetchProductSearchResults } from "../api/rest/pos";
import { fetchCustomerTillSetting } from "../api/rest/settings";
import Worker from "../binder.worker.js";
import {
  getCartById,
  getLatestCartForTill,
  keepCartActive,
  updateCart,
  submitCart as gqlSubmitCart,
} from "../api/graphql/cart";
import {
  UPDATED_PRODUCT_SEARCH,
  POS_NEW_CART_IDLE_CHECK,
  GQL_SUBMIT_CART,
  GQL_UPDATE_CART,
} from "../constants/featureFlags";
import PaymentTerminal from "../services/paymentTerminal";
import { logError } from "../helpers/loggingHelpers";
import { calculateCartTenders } from "../utils/pos/tenders";

window.Big = Big;

const CART_IDLE_TIMEOUT = 25 * 60; // seconds

class Item {
  @observable id;
  @observable name;
  constructor(name, image) {
    this.name = name;
    this.id = Date.now();
    this.image = image;
  }
}

class Register {}

class LineItem {
  @observable title;
  @observable buyItem;
  @observable qty;
  @observable actualPrice;
  @observable shopifyPrice;
  @observable variantTitle;
  @observable productId;
  @observable variantId;
  @observable lineId;
  @observable imageUrl;
  @observable discountAmount = 0;
  @observable discountType = "percentage";
  @observable taxSetting;
  @observable tags;
  taxDisabledShopify;
  taxDisabledUI;
  cartType;

  @computed get tax() {
    return calculateLineItemTotalTax(this);
  }

  @computed get lineTotal() {
    return this.qty * this.tax.itemPrice;
  }

  @computed get displayTotal() {
    return this.qty * this.tax.displayPrice;
  }

  @computed get lineTaxTotal() {
    return this.qty * this.tax.itemTax;
  }

  @computed get itemTaxRate() {
    return determineLineItemTaxRate(this.tags, this.taxSetting.taxRate);
  }

  @computed get specialTax() {
    return getSpecialTaxName(this.tags);
  }

  @computed get discountValue() {
    return calculateDiscountValue(this);
  }

  @computed get price() {
    return this.actualPrice + this.discountValue / this.qty;
  }

  constructor(
    product,
    buyMode,
    cashPrice,
    taxSetting,
    eventAdditionalInfo,
    cartType = null
  ) {
    this.tags = product.tags;
    this.taxSetting = taxSetting;
    this.cartType = cartType;
    //if we are creating the line item by adding within POS

    if (!Object.prototype.hasOwnProperty.call(product, "buying")) {
      var index = buyMode
        ? product.selectedBuyVariant
        : product.selectedVariant;
      this.buyItem = false;
      this.title = product.title;
      this.qty = 1;
      this.actualPrice = product.variants[index].price;
      this.shopifyPrice = product.variants[index].price;
      this.cashBuyPrice = product.variants[index].cashBuyPrice;
      this.creditBuyPrice = product.variants[index].storeCreditBuyPrice;
      this.variantTitle = product.variants[index].title;
      this.productId = product.id;
      this.variantId = product.variants[index].id;
      this.imageUrl = product.img;
      this.eventAdditionalInfo = eventAdditionalInfo;
      this.taxDisabledShopify =
        product.variants[index].taxable == null
          ? false
          : !product.variants[index].taxable;
      this.taxDisabledUI = false;
      // Grab the defualt discounts and set them if not in buy mode
      const { amount, type } = getAutomaticDiscounts(product.tags);
      this.discountAmount = (buyMode ? null : amount) || product.discountAmount;
      this.discountType = (buyMode ? null : type) || product.discountType;
      if (buyMode) {
        this.buyItem = true;
        this.actualPrice = cashPrice
          ? product.variants[index].cashBuyPrice * -1
          : product.variants[index].storeCreditBuyPrice * -1;
        this.variantId = product.variants[index].id;
      }
      // If we are creating the line item from server response ie cart validation
    } else {
      this.buyItem = product.buying;
      this.title = product.productTitle;
      this.lineId = product.id;
      this.qty = product.quantity;
      this.productId = product.id;
      this.variantId = product.variantId;
      this.variantTitle = product.variantTitle;
      this.imageUrl = product.imageSrc;
      this.taxable = product.taxable;
      this.taxDisabledShopify =
        product.shopifyTaxable == null ? false : !product.shopifyTaxable;
      this.taxDisabledUI = product.taxable == null ? false : !product.taxable;
      this.eventAdditionalInfo = eventAdditionalInfo;
      this.shopifyPrice = product.shopifyPrice;
      this.actualPrice = product.actualPrice;
      this.discountAmount = product.discountAmount;
      this.discountType = product.discountType;
    }
  }
}

class CustomLineItem {
  @observable title;
  @observable buyItem;
  @observable qty;
  @observable actualPrice;
  @observable cashBuyPrice;
  @observable creditBuyPrice;
  @observable variantTitle;
  @observable productId;
  @observable variantId;
  @observable lineId;
  @observable imageUrl;
  @observable taxSetting;
  @observable discountAmount = 0;
  @observable discountType = "percentage";

  @computed get tax() {
    return calculateLineItemTotalTax(this);
  }

  @computed get lineTotal() {
    return this.qty * this.tax.itemPrice;
  }

  @computed get displayTotal() {
    return this.qty * this.tax.displayPrice;
  }
  @computed get lineTaxTotal() {
    return this.qty * this.tax.itemTax;
  }

  @computed get itemTaxRate() {
    return this.taxSetting.taxRate;
  }

  @computed get specialTax() {
    return false;
  }

  @computed get discountValue() {
    return calculateDiscountValue(this);
  }

  @computed get price() {
    return this.actualPrice + this.discountValue;
  }

  constructor(custom, buyMode, taxSetting, cartType) {
    this.taxSetting = taxSetting;
    this.cartType = cartType;
    this.buyItem = false;
    this.title = custom.name;
    this.qty = 1;
    if (custom.actualPrice < 0) {
      this.actualPrice = custom.actualPrice * -1;
      this.cashBuyPrice = custom.actualPrice * -1;
      this.creditBuyPrice = custom.actualPrice * -1;
    } else {
      this.actualPrice = custom.actualPrice;
      this.cashBuyPrice = custom.actualPrice;
      this.creditBuyPrice = custom.actualPrice;
    }

    this.variantTitle = "-";
    this.productId = null;
    this.variantId = null;
    this.imageUrl = "";
    this.currencySymbol = "$";
    this.taxable = true;
    if (buyMode) {
      this.buyItem = true;
      if (custom.actualPrice > 0) {
        this.actualPrice = custom.actualPrice * -1;
      } else {
        this.actualPrice = custom.actualPrice;
      }
      this.variantId = null;
    }
  }
}
/**
 * ItemList contains two arrays one for items in the
 * results grid and another for items in the cart. This
 * is just for messing around and will be restructured.
 */
class ItemList {
  constructor() {
    this.cartIdleInterval = null;
    this.cartLastUpdated = Date.now();
    this.paymentTerminal = new PaymentTerminal();
  }

  isFeatureFlagEnabled(featureFlag) {
    return this.featureFlags[featureFlag] === "on";
  }

  @observable featureFlags = {};
  @action
  async setFeatureFlags(flags) {
    this.featureFlags = flags;
  }

  @observable error;

  @action setError(error, fallbackTitle = null, fallbackMessage = null) {
    if (error?.error || !fallbackTitle) {
      this.error = error;
    } else {
      this.error = {
        error: fallbackTitle,
        detailedMessage: fallbackMessage,
      };
    }
  }

  @observable posActive;

  @action setPosActive = (isActive) => {
    this.posActive = isActive;
    if (isActive) {
      this.startCartIdleInterval();
    } else {
      // Clear cart idle timer when exiting pos
      this.clearCartIdleInterval();
    }
  };

  @observable cartIdleCheckActive = false;
  @action setCartIdleCheckActive = (cartIdleCheckActive) =>
    (this.cartIdleCheckActive = cartIdleCheckActive);

  startCartIdleInterval = () => {
    if (this.cartIdleCheckActive && !this.cartIdleInterval) {
      this.cartIdleInterval = setInterval(this.handleRefreshCart, 60 * 1000);
    }
  };

  clearCartIdleInterval = () => {
    if (this.cartIdleInterval) {
      clearInterval(this.cartIdleInterval);
    }
    this.cartIdleInterval = null;
  };

  updateCartLastUpdated = () => {
    this.cartLastUpdated = Date.now();
  };

  handleRefreshCart = () => {
    const elapsedSinceLastUpdate = Date.now() - this.cartLastUpdated;
    const isNewCartIdleCheckEnabled = this.isFeatureFlagEnabled(
      POS_NEW_CART_IDLE_CHECK
    );
    if (elapsedSinceLastUpdate > CART_IDLE_TIMEOUT * 1000) {
      try {
        isNewCartIdleCheckEnabled
          ? keepCartActive(Number(this.cartId))
          : this.validateCartNoRefresh();
      } catch (error) {
        logError(error, {
          function: "handleRefreshCart",
          tillId: this.tillId,
          cartId: this.cartId,
        });
      }
    }
  };

  @observable items = [];
  @action clearSearchItems = () => {
    this.items = [];
  };
  @observable cart = [];
  @action setCart = (input) => {
    this.cart = input;
  };

  @observable availableQuantities = {};

  @action resetAvailableQuantities = () => {
    this.availableQuantities = {};
  };

  @action addAvailableQuantity = (variant) => {
    if (variant.quantity && !(variant.id in this.availableQuantities)) {
      this.availableQuantities[variant.id] = variant.quantity;
    }
  };

  @action removeAvailableQuantity = (item) => {
    if (item.variantId in this.availableQuantities) {
      delete this.availableQuantities[item.variantId];
    }
  };

  getAvailableQuantity = (item) => {
    if (item.variantId in this.availableQuantities) {
      return this.availableQuantities[item.variantId];
    }
    return Number.MAX_SAFE_INTEGER;
  };

  @observable cartItemBuyLimit = {};

  @action resetCartItemBuyLimit = () => (this.cartItemBuyLimit = {});

  @action addCartItemBuyLimit = (variant) => {
    if (variant.id in this.cartItemBuyLimit) {
      return;
    }
    const {
      quantity: initialQuantity,
      maxPurchaseQuantity,
      overtStockBuyPrice,
      creditOverstockBuyPrice,
    } = variant;

    this.cartItemBuyLimit[variant.id] = {
      initialQuantity,
      maxPurchaseQuantity,
      cashOverstockBuyPrice: overtStockBuyPrice,
      creditOverstockBuyPrice: creditOverstockBuyPrice,
    };
  };

  @action removeCartItemBuyLimit = (item) => {
    if (item.variantId in this.cartItemBuyLimit) {
      delete this.cartItemBuyLimit[item.variantId];
    }
  };

  getCartItemBuyLimit = (variantId) => {
    return this.cartItemBuyLimit[variantId];
  };

  @observable customItem = { name: "", actualPrice: 0, qty: 1 };
  @observable showCustomItemComponent = false;
  @action toggleCustomItem = () => {
    this.showCustomItemComponent = !this.showCustomItemComponent;
  };

  @observable fetchingSearch = false;
  @observable searchNumber = 0;
  @observable timer;

  @action setTimer(timer) {
    this.timer = timer;
  }

  @observable searchTerm = "";
  @action setSearchTerm(term) {
    this.searchTerm = term;
    this.db.products
      .where("title")
      .startsWith(term.toLowerCase())
      .limit(10)
      .toArray()
      .then((e) => e.map((t) => t.title))
      .then((l) => this.setSearchSuggestions(l));
  }

  @action
  refreshSearch = () => {
    if (this.searchTerm) {
      this.loaderOn();
      const isProductSearchFeatureEnabled = this.isFeatureFlagEnabled(
        UPDATED_PRODUCT_SEARCH
      );
      const resultFn = isProductSearchFeatureEnabled
        ? fetchProductSearchResults
        : fetchSearchResults;
      return resultFn(
        this.searchTerm,
        this.includeSingles,
        this.buyMode,
        this.searchOffset,
        this.searchLimit,
        this.inStockOnly
      )
        .then((data) => {
          this.loaderOff();
          this.emptyList();
          if (data[0]) {
            data.map((item) => this.addItem(item));
          }
        })
        .catch((error) => {
          logError(error, {
            function: "refreshSearch",
            tillId: this.tillId,
            searchTerm: this.searchTerm,
            includeSingles: this.includeSingles,
            buyMode: this.buyMode,
            searchOffset: this.searchOffset,
            searchLimit: this.searchLimit,
            inStockOnly: this.inStockOnly,
          });
          this.loaderOff();
        });
    }
  };

  @observable waitingToSearch = false;
  @action setWaitingToSearch(value) {
    this.waitingToSearch = !!value;
  }
  @observable
  searchSuggestions = [];

  @observable cartNotes = "";

  @action
  setCartNotes = (notes) => {
    this.cartNotes = notes;
  };

  @observable customFields = [];
  @action setCustomFields(fields) {
    this.customFields = fields;
  }

  getCustomFields = async () => {
    const customFields = await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/settings/customFields/forMe`,
    });
    this.setCustomFields(JSON.parse(customFields.settingValue));
  };

  @observable cartName = "";
  @action setCartName = (cartName) => (this.cartName = cartName);

  @action
  setSearchSuggestions = (value) => {
    this.searchSuggestions = value;
  };
  @observable suggestCookie = new Cookie("searchSuggest");

  @computed get suggestEnabled() {
    if ((this.suggestCookie.value == "false") | !this.suggestCookie.value) {
      return false;
    }
    return true;
  }

  @action toggleSuggest = () => {
    this.suggestCookie.set(!this.suggestEnabled, { expires: 365 });
  };

  @observable
  searchFocused;

  @action
  setSearchFocused = () => {
    this.searchFocused = true;
  };
  @action
  setSearchBlurred = async () => {
    this.searchFocused = false;
  };

  searchLimit = 50;

  @observable searchOffset = 0;
  @action setSearchOffset = (searchOffset) =>
    (this.searchOffset = searchOffset);

  @observable moreResultsAvailable = false;
  @action setMoreResultsAvailable = (moreResultsAvailable) =>
    (this.moreResultsAvailable = moreResultsAvailable);

  setSearchBlurredDelayed = async () => {
    await this.sleep(300);
    this.setSearchBlurred();
  };
  @action
  search = (e) => {
    if (
      this.showNoMatchingBarcodeError ||
      this.showMultipleMatchingBarcodesError
    ) {
      return;
    }
    this.setSearchOffset(0);
    this.setWaitingToSearch(true);
    this.searchNumber++;
    var currentSearch = this.searchNumber;
    this.loaderOff();
    clearTimeout(this.timer);
    var query = e.target.value;
    this.setSearchTerm(query);

    let localTimer = setTimeout(() => {
      if (query) {
        this.setWaitingToSearch(false);
        this.loaderOn();
        const isProductSearchFeatureEnabled = this.isFeatureFlagEnabled(
          UPDATED_PRODUCT_SEARCH
        );
        const resultFn = isProductSearchFeatureEnabled
          ? fetchProductSearchResults
          : fetchSearchResults;
        resultFn(
          this.searchTerm,
          this.includeSingles,
          this.buyMode,
          this.searchOffset,
          this.searchLimit + 1,
          this.inStockOnly
        )
          .then((data) => {
            this.emptyList();
            if (data[0] && currentSearch == this.searchNumber) {
              if (data.length > this.searchLimit) {
                this.setMoreResultsAvailable(true);
              } else {
                this.setMoreResultsAvailable(false);
              }
              data
                .slice(0, this.searchLimit)
                .map((items) => this.addItem(items));
            }
          })
          .catch((error) => {
            logError(error, {
              function: "search",
              tillId: this.tillId,
              searchTerm: this.searchTerm,
              includeSingles: this.includeSingles,
              buyMode: this.buyMode,
              searchOffset: this.searchOffset,
              searchLimit: this.searchLimit + 1,
              inStockOnly: this.inStockOnly,
            });
          })
          .finally(() => this.loaderOff());
      } else {
        this.emptyList();
      }
    }, 650);

    this.setTimer(localTimer);
  };

  @action changeSearchPage = (newOffset) => {
    this.loaderOn();
    this.setSearchOffset(newOffset);
    const isProductSearchFeatureEnabled = this.isFeatureFlagEnabled(
      UPDATED_PRODUCT_SEARCH
    );
    const resultFn = isProductSearchFeatureEnabled
      ? fetchProductSearchResults
      : fetchSearchResults;
    resultFn(
      this.searchTerm,
      this.includeSingles,
      this.buyMode,
      newOffset,
      this.searchLimit + 1,
      this.inStockOnly
    )
      .then((data) => {
        this.loaderOff();
        this.emptyList();
        if (data[0]) {
          if (data.length > this.searchLimit) {
            this.setMoreResultsAvailable(true);
          } else {
            this.setMoreResultsAvailable(false);
          }
          data.slice(0, this.searchLimit).map((items) => this.addItem(items));
        }
      })
      .catch((error) => {
        logError(error, {
          function: "changeSearchPage",
          tillId: this.tillId,
          searchTerm: this.searchTerm,
          includeSingles: this.includeSingles,
          buyMode: this.buyMode,
          newOffset,
          searchLimit: this.searchLimit + 1,
          inStockOnly: this.inStockOnly,
        });
      })
      .finally(() => {
        this.setSearchOffset(newOffset);
        this.loaderOff();
      });
  };

  @observable showOutOfStockWarning = false;
  @action setShowOutOfStockWarning = (showOutOfStockWarning) =>
    (this.showOutOfStockWarning = showOutOfStockWarning);

  @observable outOfStockItem;
  @action setOutOfStockItem = (outOfStockItem) =>
    (this.outOfStockItem = outOfStockItem);

  openCreditMenu = async () => {
    await this.getPaxIP();
    const pax = new PAX("https://" + this.integratedPaySettings.paxIP);
    pax.creditMenu();
    this.paymentTerminal.openMenu();
  };

  @action fetchBarcode = async (event) => {
    if (
      this.showNoMatchingBarcodeError ||
      this.showMultipleMatchingBarcodesError
    ) {
      return;
    }
    var barcode = event.target.value;
    if (event.key !== "Enter") {
      return null;
    }
    if (this.cartLoading) {
      event.target.select();
      return;
    }
    clearTimeout(this.timer);
    event.preventDefault();
    if (barcode == "CreditMenu") {
      this.paymentTerminal.openMenu();
      return this.setSearchTerm("");
    }
    this.loaderOn();
    event.persist();
    const result = await _fetch({
      endpoint:
        `${BASE_URL}/products/byBarcode/${barcode}` +
        (this.buyMode ? "?includeBuyprice=true" : ""),
    });
    if (result.error) {
      notify.warn(result.error);
    }
    if (result.length === 0) {
      if (this.usePosBarcodeErrorModal) {
        this.loaderOff();
        this.setSearchTerm("");
        this.setShowNoMatchingBarcodeError(barcode);
        return;
      } else {
        // No barcode matched, do normal search
        this.search({ target: { value: barcode } });
        event.target.select();
        return;
      }
    }
    this.loaderOff();
    if (result.length === 1) {
      result[0].variants.forEach((variant, index) => {
        if (variant.barcode == barcode || variant.sku == barcode) {
          result[0].selectedVariant = index;
          result[0].selectedBuyVariant = index;
        }
      });
      const selectedVariant = result[0]?.variants?.[result[0]?.selectedVariant];
      if (
        selectedVariant?.quantity <= 0 &&
        this.useBarcodeQuantityCheck &&
        !this.buyMode
      ) {
        this.setOutOfStockItem(result[0]);
        this.setShowOutOfStockWarning(true);
      } else {
        this.addToCart(
          new LineItem(result[0], this.buyMode, null, this.allTax)
        );
      }
    } else {
      if (this.usePosBarcodeErrorModal) {
        return this.setShowMultipleMatchingBarcodesError(result);
      }
      this.emptyList();
      result.forEach((res, resIndex) => {
        res.variants.forEach((variant, variantIndex) => {
          if (variant.barcode == barcode || variant.sku == barcode) {
            result[resIndex].selectedVariant = variantIndex;
            result[resIndex].selectedBuyVariant = variantIndex;
          }
        });
        this.addItem(res);
      });
    }
    return this.setSearchTerm("");
  };

  @action fetchVariantById = async (variantId) => {
    const product = await _fetch({
      endpoint: `${BASE_URL}/products/byVariantId/` + variantId,
    });
    if (product.event?.additionalInfo?.length)
      return this.setAdditionalInfoItem({
        ...product,
        selectedVariant: product.selectedVariant - 1,
      });
    product.variants.map((variant, index) => {
      if (variant.id == variantId) {
        product.selectedVariant = index;
        product.selectedBuyVariant = index;
      }
    });
    this.addToCart(new LineItem(product, this.buyMode, null, this.allTax));
  };

  @action loaderOn() {
    this.fetchingSearch = true;
    this.updateCartLastUpdated();
  }
  @action loaderOff() {
    this.fetchingSearch = false;
  }
  @observable cartLoading = false;
  @action cartLoadingOn() {
    this.cartLoading = true;
    this.updateCartLastUpdated();
  }
  @action cartLoadingOff() {
    this.cartLoading = false;
  }
  @observable gettingLatestCart = false;
  @action setGettingLatestCart(getting) {
    this.gettingLatestCart = getting;
    if (getting) this.updateCartLastUpdated();
  }

  @observable errorMessage = "";
  @action setErrorMessage(value, fallback) {
    this.errorMessage = value ?? fallback;
    this.errorTraceId = "";
  }

  @observable errorHeader = "";
  @action setHeaderMessage(value) {
    this.headerMessage = value;
    this.errorTraceId = "";
  }

  @observable cartInvalid = false;
  @action setCartInvalid = (cartInvalid) => (this.cartInvalid = cartInvalid);

  @observable additionalInfoItem;
  @action setAdditionalInfoItem = (additionalInfoItem) => {
    this.additionalInfoItem = additionalInfoItem;
  };

  @observable errorTraceId = "";
  @observable lastTraceId = "";
  @observable externalUrl = "";

  @action setAPIError(json) {
    this.errorHeader = json.error;
    this.errorMessage = json.detailedMessage;
    this.errorTraceId = json.traceId;
    this.lastTraceId = json.traceId;
    this.externalUrl = json.externalUrl;
  }

  @observable floatOpenAmount = "0.00";

  @action setFloatOpenAmount = (value) => (this.floatOpenAmount = value);

  @observable deleteModal = null;
  @action setDeleteModal = (id) => {
    this.deleteModal = id;
  };

  /**
   * This boolean tracks wether the POS is in buy or sell mode
   */
  @observable buyMode = false;
  @action toggleBuyMode = () => {
    this.buyMode = !this.buyMode;
    this.buyMode
      ? notify.info("Switched mode: store buys items")
      : notify.info("Switched mode: store sells items");
    this.refreshSearch();
  };
  @observable cashPrice = false;
  @action toggleCashPrice = () => {
    this.cashPrice = !this.cashPrice;
    this.cashPrice
      ? notify.info("Switched to cash pricing")
      : notify.info("Switched to credit pricing");
  };
  @observable floatModal = false;
  @action closeFloatModal() {
    this.floatModal = false;
  }

  @observable openTillModalVisible = false;
  @action setOpenTillModalVisible = (visible) =>
    (this.openTillModalVisible = visible);

  @observable selectedCustomer = null;
  @action setSelectedCustomer(customer) {
    this.selectedCustomer = customer;
  }
  @observable customerResults = [];
  @observable activeTender = 0;
  @action emptyList() {
    this.items = [];
  }
  @action addItem(item) {
    item.selectedBuyVariant = -1;
    if (item.selectedVariant < 0) {
      item.selectedVariant = -1;
      item.variants.some((variant, index) => {
        if (variant.quantity > 0) {
          item.selectedVariant = index;
          return true;
        }
      });
    }
    this.items.push(item);
  }
  @action
  addCustomerResults(data) {
    this.customerResults = data;
  }

  @observable customerInput = "";
  @action setCustomerInput(input) {
    this.customerInput = input;
  }

  @observable cartId = null;
  @action setCartId = (value) => {
    this.cartId = value;
  };
  @observable includeSingles = true;

  @action toggleSingle = () => {
    this.includeSingles = !this.includeSingles;
  };

  @observable
  inStockOnly = false;

  @action
  toggleInStockOnly = () => {
    this.inStockOnly = !this.inStockOnly;
    notify.info(
      this.inStockOnly
        ? "Your searches now include only in stock products"
        : "Your searches now include out of stock products"
    );
    this.refreshSearch();
  };

  @computed get total() {
    return roundCents(parseFloat(this.subTotal) + parseFloat(this.taxTotal));
  }

  @computed get taxRates() {
    return calculateTaxRates(this);
  }

  @computed get subTotal() {
    return this.saleTotal + this.buyTotal;
  }

  @observable
  globalDiscount = { type: "percentage", amount: "0.00" };

  @computed get discountAmount() {
    return calculateDiscountAmount(this);
  }

  @computed get discountPercentage() {
    return -this.discountAmount / (this.saleTotal + -this.discountAmount);
  }

  @computed get discountTaxAmount() {
    return calculateDiscountTaxAmount(this);
  }

  @computed get saleTotal() {
    return calculateSaleTotal(this);
  }

  @computed get buyTotal() {
    return calculateBuyTotal(this);
  }

  @computed get negatedTaxTotal() {
    return calculateNegatedTaxTotal(this);
  }

  @computed get saleTaxTotal() {
    return calculateSaleTaxTotal(this);
  }

  @computed get taxTotal() {
    return calculateTaxTotal(this);
  }

  @computed get totalItems() {
    if (this.cart.length) {
      var tots = this.cart.reduce(
        (reducer, line) => parseInt(reducer) + parseInt(line.qty),
        0
      );

      return tots;
    }
    return 0;
  }

  @action addToCart(item) {
    var newLine = true;
    this.cart.map((lines, index) => {
      if (
        lines.variantId == item.variantId &&
        item.variantId != null &&
        lines.buyItem != item.buyItem
      ) {
        newLine = false;
        lines.buyItem
          ? notify.warn("You are already buying that exact item!")
          : notify.warn("You are already selling that exact item!");
      } else if (lines.variantId == item.variantId && item.variantId != null) {
        this.cart[index].qty++;
        newLine = false;
      } else if (lines.lineId == item.lineId && item.lineId != null) {
        this.cart[index].qty++;
        newLine = false;
      }
    });
    if (newLine) {
      this.cart.push(item);
    }
    this.cartLoadingOn();
    if (this.isFeatureFlagEnabled(GQL_UPDATE_CART)) {
      updateCart(this.cartObject)
        .then((response) => {
          this.cartLoadingOff();
          this.checkCart(response);
        })
        .catch((error) => {
          this.setError(error);
          //this.setCart(oldCart);
          //this.setErrorMessage(error.message, "Unknown Error");
          this.cartLoadingOff();
          this.getCartById(null, this.cartId);
        });
    } else {
      _fetch({
        endpoint: `${BASE_URL}/pos/carts`,
        method: "PUT",
        payload: this.cartObject,
      })
        .then((response) => {
          if (response.error) {
            // this.setCart(oldCart);
            this.cartLoadingOff();
            this.setAPIError(response);
            this.getCartById(null, this.cartId);
          } else {
            this.cartLoadingOff();
            this.checkCart(response);
          }
        })
        .catch((error) => {
          //this.setCart(oldCart);
          this.setErrorMessage(error?.detailedMessage, "Unknown Error");
          this.cartLoadingOff();
          this.getCartById(null, this.cartId);
        });
    }
  }

  @observable isDeletingCartItem;
  @action setIsDeletingCartItem(isDeletingCartItem) {
    this.isDeletingCartItem = isDeletingCartItem;
  }

  /**
   * matches an item by variantId and removes it if there is a match
   * @param {object} item
   */
  @action async removeFromCart(item) {
    this.cartLoadingOn();
    this.removeAvailableQuantity(item);
    this.removeCartItemBuyLimit(item);
    this.cart.map((lines, index) => {
      if (lines.lineId == item.lineId) {
        this.cart.splice(index, 1);
      }
    });
    await this.validateCartNoRefresh();
    this.cartLoadingOff();
  }

  @observable tenders = [{ type: "cash", amount: "0.00" }];

  @observable availableTenders = [];

  @computed get changeDue() {
    const tendersTotal = this.tenders.reduce(
      (acumulator, tender) => acumulator + Number(tender.amount),
      0
    );
    if (tendersTotal > this.total) {
      return (tendersTotal - this.total).toFixed(2);
    }
    return (0).toFixed(2);
  }

  @computed get balance() {
    return roundCents(
      this.total -
        Object.values(this.tenders).reduce(
          (reducer, line) =>
            parseFloat(reducer) +
            parseFloat(line.amount == "" ? 0 : line.amount),
          0
        )
    );
  }

  @action zeroTenders = () => {
    this.tenders.forEach((tend) => {
      tend.amount = "0.00";
    });
  };

  @action addTender = (type, amount) => {
    //check if type already has 0 entry if so update
    const tenderToUpdate = this.tenders.find(
      (t) => t.type.toLowerCase() === type.toLowerCase() && t.amount == 0
    );
    if (tenderToUpdate) {
      tenderToUpdate.amount = amount;
      return;
    }
    this.tenders.push({ type, amount });
  };

  @action removeTender = (index) => {
    const tenders = [...this.tenders];
    tenders.splice(index, 1);
    this.tenders = tenders;
  };

  @observable tillList = [];

  @observable forceTill = false;
  @action setForceTill = (force) => (this.forceTill = force);

  @action setTillList(json) {
    this.tillList = json;
  }

  @action fetchTills() {
    return _fetch({ endpoint: `${BASE_URL}/pos/tills` }).then((json) => {
      if (json) {
        this.setTillList(json);
      }
    });
  }

  @action fetchFloat() {
    if (this.tillId === undefined) {
      return Promise.resolve();
    }
    return _fetch({
      endpoint: `${BASE_URL}/pos/float/byTill/` + this.tillId,
    }).then((json) => this.setFloat(json));
  }

  @action
  adjustFloat = async () => {
    await _fetch({
      endpoint: `${BASE_URL}/pos/float/entry`,
      method: "POST",
      payload: this.adjustObject,
    });

    // we want to clear the settings after the call is made
    notify.info(
      this.adjustObject.float[0].name +
        " has been adjusted by " +
        this.fCurr(this.adjustAmount)
    );
    this.setAdjustAmount("0.00");
    this.updateNote("");
    this.closeFloatModal();
    return true;
  };

  @observable adjustAmount = "";
  @action setAdjustAmount = (amount) => (this.adjustAmount = amount);

  @observable adjustNote = "";
  @action updateNote(input) {
    this.adjustNote = input;
  }

  @observable adjustTender = "";
  @action setAdjustTender = (tenderType) => (this.adjustTender = tenderType);

  @computed get adjustObject() {
    var floatA = {
      name: this.adjustTender ? this.adjustTender : this.tenders[0].type,
      notes: this.adjustNote,
    };
    this.adjustAmount > 0
      ? (floatA.increment = this.adjustAmount)
      : (floatA.decrement = this.adjustAmount * -1);
    return {
      till: this.tillId,
      float: [floatA],
    };
  }

  @observable cookie = new Cookie("register");
  @observable float = {
    status:
      this.cookie.value != undefined &&
      this.cookie.value != -1 &&
      this.cookie.value != "No Till Selected"
        ? "open"
        : "closed",
  };
  @action setFloat = (float) => {
    this.float = float;
    this.tenders = [];
    this.tenderClose = float?.float ? new Array(float.float.length) : [];
    const availableTenders = new Set();
    float?.float?.map((tender) => {
      this.tenders.push({ type: tender.name, amount: 0.0 });
      availableTenders.add(tender.name);
    });
    this.availableTenders = [...availableTenders];
  };

  @computed get tillId() {
    //this.fetchTills();
    return this.cookie.value;
  }

  @computed get tillName() {
    return this.tillList.find((till) => till.id == this.tillId)?.name;
  }

  @observable previousTillId = -1;
  @action setPreviousTillId = (previousTillId) =>
    (this.previousTillId = previousTillId);

  @computed get floatStatus() {
    return this.float.status === "open";
  }

  @action resetTenders = () => {
    this.tenders =
      this.float?.float?.map((tender) => ({ type: tender.name, amount: 0 })) ||
      [];
  };

  @observable showBuyLimitWarnings = false;
  @action setShowBuyLimitWarnings = (showBuyLimitWarnings) =>
    (this.showBuyLimitWarnings = showBuyLimitWarnings);

  loadShowBuyLimitWarningsSetting = async (tillId = this.cookie?.value) => {
    if (Number(tillId) > 0) {
      return fetchCustomerTillSetting("posBuylimitWarning", tillId)
        .then((result) =>
          this.setShowBuyLimitWarnings(result?.settingValue === "true")
        )
        .catch((error) =>
          logError(error, {
            function: "loadShowBuyLimitWarningsSetting",
            tillId: this.tillId,
          })
        );
    } else {
      return Promise.resolve();
    }
  };

  @action setTill = async (value) => {
    if (!value) {
      value = -1;
    }
    this.cookie.set(value, { expires: 365 }); // 1 year expiry
    if (value != -1) {
      await Promise.all([
        this.getTax(),
        this.useNewCartOnPOSOpening ? this.newCart() : this.getLatestCart(),
        this.getQuickLinkData(),
        this.loadShowBuyLimitWarningsSetting(value),
        this.paymentTerminal.changeTill(value),
      ]);
      this.startCartIdleInterval();
    }
  };

  @action submitFloat = async () => {
    if (this.tillId !== -1 && this.tillId !== undefined) {
      try {
        await this.loadExtraPaymentMethods();
        const json = await _fetch({
          endpoint: `${BASE_URL}/pos/float/open`,
          method: "POST",
          payload: this.floatObject,
        });
        this.setFloat(json);
      } catch (e) {
        logError(e, {
          function: "submitFloat",
          tillId: this.tillId,
          payload: JSON.stringify(this.floatObject),
        });
      }
      this.useNewCartOnPOSOpening ? this.newCart() : this.getLatestCart(0);
      this.closeFloatModal();
      this.startCartIdleInterval();
      if (this.forceTill) {
        this.fetchFloat();
      }
    } else {
      notify.info("Please select a valid till");
    }
  };

  @observable closingTill = false;
  @action setClosingTill = (closingTill) => (this.closingTill = closingTill);

  @action closeFloat = () => {
    this.setClosingTill(true);
    _fetch({
      endpoint: `${BASE_URL}/pos/float/byTill/${this.tillId}/close`,
      method: "POST",
      payload: this.floatObject,
    })
      .then((json) => {
        this.unsetTill();
        this.setFloat(json);
        return this.fetchTills();
      })
      .finally(() => {
        this.setClosingTill(false);
        this.closeFloatModal();
        this.clearCartIdleInterval();
        this.closeTillWarning();
      });
  };

  @observable tillWarning = false;

  @action openTillWarning = () => {
    this.tillWarning = true;
  };
  @action closeTillWarning = () => {
    this.tillWarning = false;
  };

  @computed get floatObject() {
    return {
      till: this.tillId,
      float: [
        {
          name: "Cash",
          openingAmount: this.floatOpenAmount,
          closingAmount: this.tenderClose ? this.tenderClose[0] : null,
        },
        {
          name: "Credit",
          openingAmount: 0.0,
          closingAmount: this.tenderClose ? this.tenderClose[1] : null,
        },
        {
          name: "EFTPOS",
          openingAmount: 0.0,
          closingAmount: this.tenderClose ? this.tenderClose[2] : null,
        },
        {
          name: "Store Credit",
          openingAmount: 0.0,
          closingAmount: this.tenderClose ? this.tenderClose[3] : null,
        },
        ...this.extraPaymentMethods.map((method, index) => ({
          name: method,
          openingAmount: 0.0,
          closingAmount: this.tenderClose ? this.tenderClose[index + 4] : null,
        })),
      ],
    };
  }

  @computed get cartObject() {
    var cart = {};
    if (this.cartId) {
      cart.id = this.cartId;
    }
    cart.tillId = this.tillId;
    cart.customer = this.selectedCustomer
      ? { id: this.selectedCustomer.id }
      : null;

    cart.cartItems = [];
    this.cart.map((lineItem) => {
      var line = {};
      line.variantTitle = lineItem.variantTitle;
      line.productTitle = lineItem.title;
      line.id = lineItem.lineId;
      line.variantId = lineItem.variantId;
      line.price = lineItem.displayTotal;
      line.actualPrice = lineItem.actualPrice;
      line.quantity = lineItem.qty;
      line.imageSrc = lineItem.imageUrl;
      line.buying = lineItem.buyItem;
      line.taxable = !lineItem.taxDisabledUI;
      line.shopifyTaxable = !lineItem.taxDisabledShopify;
      line.eventAdditionalInfo = lineItem.eventAdditionalInfo;
      line.shopifyPrice = lineItem.shopifyPrice;
      line.tags = lineItem.tags;
      line.discountAmount = lineItem.discountAmount;
      line.discountType = lineItem.discountType;
      line.discountValue = lineItem.discountValue;
      cart.cartItems.push(line);
    });
    if (isKioskMode()) {
      cart.cartType = "kiosk";
    }
    cart.totalTax = roundCents(this.taxTotal);
    cart.tenders = calculateCartTenders(this.tenders, this.changeDue);

    cart.customer = this.selectedCustomer
      ? { id: this.selectedCustomer.id }
      : null;
    cart.taxLines = this.taxRates;

    cart.discountValue = this.globalDiscount.amount;
    cart.discountType = this.globalDiscount.type;
    cart.discountAmount = this.discountAmount;
    cart.cartNotes = this.cartNotes;
    if (this.integratedPaymentData) {
      cart.cartIntegratedPayments = this.integratedPaymentData;
    }
    if (this.cartName !== "") {
      cart.cartName = this.cartName;
    }

    return cart;
  }

  @observable submittingCart = false;
  @action setSubmittingCart(value) {
    this.submittingCart = value;
  }

  @action submitCart = async (transactionData = {}) => {
    this.cartLoadingOn();
    this.setSubmittingCart(true);
    try {
      if (this.cartObject.cartItems.length == 0) {
        this.setSubmittingCart(false);
        this.setErrorMessage(
          "You need an item in the cart to checkout! \n (Try adding a custom item for 0.00 if you are trying to exchange tenders.)"
        );
        this.cartLoadingOff();
      } else {
        if (this.isFeatureFlagEnabled(GQL_SUBMIT_CART)) {
          const result = await gqlSubmitCart(this.cartObject, true);
          this.setSearchTerm("");
          this.clearSearchItems();
          if (result.dateSubmitted) {
            this.openReceipt(null, true);
          } else if (result.error) {
            this.setError(result.error);
          } else {
            this.setErrorMessage("Unknown error, cart submission failed");
          }
        } else {
          const body = await _fetch({
            endpoint: `${BASE_URL}/pos/carts?submit=true`,
            method: "POST",
            payload: { ...this.cartObject, transactionData: transactionData },
          });
          this.setSearchTerm("");
          this.clearSearchItems();
          if (body.dateSubmitted) {
            this.openReceipt(null, true);
            //this.setErrorMessage("Cart successfully submitted!");
            //this.setReceiptData(body);
          } else if (body.error) {
            this.setAPIError(body);
          } else {
            this.setErrorMessage("Unknown error, cart submission failed");
          }
        }
      }
    } catch (err) {
      if (err?.detailedMessage?.includes("Line items is invalid")) {
        err.detailedMessage =
          "An item in the cart has been deleted from Shopify and needs to been removed from cart.";
      }
      if (err.error) {
        this.setAPIError(err);
      } else {
        this.setErrorMessage("Unknown error, cart submission failed");
      }
    } finally {
      this.cartLoadingOff();
      this.setSubmittingCart(false);
    }
  };

  //This is the logic for handling integrated payments

  @action getPaxIP = async () => {
    //see if payment terminal is enabled
    const paxEnabled = await _fetch({
      endpoint: `${BASE_URL}/pos/settings/forTill/${this.tillId}/paxEnabled`,
    });
    this.integratedPaySettings.enabled =
      paxEnabled.settingValue == "true" ? true : false;

    const dnsResolver = await _fetch({
      endpoint: `${BASE_URL}/pos/settings/forTill/${this.tillId}/dnsResolver`,
    });
    if (dnsResolver.settingValue == "true") {
      this.integratedPaySettings.paxIP = `${this.tillId}.dns.binderpos.com:10009`;
    } else {
      const paxIP = await _fetch({
        endpoint: `${BASE_URL}/pos/settings/forTill/${this.tillId}/paxIP`,
      });
      this.integratedPaySettings.paxIP = paxIP.settingValue;
    }
  };

  //These are customer specific settings for the PAX terminal
  @observable integratedPaySettings = {
    paxIP: "192.168.1.118",
    enabled: false,
    dnsResolver: false,
  };

  @observable processingActive = false;
  @action endProcessing = () => {
    this.processingActive = false;
    this.clearProcessingAction();
  };
  @action startProcessing = () => {
    this.processingActive = true;
  };
  @observable processingData = {
    amount: 0.0,
  };

  @observable processingMessage = "";
  @action
  setProcessingMessage = (message) => {
    this.processingMessage = message;
  };

  @observable processingAction = null;
  @action
  setProcessingAction = (action, args, text = "Confirm") => {
    this.processingAction = { action: action, args: args, text: text };
  };

  @action
  clearProcessingAction = () => {
    this.processingAction = null;
  };

  @observable integratedPaymentData = [];
  @action
  clearPaymentData = () => {
    this.integratedPaymentData = [];
  };

  @action
  pushPaymentData = (data) => {
    this.integratedPaymentData.push(data);
  };

  @observable returnCartIntegratedPayment;
  @action setReturnCartIntegratedPayment = (payment) =>
    (this.returnCartIntegratedPayment = payment);

  cancelCardTransaction = async () => {
    try {
      await this.paymentTerminal.cancelCurrentTransaction();
    } catch (_err) {
      // No
    }
    this.endProcessing();
    this.setSubmittingCart(false);
  };

  forceFinalizeCredit = async () => {
    this.endProcessing();
    await this.submitCart();
    this.cartLoadingOff();
  };

  tendersIncludeCreditCard = () =>
    Number(
      this.cartObject.tenders.find((tender) => tender.type == "Credit")
        ?.amount ?? 0
    ) !== 0;

  getOriginalTransactionKey = (responseObject) => {
    try {
      const data = JSON.parse(responseObject);
      const originalTransactionKey = data?.transaction?.key || data?.replayId;
      return originalTransactionKey;
    } catch (error) {
      logError(error, {
        function: "getOriginalTransactionKey",
        tillId: this.tillId,
        cartId: this.cartId,
        responseObject,
      });
      return undefined;
    }
  };

  //this is always called when a cart is submitted it does nothing and calls
  //submitCart if no Credit Tender is present or the customer has no integrated
  //payments tied to their account
  @action processPayments = async () => {
    try {
      this.cartLoadingOn();
      if (!this.submittingCart) {
        this.setSubmittingCart(true);
        if (!this.processingActive) {
          if (
            this.tendersIncludeCreditCard() &&
            this.paymentTerminal?.settings?.enabled
          ) {
            this.setProcessingMessage("Please continue transaction on pinpad");
            this.processingData.amount = this.cartObject.tenders.find(
              (e) => e.type == "Credit"
            ).amount;
            this.startProcessing();
            let paymentResponse;
            try {
              if (this.processingData.amount < 0) {
                paymentResponse = await this.paymentTerminal.processReturn(
                  -this.processingData.amount,
                  this.cartObject,
                  this.getOriginalTransactionKey(
                    this.returnCartIntegratedPayment?.[0]?.fullResponseObject
                  )
                );
              } else {
                paymentResponse = await this.paymentTerminal.processSale(
                  this.processingData.amount,
                  this.cartObject
                );
              }
              if (!paymentResponse) {
                throw new Error("Unable to contact terminal");
              }
              const {
                status,
                message = undefined,
                reason = undefined,
                paymentData = {},
              } = paymentResponse;
              if (status) {
                const leftToProcess = new Big(this.processingData.amount);
                if (
                  leftToProcess.lte(
                    Big(paymentData.approveAmount).minus(
                      paymentData.surchargeFee
                    )
                  )
                ) {
                  paymentData.cartId = this.cartId;
                  this.pushPaymentData(paymentData);
                  await this.submitCart();
                  this.cartLoadingOff();
                  this.endProcessing();
                } else {
                  this.setProcessingMessage(
                    `ONLY PARTIALLY AUTHORIZED FOR ${this.fCurr(
                      paymentData.approveAmount - paymentData.surchargeFee
                    )} REMOVE CARD AND VOID`
                  );
                  this.setProcessingAction(
                    async (transactionNumber) => {
                      await this.paymentTerminal.voidSale(
                        transactionNumber,
                        this.processingData.amount
                      );
                      this.endProcessing();
                    },
                    paymentData.transactionNumber,
                    "VOID"
                  );
                }
              } else {
                this.setProcessingMessage(
                  reason + (message ? " - " + message : "")
                );
              }
            } catch (error) {
              logError(error, {
                function: "processPayments-paymentResponse",
                tillId: this.tillId,
                cartId: this.cartId,
                processingData: JSON.stringify(this.processingData),
              });
              console.log(JSON.stringify(error));
              this.setProcessingMessage(
                `Error: ${
                  error.message ?? error.error ?? JSON.stringify(error)
                }`
              );
            }
          } else {
            await this.submitCart();
            this.cartLoadingOff();
          }
        }
      }
    } catch (e) {
      logError(e, {
        function: "processPayments",
        tillId: this.tillId,
        cartId: this.cartId,
        processingData: JSON.stringify(this.processingData),
      });
    } finally {
      this.setSubmittingCart(false);
      this.cartLoadingOff();
    }
  };

  @action voidSale = async (saleId) => {
    const pax = new PAX("https://" + this.integratedPaySettings.paxIP);
    return pax.creditVoid(saleId);
  };

  @observable tenderClose = [];
  @action setTenderClose = (value, tenderIndex) => {
    if (tenderIndex < this.tenderClose.length) {
      this.tenderClose[tenderIndex] = value;
    }
  };
  @computed get tenderDiff() {
    var diff = new Array(this.tenderClose.length);
    this.tenderClose.map((close, index) => {
      if (isNaN(this.tenderClose[index])) return;
      diff[index] = (
        this.tenderClose[index] - this.float.float[index].currentAmount
      ).toFixed(2);
    });
    return diff;
  }

  @action clearCustomer() {
    this.selectedCustomer = null;
    this.customerInput = "";
  }

  @observable checkoutModal = false;
  @observable disableLineItems = false;
  @action setDisableLineItems(bool) {
    this.disableLineItems = bool;
  }

  @observable cartModal = false;
  @action closeCartModal = () => {
    this.cartModal = false;
  };
  @action openCartModal = () => {
    this.cartModal = true;
  };

  @observable orderModal = false;
  @action closeOrderModal = () => {
    this.orderModal = false;
  };
  @action openOrderModal = () => {
    this.orderModal = true;
  };

  @observable receiptModal = false;
  @action closeReceipt = () => {
    this.receiptModal = false;
    if (this.completedReceipt) {
      this.newCart();
      this.checkoutModalFalse();
      this.clearCustomer();
      this.setCartName("");
      this.getTax();
      this.resetTenders();
      this.setDisableLineItems(false);
    }
    this.completedReceipt = false;
  };
  @observable completedReceipt = false;

  @action openReceipt = async (e, completed = false) => {
    if (!completed) {
      await this.validateCartNoRefresh();
    }
    runInAction(() => {
      this.completedReceipt = completed;
      this.receiptModal = true;
    });
  };

  @observable receiptData = {};

  @action setReceiptData = (data) => {
    this.receiptData = data;
  };
  @action checkoutModalFalse = () => {
    this.checkoutModal = false;
    this.setDisableLineItems(false);
  };

  @observable cashDenoms = [5, 10, 20, 50, 100];

  @action newCart = (isRetry = false) => {
    var blankBody = {
      tillId: this.tillId,
      cartItems: [],
    };
    this.resetAvailableQuantities();
    this.resetCartItemBuyLimit();
    this.setReturnCartIntegratedPayment();
    this.setCartInvalid(false);
    this.clearCustomer();
    this.clearDiscount();
    this.setCartName("");
    this.setSearchTerm("");
    this.clearSearchItems();
    this.clearPaymentData();
    this.zeroTenders();
    this.buyMode = false;
    blankBody.customer = this.selectedCustomer
      ? { id: this.selectedCustomer.id }
      : null;
    return _fetch({
      endpoint: `${BASE_URL}/pos/carts`,
      method: "POST",
      payload: blankBody,
    })
      .then((response) => {
        this.setCartId(response.id);
        this.getLatestCart(isRetry);
      })
      .catch((error) => {
        this.setError(
          error,
          "Unable to create a new cart",
          "We seem to have had an issue communicating with the server. Please refresh and try again."
        );
      });
  };

  @action clearDiscount = () => {
    this.globalDiscount = { amount: 0, type: "percentage" };
  };

  @action saveCart = () => {
    const blankBody = {
      savedName: "",
      savedCart: "true",
      id: this.cartId,
    };
    return _fetch({
      endpoint: `${BASE_URL}/pos/carts/save`,
      method: "POST",
      payload: blankBody,
    })
      .then((response) => {
        notify.info("Cart #" + this.cartId + " has been saved!");
        this.setCartId(response.id);
        this.getLatestCart();
      })
      .catch((error) =>
        this.setError(
          error,
          "Unable to save cart",
          "We seem to have had an issue communicating with the server. Please try again."
        )
      );
  };

  @action checkCart = (response) => {
    this.cart = [];
    this.cartType = response.cartType;
    if (response.customer) {
      //this.selectedCustomer = response.customer;
    }
    if (response.discountAmount && response.discountType) {
      this.globalDiscount.amount = response.discountValue;
      this.globalDiscount.type = response.discountType;
    }
    this.cartNotes = response.cartNotes;
    response.cartItems?.map((line) => {
      this.cart.push(
        new LineItem(
          line,
          null,
          null,
          this.allTax,
          line.eventAdditionalInfo,
          this.cartType
        )
      );
    });
  };

  @action validateCart = () => {
    this.cartLoadingOn();
    if (this.isFeatureFlagEnabled(GQL_UPDATE_CART)) {
      updateCart(this.cartObject)
        .then((response) => {
          this.checkCart(response);
          this.cartLoadingOff();

          this.setIsDeletingCartItem(false);
          this.refreshSearch();
        })
        .catch((error) => {
          //this.setErrorMessage(error.message);
          this.setError(error);
          this.getCartById(undefined, this.cartId);
          this.cartLoadingOff();
        });
    } else {
      return _fetch({
        endpoint: `${BASE_URL}/pos/carts`,
        method: "PUT",
        payload: this.cartObject,
      }).then((response) => {
        if (response.error) {
          this.setAPIError(response);
          this.cartLoadingOff();
          //this.newCart();
        } else {
          this.checkCart(response);
          this.cartLoadingOff();
        }
        this.setIsDeletingCartItem(false);
        this.refreshSearch();
      });
    }
  };

  @action validateCartNoRefresh = () => {
    this.cartLoadingOn();
    if (this.isFeatureFlagEnabled(GQL_UPDATE_CART)) {
      return updateCart(this.cartObject)
        .then((response) => {
          this.checkCart(response);
          this.cartLoadingOff();
        })
        .catch((error) => {
          //this.setErrorMessage(error.message);
          this.setError(error);
          this.getCartById(undefined, this.cartId);
          this.cartLoadingOff();
        });
    } else {
      return _fetch({
        endpoint: `${BASE_URL}/pos/carts`,
        method: "PUT",
        payload: this.cartObject,
      })
        .then((response) => {
          if (response.error) {
            this.setAPIError(response);
            this.cartLoadingOff();
            //this.newCart();
          } else {
            this.checkCart(response);
            this.cartLoadingOff();
          }
          this.setIsDeletingCartItem(false);
        })
        .catch((error) => {
          if (error?.error === "POSCart marked as abandoned") {
            this.setCartInvalid(true);
            this.setErrorMessage(
              "This cart has been abandoned automatically by BinderPOS due to being over 1 hour old. Items have been added back into stock. You will need to create a new cart."
            );
          } else if (error?.detailedMessage) {
            this.setErrorMessage(error.detailedMessage);
            this.getCartById(undefined, this.cartId);
          }
          logError(error, {
            function: "validateCartNoRefresh",
            tillId: this.tillId,
            cartId: this.cartId,
          });
        });
    }
  };

  @action getLatestCart = async (isRetry = false) => {
    try {
      this.setGettingLatestCart(true);
      const result = await getLatestCartForTill(Number(this.tillId));
      if (!result || result.dateSubmitted || result.id == null) {
        this.newCart();
      } else {
        this.resetAvailableQuantities();
        this.resetCartItemBuyLimit();
        this.setReturnCartIntegratedPayment();
        this.setCartId(result.id);
        this.setSelectedCustomer(result.customer);
        this.checkCart(result);
      }
    } catch (e) {
      if (!isRetry) {
        this.newCart(true);
      } else {
        this.setErrorMessage(
          "There was a problem retrieving the contents of this cart. You will need to create a new cart."
        );
        logError(e, {
          function: "getLatestCart",
          tillId: this.tillId,
          cartId: this.cartId,
        });
      }
    } finally {
      this.setGettingLatestCart(false);
    }
  };

  @action getCartById = (e, id) => {
    return getCartById(id)
      .then((result) => {
        if (result.dateSubmitted) {
          notify.info("This cart has already been submitted");
        } else {
          if (result.abandoned) {
            this.newCart();
          } else {
            this.resetAvailableQuantities();
            this.setCartId(result.id);
            this.setSelectedCustomer(result.customer);
            this.setReturnCartIntegratedPayment();
            this.checkCart(result);
          }
        }
        this.closeCartModal();
      })
      .catch((error) =>
        logError(error, {
          function: "getCartById",
          tillId: this.tillId,
          cartId: id,
        })
      )
      .finally(() => this.cartLoadingOff());
  };

  @action returnCartById = async (id) => {
    this.resetAvailableQuantities();
    this.resetCartItemBuyLimit();
    this.setReturnCartIntegratedPayment();
    const result = await _fetch({
      endpoint: `${BASE_URL}/pos/carts/createReturn/${id}`,
      method: "POST",
      payload: null,
    });
    if (result.id == null) {
      notify.warn("Error");
    } else {
      this.setCartId(result.id);
      this.setSelectedCustomer(result.customer);
      this.checkCart(result);
    }
  };

  @action cartFromResponse = (response) => {
    this.resetAvailableQuantities();
    this.resetCartItemBuyLimit();
    this.setCartId(response.id);
    this.setSelectedCustomer(response.customer);
    this.checkCart(response);
  };

  @action unsetTill = (savePreviousTillId = true) => {
    if (savePreviousTillId) this.setPreviousTillId(String(this.tillId));
    this.cookie.remove();
    this.setFloat({
      status: "No Till Selected",
    });
  };

  @observable taxRate = undefined;
  @observable taxRateDisplay = 0;
  @observable taxNumber = "";
  @observable taxIncluded = true;
  @observable taxTrades = false;
  @observable taxWording = "";
  @observable currency = "$";

  @observable storeInfo = {};
  @action setTaxTrades = (value) => {
    this.taxTrades = value;
  };
  @action taxIncludedSet = (value) => {
    this.taxIncluded = value;
  };
  @action taxRateSet = (value) => {
    this.taxRate = isNaN(value) ? 0 : value;
  };
  @action taxRateDisplaySet = (value) => {
    this.taxRateDisplay = value;
  };
  @action taxNumberSet = (value) => {
    this.taxNumber = value;
  };
  @action setStoreInfo = (value) => {
    this.storeInfo = value;
  };
  @action taxWordingSet = (value) => {
    this.taxWording = value;
  };
  @action currencySet = (value) => {
    this.currency = value;
  };

  @observable currencyCode = "USD";
  @action currencyCodeSet = (value) => {
    this.currencyCode = value;
  };

  @action getTax = async () => {
    const taxRate = await _fetch({
      endpoint: `${BASE_URL}/settings/taxRate/forMe`,
    });
    if (isTaxRateValid(taxRate.settingValue)) {
      this.taxRateDisplaySet(taxRate.settingValue);
      this.taxRateSet(taxRate.settingValue / 100);
    } else {
      this.setShowTaxErrorModal(true);
      this.taxRateSet(null);
    }
    const taxIncluded = await _fetch({
      endpoint: `${BASE_URL}/settings/taxIncluded/forMe`,
    });
    this.taxIncludedSet(taxIncluded.settingValue == "true");

    const taxTrades = await _fetch({
      endpoint: `${BASE_URL}/settings/tradeInTax/forMe`,
    });
    this.setTaxTrades(taxTrades.settingValue == "true");

    const storeInfo = await _fetch({ endpoint: `${BASE_URL}/settings/store` });
    this.setStoreInfo(storeInfo);

    const taxNumber = await _fetch({
      endpoint: `${BASE_URL}/settings/taxNumber/forMe`,
    });
    this.taxNumberSet(taxNumber?.settingValue);

    const taxWording = await _fetch({
      endpoint: `${BASE_URL}/settings/taxWording/forMe`,
    });
    this.taxWordingSet(taxWording?.settingValue);

    const currencySymbol = await _fetch({
      endpoint: `${BASE_URL}/settings/currencySymbol`,
    });
    this.currencySet(currencySymbol?.settingValue);

    const currencyCode = await _fetch({
      endpoint: `${BASE_URL}/settings/currency`,
    });
    this.currencyCodeSet(currencyCode?.settingValue);

    //this.validateCartNoRefresh();
  };

  @action updateTax = (
    taxRate,
    taxIncluded,
    taxNumber,
    tradeInTax,
    taxWording
  ) => {
    this.taxRateDisplaySet(taxRate);
    this.taxRateSet(taxRate / 100);
    this.taxIncludedSet(taxIncluded);
    this.taxNumberSet(taxNumber);
    this.setTaxTrades(tradeInTax);
    this.taxWordingSet(taxWording);
  };

  @action fetchCurrency() {
    _fetch({ endpoint: `${BASE_URL}/settings/currencySymbol` }).then(
      (result) => {
        this.currencySymbol(result?.settingValue);
      }
    );
  }

  @computed get allTax() {
    return {
      taxRate: isNaN(this.taxRate) ? 0 : this.taxRate,
      taxTrades: this.taxTrades,
      taxIncluded: this.taxIncluded,
    };
  }
  @observable stockLimit = false;

  logError = () => {
    // let time = new Date(Date.now()).toLocaleString();
    // fetch("https://pos.alvarandhurriks.com:1880/binderLog", {
    //   method: "POST",
    //   body: JSON.stringify(
    //     Object.assign(
    //       {
    //         time: time,
    //         user: firebase.auth().currentUser.displayName
    //       },
    //       toJS(this)
    //     )
    //   )
    // });
  };

  fCurr = (amount) => formatCurrency(amount, this.currency);

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async fetchAllProducts(name) {
    this.db = new Dexie(name);
    this.db.version(1).stores({
      products: "id,title",
    });
    this.worker.postMessage({
      topic: "fetchProducts",
      name: name,
      token: await firebase.auth().currentUser?.getIdToken(),
    });
  }

  worker = new Worker();

  @action setQuickLinkData(data) {
    if (Array.isArray(data)) {
      this.quickLinkData = data;
      _fetch({
        endpoint: `${BASE_URL}/pos/quicklinks/forTill/${this.tillId}`,
        method: "PUT",
        payload: this.quickLinkData,
      });
    }
  }

  @action setQuickLinkNav(data) {
    this.quickLinkNav.push(data);
  }

  @action updateQuickLinks = () => {
    _fetch({
      endpoint: `${BASE_URL}/pos/quicklinks/forTill/${this.tillId}`,
      method: "PUT",
      payload: this.quickLinkData,
    });
  };

  @observable
  quickLinkNav = [];

  @action moveQuickLink(itemIndex, position) {
    if (itemIndex > position) {
      this.currentNav.splice(
        position,
        0,
        this.currentNav.splice(itemIndex, 1)[0]
      );
    } else {
      this.currentNav.splice(
        position - 1,
        0,
        this.currentNav.splice(itemIndex, 1)[0]
      );
    }
    this.updateQuickLinks();
  }

  @action moveQuickLinkToFolder(itemIndex, folderIndex) {
    this.currentNav[folderIndex].value.items.push(
      this.currentNav.splice(itemIndex, 1)[0]
    );
    this.updateQuickLinks();
  }

  @action moveQuickLinkUp(itemIndex) {
    const navCopy = [...this.quickLinkNav];
    navCopy.pop();
    var nav = this.quickLinkData;
    navCopy.forEach((index) => {
      nav = nav[index].value.items;
    });
    nav.push(this.currentNav.splice(itemIndex, 1)[0]);
    this.updateQuickLinks();
  }

  @action deleteQuickItem(itemIndex) {
    this.currentNav.splice(itemIndex, 1);
    this.updateQuickLinks();
  }

  @computed get currentNav() {
    var nav = this.quickLinkData;
    this.quickLinkNav.forEach((index) => {
      nav = nav[index].value.items;
    });
    return nav;
  }

  @computed get currentNavName() {
    var nav = this.quickLinkData;
    var name = "POS";
    this.quickLinkNav.forEach((value) => {
      name += " > " + nav[value].value.title;
      nav = nav[value].value.items;
    });
    return name;
  }

  @observable
  movingQuickLink = null;

  @action setMovingQuickLink(value) {
    this.movingQuickLink = value;
  }

  @observable
  quickLinkData = [];
  /**
   * Neat function
   *
   */
  @action
  getQuickLinkData() {
    if (this.tillId === -1 || this.tillId === undefined) return;
    return _fetch({
      endpoint: `${BASE_URL}/pos/quicklinks/forTill/${this.tillId}`,
    })
      .then((result) => {
        if (Object.keys(result).length === 0) {
          this.setQuickLinkData([]);
          this.setQuickLinksReady(true);
        } else {
          this.setQuickLinkData(result);
          this.setQuickLinksReady(true);
        }
      })
      .catch((error) =>
        logError(error, {
          function: "getQuickLinkData",
          tillId: this.tillId,
        })
      );
  }
  //this is used to make sure the quickLinks component doesn't mount prematurely
  //project://src/js/components/pos/ResultsGrid.jsx
  @observable
  quickLinksReady = false;

  @action
  setQuickLinksReady(value) {
    this.quickLinksReady = value;
  }

  @observable
  editingQuickItem = null;

  @observable
  posVersion = "2.1.1";

  //this computed tells us the current users info like store name etc
  @computed get customerInfo() {
    return Store.SettingsStore.storeSettings;
  }

  @observable
  useSplitTill = false;

  @action
  setUseSplitTill = (useSplitTill) => {
    this.useSplitTill = useSplitTill;
  };

  @action
  loadSplitTillSetting = async () => {
    try {
      const posUseSplitCheckout = await _fetch({
        method: "GET",
        endpoint: `${BASE_URL}/settings/posUseSplitCheckout/forMe`,
      });
      this.setUseSplitTill(posUseSplitCheckout?.settingValue === "true");
    } catch (error) {
      logError(error, {
        function: "loadSplitTillSetting",
        tillId: this.tillId,
      });
    }
  };

  @observable customerNoteModalVisible = false;
  @action setCustomerNoteModalVisible = (visible) =>
    (this.customerNoteModalVisible = visible);

  @observable customerToEdit = null;
  @action setCustomerToEdit = (customer) => (this.customerToEdit = customer);

  @observable showTaxErrorModal = false;
  @action setShowTaxErrorModal = (showTaxErrorModal) =>
    (this.showTaxErrorModal = showTaxErrorModal);

  @observable useNewCartOnPOSOpening = false;

  @action setUseNewCartOnPOSOpening = (useNewCartOnPOSOpening) => {
    this.useNewCartOnPOSOpening = useNewCartOnPOSOpening;
  };

  @action
  loadUseNewCartOnPOSOpening = async () => {
    try {
      const newCartOnPOSOpening = await _fetch({
        method: "GET",
        endpoint: `${BASE_URL}/settings/newCartOnPOSOpening/forMe`,
      });
      this.setUseNewCartOnPOSOpening(
        newCartOnPOSOpening?.settingValue === "true"
      );
    } catch (error) {
      logError(error, {
        function: "loadUseNewCartOnPOSOpening",
        tillId: this.tillId,
      });
    }
  };

  @observable
  useBarcodeQuantityCheck = false;

  @action
  setUseBarcodeQuantityCheck = (useBarcodeQuantityCheck) => {
    this.useBarcodeQuantityCheck = useBarcodeQuantityCheck;
  };

  @action
  loadBarcodeQuantityCheckSetting = async () => {
    try {
      const useBarcodeQuantityCheck = await _fetch({
        method: "GET",
        endpoint: `${BASE_URL}/settings/barcodeQuantityCheck/forMe`,
      });
      this.setUseBarcodeQuantityCheck(
        useBarcodeQuantityCheck?.settingValue === "true"
      );
    } catch (error) {
      logError(error, {
        function: "loadBarcodeQuantityCheckSetting",
        tillId: this.tillId,
      });
    }
  };

  @observable usePosBarcodeErrorModal = false;

  @action setUsePosBarcodeErrorModal = (usePosBarcodeErrorModal) =>
    (this.usePosBarcodeErrorModal = usePosBarcodeErrorModal);

  @action
  loadBarcodeErrorModalSetting = async () => {
    try {
      const useBarcodeErrorModal = await _fetch({
        method: "GET",
        endpoint: `${BASE_URL}/settings/posBarcodeErrorModal/forMe`,
      });
      this.setUsePosBarcodeErrorModal(
        useBarcodeErrorModal?.settingValue === "true"
      );
    } catch (error) {
      logError(error, {
        function: "loadBarcodeErrorModalSetting",
        tillId: this.tillId,
      });
    }
  };

  @observable showNoMatchingBarcodeError = false;
  @action setShowNoMatchingBarcodeError = (showNoMatchingBarcodeError) =>
    (this.showNoMatchingBarcodeError = showNoMatchingBarcodeError);

  @observable showMultipleMatchingBarcodesError = null;
  @action setShowMultipleMatchingBarcodesError = (
    showMultipleMatchingBarcodesError
  ) =>
    (this.showMultipleMatchingBarcodesError =
      showMultipleMatchingBarcodesError);

  @observable
  extraPaymentMethods = [];

  @action
  setExtraPaymentMethods = (posPaymentTypes) => {
    this.extraPaymentMethods =
      (posPaymentTypes || "").split(";").filter(Boolean) || [];
  };

  @action
  loadExtraPaymentMethods = async () => {
    try {
      const posPaymentTypes = await _fetch({
        method: "GET",
        endpoint: `${BASE_URL}/settings/posPaymentTypes/forMe`,
      });
      this.setExtraPaymentMethods(posPaymentTypes?.settingValue);
      return;
    } catch (error) {
      logError(error, {
        function: "loadExtraPaymentMethods",
        tillId: this.tillId,
      });
    }
  };
}

export { Item, ItemList, LineItem, CustomLineItem, Register };
