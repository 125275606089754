import React, { useEffect, useState } from "react";
import CartNoteModel from "./cart-note.model";
import ButtonComponent from "../generic/ButtonComponent";
import Modal from "../generic/Modal";
import "./EditCartNotes.scss";
import { ItemList } from "../../pos/ItemStore";

export default function EditCartNotes({
  onClose,
  posStore,
  cartNotes,
  customFields,
}: {
  cartNotes?: string;
  customFields?: string[];
  onClose: () => void;
  posStore: ItemList;
}) {
  const [note, setNote] = useState<CartNoteModel>({
    note: "",
    customFields: {},
  });

  useEffect(() => {
    if (!cartNotes) return;
    setNote(JSON.parse(cartNotes));
  }, [cartNotes]);

  return (
    <Modal onClose={onClose} className="EditCartNotes">
      <Modal.Header>Notes</Modal.Header>
      <Modal.Content>
        <textarea
          aria-label="Cart notes"
          className="EditCartNotes__cart-notes"
          id="note"
          rows={10}
          onChange={(event) => setNote({ ...note, note: event.target.value })}
          value={note.note || ""}
        />
        {customFields && (
          <div>
            {customFields.map((customField) => (
              <div key={customField} className="EditCartNotes__custom-field">
                <label htmlFor={customField} className="label">
                  {customField}
                </label>
                <input
                  id={customField}
                  className="input"
                  onChange={(event) => {
                    const newNote = { ...note };
                    newNote.customFields[customField] = event.target.value;
                    setNote(newNote);
                  }}
                  value={note.customFields[customField] || ""}
                />
              </div>
            ))}
          </div>
        )}
      </Modal.Content>
      <Modal.Actions>
        <ButtonComponent secondary onClick={() => onClose()}>
          Cancel
        </ButtonComponent>
        <ButtonComponent
          primary
          onClick={async () => {
            posStore.setCartNotes(JSON.stringify(note));
            await posStore.validateCart();
            onClose();
          }}
        >
          Attach note
        </ButtonComponent>
      </Modal.Actions>
    </Modal>
  );
}
