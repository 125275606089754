import React, { Component } from "react";
import GridItem from "./GridItem";
import QuickLinks from "./QuickLinks";
import { inject, observer } from "mobx-react";
import { Loader } from "..";
import AdditionalInfo from "./AdditionalInfo";
import { action } from "mobx";
import { ItemList, LineItem } from "../../pos/ItemStore";
import ResultsPagination from "./ResultsPagination";

interface ResultsGridProps {
  posStore?: ItemList;
}

@inject("posStore")
@observer
class ResultsGrid extends Component<ResultsGridProps> {
  form: React.RefObject<HTMLFormElement>;

  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  @action
  addItem = (item) => {
    this.props.posStore.addAvailableQuantity(
      item.variants[item.selectedVariant]
    );
    if (this.props.posStore.buyMode && item.selectedBuyVariant > -1) {
      this.props.posStore.addCartItemBuyLimit(
        item.variants[item.selectedBuyVariant]
      );
      item.variants[item.selectedBuyVariant].quantity++;
    }
    if (!this.props.posStore.buyMode && item.selectedVariant > -1) {
      item.variants[item.selectedVariant].quantity--;
    }

    this.props.posStore.addToCart(
      new LineItem(
        item,
        this.props.posStore.buyMode,
        this.props.posStore.cashPrice,
        this.props.posStore.allTax
      )
    );
    this.props.posStore.setAdditionalInfoItem(null);
  };

  @action addItemWithAdditionalInfo = (item) => {
    this.props.posStore.addAvailableQuantity(
      item.variants[item.selectedVariant]
    );
    const lineItemValues = [];
    item.event.additionalInfo.forEach((additionalInfo) => {
      const additionalInfoItem = {
        name: additionalInfo.id + "",
        value:
          this.form.current?.elements["additionalInfo" + additionalInfo.id]
            .value,
      };
      lineItemValues.push(additionalInfoItem);
    });

    this.props.posStore.addToCart(
      new LineItem(
        item,
        this.props.posStore.buyMode,
        this.props.posStore.cashPrice,
        this.props.posStore.allTax,
        lineItemValues
      )
    );
    this.props.posStore.setAdditionalInfoItem(null);
  };

  render() {
    const store = this.props.posStore;
    if (store.fetchingSearch) return <Loader text="Searching products" />;
    if (!store.quickLinksReady) return <Loader text="Loading quick links..." />;

    return (
      <>
        <ResultsPagination />
        <div className="results-grid" tabIndex={-1}>
          {!!this.props.posStore.additionalInfoItem && (
            <AdditionalInfo
              addItem={() =>
                this.addItem(this.props.posStore.additionalInfoItem)
              }
              item={this.props.posStore.additionalInfoItem}
              form={this.form}
              addItemWithAdditionalInfo={(event) => {
                event.preventDefault();
                this.addItemWithAdditionalInfo(
                  this.props.posStore.additionalInfoItem
                );
              }}
              close={() => this.props.posStore.setAdditionalInfoItem(null)}
            />
          )}
          {store.items.map((item) => (
            <GridItem item={item} key={item.id} addItem={this.addItem} />
          ))}
          {store.items.length == 0 &&
          !store.fetchingSearch &&
          store.searchTerm != "" &&
          !store.waitingToSearch ? (
            store.inStockOnly ? (
              <div className="emptySearch">
                No products found. Search results excludes out of stock
                products, switch to 'All stock' to see more products.
              </div>
            ) : (
              <div className="emptySearch">No products found...</div>
            )
          ) : null}
          {store.searchTerm == "" && store.quickLinksReady ? (
            <QuickLinks menu={store.quickLinkData} />
          ) : null}
        </div>
      </>
    );
  }
}

export default ResultsGrid;
