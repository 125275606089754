import React, { useState } from "react";
import IconButton from "../generic/IconButton";
import "./PosPaymentTypesSettings.scss";

interface PosPaymentTypesSettingsProps {
  paymentTypesList: string[];
  setPaymentTypes: (paymentType: string[]) => void;
}

const PosPaymentTypesSettings = (props: PosPaymentTypesSettingsProps) => {
  const { paymentTypesList, setPaymentTypes } = props;
  const [newPaymentType, setNewPaymentType] = useState<string>("");

  const deletePaymentType = (paymentTypeIndex) =>
    setPaymentTypes([
      ...paymentTypesList?.slice(0, paymentTypeIndex),
      ...paymentTypesList?.slice(paymentTypeIndex + 1, paymentTypesList.length),
    ]);

  const addPaymentType = (paymentType: string) => {
    const trimmedPaymentType = paymentType?.trim();
    if (trimmedPaymentType.length) {
      setPaymentTypes([...paymentTypesList, trimmedPaymentType]);
      setNewPaymentType("");
    }
  };

  return (
    <div className="PosPaymentTypesSettings">
      <div className="PosPaymentTypesSettings__title">
        Extra POS Payment Methods
      </div>
      <div className="PosPaymentTypesSettings__description">
        Add additional payment method types to the POS. The new methods will be
        available to use when setting a till float or cart payment.
      </div>
      <div className="PosPaymentTypesSettings__description">
        <em>
          Important: changes to this list will not be applied to open Tills. To
          see the changes, first, close all the opened Tills.
        </em>
      </div>
      {paymentTypesList?.length > 0 ? (
        <ul className="PosPaymentTypesSettings__paymentTypeList">
          {paymentTypesList.map((paymentType, index) => (
            <li
              className="PosPaymentTypesSettings__paymentType"
              key={paymentType}
            >
              <IconButton
                aria-label={`Delete ${paymentType} method`}
                icon="fas fa-trash-alt"
                data-testid={`${paymentType}-delete-button`}
                className="PosPaymentTypesSettings__removeButton"
                onClick={() => deletePaymentType(index)}
              />
              <span>{paymentType}</span>
            </li>
          ))}
        </ul>
      ) : null}
      <label htmlFor="new-payment-type">New payment method name:</label>
      <form
        data-testid="new-payment-form"
        className="PosPaymentTypesSettings__newPaymentMethod"
        onSubmit={(evt) => {
          evt.preventDefault();
          addPaymentType(newPaymentType);
        }}
      >
        <input
          className="input PosPaymentTypesSettings__input"
          data-testid="new-payment-input"
          id="new-payment-type"
          value={newPaymentType}
          onChange={(evt) => setNewPaymentType(evt.target.value)}
        />
        <IconButton
          aria-label="Add payment method"
          icon="fa fa-plus"
          secondary
          className="PosPaymentTypesSettings__addButton"
          onClick={() => addPaymentType(newPaymentType)}
        />
      </form>
    </div>
  );
};

export default PosPaymentTypesSettings;
