import React from "react";
import ButtonComponent from "../../components/generic/ButtonComponent";
import "./ProductPagination.scss";

interface ProductPaginationProps {
  resultsCount: number;
  offset: number;
  limit: number;
  setOffset: Function;
}

function ProductPagination(props: ProductPaginationProps) {
  const { resultsCount, offset, limit, setOffset } = props;

  const goToNextPage = () => {
    setOffset(offset + limit);
  };

  const goToPreviousPage = () => {
    setOffset(offset - limit);
  };

  if (resultsCount < limit) return null;

  return (
    <div className="product-pagination paging-nav-body">
      <ButtonComponent
        disabled={offset === 0}
        onClick={() => goToPreviousPage()}
      >
        <i className="fas fa-caret-left" /> Prev
      </ButtonComponent>
      <ButtonComponent
        disabled={resultsCount ? resultsCount < offset + limit : false}
        onClick={() => goToNextPage()}
      >
        Next <i className="fas fa-caret-right" />
      </ButtonComponent>
    </div>
  );
}

export default ProductPagination;
