import {
  Cart,
  CartModal,
  CloseTillWarning,
  FloatModal,
  Navbar,
  OrderModal,
  POSErrorModal,
  QuickLinkEditMenu,
  ReceiptModal,
  ResultsGrid,
  SearchBar,
  ViewSwipePOS,
} from "../components";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import Modal from "../components/generic/Modal";
import OpenTillModal from "../components/pos/OpenTillModal";
import EditCustomerNote from "../components/pos/EditCustomerNote";
import OutOfStockWarning from "../components/pos/OutOfStockWarning";
import KioskCart from "../components/pos/KioskCart";
import TaxErrorModal from "../components/pos/TaxErrorModal";
import CartIdleCheck from "../components/pos/CartIdleCheck";
import BarcodeMultipleProducts from "../components/pos/BarcodeMultipleProducts";
import Watermark from "../../assets/img/watermark.png";
import { isKioskMode } from "../helpers/posModeHelpers";
import { RootStore } from "../store";
import { ItemList } from "./ItemStore";
import IconButton from "../components/generic/IconButton";
import "./Pos.scss";

interface PosProps {
  store?: RootStore;
  posStore?: ItemList;
}

@inject("store", "posStore")
@observer
export default class Pos extends Component<PosProps> {
  posStore = this.props.posStore;
  store = this.props.store;

  componentDidMount() {
    document.title = "Point of Sale | BinderPOS";
    const mainElement = document.querySelector("main");
    mainElement.id = "binderpos-pos";
    const body = document.querySelector("body");
    body.className = "pos";

    this.posStore.loadUseNewCartOnPOSOpening();
    this.posStore.fetchAllProducts(this.store.AuthStore.user.email);
    this.posStore.loadSplitTillSetting();
    this.posStore.loadBarcodeQuantityCheckSetting();
    this.posStore.loadBarcodeErrorModalSetting();
    this.posStore.fetchTills();
    this.posStore.fetchFloat();
    this.posStore.loadShowBuyLimitWarningsSetting();
    this.posStore.paymentTerminal.changeTill(this.posStore.cookie?.value);
    this.store.WindowSizeStore.setWindow();
  }

  openPOSMenu() {
    if (!this.posMenuVisible) {
      this.setposMenuVisible(true);
    } else {
      this.setposMenuVisible(false);
    }
  }

  closePOSMenu = () => {
    if (this.posMenuVisible) {
      this.setposMenuVisible(false);
    }
  };

  @observable posMenuVisible = false;

  @action setposMenuVisible(bool: boolean) {
    this.posMenuVisible = bool;
  }

  componentWillUnmount() {
    const mainElement = document.querySelector("main");
    mainElement.id = "binderpos-app";

    const body = document.querySelector("body");
    body.className = "";
  }

  render() {
    return (
      <>
        {this.store.AuthStore.screenSettings.pos ? (
          <>
            <CartIdleCheck />
            {this.store.WindowSizeStore.windowWidth > 1365 ? (
              <div className="App desktop">
                <div className="watermarkPos1">
                  <img src={Watermark} role="presentation" />
                </div>
                <div className="watermarkPos2">
                  <img src={Watermark} role="presentation" />
                </div>
                <Navbar />
                <div className="body">
                  <h1 className="sr-only">POS and Cart view</h1>
                  <div className="product-search">
                    <SearchBar />
                    <ResultsGrid />
                  </div>
                  {isKioskMode() ? <KioskCart /> : <Cart />}
                </div>
              </div>
            ) : (
              <div className="App mobile">
                <div className="watermarkPos1">
                  <img src={Watermark} role="presentation" />
                </div>
                <IconButton
                  className="Pos__ham-menu"
                  icon="far fa-bars"
                  onClick={() => this.openPOSMenu()}
                  aria-label="Cart menu"
                />
                <Navbar
                  menuState={this.posMenuVisible}
                  closeMenu={this.closePOSMenu}
                />
                <ViewSwipePOS />
              </div>
            )}
            {this.posStore.floatStatus ? null : <OpenTillModal />}
            {this.posStore.floatModal ? <FloatModal /> : null}
            <POSErrorModal />
            <QuickLinkEditMenu />
            <ReceiptModal />
            {this.posStore.tillWarning ? <CloseTillWarning /> : null}
            {this.posStore.cartModal ? (
              <CartModal
                getCartById={(id) => this.posStore.getCartById(null, id)}
                hideModal={() => {
                  this.posStore.closeCartModal();
                }}
              />
            ) : null}
            {this.posStore.orderModal ? (
              <OrderModal close={this.posStore.closeOrderModal} />
            ) : null}
            {this.posStore.customerNoteModalVisible ? (
              <EditCustomerNote
                onClose={() => {
                  this.posStore.setCustomerToEdit(null);
                  this.posStore.setCustomerNoteModalVisible(false);
                }}
              />
            ) : null}
            {this.posStore.showTaxErrorModal ? <TaxErrorModal /> : null}
            {this.posStore.showOutOfStockWarning ? <OutOfStockWarning /> : null}
            {this.posStore.showMultipleMatchingBarcodesError ? (
              <BarcodeMultipleProducts />
            ) : null}
            {this.posStore.showNoMatchingBarcodeError ? (
              <Modal
                onClose={() =>
                  this.posStore.setShowNoMatchingBarcodeError(false)
                }
              >
                <Modal.Header>Barcode not found</Modal.Header>
                <Modal.Content>
                  No products were found matching barcode:{" "}
                  {this.posStore.showNoMatchingBarcodeError}
                </Modal.Content>
              </Modal>
            ) : null}
          </>
        ) : (
          <div>
            <p>Please contact BinderPOS to enable this screen.</p>
          </div>
        )}
      </>
    );
  }
}
