import { useEffect } from "react";

/**
 * useTitle hook - convenience function to set the page title
 *
 * @param {(string|array)} title Page title or array of titles if nested
 */
function useTitle(title) {
  useEffect(() => {
    const titleParts = [];
    if (Array.isArray(title)) {
      titleParts.push([...title]);
    } else if (title.length) {
      titleParts.push(title);
    }
    titleParts.push("BinderPOS");
    const newTitle = titleParts.join(" | ");
    document.title = newTitle;
  }, []);
}

export default useTitle;
