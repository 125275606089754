import React from "react";
import TimeUtils from "../../utils/TimeUtils";
import HtmlComponent from "../generic/HtmlComponent";
import "./EbayStockChangeInfo.scss";

type InfoItemType = { label: string; value: any };

const renderItem = (item: InfoItemType) => (
  <div className="EbayStockChangeInfo__item" key={item.label}>
    <span className="EbayStockChangeInfo__label">{item.label}:</span>
    {item.value}
  </div>
);

const getStatus = (success: boolean, hasOfferOrListing: boolean) => {
  if (!hasOfferOrListing) return "Removed";
  return success ? "Success" : "Error";
};

function EbayStockChangeInfo(props: EbayStockChange) {
  const {
    lastUpdated,
    success,
    binderProductId,
    binderSkuId,
    offerId,
    listingId,
    ebayCondition,
    name,
    mobileDescription,
    description,
    imageUrl,
    newPrice,
    newStockLevel,
    oldPrice,
    oldStockLevel,
    allErrors,
  } = props;

  const statusClass = success
    ? "EbayStockChangeInfo__status--success"
    : "EbayStockChangeInfo__status--problem";

  const infoItems = [
    { label: "Old price", value: oldPrice },
    { label: "New price", value: newPrice },
    { label: "Old stock level", value: oldStockLevel },
    { label: "New stock level", value: newStockLevel },
    { label: "BinderPOS product Id", value: binderProductId },
    { label: "BinderPOS SKU", value: binderSkuId },
    { label: "Offer Id", value: offerId },
    { label: "Listing Id", value: listingId },
    { label: "eBay condition", value: ebayCondition },
    { label: "Listing", value: name },
  ];

  const hasOfferOrListing = offerId !== null || listingId !== null;

  return (
    <div className="EbayStockChangeInfo">
      <div className="EbayStockChangeInfo__header">
        <div className="EbayStockChangeInfo__item">
          <span className="EbayStockChangeInfo__label EbayStockChangeInfo__label--no-min">
            Status:
          </span>
          <span className={statusClass}>
            {getStatus(success, hasOfferOrListing)}
          </span>
        </div>
        <div className="EbayStockChangeInfo__item">
          <span className="EbayStockChangeInfo__label EbayStockChangeInfo__label--no-min">
            Last Updated:
          </span>
          {TimeUtils.convertDateToHumanReadable(lastUpdated)}
        </div>
      </div>

      {allErrors && allErrors.length > 0 ? (
        <div className="EbayStockChangeInfo__error-list">
          {[...allErrors]
            .sort((a, b) => (a.lastUpdated < b.lastUpdated ? 1 : -1))
            .map((allError) =>
              allError.errors ? (
                <div className="EbayStockChangeInfo__error-group">
                  <span className="EbayStockChangeInfo__error-date">
                    {TimeUtils.convertDateToHumanReadable(allError.lastUpdated)}
                  </span>
                  {allError.errors.map((error) => (
                    <div
                      key={error.errorId}
                      className="EbayStockChangeInfo__error"
                    >
                      {error.longMessage ? error.longMessage : error.message}
                      {error.parameters ? (
                        <div className="EbayStockChangeInfo__error-params">
                          {error.parameters.map((param) => (
                            <pre key={param.name}>
                              Name {param.name}: Value {param.value}
                            </pre>
                          ))}
                        </div>
                      ) : null}
                    </div>
                  ))}
                </div>
              ) : null
            )}
        </div>
      ) : null}
      <div className="EbayStockChangeInfo__sections">
        <div className="EbayStockChangeInfo__section">
          {infoItems.map((item) => renderItem(item))}
          <div>
            <span className="EbayStockChangeInfo__label">
              Mobile description:
            </span>
            <HtmlComponent>{mobileDescription}</HtmlComponent>
          </div>
          <div>
            <span className="EbayStockChangeInfo__label">Description:</span>
            <HtmlComponent>{description}</HtmlComponent>
          </div>
        </div>
        <div className="EbayStockChangeInfo__image">
          {imageUrl ? <img src={imageUrl} role="presentation" /> : "No image"}
        </div>
      </div>
    </div>
  );
}

export default EbayStockChangeInfo;
