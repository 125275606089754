import React, { Component } from "react";
import { action } from "mobx";
import "./CustomerLine.scss";
import { ItemList } from "../../pos/ItemStore";
import CustomerAvatar from "../customers/CustomerAvatar";

interface CustomerLineProps {
  store?: Pick<
    ItemList,
    "selectedCustomer" | "validateCart" | "customerResults" | "fCurr"
  >;
  customer: any;
  onSelect: () => void;
}

class CustomerLine extends Component<CustomerLineProps> {
  @action
  selectCustomer = () => {
    this.props.store.selectedCustomer = this.props.customer;
    this.props.store.validateCart();
    this.props.store.customerResults = [];
    this.props.onSelect();
  };

  render() {
    const fC = this.props.store.fCurr;
    return (
      <div className="CustomerLine" onClick={this.selectCustomer} role="button">
        <CustomerAvatar customer={this.props.customer} />
        <div className="CustomerLine__searchInfo">
          <div className="name">
            {this.props.customer.firstName &&
            this.props.customer.firstName.length > 0
              ? this.props.customer.firstName
              : "Unknown"}{" "}
            {this.props.customer.lastName &&
            this.props.customer.lastName.length > 0
              ? this.props.customer.lastName
              : "Unknown"}
          </div>
          <div className="email">
            {this.props.customer.email
              ? this.props.customer.email
              : "No Email Provided"}
          </div>
        </div>
        <span className="CustomerLine__credit">
          {fC(this.props.customer.storeCredit.toFixed(2))}
        </span>
      </div>
    );
  }
}

export default CustomerLine;
