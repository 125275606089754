import { useEffect, useState } from "react";
import { useQueries } from "react-query";

import { GamePriceRule, RarityFloor } from "../types/settings";

import { fetchSupportedCardGames, fetchCardRarities } from "../api/rest/cards";
import {
  fetchPriceRuleSettings,
  fetchDefaultPriceRuleSettings,
} from "../api/rest/pricing";

import { getRemoteConfig } from "../services/firebase";
import { useStoreDetails } from "./storeHooks";

import { arrayHasEntryWithTrueValue } from "../helpers/valueHelpers";

const getGlobalRoundToNearest = (
  roundToNearest: number | null,
  weakCurrency: object | undefined,
  currencyCode: string,
  currencyIsFractional: boolean
) => {
  if (roundToNearest) {
    return roundToNearest;
  }
  if (weakCurrency?.[currencyCode]) {
    return String(weakCurrency[currencyCode]);
  }
  if (currencyIsFractional) {
    return "0.1";
  } else {
    return "1";
  }
};

export const mergePriceRules = (
  isLoading: boolean,
  isSuccess: boolean,
  data: {
    cardGames: Game[];
    priceRulesSettings: GamePriceRule[];
    defaultPriceRulesSettings: GamePriceRule;
    gameRarities: any;
  },
  weakCurrency: object | undefined,
  currencyCode: string
) => {
  const {
    cardGames,
    priceRulesSettings = [],
    defaultPriceRulesSettings,
    gameRarities,
  } = data;

  if (isLoading || !isSuccess || !weakCurrency || !currencyCode) {
    return [];
  } else {
    return cardGames
      .map((game, index) => {
        const { gameId, gameName, isSealed } = game;

        if (isSealed) {
          return null;
        }

        let gameFound = false;
        const priceRule: GamePriceRule & { exists: boolean } = {
          exists: false,
          game: gameId,
          gameName: gameName,
          setName: "",
          priceMarkup: "",
          priceMultiplier: "",
          updatePricing: "",
          noRounding: "",
          roundToNearest: "",
          tcgPlayerPriceType: "",
          useTCGPlayerPricing: "",
          rarityFloors: [],
        };

        priceRulesSettings &&
          priceRulesSettings.forEach((priceRulesForGame) => {
            if (priceRulesForGame.game == gameId) {
              const {
                setName,
                priceMarkup,
                priceMultiplier,
                updatePricing,
                noRounding,
                roundToNearest,
                tcgPlayerPriceType,
                useTCGPlayerPricing,
              } = priceRulesForGame;
              gameFound = true;
              priceRule.exists = true;
              priceRule.game = gameId;
              priceRule.gameName = gameName;
              priceRule.setName = setName;
              priceRule.priceMarkup = priceMarkup || "";
              priceRule.priceMultiplier = priceMultiplier || "";
              priceRule.updatePricing = String(updatePricing);
              priceRule.noRounding = noRounding || "";
              priceRule.roundToNearest = roundToNearest || "";
              priceRule.tcgPlayerPriceType = tcgPlayerPriceType || "";
              priceRule.useTCGPlayerPricing = useTCGPlayerPricing || "";
              const rarityFloors = [];
              gameRarities[index] &&
                gameRarities[index].data.forEach((rarity: string) => {
                  const rarityFloorItem: Partial<RarityFloor> = {};

                  const foundRarity = priceRulesForGame.rarityFloors.find(
                    (rarityFloor) =>
                      rarityFloor.rarity.toLowerCase() == rarity.toLowerCase()
                  );

                  if (foundRarity) {
                    rarityFloorItem.rarity = rarity;
                    rarityFloorItem.price = foundRarity.price;
                    rarityFloorItem.id = foundRarity.id;
                  }

                  if (!foundRarity) {
                    rarityFloorItem.rarity = rarity;
                    rarityFloorItem.id = null;
                    rarityFloorItem.price = "";
                  }
                  rarityFloors.push(rarityFloorItem);
                });

              priceRule.rarityFloors = rarityFloors;
            }
          });

        if (!gameFound) {
          const {
            priceMarkup,
            priceMultiplier,
            updatePricing,
            noRounding,
            tcgPlayerPriceType,
            useTCGPlayerPricing,
          } = defaultPriceRulesSettings;

          priceRule.game = gameId;
          priceRule.gameName = gameName;
          priceRule.setName = null;
          priceRule.priceMarkup = priceMarkup || "";
          priceRule.priceMultiplier = priceMultiplier || "";
          priceRule.updatePricing = updatePricing || "";
          priceRule.noRounding = noRounding || "";
          priceRule.roundToNearest = "";
          priceRule.tcgPlayerPriceType = tcgPlayerPriceType || "";
          priceRule.useTCGPlayerPricing = useTCGPlayerPricing || "";

          const rarityFloors = [];
          gameRarities[index] &&
            gameRarities[index].data.forEach((rarity) => {
              const rarityFloorItem: Partial<RarityFloor> = {
                rarity,
                price: "",
              };
              rarityFloors.push(rarityFloorItem);
            });

          priceRule.rarityFloors = rarityFloors;
        }

        return priceRule;
      })
      .filter(Boolean);
  }
};

/**
 * useMergedPriceRules hook
 *
 */
const useMergedPriceRulesQueries = () => {
  const results = useQueries([
    {
      queryKey: ["cardGames", 1],
      queryFn: fetchSupportedCardGames,
      staleTime: Infinity,
    },
    {
      queryKey: ["priceRulesSettings", 2],
      queryFn: fetchPriceRuleSettings,
      staleTime: Infinity,
    },
    {
      queryKey: ["defaultPriceRulesSettings", 3],
      queryFn: fetchDefaultPriceRuleSettings,
      staleTime: Infinity,
    },
  ]);

  const [cardGames, priceRulesSettings, defaultPriceRulesSettings] = results;

  const gameRarities = useQueries(
    !arrayHasEntryWithTrueValue("isLoading", results) &&
      arrayHasEntryWithTrueValue("isSuccess", results)
      ? cardGames.data.map((game) => {
          return {
            queryKey: ["gameRarities", game.gameId],
            queryFn: () => fetchCardRarities(game.gameId),
            staleTime: Infinity,
            enabled: !!game.gameId,
          };
        })
      : []
  );

  return {
    isError: arrayHasEntryWithTrueValue("isError", [
      ...results,
      ...gameRarities,
    ]),
    isLoading: arrayHasEntryWithTrueValue("isLoading", [
      ...results,
      ...gameRarities,
    ]),
    isSuccess: arrayHasEntryWithTrueValue("isSuccess", [
      ...results,
      ...gameRarities,
    ]),
    data: {
      cardGames: cardGames.data,
      priceRulesSettings: priceRulesSettings.data,
      defaultPriceRulesSettings: defaultPriceRulesSettings.data,
      gameRarities,
    },
  };
};

export const useMergedPriceRules = () => {
  const { isLoading, isSuccess, isError, data } = useMergedPriceRulesQueries();
  const [weakCurrency, setWeakCurrency] = useState<object | undefined>(
    undefined
  );

  const { currencyCode, currencyIsFractional } = useStoreDetails();

  const fetchRemoteConfig = async () => {
    const weakCurrency = await getRemoteConfig("weakCurrencyConfig");
    setWeakCurrency(JSON.parse(weakCurrency?.asString() || "{}"));
  };

  useEffect(() => {
    fetchRemoteConfig();
  }, []);

  const mergedPriceRules = mergePriceRules(
    isLoading,
    isSuccess,
    data,
    weakCurrency,
    currencyCode
  );

  return {
    isSuccess,
    isLoading,
    isError,
    mergedPriceRules,
    globalRoundToNearest: getGlobalRoundToNearest(
      data.defaultPriceRulesSettings?.roundToNearest,
      weakCurrency,
      currencyCode,
      currencyIsFractional
    ),
  };
};
