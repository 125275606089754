import { OrderData } from "../types/order";
import { mapCustomerSettings } from "../hooks/settingHooks";
import { mapCustomer } from "./customer";
import { mapCartWithTotals } from "./cartTotals";

export function mapOrderDetails(data: any): OrderData {
  const customerSettings = mapCustomerSettings(
    data.BinderCustomer.BinderCustomerSettings
  );
  const cart = mapCartWithTotals(data, customerSettings);
  return {
    customerSettings,
    cart: cart,
    selectedCustomer: mapCustomer(data),
    till: {
      id: data.BinderCustomerTill.id,
      name: data.BinderCustomerTill.name,
      description: data.BinderCustomerTill.description,
    },
  };
}
