import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  addNewTill,
  archiveTill,
  disableTill,
  enableTill,
  fetchTills,
} from "../api/rest/tills";
import { Till } from "../types/pos";

const LONG_LIFE_DATA_STALE_TIME = 5 * 60 * 1000; // ms

type FetchTillsParams = Record<string, unknown>;

export const useFetchTills = (params: FetchTillsParams) =>
  useQuery(["tills", params], () => fetchTills(params), {
    staleTime: LONG_LIFE_DATA_STALE_TIME,
  });

export type AddNewTillParams = Pick<Till, "name" | "description">;

export const useAddNewTill = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    (params: AddNewTillParams) => addNewTill(params),
    {
      onSuccess: () =>
        queryClient.invalidateQueries("tills", { refetchInactive: true }),
    }
  );
  return { isMutating, ...rest };
};

export const useArchiveTill = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    (tillId: number) => archiveTill(tillId),
    {
      onSuccess: () =>
        queryClient.invalidateQueries("tills", { refetchInactive: true }),
    }
  );
  return { isMutating, ...rest };
};

export const useDisableTill = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    (tillId: number) => disableTill(tillId),
    {
      onSuccess: () =>
        queryClient.invalidateQueries("tills", { refetchInactive: true }),
    }
  );
  return { isMutating, ...rest };
};

export const useEnableTill = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    (tillId: number) => enableTill(tillId),
    {
      onSuccess: () =>
        queryClient.invalidateQueries("tills", { refetchInactive: true }),
    }
  );
  return { isMutating, ...rest };
};
