import React from "react";
import { Table, Column, Cell, SortType } from "rsuite-table";
import "rsuite-table/dist/css/rsuite-table.css";
import "../../../css/rstable-overwrites.scss";
import CustomHeaderCell from "../reports/CustomHeader";
import { TextInputFilter } from "../reports/CellTypes";
import { TimeUtils } from "../../utils";
import CustomerAvatar from "../customers/CustomerAvatar";
import ButtonComponent from "../generic/ButtonComponent";
import Spinner from "../generic/Spinner";
import "./BuylistTable.scss";
import { POS_BUYLIST_COMPLETED_DATE_FILTER } from "../../constants/featureFlags";
import {
  VIEW_BUYLIST_CLICKED,
  VIEW_BUYLIST_ID_CLICKED,
} from "../../constants/eventsTracked";
import LinkComponent from "../generic/LinkComponent";
import { useFeatureFlag } from "../../hooks/globalSettingsHooks";
import { segmentAnalytics } from "../services/Analytics";
import { buylistStatusMap } from "../../views/buylist/buylistsTableHelpers";
import { capitalizeFirstLetter } from "../../utils/formatting";

interface BuylistTableProps {
  buylistType: string;
  buylists: Array<any>;
  isLoading?: boolean;
  filterValues: Record<string, any>;
  viewUrl: string;
  updateFilterValue: Function;
  onColumnSort: (dataKey: string, sortType?: SortType) => void;
  sortBy: { key: string; order: SortType };
}

function PaymentTypeSelect({ value, setValue }) {
  return (
    <select
      onChange={(event) => setValue(event.target.value)}
      value={value}
      aria-label="Payment type"
    >
      <option value="">- All -</option>
      <option>Cash</option>
      <option>Store Credit</option>
    </select>
  );
}

function DateRenderer(date: string) {
  if (!date) return "--";
  return <div>{TimeUtils.convertDateToHumanReadable(date)}</div>;
}

function LoaderRenderer() {
  return (
    <div className="BuylistTable__loader">
      <Spinner isLoading={true}>
        <></>
      </Spinner>
    </div>
  );
}

function BuylistTable(props: BuylistTableProps) {
  const {
    buylistType,
    filterValues,
    updateFilterValue,
    buylists,
    isLoading,
    sortBy,
    onColumnSort,
    viewUrl,
  } = props;
  const title = capitalizeFirstLetter(`${buylistType} buylists`);
  const { id = "", customer = "", email = "", paymentType = "" } = filterValues;
  const posBuylistCompletedDateFilterEnabled = useFeatureFlag(
    POS_BUYLIST_COMPLETED_DATE_FILTER
  );

  const linkRenderer = (id: number) => {
    return (
      <ButtonComponent
        isLink
        compact
        to={`${viewUrl}/${id}`}
        icon="fas fa-caret-right"
        iconPosition="right"
        segmentEventName={VIEW_BUYLIST_CLICKED}
        segmentEventProperties={{
          buylist_type: title,
        }}
      >
        View
      </ButtonComponent>
    );
  };

  return (
    <div className="BuylistTable">
      <div style={{ height: `${buylists ? buylists.length * 50 + 80 : 0}px` }}>
        <Table
          data={buylists}
          headerHeight={80}
          loading={isLoading}
          sortColumn={sortBy.key}
          sortType={sortBy.order}
          onSortColumn={onColumnSort}
          renderEmpty={() => (
            <div className="BuylistTable__no-results">No data to display</div>
          )}
          renderLoading={LoaderRenderer}
          className="rs-table--multi-header"
          autoHeight
        >
          <Column key="id" width={110} sortable resizable>
            <CustomHeaderCell title="#">
              <TextInputFilter
                aria-label="Id filter"
                title="Id"
                value={id}
                setValue={(value: string) => updateFilterValue({ id: value })}
              />
            </CustomHeaderCell>
            <Cell dataKey="id">
              {(rowData) => (
                <LinkComponent
                  to={`${viewUrl}/${rowData?.id}`}
                  onClick={() => {
                    segmentAnalytics.track(VIEW_BUYLIST_ID_CLICKED, {
                      buylist_type: title,
                    });
                  }}
                >
                  {rowData?.id}
                </LinkComponent>
              )}
            </Cell>
          </Column>
          <Column key="col_avatar" width={40}>
            <CustomHeaderCell title="" srOnlyTitle="Avatar" />
            <Cell dataKey="avatar">
              {(rowData) => (
                <CustomerAvatar
                  customer={rowData.ShopifyCustomer}
                  className={
                    rowData.paymentType == "Cash" ? "avatar red" : "avatar blue"
                  }
                />
              )}
            </Cell>
          </Column>
          <Column key="customer" sortable flexGrow={1}>
            <CustomHeaderCell title="Customer">
              <TextInputFilter
                aria-label="Customer filter"
                title="Customer"
                value={customer}
                setValue={(value: string) =>
                  updateFilterValue({ customer: value })
                }
              />
            </CustomHeaderCell>
            <Cell dataKey="ShopifyCustomer.firstName">
              {(rowData) =>
                [
                  rowData?.ShopifyCustomer?.firstName,
                  rowData?.ShopifyCustomer?.lastName,
                ]
                  .filter(Boolean)
                  .join(" ")
              }
            </Cell>
          </Column>
          <Column key="email" width={230} sortable flexGrow={1}>
            <CustomHeaderCell title="Email">
              <TextInputFilter
                aria-label="Email filter"
                title="Email"
                value={email}
                setValue={(value: string) =>
                  updateFilterValue({ email: value })
                }
              />
            </CustomHeaderCell>
            <Cell dataKey="ShopifyCustomer.email">
              {(rowData) => rowData?.ShopifyCustomer?.email}
            </Cell>
          </Column>
          <Column key="dateSubmitted" width={140} sortable>
            <CustomHeaderCell title="Date Submitted" />
            <Cell dataKey="dateSubmitted">
              {(rowData) => DateRenderer(rowData?.dateSubmitted)}
            </Cell>
          </Column>
          {buylistType === buylistStatusMap.completed.name &&
          posBuylistCompletedDateFilterEnabled ? (
            <Column key="dateCompleted" width={140} sortable>
              <CustomHeaderCell title="Date Completed" />
              <Cell dataKey="dateCompleted">
                {(rowData) => DateRenderer(rowData?.dateCompleted)}
              </Cell>
            </Column>
          ) : null}
          {buylistType === buylistStatusMap.approved.name &&
          posBuylistCompletedDateFilterEnabled ? (
            <Column key="dateApproved" width={140} sortable>
              <CustomHeaderCell title="Date Approved" />
              <Cell dataKey="dateApproved">
                {(rowData) => DateRenderer(rowData?.dateApproved)}
              </Cell>
            </Column>
          ) : null}
          <Column key="col_paymentType" width={120} sortable>
            <CustomHeaderCell title="Payment Type">
              <PaymentTypeSelect
                value={paymentType}
                setValue={(value: string) =>
                  updateFilterValue({ paymentType: value })
                }
              />
            </CustomHeaderCell>
            <Cell dataKey="paymentType" />
          </Column>
          <Column key="col_link" width={120}>
            <CustomHeaderCell title="" srOnlyTitle="Action" />
            <Cell dataKey="id">{(rowData) => linkRenderer(rowData?.id)}</Cell>
          </Column>
        </Table>
      </div>
    </div>
  );
}

export default BuylistTable;
