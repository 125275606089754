import { Tender } from "../../types/pos";

export function disableFinalizeSale(
  balance: string,
  changeDue: string,
  tenders: Tender[]
) {
  const parsedBalance = parseFloat(balance);
  const parsedChangeDue = changeDue == "" ? 0 : parseFloat(changeDue);
  if (!hasAtLeastOneTender(tenders) && parsedBalance !== 0) {
    return true;
  }
  if (parsedBalance + parsedChangeDue !== 0) {
    return true;
  }
  return false;
}

export function hasAtLeastOneTender(tenders: any) {
  return tenders.some(
    (tender) => tender.amount !== 0 && tender.amount !== "0.00"
  );
}

export function parseShopifyTagCommand(tags: string) {
  if (!tags) {
    return [];
  }
  const regexString = /^([A-Z]{2,3})({[\w. ]+})+([\w. ])*$/;
  const tagCommands = tags
    .split(",")
    //remove the leading spaces
    .map((tag) => tag.trim())
    .filter((tag) => tag.match(regexString))
    .map((tag) => {
      //the command is the first 2-3 characters of the tag
      const command = tag.match(regexString)[1];
      //remove the command from the tag to get the rest of the tag
      //this should be strings enclosed in {} followed by an optional final string
      const argString = tag.replace(/^([A-Z]{2,3})/, "");
      //turn the string of curly braced strings into a comma separated list
      const args = argString
        .replace(/}{/g, ",")
        .replace(/}/g, ",")
        .replace(/{/g, "")
        //split on the comma
        .split(",")
        //remove empty strings
        .filter((arg) => arg !== "");
      return {
        command,
        args,
      };
    });

  return tagCommands;
}
