import { GetStoreCreditOutstandingQuery } from "../../generated/reporting";

export const formatStoreCreditOutstanding = (
  report?: GetStoreCreditOutstandingQuery
) => {
  const reportData = report?.Customers;
  if (!reportData) {
    return [];
  }
  // Add an ID field so the table can use it as a key
  return reportData.map((row, index) => ({
    ...row,
    phone: row?.phone ?? "",
    email: row?.email ?? "",
    customerName:
      row?.firstName || row?.lastName
        ? `${row?.firstName} ${row?.lastName}`
        : "",
    id: index,
  }));
};
