import { _fetch } from "..";
import { BASE_URL } from "../../constants/api";

export type QueuedJobsParams = {
  type: string;
  limit: number;
  offset: number;
};

export type QueuedJobStatusParams = {
  jobId: number;
  type: string;
};

export const fetchProductQueuedJobs = async ({
  type,
  offset,
  limit,
}: QueuedJobsParams) => {
  return await _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/products/queuedJobs?type=${type}&offset=${offset}&limit=${limit}`,
  });
};

export const fetchProductQueuedJobStatus = async ({
  jobId,
  type,
}: QueuedJobStatusParams) => {
  return await _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/products/queuedJobs/${jobId}/type/${type}`,
  });
};
