import { BASE_URL, CSV_URL } from "../../constants/api";
import { _fetch, _fetchWithFile } from "../../api";

export async function fetchCustomers(keyword = null) {
  if (!keyword || keyword.length < 1) {
    return;
  }
  return _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/customers?keyword=${encodeURIComponent(keyword)}`,
  }).then((data) =>
    data.map((customer) => {
      const { id, firstName, lastName, email } = customer;
      return { value: id, label: `${firstName} ${lastName} (${email})` };
    })
  );
}

export async function adjustCustomerCredit(toUpdate) {
  return await _fetch({
    method: "POST",
    endpoint: `${BASE_URL}/customers/adjustStoreCredit`,
    payload: toUpdate,
  });
}

export async function fetchCustomerCreditHistory(customerId, offset, limit) {
  return await _fetch({
    method: "GET",
    endpoint:
      `${BASE_URL}/customers/byId/${customerId}/creditHistory?` +
      `offset=${offset}&` +
      `limit=${limit}`,
  });
}

export async function fetchCustomerById(id) {
  return await _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/customers/byId/${id}`,
  });
}

export async function addCustomer(customerToAdd) {
  return await _fetch({
    method: "POST",
    endpoint: `${BASE_URL}/customers/add`,
    payload: customerToAdd,
  });
}

export async function updateCustomer(customerToUpdate) {
  return await _fetch({
    method: "PUT",
    endpoint: `${BASE_URL}/customers/update`,
    payload: customerToUpdate,
  });
}

export async function postCustomerCsv(file) {
  return await _fetchWithFile({
    endpoint: `${CSV_URL}/upload/customers`,
    payload: file,
  });
}

export async function fetchCustomerList(offset, limit, keyword) {
  return await _fetch({
    method: "GET",
    endpoint:
      `${BASE_URL}/customers?` +
      `offset=${offset}` +
      `&limit=${limit}` +
      `${keyword ? `&keyword=${encodeURIComponent(keyword)}` : ""}`,
  });
}

export const refreshCustomerList = () =>
  _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/customers?refresh=true`,
  });
