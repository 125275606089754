import React, { Fragment, useEffect } from "react";
import { API_STATE } from "../../constants/api";
import { Table, Column, Cell } from "rsuite-table";
import "rsuite-table/dist/css/rsuite-table.css";
import "../../../css/rstable-overwrites.scss";
import CustomHeaderCell from "../../components/reports/CustomHeader";
import { ReportsMenuStructure } from "../../menuStructures";
import { Pagination, ReportControls } from "../../components/reports";
import {
  CurrencyRenderer,
  TextInputFilter,
} from "../../components/reports/CellTypes";
import { useReportsDetails } from "../../hooks/reportsHooks";
import { useStoreDetails } from "../../hooks/storeHooks";
import { useShowError } from "../../hooks/errorHooks";
import { storeCreditOutstandingCSVHeaders } from "../../constants/reportCSVHeaders";
import { rowPerPageOptions } from "../../constants/reports";
import SetActiveMenu from "../../menuStructures/SetActiveMenu";
import { useFetchStoreCreditOutstanding } from "../../hooks/reports/useFetchStoreCreditOutstanding";

function StoreCreditOutstanding() {
  const {
    apiState,
    currentPage,
    filterValues,
    overrideTableHeight,
    rowsPerPage,
    sortColumn,
    tableData,
    computedTableData,
    reset,
    setApiState,
    setCurrentPage,
    setTableRowsPerPage,
    setFilters,
    setSortingColumn,
    setSourceDataDetails,
    updateFilterValue,
  } = useReportsDetails();
  const { currencyCode } = useStoreDetails();
  const showError = useShowError();

  const { refetch } = useFetchStoreCreditOutstanding({ enabled: false });

  useEffect(() => {
    document.title = "Reports | BinderPOS";
    reset();
    setFilters(filters);
    loadData();
  }, []);

  const filters = [
    {
      name: "customer name",
      condition: (filterValues) => filterValues?.customerName?.length,
      filterFunction: (customer, filterValues) =>
        (customer.firstName &&
          customer.firstName
            .toLowerCase()
            .includes(filterValues?.customerName?.toLowerCase())) ||
        (customer.lastName &&
          customer.lastName
            .toLowerCase()
            .includes(filterValues?.customerName?.toLowerCase())),
    },
    {
      name: "customer email",
      condition: (filterValues) => filterValues?.customerEmail?.length,
      filterFunction: (customer, filterValues) =>
        customer.email &&
        customer.email
          .toLowerCase()
          .includes(filterValues?.customerEmail?.toLowerCase()),
    },
    {
      name: "customer phone",
      condition: (filterValues) => filterValues?.customerPhone?.length,
      filterFunction: (customer, filterValues) =>
        customer.phone &&
        String(customer.phone).includes(filterValues?.customerPhone),
    },
  ];

  const loadData = () => {
    setApiState(API_STATE.LOADING);
    refetch()
      .then(({ data }) => {
        setSourceDataDetails(data);
        setApiState(API_STATE.SUCCESS);
      })
      .catch((error) => {
        setApiState(API_STATE.ERROR);
        showError(
          error,
          "Failed to load report",
          "There was an error retrieving your outstanding store credit report. Please try again"
        );
      });
  };

  const {
    customerPhone = "",
    customerName = "",
    customerEmail = "",
  } = filterValues;

  const csvFilename = "Outstanding Store Credit Report.csv";

  const data = computedTableData();

  return (
    <Fragment>
      <SetActiveMenu menuStructure={ReportsMenuStructure} />
      <div className="app-header">
        <h1 className="title">
          <span className="header-text">Outstanding Store Credit Report</span>
        </h1>
      </div>
      <div className="report">
        <ReportControls
          csvHeaders={storeCreditOutstandingCSVHeaders}
          csvFilename={csvFilename}
        />
        {apiState !== API_STATE.INITIAL && (
          <div className="data-table">
            <div className="table-container">
              <Table
                data={data}
                headerHeight={80}
                loading={apiState == API_STATE.LOADING}
                sortColumn={sortColumn.key}
                sortType={sortColumn.order}
                onSortColumn={setSortingColumn}
                renderEmpty={() => <div>No data to display</div>}
                className="rs-table--multi-header"
                height={overrideTableHeight}
                fillHeight={overrideTableHeight === 0}
              >
                <Column
                  key="col_customerName"
                  width={120}
                  flexGrow={1}
                  sortable
                  resizable
                >
                  <CustomHeaderCell title="Customer Name">
                    <TextInputFilter
                      title="Customer name"
                      aria-label="Filter by customer name"
                      value={customerName}
                      setValue={(value) =>
                        updateFilterValue("customerName", value)
                      }
                    />
                  </CustomHeaderCell>
                  <Cell dataKey="customerName" />
                </Column>
                <Column
                  key="col_customerEmail"
                  width={120}
                  flexGrow={1}
                  sortable
                  resizable
                >
                  <CustomHeaderCell title="Customer Email">
                    <TextInputFilter
                      title="Customer Email"
                      aria-label="Filter by customer email"
                      value={customerEmail}
                      setValue={(value) =>
                        updateFilterValue("customerEmail", value)
                      }
                    />
                  </CustomHeaderCell>
                  <Cell dataKey="email" />
                </Column>
                <Column
                  key="col_customerPhone"
                  width={150}
                  flexGrow={1}
                  sortable
                  resizable
                >
                  <CustomHeaderCell title="Phone Number">
                    <TextInputFilter
                      title="Customer phone"
                      aria-label="Filter by customer phone"
                      value={customerPhone}
                      setValue={(value) =>
                        updateFilterValue("customerPhone", value)
                      }
                    />
                  </CustomHeaderCell>
                  <Cell dataKey="phone" />
                </Column>
                <Column
                  key="col_storeCredit"
                  width={150}
                  flexGrow={1}
                  align="right"
                  sortable
                  resizable
                >
                  <CustomHeaderCell title="Store Credit" />
                  <Cell dataKey="storeCredit">
                    {(rowData) =>
                      CurrencyRenderer({
                        cellData: rowData.storeCredit,
                        currency: currencyCode,
                        style: { paddingRight: "8px" },
                      })
                    }
                  </Cell>
                </Column>
              </Table>
            </div>
            <Pagination
              apiState={apiState}
              currentPage={currentPage}
              totalRowCount={tableData.length}
              rowsPerPage={rowsPerPage}
              rowPerPageOptions={rowPerPageOptions}
              setCurrentPage={setCurrentPage}
              setRowsPerPage={setTableRowsPerPage}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default StoreCreditOutstanding;
