import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { doUncompleteBuylist } from "../../api/rest/buylist";
import { UNCOMPLETE_BUYLIST_CLICKED } from "../../constants/eventsTracked";
import { notify } from "../../helpers/notificationHelpers";
import ConfirmationModal from "../generic/ConfirmationModal";
import { segmentAnalytics } from "../services/Analytics";
import ButtonComponent from "../generic/ButtonComponent";

interface UncompleteBuylistProps {
  buylistId: number;
}

function UncompleteBuylist(props: UncompleteBuylistProps) {
  const { buylistId } = props;
  const [isUncompleting, setIsUncompleting] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const history = useHistory();

  const handleConfirmClick = () => {
    setShowConfirmation(false);
    setIsUncompleting(true);
    doUncompleteBuylist(buylistId)
      .then(() => {
        segmentAnalytics.track(UNCOMPLETE_BUYLIST_CLICKED);
        setIsUncompleting(false);
        history.push(`/buylists/pending/moreDetail/${buylistId}`);
      })
      .catch(() => {
        notify.error("Failed to uncomplete this buylist.");
        setIsUncompleting(false);
      });
  };

  const handleCancelClick = () => setShowConfirmation(false);

  const handleUncompleteClick = () => setShowConfirmation(true);

  return (
    <>
      <ButtonComponent
        onClick={() => handleUncompleteClick()}
        disabled={isUncompleting}
      >
        {isUncompleting ? "Uncompleting" : "Uncomplete"}
      </ButtonComponent>
      {showConfirmation ? (
        <ConfirmationModal
          cancelAction={handleCancelClick}
          confirmAction={handleConfirmClick}
          title="Uncomplete Buylist"
        >
          Are you sure you want move this buylist back to Pending?
        </ConfirmationModal>
      ) : null}
    </>
  );
}

export default UncompleteBuylist;
