import React from "react";

interface SimpleErrorProps {
  errorDetails: string;
}

function SimpleError(props: SimpleErrorProps) {
  const { errorDetails } = props;

  return <div className="field">{errorDetails}</div>;
}

export default SimpleError;
