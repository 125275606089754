import { UseQueryOptions, QueryKey } from "react-query";
import {
  GetLedgerEntriesQuery,
  GetLedgerEntriesQueryVariables,
  useGetLedgerEntriesQuery,
} from "../../generated/reporting";

export const useFetchLedgerEntries = (
  variables: GetLedgerEntriesQueryVariables,
  options: UseQueryOptions<GetLedgerEntriesQuery, unknown, any[], QueryKey> = {}
) =>
  useGetLedgerEntriesQuery(variables, {
    select: (data) => data?.POSReports?.LedgerEntries,
    keepPreviousData: true,
    staleTime: 60_000,
    ...options,
  });
