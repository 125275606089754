import { UseQueryOptions, QueryKey } from "react-query";
import {
  GetBuylistReportQuery,
  GetBuylistReportQueryVariables,
  useGetBuylistReportQuery,
} from "../../generated/reporting";
import { formatBuylistReport } from "../../transformers/reports/formatBuylistReport";
import { BuylistReportData } from "../../types/reports";

export const useFetchBuylistReport = (
  variables: GetBuylistReportQueryVariables,
  options: UseQueryOptions<
    GetBuylistReportQuery,
    unknown,
    BuylistReportData[],
    QueryKey
  > = {}
) =>
  useGetBuylistReportQuery(variables, {
    select: (report) => formatBuylistReport(report),
    keepPreviousData: true,
    staleTime: 60_000,
    ...options,
  });
