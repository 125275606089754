import { UseQueryOptions, QueryKey } from "react-query";
import {
  GetSalesByOrderBetaReportQuery,
  GetSalesByOrderBetaReportQueryVariables,
  useGetSalesByOrderBetaReportQuery,
} from "../../generated/reporting";
import { formatSalesByOrderBeta } from "../../transformers/reports/formatSalesByOrderBeta";
import { SalesByOrderBetaData } from "../../types/reports";

export const useFetchSalesByOrderBeta = (
  variables: GetSalesByOrderBetaReportQueryVariables,
  options: UseQueryOptions<
    GetSalesByOrderBetaReportQuery,
    unknown,
    SalesByOrderBetaData,
    QueryKey
  > = {}
) =>
  useGetSalesByOrderBetaReportQuery(variables, {
    select: (report) => formatSalesByOrderBeta(report),
    keepPreviousData: true,
    staleTime: 60_000,
    ...options,
  });
