import { _fetch, _fetchWithFormData } from "..";
import { BASE_URL, API_V2_BASE_URL } from "../../constants/api";
import { CatalogCategory } from "../../types/catalogSettings";
import { LocationSettings } from "../../types/settings";

export const fetchCustomerSettings = async () => {
  return await _fetch({
    method: "GET",
    endpoint: `${API_V2_BASE_URL}/customer/settings`,
  });
};

export const fetchCustomerVariants = async () => {
  return await _fetch({
    method: "GET",
    endpoint: `${API_V2_BASE_URL}/customer/variants`,
  });
};

export async function updateMyVariant(variantId: number, multiplier: number) {
  return await _fetch({
    method: "PUT",
    endpoint: `${API_V2_BASE_URL}/customer/variants/${variantId}`,
    payload: {
      multiplier,
    },
  });
}

export async function fetchStoreSetting(settingName) {
  return await _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/settings/${settingName}`,
  });
}

export async function fetchCustomerSetting(settingName) {
  return await _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/settings/${settingName}/forMe`,
  });
}

export async function updateCustomerSetting(setting) {
  return await _fetch({
    method: "POST",
    endpoint: `${BASE_URL}/settings/save`,
    payload: setting,
  });
}

export async function fetchSupportedProducts() {
  return await _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/cards/games/supported`,
  });
}

export async function fetchProductList() {
  return await _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/products/productTypes`,
  });
}

export async function fetchWPNReportProducts() {
  return await _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/settings/wpnReportTypes/forMe`,
  });
}

export async function saveWPNReportProducts(products = []) {
  return await _fetch({
    method: "POST",
    endpoint: `${BASE_URL}/settings/save`,
    payload: {
      id: null,
      settingName: "wpnReportTypes",
      settingValue: JSON.stringify(products),
      binderCustomerId: null,
    },
  });
}

export async function fetchStoreLogoUrl() {
  return await _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/settings/logo`,
  });
}

export async function saveStoreLogoUrl(logo) {
  const formData = new FormData();
  formData.append("logo", logo);
  return await _fetchWithFormData({
    method: "POST",
    endpoint: `${BASE_URL}/settings/logo`,
    payload: formData,
  });
}

export async function fetchLocations(): Promise<LocationSettings> {
  return await _fetch({
    method: "GET",
    endpoint: `${API_V2_BASE_URL}/shopify/locations`,
  });
}

export async function updateLocations(locationData) {
  return await _fetch({
    method: "PUT",
    endpoint: `${API_V2_BASE_URL}/shopify/locations`,
    payload: locationData,
  });
}

export async function fetchStoreSettings() {
  return await _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/customer/settings`,
  });
}

export async function fetchStoreDetails() {
  return await _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/settings/store`,
  });
}

export async function updateStoreDetails(storeSettingsToSave) {
  return await _fetch({
    method: "POST",
    endpoint: `${BASE_URL}/settings/store`,
    payload: storeSettingsToSave,
  });
}

export async function fetchCustomerTillSetting(setting, tillId) {
  return _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/pos/settings/forTill/${tillId}/${setting}`,
  });
}

export type UpdateCustomerTillSettingReqBody = {
  settingName: string;
  settingValue: string;
  binderCustomerTillId: number;
};

export async function updateCustomerTillSetting(
  setting: UpdateCustomerTillSettingReqBody
) {
  return _fetch({
    method: "POST",
    endpoint: `${BASE_URL}/pos/settings/forTill/save`,
    payload: setting,
  });
}

export async function fetchCatalogs(
  allCategories = false
): Promise<CatalogCategory[]> {
  return _fetch({
    method: "GET",
    endpoint: `${API_V2_BASE_URL}/catalogManagement/customerCatalogs?allCategories=${allCategories}`,
  });
}

export async function updateCatalogEnabled(
  gameCode: string,
  enabled: boolean
): Promise<unknown> {
  const action = enabled ? "enable" : "disable";
  return _fetch({
    method: "POST",
    endpoint: `${API_V2_BASE_URL}/catalogManagement/customerCatalogs/${gameCode}/${action}?pushMissing=true`,
  });
}

export async function fetchScreenSettings(): Promise<Record<string, boolean>> {
  return await _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/screens/forMe`,
  });
}
