import { CatalogCategory, CatalogGroup } from "../types/catalogSettings";
import { gameLogos } from "../constants/catalog";

export const groupCatalogs = (catalogs: CatalogCategory[]): CatalogGroup[] => {
  const groupedCatalogs = {};
  (catalogs || []).forEach((catalog) => {
    const { gameName } = catalog;
    if (gameName in groupedCatalogs) {
      groupedCatalogs[gameName].catalogs.push(catalog);
    } else {
      groupedCatalogs[gameName] = {
        logo: gameLogos[gameName],
        title: gameName,
        catalogs: [catalog],
      };
    }
  });
  return Object.values(groupedCatalogs);
};
