import { QueryKey, UseQueryOptions } from "react-query";
import {
  GetCartNotesReportQuery,
  useGetCartNotesReportQuery,
} from "../../generated/graphql";
import { formatCartNotesReport } from "../../transformers/reports/formatCartNotesReport";
import { CartNotesReport } from "../../types/reports";

type CartNotesParams = {
  startDate?: string;
  toDate?: string;
  timeZone?: string;
  limit?: number;
  offset?: number;
};

export const useFetchCartNotes = (
  params: CartNotesParams,
  options: UseQueryOptions<
    GetCartNotesReportQuery,
    unknown,
    CartNotesReport[],
    QueryKey
  > = {}
) => {
  const {
    offset: skip = 0,
    limit: take = 20,
    toDate: endDate,
    ...rest
  } = params;
  return useGetCartNotesReportQuery(
    {
      skip,
      take,
      where: {
        ...rest,
        endDate,
      },
    },
    {
      staleTime: 60_000,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      refetchOnMount: "always",
      select: ({ getAllCarts: orders }) =>
        orders.map((order) => formatCartNotesReport(order)),
      ...options,
    }
  );
};
