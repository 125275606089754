import { GetSalesByTenderBetaReportQuery } from "../../generated/reporting";
import { SalesByTenderBetaData } from "../../types/reports";
import { formatTenderKind } from "../helpers/formatTenderKind";

export const formatSalesByTenderBeta = (
  report?: GetSalesByTenderBetaReportQuery
): SalesByTenderBetaData => {
  let salesByTender = report?.Metrics?.SalesByTender;
  if (!salesByTender) salesByTender = [];
  const rows = [];
  salesByTender.forEach((row) => {
    row.tenders?.forEach((tender) => {
      if (!tender) return;
      rows.push({
        id: tender.id,
        dateCreated: tender.dateCreated,
        orderId: row.orderId,
        source: row.source,
        tenderType: tender.type,
        [formatTenderKind(tender.kind)]: tender.amount,
        total: tender.amount,
      });
    });
  });

  const tenderTypes = [
    ...new Set(rows.map((row) => row.tenderType).flat()),
  ].sort();

  const sources = [...new Set(rows.map((row) => row.source))];

  return {
    rows,
    tenderTypes,
    sources,
  };
};
