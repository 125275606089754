import React, { useEffect, useRef, useState } from "react";
import {
  deleteTemplate,
  EbayTemplate,
  fetchTemplates,
  updateTemplate,
} from "../../../api/rest/ebay";
import Loader from "../../../components/generic/Loader";
import EbayDeleteSetting from "../../../components/integrations/EbayDeleteSetting";
import EbaySettingsList from "../../../components/integrations/EbaySettingsList";
import EbayTemplateForm from "../../../components/integrations/EbayTemplateForm";
import { useStoreDetails } from "../../../hooks/storeHooks";
import { useQueryState } from "../../../hooks/stateHooks";
import { segmentAnalytics } from "../../../components/services/Analytics";
import { SAVE_POLICY_CLICKED } from "../../../constants/eventsTracked";

const LISTING_TEMPLATES = "Listing Templates";

const wrapTitle = (children: React.ReactChild) => (
  <>
    <h2>{LISTING_TEMPLATES}</h2>
    {children}
  </>
);

function TemplateSettingsContainer() {
  const { customerId } = useStoreDetails();
  const listRef = useRef<HTMLElement>();
  const [settings, setSettings] = useState<EbayTemplate[]>([]);
  const [editSetting, setEditSetting] = useQueryState<number | null>(
    "edit",
    null
  );
  const [deletingSetting, setDeletingSetting] = useQueryState<number | null>(
    "delete",
    null
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const loadSettings = () => {
    setIsLoading(true);
    fetchTemplates()
      .then((data: []) => setSettings(data))
      .catch((error: Error) => setErrorMessage(String(error)))
      .finally(() => setIsLoading(false));
  };

  const saveSetting = (values: EbayTemplate, setSubmitting: Function) => {
    if (values.id < 0) values.id = null;
    setSubmitting(true);
    updateTemplate(values)
      .then(() => {
        segmentAnalytics.track(SAVE_POLICY_CLICKED, {
          integration_type: "ebay",
          policy_type: LISTING_TEMPLATES,
        });
        loadSettings();
        setEditSetting(null);
      })
      .catch((error: Error) => setErrorMessage(String(error)))
      .finally(() => setSubmitting(false));
  };

  const deleteSetting = (settingId: number) => {
    setIsDeleting(true);
    deleteTemplate(settingId)
      .then(() => loadSettings())
      .catch((error: Error) => setErrorMessage(String(error)))
      .finally(() => {
        setIsDeleting(false);
        setDeletingSetting(null);
      });
  };

  useEffect(() => {
    if (customerId) {
      loadSettings();
    }
  }, [customerId]);

  if (errorMessage) {
    return (
      <div className="error">
        An error occurred. Please refresh and try again
      </div>
    );
  }

  if (isLoading) {
    return wrapTitle(<Loader />);
  }

  if (editSetting) {
    return wrapTitle(
      <EbayTemplateForm
        templateSetting={settings.find((setting) => setting.id === editSetting)}
        setEditSetting={() => setEditSetting(null)}
        saveSetting={saveSetting}
      />
    );
  }

  if (deletingSetting) {
    const template = settings.find((setting) => setting.id === deletingSetting);
    if (location) {
      const { id, name } = template;
      return wrapTitle(
        <EbayDeleteSetting
          id={id}
          name={name}
          isDeleting={isDeleting}
          handleCancel={() => setDeletingSetting(null)}
          handleDelete={(deleteId: number) => deleteSetting(deleteId)}
        />
      );
    }
  }

  return wrapTitle(
    <EbaySettingsList
      settingsTitle={LISTING_TEMPLATES}
      settings={settings}
      deletingSetting={deletingSetting}
      setEditSetting={setEditSetting}
      setDeletingSetting={setDeletingSetting}
      listRef={listRef}
    />
  );
}

export default TemplateSettingsContainer;
