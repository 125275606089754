import React, { Component } from "react";
import { inject, observer } from "mobx-react";

interface CheckPermissionProps {
  screenName: string;
  children: React.ReactNode;
  store?: {
    AuthStore: {
      screenSettings: string[];
    };
  };
}

@inject("store")
@observer
class CheckPermission extends Component<CheckPermissionProps, {}> {
  render() {
    const { screenName, children } = this.props;
    const { screenSettings } = this.props.store.AuthStore;

    if (!screenSettings) return null;

    if (screenSettings[screenName]) return <>{children}</>;

    return (
      <div>
        <p>Please contact BinderPOS to enable this screen.</p>
      </div>
    );
  }
}

export default CheckPermission;
