import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import ButtonComponent from "../generic/ButtonComponent";
import Modal from "../generic/Modal";
import "./DeclineModal.scss";

class DeclineModal extends Component {
  form = createRef();

  render() {
    const { isLoading, setVisible, declineBuylist, forwardedRef } = this.props;

    return (
      <Modal
        isLoading={isLoading}
        ref={forwardedRef}
        onClose={() => setVisible(false)}
      >
        <Modal.Header>Decline buylist request</Modal.Header>
        <Modal.Content>
          <form
            id="declineBuylistForm"
            ref={this.form}
            onSubmit={(event) =>
              declineBuylist(
                event,
                this.form.current.elements.declineReason.value
              )
            }
            noValidate
            className="DeclineModal"
          >
            <label className="label">
              Enter the reason for declining the buylist. This will be emailed
              to the customer, leave blank for no email to be sent.
              <textarea
                id="declineReason"
                className="DeclineModal__notes-field"
                placeholder="E.g. Most cards are too damaged to sell"
              />
            </label>
          </form>
        </Modal.Content>
        <Modal.Actions>
          <ButtonComponent secondary onClick={() => setVisible(false)}>
            Cancel
          </ButtonComponent>
          <ButtonComponent
            primary
            type="submit"
            form="declineBuylistForm"
            data-testid="confirm-decline"
          >
            Decline
          </ButtonComponent>
        </Modal.Actions>
      </Modal>
    );
  }
}

DeclineModal.propTypes = {
  isLoading: PropTypes.bool,
  setVisible: PropTypes.func.isRequired,
  declineBuylist: PropTypes.func.isRequired,
};

export default DeclineModal;
