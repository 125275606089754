import firebase from "firebase/app";

let currentToken: AuthToken = null;

export const updateUserToken = async () => {
  const user = firebase.auth().currentUser;
  if (user) {
    return user.getIdToken(true).then((token) => {
      currentToken = token;
      return token;
    });
  }
};

// Used only for testing
export const setUserToken = (newToken: string) => (currentToken = newToken);

export const getUserToken = async (forceUpdate = false) => {
  if (!currentToken || currentToken.length === 0 || forceUpdate) {
    currentToken = await updateUserToken();
  }
  return currentToken;
};

export const deleteUserToken = () => (currentToken = null);

export const getCurrentUser = () => firebase.auth().currentUser;
