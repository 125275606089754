import React from "react";
import { useIntegrations } from "../hooks/integrationHooks";
import IntegrationsMenuStructure from "./IntegrationsMenuStructure";
import SetActiveMenu from "./SetActiveMenu";

function IntegrationsSideMenu() {
  const { availableIntegrations } = useIntegrations();

  return (
    <SetActiveMenu
      menuStructure={IntegrationsMenuStructure}
      availableIntegrations={availableIntegrations}
    />
  );
}

export default IntegrationsSideMenu;
