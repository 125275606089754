import React, { Fragment, useState } from "react";
import { Table, Column, Cell } from "rsuite-table";
import "rsuite-table/dist/css/rsuite-table.css";
import "../../../css/rstable-overwrites.scss";
import CustomHeaderCell from "../../components/reports/CustomHeader";
import { API_STATE } from "../../constants/api";
import { useDebounce } from "../../hooks/inputHooks";
import {
  ActiveCartsQueryParams,
  useFetchActiveCarts,
} from "../../hooks/useFetchActiveCarts";
import TimeUtils from "../../utils/TimeUtils";
import DeleteButtonCell from "../generic/DeleteButtonCell";
import LoadButtonCell from "../generic/LoadButtonCell";
import Modal from "../generic/Modal";
import CartTypeSelect from "../posSettings/CartTypeSelect";
import { NumberInputFilter, TextInputFilter } from "../reports/CellTypes";
import Pagination from "../reports/Pagination";
import DeleteCartModal from "./DeleteCartModal";

const DEBOUNCE_TIMEOUT = 250;

interface CartModalProps {
  getCartById: (cartId: number) => void;
  hideModal: () => void;
}

function CartModal({ getCartById, hideModal }: CartModalProps) {
  const rowPerPageOptions = [10, 20];
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [deleteModalId, setDeleteModalId] = useState<bigint>(undefined);
  const [listParams, setListParams] = useState<ActiveCartsQueryParams>({
    offset: 0,
    limit: pageSize,
  });

  const [tableSort, setTableSort] = useState<{
    sortKey?: string;
    sortOrder?: "asc" | "desc";
  }>({ sortKey: "dateSubmitted", sortOrder: "desc" });

  const debouncedParams = useDebounce(listParams, DEBOUNCE_TIMEOUT);
  const { data, isFetching, refetch } = useFetchActiveCarts(debouncedParams);

  const updatePageSize = (size: number) => {
    setPageSize(size);
    setCurrentPage(0);
    setListParams((prev) => ({
      ...prev,
      offset: 0,
      limit: size,
    }));
  };

  const updateCurrentPage = (page: number) => {
    setCurrentPage(page);
    setListParams((prev) => ({
      ...prev,
      offset: page * pageSize,
    }));
  };

  const setSortColumn = (key, order) => {
    setTableSort({
      sortKey: key,
      sortOrder: order,
    });
    setListParams((prev) => ({
      ...prev,
      sortKey: key,
      sortOrder: order,
      offset: 0,
    }));
  };

  const onListFilterChange = (
    parameter: keyof ActiveCartsQueryParams,
    value: string
  ) => {
    setCurrentPage(0);
    setListParams((prev) => ({
      ...prev,
      offset: 0,
      [parameter]: !value || value === "" ? undefined : value,
    }));
  };

  const { totalRows, carts } = data || {};

  return (
    <Fragment>
      <Modal onClose={hideModal} large>
        <Modal.Header>Load a cart</Modal.Header>
        <Modal.Content>
          <div className="data-table">
            <div
              style={{
                height: `${carts ? carts.length * 50 + 80 : 0}px`,
              }}
            >
              <Table
                data={carts}
                headerHeight={80}
                loading={isFetching}
                sortColumn={tableSort.sortKey}
                sortType={tableSort.sortOrder}
                onSortColumn={setSortColumn}
                className="rs-table--multi-header"
                renderEmpty={() => <div>No data to display</div>}
                fillHeight
                style={{
                  height: `${carts ? carts.length * 50 + 80 : 0}px`,
                }}
              >
                <Column key="delete" width={50} align="center">
                  <CustomHeaderCell title="" />
                  <Cell dataKey="id">
                    {(rowData) => (
                      <DeleteButtonCell
                        onClick={() => setDeleteModalId(rowData?.id)}
                      />
                    )}
                  </Cell>
                </Column>
                <Column key="load" width={50} align="center">
                  <CustomHeaderCell title="" />
                  <Cell dataKey="id">
                    {(rowData) => (
                      <LoadButtonCell
                        onClick={() => getCartById(rowData?.id)}
                      />
                    )}
                  </Cell>
                </Column>
                <Column
                  key="cartType"
                  width={100}
                  align="left"
                  sortable
                  resizable
                >
                  <CustomHeaderCell title="Cart Type">
                    <CartTypeSelect
                      aria-label="Filter by cart type"
                      title="Cart type"
                      value={listParams.cartType}
                      setValue={(value) =>
                        onListFilterChange("cartType", value)
                      }
                    />
                  </CustomHeaderCell>
                  <Cell dataKey="cartType" />
                </Column>
                <Column
                  key="cartId"
                  width={100}
                  align="left"
                  sortable
                  resizable
                >
                  <CustomHeaderCell title="Cart Id">
                    <NumberInputFilter
                      title="Cart Id"
                      aria-label="Filter by cart ID"
                      value={listParams.cartId}
                      setValue={(value) => onListFilterChange("cartId", value)}
                    />
                  </CustomHeaderCell>
                  <Cell dataKey="id" />
                </Column>
                <Column
                  key="col_cart_name"
                  width={200}
                  align="left"
                  sortable
                  resizable
                >
                  <CustomHeaderCell title="Kiosk Cart Name">
                    <TextInputFilter
                      title="Kiosk Cart name"
                      aria-label="Filter by kiosk cart name"
                      value={listParams.cartName}
                      setValue={(value) =>
                        onListFilterChange("cartName", value)
                      }
                    />
                  </CustomHeaderCell>
                  <Cell dataKey="cartName" />
                </Column>
                <Column
                  key="firstName"
                  width={200}
                  align="left"
                  sortable
                  resizable
                >
                  <CustomHeaderCell title="Customer first name">
                    <TextInputFilter
                      title="Customer first name"
                      aria-label="Filter by customer first name"
                      value={listParams.firstName}
                      setValue={(value) =>
                        onListFilterChange("firstName", value)
                      }
                    />
                  </CustomHeaderCell>
                  <Cell dataKey="firstName">
                    {(rowData) => rowData?.customer?.firstName}
                  </Cell>
                </Column>
                <Column
                  key="lastName"
                  width={200}
                  align="left"
                  sortable
                  resizable
                >
                  <CustomHeaderCell title="Customer last name">
                    <TextInputFilter
                      title="Customer last name"
                      aria-label="Filter by customer last name"
                      value={listParams.lastName}
                      setValue={(value) =>
                        onListFilterChange("lastName", value)
                      }
                    />
                  </CustomHeaderCell>
                  <Cell dataKey="lastName">
                    {(rowData) => rowData?.customer?.lastName}
                  </Cell>
                </Column>
                <Column
                  key="itemCount"
                  width={100}
                  align="left"
                  sortable
                  resizable
                >
                  <CustomHeaderCell title="Line items" />
                  <Cell dataKey="lineItemCount" />
                </Column>
                <Column
                  key="col_updated"
                  width={220}
                  align="left"
                  sortable
                  resizable
                >
                  <CustomHeaderCell title="Last Updated" />
                  <Cell dataKey="lastUpdated">
                    {(rowData) =>
                      TimeUtils.convertDateToHumanReadable(rowData?.lastUpdated)
                    }
                  </Cell>
                </Column>
                <Column key="saved" width={100} align="left" sortable resizable>
                  <CustomHeaderCell title="Status" />
                  <Cell dataKey="savedCart">
                    {(rowData) => (rowData?.savedCart ? "Saved" : "Autosaved")}
                  </Cell>
                </Column>
              </Table>
            </div>
            <Pagination
              apiState={isFetching ? API_STATE.LOADING : API_STATE.SUCCESS}
              currentPage={currentPage}
              totalRowCount={totalRows}
              rowsPerPage={pageSize}
              rowPerPageOptions={rowPerPageOptions}
              setCurrentPage={updateCurrentPage}
              setRowsPerPage={updatePageSize}
            />
          </div>
        </Modal.Content>
        <Modal.Actions> </Modal.Actions>
      </Modal>

      {deleteModalId !== undefined ? (
        <>
          <DeleteCartModal
            cartId={Number(deleteModalId)}
            onCancel={() => setDeleteModalId(undefined)}
            onComplete={async () => {
              setDeleteModalId(undefined);
              refetch();
            }}
          />
        </>
      ) : null}
    </Fragment>
  );
}

export default CartModal;
