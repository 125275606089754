import React from "react";
import { User } from "../../types/user";
import UserListAvatar from "../../components/users/UserListAvatar";
import EnableButton from "../../components/users/EnableButton";
import IconButton from "../../components/generic/IconButton";
import { Column } from "../../types/table";
import PasswordResetButton from "../../components/users/PasswordResetButton";

const UsersTableColumns: Column[] = [
  {
    header: "User",
    accessorKey: "id",
    cell: (_value, _column, user: User) => (
      <UserListAvatar
        user={user}
        className={
          user.enabled ? "UserListAvatar--enabled" : "UserListAvatar--disabled"
        }
      />
    ),
  },
  {
    header: "Email",
    accessorKey: "email",
  },
  {
    header: "Name",
    accessorKey: "firstName",
    cell: (_value, _column, { firstName, lastName }: User) =>
      [firstName, lastName].filter(Boolean).join(" "),
  },
  {
    header: "Status",
    accessorKey: "enabled",
    cell: (enabled) => (enabled ? "Enabled" : "Disabled"),
  },
  {
    header: "",
    headerLabel: "actions",
    accessorKey: "id",
    cell: (_value, _column, user: User) => (
      <div className="Users__actions">
        <IconButton
          icon="fal fa-user-edit"
          isLink
          to={`/settings/users/update/${user.id}`}
          className="userActions"
          title="Edit user details"
        />
        <EnableButton user={user} />
        <PasswordResetButton user={user} />
      </div>
    ),
  },
];

export default UsersTableColumns;
