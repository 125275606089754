import React from "react";
import { Column, AdditionalTableProps } from "../../types/table";

const renderData = (
  value: unknown,
  column: Column,
  rowData: unknown,
  rowIndex: number,
  additionalTableProps: AdditionalTableProps
) => {
  if (column.cell) {
    return column.cell(value, column, rowData, rowIndex, additionalTableProps);
  }
  return value;
};

const renderLabel = (value: unknown, label?: string) =>
  typeof value === "string" ? value : label;

interface TableRowProps {
  rowData: unknown;
  columns: Column[];
  rowIndex: number;
  additionalTableProps: AdditionalTableProps;
  getTrProps?: (rowData: unknown) => object;
}

function TableRow(props: TableRowProps) {
  const { rowData, columns, rowIndex, additionalTableProps, getTrProps } =
    props;

  const rowProps = getTrProps ? getTrProps(rowData) : {};

  return (
    <tr {...rowProps}>
      {columns.map((column) => (
        <td
          key={`${column.accessorKey}_${column.headerLabel ?? column.header}`}
          data-label={renderLabel(column.header, column.headerLabel)}
          className={column.className}
        >
          {renderData(
            rowData[column.accessorKey],
            column,
            rowData,
            rowIndex,
            additionalTableProps
          )}
        </td>
      ))}
    </tr>
  );
}

export default TableRow;
