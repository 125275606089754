import React, { useEffect, useRef, useState } from "react";
import { PRODUCT_PRICING_INFO } from "../../constants/featureFlags";
import { useFeatureFlag } from "../../hooks/globalSettingsHooks";
import ProductLineVariantHeader from "./ProductLineVariantHeader";
import ProductLineVariantItem from "./ProductLineVariantItem";
import FilterVariantsSelect from "./FilterVariantsSelect";
import ToggleVariantsButton from "./ToggleVariantsButton";
import Modal from "../generic/Modal";
import ProductPricingInfo from "./ProductPricingInfo";
import ButtonComponent from "../generic/ButtonComponent";

const getProductTitle = (value: string) => value.split("[")[0];

const getProductSet = (value: string) => {
  const split = value.split("[");
  if (split && split.length > 1) {
    return split[1].replace("]", "");
  }
  return "";
};

const capitalizeFirstLetter = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

type PriceCascade = {
  suffix: string;
  variant: Variant;
};

interface ProductLineItemProps {
  setUpdateProduct: (func: Function) => void;
  product: Product;
  listIndex: number;
  columnsToShow: string[];
  currentInput: () => number;
  updateCurrentInput: (value: number) => void;
  getStockUpdateType: () => string;
  onChange: (newVariant: Variant, oldVariant: Variant) => void;
  onComplete: (id: number) => void;
  currency: string;
  collapseVariants: boolean;
  hideOutOfStock: boolean;
}

function ProductLineItem(props: ProductLineItemProps) {
  const {
    setUpdateProduct,
    product,
    listIndex,
    columnsToShow,
    currentInput,
    updateCurrentInput,
    getStockUpdateType,
    onChange,
    onComplete,
    currency,
    collapseVariants,
    hideOutOfStock,
  } = props;
  const pricingInfoEnabled = useFeatureFlag(PRODUCT_PRICING_INFO);
  const [collapseProduct, setCollapseProduct] = useState(collapseVariants);
  const [filterVariants, setFilterVariants] = useState(hideOutOfStock);
  const [cascadeData, setCascadeData] = useState<PriceCascade>();
  const [showPricingInfoModal, setShowPricingInfoModal] =
    useState<boolean>(false);
  const variantLineItemsToProcess = useRef<Function[]>([]);

  useEffect(() => {
    setUpdateProduct(() => process(variantLineItemsToProcess.current));
  }, []);

  useEffect(() => {
    setCollapseProduct(collapseVariants);
  }, [collapseVariants]);

  useEffect(() => {
    setFilterVariants(hideOutOfStock);
  }, [hideOutOfStock]);

  const getCurrentInput = () => currentInput();

  const process = (processFunctions: Function[]) => {
    processFunctions.forEach((functionToProcess) => {
      functionToProcess();
    });
  };

  const productVariants = filterVariants
    ? product.variants.filter((variant) => variant.quantity > 0)
    : product.variants;

  return (
    <div key={listIndex} className="container-fluid">
      <div className="row">
        <div className="lineItem">
          <div className="productHeader">
            <div className="container-fluid">
              <div className="row">
                <div
                  className={`${pricingInfoEnabled ? "col-md-4" : "col-md-6"}`}
                >
                  <div className="imgWrapper">
                    <img
                      role="presentation"
                      src={
                        product.tcgImage && product.tcgImage.length > 0
                          ? product.tcgImage
                          : product.img
                      }
                    />
                  </div>
                  <div className="productTitle">
                    {product.cardTitle || getProductTitle(product.title)}
                    <span style={{ fontWeight: 600 }}>
                      {product.setName || getProductSet(product.title)}
                    </span>
                    <span>
                      #{product.cardNumber || product.collectorNumber}{" "}
                      {product.rarity
                        ? `| ${capitalizeFirstLetter(product.rarity)}`
                        : null}
                    </span>
                  </div>
                </div>

                {pricingInfoEnabled ? (
                  <div className="col-md-4">
                    <div className="row justify-content-center align-items-center my-2">
                      <ButtonComponent
                        onClick={() => setShowPricingInfoModal(true)}
                        primary
                      >
                        View Pricing
                      </ButtonComponent>
                    </div>
                  </div>
                ) : null}
                <div
                  className={`${pricingInfoEnabled ? "col-md-4" : "col-md-6"}`}
                >
                  <div className="productData">
                    <ToggleVariantsButton
                      collapseVariants={collapseProduct}
                      hideOutOfStock={filterVariants}
                      setCollapseVariants={setCollapseProduct}
                    >
                      Total Stock: {product.overallQuantity}
                    </ToggleVariantsButton>
                  </div>
                  {!collapseProduct ? (
                    <FilterVariantsSelect
                      collapseVariants={collapseProduct}
                      hideOutOfStock={filterVariants}
                      setCollapseVariants={setCollapseProduct}
                      setHideOutOfStock={setFilterVariants}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {!collapseProduct && productVariants?.length > 0 ? (
            <div className="variantsInfo">
              <ProductLineVariantHeader
                columnsToShow={columnsToShow}
                currency={currency}
              />
              {productVariants.map((variant, i) => {
                return (
                  <ProductLineVariantItem
                    setUpdateVariant={(theFunction: Function) =>
                      variantLineItemsToProcess.current.push(theFunction)
                    }
                    key={variant.id}
                    variant={variant}
                    parentListIndex={listIndex}
                    listIndex={i}
                    columnsToShow={columnsToShow}
                    currentInput={getCurrentInput}
                    updateCurrentInput={updateCurrentInput}
                    getStockUpdateType={getStockUpdateType}
                    onChange={onChange}
                    onComplete={onComplete}
                    cascade={cascadeData}
                    setCascade={setCascadeData}
                  />
                );
              })}
            </div>
          ) : null}
        </div>
      </div>
      {showPricingInfoModal ? (
        <Modal
          onClose={() => {
            setShowPricingInfoModal(false);
          }}
        >
          <Modal.Header>{product.title}</Modal.Header>
          <Modal.Content>
            <ProductPricingInfo productId={product.id} />
          </Modal.Content>
        </Modal>
      ) : null}
    </div>
  );
}

export default ProductLineItem;
