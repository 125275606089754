import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { notify } from "../../helpers/notificationHelpers";
import { useUploadCsv } from "../../hooks/productDataImportHooks";
import {
  CsvUploadResult,
  CsvUploadFailHeaders,
} from "../../types/productImport";
import ButtonComponent from "../generic/ButtonComponent";
import Spinner from "../generic/Spinner";
import SubSectionHeaderLayout from "../layout/SubSectionHeaderLayout";
import UploadCsvFileTooltip from "./UploadCsvFileTooltip";
import UploadCsvHeadersModal from "./UploadCsvHeadersModal";
import "./UploadCsv.scss";
import { DROP_PRODUCT_CSV_CLICKED } from "../../constants/eventsTracked";
import { segmentAnalytics } from "../services/Analytics";

const EXAMPLE_CSV_URL = "https://cdn.binderpos.com/csv/binder-sample.csv";

type UploadError = {
  error: string;
};

const renderHeader = () => (
  <SubSectionHeaderLayout
    title="Upload CSV File"
    tooltip={<UploadCsvFileTooltip />}
  >
    <a
      href={EXAMPLE_CSV_URL}
      target="_blank"
      rel="noopener"
      className="UploadCsv__downloadLink"
    >
      Download a Sample CSV
    </a>
  </SubSectionHeaderLayout>
);

function UploadCsv() {
  const { isMutating, mutateAsync } = useUploadCsv();
  const [uploadSuccessful, setUploadSuccessful] = useState(false);
  const [uploadFailed, setUploadFailed] = useState<string>();
  const [failedHeaders, setFailedHeaders] = useState<CsvUploadFailHeaders>();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    segmentAnalytics.track(DROP_PRODUCT_CSV_CLICKED);
    if (acceptedFiles.length > 1) {
      notify.warn("Only one CSV file may be uploaded");
      return;
    }
    if (acceptedFiles.length < 1) {
      notify.warn("Only CSV files may be uploaded");
      return;
    }
    const data = new FormData();
    acceptedFiles.forEach((file) => {
      data.append("file", file, file.name);
    });
    mutateAsync(data, {
      onSuccess: handleUploadResponse,
      onError: (error: UploadError) => setUploadFailed(error.error),
    });
  }, []);

  const handleUploadResponse = (response: CsvUploadResult) => {
    if ("csvType" in response) {
      setUploadSuccessful(true);
      return;
    }
    if ("message" in response) {
      if (response.message === "invalid Headers") {
        setFailedHeaders(response);
      } else {
        setUploadFailed(response.message);
      }
      return;
    }
    setUploadFailed(response.error);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "text/csv": [".csv"] },
  });

  const closeHeadersModal = () => setFailedHeaders(undefined);

  if (uploadSuccessful) {
    return (
      <div className="UploadCsv">
        {renderHeader()}
        <div className="UploadCsv__dropzone UploadCsv__dropzone--success">
          <div className="UploadCsv__layout">
            <i className="fal fa-file-check" />
            Upload successful
          </div>
          <p className="UploadCsv__uploadStatus">
            The file may take a few minutes to process (depending on size).
            Check the table below for progress. You may leave this page.
          </p>
          <ButtonComponent primary onClick={() => setUploadSuccessful(false)}>
            Upload another file
          </ButtonComponent>
        </div>
      </div>
    );
  }

  if (uploadFailed) {
    return (
      <div className="UploadCsv">
        {renderHeader()}
        <div className="UploadCsv__dropzone UploadCsv__dropzone--error">
          <div className="UploadCsv__layout">
            <i className="fal fa-file-exclamation" />
            We were unable to upload your file
          </div>
          <p className="UploadCsv__uploadStatus">{uploadFailed}</p>
          <ButtonComponent primary onClick={() => setUploadFailed(undefined)}>
            Try again
          </ButtonComponent>
        </div>
      </div>
    );
  }

  return (
    <div className="UploadCsv">
      {renderHeader()}
      <Spinner isLoading={isMutating}>
        <div
          {...getRootProps()}
          className={
            isDragActive
              ? "UploadCsv__dropzone UploadCsv__dropzone--active"
              : "UploadCsv__dropzone"
          }
          data-testid="dropzone"
        >
          <input {...getInputProps()} />
          <div className="UploadCsv__layout">
            {isDragActive ? (
              <span>Drop the file here ...</span>
            ) : (
              <>
                <i className="fal fa-file-csv" />
                Drop a CSV file here, or click to select a file
              </>
            )}
          </div>
        </div>
      </Spinner>
      {failedHeaders ? (
        <UploadCsvHeadersModal {...failedHeaders} onClose={closeHeadersModal} />
      ) : null}
    </div>
  );
}

export default UploadCsv;
