import { _fetch } from "../../api";
import { API_V2_BASE_URL } from "../../constants/api";

export const fetchSupportedCardGames = async () => {
  return await _fetch({
    method: "GET",
    endpoint: `${API_V2_BASE_URL}/cards/games/supported?includeIsSealed=true`,
  });
};

export const fetchCardRarities = async (type) => {
  return await _fetch({
    method: "GET",
    endpoint: `${API_V2_BASE_URL}/cards/${type}/rarities`,
  });
};
