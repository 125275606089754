import { ToastOptions } from "react-toastify";

export const notificationParameters = {
  position: "bottom-left",
  autoClose: 3200,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  icon: false,
} as ToastOptions;
