import { useCallback, useEffect, useState, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

type SetState<T> = ((v: T) => T) | T;
type SetStateFunction<T> = (value: SetState<T>) => void;

const parseJsonValue = <T>(jsonValue: string): T => {
  try {
    return JSON.parse(jsonValue);
  } catch (_err) {
    return undefined;
  }
};

export const useQueryState = <TValue>(
  paramName: string,
  initialValue: TValue
): [TValue, SetStateFunction<TValue>] => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const [encodedValue, setEncodedValue] = useState<string>(
    JSON.stringify(initialValue)
  );
  const setValue = useCallback(
    (update: SetState<TValue>) => {
      const searchParams = new URLSearchParams(search);
      const paramValue = searchParams.get(paramName);
      const prevValue = parseJsonValue<TValue>(paramValue);
      const newValue = update instanceof Function ? update(prevValue) : update;
      searchParams.set(paramName, JSON.stringify(newValue));
      const queryString = searchParams.toString();
      history.replace(`${pathname}?${queryString}`);
    },
    [history, search, paramName, pathname]
  );

  useEffect(() => {
    if (search) {
      const searchParams = new URLSearchParams(search);
      if (searchParams.has(paramName)) {
        const paramValue = searchParams.get(paramName);
        setEncodedValue(paramValue);
      }
    }
  }, [paramName, search]);

  const value = useMemo(
    () => parseJsonValue<TValue>(encodedValue),
    [encodedValue]
  );

  return [value, setValue];
};
