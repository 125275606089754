import React, { useEffect, useState } from "react";
import Loader from "../../components/generic/Loader";
import Panel from "../../components/layout/Panel";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";
import CurrentThemeVersion from "../../components/themes/CurrentThemeVersion";
import ReleaseTrainSelect from "../../components/themes/ReleaseTrainSelect";
import ThemeReleases from "../../components/themes/ThemeReleases";
import ThemeRollbacks from "../../components/themes/ThemeRollbacks";
import ThemeSelect from "../../components/themes/ThemeSelect";
import { useGetShopifyThemesQuery } from "../../generated/graphql";
import { availableThemes } from "../../constants/themes";
import {
  useLocalSetting,
  useLocalSettingUpdate,
} from "../../hooks/localSettingsHooks";
import { useShowError } from "../../hooks/errorHooks";
import "./Themes.scss";

export const SELECTED_THEME_SETTING = "selectedShopifyTheme";

function Themes() {
  const selectedTheme = useLocalSetting(SELECTED_THEME_SETTING) as string;
  const [currentTheme, setCurrentTheme] = useState(selectedTheme);
  const showError = useShowError();
  const { data: customerThemes, isLoading, error } = useGetShopifyThemesQuery();
  const updateLocalSetting = useLocalSettingUpdate();

  useEffect(() => {
    if (customerThemes && !selectedTheme) {
      const firstCustomerTheme =
        customerThemes.BinderCustomerShopifyTheme[0]?.theme;
      setCurrentTheme(firstCustomerTheme);
      updateLocalSetting(SELECTED_THEME_SETTING, firstCustomerTheme);
    }
  }, [customerThemes]);

  useEffect(() => {
    if (error) {
      showError(
        error as DetailedError,
        "Unable to load theme details",
        "Please refresh and try again"
      );
    }
  }, [error]);

  const handleThemeChange = (theme: string) => {
    updateLocalSetting(SELECTED_THEME_SETTING, theme);
    setCurrentTheme(theme);
  };

  if (isLoading) {
    return <Loader />;
  }

  const currentThemeDetails = customerThemes?.BinderCustomerShopifyTheme.find(
    (customerTheme) => customerTheme.theme === currentTheme
  );

  return (
    <div className="Themes">
      <SectionHeaderLayout title="Shopify Theme Management" />
      {customerThemes?.BinderCustomerShopifyTheme.length > 1 ? (
        <ThemeSelect
          customerThemes={customerThemes.BinderCustomerShopifyTheme}
          selectedTheme={currentTheme}
          setSelectedTheme={handleThemeChange}
        />
      ) : null}
      <h2 className="Themes__title" data-testid="selectedThemeName">
        {availableThemes[currentTheme]?.themeName}
      </h2>
      {currentTheme ? (
        <>
          <Panel>
            <ReleaseTrainSelect
              theme={currentTheme}
              activeReleaseTrain={currentThemeDetails?.releaseTrain}
            />
          </Panel>
          <Panel>
            <CurrentThemeVersion theme={currentTheme} />
          </Panel>
          <Panel>
            <ThemeReleases
              theme={currentTheme}
              currentVersion={currentThemeDetails?.currentVersion}
            />
          </Panel>
          <Panel>
            <ThemeRollbacks
              theme={currentTheme}
              currentVersion={currentThemeDetails?.currentVersion}
            />
          </Panel>
        </>
      ) : (
        <div className="CurrentThemeVersion">
          <p>
            Your Shopify store has not been configured to allow managed theme
            updates. Please contact our support team to enable this feature.
          </p>
        </div>
      )}
    </div>
  );
}

export default Themes;
