import React from "react";
import PropTypes from "prop-types";
import IconButton from "../generic/IconButton";

function SortDirection(props) {
  const { ascending, setAscending } = props;

  const handleChange = () => {
    setAscending(!ascending);
  };

  return (
    <IconButton
      icon={
        ascending ? "fas fa-sort-amount-up-alt" : "fas fa-sort-amount-down-alt"
      }
      title={ascending ? "Sort descending" : "Sort ascending"}
      onClick={handleChange}
    />
  );
}

SortDirection.propTypes = {
  ascending: PropTypes.bool.isRequired,
  setAscending: PropTypes.func.isRequired,
};

export default SortDirection;
