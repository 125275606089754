import moment from "moment";

export const defaultChartOptions = {
  colors: ["#007d7d"],
  plotOptions: {
    bar: {
      dataLabels: {
        position: "top", // top, center, bottom
      },
    },
  },
  grid: {
    borderColor: "#93949A",
  },
  chart: {
    toolbar: {
      show: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  fill: {
    gradient: {
      shade: "light",
      type: "horizontal",
      shadeIntensity: 0.25,
      gradientToColors: undefined,
      inverseColors: true,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [50, 0, 100, 100],
    },
  },
};

export const paymentTypeCollections = {
  sales: ["Cash_In", "Credit_In", "EFTPOS_In", "Store_Credit_In"],
  buys: ["Cash_Out", "Credit_Out", "EFTPOS_Out", "Store_Credit_Out"],
  buys_sales: [
    "Cash_Out",
    "Credit_Out",
    "EFTPOS_Out",
    "Store_Credit_Out",
    "Cash_In",
    "Credit_In",
    "EFTPOS_In",
    "Store_Credit_In",
  ],
  cash_credit_in: ["Cash_In", "Credit_In"],
  store_credit_in: ["Store_Credit_In"],
};

export const rangeOptions = [
  { value: "today", label: "Today" },
  { value: "yesterday", label: "Yesterday" },
  { value: "thisWeek", label: "This Week" },
  { value: "lastWeek", label: "Last Week" },
  { value: "thisMonth", label: "This Month" },
  { value: "lastMonth", label: "Last Month" },
  { value: "thisYear", label: "This Year" },
  { value: "lastYear", label: "Last Year" },
];

export const rangeOptionToDates = {
  today: {
    startDate: moment().startOf("day").toISOString(),
    endDate: moment().endOf("day").toISOString(),
    binSize: 3600,
  },
  yesterday: {
    startDate: moment().subtract(1, "days").startOf("day").toISOString(),
    endDate: moment().subtract(1, "days").endOf("day").toISOString(),
    binSize: 3600,
  },
  thisWeek: {
    startDate: moment().startOf("isoWeek").toISOString(),
    endDate: moment().endOf("isoWeek").toISOString(),
    binSize: 3600 * 24,
  },
  lastWeek: {
    startDate: moment().subtract(1, "weeks").startOf("isoWeek").toISOString(),
    endDate: moment().subtract(1, "weeks").endOf("isoWeek").toISOString(),
    binSize: 3600 * 24,
  },
  thisMonth: {
    startDate: moment().startOf("month").toISOString(),
    endDate: moment().endOf("month").toISOString(),
    binSize: 3600 * 24,
  },
  lastMonth: {
    startDate: moment().subtract(1, "months").startOf("month").toISOString(),
    endDate: moment().subtract(1, "months").endOf("month").toISOString(),
    binSize: 3600 * 24,
  },
  thisYear: {
    startDate: moment().startOf("year").toISOString(),
    endDate: moment().endOf("year").toISOString(),
    binSize: 3600 * 24 * 7,
  },
  lastYear: {
    startDate: moment().subtract(1, "years").startOf("year").toISOString(),
    endDate: moment().subtract(1, "years").endOf("year").toISOString(),
    binSize: 3600 * 24 * 7,
  },
};
