import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  ADD_EVENT_CLICKED,
  EDIT_EVENT_CLICKED,
} from "../../constants/eventsTracked";
import { EventMenuStructure } from "../../menuStructures";
import SetActiveMenu from "../../menuStructures/SetActiveMenu";
import { DEFAULT_EVENTS_PER_PAGE } from "../../constants/events";
import { removeNullValuesFromObject } from "../../helpers/valueHelpers";
import { useShowError } from "../../hooks/errorHooks";
import { useFetchEvents } from "../../hooks/eventHooks";
import { useDebounce } from "../../hooks/inputHooks";
import useTitle from "../../hooks/useTitle";
import { EventInfo } from "../../types/events";
import { ButtonComponent, Paging, Spinner } from "../../components";
import {
  EventCard,
  ViewEventModal,
  DeleteEventModal,
} from "../../components/events";
import EventsFilter, {
  FilterValue,
} from "../../components/events/EventsFilter";
import ActionButtonsLayout from "../../components/layout/ActionButtonsLayout";
import GridLayout from "../../components/layout/GridLayout";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";

const DEBOUNCE_TIMEOUT = 250;

function EventList() {
  const eventRef = useRef<HTMLButtonElement>(null);
  const modalRef = useRef<HTMLElement>(null);
  const topOfListRef = useRef<HTMLDivElement>(null);

  useTitle("Events");

  const [offset, setOffset] = useState(0);
  const [eventToView, setEventToView] = useState<EventInfo>();
  const [eventToDelete, setEventToDelete] = useState<EventInfo>();
  const [eventFilters, setEventFilters] = useState<Record<string, FilterValue>>(
    {}
  );
  const debouncedEventFilters = useDebounce(eventFilters, DEBOUNCE_TIMEOUT);
  const showError = useShowError();
  const {
    data: events = [],
    isLoading,
    error,
  } = useFetchEvents({
    offset,
    limit: DEFAULT_EVENTS_PER_PAGE,
    ...removeNullValuesFromObject(debouncedEventFilters),
  });

  useEffect(() => {
    if (error) {
      showError(
        error as DetailedError,
        "Failed to load events",
        "There was an error retrieving your events. Please refresh and try again"
      );
    }
  }, [error]);

  useEffect(() => {
    if (eventToView || eventToDelete) {
      modalRef.current?.focus();
    } else {
      eventRef.current?.focus();
    }
  }, [eventToView, eventToDelete]);

  const handleViewEventClick = (eventInfo: EventInfo) => {
    setEventToView(eventInfo);
    modalRef.current?.focus();
  };

  const handleDeleteEventClick = (eventInfo: EventInfo) =>
    setEventToDelete(eventInfo);

  const resetEventFilters = () => setEventFilters({});

  const handleEventFilterChange = (parameter: string, value: FilterValue) => {
    if (parameter === "offset") {
      setOffset(Number(value));
    } else {
      setEventFilters((prev) => ({ ...prev, [parameter]: value }));
    }
  };

  const prevPage = () =>
    setOffset((prev) => Math.min(0, prev - DEFAULT_EVENTS_PER_PAGE));

  const nextPage = () => setOffset((prev) => prev + DEFAULT_EVENTS_PER_PAGE);

  return (
    <>
      <SetActiveMenu menuStructure={EventMenuStructure} />
      <SectionHeaderLayout title="My Events">
        <ActionButtonsLayout>
          <ButtonComponent
            primary
            isLink
            to="/events/add"
            icon="fas fa-caret-right"
            iconPosition="right"
            segmentEventName={ADD_EVENT_CLICKED}
          >
            Add Event
          </ButtonComponent>
        </ActionButtonsLayout>
      </SectionHeaderLayout>
      <EventsFilter
        eventFilters={eventFilters}
        resetEventFilters={resetEventFilters}
        updateEventFilter={handleEventFilterChange}
      />
      <div ref={topOfListRef}></div>
      <Paging
        pageOffset={offset}
        currentPageItemCount={events && events.length}
        maxItemsPerPage={DEFAULT_EVENTS_PER_PAGE}
        getPrevPage={prevPage}
        getNextPage={nextPage}
      />
      <Spinner isLoading={isLoading}>
        <GridLayout>
          {events.map((eventInfo) => (
            <EventCard key={eventInfo.id} event={eventInfo}>
              <EventCard.Links>
                <div className="col-md-6">
                  <p className="EventCard__info">
                    {eventInfo.recurring ? (
                      <Link to={`/events/view/${eventInfo.id}`}>
                        <i className="fas fa-eye" aria-hidden="true" />
                        View All Events
                      </Link>
                    ) : (
                      <button
                        onClick={(event) => {
                          eventRef.current = event.target as HTMLButtonElement;
                          handleViewEventClick(eventInfo);
                        }}
                      >
                        <i className="far fa-eye" aria-hidden="true" /> View
                        Event
                      </button>
                    )}
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="EventCard__info">
                    <button
                      onClick={(event) => {
                        eventRef.current = event.target as HTMLButtonElement;
                        handleDeleteEventClick(eventInfo);
                      }}
                    >
                      <i className="far fa-trash-alt" />
                      Remove Event
                    </button>
                  </p>
                </div>
                {eventInfo.recurring ? (
                  <div className="col-md-12">&nbsp;</div>
                ) : (
                  <div className="col-md-12">
                    <p className="EventCard__participants">
                      <em>Participants: </em>
                      <Link
                        aria-label={`View the entrants for ${eventInfo.title}`}
                        to={`/events/participants/${eventInfo.id}`}
                      >
                        View the entrants
                      </Link>
                    </p>
                  </div>
                )}
              </EventCard.Links>
              <EventCard.Actions>
                <ButtonComponent
                  aria-label={`Edit event ${eventInfo.title}`}
                  isLink
                  to={`/events/update/${eventInfo.id}`}
                  primary
                  fullWidth
                  icon="far fa-calendar-edit"
                  iconPosition="left"
                  segmentEventName={EDIT_EVENT_CLICKED}
                  segmentEventProperties={{
                    event_type: eventInfo.type,
                    game_type: eventInfo.game,
                  }}
                >
                  Edit event
                </ButtonComponent>
              </EventCard.Actions>
            </EventCard>
          ))}
          {events && events.length === 0 && "No events found"}
        </GridLayout>
        {events && events.length > 4 && (
          <Paging
            pageOffset={offset}
            currentPageItemCount={events && events.length}
            maxItemsPerPage={DEFAULT_EVENTS_PER_PAGE}
            getPrevPage={prevPage}
            getNextPage={nextPage}
          />
        )}
        <br />
        <ButtonComponent
          isLink
          to="/events/add"
          primary
          fullWidth
          icon="far fa-calendar-plus"
          iconPosition="left"
        >
          Add Event
        </ButtonComponent>
      </Spinner>
      {eventToView ? (
        <ViewEventModal
          event={eventToView}
          handleClose={() => setEventToView(undefined)}
          ref={modalRef}
        />
      ) : null}
      {eventToDelete ? (
        <DeleteEventModal
          id={eventToDelete.id}
          title={eventToDelete.title}
          parentEventId={eventToDelete.parentEventId}
          handleClose={() => setEventToDelete(undefined)}
          ref={modalRef}
        />
      ) : null}
    </>
  );
}

export default EventList;
