import { useContext } from "react";
import { StoreContext } from "../providers/StoreProvider";
import { roundToDecimalPlaces } from "../utils/formatting";

export const useStoreDetails = () => {
  const context = useContext(StoreContext);
  if (!context) {
    throw new Error("useStoreDetails hook must be used within StoreProvider");
  }
  const {
    customerId,
    customerName,
    customerSiteUrl,
    customerEmail,
    currencySymbol,
    currencyCode,
    storeDetails,
    taxRate,
    currencyIsFractional,
  } = context;
  return {
    customerId,
    customerName,
    customerSiteUrl,
    customerEmail,
    currencySymbol,
    currencyCode,
    storeDetails,
    taxRate,
    currencyIsFractional,
  };
};

export const useFormatCurrency = () => {
  const context = useContext(StoreContext);
  if (!context) {
    throw new Error("useFormatCurrency hook must be used within StoreProvider");
  }
  return (amount: string | number): string => {
    if (amount || amount === 0) {
      const value = Number(amount);
      const { currencySymbol } = context;
      if (value >= 0) {
        return `${currencySymbol}${roundToDecimalPlaces(value, 2)}`;
      } else {
        return `-${currencySymbol}${roundToDecimalPlaces(Math.abs(value), 2)}`;
      }
    }
  };
};
