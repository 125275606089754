import React from "react";
import ForceReauthoriseButton from "../../../components/integrations/ForceReauthoriseButton";
import TabNavigation from "../../../components/integrations/TabNavigation";
import SectionHeaderLayout from "../../../components/layout/SectionHeaderLayout";
import IntegrationsSideMenu from "../../../menuStructures/IntegrationsSideMenu";
import EbayIntegrationRouter from "../../../routers/EbayIntegrationRouter";
import "./EbayIntegrationView.scss";

function EbayIntegrationView() {
  return (
    <>
      <IntegrationsSideMenu />
      <SectionHeaderLayout title={`ebay Integration Settings`}>
        <ForceReauthoriseButton integration="ebay" />
      </SectionHeaderLayout>
      <TabNavigation integrationId="ebay" />
      <div className="ebay-integration-view__container">
        <EbayIntegrationRouter />
      </div>
    </>
  );
}

export default EbayIntegrationView;
