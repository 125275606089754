import { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { useShowError } from "../../hooks/errorHooks";
import {
  useGetStoreDetails,
  useUpdateStoreDetails,
} from "../../hooks/settingHooks";
import { notify } from "../../helpers/notificationHelpers";
import SettingsSection from "./SettingsSection";
import Spinner from "../generic/Spinner";
import Input from "../form/Input";
import "./StoreDetailsSettingsContainer.scss";

const initialValues = {
  storeName: "",
  phoneNumber: "",
  city: "",
  country: "",
  addressLine1: "",
  addressLine2: "",
  state: "",
  zipCode: "",
};

const StoreDetailsSettingsContainer = () => {
  const [updatingStoreSettings, setUpdatingStoreSettings] = useState(false);
  const {
    data: storeSettings,
    isLoading,
    error: settingsError,
  } = useGetStoreDetails();
  const { mutateAsync: updateStoreDetails } = useUpdateStoreDetails();

  const saveStoreSettings = (params) =>
    updateStoreDetails({ storeDetails: params })
      .then(() => {
        notify.info("Settings saved");
        setUpdatingStoreSettings(false);
      })
      .catch((error) =>
        showError(
          error,
          "Failed to save settings",
          "There was an error submitting your store settings. Please try again"
        )
      );
  const showError = useShowError();

  useEffect(() => {
    if (settingsError) {
      showError(
        settingsError as DetailedError,
        "Failed to load settings",
        "There was an error retrieving your store settings. Please refresh and try again"
      );
    }
  }, [settingsError]);

  return (
    <Formik
      onSubmit={saveStoreSettings}
      initialValues={storeSettings ?? initialValues}
      enableReinitialize
    >
      {({ errors, submitForm }) => (
        <SettingsSection
          title="Contact Details"
          isEditing={updatingStoreSettings}
          enableEditMode
          toggleEditMode={setUpdatingStoreSettings}
          formHasErrors={Object.keys(errors).length > 0}
          submitHandler={submitForm}
        >
          <Form className="StoreDetailsSettingsContainer__form">
            <Spinner isLoading={isLoading}>
              <div className="StoreDetailsSettingsContainer__field StoreDetailsSettingsContainer__field--large">
                <Field
                  component={Input}
                  editing={updatingStoreSettings}
                  label="Store name"
                  name="storeName"
                  type="text"
                  required
                  placeholder="BinderPOS"
                />
              </div>
              <div className="StoreDetailsSettingsContainer__field StoreDetailsSettingsContainer__field--large">
                <Field
                  component={Input}
                  editing={updatingStoreSettings}
                  label="Phone Number"
                  name="phoneNumber"
                  type="text"
                />
              </div>
              <div className="StoreDetailsSettingsContainer__field StoreDetailsSettingsContainer__field--large">
                <Field
                  component={Input}
                  editing={updatingStoreSettings}
                  label="Address line 1"
                  name="addressLine1"
                  type="text"
                />
              </div>
              <div className="StoreDetailsSettingsContainer__field StoreDetailsSettingsContainer__field--large">
                <Field
                  component={Input}
                  editing={updatingStoreSettings}
                  label="Address line 2"
                  name="addressLine2"
                  type="text"
                />
              </div>
              <div className="StoreDetailsSettingsContainer__field">
                <Field
                  component={Input}
                  editing={updatingStoreSettings}
                  label="City"
                  name="city"
                  type="text"
                />
              </div>
              <div className="StoreDetailsSettingsContainer__field">
                <Field
                  component={Input}
                  editing={updatingStoreSettings}
                  label="State"
                  name="state"
                  type="text"
                />
              </div>
              <div className="StoreDetailsSettingsContainer__field">
                <Field
                  component={Input}
                  editing={updatingStoreSettings}
                  label="Country"
                  name="country"
                  type="text"
                />
              </div>
              <div className="StoreDetailsSettingsContainer__field">
                <Field
                  component={Input}
                  editing={updatingStoreSettings}
                  label="Zip Code / Post Code"
                  name="zipCode"
                  type="text"
                />
              </div>
            </Spinner>
          </Form>
        </SettingsSection>
      )}
    </Formik>
  );
};

export default StoreDetailsSettingsContainer;
