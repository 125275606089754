import React from "react";
import { useFormatCurrency } from "../../hooks/storeHooks";
import "./BuyLimitWarning.scss";
import { BaseLineItem } from "../../types/pos";

type CartItemBuyLimit = {
  initialQuantity: number;
  maxPurchaseQuantity: number;
  cashOverstockBuyPrice: number | null;
  creditOverstockBuyPrice: number | null;
};

const getWarningText = (
  buyPrice: string | undefined,
  permittedQuantity: number,
  isKioskMode: boolean
) => {
  if (isKioskMode && permittedQuantity === 0) {
    return "This item may be purchased at a reduced price";
  }
  if (isKioskMode) {
    return `Quantities beyond ${permittedQuantity} may be purchased at a reduced price`;
  }
  if (!buyPrice && permittedQuantity === 0) {
    return "None of this item should be purchased in this transaction";
  }
  if (!buyPrice) {
    return `No more than ${permittedQuantity} should be purchased in this transaction`;
  }
  if (permittedQuantity === 0) {
    return `Purchases of this item should be charged at ${buyPrice}`;
  }
  return `Purchases beyond ${permittedQuantity} of this item should be charged at ${buyPrice}`;
};

interface BuyLimitWarningProps {
  item: BaseLineItem;
  cashPrice: boolean;
  isKioskMode: boolean;
  showBuyLimitWarnings: boolean;
  cartItemBuyLimit: CartItemBuyLimit | null;
}

function BuyLimitWarning(props: BuyLimitWarningProps) {
  const {
    item,
    cashPrice,
    isKioskMode,
    showBuyLimitWarnings,
    cartItemBuyLimit,
  } = props;
  const formatCurrency = useFormatCurrency();

  if (!item.buyItem || !cartItemBuyLimit || !showBuyLimitWarnings) {
    return null;
  }

  const {
    initialQuantity,
    maxPurchaseQuantity,
    cashOverstockBuyPrice,
    creditOverstockBuyPrice,
  } = cartItemBuyLimit;
  if (maxPurchaseQuantity === null) {
    return null;
  }
  if (item.qty + initialQuantity < maxPurchaseQuantity) {
    return null;
  }

  let stockWarning;
  if (item.qty + initialQuantity === maxPurchaseQuantity) {
    stockWarning = "The desired stock level for this item has been reached";
  }
  if (item.qty + initialQuantity > maxPurchaseQuantity) {
    stockWarning = "The desired stock level for this item has been exceeded";
  }
  const buyPrice = cashPrice ? cashOverstockBuyPrice : creditOverstockBuyPrice;
  const permittedQuantity = Math.max(maxPurchaseQuantity - initialQuantity, 0);
  const warningText = getWarningText(
    formatCurrency(buyPrice),
    permittedQuantity,
    isKioskMode
  );

  return (
    <div className="BuyLimitWarning">
      <i className="fas fa-exclamation BuyLimitWarning__icon" />
      {stockWarning}. {warningText}.
    </div>
  );
}

export default BuyLimitWarning;
