import React from "react";
import ForceReauthoriseButton from "../../../components/integrations/ForceReauthoriseButton";
import TabNavigation from "../../../components/integrations/TabNavigation";
import SectionHeaderLayout from "../../../components/layout/SectionHeaderLayout";
import IntegrationsSideMenu from "../../../menuStructures/IntegrationsSideMenu";
import EbayLogsRouter from "../../../routers/EbayLogsRouter";
import "./EbayLogsView.scss";

function EbayLogsView() {
  return (
    <>
      <IntegrationsSideMenu />
      <SectionHeaderLayout title="Logs">
        <ForceReauthoriseButton integration="ebay" />
      </SectionHeaderLayout>
      <TabNavigation integrationId="ebay" />
      <div className="ebay-logs-view__container">
        <EbayLogsRouter />
      </div>
    </>
  );
}

export default EbayLogsView;
