const CustomerMenuStructure = {
  title: "Customers",
  links: [
    {
      title: "Customers List",
      href: "customers/list",
    },
    {
      title: "Add Customer",
      href: "customers/add",
    },
  ],
};

export default CustomerMenuStructure;
