import React from "react";
import { Route, Switch } from "react-router-dom";
import FulfillmentSettingsContainer from "../views/integrations/ebay/FulfillmentSettingsContainer";
import LocationSettingsContainer from "../views/integrations/ebay/LocationSettingsContainer";
import PaymentSettingsContainer from "../views/integrations/ebay/PaymentSettingsContainer";
import ReturnSettingsContainer from "../views/integrations/ebay/ReturnSettingsContainer";
import SettingsMenu from "../views/integrations/ebay/SettingsMenu";
import TemplateSettingsContainer from "../views/integrations/ebay/TemplateSettingsContainer";
import MiscSettingsContainer from "../views/integrations/ebay/MiscSettingsContainer";

function EbayIntegrationRouter() {
  return (
    <Switch>
      <Route exact path="/integrations/ebay/policies">
        <SettingsMenu />
      </Route>
      <Route path="/integrations/ebay/policies/location">
        <LocationSettingsContainer />
      </Route>
      <Route path="/integrations/ebay/policies/payment">
        <PaymentSettingsContainer />
      </Route>
      <Route path="/integrations/ebay/policies/returns">
        <ReturnSettingsContainer />
      </Route>
      <Route path="/integrations/ebay/policies/fulfillment">
        <FulfillmentSettingsContainer />
      </Route>
      <Route path="/integrations/ebay/policies/templates">
        <TemplateSettingsContainer />
      </Route>
      <Route path="/integrations/ebay/policies/misc">
        <MiscSettingsContainer />
      </Route>
    </Switch>
  );
}

export default EbayIntegrationRouter;
