import React, { useEffect, useState, useRef } from "react";
import ButtonComponent from "../generic/ButtonComponent";
import Modal from "../generic/Modal";
import Spinner from "../generic/Spinner";
import "./CreditProcess.scss";

interface CreditProcessProps {
  paymentTerminalConfig: {
    forceFinalizeTimeout?: number;
    transactionTimeout?: number;
  };
  amount: number;
  processingMessage: string;
  processingAction?: {
    action: Function;
    args: unknown;
    text: string;
  };
  forceFinalizeCredit: Function;
  cancelCardTransaction: Function;
}

function CreditProcess(props: CreditProcessProps) {
  const {
    paymentTerminalConfig,
    amount,
    processingMessage,
    processingAction,
    forceFinalizeCredit,
    cancelCardTransaction,
  } = props;
  const [cancelling, setCancelling] = useState(false);
  const [overrideCounter, setOverrideCounter] = useState(
    paymentTerminalConfig.forceFinalizeTimeout
  );
  const [transactionTimeout, setTransactionTimeout] = useState(
    paymentTerminalConfig.transactionTimeout
  );

  const overrideTimer = useRef<NodeJS.Timer>();
  const transactionTimer = useRef<NodeJS.Timer>();

  useEffect(() => {
    if (overrideCounter) {
      overrideTimer.current = setInterval(() => {
        setOverrideCounter((prev) => prev - 1);
      }, 1000);
    }
    if (transactionTimeout) {
      transactionTimer.current = setInterval(() => {
        setTransactionTimeout((prev) => prev - 1);
      }, 1000);
    }
    return () => {
      if (overrideTimer) {
        clearInterval(overrideTimer.current);
      }
      if (transactionTimer) {
        clearInterval(transactionTimer.current);
      }
    };
  }, []);

  useEffect(() => {
    if (transactionTimeout < 1) {
      clearInterval(transactionTimer.current);
    }
  }, [transactionTimeout < 1]);

  useEffect(() => {
    if (overrideCounter < 1) {
      clearInterval(overrideTimer.current);
    }
  }, [overrideCounter < 1]);

  const handleCancelClick = async () => {
    setCancelling(true);
    await cancelCardTransaction();
    setCancelling(false);
  };

  const hasError = processingMessage.includes("Error");
  const canForceFinalize = overrideCounter !== undefined || hasError;
  const formattedMessage = processingMessage.replace(/^Error: /, "");

  return (
    <Modal onClose={handleCancelClick} small>
      <Modal.Header>Charging Amount: {amount}</Modal.Header>
      <Modal.Content>
        {hasError ? (
          <div className="CreditProcess__status CreditProcess__status--failed">
            <i className="far fa-exclamation-triangle" />
            Transaction Failed
          </div>
        ) : (
          <>
            <div className="CreditProcess__status CreditProcess__status--processing">
              Processing Transaction
            </div>
            <br />
            <Spinner isLoading={true} />
          </>
        )}
        <div className="CreditProcess__message">{formattedMessage}</div>
        {overrideCounter === 0 || hasError ? (
          <div className="CreditProcess__message">
            Ensure payment was processed before clicking FORCE FINALIZE
          </div>
        ) : null}
        {transactionTimeout === 0 ? (
          <div className="CreditProcess__message">
            Transaction did not complete and timed out. Please cancel and try
            again.
          </div>
        ) : null}
      </Modal.Content>
      <Modal.Actions>
        <ButtonComponent
          onClick={handleCancelClick}
          disabled={cancelling}
          halfWidth={canForceFinalize}
          fullWidth={!canForceFinalize}
        >
          Cancel
        </ButtonComponent>
        {processingAction ? (
          <ButtonComponent
            primary
            onClick={() => processingAction.action(processingAction.args)}
            halfWidth
          >
            {processingAction.text}
          </ButtonComponent>
        ) : null}
        {canForceFinalize ? (
          <ButtonComponent
            disabled={overrideCounter !== 0}
            onClick={() => forceFinalizeCredit()}
            halfWidth
            danger
          >
            FORCE FINALIZE{" "}
            {overrideCounter && overrideCounter > 0 ? overrideCounter : null}
          </ButtonComponent>
        ) : null}
      </Modal.Actions>
    </Modal>
  );
}

export default CreditProcess;
