import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import SellBuyToggle from "./SellBuyToggle";
import { isKioskMode } from "../../helpers/posModeHelpers";
import { segmentAnalytics } from "../services/Analytics";
import { PREDICTIVE_SEARCH_TOGGLED } from "../../constants/eventsTracked";
import { ItemList } from "../../pos/ItemStore";
import "./SearchBar.scss";

interface SearchBarProps {
  posStore?: ItemList;
}
@inject("posStore")
@observer
class SearchBar extends Component<SearchBarProps> {
  nameInput: HTMLInputElement;
  handleKeyDown = (event) => {
    switch (event.keyCode) {
      case 220:
        if (
          (document.activeElement as HTMLInputElement).type != "text" &&
          (document.activeElement as HTMLInputElement).type != "textarea" &&
          (document.activeElement as HTMLInputElement).type != "search" &&
          (document.activeElement as HTMLInputElement).type != "number" &&
          (document.activeElement as HTMLInputElement).type != "tel"
        ) {
          event.preventDefault();
          this.nameInput?.focus();
          this.nameInput?.select();
        }
        break;
      case 13:
        break;
      case 16:
        break;
      case 17:
        break;
      case 18:
        break;
      case 37:
        break;
      case 38:
        break;
      case 39:
        break;
      case 40:
        this.nameInput?.blur();
        (
          document.getElementsByClassName(
            "results-grid"
          ) as HTMLCollectionOf<HTMLElement>
        )?.[0]?.focus();
        break;
      default:
        if (
          (document.activeElement as HTMLInputElement).type != "text" &&
          (document.activeElement as HTMLInputElement).type != "textarea" &&
          (document.activeElement as HTMLInputElement).type != "number" &&
          (document.activeElement as HTMLInputElement).type != "search" &&
          (document.activeElement as HTMLInputElement).type != "tel"
        ) {
          this.nameInput?.focus();
          this.nameInput?.select();
        }
        break;
    }
  };

  componentDidMount() {
    this.nameInput.focus();
    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  render() {
    return (
      <form className="SearchBar">
        <div>
          {!isKioskMode() ? (
            <div className="SearchBar__suggestToggle">
              <span className="predictive">Predictive</span>
              <label className="switch">
                <input
                  className="switchCheckbox"
                  aria-label="Enable predictive search"
                  aria-checked={this.props.posStore.suggestEnabled}
                  type="checkbox"
                  checked={this.props.posStore.suggestEnabled}
                  onChange={() => {
                    segmentAnalytics.track(PREDICTIVE_SEARCH_TOGGLED, {
                      predictive_search: !this.props.posStore.suggestEnabled,
                    });
                    this.props.posStore.toggleSuggest();
                  }}
                />
                <span className="slider round" />
              </label>
            </div>
          ) : null}
          <input
            type="text"
            value={this.props.posStore.searchTerm}
            onChange={this.props.posStore.search}
            className="SearchBar__input"
            placeholder="Search Product"
            aria-label="Search Product"
            onKeyPress={this.props.posStore.fetchBarcode}
            ref={(input) => {
              this.nameInput = input;
            }}
            onFocus={this.props.posStore.setSearchFocused}
            onBlur={this.props.posStore.setSearchBlurredDelayed}
          />
          {this.props.posStore.searchTerm &&
          this.props.posStore.suggestEnabled &&
          this.props.posStore.searchSuggestions.length > 0 &&
          this.props.posStore.searchSuggestions[0] !=
            this.props.posStore.searchTerm &&
          this.props.posStore.searchFocused ? (
            <ul className="SearchBar__suggestBox">
              {this.props.posStore.searchSuggestions.map((title) =>
                title ? (
                  <li
                    className="suggestionItem"
                    key={title}
                    aria-label={`Suggestion: ${title}`}
                    onClick={() =>
                      this.props.posStore.search({ target: { value: title } })
                    }
                    onKeyPress={() =>
                      this.props.posStore.search({ target: { value: title } })
                    }
                    tabIndex={0}
                  >
                    {title}
                  </li>
                ) : null
              )}
            </ul>
          ) : null}
        </div>

        <SellBuyToggle
          buyMode={this.props.posStore.buyMode}
          toggleBuyMode={this.props.posStore.toggleBuyMode}
        />
      </form>
    );
  }
}

export default SearchBar;
