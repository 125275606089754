import { Field } from "formik";
import React from "react";
import { EbayTemplate } from "../../api/rest/ebay";
import EbayDescriptionEditor from "./EbayDescriptionEditor";
import "./EbayListingTemplateForm.scss";

interface EbayListingTemplateFormProps {
  game?: string;
  templates: EbayTemplate[];
  setFieldValue: Function;
}

function EbayListingTemplateForm(props: EbayListingTemplateFormProps) {
  const { game, templates, setFieldValue } = props;

  const onTemplateSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    const templateContents = templates.find(
      (template) => template.id === Number(value)
    );
    if (templateContents) {
      setFieldValue("ebayTemplate", templateContents.template);
    }
  };

  return (
    <>
      <div className="ebay-listing-template-form">
        <div className="ebay-listing-template-form__base-selector">
          <label
            htmlFor="templates"
            className="ebay-listing-template-form__label"
          >
            Listing Description
          </label>
          <select
            id="templates"
            name="templates"
            className="ebay-listing-template-form__select"
            onChange={onTemplateSelect}
          >
            <option value="">-- Select Template --</option>
            {templates.map((template) => (
              <option key={template.id} value={template.id}>
                {template.name}
              </option>
            ))}
          </select>
        </div>
        <div className="ebay-listing-template-form__editor">
          <label className="ebay-listing-template-form__label">
            Customise Template
          </label>
          <Field name="ebayTemplate">
            {({ field }: any) => (
              <EbayDescriptionEditor
                game={game}
                name={field.name}
                value={field.value}
                setFieldValue={setFieldValue}
                onChange={field.onChange(field.name)}
              />
            )}
          </Field>
        </div>
      </div>
      <div className="ebay-listing-template-form__editor-padding"></div>
    </>
  );
}

export default EbayListingTemplateForm;
