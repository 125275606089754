import { GetCompletedBuylistReportQuery } from "../../generated/reporting";

export const formatCompletedBuylistReport = (
  report?: GetCompletedBuylistReportQuery
) => {
  const buylistDetails = report?.Metrics?.CompletedBuylistDetails;
  if (!buylistDetails) {
    return [];
  }
  return buylistDetails.map((detail) => ({
    ...detail,
    customerName: `${detail.firstName} ${detail.lastName}`,
    dateCompleted: Number(detail.dateCompleted),
    dateSubmitted: Number(detail.dateSubmitted),
  }));
};
