import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import Loader from "../generic/Loader";
import ConfirmationModal from "../generic/ConfirmationModal";
import CartLine from "./CartLine";
import KioskCartSubmitModal from "./KioskCartSubmitModal";
import { roundCents } from "../../utils/currencyHelpers";
import { KIOSK_CART_NAME } from "../../constants/featureFlags";
import { ItemList } from "../../pos/ItemStore";
import { RootStore } from "../../store";
import ButtonComponent from "../generic/ButtonComponent";

interface KioskCartProps {
  posStore?: ItemList;
  store?: RootStore;
}

@inject("posStore", "store")
@observer
class KioskCart extends Component<KioskCartProps> {
  async componentDidMount() {
    //if the component mounts while already logged into a till
    //load tax, latest cart, and quicklinks
    //otherwise we expect the float modal to be active and will handle
    //this logic once log in is complete
    if (!this.props.posStore.floatStatus) return;
    if (!this.props.posStore.tillId) return;
    await this.props.posStore.getTax();
    await this.props.posStore.getLatestCart();
    await this.props.posStore.getQuickLinkData();
    await this.props.posStore.getCustomFields();
  }

  state = { saveCartModal: false, confirmationModal: false };

  showSaveCartModal = (visible) => this.setState({ saveCartModal: visible });

  showConfirmationModal = (visible) =>
    this.setState({ saveCartModal: false, confirmationModal: visible });

  saveCart = async (cartName) => {
    this.props.posStore.setCartName(cartName);
    await this.props.posStore.validateCartNoRefresh();
    this.showConfirmationModal(true);
  };

  LEGACY_saveCart = () => {
    this.props.posStore.newCart();
    this.showSaveCartModal(false);
  };

  handleConfirmClick = async () => {
    await this.props.posStore.newCart();
    this.showConfirmationModal(false);
  };

  render() {
    const fC = this.props.posStore.fCurr;
    const promptForCustomerName =
      this.props.posStore.isFeatureFlagEnabled(KIOSK_CART_NAME);

    return (
      <React.Fragment>
        <div className="cartNumber">Cart #{this.props.posStore.cartId}</div>
        <div
          className={
            this.props.posStore.disableLineItems ? " cart disable" : "cart"
          }
        >
          {this.props.posStore.gettingLatestCart ? (
            <Loader text="Loading cart..." />
          ) : (
            <div className="cart-items" role="list">
              {this.props.posStore.cart.map((item) => (
                <CartLine item={item} key={item.productId} />
              ))}
            </div>
          )}
          {/*This div is used as a reference to autoscroll to the bottom*/}
          <div style={{ float: "left", clear: "both" }}></div>
          <div className="cartTotals">
            <span className="subTotal">
              Subtotal ({this.props.posStore.totalItems} Item)
              <em>{fC(roundCents(this.props.posStore.subTotal))}</em>
            </span>
            <span className="tax">
              Tax ({this.props.posStore.taxRateDisplay}%)
              {this.props.posStore.negatedTaxTotal ? (
                <span className="taxNegated">
                  {"("} {fC(roundCents(this.props.posStore.negatedTaxTotal))}{" "}
                  {"negated by trade ins)"}
                </span>
              ) : (
                ""
              )}
              <em>{fC(roundCents(this.props.posStore.taxTotal))}</em>
            </span>
            <span className="total">
              Total<em>{fC(roundCents(this.props.posStore.total))}</em>
            </span>
          </div>
          <ButtonComponent
            primary
            fullWidth
            className="Cart__buyButton"
            icon="fas fa-caret-right"
            iconPosition="right"
            data-testid="buy-button"
            onClick={() => this.showSaveCartModal(true)}
          >
            Submit
          </ButtonComponent>
        </div>
        {!promptForCustomerName && this.state.saveCartModal ? (
          <ConfirmationModal
            cancelAction={() => this.showSaveCartModal(false)}
            confirmAction={this.LEGACY_saveCart}
          >
            Are you sure you want to submit this cart? Once you submit it,
            please notify staff you are ready to pick up your order.
            Confirmation number is #{this.props.posStore.cartId}
          </ConfirmationModal>
        ) : null}
        {promptForCustomerName && this.state.saveCartModal ? (
          <KioskCartSubmitModal
            cancelAction={() => this.showSaveCartModal(false)}
            confirmAction={this.saveCart}
          />
        ) : null}
        {this.state.confirmationModal ? (
          <ConfirmationModal
            title="Cart Submitted"
            confirmWord="Ok"
            confirmAction={this.handleConfirmClick}
          >
            <p>
              Your cart has been submitted. Please notify staff you are ready to
              pick up your order.
            </p>
            <p>Confirmation number: #{this.props.posStore.cartId}.</p>
          </ConfirmationModal>
        ) : null}
      </React.Fragment>
    );
  }
}

export default KioskCart;
