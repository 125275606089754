export const isNull = (value: unknown) => value === null;

export const convertToSnakeCase = (value: string) =>
  value.toLowerCase().split(" ").join("_");

export const filterDecimalString = (
  input: string,
  decimals: number
): string => {
  if (input === ".") {
    return "0.";
  }
  const split = input.split(".");
  if (!isNaN(Number(input)) && split.length <= 2) {
    if (split.length === 2 && split[1].length === 0) {
      return input;
    }
    if (split.length === 2 && split[1].length > 2) {
      return `${split[0]}.${split[1].slice(0, decimals)}`;
    }
    return String(input);
  }
  return null;
};

export const arrayHasEntryWithTrueValue = (key: string, arr: any[]) =>
  arr.some((val) => val[key] === true);

export const isANumber = (value: number | string) => {
  const validNumber = parseFloat(String(value));
  if (isNaN(validNumber) || String(validNumber) !== String(value)) {
    return false;
  }
  return true;
};

export const isAPositiveWholeNumber = (value: number | string) => {
  const validNumber = parseFloat(String(value));
  if (!Number.isInteger(validNumber) || validNumber <= 0) {
    return false;
  }
  return true;
};

export const removeNullValuesFromObject = (obj: Record<string, any>) =>
  Object.fromEntries(Object.entries(obj).filter(([, value]) => !!value));
