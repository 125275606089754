import React, { useState } from "react";
import { ApexOptions } from "apexcharts";
import moment from "moment";
import "moment-timezone";
import ReactApexChart from "react-apexcharts";
import Select from "react-select";
import {
  defaultChartOptions,
  paymentTypeCollections,
  rangeOptions,
  rangeOptionToDates,
} from "../../constants/charts";
import { useDailyPosSalesDataQuery } from "../../generated/reporting";
import { useFormatCurrency } from "../../hooks/storeHooks";
import "./SalesBarChart.scss";
import { segmentAnalytics } from "../services/Analytics";
import {
  CHART_DATE_RANGE_SELECTED,
  DATA_TYPE_SELECTED,
} from "../../constants/eventsTracked";

const LONG_LIFE_DATA_STALE_TIME = 60 * 1000; // ms

function SalesBarChart() {
  const timeZoneOffset = moment.tz(moment.tz.guess()).format("Z");
  const [paymentTypes, setPaymentTypes] = useState([
    "Cash_In",
    "Credit_In",
    "EFTPOS_In",
    "Store_Credit_In",
  ]);
  const defaultRange = rangeOptionToDates.today;
  const [startDate, setStartDate] = useState(defaultRange.startDate);
  const [endDate, setEndDate] = useState(defaultRange.endDate);
  const [binSize, setBinSize] = useState(defaultRange.binSize);
  const [selectedLabel, setSelectedLabel] = useState("Today");
  const [dataTypeLabel, setDataTypeLabel] = useState("Sales");
  const formatCurrency = useFormatCurrency();
  const { data } = useDailyPosSalesDataQuery(
    {
      startDate,
      endDate,
      binSize,
      timeZoneOffset,
    },
    {
      keepPreviousData: true,
      staleTime: LONG_LIFE_DATA_STALE_TIME,
    }
  );

  type OptionType = { label: string; value: string };

  const updateDataTypes = async ({ label, value }: OptionType) => {
    segmentAnalytics.track(DATA_TYPE_SELECTED, {
      data_type: label,
      chart_type: "Totals Chart",
    });
    setDataTypeLabel(label);
    setPaymentTypes(paymentTypeCollections[value]);
  };

  const updateRange = ({ label, value }: OptionType) => {
    segmentAnalytics.track(CHART_DATE_RANGE_SELECTED, {
      range_title: label,
      chart_type: "Totals Chart",
    });
    setSelectedLabel(label);
    const selectedDates = rangeOptionToDates[value];
    setStartDate(selectedDates.startDate);
    setEndDate(selectedDates.endDate);
    setBinSize(selectedDates.binSize);
  };

  const chartOptions: ApexOptions = {
    ...defaultChartOptions,
    xaxis: {
      type: "datetime",
      axisTicks: {
        show: false,
      },
      labels: {
        datetimeUTC: false,
        datetimeFormatter: {
          year: "yyyy",
          month: "MMM 'yy",
          day: "dd MMM",
          hour: "h:mm tt",
        },
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        formatter: function (val) {
          return "$" + val;
        },
      },
    },
  };

  let dataPoints = [];
  if (data?.Metrics.SalesByRange) {
    dataPoints = data.Metrics.SalesByRange.map((sale) => ({
      x: sale.time,
      y: paymentTypes
        .reduce(
          (accumulator, paymentType) => accumulator + sale[paymentType],
          0
        )
        .toFixed(2),
    }));
  }

  let totalSales;
  if (data?.Metrics?.Sales?.Total_In) {
    totalSales = paymentTypes
      .reduce(
        (accumulator, paymentType) =>
          accumulator + data.Metrics.Sales[paymentType],
        0
      )
      .toFixed(2);
  }

  const chartSeries = [{ name: "Sales", data: dataPoints }];

  return (
    <div className="SalesBarChart">
      <div className="row">
        <div className="col-sm-4">
          <label className="sr-only" htmlFor="salesSource">
            Sales Source
          </label>
          <Select
            inputId="salesSource"
            className="SalesBarChart__selectInput"
            classNamePrefix="SalesBarChart__selectInput"
            options={[{ label: "Point of Sale", value: "pos" }]}
            defaultValue={{ label: "Point of Sale", value: "pos" }}
          />
        </div>
        <div className="col-sm-4">
          <label className="sr-only" htmlFor="dataType">
            Data type
          </label>
          <Select
            inputId="dataType"
            aria-label="Data type"
            className="SalesBarChart__selectInput"
            classNamePrefix="SalesBarChart__selectInput"
            options={[
              {
                label: "Sales",
                value: "sales",
              },
              {
                label: "Buys",
                value: "buys",
              },
              {
                label: "Buys + Sales",
                value: "buys_sales",
              },
              {
                label: "Cash + Credit In",
                value: "cash_credit_in",
              },
              {
                label: "Store Credit In",
                value: "store_credit_in",
              },
            ]}
            onChange={updateDataTypes}
            defaultValue={{
              label: "Sales",
              value: "sales",
            }}
          />
        </div>
        <div className="col-sm-4">
          <label className="sr-only" htmlFor="salesRange">
            Range
          </label>
          <Select
            inputId="salesRange"
            className="SalesBarChart__selectInput"
            classNamePrefix="SalesBarChart__selectInput"
            options={rangeOptions}
            onChange={updateRange}
            defaultValue={{ label: "Today", value: "" }}
          />
        </div>
      </div>
      <div className="SalesBarChart__header">
        <h2 className="SalesBarChart__title">
          {selectedLabel}'s {dataTypeLabel}
        </h2>
        <span className="SalesBarChart__period">{selectedLabel}</span>
      </div>
      <p className="SalesBarChart__total">{formatCurrency(totalSales)}</p>
      <ReactApexChart
        aria-label="Chart of sales, buys and credit movement totals from a selected source in the selected time window"
        options={chartOptions}
        series={chartSeries}
        type="bar"
        height="250"
      />
    </div>
  );
}

export default SalesBarChart;
