import React, { useState } from "react";
import { notify } from "../../helpers/notificationHelpers";
import { useShowError } from "../../hooks/errorHooks";
import { useSendPasswordReset } from "../../hooks/userHooks";
import { User } from "../../types/user";
import ConfirmationModal from "../generic/ConfirmationModal";
import IconButton from "../generic/IconButton";

interface PasswordResetButtonProps {
  user: User;
}

function PasswordResetButton(props: PasswordResetButtonProps) {
  const { user } = props;
  const [showPasswordReset, setShowPasswordReset] = useState<boolean>(false);
  const showError = useShowError();
  const { mutateAsync: resetPassword } = useSendPasswordReset();

  const cancelPasswordReset = () => setShowPasswordReset(false);

  const sendPasswordReset = () =>
    resetPassword(user.id)
      .then(() => {
        notify.info("Password reset sent");
        setShowPasswordReset(false);
      })
      .catch(showError);

  if (showPasswordReset) {
    return (
      <ConfirmationModal
        title="Send password reset?"
        cancelAction={cancelPasswordReset}
        confirmAction={sendPasswordReset}
      >
        Do you want to send a password reset email to {user.email}
      </ConfirmationModal>
    );
  }

  return (
    <IconButton
      icon="fal fa-paper-plane"
      title="Send a password reset"
      className="userActions"
      onClick={() => setShowPasswordReset(true)}
    />
  );
}

export default PasswordResetButton;
