import { ADABOT_BINDER_PORTAL } from "../../constants/featureFlags";
import { useFeatureFlag } from "../../hooks/globalSettingsHooks";
import { SUPPORT_CLICKED } from "../../constants/eventsTracked";
import NavButton from "./NavButton";

export function AdaBotNavbarButton() {
  const adaBotEnabled = useFeatureFlag(ADABOT_BINDER_PORTAL);

  return adaBotEnabled ? (
    <NavButton
      onClick={() => window?.adaEmbed.toggle()}
      title="Open the chat bot"
      icon="fas fa-question-circle"
      segmentEventName={SUPPORT_CLICKED}
    >
      Support
    </NavButton>
  ) : null;
}
