import React from "react";
import { Field } from "formik";
import Input from "../form/Input";
import CheckboxComponent from "../generic/CheckboxComponent";
import AdditionalInfoForm from "./AdditionalInfoForm";
import { segmentAnalytics } from "../services/Analytics";
import { EVENT_REQUIRE_TICKED_TOGGLED } from "../../constants/eventsTracked";
import { EventInfo } from "../../types/events";

interface EventTicketingFormProps {
  values: EventInfo;
  setFieldValue: SetFieldValue;
  isEditing: boolean;
}

function EventTicketingForm(props: EventTicketingFormProps) {
  const { values, setFieldValue, isEditing } = props;

  return (
    <>
      <div className="Event__formSection Event__formSection--no-background">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <CheckboxComponent
                name="eventIsTicketed"
                label="Does this event require a ticket?"
                checked={values.isTicketed}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue("isTicketed", event.target.checked);
                  setFieldValue("ticketed", event.target.checked);
                  segmentAnalytics.track(EVENT_REQUIRE_TICKED_TOGGLED, {
                    event_require_ticket: event.target.checked,
                  });
                }}
              />
            </div>
            {values.isTicketed ? (
              <>
                <div className="col-lg-12 col-xl-4">
                  <Field
                    component={Input}
                    name="ticketPrice"
                    type="number"
                    min="0"
                    placeholder="E.g. 29.95"
                    step=".01"
                    label="Price of ticket"
                  />
                  <p className="Event__helpText">
                    Entering 0 will mean the ticket is free
                  </p>
                </div>
                <div className="col-lg-12 col-xl-4">
                  <Field
                    component={Input}
                    name="maxParticipants"
                    type="number"
                    min="0"
                    placeholder="E.g. 50"
                    step="1"
                    label="Maximum participants (Max tickets)"
                  />
                  <p className="Event__helpText">
                    Entering 0 indicates that there are unlimited tickets
                    available
                  </p>
                </div>
                <div className="col-lg-12 col-xl-4">
                  <br />
                  <CheckboxComponent
                    name="taxable"
                    label="Charge tax on event ticket?"
                    checked={values.taxable}
                    disabled={isEditing}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue("taxable", event.target.checked)
                    }
                  />
                  {isEditing ? (
                    <p className="Event__helpText Event__helpText--withPadding">
                      Tax settings cannot be changed after an event is created
                    </p>
                  ) : null}
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
      {values.isTicketed ? (
        <div className="Event__formSection Event__formSection--no-background">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <CheckboxComponent
                  name="additionalInfoRequired"
                  label="Does this event require additional information?"
                  checked={values.additionalInfoRequired}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue(
                      "additionalInfoRequired",
                      event.target.checked
                    );
                    if (
                      event.target.checked &&
                      values.additionalInfo.length < 1
                    ) {
                      setFieldValue("additionalInfo", [
                        ...values.additionalInfo,
                        { header: "", headerDescription: "" },
                      ]);
                    }
                  }}
                  disabled={values.id && values.additionalInfoRequired}
                />
                <span className="Event__helpText">
                  {" "}
                  (For example DCI number)
                </span>
              </div>
            </div>
            {values.additionalInfoRequired ? (
              <div id="additionalInfoSection">
                <div className="row">
                  {values.additionalInfo.map((additionalInfo, index) => (
                    <div className="col-lg-12 col-xl-4" key={index}>
                      <AdditionalInfoForm
                        additionalInfo={additionalInfo}
                        position={index}
                        values={values}
                        setFieldValue={setFieldValue}
                      />
                    </div>
                  ))}
                  <div className="col-lg-12 col-xl-4">
                    <div
                      className="AdditionalInfoForm AdditionalInfoForm__add"
                      onClick={() =>
                        setFieldValue("additionalInfo", [
                          ...values.additionalInfo,
                          { header: "", headerDescription: "" },
                        ])
                      }
                    >
                      <i className="AdditionalInfoForm__addIcon far fa-plus-square" />
                      Add Info
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  );
}

export default EventTicketingForm;
