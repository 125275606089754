import { GetSalesByCategoryQuery } from "../../generated/reporting";
import { SalesByCategoryData } from "../../types/reports";

export function formatTotalSalesByCategory(
  report?: GetSalesByCategoryQuery
): SalesByCategoryData {
  const categorySales = report?.Metrics?.CategorySales;
  if (!categorySales) {
    return [];
  }
  // Add an ID field so the table can use it as a key
  return categorySales.map((row, index) => ({
    ...row,
    id: index,
    buys: 0 - row.buys,
    total: row.buys + row.sales,
  }));
}
