const EventMenuStructure = {
  title: "Events",
  links: [
    {
      title: "My Events",
      href: "events/list",
    },
    {
      title: "Add an Event",
      href: "events/add",
    },
    {
      title: "Failed to Create",
      href: "events/failedToCreate",
    },
    {
      title: "Failed to Delete",
      href: "events/failedToDelete",
    },
    {
      title: "Event Settings",
      href: "events/settings",
    },
  ],
};

export default EventMenuStructure;
