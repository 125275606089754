import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Loader from "../generic/Loader";
import GridLayout from "../layout/GridLayout";
import BuylistItem from "./BuylistItem";
import BuylistAddCardButton from "./BuylistAddCardButton";

@inject("store")
@observer
class GridView extends Component {
  render() {
    const {
      removeLine,
      setSearchModalVisible,
      onVariantQuantityChange,
      onVariantBuyPriceChange,
      viewRef,
    } = this.props;
    const { BuylistRulesStore, CustomersStore } = this.props.store;
    const { completed, paymentType, cards } = BuylistRulesStore.buylistDetails;

    if (!CustomersStore.customerVariants) return <Loader />;

    return (
      <GridLayout>
        {cards.map((card, index) => (
          <BuylistItem
            key={index}
            index={index}
            card={card}
            isBuylistCompleted={completed}
            paymentType={paymentType}
            customerVariants={CustomersStore.customerVariants}
            onVariantQuantityChange={onVariantQuantityChange}
            onVariantBuyPriceChange={onVariantBuyPriceChange}
            removeLine={removeLine}
            ref={viewRef}
          />
        ))}
        {!completed ? (
          <BuylistAddCardButton
            onAddCardClick={(event) => {
              setSearchModalVisible(true, event.currentTarget);
            }}
          />
        ) : null}
      </GridLayout>
    );
  }
}

GridView.propTypes = {
  onVariantQuantityChange: PropTypes.func.isRequired,
  onVariantBuyPriceChange: PropTypes.func.isRequired,
  removeLine: PropTypes.func.isRequired,
  setSearchModalVisible: PropTypes.func.isRequired,
};

export default GridView;
