import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import SunEditor, { buttonList } from "suneditor-react";
import { TimeUtils } from "../../utils";
import { getRecurringDescription } from "../../helpers/eventHelpers";
import { parseNumericParam } from "../../helpers/routeHelpers";
import { eventSchema } from "../../schemas/event";
import {
  useAddEvent,
  useFetchEventGamesForClient,
  useFetchEventTypesForClient,
  useFetchEventInfo,
  useUpdateEvent,
} from "../../hooks/eventHooks";
import { useShowError } from "../../hooks/errorHooks";
import { useFetchSetting } from "../../hooks/settingHooks";
import { EventMenuStructure } from "../../menuStructures";
import SetActiveMenu from "../../menuStructures/SetActiveMenu";
import { EventInfo } from "../../types/events";
import EventImageUpload from "../../components/events/EventImageUpload";
import EventLocationInput from "../../components/events/EventLocationInput";
import EventRepetition from "../../components/events/EventRepetition";
import Input from "../../components/form/Input";
import Select from "../../components/form/Select";
import Modal from "../../components/generic/Modal";
import Loader from "../../components/generic/Loader";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";
import ActionButtonsLayout from "../../components/layout/ActionButtonsLayout";
import ButtonComponent from "../../components/generic/ButtonComponent";
import UploadImage from "../../../assets/img/uploadImage.png";
import EventTicketingForm from "../../components/events/EventTicketingForm";
import InfoPanel from "../../components/generic/InfoPanel";
import {
  UPLOAD_EVENT_ICON_CLICKED,
  UPLOAD_EVENT_FEATURE_IMAGE_CLICKED,
  UPLOAD_EVENT_TICKET_IMAGE,
  SAVE_CHANGES_CLICKED,
} from "../../constants/eventsTracked";
import "./Event.scss";

type RouteParams = {
  eventId?: string;
};

const initialValues = (dateFormat?: string) => ({
  id: undefined,
  title: "",
  game: "",
  type: "",
  date: "",
  time: "",
  recurring: false,
  recurringFrequency: 1,
  recurringType: "",
  recurringEndDate: "",
  recurringDays: "",
  monthlyRecurringType: "",
  monthlyRecurringDayNumber: "",
  monthlyRecurringDay: "",
  includeDateInEventTitle: "",
  dateFormat: dateFormat ?? "EEE, MMM dd yyyy",
  buildingName: "",
  streetAddress: "",
  city: "",
  state: "",
  zipCode: "",
  prizePool: "",
  description: "",
  prizeStructure: "",
  results: "",
  isTicketed: false,
  ticketed: false,
  ticketPrice: 0,
  maxParticipants: 0,
  taxable: false,
  additionalInfoRequired: false,
  additionalInfo: [],
});

function Event() {
  const { eventId: eventIdParam } = useParams<RouteParams>();
  const history = useHistory();
  const eventId = parseNumericParam(eventIdParam);
  const iconImageRef = useRef<HTMLInputElement>(null);
  const featureImageRef = useRef<HTMLInputElement>(null);
  const ticketImageRef = useRef<HTMLInputElement>(null);
  const [showConfirmation, setShowConfirmation] = useState<string>();
  const { data: eventGames } = useFetchEventGamesForClient();
  const { data: eventTypes } = useFetchEventTypesForClient();
  const { data: dateFormat } = useFetchSetting("eventDateFormat");
  const { data: eventInfo, isLoading, error } = useFetchEventInfo(eventId);
  const { mutateAsync: saveAsync } = useAddEvent();
  const { mutateAsync: updateAsync } = useUpdateEvent();
  const showError = useShowError();

  useEffect(() => {
    if (error) {
      showError(
        error as DetailedError,
        "Unable to load event details",
        "Please refresh and try again"
      );
    }
  }, [error]);

  const handleSubmit = (values: EventInfo) => {
    delete values.childEvents;
    if (values.id) {
      updateEvent(values);
    } else {
      addEvent(values);
    }
  };

  const addEvent = (event: EventInfo) =>
    saveAsync(buildForm(event))
      .then(() => setShowConfirmation("added"))
      .catch((err: DetailedError) =>
        showError(
          err,
          "Unable to add event",
          "Please check the details and try again"
        )
      );

  const updateEvent = (event: EventInfo) =>
    updateAsync({
      eventId: event.id,
      event: buildForm(event),
    })
      .then(() => setShowConfirmation("updated"))
      .catch((err: DetailedError) =>
        showError(
          err,
          "Unable to update event",
          "Please check the details and try again"
        )
      );

  const buildForm = (event: EventInfo) => {
    const formData = new FormData();
    if (iconImageRef?.current.files[0]) {
      formData.append("calendarIcon", iconImageRef.current.files[0]);
    } else if (event.calendarIcon) {
      formData.append("calendarIconUrl", event.calendarIcon);
    }
    if (featureImageRef?.current.files[0]) {
      formData.append("banner", featureImageRef.current.files[0]);
    } else if (event.banner) {
      formData.append("bannerUrl", event.banner);
    }
    if (ticketImageRef?.current.files[0]) {
      formData.append("ticketImage", ticketImageRef.current.files[0]);
    } else if (event.ticketImage) {
      formData.append("ticketImageUrl", event.ticketImage);
    }
    formData.append("event", JSON.stringify(event));
    return formData;
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <SetActiveMenu menuStructure={EventMenuStructure} />
      <Formik
        initialValues={eventInfo || initialValues(dateFormat)}
        enableReinitialize
        validationSchema={eventSchema}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting, setFieldValue }) => (
          <Form>
            <SectionHeaderLayout
              title={
                values.id ? `Update ${eventInfo?.title}` : "Create new event"
              }
            >
              <ActionButtonsLayout>
                {values.id ? (
                  <ButtonComponent
                    primary
                    onClick={() => setFieldValue("id", "", false)}
                  >
                    {" "}
                    Duplicate
                  </ButtonComponent>
                ) : null}
                <ButtonComponent primary type="submit" disabled={isSubmitting}>
                  {values.id ? "Update" : "Save"}
                </ButtonComponent>
              </ActionButtonsLayout>
            </SectionHeaderLayout>
            <div className="Event">
              <div className="Event__formSection Event__formSection--top">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-12 col-xl-4">
                      <Field
                        component={Input}
                        name="title"
                        type="text"
                        label="Event title"
                        placeholder="E.g. Friday Commander Night"
                        requiredLabel
                      />
                    </div>
                    <div className="col-lg-12 col-xl-4">
                      <Field
                        component={Input}
                        name="game"
                        type="text"
                        label="Event game"
                        placeholder="Search game"
                        list="eventGamesList"
                        requiredLabel
                      />
                      <datalist id="eventGamesList">
                        {eventGames?.map((game) => (
                          <option key={game} value={game}>
                            {game}
                          </option>
                        ))}
                      </datalist>
                    </div>
                    <div className="col-lg-12 col-xl-4">
                      <Field
                        component={Input}
                        name="type"
                        type="text"
                        label="Event type"
                        placeholder="Search event type"
                        list="eventTypesList"
                        requiredLabel
                      />
                      <datalist id="eventTypesList">
                        {eventTypes?.map((type) => (
                          <option key={type} value={type}>
                            {type}
                          </option>
                        ))}
                      </datalist>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Event__formSection">
                <div className="container-fluid">
                  <p className="Event__formSectionHeader">Event information</p>
                  <div className="row">
                    <div className="col-lg-12 col-xl-4">
                      {eventInfo?.recurring ? (
                        <>
                          <label className="label">Event date</label>
                          <p>
                            {TimeUtils.convertDateToHumanReadableNoTime(
                              eventInfo?.date
                            )}
                          </p>
                        </>
                      ) : (
                        <Field
                          component={Input}
                          name="date"
                          type="date"
                          label="Event date"
                          placeholder="E.g. 18/07/2018"
                          requiredLabel
                          value={TimeUtils.dateInput(values.date)}
                        />
                      )}
                    </div>
                    <div className="col-lg-12 col-xl-4">
                      <Field
                        component={Input}
                        name="time"
                        type="time"
                        label="Event time"
                        placeholder="E.g. 10:00"
                        requiredLabel
                      />
                    </div>
                    {!values?.id ? (
                      <div className="col-lg-12 col-xl-4">
                        <Field
                          component={Select}
                          name="recurring"
                          label="Event frequency"
                          className="Event__select"
                          requiredLabel
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const isRecurring = event.target.value === "1";
                            setFieldValue("recurring", isRecurring);
                            setFieldValue("isRecurring", isRecurring);
                          }}
                          value={values.recurring ? "1" : "0"}
                        >
                          <option value="0">One off event</option>
                          <option value="1">Recurring</option>
                        </Field>
                      </div>
                    ) : null}
                  </div>
                </div>
                <EventRepetition
                  values={values}
                  setFieldValue={setFieldValue}
                />
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-12 col-xl-4">
                      <Field
                        component={Select}
                        name="includeDateInEventTitle"
                        label="Include date in event title"
                        className="Event__select"
                      >
                        <option value="">Use default</option>
                        <option value="true">Include</option>
                        <option value="false">Exclude</option>
                      </Field>
                    </div>
                    <div className="col-lg-12 col-xl-4">
                      <Field
                        component={Input}
                        name="dateFormat"
                        label="Custom date format"
                        disabled={values.includeDateInEventTitle === "false"}
                      />
                    </div>
                    <div className="col-lg-12 col-xl-4">
                      <InfoPanel>
                        <a
                          href="https://docs.oracle.com/javase/8/docs/api/java/time/format/DateTimeFormatter.html#patterns"
                          target="_blank"
                          rel="noopner"
                        >
                          Date format details
                        </a>
                        . Default can be set in{" "}
                        <Link to="/events/settings">Event Settings</Link>
                      </InfoPanel>
                    </div>
                  </div>
                </div>
              </div>
              {eventInfo?.id ? (
                <div className="container-fluid Event__formSection Event__formSection--no-background">
                  <label className="label">Event frequency</label>
                  <p>{getRecurringDescription(eventInfo)}</p>
                </div>
              ) : null}
              <div className="Event__formSection">
                <EventLocationInput setFieldValue={setFieldValue} />
              </div>
              <div className="Event__formSection">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-12 col-xl-4">
                      <Field
                        component={Input}
                        name="prizePool"
                        type="text"
                        label="Prizepool"
                        placeholder="$5000"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="Event__formSection Event__formSection--no-background">
                <label className="label">Description</label>
                <Field name="description">
                  {({ field }: any) => (
                    <SunEditor
                      setContents={field.value}
                      onChange={field.onChange(field.name)}
                      setOptions={{
                        height: "200",
                        tabDisable: true,
                        buttonList: [...buttonList.formatting, ["link"]],
                      }}
                    />
                  )}
                </Field>
              </div>
              <div className="Event__formSection Event__formSection--no-background">
                <label className="label">Prize structure</label>
                <Field name="prizeStructure">
                  {({ field }: any) => (
                    <SunEditor
                      setContents={field.value}
                      onChange={field.onChange(field.name)}
                      setOptions={{
                        height: "200",
                        tabDisable: true,
                        buttonList: [...buttonList.formatting, ["link"]],
                      }}
                    />
                  )}
                </Field>
              </div>
              <div className="Event__formSection Event__formSection--no-background">
                <label className="label">Event results</label>
                <Field name="results">
                  {({ field }: any) => (
                    <SunEditor
                      setContents={field.value}
                      onChange={field.onChange(field.name)}
                      setOptions={{
                        height: "200",
                        tabDisable: true,
                        buttonList: [...buttonList.formatting, ["link"]],
                      }}
                    />
                  )}
                </Field>
              </div>
              <div className="Event__formSection">
                <div className="container-fluid">
                  <p className="Event__formSectionHeader">Event images</p>
                  <div className="row">
                    <div className="col-md-12 col-lg-4 col-xl-4">
                      <EventImageUpload
                        label="Event calendar icon"
                        placeholder="https://storage.googleapis.com/binderpos-event-images/binder-event-icon.png"
                        selectedImage={eventInfo?.calendarIcon}
                        ref={iconImageRef}
                        segmentEventName={UPLOAD_EVENT_ICON_CLICKED}
                        segmentEventProperties={{
                          action_type: values.id ? "edit" : "add",
                        }}
                      />
                    </div>
                    <div className="col-md-12 col-lg-4 col-xl-4">
                      <EventImageUpload
                        label="Event feature image"
                        placeholder={UploadImage}
                        selectedImage={eventInfo?.banner}
                        ref={featureImageRef}
                        segmentEventName={UPLOAD_EVENT_FEATURE_IMAGE_CLICKED}
                        segmentEventProperties={{
                          action_type: values.id ? "edit" : "add",
                        }}
                      />
                    </div>
                    <div className="col-md-12 col-lg-4 col-xl-4">
                      <EventImageUpload
                        label="Event ticket image"
                        placeholder="https://storage.googleapis.com/binderpos-event-images/binderpos-ticket.png"
                        selectedImage={eventInfo?.ticketImage}
                        ref={ticketImageRef}
                        segmentEventName={UPLOAD_EVENT_TICKET_IMAGE}
                        segmentEventProperties={{
                          action_type: values.id ? "edit" : "add",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <EventTicketingForm
                values={values}
                setFieldValue={setFieldValue}
                isEditing={!!eventInfo?.isTicketed}
              />
              <ButtonComponent
                primary
                type="submit"
                disabled={isSubmitting}
                fullWidth
                icon="fas fa-caret-right"
                iconPosition="right"
                segmentEventName={SAVE_CHANGES_CLICKED}
                segmentEventProperties={{
                  form_type: eventInfo?.id ? "Edit event" : "Add event",
                }}
              >
                {eventInfo?.id
                  ? "Save and update event"
                  : "Save and publish event"}
              </ButtonComponent>
            </div>
          </Form>
        )}
      </Formik>
      {showConfirmation ? (
        <Modal small onClose={() => history.push("/events")}>
          <Modal.Header>Event {showConfirmation}</Modal.Header>
          <Modal.Content>
            Your event has been successfully {showConfirmation}
          </Modal.Content>
        </Modal>
      ) : null}
    </>
  );
}

export default Event;
