import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import React from "react";
import { notify } from "../../helpers/notificationHelpers";
import { roundCents } from "../../utils/currencyHelpers";
import ButtonComponent from "../generic/ButtonComponent";
import Tooltip from "../generic/Tooltip";
import {
  STOCK_QTY_CLICKED,
  RESERVE_QTY_CLICKED,
  SELL_OVERRIDE_CLICKED,
  CASH_BUY_CLICKED,
  CREDIT_BUY_CLICKED,
  BUY_LIMIT_CLICKED,
  OVER_LIMIT_CASH_BUY_CLICKED,
  OVER_LIMIT_CREDIT_BUY_CLICKED,
  CASCADE_CHANGES_CLICKED,
  UPDATE_PRODUCT_CLICKED,
} from "../../constants/eventsTracked";
import { segmentAnalytics } from "../services/Analytics";
import "./ProductLineVariantItem.scss";
import { RootStore } from "../../store";
import QuantitySelector from "../generic/QuantitySelector";

const isNil = (value, zeroIsOkay = false) => {
  if (value === undefined) return true;
  if (value === null) return true;
  // TODO:: Find out why some values are false. It makes no sense
  if (value === false) return true;
  if (value === "") return true;
  if (zeroIsOkay && value === 0) return true;
  return false;
};

const areSame = (fieldValue, originalValue, zeroIsOkay = false) => {
  if (isNil(fieldValue, zeroIsOkay) && isNil(originalValue, zeroIsOkay)) {
    return true;
  }
  return fieldValue === originalValue;
};

type LineVariantItem = Pick<
  Variant,
  | "cashBuyPrice"
  | "id"
  | "maxInstockBuyPercentage"
  | "maxInstockBuyPrice"
  | "maxInstockCreditBuyPrice"
  | "maxInstockCreditBuyPercentage"
  | "priceOverride"
  | "reserveQuantity"
  | "shopifyId"
> & {
  buyLimit: number | null;
  cashBuyPercentage: number | null;
  creditBuyPrice: number | null;
  creditBuyPercentage: number | null;
  stock: number | null;
  stockUpdateType: string;
};

interface ProductLineVariantItemProps {
  store?: RootStore;
  setUpdateVariant: (theFunction: Function) => void;
  variant: Variant;
  parentListIndex: number;
  listIndex: number;
  columnsToShow: string[];
  currentInput: () => number;
  updateCurrentInput: (input: number) => void;
  getStockUpdateType: () => string;
  onChange: (newVariant: any, currentVariants: any) => void;
  onComplete: (id: number) => void;
  cascade: {
    suffix: string;
    variant: any;
  };
  setCascade: ({ suffix, variant }) => void;
}

interface ProductLineVariantItemState {
  variant: LineVariantItem;
  savedVariant: LineVariantItem;
}

@inject("store")
@observer
export default class ProductLineVariantItem extends React.Component<
  ProductLineVariantItemProps,
  ProductLineVariantItemState
> {
  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.updateVariant();
    }
    if (event.key === "w" || event.key === "W") {
      event.preventDefault();
      if (this.parentLineItemId == 0 && this.currentLineItemId == 0) {
        return;
      }
      if (this.currentLineItemId - 1 == -1) {
        const foundLines = document.querySelectorAll(
          "[id^=variantline-" + (this.parentLineItemId - 1) + "-]"
        ) as NodeListOf<HTMLElement>;
        (
          document.querySelector(
            "#variantlineitem-" +
              (this.parentLineItemId - 1) +
              "-" +
              (foundLines.length - 1) +
              "-" +
              this.props.currentInput()
          ) as HTMLElement
        ).focus();
      } else {
        (
          document.querySelector(
            "#variantlineitem-" +
              this.parentLineItemId +
              "-" +
              (this.currentLineItemId - 1) +
              "-" +
              this.props.currentInput()
          ) as HTMLElement
        ).focus();
      }
    }
    if (event.key === "s" || event.key === "S") {
      event.preventDefault();
      let foundElement = document.querySelector(
        "#variantlineitem-" +
          this.parentLineItemId +
          "-" +
          (this.currentLineItemId + 1) +
          "-" +
          this.props.currentInput()
      ) as HTMLElement;
      if (foundElement) {
        foundElement.focus();
      } else {
        foundElement = document.querySelector(
          "#variantlineitem-" +
            (this.parentLineItemId + 1) +
            "-0-" +
            this.props.currentInput()
        );
        if (foundElement) {
          foundElement.focus();
        }
      }
    }
    if (event.key === "a" || event.key === "A") {
      event.preventDefault();
      this.determineIfVisibleAndFocusLeft(this.props.currentInput() - 1);
    }
    if (event.key === "d" || event.key === "D") {
      event.preventDefault();
      this.determineIfVisibleAndFocusRight(this.props.currentInput() + 1);
    }
  };

  determineIfVisibleAndFocusRight(inputNumber) {
    if (inputNumber < 13) {
      const foundElement = document.querySelector(
        "#variantlineitem-" +
          this.parentLineItemId +
          "-" +
          this.currentLineItemId +
          "-" +
          inputNumber
      ) as HTMLElement;
      if (foundElement) {
        if (
          foundElement.offsetWidth ||
          foundElement.offsetHeight ||
          foundElement.getClientRects().length
        ) {
          this.props.updateCurrentInput(inputNumber);
          foundElement.focus();
        } else {
          this.determineIfVisibleAndFocusRight(inputNumber + 1);
        }
      }
    }
  }

  determineIfVisibleAndFocusLeft(inputNumber) {
    if (inputNumber > 0) {
      const foundElement = document.querySelector(
        "#variantlineitem-" +
          this.parentLineItemId +
          "-" +
          this.currentLineItemId +
          "-" +
          inputNumber
      ) as HTMLElement;
      if (foundElement) {
        if (
          foundElement.offsetWidth ||
          foundElement.offsetHeight ||
          foundElement.getClientRects().length
        ) {
          this.props.updateCurrentInput(inputNumber);
          foundElement.focus();
        } else {
          this.determineIfVisibleAndFocusLeft(inputNumber - 1);
        }
      }
    }
  }

  parentLineItemId;
  currentLineItemId;

  @observable changeHasApplied = false;
  @action setChangeHasApplied(bool) {
    this.changeHasApplied = bool;
  }

  @observable isUpdating = false;
  @action setIsUpdating(bool) {
    this.isUpdating = bool;
  }

  @observable hasUpdated = false;
  @action setHasUpdated(bool) {
    this.hasUpdated = bool;
  }

  constructor(props) {
    super(props);
    this.state = {
      savedVariant: null,
      variant: {
        buyLimit:
          this.props.variant.maxPurchaseQuantity === null
            ? undefined
            : this.props.variant.maxPurchaseQuantity,
        cashBuyPercentage:
          this.props.variant.cashBuyPercent === null
            ? undefined
            : this.props.variant.cashBuyPercent,
        cashBuyPrice:
          this.props.variant.cashBuyPrice === null
            ? undefined
            : this.props.variant.cashBuyPrice,
        creditBuyPrice:
          this.props.variant.storeCreditBuyPrice === null
            ? undefined
            : this.props.variant.storeCreditBuyPrice,
        creditBuyPercentage:
          this.props.variant.creditBuyPercent === null
            ? undefined
            : this.props.variant.creditBuyPercent,
        id: this.props.variant.id,
        maxInstockBuyPercentage:
          this.props.variant.maxInstockBuyPercentage === null
            ? undefined
            : this.props.variant.maxInstockBuyPercentage,
        maxInstockBuyPrice:
          this.props.variant.maxInstockBuyPrice === null
            ? undefined
            : this.props.variant.maxInstockBuyPrice,
        maxInstockCreditBuyPrice:
          this.props.variant.maxInstockCreditBuyPrice === null
            ? undefined
            : this.props.variant.maxInstockCreditBuyPrice,
        maxInstockCreditBuyPercentage:
          this.props.variant.maxInstockCreditBuyPercentage === null
            ? undefined
            : this.props.variant.maxInstockCreditBuyPercentage,
        priceOverride:
          this.props.variant.priceOverride === null
            ? undefined
            : this.props.variant.priceOverride,
        reserveQuantity:
          this.props.variant.reserveQuantity === null
            ? undefined
            : this.props.variant.reserveQuantity,
        shopifyId: this.props.variant.shopifyId,
        stock: this.props.variant.quantity,
        stockUpdateType: "set",
      },
    };
  }

  componentDidMount() {
    this.props.setUpdateVariant(this.updateVariant.bind(this));
    this.setState({ savedVariant: this.state.variant });
  }

  componentDidUpdate(prevProps) {
    const { cascade } = this.props;
    const { cascade: previousCascade } = prevProps;
    if (
      cascade?.suffix !== previousCascade?.suffix ||
      cascade?.variant !== previousCascade?.variant
    ) {
      this.cascadePrimaryVariant(cascade.suffix, cascade.variant);
    }
  }

  cascadePrimaryVariant = (suffix, primaryVariant) => {
    if (!this.props.variant.title.endsWith(suffix) || this.isPrimaryVariant) {
      return;
    }
    const variantName = this.props.variant.title.replace(suffix, "").trim();
    const variantPricingSetting =
      this.props.store.SettingsStore.storeSettings?.variantTypes.find(
        (variant) => variant.name === variantName
      );
    if (!variantPricingSetting) {
      return;
    }
    const variantPriceMultiplier = variantPricingSetting.multiplier;
    this.setState((prevState) => ({
      variant: {
        ...prevState.variant,
        priceOverride: primaryVariant?.priceOverride
          ? Number(
              roundCents(primaryVariant.priceOverride * variantPriceMultiplier)
            )
          : prevState.variant.priceOverride,
        cashBuyPrice: primaryVariant?.cashBuyPrice
          ? Number(
              roundCents(primaryVariant.cashBuyPrice * variantPriceMultiplier)
            )
          : prevState.variant.cashBuyPrice,
        cashBuyPercentage:
          primaryVariant?.cashBuyPercentage ??
          prevState.variant.cashBuyPercentage,
        creditBuyPrice: primaryVariant?.creditBuyPrice
          ? Number(
              roundCents(primaryVariant.creditBuyPrice * variantPriceMultiplier)
            )
          : prevState.variant.creditBuyPrice,
        creditBuyPercentage:
          primaryVariant?.creditBuyPercentage ??
          prevState.variant.creditBuyPercentage,
        buyLimit: primaryVariant?.buyLimit ?? prevState.variant.buyLimit,
        maxInstockBuyPrice: primaryVariant?.maxInstockBuyPrice
          ? Number(
              roundCents(
                primaryVariant.maxInstockBuyPrice * variantPriceMultiplier
              )
            )
          : prevState.variant.maxInstockBuyPrice,
        maxInstockBuyPercentage:
          primaryVariant?.maxInstockBuyPercentage ??
          prevState.variant.maxInstockBuyPercentage,
        maxInstockCreditBuyPrice: primaryVariant?.maxInstockCreditBuyPrice
          ? Number(
              roundCents(
                primaryVariant.maxInstockCreditBuyPrice * variantPriceMultiplier
              )
            )
          : prevState.variant.maxInstockCreditBuyPrice,
        maxInstockCreditBuyPercentage:
          primaryVariant?.maxInstockCreditBuyPercentage ??
          prevState.variant.maxInstockCreditBuyPercentage,
      },
    }));
  };

  isPrimaryVariant = this.props.variant.title?.startsWith(
    this.props.store.SettingsStore.storeSettings?.variantTypes[0]?.name
  );

  primaryVariantSuffix = this.isPrimaryVariant
    ? this.props.variant.title
        .replace(
          this.props.store.SettingsStore.storeSettings.variantTypes[0].name,
          ""
        )
        .trim()
    : undefined;

  returnNullOrValue(value) {
    return value && value.length > 0 ? parseFloat(value) : null;
  }

  hasVariantChanged = () => {
    if (
      !areSame(this.state.variant.buyLimit, this.state.savedVariant?.buyLimit)
    )
      return true;
    if (
      !areSame(
        this.state.variant.cashBuyPercentage,
        this.state.savedVariant?.cashBuyPercentage
      )
    )
      return true;
    if (
      !areSame(
        this.state.variant.cashBuyPrice,
        this.state.savedVariant?.cashBuyPrice
      )
    )
      return true;
    if (
      !areSame(
        this.state.variant.creditBuyPrice,
        this.state.savedVariant?.creditBuyPrice
      )
    )
      return true;
    if (
      !areSame(
        this.state.variant.creditBuyPercentage,
        this.state.savedVariant?.creditBuyPercentage
      )
    )
      return true;

    if (
      !areSame(
        this.state.variant.maxInstockBuyPercentage,
        this.state.savedVariant?.maxInstockBuyPercentage
      )
    )
      return true;
    if (
      !areSame(
        this.state.variant.maxInstockBuyPrice,
        this.state.savedVariant?.maxInstockBuyPrice
      )
    )
      return true;
    if (
      !areSame(
        this.state.variant.maxInstockCreditBuyPrice,
        this.state.savedVariant?.maxInstockCreditBuyPrice
      )
    )
      return true;
    if (
      !areSame(
        this.state.variant.maxInstockCreditBuyPercentage,
        this.state.savedVariant?.maxInstockCreditBuyPercentage
      )
    )
      return true;
    if (
      !areSame(
        this.state.variant.priceOverride,
        this.state.savedVariant?.priceOverride
      )
    )
      return true;
    if (
      !areSame(
        this.state.variant.reserveQuantity,
        this.state.savedVariant?.reserveQuantity,

        true
      )
    )
      return true;

    if (
      !areSame(this.state.variant.stock, this.state.savedVariant?.stock, true)
    )
      return true;
    return false;
  };

  updateVariant(showNotification = false) {
    if (!this.hasVariantChanged()) {
      if (showNotification) {
        notify.warn("No changes to save");
      }
      return;
    }
    this.setChangeHasApplied(false);
    this.setIsUpdating(true);
    let newStock = 0;
    if (this.state.variant.stock) {
      newStock = this.state.variant.stock;
    }
    const variantToSend = {
      ...this.state.variant,
      stockUpdateType: this.props.getStockUpdateType(),
      stock:
        newStock == this.state.savedVariant?.stock &&
        this.props.getStockUpdateType() == "set"
          ? null
          : newStock,
      reserveQuantity: this.state.variant.reserveQuantity || 0,
    };
    this.props.store.ProductsStore.updateVariant(variantToSend)
      .then((data) => {
        this.setState({ savedVariant: this.state.variant });
        if (showNotification) {
          if (data?.message === "Variant update queued") {
            notify.info(data.message);
          } else {
            notify.warn("Update may not be queued. Please try again");
          }
        }
        this.setIsUpdating(false);
        this.setHasUpdated(true);
        this.setChangeHasApplied(true);
        setTimeout(() => {
          this.setHasUpdated(false);
        }, 2000);
        this.props.onComplete(this.props.variant.id);
      })
      .catch((err) => {
        console.error(err);
        this.setIsUpdating(false);
        this.setHasUpdated(false);
        this.setChangeHasApplied(false);
        this.props.store.MainStore.setError(
          err,
          "Failed to update variant",
          "There was an error updating your selected variant. Please try again"
        );
      });
  }

  onFocus(event, currentInput) {
    event.target.select();
    this.props.updateCurrentInput(currentInput);
  }

  handleVariant(props) {
    const newVariant = { ...this.state.variant, ...props };
    this.props.onChange(newVariant, this.props.variant);
    this.setState({ variant: newVariant });
  }

  render() {
    const fC = this.props.store.MainStore.currencyBuilder;
    this.parentLineItemId = this.props.parentListIndex;
    this.currentLineItemId = this.props.listIndex;
    return (
      <div
        key={this.props.listIndex}
        id={
          "variantline-" + this.parentLineItemId + "-" + this.currentLineItemId
        }
        className="ProductLineVariantItem variantLine"
        data-testid="ProductLineVariantItem"
      >
        <div className="ProductLineVariantItem__variantTitle">
          {this.props.variant.title}
        </div>

        <div className="ProductLineVariantItem__settings">
          <div className="grouping">
            <div
              className={
                "input-group mb-3 " +
                (this.props.columnsToShow.length == 0 ||
                this.props.columnsToShow.includes("stock")
                  ? ""
                  : " hidden")
              }
            >
              <p className="variant-input-header">Stock Qty</p>
              <QuantitySelector
                minusDisabled={this.state.variant.stock === 0}
                onMinusClick={() => {
                  this.handleVariant({
                    stock: this.state.variant.stock - 1,
                  });
                  segmentAnalytics.track(STOCK_QTY_CLICKED);
                }}
                onPlusClick={() => {
                  this.handleVariant({
                    stock: this.state.variant.stock + 1,
                  });
                  segmentAnalytics.track(STOCK_QTY_CLICKED);
                }}
                onChange={(event) =>
                  this.handleVariant({
                    stock: Number(event.target.value),
                  })
                }
                onFocus={(e) => {
                  this.onFocus(e, 1);
                  segmentAnalytics.track(STOCK_QTY_CLICKED);
                }}
                onKeyPress={this.handleKeyPress}
                value={Number(this.state.variant.stock)}
                id={
                  "variantlineitem-" +
                  this.parentLineItemId +
                  "-" +
                  this.currentLineItemId +
                  "-1"
                }
              />
            </div>
            <div
              className={
                "input-group mb-3 " +
                (this.props.columnsToShow.length == 0 ||
                this.props.columnsToShow.includes("reserveStock")
                  ? ""
                  : " hidden")
              }
            >
              <p className="variant-input-header">Reserve Qty</p>
              <QuantitySelector
                minusDisabled={this.state.variant.reserveQuantity === 0}
                onMinusClick={() => {
                  this.handleVariant({
                    reserveQuantity: this.state.variant.reserveQuantity - 1,
                  });
                  segmentAnalytics.track(RESERVE_QTY_CLICKED);
                }}
                onPlusClick={() => {
                  this.handleVariant({
                    reserveQuantity: this.state.variant.reserveQuantity + 1,
                  });
                  segmentAnalytics.track(RESERVE_QTY_CLICKED);
                }}
                onChange={(event) =>
                  this.handleVariant({
                    reserveQuantity: Number(event.target.value),
                  })
                }
                onFocus={(e) => {
                  this.onFocus(e, 2);
                  segmentAnalytics.track(RESERVE_QTY_CLICKED);
                }}
                onKeyPress={this.handleKeyPress}
                value={Number(this.state.variant.reserveQuantity || 0)}
                id={
                  "variantlineitem-" +
                  this.parentLineItemId +
                  "-" +
                  this.currentLineItemId +
                  "-2"
                }
              />
            </div>
          </div>
          <div className="grouping">
            <div
              className={
                "currency-input " +
                (this.props.columnsToShow.length == 0 ||
                this.props.columnsToShow.includes("sellPrice")
                  ? ""
                  : " hidden")
              }
            >
              <p className="variant-input-header">Sell Price</p>
              <div className="text-center price">
                {fC(parseFloat(roundCents(this.props.variant.price)))}
              </div>
            </div>
            <div
              className={
                "currency-input " +
                (this.props.columnsToShow.length == 0 ||
                this.props.columnsToShow.includes("sellPriceOverride")
                  ? ""
                  : " hidden")
              }
              style={{ width: "5em" }}
            >
              <p className="variant-input-header">Sell Override</p>
              <input
                aria-label="Sell override value"
                onChange={(event) =>
                  this.handleVariant({
                    priceOverride: event.target.value || null,
                  })
                }
                onWheel={(e) => e.currentTarget.blur()}
                value={this.state.variant.priceOverride}
                id={
                  "variantlineitem-" +
                  this.parentLineItemId +
                  "-" +
                  this.currentLineItemId +
                  "-3"
                }
                onFocus={(e) => {
                  this.onFocus(e, 3);
                  segmentAnalytics.track(SELL_OVERRIDE_CLICKED);
                }}
                onKeyPress={this.handleKeyPress}
                type="number"
                step="0.01"
                className="form-control"
              />
            </div>
            <div
              className={
                "text-small " +
                (this.props.columnsToShow.length == 0 ||
                this.props.columnsToShow.includes("cashBuyPrice") ||
                this.props.columnsToShow.includes("cashBuyPercent")
                  ? ""
                  : " hidden")
              }
            >
              <p className="variant-input-header">Cash Buy</p>
              <input
                aria-label="Cash buy value"
                onChange={(event) =>
                  this.handleVariant({
                    cashBuyPrice: event.target.value || null,
                  })
                }
                onWheel={(e) => e.currentTarget.blur()}
                value={this.state.variant.cashBuyPrice}
                id={
                  "variantlineitem-" +
                  this.parentLineItemId +
                  "-" +
                  this.currentLineItemId +
                  "-4"
                }
                onFocus={(e) => {
                  this.onFocus(e, 4);
                  segmentAnalytics.track(CASH_BUY_CLICKED);
                }}
                onKeyPress={this.handleKeyPress}
                type="number"
                step="0.01"
                className={
                  "form-control currency-input " +
                  (this.props.columnsToShow.length == 0 ||
                  this.props.columnsToShow.includes("cashBuyPrice")
                    ? this.props.columnsToShow.includes("cashBuyPercent")
                      ? " halfInput"
                      : ""
                    : " hidden")
                }
                placeholder={this.props.store.MainStore.currency}
                title="Cash buy"
              />
              <span
                className={
                  this.props.columnsToShow.length == 0 ||
                  (this.props.columnsToShow.includes("cashBuyPrice") &&
                    this.props.columnsToShow.includes("cashBuyPercent"))
                    ? ""
                    : "hidden"
                }
              >
                {" "}
                or{" "}
              </span>
              <input
                aria-label="Cash buy percentage"
                onChange={(event) =>
                  this.handleVariant({
                    cashBuyPercentage: event.target.value || null,
                  })
                }
                onWheel={(e) => e.currentTarget.blur()}
                value={this.state.variant.cashBuyPercentage}
                id={
                  "variantlineitem-" +
                  this.parentLineItemId +
                  "-" +
                  this.currentLineItemId +
                  "-5"
                }
                onFocus={(e) => {
                  this.onFocus(e, 5);
                  segmentAnalytics.track(CASH_BUY_CLICKED);
                }}
                onKeyPress={this.handleKeyPress}
                type="number"
                step="0.01"
                className={
                  "form-control percentage-input" +
                  (this.props.columnsToShow.length == 0 ||
                  this.props.columnsToShow.includes("cashBuyPercent")
                    ? this.props.columnsToShow.includes("cashBuyPrice")
                      ? " halfInput"
                      : ""
                    : " hidden")
                }
                placeholder="%"
                title="Cash buy percentage"
              />
            </div>
            <div
              className={
                "text-small " +
                (this.props.columnsToShow.length == 0 ||
                this.props.columnsToShow.includes("creditBuyPrice") ||
                this.props.columnsToShow.includes("creditBuyPercent")
                  ? ""
                  : " hidden")
              }
            >
              <p className="variant-input-header">Credit Buy</p>
              <input
                aria-label="Credit buy value"
                onChange={(event) =>
                  this.handleVariant({
                    creditBuyPrice: event.target.value || null,
                  })
                }
                onWheel={(e) => e.currentTarget.blur()}
                value={this.state.variant.creditBuyPrice}
                id={
                  "variantlineitem-" +
                  this.parentLineItemId +
                  "-" +
                  this.currentLineItemId +
                  "-6"
                }
                onFocus={(e) => {
                  this.onFocus(e, 6);
                  segmentAnalytics.track(CREDIT_BUY_CLICKED);
                }}
                onKeyPress={this.handleKeyPress}
                type="number"
                step="0.01"
                className={
                  "form-control currency-input " +
                  (this.props.columnsToShow.length == 0 ||
                  this.props.columnsToShow.includes("creditBuyPrice")
                    ? this.props.columnsToShow.includes("creditBuyPercent")
                      ? " halfInput"
                      : ""
                    : " hidden")
                }
                placeholder={this.props.store.MainStore.currency}
                title="Credit buy"
              />
              <span
                className={
                  this.props.columnsToShow.length == 0 ||
                  (this.props.columnsToShow.includes("creditBuyPrice") &&
                    this.props.columnsToShow.includes("creditBuyPercent"))
                    ? ""
                    : "hidden"
                }
              >
                {" "}
                or{" "}
              </span>
              <input
                aria-label="Credit buy percentage"
                onChange={(event) =>
                  this.handleVariant({
                    creditBuyPercentage: event.target.value || null,
                  })
                }
                onWheel={(e) => e.currentTarget.blur()}
                value={this.state.variant.creditBuyPercentage}
                id={
                  "variantlineitem-" +
                  this.parentLineItemId +
                  "-" +
                  this.currentLineItemId +
                  "-7"
                }
                onFocus={(e) => {
                  this.onFocus(e, 7);
                  segmentAnalytics.track(CREDIT_BUY_CLICKED);
                }}
                onKeyPress={this.handleKeyPress}
                type="number"
                step="0.01"
                className={
                  "form-control  percentage-input " +
                  (this.props.columnsToShow.length == 0 ||
                  this.props.columnsToShow.includes("creditBuyPercent")
                    ? this.props.columnsToShow.includes("creditBuyPrice")
                      ? " halfInput"
                      : ""
                    : " hidden")
                }
                placeholder="%"
                title="Credit buy percentage"
              />
            </div>
          </div>
          <div className="grouping">
            <div
              className={
                "quantity-input " +
                (this.props.columnsToShow.length == 0 ||
                this.props.columnsToShow.includes("buyLimit")
                  ? ""
                  : " hidden")
              }
            >
              <p className="variant-input-header">Buy Limit</p>
              <input
                aria-label="Buy limit"
                data-testid="buyLimit"
                onChange={(event) =>
                  this.handleVariant({
                    buyLimit: event.target.value || null,
                  })
                }
                onWheel={(e) => e.currentTarget.blur()}
                value={this.state.variant.buyLimit}
                id={
                  "variantlineitem-" +
                  this.parentLineItemId +
                  "-" +
                  this.currentLineItemId +
                  "-8"
                }
                onFocus={(e) => {
                  this.onFocus(e, 8);
                  segmentAnalytics.track(BUY_LIMIT_CLICKED);
                }}
                onKeyPress={this.handleKeyPress}
                type="number"
                step="1"
                className="form-control"
                placeholder="∞"
              />
            </div>
            <div
              className={
                "text-small " +
                (this.props.columnsToShow.length == 0 ||
                this.props.columnsToShow.includes("overstockCashBuyPrice") ||
                this.props.columnsToShow.includes("overstockCashBuyPercent")
                  ? ""
                  : " hidden")
              }
            >
              <p className="variant-input-header">Over-limit Cash Buy</p>
              <input
                aria-label="Over-limit cash buy value"
                onChange={(event) =>
                  this.handleVariant({
                    maxInstockBuyPrice: event.target.value || null,
                  })
                }
                onWheel={(e) => e.currentTarget.blur()}
                value={this.state.variant.maxInstockBuyPrice}
                id={
                  "variantlineitem-" +
                  this.parentLineItemId +
                  "-" +
                  this.currentLineItemId +
                  "-9"
                }
                onFocus={(e) => {
                  this.onFocus(e, 9);
                  segmentAnalytics.track(OVER_LIMIT_CASH_BUY_CLICKED);
                }}
                onKeyPress={this.handleKeyPress}
                type="number"
                step="0.01"
                className={
                  "form-control currency-input " +
                  (this.props.columnsToShow.length == 0 ||
                  this.props.columnsToShow.includes("overstockCashBuyPrice")
                    ? this.props.columnsToShow.includes(
                        "overstockCashBuyPercent"
                      )
                      ? " halfInput"
                      : ""
                    : " hidden")
                }
                placeholder={this.props.store.MainStore.currency}
              />
              <span
                className={
                  this.props.columnsToShow.length == 0 ||
                  (this.props.columnsToShow.includes("overstockCashBuyPrice") &&
                    this.props.columnsToShow.includes(
                      "overstockCashBuyPercent"
                    ))
                    ? ""
                    : "hidden"
                }
              >
                {" "}
                or{" "}
              </span>
              <input
                aria-label="Over-limit cash buy percentage"
                onChange={(event) =>
                  this.handleVariant({
                    maxInstockBuyPercentage: event.target.value || null,
                  })
                }
                onWheel={(e) => e.currentTarget.blur()}
                value={this.state.variant.maxInstockBuyPercentage}
                id={
                  "variantlineitem-" +
                  this.parentLineItemId +
                  "-" +
                  this.currentLineItemId +
                  "-10"
                }
                onFocus={(e) => {
                  this.onFocus(e, 10);
                  segmentAnalytics.track(OVER_LIMIT_CASH_BUY_CLICKED);
                }}
                onKeyPress={this.handleKeyPress}
                type="number"
                step="0.01"
                className={
                  "form-control percentage-input " +
                  (this.props.columnsToShow.length == 0 ||
                  this.props.columnsToShow.includes("overstockCashBuyPercent")
                    ? this.props.columnsToShow.includes("overstockCashBuyPrice")
                      ? " halfInput"
                      : ""
                    : " hidden")
                }
                placeholder="%"
              />
            </div>
            <div
              className={
                "text-small " +
                (this.props.columnsToShow.length == 0 ||
                this.props.columnsToShow.includes("overstockCreditBuyPrice") ||
                this.props.columnsToShow.includes("overstockCreditBuyPercent")
                  ? ""
                  : " hidden")
              }
            >
              <p className="variant-input-header">Over-limit Credit Buy</p>
              <input
                aria-label="Over-limit credit buy value"
                onChange={(event) =>
                  this.handleVariant({
                    maxInstockCreditBuyPrice: event.target.value || null,
                  })
                }
                onWheel={(e) => e.currentTarget.blur()}
                value={this.state.variant.maxInstockCreditBuyPrice}
                id={
                  "variantlineitem-" +
                  this.parentLineItemId +
                  "-" +
                  this.currentLineItemId +
                  "-11"
                }
                onFocus={(e) => {
                  this.onFocus(e, 11);
                  segmentAnalytics.track(OVER_LIMIT_CREDIT_BUY_CLICKED);
                }}
                onKeyPress={this.handleKeyPress}
                type="number"
                step="0.01"
                className={
                  "form-control currency-input " +
                  (this.props.columnsToShow.length == 0 ||
                  this.props.columnsToShow.includes("overstockCreditBuyPrice")
                    ? this.props.columnsToShow.includes(
                        "overstockCreditBuyPercent"
                      )
                      ? " halfInput"
                      : ""
                    : " hidden")
                }
                placeholder={this.props.store.MainStore.currency}
              />
              <span
                className={
                  this.props.columnsToShow.length == 0 ||
                  (this.props.columnsToShow.includes(
                    "overstockCreditBuyPrice"
                  ) &&
                    this.props.columnsToShow.includes(
                      "overstockCreditBuyPercent"
                    ))
                    ? ""
                    : "hidden"
                }
              >
                {" "}
                or{" "}
              </span>
              <input
                aria-label="Over-limit credit buy percentage"
                onChange={(event) =>
                  this.handleVariant({
                    maxInstockCreditBuyPercentage: event.target.value || null,
                  })
                }
                onWheel={(e) => e.currentTarget.blur()}
                value={this.state.variant.maxInstockCreditBuyPercentage}
                id={
                  "variantlineitem-" +
                  this.parentLineItemId +
                  "-" +
                  this.currentLineItemId +
                  "-12"
                }
                onFocus={(e) => {
                  this.onFocus(e, 12);
                  segmentAnalytics.track(OVER_LIMIT_CREDIT_BUY_CLICKED);
                }}
                onKeyPress={this.handleKeyPress}
                type="number"
                step="0.01"
                className={
                  "form-control percentage-input " +
                  (this.props.columnsToShow.length == 0 ||
                  this.props.columnsToShow.includes("overstockCreditBuyPercent")
                    ? this.props.columnsToShow.includes(
                        "overstockCreditBuyPrice"
                      )
                      ? " halfInput"
                      : ""
                    : " hidden")
                }
                placeholder="%"
              />
            </div>
          </div>
        </div>
        <div className="ProductLineVariantItem__actions">
          {this.isPrimaryVariant ? (
            <div>
              <ButtonComponent
                warning
                onClick={() => {
                  this.props.setCascade({
                    suffix: this.primaryVariantSuffix,
                    variant: this.state.variant,
                  });
                  segmentAnalytics.track(CASCADE_CHANGES_CLICKED);
                }}
              >
                Cascade
              </ButtonComponent>
              <Tooltip>
                <br />
                Takes the settings for this variant and applies them to all
                subsequent variants according to your store's price multiplier
                settings.
              </Tooltip>
            </div>
          ) : null}
          <div
            className={
              this.props.columnsToShow.length == 0 ||
              this.props.columnsToShow.includes("updateButton")
                ? ""
                : " hidden"
            }
          >
            <ButtonComponent
              primary={!this.isUpdating}
              disabled={this.hasUpdated}
              onClick={() => {
                this.updateVariant(true);
                segmentAnalytics.track(UPDATE_PRODUCT_CLICKED);
              }}
            >
              {!this.hasUpdated ? "Update" : "Queued!"}
            </ButtonComponent>
          </div>
        </div>
      </div>
    );
  }
}
