import React from "react";
import { joinClasses } from "../../utils/styling";
import "./InfoPanel.scss";

interface InfoPanelProps {
  title?: string;
  className?: string;
  children: React.ReactNode;
}

function InfoPanel(props: InfoPanelProps) {
  const { title, className, children } = props;

  if (title) {
    return (
      <div
        className={joinClasses([
          "InfoPanel",
          "InfoPanel--withTitle",
          className,
        ])}
      >
        <div className="InfoPanel__title">
          <i className="far fa-info-circle" />
          {title}
        </div>
        {children}
      </div>
    );
  }

  return (
    <div className={joinClasses(["InfoPanel", className])}>
      <i className="far fa-info-circle" />
      <div>{children}</div>
    </div>
  );
}

export default InfoPanel;
