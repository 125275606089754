import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import "./CustomerNote.scss";

const CHARS_TO_DISPLAY = 30;

export type Customer = {
  id: number;
  email: string;
  firstName: string | null;
  lastName: string | null;
  phone: string | null;
  notes: string | null;
  storeCredit: number;
};

interface CustomerNoteProps {
  posStore?: {
    setCustomerNoteModalVisible: (visible: boolean) => void;
    setCustomerToEdit: (customer: Customer) => void;
  };
  customer: Customer;
}

@inject("posStore")
@observer
class CustomerNote extends Component<CustomerNoteProps> {
  showCustomerEditModal = () => {
    this.props.posStore.setCustomerNoteModalVisible(true);
    this.props.posStore.setCustomerToEdit(this.props.customer);
  };

  formatNote = (notes: string) => {
    if (!notes) {
      return "None";
    }
    if (notes.length <= CHARS_TO_DISPLAY) {
      return notes;
    }
    return notes.substring(0, CHARS_TO_DISPLAY) + "...";
  };

  render() {
    const { notes } = this.props.customer;

    return (
      <div
        className="CustomerNote"
        onClick={() => this.showCustomerEditModal()}
        role="button"
      >
        Customer Notes: {this.formatNote(notes)}
      </div>
    );
  }
}

export default CustomerNote;
