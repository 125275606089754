import IconButton from "../../components/generic/IconButton";
import {
  EventAdditionalInfo,
  EventParticipant,
  EventParticipantAdditionalInfo,
} from "../../types/events";
import { Column } from "../../types/table";

interface ColumnWithAdditionInfo extends Column {
  additionalInfoId?: string;
}

export const getOnlineParticipantsColumns = (
  eventAdditionalInfo: EventAdditionalInfo[]
): Column[] => [
  {
    header: "Name",
    accessorKey: "participantName",
  },
  {
    header: "Email",
    accessorKey: "participantEmail",
  },
  ...eventAdditionalInfo.map((additionalInfo) => ({
    header: additionalInfo.header,
    accessorKey: "additionalInfo",
    additionalInfoId: additionalInfo.id,
    cell: (
      additionalInfo: EventParticipantAdditionalInfo[],
      column: ColumnWithAdditionInfo
    ) =>
      additionalInfo.find((info) => info.id === column.additionalInfoId)?.info
        ?.value,
  })),
  {
    header: "",
    accessorKey: "id",
    cell: (id, _column, _row, _index, { eventId }) => (
      <>
        <IconButton
          icon="fal fa-user-edit"
          isLink
          to={`/events/participants/${eventId}/update/${id}`}
          title="Edit participant"
          id={`update/${id}`}
        />
        <IconButton
          icon="fal fa-ban"
          danger
          isLink
          to={`/events/participants/${eventId}/remove/${id}`}
          title="Remove participant"
          id={`remove/${id}`}
        />
      </>
    ),
  },
];

export const getPoSParticipantsColumns = (
  eventAdditionalInfo: EventAdditionalInfo[]
): Column[] => [
  {
    header: "Name",
    accessorKey: "customer",
    cell: ({ participantName }) => participantName ?? "Guest",
  },
  {
    header: "Email",
    accessorKey: "customer",
    cell: ({ participantEmail }) => participantEmail ?? "Guest",
  },
  ...eventAdditionalInfo.map((additionalInfo) => ({
    header: additionalInfo.header,
    accessorKey: "customer",
    additionalInfoId: additionalInfo.id,
    cell: (customer: EventParticipant, column: ColumnWithAdditionInfo) => {
      const columnAdditionalInfo = customer.additionalInfo.find(
        (info) => info.id === column.additionalInfoId
      );
      return columnAdditionalInfo && "info" in columnAdditionalInfo
        ? columnAdditionalInfo.info.value
        : null;
    },
  })),
  {
    header: "Order number",
    accessorKey: "cartId",
  },
];
