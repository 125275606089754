import { useQuery } from "react-query";
import { fetchShopifySubscriptionStatus } from "../api/rest/subscriptions";

export const useFetchShopifySubscriptionStatus = (customerId: string) =>
  useQuery(
    ["shopifySubscriptionInvalid", customerId],
    () => fetchShopifySubscriptionStatus(),
    {
      retry: false,
    }
  );
