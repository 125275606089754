import React from "react";
import { isKioskMode } from "../../helpers/posModeHelpers";
import { useFetchSetting } from "../../hooks/settingHooks";
import ToggleSwitch from "../generic/ToggleSwitch";
import "./SellBuyToggle.scss";
import { segmentAnalytics } from "../services/Analytics";
import { BUY_SELL_TOGGLED } from "../../constants/eventsTracked";

interface SellBuyToggleProps {
  buyMode: boolean;
  toggleBuyMode: () => void;
}

function SellBuyToggle(props: SellBuyToggleProps) {
  const { buyMode, toggleBuyMode } = props;
  const { data: kioskBuyModeEnabled } = useFetchSetting("kioskBuyModeEnabled");
  const { data: kioskBuyModeLabel } = useFetchSetting("kioskBuyModeLabel");
  const { data: kioskSellModeLabel } = useFetchSetting("kioskSellModeLabel");

  if (!isKioskMode()) {
    return (
      <div className="SellBuyToggle">
        <ToggleSwitch
          currentValue={buyMode}
          onChange={() => {
            segmentAnalytics.track(BUY_SELL_TOGGLED, {
              // buyMode here is before change
              cart_mode: buyMode ? "sell" : "buy",
            });
            toggleBuyMode();
          }}
          label="Buy or sell toggle"
          trueLabel="Buy"
          falseLabel="Sell"
        />
      </div>
    );
  }

  if (kioskBuyModeEnabled === "false") {
    return null;
  }

  return (
    <div className="SellBuyToggle">
      <ToggleSwitch
        currentValue={buyMode}
        onChange={() => {
          segmentAnalytics.track(BUY_SELL_TOGGLED, {
            // buyMode here is before change
            cart_mode: buyMode ? "sell" : "buy",
          });
          toggleBuyMode();
        }}
        label={`${kioskBuyModeLabel || "Buy"} or ${
          kioskSellModeLabel || "Sell"
        } toggle`}
        trueLabel={kioskBuyModeLabel || "Buy"}
        falseLabel={kioskSellModeLabel || "Sell"}
      />
    </div>
  );
}

export default SellBuyToggle;
