import { RootMenuItem, MenuLink } from "../types/nav";

export const showMenuItem = (
  userSettings: ScreenSettings,
  menuItemSetting: undefined | null | string
) => {
  // Icons that can be shown to any user
  if (menuItemSetting === null) {
    return true;
  }
  // User needs settings, but no specific setting
  if (menuItemSetting === undefined && userSettings) {
    return true;
  }
  return userSettings[menuItemSetting];
};

export const filterMenuItems = <T extends RootMenuItem | MenuLink>(
  items: T[],
  activeFeatureFlags: string[],
  screenSettings: ScreenSettings
): T[] =>
  items.filter(
    (menuItem) =>
      (!menuItem.featureFlag ||
        activeFeatureFlags.includes(menuItem.featureFlag)) &&
      (!("screenSetting" in menuItem) ||
        !menuItem.screenSetting ||
        screenSettings?.[menuItem.screenSetting])
  );
