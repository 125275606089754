import React, { Component } from "react";
import { action } from "mobx";
import { inject, observer } from "mobx-react";
import Modal from "../generic/Modal";
import { ItemList, LineItem } from "../../pos/ItemStore";
import GridItem from "./GridItem";
import "./BarcodeMultipleProducts.scss";

interface BarcodeMultipleProductsProps {
  posStore?: ItemList;
}

@inject("posStore")
@observer
class BarcodeMultipleProducts extends Component<BarcodeMultipleProductsProps> {
  handleAddItem = (item) => {
    this.addItem(item);
    this.props.posStore.setSearchTerm("");
    this.props.posStore.setShowMultipleMatchingBarcodesError(false);
  };

  @action
  addItem = (item) => {
    this.props.posStore.addAvailableQuantity(
      item.variants[item.selectedVariant]
    );
    if (this.props.posStore.buyMode && item.selectedBuyVariant > -1) {
      this.props.posStore.addCartItemBuyLimit(
        item.variants[item.selectedBuyVariant]
      );
      item.variants[item.selectedBuyVariant].quantity++;
    }
    if (!this.props.posStore.buyMode && item.selectedVariant > -1) {
      item.variants[item.selectedVariant].quantity--;
    }
    this.props.posStore.addToCart(
      new LineItem(
        item,
        this.props.posStore.buyMode,
        this.props.posStore.cashPrice,
        this.props.posStore.allTax
      )
    );
    this.props.posStore.setAdditionalInfoItem(null);
  };

  render() {
    const barcode = this.props.posStore.searchTerm;
    const products = (
      this.props.posStore.showMultipleMatchingBarcodesError || []
    ).map((product) => {
      const variantIndex = product.variants.findIndex(
        (variant) => variant.barcode == barcode || variant.sku == barcode
      );
      return {
        ...product,
        selectedVariant: variantIndex,
        selectedBuyVariant: variantIndex,
      };
    });

    return (
      <Modal
        onClose={() =>
          this.props.posStore.setShowMultipleMatchingBarcodesError(false)
        }
      >
        <Modal.Header>
          Multiple matching products found for barcode: {barcode}
        </Modal.Header>
        <Modal.Content>
          <div className="BarcodeMultipleProducts__products">
            {products.map((product) => (
              <GridItem
                item={product}
                key={product.id}
                addItem={this.handleAddItem}
              />
            ))}
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

export default BarcodeMultipleProducts;
