import React from "react";
import ButtonComponent from "./ButtonComponent";
import "./DateSelect.scss";

interface DateSelectProps {
  filterValues?: Record<string, any>;
  updateFilterValue: Function;
  title: string;
}

function DateSelect({
  filterValues = {},
  updateFilterValue,
  title,
}: DateSelectProps) {
  const filterStartDateName = `${title.toLowerCase()}StartDate`;
  const filterEndDateName = `${title.toLowerCase()}EndDate`;

  const clearAll = () => {
    updateFilterValue({
      [filterStartDateName]: "",
      [filterEndDateName]: "",
    });
  };

  return (
    <form className="DateSelect">
      <span className="DateSelect__title">{`${title}`}</span>
      <div className="DateSelect__input-group">
        <label
          htmlFor={filterStartDateName}
          className="DateSelect__label DateSelect__label--from"
        >
          From
        </label>
        <input
          id={filterStartDateName}
          className="DateSelect__start-date"
          type="date"
          defaultValue={filterValues[filterStartDateName] || ""}
          onChange={(event) =>
            updateFilterValue({
              [filterStartDateName]: event.currentTarget.value,
            })
          }
        />
      </div>
      <div className="DateSelect__input-group">
        <label htmlFor={filterEndDateName} className="DateSelect__label">
          To
        </label>
        <input
          id={filterEndDateName}
          className="DateSelect__end-date"
          type="date"
          defaultValue={filterValues[filterEndDateName] || ""}
          onChange={(event) =>
            updateFilterValue({
              [filterEndDateName]: event.currentTarget.value,
            })
          }
        />
      </div>
      <ButtonComponent compact type="reset" onClick={clearAll}>
        Clear
      </ButtonComponent>
    </form>
  );
}

export default DateSelect;
