import React from "react";
import { NavLink } from "react-router-dom";
import { showMenuItem } from "../../helpers/navHelpers";
import { useLegacyMainMenu } from "../../hooks/menuHooks";
import { MenuStructure } from "../../types/nav";
import "./DesktopMenu.scss";
import { segmentAnalytics } from "../services/Analytics";
import { MAIN_MENU_CLICKED } from "../../constants/eventsTracked";

interface DesktopMenuProps {
  screenSettings: ScreenSettings;
  updateMenu: (menu: MenuStructure | undefined | null) => void;
}

function DesktopMenu(props: DesktopMenuProps) {
  const { screenSettings, updateMenu } = props;
  const menuItems = useLegacyMainMenu();

  return (
    <div className="DesktopMenu">
      {menuItems.map((menuItem) => {
        if (!showMenuItem(screenSettings, menuItem.screenSetting)) {
          return null;
        }
        return (
          <NavLink
            key={menuItem.target}
            className={menuItem.classNameOverride ?? "DesktopMenu__link"}
            activeClassName="active"
            to={menuItem.target}
            onClick={() => {
              segmentAnalytics.track(MAIN_MENU_CLICKED, {
                menu_title: menuItem.title,
              });
              updateMenu(menuItem.menuStructure);
            }}
          >
            <div title={menuItem.title}>
              <i className={menuItem.iconClass} />
            </div>
          </NavLink>
        );
      })}
    </div>
  );
}

export default DesktopMenu;
