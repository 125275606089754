import { _fetch } from "../../api";
import { BASE_URL } from "../../constants/api";
import { DEFAULT_BUYLISTS_PER_PAGE } from "../../constants/buylist";
import moment from "moment";

export async function fetchBuylists(buylistType, params = {}) {
  // Set some defaults for pagination
  if (!params.limit) {
    params.limit = DEFAULT_BUYLISTS_PER_PAGE;
  }
  if (!params.offset) {
    params.offset = 0;
  }
  // Increment end date
  if (params.endDate) {
    params.endDate = moment(params.endDate)
      .add(1, "day")
      .format(moment.HTML5_FMT.DATE);
  }
  const queryString = Object.entries(params)
    .map(
      ([param, value]) =>
        `${encodeURIComponent(param)}=${encodeURIComponent(value)}`
    )
    .join("&");
  return await _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/buylist/${buylistType}?${queryString}`,
  });
}

export async function fetchBuylistDetails(buylistDetailsId) {
  return await _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/buylist/byId/${buylistDetailsId}/details`,
  });
}

export async function fetchFailedToSyncProducts(offset, limit) {
  return await _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/buylist/failedProducts?offset=${offset}&limit=${limit}`,
  });
}

export async function processFailedProduct(buylistItemToProcess) {
  return await _fetch({
    method: "POST",
    endpoint: `${BASE_URL}/buylist/pushFailedProduct`,
    payload: buylistItemToProcess,
  });
}

export async function removeFailedProduct(buylistItemToProcess) {
  return await _fetch({
    method: "DELETE",
    endpoint: `${BASE_URL}/buylist/removeFailedProduct/byId/${buylistItemToProcess.id}`,
  });
}

export async function fetchBuylistRules() {
  return await _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/buylist/rule/list`,
  });
}

export async function doDeclineBuylist(buylist) {
  return await _fetch({
    method: "POST",
    endpoint: `${BASE_URL}/buylist/decline`,
    payload: buylist,
  });
}

export async function doApproveBuylist(buylist) {
  return await _fetch({
    method: "POST",
    endpoint: `${BASE_URL}/buylist/approve`,
    payload: buylist,
  });
}

export async function doUncompleteBuylist(id) {
  return await _fetch({
    method: "POST",
    endpoint: `${BASE_URL}/buylist/uncomplete`,
    payload: { id: id },
  });
}

export async function doCompleteBuylist(buylist) {
  return await _fetch({
    method: "POST",
    endpoint: `${BASE_URL}/buylist/complete`,
    payload: buylist,
  });
}

export async function saveBuylist(buylist) {
  return await _fetch({
    method: "POST",
    endpoint: `${BASE_URL}/buylist/save`,
    payload: buylist,
  });
}

export async function fetchBuylistRule(id) {
  return await _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/buylist/rule/byId/${id}`,
  });
}

export async function addBuylistRule(buylistRule) {
  return await _fetch({
    method: "POST",
    endpoint: `${BASE_URL}/buylist/rule/add`,
    payload: buylistRule,
  });
}

export async function updateBuylistRule(buylistRule) {
  return await _fetch({
    method: "POST",
    endpoint: `${BASE_URL}/buylist/rule/update`,
    payload: buylistRule,
  });
}

export async function doDeleteBuylistRule(buylistRuleId) {
  return await _fetch({
    method: "DELETE",
    endpoint: `${BASE_URL}/buylist/rule/byId/${buylistRuleId}`,
  });
}

export async function doDeleteBuylistRules(buylistObj) {
  return await _fetch({
    method: "POST",
    endpoint: `${BASE_URL}/buylist/rules/delete`,
    payload: buylistObj,
  });
}

export async function fetchCardBuylistDetails(game, setName, keyword) {
  return await _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/buylist/cards`,
    query: {
      game,
      setName,
      keyword,
    },
  });
}

export async function fetchCardPrices(gamesAndCards = []) {
  return await _fetch({
    method: "POST",
    endpoint: `${BASE_URL}/buylists/cards/allPrices`,
    payload: gamesAndCards,
  });
}

export async function doPushStock(buylistId) {
  return await _fetch({
    method: "POST",
    endpoint: `${BASE_URL}/buylist/${buylistId}/pushStock`,
  });
}

export async function doPushStoreCredit(buylistId) {
  return await _fetch({
    method: "POST",
    endpoint: `${BASE_URL}/buylist/${buylistId}/pushStoreCredit`,
  });
}
