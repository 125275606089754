import { Field, Form, Formik, ErrorMessage } from "formik";
import React from "react";
import { AdvancedSearchParameters, Game } from "../../api/rest/integrations";
import ButtonComponent from "../../components/generic/ButtonComponent";
import SelectComponent from "../../components/generic/SelectComponent";
import Spinner from "../../components/generic/Spinner";
import { excludeProducts } from "../../constants/integtations";
import { useFeatureFlag } from "../../hooks/globalSettingsHooks";
import { EBAY_CONDITION_MAPPING } from "../../constants/featureFlags";

export const initialParameters = {
  game: null,
  productTypes: [],
  vendors: [],
  title: "",
  variants: [],
  tags: [],
  barcode: "",
  sku: "",
  priceGreaterThan: "",
  priceLessThan: "",
  overallQuantityGreaterThan: "",
  overallQuantityLessThan: "",
  quantityGreaterThan: "",
  quantityLessthan: "",
  rarities: [],
  setNames: [],
  types: [],
  colors: [],
  monsterTypes: [],
  priceOverrideType: "",
} as AdvancedSearchParameters;

const priceOverrideTypes = [
  {
    id: "",
    value: "Include both",
  },
  {
    id: "manual",
    value: "Manual override",
  },
  {
    id: "automatic",
    value: "Automatic price update",
  },
];

type Item = string | { id: string; value: string };

const buildOptionsList = (listToConvert: Item[], excludeDamaged?: boolean) => {
  if (!listToConvert) {
    return;
  }
  const options = [] as Array<Record<string, string>>;
  listToConvert.forEach((item) => {
    if (typeof item === "string") {
      if (excludeDamaged && item.toLowerCase().includes("damaged")) {
        return;
      }
      if (item && item.length > 0) {
        options.push({ value: item, label: item });
      }
    } else {
      if (excludeDamaged && item?.value.toLowerCase().includes("damaged")) {
        return;
      }
      if (item?.value && item.value.length > 0) {
        options.push({ value: item.id, label: item.value });
      }
    }
  });
  return options;
};

const formatGameOptions = (games: Game[], catalogOnly = false) => {
  const finalList = [];
  if (!catalogOnly) {
    finalList.push({ value: null, label: "Any Game" });
  } else {
    finalList.push({ value: null, label: "Select Game", disabled: true });
  }
  return [
    ...finalList,
    ...games?.map((game) => ({ value: game.gameId, label: game.gameName })),
  ];
};

const filterProductList = (products: string[]) =>
  products.filter(
    (product: string) => !excludeProducts.includes(product.toLocaleLowerCase())
  );

const validate = (values: AdvancedSearchParameters, catalogOnly = false) => {
  const errors = {} as Record<string, string>;
  if (catalogOnly && !values.game) {
    errors.game = "Please select a game";
  }
  return errors;
};

interface AdvancedSearchParametersFormProps {
  isLoading: boolean;
  games: Game[];
  setNames: string[];
  rarities: string[];
  types: string[];
  colors: string[];
  monsterTypes: string[];
  variants: string[];
  productTypes: string[];
  tags: string[];
  vendors: string[];
  searchParams: any;
  updateSearchParameters: Function;
  handleGameChange: Function;
  catalogOnly?: boolean;
}

function AdvancedSearchParametersForm(
  props: AdvancedSearchParametersFormProps
) {
  const {
    isLoading,
    games,
    setNames,
    rarities,
    types,
    colors,
    monsterTypes,
    variants,
    productTypes,
    tags,
    vendors,
    searchParams,
    updateSearchParameters,
    handleGameChange,
    catalogOnly = false,
  } = props;
  const autoConditionMappingEnabled = useFeatureFlag(EBAY_CONDITION_MAPPING);

  return (
    <Formik
      initialValues={searchParams ?? initialParameters}
      validate={(values) => validate(values, catalogOnly)}
      onSubmit={(values, { setSubmitting }) =>
        updateSearchParameters(values, setSubmitting)
      }
    >
      {({ isSubmitting, errors }) => (
        <Form className="filters">
          <div className="topcontent">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 col-xl-4 align-col-bottom">
                  <label className="label" id="gameLabel">
                    Select Game:
                  </label>
                  <div
                    className={
                      errors.game
                        ? "select2 integration-game-select-error"
                        : "select2"
                    }
                  >
                    <Field
                      aria-labelledby="gameLabel"
                      name="game"
                      component={SelectComponent}
                      options={formatGameOptions(games, catalogOnly)}
                      defaultValue={formatGameOptions(games, catalogOnly)[0]}
                      placeholder={
                        <span>
                          <i className="far fa-search" /> Select game
                        </span>
                      }
                      onChangeValue={handleGameChange}
                    />
                    <ErrorMessage
                      name="game"
                      className="integration-game-error"
                      component="span"
                    />
                  </div>
                </div>
                {!catalogOnly ? (
                  <div className="col-md-12 col-xl-4 align-col-bottom">
                    <label className="label" id="productTypesLabel">
                      Product type:
                    </label>
                    <div className="select2">
                      <Field
                        aria-labelledby="productTypesLabel"
                        name="productTypes"
                        component={SelectComponent}
                        placeholder={
                          <span>
                            <i className="far fa-search" /> Select product types
                          </span>
                        }
                        isMulti
                        options={buildOptionsList(
                          filterProductList(productTypes)
                        )}
                      />
                    </div>
                  </div>
                ) : null}
                {!catalogOnly ? (
                  <div className="col-md-12 col-xl-4 align-col-bottom">
                    <label className="label" id="vendorsLabel">
                      Vendor:
                    </label>
                    <div className="select2">
                      <Field
                        aria-labelledby="vendorsLabel"
                        name="vendors"
                        component={SelectComponent}
                        placeholder={
                          <span>
                            <i className="far fa-search" /> Select vendors
                          </span>
                        }
                        closeMenuOnSelect={false}
                        isMulti
                        options={buildOptionsList(vendors)}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <Spinner isLoading={isLoading}>
            <div className="filtersWrapper">
              <div className="container-fluid">
                <div className="row">
                  <div
                    className={
                      variants?.length > 0
                        ? "col-sm-6 col-xl-5col align-col-bottom"
                        : "col-sm-6 col-xl-3 align-col-bottom"
                    }
                  >
                    <label htmlFor="title" className="label">
                      Product title:
                    </label>
                    <Field
                      id="title"
                      name="title"
                      className="input"
                      type="text"
                    />
                  </div>
                  {variants?.length > 0 ? (
                    <div className="col-sm-6 col-xl-5 align-col-bottom">
                      <label id="variantsLabel" className="label">
                        Variants:
                      </label>
                      <div className="select2">
                        <Field
                          aria-labelledby="variantsLabel"
                          name="variants"
                          component={SelectComponent}
                          placeholder={"Select variants"}
                          isMulti
                          options={buildOptionsList(
                            variants,
                            autoConditionMappingEnabled
                          )}
                        />
                      </div>
                    </div>
                  ) : null}
                  {tags && tags.length > 0 ? (
                    <div
                      className={
                        variants?.length > 0
                          ? "col-sm-6 col-xl-5 align-col-bottom"
                          : "col-sm-6 col-xl-3 align-col-bottom"
                      }
                    >
                      <label id="tagsLabel" className="label">
                        Tagged with:
                      </label>
                      <Field
                        aria-labelledby="tagsLabel"
                        name="tags"
                        component={SelectComponent}
                        placeholder={"Select tags"}
                        isMulti
                        options={buildOptionsList(tags)}
                      />
                    </div>
                  ) : null}
                  <div className="col-sm-6 col-xl-3 align-col-bottom">
                    <label htmlFor="barcode" className="label">
                      Product barcode:
                    </label>
                    <Field
                      id="barcode"
                      name="barcode"
                      className="input"
                      type="text"
                    />
                  </div>
                  <div
                    className={
                      variants?.length > 0
                        ? "col-sm-6 col-xl-5 align-col-bottom"
                        : "col-sm-6 col-xl-3 align-col-bottom"
                    }
                  >
                    <label htmlFor="sku" className="label">
                      Product sku:
                    </label>
                    <Field id="sku" name="sku" className="input" type="text" />
                  </div>
                </div>
              </div>
            </div>
          </Spinner>
          <div className="filtersWrapper2">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-4 col-xl-2 align-col-bottom">
                  <label htmlFor="priceGreaterThan" className="label">
                    Price greater than:
                  </label>
                  <Field
                    id="priceGreaterThan"
                    name="priceGreaterThan"
                    className="input"
                    type="number"
                    step="0.01"
                    min="0"
                  />
                </div>
                <div className="col-sm-4 col-xl-2 align-col-bottom">
                  <label htmlFor="priceLessThan" className="label">
                    Price less than:
                  </label>
                  <Field
                    id="priceLessThan"
                    name="priceLessThan"
                    className="input"
                    type="number"
                    step="0.01"
                    min="0"
                  />
                </div>
                <div className="col-sm-4 col-xl-2 align-col-bottom">
                  <label htmlFor="overallQuantityGreaterThan" className="label">
                    Overall qty greater than:
                  </label>
                  <Field
                    id="overallQuantityGreaterThan"
                    name="overallQuantityGreaterThan"
                    className="input"
                    type="number"
                  />
                </div>
                <div className="col-sm-4 col-xl-2 align-col-bottom">
                  <label htmlFor="overallQuantityLessThan" className="label">
                    Overall qty less than:
                  </label>
                  <Field
                    id="overallQuantityLessThan"
                    name="overallQuantityLessThan"
                    className="input"
                    type="number"
                  />
                </div>
                <div className="col-sm-4 col-xl-2 align-col-bottom">
                  <label htmlFor="quantityGreaterThan" className="label">
                    Variant qty greater than:
                  </label>
                  <Field
                    id="quantityGreaterThan"
                    name="quantityGreaterThan"
                    className="input"
                    type="number"
                  />
                </div>
                <div className="col-sm-4 col-xl-2 align-col-bottom">
                  <label htmlFor="quantityLessThan" className="label">
                    Variant qty less than:
                  </label>
                  <Field
                    id="quantityLessThan"
                    name="quantityLessThan"
                    className="input"
                    type="number"
                  />
                </div>
                {rarities && rarities.length > 0 ? (
                  <div className="col-sm-4 col-xl-2 align-col-bottom">
                    <label id="raritiesLabel" className="label">
                      Rarities:
                    </label>
                    <div className="select2">
                      <Field
                        aria-labelledby="raritiesLabel"
                        name="rarities"
                        component={SelectComponent}
                        placeholder="Select rarities"
                        isMulti
                        options={buildOptionsList(rarities)}
                      />
                    </div>
                  </div>
                ) : null}
                {setNames?.length > 0 ? (
                  <div className="col-sm-4 col-xl-2 align-col-bottom">
                    <label id="setNamesLabel" className="label">
                      Sets:
                    </label>
                    <div className="select2">
                      <Field
                        aria-labelledby="setNamesLabel"
                        name="setNames"
                        component={SelectComponent}
                        isMulti
                        options={buildOptionsList(setNames)}
                      />
                    </div>
                  </div>
                ) : null}
                {types?.length > 0 ? (
                  <div className="col-sm-4 col-xl-2 align-col-bottom">
                    <label id="typesLabel" className="label">
                      Card types:
                    </label>
                    <div className="select2">
                      <Field
                        aria-labelledby="typesLabel"
                        name="types"
                        component={SelectComponent}
                        isMulti
                        options={buildOptionsList(types)}
                      />
                    </div>
                  </div>
                ) : null}
                {colors.length > 0 ? (
                  <div className="col-sm-4 col-xl-2 align-col-bottom">
                    <label id="colorsLabel" className="label">
                      Colors:
                    </label>
                    <div className="select2">
                      <Field
                        aria-labelledby="colorsLabel"
                        name="colors"
                        component={SelectComponent}
                        isMulti
                        options={buildOptionsList(colors)}
                      />
                    </div>
                  </div>
                ) : null}
                {monsterTypes.length > 0 ? (
                  <div className="col-sm-4 col-xl-2 align-col-bottom">
                    <label id="monsterTypesLabel" className="label">
                      Monster types:
                    </label>
                    <div className="select2">
                      <Field
                        aria-labelledby="monsterTypesLabel"
                        name="monsterTypes"
                        component={SelectComponent}
                        isMulti
                        options={buildOptionsList(monsterTypes)}
                      />
                    </div>
                  </div>
                ) : null}
                <div className="col-sm-4 col-xl-2 align-col-bottom">
                  <label id="priceOverrideTypeLabel" className="label">
                    Price override type:
                  </label>
                  <div className="select2">
                    <Field
                      aria-labelledby="priceOverrideTypeLabel"
                      name="priceOverrideType"
                      component={SelectComponent}
                      options={buildOptionsList(priceOverrideTypes)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="filtersWrapper3">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6 applyFilters">
                  <ButtonComponent
                    primary
                    icon="fas fa-caret-right"
                    iconPosition="right"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Apply filters
                  </ButtonComponent>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default AdvancedSearchParametersForm;
