import React from "react";
import { Formik, Form, Field } from "formik";
import { eventParticipantSchema } from "../../schemas/event";
import { useShowError } from "../../hooks/errorHooks";
import {
  EventAdditionalInfo,
  EventParticipant,
  EventParticipantAdditionalInfo,
} from "../../types/events";
import Input from "../form/Input";
import ButtonComponent from "../generic/ButtonComponent";
import Modal from "../generic/Modal";
import Loader from "../generic/Loader";
import {
  useAddEventParticipant,
  useFetchEventAdditionalInfo,
  useFetchEventParticipant,
  useUpdateEventParticipant,
} from "../../hooks/eventHooks";
import { useHistory, useParams } from "react-router-dom";
import "./UpdateParticipantModal.scss";

type RouteParams = {
  eventId: string;
  participantId?: string;
};

type AdditionalInfo = {
  additionalInfoId: number;
  value: string;
};

interface EventParticipantForm {
  participantName: string;
  participantEmail: string;
  additionalInfo: AdditionalInfo[];
}

const initialValues: EventParticipantForm = {
  participantName: "",
  participantEmail: "",
  additionalInfo: [],
};

const reformatData = (values: EventParticipant) => {
  const { participantName, participantEmail, additionalInfo } = values;
  return {
    participantName,
    participantEmail,
    additionalInfo: (
      additionalInfo as unknown as EventParticipantAdditionalInfo[]
    ).map((info) => ({
      additionalInfoId: info.id,
      value: info.info.value,
    })),
  };
};

const mergeAdditionalInfo = (additionalInfo?: EventAdditionalInfo[]) => {
  if (!additionalInfo) return initialValues;
  return {
    ...initialValues,
    additionalInfo: additionalInfo.map((fields) => ({
      ...fields,
      info: {
        value: "",
      },
    })),
  };
};

function UpdateParticipantModal() {
  const { eventId, participantId } = useParams<RouteParams>();
  const history = useHistory();
  const showError = useShowError();
  const hasParticipant = participantId !== undefined;
  const { isLoading, data: participant } = useFetchEventParticipant({
    eventId,
    participantId,
  });
  const { data: additionalInfo, isLoading: isLoadingAdditionalInfo } =
    useFetchEventAdditionalInfo(Number(eventId));
  const { isMutating: isAddingParticipant, mutateAsync: addParticipant } =
    useAddEventParticipant();
  const { isMutating: isUpdatingParticipant, mutateAsync: updateParticipant } =
    useUpdateEventParticipant();

  const onClose = () => {
    history.push(`/events/participants/${eventId}`);
  };

  const handleSubmit = (values: EventParticipantForm) => {
    const save = hasParticipant ? updateParticipant : addParticipant;
    // @ts-ignore
    save({ eventId, participantId, participant: reformatData(values) })
      .then(() => onClose())
      .catch((error) => {
        showError(
          null,
          "Failed to save participant",
          error?.error
            ? error.error
            : "There was an error saving your event participant. Please try again"
        );
      });
  };

  if (isLoading || isLoadingAdditionalInfo) {
    return (
      <Modal onClose={onClose}>
        <Modal.Header>
          {" "}
          {hasParticipant ? "Update Participant" : "Add Participant"}
        </Modal.Header>
        <Modal.Content>
          <Loader />
        </Modal.Content>
      </Modal>
    );
  }

  const isMutating = isAddingParticipant || isUpdatingParticipant;

  return (
    <Modal onClose={onClose} isLoading={isMutating}>
      <Modal.Header>
        {hasParticipant ? "Update Participant" : "Add Participant"}
      </Modal.Header>
      <Modal.Content>
        <Formik
          enableReinitialize
          initialValues={participant || mergeAdditionalInfo(additionalInfo)}
          validationSchema={eventParticipantSchema}
          onSubmit={handleSubmit}
        >
          <Form id="UpdateParticipantForm" className="UpdateParticipantModal">
            <Field
              component={Input}
              name="participantName"
              type="text"
              label="Name"
            />
            <Field
              component={Input}
              name="participantEmail"
              type="text"
              label="Email"
            />
            {(
              (participant?.additionalInfo as unknown as EventParticipantAdditionalInfo[]) ||
              additionalInfo
            ).map((info, index) => (
              <React.Fragment key={info.id}>
                <Field
                  component={Input}
                  name={`additionalInfo.${index}.info.value`}
                  label={info.header}
                  placeholder={info.description ?? info.headerDescription}
                  type="text"
                />
                <Field name={`additionalInfo.${index}.id`} type="hidden" />
              </React.Fragment>
            ))}
          </Form>
        </Formik>
      </Modal.Content>
      <Modal.Actions>
        <ButtonComponent
          icon="fal fa-times"
          iconPosition="right"
          onClick={onClose}
        >
          Cancel
        </ButtonComponent>
        <ButtonComponent
          disabled={isMutating}
          primary
          type="submit"
          form="UpdateParticipantForm"
          icon="fas fa-caret-right"
          iconPosition="right"
        >
          {hasParticipant ? "Save Changes" : "Add Participant"}
        </ButtonComponent>
      </Modal.Actions>
    </Modal>
  );
}

export default UpdateParticipantModal;
