import React, { useState } from "react";
import Spinner from "../../components/generic/Spinner";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";
import PaymentTerminalSettings from "../../components/posSettings/PaymentTerminalSettings";
import TillSelect from "../../components/posSettings/TillSelect";
import { useGetTillSettingsQuery } from "../../generated/graphql";
import useTitle from "../../hooks/useTitle";
import SetActiveMenu from "../../menuStructures/SetActiveMenu";
import POSMenuStructure from "../../menuStructures/POSMenuStructure";
import "./PaymentTerminal.scss";

function PaymentTerminal() {
  const [selectedTill, setSelectedTill] = useState<number>();
  const [tillName, setTillName] = useState<string>();
  useTitle("Payment terminal settings");
  const { isLoading, data, error } = useGetTillSettingsQuery(
    { tillId: selectedTill },
    {
      enabled: selectedTill !== undefined,
    }
  );

  const handleTillChange = (tillId: number, name: string) => {
    setSelectedTill(tillId);
    setTillName(name);
  };

  if (error) {
    return (
      <div className="PaymentTerminal">
        <SetActiveMenu menuStructure={POSMenuStructure} />
        <SectionHeaderLayout title="Payment Terminal Settings" />
        <p>Failed to load till details. Please refresh and try again.</p>
      </div>
    );
  }

  return (
    <div className="PaymentTerminal">
      <SetActiveMenu menuStructure={POSMenuStructure} />
      <SectionHeaderLayout title="Payment Terminal Settings" />
      <label htmlFor="tillSelect">
        Choose a till:
        <TillSelect value={selectedTill} setValue={handleTillChange} />
      </label>
      <Spinner isLoading={isLoading}>
        {selectedTill ? (
          <div className="PaymentTerminal__settings">
            <h3>Payment terminal settings for till: {tillName}</h3>
            <PaymentTerminalSettings
              tillId={selectedTill}
              tillSettings={data?.BinderCustomerTillSettings}
            />
          </div>
        ) : null}
      </Spinner>
    </div>
  );
}

export default PaymentTerminal;
