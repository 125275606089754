import {
  CreateVariants,
  UpdateVariants,
  Product,
  WeightUnit,
} from "../generated/graphql";
import { VariantEditData } from "../types/productEdit";

export const mapCreateVariantData = (
  data: Omit<VariantEditData, "id">
): CreateVariants => {
  const { barcode, price, position, title, sku, weight, weightUnit } = data;
  return {
    barcode,
    position,
    price: price === "" ? undefined : price,
    sku,
    title,
    weight: weight === "" ? undefined : Number(weight),
    weightUnit: weightUnit as WeightUnit,
  };
};

export const mapUpdateVariantData = (data: VariantEditData): UpdateVariants => {
  const {
    barcode,
    toDelete,
    id,
    position,
    price,
    title,
    sku,
    weight,
    weightUnit,
  } = data;
  return {
    id,
    barcode,
    delete: toDelete,
    position,
    price: price === "" ? undefined : price,
    sku,
    title,
    weight: weight === "" ? undefined : Number(weight),
    weightUnit: weightUnit as WeightUnit,
  };
};

export const mapCreateProductData = (product: Product) => {
  return {
    ...product,
    bodyHtml: product.ProductDescription.descriptionHtml,
    productType: product.productType,
    published: true,
    tags: product.ProductTag.map((productTag) => productTag.tag),
    title: product.title,
    variants: product.ProductVariant.map(mapCreateVariantData),
    vendor: product.vendor,
  };
};

export const mapUpdateProductData = (product: Product) => {
  return {
    ...product,
    updateProductId: product.id,
    bodyHtml: product.ProductDescription.descriptionHtml,
    productType: product.productType,
    tags: product.ProductTag.map((productTag) => productTag.tag),
    title: product.title,
    variants: product.ProductVariant.map(mapUpdateVariantData).filter(
      (variant) => variant.id
    ),
    vendor: product.vendor,
  };
};
