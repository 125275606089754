import { useContext, useEffect } from "react";
import { ErrorContext } from "../providers/ErrorProvider";
import { CaughtError, GraphQLError } from "../types/error";
import { logError } from "../helpers/loggingHelpers";

export const useShowError = () => {
  const context = useContext(ErrorContext);
  if (!context) {
    throw new Error("useError hook must be used within ErrorProvider");
  }
  const { setError } = context;

  const showError = (
    error: DetailedError | CaughtError<GraphQLError[]>,
    fallbackTitle?: string,
    fallbackMessage?: string
  ) => {
    if (
      error instanceof Object &&
      ("data" in error || "error" in error || !fallbackTitle)
    ) {
      setError(error);
    } else {
      setError({
        error: fallbackTitle,
        detailedMessage: fallbackMessage,
      });
    }
  };
  return showError;
};

export const useLogErrors = (errors: unknown) => {
  useEffect(() => {
    if (errors) logError(errors);
  }, [errors]);
};
