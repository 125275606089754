import React from "react";

function BuylistConfirmationToolTip() {
  return (
    <aside>
      <h5>Buylist confirmation text</h5>
      <p>
        Adding text here allows you to override the default confirmation text
        that appears in the pop-up when a customer submits an online buylist.
      </p>
    </aside>
  );
}

export default BuylistConfirmationToolTip;
