export const debounce = <T extends (...args: any[]) => ReturnType<T>>(
  callbackFunction: T,
  delay: number
) => {
  let timeout: NodeJS.Timeout;

  const debounced = (...args: Parameters<T>) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => callbackFunction(...args), delay);
  };

  return debounced;
};
