import React, { useEffect, useState } from "react";
import { EbayTemplate, fetchTemplates } from "../../../api/rest/ebay";
import EbayListingTemplateForm from "../../../components/integrations/EbayListingTemplateForm";

interface ListingTemplateContainerProps {
  integration: string;
  game: string;
  setFieldValue: Function;
}

function ListingTemplateContainer(props: ListingTemplateContainerProps) {
  const [templates, setTemplates] = useState<EbayTemplate[]>([]);

  const { integration, game, setFieldValue } = props;

  const loadTemplates = () =>
    fetchTemplates().then((data) => setTemplates(data));

  useEffect(() => {
    loadTemplates();
  }, []);

  if (integration !== "ebay") {
    return null;
  }

  return (
    <EbayListingTemplateForm
      templates={templates}
      game={game}
      setFieldValue={setFieldValue}
    />
  );
}

export default ListingTemplateContainer;
