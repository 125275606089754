import React, { ReactNode } from "react";
import { segmentAnalytics, SegmentEventProps } from "../services/Analytics";
import "./NavButton.scss";

interface NavButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
  icon: string;
}

type NavButtonComponentProps = NavButtonProps & SegmentEventProps;

function NavButton(props: NavButtonComponentProps) {
  const {
    children,
    icon,
    segmentEventName,
    segmentEventProperties,
    onClick,
    ...rest
  } = props;

  const clickHandler = (
    event: React.MouseEvent<HTMLButtonElement & HTMLAnchorElement>
  ) => {
    if (segmentEventName) {
      segmentAnalytics.track(segmentEventName, segmentEventProperties);
    }
    onClick?.(event);
  };

  return (
    <button
      className="NavButton"
      type="button"
      onClick={clickHandler}
      {...rest}
    >
      <i className={icon}></i> {children}
    </button>
  );
}

export default NavButton;
