import React, { Component } from "react";
import { action } from "mobx";
import { inject, observer } from "mobx-react";
import FontIconPicker from "@fonticonpicker/react-fonticonpicker";
import "@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.base-theme.react.css";
import "@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.material-theme.react.css";
import prettierArray from "./prettierArray";
import ButtonComponent from "../generic/ButtonComponent";
import Modal from "../generic/Modal/Modal";
import "./QuickMenuEdit.scss";
import { ItemList } from "../../pos/ItemStore";

interface QuickLinkEditMenuProps {
  posStore?: ItemList;
}

@inject("posStore")
@observer
class QuickMenuEdit extends Component<QuickLinkEditMenuProps> {
  constructor(props) {
    super(props);
    this.state = {
      value: "fas fa-address-book",
    };
  }
  @action
  closeModal = () => {
    this.props.posStore.editingQuickItem = null;
    this.props.posStore.updateQuickLinks();
  };

  @action
  updateQuickTitle = (e) => {
    this.props.posStore.editingQuickItem.value.title = e.target.value;
    this.props.posStore.editingQuickItem.value.cardName = null;
    this.props.posStore.editingQuickItem.value.setName = null;
  };

  @action
  updateIcon = (value) => {
    console.log({ updatedIcon: value });
    this.props.posStore.editingQuickItem.icon = value;
  };

  @action
  updateColor = (e) => {
    console.log({ updatedColor: e.target.value });
    this.props.posStore.editingQuickItem.color = e.target.value;
  };

  render() {
    // prettier-ignore
    const props = {
      icons: prettierArray,
      theme: "bluegrey",
      renderUsing: "class",
      value: this.props.posStore.editingQuickItem
        ? this.props.posStore.editingQuickItem.icon
        : "fas fa-address-book",
      onChange: this.updateIcon,
      isMulti: false
    };
    const store = this.props.posStore;
    return store.editingQuickItem ? (
      <Modal onClose={this.closeModal} small>
        <Modal.Header>Edit Quicklink</Modal.Header>
        <Modal.Content>
          <div className="QuickMenuEdit">
            <label className="label" htmlFor="title">
              Title
            </label>
            <input
              className="input"
              type="text"
              id="title"
              value={this.props.posStore.editingQuickItem.value.title}
              onChange={this.updateQuickTitle}
              data-testid="quick-title"
            />

            {this.props.posStore.editingQuickItem.type == "folder" ? (
              <>
                <span className="label">Icon</span>
                <FontIconPicker {...props} />
              </>
            ) : null}
            <label className="label" htmlFor="color">
              Accent color
            </label>
            <input
              aria-label="Quicklink accent color picker"
              type="color"
              id="color"
              className="input"
              onChange={this.updateColor}
              value={this.props.posStore.editingQuickItem.color}
            />
          </div>
        </Modal.Content>
        <Modal.Actions>
          <ButtonComponent
            primary
            onClick={this.closeModal}
            icon="fas fa-caret-right"
            iconPosition="right"
          >
            Done
          </ButtonComponent>
        </Modal.Actions>
      </Modal>
    ) : (
      ""
    );
  }
}

export default QuickMenuEdit;
