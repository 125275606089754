import moment from "moment";
import "moment-timezone";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { TIMEZONES } from "../../timezones";
import { segmentAnalytics } from "../services/Analytics";
import {
  REPORT_DATE_RANGE_SELECTED,
  REPORT_TIMEZONE_SELECTED,
} from "../../constants/eventsTracked";

function DateRangeSelect({
  setDateRange,
  defaultFromDate,
  defaultToDate,
  defaultPreset,
  reportType,
}) {
  const TODAY = moment().format(moment.HTML5_FMT.DATE);
  const initialFromDate = defaultFromDate
    ? moment(defaultFromDate).format(moment.HTML5_FMT.DATE)
    : moment().startOf("month").format(moment.HTML5_FMT.DATE);
  const initialToDate = defaultToDate
    ? moment(defaultToDate).subtract(1, "days").format(moment.HTML5_FMT.DATE)
    : TODAY;
  const [timeZone, setTimeZone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [preset, setPreset] = useState("");
  const [startDate, setStartDate] = useState(initialFromDate);
  const [endDate, setEndDate] = useState(initialToDate);

  useEffect(() => {
    if (defaultPreset) {
      selectPreset(defaultPreset);
    }
  }, [defaultPreset]);

  const setDates = (start, end, tz) => {
    setStartDate(start);
    setEndDate(end);

    // Apply customer timezone to requested range
    const startWithTz = moment.tz(start, tz).format();
    const endWithTz = moment.tz(end, tz).format();

    // Increment end date, as we want to include that day in the range
    const newEndDate = moment(endWithTz).add(1, "days").toISOString();
    setDateRange(moment(startWithTz).toISOString(), newEndDate, tz);
  };

  const onPresetChange = (event) => {
    selectPreset(event.target.value);
    if (reportType) {
      segmentAnalytics.track(REPORT_DATE_RANGE_SELECTED, {
        date_range: event.target.value,
        report_type: reportType,
      });
    }
  };

  const selectPreset = (selectedPreset) => {
    let start;
    let end;
    switch (selectedPreset) {
      case "mtd":
        start = moment().startOf("month").format(moment.HTML5_FMT.DATE);
        end = TODAY;
        break;
      case "p30d":
        start = moment().subtract(30, "days").format(moment.HTML5_FMT.DATE);
        end = TODAY;
        break;
      case "m-1":
        start = moment()
          .subtract(1, "months")
          .startOf("month")
          .format(moment.HTML5_FMT.DATE);

        end = moment()
          .subtract(1, "month")
          .endOf("month")
          .format(moment.HTML5_FMT.DATE);
        break;
      case "ytd":
        start = moment().startOf("year").format(moment.HTML5_FMT.DATE);
        end = TODAY;
        break;
      case "p12m":
        start = moment().subtract(12, "months").format(moment.HTML5_FMT.DATE);
        end = TODAY;
        break;
      case "y-1":
        start = moment()
          .subtract(1, "year")
          .startOf("year")
          .format(moment.HTML5_FMT.DATE);
        end = moment()
          .subtract(1, "year")
          .endOf("year")
          .format(moment.HTML5_FMT.DATE);
        break;
      default:
      // Range
    }
    setPreset(selectedPreset);
    setDates(start, end, timeZone);
  };

  const onStartDateChange = (event) => {
    setDates(event.target.value, endDate, timeZone);
    setPreset("range");
  };

  const onEndDateChange = (event) => {
    setDates(startDate, event.target.value, timeZone);
    setPreset("range");
  };

  const onTimeZoneChange = (event) => {
    if (reportType) {
      segmentAnalytics.track(REPORT_TIMEZONE_SELECTED, {
        timezone: event.target.value,
        report_type: reportType,
      });
    }
    setTimeZone(event.target.value);
    setDates(startDate, endDate, event.target.value);
  };

  return (
    <div className="date-range-select">
      <select
        value={preset}
        onChange={onPresetChange}
        data-testid="selector"
        aria-label="Predefined date range selector"
      >
        <option value="" disabled>
          -- Select a date range --
        </option>
        <option value="mtd">Month to Date</option>
        <option value="p30d">Last 30 Days</option>
        <option value="ytd">Year to Date</option>
        <option value="m-1">Previous Month</option>
        <option value="p12m">Last 12 Months</option>
        <option value="y-1">Previous Year</option>
        <option value="range">Date Range</option>
      </select>
      <input
        aria-label="Initial date selector"
        type="date"
        value={startDate}
        max={TODAY}
        onChange={onStartDateChange}
      />
      <input
        aria-label="Final date selector"
        type="date"
        value={endDate}
        max={TODAY}
        onChange={onEndDateChange}
      />
      <select
        value={timeZone}
        onChange={onTimeZoneChange}
        title="Timezone"
        aria-label="Timezone selector"
      >
        {TIMEZONES.map((timeZoneOption) => (
          <option value={timeZoneOption} key={timeZoneOption}>
            {timeZoneOption}
          </option>
        ))}
      </select>
    </div>
  );
}

DateRangeSelect.propTypes = {
  setDateRange: PropTypes.func.isRequired,
  defaultFromDate: PropTypes.string,
  defaultToDate: PropTypes.string,
  defaultPreset: PropTypes.string,
  reportType: PropTypes.string,
};

export default DateRangeSelect;
