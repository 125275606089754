import * as Yup from "yup";

export const eventSchema = Yup.object().shape(
  {
    eventId: Yup.string(),
    title: Yup.string().required("Please enter a name"),
    game: Yup.string().required("Please choose a game"),
    type: Yup.string().required("Please choose an event type"),
    date: Yup.string().required("Please enter a date"),
    time: Yup.string().required("Please enter a time"),
    recurring: Yup.bool(),
    recurringFrequency: Yup.string()
      .nullable()
      .when("recurring", {
        is: true,
        then: Yup.string().required("Please choose a repeat frequency"),
      }),
    recurringType: Yup.string()
      .nullable()
      .when("recurring", {
        is: true,
        then: Yup.string().required("Please choose a repeat type"),
      }),
    recurringEndDate: Yup.string().nullable(),
    recurringDays: Yup.string()
      .nullable()
      .when("recurringType", {
        is: "week",
        then: Yup.string().min(1, "At least one day is required"),
      }),
    monthlyRecurringDayNumber: Yup.number()
      .nullable()
      .max(31)
      .when(["recurringType", "monthlyRecurringType"], {
        is: (recurringType: string, monthlyRecurringType: string) =>
          recurringType === "month" &&
          (!monthlyRecurringType || monthlyRecurringType.length === 0),
        then: Yup.number().required("A day of the month is required"),
      }),
    monthlyRecurringType: Yup.string()
      .nullable()
      .when(["recurringType", "monthlyRecurringDayNumber"], {
        is: (recurringType: string, monthlyRecurringDayNumber: string) =>
          recurringType === "month" &&
          (!monthlyRecurringDayNumber ||
            monthlyRecurringDayNumber.length === 0),
        then: Yup.string().required("required"),
      }),

    monthlyRecurringDay: Yup.string()
      .nullable()
      .when(["recurringType", "monthlyRecurringDayNumber"], {
        is: (recurringType: string, monthlyRecurringDayNumber: string) =>
          recurringType === "month" &&
          (!monthlyRecurringDayNumber ||
            monthlyRecurringDayNumber.length === 0),
        then: Yup.string().required("required"),
      }),
    includeDateInEventTitle: Yup.bool().nullable(),
    dateFormat: Yup.string().nullable(),
    buildingName: Yup.string(),
    streetAddress: Yup.string(),
    city: Yup.string(),
    state: Yup.string(),
    zipCode: Yup.string(),
    prizePool: Yup.string(),
    description: Yup.string(),
    prizeStructure: Yup.string(),
    results: Yup.string(),
    isTicketed: Yup.bool(),
    ticketed: Yup.bool(),
    ticketPrice: Yup.number().nullable(),
    maxParticipants: Yup.number().nullable(),
    taxable: Yup.bool(),
    additionalInfoRequired: Yup.bool(),
    additionalInfo: Yup.array().of(
      Yup.object().shape({
        id: Yup.number(),
        header: Yup.string().required("Please enter a value"),
        headerDescription: Yup.string(),
      })
    ),
  },
  [["monthlyRecurringType", "monthlyRecurringDayNumber"]]
);

export const eventParticipantSchema = Yup.object().shape({
  participantName: Yup.string().required("Participant name is required"),
  participantEmail: Yup.string()
    .email("Please enter a valid email")
    .required("Participant email is required"),
  additionalInfo: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number(),
        value: Yup.string(),
      })
    )
    .optional(),
});
