import React from "react";

interface EventCardLinksProps {
  children: React.ReactNode;
}

function EventCardLinks(props: EventCardLinksProps) {
  const { children } = props;

  return <>{children}</>;
}

EventCardLinks.isLinks = true;

export default EventCardLinks;
