import React from "react";
import "./CustomerAvatar.scss";

interface CustomerAvatarProps {
  customer: Customer;
  className?: string;
}

function CustomerAvatar(props: CustomerAvatarProps) {
  const { customer, className } = props;
  return (
    <div className={["CustomerAvatar", className].join(" ")}>
      {customer.firstName?.length > 0 ? (
        <>
          {customer.firstName.charAt(0)}
          {customer?.lastName?.length > 0 ? (
            <>{customer.lastName.charAt(0)}</>
          ) : null}
        </>
      ) : (
        <>
          {customer.email?.length > 0 ? <>{customer.email.charAt(0)}</> : null}
        </>
      )}
    </div>
  );
}

export default CustomerAvatar;
