/**
 * Ensures all required fields have been filled in
 *
 * @param {array} requiredFields List of required fields
 * @param {object} values Form values
 * @returns {object}
 */
export const validateRequiredFields = (
  requiredFields: string[],
  values: unknown
) => {
  const errors = {};
  requiredFields.forEach((requiredField: string) => {
    const value = getNestedValue(values, requiredField);
    if (
      value === null ||
      value === undefined ||
      value === "" ||
      value.length === 0
    )
      setNestedValue(errors, requiredField, "This field must be completed");
  });
  return errors;
};

/**
 *
 * @param {object} values data
 * @param {string} path Dot delimited string representing path to child
 * @returns {any}
 */
const getNestedValue = (values: unknown, path: string) => {
  if (values === null || values === undefined) return;
  if (!path) return values;
  const properties = path.split(".");
  return getNestedValue(values?.[properties.shift()], properties.join("."));
};

/**
 *
 * @param {object} values  data
 * @param {string} path Dot delimited string representing path to child
 * @param {any} value Value to set at given path
 * @returns {object}
 */
const setNestedValue = (values: unknown, path: string, value: unknown) => {
  const splitPath = path.split(".");
  splitPath.reduce((obj, val, level) => {
    if (typeof obj[val] === "undefined" && level !== splitPath.length - 1) {
      obj[val] = {};
      return obj[val];
    }
    if (level === splitPath.length - 1) {
      obj[val] = value;
      return value;
    }
    return obj[val];
  }, values);
};
