import React, { Component, Fragment, createRef } from "react";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import SettingsMenu from "../../menuStructures/SettingsMenu";
import { Loader, Spinner } from "../../components";
import {
  fetchProductList,
  fetchWPNReportProducts,
  saveWPNReportProducts,
} from "../../api/rest/settings";
import {
  EDIT_REPORT_SETTINGS_CLICKED,
  SAVE_REPORT_SETTINGS_CLICKED,
} from "../../constants/eventsTracked";
import { segmentAnalytics } from "../../components/services/Analytics";
import { notify } from "../../helpers/notificationHelpers";
import ButtonComponent from "../../components/generic/ButtonComponent";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";
import CheckboxComponent from "../../components/generic/CheckboxComponent";
import SubSectionHeaderLayout from "../../components/layout/SubSectionHeaderLayout";
import "./ReportSettings.scss";

@inject("store")
@observer
class ReportSettings extends Component {
  @observable isLoading = true;
  @action setIsLoading = (loading) => {
    this.isLoading = loading;
  };

  @observable updatingReportSettings = false;
  @action setUpdatingReportSettings = (updating) => {
    if (updating) segmentAnalytics.track(EDIT_REPORT_SETTINGS_CLICKED);
    this.updatingReportSettings = updating;
  };

  @observable reportSettings;
  @action setReportSettings = (settings) => {
    this.reportSettings = settings;
  };

  @observable savingSettings;
  @action setSavingSettings = (saving) => {
    this.savingSettings = saving;
  };

  @observable availableProducts = [];
  @action setAvailableProducts = (products) => {
    this.availableProducts = products;
  };

  @observable selectedProducts = [];
  @action setSelectedProducts = (products) => {
    this.selectedProducts = products;
  };

  constructor(props) {
    super(props);
    this.formRef = createRef();
  }

  componentDidMount() {
    document.title = "Report Settings | BinderPOS";
    this.loadAvailableProducts()
      .then(() => {
        return this.loadSelectedProducts();
      })
      .catch((error) => {
        this.props.store.MainStore.setError(
          error,
          "Failed to load products",
          "There was an error retrieving your list of selected products. Please refresh and try again"
        );
      })
      .finally(() => {
        this.setIsLoading(false);
      });
  }

  loadAvailableProducts = async () => {
    try {
      const result = await fetchProductList();
      this.setAvailableProducts(
        result.filter((product) => product && product.length)
      );
    } catch (error) {
      this.props.store.MainStore.setError(
        error,
        "Failed to load products",
        "There was an error retrieving the list of available products. Please try again"
      );
    }
  };

  loadSelectedProducts = async () => {
    try {
      const result = await fetchWPNReportProducts();
      if (result.settingValue) {
        const products = JSON.parse(result.settingValue);
        this.setSelectedProducts(products);
      }
    } catch (error) {
      this.props.store.MainStore.setError(
        error,
        "Failed to load products",
        "There was an error retrieving your selected products. Please try again"
      );
    }
  };

  updateWPNProducts = (event) => {
    const { checked, name } = event.target;
    let updatedProducts;
    if (checked) {
      updatedProducts = [...this.selectedProducts, name];
    } else {
      updatedProducts = this.selectedProducts.filter(
        (product) => product !== name
      );
    }
    this.setSelectedProducts(updatedProducts);
  };

  saveSelectedProducts = (event) => {
    event.preventDefault();
    this.setSavingSettings(true);
    segmentAnalytics.track(SAVE_REPORT_SETTINGS_CLICKED, {
      product_types: this.selectedProducts,
    });
    saveWPNReportProducts(this.selectedProducts)
      .then(() => {
        notify.info("Report settings updated");
        this.setUpdatingReportSettings(false);
      })
      .catch((error) => {
        this.props.store.MainStore.setError(
          error,
          "Failed to save products",
          "There was an error submitting your selected products. Please try again"
        );
      })
      .finally(() => {
        this.setSavingSettings(false);
      });
  };

  render() {
    if (this.isLoading) {
      return <Loader />;
    }

    return (
      <div className="ReportSettings">
        <SettingsMenu />
        <SectionHeaderLayout title="Report Settings">
          <ButtonComponent
            primary
            onClick={() =>
              this.setUpdatingReportSettings(!this.updatingReportSettings)
            }
          >
            {this.updatingReportSettings ? "Cancel" : "Edit Settings"}
          </ButtonComponent>
        </SectionHeaderLayout>

        <form
          ref={this.formRef}
          onSubmit={this.saveSelectedProducts}
          noValidate
        >
          <Spinner isLoading={this.savingSettings}>
            <SubSectionHeaderLayout title="WPN Report" />
            <p>
              The following product types will be used to generate your WPN
              Report:
            </p>
            {this.updatingReportSettings ? (
              <div className="ReportSettings__select-products-container">
                {this.availableProducts
                  .sort((a, b) => a.localeCompare(b))
                  .map((product) => (
                    <CheckboxComponent
                      key={product}
                      name={product}
                      label={product}
                      checked={this.selectedProducts.includes(product)}
                      onChange={this.updateWPNProducts}
                    />
                  ))}
                <ButtonComponent primary fullWidth type="submit">
                  Save Changes
                </ButtonComponent>
              </div>
            ) : (
              <Fragment>
                <ul className="ReportSettings__selected-products-container">
                  {this.selectedProducts
                    .sort((a, b) => a.localeCompare(b))
                    .map((product, index) => (
                      <li key={index}>{product}</li>
                    ))}
                  {this.selectedProducts.length === 0 && (
                    <li key="none">You currency have no selected products</li>
                  )}
                </ul>
              </Fragment>
            )}
          </Spinner>
        </form>
      </div>
    );
  }
}

ReportSettings.propTypes = { store: PropTypes.object };

export default ReportSettings;
