import { BaseLineItem } from "../../types/pos";
import { calculateTotal } from "./cart";
import { parseShopifyTagCommand } from "./pos";

type LineItemTaxRate = {
  title: string;
  price: number;
  rate: number;
};

export const isTaxRateValid = (taxRate: number | string | undefined | null) =>
  taxRate !== null;

export function determineLineItemTaxRate(tags: string, taxRate: number) {
  const specialTaxCommand = parseShopifyTagCommand(tags).find(
    (tc) => tc.command === "AT"
  );
  if (!specialTaxCommand) {
    return taxRate;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [specialTaxName, specialTaxRate] = specialTaxCommand.args;
  const parsedTaxRate = parseFloat(specialTaxRate);
  if (isNaN(parsedTaxRate)) {
    return taxRate;
  }
  return parsedTaxRate / 100;
}

export function getSpecialTaxName(tags: string) {
  const specialTaxCommand = parseShopifyTagCommand(tags).find(
    (tc) => tc.command === "AT"
  );
  if (!specialTaxCommand) {
    return false;
  }
  return specialTaxCommand.args[0];
}

export function calculateLineItemTotalTax(item: BaseLineItem) {
  const itemBasePrice = item.actualPrice + item.discountValue / item.qty;
  const itemTaxRate = item.itemTaxRate || 0;
  // If taxes are included in BinderPOS UI
  if (item.taxSetting.taxIncluded) {
    // Tax disabled by Shopify
    if (item.taxDisabledShopify) {
      return {
        actualPrice: item.actualPrice,
        itemPrice: itemBasePrice,
        displayPrice: itemBasePrice,
        itemTax: 0,
      };
    }
    // Tax disabled by POS toggle
    if (item.taxDisabledUI) {
      return {
        actualPrice: item.actualPrice,
        itemPrice: itemBasePrice,
        // VERIFY 2.2: The Check below looks wrong and always evaluate as true.
        // item.buyItem !== "return"
        //   ? itemBasePrice
        //   : itemBasePrice / (1 + itemTaxRate),
        displayPrice: itemBasePrice,
        itemTax: 0,
      };
    }
    // No Tax settings
    const itemPrice =
      item.buyItem && item.cartType !== "return"
        ? itemBasePrice
        : itemBasePrice / (1 + itemTaxRate);
    return {
      actualPrice: item.actualPrice,
      itemPrice: itemPrice,
      displayPrice: itemBasePrice,
      itemTax: itemPrice * itemTaxRate || 0, // Prevent a return/buy with 0 tax Rate to generate -0
    };
  }
  // If taxes are NOT included in BinderPOS UI
  else {
    // Tax disabled by shopify or POS toggle
    if (item.taxDisabledShopify || item.taxDisabledUI) {
      return {
        actualPrice: item.actualPrice,
        itemPrice: itemBasePrice,
        displayPrice: itemBasePrice,
        itemTax: 0,
      };
    }
    // Tax included via tag or later db column
    // VERIFY 2.1,3.1: It Never gets updated
    // if (item.itemTaxIncluded) {
    //   return {
    //     actualPrice: item.actualPrice,
    //     itemPrice: itemBasePrice / (1 + itemTaxRate),
    //     displayPrice: itemBasePrice,
    //     itemTax: itemBasePrice * itemTaxRate,
    //   };
    // }
    // No Tax settings or tags have been set
    return {
      actualPrice: item.actualPrice,
      itemPrice: itemBasePrice,
      displayPrice: itemBasePrice,
      itemTax: itemBasePrice * itemTaxRate || 0, // Prevent a return/buy with 0 tax Rate to generate -0
    };
  }
}

export function calculateDiscountTaxAmount(itemList) {
  // Basically this is trying to figure out the amount of tax negated by a discount
  const tempTotal = calculateTotal(itemList);
  let tempTaxTotal = 0;
  let discountTaxAmount = 0;
  if (itemList.cart.length) {
    itemList.cart.forEach((lineItem: BaseLineItem) => {
      if (!lineItem.buyItem || itemList.cartType === "return") {
        tempTaxTotal += lineItem.lineTaxTotal;
      }
    });
  }

  if (itemList.globalDiscount?.type === "percentage") {
    discountTaxAmount =
      -tempTaxTotal * (parseFloat(itemList.globalDiscount.amount || "0") / 100);
  } else if (itemList.globalDiscount?.type === "amount" && tempTaxTotal) {
    discountTaxAmount =
      -(itemList.globalDiscount.amount || 0) * (tempTaxTotal / tempTotal);
  }

  if (discountTaxAmount + tempTaxTotal < 0 && itemList.cartType !== "return") {
    discountTaxAmount = -tempTaxTotal;
  }
  return discountTaxAmount;
}

export function calculateSaleTaxTotal(itemList) {
  if (itemList.cart.length) {
    let taxTotal = 0;
    itemList.cart.forEach((line) => {
      if (!line.buyItem || itemList.cartType === "return") {
        taxTotal += parseFloat(line.lineTaxTotal);
      }
    });
    return taxTotal + itemList.discountTaxAmount;
  }
  // VERIFY 2.3: Doesnt seem achievable/make sense
  return 0 + itemList.discountTaxAmount;
}

export function calculateNegatedTaxTotal(itemList) {
  if (
    itemList.cart.length &&
    !itemList.taxTrades &&
    itemList.cartType !== "return"
  ) {
    let taxTotal = 0;
    itemList.cart.forEach((lineItem: BaseLineItem) => {
      if (lineItem.buyItem) {
        taxTotal += lineItem.lineTaxTotal;
      }
    });
    if (itemList.saleTaxTotal + taxTotal < 0) {
      return -itemList.saleTaxTotal;
    } else {
      return taxTotal;
    }
  }
  return 0;
}

export function calculateTaxTotal(itemList) {
  return itemList.saleTaxTotal + itemList.negatedTaxTotal < 0 &&
    itemList.cartType !== "return"
    ? 0
    : itemList.saleTaxTotal + itemList.negatedTaxTotal;
}

export function calculateTaxRates(itemList) {
  const taxes: LineItemTaxRate[] = [];
  const standardTax: LineItemTaxRate = {
    title: itemList.taxWording,
    price: 0,
    rate: itemList.taxRate,
  };
  for (const lineItem of itemList.cart) {
    if (!lineItem.buyItem) {
      if (lineItem.specialTax) {
        let exists = false;
        const taxLine = {
          title: lineItem.specialTax,
          price: lineItem.lineTaxTotal,
          rate: lineItem.itemTaxRate,
        };
        if (taxes.length) {
          for (const tax of taxes) {
            if (taxLine.title === tax.title && taxLine.rate === tax.rate) {
              tax.price += taxLine.price;
              exists = true;
              break;
            }
          }
        }
        if (!exists) {
          taxes.push(taxLine);
        }
      } else {
        standardTax.price += lineItem.lineTaxTotal;
      }
    }
  }
  taxes.push(standardTax);
  for (const tax of taxes) {
    if (tax.price < 0) {
      tax.price = 0;
    }
    if (itemList.discountPercentage) {
      tax.price = tax.price - tax.price * itemList.discountPercentage;
    }
    if (itemList.negatedTaxTotal) {
      tax.price =
        tax.price +
        itemList.negatedTaxTotal * (tax.price / itemList.saleTaxTotal);
    }
  }
  return taxes;
}
