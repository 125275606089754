import { useMutation, useQuery, useQueryClient } from "react-query";
import { User } from "../types/user";
import {
  fetchUserById,
  fetchUsers,
  createUser,
  updateUser,
  disableUser,
  enableUser,
  sendPasswordReset,
} from "../api/rest/users";

const LONG_LIFE_DATA_STALE_TIME = 5 * 60 * 1000; // ms

const mergeUser = (userList: User[], user: User) =>
  userList.map((oldUser: User) => (oldUser.id === user.id ? user : oldUser));

export const useFetchUserById = (userId: string) =>
  useQuery(["users", { userId }], () => fetchUserById(userId), {
    enabled: !!userId,
  });

export const useFetchUsers = () =>
  useQuery(["users"], fetchUsers, {
    staleTime: LONG_LIFE_DATA_STALE_TIME,
  });

export const useCreateUser = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(createUser, {
    onSuccess: (response: User) => {
      queryClient.setQueryData<User[]>(["users"], (prev): User[] => {
        if (prev) {
          return [...prev, response];
        }
        return [response];
      });
    },
  });
  return { isMutating, ...rest };
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(updateUser, {
    onSuccess: (response: User) => {
      queryClient.setQueryData<User[]>(["users"], (prev = []): User[] =>
        mergeUser(prev, response)
      );
    },
  });
  return { isMutating, ...rest };
};

export const useDisableUser = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(disableUser, {
    onSuccess: (response: User) => {
      queryClient.setQueryData<User[]>(["users"], (prev = []): User[] =>
        mergeUser(prev, response)
      );
    },
  });
  return { isMutating, ...rest };
};

export const useEnableUser = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(enableUser, {
    onSuccess: (response: User) => {
      queryClient.setQueryData<User[]>(["users"], (prev = []): User[] =>
        mergeUser(prev, response)
      );
    },
  });
  return { isMutating, ...rest };
};

export const useSendPasswordReset = () => {
  const { isLoading: isMutating, ...rest } = useMutation(sendPasswordReset);
  return { isMutating, ...rest };
};
