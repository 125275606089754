import { useEffect, useState } from "react";
import {
  BUYLIST_CONFIRMATION_TEXT_TOOLTIP_CLICKED,
  UPDATE_BUYLIST_CONFIRMATION_TEXT_CLICKED,
} from "../../constants/eventsTracked";
import { notify } from "../../helpers/notificationHelpers";
import { useFetchSetting, useUpdateSetting } from "../../hooks/settingHooks";
import { useShowError } from "../../hooks/errorHooks";
import Tooltip from "../../components/generic/Tooltip";
import BuylistConfirmationToolTip from "../buylist/BuylistConfirmationToolTip";
import SunEditor, { buttonList } from "suneditor-react";
import ButtonComponent from "../../components/generic/ButtonComponent";

function BuylistConfirmationSettings() {
  const [buylistConfirmationTextHTML, setBuylistConfirmationTextHTML] =
    useState("");
  const showError = useShowError();
  const { mutateAsync: updateSetting } = useUpdateSetting();

  const { data: buylistConfirmationText, error: buylistSettingError } =
    useFetchSetting("buylistConfirmationText");

  useEffect(() => {
    if (buylistSettingError) {
      showError(
        buylistSettingError as DetailedError,
        "Failed to load setting",
        "There was an error retrieving your buylist confirmation text. Please refresh and try again"
      );
    }
  }, [buylistSettingError]);

  const updateBuylistConfirmationText = () =>
    updateSetting({
      settingName: "buylistConfirmationText",
      settingValue: String(buylistConfirmationTextHTML),
    })
      .then(() => {
        notify.info("Buylist confirmation text updated!");
      })
      .catch((error: DetailedError) => {
        showError(
          error,
          "Failed to save setting",
          "There was an error submitting your buylist confirmation text. Please try again"
        );
      });

  return (
    <>
      <div>
        <label>
          Buylist confirmation text
          <Tooltip segmentEventName={BUYLIST_CONFIRMATION_TEXT_TOOLTIP_CLICKED}>
            <BuylistConfirmationToolTip />
          </Tooltip>
        </label>
        <SunEditor
          setContents={buylistConfirmationText}
          onChange={(content) => setBuylistConfirmationTextHTML(content)}
          setOptions={{
            buttonList: buttonList.complex,
            height: "200",
            tabDisable: true,
          }}
        />
      </div>
      <ButtonComponent
        primary
        onClick={() => updateBuylistConfirmationText()}
        segmentEventName={UPDATE_BUYLIST_CONFIRMATION_TEXT_CLICKED}
        fullWidth
      >
        Update buylist confirmation text
      </ButtonComponent>
    </>
  );
}

export default BuylistConfirmationSettings;
