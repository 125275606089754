import React from "react";
import { Field } from "formik";
import { PRODUCT_EDIT_AUTOCOMPLETE } from "../../constants/featureFlags";
import Input from "../form/Input";
import { useProductTypesQuery } from "../../generated/graphql";
import { useFeatureFlag } from "../../hooks/globalSettingsHooks";

interface ProductTypeInputProps {
  name: string;
}

function ProductTypeInput(props: ProductTypeInputProps) {
  const { name } = props;
  const autocompleteEnabled = useFeatureFlag(PRODUCT_EDIT_AUTOCOMPLETE);
  const { data } = useProductTypesQuery(
    {},
    {
      enabled: autocompleteEnabled,
      refetchOnWindowFocus: false,
      cacheTime: 60_000,
    }
  );

  return (
    <>
      <Field
        component={Input}
        name={name}
        label="Product Type"
        type="string"
        list="productTypesList"
      />
      <datalist id="productTypesList">
        {data?.Product?.filter(
          (product) => product.productType && product.productType.length > 1
        ).map((product) => (
          <option key={product.productType} value={product.productType}>
            {product.productType}
          </option>
        ))}
      </datalist>
    </>
  );
}

export default ProductTypeInput;
