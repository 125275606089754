import PropTypes from "prop-types";
import React from "react";
import { NavLink } from "react-router-dom";
import { segmentAnalytics } from "../services/Analytics";
import {
  BULK_SETTINGS_CLICKED,
  EBAY_LOGS_CLICKED,
  EBAY_POLICIES_CLICKED,
  INDIVIDUAL_SETTINGS_CLICKED,
  REVIEW_VARIANTS_CLICKED,
} from "../../constants/eventsTracked";

interface TabNavigationProps {
  integrationId: string;
}

function TabNavigation({ integrationId }: TabNavigationProps) {
  return (
    <ul className="integration-tab-navigation">
      <li>
        <NavLink
          to={`/integrations/${integrationId}`}
          exact
          activeClassName="selected"
          onClick={() =>
            segmentAnalytics.track(BULK_SETTINGS_CLICKED, {
              integration_type: integrationId,
            })
          }
        >
          <span>Bulk Settings</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to={`/integrations/${integrationId}/advanced`}
          exact
          activeClassName="selected"
          onClick={() =>
            segmentAnalytics.track(INDIVIDUAL_SETTINGS_CLICKED, {
              integration_type: integrationId,
            })
          }
        >
          <span>Individual Settings</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to={`/integrations/${integrationId}/variants`}
          exact
          activeClassName="selected"
          onClick={() =>
            segmentAnalytics.track(REVIEW_VARIANTS_CLICKED, {
              integration_type: integrationId,
            })
          }
        >
          <span>Review Variants</span>
        </NavLink>
      </li>
      {integrationId === "ebay" ? (
        <>
          <li>
            <NavLink
              to={`/integrations/${integrationId}/policies`}
              activeClassName="selected"
              onClick={() =>
                segmentAnalytics.track(EBAY_POLICIES_CLICKED, {
                  integration_type: integrationId,
                })
              }
            >
              <span>Ebay Policies</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/integrations/${integrationId}/logs`}
              activeClassName="selected"
              onClick={() =>
                segmentAnalytics.track(EBAY_LOGS_CLICKED, {
                  integration_type: integrationId,
                })
              }
            >
              <span>Logs</span>
            </NavLink>
          </li>
        </>
      ) : null}
      {integrationId === "cfbmarket" ? (
        <>
          <li>
            <NavLink
              to={`/integrations/${integrationId}/configure`}
              activeClassName="selected"
            >
              <span>Configure</span>
            </NavLink>
          </li>
        </>
      ) : null}
    </ul>
  );
}

TabNavigation.propTypes = {
  integrationId: PropTypes.string.isRequired,
};

export default TabNavigation;
