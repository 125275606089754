import React from "react";
import { CatalogGroup } from "../../types/catalogSettings";
import CatalogEnable from "./CatalogEnable";
import Logo from "../../../assets/img/logo.png";
import "./CatalogComponent.scss";

interface CatalogComponentProps {
  catalogGroup: CatalogGroup;
}

function CatalogComponent(props: CatalogComponentProps) {
  const { catalogGroup } = props;
  const { logo, title, catalogs } = catalogGroup;

  return (
    <div className="CatalogComponent" data-testid="CatalogComponent">
      <div className="CatalogComponent__header">
        <img
          src={logo ?? Logo}
          title={title}
          className="CatalogComponent__logo"
          role="presentation"
        />

        {title}
      </div>
      <div className="CatalogComponent__catalogs">
        {catalogs.map(({ name, binderGameType, enabled }) => (
          <CatalogEnable
            key={binderGameType}
            gameCode={binderGameType}
            name={name}
            isEnabled={enabled}
          />
        ))}
      </div>
    </div>
  );
}

export default CatalogComponent;
