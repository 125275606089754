import React, { useState } from "react";
import Loader from "../../components/generic/Loader";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";
import { TillSelect } from "../../components/posSettings";
import PerTillSettings from "../../components/posSettings/PerTillSettings";
import { useGetTillSettingsQuery } from "../../generated/graphql";
import "./TillSettings.scss";
import Panel from "../../components/layout/Panel";

function TillSettings() {
  const [selectedTill, setSelectedTill] = useState<number>();
  const { data, isLoading, error } = useGetTillSettingsQuery(
    {
      tillId: selectedTill,
    },
    {
      enabled: selectedTill > 0,
    }
  );

  const handleTillChange = (tillId: number) => setSelectedTill(tillId);

  if (error) {
    return <p>Failed to load till settings. Please refresh and try again</p>;
  }

  return (
    <div className="TillSettings">
      <SectionHeaderLayout title="Till Settings" />
      <Panel>
        These settings apply to the selected till and may be different for each
        till. For example you may wish to have different settings for a
        self-service kiosk that your standard Point of Sale.
        <div className="TillSettings__tillSelect">
          <label htmlFor="tillSelect">Select a till:</label>
          <TillSelect
            title="Select a till"
            value={selectedTill}
            setValue={handleTillChange}
          />
        </div>
      </Panel>
      {isLoading ? (
        <Loader />
      ) : (
        <PerTillSettings
          tillId={selectedTill}
          savedSettings={data?.BinderCustomerTillSettings}
        />
      )}
    </div>
  );
}

export default TillSettings;
