import { UseQueryOptions, QueryKey } from "react-query";
import {
  GetBuylistReportQuery,
  GetBuylistReportQueryVariables,
  useGetCompletedBuylistReportQuery,
} from "../../generated/reporting";
import { formatCompletedBuylistReport } from "../../transformers/reports/formatCompletedBuylistReport";
import { CompletedBuylistData } from "../../types/reports";

export const useFetchCompletedBuylistReport = (
  variables: GetBuylistReportQueryVariables,
  options: UseQueryOptions<
    GetBuylistReportQuery,
    unknown,
    CompletedBuylistData[],
    QueryKey
  > = {}
) =>
  useGetCompletedBuylistReportQuery(variables, {
    select: (report) => formatCompletedBuylistReport(report),
    keepPreviousData: true,
    staleTime: 60_000,
    ...options,
  });
