// Would be good to move these to API in future
export const gameLogos = {
  "Magic: The Gathering": "https://cdn.binderpos.com/catalog-logos/mtg.png",
  Pokémon: "https://cdn.binderpos.com/catalog-logos/pkm.png",
  "Yu-Gi-Oh!": "https://cdn.binderpos.com/catalog-logos/ygo.png",
  "Dragon Ball Super": "https://cdn.binderpos.com/catalog-logos/dbs.png",
  "Flesh and Blood": "https://cdn.binderpos.com/catalog-logos/fab.png",
  Metazoo: "https://cdn.binderpos.com/catalog-logos/mtz.png",
  Digimon: "https://cdn.binderpos.com/catalog-logos/dig.png",
  "My Hero Academia": "https://cdn.binderpos.com/catalog-logos/mha.png",
  "Weiss Schwarz": "https://cdn.binderpos.com/catalog-logos/wss.png",
};
