import React from "react";
import { forceDownloadFromData } from "../../helpers/fileHelpers";
import { useFetchCartById } from "../../hooks/useFetchCartById";
import { convertToCsv } from "../../utils/data";
import ButtonComponent from "../generic/ButtonComponent";

interface ExportCartProps {
  cartId: number;
  headers: Array<Object>;
  children: React.ReactNode;
}

type ExportCartButtonProps = ExportCartProps &
  React.ComponentProps<typeof ButtonComponent>;

function ExportCartButton(props: ExportCartButtonProps) {
  const { cartId, headers, children, ...rest } = props;

  const saveCartAsCsv = (data: any) => {
    const filename = `cart_${cartId}.csv`;
    convertToCsv(headers, data.cart.cartItems).then((csv) =>
      forceDownloadFromData(csv, filename, "text/csv")
    );
  };

  const { isLoading, refetch } = useFetchCartById(cartId, {
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });

  const handleSaveClick = () =>
    refetch().then(({ data }) => saveCartAsCsv(data));

  return (
    <ButtonComponent onClick={handleSaveClick} disabled={isLoading} {...rest}>
      {children}
    </ButtonComponent>
  );
}

export default ExportCartButton;
