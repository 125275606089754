import React, { useEffect, useState } from "react";
import {
  PAYMENT_GATEWAY_USAEPAY,
  PAYMENT_GATEWAY_MXMERCHANT,
} from "../../constants/featureFlags";
import { findTillSettingValue } from "../../helpers/settingHelpers";
import { PaymentProvider } from "../../types/paymentTerminal";
import { TillSetting } from "../../types/settings";
import PaxSettings from "../../components/posSettings/PaxSettings";
import PaymentGatewaySettings from "./PaymentGatewaySettings";
import "./PaymentTerminalSettings.scss";
import NoPaymentTerminalSettings from "./NoPaymentTerminalSettings";
import { useFeatureFlag } from "../../hooks/globalSettingsHooks";
import { segmentAnalytics } from "../services/Analytics";
import { PAX_TERMINAL_SETTINGS_SUBMENU_CLICKED } from "../../constants/eventsTracked";

const getInitialSetting = (tillSettings: TillSetting[]) =>
  findTillSettingValue(tillSettings, "paymentProvider") as
    | PaymentProvider
    | undefined;

interface PaymentTerminalSettingsProps {
  tillId: number;
  tillSettings: TillSetting[];
}

function PaymentTerminalSettings(props: PaymentTerminalSettingsProps) {
  const { tillId, tillSettings } = props;
  const [selectedProvider, setSelectedProvider] = useState<
    PaymentProvider | ""
  >();

  const usaePayEnabled = useFeatureFlag(PAYMENT_GATEWAY_USAEPAY);
  const mxMerchantEnabled = useFeatureFlag(PAYMENT_GATEWAY_MXMERCHANT);

  const availablePaymentProviders = [
    <option value="" key="none">
      No payment terminal
    </option>,
    <option value="PAX" key="PAX">
      PAX S300
    </option>,
    usaePayEnabled ? (
      <option value="USAePay" key="USAePay">
        GPSpay Gateway
      </option>
    ) : null,
    mxMerchantEnabled ? (
      <option value="mxMerchant" key="mxMerchant">
        MX Merchant Gateway
      </option>
    ) : null,
  ].filter(Boolean);

  useEffect(
    () => setSelectedProvider(getInitialSetting(tillSettings)),
    [tillSettings]
  );

  const handleConnectionTypeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (event.target.value === "PAX") {
      segmentAnalytics.track(PAX_TERMINAL_SETTINGS_SUBMENU_CLICKED);
    }
    setSelectedProvider(event.target.value as PaymentProvider);
  };

  return (
    <div className="PaymentTerminalSettings">
      <label>
        Select your payment terminal's connection type:
        <select value={selectedProvider} onChange={handleConnectionTypeChange}>
          {availablePaymentProviders}
        </select>
      </label>
      {selectedProvider === "" ? (
        <NoPaymentTerminalSettings tillId={tillId} />
      ) : null}
      {selectedProvider === "PAX" ? (
        <PaxSettings tillId={tillId} tillSettings={tillSettings} />
      ) : null}
      {selectedProvider === "USAePay" ? (
        <PaymentGatewaySettings
          paymentProvider="USAePay"
          tillId={tillId}
          tillSettings={tillSettings}
        />
      ) : null}
      {selectedProvider === "mxMerchant" ? (
        <PaymentGatewaySettings
          paymentProvider="mxMerchant"
          tillId={tillId}
          tillSettings={tillSettings}
        />
      ) : null}
    </div>
  );
}

export default PaymentTerminalSettings;
