import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "../views/reports/Home";
import CompletedBuylist from "../views/reports/CompletedBuylist";
import StoreCreditHome from "../views/reports/StoreCreditHome";
import StockHome from "../views/reports/StockHome";
import BuylistsHome from "../views/reports/BuylistsHome";
import SalesHome from "../views/reports/SalesHome";
import Buylist from "../views/reports/Buylist";
import StoreCreditByCustomer from "../views/reports/StoreCreditByCustomer";
import StoreCreditTransactions from "../views/reports/StoreCreditTransactions";
import StoreCreditOutstanding from "../views/reports/StoreCreditOutstanding";
import StockValue from "../views/reports/StockValue";
import WPNReport from "../views/reports/WPNReport";
import FloatReport from "../views/reports/FloatReport";
import EndOfDay from "../views/reports/EndOfDay";
import CartNotes from "../views/reports/CartNotes";
import SubmittedCarts from "../views/reports/SubmittedCarts";
import SalesByOrderBeta from "../views/reports/SalesByOrderBeta";
import SalesByTender from "../views/reports/SalesByTender";
import SalesByTenderBeta from "../views/reports/SalesByTenderBeta";
import TotalSalesByCategory from "../views/reports/TotalSalesByCategory";
import ReportsProvider from "../providers/ReportsProvider";

function ReportsRouter() {
  return (
    <ReportsProvider>
      <Switch>
        <Route exact path="/reports/sales" component={SalesHome} />
        <Route exact path="/reports/sales/tender" component={SalesByTender} />
        <Route
          exact
          path="/reports/sales/tender-beta"
          component={SalesByTenderBeta}
        />
        <Route
          exact
          path="/reports/sales/order-beta"
          component={SalesByOrderBeta}
        />
        <Route
          exact
          path="/reports/sales/category"
          component={TotalSalesByCategory}
        />
        <Route exact path="/reports/sales/carts" component={SubmittedCarts} />
        <Route exact path="/reports/sales/cart-notes" component={CartNotes} />
        <Route exact path="/reports/sales/float" component={FloatReport} />
        <Route exact path="/reports/sales/wpn" component={WPNReport} />
        <Route exact path="/reports/sales/end-of-day" component={EndOfDay} />
        <Route exact path="/reports/stock" component={StockHome} />
        <Route exact path="/reports/stock/value" component={StockValue} />
        <Route exact path="/reports/buylist" component={Buylist} />
        <Route exact path="/reports/buylists" component={BuylistsHome} />
        <Route exact path="/reports/buylists/all" component={Buylist} />
        <Route
          exact
          path="/reports/buylists/completed"
          component={CompletedBuylist}
        />
        <Route exact path="/reports/store-credit" component={StoreCreditHome} />
        <Route
          exact
          path="/reports/store-credit/transactions"
          component={StoreCreditTransactions}
        />
        <Route
          exact
          path="/reports/store-credit/outstanding"
          component={StoreCreditOutstanding}
        />
        <Route
          exact
          path="/reports/store-credit/customer"
          component={StoreCreditByCustomer}
        />
        <Route path="/reports" component={Home} />
      </Switch>
    </ReportsProvider>
  );
}

export default ReportsRouter;
