import React from "react";
import Modal from "../generic/Modal";

interface ViewNotesProps {
  notes?: string;
  onClose: VoidFunction;
}

function ViewNotes(props: ViewNotesProps, ref: React.MutableRefObject<any>) {
  const { notes, onClose } = props;

  return (
    <Modal onClose={onClose} ref={ref}>
      <Modal.Header>View Notes</Modal.Header>
      <Modal.Content>{notes}</Modal.Content>
    </Modal>
  );
}

export default React.forwardRef(ViewNotes);
