import * as Yup from "yup";

const isEmpty = (value: string) => {
  if (!value) return true;
  if (value.length === 0) return true;
  return false;
};

export const customerSchema = Yup.object().shape(
  {
    firstName: Yup.string().when(["email", "phone"], {
      is: (email: string, phone: string) => isEmpty(email) && isEmpty(phone),
      then: Yup.string().required(
        "At least a first name, email or phone number is required"
      ),
    }),
    lastName: Yup.string(),
    email: Yup.string()
      .email("Invalid email")
      .when(["firstName", "phone"], {
        is: (firstName: string, phone: string) =>
          isEmpty(firstName) && isEmpty(phone),
        then: Yup.string().required(
          "At least a first name, email or phone number is required"
        ),
      }),
    phone: Yup.string().when(["firstName", "email"], {
      is: (firstName: string, email: string) =>
        isEmpty(firstName) && isEmpty(email),
      then: Yup.string().required(
        "At least a first name, email or phone number is required"
      ),
    }),
    notes: Yup.string(),
  },
  [
    ["firstName", "phone"],
    ["firstName", "email"],
    ["email", "phone"],
  ]
);
