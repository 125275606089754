import { createContext, useEffect, useMemo, useState } from "react";
import { filterMenuItems } from "../helpers/navHelpers";
import { useLogErrors } from "../hooks/errorHooks";
import { useActiveFeatureFlags } from "../hooks/globalSettingsHooks";
import { useFetchScreenSettings, useFetchSetting } from "../hooks/settingHooks";
import { mainMenuStructure } from "../menuStructures/mainMenuStructure";
import { MenuLink, RootMenuItem } from "../types/nav";
import { useFetchAvailableIntegrations } from "../hooks/integrationHooks";
import { ALLOW_CATALOG_CHANGES } from "../constants/settings";

interface MainMenuContextProps {
  menuItems: RootMenuItem[];
}

export const MenuContext = createContext<MainMenuContextProps>(null);

interface MenuProviderProps {
  children: React.ReactNode;
}

function MenuProvider({ children }: MenuProviderProps) {
  const [dynamicItems, setDynamicItems] = useState<Record<string, MenuLink[]>>(
    {}
  );
  const activeFeatureFlags = useActiveFeatureFlags();
  const { data: screenSettings, error: screenSettingsError } =
    useFetchScreenSettings();
  const { data: allowCatalogChanges } = useFetchSetting(ALLOW_CATALOG_CHANGES);
  const enhancedScreenSettings = { ...screenSettings, allowCatalogChanges };

  const { data: availableIntegrations, error: integrationsError } =
    useFetchAvailableIntegrations();
  useLogErrors(screenSettingsError);
  useLogErrors(integrationsError);

  useEffect(() => {
    if (availableIntegrations) {
      setDynamicItems((prev) => ({
        ...prev,
        integrations: availableIntegrations.map((integration) => ({
          title: integration.syncFriendlyName,
          href: `integrations/${integration.syncName}`,
        })),
      }));
    }
  }, [availableIntegrations]);

  const menuItems = filterMenuItems(
    mainMenuStructure,
    activeFeatureFlags,
    enhancedScreenSettings
  ).map((menuItem) => {
    const filteredItem = { ...menuItem };
    if (filteredItem.subMenu?.links) {
      filteredItem.subMenu = {
        ...filteredItem.subMenu,
        links: [
          ...filterMenuItems(
            menuItem.subMenu.links,
            activeFeatureFlags,
            enhancedScreenSettings
          ),
          ...(dynamicItems[filteredItem.appendDynamicItems] ?? []),
        ],
      };
    }
    return filteredItem;
  });

  const contextValue = useMemo<MainMenuContextProps>(
    () => ({ menuItems }),
    [activeFeatureFlags, screenSettings, dynamicItems]
  );

  return (
    <MenuContext.Provider value={contextValue}>{children}</MenuContext.Provider>
  );
}

export default MenuProvider;
