import React from "react";
import IconButton from "../IconButton";

interface CloseButtonProps {
  plain?: boolean;
  onClose?: () => void;
}

function CloseButton(
  props: CloseButtonProps,
  ref: React.MutableRefObject<any>
) {
  const { plain = false, onClose = undefined } = props;

  if (!onClose) {
    return null;
  }

  if (plain) {
    return (
      <IconButton
        ref={ref}
        icon="fal fa-times"
        className="NewModal__close-button"
        aria-label="Close dialog"
        onClick={onClose}
      />
    );
  }

  return (
    <button
      ref={ref}
      type="button"
      className="NewModal__close-button"
      aria-label="Close dialog"
      onClick={onClose}
    >
      <i className="fal fa-times" />
      <span className="NewModal__close-button-text"> Close</span>
    </button>
  );
}

export default React.forwardRef(CloseButton);
