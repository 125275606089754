import React from "react";
import { Formik, Form, Field } from "formik";
import { notify } from "../../helpers/notificationHelpers";
import { useFetchSetting, useUpdateSetting } from "../../hooks/settingHooks";
import Input from "../form/Input";
import ButtonComponent from "../../components/generic/ButtonComponent";
import Spinner from "../generic/Spinner";
import InfoPanel from "../generic/InfoPanel";
import {
  DATE_FORMAT_FIELD_CLICKED,
  SAVE_EVENT_SETTINGS_CLICKED,
  CUSTOM_DATE_FORMAT_HELPER_DOC_CLICKED,
} from "../../constants/eventsTracked";
import { segmentAnalytics } from "../services/Analytics";
import "./EventDateFormatSetting.scss";

type Setting = { setting: string };

const settingName = "eventDateFormat";

function EventDateFormatSetting() {
  const { data, isLoading } = useFetchSetting(settingName);
  const { mutateAsync, isMutating } = useUpdateSetting();

  const onSubmit = (values: Setting) =>
    mutateAsync({
      settingName,
      settingValue: values.setting,
    })
      .then(() => notify.info("Setting updated"))
      .catch(() => {
        notify.error("Failed to save setting. Please try again");
      });

  return (
    <Formik
      enableReinitialize
      initialValues={{ setting: data || "" }}
      onSubmit={onSubmit}
    >
      {({ values }) => (
        <Form>
          <div className="EventDateFormatSetting">
            <Spinner isLoading={isLoading}>
              <Field
                component={Input}
                name="setting"
                type="text"
                label="Date Format"
                onFocus={() =>
                  segmentAnalytics.track(DATE_FORMAT_FIELD_CLICKED)
                }
              />
            </Spinner>
            <ButtonComponent
              primary
              type="submit"
              disabled={isMutating}
              segmentEventName={SAVE_EVENT_SETTINGS_CLICKED}
              segmentEventProperties={{
                date_format: values.setting,
              }}
            >
              Save
            </ButtonComponent>
          </div>
          <br />
          <InfoPanel>
            Set a custom format for dates when they appear in event titles. The
            field uses the format{" "}
            <a
              href="https://docs.oracle.com/javase/8/docs/api/java/time/format/DateTimeFormatter.html#patterns"
              target="_blank"
              rel="noopner"
              onClick={() => {
                segmentAnalytics.track(CUSTOM_DATE_FORMAT_HELPER_DOC_CLICKED);
              }}
            >
              described in this document
            </a>
            . By default, the format is: <pre>EEE, MMM dd yyyy</pre> which looks
            like: <pre>Tue, Feb 15 2022</pre>. Note, it is not recommended to
            include the time in title from this. It is possible, but won’t use
            the event time unfortunately.
          </InfoPanel>
        </Form>
      )}
    </Formik>
  );
}

export default EventDateFormatSetting;
