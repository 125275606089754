import React, { useEffect, useRef, useState } from "react";
import ButtonComponent from "../../components/generic/ButtonComponent";
import ConfirmationModal from "../../components/generic/ConfirmationModal";
import { Till } from "../../types/pos";
import "./Till.scss";
import { segmentAnalytics } from "../../components/services/Analytics";
import { DELETE_TILL_CLICKED } from "../../constants/eventsTracked";
import { notify } from "../../helpers/notificationHelpers";
import { useShowError } from "../../hooks/errorHooks";
import {
  useArchiveTill,
  useDisableTill,
  useEnableTill,
} from "../../hooks/tillHooks";

interface TillProps {
  till: Till;
}

function TillComponent(props: TillProps) {
  const { till } = props;
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const viewRef = useRef<HTMLElement>();
  const modalRef = useRef<HTMLDivElement>();
  const { mutateAsync: archiveTill, isMutating: isArchiving } =
    useArchiveTill();
  const { mutateAsync: disableTill, isMutating: isDisabling } =
    useDisableTill();
  const { mutateAsync: enableTill, isMutating: isEnabling } = useEnableTill();
  const showError = useShowError();

  const handleArchiveTillClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    viewRef.current = event.currentTarget;
    setShowConfirmationModal(true);
  };

  const handleCancelArchiveClick = () => setShowConfirmationModal(false);

  const handleArchiveTill = async () => {
    segmentAnalytics.track(DELETE_TILL_CLICKED);
    return archiveTill(till.id)
      .then(() => {
        notify.info(`${till.name} has been archived`);
        setShowConfirmationModal(false);
      })
      .catch((error: DetailedError) => {
        showError(
          error,
          "Failed to archive till",
          "There was an error retrieving your till setting. Please try again"
        );
      });
  };

  const handleDisableTillClick = () =>
    disableTill(till.id)
      .then(() => notify.info("Till has been disabled"))
      .catch((error: DetailedError) => {
        showError(
          error,
          "Failed to disable till",
          "There was an error updating your till setting. Please try again"
        );
      });

  const handleEnableTillClick = () =>
    enableTill(till.id)
      .then(() => notify.info("Till has been enabled"))
      .catch((error: DetailedError) => {
        showError(
          error,
          "Failed to enable till",
          "There was an error updating your till setting. Please try again"
        );
      });

  useEffect(() => {
    if (showConfirmationModal) {
      modalRef.current?.focus();
    } else {
      viewRef.current?.focus();
    }
  }, [showConfirmationModal]);

  return (
    <>
      <div className="Till">
        <div className="Till__header">
          <span className="Till__image">
            <i className="fas fa-credit-card" />
          </span>
          <div>
            <h3 className="Till__name">{till.name}</h3>
            <p className="Till__status">
              {till.active ? "Enabled" : "Disabled"}
            </p>
          </div>
        </div>
        <div className="Till__details">
          <p className="Till__detail">
            <span className="Till__label">Till Description: </span>
            {till.description}
          </p>
          <p className="Till__detail">
            <span className="Till__label">Till ID: </span>
            {till.id}
          </p>
        </div>
        <div className="Till__actions">
          {till.active ? (
            <ButtonComponent
              secondary
              halfWidth
              disabled={isDisabling}
              onClick={handleDisableTillClick}
              icon="fas fa-eye pause"
              iconPosition="left"
            >
              Till enabled
            </ButtonComponent>
          ) : (
            <ButtonComponent
              secondary
              halfWidth
              disabled={isEnabling}
              onClick={handleEnableTillClick}
              icon="fas fa-eye-slash"
              iconPosition="left"
            >
              Till disabled
            </ButtonComponent>
          )}
          <ButtonComponent
            primary
            halfWidth
            onClick={handleArchiveTillClick}
            icon="fas fa-archive"
            iconPosition="left"
          >
            Delete till
          </ButtonComponent>
        </div>
      </div>
      {showConfirmationModal ? (
        <ConfirmationModal
          title="Archive till?"
          cancelAction={handleCancelArchiveClick}
          confirmAction={handleArchiveTill}
          isLoading={isArchiving}
          ref={modalRef}
        >
          Are you sure you want to delete till: {till.name}
        </ConfirmationModal>
      ) : null}
    </>
  );
}

export default TillComponent;
