import React, { useState } from "react";
import { doPushStoreCredit } from "../../api/rest/buylist";
import { notify } from "../../helpers/notificationHelpers";
import ConfirmationModal from "../generic/ConfirmationModal";
import ButtonComponent from "../generic/ButtonComponent";

interface PushStoreCreditProps {
  buylistId: number;
  paymentType: string;
  applyStoreCredit?: boolean;
}

function PushStoreCredit(props: PushStoreCreditProps) {
  const { buylistId, applyStoreCredit, paymentType } = props;
  const [isPushing, setIsPushing] = useState(false);
  const [hasPushed, setHasPushed] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleConfirmClick = () => {
    setShowConfirmation(false);
    setIsPushing(true);
    doPushStoreCredit(buylistId)
      .then(() => {
        setIsPushing(false);
        setHasPushed(true);
        notify.info("Store Credit has been pushed successfully");
      })
      .catch(() => {
        notify.error("Failed to push Store Credit");
        setIsPushing(false);
      });
  };

  const handleCancelClick = () => setShowConfirmation(false);

  const handleUncompleteClick = () => setShowConfirmation(true);

  if (applyStoreCredit !== false) {
    return null;
  }

  if (paymentType !== "Store Credit") {
    return null;
  }

  return (
    <>
      <ButtonComponent
        secondary
        onClick={() => handleUncompleteClick()}
        disabled={isPushing || hasPushed}
      >
        {isPushing ? "Pushing..." : "Push Store Credit"}
      </ButtonComponent>
      {showConfirmation ? (
        <ConfirmationModal
          cancelAction={handleCancelClick}
          confirmAction={handleConfirmClick}
          title="Push StoreCredit"
        >
          Are you sure you want to push the store credit for this buylist
        </ConfirmationModal>
      ) : null}
    </>
  );
}

export default PushStoreCredit;
