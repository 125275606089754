import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field } from "formik";
import { notify } from "../../helpers/notificationHelpers";
import { useShowError } from "../../hooks/errorHooks";
import {
  useRegisterDevice,
  useFetchDeviceDetails,
} from "../../hooks/paymentTerminalHooks";
import { paymentGatewayPairDeviceSettingsSchema } from "../../schemas/pointOfSaleSettings";
import { PaymentProvider, TerminalResponse } from "../../types/paymentTerminal";
import Input from "../form/Input";
import ButtonComponent from "../generic/ButtonComponent";
import MxMerchantTerminalInstructions from "./MxMerchantTerminalInstructions";
import MxMerchantTerminalOptions from "./MxMerchantTerminalOptions";
import "./PaymentGatewayRegisterDevice.scss";

const POLL_INTERVAL = 1_400; // ms

const getDeviceStatus = (deviceDetails: TerminalResponse) => {
  if (deviceDetails && "status" in deviceDetails) {
    return deviceDetails.status;
  }
  return undefined;
};

type FormValues = {
  name: string;
  description?: string;
  entryModes?: Record<string, boolean>;
  paymentMethods?: Record<string, boolean>;
  externalSource: string;
  externalId: string;
  key: {
    activationCode: string;
    authKey: string;
  };
  providerKey?: string;
};

const initialValues = {
  name: "",
  description: "",
  entryModes: {
    swipe: true,
    insert: true,
    tap: true,
    pinpad: true,
  },
  paymentMethods: { credit: true, debit: true },
  providerKey: "anywherecommerce",
  key: {
    activationCode: "",
    authKey: "",
  },
  externalSource: "",
  externalId: "",
};

interface PaymentGatewayRegisterDeviceProps {
  tillId: number;
  paymentProvider: PaymentProvider;
  closeModal: () => void;
}

function PaymentGatewayRegisterDevice(
  props: PaymentGatewayRegisterDeviceProps
) {
  const { tillId, closeModal, paymentProvider } = props;
  const [deviceKey, setDeviceKey] = useState<string>();
  const [pairingCode, setPairingCode] = useState<number>();
  const [activationCode, setActivationCode] = useState<string>();
  const [vendorKey, setVendorKey] = useState<number>();
  const detailsInterval = useRef<NodeJS.Timer>();
  const showError = useShowError();
  const { mutateAsync: registerDevice } = useRegisterDevice();
  const {
    data: deviceDetails,
    error,
    refetch,
  } = useFetchDeviceDetails(tillId, deviceKey);
  const deviceStatus = getDeviceStatus(deviceDetails);

  useEffect(() => {
    if (deviceKey) {
      detailsInterval.current = setInterval(refetch, POLL_INTERVAL);
    }
    return () => {
      if (detailsInterval.current) {
        clearInterval(detailsInterval.current);
      }
    };
  }, [deviceKey]);

  useEffect(() => {
    if (deviceStatus === "connected") {
      closeModal();
    }
  }, [deviceStatus]);

  useEffect(() => {
    if (error) {
      showError(
        error as DetailedError,
        "Failed to check device pairing status",
        "Please check your API credentials and try again"
      );
    }
  }, [error]);

  const handleSubmit = ({
    name,
    description,
    entryModes,
    paymentMethods,
    providerKey,
    key,
  }: FormValues) =>
    registerDevice({
      tillId,
      name,
      description,
      entryModes,
      paymentMethods,
      providerKey,
      key,
    })
      .then((data) => {
        if ("key" in data && "pairing_code" in data) {
          // Start USAePay pairing process
          setDeviceKey(data?.key);
          setPairingCode(data?.pairing_code);
        } else if ("provider" in data && "terminal" in data.provider) {
          // Show MxMerchant pairing instructions
          setActivationCode(data.provider.terminal.activationCode);
          setVendorKey(data.provider.terminal.vendorKey);
        } else {
          notify.info("Device added");
          closeModal();
        }
      })
      .catch((registerError) =>
        showError(
          registerError,
          "Failed to register new device",
          "Please check your API credentials and try again"
        )
      );

  if (deviceKey) {
    return (
      <div className="PaymentGatewayRegisterDevice">
        <div className="PaymentGatewayRegisterDevice__modal">
          <div className="PaymentGatewayRegisterDevice__layout">
            <div className="PaymentGatewayRegisterDevice__content">
              <h3>Waiting for pairing</h3>
              <p className="PaymentGatewayRegisterDevice__guidance">
                Please enter the following pairing code on your device.
              </p>
            </div>
            <p className="PaymentGatewayRegisterDevice__pairingCode">
              {pairingCode}
            </p>
            <div className="PaymentGatewayRegisterDevice__actions">
              <ButtonComponent onClick={closeModal} fullWidth>
                Cancel
              </ButtonComponent>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (activationCode && vendorKey) {
    return (
      <div className="PaymentGatewayRegisterDevice">
        <div className="PaymentGatewayRegisterDevice__modal">
          <div className="PaymentGatewayRegisterDevice__layout">
            <MxMerchantTerminalInstructions
              activationCode={activationCode}
              vendorKey={vendorKey}
              handleClose={closeModal}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="PaymentGatewayRegisterDevice">
      <div className="PaymentGatewayRegisterDevice__modal">
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={paymentGatewayPairDeviceSettingsSchema}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form>
              <div className="PaymentGatewayRegisterDevice__layout">
                <div className="PaymentGatewayRegisterDevice__content">
                  <h3>Register new device</h3>
                  <p className="PaymentGatewayRegisterDevice__guidance">
                    Please ensure your payment terminal is powered on and
                    connected to WiFi.
                  </p>
                  <p className="PaymentGatewayRegisterDevice__guidance">
                    Enter a name for your device (this is how it will be named
                    when selecting a device) and click Register.
                  </p>
                  <Field component={Input} name="name" label="Device name" />
                  {paymentProvider === "mxMerchant" ? (
                    <MxMerchantTerminalOptions
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  ) : null}
                </div>
                <div className="PaymentGatewayRegisterDevice__actions">
                  <ButtonComponent
                    primary
                    type="submit"
                    halfWidth
                    disabled={isSubmitting}
                  >
                    Register
                  </ButtonComponent>
                  <ButtonComponent onClick={closeModal} halfWidth>
                    Cancel
                  </ButtonComponent>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default PaymentGatewayRegisterDevice;
