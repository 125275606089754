import { useContext } from "react";
import { useQuery } from "react-query";
import { IntegrationContext } from "../providers/IntegrationProvider";
import { fetchAvailableIntegrations } from "../api/rest/integrations";

const HOUR_LONG_LIFE_DATA_STALE_TIME = 60 * 60 * 1000; // ms

export const useIntegrations = () => {
  const context = useContext(IntegrationContext);
  if (context === undefined) {
    throw new Error("useIntegrations must be used within IntegrationProvider");
  }
  const {
    availableIntegrations,
    ebayDefaultMarketplace,
    setEbayDefaultMarketplace,
  } = context;
  return {
    availableIntegrations,
    ebayDefaultMarketplace,
    setEbayDefaultMarketplace,
  };
};

export const useIntegrationFriendlyName = (syncName: string) => {
  const context = useContext(IntegrationContext);
  if (context === undefined) {
    throw new Error(
      "useAvailableIntegrations must be used within IntegrationProvider"
    );
  }
  const { availableIntegrations } = context;
  const matchingIntegration = availableIntegrations.find(
    (integration: IntegrationDetails) => integration.syncName === syncName
  );
  if (!matchingIntegration?.syncFriendlyName) return syncName;
  return matchingIntegration.syncFriendlyName;
};

export const useFetchAvailableIntegrations = () =>
  useQuery("availableIntegrations", fetchAvailableIntegrations, {
    staleTime: HOUR_LONG_LIFE_DATA_STALE_TIME,
  });
