import React, { InputHTMLAttributes } from "react";
import "./RadioButton.scss";

interface RadioButtonProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  compact?: boolean;
  editing?: boolean;
  field?: InputHTMLAttributes<HTMLInputElement>;
}

const getIcon = (checked: boolean) =>
  checked ? (
    <i className="far fa-dot-circle" />
  ) : (
    <i className="far fa-circle" />
  );

const getClass = (checked: boolean, compact: boolean) => {
  let className = compact ? "RadioButtonCompact" : "RadioButton";

  if (checked && !compact) {
    className += " RadioButton--checked";
  }

  return className;
};

function RadioButton(props: RadioButtonProps) {
  const {
    name,
    label,
    value,
    compact = false,
    checked = undefined,
    onChange = undefined,
    editing = true,
    field,
    ...rest
  } = props;

  if (field) {
    const {
      name: fieldName,
      checked: fieldChecked,
      onChange: fieldOnChange,
      ...inputProps
    } = field;

    return (
      <label htmlFor={name} className={getClass(checked, compact)}>
        <input
          name={fieldName}
          value={value}
          type="radio"
          className={`${compact ? "" : "RadioButton__input"}`}
          checked={checked ?? fieldChecked}
          onChange={onChange ?? fieldOnChange}
          disabled={!editing}
          {...rest}
          {...inputProps}
        />
        {compact ? null : (
          <span className="RadioButton__checkbox">{getIcon(checked)}</span>
        )}
        <span
          className={`RadioButton__label${
            compact ? " RadioButton__label--compact" : ""
          }`}
        >
          {label}
        </span>
      </label>
    );
  }

  return (
    <label className={getClass(checked, compact)}>
      <input
        name={name}
        value={value}
        type="radio"
        className={`${compact ? "" : "RadioButton__input"}`}
        onChange={onChange}
        checked={checked}
        disabled={!editing}
        {...rest}
      />
      {compact ? null : (
        <span className="RadioButton__checkbox">{getIcon(checked)}</span>
      )}
      <span
        className={`RadioButton__label${
          compact ? " RadioButton__label--compact" : ""
        }`}
      >
        {label}
      </span>
    </label>
  );
}

export default RadioButton;
