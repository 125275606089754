import React, { useEffect } from "react";
import {
  useLocalSetting,
  useLocalSettingUpdate,
} from "../../hooks/localSettingsHooks";
import "./FilterVariantsSelect.scss";
import { segmentAnalytics } from "../services/Analytics";
import { COLLAPSE_VARIANTS_SELECTED } from "../../constants/eventsTracked";

const SETTING_NAME = "collapseProductVariants";

const parseSetting = (settingValue: unknown) => {
  // Handle legacy setting value
  if (settingValue === true) {
    return { defaultCollapse: true, defaultHideNoStock: false };
  }
  if (settingValue === "all") {
    return { defaultCollapse: true, defaultHideNoStock: false };
  }
  if (settingValue === "outOfStock") {
    return { defaultCollapse: false, defaultHideNoStock: true };
  }
  return { defaultCollapse: false, defaultHideNoStock: false };
};

const generateSetting = (
  defaultCollapse: boolean,
  defaultHideNoStock: boolean
) => {
  if (defaultCollapse) {
    return "all";
  }
  if (defaultHideNoStock) {
    return "outOfStock";
  }
  return "none";
};

export const filterVariantsLabelMapping = {
  none: "Show all variants",
  outOfStock: "Show in-stock only",
  all: "Collapse variants",
};

interface FilterVariantsSelectProps {
  collapseVariants: boolean;
  hideOutOfStock: boolean;
  setCollapseVariants: (collapse: boolean) => void;
  setHideOutOfStock: (hide: boolean) => void;
  applyToAllProducts?: boolean;
}

function FilterVariantsSelect(props: FilterVariantsSelectProps) {
  const {
    collapseVariants,
    hideOutOfStock,
    setCollapseVariants,
    setHideOutOfStock,
    applyToAllProducts = false,
  } = props;
  const setting = useLocalSetting(SETTING_NAME);

  const defaultValues = applyToAllProducts
    ? parseSetting(setting)
    : { defaultCollapse: collapseVariants, defaultHideNoStock: hideOutOfStock };
  const { defaultCollapse, defaultHideNoStock } = defaultValues;
  const updateLocalSetting = useLocalSettingUpdate();

  useEffect(() => {
    setCollapseVariants(defaultCollapse);
    const newSetting = generateSetting(defaultCollapse, defaultHideNoStock);
    if (applyToAllProducts) {
      updateLocalSetting(SETTING_NAME, newSetting);
    }
  }, [defaultCollapse, defaultHideNoStock]);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    const { defaultCollapse: collapse, defaultHideNoStock: hideNoStock } =
      parseSetting(value);
    setCollapseVariants(collapse);
    setHideOutOfStock(hideNoStock);
    if (applyToAllProducts) {
      updateLocalSetting(SETTING_NAME, value);
    }
  };

  const className = applyToAllProducts
    ? "FilterVariantsSelect FilterVariantsSelect--header"
    : "FilterVariantsSelect";

  return (
    <select
      aria-label="Variants Filter"
      className={className}
      onChange={(event) => {
        segmentAnalytics.track(COLLAPSE_VARIANTS_SELECTED, {
          collapse_option: collapseVariants ? "hide" : "show",
          dropdown_type: filterVariantsLabelMapping[event.target.value],
        });
        handleChange(event);
      }}
      value={generateSetting(collapseVariants, hideOutOfStock)}
    >
      <option value="none">Show all variants</option>
      <option value="outOfStock">Show in-stock only</option>
      {applyToAllProducts ? (
        <option value="all">Collapse variants</option>
      ) : null}
    </select>
  );
}

export default FilterVariantsSelect;
