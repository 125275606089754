import React, { Fragment, useEffect } from "react";
import { ReportsMenuStructure } from "../../menuStructures";
import SetActiveMenu from "../../menuStructures/SetActiveMenu";
import { ButtonComponent } from "../../components";
import { SUBMENU_ITEM_CLICKED } from "../../constants/eventsTracked";

function Home() {
  useEffect(() => {
    document.title = "Events | BinderPOS";
  }, []);

  return (
    <Fragment>
      <SetActiveMenu menuStructure={ReportsMenuStructure} />
      <div className="app-header">
        <h1 className="title">
          <span className="header-text">
            Reports<span className="beta">BETA</span>
          </span>
        </h1>
      </div>
      <div className="reports-menu row">
        <div className="col-sm-6">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title">Sales Reports</h2>
              <p className="card-text">
                View sales data grouped by various parameters, such as category
                and tender type. Generate WPN reports.
              </p>
              <ButtonComponent
                primary
                to="/reports/sales"
                segmentEventName={SUBMENU_ITEM_CLICKED("Reports")}
                segmentEventProperties={{ menu_title: "Sales Reports" }}
                fullWidth
                compact
                isLink
              >
                View Sales Reports
              </ButtonComponent>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title">Stock Reports</h2>
              <p className="card-text">View reports of stock values.</p>
              <ButtonComponent
                primary
                to="/reports/stock"
                segmentEventName={SUBMENU_ITEM_CLICKED("Reports")}
                segmentEventProperties={{ menu_title: "Stock Reports" }}
                fullWidth
                compact
                isLink
              >
                View Stock Reports
              </ButtonComponent>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title">Store Credit Reports</h2>
              <p className="card-text">
                View store credit spending and amounts outstanding.
              </p>
              <ButtonComponent
                primary
                to="/reports/store-credit"
                segmentEventName={SUBMENU_ITEM_CLICKED("Reports")}
                segmentEventProperties={{ menu_title: "Store Credit Reports" }}
                fullWidth
                compact
                isLink
              >
                View Store Credit Reports
              </ButtonComponent>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title">Online Buylist Reports</h2>
              <p className="card-text">View online buylist data by date.</p>
              <ButtonComponent
                primary
                to="/reports/buylists"
                segmentEventName={SUBMENU_ITEM_CLICKED("Reports")}
                segmentEventProperties={{
                  menu_title: "Online Buylist Reports",
                }}
                fullWidth
                compact
                isLink
              >
                View Online Buylist Reports
              </ButtonComponent>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Home;
