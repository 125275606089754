import React from "react";
import Modal from "../generic/Modal";
import "./EbayConditionMappingModal.scss";

interface EbayConditionMappingModalProps {
  showModal: boolean;
  closeModal: VoidFunction;
}

const ebayConditionMappingColumns = [
  { header: "BinderPOS Condition", accessorKey: "binder" },
  {
    header: "maps to",
    accessorKey: "mapsTo",
    cell: () => <i className="fal fa-arrow-right" />,
  },
  { header: "eBay Condition", accessorKey: "ebay" },
];

const ebayConditionMappingRows = [
  { binder: "Near Mint", mapsTo: null, ebay: "Near Mint or Better" },
  {
    binder: "Lightly Played",
    mapsTo: null,
    ebay: "Lightly Played (Excellent)",
  },
  {
    binder: "Moderately Played",
    mapsTo: null,
    ebay: "Moderately Played (Very Good)",
  },
  {
    binder: "Heavily Played",
    mapsTo: null,
    ebay: "Heavily Played (Poor)",
  },
  {
    binder: "Damaged",
    mapsTo: null,
    ebay: "No mapping, won't list",
  },
];

function EbayConditionMappingModal(props: EbayConditionMappingModalProps) {
  const { closeModal } = props;
  return (
    <Modal onClose={closeModal}>
      <Modal.Header>eBay Condition Mapping</Modal.Header>
      <Modal.Content>
        When bulk rules are created for multiple items, conditions will map as
        indicated below. Damaged cards have no equivalent condition on eBay and
        cannot be listed.
        <table className="EbayConditionMappingModal__table">
          <thead>
            <tr className="EbayConditionMappingModal__table-row">
              {ebayConditionMappingColumns.map((column) => {
                return (
                  <th
                    key={column.accessorKey}
                    className="EbayConditionMappingModal__table-header"
                  >
                    {column.header}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {ebayConditionMappingRows.map((row) => (
              <tr
                className="EbayConditionMappingModal__table-row"
                key={row.binder}
              >
                {ebayConditionMappingColumns.map((column) => (
                  <td
                    key={column.accessorKey}
                    className="EbayConditionMappingModal__table-cell"
                  >
                    {column.cell ? column.cell() : row[column.accessorKey]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </Modal.Content>
    </Modal>
  );
}

export default EbayConditionMappingModal;
