import CustomerAvatar from "../../components/customers/CustomerAvatar";
import { CartItem, CartWithTotals } from "../../types/order";
import { Column } from "../../types/table";
import { TimeUtils } from "../../utils";

type formatCurrencyType = (amount: string | Number) => string;

export const cartCustomerColumns: Column[] = [
  {
    header: "",
    accessorKey: "customer",
    cell: (customer: Customer) =>
      customer ? <CustomerAvatar customer={customer} /> : null,
  },
  {
    header: "Customer",
    accessorKey: "customer",
    cell: (customer: Customer) =>
      customer ? [customer.firstName, customer.lastName].join(" ") : "Guest",
  },
  {
    header: "Email",
    accessorKey: "customer",
    cell: (customer: Customer) => customer?.email ?? "Guest cart",
  },
  {
    header: "Date Submitted",
    accessorKey: "dateSubmitted",
    cell: (dateSubmitted: string) =>
      TimeUtils.convertDateToHumanReadable(dateSubmitted),
  },
  {
    header: "Order #",
    accessorKey: "orderNumber",
  },
];

export const getCartItemsColumns = (
  formatCurrency: formatCurrencyType
): Column[] => [
  {
    header: "",
    accessorKey: "imageSrc",
    cell: (imageSrc: string) =>
      imageSrc ? (
        <img
          src={imageSrc}
          role="presentation"
          className="CartView__productImage"
        />
      ) : null,
  },
  {
    header: "Product",
    accessorKey: "id",
    cell: (_id, _column, cartItem: CartItem) => (
      <>
        <div className="CartView__productTitle">{cartItem.productTitle}</div>
        <div className="CartView__productTitle">{cartItem.variantTitle}</div>
      </>
    ),
  },
  {
    header: "Bought / Sold",
    accessorKey: "buying",
    cell: (buying) => (buying ? "Bought" : "Sold"),
  },
  { header: "Quantity", accessorKey: "quantity" },
  {
    header: "Price",
    accessorKey: "actualPrice",
    cell: (actualPrice: number) => formatCurrency(actualPrice),
  },
  {
    header: "Total",
    accessorKey: "price",
    cell: (price: number, _column, cartItem: CartItem) =>
      cartItem.discountValue ? (
        <>
          <del>{formatCurrency(cartItem.actualPrice * cartItem.quantity)}</del>
          <br />
          <strong>
            {formatCurrency(
              cartItem.actualPrice * cartItem.quantity -
                Math.abs(cartItem.discountValue)
            )}
          </strong>
        </>
      ) : (
        formatCurrency(price)
      ),
  },
];

type GlobalDiscount = {
  value: number;
  amount: number;
  type: string;
};

type Totals = {
  totalTax: number;
  subTotal: number;
  grandTotal: number;
  cartSubTotalWithDiscount: Number;
  cartTotalWithDiscount: number;
};

export const getCartTotalsColumns = (
  formatCurrency: formatCurrencyType
): Column[] => [
  {
    header: "Discount",
    accessorKey: "globalDiscount",
    cell: (globalDiscount: GlobalDiscount, _column, cart: CartWithTotals) =>
      globalDiscount?.amount !== 0 ? (
        <>
          {cart.globalDiscount.type === "percentage"
            ? ` -${cart.globalDiscount.value}%`
            : ""}
          <em>{formatCurrency(Number(cart.globalDiscount.amount))}</em>
        </>
      ) : (
        formatCurrency(0)
      ),
  },
  {
    header: "Sub Total",
    accessorKey: "totals",
    cell: (totals: Totals) => formatCurrency(totals.cartSubTotalWithDiscount),
  },
  {
    header: "Tax included",
    accessorKey: "totalTax",
    cell: (totalTax: number) => formatCurrency(totalTax),
  },
  {
    header: "Total",
    accessorKey: "totals",
    cell: (totals: Totals) => formatCurrency(totals.cartTotalWithDiscount),
  },
];
