import React from "react";
import { observer } from "mobx-react";
import { ContextMenuTrigger } from "react-contextmenu";
import BuyIcon from "../../../assets/img/sell.svg";
import {
  isVariantForPurchase,
  getVariantBuyPrice,
} from "../../helpers/posHelpers";
import ItemTitle from "./ItemTitle";
import ButtonComponent from "../generic/ButtonComponent";

interface GridItemBuyProps {
  item: Product;
  totalInStock: number;
  cashPrice: boolean;
  currency: string;
  isLoading: boolean;
  showBuyLimitWarnings: boolean;
  addItem: Function;
  changeVariant: Function;
}

function GridItemBuy(props: GridItemBuyProps) {
  const {
    item,
    totalInStock,
    cashPrice,
    currency,
    isLoading,
    showBuyLimitWarnings,
    addItem,
    changeVariant,
  } = props;

  const handleVariantChange = (event: React.ChangeEvent<HTMLSelectElement>) =>
    changeVariant(event.target.value, true);

  return (
    <>
      <ContextMenuTrigger id={item.id.toFixed()}>
        <img className="cartBadge" src={BuyIcon} role="presentation" />
        <span className="stock">Stock: {totalInStock} </span>
        <div className="cardpos-container">
          <img src={item.img} role="presentation" />
        </div>
      </ContextMenuTrigger>
      <>
        <div className="productDetails">
          <ItemTitle title={item.title} />
          <span className="sellPrice sellPrice--visible">
            Sell Price: {currency + " "}{" "}
            {item.variants[item.selectedBuyVariant]?.price?.toFixed(2)}
          </span>
          <span className="variant">
            <select
              aria-label="Variant selector"
              value={item.selectedBuyVariant}
              onChange={handleVariantChange}
            >
              {item.variants.map((variant, index) => (
                <option
                  className={
                    variant.quantity >= 0
                      ? variant.quantity == 0
                        ? "blackClass"
                        : "greenClass"
                      : "redClass"
                  }
                  disabled={
                    !isVariantForPurchase(
                      variant,
                      cashPrice,
                      showBuyLimitWarnings
                    )
                  }
                  value={index}
                  key={index}
                >
                  {variant.title + "\tx" + variant.quantity}
                </option>
              ))}
            </select>
          </span>
        </div>
        {item.selectedBuyVariant > -1 ? (
          <span className="price">
            {currency + " "}
            {getVariantBuyPrice(
              item.variants[item.selectedBuyVariant],
              cashPrice
            )}
          </span>
        ) : (
          <span className="price">N/A</span>
        )}
        {isVariantForPurchase(
          item.variants[item.selectedBuyVariant],
          cashPrice,
          showBuyLimitWarnings
        ) ? (
          <ButtonComponent
            primary
            compact
            halfWidth
            disabled={isLoading}
            aria-label={`Add ${item?.title} - ${
              item?.variants[item?.selectedBuyVariant]?.title
            } to your cart`}
            onClick={() => {
              addItem(item);
            }}
            icon="fas fa-plus"
          >
            Add
          </ButtonComponent>
        ) : (
          <span className="no-stock">Unavailable</span>
        )}
      </>
    </>
  );
}

export default observer(GridItemBuy);
