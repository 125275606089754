import React from "react";
import Logout from "./Logout";
import DesktopMenu from "./DesktopMenu";
import SideMenu from "./SideMenu";
import UserAvatar from "./UserAvatar";
import SettingsMenuStructure from "../../menuStructures/SettingsMenuStructure";
import "./DesktopHeader.scss";
import Watermark from "../../../assets/img/watermark.png";
import Logo from "../../../assets/img/logo.png";
import IconButton from "../generic/IconButton";
import { isViewingPOS } from "../../helpers/routeHelpers";
import { segmentAnalytics } from "../services/Analytics";
import { SETTINGS_CLICKED } from "../../constants/eventsTracked";
import { MenuStructure } from "../../types/nav";
import SupportMenu from "./SupportMenu";

interface DesktopHeaderProps {
  storeName?: string;
  currentTill?: string;
  sideMenu?: MenuStructure;
  screenSettings: ScreenSettings;
  updateMenu: (menu: MenuStructure | undefined | null) => void;
  handleLogout: () => void;
}

function DesktopHeader(props: DesktopHeaderProps) {
  const {
    sideMenu,
    storeName,
    currentTill,
    screenSettings,
    updateMenu,
    handleLogout,
  } = props;

  return (
    <>
      <aside className="watermark">
        <img src={Watermark} role="presentation" />
      </aside>
      <header>
        <div className="topCnrBg" />
        <div className="brand">
          <img src={Logo} alt="Binder POS Logo" />
        </div>
        <div className="company">
          {storeName} {currentTill ? `(${currentTill})` : null}
        </div>
      </header>
      <nav className="app-sidebar">
        <div className="topCnrBgLight" />
        <div className="leftBgAngle" />
        <div className="leftBgBlur" />
        <div className="POSangle" />
        {!isViewingPOS() ? (
          <DesktopMenu
            screenSettings={screenSettings}
            updateMenu={updateMenu}
          />
        ) : null}
      </nav>
      {!isViewingPOS() ? (
        <nav
          className="DesktopHeader__navbar"
          role="navigation"
          aria-label="main navigation"
        >
          <SideMenu sideMenu={sideMenu} />
          <div className="DesktopHeader__topNav">
            <div className="supportlink">
              <SupportMenu />
            </div>
            <div className="supportlink">
              <IconButton
                className="DesktopHeader__navIcon"
                to="/settings"
                icon="fas fa-cogs"
                isLink
                onClick={() => {
                  segmentAnalytics.track(SETTINGS_CLICKED);
                  updateMenu(SettingsMenuStructure);
                }}
                title="Settings"
                aria-label="Settings"
              />
            </div>
            <div className="supportlink">
              <Logout onLogout={handleLogout} />
            </div>
            <UserAvatar />
          </div>
        </nav>
      ) : null}
    </>
  );
}
export default DesktopHeader;
