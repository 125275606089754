import { DateTime } from "luxon";
import {
  BinderCustomerTill,
  Cart,
  CartItem,
  CartTender,
  LineItemProperty,
} from "../types/order";
import { mapCustomer } from "./customer";

function mapEventAdditionalInfo(data): Array<LineItemProperty> {
  return (
    data.CartItemAdditionalInfo &&
    data.CartItemAdditionalInfo.map((info) => ({
      name: info.name,
      value: info.value,
    }))
  );
}

function mapCartItem(item: any): CartItem {
  return {
    actualPrice: item.actualPrice && Number(item.actualPrice),
    buying: item.buying,
    discountAmount: item.discountAmount && Number(item.discountAmount),
    discountValue: item.discountValue && Number(item.discountValue),
    imageSrc: item.imageSrc,
    lineTotal: item.price && Number(item.price),
    price: item.price && Number(item.price),
    quantity: item.quantity,
    productTitle: item.productTitle,
    variantTitle: item.variantTitle,
    taxable: item.taxable,
    cartId: item.cartId,
    discountType: item.discountType,
    eventAdditionalInfo: mapEventAdditionalInfo(item),
    id: item.id,
    itemTax: item.itemTax,
    quantityAvailable:
      item.quantity +
      (item.ShopifyCustomerProductVariant?.inventoryQuantity || 0),
    shopifyPrice: item.shopifyPrice && Number(item.shopifyPrice),
    shopifyTaxable: item.shopifyTaxable,
    tags: item.tags,
    variantId: item.variantId,
    type:
      item?.ShopifyCustomerProductVariant?.ShopifyCustomerProduct?.type || null,
  };
}

function mapCartTender(data): Array<CartTender> {
  return (
    (data.CartTender &&
      data.CartTender.map((tender) => ({
        id: tender.id,
        type: tender.type,
        amount: tender.amount && Number(tender.amount),
      }))) ||
    []
  );
}

function mapTill(data): BinderCustomerTill {
  return (
    data.BinderCustomerTill && {
      id: data.BinderCustomerTill.id,
      description: data.BinderCustomerTill.description,
      name: data.BinderCustomerTill.name,
    }
  );
}

export function mapCart(data: any, resolveReturnCarts = true): Cart {
  return {
    id: data.id,
    cartNotes: data.cartNotes,
    dateSubmitted:
      data.dateSubmitted && DateTime.fromISO(data.dateSubmitted).toString(),
    globalDiscount: {
      value: Number(data.discountValue ?? 0),
      amount: Number(data.discountAmount ?? 0),
      type: data.discountType,
    },
    customer: mapCustomer(data),
    abandoned: data.abandoned,
    cartName: data.cartName,
    till: mapTill(data),
    cartIntegratedPayments: data.CartIntegratedPayment,
    cartItems:
      (data.CartItem &&
        data.CartItem.length &&
        data.CartItem.map(mapCartItem)) ||
      [],
    orderNumber: data.orderNumber,
    subTotal: data.subTotal && Number(data.subTotal),
    taxIncluded: data.taxIncluded,
    taxRate: data.taxRate,
    tenders: mapCartTender(data),
    taxLines: data.CartTax,
    totalItems:
      data.CartItem &&
      data.CartItem.length &&
      data.CartItem.reduce((acc, item) => acc + item.quantity, 0),
    totalPrice: data.totalPrice && Number(data.totalPrice),
    totalTax: data.totalTax && Number(data.totalTax),
    parentCartId: data.parentCartId,
    returnCarts:
      resolveReturnCarts && data.other_Cart && data.other_Cart.length
        ? data.other_Cart.map((other) => mapCart(other, false))
        : null,
    cartType: data.cartType
      ? data.cartType
      : data.parentCartId
      ? "return"
      : "POS",
    shopifyOrderId: data.shopifyOrderId,
    submittedBy:
      data.BinderCustomerUser_BinderCustomerUserToCart_submittedBy?.email,
    createdBy:
      data.BinderCustomerUser_BinderCustomerUserToCart_createdBy?.email,
    error: data.error,
    dateCreated: DateTime.fromISO(data.dateCreated).toString(),
    discountAmount: data.discountAmount && Number(data.discountAmount),
    discountType: data.discountType,
    discountValue: data.discountValue,
    lastUpdated: DateTime.fromISO(data.lastUpdated).toString(),
    readableDateSubmitted: DateTime.fromISO(data.dateSubmitted)
      .setZone("GMT")
      .toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS),
    savedCart: data.savedCart,
    savedName: data.savedName,
    tillId: data.binderCustomerTillId,
  };
}
