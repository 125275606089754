import React, { Fragment, useState } from "react";
import { useFetchCartById } from "../../hooks/useFetchCartById";
import { StoreInfo } from "../../types/order";
import { ReturnCartPaymentData } from "../../types/pos";
import Loader from "../generic/Loader";
import CartNotes from "./CartNotes";
import ReturnCartButton from "./ReturnCartButton";
import ReceiptOptionsContainer from "../generic/ReceiptOptionsContainer";
import TableComponent from "../table/TableComponent";
import {
  getOrderDetailsCartColumns,
  getOrderDetailsTotalsColumns,
  orderDetailsColumns,
  orderDetailsIntegratedPaymentsColumns,
} from "./OrderDetailsColumns";
import SectionHeaderLayout from "../layout/SectionHeaderLayout";
import ButtonComponent from "../generic/ButtonComponent";
import ActionButtonsLayout from "../layout/ActionButtonsLayout";
import SubSectionHeaderLayout from "../layout/SubSectionHeaderLayout";
import Panel from "../layout/Panel";
import LinkComponent from "../generic/LinkComponent";
import "./OrderDetails.scss";

interface OrderDetailsProps {
  cartId: number;
  setOrderNumber: (id: BigInt) => void;
  cartFromResponse: (response: any) => void;
  setReturnCartIntegratedPayment: (payment: ReturnCartPaymentData[]) => void;
  closeModal: () => void;
  formatCurrency: (amount: number) => string;
  storeInfo: StoreInfo;
}

function OrderDetails({
  cartId,
  setOrderNumber,
  cartFromResponse,
  setReturnCartIntegratedPayment,
  closeModal,
  formatCurrency,
  storeInfo,
}: OrderDetailsProps) {
  const [showReceiptModal, setShowReceiptModal] = useState<boolean>(false);
  const [showGiftReceiptModal, setShowGiftReceiptModal] =
    useState<boolean>(false);

  const { data: orderData, isFetching: isCartFetching } =
    useFetchCartById(cartId);
  const closeReceiptModal = () => {
    setShowGiftReceiptModal(false);
    setShowReceiptModal(false);
  };

  if (isCartFetching) {
    return <Loader />;
  }
  const { cart, selectedCustomer, customerSettings } = orderData;

  const hasReturns = cart.returnCarts?.some((rc) => !!rc.dateSubmitted);
  const canCreateReturn = !hasReturns && cart.cartType !== "return";

  const initialValues = {
    storeInfo,
    selectedCustomer,
    customerSettings,
    cart,
  };

  return (
    <Fragment>
      {cart?.id && showReceiptModal ? (
        <ReceiptOptionsContainer
          title="Receipt Reprint"
          cartId={parseInt(`${cart.id}`, 10)}
          isModalOpen={showReceiptModal}
          closeReceipt={closeReceiptModal}
          initialValues={initialValues}
          completedReceipt
        />
      ) : null}
      {showGiftReceiptModal ? (
        <ReceiptOptionsContainer
          title="Gift Receipt"
          cartId={parseInt(`${cart.id}`, 10)}
          isModalOpen={showGiftReceiptModal}
          closeReceipt={closeReceiptModal}
          completedReceipt
          initialValues={initialValues}
          giftReceipt
        />
      ) : null}
      <SectionHeaderLayout
        title={`${cart.parentCartId ? "Return " : "Cart "}# ${cart.id}`}
      >
        <ActionButtonsLayout>
          <ButtonComponent
            primary
            onClick={() => setShowGiftReceiptModal(true)}
          >
            Print Gift Receipt
          </ButtonComponent>
          <ButtonComponent primary onClick={() => setShowReceiptModal(true)}>
            Reprint Receipt
          </ButtonComponent>
          {canCreateReturn && (
            <ReturnCartButton
              cartId={cart.id}
              onComplete={(response, paymentData) => {
                cartFromResponse(response);
                setReturnCartIntegratedPayment(paymentData);
                closeModal();
              }}
            />
          )}
        </ActionButtonsLayout>
      </SectionHeaderLayout>
      {hasReturns ? (
        <>
          <SubSectionHeaderLayout title="Attached Returns" />
          <Panel>
            {cart.returnCarts.map((returnCart) => {
              if (returnCart.dateSubmitted) {
                return (
                  <span key={returnCart.id.toString()}>
                    A return was made, see order{" "}
                    <LinkComponent
                      className="OrderDetails__innerLink"
                      onClick={() => {
                        setOrderNumber(returnCart.id);
                      }}
                    >
                      <>#{returnCart.id.toString()}</>
                    </LinkComponent>
                  </span>
                );
              }
            })}
          </Panel>
        </>
      ) : null}
      {cart.parentCartId ? (
        <>
          <SubSectionHeaderLayout title="Parent Cart" />
          <Panel>
            <span key={cart.parentCartId.toString()}>
              This return was created for cart{" "}
              <LinkComponent
                className="OrderDetails__innerLink"
                onClick={() => {
                  setOrderNumber(cart.parentCartId);
                }}
              >
                <>#{cart.parentCartId}</>
              </LinkComponent>
            </span>
          </Panel>
        </>
      ) : null}
      <TableComponent
        columns={orderDetailsColumns}
        data={[
          {
            customer: selectedCustomer,
            dateSubmitted: cart.dateSubmitted,
            orderNumber: cart.orderNumber,
          },
        ]}
      />
      <br />
      {cart.tenders && cart.tenders.length > 0 ? (
        <>
          <SubSectionHeaderLayout title="Payment Details" />
          <Panel>
            {cart.tenders.map((tender) => (
              <p key={tender.type}>
                <em>{tender.type} :</em> {formatCurrency(tender.amount)}
                {tender.type.toLowerCase() === "credit" &&
                  cart.cartIntegratedPayments.map((payment) => (
                    <TableComponent
                      columns={orderDetailsIntegratedPaymentsColumns}
                      data={[payment]}
                    />
                  ))}
              </p>
            ))}
          </Panel>
        </>
      ) : (
        <p>No tenders used</p>
      )}
      <br />
      <CartNotes rawNotes={cart.cartNotes} />
      <SubSectionHeaderLayout title="Cart Items" />
      <TableComponent
        columns={getOrderDetailsCartColumns(formatCurrency)}
        data={cart.cartItems}
      />
      <br />
      <SubSectionHeaderLayout title="Totals" />
      <TableComponent
        columns={getOrderDetailsTotalsColumns(formatCurrency)}
        data={[cart]}
      />
    </Fragment>
  );
}

export default OrderDetails;
