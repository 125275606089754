import React from "react";
import { User } from "../../types/user";
import "./UserListAvatar.scss";

interface UserListAvatarProps {
  user: User;
  className?: string;
}

function UserListAvatar(props: UserListAvatarProps) {
  const { user, className } = props;
  return (
    <div className={["UserListAvatar", className].join(" ")}>
      {user.firstName?.length > 0 ? (
        <>
          {user.firstName.charAt(0)}
          {user?.lastName?.length > 0 ? <>{user.lastName.charAt(0)}</> : null}
        </>
      ) : (
        <>{user.email?.length > 0 ? <>{user.email.charAt(0)}</> : null}</>
      )}
    </div>
  );
}

export default UserListAvatar;
