import React from "react";
import PropTypes from "prop-types";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";
import { POSMenuStructure } from "../../menuStructures";
import { ConfirmationModal, Loader, Paging } from "../../components";
import FailedToSyncProduct from "../../components/generic/FailedToSyncProduct";
import Modal from "../../components/generic/Modal";
import ActionButtonsLayout from "../../components/layout/ActionButtonsLayout";
import GridLayout from "../../components/layout/GridLayout";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";

const LIMIT = 100;

@inject("store")
@observer
class POSFailedBuylistProducts extends React.Component {
  @observable offset = 0;

  @action setOffset(offset) {
    this.offset = offset;
  }

  @observable messageModalText;

  @action setMessageModalText(text) {
    this.messageModalText = text;
  }

  @observable messageModalTitle;

  @action setMessageModalTitle(title) {
    this.messageModalTitle = title;
  }

  @observable messageModalVisible;

  @action setMessageModalVisible(bool) {
    this.messageModalVisible = bool;
  }

  @observable processing;

  @action setProcessing(processing) {
    this.processing = processing;
  }

  @observable deleteModalVisible = false;

  @action setDeleteModalVisible(bool) {
    this.deleteModalVisible = bool;
  }

  @observable deleteModalProduct;

  @action setDeleteModalProduct(product) {
    this.deleteModalProduct = product;
  }

  constructor(props) {
    super(props);
    this.modalRef = React.createRef();
    this.listRef = React.createRef();
  }

  componentDidMount() {
    document.title = "POS Failed to Sync to Stock | BinderPOS";
    this.props.store.MenuStore.setSideMenuToDisplay(POSMenuStructure);
    this.props.store.POSBuylistRulesStore.rehydratePOSFailedToSync(
      this.offset,
      LIMIT
    );
  }

  componentDidUpdate() {
    if (this.deleteModalVisible || this.messageModalVisible) {
      this.modalRef.current?.focus();
    } else {
      this.listRef.current?.focus();
    }
  }

  getNextFailedToSync = () => {
    this.setOffset(this.offset + 100);
    this.props.store.POSBuylistRulesStore.rehydratePOSFailedToSync(
      this.offset,
      LIMIT
    );
  };

  getPrevFailedToSync = () => {
    let offsetUpdate = this.offset - 100;
    if (offsetUpdate < 0) {
      offsetUpdate = 0;
    }
    this.setOffset(offsetUpdate);
    this.props.store.POSBuylistRulesStore.rehydratePOSFailedToSync(
      this.offset,
      LIMIT
    );
  };

  pushStockThrough = (failedToSync) => {
    this.setProcessing(true);
    this.props.store.POSBuylistRulesStore.processPOSFailedProduct(failedToSync)
      .then((result) => {
        if (!result.actionPass) {
          this.setMessageModalText(result.message);
          this.setMessageModalTitle("Issue pushing through to stock");
          this.setMessageModalVisible(true);
          this.setProcessing(false);
        } else {
          this.setProcessing(false);
          this.props.store.POSBuylistRulesStore.rehydratePOSFailedToSync(
            this.offset,
            LIMIT
          );
        }
      })
      .catch((error) => {
        this.setProcessing(false);
        this.props.store.MainStore.setError(
          error,
          "Failed to push stock buylists",
          "There was an error pushing your stock. Please try again"
        );
      });
  };

  showRemoveProductModal = (failedToSync) => {
    this.setDeleteModalProduct(failedToSync);
    this.setDeleteModalVisible(true);
  };

  removeFromList = (failedToSync) => {
    this.setProcessing(true);
    this.setDeleteModalVisible(false);
    this.props.store.POSBuylistRulesStore.removePOSFailedProduct(failedToSync)
      .then((result) => {
        this.setMessageModalText(
          result.actionPass ? "Product removed successfully!" : result.message
        );
        this.setMessageModalTitle(
          result.actionPass
            ? "Product removed"
            : "There was an issue removing this product"
        );
        this.setMessageModalVisible(true);
        this.setProcessing(false);
        this.props.store.POSBuylistRulesStore.rehydratePOSFailedToSync(
          this.offset,
          LIMIT
        );
      })
      .catch((error) => {
        this.setProcessing(false);
        this.props.store.MainStore.setError(
          error,
          "Failed to remove product",
          "There was an error removing your product. Please try again"
        );
      });
  };

  render() {
    if (!this.props.store.AuthStore.screenSettings.pos)
      return (
        <div>
          <p>Please contact BinderPOS to enable this screen.</p>
        </div>
      );
    if (!this.props.store.POSBuylistRulesStore.posFailedToSyncList)
      return <Loader />;
    if (this.processing) return <Loader />;
    return (
      <>
        <SectionHeaderLayout title="Failed to Sync to Stock from POS Buylist">
          <ActionButtonsLayout>
            <Paging
              pageOffset={this.offset}
              currentPageItemCount={
                this.props.store.POSBuylistRulesStore.posFailedToSyncList
                  ?.length || 0
              }
              maxItemsPerPage={this.limit}
              getNextPage={this.getNextFailedToSync}
              getPrevPage={this.getPrevFailedToSync}
            />
          </ActionButtonsLayout>
        </SectionHeaderLayout>
        <GridLayout>
          {this.props.store.POSBuylistRulesStore.posFailedToSyncList.map(
            (failedToSync) => (
              <FailedToSyncProduct
                key={failedToSync.id}
                product={failedToSync}
                addToStockAction={() => this.pushStockThrough(failedToSync)}
                removeAction={(event) => {
                  this.listRef.current = event.currentTarget;
                  this.showRemoveProductModal(failedToSync);
                }}
              />
            )
          )}
        </GridLayout>
        {this.messageModalVisible ? (
          <Modal
            onClose={() => this.setMessageModalVisible(false)}
            ref={this.modalRef}
          >
            <Modal.Header>{this.messageModalTitle}</Modal.Header>
            <Modal.Content>{this.messageModalText}</Modal.Content>
          </Modal>
        ) : null}
        {this.deleteModalVisible ? (
          <ConfirmationModal
            ref={this.modalRef}
            cancelAction={() => this.setDeleteModalVisible(false)}
            confirmWord="Remove"
            confirmAction={() => this.removeFromList(this.deleteModalProduct)}
          >
            Are you sure you want to remove {this.deleteModalProduct.cardName} [
            {this.deleteModalProduct.setName}] -{" "}
            {this.deleteModalProduct.variantName}?
          </ConfirmationModal>
        ) : null}
      </>
    );
  }
}

POSFailedBuylistProducts.propTypes = { store: PropTypes.object };

export default POSFailedBuylistProducts;
