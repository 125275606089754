import { SplitContext } from "@splitsoftware/splitio-react";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { CURRENT_FEATURE_FLAGS } from "../constants/featureFlags";
import { useStoreDetails } from "../hooks/storeHooks";
import { segmentAnalytics } from "../components/services/Analytics";
import { isKioskMode } from "../helpers/posModeHelpers";

export interface GlobalSettingsContextProps {
  featureFlags: Record<string, string>;
  isPromptEnabled: boolean;
  setFormIsEditing: any;
}

export const GlobalSettingsContext =
  createContext<GlobalSettingsContextProps>(null);

interface GlobalSettingsProviderProps {
  children: React.ReactNode;
  user?: any;
  featureFlagsUpdater?: (flags: { [flagName: string]: string }) => void;
}

function GlobalSettingsProvider(props: GlobalSettingsProviderProps) {
  const { children, user, featureFlagsUpdater } = props;
  const { customerId } = useStoreDetails();
  const { isReady, client } = useContext(SplitContext);
  const [featureFlags, setFeatureFlags] = useState<Record<string, string>>({});

  // Global setting for managing the navigation prompt
  const [isEditingForms, handleSetIsEditingForms] = useState<
    { value: boolean; key: string }[]
  >([]);

  const isPromptEnabled = useMemo(
    () => isEditingForms.some((item) => item.value === true),
    [isEditingForms]
  );

  const setFormIsEditing = (value: boolean, key: string) => {
    if (key) {
      if (value === false) {
        handleSetIsEditingForms((prevIsEditingForms) =>
          prevIsEditingForms.filter((item) => item.key !== key)
        );
      } else {
        handleSetIsEditingForms((prevIsEditingForms) => [
          ...prevIsEditingForms,
          { value, key },
        ]);
      }
    }
  };

  useEffect(() => {
    if (customerId && user) {
      segmentAnalytics.identify(user?.uid, {
        email: user?.email,
        store_id: customerId,
        store_platform: isKioskMode() ? "Kiosk" : "Portal",
      });
    }
  }, [customerId, user]);

  useEffect(() => {
    if (isReady) {
      if (user?.email) {
        client.setAttribute("email", user.email);
        const domain = user.email.split("@")[1];
        client.setAttribute("userEmailDomain", domain);
      }
      if (customerId) client.setAttribute("customerId", customerId);
      const treatments = client.getTreatments(CURRENT_FEATURE_FLAGS);
      setFeatureFlags(treatments);
      featureFlagsUpdater?.(treatments);
    }
  }, [isReady, customerId]);

  return (
    <GlobalSettingsContext.Provider
      value={{
        featureFlags,
        isPromptEnabled,
        setFormIsEditing,
      }}
    >
      {children}
    </GlobalSettingsContext.Provider>
  );
}

export default GlobalSettingsProvider;
