import {
  BulkUpdateModal,
  ButtonComponent,
  ConfirmationModal,
  Loader,
  ProductLineItem,
} from "../../components";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import NumberPagination from "./NumberPagination";
import SortBy from "./SortBy";
import FilterVariantsSelect from "../../components/product/FilterVariantsSelect";
import ImportCsvButtons from "../../components/product/ImportCsvButtons";
import CheckboxComponent from "../../components/generic/CheckboxComponent";
import IconButton from "../../components/generic/IconButton";
import Modal from "../../components/generic/Modal";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";
import ActionButtonsLayout from "../../components/layout/ActionButtonsLayout";
import {
  BULK_UPDATE_SEARCH_CLICKED,
  EXPORT_PRODUCT_SEARCH_CLICKED,
  VIEW_PREVIOUS_EXPORTS_CLICKED,
  EXPORT_SEARCH_CSV_CLICKED,
  CANCEL_EXPORT_SEARCH_CSV_CLICKED,
  INVENTORY_UPDATE_TYPE_SELECTED,
  APPLY_PRODUCT_FILTER_CLICKED,
  UPDATE_COLUMNS_CLICKED,
  PRODUCTS_MENU_CLICKED,
  UPDATE_PRODUCT_LIST_CLICKED,
} from "../../constants/eventsTracked";
import "../../../css/react-select-overwrites.scss";
import { segmentAnalytics } from "../../components/services/Analytics";
import FilterTabButton from "../../components/product/FilterTabButton";
import { notify } from "../../helpers/notificationHelpers";
import { RootStore } from "../../store";
import ExternalCSVImportModal from "../../components/product/ExternalCSVImportModal";
import CSVImportModal from "../../components/product/CSVImportModal";

const priceOverrideTypes = [
  {
    id: "",
    value: "Include both",
  },
  {
    id: "manual",
    value: "Manual override",
  },
  {
    id: "automatic",
    value: "Automatic price update",
  },
];

interface ProductListProps extends RouteComponentProps {
  store?: RootStore;
  index: number;
  selected: number;
}

interface ProductListState {
  collapseVariants: boolean;
  hideOutOfStock: boolean;
}

@inject("store")
@observer
class ProductList extends React.Component<ProductListProps, ProductListState> {
  advancedSearchForm: React.RefObject<any>;
  columnToggle: React.RefObject<any>;
  scrollTo: React.RefObject<any>;
  modalRef: React.RefObject<any>;
  listRef: React.MutableRefObject<any>;

  @observable stockAddType = "set";

  @action setStockAddType(type) {
    this.stockAddType = type;
  }

  @observable currentInput = 1;

  @action setCurrentInput(currentInput) {
    this.currentInput = currentInput;
  }

  @observable exportModalVisible;

  @action setExportModalVisible(exportModalVisible) {
    this.exportModalVisible = exportModalVisible;
  }

  @observable exportSuccessful;

  @action setExportSuccessful(exportSuccessful) {
    this.exportSuccessful = exportSuccessful;
  }

  @observable exportModalLoading;

  @action setExportModalLoading(bool) {
    this.exportModalLoading = bool;
  }

  @observable deleteModalVisible;

  @action setDeleteModalVisible(deleteModalVisible) {
    this.deleteModalVisible = deleteModalVisible;
  }

  @observable savedSearchFilterToDelete;

  @action setSavedSearchFilterToDelete(filter) {
    this.savedSearchFilterToDelete = filter;
  }

  @observable deleteModalLoading;

  @action setDeleteModalLoading(bool) {
    this.deleteModalLoading = bool;
  }

  @observable offset = 0;

  remapSearchFields = (search) => {
    const remappedSearch = {};
    const remapKeys = {
      classes: "fabClasses",
      priceOverrideType: "priceOverrideType",
    };
    Object.keys(search).forEach((key) => {
      const value = search[key];

      if (Object.keys(remapKeys).includes(key)) {
        remappedSearch[remapKeys[key]] = value;
      } else {
        remappedSearch[key] = value;
      }
    });
    return remappedSearch;
  };

  searchToParams = (search, page, sortBy, sortAsc) => {
    const remappedSearch = this.remapSearchFields(search);
    const encodedSearch = JSON.stringify(remappedSearch);
    const params = new URLSearchParams();
    params.set("search", encodedSearch);
    if (page) params.set("page", page);
    if (sortBy) params.set("sortBy", sortBy);
    if (sortAsc !== undefined) params.set("sortAsc", sortAsc);
    // Unique indentifier for request
    params.set("searchId", Math.random().toString(36));
    this.props.history.push({
      search: "?" + params.toString(),
    });
  };

  @action setOffset(offset) {
    this.offset = offset;
  }

  @observable limit = 25;

  @action setLimit(limit) {
    this.limit = limit;
  }

  @observable filtersSetup = false;

  @action setFiltersSetup(bool) {
    this.filtersSetup = bool;
  }

  @observable selectedTags = [];

  @action setSelectedTags(tags) {
    this.selectedTags = tags;
  }

  @action pushTag(tag) {
    this.selectedTags.push(tag);
  }

  @observable productInfoSaving = false;

  @action setProductInfoSaving(saving) {
    this.productInfoSaving = saving;
  }

  @observable productInfo;

  @action setProductInfo(product) {
    this.productInfo = product;
  }

  @observable productInfoModalVisible;

  @action setProductInfoModalVisible(visible) {
    this.productInfoModalVisible = visible;
  }

  @observable selectedGame;

  @action setSelectedGame(selectedGame) {
    this.selectedGame = selectedGame;
  }

  @observable selectedProductTypes;

  @action setSelectedProductTypes(selectedProductTypes) {
    this.selectedProductTypes = selectedProductTypes;
  }

  @observable selectedVendors;

  @action setSelectedVendors(selectedVendors) {
    this.selectedVendors = selectedVendors;
  }

  @observable selectedVariants;

  @action setSelectedVariants(selectedVariants) {
    this.selectedVariants = selectedVariants;
  }

  @observable selectedRarities;

  @action setSelectedRarities(selectedRarities) {
    this.selectedRarities = selectedRarities;
  }

  @observable selectedCardTypes;

  @action setSelectedCardTypes(selectedCardTypes) {
    this.selectedCardTypes = selectedCardTypes;
  }

  @observable selectedSets;

  @action setSelectedSets(selectedSets) {
    this.selectedSets = selectedSets;
  }

  @observable selectedMonsterTypes;

  @action setSelectedMonsterTypes(selectedMonsterTypes) {
    this.selectedMonsterTypes = selectedMonsterTypes;
  }

  @observable selectedColors;

  @action setSelectedColors(selectedColors) {
    this.selectedColors = selectedColors;
  }

  @observable selectedClasses;

  @action setSelectedClasses(selectedClasses) {
    this.selectedClasses = selectedClasses;
  }

  @observable selectedSubTypes;

  @action setSelectedSubTypes(selectedSubTypes) {
    this.selectedSubTypes = selectedSubTypes;
  }

  @observable selectedFinishes;

  @action setSelectedFinishes(selectedFinishes) {
    this.selectedFinishes = selectedFinishes;
  }

  @observable selectedEditions;

  @action setSelectedEditions(selectedEditions) {
    this.selectedEditions = selectedEditions;
  }

  @observable showBulkUpdate;

  @action setShowBulkUpdate(bool) {
    this.showBulkUpdate = bool;
  }

  @observable bulkUpdateLoading = false;

  @action setBulkUpdateLoading = (bulkUpdateLoading) =>
    (this.bulkUpdateLoading = bulkUpdateLoading);

  @observable bulkUpdateType;

  @action setBulkUpdateType(type) {
    this.bulkUpdateType = type;
  }

  @observable variantsToUpdate;

  @action setVariantsToUpdate(variantsToUpdate) {
    this.variantsToUpdate = variantsToUpdate;
  }

  @observable currentSearch;

  @action setCurrentSearch(currentSearch) {
    this.currentSearch = currentSearch;
  }

  @observable productCount;

  @action setProductCount(productCount) {
    this.productCount = productCount;
  }

  @observable selectedTab = "filters";

  @action setSelectedTab(selectedTab) {
    this.selectedTab = selectedTab;
  }

  @observable selectedPriceOverrideType;

  @action setSelectedPriceOverrideType(selectedPriceOverrideType) {
    this.selectedPriceOverrideType = selectedPriceOverrideType;
  }

  @observable minimizeFilters = false;

  @action setMinimizeFilters(bool) {
    this.minimizeFilters = bool;
  }

  @observable columnsToShow = [
    "stock",
    "sellPrice",
    "sellPriceOverride",
    "cashBuyPrice",
    "cashBuyPercent",
    "creditBuyPrice",
    "creditBuyPercent",
    "buyLimit",
    "overstockCashBuyPrice",
    "overstockCashBuyPercent",
    "overstockCreditBuyPrice",
    "overstockCreditBuyPercent",
    "updateButton",
  ];

  @action setColumnsToShow(columnsToShow) {
    this.columnsToShow = columnsToShow;
  }

  @observable columnsToUpdate = [
    "stock",
    "sellPrice",
    "sellPriceOverride",
    "cashBuyPrice",
    "cashBuyPercent",
    "creditBuyPrice",
    "creditBuyPercent",
    "buyLimit",
    "overstockCashBuyPrice",
    "overstockCashBuyPercent",
    "overstockCreditBuyPrice",
    "overstockCreditBuyPercent",
    "updateButton",
  ];

  @action setColumnsToUpdate(columnsToUpdate) {
    this.columnsToUpdate = columnsToUpdate;
  }

  sortTypes = [
    {
      label: "Title A-Z",
      value: {
        type: "title",
        asc: true,
      },
    },
    {
      label: "Title Z-A",
      value: {
        type: "title",
        asc: false,
      },
    },
    {
      label: "Card number ascending",
      value: {
        type: "number",
        asc: true,
      },
    },
    {
      label: "Card number descending",
      value: {
        type: "number",
        asc: false,
      },
    },
    {
      label: "Price high to low",
      value: {
        type: "price",
        asc: false,
      },
    },
    {
      label: "Price low to high",
      value: {
        type: "price",
        asc: true,
      },
    },
    {
      label: "Stock high to low",
      value: {
        type: "inventoryQuantity",
        asc: false,
      },
    },
    {
      label: "Stock low to high",
      value: {
        type: "inventoryQuantity",
        asc: true,
      },
    },
  ];

  @observable stockUpdateTypes = [
    {
      label: "Set inventory level",
      value: "set",
    },
    {
      label: "Add inventory level",
      value: "add",
    },
    {
      label: "Remove inventory level",
      value: "remove",
    },
  ];

  @observable selectedSortType = {
    label: "Title A-Z",
    value: {
      type: "title",
      asc: true,
    },
  };

  @action setSelectedSortType(selectedSortType) {
    this.selectedSortType = selectedSortType;
  }

  @observable reloadProductFilters = false;

  @action setReloadProductFilters(bool) {
    this.reloadProductFilters = bool;
  }

  @observable showProcessUpdateVariants = false;

  @action setShowProcessUpdateVariants(bool) {
    this.showProcessUpdateVariants = bool;
  }

  @observable exportType = "set";

  @action setExportType(value) {
    this.exportType = value;
  }

  @observable changedProducts = {};

  @action setChangedProducts(changedProducts) {
    this.changedProducts = changedProducts;
  }

  cancelVariantUpdate = () => {
    this.setShowProcessUpdateVariants(false);
  };

  updateCurrentInput = (value) => {
    this.setCurrentInput(value);
  };

  getCurrentInput = () => this.currentInput;

  getCurrentStockUpdateType = () => this.stockAddType;

  processLineByLineUpdateList = [];

  constructor(props) {
    super(props);
    this.advancedSearchForm = React.createRef();
    this.columnToggle = React.createRef();
    this.scrollTo = React.createRef();
    this.modalRef = React.createRef();
    this.listRef = React.createRef();
    this.state = { collapseVariants: false, hideOutOfStock: false };
  }

  @observable tagInputValue = "";

  @action
  handleInputChange = (e) => {
    this.tagInputValue = e;
  };

  setCollapseVariants = (collapseVariants) =>
    this.setState({ collapseVariants });

  setHideOutOfStock = (hideOutOfStock) => this.setState({ hideOutOfStock });

  loadOptions = async (inputValue, callback) => {
    const results = await this.props.store.ProductsStore.fetchTagsStartsWith(
      inputValue
    );
    return callback(this.buildOptionsList(results));
  };

  handleSelectedTagsChange = (selectedOptions) => {
    this.setSelectedTags(selectedOptions);
  };

  handleSelectedProductTypesChange = (selectedOptions) => {
    this.setSelectedProductTypes(selectedOptions);
    // TODO:: What is this live even for?
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const types = selectedOptions?.map((option) => option.value).join(",");
  };

  handleSelectedVendorsChange = (selectedOptions) => {
    this.setSelectedVendors(selectedOptions);
  };

  handleSelectedVariantsChange = (selectedOptions) => {
    this.setSelectedVariants(selectedOptions);
  };

  handleSelectedRaritiesChange = (selectedOptions) => {
    this.setSelectedRarities(selectedOptions);
  };

  handleSelectedSetsChange = (selectedOptions) => {
    this.setSelectedSets(selectedOptions);
  };

  handleSelectedCardTypesChange = (selectedOptions) => {
    this.setSelectedCardTypes(selectedOptions);
  };

  handleSelectedMonsterTypesChange = (selectedOptions) => {
    this.setSelectedMonsterTypes(selectedOptions);
  };

  handleSelectedColorsChange = (selectedOptions) => {
    this.setSelectedColors(selectedOptions);
  };

  handleSelectedClassesChange = (selectedClasses) => {
    this.setSelectedClasses(selectedClasses);
  };

  handleSelectedSubTypesChange = (selectedSubTypes) => {
    this.setSelectedSubTypes(selectedSubTypes);
  };

  handleSelectedFinishesChange = (selectedFinishes) => {
    this.setSelectedFinishes(selectedFinishes);
  };

  handleSelectedEditionsChange = (selectedEditions) => {
    this.setSelectedEditions(selectedEditions);
  };

  handleStockUpdateType = (selectedOption) => {
    this.setStockAddType(selectedOption.value);
  };

  handleSelectedPriceOverrideType = (selectedOption) => {
    this.setSelectedPriceOverrideType(selectedOption.value);
  };

  buildSearchString(searchFilters) {
    let searchUpdated = "";
    if (searchFilters.game && searchFilters.game.length > 0) {
      searchUpdated += `Game: ${searchFilters.game}, `;
    }
    if (searchFilters.title && searchFilters.title.length > 0) {
      searchUpdated += `Title: ${searchFilters.title}, `;
    }
    if (searchFilters.productTypes && searchFilters.productTypes.length > 0) {
      searchUpdated += `Product Types: ${this.buildListForSearchFilters(
        searchFilters.productTypes
      )}, `;
    }
    if (searchFilters.vendors && searchFilters.vendors.length > 0) {
      searchUpdated += `Vendors: ${this.buildListForSearchFilters(
        searchFilters.vendors
      )}, `;
    }
    if (searchFilters.tags && searchFilters.tags.length > 0) {
      searchUpdated += `Tags: ${this.buildListForSearchFilters(
        searchFilters.tags
      )}, `;
    }
    if (searchFilters.setNames && searchFilters.setNames.length > 0) {
      searchUpdated += `Set Names: ${this.buildListForSearchFilters(
        searchFilters.setNames
      )}, `;
    }
    if (searchFilters.variants && searchFilters.variants.length > 0) {
      searchUpdated += `Variants: ${this.buildListForSearchFilters(
        searchFilters.variants
      )}, `;
    }
    if (searchFilters.colors && searchFilters.colors.length > 0) {
      searchUpdated += `Colors: ${this.buildListForSearchFilters(
        searchFilters.colors
      )}, `;
    }
    if (searchFilters.types && searchFilters.types.length > 0) {
      searchUpdated += `Types: ${this.buildListForSearchFilters(
        searchFilters.types
      )}, `;
    }
    if (searchFilters.rarities && searchFilters.rarities.length > 0) {
      searchUpdated += `Rarities: ${this.buildListForSearchFilters(
        searchFilters.rarities
      )}, `;
    }
    if (searchFilters.classes && searchFilters.classes.length > 0) {
      searchUpdated += `Classes: ${this.buildListForSearchFilters(
        searchFilters.classes
      )}, `;
    }

    if (searchFilters.monsterTypes && searchFilters.monsterTypes.length > 0) {
      searchUpdated += `Monster types: ${this.buildListForSearchFilters(
        searchFilters.monsterTypes
      )}, `;
    }

    if (searchFilters.SubTypes && searchFilters.SubTypes.length > 0) {
      searchUpdated += `SubTypes: ${this.buildListForSearchFilters(
        searchFilters.SubTypes
      )}, `;
    }
    if (searchFilters.finishes && searchFilters.finishes.length > 0) {
      searchUpdated += `Finishes: ${this.buildListForSearchFilters(
        searchFilters.finishes
      )}, `;
    }

    if (searchFilters.editions && searchFilters.editions.length > 0) {
      searchUpdated += `Editions: ${this.buildListForSearchFilters(
        searchFilters.editions
      )}, `;
    }

    if (searchFilters.priceGreaterThan) {
      searchUpdated += `Price > ${searchFilters.priceGreaterThan}, `;
    }
    if (searchFilters.priceLessThan) {
      searchUpdated += `Price < ${searchFilters.priceLessThan}, `;
    }
    if (searchFilters.priceOverrideType) {
      searchUpdated += `Price override type: ${searchFilters.priceOverrideType}, `;
    }
    if (searchFilters.overallQuantityGreaterThan) {
      searchUpdated += `Overall Quantity > ${searchFilters.overallQuantityGreaterThan}, `;
    }
    if (searchFilters.overallQuantityLessThan) {
      searchUpdated += `Overall Quantity < ${searchFilters.overallQuantityLessThan}, `;
    }
    if (searchFilters.quantityGreaterThan) {
      searchUpdated += `Quantity > ${searchFilters.quantityGreaterThan}, `;
    }
    if (searchFilters.quantityLessThan) {
      searchUpdated += `Quantity < ${searchFilters.quantityLessThan}, `;
    }
    if (searchFilters.barcode && searchFilters.barcode.length > 0) {
      searchUpdated += `Barcode: ${searchFilters.barcode}, `;
    }
    if (searchFilters.sku && searchFilters.sku.length > 0) {
      searchUpdated += `Sku: ${searchFilters.sku}, `;
    }
    return searchUpdated.substring(0, searchUpdated.length - 2);
  }

  buildListForSearchFilters(list) {
    let listOfItemsToReturn = "[";
    list.forEach((item) => {
      listOfItemsToReturn += `${item}, `;
    });
    listOfItemsToReturn = listOfItemsToReturn.substring(
      0,
      listOfItemsToReturn.length - 2
    );
    listOfItemsToReturn += "]";
    return listOfItemsToReturn;
  }

  loadUserColumnsPreference() {
    this.props.store.ProductsStore.fetchColumnsToShow().then((columns) => {
      if (columns) {
        this.setColumnsToShow(columns);
        this.setColumnsToUpdate(columns);
      }
    });
  }

  @action
  async componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.initialiseSearch();
    }
    if (
      this.showBulkUpdate ||
      this.deleteModalVisible ||
      this.exportModalVisible
    ) {
      this.modalRef.current?.focus();
    } else {
      this.listRef.current?.focus();
    }
  }

  @action
  componentDidMount() {
    document.title = "Products | BinderPOS";
    this.props.store.MainStore.getCurrency();
    this.loadUserColumnsPreference();
    this.setupGenericFilter();
    this.currentSearch = {
      sortTypes: [
        {
          type: "title",
          asc: true,
        },
      ],
    };
    this.setProductCount(-1);
    this.props.store.ProductsStore.setProducts([]);
    this.props.store.ProductsStore.rehydrateSavedSearches();
    this.initialiseSearch();
  }
  setRadioChanged(self, e) {
    self.setExportType(e.currentTarget.value);
  }

  addRadioChanged(self, e) {
    self.setExportType(e.currentTarget.value);
  }

  initialiseSearch = () => {
    this.processLineByLineUpdateList = [];
    const query = new URLSearchParams(this.props.location.search);
    let search = this.currentSearch;
    let doSearch = false;
    if (query.has("search")) {
      try {
        search = JSON.parse(query.get("search"));
        if (search?.game && search.game !== this.currentSearch.game) {
          this.fetchInformationForGameType({
            value: search.game,
          });
        }
        this.setCurrentSearch(search);
        doSearch = true;
      } catch (error) {
        console.error(error);
      }
    }
    if (query.has("page")) {
      this.offset = Number(query.get("page")) * this.limit;
    }
    let sortBy = this.selectedSortType.value.type;
    let sortAsc = this.selectedSortType.value.asc;
    if (query.has("sortBy")) {
      sortBy = query.get("sortBy");
    }
    if (query.has("sortAsc")) {
      sortAsc = query.get("sortAsc") === "true";
    }
    const selectedSort =
      this.sortTypes.find(
        (sortType) =>
          sortType.value.type === sortBy && sortType.value.asc === sortAsc
      ) || this.selectedSortType;
    this.setSelectedSortType(selectedSort);

    if (doSearch) {
      this.search({
        ...search,
        offset: this.offset,
        sortBy: this.selectedSortType.value.type,
        sortAsc: this.selectedSortType.value.asc,
      });
    }
  };

  buildOptionsList(listToConvert) {
    const options = [];
    listToConvert.forEach((item) => {
      if (typeof item === "string") {
        if (item && item.length > 0) {
          options.push({ value: item, label: item });
        }
      } else if (item && item.value && item.value.length > 0) {
        options.push({ value: item.id, label: item.value });
      }
    });
    return options;
  }

  setupGenericFilter() {
    this.fetchCardGames();
    this.props.store.ProductsStore.buildGenericFilters().then(() => {
      this.setFiltersSetup(true);
    });
  }

  changeGameType() {
    this.props.store.CardStore.buildGameTypeData;
  }

  fetchInformationForGameType = (selectedOptions) => {
    this.setSelectedGame(selectedOptions.value);
    if (!selectedOptions.value || selectedOptions.value.length == 0) {
      this.props.store.CardStore.clearGameTypeData();
    } else {
      this.props.store.CardStore.buildGameTypeData(selectedOptions.value);
    }
    this.setSelectedVariants(null);
    this.setSelectedRarities(null);
    this.setSelectedSets(null);
    this.setSelectedCardTypes(null);
    this.setSelectedMonsterTypes(null);
    this.setSelectedColors(null);
    this.setSelectedClasses(null);
    this.setSelectedSubTypes(null);
    this.setSelectedFinishes(null);
    this.setSelectedEditions(null);
  };

  fetchInformationForFilterForGameType(game) {
    this.setSelectedGame(game);
    if (!game || game.length == 0) {
      this.props.store.CardStore.clearGameTypeData();
    } else {
      this.props.store.CardStore.buildGameTypeData(game);
    }
  }

  fetchCardGames() {
    this.props.store.CardStore.fetchCardGames()
      .then((results) => {
        const options = [{ value: "", label: "All games" }];
        results.forEach((item) => {
          options.push({ value: item.gameId, label: item.gameName });
        });
        this.props.store.CardStore.setGames(options);
      })
      .catch((error) => {
        this.props.store.MainStore.setError(
          error,
          "Failed to load games",
          "There was an error retrieving your list of supported games. Please refresh and try again"
        );
      });
  }

  cancelBulkUpdate = () => {
    this.setShowBulkUpdate(false);
    this.setVariantsToUpdate(null);
    this.setBulkUpdateType(null);
  };

  @observable
  importModal = false;

  @action
  showImportModal = (target) => {
    this.listRef.current = target;
    this.importModal = true;
  };

  @action
  closeImportModal = () => {
    this.importModal = false;
  };

  @observable
  tcgModal = false;

  @action
  showTcgModal = (target) => {
    this.listRef.current = target;
    this.tcgModal = true;
  };

  @action
  closeTcgModal = () => {
    this.tcgModal = false;
  };

  search = (searchObj) => {
    return this.props.store.ProductsStore.advancedSearch(
      searchObj || this.currentSearch
    )
      .then((result) => {
        this.setProductCount(result.count);
        this.props.store.ProductsStore.setProducts(result.products);
        this.scrollTo.current?.scrollIntoView();
      })
      .catch((err) => {
        console.error(err);
        this.props.store.MainStore.setError(
          err,
          "Failed to seach products",
          "There was an error submitting your search request. Please try again"
        );
      });
  };

  setProductToUpdateStock(product) {
    this.setProductInfoSaving(false);
    this.setProductInfo(product);
    this.setProductInfoModalVisible(true);
  }

  getValues(list) {
    return list && list.length > 0 ? list.map((s) => s.value) : [];
  }

  processBulkUpdate() {
    this.processLineByLineUpdateList = [];
    this.props.store.ProductsStore.setProducts(null);
    const elm = this.advancedSearchForm.current.elements;

    const selectedTags = this.getValues(this.selectedTags);
    const selectedProductTypes = this.getValues(this.selectedProductTypes);
    const selectedVendors = this.getValues(this.selectedVendors);
    const selectedVariants = this.getValues(this.selectedVariants);
    const selectedRarities = this.getValues(this.selectedRarities);
    const selectedCardTypes = this.getValues(this.selectedCardTypes);
    const selectedSets = this.getValues(this.selectedSets);
    const selectedMonsterTypes = this.getValues(this.selectedMonsterTypes);
    const selectedColors = this.getValues(this.selectedColors);
    const selectedClasses = this.getValues(this.selectedClasses);
    const selectedSubTypes = this.getValues(this.selectedSubTypes);
    const selectedFinishes = this.getValues(this.selectedFinishes);
    const selectedEditions = this.getValues(this.selectedEditions);

    const searchObj = {
      game: this.selectedGame,
      tags: selectedTags,
      productTypes: selectedProductTypes,
      vendors: selectedVendors,
      variants: selectedVariants,
      rarities: selectedRarities,
      types: selectedCardTypes,
      setNames: selectedSets,
      monsterTypes: selectedMonsterTypes,
      colors: selectedColors,
      fabClasses: selectedClasses,
      subTypes: selectedSubTypes,
      finishes: selectedFinishes,
      editions: selectedEditions,
      title:
        elm.title.value && elm.title.value.length > 0 ? elm.title.value : null,
      barcode:
        elm.barcode.value && elm.barcode.value.length > 0
          ? elm.barcode.value
          : null,
      sku: elm.sku.value && elm.sku.value.length > 0 ? elm.sku.value : null,
      priceOverrideType:
        this.selectedPriceOverrideType &&
        this.selectedPriceOverrideType.length > 0
          ? this.selectedPriceOverrideType
          : null,
      priceGreaterThan:
        elm.priceGreaterThan.value && elm.priceGreaterThan.value.length > 0
          ? parseFloat(elm.priceGreaterThan.value)
          : null,
      priceLessThan:
        elm.priceLessThan.value && elm.priceLessThan.value.length > 0
          ? parseFloat(elm.priceLessThan.value)
          : null,
      overallQuantityGreaterThan:
        elm.overallQuantityGreaterThan.value &&
        elm.overallQuantityGreaterThan.value.length > 0
          ? parseInt(elm.overallQuantityGreaterThan.value)
          : null,
      overallQuantityLessThan:
        elm.overallQuantityLessThan.value &&
        elm.overallQuantityLessThan.value.length > 0
          ? parseInt(elm.overallQuantityLessThan.value)
          : null,
      quantityGreaterThan:
        elm.quantityGreaterThan.value &&
        elm.quantityGreaterThan.value.length > 0
          ? parseInt(elm.quantityGreaterThan.value)
          : null,
      quantityLessThan:
        elm.quantityLessThan.value && elm.quantityLessThan.value.length > 0
          ? parseInt(elm.quantityLessThan.value)
          : null,
    };
    this.setCurrentSearch(searchObj);

    this.setBulkUpdateLoading(true);
    this.search(searchObj)
      .then(() => {
        this.setBulkUpdateType("bulk");
        this.setShowBulkUpdate(true);
      })
      .catch((error) => {
        this.props.store.MainStore.setError(
          error,
          "Failed to launch bulk update",
          "There was an error opening the bulk update modal. Please refresh the page and try again"
        );
      })
      .finally(() => this.setBulkUpdateLoading(false));
  }

  applyFilters(searchObj) {
    this.setReloadProductFilters(true);
    this.props.store.ProductsStore.setProducts(null);
    this.setCurrentSearch(searchObj);
    this.fetchInformationForFilterForGameType(searchObj.game);
    setTimeout(() => {
      this.setReloadProductFilters(false);
    }, 250);

    this.search(searchObj);
  }

  @action
  processAdvancedSearch(e?: React.FormEvent) {
    if (e) {
      e.preventDefault();
    }
    this.processLineByLineUpdateList = [];
    this.props.store.ProductsStore.setProducts(null);

    const elm = this.advancedSearchForm.current.elements;

    const selectedTags = this.getValues(this.selectedTags);
    const selectedProductTypes = this.getValues(this.selectedProductTypes);
    const selectedVendors = this.getValues(this.selectedVendors);
    const selectedVariants = this.getValues(this.selectedVariants);
    const selectedRarities = this.getValues(this.selectedRarities);
    const selectedCardTypes = this.getValues(this.selectedCardTypes);
    const selectedSets = this.getValues(this.selectedSets);
    const selectedMonsterTypes = this.getValues(this.selectedMonsterTypes);
    const selectedColors = this.getValues(this.selectedColors);
    const selectedClasses = this.getValues(this.selectedClasses);
    const selectedSubTypes = this.getValues(this.selectedSubTypes);
    const selectedFinishes = this.getValues(this.selectedFinishes);
    const selectedEditions = this.getValues(this.selectedEditions);
    const selectedTitle =
      elm.title.value && elm.title.value.length > 0 ? elm.title.value : null;
    const selectedBarcode =
      elm.barcode.value && elm.barcode.value.length > 0
        ? elm.barcode.value
        : null;
    const selectedSku =
      elm.sku.value && elm.sku.value.length > 0 ? elm.sku.value : null;
    const selectedPriceOverride =
      this.selectedPriceOverrideType &&
      this.selectedPriceOverrideType.length > 0
        ? this.selectedPriceOverrideType
        : null;
    const selectedPriceGreaterThan =
      elm.priceGreaterThan.value && elm.priceGreaterThan.value.length > 0
        ? parseFloat(elm.priceGreaterThan.value)
        : null;
    const selectedPriceLessThan =
      elm.priceLessThan.value && elm.priceLessThan.value.length > 0
        ? parseFloat(elm.priceLessThan.value)
        : null;
    const selectedOverallQtyGreaterThan =
      elm.overallQuantityGreaterThan.value &&
      elm.overallQuantityGreaterThan.value.length > 0
        ? parseInt(elm.overallQuantityGreaterThan.value)
        : null;
    const selectedOverallQtyLessThan =
      elm.overallQuantityLessThan.value &&
      elm.overallQuantityLessThan.value.length > 0
        ? parseInt(elm.overallQuantityLessThan.value)
        : null;
    const selectedVariantQtyGreaterThan =
      elm.quantityGreaterThan.value && elm.quantityGreaterThan.value.length > 0
        ? parseInt(elm.quantityGreaterThan.value)
        : null;
    const selectedVariantQtyLessThan =
      elm.quantityLessThan.value && elm.quantityLessThan.value.length > 0
        ? parseInt(elm.quantityLessThan.value)
        : null;

    const segmentEventProperties = {
      game: this.selectedGame,
      product_types: selectedProductTypes,
      vendors: selectedVendors,
      product_title: selectedTitle,
      tagged_with: selectedTags || [],
      barcode: selectedBarcode,
      sku: selectedSku,
      price_greater_than: selectedPriceGreaterThan,
      price_less_than: selectedPriceLessThan,
      overall_qty_greater_than: selectedOverallQtyGreaterThan,
      overall_qty_less_than: selectedOverallQtyLessThan,
      variant_qty_greater_than: selectedVariantQtyGreaterThan,
      variant_qty_less_than: selectedVariantQtyLessThan,
      price_override_type: selectedPriceOverride,
    };

    this.offset = 0;
    const searchObj = {
      game: this.selectedGame,
      tags: selectedTags,
      productTypes: selectedProductTypes,
      vendors: selectedVendors,
      variants: selectedVariants,
      rarities: selectedRarities,
      types: selectedCardTypes,
      setNames: selectedSets,
      monsterTypes: selectedMonsterTypes,
      colors: selectedColors,
      fabClasses: selectedClasses,
      subTypes: selectedSubTypes,
      finishes: selectedFinishes,
      editions: selectedEditions,
      limit: this.limit,
      offset: 0,
      title: selectedTitle,
      barcode: selectedBarcode,
      sku: selectedSku,
      priceOverrideType: selectedPriceOverride,
      priceGreaterThan: selectedPriceGreaterThan,
      priceLessThan: selectedPriceLessThan,
      overallQuantityGreaterThan: selectedOverallQtyGreaterThan,
      overallQuantityLessThan: selectedOverallQtyLessThan,
      quantityGreaterThan: selectedVariantQtyGreaterThan,
      quantityLessThan: selectedVariantQtyLessThan,
      sortTypes: this.selectedSortType
        ? [this.selectedSortType.value]
        : [
            {
              type: "title",
              asc: true,
            },
          ],
    };

    this.setCurrentSearch(searchObj);
    this.searchToParams(
      searchObj,
      0,
      this.selectedSortType.value.type,
      this.selectedSortType.value.asc
    );
    segmentAnalytics.track(
      APPLY_PRODUCT_FILTER_CLICKED,
      segmentEventProperties
    );
  }

  @observable showFilterSaveConfirmationModal = false;
  @action setShowFilterSaveConfirmationModal = (searchName) =>
    (this.showFilterSaveConfirmationModal = searchName);

  saveFilter() {
    const elm = this.advancedSearchForm.current.elements;

    const selectedTags = this.getValues(this.selectedTags);
    const selectedProductTypes = this.getValues(this.selectedProductTypes);
    const selectedVendors = this.getValues(this.selectedVendors);
    const selectedVariants = this.getValues(this.selectedVariants);
    const selectedRarities = this.getValues(this.selectedRarities);
    const selectedCardTypes = this.getValues(this.selectedCardTypes);
    const selectedSets = this.getValues(this.selectedSets);
    const selectedMonsterTypes = this.getValues(this.selectedMonsterTypes);
    const selectedColors = this.getValues(this.selectedColors);
    const selectedClasses = this.getValues(this.selectedClasses);
    const selectedSubTypes = this.getValues(this.selectedSubTypes);
    const selectedFinishes = this.getValues(this.selectedFinishes);
    const selectedEditions = this.getValues(this.selectedEditions);
    const searchName = elm.saveFilter.value;

    if (searchName && searchName.length > 0) {
      const searchObj = {
        name: searchName,
        game: this.selectedGame,
        tags: selectedTags,
        productTypes: selectedProductTypes,
        vendors: selectedVendors,
        variants: selectedVariants,
        rarities: selectedRarities,
        types: selectedCardTypes,
        setNames: selectedSets,
        monsterTypes: selectedMonsterTypes,
        colors: selectedColors,
        fabClasses: selectedClasses,
        subTypes: selectedSubTypes,
        finishes: selectedFinishes,
        editions: selectedEditions,
        limit: this.limit,
        title:
          elm.title.value && elm.title.value.length > 0
            ? elm.title.value
            : null,
        barcode:
          elm.barcode.value && elm.barcode.value.length > 0
            ? elm.barcode.value
            : null,
        sku: elm.sku.value && elm.sku.value.length > 0 ? elm.sku.value : null,
        priceOverrideType:
          this.selectedPriceOverrideType &&
          this.selectedPriceOverrideType.length > 0
            ? this.selectedPriceOverrideType
            : null,
        priceGreaterThan:
          elm.priceGreaterThan.value && elm.priceGreaterThan.value.length > 0
            ? parseFloat(elm.priceGreaterThan.value)
            : null,
        priceLessThan:
          elm.priceLessThan.value && elm.priceLessThan.value.length > 0
            ? parseFloat(elm.priceLessThan.value)
            : null,
        overallQuantityGreaterThan:
          elm.overallQuantityGreaterThan.value &&
          elm.overallQuantityGreaterThan.value.length > 0
            ? parseInt(elm.overallQuantityGreaterThan.value)
            : null,
        overallQuantityLessThan:
          elm.overallQuantityLessThan.value &&
          elm.overallQuantityLessThan.value.length > 0
            ? parseInt(elm.overallQuantityLessThan.value)
            : null,
        quantityGreaterThan:
          elm.quantityGreaterThan.value &&
          elm.quantityGreaterThan.value.length > 0
            ? parseInt(elm.quantityGreaterThan.value)
            : null,
        quantityLessThan:
          elm.quantityLessThan.value && elm.quantityLessThan.value.length > 0
            ? parseInt(elm.quantityLessThan.value)
            : null,
      };

      this.props.store.ProductsStore.saveSearch(searchObj)
        .then(() => {
          this.props.store.ProductsStore.rehydrateSavedSearches();
          this.setShowFilterSaveConfirmationModal(searchName);
        })
        .catch((err) => {
          this.props.store.MainStore.setError(
            err,
            "Failed to save search",
            "There was an error saving your search. Please try again"
          );
        });
    } else {
      this.props.store.MainStore.setError({
        error: "Search filter name cannot be empty",
        detailedError: "Please enter a name for you saved search",
      });
    }
  }

  changeSortType = (selectedOption) => {
    this.setSelectedSortType(selectedOption);
    this.processAdvancedSearch();
  };

  @action
  updateColumnShown(columnName) {
    const { checked } =
      this.columnToggle.current.elements[`${columnName}ColumnCheckbox`];
    if (checked) {
      if (!this.columnsToShow.includes(columnName)) {
        this.columnsToShow.push(columnName);
      }
    } else if (this.columnsToShow.includes(columnName)) {
      const index = this.columnsToShow.indexOf(columnName);
      if (index > -1) {
        this.columnsToShow.splice(index, 1);
      }
    }
  }

  processColumnUpdate() {
    this.props.store.ProductsStore.updateColumnsToShow(this.columnsToShow).then(
      () => notify.info("Columns updated successfully")
    );
    const toSet = JSON.parse(JSON.stringify(this.columnsToShow));
    this.setColumnsToUpdate(toSet);
  }

  // TODO:: This is bad.
  processLineUpdates = () => {
    this.processLineByLineUpdateList.forEach((functionToRun) => {
      functionToRun();
    });
    this.setShowProcessUpdateVariants(false);
    notify.info("Update to multiple variants has been queued");
  };

  showDeleteModal(searchFilter) {
    this.setSavedSearchFilterToDelete(searchFilter);
    this.setDeleteModalVisible(true);
  }

  deleteFilter(filterId) {
    this.setDeleteModalLoading(true);
    this.props.store.ProductsStore.deleteSavedSearchFilter(filterId)
      .then(() => {
        this.props.store.ProductsStore.rehydrateSavedSearches();
        this.setDeleteModalLoading(false);
        this.setDeleteModalVisible(false);
        this.setSavedSearchFilterToDelete(null);
      })
      .catch((err) => {
        this.setDeleteModalLoading(false);
        this.setDeleteModalVisible(false);
        this.setSavedSearchFilterToDelete(null);
        this.props.store.MainStore.setError(
          err,
          "Failed to delete filter",
          "There was an error deleting your saved filter. Please try again"
        );
      });
  }

  getDefaultValuesForFields(type) {
    let found;
    let selected;
    if (type == "game") {
      const selectedGame = this.props.store.CardStore.games;
      if (this.currentSearch && this.currentSearch.game) {
        found = selectedGame.filter(
          (game) => game.value == this.currentSearch.game
        );
        if (found && found.length > 0) {
          return found;
        }
      }
      return selectedGame[0];
    }
    if (type == "productTypes") {
      selected = this.buildOptionsList(
        this.props.store.ProductsStore.productTypes
      );
      if (this.currentSearch && this.currentSearch.productTypes) {
        found = selected.filter((s) =>
          this.currentSearch.productTypes.includes(s.value)
        );
        this.setSelectedProductTypes(found);
        return found;
      }
    }
    if (type == "vendors") {
      selected = this.buildOptionsList(this.props.store.ProductsStore.vendors);
      if (this.currentSearch && this.currentSearch.vendors) {
        found = selected.filter((s) =>
          this.currentSearch.vendors.includes(s.value)
        );
        this.setSelectedVendors(found);
        return found;
      }
    }
    if (type == "variants") {
      selected = this.buildOptionsList(this.props.store.CardStore.cardVariants);
      if (this.currentSearch && this.currentSearch.variants) {
        found = selected.filter((s) =>
          this.currentSearch.variants.includes(s.value)
        );
        this.setSelectedVariants(found);
        return found;
      }
    }
    if (type == "types") {
      selected = this.buildOptionsList(this.props.store.CardStore.cardTypes);
      if (this.currentSearch && this.currentSearch.types) {
        found = selected.filter((s) =>
          this.currentSearch.types.includes(s.value)
        );
        this.setSelectedCardTypes(found);
        return found;
      }
    }
    if (type == "tags") {
      selected = this.buildOptionsList(this.props.store.ProductsStore.tags);
      if (this.currentSearch && this.currentSearch.tags) {
        found = selected.filter((s) =>
          this.currentSearch.tags.includes(s.value)
        );
        if (found.length < 1) {
          found = this.buildOptionsList(this.currentSearch?.tags);
        }
        this.setSelectedTags(found);
        return found;
      }
    }
    if (type == "setNames") {
      selected = this.buildOptionsList(this.props.store.CardStore.sets);
      if (this.currentSearch && this.currentSearch.setNames) {
        found = selected.filter((s) =>
          this.currentSearch.setNames.includes(s.value)
        );
        this.setSelectedSets(found);
        return found;
      }
    }
    if (type == "rarities") {
      selected = this.buildOptionsList(this.props.store.CardStore.rarities);
      if (this.currentSearch && this.currentSearch.rarities) {
        found = selected.filter((s) =>
          this.currentSearch.rarities.includes(s.value)
        );
        this.setSelectedRarities(found);
        return found;
      }
    }
    if (type == "monsterTypes") {
      selected = this.buildOptionsList(this.props.store.CardStore.monsterTypes);
      if (this.currentSearch && this.currentSearch.monsterTypes) {
        found = selected.filter((s) =>
          this.currentSearch.monsterTypes.includes(s.value)
        );
        this.setSelectedMonsterTypes(found);
        return found;
      }
    }
    if (type == "fabClasses") {
      selected = this.buildOptionsList(this.props.store.CardStore.classes);
      if (this.currentSearch && this.currentSearch.fabClasses) {
        found = selected.filter((s) =>
          this.currentSearch.fabClasses.includes(s.value)
        );
        this.setSelectedClasses(found);
        return found;
      }
    }
    if (type == "subTypes") {
      selected = this.buildOptionsList(this.props.store.CardStore.subTypes);
      if (this.currentSearch && this.currentSearch.subTypes) {
        found = selected.filter((s) =>
          this.currentSearch.subTypes.includes(s.value)
        );
        this.setSelectedSubTypes(found);
        return found;
      }
    }
    if (type == "finishes") {
      selected = this.buildOptionsList(this.props.store.CardStore.finishes);
      if (this.currentSearch && this.currentSearch.finishes) {
        found = selected.filter((s) =>
          this.currentSearch.finishes.includes(s.value)
        );
        this.setSelectedFinishes(found);
        return found;
      }
    }
    if (type == "editions") {
      selected = this.buildOptionsList(this.props.store.CardStore.editions);
      if (this.currentSearch && this.currentSearch.editions) {
        found = selected.filter((s) =>
          this.currentSearch.editions.includes(s.value)
        );
        this.setSelectedEditions(found);
        return found;
      }
    }
    if (type == "colors") {
      selected = this.buildOptionsList(this.props.store.CardStore.colors);
      if (this.currentSearch && this.currentSearch.colors) {
        found = selected.filter((s) =>
          this.currentSearch.colors.includes(s.value)
        );
        this.setSelectedColors(found);
        return found;
      }
    }
    if (type == "priceOverrideType") {
      selected = this.buildOptionsList(priceOverrideTypes);
      if (this.currentSearch && this.currentSearch.priceOverrideType) {
        found = selected.find(
          (s) => this.currentSearch.priceOverrideType == s.value
        );
        if (!found) return null;
        this.setSelectedPriceOverrideType(found?.value);
        return found;
      }
    }
  }

  dismissExportModal() {
    this.setExportSuccessful(false);
    this.setExportModalVisible(false);
  }

  exportSearch() {
    this.setExportModalLoading(true);
    this.setExportSuccessful(false);
    this.processLineByLineUpdateList = [];
    const elm = this.advancedSearchForm.current.elements;

    const selectedTags = this.getValues(this.selectedTags);
    const selectedProductTypes = this.getValues(this.selectedProductTypes);
    const selectedVendors = this.getValues(this.selectedVendors);
    const selectedVariants = this.getValues(this.selectedVariants);
    const selectedRarities = this.getValues(this.selectedRarities);
    const selectedCardTypes = this.getValues(this.selectedCardTypes);
    const selectedSets = this.getValues(this.selectedSets);
    const selectedMonsterTypes = this.getValues(this.selectedMonsterTypes);
    const selectedColors = this.getValues(this.selectedColors);
    const selectedClasses = this.getValues(this.selectedClasses);
    const selectedSubTypes = this.getValues(this.selectedSubTypes);
    const selectedFinishes = this.getValues(this.selectedFinishes);
    const selectedEditions = this.getValues(this.selectedEditions);

    const searchObj = {
      game: this.selectedGame,
      tags: selectedTags,
      productTypes: selectedProductTypes,
      vendors: selectedVendors,
      variants: selectedVariants,
      rarities: selectedRarities,
      types: selectedCardTypes,
      setNames: selectedSets,
      monsterTypes: selectedMonsterTypes,
      colors: selectedColors,
      fabClasses: selectedClasses,
      subTypes: selectedSubTypes,
      finishes: selectedFinishes,
      editions: selectedEditions,
      title:
        elm.title.value && elm.title.value.length > 0 ? elm.title.value : null,
      barcode:
        elm.barcode.value && elm.barcode.value.length > 0
          ? elm.barcode.value
          : null,
      sku: elm.sku.value && elm.sku.value.length > 0 ? elm.sku.value : null,
      priceOverrideType:
        this.selectedPriceOverrideType &&
        this.selectedPriceOverrideType.length > 0
          ? this.selectedPriceOverrideType
          : null,
      priceGreaterThan:
        elm.priceGreaterThan.value && elm.priceGreaterThan.value.length > 0
          ? parseFloat(elm.priceGreaterThan.value)
          : null,
      priceLessThan:
        elm.priceLessThan.value && elm.priceLessThan.value.length > 0
          ? parseFloat(elm.priceLessThan.value)
          : null,
      overallQuantityGreaterThan:
        elm.overallQuantityGreaterThan.value &&
        elm.overallQuantityGreaterThan.value.length > 0
          ? parseInt(elm.overallQuantityGreaterThan.value)
          : null,
      overallQuantityLessThan:
        elm.overallQuantityLessThan.value &&
        elm.overallQuantityLessThan.value.length > 0
          ? parseInt(elm.overallQuantityLessThan.value)
          : null,
      quantityGreaterThan:
        elm.quantityGreaterThan.value &&
        elm.quantityGreaterThan.value.length > 0
          ? parseInt(elm.quantityGreaterThan.value)
          : null,
      quantityLessThan:
        elm.quantityLessThan.value && elm.quantityLessThan.value.length > 0
          ? parseInt(elm.quantityLessThan.value)
          : null,
      sortTypes: this.selectedSortType
        ? [this.selectedSortType.value]
        : [
            {
              type: "title",
              asc: true,
            },
          ],
    };

    this.setCurrentSearch(searchObj);
    this.props.store.CSVStore.exportProductsCSV(searchObj, this.exportType)
      .then(() => {
        this.setExportSuccessful(true);
      })
      .catch((err) => {
        this.props.store.MainStore.setError(
          err,
          "Failed to export CSV",
          "There was an error exporting your requested CSV. Please try again"
        );
        this.dismissExportModal();
      })
      .finally(() => {
        this.setExportModalLoading(false);
      });
  }

  @action
  async handleOffset(offset) {
    if (Object.keys(this.changedProducts).length > 0) {
      const changePage = confirm(
        "Are you sure you want to change pages? You have unsaved changes."
      );
      if (!changePage) return;
    }
    this.changedProducts = {};
    this.props.store.ProductsStore.setProducts(undefined);
    this.setOffset(offset);
    this.searchToParams(
      this.currentSearch,
      Math.floor(this.offset / this.limit),
      this.selectedSortType.value.type,
      this.selectedSortType.value.asc
    );
  }

  variantsEqual(newVariant, oldVariant) {
    return (
      newVariant.buyLimit == oldVariant.maxPurchaseQuantity &&
      newVariant.cashBuyPercentage == oldVariant.cashBuyPercent &&
      newVariant.cashBuyPrice == oldVariant.cashBuyPrice &&
      newVariant.creditBuyPrice == oldVariant.storeCreditBuyPrice &&
      newVariant.creditBuyPercentage == oldVariant.creditBuyPercent &&
      newVariant.maxInstockBuyPercentage ==
        oldVariant.maxInstockBuyPercentage &&
      newVariant.maxInstockBuyPrice == oldVariant.maxInstockBuyPrice &&
      newVariant.maxInstockCreditBuyPercentage ==
        oldVariant.maxInstockCreditBuyPercentage &&
      newVariant.priceOverride == oldVariant.priceOverride &&
      newVariant.stock == oldVariant.quantity
    );
  }

  @action
  handleChange(newVariant, oldVariant) {
    const existingChange = this.changedProducts[newVariant.id];
    if (!existingChange || !this.variantsEqual(newVariant, oldVariant))
      return (this.changedProducts = {
        ...this.changedProducts,
        [newVariant.id]: newVariant,
      });
    const newChangedProducts = {
      ...this.changedProducts,
    };
    delete newChangedProducts[newVariant.id];
    this.changedProducts = newChangedProducts;
  }

  render() {
    if (!this.props.store.AuthStore.screenSettings.inventoryManagement) {
      return (
        <div>
          <p>Please contact BinderPOS to enable this screen.</p>
        </div>
      );
    }

    if (
      !this.props.store.CardStore.games ||
      !this.props.store.ProductsStore.productTypes ||
      !this.props.store.ProductsStore.vendors ||
      !this.filtersSetup
    ) {
      return <Loader text="Loading products..." />;
    }

    return (
      <div id="inventoryManagement">
        <SectionHeaderLayout title="My Products">
          <ActionButtonsLayout>
            <ImportCsvButtons
              showImportModal={this.showImportModal}
              showTcgModal={this.showTcgModal}
            />
            <ButtonComponent
              primary
              icon="fas fa-caret-right"
              iconPosition="right"
              onClick={(event) => {
                this.listRef.current = event.currentTarget;
                this.processBulkUpdate();
              }}
              disabled={this.bulkUpdateLoading}
              segmentEventName={BULK_UPDATE_SEARCH_CLICKED}
            >
              Bulk update search
            </ButtonComponent>
            <ButtonComponent
              primary
              onClick={(event) => {
                this.listRef.current = event.currentTarget;
                this.setExportModalVisible(true);
              }}
              segmentEventName={EXPORT_PRODUCT_SEARCH_CLICKED}
            >
              Export search
            </ButtonComponent>
          </ActionButtonsLayout>
        </SectionHeaderLayout>
        <div className="filterTabs">
          <ActionButtonsLayout>
            {!this.minimizeFilters ? (
              <>
                <FilterTabButton
                  tabName="filters"
                  selectedTab={this.selectedTab}
                  onClick={() => this.setSelectedTab("filters")}
                  segmentEventName={PRODUCTS_MENU_CLICKED}
                  segmentEventProperties={{
                    menu_title: "Product filters",
                  }}
                >
                  Product filters
                </FilterTabButton>
                <FilterTabButton
                  tabName="fields"
                  selectedTab={this.selectedTab}
                  onClick={() => this.setSelectedTab("fields")}
                  segmentEventName={PRODUCTS_MENU_CLICKED}
                  segmentEventProperties={{
                    menu_title: "Modify fields",
                  }}
                >
                  Modify fields
                </FilterTabButton>

                <FilterTabButton
                  tabName="savedFilters"
                  selectedTab={this.selectedTab}
                  onClick={() => this.setSelectedTab("savedFilters")}
                  segmentEventName={PRODUCTS_MENU_CLICKED}
                  segmentEventProperties={{
                    menu_title: "Saved filters",
                  }}
                >
                  Saved Filters
                </FilterTabButton>
              </>
            ) : null}
            <FilterTabButton
              isLink
              to="/products/jobQueue"
              tabName="Queued Jobs"
              onClick={() =>
                segmentAnalytics.track(PRODUCTS_MENU_CLICKED, {
                  menu_title: "Queued Jobs",
                })
              }
            >
              Queued Jobs
            </FilterTabButton>

            <FilterTabButton
              isLink
              to="/products/exportHistory"
              tabName="CSV Export History"
              onClick={() =>
                segmentAnalytics.track(PRODUCTS_MENU_CLICKED, {
                  menu_title: "CSV Export History",
                })
              }
            >
              CSV Export History
            </FilterTabButton>
            <ButtonComponent
              secondary
              icon={this.minimizeFilters ? "fas fa-eye-slash" : "fas fa-eye"}
              iconPosition="right"
              onClick={() => this.setMinimizeFilters(!this.minimizeFilters)}
            >
              Hide Filters
            </ButtonComponent>
          </ActionButtonsLayout>
        </div>
        <div
          id="filters"
          className={
            (this.selectedTab && this.selectedTab != "filters") ||
            this.minimizeFilters
              ? "hidden"
              : ""
          }
        >
          {!this.reloadProductFilters ? (
            <form
              className="filters"
              ref={this.advancedSearchForm}
              onSubmit={(e) => this.processAdvancedSearch(e)}
            >
              <div className="topcontent">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12 col-xl-4">
                      <label className="label" htmlFor="select-game">
                        Select Game:
                      </label>
                      <div className="select2">
                        <Select
                          key={this.currentSearch?.game}
                          inputId="select-game"
                          classNamePrefix="react-select"
                          placeholder="Select game"
                          onChange={this.fetchInformationForGameType}
                          options={this.props.store.CardStore.games}
                          defaultValue={this.getDefaultValuesForFields("game")}
                        />
                        <span className="icon is-small is-left">
                          <i className="far fa-search" />
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12 col-xl-4">
                      <label className="label" htmlFor="product-type">
                        Product type:
                      </label>
                      <div className="select2">
                        <Select
                          key={this.currentSearch?.productTypes}
                          inputId="product-type"
                          classNamePrefix="react-select"
                          placeholder="Select product types"
                          closeMenuOnSelect={false}
                          onChange={this.handleSelectedProductTypesChange}
                          isMulti
                          options={this.buildOptionsList(
                            this.props.store.ProductsStore.productTypes
                          )}
                          defaultValue={this.getDefaultValuesForFields(
                            "productTypes"
                          )}
                        />
                        <span className="icon is-small is-left">
                          <i className="far fa-search" />
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12 col-xl-4">
                      <label className="label" htmlFor="select-vendor">
                        Vendor:
                      </label>
                      <div className="select2">
                        <Select
                          key={this.currentSearch?.vendors}
                          inputId="select-vendor"
                          classNamePrefix="react-select"
                          placeholder="Select vendors"
                          closeMenuOnSelect={false}
                          onChange={this.handleSelectedVendorsChange}
                          isMulti
                          options={this.buildOptionsList(
                            this.props.store.ProductsStore.vendors
                          )}
                          defaultValue={this.getDefaultValuesForFields(
                            "vendors"
                          )}
                        />
                        <span className="icon is-small is-left">
                          <i className="far fa-search" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filtersWrapper">
                <div className="container-fluid">
                  <div className="row">
                    <div
                      className={
                        this.props.store.CardStore.cardVariants &&
                        this.props.store.CardStore.cardVariants.length > 0
                          ? "col-sm-6 col-xl-5col"
                          : "col-sm-6 col-xl-3"
                      }
                    >
                      <label className="label" htmlFor="title">
                        Product title:
                      </label>
                      <input
                        id="title"
                        className="input"
                        type="text"
                        defaultValue={this.currentSearch?.title}
                      />
                    </div>
                    {this.props.store.CardStore.cardVariants &&
                    this.props.store.CardStore.cardVariants.length > 0 ? (
                      <div className="col-sm-6 col-xl-5col">
                        <label className="label" htmlFor="select-variants">
                          Variants:
                        </label>
                        <div className="select2">
                          <Select
                            key={this.currentSearch?.variants}
                            inputId="select-variants"
                            classNamePrefix="react-select"
                            placeholder="Select variants"
                            closeMenuOnSelect={false}
                            onChange={this.handleSelectedVariantsChange}
                            isMulti
                            options={this.buildOptionsList(
                              this.props.store.CardStore.cardVariants
                            )}
                            defaultValue={this.getDefaultValuesForFields(
                              "variants"
                            )}
                          />
                        </div>
                      </div>
                    ) : null}
                    {this.props.store.ProductsStore.tags &&
                    this.props.store.ProductsStore.tags.length > 0 ? (
                      <div
                        className={
                          this.props.store.CardStore.cardVariants &&
                          this.props.store.CardStore.cardVariants.length > 0
                            ? "col-sm-6 col-xl-5col"
                            : "col-sm-6 col-xl-3"
                        }
                      >
                        <label htmlFor="tagged-with" className="label">
                          Tagged with:
                        </label>
                        <div className="select2">
                          <AsyncSelect
                            key={this.currentSearch?.tags}
                            inputId="tagged-with"
                            classNamePrefix="react-select"
                            isMulti
                            cacheOptions
                            loadOptions={this.loadOptions}
                            defaultOptions={this.buildOptionsList(
                              this.props.store.ProductsStore.tags
                            )}
                            onChange={this.handleSelectedTagsChange}
                            onInputChange={this.handleInputChange}
                            defaultValue={this.getDefaultValuesForFields(
                              "tags"
                            )}
                          />
                        </div>
                      </div>
                    ) : null}
                    <div
                      className={
                        this.props.store.CardStore.cardVariants &&
                        this.props.store.CardStore.cardVariants.length > 0
                          ? "col-sm-6 col-xl-5col"
                          : "col-sm-6 col-xl-3"
                      }
                    >
                      <label className="label" htmlFor="barcode">
                        Product barcode:
                      </label>
                      <input
                        key={this.currentSearch?.barcode}
                        id="barcode"
                        className="input"
                        type="text"
                        defaultValue={this.currentSearch?.barcode}
                      />
                    </div>
                    <div
                      className={
                        this.props.store.CardStore.cardVariants &&
                        this.props.store.CardStore.cardVariants.length > 0
                          ? "col-sm-6 col-xl-5col"
                          : "col-sm-6 col-xl-3"
                      }
                    >
                      <label className="label" htmlFor="sku">
                        Product sku:
                      </label>
                      <input
                        key={this.currentSearch?.sku}
                        id="sku"
                        className="input"
                        type="text"
                        defaultValue={this.currentSearch?.sku}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="filtersWrapper2">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-4 col-xl-2">
                      <label className="label" htmlFor="priceGreaterThan">
                        Price greater than:
                      </label>
                      <input
                        key={this.currentSearch?.priceGreaterThan}
                        id="priceGreaterThan"
                        className="input"
                        type="number"
                        step="0.01"
                        min="0"
                        defaultValue={this.currentSearch?.priceGreaterThan}
                      />
                    </div>
                    <div className="col-sm-4 col-xl-2">
                      <label className="label" htmlFor="priceLessThan">
                        Price less than:
                      </label>
                      <input
                        key={this.currentSearch?.priceLessThan}
                        id="priceLessThan"
                        className="input"
                        type="number"
                        step="0.01"
                        min="0"
                        defaultValue={this.currentSearch?.priceLessThan}
                      />
                    </div>
                    <div className="col-sm-4 col-xl-2">
                      <label
                        className="label"
                        htmlFor="overallQuantityGreaterThan"
                      >
                        Overall qty greater than:
                      </label>
                      <input
                        key={this.currentSearch?.overallQuantityGreaterThan}
                        id="overallQuantityGreaterThan"
                        className="input"
                        type="number"
                        defaultValue={
                          this.currentSearch?.overallQuantityGreaterThan
                        }
                      />
                    </div>
                    <div className="col-sm-4 col-xl-2">
                      <label
                        className="label"
                        htmlFor="overallQuantityLessThan"
                      >
                        Overall qty less than:
                      </label>
                      <input
                        key={this.currentSearch?.overallQuantityLessThan}
                        id="overallQuantityLessThan"
                        className="input"
                        type="number"
                        defaultValue={
                          this.currentSearch?.overallQuantityLessThan
                        }
                      />
                    </div>
                    <div className="col-sm-4 col-xl-2">
                      <label className="label" htmlFor="quantityGreaterThan">
                        Variant qty greater than:
                      </label>
                      <input
                        key={this.currentSearch?.quantityGreaterThan}
                        id="quantityGreaterThan"
                        className="input"
                        type="number"
                        defaultValue={this.currentSearch?.quantityGreaterThan}
                      />
                    </div>
                    <div className="col-sm-4 col-xl-2">
                      <label className="label" htmlFor="quantityLessThan">
                        Variant qty less than:
                      </label>
                      <input
                        key={this.currentSearch?.quantityLessThan}
                        id="quantityLessThan"
                        className="input"
                        type="number"
                        defaultValue={this.currentSearch?.quantityLessThan}
                      />
                    </div>
                    {this.props.store.CardStore.rarities &&
                    this.props.store.CardStore.rarities.length > 0 ? (
                      <div className="col-sm-4 col-xl-2">
                        <label className="label" htmlFor="rarities">
                          Rarities:
                        </label>
                        <div className="select2">
                          <Select
                            inputId="rarities"
                            key={this.currentSearch?.rarities}
                            classNamePrefix="react-select"
                            placeholder="Select rarities"
                            closeMenuOnSelect={false}
                            onChange={this.handleSelectedRaritiesChange}
                            isMulti
                            options={this.buildOptionsList(
                              this.props.store.CardStore.rarities
                            )}
                            defaultValue={this.getDefaultValuesForFields(
                              "rarities"
                            )}
                          />
                        </div>
                      </div>
                    ) : null}
                    {this.props.store.CardStore.sets &&
                    this.props.store.CardStore.sets.length > 0 ? (
                      <div className="col-sm-4 col-xl-2">
                        <label className="label" htmlFor="sets">
                          Sets:
                        </label>
                        <div className="select2">
                          <Select
                            inputId="sets"
                            key={this.currentSearch?.setNames}
                            classNamePrefix="react-select"
                            closeMenuOnSelect={false}
                            onChange={this.handleSelectedSetsChange}
                            isMulti
                            options={this.buildOptionsList(
                              this.props.store.CardStore.sets
                            )}
                            defaultValue={this.getDefaultValuesForFields(
                              "setNames"
                            )}
                          />
                        </div>
                      </div>
                    ) : null}
                    {this.props.store.CardStore.cardTypes &&
                    this.props.store.CardStore.cardTypes.length > 0 ? (
                      <div className="col-sm-4 col-xl-2">
                        <label className="label" htmlFor="cardTypes">
                          Card types:
                        </label>
                        <div className="select2">
                          <Select
                            inputId="cardTypes"
                            key={this.currentSearch?.types}
                            classNamePrefix="react-select"
                            closeMenuOnSelect={false}
                            onChange={this.handleSelectedCardTypesChange}
                            isMulti
                            options={this.buildOptionsList(
                              this.props.store.CardStore.cardTypes
                            )}
                            defaultValue={this.getDefaultValuesForFields(
                              "types"
                            )}
                          />
                        </div>
                      </div>
                    ) : null}
                    {this.props.store.CardStore.colors &&
                    this.props.store.CardStore.colors.length > 0 ? (
                      <div className="col-sm-4 col-xl-2">
                        <label className="label" htmlFor="colors">
                          Colors:
                        </label>
                        <div className="select2">
                          <Select
                            inputId="colors"
                            key={this.currentSearch?.colors}
                            classNamePrefix="react-select"
                            closeMenuOnSelect={false}
                            onChange={this.handleSelectedColorsChange}
                            isMulti
                            options={this.buildOptionsList(
                              this.props.store.CardStore.colors
                            )}
                            defaultValue={this.getDefaultValuesForFields(
                              "colors"
                            )}
                          />
                        </div>
                      </div>
                    ) : null}
                    {this.props.store.CardStore.monsterTypes &&
                    this.props.store.CardStore.monsterTypes.length > 0 ? (
                      <div className="col-sm-4 col-xl-2">
                        <label className="label" htmlFor="monsterTypes">
                          Monster types:
                        </label>
                        <div className="select2">
                          <Select
                            inputId="monsterTypes"
                            key={this.currentSearch?.monsterTypes}
                            classNamePrefix="react-select"
                            closeMenuOnSelect={false}
                            onChange={this.handleSelectedMonsterTypesChange}
                            isMulti
                            options={this.buildOptionsList(
                              this.props.store.CardStore.monsterTypes
                            )}
                            defaultValue={this.getDefaultValuesForFields(
                              "monsterTypes"
                            )}
                          />
                        </div>
                      </div>
                    ) : null}
                    {this.props.store.CardStore.classes &&
                    this.props.store.CardStore.classes.length > 0 ? (
                      <div className="col-sm-4 col-xl-2">
                        <label className="label" htmlFor="classes">
                          Classes:
                        </label>
                        <div className="select2">
                          <Select
                            inputId="classes"
                            key={this.currentSearch?.fabClasses}
                            classNamePrefix="react-select"
                            closeMenuOnSelect={false}
                            onChange={this.handleSelectedClassesChange}
                            isMulti
                            options={this.buildOptionsList(
                              this.props.store.CardStore.classes
                            )}
                            defaultValue={this.getDefaultValuesForFields(
                              "fabClasses"
                            )}
                          />
                        </div>
                      </div>
                    ) : null}
                    {this.props.store.CardStore.subTypes &&
                    this.props.store.CardStore.subTypes.length > 0 ? (
                      <div className="col-sm-4 col-xl-2">
                        <label className="label" htmlFor="subTypes">
                          SubTypes:
                        </label>
                        <div className="select2">
                          <Select
                            inputId="subTypes"
                            key={this.currentSearch?.subTypes}
                            classNamePrefix="react-select"
                            closeMenuOnSelect={false}
                            onChange={this.handleSelectedSubTypesChange}
                            isMulti
                            options={this.buildOptionsList(
                              this.props.store.CardStore.subTypes
                            )}
                            defaultValue={this.getDefaultValuesForFields(
                              "subTypes"
                            )}
                          />
                        </div>
                      </div>
                    ) : null}
                    {this.props.store.CardStore.finishes &&
                    this.props.store.CardStore.finishes.length > 0 ? (
                      <div className="col-sm-4 col-xl-2">
                        <label className="label" htmlFor="finishes">
                          Finishes:
                        </label>
                        <div className="select2">
                          <Select
                            inputId="finishes"
                            key={this.currentSearch?.finishes}
                            classNamePrefix="react-select"
                            closeMenuOnSelect={false}
                            onChange={this.handleSelectedFinishesChange}
                            isMulti
                            options={this.buildOptionsList(
                              this.props.store.CardStore.finishes
                            )}
                            defaultValue={this.getDefaultValuesForFields(
                              "finishes"
                            )}
                          />
                        </div>
                      </div>
                    ) : null}
                    {this.props.store.CardStore.editions &&
                    this.props.store.CardStore.editions.length > 0 ? (
                      <div className="col-sm-4 col-xl-2">
                        <label className="label" htmlFor="editions">
                          Editions:
                        </label>
                        <div className="select2">
                          <Select
                            inputId="editions"
                            key={this.currentSearch?.editions}
                            classNamePrefix="react-select"
                            closeMenuOnSelect={false}
                            onChange={this.handleSelectedEditionsChange}
                            isMulti
                            options={this.buildOptionsList(
                              this.props.store.CardStore.editions
                            )}
                            defaultValue={this.getDefaultValuesForFields(
                              "editions"
                            )}
                          />
                        </div>
                      </div>
                    ) : null}
                    <div className="col-sm-4 col-xl-2">
                      <label className="label" htmlFor="price-override-type">
                        Price override type:
                      </label>
                      <div className="select2">
                        <Select
                          key={this.currentSearch?.priceOverrideType}
                          inputId="price-override-type"
                          classNamePrefix="react-select"
                          onChange={this.handleSelectedPriceOverrideType}
                          options={this.buildOptionsList(priceOverrideTypes)}
                          defaultValue={this.getDefaultValuesForFields(
                            "priceOverrideType"
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="saveFilterWrapper">
                        <input
                          id="saveFilter"
                          className="input"
                          type="text"
                          placeholder="My custom filter"
                        />
                        <ButtonComponent
                          primary
                          onClick={() => this.saveFilter()}
                        >
                          Save filter
                        </ButtonComponent>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filtersWrapper3">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-lg-12 col-xl-5">
                            <label className="label" htmlFor="select-action">
                              Action when updating inventory level:
                            </label>
                          </div>
                          <div className="col-lg-12 col-xl-7">
                            <div className="select2">
                              <Select
                                inputId="select-action"
                                classNamePrefix="react-select"
                                closeMenuOnSelect
                                onChange={(event) => {
                                  this.handleStockUpdateType(event);
                                  segmentAnalytics.track(
                                    INVENTORY_UPDATE_TYPE_SELECTED,
                                    { stock_update_type: event.value }
                                  );
                                }}
                                options={this.stockUpdateTypes}
                                defaultValue={this.stockUpdateTypes[0]}
                              />
                              <span className="icon is-small is-left">
                                <i className="far fa-search" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 applyFilters">
                      <ButtonComponent
                        primary
                        halfWidth
                        type="submit"
                        icon="fas fa-caret-right"
                        iconPosition="right"
                      >
                        Apply filters
                      </ButtonComponent>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          ) : null}
        </div>
        <div
          id="modifyFilters"
          className={
            this.selectedTab != "fields" || this.minimizeFilters ? "hidden" : ""
          }
        >
          <form ref={this.columnToggle} onSubmit={() => false}>
            <div className="modifyFilters">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6 col-lg-4 col-xl-3">
                    <CheckboxComponent
                      name="stockColumnCheckbox"
                      label="Show stock column"
                      onChange={() => this.updateColumnShown("stock")}
                      defaultChecked={this.columnsToShow.includes("stock")}
                    />
                  </div>
                  <div className="col-md-6 col-lg-4 col-xl-3">
                    <CheckboxComponent
                      name="reserveStockColumnCheckbox"
                      label="Show reserve stock column"
                      onChange={() => this.updateColumnShown("reserveStock")}
                      defaultChecked={this.columnsToShow.includes(
                        "reserveStock"
                      )}
                    />
                  </div>
                  <div className="col-md-6 col-lg-4 col-xl-3">
                    <CheckboxComponent
                      name="sellPriceColumnCheckbox"
                      label="Show sell price column"
                      onChange={() => this.updateColumnShown("sellPrice")}
                      defaultChecked={this.columnsToShow.includes("sellPrice")}
                    />
                  </div>
                  <div className="col-md-6 col-lg-4 col-xl-3">
                    <CheckboxComponent
                      name="sellPriceOverrideColumnCheckbox"
                      label="Show sell override column"
                      onChange={() =>
                        this.updateColumnShown("sellPriceOverride")
                      }
                      defaultChecked={this.columnsToShow.includes(
                        "sellPriceOverride"
                      )}
                    />
                  </div>
                  <div className="col-md-6 col-lg-4 col-xl-3">
                    <CheckboxComponent
                      name="cashBuyPriceColumnCheckbox"
                      label="Show cash buy column"
                      onChange={() => this.updateColumnShown("cashBuyPrice")}
                      defaultChecked={this.columnsToShow.includes(
                        "cashBuyPrice"
                      )}
                    />
                  </div>
                  <div className="col-md-6 col-lg-4 col-xl-3">
                    <CheckboxComponent
                      name="cashBuyPercentColumnCheckbox"
                      label="Show cash percent column"
                      onChange={() => this.updateColumnShown("cashBuyPercent")}
                      defaultChecked={this.columnsToShow.includes(
                        "cashBuyPercent"
                      )}
                    />
                  </div>
                  <div className="col-md-6 col-lg-4 col-xl-3">
                    <CheckboxComponent
                      name="creditBuyPriceColumnCheckbox"
                      label="Show credit buy column"
                      onChange={() => this.updateColumnShown("creditBuyPrice")}
                      defaultChecked={this.columnsToShow.includes(
                        "creditBuyPrice"
                      )}
                    />
                  </div>
                  <div className="col-md-6 col-lg-4 col-xl-3">
                    <CheckboxComponent
                      name="creditBuyPercentColumnCheckbox"
                      label="Show credit buy % column"
                      onChange={() =>
                        this.updateColumnShown("creditBuyPercent")
                      }
                      defaultChecked={this.columnsToShow.includes(
                        "creditBuyPercent"
                      )}
                    />
                  </div>
                  <div className="col-md-6 col-lg-4 col-xl-3">
                    <CheckboxComponent
                      name="buyLimitColumnCheckbox"
                      label="Show buy limit column"
                      onChange={() => this.updateColumnShown("buyLimit")}
                      defaultChecked={this.columnsToShow.includes("buyLimit")}
                    />
                  </div>
                  <div className="col-md-6 col-lg-4 col-xl-3">
                    <CheckboxComponent
                      name="overstockCashBuyPriceColumnCheckbox"
                      label="Show overstock cash buy column"
                      onChange={() =>
                        this.updateColumnShown("overstockCashBuyPrice")
                      }
                      defaultChecked={this.columnsToShow.includes(
                        "overstockCashBuyPrice"
                      )}
                    />
                  </div>
                  <div className="col-md-6 col-lg-4 col-xl-3">
                    <CheckboxComponent
                      name="overstockCashBuyPercentColumnCheckbox"
                      label="Show overstock cash percent column"
                      onChange={() =>
                        this.updateColumnShown("overstockCashBuyPercent")
                      }
                      defaultChecked={this.columnsToShow.includes(
                        "overstockCashBuyPercent"
                      )}
                    />
                  </div>
                  <div className="col-md-6 col-lg-4 col-xl-3">
                    <CheckboxComponent
                      name="overstockCreditBuyPriceColumnCheckbox"
                      label="Show overstock credit buy column"
                      onChange={() =>
                        this.updateColumnShown("overstockCreditBuyPrice")
                      }
                      defaultChecked={this.columnsToShow.includes(
                        "overstockCreditBuyPrice"
                      )}
                    />
                  </div>
                  <div className="col-md-6 col-lg-4 col-xl-3">
                    <CheckboxComponent
                      name="overstockCreditBuyPercentColumnCheckbox"
                      label="Show overstock credit buy % column"
                      onChange={() =>
                        this.updateColumnShown("overstockCreditBuyPercent")
                      }
                      defaultChecked={this.columnsToShow.includes(
                        "overstockCreditBuyPercent"
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div
            className="filtersWrapper3"
            style={{ margin: "0", marginRight: "15px" }}
          >
            <div className="row">
              <div className="col-lg-4 col-xl-6">&nbsp;</div>
              <div className="col-lg-4 col-xl-6 applyFilters">
                <ButtonComponent
                  primary
                  halfWidth
                  onClick={() => this.processColumnUpdate()}
                  icon="fas fa-caret-right"
                  iconPosition="right"
                  segmentEventName={UPDATE_COLUMNS_CLICKED}
                  segmentEventProperties={{
                    stock_column: this.columnsToShow.includes("stock"),
                    cash_buy_column:
                      this.columnsToShow.includes("cashBuyPrice"),
                    buy_limit_column: this.columnsToShow.includes("buyLimit"),
                    overstock_credit_buy_percent_column:
                      this.columnsToShow.includes("overstockCreditBuyPercent"),
                    reserve_stock_column:
                      this.columnsToShow.includes("reserveStock"),
                    cash_percent_column:
                      this.columnsToShow.includes("cashBuyPercent"),
                    sell_price_column: this.columnsToShow.includes("sellPrice"),
                    credit_buy_column:
                      this.columnsToShow.includes("creditBuyPrice"),
                    overstock_cash_percent_column: this.columnsToShow.includes(
                      "overstockCashBuyPercent"
                    ),
                    sell_override_column:
                      this.columnsToShow.includes("sellPriceOverride"),
                    credit_buy_percent_column:
                      this.columnsToShow.includes("creditBuyPercent"),
                    overstock_credit_buy_column: this.columnsToShow.includes(
                      "overstockCreditBuyPrice"
                    ),
                    overstock_cash_buy_price: this.columnsToShow.includes(
                      "overstockCashBuyPrice"
                    ),
                  }}
                >
                  Update columns
                </ButtonComponent>
              </div>
            </div>
          </div>
        </div>
        <div
          id="savedFilters"
          className={
            this.selectedTab != "savedFilters" || this.minimizeFilters
              ? "hidden"
              : ""
          }
        >
          <div className="savedFilters">
            <div className="filterBar">
              <div className="row">
                <div className="col-sm-3">Filter name</div>
                <div className="col-sm-9">Game</div>
              </div>
            </div>
            <div className="container-fluid">
              {this.props.store.ProductsStore.savedSearches ? (
                <>
                  {this.props.store.ProductsStore.savedSearches.map(
                    (savedSearch, i) => (
                      <React.Fragment key={i}>
                        <div className="row">
                          <div className="col-sm-3 savedName">
                            {savedSearch.name}
                          </div>
                          <div className="col-sm-6 savedQueries">
                            {this.buildSearchString(savedSearch)}
                          </div>
                          <div className="col-sm-3 savedActions">
                            <IconButton
                              title="Delete this saved filter"
                              icon="fal fa-trash"
                              onClick={(event) => {
                                this.listRef.current = event.currentTarget;
                                this.showDeleteModal(savedSearch);
                              }}
                            />
                            <ButtonComponent
                              primary
                              onClick={() => this.applyFilters(savedSearch)}
                            >
                              Apply filter
                            </ButtonComponent>
                          </div>
                        </div>
                        <hr />
                      </React.Fragment>
                    )
                  )}
                </>
              ) : null}
            </div>
          </div>
        </div>
        <br />
        {this.props.store.ProductsStore.products && this.productCount > -1 ? (
          <>
            <div className="container-fluid">
              <div
                ref={this.scrollTo}
                className="row"
                style={{ marginBottom: 10 }}
              >
                <NumberPagination
                  offset={this.offset}
                  setOffset={(offset) => {
                    this.handleOffset(offset);
                  }}
                  limit={this.limit}
                  productCount={this.productCount}
                />
                <FilterVariantsSelect
                  setCollapseVariants={this.setCollapseVariants}
                  collapseVariants={this.state.collapseVariants}
                  hideOutOfStock={this.state.hideOutOfStock}
                  setHideOutOfStock={this.setHideOutOfStock}
                  applyToAllProducts
                />
                <SortBy
                  // @ts-ignore
                  sortTypes={this.sortTypes}
                  // @ts-ignore
                  selectedSortType={this.selectedSortType}
                  changeSortType={(option) => this.changeSortType(option)}
                />
              </div>
            </div>
            <div className="container-fluid resultsPanel">
              {this.props.store.ProductsStore.products.map((product, i) => (
                <ProductLineItem
                  setUpdateProduct={(theFunction) =>
                    this.processLineByLineUpdateList.push(theFunction)
                  }
                  key={i}
                  product={product}
                  listIndex={i}
                  columnsToShow={this.columnsToUpdate}
                  currentInput={this.getCurrentInput}
                  updateCurrentInput={this.updateCurrentInput}
                  getStockUpdateType={this.getCurrentStockUpdateType}
                  onChange={(newVariant, oldVariant) =>
                    this.handleChange(newVariant, oldVariant)
                  }
                  onComplete={(id) => {
                    const changedProducts = {
                      ...this.changedProducts,
                    };
                    delete changedProducts[id];
                    this.setChangedProducts(changedProducts);
                  }}
                  currency={this.props.store.MainStore.currency}
                  collapseVariants={this.state.collapseVariants}
                  hideOutOfStock={this.state.hideOutOfStock}
                />
              ))}
            </div>
            {this.showProcessUpdateVariants ? (
              <ConfirmationModal
                title="Update variants?"
                cancelAction={this.cancelVariantUpdate}
                confirmAction={this.processLineUpdates}
                ref={this.modalRef}
              >
                Do you want to update the above variants? This cannot be undone.
              </ConfirmationModal>
            ) : null}
            <div style={{ display: "flex", columnGap: "1rem" }}>
              <NumberPagination
                offset={this.offset}
                setOffset={(offset) => this.handleOffset(offset)}
                limit={this.limit}
                productCount={this.productCount}
              />
              <ButtonComponent
                primary
                halfWidth
                icon="fas fa-caret-right"
                iconPosition="right"
                onClick={() => this.setShowProcessUpdateVariants(true)}
                segmentEventName={UPDATE_PRODUCT_LIST_CLICKED}
              >
                Update
              </ButtonComponent>
            </div>
          </>
        ) : null}
        {!this.props.store.ProductsStore.products ? (
          <Loader text="Loading products..." />
        ) : null}
        {this.showBulkUpdate ? (
          <BulkUpdateModal
            search={this.currentSearch}
            submitType={this.bulkUpdateType}
            variants={this.variantsToUpdate}
            productCount={this.productCount}
            cancelAction={this.cancelBulkUpdate}
            getStockUpdateType={this.getCurrentStockUpdateType}
            ref={this.modalRef}
          />
        ) : null}
        {this.deleteModalVisible ? (
          <ConfirmationModal
            ref={this.modalRef}
            cancelAction={() => this.setDeleteModalVisible(false)}
            confirmWord="Delete"
            confirmAction={() =>
              this.deleteFilter(this.savedSearchFilterToDelete.id)
            }
          >
            <p>
              If you proceed with deleting your saved filter:{" "}
              <strong>{this.savedSearchFilterToDelete.name}</strong>, you will
              not be able to restore it.
            </p>
            <br />
            <p>Are you sure you want to delete your saved filter?</p>
          </ConfirmationModal>
        ) : null}
        {this.exportModalVisible ? (
          <Modal
            ref={this.modalRef}
            small
            onClose={() => this.dismissExportModal()}
          >
            <Modal.Header>CSV Export</Modal.Header>
            <Modal.Content>
              {this.exportSuccessful ? (
                <>
                  <p>
                    Your search results are being exported and you will shortly
                    receive an email when your file is ready to download.
                  </p>
                  <p>
                    You will be able to view your file, along with{" "}
                    <Link
                      to="/products/exportHistory"
                      onClick={() =>
                        segmentAnalytics.track(VIEW_PREVIOUS_EXPORTS_CLICKED)
                      }
                    >
                      previously exported searches here.
                    </Link>
                  </p>
                </>
              ) : (
                <>
                  <p>
                    What format would you like the CSV in? Default is Set stock
                    format
                  </p>
                  <br />
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="stockRadio"
                      id="setStockRadio"
                      onChange={(e) => this.setRadioChanged(this, e)}
                      value="set"
                    />
                    <label className="form-check-label" htmlFor="setStockRadio">
                      Set stock format
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="stockRadio"
                      id="addStockRadio"
                      onChange={(e) => this.addRadioChanged(this, e)}
                      value="add"
                    />
                    <label className="form-check-label" htmlFor="addStockRadio">
                      Add stock format
                    </label>
                  </div>
                  <br />
                  <p>
                    View your{" "}
                    <Link
                      to="/products/exportHistory"
                      onClick={() =>
                        segmentAnalytics.track(VIEW_PREVIOUS_EXPORTS_CLICKED)
                      }
                    >
                      previously exported searches
                    </Link>
                  </p>
                </>
              )}
            </Modal.Content>
            <Modal.Actions>
              {this.exportSuccessful ? (
                <ButtonComponent
                  primary
                  onClick={() => this.dismissExportModal()}
                >
                  Ok
                </ButtonComponent>
              ) : (
                <>
                  <ButtonComponent
                    primary
                    onClick={() => this.exportSearch()}
                    disabled={this.exportModalLoading}
                    segmentEventName={EXPORT_SEARCH_CSV_CLICKED}
                    segmentEventProperties={{
                      csv_format_type: this.exportType,
                    }}
                  >
                    Export
                  </ButtonComponent>
                  <ButtonComponent
                    onClick={() => this.dismissExportModal()}
                    segmentEventName={CANCEL_EXPORT_SEARCH_CSV_CLICKED}
                  >
                    Cancel
                  </ButtonComponent>
                </>
              )}
            </Modal.Actions>
          </Modal>
        ) : null}
        {this.importModal ? (
          <CSVImportModal
            ref={this.modalRef}
            onClose={this.closeImportModal}
            uploadCSV={this.props.store.ProductsStore.uploadCSV}
          />
        ) : null}
        {this.tcgModal ? (
          <ExternalCSVImportModal
            ref={this.modalRef}
            onClose={this.closeTcgModal}
            uploadCSV={this.props.store.ProductsStore.uploadTcgCSV}
          />
        ) : null}
        {this.showFilterSaveConfirmationModal ? (
          <Modal
            small
            onClose={() => this.setShowFilterSaveConfirmationModal(undefined)}
          >
            <Modal.Header>Search filter saved!</Modal.Header>
            <Modal.Content>
              Your search filter '{this.showFilterSaveConfirmationModal}' has
              been saved!
            </Modal.Content>
          </Modal>
        ) : null}
      </div>
    );
  }
}

export default withRouter(ProductList);
