import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SortType } from "rsuite-table";
import BuylistTable from "../../components/buylist/BuylistTable";
import DateSelect from "../../components/generic/DateSelect";
import Paging from "../../components/generic/Paging";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";
import { DEFAULT_BUYLISTS_PER_PAGE } from "../../constants/buylist";
import { POS_BUYLIST_COMPLETED_DATE_FILTER } from "../../constants/featureFlags";
import { useGetBuylistsQuery } from "../../generated/graphql";
import { useShowError } from "../../hooks/errorHooks";
import { useDebounce } from "../../hooks/inputHooks";
import useTitle from "../../hooks/useTitle";
import BuylistMenuStructure from "../../menuStructures/BuylistMenuStructure";
import SetActiveMenu from "../../menuStructures/SetActiveMenu";
import { capitalizeFirstLetter } from "../../utils/formatting";
import { useQueryState } from "../../hooks/stateHooks";
import {
  getSortOptions,
  getFilterOptions,
  buylistStatusMap,
} from "./buylistsTableHelpers";
import { useFeatureFlag } from "../../hooks/globalSettingsHooks";
import "./Buylists.scss";

type RouteParams = {
  status: keyof typeof buylistStatusMap;
};

type SortParams = { key: string; order: SortType };

function Buylists() {
  const { status } = useParams<RouteParams>();
  const posBuylistCompletedDateFilterEnabled = useFeatureFlag(
    POS_BUYLIST_COMPLETED_DATE_FILTER
  );
  const [currentPage, setCurrentPage] = useQueryState<number>("page", 0);
  const [buylistFilters, setBuylistFilters] = useQueryState<
    Record<string, any>
  >("filters", {});
  const [sortColumn, setSortColumn] = useState<SortParams>({
    key: "dateSubmitted",
    order: "asc",
  });
  const { name: buylistName, searchFields } =
    buylistStatusMap[status] || buylistStatusMap.pending;
  const title = capitalizeFirstLetter(`${buylistName} buylists`);
  useTitle(title);
  const showError = useShowError();
  const debouncedFilterValues = useDebounce(buylistFilters, 500);
  const { data, isLoading, isPreviousData, error } = useGetBuylistsQuery(
    {
      where: { ...searchFields, ...getFilterOptions(debouncedFilterValues) },
      take: DEFAULT_BUYLISTS_PER_PAGE,
      skip: currentPage * DEFAULT_BUYLISTS_PER_PAGE,
      orderBy: getSortOptions(sortColumn.key, sortColumn.order),
    },
    {
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (error) {
      showError(error as DetailedError);
    }
  }, [error]);

  useEffect(() => {
    setCurrentPage(0);
    setBuylistFilters({});
  }, [status]);

  useEffect(() => {
    setCurrentPage(0);
  }, [buylistFilters]);

  const prevPage = () => setCurrentPage((page) => Math.max(0, page - 1));

  const nextPage = () => setCurrentPage((page) => page + 1);

  const handleFilterValueChange = (newFilters: Record<string, string>) =>
    setBuylistFilters((filters) => ({
      ...filters,
      ...newFilters,
    }));

  const handleSortOrderChange = (key: string, order: SortType) => {
    setSortColumn({ key, order });
    setCurrentPage(0);
  };

  return (
    <div className="Buylists">
      <SetActiveMenu menuStructure={BuylistMenuStructure} />
      <SectionHeaderLayout title={title} />
      <div className="Buylists__table-controls">
        <div className="Buylists__filters-wrapper">
          <DateSelect
            filterValues={buylistFilters || {}}
            updateFilterValue={handleFilterValueChange}
            title="Submitted"
          />
          {status === buylistStatusMap.completed.name &&
          posBuylistCompletedDateFilterEnabled ? (
            <DateSelect
              filterValues={buylistFilters || {}}
              updateFilterValue={handleFilterValueChange}
              title="Completed"
            />
          ) : null}
          {status === buylistStatusMap.approved.name &&
          posBuylistCompletedDateFilterEnabled ? (
            <DateSelect
              filterValues={buylistFilters || {}}
              updateFilterValue={handleFilterValueChange}
              title="Approved"
            />
          ) : null}
        </div>
        <Paging
          pageOffset={currentPage * DEFAULT_BUYLISTS_PER_PAGE}
          currentPageItemCount={(data?.ShopifyCustomerBuylist || []).length}
          maxItemsPerPage={DEFAULT_BUYLISTS_PER_PAGE}
          getPrevPage={prevPage}
          getNextPage={nextPage}
        />
      </div>
      <BuylistTable
        buylistType={buylistName}
        buylists={data?.ShopifyCustomerBuylist || []}
        viewUrl={`/buylists/${status}/moreDetail`}
        isLoading={isLoading || isPreviousData}
        filterValues={buylistFilters || {}}
        updateFilterValue={handleFilterValueChange}
        sortBy={sortColumn}
        onColumnSort={handleSortOrderChange}
      />
      {(data?.ShopifyCustomerBuylist || []).length > 10 ? (
        <Paging
          pageOffset={currentPage * DEFAULT_BUYLISTS_PER_PAGE}
          currentPageItemCount={(data?.ShopifyCustomerBuylist || []).length}
          maxItemsPerPage={DEFAULT_BUYLISTS_PER_PAGE}
          getPrevPage={prevPage}
          getNextPage={nextPage}
        />
      ) : null}
    </div>
  );
}

export default Buylists;
