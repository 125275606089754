import { NavLink } from "react-router-dom";
import { MenuLink, MenuStructure } from "../../types/nav";
import "./NewMobileNavItemLink.scss";
import { segmentAnalytics } from "../services/Analytics";
import { MOBILE_MENU_CLICKED } from "../../constants/eventsTracked";

interface NewMobileNavItemLinkProps {
  navItemLink: MenuLink;
  menuClickHandler: Function;
  parentStructure: MenuStructure;
  setNavItemOpen: (open: boolean) => void;
}

function NewMobileNavItemLink({
  navItemLink,
  menuClickHandler,
  parentStructure,
  setNavItemOpen,
}: NewMobileNavItemLinkProps) {
  if (navItemLink.clickHandler) {
    return (
      <div className="NewMobileNavItemLink">
        <button
          className="NewMobileNavItemLink__Button"
          onClick={() => {
            navItemLink.clickHandler();
            menuClickHandler(null);
          }}
          onFocus={() => setNavItemOpen(true)}
        >
          {navItemLink.title}
        </button>
      </div>
    );
  }

  if (navItemLink.external) {
    return (
      <div className="NewMobileNavItemLink">
        <a
          className="NewMobileNavItemLink__Link"
          href={navItemLink.href}
          target="_blank"
          rel="noopner"
        >
          {navItemLink.title}
        </a>
      </div>
    );
  }

  return (
    <div className="NewMobileNavItemLink">
      <NavLink
        className="NewMobileNavItemLink__Link"
        activeClassName="active"
        to={`/${navItemLink.href}`}
        onClick={() => {
          segmentAnalytics.track(MOBILE_MENU_CLICKED, {
            menu_title: navItemLink.title,
          });
          menuClickHandler(parentStructure);
        }}
        onFocus={() => setNavItemOpen(true)}
      >
        {navItemLink.title}
      </NavLink>
    </div>
  );
}

export default NewMobileNavItemLink;
