import { _fetch } from "../../api";
import { CFB_MARKETPLACE_BASE_URL } from "../../constants/api";

export const fetchApiKey = () =>
  _fetch({
    method: "GET",
    endpoint: `${CFB_MARKETPLACE_BASE_URL}/api`,
  });

export const updateApiKey = (apiKey: string, apiPassword: string) =>
  _fetch({
    method: "POST",
    endpoint: `${CFB_MARKETPLACE_BASE_URL}/api`,
    payload: {
      apiKey,
      apiPassword,
    },
  });
