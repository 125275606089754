export const cartCsvHeaders = [
  { name: "Title", key: "title" },
  { name: "Variant", key: "variantTitle" },
  {
    name: "Price",
    key: "price",
    formatter: (number: number) => number && number.toFixed(2),
  },
  { name: "Quantity", key: "quantity" },
  { name: "Buy mode", key: "buying" },
  { name: "Taxable", key: "taxable" },
  {
    name: "Discount",
    key: "discountAmount",
    formatter: (number: number) => number && number.toFixed(2),
  },
];

export const importerCompatibleCartCsvHeaders = [
  {
    name: "Game Type",
    key: "type",
  },
  { name: "Variant Id", key: "variantId" },
  { name: "Product Title", key: "productTitle" },
  { name: "Variant Title", key: "variantTitle" },
  { name: "Add Stock", key: "quantity" },
  {
    name: "Current Price",
    key: "price",
  },
  {
    name: "Price Override",
    key: "priceOverride",
  },
  {
    name: "Cash Buy Price",
    key: "cashBuyPrice",
  },
  {
    name: "Cash Buy Percent",
    key: "cashBuyPercent",
  },
  {
    name: "Store Credit Buy Price",
    key: "storeCreditBuyPrice",
  },
  {
    name: "Store Credit Buy Percent",
    key: "storeCreditBuyPercent",
  },
  {
    name: "Buy Limit",
    key: "buyLimit",
  },
  {
    name: "Overstocked Cash Buy Price",
    key: "overstockedCashPrice",
  },
  {
    name: "Overstocked Cash Buy Percentage",
    key: "overstockedCashBuyPercentage",
  },
  {
    name: "Overstocked Store Credit Buy Price",
    key: "overstockedStoreCreditBuyPrice",
  },
  {
    name: "Overstocked Store Credit Buy Percentage",
    key: "overstockedStoreCreditBuyPercentage",
  },
  {
    name: "Reserve Quantity",
    key: "reserveQuantity",
  },
];
