import { _fetch } from "../../api";
import { BASE_URL, API_V2_BASE_URL } from "../../constants/api";

export const fetchSearchResults = async (
  query,
  includeSingles,
  buyMode,
  offset = 0,
  limit = 50,
  inStockOnly = false
) =>
  _fetch({
    endpoint:
      `${BASE_URL}/products?` +
      `limit=${limit}` +
      `&offset=${offset}` +
      `&keyword=${encodeURIComponent(query)}` +
      `&includeSingles=${includeSingles.toString()}` +
      `&includeBuyprice=${buyMode.toString()}` +
      `&inStockOnly=${inStockOnly}`,
    method: "GET",
  });

export const fetchProductSearchResults = async (
  query,
  includeSingles,
  buyMode,
  offset = 0,
  limit = 50,
  inStockOnly = false
) =>
  _fetch({
    endpoint:
      `${API_V2_BASE_URL}/products?` +
      `limit=${limit}` +
      `&offset=${offset}` +
      `&keyword=${encodeURIComponent(query)}` +
      `&includeSingles=${includeSingles.toString()}` +
      `&includeBuyprice=${buyMode.toString()}` +
      `&inStockOnly=${inStockOnly}`,
    method: "GET",
  });
