import React from "react";
import { FailedProduct } from "../../types/productSync";
import { TimeUtils } from "../../utils";
import ButtonComponent from "./ButtonComponent";
import "./FailedToSyncProduct.scss";

interface FailedToSyncProductProps {
  product: FailedProduct;
  addToStockAction: () => void;
  removeAction: () => void;
}

function FailedToSyncProduct(props: FailedToSyncProductProps) {
  const { product, addToStockAction, removeAction } = props;

  return (
    <div className="FailedToSyncProduct">
      <div className="FailedToSyncProduct__content">
        <div className="FailedToSyncProduct__image">
          <img src={product.img} role="presentation" />
        </div>
        <div className="FailedToSyncProduct__product-details">
          <div>
            {`${product.cardName} ${product.foil == true ? "Foil" : ""}`}
            <br />
            <div>{product.setName}</div>
          </div>
          <div>
            Condition: <em>{product.variantName}</em>
          </div>
          <div>
            Quantity: <em>{product.quantity}</em>
          </div>
          <div>
            Processed on:{" "}
            <em>
              {TimeUtils.convertDateToHumanReadable(
                product.readableSubmittedDate
              )}
            </em>
          </div>
        </div>
      </div>
      <div>
        <ButtonComponent
          primary
          compact
          halfWidth
          icon="fal fa-paper-plane"
          iconPosition="left"
          onClick={addToStockAction}
        >
          Add to Stock
        </ButtonComponent>
        <ButtonComponent
          secondary
          compact
          halfWidth
          icon="fas fa-times-circle"
          iconPosition="right"
          onClick={removeAction}
        >
          Remove
        </ButtonComponent>
      </div>
    </div>
  );
}

export default FailedToSyncProduct;
