import React, { useState } from "react";
import { Table, Column, Cell, SortType } from "rsuite-table";
import "rsuite-table/dist/css/rsuite-table.css";
import "../../../../css/rstable-overwrites.scss";
import Modal from "../../../components/generic/Modal";
import {
  FetchEbayStockChangeInfoParams,
  deleteEbayOffer,
} from "../../../api/rest/ebay";
import ButtonComponent from "../../../components/generic/ButtonComponent";
import Loader from "../../../components/generic/Loader";
import EbayStockChangeFilters from "../../../components/integrations/EbayStockChangeFilters";
import EbayStockChangeInfo from "../../../components/integrations/EbayStockChangeInfo";
import SectionHeaderLayout from "../../../components/layout/SectionHeaderLayout";
import { CurrencyRenderer } from "../../../components/reports/CellTypes";
import CustomHeaderCell from "../../../components/reports/CustomHeader";
import Pagination from "../../../components/reports/Pagination";
import { useFetchStockChangeHistory } from "../../../hooks/ebayHooks";
import { useStoreDetails } from "../../../hooks/storeHooks";
import { useQueryState } from "../../../hooks/stateHooks";
import TimeUtils from "../../../utils/TimeUtils";
import { parseEbayError } from "../../../utils/ebay";
import { notify } from "../../../helpers/notificationHelpers";
import { useFeatureFlag } from "../../../hooks/globalSettingsHooks";
import { REMOVE_LISTING_FROM_EBAY_CONTROL } from "../../../constants/featureFlags";
import "./StockChangeInfoView.scss";
import { filterEmptyValues } from "../../../helpers/formHelpers";

const ROWS_PER_PAGE = 25;

const ROWS_PER_PAGE_OPTIONS = [25, 50, 100, 200];

type Sort = {
  key: string;
  order: SortType;
};

function StockChangeInfoView() {
  const [filters, setFilters] = useQueryState<
    Partial<FetchEbayStockChangeInfoParams>
  >("filters", {});
  const [showFilters, setShowFilters] = useState(false);
  const [pagination, setPagination] = useQueryState("pagination", {
    currentPage: 0,
    rowsPerPage: ROWS_PER_PAGE,
  });
  const [sortColumn, setSortColumn] = useQueryState<Sort>("sortBy", {
    key: "lastUpdated",
    order: "desc",
  });
  const [activeId, setActiveId] = useQueryState<number>("active", null);
  const [removeListing, setRemoveListing] = useState<Record<string, any>>(null);
  const [isLoadingRemove, setIsLoadingRemove] = useState(false);
  const { currencyCode } = useStoreDetails();
  const removeListingsFromEbayEnabled = useFeatureFlag(
    REMOVE_LISTING_FROM_EBAY_CONTROL
  );

  const params = {
    offset: pagination?.currentPage * pagination?.rowsPerPage,
    limit: pagination?.rowsPerPage,
    sort: sortColumn?.key,
    ascending: sortColumn?.order !== "desc",
    ...filterEmptyValues(filters),
  };
  if (filters?.ebayCategoryId) {
    params["ebayCategoryId"] = String(filters.ebayCategoryId);
  }

  const {
    isLoading: isLoadingStockChangeHistory,
    isSuccess,
    data,
    refetch,
  } = useFetchStockChangeHistory(params);

  const removeListingHandler = (binderSkuId: number) => {
    setIsLoadingRemove(true);
    deleteEbayOffer(binderSkuId)
      .then(() => refetch?.())
      .catch((error: Error) => {
        const message = parseEbayError(error);
        if (message) {
          notify.error(message);
        } else {
          notify.error(
            "Failed to remove this listing. Please try again later."
          );
        }
      })
      .finally(() => {
        setIsLoadingRemove(false);
        setRemoveListing(null);
      });
  };

  const cancelRemoveListingHandler = () => setRemoveListing(null);

  const handlePageChange = (currentPage: number) => {
    setPagination({ currentPage, rowsPerPage: pagination?.rowsPerPage });
  };
  const handleRowsPerPageChange = (rowsPerPage: number) => {
    setPagination({ currentPage: 0, rowsPerPage });
  };

  return (
    <div className="StockChangeInfoView">
      <SectionHeaderLayout title="Stock Change History">
        <ButtonComponent
          primary
          onClick={() => setShowFilters((value) => !value)}
          icon={showFilters ? "fas fa-caret-up" : "fas fa-caret-down"}
        >
          {showFilters ? "Hide Filters" : "Show Filters"}
        </ButtonComponent>
      </SectionHeaderLayout>
      <EbayStockChangeFilters
        visible={showFilters}
        filters={filters}
        setFilters={setFilters}
      />
      <div className="StockChangeInfoView__results">
        {isLoadingStockChangeHistory ? (
          <Loader />
        ) : (
          <>
            <div className="data-table">
              <div className="table-container">
                <Table
                  data={data?.data}
                  sortColumn={sortColumn?.key}
                  sortType={sortColumn?.order}
                  onSortColumn={(key, order) => setSortColumn({ key, order })}
                  renderEmpty={() => (
                    <div className="StockChangeInfoView__no-results">
                      No data to display
                    </div>
                  )}
                  rowKey="binderProductId"
                  fillHeight
                >
                  <Column key="binderProductId" width={80} sortable resizable>
                    <CustomHeaderCell title="Product Id" />
                    <Cell dataKey="binderProductId" />
                  </Column>
                  <Column key="name" width={100} sortable flexGrow={1}>
                    <CustomHeaderCell title="Title" />
                    <Cell dataKey="name" />
                  </Column>
                  <Column key="newPrice" width={100} sortable resizable>
                    <CustomHeaderCell title="Price" />
                    <Cell dataKey="newPrice">
                      {(rowData) =>
                        CurrencyRenderer({
                          cellData: rowData?.newPrice,
                          currency: currencyCode,
                        })
                      }
                    </Cell>
                  </Column>
                  <Column key="newStockLevel" width={80} sortable resizable>
                    <CustomHeaderCell title="Stock Level" />
                    <Cell dataKey="newStockLevel" />
                  </Column>
                  <Column key="lastUpdated" width={160} sortable resizable>
                    <CustomHeaderCell title="Last Updated" />
                    <Cell dataKey="lastUpdated">
                      {(rowData) =>
                        rowData?.lastUpdated
                          ? TimeUtils.convertDateToHumanReadable(
                              rowData?.lastUpdated
                            )
                          : "-"
                      }
                    </Cell>
                  </Column>
                  <Column key="success" width={80} sortable resizable>
                    <CustomHeaderCell title="Status" />
                    <Cell dataKey="success">
                      {(rowData) => {
                        if (
                          rowData?.offerId === null &&
                          rowData?.listingId === null
                        )
                          return "Removed";
                        return rowData?.success ? "Success" : "Error";
                      }}
                    </Cell>
                  </Column>
                  <Column
                    key="col_view"
                    width={removeListingsFromEbayEnabled ? 220 : 110}
                  >
                    <></>
                    <Cell dataKey="binderProductId">
                      {(rowData) => (
                        <div className="StockChangeInfoView__actions">
                          <ButtonComponent
                            primary
                            compact
                            onClick={() =>
                              setActiveId(rowData?.binderProductId)
                            }
                          >
                            View
                          </ButtonComponent>
                          {removeListingsFromEbayEnabled ? (
                            <ButtonComponent
                              danger
                              compact
                              onClick={() =>
                                setRemoveListing({
                                  binderSkuId: rowData?.binderSkuId,
                                  title: rowData?.name,
                                })
                              }
                            >
                              Temp Remove
                            </ButtonComponent>
                          ) : null}
                        </div>
                      )}
                    </Cell>
                  </Column>
                </Table>
              </div>
            </div>
            <Pagination
              isSuccess={isSuccess}
              currentPage={pagination?.currentPage}
              setCurrentPage={handlePageChange}
              totalRowCount={(data?.totalPages || 0) * pagination?.rowsPerPage}
              rowsPerPage={pagination?.rowsPerPage}
              rowPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              setRowsPerPage={handleRowsPerPageChange}
            />
          </>
        )}
      </div>
      {activeId ? (
        <Modal onClose={() => setActiveId(null)}>
          <Modal.Header>Stock Changes</Modal.Header>
          <Modal.Content>
            <EbayStockChangeInfo
              {...data.data.find(
                (stockChange) => stockChange.binderProductId === activeId
              )}
            />
          </Modal.Content>
        </Modal>
      ) : null}
      {removeListing ? (
        <Modal isLoading={isLoadingRemove} onClose={cancelRemoveListingHandler}>
          <Modal.Header>Temporarily remove listing from eBay</Modal.Header>
          <Modal.Content>
            <p>Temp Remove temporarily de-lists this product. Please note:</p>
            <ul>
              <li>
                If inventory is zero, and stock is added, this product will be
                re-listed.
              </li>
              <li>
                If there is an update to a rule that applies to this product
                (bulk or individual), the product will be re-listed (unless
                there is zero quantity)
              </li>
              <li>
                If there are price changes to this product, the product will be
                re-listed (unless there is zero quantity)
              </li>
            </ul>
            <p>
              Are you sure you want to temporarily remove {removeListing.title}?
            </p>
          </Modal.Content>
          <Modal.Actions>
            <ButtonComponent
              secondary
              icon="fal fa-times"
              iconPosition="left"
              onClick={cancelRemoveListingHandler}
            >
              Cancel
            </ButtonComponent>
            <ButtonComponent
              primary
              disabled={isLoadingRemove}
              icon="fas fa-caret-right"
              iconPosition="right"
              onClick={() => removeListingHandler(removeListing.binderSkuId)}
            >
              Remove
            </ButtonComponent>
          </Modal.Actions>
        </Modal>
      ) : null}
    </div>
  );
}

export default StockChangeInfoView;
