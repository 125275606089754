import React, { MutableRefObject } from "react";
import Modal from "../generic/Modal";
import Dropzone from "react-dropzone";
import { notify } from "../../helpers/notificationHelpers";
import ButtonComponent from "../generic/ButtonComponent";

interface ExternalCSVImportModalProps {
  onClose: () => void;
  uploadCSV: (file: File) => Promise<any>;
}

function ExternalCSVImportModal(
  props: ExternalCSVImportModalProps,
  ref: MutableRefObject<any>
) {
  const { onClose, uploadCSV } = props;

  return (
    <Modal ref={ref} small onClose={onClose}>
      <Modal.Header>External CSV Import</Modal.Header>
      <Modal.Content>
        <Dropzone
          onDrop={async (acceptedFiles) => {
            const resp = await uploadCSV(acceptedFiles[0]);
            if (resp.errors.length) {
              onClose();
              notify.warn(resp.errors[0]);
            } else {
              onClose();
              notify.info("CSV uploaded, importing data in background...");
            }
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <p>
                  This is experimental support for importing CSV files from
                  external sources. The CSV must contain the following columns:
                  Quantity, Name, Product ID, Language, Printing, and Condition.
                  Condition MUST match the condition names you have in
                  BinderPOS. This only works for MTG Singles. Drag file here or
                  click on the paragraph to select file.
                </p>
              </div>
            </section>
          )}
        </Dropzone>
      </Modal.Content>
      <Modal.Actions>
        <ButtonComponent onClick={onClose}>Cancel</ButtonComponent>
      </Modal.Actions>
    </Modal>
  );
}

export default React.forwardRef(ExternalCSVImportModal);
