import React, { useEffect } from "react";
import { useGetActiveTillsQuery } from "../../generated/graphql";
import { useShowError } from "../../hooks/errorHooks";

interface TillSelectProps {
  value: number;
  setValue: (tillId: number, tillName: string) => void;
  title?: string;
  allowAll?: boolean;
}

function TillSelect(props: TillSelectProps) {
  const { value, setValue, title, allowAll = false } = props;
  const { data, error } = useGetActiveTillsQuery(
    {},
    { staleTime: 5 * 300_000 }
  );
  const showError = useShowError();

  const handleTillChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { target } = event;
    const tillId = Number(target.value);
    const tillName = target.children[target.selectedIndex].textContent;
    setValue(tillId, tillName);
  };

  useEffect(() => {
    if (error) {
      showError(
        error as DetailedError,
        "Failed to load tills",
        "There was an error retrieving your list of tills. Please refresh and try again"
      );
    }
  }, [error]);

  return (
    <select
      id="tillSelect"
      aria-label={title ?? "Select Till"}
      onChange={handleTillChange}
      value={value}
      title={title ?? "Select Till"}
    >
      <option value="">{allowAll ? "- All -" : "- Select a till -"}</option>
      {(data?.BinderCustomerTill || []).map((till) => (
        <option value={till.id} key={till.id}>
          {till.name}
        </option>
      ))}
    </select>
  );
}

export default TillSelect;
