import React, { Component } from "react";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";
import { BuylistMenuStructure } from "../../menuStructures";
import { ConfirmationModal, Loader, Paging } from "../../components";
import {
  removeFailedProduct,
  processFailedProduct,
} from "../../api/rest/buylist";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";
import ActionButtonsLayout from "../../components/layout/ActionButtonsLayout";
import FailedToSyncProduct from "../../components/generic/FailedToSyncProduct";
import Modal from "../../components/generic/Modal";
import GridLayout from "../../components/layout/GridLayout";

@inject("store")
@observer
export default class FailedBuylistProducts extends Component {
  @observable offset = 0;

  @action setOffset(offset) {
    this.offset = offset;
  }

  @observable limit = 100;

  @action setLimit(limit) {
    this.limit = limit;
  }

  @observable messageModalText;

  @action setMessageModalText(text) {
    this.messageModalText = text;
  }

  @observable messageModalTitle;

  @action setMessageModalTitle(title) {
    this.messageModalTitle = title;
  }

  @observable messageModalVisible;

  @action setMessageModalVisible(bool) {
    this.messageModalVisible = bool;
  }

  @observable processing;

  @action setProcessing(processing) {
    this.processing = processing;
  }

  @observable deleteModalVisible = false;

  @action setDeleteModalVisible(bool) {
    this.deleteModalVisible = bool;
  }

  @observable deleteModalProduct;

  @action setDeleteModalProduct(product) {
    this.deleteModalProduct = product;
  }

  constructor(props) {
    super(props);
    this.modalRef = React.createRef();
    this.listRef = React.createRef();
  }

  componentDidMount() {
    document.title = "Failed to Sync to Stock | BinderPOS";
    this.props.store.MenuStore.setSideMenuToDisplay(BuylistMenuStructure);

    this.props.store.BuylistRulesStore.rehydrateFailedToSync(
      this.offset,
      this.limit
    );
  }

  componentDidUpdate() {
    if (this.deleteModalVisible || this.messageModalVisible) {
      this.modalRef.current?.focus();
    } else {
      this.listRef.current?.focus();
    }
  }

  getNextFailedToSync = () => {
    this.setOffset(this.offset + 100);
    this.props.store.BuylistRulesStore.rehydrateFailedToSync(
      this.offset,
      this.limit
    );
  };

  getPrevFailedToSync = () => {
    let offsetUpdate = this.offset - 100;
    if (offsetUpdate < 0) {
      offsetUpdate = 0;
    }
    this.setOffset(offsetUpdate);
    this.props.store.BuylistRulesStore.rehydrateFailedToSync(
      this.offset,
      this.limit
    );
  };

  pushStockThrough(failedToSync) {
    this.setProcessing(true);
    processFailedProduct(failedToSync)
      .then((result) => {
        if (!result.actionPass) {
          this.setMessageModalText(result.message);
          this.setMessageModalTitle("Issue pushing through to stock");
          this.setMessageModalVisible(true);
          this.setProcessing(false);
        } else {
          this.setProcessing(false);
          this.props.store.BuylistRulesStore.rehydrateFailedToSync(
            this.offset,
            this.limit
          );
        }
      })
      .catch((error) => {
        console.error(error);
        this.setProcessing(false);
        this.props.store.MainStore.setError(
          error,
          "Failed to push stock",
          "There was an error pushing stock for your failed buylist products. Please try again"
        );
      });
  }

  showRemoveProductModal(failedToSync) {
    this.setDeleteModalProduct(failedToSync);
    this.setDeleteModalVisible(true);
  }

  removeFromList(failedToSync) {
    this.setProcessing(true);
    this.setDeleteModalVisible(false);
    removeFailedProduct(failedToSync)
      .then((result) => {
        this.setMessageModalText(
          result.actionPass ? "Product removed successfully!" : result.message
        );
        this.setMessageModalTitle(
          result.actionPass
            ? "Product removed"
            : "There was an issue removing this product"
        );
        this.setMessageModalVisible(true);
        this.setProcessing(false);
        this.props.store.BuylistRulesStore.rehydrateFailedToSync(
          this.offset,
          this.limit
        );
      })
      .catch((error) => {
        this.setProcessing(false);
        this.props.store.MainStore.setError(
          error,
          "Failed to load remove product",
          "There was an error removing the selected product. Please try again"
        );
      });
  }

  render() {
    if (!this.props.store.BuylistRulesStore.failedToSyncList) return <Loader />;

    if (this.processing) return <Loader />;

    return (
      <>
        <SectionHeaderLayout title="Failed to Sync to Stock from Buylist">
          <ActionButtonsLayout>
            <Paging
              pageOffset={this.offset}
              currentPageItemCount={
                this.props.store.BuylistRulesStore.failedToSyncList?.length || 0
              }
              maxItemsPerPage={this.limit}
              getNextPage={this.getNextFailedToSync}
              getPrevPage={this.getPrevFailedToSync}
            />
          </ActionButtonsLayout>
        </SectionHeaderLayout>
        <GridLayout>
          {this.props.store.BuylistRulesStore.failedToSyncList.map(
            (failedToSync) => (
              <FailedToSyncProduct
                key={failedToSync.id}
                product={failedToSync}
                addToStockAction={() => this.pushStockThrough(failedToSync)}
                removeAction={(event) => {
                  this.listRef.current = event.currentTarget;
                  this.showRemoveProductModal(failedToSync);
                }}
              />
            )
          )}
        </GridLayout>
        {this.messageModalVisible ? (
          <Modal
            onClose={() => this.setMessageModalVisible(false)}
            ref={this.modalRef}
          >
            <Modal.Header>{this.messageModalTitle}</Modal.Header>
            <Modal.Content>{this.messageModalText}</Modal.Content>
          </Modal>
        ) : null}
        {this.deleteModalVisible ? (
          <ConfirmationModal
            ref={this.modalRef}
            cancelAction={() => this.setDeleteModalVisible(false)}
            confirmWord="Remove"
            confirmAction={() => this.removeFromList(this.deleteModalProduct)}
          >
            Are you sure you want to remove {this.deleteModalProduct.cardName} [
            {this.deleteModalProduct.setName}] -{" "}
            {this.deleteModalProduct.variantName}?
          </ConfirmationModal>
        ) : null}
      </>
    );
  }
}
