import React from "react";

const showColumn = (columnsToShow: string[], col1: string, col2?: string) => {
  if (columnsToShow.length === 0) return true;
  if (columnsToShow.includes(col1)) return true;
  if (col2 && columnsToShow.includes(col2)) return true;
  return false;
};

interface ProductLineVariantHeaderProps {
  columnsToShow: string[];
  currency: string;
}

function ProductLineVariantHeader(props: ProductLineVariantHeaderProps) {
  const { columnsToShow, currency } = props;

  return (
    <div className="variantSelectTitles" data-testid="ProductLineVariantHeader">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-3 col-xl-1">Variant</div>
          <div className="col-sm-12 col-xl-10 configurable-fields">
            {showColumn(columnsToShow, "stock") ? (
              <div className="input-group" data-testid="columnHeader">
                <span>Stock Qty</span>
              </div>
            ) : null}
            {showColumn(columnsToShow, "reserveStock") ? (
              <div className="input-group" data-testid="columnHeader">
                <span>Reserve Qty</span>
              </div>
            ) : null}
            {showColumn(columnsToShow, "sellPrice") ? (
              <div className="currency-input" data-testid="columnHeader">
                <span>Sell</span>
              </div>
            ) : null}
            {showColumn(columnsToShow, "sellPriceOverride") ? (
              <div className="currency-input" data-testid="columnHeader">
                <span>Sell&nbsp;Override</span>
              </div>
            ) : null}
            {showColumn(columnsToShow, "cashBuyPrice", "cashBuyPercent") ? (
              <div className="multi-input" data-testid="columnHeader">
                <span>
                  Cash Buy
                  {columnsToShow.includes("cashBuyPrice") ? (
                    <span> {currency} </span>
                  ) : null}
                  {columnsToShow.includes("cashBuyPrice") &&
                  columnsToShow.includes("cashBuyPercent") ? (
                    <span> or </span>
                  ) : null}
                  {columnsToShow.includes("cashBuyPercent") ? (
                    <span> % </span>
                  ) : null}
                </span>
              </div>
            ) : null}
            {showColumn(columnsToShow, "creditBuyPrice", "creditBuyPercent") ? (
              <div className="multi-input" data-testid="columnHeader">
                <span>
                  Credit Buy
                  {columnsToShow.includes("creditBuyPrice") ? (
                    <span> {currency} </span>
                  ) : null}
                  {columnsToShow.includes("creditBuyPrice") &&
                  columnsToShow.includes("creditBuyPercent") ? (
                    <span> or </span>
                  ) : null}
                  {columnsToShow.includes("creditBuyPercent") ? (
                    <span> % </span>
                  ) : null}
                </span>
              </div>
            ) : null}
            {showColumn(columnsToShow, "buyLimit") ? (
              <div className="quantity-input" data-testid="columnHeader">
                <span>Buy&nbsp;Limit</span>
              </div>
            ) : null}
            {showColumn(
              columnsToShow,
              "overstockCashBuyPrice",
              "overstockCashBuyPercent"
            ) ? (
              <div className="multi-input" data-testid="columnHeader">
                <span>
                  Over-limit Cash Buy
                  {columnsToShow.includes("overstockCashBuyPrice") ? (
                    <span> {currency} </span>
                  ) : null}
                  {columnsToShow.includes("overstockCashBuyPrice") &&
                  columnsToShow.includes("overstockCashBuyPercent") ? (
                    <span> or </span>
                  ) : null}
                  {columnsToShow.includes("overstockCashBuyPercent") ? (
                    <span> % </span>
                  ) : null}
                </span>
              </div>
            ) : null}
            {showColumn(
              columnsToShow,
              "overstockCreditBuyPrice",
              "overstockCreditBuyPercent"
            ) ? (
              <div className="multi-input" data-testid="columnHeader">
                <span>
                  Over-limit Credit Buy
                  {columnsToShow.includes("overstockCreditBuyPrice") ? (
                    <span> {currency} </span>
                  ) : null}
                  {columnsToShow.includes("overstockCreditBuyPrice") &&
                  columnsToShow.includes("overstockCreditBuyPercent") ? (
                    <span> or </span>
                  ) : null}
                  {columnsToShow.includes("overstockCreditBuyPercent") ? (
                    <span> % </span>
                  ) : null}
                </span>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductLineVariantHeader;
