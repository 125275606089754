import React from "react";
import ButtonComponent from "../generic/ButtonComponent";
import { createAdaBotLauncher } from "../services/ADAChatbot";
import { adaBotAnswers, adaBotMetaFields } from "../../constants/adaBot";
import { ADABOT_BINDER_PORTAL } from "../../constants/featureFlags";
import { useOpenFreshworksTicket } from "../../hooks/freshworksHooks";
import { useFeatureFlag } from "../../hooks/globalSettingsHooks";
import { useStoreDetails } from "../../hooks/storeHooks";

interface PreviousImportsSupportButtonProps {
  id: string;
  fileType: string;
}

function PreviousImportsSupportButton(
  props: PreviousImportsSupportButtonProps
) {
  const { id, fileType } = props;
  const adaBotBinderPortal = useFeatureFlag(ADABOT_BINDER_PORTAL);
  const { customerId, customerName, customerSiteUrl, customerEmail } =
    useStoreDetails();
  const openFreshworksTicket = useOpenFreshworksTicket();

  const reportIssueToFreshworks = () =>
    openFreshworksTicket(
      `Product import: CSV #${id}`,
      "Question",
      "Issue reported with uploaded CSV file",
      `CSV file: id ${id}, type: ${fileType}`
    );

  const reportIssueToAdaBot = () => {
    createAdaBotLauncher()
      .setAnswer(adaBotAnswers.REPORT_CSV_IMPORT_ISSUE)
      .addMetaData(adaBotMetaFields.STORE_ID, customerId)
      .addMetaData(adaBotMetaFields.STORE_NAME, customerName)
      .addMetaData(adaBotMetaFields.STORE_URL, customerSiteUrl)
      .addMetaData(adaBotMetaFields.STORE_EMAIL, customerEmail)
      .addMetaData(adaBotMetaFields.CSV_IMPORT_ID, id)
      .addMetaData(adaBotMetaFields.CSV_IMPORT_TYPE, fileType)
      .launchAdaBot();
  };

  const reportIssue = adaBotBinderPortal
    ? reportIssueToAdaBot
    : reportIssueToFreshworks;

  return (
    <ButtonComponent icon="far fa-flag-alt" onClick={reportIssue}>
      Report issue
    </ButtonComponent>
  );
}

export default PreviousImportsSupportButton;
