import React from "react";
import { Route, Redirect } from "react-router-dom";
import Pos from "../pos/Pos";

function KioskRouter() {
  return (
    <>
      <Route exact path="/">
        <Redirect to="/pos" />
      </Route>
      <Route exact path="/dashboard">
        <Redirect to="/pos" />
      </Route>
      <Route exact path="/pos">
        <Pos />
      </Route>
    </>
  );
}

export default KioskRouter;
