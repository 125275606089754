import React from "react";
import { Setting } from "../../api/rest/integrations";
import { useEbayPolicies } from "../../views/integrations/ebay/EbayPoliciesContext";
import { EbayFulfillment, EbayReturn, EbayPayment } from "../../api/rest/ebay";

type PolicyType = EbayFulfillment | EbayLocation | EbayReturn | EbayPayment;

const getMatchingPolicyName = (
  policies: PolicyType[],
  selectedPolicy: number | string
) => policies.find((policy) => policy.id == selectedPolicy)?.name || "None set";

const getMatchingParamName = (params: EbayParam[], selectedParam: string) =>
  params.find((param) => param.code === selectedParam)?.description ||
  "Default condition";

interface ViewEbaySettingsProps {
  setting: Setting;
}

function ViewEbaySettings(props: ViewEbaySettingsProps) {
  const { setting } = props;
  const {
    locationPolicies,
    paymentPolicies,
    fulfillmentPolicies,
    returnsPolicies,
    conditions,
  } = useEbayPolicies();

  return (
    <>
      <h5>eBay Settings</h5>
      <span />
      <span className="label">Condition</span>
      <span className="value">
        {getMatchingParamName(conditions, setting.ebayCondition)}
      </span>
      <span className="label">Category</span>
      <span className="value">{setting.categoryName}</span>
      <span className="label">Fulfilment Policy</span>
      <span className="value">
        {getMatchingPolicyName(fulfillmentPolicies, setting.fulfilmentPolicyId)}
      </span>
      <span className="label">Payment Policy</span>
      <span className="value">
        {getMatchingPolicyName(paymentPolicies, setting.paymentPolicyId)}
      </span>
      <span className="label">Return Policy</span>
      <span className="value">
        {getMatchingPolicyName(returnsPolicies, setting.returnPolicyId)}
      </span>
      <span className="label">Location Policy</span>
      <span className="value">
        {getMatchingPolicyName(locationPolicies, setting.merchantLocationKey)}
      </span>
    </>
  );
}

export default ViewEbaySettings;
