const SPLIT_IO_KEY =
  process?.env?.REACT_APP_SPLIT_IO_API_KEY ||
  "q977um07eul92gtiu8rv3i7umb53pt7a8g58";

export const SPLIT_CONFIG = {
  core: {
    authorizationKey: SPLIT_IO_KEY,
    key: "BinderPOS",
  },
};

export const THANKSGIVING_FLAG = "thanksgivingMessage";
export const CUSTOMER_SURVEY_ID_FLAG = "customerSurveyId";
export const BARCODE_ERROR_SETTINGS_FLAG = "barcodeErrorSettings";
export const GQL_SUBMIT_CART = "gqlSubmitCart";
export const GQL_UPDATE_CART = "gqlUpdateCart";
export const FRESH_CHAT = "freshChat";
export const DEV_BLOG = "devBlog";
export const POS_CART_IDLE_CHECK = "posCartIdleCheck";
export const POS_COMBINED_CSV_UPLOADER = "posCombinedCsvUploader";
export const POS_NEW_MOBILE_MENU = "posNewMobileMenu";
export const POS_SHOPIFY_THEMES = "posShopifyThemes";
export const UPDATED_PRODUCT_SEARCH = "updatedProductSearch";
export const PAYMENT_GATEWAY_USAEPAY = "posPaymentGatewayUSAePay";
export const PAYMENT_GATEWAY_MXMERCHANT = "posPaymentMxMerchant";
export const PRODUCT_EDIT_AUTOCOMPLETE = "productEditAutoComplete";
export const USE_NEW_RECEIPT_STYLING = "posUseNewReceiptStyling";
export const SHOPIFY_BILLING_ENABLED = "shopifyBillingEnabled";
export const CURRENCY_OVERRIDE_WORDING = "currencyOverrideWording";
export const ADABOT_BINDER_PORTAL = "adabotBinderPortal";
export const QUALTRICS_SURVEY_ENABLE = "posQualtricsSurveyEnable";
export const PRODUCT_PRICING_INFO = "posProductPricingInfo";
export const KIOSK_CART_NAME = "kioskCartName";
export const POS_BUYLIST_COMPLETED_DATE_FILTER =
  "posBuylistCompletedDateFilter";
export const POS_NEW_CART_IDLE_CHECK = "posNewCartIdleCheck";
export const EBAY_CONDITION_MAPPING = "ebayConditionMapping";
export const REMOVE_LISTING_FROM_EBAY_CONTROL = "removeListingFromEbayControl";
export const CURRENT_FEATURE_FLAGS = [
  THANKSGIVING_FLAG,
  CUSTOMER_SURVEY_ID_FLAG,
  BARCODE_ERROR_SETTINGS_FLAG,
  GQL_UPDATE_CART,
  GQL_SUBMIT_CART,
  FRESH_CHAT,
  DEV_BLOG,
  POS_CART_IDLE_CHECK,
  POS_COMBINED_CSV_UPLOADER,
  POS_NEW_MOBILE_MENU,
  POS_SHOPIFY_THEMES,
  UPDATED_PRODUCT_SEARCH,
  PAYMENT_GATEWAY_USAEPAY,
  PAYMENT_GATEWAY_MXMERCHANT,
  PRODUCT_EDIT_AUTOCOMPLETE,
  USE_NEW_RECEIPT_STYLING,
  SHOPIFY_BILLING_ENABLED,
  ADABOT_BINDER_PORTAL,
  QUALTRICS_SURVEY_ENABLE,
  PRODUCT_PRICING_INFO,
  KIOSK_CART_NAME,
  POS_BUYLIST_COMPLETED_DATE_FILTER,
  POS_NEW_CART_IDLE_CHECK,
  EBAY_CONDITION_MAPPING,
  REMOVE_LISTING_FROM_EBAY_CONTROL,
  CURRENCY_OVERRIDE_WORDING,
];
