import React from "react";
import ButtonComponent from "../../components/generic/ButtonComponent";
import "./NumberPagination.scss";

interface NumberPaginationProps {
  offset: number;
  setOffset: (offset: number) => void;
  limit: number;
  productCount: number;
}

function NumberPagination({
  offset,
  setOffset,
  limit,
  productCount,
}: NumberPaginationProps) {
  const page = Math.floor(offset / limit + 1);
  const max = Math.ceil(productCount / limit);

  return (
    <div className="NumberPagination">
      <ButtonComponent
        compact
        disabled={offset == 0}
        onClick={() => setOffset(offset - limit)}
      >
        {"<<"}
      </ButtonComponent>
      {offset > 0 && (
        <ButtonComponent secondary compact onClick={() => setOffset(0)}>
          1
        </ButtonComponent>
      )}
      {page - 2 > 2 && "..."}
      {page - 2 > 1 && (
        <ButtonComponent
          secondary
          compact
          onClick={() => setOffset(offset - limit * 2)}
        >
          {page - 2}
        </ButtonComponent>
      )}
      {page - 1 > 1 && (
        <ButtonComponent
          compact
          secondary
          onClick={() => setOffset(offset - limit)}
        >
          {page - 1}
        </ButtonComponent>
      )}
      <ButtonComponent primary compact>
        {page}
      </ButtonComponent>
      {page + 1 < max && (
        <ButtonComponent
          secondary
          compact
          onClick={() => setOffset(offset + limit)}
        >
          {page + 1}
        </ButtonComponent>
      )}
      {page + 2 < max && (
        <ButtonComponent
          secondary
          compact
          onClick={() => setOffset(offset + limit * 2)}
        >
          {page + 2}
        </ButtonComponent>
      )}
      {page + 3 < max && "..."}
      {page < max && (
        <ButtonComponent
          secondary
          compact
          onClick={() => setOffset((max - 1) * limit)}
        >
          {max}
        </ButtonComponent>
      )}
      <ButtonComponent
        compact
        secondary
        disabled={productCount < offset + limit}
        onClick={() => setOffset(offset + limit)}
      >
        {">>"}
      </ButtonComponent>
      <span>{productCount.toLocaleString()} total products</span>
    </div>
  );
}

export default NumberPagination;
