import React from "react";
import "./JobQueueFilterTabs.scss";
import { segmentAnalytics } from "../services/Analytics";
import { QUEUED_JOBS_MENU_CLICKED } from "../../constants/eventsTracked";

type FilterOption = {
  title: string;
  type: string;
};

interface JobQueueFilterTabsProps {
  filterOptions: Array<FilterOption>;
  selectedTab: string;
  updateSelectedTab: (tab: string) => void;
}

const JobQueueFilterTabs = (props: JobQueueFilterTabsProps) => {
  const { filterOptions, selectedTab, updateSelectedTab } = props;

  const handleClick = (item: FilterOption) => {
    segmentAnalytics.track(QUEUED_JOBS_MENU_CLICKED, {
      menu_title: item.title,
    });
    updateSelectedTab(item.type);
  };

  return (
    <div className="jobQueueFilterTabs">
      {filterOptions.map((item) => (
        <button
          key={item.type}
          className={!selectedTab || selectedTab === item.type ? "active" : ""}
          onClick={() => handleClick(item)}
        >
          {item.title}{" "}
          {!selectedTab || selectedTab === item.type ? (
            <i className="fas fa-caret-up" />
          ) : (
            <i className="fas fa-caret-down" />
          )}
        </button>
      ))}
    </div>
  );
};

export default JobQueueFilterTabs;
