import { Component } from "react";
import { inject } from "mobx-react";
import { withRouter } from "react-router-dom";

@inject("posStore")
class PosRouteWatcher extends Component {
  componentDidMount() {
    this.signalRouteChange();
  }

  componentDidUpdate() {
    this.signalRouteChange();
  }

  signalRouteChange() {
    const { location } = this.props;
    const inPos = location.pathname.indexOf("/pos") === 0;
    const { setPosActive } = this.props.posStore;
    setPosActive(inPos);
    if (inPos) {
      window.fcWidget?.setConfig({ headerProperty: { direction: "ltr" } });
    } else {
      window.fcWidget?.setConfig({ headerProperty: { direction: "rtl" } });
    }
  }

  render() {
    return null;
  }
}

export default withRouter(PosRouteWatcher);
