import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { EbayTemplate } from "../../api/rest/ebay";
import ButtonComponent from "../../components/generic/ButtonComponent";
import { validateRequiredFields } from "../../utils/forms";
import EbayDescriptionEditor from "./EbayDescriptionEditor";
import "./EbayTemplateForm.scss";

const initialValues = {
  name: "",
  template: "",
};

const requiredFields = ["name", "template"];

const validate = (values: EbayTemplate) =>
  validateRequiredFields(requiredFields, values);

interface EbayTemplateFormProps {
  templateSetting: EbayTemplate;
  setEditSetting: Function;
  saveSetting: Function;
}

function EbayTemplateForm(props: EbayTemplateFormProps) {
  const { templateSetting, setEditSetting, saveSetting } = props;

  return (
    <div className="ebay-template-form">
      <Formik
        initialValues={templateSetting || initialValues}
        validate={validate}
        onSubmit={(values, { setSubmitting }) =>
          saveSetting(values, setSubmitting)
        }
      >
        {({ setFieldValue, isSubmitting }) => (
          <Form>
            <div className="ebay-template-form__inputs">
              <label id="name">
                Name
                <span
                  className="ebay-template-form__required"
                  aria-label="Required field"
                >
                  *
                </span>
              </label>
              <Field name="name" aria-labelledby="name" autoFocus />
              <ErrorMessage
                name="name"
                className="ebay-template-form__field-error"
                component="div"
              />
              <label id="template">
                Template
                <span
                  className="ebay-template-form__required"
                  aria-label="Required field"
                >
                  *
                </span>
              </label>
              <div className="ebay-template-form__editor">
                <Field name="template">
                  {({ field }: any) => (
                    <EbayDescriptionEditor
                      name={field.name}
                      value={field.value}
                      setFieldValue={setFieldValue}
                      onChange={field.onChange(field.name)}
                    />
                  )}
                </Field>
              </div>
              <ErrorMessage
                name="template"
                className="ebay-template-form__field-error"
                component="div"
              />
            </div>
            <div className="ebay-address-form__actions">
              <ButtonComponent onClick={() => setEditSetting()}>
                Cancel
              </ButtonComponent>
              <ButtonComponent primary type="submit" disabled={isSubmitting}>
                Save
              </ButtonComponent>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default EbayTemplateForm;
