import React, { Fragment, useEffect } from "react";
import { ReportsMenuStructure } from "../../menuStructures";
import SetActiveMenu from "../../menuStructures/SetActiveMenu";
import { ButtonComponent } from "../../components";
import { VIEW_REPORT_CLICKED } from "../../constants/eventsTracked";

function StockHome() {
  useEffect(() => {
    document.title = "Reports | BinderPOS";
  }, []);

  return (
    <Fragment>
      <SetActiveMenu menuStructure={ReportsMenuStructure} />
      <div className="app-header">
        <h1 className="title">
          <span className="header-text">Stock Reports</span>
        </h1>
      </div>
      <div className="reports-menu row">
        <div className="col-sm-6">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title">Stock Inventory Value</h2>
              <p className="card-text">
                View count, value and cost of stock inventory by product type.
              </p>
              <ButtonComponent
                primary
                to="/reports/stock/value"
                segmentEventName={VIEW_REPORT_CLICKED}
                segmentEventProperties={{
                  report_type: "Stock Inventory Value",
                }}
                fullWidth
                compact
                isLink
              >
                View Report
              </ButtonComponent>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default StockHome;
