import React from "react";

interface LoadButtonCellProps {
  onClick: () => void;
}

function LoadButtonCell({ onClick }: LoadButtonCellProps) {
  return <i className="fas fa-cloud-download-alt" onClick={() => onClick()} />;
}

export default LoadButtonCell;
