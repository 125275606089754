import { useQuery } from "react-query";
import {
  fetchCustomerSettings,
  fetchCustomerVariants,
} from "../../api/rest/settings";
import {
  SUPPORT_REDIRECT_URL,
  SUPPORT_PRICE_SETTINGS_ARTICLE_URL,
} from "../../constants/support";
import { useMergedPriceRules } from "../../hooks/useMergedPriceRules";
import useTitle from "../../hooks/useTitle";
import SettingsMenu from "../../menuStructures/SettingsMenu";
import { Loader } from "../../components";
import SubSectionHeaderLayout from "../../components/layout/SubSectionHeaderLayout";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";
import NavigationPrompt from "../../components/nav/NavigationPrompt";
import GamePriceRules from "../../components/settings/GamePriceRules";
import GeneralPricingSection from "../../components/settings/GeneralPricingSection";
import GlobalPriceSettingsSection from "../../components/settings/GlobalPriceSettingsSection";
import MyVariantsPricingSection from "../../components/settings/MyVariantsPricingSection";
import LinkComponent from "../../components/generic/LinkComponent";
import "./PricingSettings.scss";

function PricingSettings() {
  useTitle("Pricing Settings | BinderPOS");

  const {
    data: settingsData,
    isLoading: settingsIsLoading,
    isError: settingIsError,
  } = useQuery("customerSettings", fetchCustomerSettings);

  const {
    data: variantsData,
    isLoading: variantsIsLoading,
    isError: variantsIsError,
  } = useQuery("customerVariants", fetchCustomerVariants);

  const { isLoading, isError, mergedPriceRules, globalRoundToNearest } =
    useMergedPriceRules();

  if (isLoading || settingsIsLoading || variantsIsLoading)
    return (
      <>
        <SettingsMenu />
        <Loader />
      </>
    );

  return (
    <>
      <NavigationPrompt />
      <SettingsMenu />
      <SectionHeaderLayout title="Pricing Settings" />
      <p className="PricingSettings__descriptionText">
        To find detailed definitions of price settings, visit our{" "}
        <LinkComponent to={SUPPORT_PRICE_SETTINGS_ARTICLE_URL} external={true}>
          price settings help article
        </LinkComponent>{" "}
        or{" "}
        <LinkComponent to={SUPPORT_REDIRECT_URL} external={true}>
          contact customer support
        </LinkComponent>{" "}
        if you need more assistance.
      </p>
      <br />
      <p className="PricingSettings__descriptionText">
        Please allow about 12 hours for price setting changes to take effect.
      </p>
      <br />
      {settingIsError ? (
        <>There was an error loading settings.</>
      ) : (
        <>
          <GeneralPricingSection settings={settingsData} />
          <hr />
        </>
      )}
      {variantsIsError ? (
        <>There was an error loading variants.</>
      ) : (
        <>
          <MyVariantsPricingSection variantTypes={variantsData} />
          <hr />
        </>
      )}
      {isError ? (
        <>There was an error loading price rules.</>
      ) : (
        <>
          <SubSectionHeaderLayout
            title="Product Line Price Rules"
            tooltip="The product line the price rules are applied to."
          />

          <GlobalPriceSettingsSection
            globalRoundToNearest={globalRoundToNearest}
          />
          {mergedPriceRules &&
            mergedPriceRules.length > 0 &&
            mergedPriceRules.map((priceRule) => (
              <GamePriceRules key={priceRule.game} priceRule={priceRule} />
            ))}
        </>
      )}
    </>
  );
}

export default PricingSettings;
