import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { inject, observer } from "mobx-react";

import Dashboard from "../views/dashboard/Dashboard";

import Customer from "../views/customers/Customer";
import CustomerList from "../views/customers/CustomerList";

import ProductList from "../views/products/ProductList";
import ProductEdit from "../views/products/ProductEdit";
import ProductsJobQueue from "../views/products/ProductsJobQueue";
import ImportData from "../views/products/ImportData";
import CSVExportHistory from "../views/products/CSVExportHistory";

import Buylists from "../views/buylist/Buylists";
import BuylistDetails from "../views/buylist/BuylistDetails";
import BuylistRuleList from "../views/buylist/BuylistRuleList";
import BuylistRule from "../views/buylist/BuylistRule";
import FailedBuylistProducts from "../views/buylist/FailedBuylistProducts";
import BuylistSettings from "../views/buylist/BuylistSettings";

import TillList from "../views/pos/TillList";
import EODReports from "../views/pos/EODReports";
import TaxSettings from "../views/pos/TaxSettings";
import POSFailedBuylistProducts from "../views/pos/POSFailedBuylistProducts";
import PaymentTerminal from "../views/pos/PaymentTerminal";
import CatalogUpdates from "../views/pos/CatalogUpdates";
import LocationSettings from "../views/pos/LocationSettings";
import MiscSettings from "../views/pos/MiscSettings";
import KioskSettings from "../views/pos/KioskSettings";

import Pos from "../pos/Pos";

import StoreSettings from "../views/settings/StoreSettings";
import NotesSettings from "../views/settings/NotesSettings";
import PricingSettings from "../views/settings/PricingSettings";
import TimezoneSettings from "../views/settings/TimezoneSettings";
import ReportSettings from "../views/settings/ReportSettings";
import Users from "../views/settings/Users";
import User from "../views/settings/User";
import CookieSettings from "../views/settings/CookieSettings";
import CatalogSettings from "../views/settings/CatalogSettings";

import EventRouter from "./EventRouter";
import IntegrationRouter from "./IntegrationRouter";
import ReportsRouter from "./ReportsRouter";
import ReportsProvider from "../providers/ReportsProvider";
import CheckPermission from "../components/auth/CheckPermission";
import CartViewWrapper from "../views/pos/CartViewWrapper";
import CartListWrapper from "../views/pos/CartListWrapper";
import TillSettings from "../views/pos/TillSettings";

import Themes from "../views/themes/Themes";
import ShopifyAuth from "../views/shopifyAuth/ShopifyAuth";
import ShopifyAuth2 from "../views/shopifyAuth/ShopifyAuth2";
import ShopifySubscription from "../views/shopifySubscription/ShopifySubscription";

function DefaultAppRouter() {
  return (
    <>
      <Route exact path="/">
        <Redirect to="/dashboard" />
      </Route>
      <Route exact path="/dashboard">
        <Dashboard />
      </Route>

      <Route path="/events">
        <EventRouter />
      </Route>

      <Route exact path="/customers">
        <Redirect to="/customers/list" />
      </Route>
      <Route exact path="/customers/list">
        <CheckPermission screenName="customers">
          <CustomerList />
        </CheckPermission>
      </Route>
      <Route exact path="/customers/add">
        <CheckPermission screenName="customers">
          <Customer />
        </CheckPermission>
      </Route>
      <Route exact path="/customers/update/:customerId">
        <CheckPermission screenName="customers">
          <Customer />
        </CheckPermission>
      </Route>

      <Route exact path="/products">
        <ProductList />
      </Route>
      <Route exact path="/products/edit/:productId">
        <CheckPermission screenName="inventoryManagement">
          <ProductEdit />
        </CheckPermission>
      </Route>
      <Route exact path="/products/jobQueue">
        <ProductsJobQueue
          inventoryManagementEnabled={
            this.props.store.AuthStore?.screenSettings?.inventoryManagement
          }
        />
      </Route>
      <Route exact path="/products/import">
        <ImportData />
      </Route>
      <Route exact path="/products/exportHistory">
        <CSVExportHistory />
      </Route>
      <Route exact path="/buylists">
        <Redirect to="/buylists/pending" />
      </Route>
      <Switch>
        <Route exact path="/buylists/settings">
          <BuylistSettings />
        </Route>
        <Route
          exact
          path="/buylists/pending/moreDetail/:buylistId"
          render={(props) => (
            <BuylistDetails buylistType="Pending buylist" {...props} />
          )}
        />
        <Route
          exact
          path="/buylists/approved/moreDetail/:buylistId"
          render={(props) => (
            <BuylistDetails buylistType="Approved buylist" {...props} />
          )}
        />
        <Route
          exact
          path="/buylists/completed/moreDetail/:buylistId"
          render={(props) => (
            <BuylistDetails buylistType="Completed buylist" {...props} />
          )}
        />
        <Route exact path="/buylists/rules">
          <CheckPermission screenName="buylist">
            <BuylistRuleList />
          </CheckPermission>
        </Route>
        <Route exact path="/buylists/rules/add">
          <CheckPermission screenName="buylist">
            <BuylistRule />
          </CheckPermission>
        </Route>
        <Route
          exact
          path="/buylists/rules/update/:buylistRuleId"
          render={(props) => <BuylistRule {...props} />}
        />
        <Route exact path="/buylists/failedToSyncProducts">
          <CheckPermission screenName="buylist">
            <FailedBuylistProducts />
          </CheckPermission>
        </Route>
        <Route exact path="/buylists/:status">
          <CheckPermission screenName="buylist">
            <Buylists />
          </CheckPermission>
        </Route>
      </Switch>

      <Route exact path="/pointOfSale">
        <Redirect to="/pointOfSale/tills" />
      </Route>
      <Route exact path="/pointOfSale/tills">
        <CheckPermission screenName="pos">
          <TillList />
        </CheckPermission>
      </Route>
      <Route exact path="/pointOfSale/carts">
        <CartListWrapper />
      </Route>
      <Route exact path="/pointOfSale/carts/:cartId">
        <CartViewWrapper />
      </Route>
      <Route exact path="/pointOfSale/eodReports">
        <ReportsProvider>
          <EODReports />
        </ReportsProvider>
      </Route>
      <Route exact path="/pointOfSale/settings/tax">
        <TaxSettings updatePosTax={this.props.posStore.updateTax} />
      </Route>
      <Route exact path="/pointOfSale/settings/till">
        <TillSettings />
      </Route>
      <Route exact path="/pointOfSale/failedToSyncProducts">
        <POSFailedBuylistProducts />
      </Route>
      {/* Legacy route - keeping for now to avoid 404s */}
      <Route exact path="/pointOfSale/paxSettings">
        <PaymentTerminal />
      </Route>
      <Route exact path="/pointOfSale/paymentTerminal">
        <PaymentTerminal />
      </Route>
      <Route exact path="/pointOfSale/catalogUpdates">
        {" "}
        <CatalogUpdates />
      </Route>
      <Route exact path="/pointOfSale/locationSettings">
        {" "}
        <LocationSettings />
      </Route>
      <Route exact path="/pointOfSale/miscSettings">
        <MiscSettings />
      </Route>
      <Route exact path="/pointOfSale/kioskSettings">
        <KioskSettings />
      </Route>
      <Route exact path="/pointOfSale/notes">
        <NotesSettings />
      </Route>

      <Route exact path="/pos">
        <Pos />
      </Route>

      <Route exact path="/settings">
        <Redirect to="/settings/store" />
      </Route>
      <Route exact path="/settings/store">
        <StoreSettings />
      </Route>
      <Route exact path="/settings/catalog">
        <CatalogSettings />
      </Route>
      <Route exact path="/settings/pricing">
        <PricingSettings />
      </Route>
      <Route exact path="/settings/timezone">
        <TimezoneSettings />
      </Route>
      <Route exact path="/settings/reports">
        <ReportSettings />
      </Route>
      <Route exact path="/settings/users">
        <Redirect to="/settings/users/list" />
      </Route>
      <Route exact path="/settings/users/list">
        <Users />
      </Route>
      <Route exact path="/settings/users/add">
        <User />
      </Route>
      <Route exact path="/settings/users/update/:userId">
        <User />
      </Route>
      <Route exact path="/settings/cookies">
        <CookieSettings />
      </Route>

      <Route path="/reports">
        <ReportsRouter />
      </Route>
      <Route path="/integrations">
        <CheckPermission screenName="integrations">
          <IntegrationRouter />
        </CheckPermission>
      </Route>

      <Route path="/themes">
        <Themes />
      </Route>

      <Route path="/shopifyAppAuth/:appType">
        <ShopifyAuth2 />
      </Route>

      <Route exact path="/shopifyAppAuth">
        <ShopifyAuth />
      </Route>

      <Route exact path="/shopifySubscription">
        <ShopifySubscription />
      </Route>
    </>
  );
}

export default inject("store", "posStore")(observer(DefaultAppRouter));
