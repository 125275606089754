import React from "react";
import { signOut } from "../../services/firebase";
import IconButton from "../generic/IconButton";
import "./Logout.scss";
import { segmentAnalytics } from "../services/Analytics";
import { SIGNED_OUT } from "../../constants/eventsTracked";

const Logout = ({ onLogout }: { onLogout: () => void }): JSX.Element => (
  <IconButton
    icon="fad fa-sign-out-alt"
    onClick={(): void => {
      segmentAnalytics.track(SIGNED_OUT);
      signOut();
      onLogout();
    }}
    title="Log out"
    className="Logout"
  />
);

export default Logout;
