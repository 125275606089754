const sortTenders = (tenderA: string, tenderB: string) => {
  if (tenderA === "Store_Credit") return 1;
  if (tenderB === "Store_Credit") return -1;
  const defaultOrder = ["Cash", "Credit", "EFTPOS"];
  const indexOfA =
    defaultOrder.indexOf(tenderA) !== -1 ? defaultOrder.indexOf(tenderA) : 3;
  const indexOfB =
    defaultOrder.indexOf(tenderB) !== -1 ? defaultOrder.indexOf(tenderB) : 3;
  return indexOfA - indexOfB;
};

export const formatEODReport = (report) => {
  const floatEntries = report?.POSReports?.FloatEntries;
  if (!floatEntries) {
    return { report: [], tenders: [] };
  }
  const reformattedEodReport = [];
  const availableTenders = new Set();
  floatEntries.forEach((line, index) => {
    const { tenders, ...props } = line;
    const newLine = { ...props };
    newLine.id = index;
    tenders.forEach((tender) => {
      const { name, ...amounts } = tender;
      const keyName = name.replace(" ", "_");
      availableTenders.add(keyName);
      newLine[keyName] = amounts;
      const difference = amounts.closingAmount - amounts.currentAmount;
      newLine[keyName].difference = difference || 0;
    });
    reformattedEodReport.push(newLine);
  });
  return {
    report: reformattedEodReport,
    tenders: Array.from(availableTenders).sort(sortTenders),
  };
};
