import React, { useEffect, useState } from "react";
import {
  CANCEL_MISC_SETTINGS_CLICKED,
  SAVE_MISC_SETTINGS_CLICKED,
} from "../../constants/eventsTracked";
import { BARCODE_ERROR_SETTINGS_FLAG } from "../../constants/featureFlags";
import { POSMenuStructure } from "../../menuStructures";
import PosPaymentTypesSettings from "../../components/posSettings/PosPaymentTypesSettings";
import ButtonComponent from "../../components/generic/ButtonComponent";
import CheckboxComponent from "../../components/generic/CheckboxComponent";
import Loader from "../../components/generic/Loader";
import ActionButtonsLayout from "../../components/layout/ActionButtonsLayout";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";
import { segmentAnalytics } from "../../components/services/Analytics";
import { notify } from "../../helpers/notificationHelpers";
import { useShowError } from "../../hooks/errorHooks";
import { useFeatureFlag } from "../../hooks/globalSettingsHooks";
import { useFetchSetting, useUpdateSetting } from "../../hooks/settingHooks";
import useTitle from "../../hooks/useTitle";
import SetActiveMenu from "../../menuStructures/SetActiveMenu";
import "./MiscSettings.scss";

const convertToBoolean = (value: boolean | string) =>
  value === true || value === "true";

const convertStringSettingToArray = (value: string) =>
  (value || "").split(";").filter(Boolean) || [];

function MiscSettings() {
  const [updatingSettings, setUpdatingSettings] = useState(true);
  const [cartExpireEnabled, setCartExpireEnabled] = useState(false);
  const [useSplitCheckout, setUseSplitCheckout] = useState(false);
  const [useBarcodeQuantityCheck, setUseBarcodeQuantityCheck] = useState(false);
  const [useBarcodeErrorModal, setUseBarcodeErrorModal] = useState(false);
  const [useNewCartOnPOSOpening, setUseNewCartOnPOSOpening] = useState(false);
  const [posPaymentTypes, setPosPaymentTypes] = useState([]);
  const { mutateAsync: updateSetting } = useUpdateSetting();
  const showError = useShowError();
  useTitle("Misc Settings | BinderPOS");
  const isShowBarcodeErrorSettingActive = useFeatureFlag(
    BARCODE_ERROR_SETTINGS_FLAG
  );

  const {
    data: cartExpireData,
    isLoading: cartExpireLoading,
    refetch: refetchCartExpire,
  } = useFetchSetting("cartExpire", {
    keepPreviousData: true,
    onError: (error: DetailedError) => {
      showError(
        error,
        "Failed to load settings",
        "There was an error retrieving your cart expiration settings. Please refresh and try again"
      );
    },
  });

  const {
    data: useSplitCheckoutData,
    isLoading: useSplitCheckoutLoading,
    refetch: refetchSplitCheckout,
  } = useFetchSetting("posUseSplitCheckout", {
    keepPreviousData: true,
    onError: (error: DetailedError) => {
      showError(
        error,
        "Failed to load settings",
        "There was an error retrieving your split checkout settings. Please refresh and try again"
      );
    },
  });

  const {
    data: useBarcodeQuantityCheckData,
    isLoading: useBarcodeQuantityCheckLoading,
    refetch: refetchBarcodeQuantityCheck,
  } = useFetchSetting("barcodeQuantityCheck", {
    keepPreviousData: true,
    onError: (error: DetailedError) => {
      showError(
        error,
        "Failed to load settings",
        "There was an error retrieving your barcode settings. Please refresh and try again"
      );
    },
  });

  const {
    data: posPaymentTypesData,
    isLoading: posPaymentTypesLoading,
    refetch: refetchPosPaymentTypes,
  } = useFetchSetting("posPaymentTypes", {
    keepPreviousData: true,
    onError: (error: DetailedError) => {
      showError(
        error,
        "Failed to load settings",
        "There was an error retrieving your pos payment types settings. Please refresh and try again"
      );
    },
  });

  const {
    data: useNewCartOnPOSOpeningData,
    isLoading: useNewCartOnPOSOpeningLoading,
    refetch: refetchNewCartOnPOSOpening,
  } = useFetchSetting("newCartOnPOSOpening", {
    keepPreviousData: true,
    onError: (error: DetailedError) => {
      showError(
        error,
        "Failed to load settings",
        "There was an error retrieving your cart opening settings. Please refresh and try again"
      );
    },
  });

  const {
    data: useBarcodeErrorModalData,
    isLoading: useBarcodeErrorModalLoading,
    refetch: refetchBarcodeErrorModalSetting,
  } = useFetchSetting("posBarcodeErrorModal", {
    keepPreviousData: true,
    onError: (error: DetailedError) => {
      showError(
        error,
        "Failed to load settings",
        "There was an error retrieving your barcode error settings. Please refresh and try again"
      );
    },
  });

  useEffect(() => {
    setCartExpireEnabled(convertToBoolean(cartExpireData));
    setUseSplitCheckout(convertToBoolean(useSplitCheckoutData));
    setUseBarcodeQuantityCheck(convertToBoolean(useBarcodeQuantityCheckData));
    setUseBarcodeErrorModal(convertToBoolean(useBarcodeErrorModalData));
    setUseNewCartOnPOSOpening(convertToBoolean(useNewCartOnPOSOpeningData));
    setPosPaymentTypes(convertStringSettingToArray(posPaymentTypesData));
  }, [
    cartExpireData,
    useBarcodeQuantityCheckData,
    useBarcodeErrorModalData,
    useSplitCheckoutData,
    useNewCartOnPOSOpeningData,
    posPaymentTypesData,
  ]);

  const loadingSettings =
    cartExpireLoading ||
    useSplitCheckoutLoading ||
    useBarcodeQuantityCheckLoading ||
    useBarcodeErrorModalLoading ||
    useNewCartOnPOSOpeningLoading ||
    posPaymentTypesLoading;

  const updatePaymentTypes = (paymentType: string[]) => {
    setPosPaymentTypes(paymentType);
    setUpdatingSettings(false);
  };

  const fetchMiscSettings = () => {
    return Promise.all([
      refetchCartExpire(),
      refetchSplitCheckout(),
      refetchBarcodeQuantityCheck(),
      refetchBarcodeErrorModalSetting(),
      refetchNewCartOnPOSOpening(),
      refetchPosPaymentTypes(),
    ]);
  };

  const saveMiscSettings = () => {
    segmentAnalytics.track(SAVE_MISC_SETTINGS_CLICKED, {
      expire_unsaved_cart: cartExpireEnabled,
      create_new_cart_on_enter: useNewCartOnPOSOpening,
      use_split_checkout: useSplitCheckout,
      check_inventory_scanned_item: useBarcodeQuantityCheck,
      extra_payment_methods: posPaymentTypes,
    });
    const promises = [];
    const cartExpireSettings = {
      settingName: "cartExpire",
      settingValue: `${cartExpireEnabled}`,
    };
    promises.push(updateSetting(cartExpireSettings));

    const useSplitCheckoutSettings = {
      settingName: "posUseSplitCheckout",
      settingValue: `${useSplitCheckout}`,
    };
    promises.push(updateSetting(useSplitCheckoutSettings));

    const useBarcodeQuantityCheckSettings = {
      settingName: "barcodeQuantityCheck",
      settingValue: `${useBarcodeQuantityCheck}`,
    };
    promises.push(updateSetting(useBarcodeQuantityCheckSettings));

    const useBarcodeErrorModalSettings = {
      settingName: "posBarcodeErrorModal",
      settingValue: `${useBarcodeErrorModal}`,
    };
    promises.push(updateSetting(useBarcodeErrorModalSettings));

    const posPaymentTypesSettings = {
      settingName: "posPaymentTypes",
      settingValue: `${posPaymentTypes?.join(";")}`,
    };
    promises.push(updateSetting(posPaymentTypesSettings));

    const newCartOnPOSOpening = {
      settingName: "newCartOnPOSOpening",
      settingValue: `${useNewCartOnPOSOpening}`,
    };
    promises.push(updateSetting(newCartOnPOSOpening));

    Promise.all(promises)
      .then(() => {
        notify.info("Misc settings updated!");
        setUpdatingSettings(true);
        fetchMiscSettings();
      })
      .catch((error) => {
        showError(
          error,
          "Failed to update settings",
          "There was an error submitting your updated settings. Please try again"
        );
      });
  };

  if (loadingSettings) {
    return <Loader />;
  }

  return (
    <>
      <SetActiveMenu menuStructure={POSMenuStructure} />
      <SectionHeaderLayout title="Misc Settings" />

      <div className="miscSettings__setting">
        <CheckboxComponent
          name="expireCart"
          label="Expire unsaved cart and
                    return stock after 1 hour"
          checked={cartExpireEnabled}
          onChange={(e) => {
            setCartExpireEnabled(e.target.checked);
            setUpdatingSettings(false);
          }}
        />
      </div>
      <div className="miscSettings__setting">
        <CheckboxComponent
          name="newCartOnPOSOpening"
          label="Create a new cart when
                entering the POS"
          checked={useNewCartOnPOSOpening}
          onChange={(e) => {
            setUseNewCartOnPOSOpening(e.target.checked);
            setUpdatingSettings(false);
          }}
        />
        <p>
          When opening the POS or selecting a Till, always start with a fresh
          cart instead of loading the latest cart.
        </p>
      </div>
      <div>
        <CheckboxComponent
          name="optOutOfCatalogUpdates"
          label="Use Split Checkout"
          checked={useSplitCheckout}
          onChange={(e) => {
            setUseSplitCheckout(e.target.checked);
            setUpdatingSettings(false);
          }}
        />
        <span className="miscSettings__beta">beta</span>
        <p>
          Uses the new checkout process, which handles split payments of the
          same tender type. Please note this is currently not compatible with
          integrated payment terminals.
        </p>
      </div>
      <div>
        <CheckboxComponent
          name="barcodeQuantityCheck"
          label="Check inventory of scanned items"
          checked={useBarcodeQuantityCheck}
          onChange={(e) => {
            setUseBarcodeQuantityCheck(e.target.checked);
            setUpdatingSettings(false);
          }}
        />
        <p>
          When scanning the barcode of an item that has no inventory, a prompt
          will appear to warn about possible overselling.
        </p>
      </div>
      {isShowBarcodeErrorSettingActive ? (
        <div>
          <CheckboxComponent
            name="barcodeErrorModal"
            label="Flag incorrect barcodes"
            checked={useBarcodeErrorModal}
            onChange={(e) => {
              setUseBarcodeErrorModal(e.target.checked);
              setUpdatingSettings(false);
            }}
          />
          <p>
            When scanning the barcode of an item that has no matching item, or
            corresponds to multiple items, a warning will be displayed on screen
            that must be dismissed before any further items may be scanned.
          </p>
        </div>
      ) : null}
      <PosPaymentTypesSettings
        paymentTypesList={posPaymentTypes}
        setPaymentTypes={updatePaymentTypes}
      />
      <br />
      <ActionButtonsLayout>
        <ButtonComponent
          primary
          onClick={saveMiscSettings}
          disabled={updatingSettings}
        >
          Save Settings
        </ButtonComponent>

        <ButtonComponent
          secondary
          onClick={() => {
            segmentAnalytics.track(CANCEL_MISC_SETTINGS_CLICKED);
            fetchMiscSettings();
          }}
          disabled={updatingSettings}
        >
          Cancel
        </ButtonComponent>
      </ActionButtonsLayout>
    </>
  );
}

export default MiscSettings;
