import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { MutableRefObject } from "react";
import { ShippingService, ShippingServiceProvider } from "../../api/rest/ebay";
import ButtonComponent from "../../components/generic/ButtonComponent";
import Modal from "../generic/Modal";
import { useStoreDetails } from "../../hooks/storeHooks";
import { validateRequiredFields } from "../../utils/forms";
import EbayShippingServiceDetails from "./EbayShippingServiceDetails";
import "./EbayShippingServiceSettings.scss";

const initialValues = {
  additionalShippingCost: undefined,
  freeShipping: false,
  shippingCost: undefined,
  shippingServiceName: undefined,
  ebayTypeShippingServiceId: {
    shippingServiceId: undefined,
    marketplace: undefined,
  },
  carrierName: undefined,
  ebayTypeShippingCarrierId: undefined,
  sortOrder: undefined,
  shipToLocationsIncluded: [],
  shipToLocationsExcluded: [],
  cashOnDeliveryFee: null,
  buyerResponsibleForPickup: false,
  buyerResponsibleForShipping: false,
} as ShippingService;

const requiredFields = [
  "shippingCost",
  "ebayTypeShippingServiceId.shippingServiceId",
];
const validate = (values: ShippingService, ignoreFields: string[]) => {
  const fields = requiredFields.filter(
    (field: string) => !ignoreFields.includes(field)
  );
  return validateRequiredFields(fields, values);
};

const generateInput = (
  fieldName: string,
  fieldLabel: string,
  prefix?: string
) => (
  <>
    <label id={fieldName}>
      {fieldLabel} {prefix}
      {requiredFields.includes(fieldName) ? (
        <span
          className="ebay-fulfillment-form__required"
          aria-label="Required field"
        >
          *
        </span>
      ) : (
        ""
      )}
    </label>
    <Field name={fieldName} aria-labelledby={fieldName} />
    <ErrorMessage
      name={fieldName}
      className="ebay-fulfillment-form__field-error"
      component="div"
    />
  </>
);

const generateOptionList = (
  label: string,
  fieldName: string,
  options: EbayParam[],
  values: string[],
  setValue: Function
) => (
  <fieldset id={fieldName}>
    {options
      .filter((option) => option.code !== "None")
      .map((option) => (
        <label
          aria-label={label}
          htmlFor={`${fieldName}_${option.code}`}
          key={option.code}
        >
          <input
            type="checkbox"
            id={`${fieldName}_${option.code}`}
            checked={values.includes(option.code)}
            onChange={(e) => {
              if (e.target.checked) {
                setValue(fieldName, [...values, option.code]);
              } else {
                setValue(
                  fieldName,
                  values.filter((v) => v !== option.code)
                );
              }
            }}
          />
          {option.description}
        </label>
      ))}
  </fieldset>
);

interface EbayShippingServiceSettingsProps {
  isFirst: boolean;
  setting: ShippingService;
  shippingServiceProviders: ShippingServiceProvider[];
  costType: "CALCULATED" | "FLAT_RATE" | "NOT_SPECIFIED";
  includeRegions: EbayParam[];
  excludeRegions: EbayParam[];
  updateSetting: Function;
  cancel: Function;
}
function EbayShippingServiceSettings(
  props: EbayShippingServiceSettingsProps,
  ref: MutableRefObject<any>
) {
  const {
    isFirst,
    setting,
    costType,
    shippingServiceProviders,
    includeRegions,
    excludeRegions,
    updateSetting,
    cancel,
  } = props;
  const mungedCostType = costType === "FLAT_RATE" ? "Flat" : "Calculated";
  const { currencySymbol } = useStoreDetails();

  const getShippingProviderDetails = (id: number, marketplace: string) =>
    shippingServiceProviders.find(
      (provider) =>
        provider.ebayTypeShippingServiceId?.shippingServiceId === Number(id) &&
        provider.ebayTypeShippingServiceId?.marketplace === marketplace
    );

  return (
    <Modal onClose={() => cancel()} ref={ref}>
      <Modal.Header>Configure Shipping Service</Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={setting || initialValues}
          validate={(values) =>
            validate(values, costType === "CALCULATED" ? ["shippingCost"] : [])
          }
          onSubmit={(values) => {
            updateSetting(values);
          }}
        >
          {({ values, setFieldValue }) => (
            <Form id="ebayShippingServiceSettingsForm">
              <div className="ebay-shipping-service-settings__inputs">
                <label id="freeShipping">Free Shipping</label>
                <Field
                  innerRef={ref}
                  type="checkbox"
                  name="freeShipping"
                  aria-labelledby="freeShipping"
                  disabled={!isFirst && values.sortOrder !== 1}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue("freeShipping", event.target.checked);
                    if (event.target.checked) {
                      setFieldValue("shippingCost", "0");
                    }
                  }}
                />
                <ErrorMessage
                  name="freeShipping"
                  className="ebay-shipping-service-settings__field-error"
                  component="div"
                />
                {costType === "FLAT_RATE" ? (
                  <>
                    {generateInput(
                      "shippingCost",
                      "Shipping Cost",
                      currencySymbol
                    )}
                  </>
                ) : null}
                {generateInput(
                  "additionalShippingCost",
                  "Additional Shipping Cost",
                  currencySymbol
                )}
                <label id="shippingServiceId">Shipping Service</label>
                <div>
                  <Field
                    as="select"
                    name="ebayTypeShippingServiceId.shippingServiceId"
                    aria-labelledby="shippingServiceId"
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                      const { value, options, selectedIndex } = event.target;
                      const selectedOption = options[selectedIndex];
                      const marketplace = selectedOption
                        ? selectedOption.getAttribute("data-marketplace")
                        : null;

                      setFieldValue(
                        "ebayTypeShippingServiceId.shippingServiceId",
                        value
                      );
                      setFieldValue(
                        "ebayTypeShippingServiceId.marketplace",
                        marketplace
                      );
                      setFieldValue(
                        "shippingServiceName",
                        // @ts-ignore
                        getShippingProviderDetails(Number(value), marketplace)
                          .serviceName
                      );
                    }}
                  >
                    <option value="">-- Select Shipping Service --</option>
                    {shippingServiceProviders
                      .filter((provider) =>
                        provider?.serviceTypes?.includes(mungedCostType)
                      )
                      .map((provider) => (
                        <option
                          key={
                            provider.ebayTypeShippingServiceId
                              ?.shippingServiceId
                          }
                          value={
                            provider.ebayTypeShippingServiceId
                              ?.shippingServiceId
                          }
                          data-marketplace={
                            provider.ebayTypeShippingServiceId?.marketplace
                          }
                        >
                          {provider.description}
                        </option>
                      ))}
                  </Field>
                  {values.ebayTypeShippingServiceId?.shippingServiceId ? (
                    // @ts-ignore
                    <EbayShippingServiceDetails
                      {...getShippingProviderDetails(
                        values.ebayTypeShippingServiceId.shippingServiceId,
                        // @ts-ignore
                        values.ebayTypeShippingServiceId.marketplace
                      )}
                      id={values.ebayTypeShippingServiceId.shippingServiceId}
                    />
                  ) : null}
                </div>
                <ErrorMessage
                  name="shippingServiceId"
                  className="ebay-shipping-service-settings__field-error"
                  component="div"
                />
                <label htmlFor="shipToLocationsIncluded">
                  Include locations
                </label>
                <div className="ebay-shipping-service-settings__country-select">
                  {generateOptionList(
                    "Include locations",
                    "shipToLocationsIncluded",
                    includeRegions,
                    values.shipToLocationsIncluded || [],
                    setFieldValue
                  )}
                </div>
                <ErrorMessage
                  name="shipToLocationsIncluded"
                  className="ebay-shipping-service-settings__field-error"
                  component="div"
                />
                <label htmlFor="shipToLocationsExcluded">
                  Exclude locations
                </label>
                <div className="ebay-shipping-service-settings__country-select">
                  {generateOptionList(
                    "Exclude locations",
                    "shipToLocationsExcluded",
                    excludeRegions,
                    values.shipToLocationsExcluded || [],
                    setFieldValue
                  )}
                </div>
                <ErrorMessage
                  name="shipToLocationsExcluded"
                  className="ebay-shipping-service-settings__field-error"
                  component="div"
                />
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Content>
      <Modal.Actions>
        <ButtonComponent onClick={() => cancel()}>Cancel</ButtonComponent>
        <ButtonComponent
          primary
          type="submit"
          form="ebayShippingServiceSettingsForm"
        >
          Ok
        </ButtonComponent>
      </Modal.Actions>
    </Modal>
  );
}

export default React.forwardRef(EbayShippingServiceSettings);
