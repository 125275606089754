import { ErrorModal, Header } from "./components";
import { inject, observer } from "mobx-react";
import React, { Fragment } from "react";
import { HashRouter as Router } from "react-router-dom";
import "./services/firebase";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "../css/portal-style.scss";
import LoginPage from "./views/auth/LoginPage";

import PosRouteWatcher from "./routers/PosRouteWatcher";
import FreshChat from "./components/services/FreshChat";
import FreshWorks from "./components/services/Freshdesk";
import Surveys from "./components/services/Surveys";
import ApiProvider from "./providers/ApiProvider";
import GlobalSettingsProvider from "./providers/GlobalSettingsProvider";
import ErrorProvider from "./providers/ErrorProvider";
import StoreProvider from "./providers/StoreProvider";
import DefaultAppRouter from "./routers/DefaultAppRouter";
import KioskRouter from "./routers/KioskRouter";
import ErrorBoundary from "./components/error/ErrorBoundary";
import Analytics from "./components/services/Analytics";
import CookieBanner from "./components/settings/CookieBanner";
import AuthStoreLoader from "./components/auth/AuthStoreLoader";
import { isKioskMode } from "./helpers/posModeHelpers";
import ADAChatbot from "./components/services/ADAChatbot";
import ShopifySubscriptionCheck from "./components/services/ShopifySubscriptionCheck";
import MenuProvider from "./providers/MenuProvider";

@inject("store", "posStore")
@observer
class App extends React.Component {
  menuToOpen = (dataFromChild) => {
    this.props.store.MenuStore.setSideMenuToDisplay(dataFromChild);
  };

  setFeatureFlags = (flags) => {
    this.props.posStore.setFeatureFlags.apply(this.props.posStore, [flags]);
    this.props.store.MainStore.setFeatureFlags.apply(
      this.props.store.MainStore,
      [flags]
    );
  };

  render() {
    const router = isKioskMode() ? <KioskRouter /> : <DefaultAppRouter />;
    return (
      <ErrorBoundary>
        <ErrorProvider>
          {this.props.store.MainStore.error || this.props.posStore.error ? (
            <ErrorModal />
          ) : null}
          <CookieBanner />
          <Router>
            <Analytics />
            <AuthStoreLoader
              authStoreLoaded={this.props.store.AuthStore.hasLoaded}
            >
              {this.props.store.AuthStore.user &&
              this.props.store.AuthStore.screenSettings &&
              this.props.store.AuthStore.screenSettings != "No account" &&
              this.props.store.AuthStore.screenSettings !== "Error" ? (
                <ApiProvider>
                  <StoreProvider>
                    <GlobalSettingsProvider
                      featureFlagsUpdater={this.setFeatureFlags}
                      user={this.props.store.AuthStore.user}
                    >
                      <MenuProvider>
                        <Header
                          sideMenu={
                            this.props.store.MenuStore.sideMenuToDisplay
                          }
                          sideMenuUpdate={this.menuToOpen}
                          screenSettings={
                            this.props.store.AuthStore.screenSettings
                          }
                        />

                        <main
                          id="binderpos-app"
                          className={
                            this.props.store.MenuStore.sideMenuToDisplay
                              ? "hasSubMenu"
                              : ""
                          }
                        >
                          <PosRouteWatcher />
                          <div className="app-content">
                            <section className="section">
                              <ShopifySubscriptionCheck>
                                {router}
                              </ShopifySubscriptionCheck>
                            </section>
                          </div>
                        </main>
                        {!isKioskMode() ? (
                          <Fragment>
                            <FreshChat
                              storeSettings={
                                this.props.store.SettingsStore.storeSettings
                              }
                            />
                            <FreshWorks
                              storeSettings={
                                this.props.store.SettingsStore.storeSettings
                              }
                            />
                            <ADAChatbot />
                            <Surveys />
                          </Fragment>
                        ) : null}
                      </MenuProvider>
                    </GlobalSettingsProvider>
                    <ToastContainer />
                  </StoreProvider>
                </ApiProvider>
              ) : (
                <LoginPage />
              )}
            </AuthStoreLoader>
          </Router>
        </ErrorProvider>
      </ErrorBoundary>
    );
  }
}

export default App;
