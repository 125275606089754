import React, { MutableRefObject } from "react";
import Modal from "../../components/generic/Modal";
import { TimeUtils } from "../../utils";
import { getRecurringDescription } from "../../helpers/eventHelpers";
import { EventInfo } from "../../types/events";
import "./ViewEventModal.scss";

interface ViewEventModalProps {
  event: EventInfo;
  handleClose: () => void;
}

function ViewEventModal(
  props: ViewEventModalProps,
  ref: MutableRefObject<any>
) {
  const { event, handleClose } = props;

  const {
    title,
    banner,
    calendarIcon,
    game,
    type,
    date,
    time,
    description,
    maxParticipants,
    prizeStructure,
    recurring,
    isTicketed,
    ticketPrice,
    ticketImage,
  } = event;
  return (
    <Modal ref={ref} onClose={handleClose} testId="ViewEventModal">
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        {banner ? (
          <>
            <h2 className="EventModal__subtitle">Background image</h2>
            <img
              className="EventModal__image"
              src={banner}
              role="presentation"
            />
          </>
        ) : null}
        {calendarIcon ? (
          <>
            <h3 className="EventModal__subtitle">Icon shown on calendar</h3>
            <img
              className="EventModal__image"
              src={calendarIcon}
              alt={`${title} icon`}
            />
          </>
        ) : null}
        <h3 className="EventModal__subtitle">Game</h3>
        <p>{game}</p>
        <h3 className="EventModal__subtitle">Type</h3>
        <p>{type}</p>
        <h3 className="EventModal__subtitle">Date</h3>
        <p>
          {new Date(date.substring(0, 17)).toLocaleDateString(undefined, {
            timeZone: "Etc/GMT",
          })}
        </p>
        <h3 className="EventModal__subtitle">Time</h3>
        <p>{TimeUtils.convertTime(time)}</p>
        {description ? (
          <>
            <h3 className="EventModal__subtitle">Description</h3>
            <div dangerouslySetInnerHTML={{ __html: description }}></div>
            <br />
          </>
        ) : null}
        {maxParticipants ? (
          <>
            <h3 className="EventModal__subtitle">Max participants</h3>
            <p>{maxParticipants}</p>
          </>
        ) : null}
        {prizeStructure ? (
          <>
            <h3 className="EventModal__subtitle">Prize structure</h3>
            <div dangerouslySetInnerHTML={{ __html: prizeStructure }}></div>
            <br />
          </>
        ) : null}
        {recurring ? (
          <>
            <h3 className="EventModal__subtitle">Recurring details</h3>
            <p>{getRecurringDescription(event)}</p>
          </>
        ) : null}
        {isTicketed ? (
          <>
            <h3 className="EventModal__subtitle">Ticket Price</h3>
            <p>${ticketPrice}</p>
            <h3 className="EventModal__subtitle">Ticket image</h3>
            <img
              className="EventModal__image"
              src={
                ticketImage ||
                "https://storage.googleapis.com/binderpos-event-images/binderpos-ticket.png"
              }
              alt={`${title} ticket`}
            />
          </>
        ) : null}
      </Modal.Content>
    </Modal>
  );
}

export default React.forwardRef(ViewEventModal);
