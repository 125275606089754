import React from "react";
import { ErrorMessage, Field, getIn, FormikProps } from "formik";

interface TextAreaProps {
  field: HTMLTextAreaElement;
  form: FormikProps<{}>;
  label?: string;
  requiredLabel?: boolean;
}

function TextArea(props: TextAreaProps) {
  const { field, form, ...rest } = props;
  const { name, ...inputProps } = field;
  const { label, requiredLabel } = rest;
  const { errors } = form;

  const inputClass = getIn(errors, name) ? "input input--error" : "input";

  return (
    <>
      <label className="label" htmlFor={name}>
        {label}
        {requiredLabel ? <span className="label--required"> *</span> : null}
      </label>
      <div className="input-group">
        <Field
          id={name}
          name={name}
          className={inputClass}
          as="textarea"
          {...inputProps}
        />
        <ErrorMessage name={name} className="input-error" component="div" />
      </div>
    </>
  );
}

export default TextArea;
