import { _fetch } from "..";
import { BASE_URL, API_V2_BASE_URL } from "../../constants/api";
import { GlobalPriceRule, RarityFloor } from "../../types/settings";

export const fetchPriceRuleSettings = async () => {
  return await _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/settings/priceRulesForGames`,
  });
};

export const fetchDefaultPriceRuleSettings = async () => {
  return await _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/settings/priceRulesForGames/default`,
  });
};

export async function updateGamePriceRule(gamePriceRule) {
  return await _fetch({
    method: "PUT",
    endpoint: `${API_V2_BASE_URL}/customer/pricing/games`,
    payload: gamePriceRule,
  });
}

export async function updateGlobalPriceRules(
  priceRules: GlobalPriceRule
): Promise<GlobalPriceRule> {
  return await _fetch({
    method: "PUT",
    endpoint: `${API_V2_BASE_URL}/customer/pricing/default`,
    payload: priceRules,
  });
}

export async function createGamePriceFloor({
  rarityFloor,
  gameCode,
}: {
  rarityFloor: RarityFloor;
  gameCode: string;
}): Promise<RarityFloor> {
  return await _fetch({
    method: "POST",
    endpoint: `${API_V2_BASE_URL}/customer/pricing/games/${gameCode}/rarityFloor`,
    payload: rarityFloor,
  });
}

export async function updateGamePriceFloor({
  rarityFloor,
  gameCode,
}: {
  rarityFloor: RarityFloor;
  gameCode: string;
}): Promise<RarityFloor> {
  return await _fetch({
    method: "PUT",
    endpoint: `${API_V2_BASE_URL}/customer/pricing/games/${gameCode}/rarityFloor/${rarityFloor.id}`,
    payload: rarityFloor,
  });
}

export async function deleteGamePriceFloor({
  gameCode,
  rarityFloorId,
}: {
  gameCode: string;
  rarityFloorId: number | "";
}): Promise<RarityFloor> {
  return await _fetch({
    method: "DELETE",
    endpoint: `${API_V2_BASE_URL}/customer/pricing/games/${gameCode}/rarityFloor/${rarityFloorId}`,
  });
}
