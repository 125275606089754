import React, { useEffect, useRef, useState } from "react";
import ButtonComponent from "../generic/ButtonComponent";
import Modal from "../generic/Modal";
import {
  useLocalSetting,
  useLocalSettingUpdate,
} from "../../hooks/localSettingsHooks";
import {
  COOKIES,
  COOKIES_ANALYTICS,
  COOKIES_FUNCTIONAL,
  COOKIES_ADVERTISING,
} from "../../constants/settings";
import "./CookieSettings.scss";

interface CookieSettingsProps {
  titleOverride?: string;
  onClose: () => void;
}

function CookieSettings(props: CookieSettingsProps) {
  const { titleOverride, onClose } = props;
  const modalRef = useRef<HTMLElement>();
  const analyticsPreference = Boolean(useLocalSetting(COOKIES_ANALYTICS));
  const functionalPreference = Boolean(useLocalSetting(COOKIES_FUNCTIONAL));
  const advertisingPreference = Boolean(useLocalSetting(COOKIES_ADVERTISING));
  const [analyticsEnabled, setAnalyticsEnabled] = useState(analyticsPreference);
  const [functionalEnabled, setFunctionalEnabled] =
    useState(functionalPreference);
  const [advertisingEnabled, setAdvertisingEnabled] = useState(
    advertisingPreference
  );

  const updateLocalSetting = useLocalSettingUpdate();

  const handleSave = () => {
    const now = Date.now();
    updateLocalSetting(COOKIES, now);
    updateLocalSetting(COOKIES_ANALYTICS, analyticsEnabled);
    updateLocalSetting(COOKIES_FUNCTIONAL, functionalEnabled);
    updateLocalSetting(COOKIES_ADVERTISING, advertisingEnabled);
    onClose();
  };

  useEffect(() => {
    if (modalRef.current) modalRef.current?.focus();
  }, []);

  return (
    <Modal onClose={handleSave}>
      <Modal.Header>
        {titleOverride ?? "Do Not Sell or Share My Personal Information"}
      </Modal.Header>
      <Modal.Content>
        <p>
          While we do not sell personal information to third parties for money,
          we, like many companies, use services that help deliver interest-based
          ads to you as described in our{" "}
          <a
            href="https://binderpos.com/binderpos-privacy-policy"
            target="_blank"
            rel="noopener"
            className="CookieBanner__link"
          >
            Privacy Policy
          </a>
          . Some privacy laws, including the California Consumer Privacy Act
          (CCPA), classify our use of these services as the “sale” or “sharing”
          of your personal information with the partners that provide these
          advertising services to us (i.e., our “advertising partners”), and
          these laws may provide you with the right to opt-out of such “sale” or
          “sharing”.
        </p>
        <p>
          You may opt-out of our “sale” or “sharing” of your personal
          information with these advertising partners by toggling the
          “Advertising” cookie category to “No”, below.
        </p>
        <p>
          You may also choose to utilize the Global Privacy Control (GPC) signal
          from your browser to automatically opt-out of our “sale” or “sharing”
          of your personal information from or for that particular browser with
          our advertising partners. You can learn more about GPC by visiting the{" "}
          <a
            href="https://globalprivacycontrol.org/"
            target="_blank"
            rel="noopener"
            className="CookieBanner__link"
          >
            Global Privacy Control website
          </a>
          .
        </p>
        <p>
          Please note that, because both of the elections above are
          browser-specific, your request to opt-out in each instance will only
          apply to the browser and the device from which you submit the request,
          and you will need to repeat this process on each device and browser
          that you use.
        </p>
        <br />
        <table className="CookieSettings__table">
          <thead>
            <tr>
              <th>Allow</th>
              <th>Cookie Categories</th>
              <th>Purpose of Cookies</th>
              <th>Cookies and other Technologies</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="CookieSettings__options">
                <label className="CookieSettings__label">
                  <input
                    type="radio"
                    className="CookieSettings__input"
                    onChange={() => setFunctionalEnabled(true)}
                    checked={functionalEnabled}
                  />
                  Yes
                </label>
                <label className="CookieSettings__label">
                  <input
                    type="radio"
                    className="CookieSettings__input"
                    onChange={() => setFunctionalEnabled(false)}
                    checked={!functionalEnabled}
                  />
                  No
                </label>
              </td>
              <td className="CookieSettings__category">Functional</td>
              <td>
                <p>
                  To monitor the performance of our site and to enhance your
                  browsing experience. For example, these tools enable you to
                  communicate with us via live chat.
                </p>
              </td>
              <td>FreshChat</td>
            </tr>
            <tr>
              <td className="CookieSettings__options">
                <label className="CookieSettings__label">
                  <input
                    type="radio"
                    className="CookieSettings__input"
                    onChange={() => setAnalyticsEnabled(true)}
                    checked={analyticsEnabled}
                  />
                  Yes
                </label>
                <label className="CookieSettings__label">
                  <input
                    type="radio"
                    className="CookieSettings__input"
                    onChange={() => setAnalyticsEnabled(false)}
                    checked={!analyticsEnabled}
                  />
                  No
                </label>
              </td>
              <td className="CookieSettings__category">
                Marketing and Analytics
              </td>
              <td>
                <p>
                  To understand user behavior in order to provide you with a
                  more relevant browsing experience or personalize the content
                  on our site.
                </p>
                <p>
                  For example, we collect information about which pages you
                  visit to help us present more relevant information.
                </p>
              </td>
              <td>Google Analytics, New Relic</td>
            </tr>

            <tr>
              <td className="CookieSettings__options">
                <label className="CookieSettings__label">
                  <input
                    type="radio"
                    className="CookieSettings__input"
                    onChange={() => setAdvertisingEnabled(true)}
                    checked={advertisingEnabled}
                  />
                  Yes
                </label>
                <label className="CookieSettings__label">
                  <input
                    type="radio"
                    className="CookieSettings__input"
                    onChange={() => setAdvertisingEnabled(false)}
                    checked={!advertisingEnabled}
                  />
                  No
                </label>
              </td>
              <td className="CookieSettings__category">Advertising</td>
              <td>
                <p>
                  To personalize and measure the effectiveness of advertising on
                  our site and other websites. For example, we may serve you a
                  personalized ad based on the pages you visit on our site.
                </p>
              </td>
              <td>Google Tag Manager</td>
            </tr>

            <tr>
              <td className="CookieSettings__options">N/A</td>
              <td className="CookieSettings__category">Essential</td>
              <td>
                <p>
                  We use browser cookies that are necessary for the site to work
                  as intended.
                </p>
                <p>
                  For example, we store your website data collection preferences
                  so we can honor them if you return to our site. You can
                  disable these cookies in your browser settings but if you do
                  the site may not work as intended.
                </p>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </Modal.Content>
      <Modal.Actions>
        <ButtonComponent primary onClick={handleSave} ref={modalRef}>
          Save Preferences
        </ButtonComponent>
      </Modal.Actions>
    </Modal>
  );
}

export default CookieSettings;
