import React from "react";
import { API_STATE } from "../../constants/api";
import { useContext } from "react";
import { convertToCsv } from "../../utils/data";
import DateRangeSelect from "./DateRangeSelect";
import { ReportsContext } from "../../providers/ReportsProvider";
import { forceDownloadFromData } from "../../helpers/fileHelpers";

interface ReportControlsProps {
  csvHeaders: any[];
  csvFilename: string;
  loadData?: VoidFunction;
  setDateRange?: (...args: any[]) => any;
  defaultFromDate?: string;
  defaultToDate?: string;
  children?: React.ReactNode;
}

function ReportControls(props: ReportControlsProps) {
  const {
    loadData,
    setDateRange,
    children,
    defaultFromDate,
    defaultToDate,
    csvHeaders,
    csvFilename,
  } = props;

  const {
    apiState,
    currentPage,
    rowsPerPage,
    sourceData,
    totalRowCount,
    setTableRowsPerPage,
    setCurrentPage,
    setOverrideTableHeight,
  } = useContext(ReportsContext);

  const onSaveCSVClick = () => {
    const filename = csvFilename || "report.csv";
    convertToCsv(csvHeaders, sourceData).then((csv) => {
      forceDownloadFromData(csv, filename, "text/csv");
    });
  };

  const onPrintClick = () => {
    const originalPage = currentPage;
    const originalRowsPerPage = rowsPerPage;
    setTableRowsPerPage(totalRowCount);
    setCurrentPage(0);
    setOverrideTableHeight(totalRowCount * 50 + 75);
    setTimeout(() => {
      window.print();
      setCurrentPage(originalPage);
      setTableRowsPerPage(originalRowsPerPage);
      setOverrideTableHeight(0);
    }, 100);
  };

  if (!!loadData && apiState === API_STATE.INITIAL) {
    return (
      <div className="report-buttons">
        <button
          type="button"
          className="full-width"
          onClick={loadData}
          disabled={apiState === API_STATE.LOADING}
        >
          <i className="fas fa-table" />
          Load Data
        </button>
      </div>
    );
  }

  if (!loadData || apiState === API_STATE.SUCCESS) {
    return (
      <div className="report-buttons">
        {setDateRange ? (
          <DateRangeSelect
            setDateRange={setDateRange}
            defaultFromDate={defaultFromDate}
            defaultToDate={defaultToDate}
          />
        ) : (
          <div />
        )}
        {children}
        <div className="export">
          {
            /*
              Hide CSV exports for browsers that don't support the File API 
              */
            window.File && (
              <button
                type="button"
                onClick={onSaveCSVClick}
                disabled={apiState === API_STATE.LOADING}
              >
                <i className="fas fa-download" />
                Save as CSV
              </button>
            )
          }
          <button type="button" onClick={onPrintClick}>
            <i className="fas fa-print" />
            Print
          </button>
        </div>
      </div>
    );
  }

  return null;
}

export default ReportControls;
