import React from "react";
import { Field, Form, Formik } from "formik";
import { SAVE_TAX_SETTINGS_CLICKED } from "../../constants/eventsTracked";
import { POSMenuStructure } from "../../menuStructures";
import Input from "../../components/form/Input";
import ButtonComponent from "../../components/generic/ButtonComponent";
import CheckboxComponent from "../../components/generic/CheckboxComponent";
import Loader from "../../components/generic/Loader";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";
import ActionButtonsLayout from "../../components/layout/ActionButtonsLayout";
import { segmentAnalytics } from "../../components/services/Analytics";
import { notify } from "../../helpers/notificationHelpers";
import { useShowError } from "../../hooks/errorHooks";
import {
  useGetCustomerSettings,
  useUpdateSetting,
} from "../../hooks/settingHooks";
import useTitle from "../../hooks/useTitle";
import SetActiveMenu from "../../menuStructures/SetActiveMenu";
import "./TaxSettings.scss";

interface TaxSettingsProps {
  updatePosTax: (
    taxRate: number,
    taxIncluded: boolean,
    taxNumber: string,
    tradeInTax: boolean,
    taxWording: string
  ) => void;
}

type FormValues = {
  taxRate: number;
  taxIncluded: boolean;
  taxNumber: string;
  tradeInTax: boolean;
  taxWording: string;
};

function TaxSettings(props: TaxSettingsProps) {
  const { updatePosTax } = props;
  const showError = useShowError();
  const { data: settings, isLoading, error } = useGetCustomerSettings();
  const { mutateAsync: updateSetting } = useUpdateSetting();
  useTitle("Tax Settings");

  const saveTaxSettings = async (values: FormValues) => {
    segmentAnalytics.track(SAVE_TAX_SETTINGS_CLICKED, {
      tax_rate: values.taxRate,
      prices_include_tax: values.taxIncluded,
      trade_ins_include_tax: values.tradeInTax,
    });
    return Promise.all(
      ["taxRate", "taxIncluded", "taxNumber", "tradeInTax", "taxWording"].map(
        (settingName) =>
          updateSetting({
            settingName,
            settingValue: String(values[settingName]),
          })
      )
    )
      .then(() => {
        notify.info("Tax settings updated!");
        updatePosTax(
          values.taxRate,
          values.taxIncluded,
          values.taxNumber,
          values.tradeInTax,
          values.taxWording
        );
      })
      .catch((error) => {
        showError(error);
      });
  };

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return (
      <>
        <SetActiveMenu menuStructure={POSMenuStructure} />
        <SectionHeaderLayout title="Tax Settings" />
        <p>Failed to load your tax settings. Please refresh and try again</p>
      </>
    );
  }

  return (
    <div className="TaxSettings">
      <SetActiveMenu menuStructure={POSMenuStructure} />
      <SectionHeaderLayout title="Tax Settings" />

      <Formik
        initialValues={settings}
        enableReinitialize
        onSubmit={saveTaxSettings}
      >
        {({ isSubmitting, dirty, values, resetForm, setFieldValue }) => (
          <Form>
            <div className="TaxSettings__form">
              <div className="TaxSetting__input">
                <Field
                  component={Input}
                  name="taxRate"
                  type="number"
                  min="0"
                  required
                  label="Your tax rate"
                  placeholder="For example 10%"
                  step="any"
                />
              </div>
              <div className="TaxSetting__input">
                <Field
                  component={Input}
                  name="taxNumber"
                  label="Your tax number"
                  placeholder="For example 123-345-678"
                />
              </div>
              <div className="TaxSetting__input">
                <CheckboxComponent
                  name="taxIncluded"
                  label="Prices include tax?"
                  checked={values.taxIncluded}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue("taxIncluded", event.target.checked)
                  }
                />
              </div>
              <div className="TaxSetting__input">
                <CheckboxComponent
                  name="tradeInTax"
                  label="Trade ins include tax?"
                  checked={values.tradeInTax}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue("tradeInTax", event.target.checked)
                  }
                />
              </div>
              <div className="TaxSetting__input">
                <Field
                  component={Input}
                  name="taxWording"
                  label="Tax wording for receipt"
                  placeholder="For example GST"
                />
              </div>
            </div>
            <ActionButtonsLayout>
              <ButtonComponent
                primary
                type="submit"
                disabled={!dirty || isSubmitting}
              >
                Save Tax Settings
              </ButtonComponent>
              <ButtonComponent
                secondary
                onClick={() => resetForm()}
                disabled={!dirty}
              >
                Cancel
              </ButtonComponent>
            </ActionButtonsLayout>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default TaxSettings;
