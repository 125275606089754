import { isNull } from "./valueHelpers";

export const isVariantForSale = (
  variant: Variant,
  stockLimit: boolean,
  isKioskMode: boolean
) => {
  if (isKioskMode) {
    return variant?.quantity > 0;
  }
  return !!variant && !(Number(variant?.quantity) <= 0 && stockLimit);
};

export const isVariantForPurchase = (
  variant: Variant,
  isCashPrice: boolean,
  showBuyLimitWarnings: boolean
) => {
  if (!variant) {
    return false;
  }
  if (!showBuyLimitWarnings) {
    return true;
  }
  if (variant.maxPurchaseQuantity === null) {
    return true;
  }

  const canPurchaseOverstock = isCashPrice
    ? !isNull(variant.overtStockBuyPrice)
    : !isNull(variant.creditOverstockBuyPrice);
  return canPurchaseOverstock || variant.quantity < variant.maxPurchaseQuantity;
};

export const getVariantBuyPrice = (variant: Variant, cashPrice: boolean) => {
  if (!variant) {
    return false;
  }
  // TODO:: Not yet supported by the backend
  // const isOverstock = variant.quantity >= variant.maxPurchaseQuantity;
  // if (isOverstock) {
  //   return cashPrice
  //     ? variant?.overtStockBuyPrice?.toFixed(2)
  //     : variant?.creditOverstockBuyPrice?.toFixed(2);
  // }
  return cashPrice
    ? variant?.cashBuyPrice?.toFixed(2)
    : variant?.storeCreditBuyPrice?.toFixed(2);
};
