import React, { Component } from "react";
import { action, runInAction } from "mobx";
import OutsideClickHandler from "react-outside-click-handler";
import { BaseLineItem } from "../../types/pos";

interface LineDiscountProps {
  item: BaseLineItem;
  close: () => void;
  discountInput: (input: HTMLInputElement) => void;
}

class LineDiscount extends Component<
  LineDiscountProps,
  { discountModal: Boolean }
> {
  discountInput: HTMLInputElement;

  constructor(props) {
    super(props);
    this.state = { discountModal: false };
  }

  componentDidMount() {
    this.discountInput.focus();
    runInAction(() => {
      if (!this.props.item.discountType) {
        this.props.item.discountType = "percentage";
      }
    });
  }

  @action
  discountChange = (e) => {
    const floatRegExp = new RegExp("^[+-]?([0-9]+([.][0-9]{0,2})?|[.][0-9]+)$");
    if (e.target.id == "discountAmount") {
      if (e.target.value === "" || floatRegExp.test(e.target.value)) {
        this.props.item.discountAmount = e.target.value;
      }
    }
    if (e.target.id == "percent") {
      this.props.item.discountType = "percentage";
      this.props.close();
    }
    if (e.target.id == "amount") {
      this.props.item.discountType = "amount";
      this.props.close();
    }
  };

  handleEnter = (e) => {
    if (e.key === "Enter") {
      this.props.close();
    }
  };

  render() {
    const item = this.props.item;
    return (
      <OutsideClickHandler
        onOutsideClick={() => {
          if (!this.props.item.discountAmount) {
            runInAction(() => (this.props.item.discountAmount = 0));
          }
          this.props.close();
        }}
      >
        <span className="discounts">
          Choose a discount:{" "}
          <div className="discountWrapper">
            <button
              id="amount"
              className={
                item.discountType == "amount" ? "amount" : "amount notActive"
              }
              onClick={this.discountChange}
              aria-label="Discount type - value amount"
              aria-pressed={item.discountType == "amount"}
            >
              $
            </button>
            <input
              type="text"
              aria-label="Discount amount"
              name="discountAmount"
              id="discountAmount"
              value={item.discountAmount}
              onChange={this.discountChange}
              onKeyDown={this.handleEnter}
              ref={(input) => {
                this.props.discountInput(input);
                this.discountInput = input;
              }}
            />
            <button
              id="percent"
              className={
                item.discountType == "percentage"
                  ? "percentage"
                  : "percentage notActive"
              }
              aria-label="Discount type - percentage"
              aria-pressed={item.discountType == "percentage"}
              onClick={this.discountChange}
            >
              %
            </button>
          </div>
        </span>
      </OutsideClickHandler>
    );
  }
}

export default LineDiscount;
