/**
 * checks that the image to be uploaded is of the correct mime type and size
 *
 * @param {bytes} headerBytes
 *
 * @return {object}
 */
export const validateFile = (headerBytes) => {
  const validHeaders = [
    "89504e47", // image/png
    "47494638", // image/gif
    "ffd8ffe0", // image/jpeg
    "ffd8ffe1", // image/jpeg
    "ffd8ffe2", // image/jpeg
    "ffd8ffe3", // image/jpeg
    "ffd8ffe8", // image/jpeg
  ];

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = (event) => {
      if (event.target.readyState === FileReader.DONE) {
        let header = "";
        const bytes = new Uint8Array(event.target.result);
        for (let i = 0; i < bytes.length; i++) {
          header += bytes[i].toString(16);
        }
        if (validHeaders.includes(header)) {
          resolve();
        } else {
          reject("File is not one of the allowed types");
        }
      }
    };
    reader.readAsArrayBuffer(headerBytes);
  });
};
