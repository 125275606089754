import React, { ComponentProps, MutableRefObject, ReactNode } from "react";
import { Link } from "react-router-dom";
import "./ButtonComponent.scss";
import { segmentAnalytics, SegmentEventProps } from "../services/Analytics";

type IconPosition = "left" | "right";

interface Anchor extends ComponentProps<"a"> {
  isLink?: false;
  to?: string;
  isAnchor?: true;
}

type LinkProps =
  | {
      isLink: true;
      to: string;
      isAnchor?: false;
    }
  | {
      isLink?: false;
      to?: string;
      isAnchor?: false;
    }
  | Anchor;

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  primary?: boolean;
  secondary?: boolean;
  danger?: boolean;
  warning?: boolean;
  fullWidth?: boolean;
  halfWidth?: boolean;
  compact?: boolean;
  icon?: string;
  iconPosition?: IconPosition;
  type?: "submit" | "reset" | "button";
}

type ButtonComponentProps = LinkProps & ButtonProps & SegmentEventProps;

const getIcon = (iconCode?: string) => {
  if (!iconCode) return null;
  return <i className={iconCode} />;
};

const getContent = (
  children: ReactNode,
  icon?: string,
  iconPosition?: IconPosition
) =>
  iconPosition === "left" ? (
    <>
      {getIcon(icon)}
      {children}
    </>
  ) : (
    <>
      {children}
      {getIcon(icon)}
    </>
  );

const getClassName = (
  primary?: boolean,
  secondary?: boolean,
  danger?: boolean,
  warning?: boolean,
  fullWidth?: boolean,
  halfWidth?: boolean,
  compact?: boolean,
  className?: string
) =>
  [
    "ButtonComponent",
    primary ? "ButtonComponent__primary" : null,
    secondary ? "ButtonComponent__secondary" : null,
    danger ? "ButtonComponent__danger" : null,
    fullWidth ? "ButtonComponent--full-width" : null,
    halfWidth ? "ButtonComponent--half-width" : null,
    compact ? "ButtonComponent--compact" : null,
    warning ? "ButtonComponent--warning" : null,
    className || null,
  ]
    .filter(Boolean)
    .join(" ");

function ButtonComponent(
  props: ButtonComponentProps,
  ref: MutableRefObject<any>
) {
  const {
    children,
    className,
    icon,
    iconPosition = "left",
    disabled = false,
    type = "button",
    primary = false,
    secondary = false,
    warning = false,
    fullWidth,
    halfWidth,
    compact,
    danger,
    isLink,
    isAnchor,
    to,
    id,
    segmentEventName,
    segmentEventProperties,
    onClick,
    ...rest
  } = props;

  const mergedClassName = getClassName(
    primary,
    secondary,
    danger,
    warning,
    fullWidth,
    halfWidth,
    compact,
    className
  );

  const clickHandler = (
    event: React.MouseEvent<HTMLButtonElement & HTMLAnchorElement>
  ) => {
    if (segmentEventName) {
      segmentAnalytics.track(segmentEventName, segmentEventProperties);
    }
    onClick?.(event);
  };

  if (isLink)
    return (
      <Link
        to={to}
        className={mergedClassName}
        id={id}
        onClick={clickHandler}
        {...(rest as any)}
      >
        {getContent(children, icon, iconPosition)}
      </Link>
    );

  if (isAnchor)
    return (
      <a
        className={mergedClassName}
        id={id}
        onClick={clickHandler}
        {...(rest as any)}
      >
        {getContent(children, icon, iconPosition)}
      </a>
    );

  return (
    <button
      type={type}
      className={mergedClassName}
      disabled={disabled}
      id={id}
      onClick={clickHandler}
      {...rest}
      ref={ref}
    >
      {getContent(children, icon, iconPosition)}
    </button>
  );
}

export default React.forwardRef(ButtonComponent);
