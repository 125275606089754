import useTitle from "../../hooks/useTitle";
import SettingsMenu from "../../menuStructures/SettingsMenu";
import SettingsSection from "../../components/settings/SettingsSection";
import StoreReceiptsSettingsContainer from "../../components/settings/StoreReceiptsSettingsContainer";
import StoreDetailsSettingsContainer from "../../components/settings/StoreDetailsSettingsContainer";
import StoreLogo from "../../components/settings/StoreLogo";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";
import BuylistConfirmationSettings from "../../components/settings/BuylistConfirmationSettings";

function StoreSettings() {
  useTitle("Store Settings");

  return (
    <>
      <SettingsMenu />
      <SectionHeaderLayout title="Store Settings" />
      <StoreDetailsSettingsContainer />
      <StoreReceiptsSettingsContainer />
      <SettingsSection title="Customize Images">
        <StoreLogo />
      </SettingsSection>
      <SettingsSection title="Buylist Settings">
        <BuylistConfirmationSettings />
      </SettingsSection>
    </>
  );
}

export default StoreSettings;
