import React from "react";
import SetActiveMenu from "../../menuStructures/SetActiveMenu";
import { EventMenuStructure } from "../../menuStructures";
import EventDateFormatSetting from "../../components/events/EventDateFormatSetting";
import EventDateTitleSetting from "../../components/events/EventDateTitleSetting";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";

function EventSettings() {
  return (
    <>
      <SetActiveMenu menuStructure={EventMenuStructure} />
      <SectionHeaderLayout title="Event Settings" />
      <EventDateTitleSetting />
      <EventDateFormatSetting />
    </>
  );
}

export default EventSettings;
