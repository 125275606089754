import { action } from "mobx";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import GridItemMenu from "./GridItemMenu";
import GridItemBuy from "./GridItemBuy";
import GridItemSell from "./GridItemSell";
import { isKioskMode } from "../../helpers/posModeHelpers";
import { ItemList } from "../../pos/ItemStore";

interface GridItemProps {
  posStore?: ItemList;
  item: any;
  addItem: (item: any) => void;
}
@inject("posStore")
@observer
class GridItem extends Component<GridItemProps> {
  @action
  checkStock = () => {
    if (!this.props.posStore.stockLimit) {
      if (this.props.item?.selectedVariant < 0) {
        this.props.item.selectedBuyVariant = 0;
      } else if (this.props.item?.selectedVariant >= 0) {
        this.props.item.selectedBuyVariant = this.props.item.selectedVariant;
      }
      if (this.props.item?.selectedVariant < 1) {
        this.props.item.selectedVariant = 0;
      }
    }
  };

  @action
  getStockedProduct() {
    if (this.props.item?.selectedVariant >= 0) {
      return;
    }
    const revArr = this.props.item.variants.slice().reverse();
    revArr.forEach((variant, index) => {
      if (variant.quantity > 0) {
        this.props.item.selectedVariant =
          this.props.item.variants.length - index - 1;
      }
    });
  }

  @action
  changeVariant = (id, buyMode) => {
    if (buyMode) {
      this.props.item.selectedBuyVariant = Number(id);
    } else {
      this.props.item.selectedVariant = Number(id);
    }
  };

  totalInStock = this.props.item.variants.reduce(
    (total, variant) => total + variant.quantity,
    0
  );

  getStockLevelClass = (stockLevel) => {
    if (stockLevel > 1) {
      return "stockAvailable";
    }
    if (stockLevel === 1) {
      return "stockLowStock";
    }
    return "stockOutOfStock";
  };

  componentDidMount() {
    this.checkStock();
    this.getStockedProduct();
  }

  render() {
    const item = this.props.item;
    const kioskModeEnabled = isKioskMode();
    const stockLevelClass = this.getStockLevelClass(this.totalInStock);

    return (
      <>
        <div className={`cardpos ${stockLevelClass}`} key={item.id}>
          {this.props.posStore.buyMode ? (
            <GridItemBuy
              item={item}
              totalInStock={this.totalInStock}
              cashPrice={this.props.posStore.cashPrice}
              currency={this.props.posStore.currency}
              showBuyLimitWarnings={this.props.posStore.showBuyLimitWarnings}
              isLoading={this.props.posStore.cartLoading}
              addItem={this.props.addItem}
              changeVariant={this.changeVariant}
            />
          ) : (
            <GridItemSell
              item={item}
              totalInStock={this.totalInStock}
              currency={this.props.posStore.currency}
              stockLimit={this.props.posStore.stockLimit}
              isKioskMode={kioskModeEnabled}
              isLoading={this.props.posStore.cartLoading}
              setAdditionalInfoItem={this.props.posStore.setAdditionalInfoItem}
              addItem={this.props.addItem}
              changeVariant={this.changeVariant}
            />
          )}
        </div>
        <GridItemMenu
          item={item}
          currentNav={this.props.posStore.currentNav}
          customerName={this.props.posStore.customerInfo.name}
          customerSiteUrl={this.props.posStore.customerInfo.siteUrl}
          updateQuickLinks={this.props.posStore.updateQuickLinks}
        />
      </>
    );
  }
}

export default GridItem;
