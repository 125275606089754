import React from "react";
import { HeaderCell, HeaderCellProps } from "rsuite-table";

type CustomHeaderCellProps = Omit<HeaderCellProps, "children"> & {
  title: string;
  srOnlyTitle?: string;
  children?: React.ReactChild;
};

const CustomHeaderCell = (props: CustomHeaderCellProps) => {
  const { title, srOnlyTitle, children, ...rest } = props;

  return (
    <HeaderCell className="table-header" {...rest}>
      <div className="table-header__title">
        {title}
        {srOnlyTitle ? <span className="sr-only">{srOnlyTitle}</span> : null}
      </div>
      <div
        className="table-header__sub-header"
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </HeaderCell>
  );
};

export default CustomHeaderCell;
