import React from "react";
import { observer } from "mobx-react";
import { ContextMenuTrigger } from "react-contextmenu";
import SellIcon from "../../../assets/img/buy.svg";
import { isVariantForSale } from "../../helpers/posHelpers";
import ItemTitle from "./ItemTitle";
import ButtonComponent from "../generic/ButtonComponent";

interface GridItemSellProps {
  item: Product;
  totalInStock: number;
  currency: string;
  stockLimit: boolean;
  isKioskMode: boolean;
  isLoading: boolean;
  setAdditionalInfoItem: Function;
  addItem: Function;
  changeVariant: Function;
}

function GridItemSell(props: GridItemSellProps) {
  const {
    item,
    totalInStock,
    currency,
    stockLimit,
    isKioskMode,
    isLoading,
    setAdditionalInfoItem,
    addItem,
    changeVariant,
  } = props;

  const handleVariantChange = (event: React.ChangeEvent<HTMLSelectElement>) =>
    changeVariant(event.target.value, false);

  return (
    <>
      <ContextMenuTrigger id={item.id.toFixed()}>
        <img className="cartBadge" src={SellIcon} role="presentation" />
        <span className="stock">Stock: {totalInStock} </span>
        <div className="cardpos-container">
          <img src={item.img} role="presentation" />
        </div>
      </ContextMenuTrigger>
      <>
        <div className="productDetails">
          <ItemTitle title={item.title} event={item.event} />
          <span className="sellPrice sellPrice--hidden" />
          <span className="variant">
            <select
              aria-label="Variant selector"
              value={item.selectedVariant}
              onChange={handleVariantChange}
            >
              {item.variants.map((variant, index) => (
                <option
                  className={
                    variant.quantity >= 0
                      ? variant.quantity == 0
                        ? "blackClass"
                        : "greenClass"
                      : "redClass"
                  }
                  disabled={!isVariantForSale(variant, stockLimit, isKioskMode)}
                  value={index}
                  key={index}
                >
                  {variant.title + "\tx" + variant.quantity}
                </option>
              ))}
            </select>
          </span>
        </div>
        {item.selectedVariant > -1 ? (
          <span className="price">
            {currency + " "}{" "}
            {item.variants[item.selectedVariant]?.price?.toFixed(2)}
          </span>
        ) : (
          <span className="price noStock">Out of Stock</span>
        )}
        {isVariantForSale(
          item.variants[item.selectedVariant],
          stockLimit,
          isKioskMode
        ) ? (
          <ButtonComponent
            primary
            compact
            halfWidth
            aria-label={`Add ${item?.title} - ${
              item?.variants[item?.selectedBuyVariant]?.title
            } to your cart`}
            disabled={isLoading}
            onClick={() => {
              if (
                item.event?.additionalInfo &&
                item.event.additionalInfo.length > 0
              ) {
                return setAdditionalInfoItem(item);
              }
              addItem(item);
            }}
            icon="fas fa-plus"
          >
            Add
          </ButtonComponent>
        ) : (
          <span className="no-stock">Unavailable</span>
        )}
      </>
    </>
  );
}

export default observer(GridItemSell);
