import React from "react";
import { inject, observer } from "mobx-react";
import { POSMenuStructure } from "../../menuStructures";
import { ItemList } from "../../pos/ItemStore";
import { RootStore } from "../../store";
import CartList from "./CartList";

type CartViewWrapperProps = {
  posStore?: ItemList;
  store?: RootStore;
};

function CartListWrapper({ store }: CartViewWrapperProps) {
  return (
    <CartList
      setSideMenu={() => store.MenuStore.setSideMenuToDisplay(POSMenuStructure)}
      posEnabled={store.AuthStore.screenSettings.pos}
    />
  );
}

export default inject("store", "posStore")(observer(CartListWrapper));
