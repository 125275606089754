import { useContext } from "react";
import { ReportsContext } from "../providers/ReportsProvider";

export const useReportsDetails = () => {
  const context = useContext(ReportsContext);
  if (!context) {
    throw new Error(
      "useReportsDetails hook must be used within ReportsProvider"
    );
  }
  return context;
};
