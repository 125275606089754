import ButtonComponent from "./ButtonComponent";
import "./Paging.scss";

interface PagingProps {
  pageOffset: number;
  currentPageItemCount: number;
  maxItemsPerPage: number;
  pageSize?: number;
  pageSizeOptions?: number[];
  disabled?: boolean;
  getPrevPage: () => void;
  getNextPage: () => void;
  setPageSize?: (size: number) => void;
}

function Paging(props: PagingProps) {
  const {
    pageOffset = 0,
    currentPageItemCount = 0,
    maxItemsPerPage = 0,
    pageSize,
    pageSizeOptions,
    disabled = false,
    getPrevPage,
    getNextPage,
    setPageSize,
  } = props;
  return (
    <div className="Paging">
      <ButtonComponent
        compact
        secondary
        disabled={disabled || pageOffset === 0}
        onClick={getPrevPage}
      >
        Prev
      </ButtonComponent>
      {pageSizeOptions ? (
        <select
          aria-label="rows per page"
          value={pageSize}
          onChange={(event) => {
            setPageSize(Number(event.target.value));
          }}
        >
          {pageSizeOptions.map((number) => (
            <option key={number} value={number}>
              {number} rows
            </option>
          ))}
        </select>
      ) : null}
      <ButtonComponent
        compact
        secondary
        disabled={disabled || currentPageItemCount < maxItemsPerPage}
        onClick={getNextPage}
      >
        Next
      </ButtonComponent>
    </div>
  );
}

export default Paging;
