import React from "react";
import TimeUtils from "../../utils/TimeUtils";
import Modal from "../generic/Modal";
import { useFetchLedgerEntries } from "../../hooks/reports/useFetchLedgerEntries";

function LedgerReport(props, ref) {
  const { id, currencyCode, timeZone, onClose } = props;
  const { data, isFetching } = useFetchLedgerEntries({ id }, { enabled: !!id });

  const formatCurrency = (value) => {
    const floatValue = parseFloat(value);
    if (isNaN(floatValue)) {
      return "-";
    }
    const locale = navigator.languages[0];
    return floatValue.toLocaleString(locale, {
      style: "currency",
      currency: currencyCode || "USD",
    });
  };

  return (
    <Modal onClose={onClose} ref={ref} isLoading={isFetching}>
      <Modal.Header>View Ledger</Modal.Header>
      <Modal.Content>
        <table>
          <thead>
            <tr>
              <th>Date Time</th>
              <th>Amount</th>
              <th>Tender</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody className="LedgerReport__entries">
            {data?.map((row) => (
              <tr key={row.entryId}>
                <td className="LedgerReport__column LedgerReport__column--no-wrap">
                  {TimeUtils.convertDateToHumanReadable(
                    row.createdAt,
                    timeZone
                  )}
                </td>
                <td className="LedgerReport__column LedgerReport__column--no-wrap">
                  {formatCurrency(row.amount)}
                </td>
                <td className="LedgerReport__column LedgerReport__column--no-wrap">
                  {row.tender}
                </td>
                <td className="LedgerReport__column LedgerReport__notes">
                  {row.notes}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal.Content>
    </Modal>
  );
}

export default React.forwardRef(LedgerReport);
