import { useQuery, UseQueryOptions } from 'react-query';
import { reportingGraphQlFetcher } from '../services/fetchers';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

/** Returns data for the currently authorized BinderPOS Customer. This is determined by the firebase token used to authorize the request. */
export type BinderCustomer = {
  __typename?: 'BinderCustomer';
  /** The variants a customer has set for TCG products */
  CustomerVariants?: Maybe<Array<Maybe<CustomerVariant>>>;
  /** Wether or not the customer allows Non-English variants in their TCG products */
  allowNonEnglish?: Maybe<Scalars['Boolean']>;
  /** Wether or no the customer is a 'Beta Tester' */
  betaTester?: Maybe<Scalars['Boolean']>;
  /** The amount calculated buylist prices are rounded to. IE 0.25 would round all prices to 0.25 increments. */
  buylistRoundToNearest?: Maybe<Scalars['Float']>;
  /** Default price floor for common cards */
  commonLowPrice?: Maybe<Scalars['Float']>;
  /** The letter code of the customer's currency */
  currency?: Maybe<Scalars['String']>;
  /** The unique identifier for a BinderPOS Customer */
  customerId?: Maybe<Scalars['String']>;
  /** Main email address associated with the BinderPOS account. */
  email?: Maybe<Scalars['String']>;
  /** id of customer in BinderCustomer table */
  id?: Maybe<Scalars['String']>;
  /** Default price floor for mythic cards */
  mythicLowPrice?: Maybe<Scalars['Float']>;
  /** The name of the BinderPOS account */
  name?: Maybe<Scalars['String']>;
  /** Wether or not default price rounding is disabled */
  noRounding?: Maybe<Scalars['Boolean']>;
  /** The wording used for Non-English variants if enabled */
  nonEnglishWording?: Maybe<Scalars['String']>;
  /** Amount the POS prices are marked up to from the prices in Shopify */
  posPriceMarkup?: Maybe<Scalars['Float']>;
  /** The top level markup of the price used for TCG price updates */
  priceMarkup?: Maybe<Scalars['Float']>;
  /** The top level multiplier of the price used for TCG price updates */
  priceMultiplier?: Maybe<Scalars['Float']>;
  /** Default price floor for rare cards */
  rareLowPrice?: Maybe<Scalars['Float']>;
  /** The amount price updates are rounded to. IE 0.25 would round all prices to 0.25 increments. */
  roundToNearest?: Maybe<Scalars['Float']>;
  /** The shopify domain associated with the BinderPOS account. */
  siteUrl?: Maybe<Scalars['String']>;
  /** The type of pricing to use for TCG price updates */
  tcgPlayerPriceType?: Maybe<Scalars['String']>;
  /** The timezone associated with the BinderPOS account. These are in the 'Etc/GMT' style format */
  timezone?: Maybe<Scalars['String']>;
  /** Default price floor for uncommon cards */
  uncommonLowPrice?: Maybe<Scalars['Float']>;
  /** Wether or not price updates are enabled */
  updatePricing?: Maybe<Scalars['Boolean']>;
  /** Wether or not to use TCGPlayer pricing */
  useTCGPlayerPricing?: Maybe<Scalars['Boolean']>;
};

export type BuylistDetailLine = {
  __typename?: 'BuylistDetailLine';
  /** Id of the buylist */
  buylistId?: Maybe<Scalars['Int']>;
  /** List of items on the buylist */
  buylistItems?: Maybe<Array<Maybe<BuylistItemLine>>>;
  /** ISO string of when buylist was approved */
  dateApproved?: Maybe<Scalars['String']>;
  /** ISO string of when buylist was completed */
  dateCompleted?: Maybe<Scalars['String']>;
  /** ISO string of when buylist was submitted */
  dateSubmitted?: Maybe<Scalars['String']>;
  /** Email address of the customer attached to the buylist */
  email?: Maybe<Scalars['String']>;
  /** Fist name of the customer attached to the buylist */
  firstName?: Maybe<Scalars['String']>;
  /** Last name of the customer attached to the buylist */
  lastName?: Maybe<Scalars['String']>;
  /** Payment type (Cash, StoreCredit) */
  paymentType?: Maybe<Scalars['String']>;
  /** Status of the buylist (Submitted, Approved, Competed, Declined) */
  status?: Maybe<Scalars['String']>;
  /** Total buyprice of the selected pament type */
  totalBuyPrice?: Maybe<Scalars['Float']>;
  /** Total number of items in the buylist */
  totalItems?: Maybe<Scalars['Int']>;
};

/** List of items on the buylist */
export type BuylistItemLine = {
  __typename?: 'BuylistItemLine';
  /** Total price of the cards form the buylist (price*quantity) */
  buyPrice?: Maybe<Scalars['Float']>;
  /** Name of the card */
  cardName?: Maybe<Scalars['String']>;
  /** Name of the game the crad is from */
  game?: Maybe<Scalars['String']>;
  /** Total quantity of that paticular card */
  quantity?: Maybe<Scalars['Int']>;
  /** Name of the set the card is from */
  setName?: Maybe<Scalars['String']>;
  /** Printing type of the card the game is from */
  type?: Maybe<Scalars['String']>;
};

export type Cart = {
  __typename?: 'Cart';
  abandoned?: Maybe<Scalars['Boolean']>;
  binderCustomerId?: Maybe<Scalars['String']>;
  binderCustomerTillId?: Maybe<Scalars['Int']>;
  cartName?: Maybe<Scalars['String']>;
  cartNotes?: Maybe<Scalars['String']>;
  cartType?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['Int']>;
  dateCreated?: Maybe<Scalars['String']>;
  dateSubmitted?: Maybe<Scalars['String']>;
  discountAmount?: Maybe<Scalars['Float']>;
  discountType?: Maybe<Scalars['String']>;
  discountValue?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  lastUpdated?: Maybe<Scalars['String']>;
  orderNumber?: Maybe<Scalars['String']>;
  savedCart?: Maybe<Scalars['Boolean']>;
  savedName?: Maybe<Scalars['String']>;
  shopifyCustomerId?: Maybe<Scalars['Int']>;
  shopifyOrderId?: Maybe<Scalars['String']>;
  subTotal?: Maybe<Scalars['Float']>;
  submittedBy?: Maybe<Scalars['Int']>;
  taxIncluded?: Maybe<Scalars['Boolean']>;
  taxRate?: Maybe<Scalars['Float']>;
  totalPrice?: Maybe<Scalars['Float']>;
  totalTax?: Maybe<Scalars['Float']>;
};

export type CategorySale = {
  __typename?: 'CategorySale';
  /** The total amount of buys made on this category */
  buys?: Maybe<Scalars['Float']>;
  /** The product's type as set in Shopify */
  productType?: Maybe<Scalars['String']>;
  /** The total amount of sales made on this category */
  sales?: Maybe<Scalars['Float']>;
};

export type CategoryValue = {
  __typename?: 'CategoryValue';
  /** The number of products the customer has of this product type */
  count?: Maybe<Scalars['Int']>;
  /** The product type set in Shopify */
  productType?: Maybe<Scalars['String']>;
  /** The total cost of inventory for this product type */
  totalCost?: Maybe<Scalars['Float']>;
  /** The total retail value of inventory for this product type */
  totalValue?: Maybe<Scalars['Float']>;
};

export type CustomerVariant = {
  __typename?: 'CustomerVariant';
  BinderCustomer?: Maybe<BinderCustomer>;
  multiplier?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
};

export type FloatEntry = {
  __typename?: 'FloatEntry';
  /** The date time of when the till was closed */
  closedDate?: Maybe<Scalars['String']>;
  /** Unique id for this float entry */
  floatId?: Maybe<Scalars['Int']>;
  /** The name of the till */
  name?: Maybe<Scalars['String']>;
  /** The date time of when the till was opened */
  openedDate?: Maybe<Scalars['String']>;
  /** Current status of the till can be closed or open */
  status?: Maybe<Scalars['String']>;
  /** List of tenders and their opening/closed amounts */
  tenders?: Maybe<Array<Maybe<TenderEntry>>>;
  /** The internal unique id of the till */
  tillId?: Maybe<Scalars['Int']>;
};

export type LedgerEntry = {
  __typename?: 'LedgerEntry';
  /** Amount the float was changed */
  amount?: Maybe<Scalars['Float']>;
  /** The timestamp of when the entry was created */
  createdAt?: Maybe<Scalars['String']>;
  /** Unique id for this ledger entry */
  entryId?: Maybe<Scalars['Int']>;
  /** Notes attached to entry */
  notes?: Maybe<Scalars['String']>;
  /** Id of Float these entries are tied to */
  posFloatId?: Maybe<Scalars['Int']>;
  /** The name of the tender that was adjusted */
  tender?: Maybe<Scalars['String']>;
};

export type Metric = {
  __typename?: 'Metric';
  /** Generate a report that displays Buylist Details */
  BuylistDetails?: Maybe<Array<Maybe<BuylistDetailLine>>>;
  CategorySales?: Maybe<Array<Maybe<CategorySale>>>;
  /** Generate a report that displays completed Buylist Details */
  CompletedBuylistDetails?: Maybe<Array<Maybe<BuylistDetailLine>>>;
  /** All of the info for the current authorized user */
  CustomerInfo?: Maybe<BinderCustomer>;
  /** The total value of all inventory in cost, and retail price terms. This is aggregated by product type. WARNING: This query is somewhat expensive and can take a few seconds to return. May be a good idea to have it triggered by a single view or a button instead of a dashboard like scenario. */
  RetailValue?: Maybe<Array<Maybe<CategoryValue>>>;
  /** Total sales over a range of time aggregated to a single result. 'fromTime' and 'toTime' , must be supplied as ISO datetime strings. '(new Date()).toISOString()' will return the proper format. */
  Sales?: Maybe<SalesTotals>;
  SalesByOrder?: Maybe<Array<Maybe<SalesByOrderLine>>>;
  SalesByOrderBeta?: Maybe<Array<Maybe<SalesByTenderLine>>>;
  /** Returns sales by a range aggregated over a specific period. 'fromTime' and 'toTime' , must be supplied as ISO datetime strings. '(new Date()).toISOString()' will return the proper format. */
  SalesByRange?: Maybe<Array<Maybe<RangedData>>>;
  SalesByTender?: Maybe<Array<Maybe<SalesByTenderLine>>>;
  /** The total store credit */
  StoreCredit?: Maybe<StoreCredit>;
  StoreCreditByRange?: Maybe<Array<Maybe<StoreCreditCustomerLine>>>;
  StoreCreditByRangeForCustomer?: Maybe<Array<Maybe<StoreCreditCustomerLine>>>;
  /** The total store credit awarded/redeemed during a certain time period. */
  StoreCreditTransactionSummary?: Maybe<StoreCreditTransactionSummaryLine>;
  /** Generate a report that displays WPN metrics */
  WPNReport?: Maybe<Array<Maybe<WpnReportLine>>>;
};


export type MetricBuylistDetailsArgs = {
  fromTime: Scalars['String'];
  toTime: Scalars['String'];
};


export type MetricCategorySalesArgs = {
  fromTime: Scalars['String'];
  toTime: Scalars['String'];
};


export type MetricCompletedBuylistDetailsArgs = {
  fromTime: Scalars['String'];
  toTime: Scalars['String'];
};


export type MetricSalesArgs = {
  fromTime: Scalars['String'];
  toTime: Scalars['String'];
};


export type MetricSalesByOrderArgs = {
  fromTime: Scalars['String'];
  toTime: Scalars['String'];
};


export type MetricSalesByOrderBetaArgs = {
  fromTime: Scalars['String'];
  toTime: Scalars['String'];
};


export type MetricSalesByRangeArgs = {
  binSize: Scalars['Int'];
  fromTime: Scalars['String'];
  timeZone?: InputMaybe<Scalars['String']>;
  toTime: Scalars['String'];
};


export type MetricSalesByTenderArgs = {
  fromTime: Scalars['String'];
  toTime: Scalars['String'];
};


export type MetricStoreCreditByRangeArgs = {
  fromTime: Scalars['String'];
  toTime: Scalars['String'];
};


export type MetricStoreCreditByRangeForCustomerArgs = {
  customerId: Scalars['Int'];
  fromTime: Scalars['String'];
  toTime: Scalars['String'];
};


export type MetricStoreCreditTransactionSummaryArgs = {
  fromTime: Scalars['String'];
  toTime: Scalars['String'];
};


export type MetricWpnReportArgs = {
  fromTime: Scalars['String'];
  productTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  toTime: Scalars['String'];
};

export type PosCustomer = {
  __typename?: 'POSCustomer';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  shopifyId?: Maybe<Scalars['Int']>;
  storeCredit?: Maybe<Scalars['Float']>;
};

export type PosReport = {
  __typename?: 'POSReport';
  FloatEntries?: Maybe<Array<Maybe<FloatEntry>>>;
  LedgerEntries?: Maybe<Array<Maybe<LedgerEntry>>>;
};


export type PosReportFloatEntriesArgs = {
  fromTime: Scalars['String'];
  tillId?: InputMaybe<Scalars['Int']>;
  toTime: Scalars['String'];
};


export type PosReportLedgerEntriesArgs = {
  id: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  /** Returns specific info for a cart by id */
  Cart?: Maybe<Cart>;
  /** All of the info for the current authorized user */
  CustomerInfo?: Maybe<BinderCustomer>;
  /** Variants set for TCGs */
  CustomerVariants?: Maybe<Array<Maybe<CustomerVariant>>>;
  /** Returns list of customers for current user */
  Customers?: Maybe<Array<Maybe<PosCustomer>>>;
  /** Various queries for reporting financials */
  Metrics?: Maybe<Metric>;
  /** Various reports for POS/Tills currently contains Floats which returns the opening and closing amounts for each tender by day */
  POSReports?: Maybe<PosReport>;
  /** WIP Individual Sales lines */
  Sales?: Maybe<Array<Maybe<SalesLine>>>;
};


export type QueryCartArgs = {
  id: Scalars['ID'];
};


export type QueryCustomersArgs = {
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  like?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type RangedData = {
  __typename?: 'RangedData';
  /** Cash that was taken in from sales */
  Cash_In?: Maybe<Scalars['Float']>;
  /** Cash that was handed out as part of POS sales */
  Cash_Out?: Maybe<Scalars['Float']>;
  /** Credit Card payments that were taken in from sales */
  Credit_In?: Maybe<Scalars['Float']>;
  /** Credit Card payments that were handed out as part of POS sales */
  Credit_Out?: Maybe<Scalars['Float']>;
  /** EFTPOS that was taken in from sales */
  EFTPOS_In?: Maybe<Scalars['Float']>;
  /** EFTPOS that was handed out as part of POS sales */
  EFTPOS_Out?: Maybe<Scalars['Float']>;
  /** Store credit that was taken in from sales */
  Store_Credit_In?: Maybe<Scalars['Float']>;
  /** Store Credit that was handed out as part of POS sales */
  Store_Credit_Out?: Maybe<Scalars['Float']>;
  /** The total of payments in */
  Total_In?: Maybe<Scalars['Float']>;
  /** The total of payments out */
  Total_Out?: Maybe<Scalars['Float']>;
  /** Total tax collected by POS */
  Total_Tax?: Maybe<Scalars['Float']>;
  /** The start time of the period based on the bin size */
  time?: Maybe<Scalars['String']>;
  /** The total POS sales in the customer's currency */
  totalSales?: Maybe<Scalars['Float']>;
};

export type SalePeriod = {
  __typename?: 'SalePeriod';
  Cash_In?: Maybe<Scalars['Float']>;
  time?: Maybe<Scalars['String']>;
  totalSales?: Maybe<Scalars['Float']>;
};

/** Tender name and amount attached to order */
export type SaleTender = {
  __typename?: 'SaleTender';
  /** Amount in local currency */
  amount?: Maybe<Scalars['Float']>;
  dateCreated?: Maybe<Scalars['String']>;
  dateProcessed?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  /** Name of the tender */
  type?: Maybe<Scalars['String']>;
};

export type SalesByOrderLine = {
  __typename?: 'SalesByOrderLine';
  /** ISO datetime of order close */
  closedAt?: Maybe<Scalars['String']>;
  /** The discount on the order WARNING: This data still must be backfilled for web orders */
  discount?: Maybe<Scalars['Float']>;
  /** Number of lines on the order */
  lineCount?: Maybe<Scalars['Int']>;
  /** ID of the transaction this will be the Shopify ID for web orders and Cart ID for BinderPOS orders */
  orderId?: Maybe<Scalars['String']>;
  /** Shipping collected on the order WARNING: This data still must be backfilled for web orders */
  shipping?: Maybe<Scalars['Float']>;
  /** Source of the order ie BinderPOD, tcgplayer, web etc. */
  source?: Maybe<Scalars['String']>;
  /** Subtotal of the order. */
  subTotal?: Maybe<Scalars['Float']>;
  /** Taxes collected on the order */
  tax?: Maybe<Scalars['Float']>;
  /** The list of tenders for this order (this is dynamic because it includes web orders) */
  tenders?: Maybe<Array<Maybe<SaleTender>>>;
  /** Order total including all taxes, shipping discounts etc. */
  total?: Maybe<Scalars['Float']>;
};

export type SalesByTenderLine = {
  __typename?: 'SalesByTenderLine';
  /** ISO datetime of order creation */
  createdAt?: Maybe<Scalars['String']>;
  /** ID of the transaction this will be the Shopify ID for web orders and Cart ID for BinderPOS orders */
  orderId?: Maybe<Scalars['String']>;
  /** Source of the order ie BinderPOD, tcgplayer, web etc. */
  source?: Maybe<Scalars['String']>;
  /** The list of tenders for this order (this is dynamic because it includes web orders) */
  tenders?: Maybe<Array<Maybe<SaleTender>>>;
  /** Order total including all taxes, shipping discounts etc. */
  total?: Maybe<Scalars['Float']>;
  /** Total price */
  totalPrice?: Maybe<Scalars['Float']>;
  /** Total tax collected */
  totalTax?: Maybe<Scalars['Float']>;
};

export type SalesData = {
  __typename?: 'SalesData';
  SalesByRange?: Maybe<Array<Maybe<RangedData>>>;
};


export type SalesDataSalesByRangeArgs = {
  binSize: Scalars['Int'];
  fromTime: Scalars['String'];
  toTime: Scalars['String'];
};

/** Very simple dump of submitted carts */
export type SalesLine = {
  __typename?: 'SalesLine';
  /** date the cart was submitted */
  dateSubmitted?: Maybe<Scalars['String']>;
  /** number of items in cart */
  itemCount?: Maybe<Scalars['Int']>;
  /** Total price of cart */
  totalPrice?: Maybe<Scalars['Float']>;
  /** Total tax collected */
  totalTax?: Maybe<Scalars['Float']>;
};

export type SalesTotals = {
  __typename?: 'SalesTotals';
  Cash_In?: Maybe<Scalars['Float']>;
  Cash_Out?: Maybe<Scalars['Float']>;
  Credit_In?: Maybe<Scalars['Float']>;
  Credit_Out?: Maybe<Scalars['Float']>;
  EFTPOS_In?: Maybe<Scalars['Float']>;
  EFTPOS_Out?: Maybe<Scalars['Float']>;
  Store_Credit_In?: Maybe<Scalars['Float']>;
  Store_Credit_Out?: Maybe<Scalars['Float']>;
  Total_In?: Maybe<Scalars['Float']>;
  totalSales?: Maybe<Scalars['Float']>;
};

export type StoreCredit = {
  __typename?: 'StoreCredit';
  total?: Maybe<Scalars['Float']>;
};

export type StoreCreditCustomerLine = {
  __typename?: 'StoreCreditCustomerLine';
  actionNotes?: Maybe<Scalars['String']>;
  actionedBy?: Maybe<Scalars['String']>;
  amountAfterChange?: Maybe<Scalars['Float']>;
  amountBeforeChange?: Maybe<Scalars['Float']>;
  amountChanged?: Maybe<Scalars['Float']>;
  cartId?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  invoiceNumber?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['String']>;
  privateNotes?: Maybe<Scalars['String']>;
  publicNotes?: Maybe<Scalars['String']>;
  shopifyCustomerBuylistId?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['String']>;
};

export type StoreCreditTransactionSummaryLine = {
  __typename?: 'StoreCreditTransactionSummaryLine';
  /** Total credit awarded */
  awarded?: Maybe<Scalars['Float']>;
  /** Total credit redeemed */
  redeemed?: Maybe<Scalars['Float']>;
  /** Total credit awarded minus redeemed */
  total?: Maybe<Scalars['Float']>;
};

export type TenderEntry = {
  __typename?: 'TenderEntry';
  /** Closing amount */
  closingAmount?: Maybe<Scalars['Float']>;
  /** Current amount */
  currentAmount?: Maybe<Scalars['Float']>;
  /** Name of tender used in this report */
  name?: Maybe<Scalars['String']>;
  /** Opening amount */
  openingAmount?: Maybe<Scalars['Float']>;
};

export type WpnReportLine = {
  __typename?: 'WPNReportLine';
  /** The barcode of the item, if barcode is blank it will reference sku, if both are blank this returns null */
  UPC?: Maybe<Scalars['String']>;
  /** This is the title of the item as place in Shopify */
  description?: Maybe<Scalars['String']>;
  /** quantity sold */
  quantity?: Maybe<Scalars['Int']>;
  /** Total price for this line item (quantity sold times unit price */
  totalPrice?: Maybe<Scalars['Float']>;
  /** ID of the transaction this will be the Shopify ID for web orders and Cart ID for BinderPOS orders */
  transactionId?: Maybe<Scalars['String']>;
  /** ISO string representing time of the transaction */
  transactionTime?: Maybe<Scalars['String']>;
  /** Unit price this item was sold at */
  unitPrice?: Maybe<Scalars['Float']>;
};

export type GetBuylistReportQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;


export type GetBuylistReportQuery = { __typename?: 'Query', Metrics?: { __typename?: 'Metric', BuylistDetails?: Array<{ __typename?: 'BuylistDetailLine', buylistId?: number | null, dateSubmitted?: string | null, dateCompleted?: string | null, status?: string | null, paymentType?: string | null, totalBuyPrice?: number | null, totalItems?: number | null, firstName?: string | null, lastName?: string | null, email?: string | null } | null> | null } | null };

export type GetCompletedBuylistReportQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;


export type GetCompletedBuylistReportQuery = { __typename?: 'Query', Metrics?: { __typename?: 'Metric', CompletedBuylistDetails?: Array<{ __typename?: 'BuylistDetailLine', buylistId?: number | null, dateSubmitted?: string | null, dateCompleted?: string | null, status?: string | null, paymentType?: string | null, totalBuyPrice?: number | null, totalItems?: number | null, firstName?: string | null, lastName?: string | null, email?: string | null } | null> | null } | null };

export type DailyPosSalesDataQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  binSize?: InputMaybe<Scalars['Int']>;
  timeZoneOffset: Scalars['String'];
}>;


export type DailyPosSalesDataQuery = { __typename?: 'Query', Metrics?: { __typename?: 'Metric', SalesByRange?: Array<{ __typename?: 'RangedData', time?: string | null, totalSales?: number | null, Total_In?: number | null, Store_Credit_In?: number | null, Store_Credit_Out?: number | null, Cash_In?: number | null, Cash_Out?: number | null, Credit_In?: number | null, Credit_Out?: number | null, EFTPOS_In?: number | null, EFTPOS_Out?: number | null } | null> | null, Sales?: { __typename?: 'SalesTotals', Total_In?: number | null, Store_Credit_In?: number | null, Store_Credit_Out?: number | null, Cash_In?: number | null, Cash_Out?: number | null, Credit_In?: number | null, Credit_Out?: number | null, EFTPOS_In?: number | null, EFTPOS_Out?: number | null } | null } | null };

export type EndOfDayReportsQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;


export type EndOfDayReportsQuery = { __typename?: 'Query', POSReports?: { __typename?: 'POSReport', FloatEntries?: Array<{ __typename?: 'FloatEntry', floatId?: number | null, name?: string | null, openedDate?: string | null, closedDate?: string | null, status?: string | null, tenders?: Array<{ __typename?: 'TenderEntry', name?: string | null, openingAmount?: number | null, closingAmount?: number | null, currentAmount?: number | null } | null> | null } | null> | null } | null };

export type GetLedgerEntriesQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetLedgerEntriesQuery = { __typename?: 'Query', POSReports?: { __typename?: 'POSReport', LedgerEntries?: Array<{ __typename?: 'LedgerEntry', entryId?: number | null, createdAt?: string | null, amount?: number | null, notes?: string | null, tender?: string | null } | null> | null } | null };

export type GetSalesByCategoryQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;


export type GetSalesByCategoryQuery = { __typename?: 'Query', Metrics?: { __typename?: 'Metric', CategorySales?: Array<{ __typename?: 'CategorySale', productType?: string | null, buys?: number | null, sales?: number | null } | null> | null } | null };

export type GetSalesByOrderQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;


export type GetSalesByOrderQuery = { __typename?: 'Query', Metrics?: { __typename?: 'Metric', SalesByOrder?: Array<{ __typename?: 'SalesByOrderLine', orderId?: string | null, subTotal?: number | null, shipping?: number | null, discount?: number | null, tax?: number | null, total?: number | null, lineCount?: number | null, closedAt?: string | null, source?: string | null, tenders?: Array<{ __typename?: 'SaleTender', type?: string | null, amount?: number | null } | null> | null } | null> | null } | null };

export type GetSalesByOrderBetaReportQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;


export type GetSalesByOrderBetaReportQuery = { __typename?: 'Query', Metrics?: { __typename?: 'Metric', SalesByOrderBeta?: Array<{ __typename?: 'SalesByTenderLine', createdAt?: string | null, orderId?: string | null, source?: string | null, totalTax?: number | null, total?: number | null, tenders?: Array<{ __typename?: 'SaleTender', type?: string | null, amount?: number | null, kind?: string | null, status?: string | null, id?: string | null } | null> | null } | null> | null } | null };

export type GetSalesByTenderBetaReportQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;


export type GetSalesByTenderBetaReportQuery = { __typename?: 'Query', Metrics?: { __typename?: 'Metric', SalesByTender?: Array<{ __typename?: 'SalesByTenderLine', orderId?: string | null, total?: number | null, createdAt?: string | null, source?: string | null, tenders?: Array<{ __typename?: 'SaleTender', type?: string | null, amount?: number | null, kind?: string | null, dateCreated?: string | null, dateProcessed?: string | null, status?: string | null, id?: string | null } | null> | null } | null> | null } | null };

export type GetStockInventoryValueQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStockInventoryValueQuery = { __typename?: 'Query', Metrics?: { __typename?: 'Metric', RetailValue?: Array<{ __typename?: 'CategoryValue', productType?: string | null, count?: number | null, totalCost?: number | null, totalValue?: number | null } | null> | null } | null };

export type GetStoreCreditOutstandingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStoreCreditOutstandingQuery = { __typename?: 'Query', Customers?: Array<{ __typename?: 'POSCustomer', firstName?: string | null, lastName?: string | null, phone?: string | null, email?: string | null, storeCredit?: number | null } | null> | null };

export type GetStoreCreditTransactionSummaryQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;


export type GetStoreCreditTransactionSummaryQuery = { __typename?: 'Query', Metrics?: { __typename?: 'Metric', StoreCreditByRange?: Array<{ __typename?: 'StoreCreditCustomerLine', updatedDate?: string | null, actionedBy?: string | null, amountBeforeChange?: number | null, amountAfterChange?: number | null, amountChanged?: number | null, invoiceNumber?: number | null, cartId?: number | null, shopifyCustomerBuylistId?: number | null, firstName?: string | null, lastName?: string | null, email?: string | null, actionNotes?: string | null, publicNotes?: string | null, privateNotes?: string | null } | null> | null } | null };

export type GetStoreCreditTransactionSummaryByCustomerQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  customerId: Scalars['Int'];
}>;


export type GetStoreCreditTransactionSummaryByCustomerQuery = { __typename?: 'Query', Metrics?: { __typename?: 'Metric', StoreCreditByRange?: Array<{ __typename?: 'StoreCreditCustomerLine', updatedDate?: string | null, actionedBy?: string | null, amountBeforeChange?: number | null, amountAfterChange?: number | null, amountChanged?: number | null, invoiceNumber?: number | null, cartId?: number | null, shopifyCustomerBuylistId?: number | null, actionNotes?: string | null, publicNotes?: string | null, privateNotes?: string | null } | null> | null } | null };

export type GetWpnReportDataQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;


export type GetWpnReportDataQuery = { __typename?: 'Query', Metrics?: { __typename?: 'Metric', WPNReport?: Array<{ __typename?: 'WPNReportLine', transactionTime?: string | null, transactionId?: string | null, UPC?: string | null, unitPrice?: number | null, totalPrice?: number | null, quantity?: number | null, description?: string | null } | null> | null } | null };


export const GetBuylistReportDocument = `
    query GetBuylistReport($startDate: String!, $endDate: String!) {
  Metrics {
    BuylistDetails(fromTime: $startDate, toTime: $endDate) {
      buylistId
      dateSubmitted
      dateCompleted
      status
      paymentType
      totalBuyPrice
      totalItems
      firstName
      lastName
      email
    }
  }
}
    `;
export const useGetBuylistReportQuery = <
      TData = GetBuylistReportQuery,
      TError = unknown
    >(
      variables: GetBuylistReportQueryVariables,
      options?: UseQueryOptions<GetBuylistReportQuery, TError, TData>
    ) =>
    useQuery<GetBuylistReportQuery, TError, TData>(
      ['GetBuylistReport', variables],
      reportingGraphQlFetcher<GetBuylistReportQuery, GetBuylistReportQueryVariables>(GetBuylistReportDocument, variables),
      options
    );
export const GetCompletedBuylistReportDocument = `
    query GetCompletedBuylistReport($startDate: String!, $endDate: String!) {
  Metrics {
    CompletedBuylistDetails(fromTime: $startDate, toTime: $endDate) {
      buylistId
      dateSubmitted
      dateCompleted
      status
      paymentType
      totalBuyPrice
      totalItems
      firstName
      lastName
      email
    }
  }
}
    `;
export const useGetCompletedBuylistReportQuery = <
      TData = GetCompletedBuylistReportQuery,
      TError = unknown
    >(
      variables: GetCompletedBuylistReportQueryVariables,
      options?: UseQueryOptions<GetCompletedBuylistReportQuery, TError, TData>
    ) =>
    useQuery<GetCompletedBuylistReportQuery, TError, TData>(
      ['GetCompletedBuylistReport', variables],
      reportingGraphQlFetcher<GetCompletedBuylistReportQuery, GetCompletedBuylistReportQueryVariables>(GetCompletedBuylistReportDocument, variables),
      options
    );
export const DailyPosSalesDataDocument = `
    query DailyPOSSalesData($startDate: String!, $endDate: String!, $binSize: Int = 3600, $timeZoneOffset: String!) {
  Metrics {
    SalesByRange(
      fromTime: $startDate
      toTime: $endDate
      binSize: $binSize
      timeZone: $timeZoneOffset
    ) {
      time
      totalSales
      Total_In
      Store_Credit_In
      Store_Credit_Out
      Cash_In
      Cash_Out
      Credit_In
      Credit_Out
      EFTPOS_In
      EFTPOS_Out
    }
    Sales(fromTime: $startDate, toTime: $endDate) {
      Total_In
      Store_Credit_In
      Store_Credit_Out
      Cash_In
      Cash_Out
      Credit_In
      Credit_Out
      EFTPOS_In
      EFTPOS_Out
    }
  }
}
    `;
export const useDailyPosSalesDataQuery = <
      TData = DailyPosSalesDataQuery,
      TError = unknown
    >(
      variables: DailyPosSalesDataQueryVariables,
      options?: UseQueryOptions<DailyPosSalesDataQuery, TError, TData>
    ) =>
    useQuery<DailyPosSalesDataQuery, TError, TData>(
      ['DailyPOSSalesData', variables],
      reportingGraphQlFetcher<DailyPosSalesDataQuery, DailyPosSalesDataQueryVariables>(DailyPosSalesDataDocument, variables),
      options
    );
export const EndOfDayReportsDocument = `
    query EndOfDayReports($startDate: String!, $endDate: String!) {
  POSReports {
    FloatEntries(fromTime: $startDate, toTime: $endDate) {
      floatId
      name
      openedDate
      closedDate
      status
      tenders {
        name
        openingAmount
        closingAmount
        currentAmount
      }
    }
  }
}
    `;
export const useEndOfDayReportsQuery = <
      TData = EndOfDayReportsQuery,
      TError = unknown
    >(
      variables: EndOfDayReportsQueryVariables,
      options?: UseQueryOptions<EndOfDayReportsQuery, TError, TData>
    ) =>
    useQuery<EndOfDayReportsQuery, TError, TData>(
      ['EndOfDayReports', variables],
      reportingGraphQlFetcher<EndOfDayReportsQuery, EndOfDayReportsQueryVariables>(EndOfDayReportsDocument, variables),
      options
    );
export const GetLedgerEntriesDocument = `
    query GetLedgerEntries($id: Int!) {
  POSReports {
    LedgerEntries(id: $id) {
      entryId
      createdAt
      amount
      notes
      tender
    }
  }
}
    `;
export const useGetLedgerEntriesQuery = <
      TData = GetLedgerEntriesQuery,
      TError = unknown
    >(
      variables: GetLedgerEntriesQueryVariables,
      options?: UseQueryOptions<GetLedgerEntriesQuery, TError, TData>
    ) =>
    useQuery<GetLedgerEntriesQuery, TError, TData>(
      ['GetLedgerEntries', variables],
      reportingGraphQlFetcher<GetLedgerEntriesQuery, GetLedgerEntriesQueryVariables>(GetLedgerEntriesDocument, variables),
      options
    );
export const GetSalesByCategoryDocument = `
    query GetSalesByCategory($startDate: String!, $endDate: String!) {
  Metrics {
    CategorySales(fromTime: $startDate, toTime: $endDate) {
      productType
      buys
      sales
    }
  }
}
    `;
export const useGetSalesByCategoryQuery = <
      TData = GetSalesByCategoryQuery,
      TError = unknown
    >(
      variables: GetSalesByCategoryQueryVariables,
      options?: UseQueryOptions<GetSalesByCategoryQuery, TError, TData>
    ) =>
    useQuery<GetSalesByCategoryQuery, TError, TData>(
      ['GetSalesByCategory', variables],
      reportingGraphQlFetcher<GetSalesByCategoryQuery, GetSalesByCategoryQueryVariables>(GetSalesByCategoryDocument, variables),
      options
    );
export const GetSalesByOrderDocument = `
    query GetSalesByOrder($startDate: String!, $endDate: String!) {
  Metrics {
    SalesByOrder(fromTime: $startDate, toTime: $endDate) {
      orderId
      subTotal
      shipping
      discount
      tax
      total
      lineCount
      closedAt
      source
      tenders {
        type
        amount
      }
    }
  }
}
    `;
export const useGetSalesByOrderQuery = <
      TData = GetSalesByOrderQuery,
      TError = unknown
    >(
      variables: GetSalesByOrderQueryVariables,
      options?: UseQueryOptions<GetSalesByOrderQuery, TError, TData>
    ) =>
    useQuery<GetSalesByOrderQuery, TError, TData>(
      ['GetSalesByOrder', variables],
      reportingGraphQlFetcher<GetSalesByOrderQuery, GetSalesByOrderQueryVariables>(GetSalesByOrderDocument, variables),
      options
    );
export const GetSalesByOrderBetaReportDocument = `
    query GetSalesByOrderBetaReport($startDate: String!, $endDate: String!) {
  Metrics {
    SalesByOrderBeta(fromTime: $startDate, toTime: $endDate) {
      createdAt
      orderId
      tenders {
        type
        amount
        kind
        status
        id
      }
      source
      totalTax
      total
    }
  }
}
    `;
export const useGetSalesByOrderBetaReportQuery = <
      TData = GetSalesByOrderBetaReportQuery,
      TError = unknown
    >(
      variables: GetSalesByOrderBetaReportQueryVariables,
      options?: UseQueryOptions<GetSalesByOrderBetaReportQuery, TError, TData>
    ) =>
    useQuery<GetSalesByOrderBetaReportQuery, TError, TData>(
      ['GetSalesByOrderBetaReport', variables],
      reportingGraphQlFetcher<GetSalesByOrderBetaReportQuery, GetSalesByOrderBetaReportQueryVariables>(GetSalesByOrderBetaReportDocument, variables),
      options
    );
export const GetSalesByTenderBetaReportDocument = `
    query GetSalesByTenderBetaReport($startDate: String!, $endDate: String!) {
  Metrics {
    SalesByTender(fromTime: $startDate, toTime: $endDate) {
      orderId
      total
      createdAt
      source
      tenders {
        type
        amount
        kind
        dateCreated
        dateProcessed
        status
        id
      }
    }
  }
}
    `;
export const useGetSalesByTenderBetaReportQuery = <
      TData = GetSalesByTenderBetaReportQuery,
      TError = unknown
    >(
      variables: GetSalesByTenderBetaReportQueryVariables,
      options?: UseQueryOptions<GetSalesByTenderBetaReportQuery, TError, TData>
    ) =>
    useQuery<GetSalesByTenderBetaReportQuery, TError, TData>(
      ['GetSalesByTenderBetaReport', variables],
      reportingGraphQlFetcher<GetSalesByTenderBetaReportQuery, GetSalesByTenderBetaReportQueryVariables>(GetSalesByTenderBetaReportDocument, variables),
      options
    );
export const GetStockInventoryValueDocument = `
    query GetStockInventoryValue {
  Metrics {
    RetailValue {
      productType
      count
      totalCost
      totalValue
    }
  }
}
    `;
export const useGetStockInventoryValueQuery = <
      TData = GetStockInventoryValueQuery,
      TError = unknown
    >(
      variables?: GetStockInventoryValueQueryVariables,
      options?: UseQueryOptions<GetStockInventoryValueQuery, TError, TData>
    ) =>
    useQuery<GetStockInventoryValueQuery, TError, TData>(
      variables === undefined ? ['GetStockInventoryValue'] : ['GetStockInventoryValue', variables],
      reportingGraphQlFetcher<GetStockInventoryValueQuery, GetStockInventoryValueQueryVariables>(GetStockInventoryValueDocument, variables),
      options
    );
export const GetStoreCreditOutstandingDocument = `
    query GetStoreCreditOutstanding {
  Customers {
    firstName
    lastName
    phone
    email
    storeCredit
  }
}
    `;
export const useGetStoreCreditOutstandingQuery = <
      TData = GetStoreCreditOutstandingQuery,
      TError = unknown
    >(
      variables?: GetStoreCreditOutstandingQueryVariables,
      options?: UseQueryOptions<GetStoreCreditOutstandingQuery, TError, TData>
    ) =>
    useQuery<GetStoreCreditOutstandingQuery, TError, TData>(
      variables === undefined ? ['GetStoreCreditOutstanding'] : ['GetStoreCreditOutstanding', variables],
      reportingGraphQlFetcher<GetStoreCreditOutstandingQuery, GetStoreCreditOutstandingQueryVariables>(GetStoreCreditOutstandingDocument, variables),
      options
    );
export const GetStoreCreditTransactionSummaryDocument = `
    query GetStoreCreditTransactionSummary($startDate: String!, $endDate: String!) {
  Metrics {
    StoreCreditByRange(fromTime: $startDate, toTime: $endDate) {
      updatedDate
      actionedBy
      amountBeforeChange
      amountAfterChange
      amountChanged
      invoiceNumber
      cartId
      shopifyCustomerBuylistId
      firstName
      lastName
      email
      actionNotes
      publicNotes
      privateNotes
    }
  }
}
    `;
export const useGetStoreCreditTransactionSummaryQuery = <
      TData = GetStoreCreditTransactionSummaryQuery,
      TError = unknown
    >(
      variables: GetStoreCreditTransactionSummaryQueryVariables,
      options?: UseQueryOptions<GetStoreCreditTransactionSummaryQuery, TError, TData>
    ) =>
    useQuery<GetStoreCreditTransactionSummaryQuery, TError, TData>(
      ['GetStoreCreditTransactionSummary', variables],
      reportingGraphQlFetcher<GetStoreCreditTransactionSummaryQuery, GetStoreCreditTransactionSummaryQueryVariables>(GetStoreCreditTransactionSummaryDocument, variables),
      options
    );
export const GetStoreCreditTransactionSummaryByCustomerDocument = `
    query GetStoreCreditTransactionSummaryByCustomer($startDate: String!, $endDate: String!, $customerId: Int!) {
  Metrics {
    StoreCreditByRange: StoreCreditByRangeForCustomer(
      fromTime: $startDate
      toTime: $endDate
      customerId: $customerId
    ) {
      updatedDate
      actionedBy
      amountBeforeChange
      amountAfterChange
      amountChanged
      invoiceNumber
      cartId
      shopifyCustomerBuylistId
      actionNotes
      publicNotes
      privateNotes
    }
  }
}
    `;
export const useGetStoreCreditTransactionSummaryByCustomerQuery = <
      TData = GetStoreCreditTransactionSummaryByCustomerQuery,
      TError = unknown
    >(
      variables: GetStoreCreditTransactionSummaryByCustomerQueryVariables,
      options?: UseQueryOptions<GetStoreCreditTransactionSummaryByCustomerQuery, TError, TData>
    ) =>
    useQuery<GetStoreCreditTransactionSummaryByCustomerQuery, TError, TData>(
      ['GetStoreCreditTransactionSummaryByCustomer', variables],
      reportingGraphQlFetcher<GetStoreCreditTransactionSummaryByCustomerQuery, GetStoreCreditTransactionSummaryByCustomerQueryVariables>(GetStoreCreditTransactionSummaryByCustomerDocument, variables),
      options
    );
export const GetWpnReportDataDocument = `
    query GetWPNReportData($startDate: String!, $endDate: String!) {
  Metrics {
    WPNReport(fromTime: $startDate, toTime: $endDate) {
      transactionTime
      transactionId
      UPC
      unitPrice
      totalPrice
      quantity
      description
    }
  }
}
    `;
export const useGetWpnReportDataQuery = <
      TData = GetWpnReportDataQuery,
      TError = unknown
    >(
      variables: GetWpnReportDataQueryVariables,
      options?: UseQueryOptions<GetWpnReportDataQuery, TError, TData>
    ) =>
    useQuery<GetWpnReportDataQuery, TError, TData>(
      ['GetWPNReportData', variables],
      reportingGraphQlFetcher<GetWpnReportDataQuery, GetWpnReportDataQueryVariables>(GetWpnReportDataDocument, variables),
      options
    );