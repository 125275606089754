import { GetStockInventoryValueQuery } from "../../generated/reporting";
import { StockValueData } from "../../types/reports";

export const formatStockInventoryValue = (
  report?: GetStockInventoryValueQuery
): StockValueData[] => {
  const retailValue = report?.Metrics?.RetailValue;
  if (!retailValue) {
    return [];
  }
  // Add an ID field so the table can use it as a key
  return retailValue.map((row, index) => ({ ...row, id: index }));
};
