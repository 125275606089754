import React from "react";

export default function EventParticipantPOSTooltip() {
  return (
    <aside>
      <h5>Participants from POS sales</h5>
      <p>
        This represents all participants for an event from POS sales, whether or
        not a customer was selected at the time that the cart was finalized. If
        no customer was selected, the participant will be listed as a guest but
        will still contain any additional information associated with the order.
      </p>
    </aside>
  );
}
