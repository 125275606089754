import { useMutation, useQuery, useQueryClient } from "react-query";
import { uploadCsv, fetchCsvUploads } from "../api/rest/products";
import { mapPreviousCsvUploads } from "../transformers/productDataImport";

const LONG_LIFE_DATA_STALE_TIME = 60_000; // 1 minute

export const useFetchCsvUploads = (offset: number, limit = 20) =>
  useQuery(
    ["useFetchCsvUploads", { offset, limit }],
    () => fetchCsvUploads(offset, limit),
    {
      keepPreviousData: true,
      staleTime: LONG_LIFE_DATA_STALE_TIME,
      select: (data) => data.map(mapPreviousCsvUploads),
    }
  );

export const useUploadCsv = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(uploadCsv, {
    onSettled: () => {
      queryClient.invalidateQueries("useFetchCsvUploads", {
        refetchInactive: true,
      });
    },
  });
  return { isMutating, ...rest };
};
