import { UseQueryOptions, QueryKey } from "react-query";
import {
  GetStoreCreditTransactionSummaryByCustomerQuery,
  GetStoreCreditTransactionSummaryByCustomerQueryVariables,
  useGetStoreCreditTransactionSummaryByCustomerQuery,
} from "../../generated/reporting";
import { formatStoreCreditTransactionSummary } from "../../transformers/reports/formatStoreCreditTransactions";
import { StoreCreditTransactionsData } from "../../types/reports";

export const useFetchStoreCreditByCustomer = (
  variables: GetStoreCreditTransactionSummaryByCustomerQueryVariables,
  options: UseQueryOptions<
    GetStoreCreditTransactionSummaryByCustomerQuery,
    unknown,
    StoreCreditTransactionsData[],
    QueryKey
  > = {}
) =>
  useGetStoreCreditTransactionSummaryByCustomerQuery(variables, {
    select: (report) => formatStoreCreditTransactionSummary(report),
    keepPreviousData: true,
    staleTime: 60_000,
    ...options,
  });
