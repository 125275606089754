import React, { useMemo } from "react";
import { BinderCustomerShopifyTheme } from "../../generated/graphql";
import { availableThemes } from "../../constants/themes";
import InfoPanel from "../generic/InfoPanel";
import "./ThemeSelect.scss";

interface ThemeSelectProps {
  customerThemes: Partial<BinderCustomerShopifyTheme>[];
  selectedTheme: string;
  setSelectedTheme: (theme: string) => void;
}

function ThemeSelect(props: ThemeSelectProps) {
  const { customerThemes, selectedTheme, setSelectedTheme } = props;

  const installedThemes = useMemo(() => {
    return customerThemes.map((customerTheme) => {
      const theme = availableThemes[customerTheme.theme];
      return { value: customerTheme.theme, label: theme?.themeName };
    });
  }, [customerThemes]);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    setSelectedTheme(value);
  };

  return (
    <InfoPanel className="ThemeSelect">
      Your Shopify store has multiple themes installed. Please select the theme
      that corresponds to the one currently live on your store.
      <select
        value={selectedTheme}
        onChange={handleChange}
        title="Select theme"
        className="ThemeSelect__select"
      >
        {installedThemes.map((installedTheme) => (
          <option key={installedTheme.value} value={installedTheme.value}>
            {installedTheme.label}
          </option>
        ))}
      </select>
    </InfoPanel>
  );
}

export default ThemeSelect;
