/**
 * Handles communication to and from PAX temrinals such as the S300
 */

class PAX {
  /**
   *
   * @param {string} baseURL - The base URL of the PAX terminal device
   * should include http:// as well as :port
   */
  constructor(baseURL = "https://9.dns.binderpos.com:10009") {
    this.mDestinationIP = baseURL;
  }

  // These functions were written by BinderPOS

  DoCreditWrapper(creditObject) {
    const paxClass = this;
    return new Promise((resolve, reject) => {
      try {
        paxClass.DoCredit(creditObject, (resp) => {
          if (resp.error) {
            console.error({ doCreditError: resp });
            reject({
              status: false,
              reason: resp.error.message,
              response: null,
            });
          } else {
            switch (resp[5]) {
              case "TIMEOUT":
                resolve({
                  status: false,
                  reason: "Timeout",
                  response: paxClass.beautifyCreditResp(resp),
                });
                break;
              case "ABORTED":
                resolve({
                  status: false,
                  reason: "Aborted",
                  response: paxClass.beautifyCreditResp(resp),
                });
                break;
              case "DUP TRANSACTION":
                resolve({
                  status: false,
                  reason: "Aborted",
                  response: paxClass.beautifyCreditResp(resp),
                });
                break;
              case "OK":
                resolve({
                  status: true,
                  reason: "Success",
                  response: paxClass.beautifyCreditResp(resp),
                });
                break;
              default:
                reject({
                  status: false,
                  reason: resp,
                  response: paxClass.beautifyCreditResp(resp),
                });
            }
          }
        });
      } catch (e) {
        reject(e);
      }
    });
  }

  /**
   *
   * @param {float} amount - The amount to request from the terminal device
   */
  creditSale(amount, referenceNumber = 1) {
    const creditObject = {
      command: "T00",
      version: "1.40",
      transactionType: "01",
      amountInformation: {
        TransactionAmount: parseInt((amount * 100).toFixed(0)),
      },
      traceInformation: {
        ReferenceNumber: referenceNumber,
      },
    };

    return this.DoCreditWrapper(creditObject);
  }

  /**
   *
   * @param {float} amount - The amount to debit back to the terminal
   */
  creditReturn(amount, referenceNumber = 1) {
    const creditObject = {
      command: "T00",
      version: "1.40",
      transactionType: "02",
      amountInformation: {
        TransactionAmount: parseInt((amount * 100).toFixed(0)),
      },
      traceInformation: {
        ReferenceNumber: referenceNumber,
      },
    };
    return this.DoCreditWrapper(creditObject);
  }

  /**
   *
   * @param {int} transactionNumber - The transaction ID of the transaction on the batch
   */
  creditVoid(transactionNumber, referenceNumber = 1) {
    const creditObject = {
      command: "T00",
      version: "1.40",
      transactionType: "16",
      // the library accesses objects like via indices so the blanks have to be there
      traceInformation: {
        ReferenceNumber: referenceNumber,
        InvoiceNumber: "",
        AuthCode: "",
        TransactionNumber: transactionNumber,
      },
    };
    return this.DoCreditWrapper(creditObject);
  }

  creditMenu(referenceNumber = 1) {
    const creditObject = {
      command: "T00",
      version: "1.40",
      transactionType: "00",
      // the library accesses objects like via indices so the blanks have to be there
      traceInformation: {
        ReferenceNumber: referenceNumber,
        InvoiceNumber: "",
        AuthCode: "",
        TransactionNumber: "",
      },
    };
    const paxClass = this;
    return new Promise((resolve, reject) => {
      paxClass.DoCredit(creditObject, (resp) => {
        resolve({ status: true });
      });
    });
  }

  beautifyCreditResp(resp) {
    const response = {};
    response.status = resp[1];
    response.command = resp[2];
    response.version = resp[3];
    response.responseCode = resp[4];
    response.responseMessage = resp[5];
    if (resp[6]) {
      response.hostInformation = {
        hostResponseCode: resp[6][0],
        hostResponseMessage: resp[6][1],
        authCode: resp[6][2],
        hostReferenceNumber: resp[6][3],
        traceNumber: resp[6][4],
        batchNumber: resp[6][5],
      };
    }
    if (resp[7]) {
      response.transactionType = resp[7];
    }
    if (resp[8]) {
      response.amountInformation = {
        approveAmount: isNaN(parseInt(resp[8][0]))
          ? null
          : (parseInt(resp[8][0]) / 100).toFixed(2),
        amountDue: isNaN(parseInt(resp[8][1]))
          ? null
          : (parseInt(resp[8][1]) / 100).toFixed(2),
        tipAmount: isNaN(parseInt(resp[8][2]))
          ? null
          : (parseInt(resp[8][2]) / 100).toFixed(2),
        cashBackAmount: isNaN(parseInt(resp[8][3]))
          ? null
          : (parseInt(resp[8][3]) / 100).toFixed(2),
        surchargeFee: isNaN(parseInt(resp[8][4]))
          ? null
          : (parseInt(resp[8][4]) / 100).toFixed(2),
        taxAmount: isNaN(parseInt(resp[8][5]))
          ? null
          : (parseInt(resp[8][5]) / 100).toFixed(2),
        balance1: isNaN(parseInt(resp[8][6]))
          ? null
          : (parseInt(resp[8][6]) / 100).toFixed(2),
        balance2: isNaN(parseInt(resp[8][7]))
          ? null
          : (parseInt(resp[8][7]) / 100).toFixed(2),
      };
    }
    if (resp[9]) {
      response.accountInformation = {
        account: resp[9][0],
        entryMode: resp[9][1],
        expireDate: resp[9][2],
        ebtType: resp[9][3],
        voucherNumber: resp[9][4],
        newAccountNo: resp[9][5],
        cardType: resp[9][6],
        cardHolder: resp[9][7],
        cvdApprovalCode: resp[9][8],
        cvdMessage: resp[9][9],
        cardPresentIndicator: resp[9][10],
      };
      if (resp[10]) {
        response.traceInformation = {
          transactionNumber: resp[10][0],
          referenceNumber: resp[10][1],
          timeStamp: resp[10][2],
        };
      }
      if (resp[11]) {
        response.avsInformation = {
          avsApprovalCode: resp[11][0],
          avsMessage: resp[11][1],
        };
      }
      if (resp[12]) {
        response.commercialInformation = {};
      }
      if (resp[13]) {
        response.motoEcommerce = {};
      }
      if (resp[14]) {
        response.additionalInformation = {};
        resp[14].forEach((addInf) => {
          if (addInf.split("=") && addInf.split("=").length == 2) {
            // prettier-ignore
            response.additionalInformation[addInf.split('=')[0]] = addInf.split('=')[1];
          }
        });
      }
    }
    return response;
  }

  // This is all modified from the javascript example from pax portal

  // HEX TO BASE64
  hexToBase64(str) {
    return btoa(
      String.fromCharCode.apply(
        null,
        str
          .replace(/\r|\n/g, "")
          .replace(/([\da-fA-F]{2}) ?/g, "0x$1 ")
          .replace(/ +$/, "")
          .split(" ")
      )
    );
  }

  // BASE64 TO HEX
  base64ToHex(str) {
    for (var i = 0, bin = atob(str), hex = []; i < bin.length; ++i) {
      let tmp = bin.charCodeAt(i).toString(16);
      if (tmp.length === 1) tmp = `0${tmp}`;
      hex[hex.length] = tmp;
    }
    return hex.join(" ");
  }

  StringToHex(response) {
    let responseHex = "";
    for (let i = 0; i < response.length; i++) {
      if (responseHex == "") {
        responseHex =
          response.charCodeAt(i).toString(16).length < 2
            ? `0${response.charCodeAt(i).toString(16)}`
            : response.charCodeAt(i).toString(16);
      } else {
        responseHex +=
          response.charCodeAt(i).toString(16).length < 2
            ? `${" " + "0"}${response.charCodeAt(i).toString(16)}`
            : ` ${response.charCodeAt(i).toString(16)}`;
      }
    }
    return responseHex;
  }

  HexToString(response) {
    let responseHex = "";
    const arr = response.split(" ");
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] == "") continue;
      responseHex += String.fromCharCode(parseInt(arr[i], 16));
    }
    return responseHex;
  }

  // IP of the POS gets set by constructor
  mDestinationIP = "";

  mStx = {
    hex: 0x02,
    code: "02",
  };

  mFS = {
    hex: 0x1c,
    code: "1c",
  };

  mEtx = {
    hex: 0x03,
    code: "03",
  };

  mUS = {
    hex: 0x1f,
    code: "1F",
  };

  customData = "";

  timeout = {
    Initialize: 120 * 1000,
    GetSignature: 120 * 1000,
    DoSignature: 120 * 1000,
    DoCredit: 120 * 1000,
  };

  // Set ip and port
  Settings(ip, port) {
    this.mDestinationIP = `http://${ip}:${port}`;
    // console.log("New service address: " + this.mDestinationIP);
  }

  AjaxTimeOut(command, timeout) {
    this.timeout[command] = timeout;
  }

  SetCustomData(custom_data) {
    this.customData = custom_data;
    // console.log(custom_data);
  }

  // Get LRC
  getLRC(params) {
    let lrc = 0;
    for (let i = 1; i < params.length; i++) {
      const type_of = typeof params[i];
      if (type_of == "string") {
        const element = params[i].split("");
        for (let ii = 0; ii < element.length; ii++) {
          lrc ^= element[ii].charCodeAt(0);
        }
      } else {
        lrc ^= params[i];
      }
    }
    return lrc > 0 ? String.fromCharCode(lrc) : 0;
  }

  // Connect to the server
  async HttpCommunication(commandType, url, callback, timeout) {
    try {
      const requestResp = await fetch(url);

      if (requestResp.status == "200") {
        const response = await requestResp.text();

        const checkParams = this.StringToHex(response).split(" ").pop();
        const RedundancyCheck = this.StringToHex(response)
          .split(" ")
          .pop()
          .substring(1);

        const check = this.getLRC(checkParams);

        if (check == RedundancyCheck) {
          // get package detail info
          const packetInfo = [];
          const len = this.StringToHex(response).indexOf("03");
          const hex = this.StringToHex(response).slice(0, len).split(/02|1c/);

          // console.log(hex);
          if (commandType == "DoCredit") {
            let subHex = [];
            let subPacketInfo = [];
            for (var i = 0; i < hex.length; i++) {
              if (hex[i] != "") {
                if (hex[i].indexOf("1f") > 0) {
                  subHex = hex[i].split("1f");
                  // console.log(subHex);
                  subPacketInfo = [];
                  for (let j = 0; j < subHex.length; j++) {
                    if (subHex[j] != "") {
                      subPacketInfo.push(this.HexToString(subHex[j]));
                    }
                  }
                  // console.log(subPacketInfo);
                  packetInfo.push(subPacketInfo);
                } else {
                  packetInfo[i] = this.HexToString(hex[i]);
                }
              }
            }
          } else {
            for (var i = 0; i < hex.length; i++) {
              if (hex[i] != "") {
                packetInfo[i] = this.HexToString(hex[i]);
              }
            }
          }

          // console.log("Separate package info: ");
          callback(packetInfo);
        }
      } else {
        throw requestResp;
      }
    } catch (error) {
      console.log({ error });
      callback({ error: error });
    }
  }

  Initialize(initialInfo, callback) {
    const params = [
      this.mStx.hex,
      initialInfo.command,
      this.mFS.hex,
      initialInfo.version,
      this.mEtx.hex,
    ];
    // [02]A08[1c]1.28[1c]0[1c]90000[03]
    // var params = [0x02,"A08",0x1c,"1.28",0x1c, "0", 0x1c,"90000",0x03];
    const lrc = this.getLRC(params);
    const command_hex = this.base64ToHex(btoa(initialInfo.command));
    const version_hex = this.base64ToHex(btoa(initialInfo.version));
    // var elements = [this.mStx, command_hex, this.mFS, version_hex, this.mEtx, this.base64ToHex(btoa(lrc))];
    const elements = [
      this.mStx.code,
      command_hex,
      this.mFS.code,
      version_hex,
      this.mEtx.code,
      this.base64ToHex(btoa(lrc)),
    ];

    const final_string = elements.join(" ");
    /// /console.log("final_string: " + final_string);

    const final_b64 = this.hexToBase64(final_string);
    // console.log("LRC: " + lrc);
    // console.log("Base64: " + final_b64);
    const url = `${this.mDestinationIP}?${final_b64}`;
    // console.log("URL: " + url);

    this.HttpCommunication(
      "Initialize",
      url,
      (response) => {
        callback(response);
      },
      this.timeout.Initialize
    );
  }

  // GET SIGNATURE
  GetSignature(getSignatureInfo, callback) {
    const params = [
      this.mStx.hex,
      getSignatureInfo.command,
      this.mFS.hex,
      getSignatureInfo.version,
      this.mFS.hex,
      getSignatureInfo.offset,
      this.mFS.hex,
      getSignatureInfo.requestlength,
      this.mEtx.hex,
    ];
    const lrc = this.getLRC(params);

    // prepare for base64 encoding.
    const command_hex = this.base64ToHex(btoa(getSignatureInfo.command));
    const version_hex = this.base64ToHex(btoa(getSignatureInfo.version));
    const offset_hex = this.base64ToHex(btoa(getSignatureInfo.offset));
    const requestlength_hex = this.base64ToHex(
      btoa(getSignatureInfo.requestlength)
    );
    // var elements = [this.mStx.code, command_hex, this.mFS.code, version_hex, this.mFS.code, offset_hex, this.mFS.code, requestlength_hex, this.mEtx.code, this.base64ToHex(btoa(lrc))];
    const elements = [this.mStx.code];
    elements.push(command_hex);
    elements.push(this.mFS.code);
    elements.push(version_hex);
    elements.push(this.mFS.code);
    if (offset_hex != "") {
      elements.push(offset_hex);
    }
    elements.push(this.mFS.code);
    if (requestlength_hex != "") {
      elements.push(requestlength_hex);
    }
    elements.push(this.mEtx.code);
    elements.push(this.base64ToHex(btoa(lrc)));

    const final_string = elements.join(" ");
    const final_b64 = this.hexToBase64(final_string);
    // console.log("LRC: " + lrc);
    // console.log("Base64: " + final_b64);
    const url = `${this.mDestinationIP}?${final_b64}`;
    // console.log("URL: " + url);

    this.HttpCommunication(
      "GetSignature",
      url,
      (response) => {
        callback(response);
      },
      this.timeout.GetSignature
    );
  }

  // DO SIGNATURE
  DoSignature(doSignatureInfo, callback) {
    const params = [
      this.mStx.hex,
      doSignatureInfo.command,
      this.mFS.hex,
      doSignatureInfo.version,
      this.mFS.hex,
      doSignatureInfo.uploadFlag,
      this.mFS.hex,
      doSignatureInfo.hostReferenceNumber,
      this.mFS.hex,
      doSignatureInfo.edcType,
      this.mFS.hex,
      doSignatureInfo.timeout,
      this.mEtx.hex,
    ];
    const lrc = this.getLRC(params);

    // prepare for base64 encoding.
    const command_hex = this.base64ToHex(btoa(doSignatureInfo.command));
    const version_hex = this.base64ToHex(btoa(doSignatureInfo.version));
    const uploadFlag_hex = this.base64ToHex(btoa(doSignatureInfo.uploadFlag));
    const hostReferenceNumber_hex = this.base64ToHex(
      btoa(doSignatureInfo.hostReferenceNumber)
    );
    const edcType_hex = this.base64ToHex(btoa(doSignatureInfo.edcType));
    const timeout_hex = this.base64ToHex(btoa(doSignatureInfo.timeout));
    const elements = [this.mStx.code];
    elements.push(command_hex);
    elements.push(this.mFS.code);
    elements.push(version_hex);
    elements.push(this.mFS.code);
    if (uploadFlag_hex != "") {
      elements.push(uploadFlag_hex);
    }
    elements.push(this.mFS.code);
    if (hostReferenceNumber_hex != "") {
      elements.push(hostReferenceNumber_hex);
    }
    elements.push(this.mFS.code);
    if (edcType_hex != "") {
      elements.push(edcType_hex);
    }
    elements.push(this.mFS.code);
    if (timeout_hex != "") {
      elements.push(timeout_hex);
    }
    elements.push(this.mEtx.code);
    elements.push(this.base64ToHex(btoa(lrc)));

    const final_string = elements.join(" ");
    const final_b64 = this.hexToBase64(final_string);
    // console.log("LRC: " + lrc);
    // console.log("Base64: " + final_b64);
    const url = `${this.mDestinationIP}?${final_b64}`;
    // console.log("URL: " + url);

    this.HttpCommunication(
      "DoSignature",
      url,
      (response) => {
        callback(response);
      },
      this.timeout.DoSignature
    );
  }

  PushParams(params, type, objectInfo) {
    let empty = 0;
    let arr = [];
    arr = arr.concat(params);
    for (const name in objectInfo) {
      if (objectInfo[name] == "" && type != "additionalInformation") {
        arr.push(this.mUS.hex);
        continue;
      }

      if (type == "additionalInformation") {
        if (objectInfo[name] == "") {
          continue;
        }
        empty++;
        arr.push(`${name}=${objectInfo[name].toString()}`);
      } else {
        empty++;
        arr.push(objectInfo[name].toString());
      }
      arr.push(this.mUS.hex);
    }
    arr.pop();
    if (empty == 0 && type != "additionalInformation") {
      arr = params;
    }
    if (empty == 0 && type == "additionalInformation") {
      arr.push(this.mFS.hex);
    }
    /// /console.log(params);
    return arr;
  }

  AddBase64(elements, type, objectInfo) {
    /// /console.log(objectInfo);
    let empty = 0;
    let arr = [];
    arr = arr.concat(elements);
    for (name in objectInfo) {
      if (objectInfo[name] == "" && type != "additionalInformation") {
        arr.push(this.mUS.code);
        continue;
      }
      if (type == "additionalInformation") {
        if (objectInfo[name] == "") continue;
        empty++;
        arr.push(
          this.base64ToHex(btoa(`${name}=${objectInfo[name].toString()}`))
        );
      } else {
        empty++;
        arr.push(this.base64ToHex(btoa(objectInfo[name].toString())));
      }
      arr.push(this.mUS.code);
    }
    arr.pop();
    if (empty == 0 && type != "additionalInformation") {
      arr = elements;
    }
    if (empty == 0 && type == "additionalInformation") {
      arr.push(this.mFS.code);
    }
    /// /console.log(arr);
    return arr;
  }

  // DO Credit
  DoCredit(doCreditInfo, callback) {
    // console.log(doCreditInfo);
    let amountInformation;
    let accountInformation;
    let traceInformation;
    let avsInformation;
    let cashierInformation;
    let commercialInformation;
    let motoEcommerce;
    let additionalInformation;
    let params = [
      this.mStx.hex,
      doCreditInfo.command,
      this.mFS.hex,
      doCreditInfo.version,
    ];
    params.push(this.mFS.hex);
    if (doCreditInfo.transactionType != "") {
      params.push(doCreditInfo.transactionType);
    }
    params.push(this.mFS.hex);
    params = this.PushParams(
      params,
      "amountInformation",
      doCreditInfo.amountInformation
    );

    params.push(this.mFS.hex);
    params = this.PushParams(
      params,
      "accountInformation",
      doCreditInfo.accountInformation
    );

    params.push(this.mFS.hex);
    params = this.PushParams(
      params,
      "traceInformation",
      doCreditInfo.traceInformation
    );

    params.push(this.mFS.hex);
    params = this.PushParams(
      params,
      "avsInformation",
      doCreditInfo.avsInformation
    );

    params.push(this.mFS.hex);
    params = this.PushParams(
      params,
      "cashierInformation",
      doCreditInfo.cashierInformation
    );

    params.push(this.mFS.hex);
    params = this.PushParams(
      params,
      "commercialInformation",
      doCreditInfo.commercialInformation
    );

    params.push(this.mFS.hex);
    params = this.PushParams(
      params,
      "motoEcommerce",
      doCreditInfo.motoEcommerce
    );

    params.push(this.mFS.hex);
    params = this.PushParams(
      params,
      "additionalInformation",
      doCreditInfo.additionalInformation
    );

    params.push(this.mEtx.hex);

    const lrc = this.getLRC(params);

    // console.log(params);

    // prepare for base64 encoding.
    const command_hex = this.base64ToHex(btoa(doCreditInfo.command));
    const version_hex = this.base64ToHex(btoa(doCreditInfo.version));
    const transactionType_hex = this.base64ToHex(
      btoa(doCreditInfo.transactionType)
    );
    const amountInformation_hex = this.base64ToHex(
      btoa(doCreditInfo.amountInformation)
    );
    const accountInformation_hex = this.base64ToHex(
      btoa(doCreditInfo.accountInformation)
    );
    const traceInformation_hex = this.base64ToHex(
      btoa(doCreditInfo.traceInformation)
    );
    const avsInformation_hex = this.base64ToHex(
      btoa(doCreditInfo.avsInformation)
    );
    const cashierInformation_hex = this.base64ToHex(
      btoa(doCreditInfo.cashierInformation)
    );
    const commercialInformation_hex = this.base64ToHex(
      btoa(doCreditInfo.commercialInformation)
    );
    const motoEcommerce_hex = this.base64ToHex(
      btoa(doCreditInfo.motoEcommerce)
    );
    const additionalInformation_hex = this.base64ToHex(
      btoa(doCreditInfo.additionalInformation)
    );

    // var elements = [this.mStx.code, command_hex, this.mFS.code, version_hex, this.mFS.code, uploadFlag_hex, this.mFS.code, timeout, this.mEtx.code, this.base64ToHex(btoa(lrc))];
    let elements = [this.mStx.code];
    elements.push(command_hex);
    elements.push(this.mFS.code);
    elements.push(version_hex);
    elements.push(this.mFS.code);

    if (transactionType_hex != "") {
      elements.push(transactionType_hex);
    }
    elements.push(this.mFS.code);

    elements = this.AddBase64(
      elements,
      "amountInformation",
      doCreditInfo.amountInformation
    );
    elements.push(this.mFS.code);
    elements = this.AddBase64(
      elements,
      "accountInformation",
      doCreditInfo.accountInformation
    );
    elements.push(this.mFS.code);
    elements = this.AddBase64(
      elements,
      "traceInformation",
      doCreditInfo.traceInformation
    );
    elements.push(this.mFS.code);
    elements = this.AddBase64(
      elements,
      "avsInformation",
      doCreditInfo.avsInformation
    );
    elements.push(this.mFS.code);
    elements = this.AddBase64(
      elements,
      "cashierInformation",
      doCreditInfo.cashierInformation
    );
    elements.push(this.mFS.code);
    elements = this.AddBase64(
      elements,
      "commercialInformation",
      doCreditInfo.commercialInformation
    );
    elements.push(this.mFS.code);
    elements = this.AddBase64(
      elements,
      "motoEcommerce",
      doCreditInfo.motoEcommerce
    );
    elements.push(this.mFS.code);
    elements = this.AddBase64(
      elements,
      "additionalInformation",
      doCreditInfo.additionalInformation
    );

    elements.push(this.mEtx.code);
    elements.push(this.base64ToHex(btoa(lrc)));
    // console.log("elements");
    // console.log(elements);

    const final_string = elements.join(" ");
    const final_b64 = this.hexToBase64(final_string);
    // console.log("LRC: " + lrc);
    // console.log("Base64: " + final_b64);

    // if(customData != ''){
    // 	final_b64 = this.hexToBase64(final_string+"&custom_data=<PAX>"+customData+"</PAX>");
    // }

    const url = `${this.mDestinationIP}?${final_b64}`;
    // console.log("URL: " + url);

    this.HttpCommunication(
      "DoCredit",
      url,
      (response) => {
        callback(response);
      },
      this.timeout.DoCredit
    );
  }
}

export default PAX;
