import React from "react";
import { notify } from "../../helpers/notificationHelpers";
import { useShowError } from "../../hooks/errorHooks";
import { useCreateTerminalSettings } from "../../hooks/paymentTerminalHooks";
import ButtonComponent from "../generic/ButtonComponent";

const settings = {
  paymentProvider: "",
  paymentTerminalEnabled: false,
  paxEnabled: "false",
};

interface NoPaymentTerminalSettingsProps {
  tillId: number;
}

function NoPaymentTerminalSettings(props: NoPaymentTerminalSettingsProps) {
  const { tillId } = props;
  const showError = useShowError();
  const { mutateAsync, isMutating } = useCreateTerminalSettings();

  const handleSave = () =>
    mutateAsync({ tillId, settings })
      .then(() => notify.info("Setting updated"))
      .catch((error) =>
        showError(error, "Failed to save changes", "Please try again")
      );

  return (
    <ButtonComponent
      primary
      fullWidth
      onClick={handleSave}
      disabled={isMutating}
    >
      Save Changes
    </ButtonComponent>
  );
}

export default NoPaymentTerminalSettings;
