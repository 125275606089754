import React, { useState, MutableRefObject } from "react";
import { notify } from "../../helpers/notificationHelpers";
import { useShowError } from "../../hooks/errorHooks";
import { useDeleteEvent } from "../../hooks/eventHooks";
import ButtonComponent from "../generic/ButtonComponent";
import ConfirmationModal from "../generic/ConfirmationModal";
import Modal from "../generic/Modal";

interface DeleteEventModalProps {
  id: number;
  title: string;
  parentEventId?: number;
  isRecurring?: boolean;
  handleClose: () => void;
}

function DeleteEventModal(
  props: DeleteEventModalProps,
  ref: MutableRefObject<any>
) {
  const { id, title, parentEventId, isRecurring, handleClose } = props;
  const [showConfirmation, setShowConfirmation] = useState(false);
  const showError = useShowError();
  const { mutateAsync: deleteEvent, isMutating: isDeleting } = useDeleteEvent();

  const handleDeleteEvent = (eventId: number) =>
    deleteEvent({ eventId, parentEventId })
      .then(() => {
        if (!isRecurring) {
          notify.success(parentEventId ? "Event paused" : "Event deleted");
          handleClose();
        } else {
          setShowConfirmation(true);
        }
      })
      .catch((error) =>
        showError(
          error,
          "Failed to delete event",
          "There was an error deleting your event. Please try again"
        )
      );

  if (showConfirmation) {
    return (
      <ConfirmationModal
        title="Event is preparing to delete"
        link="/events/list"
      >
        Your event has been queued for deletion. This will be processed shortly.
      </ConfirmationModal>
    );
  }

  return (
    <Modal
      isLoading={isDeleting}
      onClose={handleClose}
      ref={ref}
      testId="DeleteEventModal"
    >
      <Modal.Header>Are you sure?</Modal.Header>
      <Modal.Content>
        {parentEventId ? (
          <>
            <p>
              If you proceed with disabling this event: <strong>{title}</strong>
              , customers will not be able to register for this event if you do
              so.
            </p>
            <p>Are you sure you want to disable the event?</p>
          </>
        ) : (
          <>
            <p>
              If you proceed with deleting your event: <strong>{title}</strong>,
              you will not be able to restore it.
            </p>
            <p>Are you sure you want to delete the event?</p>
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <ButtonComponent
          secondary
          icon="fal fa-times"
          iconPosition="right"
          onClick={handleClose}
        >
          Cancel
        </ButtonComponent>
        <ButtonComponent
          primary
          disabled={isDeleting}
          icon="fas fa-caret-right"
          iconPosition="right"
          onClick={() => handleDeleteEvent(id)}
        >
          {parentEventId ? "Disable " : "Delete "}
        </ButtonComponent>
      </Modal.Actions>
    </Modal>
  );
}

export default React.forwardRef(DeleteEventModal);
