import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import Loader from "../generic/Loader";
import CartLine from "./CartLine";
import Checkout from "./Checkout";
import DeleteCartModal from "./DeleteCartModal";
import MultiTenderCheckout from "./MultiTenderCheckout";
import CustomLineComponent from "./CustomLineComponent";
import EditCartNotes from "./EditCartNotes";
import { notify } from "../../helpers/notificationHelpers";
import { isTaxRateValid } from "../../utils/pos/tax";
import { roundCents } from "../../utils/currencyHelpers";
import IconButton from "../generic/IconButton";
import { segmentAnalytics } from "../services/Analytics";
import {
  ADD_CUSTOM_ITEM_CLICKED,
  ADD_NOTE_CLICKED,
  RECEIPT_PRINT_CLICKED,
  DELETE_CART_CLICKED,
  VALUE_DISCOUNT_CLICKED,
  PERCENT_DISCOUNT_CLICKED,
  CART_SUBMIT_CLICKED,
} from "../../constants/eventsTracked";
import { ItemList } from "../../pos/ItemStore";
import { RootStore } from "../../store";
import ButtonComponent from "../generic/ButtonComponent";
import "./Cart.scss";

interface CartProps {
  posStore?: ItemList;
  store?: RootStore;
}

@inject("posStore", "store")
@observer
class Cart extends Component<
  CartProps,
  { showNote: boolean; itemShow: boolean; discountModal: boolean }
> {
  debounceTimeout: NodeJS.Timeout;

  constructor(props) {
    super(props);
    this.state = {
      showNote: false,
      itemShow: false,
      discountModal: false,
    };
    this.debounceTimeout = null;
  }

  async componentDidMount() {
    //if the component mounts while already logged into a till
    //load tax, latest cart, and quicklinks
    //otherwise we expect the float modal to be active and will handle
    //this logic once log in is complete
    if (!this.props.posStore.floatStatus) return;
    if (!this.props.posStore.tillId) return;
    await this.props.posStore.getTax();
    await this.props.posStore.getLatestCart();
    await this.props.posStore.getQuickLinkData();
    await this.props.posStore.getCustomFields();
  }

  @observable deleteModal = null;
  @action setDeleteModal = (value) => {
    this.deleteModal = value;
  };

  @action
  discountChange = (e) => {
    const floatRegExp = new RegExp("^[+-]?([0-9]+([.][0-9]{0,2})?|[.][0-9]+)$");
    if (e.target.id == "discountAmount") {
      if (e.target.value === "" || floatRegExp.test(e.target.value)) {
        this.props.posStore.globalDiscount.amount = e.target.value || "0";
      }
    }
    if (e.target.id == "percent") {
      this.props.posStore.globalDiscount.type = "percentage";
    }
    if (e.target.id == "amount") {
      this.props.posStore.globalDiscount.type = "amount";
    }
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }
    this.debounceTimeout = setTimeout(
      this.props.posStore.validateCartNoRefresh,
      500
    );
  };

  @action
  showModal = () => {
    if (!this.props.posStore.showCustomItemComponent) {
      this.props.posStore.checkoutModal = true;
      this.props.posStore.setDisableLineItems(true);
    } else {
      notify.info("Unsaved custom item in cart");
    }
  };
  @action
  hideModal = () => {
    this.props.posStore.checkoutModal = false;
    this.props.posStore.setDisableLineItems(false);
    this.props.posStore.cartLoading = false;
  };

  toggleItem = () => {
    this.setState({ itemShow: !this.state.itemShow });
  };

  toggleDiscount = () => {
    this.setState({ discountModal: !this.state.discountModal });
  };

  render() {
    const fC = this.props.posStore.fCurr;
    return (
      <React.Fragment>
        <div className="cart-header">
          <button
            className="customItem"
            onClick={() => {
              this.props.posStore.toggleCustomItem();
              segmentAnalytics.track(ADD_CUSTOM_ITEM_CLICKED);
            }}
          >
            {!this.state.itemShow ? "Add a custom item" : "Close custom item"}{" "}
            <i
              className={
                !this.state.itemShow ? "fal fa-plus-square" : "fal fa-times"
              }
            ></i>{" "}
          </button>
          <button
            className="cartNote"
            onClick={() => {
              this.setState({ showNote: true });
              segmentAnalytics.track(ADD_NOTE_CLICKED);
            }}
          >
            {this.props.posStore.cartNotes ? (
              <>
                Edit note <i className="fas fa-edit"></i>
              </>
            ) : (
              <>
                Add Note <i className="fal fa-plus-square"></i>
              </>
            )}
          </button>
          <div className="cartNumber">
            <IconButton
              className=""
              icon="fas fa-print"
              aria-label="Receipt preview"
              onClick={() => {
                this.props.posStore.openReceipt();
                segmentAnalytics.track(RECEIPT_PRINT_CLICKED);
              }}
            />
            Cart #{this.props.posStore.cartId}
            <IconButton
              className=""
              title="Delete this cart"
              aria-label="Delete this cart"
              icon="far fa-trash-alt"
              onClick={() => {
                this.setDeleteModal(this.props.posStore.cartId);
                segmentAnalytics.track(DELETE_CART_CLICKED);
              }}
            />
          </div>
          {this.state.showNote && (
            <EditCartNotes
              customFields={this.props.posStore.customFields}
              cartNotes={this.props.posStore.cartNotes}
              onClose={() => this.setState({ showNote: false })}
              posStore={this.props.posStore}
            />
          )}
        </div>
        <div
          className={
            this.props.posStore.disableLineItems ? " cart disable" : "cart"
          }
        >
          {this.props.posStore.gettingLatestCart ? (
            <Loader text="Loading cart..." />
          ) : (
            <div className="cart-items" role="list">
              {this.props.posStore.cart.map((item) => (
                <CartLine item={item} key={item.productId} />
              ))}
              {this.props.posStore.showCustomItemComponent ? (
                <CustomLineComponent />
              ) : null}
            </div>
          )}
          {/*This div is used as a reference to autoscroll to the bottom*/}
          <div style={{ float: "left", clear: "both" }}></div>
          <div className="cartTotals">
            <span className="discounts">
              Discount:{" "}
              <div className="discountWrapper">
                <button
                  id="amount"
                  className={
                    this.props.posStore.globalDiscount.type == "amount"
                      ? "amount"
                      : "amount notActive"
                  }
                  onClick={(e) => {
                    this.discountChange(e);
                    segmentAnalytics.track(VALUE_DISCOUNT_CLICKED);
                  }}
                  aria-label="Discount type - value amount"
                  aria-pressed={
                    this.props.posStore.globalDiscount.type == "amount"
                  }
                >
                  $
                </button>
                <input
                  type="text"
                  aria-label="Discount amount"
                  name="discountAmount"
                  id="discountAmount"
                  value={this.props.posStore.globalDiscount.amount}
                  onChange={this.discountChange}
                />
                <button
                  id="percent"
                  className={
                    this.props.posStore.globalDiscount.type == "percentage"
                      ? "percentage"
                      : "percentage notActive"
                  }
                  onClick={(e) => {
                    this.discountChange(e);
                    segmentAnalytics.track(PERCENT_DISCOUNT_CLICKED);
                  }}
                  aria-label="Discount type - percentage"
                  aria-pressed={
                    this.props.posStore.globalDiscount.type == "percentage"
                  }
                >
                  %
                </button>
              </div>
              <em>{fC(roundCents(this.props.posStore.discountAmount))}</em>
            </span>
            <span className="subTotal">
              Subtotal ({this.props.posStore.totalItems} Item)
              <em>{fC(roundCents(this.props.posStore.subTotal))}</em>
            </span>
            <span className="tax">
              Tax ({this.props.posStore.taxRateDisplay}%)
              {this.props.posStore.negatedTaxTotal ? (
                <span className="taxNegated">
                  {"("} {fC(roundCents(this.props.posStore.negatedTaxTotal))}{" "}
                  {"negated by trade ins)"}
                </span>
              ) : (
                ""
              )}
              <em>{fC(roundCents(this.props.posStore.taxTotal))}</em>
            </span>
            <span className="total">
              Total<em>{fC(roundCents(this.props.posStore.total))}</em>
            </span>
          </div>
          <ButtonComponent
            primary
            fullWidth
            className="Cart__buyButton"
            icon="fas fa-caret-right"
            iconPosition="right"
            data-testid="buy-button"
            disabled={!isTaxRateValid(this.props.posStore.taxRate)}
            onClick={() => {
              this.showModal();
              segmentAnalytics.track(CART_SUBMIT_CLICKED, {
                cart_type:
                  this.props.posStore.cartType?.toLowerCase() === "kiosk"
                    ? "Kiosk"
                    : "POS",
                cart_total: fC(roundCents(this.props.posStore.total)),
                total_items: this.props.posStore.totalItems,
              });
            }}
          >
            {this.props.posStore.cartType == "return" ? "RETURN" : "PAY"}
          </ButtonComponent>
          {this.props.posStore.checkoutModal &&
          !this.props.posStore.useSplitTill ? (
            <Checkout handleClose={this.hideModal} />
          ) : null}
          {this.props.posStore.checkoutModal &&
          this.props.posStore.useSplitTill ? (
            <MultiTenderCheckout handleClose={this.hideModal} />
          ) : null}
        </div>

        {this.deleteModal ? (
          <DeleteCartModal
            cartId={Number(this.deleteModal)}
            onCancel={() => this.setDeleteModal(false)}
            onComplete={() => {
              this.props.posStore.newCart();
              this.setDeleteModal(false);
            }}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default Cart;
