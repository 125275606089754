import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ButtonComponent, Loader } from "../../components";
import CartNotes from "../../components/pos/CartNotes";
import ExportCartButton from "../../components/pos/ExportCartButton";
import {
  cartCsvHeaders,
  importerCompatibleCartCsvHeaders,
} from "../../constants/cart";
import { useFetchCartById } from "../../hooks/useFetchCartById";
import { StoreInfo } from "../../types/order";
import ReceiptOptionsContainer from "../../components/generic/ReceiptOptionsContainer";
import ActionButtonsLayout from "../../components/layout/ActionButtonsLayout";
import Panel from "../../components/layout/Panel";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";
import SubSectionHeaderLayout from "../../components/layout/SubSectionHeaderLayout";
import TableComponent from "../../components/table/TableComponent";
import { useFormatCurrency } from "../../hooks/storeHooks";
import useTitle from "../../hooks/useTitle";
import { segmentAnalytics } from "../../components/services/Analytics";
import {
  SAVE_STOCK_ADJUSTMENT_CSV_CLICKED,
  SAVE_SUBMITTED_CART_CSV_CLICKED,
  REPRINT_RECEIPT_CLICKED,
} from "../../constants/eventsTracked";
import {
  cartCustomerColumns,
  getCartItemsColumns,
  getCartTotalsColumns,
} from "./CartViewColumns";
import "./CartView.scss";

interface CartViewRouteParams {
  cartId: string;
}

interface CartViewProps {
  setSideMenu: () => void;
  posEnabled: boolean;
  storeInfo: StoreInfo;
}

function CartView({ setSideMenu, posEnabled, storeInfo }: CartViewProps) {
  const { cartId } = useParams<CartViewRouteParams>();
  const formatCurrency = useFormatCurrency();
  const [showReceiptModal, setShowReceiptModal] = useState<boolean>(false);
  const { data: orderData, isLoading: isCartLoading } = useFetchCartById(
    Number(cartId)
  );
  useTitle("Point of Sale");

  useEffect(() => {
    setSideMenu();
  }, []);

  if (!posEnabled) {
    return (
      <div>
        <p>Please contact BinderPOS to enable this screen.</p>
      </div>
    );
  }

  if (isCartLoading) {
    return <Loader />;
  }

  const { cart, selectedCustomer, customerSettings } = orderData;

  const initialValues = {
    storeInfo,
    selectedCustomer,
    customerSettings,
    cart,
  };

  return (
    <>
      {showReceiptModal && cart.id ? (
        <ReceiptOptionsContainer
          cartId={parseInt(`${cart.id}`, 10)}
          closeReceipt={() => setShowReceiptModal(false)}
          completedReceipt
          isModalOpen={showReceiptModal}
          title="Receipt Reprint"
          initialValues={initialValues}
        />
      ) : null}
      <SectionHeaderLayout title={`Cart #${cart.id}`}>
        <ActionButtonsLayout>
          <ButtonComponent
            primary
            onClick={() => {
              segmentAnalytics.track(REPRINT_RECEIPT_CLICKED);
              setShowReceiptModal(true);
            }}
          >
            Reprint Receipt
          </ButtonComponent>
          <ExportCartButton
            primary
            cartId={Number(cart.id)}
            headers={cartCsvHeaders}
            segmentEventName={SAVE_SUBMITTED_CART_CSV_CLICKED}
          >
            Save As CSV
          </ExportCartButton>
          <ExportCartButton
            primary
            cartId={Number(cart.id)}
            headers={importerCompatibleCartCsvHeaders}
            segmentEventName={SAVE_STOCK_ADJUSTMENT_CSV_CLICKED}
          >
            Save Stock Adjustment CSV
          </ExportCartButton>
        </ActionButtonsLayout>
      </SectionHeaderLayout>
      <TableComponent
        columns={cartCustomerColumns}
        data={[
          {
            customer: selectedCustomer,
            dateSubmitted: cart.dateSubmitted,
            orderNumber: cart.orderNumber,
          },
        ]}
      />
      <br />
      <SubSectionHeaderLayout title="Cart Tenders" />
      <Panel>
        {cart.tenders && cart.tenders.length > 0 ? (
          cart.tenders.map((tender) => (
            <p key={tender.type}>
              <em>{tender.type} :</em> {formatCurrency(tender.amount)}
            </p>
          ))
        ) : (
          <p>No tenders used</p>
        )}
      </Panel>
      <SubSectionHeaderLayout title="Cart Items" />
      <CartNotes rawNotes={cart.cartNotes} />
      <TableComponent
        columns={getCartItemsColumns(formatCurrency)}
        data={cart.cartItems}
      />
      <br />
      <SubSectionHeaderLayout title="Totals" />
      <TableComponent
        columns={getCartTotalsColumns(formatCurrency)}
        data={[cart]}
      />
    </>
  );
}

export default CartView;
