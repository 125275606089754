import React, { useState } from "react";
import ButtonComponent from "../generic/ButtonComponent";
import Modal from "../generic/Modal";

function TenderTypeSelect(
  { options = [], value = {}, setValue, onClose },
  ref
) {
  const [selected, setSelected] = useState(value);

  const handleClick = (event) => {
    const { name, checked } = event.target;
    setSelected({ ...selected, [name]: checked });
  };

  return (
    <Modal onClose={onClose} ref={ref}>
      <Modal.Header>Filter Tender Type</Modal.Header>
      <Modal.Content>
        {options.map((option, index) => (
          <div className="field" key={index}>
            <label className="checkbox">
              <input
                type="checkbox"
                name={option}
                checked={selected[option] || false}
                onChange={handleClick}
              />
              <span className="checkmark"></span>
              {option}
            </label>
          </div>
        ))}
      </Modal.Content>
      <Modal.Actions>
        <ButtonComponent
          primary
          onClick={() => {
            setValue(selected);
          }}
        >
          Update
        </ButtonComponent>
      </Modal.Actions>
    </Modal>
  );
}

export default React.forwardRef(TenderTypeSelect);
