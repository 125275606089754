// Plugin to append resolution contents to the WYSIWYG editor
export const attributeSelect = {
  // plugin name
  name: "atrributes",
  // @Required
  // data display
  display: "submenu",

  // @Options
  title: "Insert card attribute at cursor position",
  buttonClass: "se-btn se-btn-select se-tooltip",
  innerHTML:
    '<span class="txt">Insert Attribute&nbsp;</span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.73 8.67"><g><path d="M18.79,7.52a.8.8,0,0,1,.56-.23.82.82,0,0,1,.79.79.8.8,0,0,1-.23.56l-7.07,7.07a.79.79,0,0,1-.57.25.77.77,0,0,1-.57-.25h0L4.64,8.65a.8.8,0,0,1-.23-.57.82.82,0,0,1,.79-.79.8.8,0,0,1,.56.23L12.28,14l3.26-3.26,3.25-3.26Z" transform="translate(-4.41 -7.29)"></path></g></svg>',

  // @Required
  // add function - It is called only once when the plugin is first run.
  // This function generates HTML to append and register the event.
  // arguments - (core : core object, targetElement : clicked button element)
  add: function (core, targetElement) {
    // Generate submenu HTML
    // Always bind "core" when calling a plugin function
    let listDiv = this.setSubmenu(core);

    // You must bind "core" object when registering an event.
    /** add event listeners */
    listDiv
      .querySelector(".se-list-inner")
      .addEventListener("click", this.onClick.bind(core));

    // @Required
    // You must add the "submenu" element using the "core.initMenuTarget" method.
    /** append target button menu */
    core.initMenuTarget(this.name, targetElement, listDiv);

    /** empty memory */
    listDiv = null;
  },

  setSubmenu: function (core) {
    const { attributes } = core.options;
    const listDiv = core.util.createElement("DIV");
    // @Required
    // A "se-submenu" class is required for the top level element.
    listDiv.className = "se-submenu se-list-layer";
    let submenu =
      '<div class="se-list-inner se-list-font-size">' +
      '<ul class="se-list-basic"></ul>';
    attributes.forEach((attribute) => {
      submenu += `<li><button type="button" class="se-btn-list" value="{{${attribute.id}}}">${attribute.name}</button></li>`;
    });
    submenu += "</ul></div>";
    listDiv.innerHTML = submenu;

    return listDiv;
  },
  onClick: function (e) {
    const value = e.target.value;
    let node;
    if (/imageSrc/i.test(value)) {
      node = this.util.createElement("img");
      node.src = value;
      node.alt = value;
    } else {
      node = this.util.createElement("span");
      node.textContent = value;
    }
    this.insertNode(node);
    const zeroWidthSpace = this.util.createTextNode(this.util.zeroWidthSpace);
    node.parentNode.insertBefore(zeroWidthSpace, node.nextSibling);
    this.submenuOff();
  },
};
