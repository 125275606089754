import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table, Column, Cell } from "rsuite-table";
import "rsuite-table/dist/css/rsuite-table.css";
import "../../../css/rstable-overwrites.scss";
import { API_STATE } from "../../constants/api";
import { ReportsMenuStructure } from "../../menuStructures";
import { Pagination, ReportControls } from "../../components/reports";
import {
  CurrencyRenderer,
  TextInputFilter,
} from "../../components/reports/CellTypes";
import PropTypes from "prop-types";
import { useStoreDetails } from "../../hooks/storeHooks";
import { useShowError } from "../../hooks/errorHooks";
import { useReportsDetails } from "../../hooks/reportsHooks";
import SetActiveMenu from "../../menuStructures/SetActiveMenu";
import { rowPerPageOptions } from "../../constants/reports";
import { useFetchBuylistReport } from "../../hooks/reports/useFetchBuylistReport";
import { buylistCSVHeaders } from "../../constants/reportCSVHeaders";
import { addMultipleLocalizedDates } from "../../transformers/helpers/addLocalizedDate";
import CustomHeaderCell from "../../components/reports/CustomHeader";

function PaymentTypeSelect({ value, setValue }) {
  return (
    <select
      onChange={(event) => setValue(event.target.value)}
      value={value}
      aria-label="Filter by payment type"
    >
      <option value="">All</option>
      <option value="Cash">Cash</option>
      <option value="Credit">Store Credit</option>
    </select>
  );
}

function StatusSelect({ value, setValue }) {
  return (
    <select
      onChange={(event) => setValue(event.target.value)}
      value={value}
      aria-label="Filter by status"
    >
      <option value="">All</option>
      <option value="Pending">Pending</option>
      <option value="Approved">Approved</option>
      <option value="Completed">Completed</option>
      <option value="Declined">Declined</option>
    </select>
  );
}

function Buylist() {
  const {
    apiState,
    currentPage,
    filterValues,
    overrideTableHeight,
    rowsPerPage,
    sortColumn,
    tableData,
    computedTableData,
    reset,
    setApiState,
    setCurrentPage,
    setTableRowsPerPage,
    setFilters,
    setSortingColumn,
    setSourceDataDetails,
    updateFilterValue,
  } = useReportsDetails();
  const { currencyCode } = useStoreDetails();
  const showError = useShowError();
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [timeZone, setTimeZone] = useState<string>(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const {
    data: sourceData,
    isFetching,
    error,
  } = useFetchBuylistReport(
    {
      startDate,
      endDate,
    },
    {
      enabled: !!startDate && !!endDate,
    }
  );

  useEffect(() => {
    document.title = "Reports | BinderPOS";
    reset();
    setFilters(filters);
  }, []);

  useEffect(() => {
    if (isFetching) {
      setApiState(API_STATE.LOADING);
    } else if (error) {
      showError(
        error as any,
        "Failed to load report",
        "There was an error retrieving your buylist report. Please refresh and try again"
      );
      setApiState(API_STATE.ERROR);
    } else if (sourceData) {
      setSourceDataDetails(sourceData);
      setApiState(API_STATE.SUCCESS);
    }
  }, [isFetching, sourceData, error]);

  const filters = [
    {
      name: "buylist Id",
      condition: (filterValues) => filterValues?.buylistId?.length,
      filterFunction: (buylistDetail, filterValues) =>
        buylistDetail.buylistId &&
        String(buylistDetail.buylistId).includes(filterValues?.buylistId),
    },
    {
      name: "customer name",
      condition: (filterValues) => filterValues?.customerName?.length,
      filterFunction: (buylistDetail, filterValues) =>
        (buylistDetail.firstName &&
          buylistDetail.firstName
            .toLowerCase()
            .includes(filterValues?.customerName.toLowerCase())) ||
        (buylistDetail.lastName &&
          buylistDetail.lastName
            .toLowerCase()
            .includes(filterValues?.customerName.toLowerCase())),
    },
    {
      name: "customer email",
      condition: (filterValues) => filterValues?.customerEmail?.length,
      filterFunction: (buylistDetail, filterValues) =>
        buylistDetail.email &&
        buylistDetail.email
          .toLowerCase()
          .includes(filterValues?.customerEmail.toLowerCase()),
    },
    {
      name: "payment type",
      condition: (filterValues) => filterValues?.paymentType?.length,
      filterFunction: (buylistDetail, filterValues) =>
        buylistDetail.paymentType === filterValues?.paymentType,
    },
    {
      name: "status",
      condition: (filterValues) => filterValues?.status?.length,
      filterFunction: (buylistDetail, filterValues) =>
        buylistDetail.status === filterValues?.status,
    },
  ];

  const handleSetDateRange = (fromDate, toDate, tz) => {
    setStartDate(fromDate);
    setEndDate(toDate);
    setTimeZone(tz);
  };

  const {
    buylistId = "",
    customerName = "",
    customerEmail = "",
    paymentType = "",
    status = "",
  } = filterValues;

  const csvFilename = "Online Buylist Report.csv";

  const data = addMultipleLocalizedDates(computedTableData(), timeZone, [
    "dateSubmitted",
    "dateCompleted",
  ]);

  return (
    <Fragment>
      <SetActiveMenu menuStructure={ReportsMenuStructure} />
      <div className="app-header">
        <h1 className="title">
          <span className="header-text">Online Buylist Report</span>
        </h1>
      </div>
      <div className="report">
        <ReportControls
          setDateRange={handleSetDateRange}
          csvHeaders={buylistCSVHeaders}
          csvFilename={csvFilename}
        />
        {apiState !== API_STATE.INITIAL && (
          <div className="data-table">
            <div className="table-container">
              <Table
                data={data}
                headerHeight={80}
                loading={apiState == API_STATE.LOADING}
                sortColumn={sortColumn.key}
                sortType={sortColumn.order}
                onSortColumn={setSortingColumn}
                renderEmpty={() => <div>No data to display</div>}
                className="rs-table--multi-header"
                height={overrideTableHeight}
                fillHeight={overrideTableHeight === 0}
              >
                <Column key="col_buylistId" width={80} sortable resizable>
                  <CustomHeaderCell title="Buylist #">
                    <TextInputFilter
                      title="Buylist Id"
                      aria-label="Filter by Buylist ID"
                      value={buylistId}
                      setValue={(value) =>
                        updateFilterValue("buylistId", value)
                      }
                    />
                  </CustomHeaderCell>
                  <Cell dataKey="buylistId">
                    {(rowData) => (
                      <Link
                        className="viewBuylist"
                        to={`/buylists/pending/moreDetail/${rowData.buylistId}`}
                      >
                        {rowData.buylistId}
                      </Link>
                    )}
                  </Cell>
                </Column>
                <Column key="col_customerName" width={250} sortable resizable>
                  <CustomHeaderCell title="Customer Name">
                    <TextInputFilter
                      title="Customer Name"
                      aria-label="Filter by customer name"
                      value={customerName}
                      setValue={(value) =>
                        updateFilterValue("customerName", value)
                      }
                    />
                  </CustomHeaderCell>
                  <Cell dataKey="customerName" />
                </Column>
                <Column key="col_customerEmail" width={250} sortable resizable>
                  <CustomHeaderCell title="Customer Email">
                    <TextInputFilter
                      title="Customer Email"
                      aria-label="Filter by customer email"
                      value={customerEmail}
                      setValue={(value) =>
                        updateFilterValue("customerEmail", value)
                      }
                    />
                  </CustomHeaderCell>
                  <Cell dataKey="email" />
                </Column>
                <Column key="col_paymentType" width={130} sortable resizable>
                  <CustomHeaderCell title="Payment Type">
                    <PaymentTypeSelect
                      value={paymentType}
                      setValue={(value) =>
                        updateFilterValue("paymentType", value)
                      }
                    />
                  </CustomHeaderCell>
                  <Cell dataKey="paymentType" />
                </Column>
                <Column key="col_totalItems" width={100} sortable resizable>
                  <CustomHeaderCell title="Total Items" />
                  <Cell dataKey="totalItems" />
                </Column>
                <Column
                  key="col_totalBuyPrice"
                  width={120}
                  align="right"
                  sortable
                  resizable
                >
                  <CustomHeaderCell title="Total Buy Price" />
                  <Cell dataKey="totalBuyPrice">
                    {(rowData) =>
                      CurrencyRenderer({
                        cellData: rowData.totalBuyPrice,
                        currency: currencyCode,
                        style: { paddingRight: "8px" },
                      })
                    }
                  </Cell>
                </Column>
                <Column
                  key="col_status"
                  width={100}
                  align="left"
                  sortable
                  resizable
                >
                  <CustomHeaderCell title="Status">
                    <StatusSelect
                      value={status}
                      setValue={(value) => updateFilterValue("status", value)}
                    />
                  </CustomHeaderCell>
                  <Cell dataKey="status" />
                </Column>
                <Column
                  key="col_dateSubmitted"
                  width={150}
                  align="left"
                  sortable
                  resizable
                >
                  <CustomHeaderCell title="Date Submitted" />
                  <Cell dataKey="dateSubmitted">
                    {(rowData) => rowData.dateSubmitted_localized}
                  </Cell>
                </Column>
                <Column
                  key="col_dateCompleted"
                  width={150}
                  align="left"
                  sortable
                  resizable
                >
                  <CustomHeaderCell title="Date Completed" />
                  <Cell dataKey="dateCompleted">
                    {(rowData) => rowData.dateCompleted_localized}
                  </Cell>
                </Column>
              </Table>
            </div>
            <Pagination
              apiState={apiState}
              currentPage={currentPage}
              totalRowCount={tableData.length}
              rowsPerPage={rowsPerPage}
              rowPerPageOptions={rowPerPageOptions}
              setCurrentPage={setCurrentPage}
              setRowsPerPage={setTableRowsPerPage}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
}

Buylist.propTypes = { store: PropTypes.object };
export default Buylist;
