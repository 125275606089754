import React from "react";
import ButtonComponent from "../../components/generic/ButtonComponent";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";
import PreviousImports from "../../components/product/PreviousImports";
import UploadCsv from "../../components/product/UploadCsv";

function ImportData() {
  return (
    <>
      <SectionHeaderLayout title="Import Product Data">
        <ButtonComponent
          primary
          isLink
          to="/products"
          icon="fas fa-caret-left"
          iconPosition="left"
        >
          Back to Products
        </ButtonComponent>
      </SectionHeaderLayout>
      <UploadCsv />
      <PreviousImports />
    </>
  );
}

export default ImportData;
