import React, { useMemo, useState } from "react";
import {
  EVENT_FILTER_CLICKED,
  MORE_FILTERS_ADDED,
  RESET_FILTER_CLICKED,
} from "../../constants/eventsTracked";
import { debounce as functionDebounce } from "../../helpers/functionHelpers";
import {
  useFetchEventGamesForClient,
  useFetchEventTypesForClient,
} from "../../hooks/eventHooks";
import { useStoreDetails } from "../../hooks/storeHooks";
import ButtonComponent from "../generic/ButtonComponent";
import Panel from "../layout/Panel";
import { segmentAnalytics } from "../services/Analytics";
import { joinClasses } from "../../utils/styling";
import "./EventsFilter.scss";

const TRACKING_DEBOUNCE_TIMEOUT = 2000;

export type FilterValue = string | number | boolean;

const trackFilterChanges = (
  value: FilterValue,
  eventFilters: Record<string, FilterValue>
) => {
  if (value) {
    segmentAnalytics.track(MORE_FILTERS_ADDED, {
      event_title: eventFilters.title,
      between_dates: {
        start_date: eventFilters.startDate,
        end_date: eventFilters.endDate,
      },
      game: eventFilters.gameType,
      event_type: eventFilters.eventType,
      ticketed: eventFilters.ticketed,
      between_prices: {
        from: eventFilters.lowPrice,
        to: eventFilters.highPrice,
      },
    });
  }
};

interface EventsFilterProps {
  eventFilters: Record<string, FilterValue>;
  updateEventFilter: (parameter: string, value: FilterValue) => void;
  resetEventFilters: () => void;
}

function EventsFilter(props: EventsFilterProps) {
  const { eventFilters, resetEventFilters, updateEventFilter } = props;
  const [showFilters, setShowFilters] = useState(false);
  const [era, setEra] = useState<string>("all");
  const { currencySymbol } = useStoreDetails();
  const { data: eventGames = [] } = useFetchEventGamesForClient();
  const { data: eventTypes = [] } = useFetchEventTypesForClient();

  const debouncedTrackFilterChanges = useMemo(
    () => functionDebounce(trackFilterChanges, TRACKING_DEBOUNCE_TIMEOUT),
    [trackFilterChanges]
  );

  const onEraChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    segmentAnalytics.track(EVENT_FILTER_CLICKED, { event_filter: value });
    setEra(value);
    const now = new Date().toISOString().substring(0, 10);
    switch (value) {
      case "past":
        updateEventFilter("startDate", null);
        updateEventFilter("endDate", now);
        break;
      case "upcoming":
        updateEventFilter("startDate", now);
        updateEventFilter("endDate", null);
        break;
      case "all":
      default:
        updateEventFilter("startDate", null);
        updateEventFilter("endDate", null);
    }
    updateEventFilter("offset", 0);
  };

  const updateFilter = (parameter: string, value: FilterValue) => {
    if (value === "") {
      value = null;
    }
    updateEventFilter("offset", 0);
    updateEventFilter(parameter, value);
    debouncedTrackFilterChanges(value, eventFilters);
  };

  const handleResetFilters = () => {
    resetEventFilters();
    setEra("all");
  };

  return (
    <div className="EventsFilter">
      <form>
        <div className="EventsFilter__controls">
          <div className="EventsFilter__eraSelect">
            <div className="EventsFilter__eraOption">
              <input
                type="radio"
                name="upcomingFilter"
                id="allEvents"
                value="all"
                checked={era === "all"}
                onChange={onEraChange}
              />
              <label htmlFor="allEvents">All Events</label>
            </div>
            <div className="EventsFilter__eraOption">
              <input
                type="radio"
                name="upcomingFilter"
                id="upcomingEvents"
                value="upcoming"
                checked={era === "upcoming"}
                onChange={onEraChange}
              />
              <label htmlFor="upcomingEvents">Upcoming Events</label>
            </div>
            <div className="EventsFilter__eraOption">
              <input
                type="radio"
                name="upcomingFilter"
                id="pastEvents"
                value="past"
                checked={era === "past"}
                onChange={onEraChange}
              />
              <label htmlFor="pastEvents">Past Events</label>
            </div>
          </div>
          <ButtonComponent
            primary
            onClick={() => setShowFilters((prev) => !prev)}
            icon={showFilters ? "fas fa-caret-up" : "fas fa-caret-down"}
          >
            {showFilters ? "Hide Filters" : "More Filters"}
          </ButtonComponent>
        </div>
        <Panel
          className={joinClasses([
            "EventsFilter__filterPanel",
            showFilters ? "EventsFilter__filterPanel--visible" : null,
          ])}
        >
          <ButtonComponent
            secondary
            className="EventsFilter__resetFiltersButton"
            onClick={() => {
              segmentAnalytics.track(RESET_FILTER_CLICKED);
              handleResetFilters();
            }}
          >
            Reset Filters
          </ButtonComponent>
          <div className="EventsFilter__filters">
            <label htmlFor="title" className="label">
              Event Title
            </label>
            <input
              className="input"
              type="text"
              onChange={(event) => updateFilter("title", event.target.value)}
              value={eventFilters.title ? String(eventFilters.title) : ""}
              id="title"
            />
            <label htmlFor="start-date" className="label">
              Between dates
            </label>
            <span>
              <input
                className="input"
                type="date"
                value={
                  eventFilters.startDate ? String(eventFilters.startDate) : ""
                }
                onChange={(event) =>
                  updateFilter("startDate", event.target.value)
                }
                id="start-date"
              />
              <label
                htmlFor="end-date"
                className="label"
                style={{ marginLeft: "4px", marginRight: "4px" }}
              >
                and
              </label>
              <input
                id="end-date"
                className="input"
                data-testid="end-date"
                type="date"
                value={eventFilters.endDate ? String(eventFilters.endDate) : ""}
                onChange={(event) =>
                  updateFilter("endDate", event.target.value)
                }
              />
            </span>
            <label htmlFor="games" className="label">
              Game
            </label>
            <select
              className="input"
              onChange={(event) => updateFilter("gameType", event.target.value)}
              value={eventFilters.gameType ? String(eventFilters.gameType) : ""}
              id="games"
            >
              <option value="">- All -</option>
              {eventGames.map((game, index) => (
                <option key={index} value={game}>
                  {game}
                </option>
              ))}
            </select>
            <label htmlFor="types" className="label">
              Event type
            </label>
            <select
              className="input"
              onChange={(event) =>
                updateFilter("eventType", event.target.value)
              }
              value={
                eventFilters.eventType ? String(eventFilters.eventType) : ""
              }
              id="types"
            >
              <option value="">- All -</option>
              {eventTypes.map((type, index) => (
                <option key={index} value={type}>
                  {type}
                </option>
              ))}
            </select>
            <label className="label" htmlFor="ticketed">
              Ticketed
            </label>
            <div>
              <input
                className="EventsFilter__ticketedInput"
                type="checkbox"
                onChange={(event) =>
                  updateFilter("ticketed", event.target.checked)
                }
                checked={
                  eventFilters.ticketed ? Boolean(eventFilters.ticketed) : false
                }
                id="ticketed"
              />
            </div>
            <label htmlFor="low-price" className="label">
              Between prices
            </label>
            <span className="label">
              {currencySymbol || "$"}
              <input
                id="low-price"
                className="input"
                type="number"
                min={0}
                disabled={!eventFilters.ticketed}
                value={
                  eventFilters.lowPrice ? Number(eventFilters.lowPrice) : ""
                }
                onChange={(event) =>
                  updateFilter("lowPrice", event.target.value)
                }
              />
              <label
                style={{ marginLeft: "4px", marginRight: "4px" }}
                htmlFor="high-price"
                className="label"
              >
                and
              </label>
              <span className="label">
                {currencySymbol || "$"}
                <input
                  data-testid="high-price"
                  className="input"
                  id="high-price"
                  type="number"
                  min={0}
                  disabled={!eventFilters.ticketed}
                  value={
                    eventFilters.highPrice ? Number(eventFilters.highPrice) : ""
                  }
                  onChange={(event) =>
                    updateFilter("highPrice", event.target.value)
                  }
                />
              </span>
            </span>
          </div>
        </Panel>
      </form>
    </div>
  );
}

export default EventsFilter;
