import React, { useEffect, useMemo, useState } from "react";
import { Table, Column, Cell } from "rsuite-table";
import "rsuite-table/dist/css/rsuite-table.css";
import "../../../css/rstable-overwrites.scss";
import CustomHeaderCell from "../../components/reports/CustomHeader";
import { ButtonComponent, Paging } from "../../components";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";
import ExportCartButton from "../../components/pos/ExportCartButton";
import { TillSelect } from "../../components/posSettings";
import { DateRangeSelect } from "../../components/reports";
import {
  NumberInputFilter,
  TextInputFilter,
} from "../../components/reports/CellTypes";
import { cartCsvHeaders } from "../../constants/cart";
import { DEFAULT_SUBMITTED_CARTS_PER_PAGE } from "../../constants/pos";
import { useDebounce } from "../../hooks/inputHooks";
import {
  CartListParams,
  useFetchSubmittedCarts,
} from "../../hooks/useFetchSubmittedCarts";
import useTitle from "../../hooks/useTitle";
import { TimeUtils } from "../../utils";
import { debounce as functionDebounce } from "../../helpers/functionHelpers";
import { segmentAnalytics } from "../../components/services/Analytics";
import {
  REPORT_FILTER_APPLIED,
  VIEW_SUBMITTED_CART,
} from "../../constants/eventsTracked";
import { reportNames } from "../../constants/reports";

const DEBOUNCE_TIMEOUT = 250;
const TRACKING_DEBOUNCE_TIMEOUT = 2000;

const trackFilterChanges = (
  value: string | number,
  cartParams: CartListParams
) => {
  if (value) {
    segmentAnalytics.track(REPORT_FILTER_APPLIED, {
      report_type: reportNames.POS_SUBMITTED_CARTS,
      cart_number: cartParams.cartId,
      shopify_number: cartParams.shopifyOrderId,
      till: cartParams.tillId,
      first_name: cartParams.firstName,
      last_name: cartParams.lastName,
    });
  }
};

interface CartListProps {
  setSideMenu: () => void;
  posEnabled: boolean;
}

function CartList({ setSideMenu, posEnabled }: CartListProps) {
  const [cartParams, setCartParams] = useState<CartListParams>({
    offset: 0,
    orderBy: "dateSubmitted",
    desc: true,
    oldestFirst: false,
  });
  const [tableSort, setTableSort] = useState<{
    sortKey?: string;
    sortOrder?: "asc" | "desc";
  }>({ sortKey: "dateSubmitted", sortOrder: "desc" });
  const debouncedCartParams = useDebounce(cartParams, DEBOUNCE_TIMEOUT);
  useTitle("Point of Sale Submitted Carts");

  const debouncedTrackFilterChanges = useMemo(
    () => functionDebounce(trackFilterChanges, TRACKING_DEBOUNCE_TIMEOUT),
    [trackFilterChanges]
  );

  useEffect(() => {
    setSideMenu();
  }, []);

  const { data: carts, isFetching } =
    useFetchSubmittedCarts(debouncedCartParams);

  const prevPage = () => {
    setCartParams((prev) => {
      let newOffset = prev.offset - DEFAULT_SUBMITTED_CARTS_PER_PAGE;
      if (newOffset < 0) {
        newOffset = 0;
      }
      return {
        ...prev,
        offset: newOffset,
      };
    });
  };

  const nextPage = () => {
    setCartParams((prev) => {
      const newOffset = prev.offset + DEFAULT_SUBMITTED_CARTS_PER_PAGE;

      return {
        ...prev,
        offset: newOffset,
      };
    });
  };

  const handleDateChange = (startDate, toDate, timeZone) => {
    setCartParams((prev) => ({
      ...prev,
      startDate,
      toDate,
      timeZone,
    }));
  };

  const onCartFilterChange = (
    parameter: keyof CartListParams,
    value: string | number
  ) => {
    debouncedTrackFilterChanges(value, cartParams);
    setCartParams((prev) => ({
      ...prev,
      [parameter]: !value || value === "" ? undefined : value,
    }));
  };

  const setSortColumn = (key, order) => {
    setTableSort({
      sortKey: key,
      sortOrder: order,
    });
    setCartParams((prev) => ({
      ...prev,
      orderBy: key,
      desc: order === "desc",
      offset: 0,
    }));
  };

  if (!posEnabled) {
    return (
      <div>
        <p>Please contact BinderPOS to enable this screen.</p>
      </div>
    );
  }

  return (
    <>
      <SectionHeaderLayout title="Submitted Carts" />
      <div className="table-controls report">
        <div className="report-buttons">
          <DateRangeSelect
            setDateRange={handleDateChange}
            defaultPreset="p30d"
            reportType={reportNames.POS_SUBMITTED_CARTS}
          />
        </div>
        <Paging
          pageOffset={cartParams.offset}
          currentPageItemCount={carts && carts.length}
          maxItemsPerPage={1}
          getPrevPage={prevPage}
          getNextPage={nextPage}
        />
      </div>
      <div
        className="data-table"
        style={{
          height: `${carts ? carts.length * 50 + 80 : 0}px`,
        }}
      >
        <Table
          data={carts}
          headerHeight={80}
          loading={isFetching}
          sortColumn={tableSort.sortKey}
          sortType={tableSort.sortOrder}
          onSortColumn={setSortColumn}
          renderEmpty={() => <div>No data to display</div>}
          className="rs-table--multi-header"
          fillHeight
          rowClassName={(rowData) => {
            if (!rowData) return;
            return rowData?.ShopifyCustomer ? "registered-customer" : "guest";
          }}
        >
          <Column key="id" width={110} sortable resizable>
            <CustomHeaderCell title="Cart #">
              <NumberInputFilter
                title="Filter by ID"
                aria-label="Filter by ID"
                value={cartParams.cartId}
                setValue={(value) => onCartFilterChange("cartId", value)}
              />
            </CustomHeaderCell>
            <Cell dataKey="id" />
          </Column>
          <Column key="shopifyOrderId" width={140} sortable resizable>
            <CustomHeaderCell title="Shopify Order #">
              <NumberInputFilter
                title="Filter by order ID"
                aria-label="Filter by order ID"
                value={cartParams.shopifyOrderId}
                setValue={(value) =>
                  onCartFilterChange("shopifyOrderId", value)
                }
              />
            </CustomHeaderCell>
            <Cell dataKey="shopifyOrderId" />
          </Column>
          <Column key="dateSubmitted" width={160} sortable resizable>
            <CustomHeaderCell title="Date Submitted" />
            <Cell dataKey="dateSubmitted">
              {(rowData) =>
                TimeUtils.convertDateToHumanReadable(
                  rowData.dateSubmitted,
                  cartParams.timeZone
                )
              }
            </Cell>
          </Column>
          <Column key="tillName" width={160} sortable resizable>
            <CustomHeaderCell title="Till">
              <TillSelect
                value={cartParams.tillId}
                setValue={(value) => onCartFilterChange("tillId", value)}
                title="Filter by till"
                allowAll
              />
            </CustomHeaderCell>
            <Cell dataKey="till.name">{(rowData) => rowData?.till?.name}</Cell>
          </Column>
          <Column key="firstName" width={150} sortable resizable>
            <CustomHeaderCell title="First Name">
              <TextInputFilter
                title="Filter by first name"
                aria-label="Filter by first name"
                value={cartParams.firstName}
                setValue={(value) => onCartFilterChange("firstName", value)}
              />
            </CustomHeaderCell>
            <Cell dataKey="firstName">
              {(rowData) => rowData?.ShopifyCustomer?.firstName || "Guest"}
            </Cell>
          </Column>
          <Column key="lastName" width={150} sortable resizable>
            <CustomHeaderCell title="Last Name">
              <TextInputFilter
                title="Filter by last name"
                aria-label="Filter by last name"
                value={cartParams.lastName}
                setValue={(value) => onCartFilterChange("lastName", value)}
              />
            </CustomHeaderCell>
            <Cell dataKey="lastName">
              {(rowData) => rowData?.ShopifyCustomer?.lastName}
            </Cell>
          </Column>
          <Column key="processedBy" width={180} sortable resizable>
            <CustomHeaderCell title="Completed By" />
            <Cell dataKey="submittedBy" />
          </Column>
          <Column key="view" width={100} align="right">
            <CustomHeaderCell title="" srOnlyTitle="View cart" />
            <Cell dataKey="id">
              {(rowData) => (
                <ButtonComponent
                  primary
                  compact
                  isLink
                  to={`/pointOfSale/carts/${rowData.id}`}
                  onClick={() => segmentAnalytics.track(VIEW_SUBMITTED_CART)}
                  icon="fal fa-eye"
                  iconPosition="left"
                >
                  View
                </ButtonComponent>
              )}
            </Cell>
          </Column>
          <Column key="csv" width={100} align="right">
            <CustomHeaderCell title="" srOnlyTitle="Download cart CSV" />
            <Cell dataKey="id">
              {(rowData) => (
                <ExportCartButton
                  primary
                  compact
                  cartId={Number(rowData?.id)}
                  headers={cartCsvHeaders}
                  icon="fal fa-download"
                  iconPosition="left"
                >
                  CSV
                </ExportCartButton>
              )}
            </Cell>
          </Column>
        </Table>
      </div>
      {carts && carts.length > 10 ? (
        <Paging
          pageOffset={cartParams.offset}
          currentPageItemCount={carts && carts.length}
          maxItemsPerPage={1}
          getPrevPage={prevPage}
          getNextPage={nextPage}
        />
      ) : null}
    </>
  );
}

export default CartList;
