import React, { useState } from "react";
import { Field } from "formik";
import { EventInfo } from "../../types/events";
import Input from "../form/Input";
import MultiSelect from "../form/MultiSelect";
import RadioButton from "../form/RadioButton";
import Select from "../form/Select";
import { daysArray } from "../../constants/misc";
import "./EventRepetition.scss";

interface EventRepetitionProps {
  values: EventInfo;
  setFieldValue: SetFieldValue;
}

function EventRepetition(props: EventRepetitionProps) {
  const { values, setFieldValue } = props;
  const [recurringEnds, setRecurringEnds] = useState(
    values.recurringEndDate?.length ? "onDate" : "never"
  );
  const [monthlyRepeat, setMonthlyRepeat] = useState(
    values.monthlyRecurringType?.length > 0 ? "day" : "date"
  );

  if (values.id || !values.recurring) {
    return null;
  }

  return (
    <div className="EventRepetition Event__formSection">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-xl-4">
            <label className="label">Repeat every</label>
            <div className="Event__recurringFrequency">
              <Field
                component={Input}
                name="recurringFrequency"
                type="number"
                max="52"
                min="1"
              />
              <Field
                className="Event__select"
                component={Select}
                name="recurringType"
              >
                <option value="">-- Select frequency --</option>
                <option value="day">days</option>
                <option value="week">weeks</option>
                <option value="month">months</option>
                <option value="year">years</option>
              </Field>
            </div>
          </div>
          <div className="col-lg-12 col-xl-4">
            {values.recurringType === "week" ? (
              <div className="container-fluid row">
                <label className="label">Repeat on</label>
                <MultiSelect
                  availableOptions={daysArray}
                  selectedOptions={(values.recurringDays || "").split(",")}
                  setSelectedOptions={(options) =>
                    setFieldValue("recurringDays", options.join(","))
                  }
                />
              </div>
            ) : null}
            {values.recurringType === "month" ? (
              <div className="row">
                <div className="col-lg-12">
                  <label className="label">Repeat on</label>
                </div>
                <div className="col-sm-6 col-xl-6">
                  <Field
                    component={RadioButton}
                    name="monthly"
                    value="date"
                    label="date"
                    checked={monthlyRepeat === "date"}
                    onChange={() => {
                      setMonthlyRepeat("date");
                      setFieldValue("monthlyRecurringType", "");
                      setFieldValue("monthlyRecurringDay", "");
                    }}
                  />
                </div>
                <div className="col-sm-6 col-xl-6">
                  <Field
                    component={RadioButton}
                    name="monthly"
                    value="day"
                    label="weekday"
                    checked={monthlyRepeat === "day"}
                    onChange={() => {
                      setMonthlyRepeat("day");
                      setFieldValue("monthlyRecurringDayNumber", "");
                    }}
                  />
                </div>
                {monthlyRepeat === "date" ? (
                  <div className="col-sm-12 col-xl-12">
                    <Field
                      label="Day of month"
                      component={Input}
                      name="monthlyRecurringDayNumber"
                      type="number"
                      min={1}
                      max={31}
                      step={1}
                      placeholder="1"
                    />
                  </div>
                ) : (
                  <div className="col-sm-12 col-xl-12">
                    <div className="EventRepetition__group">
                      <label className="label">The</label>
                      <Field component={Select} name="monthlyRecurringType">
                        <option value="" disabled>
                          --
                        </option>
                        <option value="1st">1st</option>
                        <option value="2nd">2nd</option>
                        <option value="3rd">3rd</option>
                        <option value="4th">4th</option>
                        <option value="last">last</option>
                      </Field>
                      <Field component={Select} name="monthlyRecurringDay">
                        <option value="" disabled>
                          Day
                        </option>
                        {daysArray.map((day) => (
                          <option key={day} value={day}>
                            {day}
                          </option>
                        ))}
                      </Field>
                      <label className="label">of each month</label>
                    </div>
                  </div>
                )}
              </div>
            ) : null}
          </div>
          <div className="col-sm-12 col-xl-4">
            <div className="row">
              <div className="col-lg-12">
                <label className="label">Ends</label>
              </div>
              <div className="col-sm-6 col-xl-6">
                <Field
                  component={RadioButton}
                  name="ends"
                  value="never"
                  label="Never"
                  checked={recurringEnds === "never"}
                  onChange={() => {
                    setRecurringEnds("never");
                    setFieldValue("recurringEndDate", "");
                  }}
                />
              </div>
              <div className="col-sm-6 col-xl-6">
                <Field
                  component={RadioButton}
                  name="ends"
                  value="onDate"
                  label="On date"
                  checked={recurringEnds === "onDate"}
                  onChange={() => setRecurringEnds("onDate")}
                />
              </div>
              <div className="col-sm-12 col-xl-12">
                <Field
                  label="End date"
                  component={Input}
                  name="recurringEndDate"
                  type="date"
                  placeholder="E.g. 18/07/2018"
                  disabled={recurringEnds === "never"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventRepetition;
