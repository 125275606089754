import React from "react";
import { User } from "../../types/user";
import { notify } from "../../helpers/notificationHelpers";
import { useEnableUser, useDisableUser } from "../../hooks/userHooks";
import { useShowError } from "../../hooks/errorHooks";
import IconButton from "../generic/IconButton";

interface EnableButtonProps {
  user: User;
}

function EnableButton(props: EnableButtonProps) {
  const { user } = props;
  const { mutateAsync: enableUser, isMutating: isEnabling } = useEnableUser();
  const { mutateAsync: disableUser, isMutating: isDisabling } =
    useDisableUser();
  const showError = useShowError();

  const handleDisableClick = () =>
    disableUser(user.id)
      .then(() => notify.info("User disabled"))
      .catch((error) => showError(error));

  const handleEnableClick = () =>
    enableUser(user.id)
      .then(() => notify.info("User enabled"))
      .catch((error) => showError(error));

  if (user.isMe) {
    return null;
  }

  if (user.enabled) {
    return (
      <IconButton
        icon="fal fa-lock-alt"
        title="Disable this user"
        className="userActions"
        onClick={handleDisableClick}
        disabled={isDisabling}
      />
    );
  }

  return (
    <IconButton
      icon="fal fa-lock-open-alt"
      className="userActions"
      title="Enable this user"
      onClick={handleEnableClick}
      disabled={isEnabling}
    />
  );
}

export default EnableButton;
