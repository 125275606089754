import React, { useRef } from "react";
import { Transition } from "react-transition-group";
import { joinClasses } from "../../utils/styling";
import "./Drawer.scss";

interface DrawerProps {
  drawerPosition?: "left" | "right" | "top" | "bottom";
  drawerOpen: boolean;
  children: React.ReactElement;
}

function Drawer({
  drawerPosition = "left",
  drawerOpen,
  children,
}: DrawerProps) {
  const nodeRef = useRef<HTMLDivElement>();

  return (
    <Transition
      in={drawerOpen}
      appear={true}
      mountOnEnter
      nodeRef={nodeRef}
      timeout={500}
    >
      {(state) => {
        const drawerClasses = joinClasses([
          "Drawer",
          `Drawer--${drawerPosition}`,
          `Drawer--${state}`,
        ]);
        return (
          <div ref={nodeRef} className={drawerClasses}>
            <div className="Drawer__content">{children}</div>
          </div>
        );
      }}
    </Transition>
  );
}

export default Drawer;
