import React, { useEffect } from "react";
import { useGetInstalledShopifyThemeVersionQuery } from "../../generated/graphql";
import SubSectionHeaderLayout from "../layout/SubSectionHeaderLayout";
import { TimeUtils } from "../../utils";

const POLL_INTERVAL = 60_000;

interface CurrentThemeVersionProps {
  theme: string;
}

function CurrentThemeVersion(props: CurrentThemeVersionProps) {
  const { theme } = props;
  const { isLoading, data, error, refetch } =
    useGetInstalledShopifyThemeVersionQuery({
      where: {
        theme: {
          equals: theme,
        },
      },
    });
  let pollTheme: NodeJS.Timer;

  useEffect(() => {
    if (theme) {
      clearInterval(pollTheme);
      pollTheme = setInterval(refetch, POLL_INTERVAL);
      return () => {
        clearInterval(pollTheme);
      };
    }
  }, [theme]);

  if (isLoading) {
    return null;
  }

  if (error) {
    return (
      <div className="CurrentThemeVersion">
        <SubSectionHeaderLayout title="Current Version" />
        <p>
          Failed to load details about your deployed theme. Please refresh and
          try again.
        </p>
      </div>
    );
  }

  return (
    <div className="CurrentThemeVersion">
      <SubSectionHeaderLayout title="Current Version" />
      <p>
        The version of the theme you currently have installed was released at{" "}
        {TimeUtils.convertDateToHumanReadable(
          data.BinderCustomerShopifyTheme?.[0]?.ShopifyThemeRelease?.releaseDate
        )}
      </p>
    </div>
  );
}

export default CurrentThemeVersion;
