import { action } from "mobx";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { ButtonComponent, Loader } from "..";
import Modal from "../generic/Modal";
import CreditProcess from "./CreditProcess";
import { disableFinalizeSale } from "../../utils/pos/pos";
import "./Checkout.scss";
import { ItemList } from "../../pos/ItemStore";

interface CheckoutProps {
  posStore?: ItemList;
  handleClose: () => void;
}
@inject("posStore")
@observer
class Checkout extends Component<CheckoutProps> {
  myInp: HTMLInputElement;

  @action tenderUpdate = (e, index) => {
    if (this.props.posStore.tenders.length <= index) {
      this.props.posStore.tenders.length = index + 1;
      this.props.posStore.tenders[index].amount = "0.00";
    }
    if (isNaN(e.target.value) || e.target.value == "") {
      this.props.posStore.tenders[index].amount = e.target.value.replace(
        /[^0-9.-]/g,
        ""
      );
      if (e.target.value == "" || e.target.value == ".") {
        this.props.posStore.tenders[index].amount = "0.00";
      }
    } else {
      this.props.posStore.tenders[index].amount = e.target.value;
    }
    this.verifyAmounts();
  };

  @action verifyAmounts = () => {
    this.props.posStore.tenders.forEach((tender, index) => {
      if (isNaN(Number(tender.amount)) || tender.amount == "") {
        if (tender.amount) {
          this.props.posStore.tenders[index].amount = tender.amount.replace(
            /[^0-9.-]/g,
            ""
          );
        }
        if (tender.amount == "" || tender.amount == ".") {
          this.props.posStore.tenders[index].amount = "0.00";
        }
      } else {
        this.props.posStore.tenders[index].amount = tender.amount;
      }
    });
  };

  @action selectAll = (e, name) => {
    if (this.props.posStore.tenders.length <= name) {
      this.props.posStore.tenders.length = name + 1;
      this.props.posStore.tenders[name].amount = "0.00";
    }
    this.props.posStore.tenders[name].amount = (
      parseFloat(this.props.posStore.tenders[name].amount) +
      parseFloat(this.props.posStore.balance)
    ).toFixed(2);

    if (
      this.props.posStore.tenders[name].type == "Store Credit" &&
      this.props.posStore.tenders[name].amount >
        this.props.posStore?.selectedCustomer?.storeCredit
    ) {
      this.props.posStore.tenders[name].amount =
        this.props.posStore.selectedCustomer.storeCredit;
    }
    e.target.select();
    this.verifyAmounts();
  };

  @action setTender = (e, index) => {
    this.props.posStore.activeTender = index;
    if (this.props.posStore.tenders.length <= index) {
      this.props.posStore.tenders.length = index + 1;
      this.props.posStore.tenders[index].amount = "0.00";
    }
    if (this.props.posStore.tenders[index].amount == "") {
      this.props.posStore.tenders[index].amount = "0.00";
    }
    this.myInp.focus();
    this.myInp.select();
  };

  @action quickCash = (e, amount) => {
    if (this.props.posStore.tenders.length === 0)
      this.props.posStore.tenders = [{ amount: "0.00", type: "Cash" }];
    this.props.posStore.tenders[0].amount = (
      parseFloat(this.props.posStore.tenders[0].amount) + amount
    ).toFixed(2);
  };

  canPayByCreditCard = () => {
    if (
      this.props.posStore.paymentTerminal?.paymentProvider?.match(
        /USAePay|mxMerchant/i
      )
    ) {
      return true;
    }
    return Number(this.props.posStore.balance) > 0;
  };

  render() {
    const store = this.props.posStore;
    const fC = this.props.posStore.fCurr;

    return (
      <>
        <Modal onClose={this.props.handleClose} small>
          <Modal.Header>Checkout</Modal.Header>
          <Modal.Content>
            {store.submittingCart ? (
              <Loader text="Submitting cart..." />
            ) : (
              <div className="Checkout">
                <button
                  className="Checkout__reset-button"
                  onClick={store.resetTenders}
                >
                  <i className="far fa-redo"></i> Reset
                </button>
                <div className="Checkout__balance">
                  Balance to Pay:<span>{store.balance}</span>
                </div>
                <div className="Checkout__tendered">
                  Amount tendered ({this.props.posStore.currency}){" "}
                  <input
                    data-testid="tender-amount-input"
                    className="Checkout__total-tendered"
                    value={
                      store.tenders.length > store.activeTender
                        ? store.tenders[store.activeTender].amount
                        : 0.0
                    }
                    onChange={(e) => this.tenderUpdate(e, store.activeTender)}
                    //onBlur={e => this.verifyAmounts()}
                    onFocus={(e) => this.selectAll(e, store.activeTender)}
                    ref={(ip) => (this.myInp = ip)}
                    type="textbox"
                  />
                </div>
                <div>
                  <div className="Checkout__tenders">
                    {store.tenders.map((tender, index) => (
                      <div className="Checkout__tender" key={tender.type}>
                        <input
                          className="Checkout__tender-input"
                          value={
                            store.tenders.length > index
                              ? store.tenders[index].amount
                              : null
                          }
                          onFocus={(e) => e.target.select()}
                          onChange={(e) => this.tenderUpdate(e, index)}
                          disabled={
                            !store.selectedCustomer &&
                            tender.type == "Store Credit"
                          }
                        ></input>
                        <button
                          className="Checkout__payment-button"
                          onClick={(e) => {
                            this.setTender(e, index);
                          }}
                          key={index}
                          disabled={
                            (!store.selectedCustomer &&
                              tender.type == "Store Credit") ||
                            (tender.type === "Credit" &&
                              !this.canPayByCreditCard())
                          }
                        >
                          {tender.type == "Credit"
                            ? "Credit Card"
                            : tender.type}
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
                <span className="Checkout__quick-cash">Quick cash payment</span>
                <div>
                  {store.cashDenoms.map((denom, index) => (
                    <ButtonComponent
                      key={denom}
                      compact
                      primary={index % 2 !== 0 ? true : false}
                      onClick={(e) => this.quickCash(e, denom)}
                      className="Checkout__quick-cash-button"
                    >
                      {fC(denom)}
                    </ButtonComponent>
                  ))}
                  {store.changeDue ? (
                    <span className="Checkout__change-due">
                      Change Due: <em>{fC(store.changeDue)}</em>
                    </span>
                  ) : null}
                </div>
              </div>
            )}
          </Modal.Content>
          <Modal.Actions>
            <ButtonComponent
              secondary
              onClick={this.props.handleClose}
              icon="fal fa-times"
              iconPosition="left"
            >
              Cancel
            </ButtonComponent>
            <ButtonComponent
              primary
              disabled={disableFinalizeSale(
                store.balance,
                store.changeDue,
                this.props.posStore.tenders
              )}
              onClick={store.processPayments}
              icon="fas fa-caret-right"
              iconPosition="right"
            >
              Finalize Sale
            </ButtonComponent>
          </Modal.Actions>
        </Modal>

        {this.props.posStore.processingActive ? (
          <CreditProcess
            paymentTerminalConfig={
              this.props.posStore.paymentTerminal.paymentModule.config
            }
            amount={this.props.posStore.processingData.amount}
            processingMessage={this.props.posStore.processingMessage}
            processingAction={this.props.posStore.processingAction}
            forceFinalizeCredit={this.props.posStore.forceFinalizeCredit}
            cancelCardTransaction={this.props.posStore.cancelCardTransaction}
          />
        ) : null}
      </>
    );
  }
}

export default Checkout;
