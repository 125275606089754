import React from "react";
import ButtonComponent from "../generic/ButtonComponent";
import "./MxMerchantTerminalInstructions.scss";

interface MxMerchantTerminalInstructionsProps {
  activationCode: string;
  vendorKey: number;
  handleClose: () => void;
}

function MxMerchantTerminalInstructions(
  props: MxMerchantTerminalInstructionsProps
) {
  const { activationCode, vendorKey, handleClose } = props;

  return (
    <div className="MxMerchantTerminalInstructions">
      <div className="MxMerchantTerminalInstructions__content">
        <h3>Terminal Activation Instructions</h3>
        <ol>
          <li>Turn on the terminal.</li>
          <li>
            Open the AnyPay Cloud POS V2 app. If you do not see this app, then
            open the Money Store app and install the AnyPay Cloud POS V2 app.
          </li>
          <li>Enable all permissions.</li>
          <li>
            When the app prompts for a Activation All-in-one Nomad POS Code
            enter:
            <span className="MxMerchantTerminalInstructions__detail">
              {activationCode}
            </span>
          </li>
          <li>
            When the app prompts for a Vendor Key enter:{" "}
            <span className="MxMerchantTerminalInstructions__detail">
              {vendorKey}
            </span>
          </li>
          <li>
            Then, the app should be waiting for a transaction to be sent to it.
          </li>
        </ol>
      </div>
      <ButtonComponent primary fullWidth onClick={handleClose}>
        Close
      </ButtonComponent>
    </div>
  );
}

export default MxMerchantTerminalInstructions;
