import React, { useEffect, useState } from "react";
import { SAVE_LOCATION_SETTINGS } from "../../constants/eventsTracked";
import ButtonComponent from "../../components/generic/ButtonComponent";
import InfoPanel from "../../components/generic/InfoPanel";
import Loader from "../../components/generic/Loader";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";
import SetActiveMenu from "../../menuStructures/SetActiveMenu";
import POSMenuStructure from "../../menuStructures/POSMenuStructure";
import { notify } from "../../helpers/notificationHelpers";
import { useShowError } from "../../hooks/errorHooks";
import {
  useFetchLocations,
  useUpdateLocations,
} from "../../hooks/settingHooks";
import "./LocationSettings.scss";

function LocationSettings() {
  const [locationId, setLocationId] = useState<number>();
  const { data: locations, isLoading, error } = useFetchLocations();
  const { mutateAsync: updateLocation, isMutating: isSaving } =
    useUpdateLocations();
  const showError = useShowError();

  const handleSave = () =>
    updateLocation({ id: locationId })
      .then(() => {
        notify.info("Location updated");
      })
      .catch(showError);

  const handleChange = (shopifyId: number) => {
    setLocationId(shopifyId);
  };

  useEffect(
    () => setLocationId(locations?.binder[0].shopifyId),
    [locations?.binder[0].shopifyId]
  );

  useEffect(() => {
    if (error) {
      showError(error as DetailedError);
    }
  }, [error]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <SetActiveMenu menuStructure={POSMenuStructure} />
      <SectionHeaderLayout title="Location Settings" />
      <InfoPanel>
        Location selected must match the default location in Shopify. The
        default location can be found in your Shopify Admin page under Settings
        -{">"} Locations.
      </InfoPanel>
      <br />
      <form>
        <div className="LocationSettings__attributeTable">
          <div className="LocationSettings__attributeTableHeader">
            <div className="LocationSettings__attributeLabel">Location</div>
            <div className="LocationSettings__attributeRadio">Selection</div>
          </div>
          {(locations?.shopify || []).map((field) => (
            <div
              key={field.name}
              className="LocationSettings__attributeTableRow"
            >
              <div className="LocationSettings__attributeLabel">
                <label htmlFor={field.name}>{field.name}</label>
              </div>
              <div className="LocationSettings__attributeRadio">
                <input
                  type="radio"
                  id={field.name}
                  checked={field.shopifyId === locationId || false}
                  onChange={() => handleChange(field.shopifyId)}
                />
              </div>
            </div>
          ))}
        </div>
        <div className="LocationSettings__saveButton">
          <ButtonComponent
            primary
            disabled={isSaving}
            onClick={() => handleSave()}
            segmentEventName={SAVE_LOCATION_SETTINGS}
          >
            Save
          </ButtonComponent>
        </div>
      </form>
    </>
  );
}

export default LocationSettings;
