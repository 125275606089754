import { GetWpnReportDataQuery } from "../../generated/reporting";
import { WPNReportData } from "../../types/reports";

export const formatWPNReport = (
  report?: GetWpnReportDataQuery
): WPNReportData[] => {
  const wpnReport = report?.Metrics?.WPNReport;
  if (!wpnReport) {
    return [];
  }
  // Add an ID field so the table can use it as a key
  return wpnReport.map((row, index) => ({
    ...row,
    id: index,
  }));
};
