import { ReactNode, useState } from "react";
import { Form, Formik } from "formik";
import { notify } from "../../helpers/notificationHelpers";
import { filterDecimalString } from "../../helpers/valueHelpers";
import { useShowError } from "../../hooks/errorHooks";
import { useUpdateRarityPriceFloor } from "../../hooks/pricingHooks";
import { rarityFloorsSchema } from "../../schemas/pricing";
import { RarityFloor, GamePriceRule } from "../../types/settings";
import { joinClasses } from "../../utils/styling";
import ButtonComponent from "../generic/ButtonComponent";
import IconButton from "../generic/IconButton";
import Tooltip from "../generic/Tooltip";
import ActionButtonsLayout from "../layout/ActionButtonsLayout";
import NavigationPromptWatcher from "../nav/NavigationPromptWatcher";
import GamePriceFloor from "./GamePriceFloor";
import SettingsSectionToggle from "./SettingsSectionToggle";
import { segmentAnalytics } from "../services/Analytics";
import { SAVE_RARITY_PRICE_FLOOR_CLICKED } from "../../constants/eventsTracked";
import "./GamePriceFloors.scss";

interface GamePriceFloorsProps {
  priceRule: Partial<GamePriceRule> & { exists: boolean };
  priceRulesExist: boolean;
}

type FormValues = {
  rarityFloors: RarityFloor[];
};

function GamePriceFloors(props: GamePriceFloorsProps) {
  const { priceRule, priceRulesExist } = props;
  const { gameName, rarityFloors, game } = priceRule;
  const [isMinimized, setIsMinimized] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const { mutateAsync: saveRarityPriceFloor } = useUpdateRarityPriceFloor();
  const showError = useShowError();

  const hasPriceFloors =
    priceRulesExist && rarityFloors && rarityFloors.length > 0;
  const headerClass = joinClasses([
    "GamePriceFloors__header",
    hasPriceFloors ? "GamePriceFloors__header--sticky" : undefined,
  ]);

  const handleSubmit = (values: FormValues) => {
    const changedValues = rarityFloorsSchema
      .cast(values)
      .rarityFloors.filter(
        (rarity: RarityFloor, index: number) =>
          filterDecimalString(String(rarity.price), 3) !==
          filterDecimalString(String(rarityFloors[index].price), 3)
      );
    const saveRarityPriceFloors = changedValues.map((rarityFloor) => {
      saveRarityPriceFloor({ rarityFloor, gameCode: game });
      segmentAnalytics.track(SAVE_RARITY_PRICE_FLOOR_CLICKED, {
        product_type: gameName,
        rarity: rarityFloor.rarity,
        price_floor: Number(rarityFloor.price),
      });
    });
    return Promise.all(saveRarityPriceFloors)
      .then(() => {
        setIsEditing(false);
        notify.info(
          "Your pricing adjustments have been queued and will take effect in about 12 hours."
        );
      })
      .catch(showError);
  };

  const renderWithHeader = (content: ReactNode) => (
    <div className="GamePriceFloors">
      <Formik<FormValues>
        onSubmit={handleSubmit}
        validationSchema={rarityFloorsSchema}
        initialValues={{ rarityFloors }}
        enableReinitialize
      >
        {({ dirty, isSubmitting, resetForm }) => (
          <Form>
            <NavigationPromptWatcher
              dirty={dirty}
              formName={`game-price-floor-${game}`}
            >
              <div className={headerClass}>
                <div className="GamePriceFloors__headerContent">
                  <h3>
                    Rarity Floors
                    <Tooltip>
                      <aside>
                        <p>
                          The minimum price of singles (set in currency) based
                          on card rarity.
                        </p>
                        <p>
                          NOTE: Variant percentage settings may result in prices
                          that are lower than the rarity price floors you set.
                        </p>
                      </aside>
                    </Tooltip>
                  </h3>
                  {priceRulesExist ? (
                    <IconButton
                      data-testid="settings-section-toggle"
                      title="Toggle rarity price floors"
                      aria-label="Toggle rarity price floors"
                      onClick={() => setIsMinimized(!isMinimized)}
                      className="GamePriceFloors__toggle"
                      icon={
                        isMinimized
                          ? "fas fa-chevron-down"
                          : "fas fa-chevron-up"
                      }
                      secondary
                    />
                  ) : null}
                </div>
                <hr />
                {hasPriceFloors && !isMinimized ? (
                  <ActionButtonsLayout alignment="right">
                    <SettingsSectionToggle
                      isOn={isEditing}
                      disabled={isSubmitting}
                      toggle={(edit: boolean) => setIsEditing(edit)}
                      toggleTextOn="Edit Rarity Floors"
                      toggleTextOff="Cancel"
                      toggleOnFunction={() => setIsMinimized(false)}
                      toggleOffFunction={resetForm}
                    />
                    {isEditing ? (
                      <ButtonComponent
                        primary
                        type="submit"
                        disabled={isSubmitting}
                        title={`Save rarity price floors for ${gameName}`}
                      >
                        Save
                      </ButtonComponent>
                    ) : null}
                  </ActionButtonsLayout>
                ) : null}
              </div>
              {content}
            </NavigationPromptWatcher>
          </Form>
        )}
      </Formik>
    </div>
  );

  if (!priceRulesExist) {
    return renderWithHeader(
      <em>
        You must first set up price rules before configuring rarity floors.
      </em>
    );
  }

  if (rarityFloors.length === 0) {
    return renderWithHeader(<em>No price floors available</em>);
  }

  if (isMinimized) {
    return renderWithHeader(null);
  }

  return renderWithHeader(
    <table className="GamePriceFloors__rarityTable">
      <tbody>
        {rarityFloors.map((rarityFloor, i) => (
          <GamePriceFloor
            gameName={gameName}
            gameCode={game}
            rarityFloor={rarityFloor}
            key={`${gameName} - ${rarityFloor.rarity} - ${i}`}
            enableEditMode={isEditing}
            index={i}
          />
        ))}
      </tbody>
    </table>
  );
}

export default GamePriceFloors;
