import React from "react";
import "./MultiSelect.scss";

const getIcon = (checked: boolean) =>
  checked ? (
    <i className="far fa-check-square" />
  ) : (
    <i className="far fa-square" />
  );

const getClass = (checked: boolean) =>
  checked
    ? "MultiSelect__option MultiSelect__option--checked"
    : "MultiSelect__option";

interface MultiSelectProps {
  availableOptions: string[];
  selectedOptions: string[];
  setSelectedOptions: (options: string[]) => void;
}

function MultiSelect(props: MultiSelectProps) {
  const { availableOptions, selectedOptions, setSelectedOptions } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    let options;
    if (checked) {
      options = [...selectedOptions, name];
    } else {
      options = selectedOptions.filter((option) => option !== name);
    }
    setSelectedOptions(options);
  };

  return (
    <div className="MultiSelect">
      {availableOptions.map((option) => {
        const isChecked = selectedOptions.includes(option);
        return (
          <label
            className={getClass(isChecked)}
            key={option}
            htmlFor={`${option}Checkbox`}
          >
            <input
              id={`${option}Checkbox`}
              name={option}
              className="MultiSelect__input"
              type="checkbox"
              checked={isChecked}
              onChange={handleChange}
            />
            <span className="MultiSelect__checkbox">{getIcon(isChecked)}</span>
            <span className="MultiSelect__label">{option}</span>
          </label>
        );
      })}
    </div>
  );
}

export default MultiSelect;
