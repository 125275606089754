import { UseQueryOptions, QueryKey } from "react-query";
import {
  GetWpnReportDataQuery,
  GetWpnReportDataQueryVariables,
  useGetWpnReportDataQuery,
} from "../../generated/reporting";
import { formatWPNReport } from "../../transformers/reports/formatWPNReport";
import { WPNReportData } from "../../types/reports";

export const useFetchWPNReport = (
  variables: GetWpnReportDataQueryVariables,
  options: UseQueryOptions<
    GetWpnReportDataQuery,
    unknown,
    WPNReportData[],
    QueryKey
  > = {}
) =>
  useGetWpnReportDataQuery(variables, {
    select: (report) => formatWPNReport(report),
    keepPreviousData: true,
    staleTime: 60_000,
    ...options,
  });
