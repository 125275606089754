import React, { useEffect, useState } from "react";
import ButtonComponent from "../generic/ButtonComponent";
import {
  useLocalSetting,
  useLocalSettingUpdate,
} from "../../hooks/localSettingsHooks";
import {
  COOKIES_ANALYTICS,
  COOKIES,
  COOKIE_POLICY_LAST_UPDATED,
} from "../../constants/settings";
import "./CookieBanner.scss";
import CookieSettings from "./CookieSettings";
import IconButton from "../generic/IconButton";

declare global {
  interface Navigator {
    globalPrivacyControl?: boolean;
  }
}

const CONSENT_DURATION = 180 * 24 * 3600 * 1000; // 180 days

function CookieBanner() {
  const [showBanner, setShowBanner] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const now = Date.now();

  const cookiesConsented = useLocalSetting(COOKIES);
  const updateLocalSetting = useLocalSettingUpdate();
  const policyLastUpdated = new Date(COOKIE_POLICY_LAST_UPDATED).getTime();

  const globalPrivacyControlEnabled = navigator.globalPrivacyControl;

  useEffect(() => {
    if (
      (!cookiesConsented ||
        cookiesConsented < policyLastUpdated ||
        cookiesConsented < now - CONSENT_DURATION) &&
      !globalPrivacyControlEnabled
    ) {
      setShowBanner(true);
    }
  }, []);

  const handleAllowAll = () => {
    updateLocalSetting(COOKIES, now);
    updateLocalSetting(COOKIES_ANALYTICS, true);
    setShowBanner(false);
  };

  const handleCustomise = () => {
    setShowSettings(true);
    setShowBanner(false);
  };

  const handleDismiss = () => setShowBanner(false);

  if (showSettings) {
    return <CookieSettings onClose={() => setShowSettings(false)} />;
  }

  if (!showBanner) {
    return null;
  }

  return (
    <div className="CookieBanner">
      <div className="CookieBanner_message">
        <div className="CookieBanner__header">
          <IconButton
            icon="fal fa-times"
            className="CookieBanner__dismiss"
            aria-label="dismiss cookie banner"
            onClick={() => handleDismiss()}
          />
          <b>This website uses cookies</b>
        </div>
        <p>
          While we do not sell personal information to third parties for money,
          we, like many companies, use services that help deliver interest-based
          ads to you as described in our{" "}
          <a
            href="https://binderpos.com/binderpos-privacy-policy"
            target="_blank"
            rel="noopener"
            className="CookieBanner__link"
          >
            Privacy Policy
          </a>
          .
          <br />
          To opt out or manage your preferences, click the <b>Customize</b>{" "}
          button.
        </p>
      </div>
      <div className="CookieBanner__actions">
        <ButtonComponent onClick={handleCustomise} icon="fas fa-chevron-right">
          Customize
        </ButtonComponent>
        <ButtonComponent
          primary
          onClick={handleAllowAll}
          icon="fas fa-check-circle"
        >
          Allow All
        </ButtonComponent>
      </div>
    </div>
  );
}

export default CookieBanner;
