import React, { FormEvent, MutableRefObject, useRef, useState } from "react";
import { adjustCustomerCredit } from "../../api/rest/customers";
import { useShowError } from "../../hooks/errorHooks";
import Modal from "../generic/Modal";
import ButtonComponent from "../generic/ButtonComponent";
import "./AdjustStoreCreditModal.scss";

type AdjustmentType = "increase" | "decrease" | "overwrite";

export interface AdjustStoreCreditModalProps {
  customer: Customer;
  onComplete: () => void;
  handleClose: () => void;
}

function AdjustStoreCreditModal(
  props: AdjustStoreCreditModalProps,
  ref: MutableRefObject<any>
) {
  const { customer, onComplete, handleClose } = props;
  const formRef = useRef(null);
  const showError = useShowError();
  const [isUpdating, setIsUpdating] = useState(false);
  const [adjustmentType, setAdjustmentType] =
    useState<AdjustmentType>("increase");

  const handleAdjustmentTypeChange = (newType: AdjustmentType) =>
    setAdjustmentType(newType);

  const adjustStoreCredit = (event: FormEvent) => {
    event.preventDefault();

    // @ts-ignore
    const elm = formRef.current?.elements;
    const toUpdate = {
      customerId: customer.id,
      amountToAdjust: elm.adjustPrice.value,
      typeOfAdjustment: adjustmentType,
      publicNotes: elm.publicNotes.value,
      privateNotes: elm.privateNotes.value,
    };

    adjustCustomerCredit(toUpdate)
      .then(() => {
        setIsUpdating(false);
        onComplete();
      })
      .catch((error: DetailedError) => {
        setIsUpdating(false);
        showError(
          error,
          "Failed to update customer",
          "There was an error submitting the adjusted store credit. Please try again"
        );
      });
  };

  const adjustText = {
    increase: "Enter how much you want to add to their store credit.",
    decrease: "Enter how much you want to remove from their store credit.",
    overwrite:
      "Enter the amount of store credit they have. This will overwrite the current value.",
  };

  return (
    <Modal onClose={handleClose} ref={ref}>
      <Modal.Header>
        Adjust store credit for {customer.firstName} {customer.lastName}
      </Modal.Header>
      <Modal.Content>
        <form
          id="adjustStoreCreditForm"
          ref={formRef}
          onSubmit={adjustStoreCredit}
        >
          <div
            className="AdjustStoreCreditModal"
            data-testid="AdjustStoreCreditModal"
          >
            <div className="field">
              <label className="label" htmlFor="adjustType">
                Adjustment type:
              </label>
              <select
                id="adjustType"
                className="AdjustStoreCreditModal__adjustType"
                onChange={(event) =>
                  handleAdjustmentTypeChange(
                    event.target.value as AdjustmentType
                  )
                }
                value={adjustmentType}
              >
                <option value="increase">Increase</option>
                <option value="decrease">Decrease</option>
                <option value="overwrite">Overwrite</option>
              </select>
            </div>
            <div className="field">
              <label className="label">{adjustText[adjustmentType]}</label>
              <div className="control has-icons-left">
                <input
                  data-testid="adjustmentValue"
                  id="adjustPrice"
                  className="input"
                  type="number"
                  min="0"
                  required
                  placeholder="E.g. 29.95"
                  step=".01"
                />
              </div>
            </div>
            <div className="field">
              <label htmlFor="publicNotes" className="label">
                Public Notes
              </label>
              <div className="control">
                <textarea
                  id="publicNotes"
                  className="input"
                  rows={5}
                  placeholder="E.g. Won tournament and $50.00 store credit was applied"
                />
              </div>
            </div>
            <div className="field">
              <label htmlFor="privateNotes" className="label">
                Internal Notes
              </label>
              <div className="control">
                <textarea
                  id="privateNotes"
                  className="input"
                  rows={5}
                  placeholder="E.g. Customer used $20.00 store credit while the internet was not working, correcting their store credit."
                />
              </div>
            </div>
          </div>
        </form>
      </Modal.Content>
      <Modal.Actions>
        <ButtonComponent
          secondary
          onClick={handleClose}
          icon="fal fa-times"
          iconPosition="left"
        >
          Cancel
        </ButtonComponent>
        <ButtonComponent
          primary
          type="submit"
          form="adjustStoreCreditForm"
          disabled={isUpdating}
          icon="fas fa-caret-right"
          iconPosition="right"
        >
          Save Adjustment
        </ButtonComponent>
      </Modal.Actions>
    </Modal>
  );
}

export default React.forwardRef(AdjustStoreCreditModal);
