import { EbayFulfillment, ShippingOption, TimePeriod } from "../api/rest/ebay";

export interface EbayFulfillmentFormData {
  fulfillmentPolicyId?: string;
  name: string;
  description: string;
  freightShipping: boolean;
  localPickup: boolean;
  pickupDropOff?: boolean;
  globalShipping: boolean;
  handlingTime: TimePeriod;
  regionsIncluded: string[];
  regionsExcluded: string[];
  domesticShippingOption: ShippingOption | null;
  internationalShippingOption: ShippingOption | null;
}

export const parseEbayError = (error: any) => {
  if (!error.data) return null;
  try {
    const data = JSON.parse(error.data);
    if (!data.message) return null;
    return data.message;
  } catch (error) {
    return null;
  }
};

export const mapEbayFulfillmentToForm = (
  fulfillmentSetting: EbayFulfillment,
  defaultValues: EbayFulfillmentFormData
): EbayFulfillmentFormData => {
  if (!fulfillmentSetting) return defaultValues;
  return {
    globalShipping: fulfillmentSetting.globalShipping,
    fulfillmentPolicyId: fulfillmentSetting.fulfillmentPolicyId,
    name: fulfillmentSetting.name,
    description: fulfillmentSetting.description,
    freightShipping: fulfillmentSetting.freightShipping,
    localPickup: fulfillmentSetting.localPickup,
    pickupDropOff: fulfillmentSetting.pickupDropOff,
    handlingTime: fulfillmentSetting.handlingTime ?? defaultValues.handlingTime,
    regionsIncluded: fulfillmentSetting.regionsIncluded,
    regionsExcluded: fulfillmentSetting.regionsExcluded,
    domesticShippingOption:
      fulfillmentSetting.shippingOptions?.[0] ??
      defaultValues.domesticShippingOption,
    internationalShippingOption:
      fulfillmentSetting.shippingOptions?.[1] ??
      defaultValues.internationalShippingOption,
  };
};

export const mapFormToEbayFulfillment = (
  fulfillmentForm: EbayFulfillmentFormData
) => {
  const shippingOptions = [];
  if (
    fulfillmentForm.domesticShippingOption !== null &&
    fulfillmentForm.domesticShippingOption.costType !== null &&
    fulfillmentForm.domesticShippingOption.shippingServices.length
  )
    shippingOptions.push(fulfillmentForm.domesticShippingOption);
  if (
    fulfillmentForm.internationalShippingOption !== null &&
    fulfillmentForm.internationalShippingOption.costType !== null &&
    fulfillmentForm.internationalShippingOption.shippingServices.length
  )
    shippingOptions.push(fulfillmentForm.internationalShippingOption);
  return {
    fulfillmentPolicyId: fulfillmentForm.fulfillmentPolicyId,
    name: fulfillmentForm.name,
    description: fulfillmentForm.description,
    freightShipping: fulfillmentForm.freightShipping,
    localPickup: fulfillmentForm.localPickup,
    pickupDropOff: fulfillmentForm.pickupDropOff,
    handlingTime:
      fulfillmentForm.localPickup || fulfillmentForm.freightShipping
        ? null
        : fulfillmentForm.handlingTime,
    regionsIncluded: fulfillmentForm.regionsIncluded,
    regionsExcluded: fulfillmentForm.regionsExcluded,
    shippingOptions: shippingOptions,
    globalShipping: fulfillmentForm.globalShipping,
  };
};
