import { FormikErrors } from "formik";
import React from "react";
import "./CFBFormError.scss";

interface CFBFormErrorProps {
  errors: FormikErrors<unknown>;
}

function CFBFormError(props: CFBFormErrorProps) {
  const { errors } = props;

  if (!Object.keys(errors).length) return null;

  return (
    <div className="cfb-form-error">
      <ul>
        {Object.keys(errors).map((k, i) => {
          // @ts-ignore
          return <li key={i}>{errors[k]}</li>;
        })}
      </ul>
    </div>
  );
}

export default CFBFormError;
