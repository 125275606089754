import React, { useState } from "react";
import { FormikProps } from "formik";
import ConfirmationModal from "../generic/ConfirmationModal";
import IconButton from "../generic/IconButton";
import { ProductVariant } from "../../generated/graphql";
import { ProductEditData } from "../../types/productEdit";

interface DeleteProductVariantButtonProps {
  form: FormikProps<ProductEditData>;
  variant: ProductVariant;
  variantIndex: number;
}

function DeleteProductVariantButton(props: DeleteProductVariantButtonProps) {
  const { form, variant, variantIndex } = props;
  const { values, setFieldValue } = form;
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  const handleDeleteClick = () => setShowConfirmation(true);

  const handleConfirmClick = () => {
    if (variant.id) {
      const newProductVariant = values.ProductVariant.map((variant, index) => {
        if (index === variantIndex) {
          return {
            ...variant,
            toDelete: true,
          };
        }
        return variant;
      });
      setFieldValue("ProductVariant", newProductVariant);
    } else {
      const newProductVariant = values.ProductVariant.filter(
        (_value, index) => variantIndex !== index
      );
      setFieldValue("ProductVariant", newProductVariant);
    }
    setShowConfirmation(false);
  };

  const handleCancelClick = () => setShowConfirmation(false);

  return (
    <>
      <IconButton
        danger
        type="button"
        icon="fas fa-ban"
        title="Delete variant"
        onClick={handleDeleteClick}
      />

      {showConfirmation ? (
        <ConfirmationModal
          title="Delete Variant"
          cancelAction={handleCancelClick}
          confirmAction={handleConfirmClick}
          confirmWord="Delete"
        >
          {variant.title
            ? `Are you sure you want to remove ${variant.title}?`
            : "Are you sure you want to remove this variant?"}
        </ConfirmationModal>
      ) : null}
    </>
  );
}

export default DeleteProductVariantButton;
