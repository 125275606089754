import React from "react";

interface ModalContentProps {
  children: React.ReactNode;
}

function ModalContent(props: ModalContentProps) {
  const { children } = props;

  return <section>{children}</section>;
}

ModalContent.isModalContent = true;

export default ModalContent;
