import React from "react";
import "./GridLayout.scss";

interface GridLayoutProps {
  children: React.ReactNode;
}

function GridLayout(props: GridLayoutProps) {
  const { children } = props;

  return <div className="GridLayout">{children}</div>;
}

export default GridLayout;
