import React, { useRef } from "react";
import { ButtonComponent } from "..";
import { segmentAnalytics } from "../services/Analytics";
import {
  CANCEL_ADD_TILL_CLICKED,
  ADD_TILL_CLICKED,
  CLOSE_ADD_TILL_CLICKED,
} from "../../constants/eventsTracked";
import Modal from "../generic/Modal";
import "./AddTill.scss";

type TillSetting = {
  name: string;
  description?: string;
};

interface TillSettingForm extends HTMLFormControlsCollection {
  tillName: HTMLInputElement;
  tillDescription: HTMLTextAreaElement;
}

interface AddTillProps {
  isLoading: boolean;
  addNewTill: (settings: TillSetting) => void;
  setAddNewTillModalVisible: (visible: boolean) => void;
}

function AddTill(props: AddTillProps, ref: React.MutableRefObject<any>) {
  const { isLoading, addNewTill, setAddNewTillModalVisible } = props;
  const formRef = useRef<HTMLFormElement>();

  const onSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    const elements = formRef.current.elements as TillSettingForm;
    const settings = {
      name: elements.tillName.value,
      description: elements.tillDescription.value,
    };
    addNewTill(settings);
  };

  const closeModal = () => setAddNewTillModalVisible(false);

  return (
    <Modal
      onClose={() => {
        segmentAnalytics.track(CLOSE_ADD_TILL_CLICKED);
        closeModal();
      }}
      ref={ref}
    >
      <Modal.Header>Add a new Till / Register</Modal.Header>
      <Modal.Content>
        <form
          ref={formRef}
          onSubmit={onSubmit}
          id="addTillForm"
          className="AddTill"
          noValidate
        >
          <div className="field">
            <label className="label" htmlFor="tillName">
              Till / Register name
            </label>
            <div className="control">
              <input
                id="tillName"
                className="input"
                type="text"
                required
                placeholder="Register 1"
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor="tillDescription">
              Till / Register description
            </label>
            <div className="control">
              <textarea
                id="tillDescription"
                className="input"
                placeholder="My awesome front of house register"
              />
            </div>
          </div>
        </form>
      </Modal.Content>
      <Modal.Actions>
        <ButtonComponent
          secondary
          onClick={() => {
            segmentAnalytics.track(CANCEL_ADD_TILL_CLICKED);
            closeModal();
          }}
          icon="fal fa-times"
          iconPosition="left"
        >
          Cancel
        </ButtonComponent>
        <ButtonComponent
          primary
          type="submit"
          form="addTillForm"
          disabled={isLoading}
          onClick={() => segmentAnalytics.track(ADD_TILL_CLICKED)}
          icon="fas fa-caret-right"
          iconPosition="right"
        >
          Add
        </ButtonComponent>
      </Modal.Actions>
    </Modal>
  );
}

export default React.forwardRef(AddTill);
