import React from "react";
import ButtonComponent from "../generic/ButtonComponent";
import { IMPORT_PRODUCT_CSV_CLICKED } from "../../constants/eventsTracked";
import { POS_COMBINED_CSV_UPLOADER } from "../../constants/featureFlags";
import { useFeatureFlag } from "../../hooks/globalSettingsHooks";

interface ImportCsvButtonsProps {
  showImportModal: (target?: HTMLElement) => void;
  showTcgModal: (target?: HTMLElement) => void;
}

function ImportCsvButtons(props: ImportCsvButtonsProps) {
  const { showImportModal, showTcgModal } = props;
  const combinedCsvUploaderEnabled = useFeatureFlag(POS_COMBINED_CSV_UPLOADER);

  if (combinedCsvUploaderEnabled) {
    return (
      <ButtonComponent
        primary
        isLink
        to="/products/import"
        icon="fas fa-caret-right"
        iconPosition="right"
        segmentEventName={IMPORT_PRODUCT_CSV_CLICKED}
      >
        Import CSV
      </ButtonComponent>
    );
  }

  return (
    <>
      <ButtonComponent
        primary
        icon="fas fa-caret-right"
        iconPosition="right"
        onClick={(event) => showImportModal(event.currentTarget)}
      >
        Import Binder CSV
      </ButtonComponent>
      <ButtonComponent
        primary
        icon="fas fa-caret-right"
        iconPosition="right"
        onClick={(event) => showTcgModal(event.currentTarget)}
      >
        Import External CSV
      </ButtonComponent>
    </>
  );
}

export default ImportCsvButtons;
