import React, { Component } from "react";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";
import { OrderHistoryTable, OrderDetails } from ".";
import Modal from "../generic/Modal";
import { ItemList } from "../../pos/ItemStore";
import { RootStore } from "../../store";

interface OrderModalProps {
  posStore?: ItemList;
  store?: RootStore;
  close: () => void;
}

@inject("store")
@inject("posStore")
@observer
class OrderModal extends Component<OrderModalProps> {
  @action closeModal = () => {
    this.props.close();
  };

  @observable orderNumber = null;
  @action setOrderNumber = (orderNumber) => {
    if (!isNaN(orderNumber)) {
      this.orderNumber = orderNumber;
      this.props.store.CartStore.fetchCartById(orderNumber)
        .then((cart) => {
          this.props.store.CartStore.setSelectedCart(cart);
        })
        .catch((error) => {
          this.props.store.MainStore.setError(
            error,
            "Failed to load cart details",
            "There was an error retrieving your cart details. Please try again"
          );
        });
    }
  };

  @action clearOrder = () => {
    this.orderNumber = null;
    this.props.store.CartStore.setSelectedCart(null);
  };

  componentDidMount() {
    // this.props.store.CartStore.rehydrateAllCarts(this.offset, this.limit);
    this.props.store.MainStore.buildReceiptData();
  }

  render() {
    return (
      <Modal
        onClose={this.orderNumber ? this.clearOrder : this.closeModal}
        large
      >
        <Modal.Header>Order History</Modal.Header>
        <Modal.Content>
          {!this.orderNumber ? (
            <OrderHistoryTable
              setOrderNumber={this.setOrderNumber}
              cartFromResponse={this.props.posStore.cartFromResponse}
              setReturnCartIntegratedPayment={
                this.props.posStore.setReturnCartIntegratedPayment
              }
              formatCurrency={this.props.posStore.fCurr}
              closeModal={this.closeModal}
            />
          ) : (
            <OrderDetails
              setOrderNumber={this.setOrderNumber}
              cartId={this.orderNumber}
              cartFromResponse={this.props.posStore.cartFromResponse}
              setReturnCartIntegratedPayment={
                this.props.posStore.setReturnCartIntegratedPayment
              }
              closeModal={this.closeModal}
              formatCurrency={this.props.posStore.fCurr}
              storeInfo={this.props.store.MainStore.storeInfo}
            />
          )}
        </Modal.Content>
        <Modal.Actions> </Modal.Actions>
      </Modal>
    );
  }
}

export default OrderModal;
