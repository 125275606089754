import { GamePricingTypeOption } from "../types/settings";

export const localSettingsKey = "portalSettings";

export const COOKIES = "cookiesAccepted";
export const COOKIES_ANALYTICS = "cookiesAllowAnalytics";
export const COOKIES_FUNCTIONAL = "cookiesAllowFunctional";
export const COOKIES_ADVERTISING = "cookiesAllowAdvertising";
export const ALLOW_CATALOG_CHANGES = "allowCatalogChanges";
export const COOKIE_POLICY_LAST_UPDATED = "2022-12-21";
export const SHOPIFY_SUBSCRIPTION_FORCED_DATE =
  "isShopifySubscriptionAlertShownNoDismiss";
export const SHOPIFY_SUBSCRIPTION_LAST_DISMISSED_DATE =
  "isShopifySubscriptionAlertShownLastDismissed";

export const optOutFields = [
  {
    value: "sku",
    label: "Product SKU",
  },
  {
    value: "bodyHtml",
    label: "HTML description",
  },
  {
    value: "inventoryManagement",
    label: "Inventory Management",
  },
  {
    value: "price",
    label: "Price",
  },
  {
    value: "images",
    label: "Images",
  },
  {
    value: "vendor",
    label: "Vendor",
  },
  {
    value: "productType",
    label: "Product Type",
  },
  {
    value: "title",
    label: "Title",
  },

  {
    value: "tags",
    label: "Tags",
  },
  {
    value: "published",
    label: "Published",
  },
  {
    value: "status",
    label: "Status",
  },
  {
    value: "weight",
    label: "Product weight",
  },
  {
    value: "weightUnit",
    label: "Weight unit",
  },
  {
    value: "barcode",
    label: "Barcode",
  },
  {
    value: "variants",
    label: "Variants",
  },
];

export const STORE_SETTINGS_KEY_RECEIPT = "storeReceiptSettings";

export const RECEIPT_FIELDS = {
  tel: {
    title: "Telephone prefix",
    id: "tel",
    type: "text",
    placeholder: "Tel",
  },
  credit: {
    title: "Credit",
    id: "credit",
    type: "text",
    placeholder: "Credit",
  },
  sale: {
    title: "Sale number",
    id: "sale",
    type: "text",
    placeholder: "Sale #",
  },
  till: {
    title: "Till",
    id: "till",
    type: "text",
    placeholder: "Till",
  },
  itemsPurchased: {
    title: "Items purchased",
    id: "itemsPurchased",
    type: "text",
    placeholder: "Items purchased on this sale",
  },
  discount: {
    title: "Discount",
    id: "discount",
    type: "text",
    placeholder: "Discount",
  },
  subTotal: {
    title: "Subtotal",
    id: "subTotal",
    type: "text",
    placeholder: "Subtotal",
  },
  taxWording: {
    title: "Tax",
    id: "taxWording",
    type: "text",
    placeholder: "GST",
  },
  creditCardSurcharge: {
    title: "Credit card surcharge",
    id: "creditCardSurcharge",
    type: "text",
    placeholder: "Credit card surcharge",
  },
  total: {
    title: "Total",
    id: "total",
    type: "text",
    placeholder: "Total",
  },
  creditCard: {
    title: "Credit card",
    id: "creditCard",
    type: "text",
    placeholder: "Credit Card",
  },
  storeCredit: {
    title: "Store credit",
    id: "storeCredit",
    type: "text",
    placeholder: "Store credit",
  },
  eftpos: {
    title: "EFTPOS",
    id: "eftpos",
    type: "text",
    placeholder: "EFTPOS",
  },
  cash: {
    title: "Cash",
    id: "cash",
    type: "text",
    placeholder: "Cash",
  },
  change: {
    title: "Change",
    id: "change",
    type: "text",
    placeholder: "Change",
  },
  giftReceipt: {
    title: "Gift Receipt",
    id: "giftReceipt",
    type: "text",
    placeholder: "Gift Receipt",
  },
  customMessage: {
    title: "Custom message",
    id: "customMessage",
    type: "textarea",
    placeholder: "Personalised thank you message (optional)",
  },
};

export const PRICING_TYPE_OPTIONS: GamePricingTypeOption[] = [
  {
    value: "low",
    description: "TCGplayer Low",
  },
  {
    value: "mid",
    description: "TCGplayer Mid",
  },
  {
    value: "high",
    description: "TCGplayer High",
  },
  {
    value: "directLow",
    description: "TCGplayer Direct Low",
  },
  {
    value: "marketLow",
    description: "TCGplayer Market Low",
  },
  {
    value: "market",
    description: "TCGplayer Market",
  },
];
