declare global {
  interface Window {
    newrelic?: {
      noticeError: (error: any, customAttributes?: Record<string, any>) => void;
    };
  }
}

export const logError = (
  error: any,
  customAttributes?: Record<string, any>
) => {
  console.error(error);
  if (window?.newrelic) {
    window.newrelic.noticeError(error, customAttributes);
  }
};
