import React from "react";
import { ErrorMessage, Field } from "formik";
import { Column } from "../../types/table";
import DeleteProductVariantButton from "./DeleteProductVariantButton";
import ProductWeightUnitInput from "./ProductWeightUnitInput";

const ProductVariantsTableColumns: Column[] = [
  {
    header: "Title",
    accessorKey: "title",
    cell: (_value, _column, _rowData, rowIndex) => (
      <div className="product-variants__row">
        <Field
          aria-label="Title"
          name={`ProductVariant[${rowIndex}].title`}
          className="product-variants__title input"
        />
        <ErrorMessage
          name={`ProductVariant[${rowIndex}].title`}
          className="input-error"
          component="div"
        />
      </div>
    ),
  },
  {
    header: "SKU",
    accessorKey: "sku",
    cell: (_value, _column, _rowData, rowIndex) => (
      <div className="product-variants__row">
        <Field
          aria-label="sku"
          name={`ProductVariant[${rowIndex}].sku`}
          className="product-variants__sku input"
        />
      </div>
    ),
  },
  {
    header: "Barcode",
    accessorKey: "barcode",
    cell: (_value, _column, _rowData, rowIndex) => (
      <div className="product-variants__row">
        <Field
          aria-label="barcode"
          name={`ProductVariant[${rowIndex}].barcode`}
          className="product-variants__barcode input"
        />
      </div>
    ),
  },
  {
    header: "Weight",
    accessorKey: "weight",
    cell: (_value, _column, _rowData, rowIndex) => (
      <div className="product-variants__row">
        <div className="product-variants__weight">
          <Field
            aria-label="weight"
            name={`ProductVariant[${rowIndex}].weight`}
            className="input"
          />
          <Field
            component={ProductWeightUnitInput}
            name={`ProductVariant[${rowIndex}].weightUnit`}
          />
        </div>
        <ErrorMessage
          name={`ProductVariant[${rowIndex}].weight`}
          className="input-error"
          component="div"
        />
      </div>
    ),
  },
  {
    header: "Price",
    accessorKey: "price",
    cell: (_value, _column, _rowData, rowIndex) => (
      <div className="product-variants__row">
        <Field
          aria-label="price"
          name={`ProductVariant[${rowIndex}].price`}
          className="product-variants__price input"
        />
        <ErrorMessage
          name={`ProductVariant[${rowIndex}].price`}
          className="input-error"
          component="div"
        />
      </div>
    ),
  },
  {
    header: "Delete",
    accessorKey: "id",
    cell: (id, _column, rowData, rowIndex) => (
      <div className="product-variants__row">
        <Field
          className="product-variants__actions"
          component={DeleteProductVariantButton}
          name={`ProductVariant[${rowIndex}].price`}
          variantIndex={rowIndex}
          variant={rowData}
        />
      </div>
    ),
  },
];

export default ProductVariantsTableColumns;
