import React from "react";
import { isNil } from "lodash";
import { useFetchProductQueuedJobStatus } from "../../hooks/jobQueueHooks";
import { ProductQueuedJob } from "../../types/queuedJobs";
import TimeUtils from "../../utils/TimeUtils";
import JobStatusProgress from "../queuedJobs/JobStatusProgress";

const ProductsJobQueueColumns = (
  updateQueuedJobStatus: (jobData: ProductQueuedJob) => void,
  currencyFormatter: (amount: number | string) => string
) => [
  {
    accessorKey: "jobId",
    header: "Job ID",
    cell: (jobId: number, _: any, rowData: ProductQueuedJob) => {
      return jobId ?? rowData.id;
    },
  },
  {
    accessorKey: "status",
    header: "Status",
    cell: (status: string) => {
      const statusName = status?.toLowerCase();
      return (
        <div
          className={`jobQueueList__status jobQueueList__status--${statusName.replace(
            / /g,
            "-"
          )}`}
        >
          {statusName}
        </div>
      );
    },
  },
  {
    accessorKey: "readableDateProcessed",
    header: "Started",
    cell: (startDate: string) =>
      startDate ? TimeUtils.convertDateToHumanReadable(startDate) : "",
  },
  {
    accessorKey: "readableDateCompleted",
    header: "Completed",
    cell: (completedDate: string) =>
      completedDate ? TimeUtils.convertDateToHumanReadable(completedDate) : "",
  },
  {
    accessorKey: "id",
    header: "Job Details",
    cell: (id: number, _: any, rowData: ProductQueuedJob) =>
      renderJobDetails(rowData),
  },
  {
    accessorKey: "id",
    header: "Updates",
    cell: (id: number, _: any, rowData: ProductQueuedJob) =>
      renderJobUpdates(rowData, currencyFormatter),
  },
  {
    accessorKey: "id",
    header: "Progress",
    cell: (id: number, _: any, rowData: ProductQueuedJob) => (
      <JobStatusProgress
        key={`${rowData?.type}-${rowData?.id}`}
        jobData={rowData}
        updateQueuedJobStatusHandler={updateQueuedJobStatus}
        jobStatusUpdater={useFetchProductQueuedJobStatus}
      />
    ),
  },
];

export default ProductsJobQueueColumns;

const renderJobDetails = (jobData: ProductQueuedJob) => {
  if (jobData.productName && jobData.variantName)
    return (
      <div className="jobQueueList__searchFilters">
        Updated{" "}
        <em>
          {jobData.productName} - {jobData.variantName}
        </em>
      </div>
    );

  const searchFilters =
    jobData?.searchFilters?.length > 0
      ? JSON.parse(jobData.searchFilters)
      : null;

  if (!searchFilters) return null;

  return (
    <div className="jobQueueList__searchFilters">
      Search Filters: <em>{buildSearchString(searchFilters)}</em>
    </div>
  );
};

const renderJobUpdates = (
  jobData: ProductQueuedJob,
  currencyFormatter: (amount: number | string) => string
) => {
  if (jobData.productName && jobData.variantName) {
    return (
      <>
        {!isNil(jobData.priceBefore) && !isNil(jobData.priceAfter) ? (
          <div className="jobQueueList__updates">
            <div className="jobQueueList__queue-adjustment">
              <strong>Price before:</strong>{" "}
              {currencyFormatter(jobData.priceBefore)}
            </div>
            <div className="jobQueueList__queue-adjustment">
              <strong>Price after:</strong>{" "}
              {currencyFormatter(jobData.priceAfter)}
            </div>
          </div>
        ) : null}
        {!isNil(jobData.stockBefore) && !isNil(jobData.stockAfter) ? (
          <div className="jobQueueList__updates">
            <div className="jobQueueList__queue-adjustment">
              <strong>Stock before:</strong> {jobData.stockBefore}
            </div>
            <div className="jobQueueList__queue-adjustment">
              <strong>Stock after:</strong> {jobData.stockAfter}
            </div>
          </div>
        ) : null}
      </>
    );
  }

  const fieldsUpdated =
    jobData?.fieldsUpdated?.length > 0
      ? JSON.parse(jobData.fieldsUpdated)
      : null;

  if (!fieldsUpdated) return null;

  return (
    <div className="jobQueueList__updates">
      <em>{buildFieldsUpdate(jobData, fieldsUpdated, currencyFormatter)}</em>
    </div>
  );
};

const buildSearchString = (searchFilters: any) => {
  let searchUpdated = "";
  if (searchFilters?.game?.length > 0) {
    searchUpdated += `Game: ${searchFilters.game}; `;
  }
  if (searchFilters?.title?.length > 0) {
    searchUpdated += `Title: ${searchFilters.title}; `;
  }
  if (searchFilters?.productTypes?.length > 0) {
    searchUpdated += `Product Types: ${buildListForSearchFilters(
      searchFilters.productTypes
    )}; `;
  }
  if (searchFilters?.vendors?.length > 0) {
    searchUpdated += `Vendors: ${buildListForSearchFilters(
      searchFilters.vendors
    )}; `;
  }
  if (searchFilters?.tags?.length > 0) {
    searchUpdated += `Tags: ${buildListForSearchFilters(searchFilters.tags)}; `;
  }
  if (searchFilters?.setNames?.length > 0) {
    searchUpdated += `Set Names: ${buildListForSearchFilters(
      searchFilters.setNames
    )}; `;
  }
  if (searchFilters?.variants?.length > 0) {
    searchUpdated += `Variants: ${buildListForSearchFilters(
      searchFilters.variants
    )}; `;
  }
  if (searchFilters?.colors?.length > 0) {
    searchUpdated += `Colors: ${buildListForSearchFilters(
      searchFilters.colors
    )}; `;
  }
  if (searchFilters?.types?.length > 0) {
    searchUpdated += `Types: ${buildListForSearchFilters(
      searchFilters.types
    )}; `;
  }
  if (searchFilters?.rarities?.length > 0) {
    searchUpdated += `Rarities: ${buildListForSearchFilters(
      searchFilters.rarities
    )}; `;
  }
  if (searchFilters?.rarities?.monsterTypes > 0) {
    searchUpdated += `Monster types: ${buildListForSearchFilters(
      searchFilters.monsterTypes
    )}; `;
  }
  if (searchFilters.priceGreaterThan || searchFilters.priceGreaterThan === 0) {
    searchUpdated += `Price > ${searchFilters.priceGreaterThan}; `;
  }
  if (searchFilters.priceLessThan || searchFilters.priceLessThan === 0) {
    searchUpdated += `Price < ${searchFilters.priceLessThan}; `;
  }
  if (
    searchFilters.overallQuantityGreaterThan ||
    searchFilters.overallQuantityGreaterThan === 0
  ) {
    searchUpdated += `Overall Quantity > ${searchFilters.overallQuantityGreaterThan}; `;
  }
  if (
    searchFilters.overallQuantityLessThan ||
    searchFilters.overallQuantityLessThan === 0
  ) {
    searchUpdated += `Overall Quantity < ${searchFilters.overallQuantityLessThan}; `;
  }
  if (
    searchFilters.quantityGreaterThan ||
    searchFilters.quantityGreaterThan === 0
  ) {
    searchUpdated += `Quantity > ${searchFilters.quantityGreaterThan}; `;
  }
  if (searchFilters.quantityLessThan || searchFilters.quantityLessThan === 0) {
    searchUpdated += `Quantity < ${searchFilters.quantityLessThan}; `;
  }
  if (searchFilters?.barcode?.length > 0) {
    searchUpdated += `Barcode: ${searchFilters.barcode}; `;
  }
  if (searchFilters?.sku?.length > 0) {
    searchUpdated += `Sku: ${searchFilters.sku}; `;
  }
  return searchUpdated.substring(0, searchUpdated.length - 2);
};

const buildListForSearchFilters = (list: string[]) => {
  return `[${list.join(", ")}]`;
};

const buildFieldsUpdate = (
  jobData: ProductQueuedJob,
  fieldsUpdated: any,
  currencyFormatter: (amount: number | string) => string
) => {
  let fieldsUpdatedString = "";
  if (jobData.type != "variantUpdate") {
    if (fieldsUpdated.stock || fieldsUpdated.stock === 0) {
      if (fieldsUpdated.stockUpdateType === "add") {
        fieldsUpdatedString +=
          jobData.status === "COMPLETED" ? "Stock added: " : "Adding stock: ";
      } else if (fieldsUpdated.stockUpdateType === "remove") {
        fieldsUpdatedString +=
          jobData.status === "COMPLETED"
            ? "Stock removed: "
            : "Removing stock: ";
      } else {
        fieldsUpdatedString +=
          jobData.status === "COMPLETED"
            ? "Stock set to: "
            : "Setting stock to: ";
      }
      fieldsUpdatedString += `${fieldsUpdated.stock}; `;
    }
    if (fieldsUpdated.priceOverride || fieldsUpdated.priceOverride === 0) {
      fieldsUpdatedString += `Price update: ${currencyFormatter(
        fieldsUpdated.priceOverride
      )}; `;
    }
  }
  if (fieldsUpdated.cashBuyPrice || fieldsUpdated.cashBuyPrice === 0) {
    fieldsUpdatedString += `Cash buy price: ${currencyFormatter(
      fieldsUpdated.cashBuyPrice
    )}; `;
  }
  if (fieldsUpdated.cashBuyPriceRemove) {
    fieldsUpdatedString += "Cash buy price will be removed; ";
  }
  if (
    fieldsUpdated.cashBuyPercentage ||
    fieldsUpdated.cashBuyPercentage === 0
  ) {
    fieldsUpdatedString += `Cash buy percent: ${fieldsUpdated.cashBuyPercentage}%; `;
  }
  if (fieldsUpdated.cashBuyPercentageRemove) {
    fieldsUpdatedString += "Cash buy percent will be removed; ";
  }
  if (fieldsUpdated.creditBuyPrice || fieldsUpdated.creditBuyPrice === 0) {
    fieldsUpdatedString += `Credit buy price: ${currencyFormatter(
      fieldsUpdated.creditBuyPrice
    )}; `;
  }
  if (fieldsUpdated.creditBuyPriceRemove) {
    fieldsUpdatedString += "Credit buy price will be removed; ";
  }
  if (
    fieldsUpdated.creditBuyPercentage ||
    fieldsUpdated.creditBuyPercentage === 0
  ) {
    fieldsUpdatedString += `Credit buy percent: ${fieldsUpdated.creditBuyPercentage}%; `;
  }
  if (fieldsUpdated.creditBuyPercentageRemove) {
    fieldsUpdatedString += "Credit buy percent will be removed; ";
  }
  if (fieldsUpdated.buyLimit || fieldsUpdated.buyLimit === 0) {
    fieldsUpdatedString += `Buy limit: ${fieldsUpdated.buyLimit}; `;
  }
  if (fieldsUpdated.buyLimitRemove) {
    fieldsUpdatedString += "Buy limit will be removed; ";
  }
  if (
    fieldsUpdated.maxInstockBuyPrice ||
    fieldsUpdated.maxInstockBuyPrice === 0
  ) {
    fieldsUpdatedString += `Over-limit Cash buy price: 
      ${currencyFormatter(fieldsUpdated.maxInstockBuyPrice)}; `;
  }
  if (fieldsUpdated.maxInstockBuyPriceRemove) {
    fieldsUpdatedString += "Over-limit Cash buy price will be removed; ";
  }
  if (
    fieldsUpdated.maxInstockBuyPercentage ||
    fieldsUpdated.maxInstockBuyPercentage === 0
  ) {
    fieldsUpdatedString += `Over-limit Cash buy percent: 
      ${fieldsUpdated.maxInstockBuyPercentage}%; `;
  }
  if (fieldsUpdated.maxInstockBuyPercentageRemove) {
    fieldsUpdatedString += "Over-limit Cash buy percent will be removed; ";
  }
  if (
    fieldsUpdated.maxInstockCreditBuyPrice ||
    fieldsUpdated.maxInstockCreditBuyPrice === 0
  ) {
    fieldsUpdatedString += `Over-limit Credit buy price: 
      ${currencyFormatter(fieldsUpdated.maxInstockCreditBuyPrice)}; `;
  }
  if (fieldsUpdated.maxInstockCreditBuyPriceRemove) {
    fieldsUpdatedString += "Over-limit Credit buy price will be removed; ";
  }
  if (
    fieldsUpdated.maxInstockCreditBuyPercentage ||
    fieldsUpdated.maxInstockCreditBuyPercentage === 0
  ) {
    fieldsUpdatedString += `Over-limit Credit buy percent: 
      ${fieldsUpdated.maxInstockCreditBuyPercentage}%; `;
  }
  if (fieldsUpdated.maxInstockCreditBuyPercentageRemove) {
    fieldsUpdatedString += "Over-limit Credit buy percent will be removed; ";
  }
  return fieldsUpdatedString.substring(0, fieldsUpdatedString.length - 2);
};
