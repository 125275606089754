import React, { useState } from "react";
import TimeUtils from "../../utils/TimeUtils";
import "./ItemTitle.scss";

const MAX_TITLE_LENGTH = 55;

type Event = {
  date: unknown;
};

const formatTitle = (title: string, event?: Event) => {
  if (!event) {
    return title;
  }
  const eventDate = TimeUtils.convertDateToHumanReadbleNoTimeZ(event.date);
  return `${title} ${eventDate}`;
};

interface ItemTitleProps {
  title: string;
  event?: Event;
}

function ItemTitle(props: ItemTitleProps) {
  const { title, event } = props;
  const [expandTitle, setExpandTitle] = useState(false);
  const titleText = formatTitle(title, event);

  if (!titleText || titleText.length < MAX_TITLE_LENGTH) {
    return (
      <span className="ItemTitle__text" title={titleText}>
        {titleText}
      </span>
    );
  }

  const truncatedTitle = titleText.substring(0, MAX_TITLE_LENGTH - 3);

  const toggleExpand = () => setExpandTitle(!expandTitle);
  return (
    <div
      className={expandTitle ? "ItemTitle ItemTitle--expanded" : "ItemTitle"}
      onClick={toggleExpand}
    >
      <span
        className="ItemTitle__text ItemTitle__text--expandable"
        title={titleText}
      >
        {expandTitle ? (
          titleText
        ) : (
          <>
            {truncatedTitle}
            &#8230;
          </>
        )}
      </span>
      <span className="ItemTitle__contractButton">
        <i className="fas fa-chevron-down" />
      </span>
    </div>
  );
}

export default ItemTitle;
