import React, { useState } from "react";
import { useDeleteProductMutation } from "../../generated/graphql";
import { notify } from "../../helpers/notificationHelpers";
import { useShowError } from "../../hooks/errorHooks";
import ButtonComponent from "../generic/ButtonComponent";
import ConfirmationModal from "../generic/ConfirmationModal";

interface ProductDeleteButtonProps {
  id: string;
}

function ProductDeleteButton(props: ProductDeleteButtonProps) {
  const { id } = props;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { mutateAsync: deleteProduct, isLoading } = useDeleteProductMutation();
  const showError = useShowError();

  const handleDeleteClick = () => setShowDeleteModal(true);

  const handleCancelClick = () => setShowDeleteModal(false);

  const handleConfirmClick = () =>
    deleteProduct({ id })
      .then(() => {
        notify.info("Product deleted");
        setShowDeleteModal(false);
        // TODO:: Redirect to original page (once known)
      })
      .catch((error) => showError(error));

  if (!id || id === "new") {
    return null;
  }

  return (
    <>
      <ButtonComponent
        danger
        disabled={isLoading}
        onClick={handleDeleteClick}
        icon="fal fa-trash-alt"
      >
        Delete Product
      </ButtonComponent>
      {showDeleteModal ? (
        <ConfirmationModal
          title="Delete Product"
          cancelAction={handleCancelClick}
          confirmWord="Delete"
          confirmAction={handleConfirmClick}
        >
          Are you sure you want to delete this product? This cannot be undone
        </ConfirmationModal>
      ) : null}
    </>
  );
}

export default ProductDeleteButton;
