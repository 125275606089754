import { FormikErrors } from "formik";
import React from "react";
import "./EbayListingFormError.scss";

const hasListingError = (
  listingFields: string[],
  errors: FormikErrors<unknown>
) => {
  let errorsFound = false;
  Object.keys(errors).forEach((key: string) => {
    if (listingFields.includes(key)) {
      errorsFound = true;
    }
  });
  return errorsFound;
};

interface EbayListingFormErrorProps {
  listingFields: string[];
  errors: FormikErrors<unknown>;
}

function EbayListingFormError(props: EbayListingFormErrorProps) {
  const { listingFields, errors } = props;

  if (!hasListingError(listingFields, errors)) return null;

  return (
    <div className="ebay-listing-form-error">
      Please resolve errors in the listing before continuing
    </div>
  );
}

export default EbayListingFormError;
