import {
  Pagination,
  ReportControls,
  SalesSourceSelect,
} from "../../components/reports";
import {
  CurrencyRenderer,
  TextInputFilter,
} from "../../components/reports/CellTypes";
import { API_STATE } from "../../constants/api";
import { ReportsMenuStructure } from "../../menuStructures";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { Table, Column, Cell } from "rsuite-table";
import "rsuite-table/dist/css/rsuite-table.css";
import "../../../css/rstable-overwrites.scss";
import CustomHeaderCell from "../../components/reports/CustomHeader";
import { useReportsDetails } from "../../hooks/reportsHooks";
import { useStoreDetails } from "../../hooks/storeHooks";
import { useShowError } from "../../hooks/errorHooks";
import CheckboxComponent from "../../components/generic/CheckboxComponent";
import {
  salesByOrderBetaCSVHeaders1,
  salesByOrderBetaCSVHeaders2,
} from "../../constants/reportCSVHeaders";
import { rowPerPageOptions } from "../../constants/reports";
import SetActiveMenu from "../../menuStructures/SetActiveMenu";
import { useFetchSalesByOrderBeta } from "../../hooks/reports/useFetchSalesByOrderBeta";
import { addLocalizedDate } from "../../transformers/helpers/addLocalizedDate";
import { useLocation } from "react-router-dom";
import { parseSearchParams } from "../../helpers/reportHelpers";

function SalesByOrderBeta() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search).get("filters");
  const parsedSearchParams = parseSearchParams(searchParams);
  const sourcesParam = parsedSearchParams?.sources
    ? parsedSearchParams.sources
    : {};
  const {
    apiState,
    currentPage,
    filterValues,
    overrideTableHeight,
    rowsPerPage,
    sortColumn,
    tableData,
    computedTableData,
    reset,
    setApiState,
    setCurrentPage,
    setTableRowsPerPage,
    setFilters,
    setSortingColumn,
    setSourceDataDetails,
    updateFilterValue,
  } = useReportsDetails();
  const { currencyCode } = useStoreDetails();
  const showError = useShowError();
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [timeZone, setTimeZone] = useState<string>(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [showFilterSourceModal, setShowFilterSourceModal] = useState(false);
  const [filterSources, setFilterSources] = useState({});
  const [tenderTypeNames, setTenderTypeNames] = useState([]);
  const [sourceTypeNames, setSourceTypeNames] = useState([]);
  const [showTenders, setShowTenders] = useState(false);

  const {
    data: sourceData,
    isFetching,
    error,
  } = useFetchSalesByOrderBeta(
    { startDate, endDate },
    { enabled: !!startDate && !!endDate }
  );

  useEffect(() => {
    document.title = "Reports | BinderPOS";
    reset();
    setFilters(filters);
  }, []);

  useEffect(() => {
    if (isFetching) {
      setApiState(API_STATE.LOADING);
    } else if (error) {
      showError(
        error as any,
        "Failed to load report",
        "There was an error retrieving your sales by order report. Please try again"
      );
      setApiState(API_STATE.ERROR);
    } else if (sourceData) {
      onQuerySuccessHandler(sourceData);
      setApiState(API_STATE.SUCCESS);
    }
  }, [isFetching, sourceData, error]);

  const filters = [
    {
      name: "orderId",
      condition: (filterValues) => filterValues?.orderId,
      filterFunction: (row, filterValues) =>
        row.orderId?.includes(filterValues?.orderId),
    },
    {
      name: "source",
      condition: () => true,
      filterFunction: (row, filterValues) =>
        row.source && filterValues?.sources?.[row.source],
    },
  ];

  const updateFilterSources = (sources) => {
    setFilterSources(sources);
    updateFilterValue("sources", sources);
  };

  const toggleFilterSources = (selected) => {
    updateFilterSources(selected);
    setShowFilterSourceModal(false);
  };

  const onQuerySuccessHandler = (data) => {
    const { rows, tenders, sources } = data;
    setSourceTypeNames(sources);
    setTenderTypeNames(tenders);
    const selectedSources = sources.reduce((selected, source) => {
      if (sourcesParam[source] !== undefined) {
        selected[source] = sourcesParam[source];
      } else {
        selected[source] = true;
      }
      return selected;
    }, {});
    toggleFilterSources(selectedSources);
    setSourceDataDetails(rows);
  };

  const handleSetDateRange = (fromDate, toDate, timeZone) => {
    setTimeZone(timeZone);
    setStartDate(fromDate);
    setEndDate(toDate);
  };

  const getColumnTotal = (tableData, key) => {
    const tenderType = key.split("_")?.pop();
    if (!tenderType) return;
    return (
      tableData.length &&
      tableData.reduce((sum, row) => sum + (row[tenderType] || 0), 0)
    );
  };

  const getTenderTotal = (tableData, key) => {
    const tenderType = key.split("_^")?.pop();
    if (!tenderType) return;
    return (
      tableData.length &&
      tableData.reduce(
        (sum, row) =>
          sum +
          ((row.tenders &&
            row.tenders.find((tender) => tender.type === tenderType)?.amount) ||
            0),
        0
      )
    );
  };

  const { orderId = "" } = filterValues;

  const csvFilename = "Sales By Order (beta).csv";

  const data = addLocalizedDate(computedTableData(), timeZone, "createdAt");

  const csvTenderHeaders = showTenders
    ? tenderTypeNames.reduce(
        (arr, tender) => [
          ...arr,
          {
            name: tender,
            key: tender,
            formatter: (number) => number && number.toFixed(2),
          },
        ],
        []
      )
    : [];

  const csvHeaders = [
    ...salesByOrderBetaCSVHeaders1,
    ...csvTenderHeaders,
    ...salesByOrderBetaCSVHeaders2,
  ];

  return (
    <Fragment>
      <SetActiveMenu menuStructure={ReportsMenuStructure} />
      <div className="app-header">
        <h1 className="title">
          <span className="header-text">Sales By Order - Beta</span>
        </h1>
      </div>
      <div className="report">
        <ReportControls
          setDateRange={handleSetDateRange}
          csvHeaders={csvHeaders}
          csvFilename={csvFilename}
        >
          <span className="checkbox">
            <CheckboxComponent
              name="showTenders"
              label="Show Tenders"
              onChange={(e) => setShowTenders(e.target.checked)}
            />
          </span>
        </ReportControls>
        {apiState !== API_STATE.INITIAL && (
          <div className="data-table">
            <div className="table-container">
              <Table
                data={data}
                headerHeight={80}
                sortColumn={sortColumn.key}
                sortType={sortColumn.order}
                onSortColumn={setSortingColumn}
                renderEmpty={() => <div>No data to display</div>}
                loading={apiState == API_STATE.LOADING}
                className="rs-table--multi-header"
                rowKey="orderId"
                fillHeight={overrideTableHeight === 0}
              >
                <Column key="col_date" width={200} sortable resizable>
                  <CustomHeaderCell title="Date" />
                  <Cell dataKey="createdAt">
                    {(rowData) => rowData.createdAt_localized}
                  </Cell>
                </Column>
                <Column key="col_order_id" width={150} sortable resizable>
                  <CustomHeaderCell title="Order Id">
                    <TextInputFilter
                      aria-label="Filter by order ID"
                      title="Order Id"
                      value={orderId}
                      setValue={(value) => updateFilterValue("orderId", value)}
                    />
                  </CustomHeaderCell>
                  <Cell dataKey="orderId" />
                </Column>
                <Column
                  key="col_source"
                  width={150}
                  align="right"
                  sortable
                  resizable
                >
                  <CustomHeaderCell title="Source">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => setShowFilterSourceModal(true)}
                    >
                      Sources
                    </button>
                  </CustomHeaderCell>
                  <Cell dataKey="source">
                    {(rowData) => rowData.source?.replace(/_/g, " ")}
                  </Cell>
                </Column>
                {showTenders &&
                  tenderTypeNames.map((tenderType, index) => (
                    <Column
                      key={`col_tender_${index}_^${tenderType}`}
                      width={110}
                      align="right"
                      sortable
                      resizable
                    >
                      <CustomHeaderCell title={tenderType}>
                        {CurrencyRenderer({
                          currency: currencyCode,
                          cellData: getTenderTotal(tableData, tenderType),
                        })}
                      </CustomHeaderCell>
                      <Cell dataKey={tenderType}>
                        {(rowData) =>
                          CurrencyRenderer({
                            cellData: rowData[tenderType],
                            currency: currencyCode,
                          })
                        }
                      </Cell>
                    </Column>
                  ))}
                <Column
                  key="col_sale"
                  width={150}
                  align="right"
                  sortable
                  resizable
                >
                  <CustomHeaderCell title="Sales">
                    {CurrencyRenderer({
                      currency: currencyCode,
                      cellData: getColumnTotal(tableData, "sale"),
                    })}
                  </CustomHeaderCell>
                  <Cell dataKey="sale">
                    {(rowData) =>
                      CurrencyRenderer({
                        cellData: rowData.sale,
                        currency: currencyCode,
                      })
                    }
                  </Cell>
                </Column>
                <Column
                  key="col_refund"
                  width={150}
                  align="right"
                  sortable
                  resizable
                >
                  <CustomHeaderCell title="Refunds">
                    {CurrencyRenderer({
                      currency: currencyCode,
                      cellData: getColumnTotal(tableData, "refund"),
                    })}
                  </CustomHeaderCell>
                  <Cell dataKey="refund">
                    {(rowData) =>
                      CurrencyRenderer({
                        cellData: rowData.refund,
                        currency: currencyCode,
                      })
                    }
                  </Cell>
                </Column>
                <Column
                  key="col_totalTax"
                  width={100}
                  align="right"
                  sortable
                  resizable
                >
                  <CustomHeaderCell title="Tax">
                    {CurrencyRenderer({
                      currency: currencyCode,
                      cellData: tableData?.reduce(
                        (sum, row) => sum + row.totalTax,
                        0
                      ),
                    })}
                  </CustomHeaderCell>
                  <Cell dataKey="totalTax">
                    {(rowData) =>
                      CurrencyRenderer({
                        cellData: rowData.totalTax,
                        currency: currencyCode,
                      })
                    }
                  </Cell>
                </Column>
                <Column
                  key="col_beforeTax"
                  width={150}
                  align="right"
                  sortable
                  resizable
                >
                  <CustomHeaderCell title="Sales Before Tax">
                    {CurrencyRenderer({
                      currency: currencyCode,
                      cellData: getColumnTotal(tableData, "beforeTax"),
                    })}
                  </CustomHeaderCell>
                  <Cell dataKey="beforeTax">
                    {(rowData) =>
                      CurrencyRenderer({
                        cellData: rowData.beforeTax,
                        currency: currencyCode,
                      })
                    }
                  </Cell>
                </Column>
                <Column
                  key="col_buy"
                  width={150}
                  align="right"
                  sortable
                  resizable
                >
                  <CustomHeaderCell title="Buys">
                    {CurrencyRenderer({
                      currency: currencyCode,
                      cellData: getColumnTotal(tableData, "buy"),
                    })}
                  </CustomHeaderCell>
                  <Cell dataKey="buy">
                    {(rowData) =>
                      CurrencyRenderer({
                        cellData: rowData.buy,
                        currency: currencyCode,
                      })
                    }
                  </Cell>
                </Column>
                <Column
                  key="col_total"
                  width={150}
                  align="right"
                  sortable
                  resizable
                >
                  <CustomHeaderCell title="Total (Incl. Tax)">
                    {CurrencyRenderer({
                      currency: currencyCode,
                      cellData: tableData?.reduce(
                        (sum, row) => sum + row.total,
                        0
                      ),
                      style: { textAlign: "right", paddingRight: "0.8em" },
                    })}
                  </CustomHeaderCell>
                  <Cell dataKey="total">
                    {(rowData) =>
                      CurrencyRenderer({
                        cellData: rowData.total,
                        currency: currencyCode,
                        style: { textAlign: "right", paddingRight: "0.8em" },
                      })
                    }
                  </Cell>
                </Column>
              </Table>
            </div>
            <Pagination
              apiState={apiState}
              currentPage={currentPage}
              totalRowCount={tableData.length}
              rowsPerPage={rowsPerPage}
              rowPerPageOptions={rowPerPageOptions}
              setCurrentPage={setCurrentPage}
              setRowsPerPage={setTableRowsPerPage}
            />
          </div>
        )}
      </div>
      {showFilterSourceModal && (
        <SalesSourceSelect
          options={sourceTypeNames}
          value={filterSources}
          setValue={toggleFilterSources}
          onClose={() => setShowFilterSourceModal(false)}
        />
      )}
    </Fragment>
  );
}

SalesByOrderBeta.propTypes = { store: PropTypes.object };
export default SalesByOrderBeta;
