import React, { useRef, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { MenuStructure, RootMenuItem } from "../../types/nav";
import { joinClasses } from "../../utils/styling";
import { segmentAnalytics } from "../services/Analytics";
import { MOBILE_MENU_CLICKED } from "../../constants/eventsTracked";
import "./NewMobileNavItem.scss";
import NewMobileNavItemLink from "./NewMobileNavItemLink";

interface NewMobileNavItemProps {
  handleClick: (menu: MenuStructure) => void;
  menuItem: RootMenuItem;
}

function NewMobileNavItem({ handleClick, menuItem }: NewMobileNavItemProps) {
  const navItemRef = useRef<HTMLDivElement>();
  const { iconClass, target, title, subMenu } = menuItem;
  const history = useHistory();
  const isNavItemActive = target
    ? history.location.pathname.startsWith(target)
    : false;
  const [isNavItemOpen, setNavItemOpen] = useState(isNavItemActive);
  const iconClasses = joinClasses(["NewMobileNavItem__icon", iconClass]);

  if (!target) {
    return (
      <li className="NewMobileNavItem">
        <button
          className="NewMobileNavItem__title"
          onClick={() => handleClick(null)}
        >
          <i className={iconClasses} />
          {title}
        </button>
      </li>
    );
  }

  if (!subMenu || subMenu.links.length < 1) {
    return (
      <li className="NewMobileNavItem">
        <NavLink
          className="NewMobileNavItem__link"
          activeClassName="active"
          to={target}
          onClick={() => {
            segmentAnalytics.track(MOBILE_MENU_CLICKED, {
              menu_title: menuItem.title,
            });
            handleClick(null);
          }}
        >
          <i className={iconClasses} />
          {title}
        </NavLink>
      </li>
    );
  }

  const titleClasses = joinClasses([
    "NewMobileNavItem__title",
    isNavItemActive ? "active" : null,
  ]);

  return (
    <li className="NewMobileNavItem">
      <button
        className={titleClasses}
        onMouseDown={(e) => {
          e.preventDefault();
          setNavItemOpen(!isNavItemOpen);
        }}
        onFocus={() => setNavItemOpen(true)}
      >
        <i className={iconClasses} />
        {title}
        {isNavItemOpen ? (
          <i className="NewMobileNavItem__chevron far fa-chevron-up" />
        ) : (
          <i className="NewMobileNavItem__chevron far fa-chevron-down" />
        )}
      </button>
      <div
        className="NewMobileNavItem__wrapper"
        ref={navItemRef}
        style={
          isNavItemOpen
            ? { height: navItemRef.current?.scrollHeight }
            : { height: 0 }
        }
      >
        {subMenu?.links?.map((link) => (
          <NewMobileNavItemLink
            key={link.title}
            navItemLink={link}
            menuClickHandler={handleClick}
            parentStructure={subMenu}
            setNavItemOpen={setNavItemOpen}
          />
        ))}
      </div>
    </li>
  );
}

export default NewMobileNavItem;
