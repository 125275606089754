import React from "react";
import DOMPurify from "dompurify";

interface HtmlComponentProps {
  children: string | Node;
  className?: string;
}

function HtmlComponent(props: HtmlComponentProps) {
  const { children, className } = props;

  const sanitizedData = () => ({
    __html: DOMPurify.sanitize(children),
  });

  return (
    <div className={className} dangerouslySetInnerHTML={sanitizedData()} />
  );
}

export default HtmlComponent;
