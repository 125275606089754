import { useNavigationPrompt } from "../../hooks/useNavigationPrompt";
import { useEffect, useRef } from "react";

interface NavigationPromptWatcherProps {
  dirty: boolean;
  formName: string;
  children: React.ReactNode;
}

function NavigationPromptWatcher({
  dirty,
  formName,
  children,
}: NavigationPromptWatcherProps) {
  const { setFormIsEditing } = useNavigationPrompt();
  const firstUpdate = useRef(true);

  useEffect(() => {
    setFormIsEditing(false, formName);
  }, []);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      setFormIsEditing(dirty, formName);
    }
  }, [dirty, formName]);

  return <>{children}</>;
}

export default NavigationPromptWatcher;
