import { ReactNode } from "react";
import "./Spinner.scss";

interface SpinnerProps {
  isLoading: boolean;
  children?: ReactNode;
}

const Spinner = ({ isLoading, children }: SpinnerProps) => {
  if (!isLoading) {
    return <>{children}</>;
  }

  return (
    <div className="Spinner" data-testid="loader">
      <div className="Spinner__overlay">
        <div className="Spinner__border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
      {children}
    </div>
  );
};

export default Spinner;
