import React, { useState } from "react";
import { PaymentProvider } from "../../types/paymentTerminal";
import { TillSetting } from "../../types/settings";
import { findTillSettingValue } from "../../helpers/settingHelpers";
import PaymentGatewayApiSettings from "./PaymentGatewayApiSettings";
import PaymentGatewayDeviceList from "./PaymentGatewayDeviceList";

const getProviderSettingsCheck = (provider: PaymentProvider) => {
  if (provider === "USAePay") {
    return "usaepayApiDetailsSet";
  }
  if (provider === "mxMerchant") {
    return "mxMerchantApiDetailsSet";
  }
  throw new Error("Invalid provider specified");
};

interface PaymentGatewaySettingsProps {
  paymentProvider: PaymentProvider;
  tillId: number;
  tillSettings: TillSetting[];
}

function PaymentGatewaySettings(props: PaymentGatewaySettingsProps) {
  const { paymentProvider, tillId, tillSettings } = props;
  const settingToCheck = getProviderSettingsCheck(paymentProvider);
  const gatewayApiConfigured =
    findTillSettingValue(tillSettings, settingToCheck) === "true";
  const [apiConfigured, setApiConfigured] = useState(gatewayApiConfigured);

  const onApiConfigured = () => setApiConfigured(true);

  return (
    <div className="PaymentGatewaySettings">
      <PaymentGatewayApiSettings
        gatewayApiConfigured={gatewayApiConfigured}
        onApiConfigured={onApiConfigured}
        merchantId={findTillSettingValue(tillSettings, "mxMerchantId")}
        paymentProvider={paymentProvider}
        tillId={tillId}
      />
      {apiConfigured ? (
        <PaymentGatewayDeviceList
          paymentProvider={paymentProvider}
          tillId={tillId}
          tillSettings={tillSettings}
        />
      ) : null}
    </div>
  );
}

export default PaymentGatewaySettings;
