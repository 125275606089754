import { inject, observer } from "mobx-react";
import React from "react";
import { withRouter } from "react-router-dom";
import DesktopHeader from "./DesktopHeader";
import MobileHeader from "./MobileHeader";

@inject("store", "posStore")
@observer
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuOpen: false,
    };
  }

  setMobileMenuOpen = (open) => {
    this.setState({ mobileMenuOpen: open });
  };

  updateMenu = (menu) => {
    this.props.sideMenuUpdate(menu);
  };

  onMobileMenuSelect = (menu) => {
    this.props.sideMenuUpdate(menu);
  };

  componentDidMount() {
    this.props.store.WindowSizeStore.setWindow();
  }

  render() {
    const currentTill = this.props.posStore.tillList.find(
      (till) => till.id == this.props.posStore.tillId
    )?.name;
    return (
      <>
        {this.props.store.WindowSizeStore.windowWidth > 1365 ||
        this.props.location.pathname == "/pos" ? (
          <DesktopHeader
            storeName={this.props.store.SettingsStore?.storeSettings?.name}
            currentTill={currentTill}
            sideMenu={this.props.sideMenu}
            screenSettings={this.props.screenSettings}
            updateMenu={this.updateMenu}
            handleLogout={this.props.store.AuthStore.logUserOut.bind(
              this.props.store.AuthStore
            )}
          />
        ) : (
          <MobileHeader
            screenSettings={this.props.screenSettings}
            mobileMenuOpen={this.state.mobileMenuOpen}
            setMobileMenuOpen={this.setMobileMenuOpen}
            handleClick={this.onMobileMenuSelect}
            handleLogout={this.props.store.AuthStore.logUserOut.bind(
              this.props.store.AuthStore
            )}
          />
        )}
      </>
    );
  }
}

export default withRouter(Header);
