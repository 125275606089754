import React from "react";
import "./EbayShippingServiceDetails.scss";

interface EbayShippingServiceDetailsProps {
  id?: unknown;
  serviceName: string;
  shippingCarriers?: string[];
  shippingPackages?: string[];
  serviceTypes?: string[];
  shippingCategory: string;
  description: string;
  shippingTimeMax?: number;
  shippingTimeMin?: number;
  internationalShipping: boolean;
  weightRequired: boolean;
  dimensionsRequired: boolean;
  surchargeApplicable: boolean;
  expeditedService: boolean;
}

const getSymbol = (prop: boolean) => (
  <i className={`fas fa-${prop ? "check" : "times"}-circle`} />
);

const formatList = (items?: string[]) => {
  if (items) {
    return items.join(", ");
  }
  return "Not specified";
};

function EbayShippingServiceDetails(props: EbayShippingServiceDetailsProps) {
  const {
    description,
    shippingCarriers,
    shippingPackages,
    serviceTypes,
    shippingCategory = "Not specified",
    shippingTimeMax = "Not specified",
    shippingTimeMin = "Not specified",
    internationalShipping,
    weightRequired,
    dimensionsRequired,
    surchargeApplicable,
    expeditedService,
  } = props;

  return (
    <div className="ebay-shipping-service-details">
      <h6 className="ebay-shipping-service-details__title">{description}</h6>
      <div className="ebay-shipping-service-details__details">
        <span className="ebay-shipping-service-details__label">Carriers:</span>
        <span className="ebay-shipping-service-details__value">
          {formatList(shippingCarriers)}
        </span>
        <span className="ebay-shipping-service-details__label">
          Package sizes:
        </span>
        <span className="ebay-shipping-service-details__value">
          {formatList(shippingPackages)}
        </span>
        <span className="ebay-shipping-service-details__label">
          Service Types:
        </span>
        <span className="ebay-shipping-service-details__value">
          {formatList(serviceTypes)}
        </span>
        <span className="ebay-shipping-service-details__label">Category:</span>
        <span className="ebay-shipping-service-details__value">
          {shippingCategory || "Not specified"}
        </span>
        <span className="ebay-shipping-service-details__label">
          Shipping times:
        </span>
        <span className="ebay-shipping-service-details__value">
          {shippingTimeMin && shippingTimeMax
            ? `${shippingTimeMin} - ${shippingTimeMax} days`
            : "Not specified"}
        </span>
      </div>
      <div className="ebay-shipping-service-details__summary">
        <span>International: {getSymbol(internationalShipping)}</span>
        <span>Weight required: {getSymbol(weightRequired)}</span>
        <span>Dimensions required: {getSymbol(dimensionsRequired)}</span>
        <span>Surcharge applicable: {getSymbol(surchargeApplicable)}</span>
        <span>Expedited service: {getSymbol(expeditedService)}</span>
      </div>
    </div>
  );
}

export default EbayShippingServiceDetails;
