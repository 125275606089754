import React, { useEffect, useState } from "react";
import { DateTimeFormatOptions } from "luxon/src/luxon";
import {
  useUpdateBinderCustomerShopifyThemeMutation,
  ReleaseTrain,
} from "../../generated/graphql";
import { notify } from "../../helpers/notificationHelpers";
import { useShowError } from "../../hooks/errorHooks";
import ButtonComponent from "../generic/ButtonComponent";
import SubSectionHeaderLayout from "../layout/SubSectionHeaderLayout";
import "./ReleaseTrainSelect.scss";

const releaseTrains = {
  NO_UPDATES: "No automatic updates",
  ALL: "All updates",
  WEEKLY: "Weekly updates",
  FORTNIGHTLY: "Fortnightly updates",
};

interface ReleaseTrainProps {
  activeReleaseTrain: ReleaseTrain;
  theme: string;
}

function ReleaseTrainSelect(props: ReleaseTrainProps) {
  const { activeReleaseTrain, theme } = props;
  const [selectedTrain, setSelectedTrain] =
    useState<ReleaseTrain>(activeReleaseTrain);
  const { mutateAsync, isLoading } =
    useUpdateBinderCustomerShopifyThemeMutation();
  const showError = useShowError();

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    setSelectedTrain(value as ReleaseTrain);
  };

  useEffect(() => setSelectedTrain(activeReleaseTrain), [activeReleaseTrain]);

  const handleSave = () =>
    mutateAsync({
      data: {
        releaseTrain: {
          set: selectedTrain,
        },
      },
      theme,
    })
      .then(() => notify.info("Changes saved"))
      .catch((error) => showError(error, "Failed to save", "Please try again"));

  const dateOptions: DateTimeFormatOptions = {
    weekday: "long",
  };

  const timeOptions: DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
  };

  const releasesAt = new Date("2022-08-01T21:00:00Z");

  return (
    <div className="ReleaseTrainSelect">
      <SubSectionHeaderLayout title="Release Train" />
      <p>
        The release train determines how frequently theme updates are applied to
        your store. You can have updates applied each day they are released (All
        updates), weekly, fortnightly or you may opt out of automatic updates
        altogether.
      </p>

      <p>
        Weekly and fortnightly releases are deployed every{" "}
        {releasesAt.toLocaleString(undefined, dateOptions)} at{" "}
        {releasesAt.toLocaleString(undefined, timeOptions)}. Daily updates, when
        available, are released at{" "}
        {releasesAt.toLocaleString(undefined, timeOptions)} (21:00 GMT).
      </p>
      <div className="ReleaseTrainSelect__controls">
        <select
          aria-label="Select update releases frequency"
          value={selectedTrain}
          onChange={handleChange}
          className="ReleaseTrainSelect__select"
        >
          {Object.entries(releaseTrains).map(([value, label]) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
        <ButtonComponent primary onClick={handleSave} disabled={isLoading}>
          Save Changes
        </ButtonComponent>
      </div>
    </div>
  );
}

export default ReleaseTrainSelect;
