import { useEffect } from "react";
import { groupCatalogs } from "../../helpers/catalogHelpers";
import { useShowError } from "../../hooks/errorHooks";
import { useFetchCatalogs } from "../../hooks/settingHooks";
import useTitle from "../../hooks/useTitle";
import SettingsMenu from "../../menuStructures/SettingsMenu";
import Spinner from "../../components/generic/Spinner";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";
import CatalogComponent from "../../components/settings/CatalogComponent";

function CatalogSettings() {
  const { data, isLoading, error } = useFetchCatalogs();
  const showError = useShowError();
  useTitle("Catalog Settings");

  useEffect(() => {
    if (error) {
      showError(error as DetailedError);
    }
  }, [error]);

  const catalogs = groupCatalogs(data);

  return (
    <div className="CatalogSettings">
      <SettingsMenu />
      <SectionHeaderLayout title="Catalog Settings" />
      <p>
        BinderPOS provides multiple product catalogs to sell via your online and
        in-store sales channels.
      </p>
      <p>
        Select the catalogs below which you would like added to your store. When
        you enable a catalog, items will begin populating in your store.
        Depending on the product line it may take several hours for all items to
        be available.
      </p>
      <Spinner isLoading={isLoading}>
        {catalogs.map((catalogGroup, index) => (
          <CatalogComponent key={index} catalogGroup={catalogGroup} />
        ))}
      </Spinner>
    </div>
  );
}

export default CatalogSettings;
