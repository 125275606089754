import { _fetch } from "../../api";
import { API_V2_BASE_URL, BASE_URL } from "../../constants/api";

export type Setting = {
  id?: number;
  syncName?: string;
  customerId?: string;
  game: string;
  setName?: string;
  productType?: string;
  vendor?: string;
  syncEnabled: boolean;
  priceMarkup: number | "";
  reserveStock?: number | "";
  maxToList?: number | "";
  minPrice?: number | "";
  maxPrice?: number | "";
  categoryId?: string;
  categoryName?: string;
  fulfilmentPolicyId?: string;
  merchantLocationKey?: string;
  paymentPolicyId?: string;
  returnPolicyId?: string;
  ebayCondition?: string;
  applyTax?: false;
  vatPercentage?: number | "";
};

export type Game = {
  gameId: string;
  gameName: string;
};

export type AdvancedSearchParameters = {
  game: string;
  productTypes: string[];
  vendors: string[];
  title?: string;
  variants: string[];
  tags: string[];
  barcode?: string;
  sku?: string;
  priceGreaterThan?: number | "";
  priceLessThan?: number | "";
  overallQuantityGreaterThan?: number | "";
  overallQuantityLessThan?: number | "";
  quantityGreaterThan?: number | "";
  quantityLessThan?: number | "";
  rarities: string[];
  setNames: string[];
  types: string[];
  colors: string[];
  monsterTypes: string[];
  priceOverrideType?: string;
};

export const fetchIntegrationSyncSettings = (
  integration: string,
  offset: number,
  limit: number
) =>
  _fetch({
    method: "GET",
    endpoint:
      `${BASE_URL}/syncsettings?syncName=${integration}` +
      `&offset=${offset}&limit=${limit}`,
  });

export const fetchSyncSettingDetails = (settingId: number) =>
  _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/syncsettings/${settingId}`,
  });

export const createIntegrationSyncSetting = (
  integrationSettingParameters: Setting
) =>
  _fetch({
    method: "POST",
    endpoint: `${BASE_URL}/syncsettings/add`,
    payload: integrationSettingParameters,
  });

export const updateIntegrationSyncSetting = (
  integrationSettingParameters: Setting
) =>
  _fetch({
    method: "PUT",
    endpoint: `${BASE_URL}/syncsettings/update`,
    payload: integrationSettingParameters,
  });

export const deleteIntegrationSyncSetting = (id: number) =>
  _fetch({
    method: "DELETE",
    endpoint: `${BASE_URL}/syncsettings/${id}/remove`,
  });

export const fetchSyncSettingsProductCount = (
  integrationSettingParameters: Setting
) =>
  _fetch({
    method: "POST",
    endpoint: `${BASE_URL}/syncsettings/count`,
    payload: integrationSettingParameters,
  });

export const fetchCardGames = () =>
  _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/cards/games/supported`,
  });

export const fetchCardSetNames = (game: string) =>
  _fetch({
    method: "GET",
    endpoint: `${API_V2_BASE_URL}/cards/${game}/sets`,
  });

export const fetchAvailableIntegrations = (): Promise<IntegrationDetails[]> =>
  _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/integrations/forMe`,
  });

export const fetchProductTypes = () =>
  _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/products/productTypes`,
  });

export const fetchProductTypesExcludingSupportedCatalogs = () =>
  _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/products/productTypes?excludeSupportedCatalogs=true`,
  });

export const fetchTags = () =>
  _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/products/tags`,
  });

export const fetchVendors = () =>
  _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/products/vendors`,
  });

export const fetchVariants = (game: string) =>
  _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/variants/byGame/${game}/types`,
  });

export const fetchRarities = (game: string) =>
  _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/cards/${game}/rarities`,
  });

export const fetchTypes = (game: string) =>
  _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/cards/${game}/types`,
  });

export const fetchMonsterTypes = (game: string) =>
  _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/cards/${game}/monsterTypes`,
  });

export const fetchColors = (game: string) =>
  _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/cards/${game}/colors`,
  });

export const fetchAdvancedSearchResults = (
  limit: number,
  offset: number,
  advancedSearchParameters: Record<string, unknown>
) =>
  _fetch({
    method: "POST",
    endpoint: `${BASE_URL}/variantsyncsettings/advancedSearch`,
    payload: {
      limit,
      offset,
      ...advancedSearchParameters,
    },
  });

export const fetchVariantSyncSettings = (
  integration: string,
  offset: number,
  limit: number
) =>
  _fetch({
    method: "GET",
    endpoint:
      `${BASE_URL}/variantsyncsettings?syncName=${integration}` +
      `&offset=${offset}&limit=${limit}`,
  });

export const createVariantSyncSettings = (settings: unknown) =>
  _fetch({
    method: "POST",
    endpoint: `${BASE_URL}/variantsyncsettings/add`,
    payload: settings,
  });

export const updateVariantSyncSettings = (settings: unknown) =>
  _fetch({
    method: "PUT",
    endpoint: `${BASE_URL}/variantsyncsettings/update`,
    payload: settings,
  });

export const removeVariantSyncSettings = (
  integration: string,
  variantId: number
) =>
  _fetch({
    method: "DELETE",
    endpoint: `${BASE_URL}/variantsyncsettings/${variantId}/${integration}/remove`,
  });

export const fetchAttributesForGame = (game: string) =>
  _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/attributesFor/${game}?forTitle=false`,
  });

export const fetchTitleAttributesForGame = (game: string) =>
  _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/attributesFor/${game}?forTitle=true`,
  });
