import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { replaceNullWithDefault } from "../../helpers/formHelpers";
import { notify } from "../../helpers/notificationHelpers";
import { useShowError } from "../../hooks/errorHooks";
import {
  useFetchUserById,
  useCreateUser,
  useUpdateUser,
} from "../../hooks/userHooks";
import useTitle from "../../hooks/useTitle";
import SettingsMenu from "../../menuStructures/SettingsMenu";
import { userSchema } from "../../schemas/user";
import { Formik, Form, Field } from "formik";
import Input from "../../components/form/Input";
import ButtonComponent from "../../components/generic/ButtonComponent";
import Loader from "../../components/generic/Loader";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";
import "./User.scss";

type RouteParams = {
  userId?: string;
};

interface UserForm {
  id?: number;
  firstName: string;
  lastName: string;
  email: string;
  password?: string;
  confirmPassword?: string;
}

const initialValues: UserForm = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
};

function User() {
  const { userId } = useParams<RouteParams>();
  useTitle(userId ? "Update User" : "Add User");
  const { data: user, isLoading, error } = useFetchUserById(userId);
  const showError = useShowError();
  const useSaveUser = user?.id ? useUpdateUser : useCreateUser;
  const { mutateAsync: saveUser, isMutating: isSaving } = useSaveUser();

  useEffect(() => {
    if (error) {
      showError(
        error as DetailedError,
        "Failed to load user details",
        "There was an error loading the user details. Please refresh and try again"
      );
    }
  }, [error]);

  const handleSave = (values: UserForm) => {
    const saveValues = { ...values };
    delete saveValues.confirmPassword;
    return saveUser(saveValues)
      .then(() => {
        notify.info(user?.id ? "Changes saved" : "User added");
      })
      .catch((saveError) =>
        showError(
          saveError,
          "Failed to save user",
          "There was an error saving your user details. Please try again"
        )
      );
  };

  if (isLoading) {
    return (
      <>
        <SettingsMenu />
        <Loader />
      </>
    );
  }

  const sectionTitle = user?.id ? `Update ${user.email}` : "Add user";

  return (
    <div className="User">
      <SettingsMenu />
      <Formik
        initialValues={replaceNullWithDefault(user, initialValues)}
        validationSchema={userSchema}
        onSubmit={handleSave}
      >
        <Form>
          <SectionHeaderLayout title={sectionTitle}>
            <ButtonComponent primary type="submit" disabled={isSaving}>
              {user?.id ? "Update" : "Save"}
            </ButtonComponent>
          </SectionHeaderLayout>

          <Field
            component={Input}
            name="email"
            placeholder="E.g. example@binderpos.com"
            type="text"
            label="Email address"
          />
          <div className="User__name-container">
            <div>
              <Field
                component={Input}
                name="firstName"
                type="text"
                label="First name"
              />
            </div>
            <div>
              <Field
                component={Input}
                name="lastName"
                type="text"
                label="Last name"
              />
            </div>
          </div>
          {!user?.id ? (
            <>
              <Field
                component={Input}
                name="password"
                type="password"
                label="Password"
                placeholder="****************"
              />

              <Field
                component={Input}
                name="confirmPassword"
                type="password"
                label="Confirm password"
                placeholder="****************"
              />
            </>
          ) : null}

          <ButtonComponent primary type="submit" disabled={isSaving}>
            {user?.id ? "Save and update user" : "Save and add new user"}
          </ButtonComponent>
        </Form>
      </Formik>
    </div>
  );
}

export default User;
