export const remapTenderName = (name: string): string => {
  if (name === "Credit") return "Credit Card";
  return name;
};

export const getTenderIcon = (tender: string): string => {
  if (tender === "Cash") return "fas fa-money-bill-wave";
  if (tender === "Credit") return "fas fa-credit-card-front";
  if (tender === "EFTPOS") return "fas fa-credit-card";
  return "fas fa-money-bill-wave";
};
