import React from "react";
import { fetchCSVExportFile } from "../../api/rest/products";
import { Column } from "../../types/table";
import ButtonComponent from "../generic/ButtonComponent";
import { segmentAnalytics } from "../services/Analytics";
import { DOWNLOAD_PRODUCT_CSV_EXPORT_HISTORY_CLICKED } from "../../constants/eventsTracked";

const CSVExportHistoryTableColumns: Column[] = [
  {
    header: "Id",
    accessorKey: "id",
  },
  {
    header: "Date Exported",
    accessorKey: "createdAt",
  },
  {
    header: "Download",
    accessorKey: "id",
    cell: (id: string) => (
      <ButtonComponent
        data-testid={`download-${id}`}
        onClick={() => {
          segmentAnalytics.track(DOWNLOAD_PRODUCT_CSV_EXPORT_HISTORY_CLICKED);
          fetchCSVExportFile(id);
        }}
        icon="far fa-file-download"
      >
        Download
      </ButtonComponent>
    ),
  },
];

export default CSVExportHistoryTableColumns;
