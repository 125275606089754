import React, { useEffect, useState } from "react";
import { authCallback } from "../../../api/rest/ebay";
import LinkComponent from "../../../components/generic/LinkComponent";
import Loader from "../../../components/generic/Loader";
import { API_STATE } from "../../../constants/api";
import { queryParamsToObject } from "../../../utils/integrations";

function AuthoriseCallbackView() {
  const [apiState, setApiState] = useState(API_STATE.INITIAL);

  const linkAccounts = () => {
    setApiState(API_STATE.LOADING);
    try {
      // @ts-ignore
      const { code, state, expires_in: expiresIn } = queryParamsToObject();
      if (!code || !state || !expiresIn) {
        console.error("Missing field in authorise response");
        setApiState(API_STATE.ERROR);
      }
      authCallback(code, state, expiresIn).then(() => {
        setApiState(API_STATE.SUCCESS);
      });
    } catch (err) {
      console.log(err);
      setApiState(API_STATE.ERROR);
    }
  };

  useEffect(() => {
    linkAccounts();
  }, []);

  if (apiState === API_STATE.LOADING) return <Loader />;

  if (apiState === API_STATE.ERROR) {
    return (
      <div>
        <p>Failed to link accounts</p>
        <LinkComponent to="/integrations/ebay/reauthorise">
          Try again
        </LinkComponent>
      </div>
    );
  }

  return (
    <div>
      <p>Accounts linked</p>
      <LinkComponent to="/integrations/ebay">Continue</LinkComponent>
    </div>
  );
}

export default AuthoriseCallbackView;
