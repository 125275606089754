const requests = [];

const MAX_REQUEST_LOG_SIZE = 5;

export const logRequest = (method, endpoint, response) => {
  if (requests.length === MAX_REQUEST_LOG_SIZE) {
    requests.pop();
  }
  const status =
    typeof response === "object" && response.detailedMessage
      ? response.detailedMessage
      : String(response);
  requests.unshift({ method, endpoint, status });
};

export const listRequests = () => requests;
