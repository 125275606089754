import React from "react";

export default function EventParticipantOnlineTooltip() {
  return (
    <aside>
      <h5>Participants from online</h5>
      <p>
        This represents all participants for an event from POS and Shopify
        sales, including any participants added manually to an event. In order
        for a participant to show up in this list from POS sales requires a
        customer to be selected at the time of finalizing an order.
      </p>
    </aside>
  );
}
