import React, { useMemo, useState } from "react";
import ButtonComponent from "../generic/ButtonComponent";
import SubSectionHeaderLayout from "../layout/SubSectionHeaderLayout";
import { useShopifyThemeAvailableUpdatesQuery } from "../../generated/graphql";
import { useShowError } from "../../hooks/errorHooks";
import { useDeployThemeVersion } from "../../hooks/themeHooks";
import TimeUtils from "../../utils/TimeUtils";
import "./ThemeReleases.scss";

interface ThemeReleasesProps {
  theme: string;
  currentVersion: string;
}

function ThemeReleases(props: ThemeReleasesProps) {
  const { theme, currentVersion } = props;
  const [deployingVersion, setDeployingVersion] = useState<string>();
  const [deployQueued, setDeployQueued] = useState(false);
  const showError = useShowError();
  const {
    data,
    isLoading,
    error: availableUpdatesError,
  } = useShopifyThemeAvailableUpdatesQuery({
    theme,
    currentVersion,
  });
  const { mutateAsync: deployTheme } = useDeployThemeVersion();

  const handleDeploy = (version: string) => {
    setDeployingVersion(version);
    deployTheme({ theme, version })
      .then(() => {
        setDeployQueued(true);
      })
      .catch((error) =>
        showError(
          error,
          "Unable to apply update",
          "We were unable to apply your theme update. Please try again."
        )
      )
      .finally(() => setDeployingVersion(undefined));
  };

  const sortedThemeUpdates = useMemo(() => {
    if (!data?.ShopifyThemeAvailableUpdates) {
      return [];
    }
    const updates = [...data.ShopifyThemeAvailableUpdates];
    updates.sort((a, b) => (a.releaseDate > b.releaseDate ? -1 : 1));
    return updates;
  }, [data]);

  if (isLoading) {
    return <div data-testid="loader"></div>;
  }

  if (availableUpdatesError) {
    return (
      <p>Failed to load available theme updates. Please refresh the page.</p>
    );
  }

  if (sortedThemeUpdates.length === 0) {
    return (
      <div className="ThemeReleases">
        <SubSectionHeaderLayout title="Available Updates" />
        <p>Your installed theme is up to date.</p>
      </div>
    );
  }

  if (deployQueued) {
    return (
      <div className="ThemeReleases">
        <SubSectionHeaderLayout title="Available Updates" />
        <p>
          Your theme update has been queued. Please allow several minutes for
          this to complete.
        </p>
        <p>
          Please monitor your Shopify store to see when the changes have taken
          effect.
        </p>
      </div>
    );
  }

  return (
    <div className="ThemeReleases">
      <SubSectionHeaderLayout title="Available Updates" />
      <p>The following updates are available for your theme.</p>
      <p>
        Depending on your selected release train, these updates will deployed
        automatically for you on your next release. If you have selected No
        Updates or would like to manually update, please select one of the
        updates below. Please note that selecting a particular update will
        automatically include all previous updates.
      </p>
      <ul className="ThemeReleases__releasesList">
        {sortedThemeUpdates.map((update, index) => (
          <li
            key={update.version}
            className="ThemeReleases__release"
            data-testid="themeRelease"
          >
            <div className="ThemeReleases__releaseDetails">
              <span className="ThemeReleases__releaseDate">
                Release date:{" "}
                {TimeUtils.convertDateToHumanReadableNoTime(update.releaseDate)}
                {index === 0 ? (
                  <span className="ThemeReleases__releaseDate--latest">
                    (latest)
                  </span>
                ) : null}
              </span>
              <p className="ThemeReleases__releaseSummary">{update.summary}</p>
            </div>
            <div className="ThemeReleases__releaseActions">
              <ButtonComponent
                primary
                onClick={() => handleDeploy(update.version)}
                disabled={update.version === deployingVersion}
              >
                Update to this version
              </ButtonComponent>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ThemeReleases;
