import React from "react";
import { joinClasses } from "../../utils/styling";
import "./CheckboxComponent.scss";

interface CheckboxComponentProps {
  name: string;
  label: string;
  baseClass?: string;
  [key: string]: unknown;
}

const generateClassName = (baseClass: string, suffix: string) => {
  const localClass = `CheckboxComponent__${suffix}`;
  const customClass = baseClass ? `${baseClass}__${suffix}` : null;
  return joinClasses([localClass, customClass]);
};

const generateLabel = (name: string, label: string, baseClass: string) => {
  const className = generateClassName(baseClass, "label");
  return (
    <label htmlFor={name} className={className}>
      {label}
    </label>
  );
};

const generateInput = (name: string, baseClass: string, props) => {
  const className = generateClassName(baseClass, "input");
  return (
    <input
      type="checkbox"
      id={name}
      name={name}
      className={className}
      {...props}
    />
  );
};

function CheckboxComponent(props: CheckboxComponentProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { name, label, labelPosition, baseClass, ...rest } = props;

  const labelEl = generateLabel(name, label, baseClass);
  const inputEl = generateInput(name, baseClass, rest);
  const output = (
    <div className="CheckboxComponent">
      {inputEl}
      {labelEl}
    </div>
  );

  return output;
}

CheckboxComponent.defaultProps = {
  baseClass: "",
};

export default CheckboxComponent;
