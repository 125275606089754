import React, { useState } from "react";
import {
  ENABLED_TILLS_CLICKED,
  DISABLED_TILLS_CLICKED,
  MORE_FILTERS_CLICKED,
} from "../../constants/eventsTracked";
import { TillFilters } from "../../types/pos";
import ButtonComponent from "../generic/ButtonComponent";
import Panel from "../layout/Panel";
import { segmentAnalytics } from "../services/Analytics";
import "./TillListFilters.scss";

interface TillListFiltersProps {
  tillFilters: TillFilters;
  showDisabled: boolean;
  setShowDisabled: (disabled: boolean) => void;
  resetTillFilters: () => void;
  updateTillFilter: <K extends keyof TillFilters>(
    parameter: K,
    value: TillFilters[K]
  ) => void;
}

function TillListFilters(props: TillListFiltersProps) {
  const {
    tillFilters,
    showDisabled,
    setShowDisabled,
    resetTillFilters,
    updateTillFilter,
  } = props;
  const [showFilters, setShowFilters] = useState(false);

  const onStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const disabled = event.target.value === "disabled";
    setShowDisabled(disabled);
    segmentAnalytics.track(
      disabled ? DISABLED_TILLS_CLICKED : ENABLED_TILLS_CLICKED
    );
  };

  const updateFilter = <K extends keyof TillFilters>(
    parameter: K,
    value: TillFilters[K]
  ) => {
    if (value === "") {
      value = null;
    }
    updateTillFilter(parameter, value);
  };

  const resetFilters = () => {
    resetTillFilters();
  };

  return (
    <div className="TillListFilters">
      <form>
        <div className="TillListFilters__controls">
          <div className="TillListFilters__radioButtons">
            <input
              type="radio"
              name="tillFilter"
              id="allTills"
              value="enabled"
              checked={!showDisabled}
              onChange={onStatusChange}
            />
            <label htmlFor="allTills">Enabled Tills</label>

            <input
              type="radio"
              name="tillFilter"
              id="enabledTills"
              value="disabled"
              checked={showDisabled}
              onChange={onStatusChange}
            />
            <label htmlFor="enabledTills">Disabled Tills</label>
          </div>

          {showFilters ? (
            <ButtonComponent
              primary
              onClick={() => setShowFilters(false)}
              icon="fas fa-caret-up"
              iconPosition="right"
            >
              Hide Filters
            </ButtonComponent>
          ) : (
            <ButtonComponent
              primary
              onClick={() => {
                setShowFilters(true);
                segmentAnalytics.track(MORE_FILTERS_CLICKED);
              }}
              icon="fas fa-caret-down"
              iconPosition="right"
            >
              More Filters
            </ButtonComponent>
          )}
        </div>
        {showFilters ? (
          <Panel className="TillListFilters__filters">
            <label className="label">
              Name contains:{" "}
              <input
                className="input"
                type="text"
                value={tillFilters.name || ""}
                onChange={(event) => updateFilter("name", event.target.value)}
              />
            </label>
            <label className="label">
              Description contains:{" "}
              <input
                className="input"
                type="text"
                value={tillFilters.description || ""}
                onChange={(event) =>
                  updateFilter("description", event.target.value)
                }
              />
            </label>
            <ButtonComponent
              className="reset-button float-md-right"
              icon="fas fa-sync"
              iconPosition="left"
              onClick={resetFilters}
            >
              Reset Filters
            </ButtonComponent>
          </Panel>
        ) : null}
      </form>
    </div>
  );
}

export default TillListFilters;
