import React from "react";
import "./DefaultErrorPage.scss";
import Logo from "../../../assets/img/logo.png";

interface DefaultErrorPageProps {
  message?: string;
}

function DefaultErrorPage(props: DefaultErrorPageProps) {
  const { message } = props;

  return (
    <div className="DefaultErrorPage">
      <div className="DefaultErrorPage__modal">
        <div className="DefaultErrorPage__header">
          <h2 className="DefaultErrorPage__title">Sorry...</h2>
          <img src={Logo} className="DefaultErrorPage__image" />
        </div>
        <p>
          An error has occurred which has prevented the intended page from being
          displayed.
        </p>
        <p>Please refresh the page and try again.</p>
        <p>
          If you continue to encounter problems, please contact our customer
          representatives.
        </p>
        {message ? <pre>{message}</pre> : null}
      </div>
    </div>
  );
}

export default DefaultErrorPage;
