import React from "react";
import Card from "../generic/Card";
import TableComponent from "../table/TableComponent";
import ProductVariantsTableColumns from "./ProductVariantsTableColumns";
import ButtonComponent from "../generic/ButtonComponent";
import { VariantEditData } from "../../types/productEdit";
import "./ProductVariants.scss";

const defaultProductVariant = {
  id: undefined,
  title: "",
  sku: "",
  barcode: "",
  weight: "",
  weightUnit: "",
  price: "",
};

const rowProps = (rowData: VariantEditData) => ({
  className: rowData.toDelete
    ? "product-variants__row--deleted"
    : "product-variants__row",
});

interface ProductVariantsProps {
  variants: VariantEditData[];
  setFieldValue: SetFieldValue;
}

const ProductVariants = (props: ProductVariantsProps) => {
  const { variants, setFieldValue } = props;

  const handleAddVariant = () => {
    const newVariant = {
      ...defaultProductVariant,
      key: Date.now(),
      position: variants.length + 1,
    };
    const updatedVariants = [...variants, newVariant];
    setFieldValue("ProductVariant", updatedVariants);
  };

  // const activeVariants = variants.filter((variant) => !variant.toDelete);

  return (
    <Card title="Variants" headingLevel="h2">
      <div className="product-variants">
        <TableComponent
          data={variants}
          columns={ProductVariantsTableColumns}
          getTrProps={rowProps}
        />
        <ButtonComponent
          primary
          onClick={handleAddVariant}
          className="product-variants__add"
          icon="fas fa-plus"
          type="button"
        >
          Add New Variant
        </ButtonComponent>
      </div>
    </Card>
  );
};

export default ProductVariants;
