import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table, Column, Cell } from "rsuite-table";
import "rsuite-table/dist/css/rsuite-table.css";
import "../../../css/rstable-overwrites.scss";
import CustomHeaderCell from "../../components/reports/CustomHeader";
import { Pagination, ReportControls } from "../../components/reports";
import {
  CurrencyRenderer,
  TextInputFilter,
} from "../../components/reports/CellTypes";
import { API_STATE } from "../../constants/api";
import { ReportsMenuStructure } from "../../menuStructures";
import SetActiveMenu from "../../menuStructures/SetActiveMenu";
import { useReportsDetails } from "../../hooks/reportsHooks";
import { useStoreDetails } from "../../hooks/storeHooks";
import { useShowError } from "../../hooks/errorHooks";
import { WPNReportCSVHeaders } from "../../constants/reportCSVHeaders";
import { rowPerPageOptions } from "../../constants/reports";
import { addLocalizedDate } from "../../transformers/helpers/addLocalizedDate";
import { useFetchWPNReport } from "../../hooks/reports/useFetchWPNReport";

function WPNReport() {
  const {
    apiState,
    currentPage,
    filterValues,
    overrideTableHeight,
    rowsPerPage,
    sortColumn,
    tableData,
    computedTableData,
    reset,
    setApiState,
    setCurrentPage,
    setTableRowsPerPage,
    setFilters,
    setSortingColumn,
    setSourceDataDetails,
    updateFilterValue,
  } = useReportsDetails();
  const { currencyCode } = useStoreDetails();
  const showError = useShowError();
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [timeZone, setTimeZone] = useState<string>(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const {
    data: sourceData,
    isFetching,
    error,
  } = useFetchWPNReport(
    {
      startDate,
      endDate,
    },
    {
      enabled: !!startDate && !!endDate,
    }
  );

  useEffect(() => {
    document.title = "Reports | BinderPOS";
    reset();
    setFilters(filters);
  }, []);

  useEffect(() => {
    if (isFetching) {
      setApiState(API_STATE.LOADING);
    } else if (error) {
      showError(
        error as any,
        "Failed to load report",
        "There was an error retrieving your WPN report. Please try again"
      );
      setApiState(API_STATE.ERROR);
    } else if (sourceData) {
      setSourceDataDetails(sourceData);
      setApiState(API_STATE.SUCCESS);
    }
  }, [isFetching, sourceData, error]);

  const filters = [
    {
      name: "transaction id",
      condition: (filterValues) => filterValues?.transactionId?.length,
      filterFunction: (row, filterValues) =>
        row.transactionId &&
        row.transactionId
          .toLowerCase()
          .includes(filterValues?.transactionId?.toLowerCase()),
    },
    {
      name: "UPC",
      condition: (filterValues) => filterValues?.UPC?.length,
      filterFunction: (row, filterValues) =>
        row.UPC &&
        row.UPC.toLowerCase().includes(filterValues?.UPC?.toLowerCase()),
    },
    {
      name: "description",
      condition: (filterValues) => filterValues?.description?.length,
      filterFunction: (row, filterValues) =>
        row.description &&
        row.description
          .toLowerCase()
          .includes(filterValues?.description?.toLowerCase()),
    },
  ];

  const handleSetDateRange = (fromDate, toDate, timeZone) => {
    setTimeZone(timeZone);
    setStartDate(fromDate);
    setEndDate(toDate);
  };

  const { transactionId, UPC, description = "" } = filterValues;

  const csvFilename = "WPN Report.csv";

  const data = addLocalizedDate(
    computedTableData(),
    timeZone,
    "transactionTime"
  );

  return (
    <Fragment>
      <SetActiveMenu menuStructure={ReportsMenuStructure} />
      <div className="app-header">
        <h1 className="title">
          <span className="header-text">WPN Report</span>
        </h1>
      </div>
      <div className="report">
        <div className="info">
          <Link to="/settings/reports">Click here</Link> to select which product
          types appear in this report.
        </div>
        <ReportControls
          setDateRange={handleSetDateRange}
          csvHeaders={WPNReportCSVHeaders(currencyCode)}
          csvFilename={csvFilename}
        />
        {apiState !== API_STATE.INITIAL && (
          <div className="data-table">
            <div className="table-container">
              <Table
                data={data}
                headerHeight={80}
                loading={apiState == API_STATE.LOADING}
                sortColumn={sortColumn.key}
                sortType={sortColumn.order}
                onSortColumn={setSortingColumn}
                renderEmpty={() => <div>No data to display</div>}
                className="rs-table--multi-header"
                height={overrideTableHeight}
                fillHeight={overrideTableHeight === 0}
              >
                <Column
                  key="col_transaction_time"
                  width={200}
                  sortable
                  resizable
                >
                  <CustomHeaderCell title="Transaction Time" />
                  <Cell dataKey="transactionTime">
                    {(rowData) => rowData.transactionTime_localized}
                  </Cell>
                </Column>
                <Column key="col_transaction_id" width={120} sortable resizable>
                  <CustomHeaderCell title="Transaction Id">
                    <TextInputFilter
                      aria-label="Filter by transaction ID"
                      title="Transaction Id"
                      value={transactionId}
                      setValue={(value) =>
                        updateFilterValue("transactionId", value)
                      }
                    />
                  </CustomHeaderCell>
                  <Cell dataKey="transactionId" />
                </Column>
                <Column key="col_upc" width={180} sortable resizable>
                  <CustomHeaderCell title="UPC">
                    <TextInputFilter
                      aria-label="Filter by UPC"
                      title="UPC"
                      value={UPC}
                      setValue={(value) => updateFilterValue("UPC", value)}
                    />
                  </CustomHeaderCell>
                  <Cell dataKey="UPC" />
                </Column>
                <Column key="col_description" width={330} sortable resizable>
                  <CustomHeaderCell title="Description">
                    <TextInputFilter
                      aria-label="Filter by description"
                      title="Description"
                      value={description}
                      setValue={(value) =>
                        updateFilterValue("description", value)
                      }
                    />
                  </CustomHeaderCell>
                  <Cell dataKey="description" />
                </Column>
                <Column
                  key="col_unit_price"
                  width={150}
                  align="right"
                  sortable
                  resizable
                >
                  <CustomHeaderCell title="Individual Unit Price" />
                  <Cell dataKey="unitPrice">
                    {(rowData) =>
                      CurrencyRenderer({
                        cellData: rowData.unitPrice,
                        currency: currencyCode,
                      })
                    }
                  </Cell>
                </Column>
                <Column
                  key="col_quantity"
                  width={120}
                  align="right"
                  sortable
                  resizable
                >
                  <CustomHeaderCell title="Quantity Sold" />
                  <Cell dataKey="quantity" />
                </Column>
                <Column
                  key="col_total_price"
                  width={120}
                  align="right"
                  sortable
                  resizable
                >
                  <CustomHeaderCell title="Total Sale Price" />
                  <Cell dataKey="totalPrice">
                    {(rowData) =>
                      CurrencyRenderer({
                        cellData: rowData.totalPrice,
                        currency: currencyCode,
                      })
                    }
                  </Cell>
                </Column>
                <Column key="col_currency" width={100} align="right" resizable>
                  <CustomHeaderCell title="Currency" />
                  <Cell dataKey="currency" style={{ paddingRight: "16px" }}>
                    {currencyCode}
                  </Cell>
                </Column>
              </Table>
            </div>
            <Pagination
              apiState={apiState}
              currentPage={currentPage}
              totalRowCount={tableData.length}
              rowsPerPage={rowsPerPage}
              rowPerPageOptions={rowPerPageOptions}
              setCurrentPage={setCurrentPage}
              setRowsPerPage={setTableRowsPerPage}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default WPNReport;
