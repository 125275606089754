import React, {
  InputHTMLAttributes,
  MouseEventHandler,
  MutableRefObject,
} from "react";
import "./QuantitySelector.scss";

type QuantitySelectorProps = {
  secondary?: boolean;
  minusDisabled?: boolean;
  plusDisabled?: boolean;
  onMinusClick: MouseEventHandler<HTMLButtonElement>;
  onPlusClick: MouseEventHandler<HTMLButtonElement>;
} & InputHTMLAttributes<HTMLInputElement>;

const getClassName = (secondary?: boolean, disabled?: boolean) =>
  [
    "QuantitySelector",
    secondary ? "QuantitySelector--secondary" : null,
    disabled ? "QuantitySelector--disabled" : null,
  ]
    .filter(Boolean)
    .join(" ");

function QuantitySelector(
  props: QuantitySelectorProps,
  ref: MutableRefObject<any>
) {
  const {
    minusDisabled,
    plusDisabled,
    onMinusClick,
    onPlusClick,
    secondary,
    disabled,
    ...rest
  } = props;
  const mergedClassName = getClassName(secondary, disabled);

  return (
    <div className={mergedClassName}>
      <button
        className="QuantitySelector__minus"
        onClick={onMinusClick}
        title="Decrease"
        disabled={minusDisabled || disabled}
      >
        <i className="fal fa-minus"></i>
      </button>
      <input
        type="number"
        step="1"
        className="input"
        ref={ref}
        disabled={disabled}
        {...rest}
      />
      <button
        className="QuantitySelector__plus"
        onClick={onPlusClick}
        title="Increase"
        disabled={plusDisabled || disabled}
      >
        <i className="fal fa-plus"></i>
      </button>
    </div>
  );
}

export default React.forwardRef(QuantitySelector);
