import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  registerDevice,
  fetchDevices,
  fetchDeviceDetails,
  deleteDevice,
  createTerminalSettings,
  updateActiveDevice,
} from "../api/rest/paymentTerminal";
import { GetTillSettingsQuery } from "../generated/graphql";
import {
  PaymentProvider,
  TerminalResponse,
  TerminalSettings,
} from "../types/paymentTerminal";

type RegisterDeviceParams = {
  tillId: number;
  name: string;
  description?: string;
  entryModes?: Record<string, boolean>;
  paymentMethods?: Record<string, boolean>;
  providerKey?: string;
  key?: Record<string, string>;
};

export const useRegisterDevice = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    ({
      tillId,
      name,
      description,
      entryModes,
      paymentMethods,
      providerKey,
      key,
    }: RegisterDeviceParams) =>
      registerDevice(
        tillId,
        name,
        description,
        entryModes,
        paymentMethods,
        providerKey,
        key
      ),
    {
      onSuccess: (
        response: TerminalResponse,
        variables: RegisterDeviceParams
      ) => {
        queryClient.setQueryData<TerminalResponse>(
          [
            "paymentTerminals",
            // @ts-ignore TODO:: handle different register responses
            { tillId: variables.tillId, deviceKey: response.key },
          ],
          (): TerminalResponse => response
        );
      },
    }
  );
  return { isMutating, ...rest };
};

export const useFetchDevices = (
  tillId: number,
  offset: number,
  limit: number
) =>
  useQuery(["paymentTerminals", { tillId, offset, limit }], () =>
    fetchDevices(tillId, offset, limit)
  );

export const useFetchDeviceDetails = (tillId: number, deviceKey: string) =>
  useQuery(
    ["paymentTerminals", { tillId, deviceKey }],
    () => fetchDeviceDetails(tillId, deviceKey),
    {
      enabled: !!deviceKey,
    }
  );

type DeleteDeviceParams = {
  tillId: number;
  deviceKey: string;
};

export const useDeleteDevice = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    ({ tillId, deviceKey }: DeleteDeviceParams) =>
      deleteDevice(tillId, deviceKey),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("paymentTerminals");
      },
    }
  );
  return { isMutating, ...rest };
};

type CreateTerminalSettingsParams = {
  tillId: number;
  settings: TerminalSettings;
};
export const useCreateTerminalSettings = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    ({ tillId, settings }: CreateTerminalSettingsParams) =>
      createTerminalSettings(tillId, settings),
    {
      onSuccess: (
        _response: unknown,
        variables: CreateTerminalSettingsParams
      ) => {
        queryClient.setQueryData<GetTillSettingsQuery>(
          ["GetTillSettings", { tillId: variables.tillId }],
          (prev: GetTillSettingsQuery): GetTillSettingsQuery => {
            const { settings } = variables;
            return {
              BinderCustomerTillSettings: prev?.BinderCustomerTillSettings.map(
                (setting) => {
                  if (Object.keys(settings).includes(setting.settingName)) {
                    return {
                      ...setting,
                      settingValue: settings[setting.settingName],
                    };
                  }
                  return setting;
                }
              ),
            };
          }
        );
      },
    }
  );
  return { isMutating, ...rest };
};

type UpdateActiveDeviceParams = {
  tillId: number;
  paymentProvider: PaymentProvider;
  deviceKey: string;
};
export const useUpdateActiveDevice = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    ({ tillId, paymentProvider, deviceKey }: UpdateActiveDeviceParams) =>
      updateActiveDevice(tillId, paymentProvider, deviceKey),
    {
      onSuccess: (_response: unknown, variables: UpdateActiveDeviceParams) => {
        queryClient.setQueryData<GetTillSettingsQuery>(
          ["GetTillSettings", { tillId: variables.tillId }],
          (prev: GetTillSettingsQuery): GetTillSettingsQuery => {
            const { deviceKey, paymentProvider } = variables;
            return {
              BinderCustomerTillSettings: prev?.BinderCustomerTillSettings.map(
                (setting) => {
                  if (setting.settingName === "paymentTerminalDeviceKey") {
                    return {
                      ...setting,
                      settingValue: deviceKey,
                    };
                  } else if (setting.settingName === "paymentProvider") {
                    return {
                      ...setting,
                      settingValue: paymentProvider,
                    };
                  }
                  return setting;
                }
              ),
            };
          }
        );
      },
    }
  );
  return { isMutating, ...rest };
};
