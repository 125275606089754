import { BinderCustomerTillSettings } from "../generated/graphql";
import { SubscriptionStatus } from "./subscriptions";

export interface ReceiptFields {
  cash?: string;
  change?: string;
  credit?: string;
  creditCard?: string;
  creditCardSurcharge?: string;
  customMessage?: string;
  discount?: string;
  eftpos?: string;
  giftReceipt?: string;
  itemsPurchased?: string;
  sale?: string;
  storeCredit?: string;
  subTotal?: string;
  taxWording?: string;
  tel?: string;
  till?: string;
  total?: string;
}

export interface ReceiptSettings {
  additionalLanguageLabel?: string;
  additionalLanguageFields?: ReceiptFields;
  defaultLanguageFields?: ReceiptFields;
  defaultLanguageLabel?: string;
}

export enum ReceiptLanguage {
  First = "First",
  Second = "Second",
}

export type StoreSettings = {
  allowNonEnglish: number | boolean;
  currency: string;
  email: string;
  id?: string;
  name?: string;
  siteUrl: string;
  variantTypes?: VariantType[];
  nonEnglishWording?: string | null;
  priceMultiplier?: string | null;
  shopify: {
    subscription: {
      status: SubscriptionStatus;
      confirmationUrl: string;
    };
  };
};

export type StoreDetails = {
  addressLine1: string;
  addressLine2: string;
  city: string;
  country: string;
  id: number;
  phoneNumber: string;
  state: string;
  storeName: string;
  zipCode: string;
};

export type RarityFloor = {
  price: number | null | "";
  rarity: string;
  id: number | null | "";
};

export type GamePriceRule = {
  game: string;
  gameName: string;
  noRounding: boolean | string;
  priceMarkup: number | string;
  priceMultiplier: number | string;
  rarityFloors: RarityFloor[];
  roundToNearest: number | string;
  setName: string | null;
  tcgPlayerPriceType: string;
  updatePricing: boolean | string;
  useTCGPlayerPricing: boolean | string;
};

export type GlobalPriceRule = {
  roundToNearest: MaybeNull<number>;
};

export type GamePricingTypeOption = { value: string; description: string };

export type GamePriceRuleStrict = {
  game: string;
  gameName: string;
  noRounding: boolean | string;
  priceMarkup: number;
  priceMultiplier: number | null;
  rarityFloors: RarityFloor[];
  roundToNearest: number | string | null;
  setName: string | null;
  tcgPlayerPriceType: string;
  updatePricing: boolean | string;
  useTCGPlayerPricing: boolean | string;
};

export type TillSetting = Omit<BinderCustomerTillSettings, "editable">;

export type PaymentTerminalSettings = {
  enabled: boolean;
  tillId?: number;
  paxHost?: string;
  deviceKey?: string;
  [key: string]: any;
};

type BinderLocation = {
  name: string;
  shopifyId: number;
};

type ShopifyLocation = {
  name: string;
  shopifyId: number;
};

export type LocationSettings = {
  binder: Array<BinderLocation>;
  shopify: Array<ShopifyLocation>;
};
