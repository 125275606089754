import React from "react";
import Select from "react-select";
import { Option } from "react-select/src/filters";
import "../../../css/react-select-overwrites.scss";

const SortBy = ({
  sortTypes,
  selectedSortType,
  changeSortType,
}: {
  sortTypes: Option[];
  selectedSortType: Option;
  changeSortType: (option: Option | null) => void;
}) => {
  if (!sortTypes || sortTypes.length < 0) return;
  return (
    <div style={{ marginLeft: "auto", display: "flex", alignItems: "center" }}>
      <label
        className="sort"
        htmlFor="sort-by"
        style={{
          width: "auto",
          display: "flex",
          alignItems: "center",
          marginBottom: 0,
        }}
      >
        Sort by:
      </label>
      <div style={{ width: "213px" }}>
        <Select
          inputId="sort-by"
          classNamePrefix="react-select"
          placeholder="Sort by"
          defaultValue={selectedSortType ? [selectedSortType] : sortTypes[0]}
          onChange={changeSortType}
          options={sortTypes}
        />
      </div>
    </div>
  );
};

export default SortBy;
