import React from "react";
import { joinClasses } from "../../utils/styling";
import "./ActionButtonsLayout.scss";

interface ActionButtonsLayoutProps {
  children: React.ReactNode;
  alignment?: "left" | "right";
}

function ActionButtonsLayout(props: ActionButtonsLayoutProps) {
  const { children, alignment = "left" } = props;

  const className = joinClasses([
    "ActionButtonsLayout",
    alignment === "right" ? "ActionButtonsLayout--right" : null,
  ]);

  return <div className={className}>{children}</div>;
}

export default ActionButtonsLayout;
