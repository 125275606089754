import React, { Component } from "react";
import { observer, inject, Observer } from "mobx-react";
import { action } from "mobx";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import ConfirmationModal from "../generic/ConfirmationModal";
import { isKioskMode } from "../../helpers/posModeHelpers";
import { ItemList } from "../../pos/ItemStore";

interface QuickItemProps {
  posStore?: ItemList;
  menu: any;
  navIndex?: number;
}

@inject("posStore")
@observer
class QuickItem extends Component<
  QuickItemProps,
  { changeTitle: boolean; showModal: boolean }
> {
  constructor(props) {
    super(props);
    this.state = { changeTitle: false, showModal: false };
    this.nameValidate(props);
  }

  isKioskMode = isKioskMode();

  @action
  nameValidate = (props) => {
    if (props.menu.value) {
      if (props.menu.value.title.match(/(.+) \[(.+)\]/)) {
        props.menu.value.cardName =
          props.menu.value.title.match(/(.+) \[(.+)\]/)[1];
        props.menu.value.setName =
          props.menu.value.title.match(/(.+) \[(.+)\]/)[2];
      } else if (props.menu.value.title.match(/(.+) - (.+)/)) {
        props.menu.value.cardName =
          props.menu.value.title.match(/(.+) - (.+)/)[1];
        props.menu.value.setName =
          props.menu.value.title.match(/(.+) - (.+)/)[2];
      }
    }
  };

  showModal = () => {
    this.setState({ showModal: true });
  };

  clearModal = () => {
    this.setState({ showModal: false });
  };

  traverse = () => {
    if (this.props.menu.type == "folder") {
      this.props.posStore.setQuickLinkNav(this.props.navIndex);
    } else if (this.props.menu.type == "variant") {
      this.props.posStore.fetchVariantById(this.props.menu.value.variantId);
    }
  };

  onDragStart = () => {
    if (this.isKioskMode) return;
    this.props.posStore.setMovingQuickLink(this.props.navIndex);
  };

  onDrop = (e) => {
    if (this.isKioskMode) return;
    if (e.nativeEvent.offsetX < e.target.clientWidth / 4) {
      this.props.posStore.moveQuickLink(
        this.props.posStore.movingQuickLink,
        this.props.navIndex
      );
    } else if (e.nativeEvent.offsetX > e.target.clientWidth * (3 / 4)) {
      this.props.posStore.moveQuickLink(
        this.props.posStore.movingQuickLink,
        this.props.navIndex + 1
      );
    } else {
      if (
        this.props.menu.type == "folder" &&
        this.props.posStore.movingQuickLink != this.props.navIndex
      ) {
        this.props.posStore.moveQuickLinkToFolder(
          this.props.posStore.movingQuickLink,
          this.props.navIndex
        );
      }
    }
  };

  onDragOver = (e) => {
    e.preventDefault();
  };

  onDragLeave = (e) => {
    e.preventDefault();
  };

  @action
  editItem = () => {
    this.props.posStore.editingQuickItem =
      this.props.posStore.currentNav[this.props.navIndex];
  };

  @action
  deleteItem = () => {
    this.setState({ showModal: false });
    this.props.posStore.deleteQuickItem(this.props.navIndex);
  };

  @action
  moveItemUp = () => {
    this.props.posStore.moveQuickLinkUp(this.props.navIndex);
  };

  VariantCard(props) {
    return (
      <Observer>
        {() => (
          <React.Fragment>
            <img src={props.menu.value.imgUrl} role="presentation" />
            <div className="itemDesc">
              <h2>
                {props.menu.value.cardName
                  ? props.menu.value.cardName
                  : props.menu.value.title}
              </h2>
              <hr />
              {props.menu.value.setName ? (
                <p className="setName">{props.menu.value.setName}</p>
              ) : null}
              <p className="variantName">{props.menu.value.variantTitle}</p>
            </div>
          </React.Fragment>
        )}
      </Observer>
    );
  }

  ProductCard(props) {
    return (
      <Observer>
        {() => (
          <React.Fragment>
            <img src={props.menu.value.imgUrl} role="presentation" />
            <div className="itemDesc">
              <h2>
                {props.menu.value.cardName
                  ? props.menu.value.cardName
                  : props.menu.value.title}
              </h2>
              <hr />
              {props.menu.value.setName ? (
                <p className="setName">{props.menu.value.setName}</p>
              ) : null}
            </div>
          </React.Fragment>
        )}
      </Observer>
    );
  }

  FolderCard(props) {
    return (
      <Observer>
        {() => (
          <React.Fragment>
            <div className="dot" style={{ backgroundColor: props.menu.color }}>
              <i className={props.menu.icon + " fa-3x"}></i>
            </div>
            <div className="itemDesc">
              <h2>
                {props.menu.value.cardName
                  ? props.menu.value.cardName
                  : props.menu.value.title}
              </h2>

              {props.menu.value.setName ? (
                <p className="setName">{props.menu.value.setName}</p>
              ) : null}
            </div>
          </React.Fragment>
        )}
      </Observer>
    );
  }
  render() {
    return (
      <React.Fragment>
        <ContextMenuTrigger
          id={"Context" + this.props.navIndex}
          holdToDisplay={-1}
        >
          <button
            draggable
            className={
              "QuickLinks__quickItem" +
              (this.props.menu.type == "blank" ? " blank" : "") +
              (this.props.posStore.cartLoading ? " disabled" : "")
            }
            data-testid="traverse"
            onClick={this.traverse}
            onDragStart={this.onDragStart}
            onDrop={this.onDrop}
            onDragOver={this.onDragOver}
            onDragLeave={this.onDragLeave}
            style={{ borderColor: this.props.menu.color }}
          >
            {this.props.menu.type == "product" ? (
              <this.ProductCard
                menu={this.props.menu}
                store={this.props.posStore}
                navIndex={this.props.navIndex}
              />
            ) : null}
            {this.props.menu.type == "folder" ? (
              <this.FolderCard
                menu={this.props.menu}
                store={this.props.posStore}
                navIndex={this.props.navIndex}
              />
            ) : null}
            {this.props.menu.type == "variant" ? (
              <this.VariantCard
                menu={this.props.menu}
                store={this.props.posStore}
                navIndex={this.props.navIndex}
              />
            ) : (
              ""
            )}
          </button>
        </ContextMenuTrigger>
        {!this.isKioskMode ? (
          <ContextMenu id={"Context" + this.props.navIndex} hideOnLeave={true}>
            <MenuItem data={{ action: "addLink" }} onClick={this.editItem}>
              Edit Item
            </MenuItem>
            <MenuItem data={{ action: "addLink" }} onClick={this.showModal}>
              Delete
            </MenuItem>
            {this.props.posStore.quickLinkNav.length ? (
              <MenuItem data={{ action: "addLink" }} onClick={this.moveItemUp}>
                Move Up a Level
              </MenuItem>
            ) : (
              ""
            )}
          </ContextMenu>
        ) : null}

        {this.state.showModal ? (
          <ConfirmationModal
            cancelAction={this.clearModal}
            confirmAction={this.deleteItem}
          >
            Are you sure you want to delete this item forever?
          </ConfirmationModal>
        ) : (
          false
        )}
      </React.Fragment>
    );
  }
}

export default QuickItem;
