import * as Yup from "yup";

export const userSchema = Yup.object().shape({
  id: Yup.number().optional(),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().email("This must be a valid email address"),
  password: Yup.string().when(["id"], {
    is: (id) => id === undefined,
    then: Yup.string()
      .required("A password must be entered")
      .min(8, "Password must be a minimum of 8 characters"),
  }),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});
