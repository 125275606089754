import { useEffect } from "react";
import { AnalyticsBrowser } from "@segment/analytics-next";
import { COOKIES_ANALYTICS } from "../../constants/settings";
import { useLocalSetting } from "../../hooks/localSettingsHooks";
import { SEGMENT_WRITE_KEY } from "../../constants/analytics";

export const segmentAnalytics = new AnalyticsBrowser();

export type SegmentEventProps = {
  segmentEventName?: string;
  segmentEventProperties?: Record<string, any>;
};

const segmentInitializer = () => {
  segmentAnalytics
    .load({ writeKey: SEGMENT_WRITE_KEY })
    .catch((error) =>
      console.log("Segment analytics couldn't be initialized:", error)
    );
};

function Analytics() {
  const handleLocalStorageChange = () => {
    const enabled = useLocalSetting(COOKIES_ANALYTICS);
    if (!enabled) {
      return;
    }
    segmentInitializer();
  };

  useEffect(() => {
    const enabled = useLocalSetting(COOKIES_ANALYTICS);
    if (enabled) {
      segmentInitializer();
    }
  }, []);

  useEffect(() => {
    window.addEventListener("storage", handleLocalStorageChange);
    return () => {
      window.removeEventListener("storage", handleLocalStorageChange);
    };
  }, []);

  return null;
}

export default Analytics;
