import * as Yup from "yup";
import { WeightUnit } from "../generated/graphql";

export const bulkUpdateSchema = Yup.object().shape({
  stockUpdateType: Yup.string(),
  priceOverride: Yup.number().min(0, "Price cannot be less than zero"),
  stock: Yup.number(),
  cashBuyPrice: Yup.number().min(0, "Buy price cannot be less than zero"),
  cashBuyPercentage: Yup.number().min(
    -100,
    "Items cannot be discounted by more than 100%"
  ),
  creditBuyPrice: Yup.number().min(0, "Buy price cannot be less than zero"),
  creditBuyPercentage: Yup.number(),
  buyLimit: Yup.number().min(0, "Buy limit cannot be less than zero"),
  maxInstockBuyPrice: Yup.number().min(0, "Buy price cannot be less than zero"),
  maxInstockBuyPercentage: Yup.number().min(
    -100,
    "Items cannot be discounted by more than 100%"
  ),
  maxInstockCreditBuyPrice: Yup.number().min(
    0,
    "Buy price cannot be less than zero"
  ),
  maxInstockCreditBuyPercentage: Yup.number().min(
    -100,
    "Items cannot be discounted by more than 100%"
  ),
});

export const productEditSchema = Yup.object({
  id: Yup.number(),
  title: Yup.string().required(),
  productType: Yup.string(),
  vendor: Yup.string(),
  ProductDescription: Yup.object({
    descriptionHtml: Yup.string(),
    customerId: Yup.string(),
    productId: Yup.number(),
  }).transform((value) => value || { descriptionHtml: "" }),
  ProductTag: Yup.array().of(
    Yup.object({
      id: Yup.number(),
      tag: Yup.string(),
    })
  ),
  ProductImage: Yup.array(),
  ProductVariant: Yup.array().of(
    Yup.object({
      id: Yup.number(),
      title: Yup.string().required("Title is required"),
      sku: Yup.string().transform((value) => value || ""),
      barcode: Yup.string().transform((value) => value || ""),
      weight: Yup.number()
        .transform((value) => value || "")
        .typeError("Weight must be a number"),
      weightUnit: Yup.string().oneOf(Object.values(WeightUnit)),
      price: Yup.string()
        .matches(/^\d+\.?\d*$/, "Price must be a number")
        .transform((value) => value || "")
        .required("Price is required"),
    })
  ),
  newProductImages: Yup.array(),
});
