import React, { MutableRefObject } from "react";
import Modal from "../generic/Modal";
import Dropzone from "react-dropzone";
import { notify } from "../../helpers/notificationHelpers";
import ButtonComponent from "../generic/ButtonComponent";

interface CSVImportModal {
  onClose: () => void;
  uploadCSV: (file: File) => Promise<any>;
}

function CSVImportModal(props: CSVImportModal, ref: MutableRefObject<any>) {
  const { onClose, uploadCSV } = props;

  return (
    <Modal ref={ref} small onClose={onClose}>
      <Modal.Header>CSV Import</Modal.Header>
      <Modal.Content>
        <Dropzone
          onDrop={async (acceptedFiles) => {
            const resp = await uploadCSV(acceptedFiles[0]);
            if (resp.errors.length) {
              onClose();
              notify.warn(resp.errors[0]);
            } else {
              onClose();
              notify.info("CSV uploaded, importing data in background...");
            }
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <p>
                  Drag a BinderPOS compatible csv here or click to open file
                  selector.
                </p>
              </div>
            </section>
          )}
        </Dropzone>
      </Modal.Content>
      <Modal.Actions>
        <ButtonComponent onClick={onClose}>Cancel</ButtonComponent>
      </Modal.Actions>
    </Modal>
  );
}

export default React.forwardRef(CSVImportModal);
