import React, { Dispatch, SetStateAction, ReactElement, useRef } from "react";
import SettingsSectionToggle from "./SettingsSectionToggle";
import ButtonComponent from "../generic/ButtonComponent";
import "./SettingsSection.scss";

interface SettingsSectionProps {
  title: string | ReactElement;
  subtitle?: string | ReactElement;
  isMutating?: boolean;
  isEditing?: boolean;
  toggleEditMode?: Dispatch<SetStateAction<boolean>>;
  enableEditMode?: boolean;
  children: React.ReactNode;
  toggleTextOn?: string | undefined;
  toggleTextOff?: string | undefined;
  isLoading?: string | undefined;
  isError?: string | undefined;
  submitHandler?: VoidFunction;
  formHasErrors?: boolean;
}

const SettingsSection = ({
  title,
  subtitle,
  isMutating,
  isEditing,
  toggleEditMode,
  enableEditMode = false,
  children,
  toggleTextOn = undefined,
  toggleTextOff = undefined,
  isLoading = undefined,
  isError = undefined,
  submitHandler,
  formHasErrors,
}: SettingsSectionProps) => {
  const containerRef = useRef<HTMLDivElement>();

  return (
    <div
      className="SettingsSection"
      data-testid="SettingsSection"
      ref={containerRef}
    >
      <div className="SettingsSection__header">
        <h2 className="SettingsSection__title">{title}</h2>
      </div>
      {enableEditMode || isEditing || subtitle ? (
        <div className="SettingsSection__subheader">
          {subtitle ? (
            <div className="SettingsSection__subtitle">{subtitle}</div>
          ) : (
            <></>
          )}
          <div className="SettingsSection__controls">
            {toggleEditMode && enableEditMode ? (
              <SettingsSectionToggle
                isOn={isEditing}
                toggle={toggleEditMode}
                toggleTextOn={toggleTextOn}
                toggleTextOff={toggleTextOff}
                disabled={isMutating}
                compact
              />
            ) : null}
            {isEditing && submitHandler ? (
              <ButtonComponent
                primary
                className="SettingsSection__submit"
                type="submit"
                disabled={isMutating || formHasErrors}
                onClick={() => submitHandler?.()}
                compact
              >
                Save
              </ButtonComponent>
            ) : null}
          </div>
        </div>
      ) : null}
      <div className="SettingsSection__content">
        {isLoading !== undefined && <div>{isLoading}</div>}
        {isError !== undefined && <div>{isError}</div>}
        {isLoading === undefined && isError === undefined && <>{children}</>}
      </div>
    </div>
  );
};

export default SettingsSection;
