import { ContextMenu, MenuItem } from "react-contextmenu";
import { isKioskMode } from "../../helpers/posModeHelpers";
import { useFeatureFlag } from "../../hooks/globalSettingsHooks";
import { ADABOT_BINDER_PORTAL } from "../../constants/featureFlags";
import { createAdaBotLauncher } from "../services/ADAChatbot";
import { adaBotAnswers, adaBotMetaFields } from "../../constants/adaBot";

interface GridItemMenuProps {
  item: any;
  currentNav: any[];
  customerName: string;
  customerSiteUrl: string;
  updateQuickLinks: Function;
}

function GridItemMenu(props: GridItemMenuProps) {
  const adaBotEnabled = useFeatureFlag(ADABOT_BINDER_PORTAL);
  const { item, currentNav, customerName, customerSiteUrl, updateQuickLinks } =
    props;

  const handleAddToQuickLinks = () => {
    const variantInfo = item.variants[item.selectedVariant];
    currentNav.push({
      color: "grey",
      icon: null,
      type: "variant",
      value: {
        title: item.title,
        variantTitle: variantInfo.title,
        variantId: variantInfo.id,
        imgUrl: item.img,
      },
    });
    updateQuickLinks();
  };

  const handleCreateCatalogTicket = () => {
    if (adaBotEnabled) {
      return (
        // launch the bot
        createAdaBotLauncher()
          // set the answer id stored in the constants file, this would come from CX
          .setAnswer(adaBotAnswers.CATALOG_ERROR_REPORT)
          // pass in all of the meta data fields that we want to send to ada (also comes from CX)
          .addMetaData(adaBotMetaFields.CATALOG_ITEM_ID, item.shopifyId)
          .addMetaData(adaBotMetaFields.CATALOG_ITEM_TITLE, item.title)
          .addMetaData(adaBotMetaFields.STORE_NAME, customerName)
          .addMetaData(adaBotMetaFields.STORE_URL, customerSiteUrl)
          .addMetaData(adaBotMetaFields.CATALOG_ITEM_IMAGE, item.img)
          .addMetaData(
            adaBotMetaFields.CATALOG_ITEM_SHOPIFY_LINK,
            `https://${customerSiteUrl}/admin/products/${item.shopifyId}`
          )
          .launchAdaBot()
      );
    }
    return launchFreshDesk(
      item.title,
      customerName,
      customerSiteUrl,
      item.shopifyId
    );
  };

  if (isKioskMode()) {
    return null;
  }

  return (
    <ContextMenu id={item.id.toFixed()}>
      <MenuItem data={{ action: "addLink" }} onClick={handleAddToQuickLinks}>
        Add To QuickLinks
      </MenuItem>
      <MenuItem
        data={{ action: "addLink" }}
        onClick={() => {
          window.open(
            `https://${customerSiteUrl}/admin/products/${item.shopifyId}`,
            "_blank"
          );
        }}
      >
        Open in Shopify
      </MenuItem>
      <MenuItem
        data={{ action: "product" }}
        onClick={() => {
          const encodedSearch = JSON.stringify({ title: item.title });
          const params = new URLSearchParams();
          params.set("search", encodedSearch);
          window.open(
            `${window.location.origin}/#/products?${params.toString()}`,
            "_blank"
          );
        }}
      >
        Product settings
      </MenuItem>
      <MenuItem
        data={{ action: "createTicket" }}
        onClick={handleCreateCatalogTicket}
      >
        Report Catalog Issue
      </MenuItem>
    </ContextMenu>
  );
}

const launchFreshDesk = (
  itemTitle: string,
  customerName: string,
  customerSiteUrl: string,
  shopifyId: string
) => {
  window.FreshworksWidget("prefill", "ticketForm", {
    subject: `Catalog issue with ${itemTitle}`,
    type: "Question",
    description: `Title: ${itemTitle}\nShopify Link: https://${customerSiteUrl}/admin/products/${shopifyId}`,
    custom_fields: {
      cf_affected_resource: itemTitle,
      cf_store_name: customerName,
      cf_shopify_url: customerSiteUrl,
    },
  });
  window.FreshworksWidget("open", "ticketForm");
};

export default GridItemMenu;
