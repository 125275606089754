import React, { useRef, useState } from "react";
import { useCombinedRefs } from "../../hooks/functionHooks";
import ButtonComponent from "../generic/ButtonComponent";
import "./EventImageUpload.scss";
import { SegmentEventProps, segmentAnalytics } from "../services/Analytics";

const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2 MB

interface EventImageUploadProps extends SegmentEventProps {
  label: string;
  placeholder: string;
  selectedImage?: string;
}

function EventImageUpload(
  props: EventImageUploadProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const {
    label,
    placeholder,
    selectedImage,
    segmentEventName,
    segmentEventProperties = {},
  } = props;
  const localRef = useRef<HTMLInputElement>(null);
  const fileInputRef = useCombinedRefs<HTMLInputElement>(ref, localRef);
  const [image, setImage] = useState<string>(selectedImage);
  const [error, setError] = useState<string>();

  const handleFileSelect = () => {
    if (!localRef.current?.files?.[0]) return;
    setError(undefined);
    const { size } = localRef.current.files[0];
    if (size > MAX_FILE_SIZE) {
      setError(
        "Images cannot be more than 2mb. Please reduce the size of the image and try again"
      );
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as string);
    };
    reader.readAsDataURL(localRef.current.files[0]);
  };

  const handleUploadButtonClick = () => {
    if (segmentEventName) {
      segmentAnalytics.track(segmentEventName, segmentEventProperties);
    }
    fileInputRef.current?.click();
  };

  return (
    <div className="EventImageUpload">
      <label className="label">{label}</label>
      <div className="EventImageUpload__container">
        {error ? (
          <p className="EventImageUpload__error">{error}</p>
        ) : (
          <div className="EventImageUpload__wrapper">
            <img
              className="EventImageUpload__image"
              src={image ?? placeholder}
              role="presentation"
            />
          </div>
        )}

        <label className="EventImageUpload__fileSelect">
          <input
            id="custom-file-upload"
            data-testid="eventImage"
            className="EventImageUpload__input"
            type="file"
            ref={fileInputRef}
            onChange={handleFileSelect}
          />
          <ButtonComponent primary fullWidth onClick={handleUploadButtonClick}>
            <i className="fal fa-upload" />
            {selectedImage ? " Change" : " Upload"}
          </ButtonComponent>
        </label>
      </div>
    </div>
  );
}

export default React.forwardRef(EventImageUpload);
