const BuylistMenuStructure = {
  title: "Online Buylist",
  links: [
    {
      title: "Pending Buylists",
      href: "buylists/pending",
    },
    {
      title: "Approved Buylists",
      href: "buylists/approved",
    },
    {
      title: "Completed Buylists",
      href: "buylists/completed",
    },
    {
      title: "Buylist Rules",
      href: "buylists/rules",
    },
    {
      title: "Failed to Sync",
      href: "buylists/failedToSyncProducts",
    },
    {
      title: "Buylist Settings",
      href: "buylists/settings",
    },
  ],
};

export default BuylistMenuStructure;
