import {
  doDeclineBuylist,
  fetchBuylistDetails,
  fetchCardPrices,
  saveBuylist,
} from "../../api/rest/buylist";
import { ButtonComponent, Loader, Spinner } from "../../components";
import { BuylistMenuStructure } from "../../menuStructures";
import { action, observable, runInAction } from "mobx";
import Cookie from "mobx-cookie";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import ReactToPrint from "react-to-print";
import {
  buylistCSVHeaders,
  importerCompatibleBuylistCSVHeaders,
} from "../../constants/buylist";
import { TimeUtils } from "../../utils";
import {
  chunkCards,
  getSortFunction,
  groupBuylistVariants,
  splitBuylistVariants,
} from "../../utils/buylist";
import { convertToCsv } from "../../utils/data";
import {
  DeclineModal,
  GridView,
  ListView,
  PrintBuylist,
  SearchModal,
  SortDirection,
} from "../../components/buylist";
import UncompleteBuylist from "../../components/buylist/UncompleteBuylist";
import PushStock from "../../components/buylist/PushStock";
import PushStoreCredit from "../../components/buylist/PushStoreCredit";
import ApproveBuylistModal from "../../components/buylist/ApproveBuylistModal";
import CompleteBuylistModal from "../../components/buylist/CompleteBuylistModal";
import Modal from "../../components/generic/Modal";
import { forceDownloadFromData } from "../../helpers/fileHelpers";
import { segmentAnalytics } from "../../components/services/Analytics";
import {
  BUYLIST_CANCELED,
  BUYLIST_DECLINED,
  BUYLIST_GRID_VIEW_CLICKED,
  BUYLIST_LIST_VIEW_CLICKED,
  EXPORT_BUYLIST_CSV_CLICKED,
  EXPORT_BUYLIST_STOCK_ADJUSTMENT_CSV_CLICKED,
  PRINT_BUYLIST_CLICKED,
  REMOVE_ITEM_FROM_BUYLIST_CLICKED,
  SAVE_CHANGES_TO_BUYLIST_CLICKED,
  SORT_BUYLIST_BY_SELECTED,
} from "../../constants/eventsTracked";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";
import ActionButtonsLayout from "../../components/layout/ActionButtonsLayout";
import CustomerAvatar from "../../components/customers/CustomerAvatar";
import { dismissNotification, notify } from "../../helpers/notificationHelpers";

const PRICE_LOAD_GROUP_SIZE = 20;

@inject("store")
@observer
class BuylistDetails extends Component {
  @observable cookie = new Cookie("buylistListView");

  @observable listView = this.cookie.value;

  @action setListView = (value) => {
    this.listView = value;
  };

  @observable noRedirect = false;

  @action setNoRedirect = (redirect) => {
    this.noRedirect = redirect;
  };

  @observable modalVisible = false;

  @action setModalVisible = (visible) => {
    this.modalVisible = visible;
    if (!visible) {
      this.noRedirect = visible;
    }
  };

  @observable modalTitle;

  @action setModalTitle = (title) => {
    this.modalTitle = title;
  };

  @observable modalText;

  @action setModalText = (text) => {
    this.modalText = text;
  };

  @observable declineModalTitle;

  @action setDeclineModalTitle = (title) => {
    this.declineModalTitle = title;
  };

  @observable declineModalVisible;

  @action setDeclineModalVisible = (visible) => {
    this.declineModalVisible = visible;
  };

  @observable declineModalLoading;

  @action setDeclineModalLoading = (loading) => {
    this.declineModalLoading = loading;
  };

  @observable buylistDetailToDecline;

  @action setBuylistDetailToDecline = (buylistDetail) => {
    this.buylistDetailToDecline = buylistDetail;
  };

  @observable approveModalVisible;

  @action setApproveModalVisible = (visible) => {
    this.approveModalVisible = visible;
  };

  @observable completeModalLoading;

  @action setCompleteModalLoading = (loading) => {
    this.completeModalLoading = loading;
  };

  @observable completeModalTitle;

  @action setCompleteModalTitle = (title) => {
    this.completeModalTitle = title;
  };

  @observable completeModalVisible;

  @action setCompleteModalVisible = (visible) => {
    this.completeModalVisible = visible;
  };

  @observable buylistDetailToApprove;

  @action setBuylistDetailToApprove = (buylistDetail) => {
    this.buylistDetailToApprove = splitBuylistVariants(buylistDetail);
  };

  @observable buylistDetailToComplete;

  @action setBuylistDetailToComplete = (buylistDetail) => {
    this.buylistDetailToComplete = splitBuylistVariants(buylistDetail);
  };

  @observable searchModalVisible;

  @action setSearchModalVisible = (visible) => {
    this.searchModalVisible = visible;
  };

  @observable isLoading = false;

  @action setIsLoading = (loading) => {
    this.isLoading = loading;
  };

  @observable sortField = "game";

  @action setSortField = (field) => {
    this.sortField = field;
    this.updateSort();
  };

  @observable sortAscending = true;

  @action setSortAscending = (ascending) => {
    this.sortAscending = ascending;
    this.updateSort();
  };

  @action updateSort = () => {
    const paymentType =
      this.props.store.BuylistRulesStore.buylistDetails?.paymentType;
    this.props.store.BuylistRulesStore.buylistDetails.cards.replace(
      this.props.store.BuylistRulesStore.buylistDetails.cards
        .slice()
        .sort(getSortFunction(this.sortField, this.sortAscending, paymentType))
    );
  };

  loadBuylistDetails = (buylistId) => {
    this.setIsLoading(true);
    const { BuylistRulesStore, MainStore } = this.props.store;
    BuylistRulesStore.setBuylistDetails(null);
    fetchBuylistDetails(buylistId)
      .then((result) => {
        const groupedResult = groupBuylistVariants(result);
        BuylistRulesStore.setBuylistDetails(groupedResult);
        setTimeout(() => this.batchCardsToGetPrices(groupedResult.cards), 0);
      })
      .catch((error) => {
        MainStore.setError(
          error,
          "Failed to load buylist details",
          "There was an error retrieving your buylist details. Please refresh and try again"
        );
      })
      .finally(() => {
        this.setIsLoading(false);
      });
  };

  batchCardsToGetPrices = async (cards) => {
    notify.warn("Loading prices...", { autoClose: false });
    const groups = chunkCards(cards, PRICE_LOAD_GROUP_SIZE);
    let foundErrors = false;
    let groupCount = 0;
    // eslint-disable-next-line no-restricted-syntax
    for await (const group of groups) {
      try {
        await this.getBuylistCardPrices(group);
        groupCount += 1;
        if (groupCount === groups.length) {
          dismissNotification();
          if (foundErrors) {
            notify.error("Failed to load prices for some cards");
          } else {
            setTimeout(() => notify.success("Prices loaded"), 500);
          }
        }
      } catch (err) {
        foundErrors = true;
      }
    }
  };

  getBuylistCardPrices = async (cards) => {
    const cardsByGame = {};
    cards.forEach((card) => {
      const { gameId, cardId } = card;
      if (Object.keys(cardsByGame).includes(gameId)) {
        cardsByGame[gameId].add(cardId);
      } else {
        cardsByGame[gameId] = new Set([cardId]);
      }
    });
    const searchData = [];
    Object.entries(cardsByGame).forEach(([gameId, cardIds]) => {
      const gameData = {
        game: gameId,
        ids: Array.from(cardIds),
      };
      searchData.push(gameData);
    });
    try {
      const result = await fetchCardPrices(searchData);
      runInAction(() => {
        result.forEach((matchedCard) => {
          const buylistCards =
            this.props.store.BuylistRulesStore.buylistDetails.cards.filter(
              (card) =>
                card.cardName === matchedCard.cardName &&
                card.setName === matchedCard.setName
            );
          // Iterate here as there may be one foil and one non-foil
          buylistCards.forEach((buylistCard) => {
            const { type } = buylistCard;
            matchedCard.variants.forEach((variant) => {
              const pricing = variant.cardBuylistTypes.find(
                (typePrice) =>
                  typePrice.type === type || typePrice.legacyType === type
              );
              if (
                !Object.keys(buylistCard.variants).includes(String(variant.id))
              ) {
                buylistCard.variants[variant.id] = {
                  quantity: 0,
                  cashBuyPrice: (pricing && pricing.buyPrice) || 0,
                  storeCreditBuyPrice: (pricing && pricing.creditBuyPrice) || 0,
                  storeSellPrice: (pricing && pricing.storeSellPrice) || 0,
                  variantId: variant.id,
                  variantName: variant.variantName,
                  productVariantId: (pricing && pricing.productVariantId) || "",
                  game: matchedCard.game,
                };
              } else {
                // Just upsert the sell price
                if (!buylistCard.variants[variant.id].storeSellPrice) {
                  buylistCard.variants[variant.id].storeSellPrice =
                    (pricing && pricing.storeSellPrice) || 0;
                }
                buylistCard.variants[variant.id].game = matchedCard.game;
                buylistCard.variants[variant.id].productVariantId =
                  (pricing && pricing.productVariantId) || "";
              }
            });
          });
        });
      });
    } catch (error) {
      this.props.store.MainStore.setError(
        error,
        "Failed to load prices",
        "There was an error retrieving your buylist card prices. Please refresh and try again"
      );
    }
  };

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.viewRef = React.createRef();
    this.modalRef = React.createRef();
  }

  componentDidMount() {
    document.title = "Buylist details | BinderPOS";
    const { CardStore, CustomersStore, MainStore, MenuStore } =
      this.props.store;
    MainStore.getCurrency();
    MenuStore.setSideMenuToDisplay(BuylistMenuStructure);
    this.setModalVisible(false);
    this.setModalTitle("");
    this.setModalText("");
    const buylistId = this.props.match?.params?.buylistId || null;
    CustomersStore.getCustomerVariants()
      .then((result) => {
        CustomersStore.setCustomerVariants(result);
      })
      .catch((error) => {
        MainStore.setError(
          error,
          "Failed to load buylist variants",
          "There was an error retrieving your store's variants. Please refresh and try again"
        );
      });
    CardStore.fetchCardGames()
      .then((result) => {
        CardStore.setGames(result);
      })
      .catch((error) => {
        MainStore.setError(
          error,
          "Failed to load buylist games",
          "There was an error retrieving your store's supported games. Please refresh and try again"
        );
      });

    if (buylistId && buylistId > 0) {
      this.loadBuylistDetails(buylistId);
    }
  }

  componentDidUpdate() {
    if (
      this.searchModalVisible ||
      this.declineModalVisible ||
      this.approveModalVisible ||
      this.completeModalVisible ||
      this.modalVisible
    ) {
      this.modalRef.current?.focus();
    } else {
      this.viewRef.current?.focus();
    }
  }

  @action onVariantQuantityChange = (
    cardName,
    cardId,
    setName,
    type,
    isOverStock,
    variantId,
    newQuantity
  ) => {
    const { buylistDetails } = this.props.store.BuylistRulesStore;
    const buylistCard = buylistDetails.cards.find(
      (card) =>
        card.cardName === cardName &&
        card.setName === setName &&
        card.cardId === cardId &&
        card.type === type &&
        card.isOverStock === isOverStock
    );
    const variant = buylistCard?.variants[variantId];
    if (variant) {
      variant.quantity = newQuantity;
    }
  };

  @action onVariantBuyPriceChange = (
    cardName,
    cardId,
    setName,
    type,
    isOverStock,
    variantId,
    isPaidInCash,
    newPrice
  ) => {
    const { buylistDetails } = this.props.store.BuylistRulesStore;
    const buylistCard = buylistDetails.cards.find(
      (card) =>
        card.cardName === cardName &&
        card.cardId === cardId &&
        card.setName === setName &&
        card.type === type &&
        card.isOverStock === isOverStock
    );
    const variant = buylistCard?.variants[variantId];
    if (variant) {
      if (isPaidInCash) {
        variant.cashBuyPrice = newPrice;
      } else {
        variant.storeCreditBuyPrice = newPrice;
      }
    }
  };

  @action removeLine = (index) => {
    segmentAnalytics.track(REMOVE_ITEM_FROM_BUYLIST_CLICKED, {
      buylist_type: this.props.buylistType,
    });
    this.props.store.BuylistRulesStore.buylistDetails.cards.splice(index, 1);
  };

  showDeclineModal = (buylistDetail) => {
    this.setBuylistDetailToDecline(buylistDetail);
    this.setDeclineModalTitle("Decline buylist request");
    this.setDeclineModalVisible(true);
  };

  declineBuylist = (event, reason) => {
    event.preventDefault();
    this.setDeclineModalLoading(true);
    const buylistDetail = JSON.parse(
      JSON.stringify(this.buylistDetailToDecline)
    );
    buylistDetail.declinedReason = reason;
    doDeclineBuylist(buylistDetail)
      .then(() => {
        segmentAnalytics.track(BUYLIST_DECLINED, {
          buylist_type: this.props.buylistType,
        });
        this.props.store.BuylistRulesStore.rehydratePendingBuylists();
        this.setDeclineModalVisible(false);
        this.setDeclineModalLoading(false);
        this.setModalTitle("Buylist declined");
        this.setModalText(
          `You have declined the buylist from ${buylistDetail.shopifyCustomer.firstName} ${buylistDetail.shopifyCustomer.lastName}`
        );
        this.setModalVisible(true);
      })
      .catch((error) => {
        this.props.store.MainStore.setError(
          error,
          "Failed to decline buylists",
          "There was an error declining your buylist. Please try again"
        );
      });
  };

  showApproveModal = (buylistDetail) => {
    this.setBuylistDetailToApprove(buylistDetail);
    this.setApproveModalVisible(true);
  };

  showCompleteModal = (buylistDetail) => {
    this.setBuylistDetailToComplete(buylistDetail);
    this.setCompleteModalTitle("Complete buylist request");
    this.setCompleteModalVisible(true);
  };

  saveChanges = (buylistDetail) => {
    this.setNoRedirect(true);
    saveBuylist(splitBuylistVariants(buylistDetail))
      .then(() => {
        segmentAnalytics.track(SAVE_CHANGES_TO_BUYLIST_CLICKED, {
          buylist_type: this.props.buylistType,
        });
        this.setModalTitle("Buylist changes saved!");
        this.setModalText(
          "The changes you have made to the buylist have been saved!"
        );
        this.setModalVisible(true);
      })
      .catch((error) => {
        this.props.store.MainStore.setError(
          error,
          "Failed to save buylist",
          "There was an error saving your changes. Please try again"
        );
        this.setNoRedirect(false);
      });
  };

  @action addCardToList = (buylistCard) => {
    let found = false;
    this.props.store.BuylistRulesStore.buylistDetails.cards.forEach(
      (existing) => {
        if (
          buylistCard.cardName === existing.cardName &&
          buylistCard.setName === existing.setName &&
          buylistCard.rarity === existing.rarity &&
          buylistCard.type === existing.type
        ) {
          found = true;
        }
      }
    );
    if (found) {
      // Card already exists. TODO:: Perhaps focus cursor on it?
      return;
    }
    const variants = {};
    Object.keys(buylistCard.variants).forEach((variantId) => {
      const variant = buylistCard.variants[variantId];
      const variantType = variant.cardBuylistTypes.find(
        (cardBuylistType) => cardBuylistType.type === buylistCard.type
      );
      variants[variant.id] = {
        cashBuyPrice: variantType?.buyPrice || 0,
        quantity: 0,
        productVariantId: variantType?.productVariantId || "",
        storeCreditBuyPrice: variantType?.creditBuyPrice || 0,
        storeSellPrice: variantType?.storeSellPrice || 0,
        variantId: variant.id,
        variantName: variant.variantName,
      };
    });
    const card = {
      cardId: buylistCard.cardId,
      cardName: buylistCard.cardName,
      foil: buylistCard.foil,
      game: buylistCard.game,
      gameId: buylistCard.gameId,
      imageUrl: buylistCard.imageUrl,
      rarity: buylistCard.rarity,
      setName: buylistCard.setName,
      shopifyCustomerBuylistId: buylistCard.shopifyCustomerBuylistId,
      type: buylistCard.type,
      variants,
    };
    this.props.store.BuylistRulesStore.buylistDetails.cards.push(card);
    this.setSearchModalVisible(false);
  };

  changeListView = () => {
    this.cookie.set("enabled", { expires: 365 });
    segmentAnalytics.track(BUYLIST_LIST_VIEW_CLICKED, {
      buylist_type: this.props.buylistType,
    });
    this.setListView(this.cookie.value);
  };

  changeGridView = () => {
    this.cookie.set("disabled", { expires: 365 });
    segmentAnalytics.track(BUYLIST_GRID_VIEW_CLICKED, {
      buylist_type: this.props.buylistType,
    });
    this.setListView(this.cookie.value);
  };

  saveToCSV = (csvHeaders) => {
    const { BuylistRulesStore } = this.props.store;
    const { buylistDetails } = BuylistRulesStore;
    const { id, shopifyCustomer, cards } = buylistDetails;

    const rows = cards.map((card) => {
      const { variants, ...cardDetails } = card;
      return Object.values(variants)
        .filter((variant) => variant.quantity)
        .map((variant) => ({
          ...cardDetails,
          ...variant,
        }));
    });

    const { firstName, lastName } = shopifyCustomer;
    const csvFilename = `Buylist ${id} - ${firstName} ${lastName}.csv`;
    convertToCsv(csvHeaders, rows.flat()).then((csv) => {
      forceDownloadFromData(csv, csvFilename, "text/csv");
    });
  };

  saveBuylistCSV = () => {
    const { BuylistRulesStore, MainStore } = this.props.store;
    const fC = MainStore.currencyBuilder;
    const { paymentType } = BuylistRulesStore.buylistDetails;
    const credit = paymentType === "Store Credit";

    const buylistFullCSVHeaders = [
      ...buylistCSVHeaders,
      {
        name: `${credit ? "Store Credit" : "Cash"} Buy Price (each)`,
        key: credit ? "storeCreditBuyPrice" : "cashBuyPrice",
        formatter: (val) => fC(val),
      },
      {
        name: "Sell Price (each)",
        key: "storeSellPrice",
        formatter: (val) => fC(val),
      },
    ];

    segmentAnalytics.track(EXPORT_BUYLIST_CSV_CLICKED, {
      buylist_type: this.props.buylistType,
    });

    this.saveToCSV(buylistFullCSVHeaders);
  };

  saveImporterCompatibleCSV = () => {
    segmentAnalytics.track(EXPORT_BUYLIST_STOCK_ADJUSTMENT_CSV_CLICKED, {
      buylist_type: this.props.buylistType,
    });
    this.saveToCSV(importerCompatibleBuylistCSVHeaders);
  };

  render() {
    const { AuthStore, BuylistRulesStore, CardStore, MainStore } =
      this.props.store;
    const fC = MainStore.currencyBuilder;

    if (!AuthStore.screenSettings.buylist) {
      return (
        <div>
          <p>Please contact BinderPOS to enable this screen.</p>
        </div>
      );
    }

    let buylistTotal = 0;
    const isPaidInCash =
      BuylistRulesStore.buylistDetails?.paymentType === "Cash";
    let zeroQuantityDetected = false;
    BuylistRulesStore.buylistDetails &&
      BuylistRulesStore.buylistDetails.cards.forEach((card) => {
        const totalQuantity = Object.values(card.variants).reduce(
          (sum, variant) => sum + parseFloat(variant.quantity),
          0
        );
        if (totalQuantity === 0) {
          zeroQuantityDetected = true;
        }
        const cardTotal = Object.values(card.variants).reduce(
          (sum, variant) =>
            sum +
            parseFloat(variant.quantity) *
              parseFloat(
                isPaidInCash
                  ? variant.cashBuyPrice
                  : variant.storeCreditBuyPrice
              ),
          0
        );
        buylistTotal += cardTotal;
      });

    return (
      <>
        {BuylistRulesStore.buylistDetails && CardStore.games ? (
          <>
            <SectionHeaderLayout
              title={
                BuylistRulesStore.buylistDetails.finalBuylistDetails &&
                BuylistRulesStore.buylistDetails.finalBuylistDetails.length > 0
                  ? "Viewing Completed buylist"
                  : "Review Submitted Buylist"
              }
            >
              <ActionButtonsLayout>
                {BuylistRulesStore.buylistDetails.completed ? (
                  <>
                    <UncompleteBuylist
                      buylistId={BuylistRulesStore.buylistDetails.id}
                    />
                    <PushStock
                      buylistId={BuylistRulesStore.buylistDetails.id}
                      pushProducts={
                        BuylistRulesStore.buylistDetails.pushProducts
                      }
                    />
                    <PushStoreCredit
                      buylistId={BuylistRulesStore.buylistDetails.id}
                      applyStoreCredit={
                        BuylistRulesStore.buylistDetails.applyStoreCredit
                      }
                      paymentType={BuylistRulesStore.buylistDetails.paymentType}
                    />
                  </>
                ) : (
                  <>
                    {BuylistRulesStore.buylistDetails.approved ? (
                      <ButtonComponent
                        primary
                        onClick={(event) => {
                          this.viewRef.current = event.currentTarget;
                          this.showCompleteModal(
                            BuylistRulesStore.buylistDetails
                          );
                        }}
                      >
                        Complete
                      </ButtonComponent>
                    ) : (
                      <ButtonComponent
                        primary
                        onClick={(event) => {
                          this.viewRef.current = event.currentTarget;
                          this.showApproveModal(
                            BuylistRulesStore.buylistDetails
                          );
                        }}
                      >
                        Approve
                      </ButtonComponent>
                    )}
                    &nbsp;
                    <ButtonComponent
                      danger
                      onClick={(event) => {
                        this.viewRef.current = event.currentTarget;
                        this.showDeclineModal(BuylistRulesStore.buylistDetails);
                      }}
                    >
                      Decline
                    </ButtonComponent>
                    &nbsp;
                    <ButtonComponent
                      onClick={() => {
                        segmentAnalytics.track(BUYLIST_CANCELED, {
                          buylist_type: this.props.buylistType,
                        });
                        this.props.history.goBack();
                      }}
                    >
                      Cancel
                    </ButtonComponent>
                  </>
                )}
              </ActionButtonsLayout>
            </SectionHeaderLayout>
            <div className="buylistDetailsPanels">
              <div className="card buylist-metadata">
                <div className="card-header">
                  <CustomerAvatar
                    customer={BuylistRulesStore.buylistDetails.shopifyCustomer}
                    className={
                      BuylistRulesStore.buylistDetails.paymentType == "Cash"
                        ? "red"
                        : "blue"
                    }
                  />
                  <span>
                    <span className="customer-name">
                      {
                        BuylistRulesStore.buylistDetails.shopifyCustomer
                          .firstName
                      }{" "}
                      {
                        BuylistRulesStore.buylistDetails.shopifyCustomer
                          .lastName
                      }
                    </span>
                    <span className="customer-email">
                      {BuylistRulesStore.buylistDetails.shopifyCustomer.email}
                    </span>
                  </span>
                </div>
                <div className="card-body">
                  <span className="label">Buylist:</span>
                  <span className="value" data-testid="buylistId">
                    #{BuylistRulesStore.buylistDetails.id}
                  </span>
                  <span className="label">Date Submitted:</span>
                  <span className="value">
                    {TimeUtils.convertDateToHumanReadable(
                      BuylistRulesStore.buylistDetails.readableSubmittedDate
                    )}
                  </span>
                  {BuylistRulesStore.buylistDetails.completed && (
                    <>
                      <span className="label">Date Completed:</span>
                      <span className="value">
                        {TimeUtils.convertDateToHumanReadable(
                          BuylistRulesStore.buylistDetails.readableCompletedDate
                        )}
                      </span>
                      <span className="label">Approved:</span>
                      <span className="value">
                        {BuylistRulesStore.buylistDetails.approved
                          ? "Yes"
                          : "No"}
                      </span>
                    </>
                  )}
                  {BuylistRulesStore.buylistDetails.completed && (
                    <>
                      {BuylistRulesStore.buylistDetails.approved ? (
                        <>
                          <span className="label">Approval note:</span>
                          <span className="value">
                            {BuylistRulesStore.buylistDetails.approvedNotes &&
                            BuylistRulesStore.buylistDetails.approvedNotes != ""
                              ? BuylistRulesStore.buylistDetails.approvedNotes
                              : "No approval notes provided"}
                          </span>
                        </>
                      ) : (
                        <>
                          <span className="label">Reason for declining:</span>
                          <span className="value">
                            {BuylistRulesStore.buylistDetails.declinedReason &&
                            BuylistRulesStore.buylistDetails.declinedReason !=
                              ""
                              ? BuylistRulesStore.buylistDetails.declinedReason
                              : "There was no reason provided for declining this buylist"}
                          </span>
                        </>
                      )}
                    </>
                  )}
                  <span className="label">Payment Type:</span>
                  <span className="value">
                    {BuylistRulesStore.buylistDetails.paymentType}
                  </span>
                  <span className="label">Buylist Value:</span>
                  <span className="value">{fC(buylistTotal)}</span>
                </div>
              </div>
            </div>
            <br />
            <div className="hidden">
              <PrintBuylist ref={(el) => (this.componentRef = el)} />
            </div>
            <div className="row buylist-controls">
              <label className="col sort">
                Sort by{" "}
                <select
                  className="mr-sm-2"
                  onChange={(event) => {
                    segmentAnalytics.track(SORT_BUYLIST_BY_SELECTED, {
                      buylist_type: this.props.buylistType,
                      sort_by: event.target.value,
                    });
                    this.setSortField(event.target.value);
                  }}
                  data-testid="select-sort"
                >
                  <option value="game">Game</option>
                  <option value="name">Card Name</option>
                  <option value="set">Set Name</option>
                  <option value="buy">Total Buy Price</option>
                  <option value="sell">Total Sell Price</option>
                  <option value="qty">Quantity</option>
                </select>{" "}
                <SortDirection
                  ascending={this.sortAscending}
                  setAscending={this.setSortAscending}
                />
              </label>
              <div className="viewChange col">
                <ReactToPrint
                  onBeforePrint={() => {
                    segmentAnalytics.track(PRINT_BUYLIST_CLICKED, {
                      buylist_type: this.props.buylistType,
                    });
                    const printWindow = document.getElementById("printWindow");
                    if (printWindow) {
                      printWindow.style.width = "100%";
                    }
                  }}
                  trigger={() => (
                    <button className="printBuylist">
                      <i className="far fa-print" /> Print
                    </button>
                  )}
                  content={() => this.componentRef}
                />
                <button
                  type="button"
                  className="printBuylist"
                  onClick={this.saveImporterCompatibleCSV}
                >
                  <i className="far fa-save" /> Export Stock Adjustment CSV
                </button>
                <button
                  type="button"
                  className="printBuylist"
                  onClick={this.saveBuylistCSV}
                >
                  <i className="far fa-save" /> Export CSV
                </button>
                <button
                  type="button"
                  style={{ marginRight: "20px" }}
                  className={`buylistView ${
                    this.cookie.value == "disabled" ? "active" : ""
                  }`}
                  onClick={this.changeGridView}
                >
                  <i className="fas fa-th" /> Grid View
                </button>
                <button
                  type="button"
                  className={`buylistView ${
                    this.cookie.value == "disabled" ? "" : "active"
                  }`}
                  onClick={this.changeListView}
                >
                  <i className="fas fa-th-list" /> List View
                </button>
              </div>
            </div>
            <Spinner isLoading={this.isLoading}>
              {this.listView == "enabled" ? (
                <ListView
                  onVariantQuantityChange={this.onVariantQuantityChange}
                  onVariantBuyPriceChange={this.onVariantBuyPriceChange}
                  removeLine={this.removeLine}
                  setSearchModalVisible={(visible, target) => {
                    this.viewRef.current = target;
                    this.setSearchModalVisible(visible);
                  }}
                  viewRef={this.viewRef}
                />
              ) : (
                <GridView
                  onVariantQuantityChange={this.onVariantQuantityChange}
                  onVariantBuyPriceChange={this.onVariantBuyPriceChange}
                  removeLine={this.removeLine}
                  setSearchModalVisible={(visible, target) => {
                    this.viewRef.current = target;
                    this.setSearchModalVisible(visible);
                  }}
                  viewRef={this.viewRef}
                />
              )}
            </Spinner>
            <ActionButtonsLayout alignment="right">
              {BuylistRulesStore.buylistDetails.completed === true ? (
                <ButtonComponent primary isLink to="/buylists/completed">
                  Done
                </ButtonComponent>
              ) : (
                <>
                  {BuylistRulesStore.buylistDetails.approved === true ? (
                    <>
                      <ButtonComponent
                        primary
                        onClick={(event) => {
                          this.viewRef.current = event.currentTarget;
                          this.saveChanges(BuylistRulesStore.buylistDetails);
                        }}
                      >
                        Save changes
                      </ButtonComponent>
                      &nbsp;
                      <ButtonComponent
                        danger
                        onClick={(event) => {
                          this.viewRef.current = event.currentTarget;
                          this.showDeclineModal(
                            BuylistRulesStore.buylistDetails
                          );
                        }}
                      >
                        Decline
                      </ButtonComponent>
                      &nbsp;
                      <ButtonComponent
                        primary
                        onClick={(event) => {
                          this.viewRef.current = event.currentTarget;
                          this.showCompleteModal(
                            BuylistRulesStore.buylistDetails
                          );
                        }}
                      >
                        Complete
                      </ButtonComponent>
                    </>
                  ) : (
                    <>
                      <ButtonComponent
                        primary
                        onClick={(event) => {
                          this.viewRef.current = event.currentTarget;
                          this.saveChanges(BuylistRulesStore.buylistDetails);
                        }}
                      >
                        Save changes
                      </ButtonComponent>
                      &nbsp;
                      <ButtonComponent
                        primary
                        onClick={(event) => {
                          this.viewRef.current = event.currentTarget;
                          this.showApproveModal(
                            BuylistRulesStore.buylistDetails
                          );
                        }}
                      >
                        Approve
                      </ButtonComponent>
                      &nbsp;
                      <ButtonComponent
                        danger
                        onClick={(event) => {
                          this.viewRef.current = event.currentTarget;
                          this.showDeclineModal(
                            BuylistRulesStore.buylistDetails
                          );
                        }}
                      >
                        Decline
                      </ButtonComponent>
                      &nbsp;
                      <ButtonComponent
                        onClick={() => this.props.history.goBack()}
                      >
                        Cancel
                      </ButtonComponent>
                    </>
                  )}
                </>
              )}
            </ActionButtonsLayout>
            {this.searchModalVisible && (
              <SearchModal
                setVisible={this.setSearchModalVisible}
                addCardToList={this.addCardToList}
                forwardedRef={this.modalRef}
              />
            )}
            {this.declineModalVisible && (
              <DeclineModal
                isLoading={this.declineModalLoading}
                setVisible={this.setDeclineModalVisible}
                declineBuylist={this.declineBuylist}
                forwardedRef={this.modalRef}
              />
            )}
            {this.approveModalVisible ? (
              <ApproveBuylistModal
                buylistType={this.props.buylistType}
                zeroQuantityDetected={zeroQuantityDetected}
                paymentType={BuylistRulesStore.buylistDetails.paymentType}
                buylistDetail={this.buylistDetailToApprove}
                handleClose={() => this.setApproveModalVisible(false)}
                ref={this.modalRef}
              />
            ) : null}
            {this.completeModalVisible ? (
              <CompleteBuylistModal
                zeroQuantityDetected={zeroQuantityDetected}
                paymentType={BuylistRulesStore.buylistDetails.paymentType}
                pushProducts={BuylistRulesStore.buylistDetails.pushProducts}
                applyStoreCredit={
                  BuylistRulesStore.buylistDetails.applyStoreCredit
                }
                buylistDetail={this.buylistDetailToComplete}
                handleClose={() => this.setCompleteModalVisible(false)}
              />
            ) : null}

            {this.modalVisible ? (
              <Modal
                onClose={() => this.setModalVisible(false)}
                ref={this.modalRef}
              >
                <Modal.Header>{this.modalTitle}</Modal.Header>
                <Modal.Content>{this.modalText}</Modal.Content>
                <Modal.Actions>
                  {this.hasValidationErrors || this.noRedirect ? (
                    <ButtonComponent
                      primary
                      onClick={() => this.setModalVisible(false)}
                      ref={this.modalRef}
                    >
                      Ok
                    </ButtonComponent>
                  ) : (
                    <>
                      {BuylistRulesStore.buylistDetails.finalBuylistDetails &&
                      BuylistRulesStore.buylistDetails.finalBuylistDetails
                        .length > 0 ? (
                        <ButtonComponent
                          isLink
                          to="/buylists/completed"
                          primary
                          ref={this.modalRef}
                        >
                          Ok
                        </ButtonComponent>
                      ) : (
                        <>
                          {BuylistRulesStore.buylistDetails.approved ? (
                            <ButtonComponent
                              isLink
                              to="/buylists/approved"
                              primary
                              ref={this.modalRef}
                            >
                              Ok
                            </ButtonComponent>
                          ) : (
                            <ButtonComponent
                              isLink
                              to="/buylists/pending"
                              primary
                              ref={this.modalRef}
                            >
                              Ok
                            </ButtonComponent>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Modal.Actions>
              </Modal>
            ) : null}
          </>
        ) : (
          <Loader />
        )}
      </>
    );
  }
}

export default BuylistDetails;
