import React from "react";
import Tooltip from "../generic/Tooltip";
import "./SubSectionHeaderLayout.scss";

interface SectionHeaderLayoutProps {
  title: string;
  tooltip?: React.ReactNode;
  children?: React.ReactNode;
}

function SubSectionHeaderLayout(props: SectionHeaderLayoutProps) {
  const { title, tooltip, children } = props;

  return (
    <header className="SubSectionHeaderLayout">
      <div className="SubSectionHeaderLayout__heading">
        <h2 className="SubSectionHeaderLayout__title">{title}</h2>
        {tooltip ? <Tooltip>{tooltip}</Tooltip> : null}
      </div>
      <div>{children}</div>
    </header>
  );
}

export default SubSectionHeaderLayout;
