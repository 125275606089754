import React, { useEffect, useState } from "react";
import { API_STATE } from "../../constants/api";
import { useFetchTotalSalesByCategory } from "../../hooks/reports/useFetchTotalSalesByCategory";
import ReportControls from "../../components/reports/ReportControls";
import { totalSalesByCategoryCSVHeaders } from "../../constants/reportCSVHeaders";
import Pagination from "../../components/reports/Pagination";
import {
  CurrencyRenderer,
  TextInputFilter,
} from "../../components/reports/CellTypes";
import { useReportsDetails } from "../../hooks/reportsHooks";
import { useStoreDetails } from "../../hooks/storeHooks";
import { useShowError } from "../../hooks/errorHooks";
import SetActiveMenu from "../../menuStructures/SetActiveMenu";
import ReportsMenuStructure from "../../menuStructures/ReportsMenuStructure";
import { rowPerPageOptions } from "../../constants/reports";
import { Cell, Column, Table } from "rsuite-table";
import "rsuite-table/dist/css/rsuite-table.css";
import "../../../css/rstable-overwrites.scss";
import CustomHeaderCell from "../../components/reports/CustomHeader";

function TotalSalesByCategory() {
  const {
    apiState,
    currentPage,
    filterValues,
    overrideTableHeight,
    rowsPerPage,
    sortColumn,
    tableData,
    computedTableData,
    reset,
    setApiState,
    setCurrentPage,
    setTableRowsPerPage,
    setFilters,
    setSortingColumn,
    setSourceDataDetails,
    updateFilterValue,
  } = useReportsDetails();
  const { currencyCode } = useStoreDetails();
  const showError = useShowError();
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();

  const {
    data: sourceData,
    isFetching,
    error,
  } = useFetchTotalSalesByCategory(
    { startDate, endDate },
    { enabled: !!startDate && !!endDate }
  );

  useEffect(() => {
    document.title = "Reports Beta | BinderPOS";
    reset();
    setFilters(filters);
  }, []);

  useEffect(() => {
    if (isFetching) {
      setApiState(API_STATE.LOADING);
    } else if (error) {
      showError(
        error as any,
        "Failed to load report",
        "There was an error retrieving your sales by category report. Please try again"
      );
      setApiState(API_STATE.ERROR);
    } else if (sourceData) {
      setSourceDataDetails(sourceData);
      setApiState(API_STATE.SUCCESS);
    }
  }, [isFetching, sourceData, error]);

  const filters = [
    {
      name: "product type",
      condition: (filterValues) => filterValues?.productType?.length,
      filterFunction: (row, filterValues) =>
        row?.productType
          ?.toLowerCase()
          ?.includes(filterValues?.productType?.toLowerCase()),
    },
  ];

  const handleSetDateRange = (startDate: string, endDate: string) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const csvFilename = "Total Sales By Category Report.csv";

  const data = computedTableData();

  return (
    <>
      <SetActiveMenu menuStructure={ReportsMenuStructure} />
      <div className="app-header">
        <h1 className="title">
          <span className="header-text">Total Sales By Category</span>
        </h1>
      </div>
      <div className="report">
        <ReportControls
          setDateRange={handleSetDateRange}
          csvHeaders={totalSalesByCategoryCSVHeaders}
          csvFilename={csvFilename}
        />
        {apiState !== API_STATE.INITIAL && (
          <div className="data-table">
            <div className="table-container">
              <Table
                data={data}
                headerHeight={80}
                renderEmpty={() => <div>No data to display</div>}
                loading={apiState == API_STATE.LOADING}
                onSortColumn={setSortingColumn}
                sortColumn={sortColumn.key}
                sortType={sortColumn.order}
                className="rs-table--multi-header"
                height={overrideTableHeight}
                fillHeight={overrideTableHeight === 0}
              >
                <Column
                  key="col_productType"
                  width={200}
                  flexGrow={3}
                  sortable
                  resizable
                >
                  <CustomHeaderCell title="Category">
                    <TextInputFilter
                      aria-label="Filter by product type"
                      title="Product type"
                      value={filterValues?.productType || ""}
                      setValue={(value) =>
                        updateFilterValue("productType", value)
                      }
                    />
                  </CustomHeaderCell>
                  <Cell dataKey="productType" />
                </Column>
                <Column
                  key="col_buys"
                  width={100}
                  flexGrow={1}
                  align="right"
                  sortable
                  resizable
                >
                  <CustomHeaderCell title="Buys">
                    {CurrencyRenderer({
                      currency: currencyCode,
                      cellData:
                        tableData.length &&
                        tableData.reduce((sum, row) => sum + row.buys, 0),
                    })}
                  </CustomHeaderCell>
                  <Cell dataKey="buys">
                    {(rowData) =>
                      CurrencyRenderer({
                        cellData: rowData.buys,
                        currency: currencyCode,
                      })
                    }
                  </Cell>
                </Column>
                <Column
                  key="col_sales"
                  width={100}
                  flexGrow={1}
                  align="right"
                  sortable
                  resizable
                >
                  <CustomHeaderCell title="Sales">
                    {CurrencyRenderer({
                      currency: currencyCode,
                      cellData:
                        tableData.length &&
                        tableData.reduce((sum, row) => sum + row.sales, 0),
                    })}
                  </CustomHeaderCell>
                  <Cell dataKey="sales">
                    {(rowData) =>
                      CurrencyRenderer({
                        cellData: rowData.sales,
                        currency: currencyCode,
                      })
                    }
                  </Cell>
                </Column>
                <Column
                  key="col_total"
                  width={100}
                  flexGrow={1}
                  align="right"
                  sortable
                  resizable
                >
                  <CustomHeaderCell title="Total (excluding tax)">
                    {CurrencyRenderer({
                      currency: currencyCode,
                      cellData:
                        tableData.length &&
                        tableData.reduce((sum, row) => sum + row.total, 0),
                      style: { paddingRight: "8px" },
                    })}
                  </CustomHeaderCell>
                  <Cell dataKey="total">
                    {(rowData) =>
                      CurrencyRenderer({
                        cellData: rowData.total,
                        currency: currencyCode,
                        style: { paddingRight: "8px" },
                      })
                    }
                  </Cell>
                </Column>
              </Table>
            </div>
            <Pagination
              apiState={apiState}
              currentPage={currentPage}
              totalRowCount={tableData.length}
              rowsPerPage={rowsPerPage}
              rowPerPageOptions={rowPerPageOptions}
              setCurrentPage={setCurrentPage}
              setRowsPerPage={setTableRowsPerPage}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default TotalSalesByCategory;
