import React, { useState } from "react";
import Modal from "./Modal";
import ButtonComponent from "./ButtonComponent";
import { Heading } from "../../types/elements";

interface ConfirmationModalProps {
  title?: string;
  cancelAction?: () => void;
  cancelWord?: string;
  confirmAction?: () => void;
  confirmWord?: string;
  link?: string;
  titleElement?: Heading;
  isLoading?: boolean;
  children: React.ReactNode;
}

function ConfirmationModal(
  props: ConfirmationModalProps,
  ref: React.MutableRefObject<any>
) {
  const {
    title = "Are you sure?",
    cancelAction,
    cancelWord = "Cancel",
    confirmAction,
    confirmWord = "Confirm",
    link,
    titleElement,
    isLoading: isParentLoading = false,
    children,
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = () => {
    setIsLoading(true);
    confirmAction();
  };

  return (
    <Modal onClose={cancelAction} isLoading={isLoading || isParentLoading}>
      <Modal.Header as={titleElement}>{title}</Modal.Header>
      <Modal.Content>{children}</Modal.Content>
      <Modal.Actions>
        {cancelAction ? (
          <ButtonComponent secondary onClick={() => cancelAction()}>
            {cancelWord}
          </ButtonComponent>
        ) : null}
        {confirmAction ? (
          <ButtonComponent
            primary
            disabled={isLoading}
            onClick={() => handleConfirm()}
            ref={ref}
          >
            {confirmWord}
          </ButtonComponent>
        ) : (
          <React.Fragment>
            {link ? (
              <ButtonComponent primary isLink to={link} ref={ref}>
                {confirmWord}
              </ButtonComponent>
            ) : null}
          </React.Fragment>
        )}
      </Modal.Actions>
    </Modal>
  );
}

export default React.forwardRef(ConfirmationModal);
