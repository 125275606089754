import CustomerAvatar from "../../components/customers/CustomerAvatar";
import IconButton from "../../components/generic/IconButton";
import { Column } from "../../types/table";

const CustomerColumns: Column[] = [
  {
    header: "",
    accessorKey: "id",
    cell: (_value, _column, customer: Customer) => (
      <CustomerAvatar
        customer={customer}
        className={customer.storeCredit > 0 ? "red" : "blue"}
      />
    ),
  },
  {
    header: "Name",
    accessorKey: "firstName",
    cell: (_value, _column, customer: Customer) =>
      [customer.firstName, customer.lastName].join(" "),
  },
  {
    header: "Email",
    accessorKey: "email",
    cell: (email) =>
      email ? (
        <a href={`mailto:${email}`}>{email}</a>
      ) : (
        <>
          <i className="fa fa-exclamation-triangle" aria-hidden="true" />
          <span className="noEmail"> No Email Provided</span>
        </>
      ),
  },
  {
    header: "Phone",
    accessorKey: "phone",
    cell: (phone) =>
      phone ? (
        <a href={`tel:${phone}`}>{phone}</a>
      ) : (
        <>
          <span>N/A</span>
        </>
      ),
  },
  {
    header: "Store Credit",
    accessorKey: "storeCredit",
    cell: (
      _value,
      _column,
      customer: Customer,
      _index,
      { listRef, showAdjustModal, formatCurrency }
    ) => (
      <>
        <IconButton
          className="userActions"
          title={`Adjust store credit - id: ${customer.id}`}
          onClick={(event) => {
            listRef.current = event.currentTarget;
            showAdjustModal(customer);
          }}
          icon="far fa-badge-dollar"
          tooltip
        />
        {formatCurrency(customer.storeCredit) ?? " - "}
      </>
    ),
  },
  {
    header: "Edit Customer Details",
    accessorKey: "id",
    cell: (id) => (
      <IconButton
        title={`Edit customer details - id: ${id}`}
        to={`/customers/update/${id}`}
        icon="far fa-edit edit"
        isLink
      />
    ),
    className: "CustomerList__editDetails",
    headerClassName: "CustomerList__editDetails",
  },
  {
    header: "Store Credit History",
    accessorKey: "id",
    cell: (
      id,
      _column,
      customer: Customer,
      _index,
      { listRef, viewHistory }
    ) => (
      <IconButton
        title={`Store credit history - id: ${customer.id}`}
        onClick={(event) => {
          listRef.current = event.currentTarget;
          viewHistory(customer);
        }}
        icon="far fa-history history"
      />
    ),
    className: "CustomerList__creditHistory",
    headerClassName: "CustomerList__creditHistory",
  },
];

export default CustomerColumns;
