import React, { useEffect, useState } from "react";
import { EventMenuStructure } from "../../menuStructures";
import {
  ButtonComponent,
  ConfirmationModal,
  Loader,
  Paging,
} from "../../components";
import { EventCard } from "../../components/events";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";
import GridLayout from "../../components/layout/GridLayout";
import {
  useFetchFailedToDeleteEvents,
  useRetryFailedToDelete,
} from "../../hooks/eventHooks";
import useTitle from "../../hooks/useTitle";
import SetActiveMenu from "../../menuStructures/SetActiveMenu";
import { useShowError } from "../../hooks/errorHooks";

const LIMIT = 100;

function EventFailedToDelete() {
  const [offset, setOffset] = useState(0);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const {
    data: failedToDeleteEvents = [],
    isLoading,
    error: loadingError,
  } = useFetchFailedToDeleteEvents(offset, LIMIT);
  const { mutateAsync: retryDelete, isMutating: isDeleting } =
    useRetryFailedToDelete();
  const showError = useShowError();
  useTitle("Failed events");

  const handleRetryDeleteClick = (eventId: number) =>
    retryDelete(eventId)
      .then((result) => {
        if (!result.actionPass) {
          throw new Error(result.message);
        }
        setShowSuccessMessage(true);
      })
      .catch((error) =>
        showError(
          error,
          "Issue deleting event from Shopify",
          "An unknown error occurred."
        )
      );

  const handlePrevPageClick = () =>
    setOffset((prev) => Math.max(0, prev - LIMIT));

  const handleNextPageClick = () => setOffset((prev) => prev + LIMIT);

  useEffect(() => {
    if (loadingError) showError(loadingError as DetailedError);
  }, [loadingError]);

  if (isLoading || isDeleting) {
    return (
      <>
        <SetActiveMenu menuStructure={EventMenuStructure} />
        <Loader />
      </>
    );
  }

  return (
    <div className="EventFailedToDelete">
      <SetActiveMenu menuStructure={EventMenuStructure} />
      <SectionHeaderLayout title="Event tickets that had issues being deleted" />
      <Paging
        pageOffset={offset}
        currentPageItemCount={failedToDeleteEvents.length}
        maxItemsPerPage={LIMIT}
        getPrevPage={handlePrevPageClick}
        getNextPage={handleNextPageClick}
      />
      <GridLayout>
        {failedToDeleteEvents.map((failedToDelete) => (
          <EventCard key={failedToDelete.id} event={failedToDelete}>
            <EventCard.Actions>
              <ButtonComponent
                primary
                fullWidth
                icon="far fa-calendar-edit"
                iconPosition="left"
                onClick={() =>
                  handleRetryDeleteClick(failedToDelete.eventToDeleteId)
                }
              >
                Retry event deletion
              </ButtonComponent>
            </EventCard.Actions>
          </EventCard>
        ))}
      </GridLayout>
      {showSuccessMessage ? (
        <ConfirmationModal
          title="Success!"
          confirmAction={() => setShowSuccessMessage(false)}
        >
          Event has been deleted from Shopify
        </ConfirmationModal>
      ) : null}
    </div>
  );
}

export default EventFailedToDelete;
