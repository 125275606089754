// Main navigation elements
export const MAIN_MENU_CLICKED = "Main Menu Clicked";
export const MOBILE_MENU_CLICKED = "Mobile Menu Clicked";
export const LEGACY_MOBILE_MENU_CLICKED = "Legacy Mobile Menu Clicked";
export const SETTINGS_CLICKED = "Settings Clicked";
export const SUPPORT_CLICKED = "Support Clicked";
export const SIGNED_OUT = "Signed Out";
export const SUBMENU_ITEM_CLICKED = (SectionName: string) =>
  `${SectionName} Menu Clicked`;

// Dashboard elements
export const CHART_DATE_RANGE_SELECTED = "Chart Date Range Selected";
export const DATA_TYPE_SELECTED = "Data Type Selected";
export const LATEST_UPDATE_CLICKED = "Latest Update Clicked";
export const SHOPIFY_SUBSCRIPTION_BANNER_DIMISSED =
  "Shopify Subscription Banner Dismissed";
export const SHOPIFY_SUBSCRIPTION_APPROVAL_BUTTON_CLICKED =
  "Shopify Subscription Approval Button Clicked";

// Settings elements
export const EDIT_RECEIPT_SETTINGS_CLICKED = "Edit Receipt Settings Clicked";
export const SAVE_RECEIPT_SETTINGS_CLICKED = "Save Receipt Settings Clicked";
export const STORE_LOGO_CHANGED = "Store Logo Changed";
export const UPDATE_BUYLIST_CONFIRMATION_TEXT_CLICKED =
  "Update Buylist Confirmation Text Clicked";
export const SAVE_VARIANTS_PRICE_SETTINGS_CLICKED =
  "Save Variants Price Settings Clicked";
export const SAVE_GLOBAL_PRICE_SETTINGS_CLICKED =
  "Save Global Price Settings Clicked";
export const SAVE_GAME_PRICE_SETTINGS_CLICKED =
  "Save Game Price Settings Clicked";
export const SAVE_RARITY_PRICE_FLOOR_CLICKED =
  "Save Rarity Price Floor Clicked";
export const UPDATE_TIMEZONE_CLICKED = "Update Timezone Clicked";
export const EDIT_REPORT_SETTINGS_CLICKED = "Edit Report Settings Clicked";
export const SAVE_REPORT_SETTINGS_CLICKED = "Save Report Settings Clicked";
export const ADD_USER_CLICKED = "Add User Clicked";

// Buylist elements
export const VIEW_BUYLIST_CLICKED = "View Buylist Clicked";
export const VIEW_BUYLIST_ID_CLICKED = "View Buylist ID Clicked";
export const BUYLIST_COMPLETED = "Buylist Completed";
export const BUYLIST_APPROVED = "Buylist Approved";
export const BUYLIST_DECLINED = "Buylist Declined";
export const BUYLIST_CANCELED = "Buylist Canceled";
export const SORT_BUYLIST_BY_SELECTED = "Sort Buylist By Selected";
export const PRINT_BUYLIST_CLICKED = "Print Buylist Clicked";
export const EXPORT_BUYLIST_STOCK_ADJUSTMENT_CSV_CLICKED =
  "Export Buylist Stock Adjustment CSV Clicked";
export const EXPORT_BUYLIST_CSV_CLICKED = "Export Buylist CSV Clicked";
export const BUYLIST_LIST_VIEW_CLICKED = "Buylist List View Clicked";
export const BUYLIST_GRID_VIEW_CLICKED = "Buylist Grid View Clicked";
export const REMOVE_ITEM_FROM_BUYLIST_CLICKED =
  "Remove Item From Buylist Clicked";
export const SAVE_CHANGES_TO_BUYLIST_CLICKED =
  "Save Changes to Buylist Clicked";
export const UNCOMPLETE_BUYLIST_CLICKED = "Uncomplete Buylist Clicked";
export const VIEW_BUYLIST_RULE_CLICKED = "View Buylist Rule Clicked";
export const EDIT_BUYLIST_RULE_CLICKED = "Edit Buylist Rule Clicked";
export const DELETE_BUYLIST_RULE_CLICKED = "Delete Buylist Rule Clicked";
export const CREATE_BUYLIST_RULE_CLICKED = "Create Buylist Rule Clicked";
export const APPLY_BUYLIST_RULES_TO_CLICKED = "Apply Buylist Rules To Clicked";
export const CARD_CONDITION_CLICKED = "Card Condition Clicked";
export const ALLOW_PURCHASE_FROM_CUSTOMERS_CHECKED =
  "Allow Purchase From Customers Checked";
export const ALLOW_PURCHASE_WHEN_OVERSTOCKED_CHECKED =
  "Allow Purchase When Overstocked Checked";
export const SAVE_BUYLIST_RULE_CLICKED = "Save Buylist Rule Clicked";
export const UPDATE_BUYLIST_RULE_CLICKED = "Update Buylist Rule Clicked";
export const BUYLIST_CONFIRMATION_TEXT_TOOLTIP_CLICKED =
  "Confirmation Text Tooltip Clicked";

// Report elements
export const VIEW_REPORT_CLICKED = "View Report Clicked";

// Integration elements
export const INTEGRATION_CLICKED = (integrationName: string) =>
  `${integrationName} Integration Clicked`;
export const BULK_SETTINGS_CLICKED = "Bulk Settings Clicked";
export const CREATE_BULK_SETTING_CLICKED = "Create New Bulk Setting Clicked";
export const SAVE_BULK_SETTING_RULE_CLICKED = "Save Bulk Setting Rule Clicked";
export const INDIVIDUAL_SETTINGS_CLICKED = "Individual Settings Clicked";
export const EDIT_LISTING_CLICKED = "Edit Listing Clicked";
export const UPDATE_LISTING_CLICKED = "Update Listing Clicked";
export const RESET_LISTING_CLICKED = "Reset Listing Clicked";
export const SYNC_LISTING_CLICKED = "Sync Listing Clicked";
export const REVIEW_VARIANTS_CLICKED = "Review Variants Clicked";
export const EBAY_POLICIES_CLICKED = "eBay Policies Clicked";
export const MANAGE_EBAY_LOCATION_POLICIES_CLICKED =
  "Manage eBay Location Policies Clicked";
export const ADD_NEW_POLICY_CLICKED = "Add New Policy Clicked";
export const SAVE_POLICY_CLICKED = "Save Policy Clicked";
export const MANAGE_EBAY_PAYMENT_POLICIES_CLICKED =
  "Manage eBay Payment Policies Clicked";
export const MANAGE_EBAY_RETURNS_POLICIES_CLICKED =
  "Manage eBay Returns Policies Clicked";
export const MANAGE_EBAY_FULFILLMENT_POLICIES_CLICKED =
  "Manage eBay Fulfillment Policies Clicked";
export const MANAGE_EBAY_LISTING_TEMPLATES_CLICKED =
  "Manage eBay Listing Templates Clicked";
export const ADD_NEW_EBAY_TEMPLATE_CLICKED = "Add New eBay Template Clicked";
export const SAVE_EBAY_TEMPLATE_CLICKED = "Save eBay Template Clicked";
export const MISC_EBAY_SETTINGS_CLICKED = "Misc eBay Settings Clicked";
export const EBAY_LOGS_CLICKED = "eBay Logs Clicked";
export const EBAY_STOCK_CHANGE_HISTORY_CLICKED =
  "eBay Stock Change History Clicked";
export const EBAY_ORDERS_CLICKED = "eBay Orders Clicked";
export const REAUTH_WITH_EBAY_CLICKED = "Reauth With eBay Clicked";

// PoS Tills / Registers elements
export const DELETE_TILL_CLICKED = "Delete Till Clicked";
export const ENABLED_TILLS_CLICKED = "Enabled Tills Clicked";
export const DISABLED_TILLS_CLICKED = "Disabled Tills Clicked";
export const ADD_NEW_TILL_CLICKED = "Add New Till Clicked";
export const CANCEL_ADD_TILL_CLICKED = "Cancel Add Till Clicked";
export const ADD_TILL_CLICKED = "Add Till Clicked";
export const CLOSE_ADD_TILL_CLICKED = "Close Add Till Clicked";
export const MORE_FILTERS_CLICKED = "More Filters Clicked";

// PoS Submitted Carts elements
export const REPORT_DATE_RANGE_SELECTED = "Report Date Range Selected";
export const REPORT_TIMEZONE_SELECTED = "Report Timezone Selected";
export const REPORT_FILTER_APPLIED = "Report Filter Applied";
export const VIEW_SUBMITTED_CART = "View Submitted Cart";
export const SAVE_STOCK_ADJUSTMENT_CSV_CLICKED =
  "Save Stock Adjustment CSV Clicked";
export const SAVE_SUBMITTED_CART_CSV_CLICKED =
  "Save Submitted Cart CSV Clicked";
export const REPRINT_RECEIPT_CLICKED = "Reprint Receipt Clicked";

// PoS Tax Settings elements
export const SAVE_TAX_SETTINGS_CLICKED = "Save Tax Settings Clicked";

// PoS PAX Settings elements
export const PAX_TERMINAL_SETTINGS_SUBMENU_CLICKED =
  "Pax Terminal Settings Submenu Clicked";
export const SAVE_PAX_SETTINGS_CLICKED = "Save Pax Settings Clicked";
export const TEST_TERMINAL_CONNECTION_CLICKED =
  "Test Terminal Connection Clicked";

// PoS Catalog Update Settings elements
export const APPLY_CATALOG_UPDATES_TO_CLICKED =
  "Apply Catalog Updates To Clicked";
export const OPT_OUT_ATTRIBUTE_CLICKED = "Opt-Out Attribute Clicked";
export const SAVE_OPT_OUT_ATTRIBUTES_CLICKED =
  "Save Opt-Out Attributes Clicked";

// PoS Location Settings elements
export const SAVE_LOCATION_SETTINGS = "Save Location Settings Clicked";

// PoS Misc Settings elements
export const SAVE_MISC_SETTINGS_CLICKED = "Save Misc Settings Clicked";
export const CANCEL_MISC_SETTINGS_CLICKED = "Cancel Misc Settings Clicked";

// PoS Till Settings elements
export const DISPLAY_OVERSTOCK_WARNING_TOGGLED =
  "Display Overstock Warning Toggled";

// PoS Kiosk Settings elements
export const ENABLE_KIOSK_BUY_MODE_TOGGLED = "Enable Kiosk Buy Mode Toggled";
export const SAVE_KIOSK_SETTINGS_CLICKED = "Save Kiosk Settings Clicked";

// PoS Cart Notes elements
export const DELETE_CART_NOTE_FIELD_CLICKED = "Delete Cart Note Field Clicked";
export const ADD_CART_NOTE_FIELD_CLICKED = "Add Cart Note Field Clicked";

// Event elements
export const EVENT_FILTER_CLICKED = "Event Filter Clicked";
export const MORE_FILTERS_ADDED = "More Filters Added";
export const RESET_FILTER_CLICKED = "Reset Filter Clicked";
export const EDIT_EVENT_CLICKED = "Edit Event Clicked";
export const ADD_EVENT_CLICKED = "Add Event Clicked";
export const UPLOAD_EVENT_ICON_CLICKED = "Upload Event Icon Clicked";
export const UPLOAD_EVENT_FEATURE_IMAGE_CLICKED =
  "Upload Event Feature Image Clicked";
export const UPLOAD_EVENT_TICKET_IMAGE = "Upload Event Ticket Image";
export const EVENT_REQUIRE_TICKED_TOGGLED = "Event Require Ticket Toggled";
export const SAVE_CHANGES_CLICKED = "Save Changes Clicked";
export const INCLUDE_DATE_IN_EVENT_TITLE_TOGGLED =
  "Include Date in Event Title Toggled";
export const DATE_FORMAT_FIELD_CLICKED = "Date Format Field Clicked";
export const SAVE_EVENT_SETTINGS_CLICKED = "Save Event Settings Clicked";
export const CUSTOM_DATE_FORMAT_HELPER_DOC_CLICKED =
  "Custom Date Format Helper Doc Clicked";

// Customer elements
export const SEARCH_CUSTOMER_FIELD_CLICKED = "Search Customer Field Clicked";
export const UPLOAD_STORE_CREDIT_CLICKED = "Upload Store Credit Clicked";
export const STORE_CREDIT_CSV_UPLOADED = "Store Credit CSV Uploaded";
export const CLOSE_STORE_CREDIT_CLICKED = "Close Store Credit Clicked";
export const REFRESH_CUSTOMERS_LIST_CLICKED = "Refresh Customers List Clicked";
export const SAVE_CUSTOMER_CLICKED = "Save Customer Clicked";
export const SAVE_AND_ADD_NEW_CUSTOMER_CLICKED =
  "Save and Add New Customer Clicked";

// Point of Sale Events
export const CREDIT_PRICE_TOGGLED = "Cash Credit Price Toggled";
export const OPEN_CLOSE_TILL_CLICKED = "Open/Close Till Clicked";
export const NEW_CART_CLICKED = "New Cart Clicked";
export const LOAD_CART_CLICKED = "Load Cart Clicked";
export const SAVE_CART_CLICKED = "Save Cart Clicked";
export const HISTORY_AND_REFUNDS_CLICKED = "History And Refunds Clicked";
export const ALL_PRODUCTS_SEARCH_CLICKED = "All Products Search Clicked";
export const EXCLUDING_SINGLES_CLICKED = "Excluding Singles Clicked";
export const IN_STOCK_ONLY_TOGGLED = "In Stock Only Toggled";
export const EXIT_POS_CLICKED = "Exit POS Clicked";
export const SELECT_A_CUSTOMER_CLICKED = "Select a Customer Clicked";
export const ADD_CUSTOM_ITEM_CLICKED = "Add Custom Item Clicked";
export const ADD_NOTE_CLICKED = "Add Note Clicked";
export const RECEIPT_PRINT_CLICKED = "Receipt Print Clicked";
export const DELETE_CART_CLICKED = "Delete Cart Clicked";
export const VALUE_DISCOUNT_CLICKED = "Value Discount Clicked";
export const PERCENT_DISCOUNT_CLICKED = "Percent Discount Clicked";
export const BUY_SELL_TOGGLED = "Buy/Sell Toggled";
export const PREDICTIVE_SEARCH_TOGGLED = "Predictive Search Toggled";
export const ADD_FOLDER_CLICKED = "Add Folder Clicked";
export const CART_SUBMIT_CLICKED = "Cart Submit Clicked";

// Products elements
export const IMPORT_PRODUCT_CSV_CLICKED = "Import Product CSV Clicked";
export const DROP_PRODUCT_CSV_CLICKED = "Drop Product CSV Clicked";
export const BULK_UPDATE_SEARCH_CLICKED = "Bulk Update Search Clicked";
export const PUSH_BULK_UPDATE_CLICKED = "Push Bulk Update Clicked";
export const EXPORT_PRODUCT_SEARCH_CLICKED = "Export Product Search Clicked";
export const VIEW_PREVIOUS_EXPORTS_CLICKED = "View Previous Exports Clicked";
export const EXPORT_SEARCH_CSV_CLICKED = "Export Search CSV Clicked";
export const CANCEL_EXPORT_SEARCH_CSV_CLICKED =
  "Cancel Export Search CSV Clicked";
export const INVENTORY_UPDATE_TYPE_SELECTED = "Inventory Update Type Selected";
export const APPLY_PRODUCT_FILTER_CLICKED = "Apply Product Filter Clicked";
export const UPDATE_COLUMNS_CLICKED = "Update Columns Clicked";
export const PRODUCTS_MENU_CLICKED = "Products Menu Clicked";
export const QUEUED_JOBS_MENU_CLICKED = "Queued Jobs Menu Clicked";
export const DOWNLOAD_PRODUCT_CSV_EXPORT_HISTORY_CLICKED =
  "Download Product CSV Export History Clicked";
export const COLLAPSE_VARIANTS_SELECTED = "Collapse Variants Selected";
export const STOCK_QTY_CLICKED = "Stock Qty Clicked";
export const RESERVE_QTY_CLICKED = "Reserve Qty Clicked";
export const SELL_OVERRIDE_CLICKED = "Sell Override Clicked";
export const CASH_BUY_CLICKED = "Cash Buy Clicked";
export const CREDIT_BUY_CLICKED = "Credit Buy Clicked";
export const BUY_LIMIT_CLICKED = "Buy Limit Clicked";
export const OVER_LIMIT_CASH_BUY_CLICKED = "Over-Limit Cash Buy Clicked";
export const OVER_LIMIT_CREDIT_BUY_CLICKED = "Over-Limit Credit Buy Clicked";
export const CASCADE_CHANGES_CLICKED = "Cascade Changes Clicked";
export const UPDATE_PRODUCT_CLICKED = "Update Product Clicked";
export const UPDATE_PRODUCT_LIST_CLICKED = "Update Product List Clicked";
