import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  addEventParticipant,
  fetchEventAdditionalInfo,
  fetchEventParticipant,
  removeEventParticipant,
  updateEventParticipant,
  addEvent,
  deleteEvent,
  enableEvent,
  fetchEventGamesForClient,
  fetchEventInfo,
  fetchEventTypesForClient,
  fetchPotentialExistingMainEvents,
  fetchPotentialNewMainEvents,
  updateEvent,
  fetchEvents,
  fetchAllEventParticipants,
  fetchFailedToCreateEvents,
  fetchFailedToDeleteEvents,
  retryFailedToDelete,
  repushFailedEvent,
} from "../api/rest/events";
import {
  EventParticipant,
  EventParticipantsAndCustomers,
} from "../types/events";

const LONG_LIFE_DATA_STALE_TIME = 5 * 60 * 1000; // ms

export const useAddEvent = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(addEvent, {
    onSettled: () => {
      // TODO:: May need to revisit this when migrating the events list to react-query
      queryClient.invalidateQueries("events", { refetchInactive: true });
    },
  });
  return { isMutating, ...rest };
};

export const useFetchEventGamesForClient = () =>
  useQuery("eventGames", fetchEventGamesForClient, {
    staleTime: LONG_LIFE_DATA_STALE_TIME,
  });

export const useFetchEventTypesForClient = () =>
  useQuery("eventTypes", fetchEventTypesForClient, {
    staleTime: LONG_LIFE_DATA_STALE_TIME,
  });

export const useFetchEventInfo = (eventId: number) =>
  useQuery(["event", { eventId }], () => fetchEventInfo(eventId), {
    enabled: eventId !== undefined,
  });

export const useFetchPotentialExistingMainEvents = (eventId: number) =>
  useQuery(["potentialExistingMainEvents", { eventId }], () =>
    fetchPotentialExistingMainEvents(eventId)
  );

export const useFetchPotentialNewMainEvents = () =>
  useQuery(["potentialNewMainEvents"], fetchPotentialNewMainEvents);

type EventUpdateVariables = {
  eventId: number | string;
  event: FormData;
};

export const useUpdateEvent = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    ({ eventId, event }: EventUpdateVariables) => updateEvent(eventId, event),
    {
      onSettled: () => {
        // TODO:: May need to revisit this when migrating the events list to react-query
        queryClient.invalidateQueries("events", { refetchInactive: true });
      },
    }
  );
  return { isMutating, ...rest };
};

export const useFetchEventAdditionalInfo = (eventId: number) =>
  useQuery(
    ["eventAdditionalInfo", { eventId }],
    () => fetchEventAdditionalInfo(eventId),
    {
      staleTime: LONG_LIFE_DATA_STALE_TIME,
    }
  );

export const useFetchAllEventParticipants = (eventId: number) =>
  useQuery(
    ["allEventParticipants", { eventId }],
    () => fetchAllEventParticipants(eventId),
    { enabled: eventId !== undefined }
  );

type EventParticipantVariables = {
  eventId: number | string;
  participantId?: number | string;
  participant?: EventParticipant;
};

export const useFetchEventParticipant = ({
  eventId,
  participantId,
}: EventParticipantVariables) =>
  useQuery(
    ["eventParticipant", { eventId, participantId }],
    () => fetchEventParticipant(eventId, participantId),
    { enabled: participantId !== undefined }
  );

export const useAddEventParticipant = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    ({ eventId, participant }: EventParticipantVariables) =>
      addEventParticipant(eventId, participant),
    {
      onSuccess: (
        response: EventParticipant,
        variables: EventParticipantVariables
      ) => {
        queryClient.setQueryData<EventParticipantsAndCustomers>(
          ["allEventParticipants", { eventId: Number(variables.eventId) }],
          (prev): EventParticipantsAndCustomers => {
            if (prev?.participants) {
              return {
                ...prev,
                participants: [...prev.participants, response],
              };
            }
            return { ...prev, participants: [response] };
          }
        );
      },
    }
  );
  return { isMutating, ...rest };
};

export const useRemoveEventParticipant = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    ({ eventId, participantId }: EventParticipantVariables) =>
      removeEventParticipant(eventId, participantId),
    {
      onSuccess: (_response: [], variables: EventParticipantVariables) => {
        queryClient.setQueryData<EventParticipantsAndCustomers>(
          ["allEventParticipants", { eventId: Number(variables.eventId) }],
          (prev): EventParticipantsAndCustomers => {
            if (prev?.participants) {
              return {
                ...prev,
                participants: prev.participants.filter(
                  (participant) =>
                    Number(participant.id) !== Number(variables.participantId)
                ),
              };
            }
            return { ...prev, participants: [] };
          }
        );
      },
    }
  );
  return { isMutating, ...rest };
};

export const useUpdateEventParticipant = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    ({ eventId, participantId, participant }: EventParticipantVariables) =>
      updateEventParticipant(eventId, participantId, participant),
    {
      onSuccess: (
        response: EventParticipant,
        variables: EventParticipantVariables
      ) => {
        queryClient.setQueryData<EventParticipant[]>(
          ["eventParticipants", { eventId: Number(variables.eventId) }],
          (prev): EventParticipant[] => {
            if (prev) {
              return prev.map((participant) => {
                if (participant.id === variables.participantId) {
                  return response;
                }
                return participant;
              });
            }
            return [variables.participant];
          }
        );
        queryClient.setQueryData<EventParticipantsAndCustomers>(
          ["allEventParticipants", { eventId: Number(variables.eventId) }],
          (prev): EventParticipantsAndCustomers => {
            if (prev?.participants) {
              return {
                ...prev,
                participants: prev.participants.map((participant) => {
                  if (
                    Number(participant.id) === Number(variables.participantId)
                  ) {
                    return response;
                  }
                  return participant;
                }),
              };
            }
            return { ...prev, participants: [] };
          }
        );

        queryClient.setQueryData<EventParticipant>(
          [
            "eventParticipant",
            {
              eventId: variables.eventId,
              participantId: variables.participantId,
            },
          ],
          () => response
        );
      },
    }
  );
  return { isMutating, ...rest };
};

type FetchEventVariables = {
  limit?: number;
  offset?: number;
  startDate?: string;
  endDate?: string;
};

export const useFetchEvents = (params: FetchEventVariables) =>
  useQuery(["events", params], () => fetchEvents(params));

export const useFetchFailedToCreateEvents = (offset: number, limit: number) =>
  useQuery(["failedToCreateEvents", offset, limit], () =>
    fetchFailedToCreateEvents(offset, limit)
  );

export const useFetchFailedToDeleteEvents = (offset: number, limit: number) =>
  useQuery(["failedToDeleteEvents", offset, limit], () =>
    fetchFailedToDeleteEvents(offset, limit)
  );

export const useRetryFailedToDelete = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    (id: number) => retryFailedToDelete(id),
    {
      onSettled: () => {
        queryClient.invalidateQueries("failedToDeleteEvents", {
          refetchInactive: true,
        });
      },
    }
  );
  return { isMutating, ...rest };
};

type DeleteEventVariables = {
  eventId: number;
  parentEventId?: number;
};

export const useDeleteEvent = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    ({ eventId }: DeleteEventVariables) => deleteEvent(eventId),
    {
      onSuccess: (_response, { parentEventId }) => {
        queryClient.invalidateQueries("events", {
          refetchInactive: true,
        });
        if (parentEventId) {
          queryClient.invalidateQueries(["event", { eventId: parentEventId }], {
            refetchInactive: true,
          });
        }
      },
    }
  );
  return { isMutating, ...rest };
};

type EnableEventVariables = {
  eventId: number;
  parentEventId: number;
};

export const useEnableEvent = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    ({ eventId }: EnableEventVariables) => enableEvent(eventId),
    {
      onSuccess: (_response, { parentEventId }) => {
        queryClient.invalidateQueries(["event", { eventId: parentEventId }], {
          refetchInactive: true,
        });
      },
    }
  );
  return { isMutating, ...rest };
};

export const useRepushFailedEvent = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    (id: number) => repushFailedEvent(id),
    {
      onSettled: () => {
        queryClient.invalidateQueries("failedToCreateEvents", {
          refetchInactive: true,
        });
      },
    }
  );
  return { isMutating, ...rest };
};
