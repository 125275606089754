import { useQuery } from "react-query";
import { fetchCSVExportHistory } from "../api/rest/products";
import { mapProductDataExports } from "../transformers/productDataExport";

const LONG_LIFE_DATA_STALE_TIME = 60_000;

export const useFetchCSVExportHistory = (offset: number, limit: number) =>
  useQuery(
    ["CSVExportHistory", { offset, limit }],
    () => fetchCSVExportHistory(offset, limit),
    {
      keepPreviousData: true,
      staleTime: LONG_LIFE_DATA_STALE_TIME,
      select: (data) => data.map(mapProductDataExports),
    }
  );
