import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import {
  EbayFulfillment,
  ShippingService,
  ShippingServiceProvider,
} from "../../api/rest/ebay";
import ButtonComponent from "../../components/generic/ButtonComponent";
import EbayShippingServices from "../../components/integrations/EbayShippingServices";
import { useStoreDetails } from "../../hooks/storeHooks";
import {
  mapEbayFulfillmentToForm,
  mapFormToEbayFulfillment,
} from "../../utils/ebay";
import { validateRequiredFields } from "../../utils/forms";
import { EbayFulfillmentFormData } from "../../utils/ebay";
import "./EbayFulfillmentForm.scss";

const defaultValues: EbayFulfillmentFormData = {
  name: "",
  description: "",
  freightShipping: false,
  localPickup: false,
  pickupDropOff: false,
  globalShipping: false,
  handlingTime: {
    unit: "DAY",
    value: 0,
  },
  regionsIncluded: [],
  regionsExcluded: [],
  domesticShippingOption: {
    costType: null,
    packageHandlingCost: null,
    optionType: "DOMESTIC",
    shippingServices: [],
  },
  internationalShippingOption: {
    costType: null,
    packageHandlingCost: null,
    optionType: "INTERNATIONAL",
    shippingServices: [],
  },
};

const baseRequiredFields = ["name", "description"];

const generateInput = (
  fieldName: string,
  fieldLabel: string,
  prefix?: string,
  autoFocus?: boolean
) => (
  <>
    <label id={fieldName}>
      {fieldLabel} {prefix}
      {baseRequiredFields.includes(fieldName) ? (
        <span className="ebay-fulfillment-form__required">*</span>
      ) : (
        ""
      )}
    </label>
    <Field name={fieldName} aria-labelledby={fieldName} autoFocus={autoFocus} />
    <ErrorMessage
      name={fieldName}
      className="ebay-fulfillment-form__field-error"
      component="div"
    />
  </>
);

const getOptions = (options: EbayParam[]) =>
  options.map((option) => (
    <option key={option.code} value={option.code}>
      {option.description}
    </option>
  ));

const supportedDurations = ["HOUR", "DAY", "CALENDAR_DAY"];
const filterDurations = (durations: EbayParam[]) =>
  durations.filter((duration: EbayParam) =>
    supportedDurations.includes(duration.code)
  );

const mergeUpdatedShippingSettings = (
  values: EbayFulfillmentFormData,
  shippingOption: "DOMESTIC" | "INTERNATIONAL",
  shippingServices: ShippingService[],
  setValues: Function
) => {
  const newValues = { ...values };
  if (shippingOption === "DOMESTIC") {
    newValues.domesticShippingOption.shippingServices = shippingServices;
  } else {
    newValues.internationalShippingOption.shippingServices = shippingServices;
  }
  setValues(newValues);
};

const costTypes = [
  { code: "FLAT_RATE", description: "Flat rate" },
  { code: "CALCULATED", description: "Calculated shipping" },
  { code: "NOT_SPECIFIED", description: "Not specified" },
];

interface EbayFulfillmentFormProps {
  fulfillmentSetting?: EbayFulfillment;
  shippingServices: ShippingServiceProvider[];
  includeRegions: EbayParam[];
  excludeRegions: EbayParam[];
  timeDurations: EbayParam[];
  shippingOptionTypes: EbayParam[];
  setEditSetting: Function;
  saveSetting: Function;
}

function EbayFulfillmentForm(props: EbayFulfillmentFormProps) {
  const {
    fulfillmentSetting,
    shippingServices,
    includeRegions,
    excludeRegions,
    timeDurations,
    setEditSetting,
    saveSetting,
  } = props;

  const convertedFulfillmentSetting = mapEbayFulfillmentToForm(
    fulfillmentSetting,
    defaultValues
  );

  const [shipInternationally, setShipInternationally] = useState(
    fulfillmentSetting?.shippingOptions
      ? fulfillmentSetting.shippingOptions.length > 1
      : false
  );
  const { currencySymbol } = useStoreDetails();

  const validate = (values: EbayFulfillmentFormData) => {
    const requiredFields =
      values.localPickup || values.freightShipping
        ? baseRequiredFields
        : [
            ...baseRequiredFields,
            "handlingTime.value",
            "domesticShippingOption.costType",
            "domesticShippingOption.shippingServices",
          ];
    if (shipInternationally)
      requiredFields.push(
        ...[
          "handlingTime.value",
          "internationalShippingOption.costType",
          "internationalShippingOption.shippingServices",
        ]
      );
    return validateRequiredFields(requiredFields, values);
  };

  const onLocalPickupChange =
    (setFieldValue: Function) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFieldValue("localPickup", event.target.checked);
      setFieldValue("pickupDropOff", event.target.checked);
      setFieldValue(
        "domesticShippingOption.costType",
        event.target.checked ? "NOT_SPECIFIED" : "FLAT_RATE"
      );
      if (event.target.checked) {
        setFieldValue("domesticShippingOption.packageHandlingCost", null);
        setFieldValue("domesticShippingOption.shippingServices", []);
      }
    };

  const onCostTypeChange =
    (setFieldValue: Function, fieldName: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFieldValue(`${fieldName}.costType`, event.target.value);
      if (event.target.value !== "FLAT_RATE")
        setFieldValue(`${fieldName}.packageHandlingCost`, null);
    };

  return (
    <div className="ebay-fulfillment-form">
      <Formik
        initialValues={convertedFulfillmentSetting}
        validate={validate}
        onSubmit={(values, { setSubmitting }) => {
          const newValues = { ...values };
          newValues.domesticShippingOption.optionType = "DOMESTIC";
          if (newValues.internationalShippingOption !== null) {
            newValues.internationalShippingOption.optionType = "INTERNATIONAL";
          }
          const convertedValues = mapFormToEbayFulfillment(newValues);
          saveSetting(convertedValues, setSubmitting);
        }}
      >
        {({ values, isSubmitting, setFieldValue, setValues }) => (
          <Form>
            <div className="ebay-fulfillment-form__inputs">
              {generateInput("name", "Fulfillment Policy Name", "", true)}
              {generateInput("description", "Description")}
              {values.localPickup ? null : (
                <>
                  <label id="freightShipping">Freight Shipping Available</label>
                  <Field
                    type="checkbox"
                    name="freightShipping"
                    aria-labelledby="freightShipping"
                  />
                  <ErrorMessage
                    name="freightShipping"
                    className="ebay-fulfillment-form__field-error"
                    component="div"
                  />
                </>
              )}
              {values.freightShipping ? null : (
                <>
                  <label id="localPickup">Local Pickup</label>
                  <Field
                    type="checkbox"
                    name="localPickup"
                    aria-labelledby="localPickup"
                    onChange={onLocalPickupChange(setFieldValue)}
                  />
                  <ErrorMessage
                    name="localPickup"
                    className="ebay-fulfillment-form__field-error"
                    component="div"
                  />
                </>
              )}
              {values.localPickup || values.freightShipping ? null : (
                <>
                  <label id="handlingTime">
                    Handling Time
                    <span className="ebay-fulfillment-form__required">*</span>
                  </label>
                  <div>
                    <Field
                      name="handlingTime.value"
                      type="number"
                      aria-labelledby="handlingTime"
                    />
                    <Field
                      name="handlingTime.unit"
                      aria-labelledby="handlingTime"
                      className="ebay-fulfillment-form__unit-select"
                      as="select"
                    >
                      {getOptions(filterDurations(timeDurations))}
                    </Field>
                  </div>
                  <ErrorMessage
                    name="handlingTime.value"
                    className="ebay-fulfillment-form__field-error"
                    component="div"
                  />
                </>
              )}
              {values.localPickup || values.freightShipping ? null : (
                <>
                  <h5>Domestic Shipping</h5>
                  <Field
                    type="hidden"
                    name="domesticShippingOption.optionType"
                    value="DOMESTIC"
                  />
                  <span />
                  <label
                    htmlFor="domesticShippingOption.costType"
                    id="nationalShippingOptionsCostType"
                  >
                    Cost Type
                    <span className="ebay-fulfillment-form__required">*</span>
                  </label>
                  <Field
                    as="select"
                    name="domesticShippingOption.costType"
                    aria-labelledby="nationalShippingOptionsCostType"
                    onChange={onCostTypeChange(
                      setFieldValue,
                      "domesticShippingOption"
                    )}
                  >
                    <option value="">-- Select Cost Type --</option>
                    {getOptions(costTypes)}
                  </Field>
                  <ErrorMessage
                    name="domesticShippingOption.costType"
                    className="ebay-fulfillment-form__field-error"
                    component="div"
                  />
                  {values?.domesticShippingOption?.costType === "CALCULATED"
                    ? generateInput(
                        "domesticShippingOption.packageHandlingCost",
                        "Handling Cost",
                        currencySymbol
                      )
                    : null}
                  <p>
                    Shipping Services{" "}
                    <span className="ebay-fulfillment-form__required">*</span>
                  </p>
                  <>
                    {values?.domesticShippingOption?.costType ? (
                      <EbayShippingServices
                        settings={
                          values.domesticShippingOption?.shippingServices || []
                        }
                        shippingServiceProviders={shippingServices}
                        costType={values.domesticShippingOption.costType}
                        includeRegions={includeRegions}
                        excludeRegions={excludeRegions}
                        updateShippingServices={(services: ShippingService[]) =>
                          mergeUpdatedShippingSettings(
                            values,
                            "DOMESTIC",
                            services,
                            setValues
                          )
                        }
                      />
                    ) : (
                      <span>Please Select a Cost Type</span>
                    )}
                    <ErrorMessage
                      name="domesticShippingOption.shippingServices"
                      className="ebay-fulfillment-form__field-error"
                      component="div"
                    />
                  </>
                  <h5>International Shipping</h5>
                  <span />
                  <label htmlFor="shipInternationally">
                    Ship Internationally
                  </label>
                  <input
                    name="shipInternationally"
                    id="shipInternationally"
                    type="checkbox"
                    defaultChecked={shipInternationally}
                    onClick={() => {
                      setShipInternationally(!shipInternationally);
                    }}
                  />
                  {shipInternationally ? (
                    <>
                      <Field
                        type="hidden"
                        name="internationalShippingOption.optionType"
                        value="INTERNATIONAL"
                      />
                      <span />
                      <span />
                      <label
                        htmlFor="internationalShippingOption.costType"
                        id="internationalShippingOptionsCostType"
                      >
                        Cost Type
                        <span className="ebay-fulfillment-form__required">
                          *
                        </span>
                      </label>
                      <Field
                        as="select"
                        name="internationalShippingOption.costType"
                        aria-labelledby="internationalShippingOptionsCostType"
                        onChange={onCostTypeChange(
                          setFieldValue,
                          "internationalShippingOption"
                        )}
                      >
                        <option value="">-- Select Cost Type --</option>
                        {getOptions(costTypes)}
                      </Field>
                      <ErrorMessage
                        name="internationalShippingOption.costType"
                        className="ebay-fulfillment-form__field-error"
                        component="div"
                      />
                      {values.internationalShippingOption?.costType ===
                      "CALCULATED"
                        ? generateInput(
                            "internationalShippingOption.packageHandlingCost",
                            "Handling Cost",
                            currencySymbol
                          )
                        : null}
                      <p>
                        Shipping Services
                        <span className="ebay-fulfillment-form__required">
                          *
                        </span>
                      </p>
                      <>
                        {values?.internationalShippingOption?.costType ? (
                          <EbayShippingServices
                            international
                            settings={
                              values.internationalShippingOption
                                ?.shippingServices || []
                            }
                            shippingServiceProviders={shippingServices}
                            costType={
                              values.internationalShippingOption.costType
                            }
                            includeRegions={includeRegions}
                            excludeRegions={excludeRegions}
                            updateShippingServices={(
                              services: ShippingService[]
                            ) =>
                              mergeUpdatedShippingSettings(
                                values,
                                "INTERNATIONAL",
                                services,
                                setValues
                              )
                            }
                          />
                        ) : (
                          <span>Please Select a Cost Type</span>
                        )}
                        <ErrorMessage
                          name="internationalShippingOption.shippingServices"
                          className="ebay-fulfillment-form__field-error"
                          component="div"
                        />
                      </>
                    </>
                  ) : null}
                </>
              )}
            </div>
            <div className="ebay-payment-methods-form__actions">
              <ButtonComponent onClick={() => setEditSetting()}>
                Cancel
              </ButtonComponent>
              <ButtonComponent primary type="submit" disabled={isSubmitting}>
                Save
              </ButtonComponent>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default EbayFulfillmentForm;
