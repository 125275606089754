import React from "react";
import { segmentAnalytics } from "../services/Analytics";
import { COLLAPSE_VARIANTS_SELECTED } from "../../constants/eventsTracked";
import { filterVariantsLabelMapping } from "./FilterVariantsSelect";
import "./ToggleVariantsButton.scss";

interface ToggleVariantsButtonProps {
  collapseVariants: boolean;
  hideOutOfStock: boolean;
  setCollapseVariants: (collapse: boolean) => void;
  children: React.ReactNode;
}

function ToggleVariantsButton(props: ToggleVariantsButtonProps) {
  const { collapseVariants, hideOutOfStock, setCollapseVariants, children } =
    props;

  return (
    <button
      className="ToggleVariantsButton"
      onClick={() => {
        segmentAnalytics.track(COLLAPSE_VARIANTS_SELECTED, {
          collapse_option: !collapseVariants ? "hide" : "show",
          dropdown_type:
            filterVariantsLabelMapping[hideOutOfStock ? "outOfStock" : "none"],
        });
        setCollapseVariants(!collapseVariants);
      }}
    >
      {children}
      {collapseVariants ? (
        <i className="fas fa-angle-double-down" title="Show all variants" />
      ) : (
        <i className="fas fa-angle-double-up" title="Hide all variants" />
      )}
    </button>
  );
}

export default ToggleVariantsButton;
