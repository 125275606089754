import { useEffect } from "react";
import { useFeatureFlag } from "../../hooks/globalSettingsHooks";
import { ADABOT_BINDER_PORTAL } from "../../constants/featureFlags";
import { AdaBotMetaFieldKey, AdaBotAnswer } from "../../types/adaBot";
import { ADA_DEFAULT_CONFIG } from "../../constants/adaBot";

function ADAChatbot() {
  const adaBotBinderPortal = useFeatureFlag(ADABOT_BINDER_PORTAL);

  useEffect(() => {
    if (!adaBotBinderPortal) return;
    const existingScript = document.getElementById("adaBot");
    if (!existingScript) {
      console.log("Loading ADAChatbot");
      window.adaSettings = ADA_DEFAULT_CONFIG;
      const script = document.createElement("script");
      script.src = "https://static.ada.support/embed2.js";
      script.id = "adaBot";
      script.setAttribute("data-handle", "tcgplayer");
      document.head.appendChild(script);
      const style = document.createElement("style");
      style.innerHTML = `#ada-button-frame {display: none;};`;
      document.body.appendChild(style);
    }
  }, [adaBotBinderPortal]);

  return null;
}

export const createAdaBotLauncher = () => {
  let adaEmbed;
  let adaAnswerId = null;
  let adaMetaData = {};

  const launchAdaBot = async () => {
    if (!window.adaEmbed) {
      console.log("Ada embed not loaded");
      throw new Error("Ada embed not loaded");
    }

    adaEmbed = window.adaEmbed;

    console.log("Launching ADAbot");
    console.log(adaMetaData);

    const windowInfo = await adaEmbed.getInfo();
    const { isChatOpen } = windowInfo;

    const adaConfig = { ...ADA_DEFAULT_CONFIG };
    adaConfig.metaFields = adaMetaData;

    if (adaAnswerId) {
      adaConfig["greeting"] = adaAnswerId;
    }

    adaEmbed.reset(adaConfig);

    if (!isChatOpen) {
      adaEmbed.toggle();
    }
  };

  const addMetaData = (key: AdaBotMetaFieldKey, value) => {
    adaMetaData[key] = value;
    return {
      launchAdaBot,
      addMetaData,
      resetMetaData,
      setAnswer,
    };
  };

  const resetMetaData = () => {
    adaMetaData = {};
    return {
      launchAdaBot,
      addMetaData,
      resetMetaData,
      setAnswer,
    };
  };

  const setAnswer = (answerId: AdaBotAnswer) => {
    adaAnswerId = answerId;
    return {
      launchAdaBot,
      addMetaData,
      resetMetaData,
      setAnswer,
    };
  };

  return {
    launchAdaBot,
    addMetaData,
    resetMetaData,
    setAnswer,
  };
};

export default ADAChatbot;
