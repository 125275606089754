import React, { useState } from "react";
import SubSectionHeaderLayout from "../layout/SubSectionHeaderLayout";
import { useFetchCsvUploads } from "../../hooks/productDataImportHooks";
import ButtonComponent from "../generic/ButtonComponent";
import Paging from "../generic/Paging";
import Spinner from "../generic/Spinner";
import TableComponent from "../table/TableComponent";
import PreviousImportsTooltip from "./PreviousImportsTooltip";
import PreviousImportsTableColumns from "./PreviousImportsTableColumns";
import "./PreviousImports.scss";

const DEFAULT_PAGE_SIZE = 5;
const ITEMS_PER_PAGE = [5, 10, 20, 50];

const rowProps = (rowData: unknown) => ({
  className:
    rowData["status"] !== "Completed"
      ? "PreviousImports__row--uncompleted"
      : "",
});

function PreviousImports() {
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const { isLoading, isPreviousData, isRefetching, error, data, refetch } =
    useFetchCsvUploads(currentPage * pageSize, pageSize + 1);

  const getPrevPage = () => setCurrentPage((page) => Math.max(page - 1, 0));
  const getNextPage = () => setCurrentPage((page) => page + 1);

  const handlePageSizeChange = (size: number) => {
    setCurrentPage(0);
    setPageSize(size);
  };

  if (error) {
    return <p>Failed to load import data. Please refresh and try again.</p>;
  }

  return (
    <div className="PreviousImports">
      <SubSectionHeaderLayout
        title="Previous Imports"
        tooltip={<PreviousImportsTooltip />}
      >
        <ButtonComponent icon="far fa-sync" onClick={() => refetch()}>
          Reload table
        </ButtonComponent>
      </SubSectionHeaderLayout>
      <Spinner isLoading={isLoading || isPreviousData || isRefetching}>
        <TableComponent
          data={data?.slice(0, pageSize) || []}
          columns={PreviousImportsTableColumns}
          getTrProps={rowProps}
        />
      </Spinner>
      <Paging
        pageOffset={currentPage}
        currentPageItemCount={(data || []).length}
        pageSize={pageSize}
        maxItemsPerPage={pageSize + 1}
        pageSizeOptions={ITEMS_PER_PAGE}
        setPageSize={handlePageSizeChange}
        getPrevPage={getPrevPage}
        getNextPage={getNextPage}
      />
    </div>
  );
}

export default PreviousImports;
