import { ReportsMenuStructure } from "../../menuStructures";
import React, { Fragment, useEffect } from "react";
import SetActiveMenu from "../../menuStructures/SetActiveMenu";
import { ButtonComponent } from "../../components";
import { VIEW_REPORT_CLICKED } from "../../constants/eventsTracked";

function Sales() {
  useEffect(() => {
    document.title = "Reports | BinderPOS";
  }, []);

  return (
    <Fragment>
      <SetActiveMenu menuStructure={ReportsMenuStructure} />
      <div className="app-header">
        <h1 className="title">
          <span className="header-text">Buylist Reports</span>
        </h1>
      </div>
      <div className="reports-menu row">
        <div className="col-sm-6">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title">All Buylists</h2>
              <p className="card-text">
                View all pending, submitted, completed and declined buylists,
                selected by date submitted
              </p>
              <ButtonComponent
                primary
                to="/reports/buylists/all"
                segmentEventName={VIEW_REPORT_CLICKED}
                segmentEventProperties={{ report_type: "All Buylists" }}
                fullWidth
                compact
                isLink
              >
                View Report
              </ButtonComponent>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title">Completed Buylists</h2>
              <p className="card-text">
                View completed buylists, selected by the date on which they were
                completed
              </p>
              <ButtonComponent
                primary
                to="/reports/buylists/completed"
                segmentEventName={VIEW_REPORT_CLICKED}
                segmentEventProperties={{ report_type: "Completed Buylists" }}
                fullWidth
                compact
                isLink
              >
                View Stock Reports
              </ButtonComponent>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Sales;
