import React, { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { _fetch } from "../../api";
import ButtonComponent from "../../components/generic/ButtonComponent";
import { SHOPIFY_API_BASE_URL } from "../../constants/api";
import useTitle from "../../hooks/useTitle";
import { useStoreDetails } from "../../hooks/storeHooks";
import "./ShopifyAuth.scss";

function ShopifyAuth() {
  const [isProcessing, setIsProcessing] = useState(false);
  const [showClose, setShowClose] = useState(false);
  const [error, setError] = useState<string>();
  const { customerSiteUrl, customerId } = useStoreDetails();
  useTitle("Shopify Auth");

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };

  const query = useQuery();
  const type = query.get("type"); // catalog or portal
  const shop = query.get("shop"); // shopify url
  const unlinkingShop = query.get("unlink") === "true";

  if (!shop || !type) {
    return <div>Invalid request</div>;
  }

  //If the shop param passed from Shopify is the same as the current store's siteUrl, then we know the user is already linked
  const alreadyLinked = customerSiteUrl === shop;

  //Due to some databse hackery we check if the siteUrl is the same as the customerId to indicate a store is unlinked
  const unlinkedStore = customerSiteUrl === customerId || false;

  const linkCurrentShop = async (unlink = false) => {
    setIsProcessing(true);
    return _fetch({
      endpoint: `${SHOPIFY_API_BASE_URL}/binderpos/${type}/${
        unlink ? "unlink" : "link"
      }?shop=${unlink ? customerSiteUrl : shop}`,
      method: "GET",
    })
      .then(() => closeWindow())
      .catch((error: DetailedError) => {
        console.log(error);
        setError(error.detailedMessage);
      })
      .finally(() => {
        setIsProcessing(false);
        setShowClose(true);
      });
  };

  const closeWindow = () => window.close();

  return (
    <div className="ShopifyAuth__background">
      <div className="ShopifyAuth__wrapper">
        <div>
          <h2>
            {!unlinkingShop
              ? "Connecting to BinderPOS"
              : "Unlinking from BinderPOS"}
          </h2>
        </div>
        <div className="shopify-auth__content__body">
          {alreadyLinked ? (
            <p>
              <a className="ShopifyAuth__anchorBadge">{shop}</a> is already
              linked to your BinderPOS account.
            </p>
          ) : (
            <>
              <p>
                We are linking your Shopify site{" "}
                <a className="ShopifyAuth__anchorBadge">{shop}</a> to BinderPOS.
              </p>
              {!unlinkedStore ? (
                <p>
                  Your BinderPOS account is currently linked to:{" "}
                  <a className="ShopifyAuth__anchorBadge">{customerSiteUrl}</a>
                </p>
              ) : null}
            </>
          )}
          {error ? (
            <div className="ShopifyAuth__error">
              <span className="ShopifyAuth__errorHeader">Error: </span>
              {error}
            </div>
          ) : null}
          {showClose ? (
            <ButtonComponent
              primary
              onClick={closeWindow}
              className="ShopifyAuth__linkButton"
            >
              Close
            </ButtonComponent>
          ) : (
            <ButtonComponent
              primary
              onClick={() => linkCurrentShop(unlinkingShop)}
              disabled={isProcessing}
              className="ShopifyAuth__linkButton"
            >
              {unlinkingShop ? "Unlink Shopify Site" : "Link Shopify Site"}
            </ButtonComponent>
          )}
        </div>
      </div>
    </div>
  );
}

export default ShopifyAuth;
