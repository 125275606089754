import React, { useEffect } from "react";
import { useFeatureFlag } from "../../hooks/globalSettingsHooks";
import { QUALTRICS_SURVEY_ENABLE } from "../../constants/featureFlags";
import { qualtricsWebsiteFeedbackSnippet } from "../../constants/surveys";

export const SCRIPT_TAG_ID = "qualtricsSurvey";
const SCRIPT_TAG_DELAY_MS = 2500;

const applyScript = () => {
  const scriptTag = document.createElement("script");
  scriptTag.setAttribute("id", SCRIPT_TAG_ID);
  scriptTag.innerHTML = qualtricsWebsiteFeedbackSnippet;
  scriptTag.type = "text/javascript";
  scriptTag.async = true;
  setTimeout(() => document.body.appendChild(scriptTag), SCRIPT_TAG_DELAY_MS);
};

function Surveys() {
  const qualtricsSurveysEnabled = useFeatureFlag(QUALTRICS_SURVEY_ENABLE);

  useEffect(() => {
    if (qualtricsSurveysEnabled) {
      applyScript();
    }
  }, [qualtricsSurveysEnabled]);

  return <div id="ZN_3KpLTWvliqQ0JYa"></div>;
}

export default Surveys;
