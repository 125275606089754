import React from "react";

interface EventCardActionsProps {
  children;
}

function EventCardActions(props: EventCardActionsProps) {
  const { children } = props;

  return <div className="EventCardActions">{children}</div>;
}

EventCardActions.isActions = true;

export default EventCardActions;
