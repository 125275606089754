import React from "react";
import { DEV_BLOG } from "../../constants/featureFlags";
import { Post, useGetLatestBlogPostsQuery } from "../../generated/devBlog";
import { useFeatureFlag } from "../../hooks/globalSettingsHooks";
import DevBlogPost from "./DevBlogPost";

const POSTS_COUNT = 5;
const LONG_LIFE_DATA_STALE_TIME = 5 * 60 * 1000; // ms

function DevBlog() {
  const featureEnabled = useFeatureFlag(DEV_BLOG);
  const { data, isLoading, error } = useGetLatestBlogPostsQuery(
    {
      count: POSTS_COUNT,
    },
    {
      enabled: featureEnabled,
      staleTime: LONG_LIFE_DATA_STALE_TIME,
    }
  );

  if (isLoading || error || !data?.posts || data.posts.edges.length < 1)
    return null;

  return (
    <div className="DevBlog">
      <h2>Latest updates</h2>
      {data.posts.edges.map((post) => (
        <DevBlogPost key={post.node.id} post={post.node as Post} />
      ))}
    </div>
  );
}

export default DevBlog;
