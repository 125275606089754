import { createAdaBotLauncher } from "../components/services/ADAChatbot";
import { segmentAnalytics } from "../components/services/Analytics";
import { adaBotAnswers, adaBotMetaFields } from "../constants/adaBot";
import { SUPPORT_CLICKED } from "../constants/eventsTracked";

export interface AdaCustomerDetails {
  customerId: string;
  customerName: string;
  customerSiteUrl: string;
}

export const handleHelpbotLaunch = ({
  customerId,
  customerName,
  customerSiteUrl,
}: AdaCustomerDetails) => {
  segmentAnalytics.track(SUPPORT_CLICKED, {
    support_type: "TCGPlayer Helpbot",
  });
  createAdaBotLauncher()
    .setAnswer(adaBotAnswers.BINDERPOS_CUSTOMER_MENU)
    .addMetaData(adaBotMetaFields.STORE_ID, customerId)
    .addMetaData(adaBotMetaFields.STORE_NAME, customerName)
    .addMetaData(adaBotMetaFields.STORE_URL, customerSiteUrl)
    .launchAdaBot();
};

export const handleReportCatalogIssueLaunch = ({
  customerId,
  customerName,
  customerSiteUrl,
}: AdaCustomerDetails) => {
  segmentAnalytics.track(SUPPORT_CLICKED, {
    support_type: "Report Catalog Issue",
  });
  createAdaBotLauncher()
    .setAnswer(adaBotAnswers.REPORT_CATALOG_ISSUE)
    .addMetaData(adaBotMetaFields.STORE_ID, customerId)
    .addMetaData(adaBotMetaFields.STORE_NAME, customerName)
    .addMetaData(adaBotMetaFields.STORE_URL, customerSiteUrl)
    .launchAdaBot();
};

export const handleReportABugLaunch = ({
  customerId,
  customerName,
  customerSiteUrl,
}: AdaCustomerDetails) => {
  segmentAnalytics.track(SUPPORT_CLICKED, {
    support_type: "Report a Bug",
  });
  createAdaBotLauncher()
    .setAnswer(adaBotAnswers.REPORT_A_BUG)
    .addMetaData(adaBotMetaFields.STORE_ID, customerId)
    .addMetaData(adaBotMetaFields.STORE_NAME, customerName)
    .addMetaData(adaBotMetaFields.STORE_URL, customerSiteUrl)
    .launchAdaBot();
};
