import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { useShowError } from "../../hooks/errorHooks";
import { TimeUtils } from "../../utils";
import { fetchCustomerCreditHistory } from "../../api/rest/customers";
import Modal from "../generic/Modal";
import Paging from "../../components/generic/Paging";
import { useFormatCurrency } from "../../hooks/storeHooks";

const LIMIT = 10;

export interface StoreCreditHistoryProps {
  customer: Customer;
  handleClose: () => void;
}

function StoreCreditHistory(
  props: StoreCreditHistoryProps,
  ref: MutableRefObject<any>
) {
  const { customer, handleClose } = props;
  const listStartRef = useRef<HTMLDivElement>(null);
  const showError = useShowError();
  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [creditHistory, setCreditHistory] = useState<CustomerCreditHistory[]>(
    []
  );
  const formatCurrency = useFormatCurrency();

  const handlePrevPageClick = () => {
    const newOffset = Math.max(offset - LIMIT, 0);
    setOffset(newOffset);
  };

  const handleNextPageClick = () => {
    const newOffset = offset + LIMIT;
    setOffset(newOffset);
  };

  const getHistory = async () => {
    setIsLoading(true);
    return fetchCustomerCreditHistory(customer.id, offset, LIMIT)
      .then((results) => {
        if (results?.data) {
          setCreditHistory(results.data);
          setTimeout(() => listStartRef.current?.scrollIntoView());
        }
      })
      .catch((error: DetailedError) => {
        showError(
          error,
          "Failed to load customer details",
          "There was an error retrieving your customer's credit history. Please refresh and try again"
        );
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getHistory();
  }, [offset]);

  return (
    <Modal ref={ref} onClose={handleClose} isLoading={isLoading}>
      <Modal.Header>
        Store credit history for{" "}
        {`${customer?.firstName} ${customer?.lastName}`}
        <Paging
          pageOffset={offset}
          currentPageItemCount={creditHistory.length}
          maxItemsPerPage={10}
          getPrevPage={handlePrevPageClick}
          getNextPage={handleNextPageClick}
        />
      </Modal.Header>
      <Modal.Content>
        <div ref={listStartRef}>
          {(creditHistory || []).map((storeCreditHistory) => (
            <div key={storeCreditHistory.id} id={String(storeCreditHistory.id)}>
              <table data-testid="storeCreditHistoryTable">
                <colgroup>
                  <col width="30%" />
                  <col width="70%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th>Actioned by</th>
                    <td>{storeCreditHistory.actionedBy}</td>
                  </tr>
                  <tr>
                    <th>Date actioned</th>
                    <td>
                      {TimeUtils.convertDateToHumanReadable(
                        storeCreditHistory.updatedDate
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Amount before</th>
                    <td>
                      {formatCurrency(storeCreditHistory.amountBeforeChange)}
                    </td>
                  </tr>
                  <tr>
                    <th>Amount after</th>
                    <td>
                      {formatCurrency(storeCreditHistory.amountAfterChange)}
                    </td>
                  </tr>
                  <tr>
                    <th>Amount changed</th>
                    <td>{formatCurrency(storeCreditHistory.amountChanged)}</td>
                  </tr>
                  <tr>
                    <th>Notes</th>
                    <td>
                      {storeCreditHistory.actionNotes
                        ? storeCreditHistory.actionNotes
                        : storeCreditHistory.publicNotes
                        ? ""
                        : "There are no notes attached"}
                      <br />
                      {storeCreditHistory.publicNotes
                        ? storeCreditHistory.publicNotes
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <th>Internal Notes</th>
                    <td>
                      {storeCreditHistory.privateNotes
                        ? storeCreditHistory.privateNotes
                        : "There are no internal notes attached"}
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
            </div>
          ))}
        </div>
        {creditHistory?.length < 1 ? (
          <p>There is no store credit history to display for this customer</p>
        ) : null}
      </Modal.Content>
    </Modal>
  );
}

export default React.forwardRef(StoreCreditHistory);
