import { _fetch } from "..";
import { API_V2_BASE_URL } from "../../constants/api";

export const fetchShopifySubscriptionStatus =
  (): Promise<ShopifySubscriptionResponse> =>
    _fetch({
      method: "POST",
      endpoint: `${API_V2_BASE_URL}/shopifyApps/subscriptionStatus`,
      payload: {},
    });
