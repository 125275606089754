import React, { useMemo, useState } from "react";
import ButtonComponent from "../generic/ButtonComponent";
import SubSectionHeaderLayout from "../layout/SubSectionHeaderLayout";
import { useShopifyThemeAvailableRollbacksQuery } from "../../generated/graphql";
import { useShowError } from "../../hooks/errorHooks";
import { useDeployThemeVersion } from "../../hooks/themeHooks";
import TimeUtils from "../../utils/TimeUtils";
import "./ThemeRollbacks.scss";

interface ThemeRollbacksProps {
  theme: string;
  currentVersion: string;
}

function ThemeRollbacks(props: ThemeRollbacksProps) {
  const { theme, currentVersion } = props;
  const [deployingVersion, setDeployingVersion] = useState<string>();
  const [deployQueued, setDeployQueued] = useState(false);
  const showError = useShowError();
  const {
    data,
    isLoading,
    error: availableRollbacksError,
  } = useShopifyThemeAvailableRollbacksQuery({
    theme,
    currentVersion,
  });
  const { mutateAsync: deployTheme } = useDeployThemeVersion();

  const handleDeploy = (version: string) => {
    setDeployingVersion(version);
    deployTheme({ theme, version })
      .then(() => {
        setDeployQueued(true);
      })
      .catch((error) => {
        console.error(error);
        showError(
          error,
          "Unable to apply update",
          "We were unable to apply your theme update. Please try again."
        );
      })
      .finally(() => setDeployingVersion(undefined));
  };

  const sortedThemeUpdates = useMemo(() => {
    if (!data?.ShopifyThemeAvailableRollbacks) {
      return [];
    }
    const updates = [...data.ShopifyThemeAvailableRollbacks];
    updates.sort((a, b) => (a.releaseDate > b.releaseDate ? -1 : 1));
    return updates;
  }, [data]);

  if (isLoading) {
    return <div data-testid="loader"></div>;
  }

  if (availableRollbacksError) {
    return (
      <p>Failed to load previous theme releases. Please refresh the page.</p>
    );
  }

  if (sortedThemeUpdates.length === 0) {
    return null;
  }

  if (deployQueued) {
    return (
      <div className="ThemeRollbacks">
        <SubSectionHeaderLayout title="Previous Versions" />
        <p>
          Your theme request has been queued. Please allow several minutes for
          this to complete.
        </p>
        <p>
          Please monitor your Shopify store to see when the changes have taken
          effect.
        </p>
      </div>
    );
  }

  return (
    <div className="ThemeRollbacks">
      <SubSectionHeaderLayout title="Previous Versions" />
      <p>
        In the event that you encounter an issue with a theme update, you can
        change back to a previous release.
      </p>
      <ul className="ThemeRollbacks__releasesList">
        {sortedThemeUpdates.map((update) => (
          <li
            key={update.version}
            className="ThemeRollbacks__release"
            data-testid="themeRelease"
          >
            <div className="ThemeRollbacks__releaseDetails">
              <span className="ThemeRollbacks__releaseDate">
                Release date:{" "}
                {TimeUtils.convertDateToHumanReadableNoTime(update.releaseDate)}
              </span>
              <p className="ThemeRollbacks__releaseSummary">{update.summary}</p>
            </div>
            <div className="ThemeRollbacks__releaseActions">
              <ButtonComponent
                primary
                onClick={() => handleDeploy(update.version)}
                disabled={update.version === deployingVersion}
              >
                Revert to this version
              </ButtonComponent>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ThemeRollbacks;
