import React from "react";
import { Post } from "../../generated/devBlog";
import { sanitize } from "dompurify";
import "./DevBlogPost.scss";
import FullLogo from "../../../assets/img/fullLogo.png";
import { segmentAnalytics } from "../services/Analytics";
import { LATEST_UPDATE_CLICKED } from "../../constants/eventsTracked";

const READ_MORE_REGEX = /<a class="more-link".*<\/a>/;

interface DevBlogPostProps {
  post: Post;
}

function DevBlogPost(props: DevBlogPostProps) {
  const { post } = props;

  const excerpt = post.excerpt?.replace(READ_MORE_REGEX, "");

  return (
    <div className="DevBlogPost" data-testid="DevBlogPost">
      <img
        className="DevBlogPost__image"
        src={post.featuredImage?.node?.mediaItemUrl || FullLogo}
        role="presentation"
      />
      <a
        className="DevBlogPost__link"
        href={post.link}
        target="_blank"
        rel="noreferrer"
        onClick={() =>
          segmentAnalytics.track(LATEST_UPDATE_CLICKED, {
            update_title: post.title,
            link: post.link,
          })
        }
      >
        <div className="DevBlogPost__content">
          <p className="DevBlogPost__title">{post.title}</p>
          <p
            className="DevBlogPost__exceprt"
            dangerouslySetInnerHTML={{
              __html: sanitize(
                // @ts-ignore
                excerpt
              ),
            }}
          />
        </div>
      </a>
    </div>
  );
}

export default DevBlogPost;
