import { useContext } from "react";
import { POS_SHOPIFY_THEMES } from "../constants/featureFlags";
import {
  legacyMainMenuStructure as mainMenuStructure,
  optionalMenuItems,
} from "../menuStructures/mainMenuStructure";
import { MenuContext } from "../providers/MenuProvider";
import { useFeatureFlag } from "./globalSettingsHooks";

export const useLegacyMainMenu = () => {
  let menuStructure = [...mainMenuStructure];
  const shopifyThemes = useFeatureFlag(POS_SHOPIFY_THEMES);
  if (shopifyThemes) {
    // Move Themes above POS and Settings
    menuStructure = [
      ...mainMenuStructure.slice(0, mainMenuStructure.length - 2),
      optionalMenuItems.shopifyThemes,
      ...mainMenuStructure.slice(mainMenuStructure.length - 2),
    ];
  }
  return menuStructure;
};

export const useMainMenu = () => {
  const context = useContext(MenuContext);
  if (!context) {
    throw new Error("useMainMenu hook must be used within MenuProvider");
  }
  return context.menuItems;
};
