import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchApiKey } from "../../../api/rest/cfbmarket";
import { fetchCustomerEbayProvisioningDetails } from "../../../api/rest/ebay";
import { API_STATE } from "../../../constants/api";
import { useStoreDetails } from "../../../hooks/storeHooks";
import { useIntegrations } from "../../../hooks/integrationHooks";

type DefaultValues = {
  provisioned: boolean;
  authorised: boolean;
};

const getDefaultValues = (integration: string): DefaultValues => {
  if (integration === "ebay") {
    return {
      provisioned: false,
      authorised: false,
    };
  }
  if (integration === "cfbmarket") {
    return {
      provisioned: true,
      authorised: false,
    };
  }
  return {
    provisioned: true,
    authorised: true,
  };
};

const integrationsToCheck = ["ebay", "cfbmarket"];

const getProvisioningCheckFunction = (integration: string) => {
  if (integration === "ebay") return fetchCustomerEbayProvisioningDetails;
  return fetchApiKey;
};

type ProvisionCheckResponse = {
  ebayAuthed?: boolean;
};

const getAuthorisationState = (
  integration: string,
  data: ProvisionCheckResponse
) => {
  if (integration === "cfbmarket") return true;
  return data.ebayAuthed;
};

interface ProvisioningAndAuthCheckContainerProps {
  children: (
    provisionCheckCompleted: boolean,
    isProvisioned: boolean,
    isAuthorised: boolean,
    setIsProvisioned: Function
  ) => JSX.Element;
}

function ProvisioningAndAuthCheckContainer(
  props: ProvisioningAndAuthCheckContainerProps
) {
  const { children } = props;
  const { integration } = useParams<{ integration: string }>();
  const initialState = getDefaultValues(integration);
  const [apiState, setApiState] = useState(API_STATE.INITIAL);
  const [isProvisioned, setIsProvisioned] = useState(initialState.provisioned);
  const [isAuthorised, setIsAuthorised] = useState(initialState.authorised);
  const { customerId } = useStoreDetails();
  const { setEbayDefaultMarketplace } = useIntegrations();
  const provisioningCheckFunction = getProvisioningCheckFunction(integration);

  const getProvisioningDetails = () => {
    setApiState(API_STATE.LOADING);
    provisioningCheckFunction()
      .then((data: any) => {
        setIsProvisioned(true);
        const authed = getAuthorisationState(integration, data);
        setIsAuthorised(authed);
        setApiState(API_STATE.SUCCESS);
        if (data?.defaultMarketPlace) {
          setEbayDefaultMarketplace(data.defaultMarketPlace);
        }
      })
      .catch(() => {
        setApiState(API_STATE.ERROR);
      });
  };

  useEffect(() => {
    setIsProvisioned(initialState.provisioned);
    setIsAuthorised(initialState.authorised);
    if (customerId && integrationsToCheck.includes(integration))
      getProvisioningDetails();
  }, [customerId, integration]);

  const provisionCheckCompleted =
    !integrationsToCheck.includes(integration) ||
    apiState === API_STATE.SUCCESS ||
    apiState === API_STATE.ERROR;

  return children(
    provisionCheckCompleted,
    isProvisioned,
    isAuthorised,
    setIsProvisioned
  );
}

export default ProvisioningAndAuthCheckContainer;
