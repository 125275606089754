import React from "react";
import { Loader } from "../../components";
import TableComponent from "../table/TableComponent";
import { Column } from "../../types/table";
import { GenericQueuedJob } from "../../types/queuedJobs";
import SectionHeaderLayout from "../layout/SectionHeaderLayout";
import "./JobQueueList.scss";

export interface QueuesMap {
  [typeKey: string]: {
    title: string;
    type: string;
    limit: number;
  };
}

interface JobQueueListProps {
  isLoading: boolean;
  queuedJobs: GenericQueuedJob[];
  columns: Column[];
  altLineComponent?: Function;
  children?: {
    paging?: React.ReactChild;
    header?: React.ReactChild;
    submenu?: React.ReactChild;
    backButton?: React.ReactChild;
  };
}

const JobQueueList = (props: JobQueueListProps) => {
  const { queuedJobs, columns, isLoading, children } = props;
  const { submenu, paging, header, backButton } = children;

  return (
    <>
      <SectionHeaderLayout title={header}>{backButton}</SectionHeaderLayout>
      {paging ? paging : null}
      {submenu ? submenu : null}
      {isLoading ? (
        <Loader text="Loading..." />
      ) : (
        <div className="jobQueueList">
          <TableComponent
            data={queuedJobs}
            columns={columns}
            getTrProps={() => ({ className: "jobQueueList__row" })}
          />
        </div>
      )}
      {paging ? paging : null}
    </>
  );
};

export default JobQueueList;
