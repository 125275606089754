import React, { createContext, useEffect, useState } from "react";
import {
  fetchCustomerSetting,
  fetchStoreSetting,
  fetchStoreSettings,
  fetchStoreDetails,
} from "../api/rest/settings";
import { StoreDetails, StoreSettings } from "../types/settings";
import { currencies } from "../constants/currencies";

// Store as in shop, not state
type SetFunction = React.Dispatch<React.SetStateAction<string>>;
const loadStoreSetting = (settingName: string, setFunction: SetFunction) =>
  fetchStoreSetting(settingName).then((response: SettingResponse) => {
    if (response?.settingValue) {
      setFunction(response.settingValue);
    }
  });

export interface StoreContextProps {
  customerId: string | undefined;
  customerName: string | undefined;
  customerSiteUrl: string | undefined;
  customerEmail: string | undefined;
  currencySymbol: string | undefined;
  currencyCode: string | undefined;
  currencyIsFractional: boolean | undefined;
  storeDetails: StoreDetails | undefined;
  taxRate: string | undefined;
}

export const StoreContext = createContext<StoreContextProps>(null);

interface StoreProviderProps {
  children: React.ReactNode;
}

function StoreProvider(props: StoreProviderProps) {
  const { children } = props;
  const [customerId, setCustomerId] = useState<string>();
  const [customerName, setCustomerName] = useState<string>();
  const [customerSiteUrl, setCustomerSiteUrl] = useState<string>();
  const [customerEmail, setCustomerEmail] = useState<string>();
  const [currencyCode, setCurrencyCode] = useState<string>();
  const [currencySymbol, setCurrencySymbol] = useState<string>();
  const [currencyIsFractional, setIsCurrencyFractional] = useState<boolean>();
  const [storeDetails, setStoreDetails] = useState<StoreDetails>();
  const [taxRate, setTaxRate] = useState<string>();

  useEffect(() => {
    // TODO:: Replace most of this with a single GraphQL query
    loadStoreSetting("currency", setCurrencyCode);
    loadStoreSetting("currencySymbol", setCurrencySymbol);
    fetchCustomerSetting("taxRate").then((response: SettingResponse) => {
      if (response?.settingValue) {
        setTaxRate(response.settingValue);
      }
    });
    fetchStoreSettings().then((data: StoreSettings) => {
      setCustomerId(data?.id);
      setCustomerName(data?.name);
      setCustomerSiteUrl(data?.siteUrl);
      setCustomerEmail(data?.email);
    });
    fetchStoreDetails().then((data: StoreDetails) => {
      setStoreDetails(data);
    });
  }, []);

  useEffect(() => {
    if (currencyCode) {
      const currency = currencies.find(
        (currency) => currency.isoCode === currencyCode
      );

      const isFractional = currency ? currency.minorUnits > 0 : true;
      setIsCurrencyFractional(isFractional);
    }
  }, [currencyCode]);

  const contextValue = {
    customerId,
    customerName,
    customerSiteUrl,
    customerEmail,
    currencyCode,
    currencySymbol,
    currencyIsFractional,
    storeDetails,
    taxRate,
  };

  return (
    <StoreContext.Provider value={contextValue}>
      {children}
    </StoreContext.Provider>
  );
}

export default StoreProvider;
