import React, { Fragment, useEffect } from "react";
import { ReportsMenuStructure } from "../../menuStructures";
import SetActiveMenu from "../../menuStructures/SetActiveMenu";
import { ButtonComponent } from "../../components";
import { VIEW_REPORT_CLICKED } from "../../constants/eventsTracked";

function StoreCredit() {
  useEffect(() => {
    document.title = "Reports | BinderPOS";
  }, []);

  return (
    <Fragment>
      <SetActiveMenu menuStructure={ReportsMenuStructure} />
      <div className="app-header">
        <h1 className="title">
          <span className="header-text">Store Credit Reports</span>
        </h1>
      </div>
      <div className="reports-menu row">
        <div className="col-sm-6">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title">Store Credit Usage</h2>
              <p className="card-text">
                View reports of store credit usage by date.
              </p>
              <ButtonComponent
                primary
                to="/reports/store-credit/transactions"
                segmentEventName={VIEW_REPORT_CLICKED}
                segmentEventProperties={{ report_type: "Store Credit" }}
                fullWidth
                compact
                isLink
              >
                View Report
              </ButtonComponent>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title">Outstanding Store Credit</h2>
              <p className="card-text">
                View the amount of store credit remaining for all customers.
              </p>
              <ButtonComponent
                primary
                to="/reports/store-credit/outstanding"
                segmentEventName={VIEW_REPORT_CLICKED}
                segmentEventProperties={{
                  report_type: "Outstanding Store Credit",
                }}
                fullWidth
                compact
                isLink
              >
                View Stock Reports
              </ButtonComponent>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title">Customer Credit History</h2>
              <p className="card-text">
                View store credit earnings and spending for a particular
                customer.
              </p>
              <ButtonComponent
                primary
                to="/reports/store-credit/customer"
                segmentEventName={VIEW_REPORT_CLICKED}
                segmentEventProperties={{
                  report_type: "Customer Credit History",
                }}
                fullWidth
                compact
                isLink
              >
                View Report
              </ButtonComponent>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default StoreCredit;
