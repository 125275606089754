export const getCustomerTitle = (customer: Customer) => {
  if (!customer) {
    return "Customer";
  }
  if (customer.firstName?.length > 0) {
    return `${customer.firstName} ${
      customer.lastName?.length > 0 ? customer.lastName : ""
    }`;
  }
  if (customer.email?.length > 0) {
    return customer.email;
  }
  if (customer.phone?.length > 0) {
    return customer.phone;
  }
  return "Customer";
};
