import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { remapTenderName } from "../../utils/tenders";
import ButtonComponent from "../generic/ButtonComponent";
import Loader from "../generic/Loader";
import Modal from "../generic/Modal";
import TenderPayment from "./TenderPayment";
import TenderSelect from "./TenderSelect";
import "./MultiTenderCheckout.scss";
import { ItemList } from "../../pos/ItemStore";

interface MultiTenderCheckoutProps {
  posStore?: ItemList;
  handleClose: () => void;
}

interface MultiTenderCheckoutState {
  showTenderSelect: boolean;
  selectedTender: any;
  tendersAdded: boolean;
}

@inject("posStore")
@observer
class MultiTenderCheckout extends Component<
  MultiTenderCheckoutProps,
  MultiTenderCheckoutState
> {
  constructor(props) {
    super(props);
    this.state = {
      showTenderSelect: true,
      selectedTender: null,
      tendersAdded: false,
    };
  }

  showTenderSelect = (visible) => {
    this.setState({ showTenderSelect: visible });
  };

  selectTender = (tender) => {
    this.setState({ selectedTender: tender, showTenderSelect: false });
  };

  addTender = (type, value) => {
    this.props.posStore.addTender(type, value);
    this.setState({ selectedTender: null, tendersAdded: true });
  };

  removeTender = (index) => {
    this.props.posStore.removeTender(index);
  };

  getRemainingStoreCredit = () => {
    const store = this.props.posStore;
    if (!store.selectedCustomer) return 0;
    const usedStoreCredit =
      store.tenders?.reduce((accumulator, tender) => {
        if (tender.type.toLowerCase() === "store credit") {
          return accumulator + Number(tender.amount);
        }
        return accumulator;
      }, 0) || 0;
    return store.selectedCustomer.storeCredit - usedStoreCredit;
  };

  storeCreditAvailable = (remainingBalance, remainingStoreCredit) => {
    if (!this.props.posStore.selectedCustomer) return false;
    if (remainingBalance < 0) {
      // Buys add to store credit
      return true;
    }
    return remainingStoreCredit > 0;
  };

  render() {
    const store = this.props.posStore;
    const fC = this.props.posStore.fCurr;

    const totalTendered =
      store.tenders?.reduce(
        (accumulator, tender) => accumulator + Number(tender.amount),
        0
      ) || 0;

    const remainingBalance = Number(store.total) - totalTendered;
    const remainingStoreCredit = this.getRemainingStoreCredit();

    if (store.submittingCart) {
      return (
        <div className="multiTenderCheckout__loader-wrapper">
          <Loader text="Submitting cart..." />
        </div>
      );
    }

    if (this.state.showTenderSelect) {
      return (
        <TenderSelect
          tenders={store.availableTenders}
          storeCreditAvailable={this.storeCreditAvailable(
            remainingBalance,
            remainingStoreCredit
          )}
          onTenderSelect={this.selectTender}
          onClose={() => this.showTenderSelect(false)}
        />
      );
    }

    if (this.state.selectedTender) {
      return (
        <TenderPayment
          tenderType={this.state.selectedTender}
          remainingStoreCredit={remainingStoreCredit}
          remainingBalance={remainingBalance}
          onAddTender={this.addTender}
          onClose={() => this.selectTender(undefined)}
        />
      );
    }

    return (
      <>
        <Modal small onClose={this.props.handleClose}>
          <Modal.Header>Checkout</Modal.Header>
          <Modal.Content>
            <div className="multiTenderCheckout__balance">
              Sale Total:<em>{fC(store.total)}</em>
            </div>
            <div className="multiTenderCheckout__balance">
              Amount received: <em>{fC(totalTendered)}</em>
            </div>
            <div className="multiTenderCheckout__balance">
              Amount outstanding: <em>{fC(Math.max(0, remainingBalance))}</em>
            </div>
            <div className="multiTenderCheckout__tenders">
              {store.tenders?.map((tender, index) => {
                if (Number(tender.amount) === 0 || tender.amount === "0.00")
                  return null;
                return (
                  <div key={index} className="multiTenderCheckout__tender">
                    <span className="multiTenderCheckout__tenderName">
                      {remapTenderName(tender.type)}
                    </span>
                    <em className="multiTenderCheckout__tenderAmount">
                      {fC(tender.amount)}
                    </em>
                    <button
                      className="multiTenderCheckout__tenderRemove"
                      data-testid="tenderRemove"
                      onClick={() => this.removeTender(index)}
                    >
                      <i className="fas fa-times-circle" />
                    </button>
                  </div>
                );
              })}
            </div>
            {Number(store.changeDue) > 0 ? (
              <>
                <hr />
                <div className="multiTenderCheckout__balance multiTenderCheckout__balance--changeDue">
                  Change Due: <em>{fC(store.changeDue)}</em>
                </div>
              </>
            ) : null}
            <br />
            <ButtonComponent
              fullWidth
              primary={remainingBalance > 0}
              onClick={() => this.showTenderSelect(true)}
              icon="fal fa-plus"
              iconPosition="left"
            >
              Add Payment
            </ButtonComponent>
          </Modal.Content>
          <Modal.Actions>
            <ButtonComponent
              secondary
              onClick={() => {
                store.resetTenders();
                this.props.handleClose();
              }}
              icon="fal fa-times"
              iconPosition="left"
            >
              Cancel
            </ButtonComponent>
            <ButtonComponent
              primary
              disabled={
                remainingBalance > 0 ||
                (remainingBalance !== 0 && this.state.tendersAdded === false)
              }
              onClick={store.processPayments}
              icon="fas fa-caret-right"
              iconPosition="right"
            >
              Finalize Sale
            </ButtonComponent>
          </Modal.Actions>
        </Modal>

        {/* {this.props.posStore.processingActive ? <CreditProcess /> : null} */}
      </>
    );
  }
}

export default MultiTenderCheckout;
