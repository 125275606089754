import { useEffect, useRef, useState } from "react";
import { Field, Form, Formik, FormikValues } from "formik";
import { segmentAnalytics } from "../services/Analytics";
import { ReceiptFields, ReceiptSettings } from "../../types/settings";
import {
  RECEIPT_FIELDS,
  STORE_SETTINGS_KEY_RECEIPT,
} from "../../constants/settings";
import { notify } from "../../helpers/notificationHelpers";
import {
  EDIT_RECEIPT_SETTINGS_CLICKED,
  SAVE_RECEIPT_SETTINGS_CLICKED,
} from "../../constants/eventsTracked";
import { useFetchSetting, useUpdateSetting } from "../../hooks/settingHooks";
import SettingsSection from "./SettingsSection";
import ButtonComponent from "../../components/generic/ButtonComponent";
import Spinner from "../../components/generic/Spinner";
import Input from "../form/Input";
import "./StoreReceiptsSettingsContainer.scss";

const ADDITIONAL_PREFIX = "additional";

const initialValues = Object.fromEntries([
  ...Object.keys(RECEIPT_FIELDS).map((fieldName) => [fieldName, ""]),
  ...Object.keys(RECEIPT_FIELDS).map((fieldName) => [
    `${ADDITIONAL_PREFIX}${fieldName}`,
    "",
  ]),
]);

const renderReceiptFieldsForm = (
  editing: boolean,
  prefix = "",
  required = false
) => {
  return (
    <div className="StoreReceiptsSettingsContainer__form-group">
      {Object.entries(RECEIPT_FIELDS).map(([key, value]) => {
        const { id, type, title, ...rest } = value;
        const isTextArea = type === "textarea";
        return (
          <div
            key={key}
            className={`StoreReceiptsSettingsContainer__input ${
              isTextArea ? "StoreReceiptsSettingsContainer__input--large" : ""
            }`}
            data-testid="settings-field"
            data-id={id}
          >
            <Field
              component={Input}
              editing={editing}
              id={`${prefix}${id}`}
              name={`${prefix}${id}`}
              label={title}
              required={required}
              type={type}
              {...rest}
            />
          </div>
        );
      })}
    </div>
  );
};

const checkHasValuesForSecondLanguage = (receiptSettings: ReceiptSettings) =>
  !!Object.entries(receiptSettings?.additionalLanguageFields || {}).find(
    ([_, value]) => value !== ""
  );

const addFieldsKeyPrefix = (
  fields: ReceiptFields,
  prefix = ADDITIONAL_PREFIX
) => {
  if (!fields) {
    return {};
  }
  return Object.fromEntries(
    Object.entries(fields).map(([key, value]) => {
      return [`${prefix}${key}`, value];
    })
  );
};

const getFormStateFromSettings = (settingsString?: string): FormikValues => {
  const receiptSettings = settingsString ? JSON.parse(settingsString) : {};
  const {
    defaultLanguageFields,
    defaultLanguageLabel = "",
    additionalLanguageLabel = "",
    additionalLanguageFields,
  } = receiptSettings;
  return {
    ...defaultLanguageFields,
    defaultLanguageLabel,
    additionalLanguageLabel,
    ...addFieldsKeyPrefix(additionalLanguageFields),
  };
};

const getDefaultLangFieldsFromState = (
  formEntries: [string, any][]
): ReceiptFields =>
  Object.fromEntries(
    formEntries.filter(([key]) => Object.keys(RECEIPT_FIELDS).includes(key))
  ) as ReceiptFields;

const getAdditionalLangFieldsFromState = (
  formEntries: [string, any][],
  prefix = ADDITIONAL_PREFIX
): ReceiptFields => {
  return Object.fromEntries(
    formEntries.map(([key, value]) =>
      key.startsWith(prefix) ? [key.slice(prefix.length), value] : []
    )
  );
};

const getSettingsFromFormValues = (
  secondLanguageSelected: boolean,
  values: FormikValues
): ReceiptSettings => {
  const { defaultLanguageLabel, additionalLanguageLabel, ...rest } = values;
  const entries = Object.entries(rest);
  const defaultLanguageFields = getDefaultLangFieldsFromState(entries);
  const additionalLanguageFields = secondLanguageSelected
    ? getAdditionalLangFieldsFromState(entries)
    : {};
  return {
    defaultLanguageFields,
    defaultLanguageLabel: secondLanguageSelected
      ? defaultLanguageLabel
      : undefined,
    additionalLanguageLabel: secondLanguageSelected
      ? additionalLanguageLabel
      : undefined,
    additionalLanguageFields,
  };
};

const StoreReceiptsSettingsContainer = () => {
  const [updatingStoreSettings, setUpdatingStoreSettings] =
    useState<boolean>(false);
  const { data, isLoading } = useFetchSetting(STORE_SETTINGS_KEY_RECEIPT);
  const { mutateAsync: doSaveSetting, isMutating: isSubmitting } =
    useUpdateSetting();
  const [secondLangSelected, setSecondLangSelected] = useState<boolean>(false);
  // this checkbox toggles the additional form fields but is outside Formik
  const secondLangChecked = useRef<HTMLInputElement>(null);

  const displaySecondSection = secondLangSelected || updatingStoreSettings;

  useEffect(() => {
    if (data) {
      const settings = JSON.parse(data || "{}");
      setSecondLangSelected(checkHasValuesForSecondLanguage(settings));
    }
  }, [data]);

  const toggleUpdatingStoreSettings = (updating: boolean) => {
    if (updating) segmentAnalytics.track(EDIT_RECEIPT_SETTINGS_CLICKED);
    setUpdatingStoreSettings(updating);
  };

  const toggleSecondLangSelected = () => {
    setSecondLangSelected(secondLangChecked?.current.checked);
  };

  const saveStoreSettings = (formValues: FormikValues) => {
    const settings = getSettingsFromFormValues(secondLangSelected, formValues);
    doSaveSetting({
      settingName: STORE_SETTINGS_KEY_RECEIPT,
      settingValue: JSON.stringify(settings),
    })
      .then(() => {
        setUpdatingStoreSettings(false);
        notify.info("Receipt Settings Saved");
      })
      .catch((e) => {
        console.error({ e });
        notify.warn(
          "Failed to Save Receipt Settings. Refresh the page and try again."
        );
      });
  };

  return (
    <Formik
      initialValues={data ? getFormStateFromSettings(data) : initialValues}
      onSubmit={(e) => {
        segmentAnalytics.track(SAVE_RECEIPT_SETTINGS_CLICKED);
        saveStoreSettings(e);
      }}
      enableReinitialize
    >
      {({ errors, submitForm }) => (
        <SettingsSection
          title="Receipt Settings"
          isEditing={updatingStoreSettings}
          enableEditMode
          toggleEditMode={toggleUpdatingStoreSettings}
          formHasErrors={Object.keys(errors).length > 0}
          submitHandler={submitForm}
        >
          <div className="StoreReceiptsSettingsContainer">
            <Spinner isLoading={isLoading || isSubmitting}>
              <Form>
                <div className="StoreReceiptsSettingsContainer__section">
                  <h3>Default Language</h3>
                  <p className="StoreReceiptsSettingsContainer__blurb">
                    Set these fields to override the labels used to print the
                    customers receipt - this can be used to change the default
                    language of the receipt.
                  </p>
                  {renderReceiptFieldsForm(updatingStoreSettings)}
                </div>
                {displaySecondSection && (
                  <div className="StoreReceiptsSettingsContainer__section">
                    <h3>Additional Language</h3>
                    <p className="StoreReceiptsSettingsContainer__blurb">
                      Provide a second language for printing receipts, and you
                      will see language options when printing receipts in the
                      POS. If a value is not supplied the English default will
                      be used instead.
                    </p>
                    {updatingStoreSettings && (
                      <label>
                        <input
                          ref={secondLangChecked}
                          className="input input--inline"
                          id="secondLanguageOption"
                          data-testid="secondLanguageOption"
                          name="secondLanguageOption"
                          type="checkbox"
                          defaultChecked={secondLangSelected}
                          onChange={toggleSecondLangSelected}
                        />
                        <span className="label">
                          Support a second language for printing receipts
                        </span>
                      </label>
                    )}
                    {secondLangSelected && (
                      <>
                        <div className="StoreReceiptsSettingsContainer__sub-section">
                          <div className="receipt-input">
                            <Field
                              component={Input}
                              editing={updatingStoreSettings}
                              label="Default Language Label"
                              id="defaultLanguageLabel"
                              name="defaultLanguageLabel"
                              type="text"
                              data-testid="settings-field"
                              required
                            />
                          </div>
                          <div className="receipt-input">
                            <Field
                              component={Input}
                              editing={updatingStoreSettings}
                              label="Additional Language Label"
                              id="additionalLanguageLabel"
                              name="additionalLanguageLabel"
                              type="text"
                              data-testid="settings-field"
                              required
                            />
                          </div>
                        </div>
                        {renderReceiptFieldsForm(
                          updatingStoreSettings,
                          ADDITIONAL_PREFIX,
                          false
                        )}
                      </>
                    )}
                  </div>
                )}
                {updatingStoreSettings && (
                  <ButtonComponent
                    primary
                    type="submit"
                    data-testid="save-submit"
                    className="StoreReceiptsSettingsContainer__action"
                  >
                    Save receipt settings
                  </ButtonComponent>
                )}
              </Form>
            </Spinner>
          </div>
        </SettingsSection>
      )}
    </Formik>
  );
};

export default StoreReceiptsSettingsContainer;
