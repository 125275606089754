import { useEffect, useState } from "react";
import SetActiveMenu from "./SetActiveMenu";
import SettingsMenuStructure from "./SettingsMenuStructure";
import { useFetchSetting } from "../hooks/settingHooks";

function SettingsMenu() {
  const { data: allowCatalogChanges } = useFetchSetting("allowCatalogChanges");
  const [menuStructure, setMenuStructure] = useState(SettingsMenuStructure);

  const conditionalMenuOption = {
    title: "Catalog settings",
    href: "settings/catalog",
  };

  useEffect(() => {
    if (allowCatalogChanges === "true") {
      setMenuStructure({
        ...SettingsMenuStructure,
        links: [
          SettingsMenuStructure.links[0],
          conditionalMenuOption,
          ...SettingsMenuStructure.links.slice(1),
        ],
      });
    }
  }, [allowCatalogChanges]);

  return <SetActiveMenu menuStructure={menuStructure} />;
}

export default SettingsMenu;
