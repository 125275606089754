import { Setting } from "../api/rest/integrations";

/**
 * Split the query params from the current URL into an object
 *
 * @returns object
 */
export const queryParamsToObject = () =>
  Array.from(
    new URLSearchParams(
      document.location.hash.replace(/^(.*)\?/, "")
      // @ts-ignore
    ).entries()
  ).reduce((q, [k, v]) => Object.assign(q, { [k]: v }), {});

type EbayError = {
  message: string;
  data?: string;
};

export const parseEbayErrorMessage = (ebayError: EbayError) => {
  try {
    // @ts-ignore
    const errorObject = JSON.parse(ebayError.data);
    if (errorObject.errors) {
      return errorObject.errors;
    }
    if (errorObject.message) {
      return [errorObject.message];
    }
  } catch (err) {
    return ["An unexpected error providing no details"];
  }
};

export const percentToDecimal = (setting: Setting) => {
  if (!setting?.priceMarkup) return setting;
  return {
    ...setting,
    priceMarkup: Number(setting.priceMarkup) / 100,
  };
};

export const decimalToPercent = (setting?: Setting) => {
  if (!setting?.priceMarkup) return setting;
  return {
    ...setting,
    priceMarkup: Number(setting.priceMarkup) * 100,
  };
};

export default queryParamsToObject;
