import { CSVExportReponse } from "../types/productExport";
import { TimeUtils } from "../utils";

export function mapProductDataExports(data: CSVExportReponse) {
  const { createdAt, updatedAt, ...rest } = data;
  return {
    createdAt: TimeUtils.convertDateToHumanReadable(createdAt),
    updatedAt: TimeUtils.convertDateToHumanReadable(updatedAt),
    ...rest,
  };
}
