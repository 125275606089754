import { useEffect, useRef, useState } from "react";
import { SAVE_GLOBAL_PRICE_SETTINGS_CLICKED } from "../../constants/eventsTracked";
import { notify } from "../../helpers/notificationHelpers";
import { useShowError } from "../../hooks/errorHooks";
import { useUpdateGlobalPriceRules } from "../../hooks/pricingHooks";
import { segmentAnalytics } from "../services/Analytics";
import NavigationPromptWatcher from "../nav/NavigationPromptWatcher";
import PriceRounding from "./PriceRounding";
import Tooltip from "../generic/Tooltip";
import { Form, Formik } from "formik";
import SettingsSection from "./SettingsSection";
import "./GlobalPriceSettingsSection.scss";

export interface GlobalPriceSettingsSectionProps {
  globalRoundToNearest: string | number;
}

const GlobalPriceSettingsSection = ({
  globalRoundToNearest,
}: GlobalPriceSettingsSectionProps) => {
  const [isEditable, setIsEditable] = useState(false);

  const formikRef = useRef<any>();
  const showError = useShowError();
  const initialValues = {
    roundToNearest: globalRoundToNearest,
  };

  const { mutateAsync, isMutating, error } = useUpdateGlobalPriceRules();

  useEffect(() => {
    if (error) {
      showError(
        error as DetailedError,
        "Failed to save",
        "Unable to save your price settings. Please try again"
      );
    }
  }, [error]);

  const handleToggleEdit = (isEditable: boolean) => {
    setIsEditable(isEditable);
    if (isEditable === false) {
      if (formikRef?.current) {
        formikRef.current.resetForm();
      }
    }
  };

  const handleOnSubmit = async (data: any) => {
    segmentAnalytics.track(SAVE_GLOBAL_PRICE_SETTINGS_CLICKED, {
      price_rounding: Number(data.roundToNearest).toFixed(2),
    });
    await mutateAsync(
      {
        roundToNearest: Number(data.roundToNearest),
      },
      {
        onSuccess: () => {
          notify.info(
            "Your pricing adjustments have been queued and will take effect in about 12 hours."
          );
          setIsEditable(false);
        },
      }
    ).catch(() => handleToggleEdit(false));
  };

  const isEditing = isEditable && !isMutating;

  return (
    <Formik
      onSubmit={handleOnSubmit}
      initialValues={initialValues}
      enableReinitialize
      innerRef={formikRef}
    >
      {({ dirty, errors, submitForm }) => {
        return (
          <SettingsSection
            title={
              <>
                All Singles: Global Settings
                <Tooltip iconTheme="light">
                  Any per-product-line settings configured will override global
                  settings.
                </Tooltip>
              </>
            }
            subtitle={
              <>
                Price Rounding
                <Tooltip>
                  Price rounding allows your automatic pricing to round based on
                  the value set. If no game-specific rounding is configured, the
                  global setting will apply.
                </Tooltip>
              </>
            }
            isEditing={isEditable}
            isMutating={isMutating}
            enableEditMode
            toggleEditMode={handleToggleEdit}
            toggleTextOn="Edit Global Settings"
            submitHandler={submitForm}
            formHasErrors={Object.keys(errors).length > 0}
          >
            <NavigationPromptWatcher
              dirty={dirty}
              formName={`GlobalPriceSettingsSection`}
            >
              <Form className="GlobalPriceSettingsSection__form">
                <PriceRounding
                  isGlobal={true}
                  isEditing={isEditing}
                  hideTitle
                />
              </Form>
            </NavigationPromptWatcher>
          </SettingsSection>
        );
      }}
    </Formik>
  );
};

export default GlobalPriceSettingsSection;
