export const availableThemes = {
  standard: {
    settingName: "shopifyStandardThemeId",
    themeName: "Standard Theme",
  },
  premium: {
    settingName: "shopifyPremiumThemeId",
    themeName: "Premium Theme",
  },
  "premium-3": {
    settingName: "shopifyPremiumThemeThreeId",
    themeName: "Premium Theme Three",
  },
  "premium-4": {
    settingName: "shopifyPremiumThemeFourId",
    themeName: "Premium Theme Four",
  },
};

export const availableThemeSettings = {
  shopifyStandardThemeId: "standard",
  shopifyPremiumThemeId: "premium",
  shopifyPremiumThemeThreeId: "premium-3",
  shopifyPremiumThemeFourId: "premium-4",
};
