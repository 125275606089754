import React from "react";
import ToggleSwitch from "../generic/ToggleSwitch";
import { notify } from "../../helpers/notificationHelpers";
import { useUpdateCatalogEnabled } from "../../hooks/settingHooks";
import { useShowError } from "../../hooks/errorHooks";
import "./CatalogEnable.scss";

interface CatalogEnableProps {
  gameCode: string;
  name: string;
  isEnabled: boolean;
}

function CatalogEnable(props: CatalogEnableProps) {
  const { gameCode, name, isEnabled } = props;
  const { mutateAsync, isMutating } = useUpdateCatalogEnabled();
  const showError = useShowError();

  const handleChange = () =>
    mutateAsync({ gameCode, enabled: !isEnabled })
      .then(() => notify.info(`Change to ${name} queued`))
      .catch((error) =>
        showError(error, "Failed to update catalog", "Please try again")
      );

  return (
    <div className="CatalogEnable">
      <div className="CatalogEnable__title">{name}</div>
      <ToggleSwitch
        key={gameCode}
        label={name}
        trueLabel="enabled"
        falseLabel="disabled"
        currentValue={isEnabled}
        onChange={handleChange}
        disabled={isMutating}
      />
    </div>
  );
}

export default CatalogEnable;
