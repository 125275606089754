import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import ConfirmationModal from "../generic/ConfirmationModal";
import { ItemList } from "../../pos/ItemStore";

interface CloseTillWarningProps {
  posStore?: ItemList;
}
@inject("posStore")
@observer
class CloseTillWarning extends Component<CloseTillWarningProps> {
  render() {
    const store = this.props.posStore;
    const currentTill =
      store.tillList.find((till) => till.id == store.tillId)?.name || "till";

    return (
      <ConfirmationModal
        title={`Close ${currentTill}`}
        cancelAction={store.closeTillWarning}
        confirmWord="OK"
        confirmAction={store.closeFloat}
      >
        <p>Are you sure you want to close this till?</p>
      </ConfirmationModal>
    );
  }
}

export default CloseTillWarning;
