export const transformPricingDataForVariantRow = (
  variant: ProductPricing,
  formatCurrency
) => {
  const priceMatchesExpectation =
    variant.currentPrice === variant.targetPrice?.expectedPrice;
  return {
    variant: variant.variantName,
    currentPrice: formatCurrency(variant.currentPrice),
    sourcePrice: `${formatCurrency(variant.sourcePrice.price)} - ${
      variant.sourcePrice.source
    }`,
    targetPrice: formatCurrency(variant.targetPrice.expectedPrice),
    ruleApplied:
      variant.targetPrice.ruleApplied.game +
      (variant.targetPrice.ruleApplied.setName
        ? " - " + variant.targetPrice.ruleApplied.setName
        : ""),
    synced: priceMatchesExpectation ? (
      <>
        Up to date <i className="fas fa-check"></i>
      </>
    ) : (
      <>
        Not up to date <i className="fas fa-exclamation-triangle" />
      </>
    ),
  };
};
