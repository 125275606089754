import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Table, Column, Cell } from "rsuite-table";
import "rsuite-table/dist/css/rsuite-table.css";
import "../../../css/rstable-overwrites.scss";
import CustomHeaderCell from "../../components/reports/CustomHeader";
import { POSMenuStructure } from "../../menuStructures";
import { API_STATE } from "../../constants/api";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";
import { Pagination } from "../../components/reports";
import { CurrencyRenderer } from "../../components/reports/CellTypes";
import TimeUtils from "../../utils/TimeUtils";
import { rowPerPageOptions } from "../../constants/reports";
import { useReportsDetails } from "../../hooks/reportsHooks";
import { useStoreDetails } from "../../hooks/storeHooks";
import { useShowError } from "../../hooks/errorHooks";
import useTitle from "../../hooks/useTitle";
import SetActiveMenu from "../../menuStructures/SetActiveMenu";
import { useFetchEndOfDayReports } from "../../hooks/reports/useFetchEndOfDayReports";

function EODReports() {
  const {
    apiState,
    sortColumn,
    currentPage,
    rowsPerPage,
    totalRowCount,
    setCurrentPage,
    setTableRowsPerPage,
    reset,
    setApiState,
    setSourceDataDetails,
    setSortingColumn,
    computedTableData,
  } = useReportsDetails();
  const { currencyCode } = useStoreDetails();
  const [availableTenders, setAvailableTenders] = useState([]);
  const showError = useShowError();
  useTitle("End of Day Reports");

  const {
    data: sourceData,
    error,
    isFetching,
  } = useFetchEndOfDayReports({
    startDate: "2022-01-01",
    endDate: "2099-12-31",
  });

  useEffect(() => {
    reset();
    setSortingColumn("openedDate", "desc");
  }, []);

  useEffect(() => {
    if (isFetching) {
      setApiState(API_STATE.LOADING);
    } else if (error) {
      showError(
        error as any,
        "Failed to load report",
        "There was an error retrieving your end of day report. Please refresh and try again"
      );
      setApiState(API_STATE.ERROR);
    } else if (sourceData) {
      setSourceDataDetails(sourceData?.report);
      setAvailableTenders(sourceData?.tenders);
      setApiState(API_STATE.SUCCESS);
    }
  }, [isFetching, sourceData, error]);

  const data = computedTableData();

  return (
    <>
      <SetActiveMenu menuStructure={POSMenuStructure} />
      <SectionHeaderLayout title="End of Day Reports" />
      <div className="data-table">
        <div className="table-container">
          <Table
            data={data}
            headerHeight={35}
            loading={apiState === API_STATE.LOADING}
            sortColumn={sortColumn.key}
            sortType={sortColumn.order}
            onSortColumn={setSortingColumn}
            renderEmpty={() => <div>No data to display</div>}
            fillHeight
          >
            <Column key="col0" width={200} sortable resizable>
              <CustomHeaderCell title="Name" />
              <Cell dataKey="name" />
            </Column>
            <Column key="col1" width={200} sortable resizable>
              <CustomHeaderCell title="Opening Time" />
              <Cell dataKey="openedDate">
                {(rowData) =>
                  rowData?.openedDate
                    ? TimeUtils.convertDateToHumanReadable(rowData?.openedDate)
                    : "-"
                }
              </Cell>
            </Column>
            <Column key="col2" width={200} sortable resizable>
              <CustomHeaderCell title="Closing Time" />
              <Cell dataKey="closedDate">
                {(rowData) =>
                  rowData?.closedDate
                    ? TimeUtils.convertDateToHumanReadable(rowData?.closedDate)
                    : "-"
                }
              </Cell>
            </Column>
            {availableTenders?.map((tender) => (
              <>
                {tender !== "Store_Credit" && (
                  <Column
                    key={`col_${tender}_opening`}
                    width={150}
                    align="right"
                    sortable
                    resizable
                  >
                    <CustomHeaderCell title={`${tender} Opening`} />
                    <Cell dataKey={`${tender}.openingAmount`}>
                      {(rowData) =>
                        CurrencyRenderer({
                          cellData: rowData?.[tender]?.openingAmount,
                          currency: currencyCode,
                        })
                      }
                    </Cell>
                  </Column>
                )}
                <Column
                  key={`col_${tender}_current`}
                  width={150}
                  align="right"
                  sortable
                  resizable
                >
                  <CustomHeaderCell title={`${tender} Current`} />
                  <Cell dataKey={`${tender}.currentAmount`}>
                    {(rowData) =>
                      CurrencyRenderer({
                        cellData: rowData?.[tender]?.currentAmount,
                        currency: currencyCode,
                      })
                    }
                  </Cell>
                </Column>
                {tender !== "Store_Credit" && (
                  <>
                    <Column
                      key={`col_${tender}_diff`}
                      width={150}
                      align="right"
                      sortable
                      resizable
                    >
                      <CustomHeaderCell title={`${tender} Diff`} />
                      <Cell dataKey={`${tender}.difference`}>
                        {(rowData) =>
                          CurrencyRenderer({
                            cellData: rowData?.[tender]?.difference,
                            currency: currencyCode,
                          })
                        }
                      </Cell>
                    </Column>
                    <Column
                      key={`col_${tender}_closing`}
                      width={150}
                      align="right"
                      sortable
                      resizable
                    >
                      <CustomHeaderCell title={`${tender} Closing`} />
                      <Cell dataKey={`${tender}.closingAmount`}>
                        {(rowData) =>
                          CurrencyRenderer({
                            cellData: rowData?.[tender]?.closingAmount,
                            currency: currencyCode,
                          })
                        }
                      </Cell>
                    </Column>
                  </>
                )}
              </>
            ))}
          </Table>
        </div>
        <Pagination
          apiState={apiState}
          currentPage={currentPage}
          totalRowCount={totalRowCount}
          rowsPerPage={rowsPerPage}
          rowPerPageOptions={rowPerPageOptions}
          setCurrentPage={setCurrentPage}
          setRowsPerPage={setTableRowsPerPage}
        />
      </div>
    </>
  );
}

EODReports.propTypes = { store: PropTypes.object };

export default EODReports;
