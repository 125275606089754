import React, { useEffect, useState } from "react";
import JobQueueList, {
  QueuesMap,
} from "../../components/queuedJobs/JobQueueList";
import { useFormatCurrency } from "../../hooks/storeHooks";
import { useFetchProductQueuedJobs } from "../../hooks/jobQueueHooks";
import useTitle from "../../hooks/useTitle";
import { Paging } from "../../components";
import ProductsJobQueueColumns from "../../components/product/ProductsJobQueueColumns";
import ButtonComponent from "../../components/generic/ButtonComponent";
import JobQueueFilterTabs from "../../components/queuedJobs/JobQueueFilterTabs";
import { ProductQueuedJob } from "../../types/queuedJobs";

interface ProductsJobQueueProps {
  inventoryManagementEnabled: boolean;
}

const productQueuesMap: QueuesMap = {
  bulkUpdate: { title: "Bulk queue", type: "bulkUpdate", limit: 10 },
  batchQueryUpdate: {
    title: "Batch query queue",
    type: "batchQueryUpdate",
    limit: 15,
  },
  priceUpdate: { title: "Price queue", type: "priceUpdate", limit: 25 },
  stockUpdate: { title: "Stock queue", type: "stockUpdate", limit: 25 },
  variantUpdate: { title: "Other queue", type: "variantUpdate", limit: 100 },
};

const ProductsJobQueue = (props: ProductsJobQueueProps) => {
  const { inventoryManagementEnabled } = props;
  useTitle("Product Queued jobs | BinderPOS");
  const formatCurrency = useFormatCurrency();
  const [queuedJobs, setQueuedJobs] = useState<ProductQueuedJob[]>();
  const [params, setParams] = useState({
    offset: 0,
    limit: 10,
    type: "bulkUpdate",
  });

  const { data, isFetching } = useFetchProductQueuedJobs(params);

  useEffect(() => {
    setQueuedJobs(data);
  }, [data]);

  if (!inventoryManagementEnabled) {
    return (
      <div>
        <p>Please contact BinderPOS to enable this screen.</p>
      </div>
    );
  }

  const updateQueuedJobStatus = (jobData) => {
    const jobIndex = queuedJobs?.findIndex(
      (queuedJob) => queuedJob?.id === jobData?.id
    );
    setQueuedJobs([
      ...queuedJobs.slice(0, jobIndex),
      jobData,
      ...queuedJobs.slice(jobIndex + 1, queuedJobs?.length),
    ]);
  };

  const prevPage = () => {
    const newOffset = params.offset - params.limit;
    setParams({
      ...params,
      offset: newOffset >= 0 ? newOffset : 0,
    });
  };

  const nextPage = () => {
    setParams({
      ...params,
      offset: params.offset + params.limit,
    });
  };

  const updateSelectedType = (selectedType) =>
    setParams({
      type: selectedType,
      limit: productQueuesMap[selectedType].limit,
      offset: 0,
    });

  return (
    <>
      <JobQueueList
        isLoading={isFetching}
        columns={ProductsJobQueueColumns(updateQueuedJobStatus, formatCurrency)}
        queuedJobs={queuedJobs}
      >
        {{
          header: "My Products > My queued jobs",
          submenu: (
            <JobQueueFilterTabs
              filterOptions={Object.values(productQueuesMap)}
              selectedTab={params.type}
              updateSelectedTab={updateSelectedType}
            />
          ),
          backButton: (
            <ButtonComponent
              primary
              isLink
              to={"/products"}
              iconPosition="left"
              icon="fas fa-caret-left"
            >
              Back to My Products
            </ButtonComponent>
          ),
          paging: (
            <Paging
              pageOffset={params.offset}
              currentPageItemCount={queuedJobs?.length || 0}
              maxItemsPerPage={productQueuesMap[params.type].limit}
              getPrevPage={prevPage}
              getNextPage={nextPage}
            />
          ),
        }}
      </JobQueueList>
    </>
  );
};

export default ProductsJobQueue;
