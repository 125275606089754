import React from "react";
import { showMenuItem } from "../../helpers/navHelpers";
import { useLegacyMainMenu } from "../../hooks/menuHooks";
import MobileNavItem from "./MobileNavItem";
import "./MobileMenu.scss";
import { signOut } from "../../services/firebase";
import { MenuStructure } from "../../types/nav";
import { useFeatureFlag } from "../../hooks/globalSettingsHooks";
import { ADABOT_BINDER_PORTAL } from "../../constants/featureFlags";
import { SUPPORT_REDIRECT_URL } from "../../constants/support";

interface MobileMenuProps {
  mobileMenuOpen: boolean;
  screenSettings: ScreenSettings;
  handleClick: (menu: MenuStructure | null | undefined) => void;
  setMobileMenuOpen: (open: boolean) => void;
  handleLogout: () => void;
}

function MobileMenu(props: MobileMenuProps) {
  const {
    mobileMenuOpen,
    screenSettings,
    handleClick,
    setMobileMenuOpen,
    handleLogout,
  } = props;
  const menuItems = useLegacyMainMenu();
  const adabotBinderPortalEnabled = useFeatureFlag(ADABOT_BINDER_PORTAL);
  const supportButtonAction = () => {
    if (adabotBinderPortalEnabled) {
      window.adaEmbed.toggle();
      setMobileMenuOpen(false);
      return;
    }
    window.open(SUPPORT_REDIRECT_URL, "_blank");
  };

  const menuItemClickHandler = (menuStructure?: MenuStructure) => {
    setMobileMenuOpen(false);
    handleClick(menuStructure);
  };

  return (
    <div className="MainMobileMenu">
      <input
        className="MainMobileMenu__checkbox"
        type="checkbox"
        checked={mobileMenuOpen}
        onChange={() => setMobileMenuOpen(!mobileMenuOpen)}
        title="Toggle mobile menu"
        aria-label="mobile menu toggle"
      />
      <div className="MainMobileMenu__hamburger">
        <div />
      </div>
      <div className="MainMobileMenu__menu">
        <div>
          <div>
            <ul>
              {menuItems.map((menuItem) => {
                if (!showMenuItem(screenSettings, menuItem.screenSetting)) {
                  return null;
                }
                return (
                  <MobileNavItem
                    key={menuItem.target}
                    handleClick={menuItemClickHandler}
                    menuLink={menuItem.target}
                    menuIcon={menuItem.iconClass}
                    menuHeaderText={menuItem.title}
                    menuStructure={menuItem.menuStructure}
                  />
                );
              })}
            </ul>
            <div className="MainMobileMenu__footer">
              <button
                onClick={() => {
                  signOut();
                  handleLogout();
                }}
                className="MainMobileMenu__footer-item"
              >
                <i className="fad fa-sign-out-alt" /> Log Out
              </button>
              <span>|</span>
              <button
                onClick={supportButtonAction}
                className="MainMobileMenu__footer-item"
              >
                <i className="fas fa-user-headset" /> Support
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileMenu;
