const getFirebaseConfig = () => {
  const environment = process?.env?.REACT_APP_ENV;
  if (["production", "beta"].includes(environment)) {
    return {
      apiKey: "AIzaSyDYmFPRKRkVFM9SQvbtCAT8oWKh4RhBGXg",
      authDomain: "hobby-pos.firebaseapp.com",
      projectId: "hobby-pos",
      appId: "1:865314745987:web:b8730646a46a283690b5b6",
    };
  }
  if (environment === "staging") {
    return {
      apiKey: "AIzaSyB3bAHolLjUADKG8WrojoRR8RvxeysKsrM",
      authDomain: "binderpos-staging.firebaseapp.com",
      projectId: "binderpos-staging",
      appId: "1:545612799601:web:f56dc6fa825c458a25c5d0",
    };
  }
  return {
    apiKey: "AIzaSyA7h34WfYXmkN4lu_kWhqhYa_6LYwXu2oQ",
    authDomain: "binderpos-dev.firebaseapp.com",
    projectId: "binderpos-dev",
    appId: "1:731305984361:web:aef30f616f2019fcd83df8",
  };
};

export { getFirebaseConfig };
