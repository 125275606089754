import React, { useState } from "react";
import { segmentAnalytics } from "../../components/services/Analytics";
import {
  ADD_CART_NOTE_FIELD_CLICKED,
  DELETE_CART_NOTE_FIELD_CLICKED,
} from "../../constants/eventsTracked";
import ButtonComponent from "../../components/generic/ButtonComponent";
import IconButton from "../../components/generic/IconButton";
import Loader from "../../components/generic/Loader";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";
import { notify } from "../../helpers/notificationHelpers";
import { useFetchSetting, useUpdateSetting } from "../../hooks/settingHooks";
import { useShowError } from "../../hooks/errorHooks";
import SetActiveMenu from "../../menuStructures/SetActiveMenu";
import POSMenuStructure from "../../menuStructures/POSMenuStructure";
import "./NotesSettings.scss";

const SETTING_NAME = "customFields";

function NotesSettings() {
  const [newField, setNewField] = useState("");
  const { data: customFieldsJSON, isLoading } = useFetchSetting(SETTING_NAME);
  const { mutateAsync: updateSetting, isMutating: isSaving } =
    useUpdateSetting();
  const showError = useShowError();

  let customFields: Array<string> = [];
  try {
    customFields = JSON.parse(customFieldsJSON) ?? [];
  } catch (error) {
    customFields = [];
  }

  const handleAddField = async () => {
    if (!newField) return;
    if (customFields.includes(newField)) return;
    const newFields = customFields ? [...customFields, newField] : [newField];
    return saveChanges(newFields);
  };

  const handleRemoveField = (fieldToRemove: string) => {
    const newFields = customFields.filter((field) => field !== fieldToRemove);
    return saveChanges(newFields);
  };

  const saveChanges = (newFields: Array<string>) =>
    updateSetting({
      settingName: SETTING_NAME,
      settingValue: JSON.stringify(newFields),
    })
      .then(() => {
        notify.info("Settings updated");
        setNewField("");
      })
      .catch((error) => showError(error));

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="NotesSettings">
      <SetActiveMenu menuStructure={POSMenuStructure} />
      <SectionHeaderLayout title="Customer Notes Fields" />
      <p>Configure what information to collect about customers</p>
      <ul className="NotesSettings__fields">
        {customFields.map((field: string) => (
          <li className="NotesSettings__field" key={field}>
            {field}
            <IconButton
              icon="fa fa-trash"
              danger
              aria-label={`Delete ${field} field`}
              onClick={async () => {
                segmentAnalytics.track(DELETE_CART_NOTE_FIELD_CLICKED);
                handleRemoveField(field);
              }}
            />
          </li>
        ))}
      </ul>
      <div className="NotesSettings__newField">
        <label className="label" htmlFor="new-note">
          Add new note field
        </label>
        <input
          id="new-note"
          className="input"
          value={newField}
          onChange={(e) => setNewField(e.target.value)}
        />
        <ButtonComponent
          primary
          disabled={isSaving}
          onClick={() => {
            segmentAnalytics.track(ADD_CART_NOTE_FIELD_CLICKED);
            handleAddField();
          }}
        >
          Add
        </ButtonComponent>
      </div>
    </div>
  );
}

export default NotesSettings;
