import { BaseLineItem } from "../../types/pos";
import { calculateTotal } from "./cart";
import { parseShopifyTagCommand } from "./pos";
import { roundCents } from "../currencyHelpers";

interface AutomaticDiscount {
  type: "percentage" | "amount" | null;
  amount: string | null;
}

export function getAutomaticDiscounts(tags: string): AutomaticDiscount {
  const discount = {
    type: null,
    amount: null,
  };
  const discountCommands = parseShopifyTagCommand(tags).find(
    (tc) => tc.command === "AD"
  );
  if (!discountCommands) {
    return discount;
  }
  const [type, amount] = discountCommands.args;
  if (isNaN(parseFloat(amount))) {
    return discount;
  }
  if (type !== "percentage" && type !== "amount") {
    return discount;
  }
  return {
    type,
    amount,
  };
}

export function calculateDiscountValue(item: BaseLineItem) {
  if (!item.discountType || !item.discountAmount) {
    return 0;
  }
  if (item.discountType === "percentage") {
    // VERIFY 1.1: Buy Mode makes this calculation inconsistent
    return -(item.qty * item.actualPrice) * (item.discountAmount / 100);
  }
  return -item.discountAmount;
}

export function calculateDiscountAmount(itemList) {
  const tempTotal = calculateTotal(itemList);
  if (itemList.globalDiscount?.type === "percentage") {
    return parseFloat(
      roundCents(
        -tempTotal * (parseFloat(itemList.globalDiscount?.amount) / 100)
      )
    );
  } else if (itemList.globalDiscount?.type === "amount") {
    return -itemList.globalDiscount?.amount;
  }
  return 0;
}
