import React from "react";
import ButtonComponent from "../generic/ButtonComponent";
import { fetchCsvDownloadUrl } from "../../api/rest/products";
import "./PreviousImportsDownloadButtons.scss";

interface PreviousImportsDownloadButtonsProps {
  id: string;
  status: string;
}

function PreviousImportsDownloadButtons(
  props: PreviousImportsDownloadButtonsProps
) {
  const { id, status } = props;

  const downloadFile = async (
    fileId: string,
    kind: "csvOriginalLink" | "csvStatusLink"
  ) => {
    const response = await fetchCsvDownloadUrl(Number(fileId));
    if (response?.[kind]) {
      window.open(response[kind]);
    }
  };

  return (
    <div className="PreviousImportsDownloadButtons">
      <ButtonComponent
        onClick={() => downloadFile(id, "csvOriginalLink")}
        icon="far fa-file-download"
      >
        Original file
      </ButtonComponent>

      {["Completed", "Failed"].includes(status) ? (
        <>
          <ButtonComponent
            onClick={() => downloadFile(id, "csvStatusLink")}
            icon="far fa-download"
          >
            Status file
          </ButtonComponent>{" "}
        </>
      ) : null}
    </div>
  );
}

export default PreviousImportsDownloadButtons;
