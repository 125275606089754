import { useEffect, useState } from "react";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { SHOPIFY_BILLING_ENABLED } from "../../constants/featureFlags";
import { SHOW_BILLING_ALERT_ON_PAGES } from "../../constants/billing";
import { SHOPIFY_SUBSCRIPTION_FORCED_DATE } from "../../constants/settings";
import { isKioskMode } from "../../helpers/posModeHelpers";
import { useFeatureFlag } from "../../hooks/globalSettingsHooks";
import { useGetStoreInfo } from "../../hooks/settingHooks";
import {
  useLocalSetting,
  useLocalSettingUpdate,
} from "../../hooks/localSettingsHooks";
import { SubscriptionStatus } from "../../types/subscriptions";
import ShopifySubscriptionAlert from "../shopifySubscription/ShopifySubscriptionAlert";

interface ShopifySubscriptionCheckProps {
  children: React.ReactNode;
}

function ShopifySubscriptionCheck({ children }: ShopifySubscriptionCheckProps) {
  const isShopifyBillingFlagEnabled = useFeatureFlag(SHOPIFY_BILLING_ENABLED);
  const localSettingUpdate = useLocalSettingUpdate();
  const [cannotDismissAlertAfterDate, setCannotDismissAlertAfterDate] =
    useState<string | undefined>(
      useLocalSetting(SHOPIFY_SUBSCRIPTION_FORCED_DATE)
    );
  const { data: storeInfo } = useGetStoreInfo();
  const { pathname } = useLocation();
  const subscriptionIsPending =
    storeInfo?.shopify?.subscription?.status === SubscriptionStatus.Pending;

  useEffect(() => {
    if (subscriptionIsPending && !cannotDismissAlertAfterDate) {
      const FIVE_DAYS_FROM_NOW = moment().add(5, "days").toISOString();
      localSettingUpdate(SHOPIFY_SUBSCRIPTION_FORCED_DATE, FIVE_DAYS_FROM_NOW);
      setCannotDismissAlertAfterDate(FIVE_DAYS_FROM_NOW);
    }
  }, [subscriptionIsPending]);

  const canShowAlertOnPage = SHOW_BILLING_ALERT_ON_PAGES.includes(
    pathname.split("/")[1]
  );

  if (
    !isShopifyBillingFlagEnabled ||
    !subscriptionIsPending ||
    !canShowAlertOnPage ||
    isKioskMode()
  ) {
    return <>{children}</>;
  }

  const alertIsDismissible = moment(cannotDismissAlertAfterDate) > moment();

  if (alertIsDismissible) {
    return (
      <>
        <ShopifySubscriptionAlert
          confirmationUrl={storeInfo?.shopify?.subscription?.confirmationUrl}
          alertIsDismissible={true}
        />
        {children}
      </>
    );
  }

  return (
    <ShopifySubscriptionAlert
      confirmationUrl={storeInfo?.shopify?.subscription.confirmationUrl}
      alertIsDismissible={false}
    />
  );
}

export default ShopifySubscriptionCheck;
