import React, { useEffect, useRef, useState } from "react";
import { ShippingService, ShippingServiceProvider } from "../../api/rest/ebay";
import ButtonComponent from "../../components/generic/ButtonComponent";
import Modal from "../generic/Modal";
import EbayShippingServiceSettings from "./EbayShippingServiceSettings";
import "./EbayShippingServices.scss";

const sortShipping = (a: ShippingService, b: ShippingService) =>
  // @ts-ignore
  a.sortOrder - b.sortOrder;

const moveUp = (
  sortIndex: number,
  settings: ShippingService[],
  updateShippingServices: Function
) => {
  const newSettings = [...settings];
  const index = settings.findIndex(
    (setting) => setting.sortOrder === sortIndex
  );
  newSettings[index].sortOrder = sortIndex - 1;
  newSettings[index - 1].sortOrder = sortIndex;
  updateShippingServices(newSettings);
};

const moveDown = (
  sortIndex: number,
  settings: ShippingService[],
  updateShippingServices: Function
) => {
  const newSettings = [...settings];
  const index = settings.findIndex(
    (setting) => setting.sortOrder === sortIndex
  );
  newSettings[index].sortOrder = sortIndex + 1;
  newSettings[index + 1].sortOrder = sortIndex;
  updateShippingServices(newSettings);
};

export type CostType = "CALCULATED" | "FLAT_RATE" | "NOT_SPECIFIED";

interface EbayShippingServicesProps {
  settings: ShippingService[];
  shippingServiceProviders: ShippingServiceProvider[];
  international: boolean;
  costType: CostType;
  includeRegions: EbayParam[];
  excludeRegions: EbayParam[];
  updateShippingServices: Function;
}

function EbayShippingServices(props: EbayShippingServicesProps) {
  const {
    settings,
    shippingServiceProviders,
    international,
    costType,
    includeRegions,
    excludeRegions,
    updateShippingServices,
  } = props;
  const viewRef = useRef<HTMLElement>();
  const modalRef = useRef<HTMLDivElement>();

  const [updatingShippingOption, setUpdatingShippingOption] =
    useState<number>();
  const [deletingShippingOption, setDeletingShippingOption] =
    useState<number>();

  const maxShippingServices = international ? 5 : 4;

  const handleRemoveShippingService = (sortOrder: number) => {
    updateShippingServices(
      settings.filter((setting) => setting.sortOrder !== sortOrder)
    );
    setDeletingShippingOption(undefined);
  };

  useEffect(() => {
    if (updatingShippingOption || deletingShippingOption) {
      modalRef.current?.focus();
    } else {
      viewRef.current?.focus();
    }
  }, [updatingShippingOption, deletingShippingOption]);

  return (
    <div className="ebay-shipping-services">
      {settings?.length
        ? settings
            .sort(sortShipping)
            .map((shippingService: ShippingService, row: number) => (
              <div
                key={
                  shippingService.ebayTypeShippingServiceId
                    ?.shippingServiceId || row
                }
                className="ebay-shipping-services__row"
              >
                <span className="ebay-shipping-services__title">
                  {shippingService.shippingServiceName}
                </span>
                <button
                  aria-label="Move up"
                  type="button"
                  className="ebay-shipping-services__change-order"
                  disabled={shippingService.sortOrder === 1}
                  onClick={() =>
                    moveUp(row + 1, settings, updateShippingServices)
                  }
                >
                  <i className="fas fa-chevron-up" />
                </button>
                <button
                  aria-label="Move down"
                  type="button"
                  className="ebay-shipping-services__change-order"
                  disabled={shippingService.sortOrder === settings.length}
                  onClick={() =>
                    moveDown(row + 1, settings, updateShippingServices)
                  }
                >
                  <i className="fas fa-chevron-down" />
                </button>
                <ButtonComponent
                  primary
                  onClick={(event) => {
                    viewRef.current = event.currentTarget;
                    setUpdatingShippingOption(row + 1);
                  }}
                >
                  Edit
                </ButtonComponent>
                <ButtonComponent
                  onClick={(event) => {
                    viewRef.current = event.currentTarget;
                    setDeletingShippingOption(row + 1);
                  }}
                >
                  Remove
                </ButtonComponent>
              </div>
            ))
        : null}
      {!settings || settings.length < maxShippingServices ? (
        <div className="ebay-shipping-services__add-button">
          <ButtonComponent
            primary
            aria-label="Add new shipping service"
            onClick={(event) => {
              viewRef.current = event.currentTarget;
              setUpdatingShippingOption(-1);
            }}
          >
            Add New
          </ButtonComponent>
        </div>
      ) : null}
      {updatingShippingOption ? (
        <EbayShippingServiceSettings
          isFirst={!settings || settings.length < 2}
          setting={settings?.find(
            (setting) => setting.sortOrder === updatingShippingOption
          )}
          costType={costType}
          updateSetting={(values: ShippingService) => {
            const newSettings = settings ? [...settings] : [];
            const index = newSettings?.findIndex(
              (setting) => setting.sortOrder === updatingShippingOption
            );
            if (index > -1) {
              newSettings[index] = values;
            } else {
              const newValues = {
                ...values,
                sortOrder: newSettings.length + 1,
              };
              newSettings.push(newValues);
            }
            updateShippingServices(newSettings);
            setUpdatingShippingOption(undefined);
          }}
          cancel={() => setUpdatingShippingOption(undefined)}
          shippingServiceProviders={shippingServiceProviders}
          includeRegions={includeRegions}
          excludeRegions={excludeRegions}
          ref={modalRef}
        />
      ) : null}
      {deletingShippingOption ? (
        <Modal
          onClose={() => setDeletingShippingOption(undefined)}
          ref={modalRef}
        >
          <Modal.Header>Remove shipping service</Modal.Header>
          <Modal.Content>
            Are you sure you want to delete the shipping service{" "}
            {
              settings.find(
                (setting) => setting.sortOrder === deletingShippingOption
              )?.shippingServiceName
            }
            ?
          </Modal.Content>
          <Modal.Actions>
            <ButtonComponent
              onClick={() => setDeletingShippingOption(undefined)}
            >
              Cancel
            </ButtonComponent>
            <ButtonComponent
              primary
              onClick={() =>
                handleRemoveShippingService(deletingShippingOption)
              }
            >
              Ok
            </ButtonComponent>
          </Modal.Actions>
        </Modal>
      ) : null}
    </div>
  );
}

EbayShippingServices.defaultProps = {
  international: false,
};

export default EbayShippingServices;
