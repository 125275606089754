import React, { useRef } from "react";
import { ErrorMessage, Field, getIn, FormikProps } from "formik";
import Tooltip from "../generic/Tooltip";

interface InputProps {
  field: Partial<HTMLInputElement>;
  form: FormikProps<{}>;
  editing?: boolean;
  label?: string;
  requiredLabel?: boolean;
  optionalLabel?: boolean;
  tooltipContent?: string | JSX.Element;
  type?: string;
  helperText?: string;
  adornment?: string;
  adornmentPosition?: string;
}

const getInputClass = (editing: boolean, hasError: any) => {
  if (editing) {
    if (hasError) {
      return "input input--error";
    }
    return "input";
  }

  return "input input--display";
};

function Input(props: InputProps) {
  const { field, form, ...rest } = props;
  const { name, ...inputProps } = field;
  const {
    label,
    requiredLabel,
    optionalLabel,
    type,
    helperText,
    editing = true,
    tooltipContent,
    adornment,
    adornmentPosition = "end",
    ...otherProps
  } = rest;
  const { errors } = form;
  const inputEl = useRef<HTMLDivElement>(null);

  const hasError = getIn(errors, name);

  return (
    <>
      {label ? (
        <label className="label" htmlFor={name}>
          {label}
          {requiredLabel ? <span className="label--required" /> : null}
          {optionalLabel ? (
            <span className="label--optional">(opt.)</span>
          ) : null}
          {tooltipContent && (
            <Tooltip>
              <>{tooltipContent}</>
            </Tooltip>
          )}
        </label>
      ) : null}
      <div className="input-group">
        {!adornment ? (
          <Field
            id={name}
            name={name}
            className={getInputClass(editing, hasError)}
            type={type}
            disabled={!editing}
            {...inputProps}
            {...otherProps}
          />
        ) : (
          <div
            className={getInputClass(editing, hasError)}
            onClick={() => {
              inputEl.current?.focus();
            }}
          >
            <div
              className={`input__adornment-wrapper ${
                editing && adornmentPosition === "end"
                  ? "input__adornment-wrapper--editing"
                  : ""
              }`}
            >
              {field.value !== "" || editing ? (
                <div
                  className={
                    adornmentPosition === "end"
                      ? "input--adornment-end"
                      : "input--adornment-start"
                  }
                >
                  {adornment}
                </div>
              ) : null}
              {editing ? (
                <Field
                  id={name}
                  name={name}
                  className="input--adornment-field"
                  type={type}
                  disabled={!editing}
                  innerRef={inputEl}
                  {...inputProps}
                  {...otherProps}
                />
              ) : (
                <span>{field.value ? field.value : "--"}</span>
              )}
            </div>
          </div>
        )}
        <ErrorMessage name={name} className="input-error">
          {(message) => (
            <div className="input-error">
              <i className="input-error-icon far fa-exclamation-circle" />{" "}
              {message}
            </div>
          )}
        </ErrorMessage>
        {!hasError && !!helperText && editing ? (
          <div className="input__helperText">{helperText}</div>
        ) : null}
      </div>
    </>
  );
}

export default Input;
