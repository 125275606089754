import React from "react";
import { SubscriptionStatus } from "../types/subscriptions";

// TODO: confirm with CX which statuses should result in a message
export const statusMessages = (returnUrl?: string) => ({
  // [SubscriptionStatus.Canceled]: returnUrl?.length
  //   ? `Your Shopify subscription was cancelled. Please proceed to <a href="https://${returnUrl}/admin" rel="noopener" target="_blank">MyShopify</a> for more details.`
  //   : "Your Shopify subscription was cancelled.",
  // [SubscriptionStatus.Declined]: returnUrl?.length ? (
  //   <>
  //     Your Shopify subscription was declined. Please proceed to{" "}
  //     <a href={`https://${returnUrl}/admin`} rel="noopener" target="_blank">
  //       MyShopify
  //     </a>{" "}
  //     for more details.
  //   </>
  // ) : (
  //   <>Your Shopify subscription was declined.</>
  // ),
  // [SubscriptionStatus.Expired]: returnUrl?.length ? (
  //   <>
  //     Your Shopify subscription has expired. Please proceed to{" "}
  //     <a href={`https://${returnUrl}/admin`} rel="noopener" target="_blank">
  //       MyShopify
  //     </a>{" "}
  //     for more details.
  //   </>
  // ) : (
  //   <>Your Shopify subscription has expired.</>
  // ),
  // [SubscriptionStatus.Frozen]: returnUrl?.length ? (
  //   <>
  //     Your Shopify subscription is frozen. Please proceed to{" "}
  //     <a href={`https://${returnUrl}/admin`} rel="noopener" target="_blank">
  //       MyShopify
  //     </a>{" "}
  //     for more details.
  //   </>
  // ) : (
  //   <>Your Shopify subscription is frozen.</>
  // ),
  [SubscriptionStatus.Pending]: returnUrl?.length ? (
    <>
      Your Shopify subscription is pending confirmation. Please proceed to{" "}
      <a href={returnUrl} rel="noopener" target="_blank">
        MyShopify
      </a>{" "}
      to approve it.
    </>
  ) : (
    <>Your Shopify subscription is pending confirmation.</>
  ),
});

export const shopifySubscriptionStatusMessages = (
  status?: SubscriptionStatus,
  returnUrl?: string
) => {
  if (!status) return;
  const message = statusMessages(returnUrl)[status.toUpperCase()];
  if (!message) return;
  return (
    <span
      className="ShopifySubscriptionStatusNotification__toast-message"
      data-testid="toastMessage"
    >
      {message}
    </span>
  );
};
