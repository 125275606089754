import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "mobx-react";
import { SplitFactory } from "@splitsoftware/splitio-react";
import { SPLIT_CONFIG } from "./js/constants/featureFlags";
import { ItemList } from "./js/pos/ItemStore";
import "prevent-pull-refresh";
import Store from "./js/store";
import App from "./js/app";

const posStore = new ItemList();
ReactDOM.render(
  <SplitFactory config={SPLIT_CONFIG}>
    <Provider store={Store} posStore={posStore}>
      <App />
    </Provider>
  </SplitFactory>,
  document.getElementById("root")
);
