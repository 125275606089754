export function replaceNullWithDefault<T extends Object, V>(
  fields: T,
  defaultValues: V
): T | V {
  const replaced = {
    ...fields,
  };
  Object.keys(defaultValues).forEach((key) => {
    if (!(key in replaced)) {
      replaced[key] = defaultValues[key];
      return;
    }
    if (replaced[key] !== null && typeof replaced[key] === "object") {
      replaced[key] = replaceNullWithDefault(replaced[key], defaultValues[key]);
      return;
    }
    if (replaced[key] === null) {
      replaced[key] = defaultValues[key];
    }
  });
  return replaced;
}

export function filterEmptyValues(fields: Record<string, any>) {
  if (!fields) return {};
  const replaced = {};
  Object.keys(fields).forEach((key) => {
    if (typeof fields[key] !== "string" || fields[key]?.trim() !== "") {
      replaced[key] = fields[key];
    }
  });
  return replaced;
}
