import React, { useState } from "react";
import { isNumber } from "lodash";
import { Table, Column, Cell } from "rsuite-table";
import "rsuite-table/dist/css/rsuite-table.css";
import "../../../css/rstable-overwrites.scss";
import CustomHeaderCell from "../../components/reports/CustomHeader";
import { DEFAULT_SUBMITTED_CARTS_PER_PAGE } from "../../constants/pos";
import { useDebounce } from "../../hooks/inputHooks";
import {
  OrderHistoryParams,
  useFetchAllCarts,
} from "../../hooks/useFetchAllCarts";
import { ReturnCartPaymentData } from "../../types/pos";
import { TimeUtils } from "../../utils";
import Paging from "../generic/Paging";
import { DateRangeSelect } from "../reports";
import { NumberInputFilter, TextInputFilter } from "../reports/CellTypes";
import ReturnCartButton from "./ReturnCartButton";

const DEBOUNCE_TIMEOUT = 250;

interface OrderHistoryTableProps {
  setOrderNumber: (orderNumber: number) => void;
  cartFromResponse: (response: any) => void;
  setReturnCartIntegratedPayment: (payment: ReturnCartPaymentData[]) => void;
  formatCurrency: (value: string | number) => string;
  closeModal: () => void;
}

function OrderHistoryTable({
  setOrderNumber,
  cartFromResponse,
  setReturnCartIntegratedPayment,
  formatCurrency,
  closeModal,
}: OrderHistoryTableProps) {
  const [cartParams, setCartParams] = useState<OrderHistoryParams>({
    oldestFirst: false,
    offset: 0,
  });
  const debouncedCartParams = useDebounce(cartParams, DEBOUNCE_TIMEOUT);

  const { data: carts, isFetching } = useFetchAllCarts(debouncedCartParams);

  const prevPage = () => {
    setCartParams((prev) => {
      let newOffset = prev.offset - DEFAULT_SUBMITTED_CARTS_PER_PAGE;
      if (newOffset < 0) {
        newOffset = 0;
      }
      return {
        ...prev,
        offset: newOffset,
      };
    });
  };

  const nextPage = () => {
    setCartParams((prev) => {
      const newOffset = prev.offset + DEFAULT_SUBMITTED_CARTS_PER_PAGE;

      return {
        ...prev,
        offset: newOffset,
      };
    });
  };

  const onRowClick = (rowData) => {
    const orderNumber = isNumber(rowData.id) ? rowData.id : Number(rowData.id);
    setOrderNumber(orderNumber);
  };

  const handleDateChange = (startDate, toDate, timeZone) => {
    setCartParams((prev) => ({
      ...prev,
      startDate,
      toDate,
      timeZone,
    }));
  };

  const onCartFilterChange = (
    parameter: keyof OrderHistoryParams,
    value: string
  ) => {
    setCartParams((prev) => ({
      ...prev,
      [parameter]: !value || value === "" ? undefined : value,
    }));
  };

  return (
    <>
      <div className="table-controls report">
        <div className="report-buttons">
          <DateRangeSelect
            setDateRange={handleDateChange}
            defaultPreset="p30d"
          />
        </div>
        <Paging
          pageOffset={cartParams.offset}
          currentPageItemCount={carts && carts.length}
          maxItemsPerPage={1}
          getPrevPage={prevPage}
          getNextPage={nextPage}
        />
      </div>
      <div className="data-table">
        <div
          style={{
            height: `${carts ? carts.length * 50 + 80 : 0}px`,
          }}
        >
          <Table
            data={carts}
            headerHeight={80}
            loading={isFetching}
            className="rs-table--multi-header"
            rowClassName="table-row-clickable"
            renderEmpty={() => <div>No data to display</div>}
            fillHeight
            onRowClick={onRowClick}
          >
            <Column key="col_submitted" width={130} flexGrow={1} resizable>
              <CustomHeaderCell title="Date" />
              <Cell dataKey="dateSubmitted">
                {(rowData) =>
                  TimeUtils.convertDateToHumanReadable(
                    rowData.dateSubmitted,
                    cartParams.timeZone
                  )
                }
              </Cell>
            </Column>
            <Column key="col_id" width={90} resizable>
              <CustomHeaderCell title="Cart">
                <NumberInputFilter
                  title="Filter by ID"
                  aria-label="Filter by cart ID"
                  value={cartParams.cartId}
                  setValue={(value) => onCartFilterChange("cartId", value)}
                />
              </CustomHeaderCell>
              <Cell dataKey="id" />
            </Column>
            <Column key="col_shopifyOrderId" width={130} resizable>
              <CustomHeaderCell title="Shopify Order">
                <NumberInputFilter
                  title="Filter by shopify ID"
                  aria-label="Filter by Shopify ID"
                  value={cartParams.shopifyOrderId}
                  setValue={(value) =>
                    onCartFilterChange("shopifyOrderId", value)
                  }
                />
              </CustomHeaderCell>
              <Cell dataKey="shopifyOrderId" />
            </Column>
            <Column key="col_processedBy" width={120} flexGrow={1} resizable>
              <CustomHeaderCell title="Completed By" />
              <Cell dataKey="submittedBy" />
            </Column>
            <Column key="col_firstName" width={120} flexGrow={1} resizable>
              <CustomHeaderCell title="First Name">
                <TextInputFilter
                  title="Filter by first name"
                  aria-label="Filter by first name"
                  value={cartParams.firstName}
                  setValue={(value) => onCartFilterChange("firstName", value)}
                />
              </CustomHeaderCell>
              <Cell dataKey="customer.frstName">
                {(rowData) => rowData?.customer?.firstName || "Guest"}
              </Cell>
            </Column>
            <Column key="col_lastName" width={120} flexGrow={1} resizable>
              <CustomHeaderCell title="Last Name">
                <TextInputFilter
                  title="Filter by last name"
                  aria-label="Filter by last name"
                  value={cartParams.lastName}
                  setValue={(value) => onCartFilterChange("lastName", value)}
                />
              </CustomHeaderCell>
              <Cell dataKey="customer.lastName">
                {(rowData) => rowData?.customer?.lastName}
              </Cell>
            </Column>
            <Column key="col_note" width={120} resizable>
              <CustomHeaderCell title="Notes">
                <TextInputFilter
                  title="Filter by note"
                  aria-label="Filter by note content"
                  value={cartParams.containsNote}
                  setValue={(value) =>
                    onCartFilterChange("containsNote", value)
                  }
                />
              </CustomHeaderCell>
              <Cell dataKey="notes" />
            </Column>
            <Column key="col_sale_total" width={120} resizable>
              <CustomHeaderCell title="Sale Total" />
              <Cell dataKey="totals.cartTotalWithDiscount">
                {(rowData) =>
                  formatCurrency(rowData?.totals?.cartTotalWithDiscount)
                }
              </Cell>
            </Column>
            <Column key="col_type" width={80} resizable>
              <CustomHeaderCell title="Type" />
              <Cell dataKey="cartType">
                {(rowData) => rowData?.cartType || "POS"}
              </Cell>
            </Column>
            <Column key="col_return" width={50} align="center" resizable>
              <CustomHeaderCell title="" />
              <Cell dataKey="id">
                {(rowData) =>
                  rowData?.cartType !== "return" && (
                    <ReturnCartButton
                      cartId={rowData?.id}
                      small
                      onComplete={(response, paymentData) => {
                        cartFromResponse(response);
                        setReturnCartIntegratedPayment(paymentData);
                        closeModal();
                      }}
                    />
                  )
                }
              </Cell>
            </Column>
          </Table>
        </div>
      </div>
      {carts && carts.length > 10 && (
        <Paging
          pageOffset={cartParams.offset}
          currentPageItemCount={carts && carts.length}
          maxItemsPerPage={1}
          getPrevPage={prevPage}
          getNextPage={nextPage}
        />
      )}
    </>
  );
}

export default OrderHistoryTable;
