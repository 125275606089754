import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { action } from "mobx";
import { CustomLineItem, ItemList } from "../../pos/ItemStore";
import NoImage from "../../../assets/img/noimage.png";
import BuyIcon from "../../../assets/img/buy.svg";
import SellIcon from "../../../assets/img/sell.svg";

interface CustomLineComponentProps {
  posStore?: ItemList;
}

@inject("posStore")
@observer
class CustomLineComponent extends Component<CustomLineComponentProps> {
  state = {};
  nameInput: HTMLInputElement;

  componentDidMount() {
    this.nameInput.focus();
  }

  _handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.addCustomItem();
    }
  };
  @action
  updatePrice = (e) => {
    this.props.posStore.customItem.actualPrice = e.target.value;
  };
  @action
  updateName = (e) => {
    this.props.posStore.customItem.name = e.target.value;
  };

  @action
  addCustomItem = () => {
    if (!isNaN(parseFloat(this.props.posStore.customItem.actualPrice as any))) {
      this.props.posStore.customItem.actualPrice = parseFloat(
        this.props.posStore.customItem.actualPrice as any
      );
    } else {
      this.props.posStore.customItem.actualPrice = 0.0;
    }
    this.props.posStore.addToCart(
      new CustomLineItem(
        this.props.posStore.customItem,
        this.props.posStore.buyMode,
        this.props.posStore.allTax
      )
    );
    this.props.posStore.toggleCustomItem();
  };
  render() {
    const store = this.props.posStore;
    return (
      <div
        className={!store.buyMode ? "cartItem buy" : "cartItem sell"}
        key={Math.random()}
      >
        <img className="img" src={NoImage} role="presentation" />
        {!store.buyMode ? (
          <img className="cartBadge" src={BuyIcon} role="presentation" />
        ) : (
          <img className="cartBadge" src={SellIcon} role="presentation" />
        )}
        <div className="itemWrapper itemWrapper--custom">
          <div className="data">
            <form>
              Title:
              <input
                data-testid="update-name"
                className="customTitle"
                type="textbox"
                placeholder="My custom Item"
                onKeyPress={this._handleKeyPress}
                onChange={this.updateName}
                ref={(input) => {
                  this.nameInput = input;
                }}
              />
              <br />
              Price:
              <input
                data-testid="update-price"
                className="customPrice"
                type="number"
                placeholder="19.95"
                onKeyPress={this._handleKeyPress}
                onChange={this.updatePrice}
              />
            </form>
          </div>
          <button
            title="Add the item"
            className="customAdd"
            type="button"
            onClick={this.addCustomItem}
          >
            <i className="far fa-save"></i>
          </button>
        </div>
      </div>
    );
  }
}

export default CustomLineComponent;
