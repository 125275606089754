import React from "react";
import Overlay from "../generic/Overlay";
import Drawer from "../generic/Drawer";
import IconButton from "../generic/IconButton";
import { MenuStructure, RootMenuItem } from "../../types/nav";
import { useMainMenu } from "../../hooks/menuHooks";
import { useStoreDetails } from "../../hooks/storeHooks";
import { useFeatureFlag } from "../../hooks/globalSettingsHooks";
import { signOut } from "../../services/firebase";
import {
  getSupportMenuStructure,
  legacySupportMenuStructure,
} from "../../menuStructures/SupportMenuStructure";
import { ADABOT_BINDER_PORTAL } from "../../constants/featureFlags";
import { SUPPORT_REDIRECT_URL } from "../../constants/support";
import NewMobileNavItem from "./NewMobileNavItem";
import "./NewMobileMenu.scss";
import { SIGNED_OUT, SUPPORT_CLICKED } from "../../constants/eventsTracked";
import { segmentAnalytics } from "../services/Analytics";

interface NewMobileMenuProps {
  mobileMenuOpen: boolean;
  handleClick: (menu: MenuStructure | null | undefined) => void;
  setMobileMenuOpen: (open: boolean) => void;
  handleLogout: VoidFunction;
}

function NewMobileMenu({
  mobileMenuOpen,
  handleClick,
  setMobileMenuOpen,
  handleLogout,
}: NewMobileMenuProps) {
  const menuItems = useMainMenu();
  const customerDetails = useStoreDetails();

  const closeMenu = () => setMobileMenuOpen(false);
  const adaBotBinderPortal = useFeatureFlag(ADABOT_BINDER_PORTAL);

  const menuItemClickHandler = (menuStructure?: MenuStructure) => {
    closeMenu();
    handleClick(menuStructure);
  };

  const logoutClickHandler = () => {
    segmentAnalytics.track(SIGNED_OUT);
    signOut();
    handleLogout();
  };

  const legacySupportButtonAction = () => {
    segmentAnalytics.track(SUPPORT_CLICKED, {
      support_type: "TCGPlayer Helpbot",
    });
    window.open(SUPPORT_REDIRECT_URL, "_blank");
  };

  const supportMenuStructure = adaBotBinderPortal
    ? {
        ...getSupportMenuStructure(customerDetails),
        clickHandler: menuItemClickHandler,
      }
    : {
        ...legacySupportMenuStructure,
        clickHandler: legacySupportButtonAction,
      };

  const secondaryMenu: Array<RootMenuItem & { clickHandler: VoidFunction }> = [
    ...menuItems.slice(-1).map((menuItem) => ({
      ...menuItem,
      clickHandler: menuItemClickHandler,
    })),
    supportMenuStructure,
    {
      iconClass: "fad fa-sign-out-alt",
      title: "Log Out",
      menuStructure: null,
      clickHandler: logoutClickHandler,
    },
  ];

  return (
    <>
      <IconButton
        aria-label="Open menu"
        className="NewMobileMenu__hamburguer"
        icon="fas fa-bars"
        onClick={() => setMobileMenuOpen(true)}
      />
      <Overlay fade showOverlay={mobileMenuOpen} dismissHandler={closeMenu}>
        <Drawer drawerOpen={mobileMenuOpen} drawerPosition="left">
          <div className="NewMobileMenu">
            <div className="NewMobileMenu__topBar">
              <IconButton
                aria-label="Close menu"
                className="NewMobileMenu__close"
                icon="fal fa-times"
                light
                onClick={closeMenu}
              />
            </div>
            <div className="NewMobileMenu__scrollTrack">
              <div className="NewMobileMenu__storeName">
                {customerDetails.customerName}
              </div>
              <div className="NewMobileMenu__menuWrapper">
                <ul className="NewMobileMenu__mainMenu">
                  {menuItems.slice(0, -1).map((menuItem) => (
                    <NewMobileNavItem
                      key={menuItem.target}
                      handleClick={menuItemClickHandler}
                      menuItem={menuItem}
                    />
                  ))}
                </ul>
                <ul className="NewMobileMenu__secondaryMenu">
                  {secondaryMenu.map((menuItem) => (
                    <NewMobileNavItem
                      key={menuItem.target || menuItem.title}
                      handleClick={menuItem.clickHandler}
                      menuItem={menuItem}
                    />
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </Drawer>
      </Overlay>
    </>
  );
}

export default NewMobileMenu;
