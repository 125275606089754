import { ToastOptions, toast } from "react-toastify";
import { notificationParameters } from "../constants/notifications";

const statuses = ["success", "info", "warn", "error"];

export const notify = Object.fromEntries(
  statuses.map((status) => [
    status,
    (message: string, options: Partial<ToastOptions> = {}) =>
      toast[status](message, { ...notificationParameters, ...options }),
  ])
);

export const dismissNotification = toast.dismiss;
