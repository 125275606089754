import React, { useEffect, useState } from "react";
import {
  fetchCurrencies,
  fetchLocales,
  fetchMarketplaces,
  provisionStore,
} from "../../../api/rest/ebay";
import Loader from "../../../components/generic/Loader";
import EbayErrorComponent from "../../../components/integrations/EbayErrorComponent";
import EbayProvisioningForm from "../../../components/integrations/EbayProvisioningForm";
import SectionHeaderLayout from "../../../components/layout/SectionHeaderLayout";
import { API_STATE } from "../../../constants/api";
import IntegrationsSideMenu from "../../../menuStructures/IntegrationsSideMenu";
import { parseEbayErrorMessage } from "../../../utils/integrations";

interface EbayProvisioningValues {
  marketplace: string;
  currency: string;
  locale: string;
}

const getHandleSubmit =
  (setErrors: Function, setIsProvisioned: Function) =>
  (
    customerName: string,
    values: EbayProvisioningValues,
    setSubmitting: Function
  ) => {
    const { marketplace, currency, locale } = values;
    provisionStore(customerName, marketplace, currency, locale)
      .then(() => {
        setSubmitting(false);
        setIsProvisioned(true);
      })
      .catch((error: Error) => setErrors(parseEbayErrorMessage(error)));
  };

interface ProvisioningContainerProps {
  setIsProvisioned: Function;
}

function ProvisioningContainer(props: ProvisioningContainerProps) {
  const { setIsProvisioned } = props;
  const [apiState, setApiState] = useState(API_STATE.INITIAL);
  const [marketplaces, setMarketplaces] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [locales, setLocales] = useState([]);
  const [errors, setErrors] = useState<string[]>([]);

  const handleSubmit = getHandleSubmit(setErrors, setIsProvisioned);

  const loadFormOptions = () => {
    setApiState(API_STATE.LOADING);
    Promise.all([fetchMarketplaces(), fetchCurrencies(), fetchLocales()]).then(
      (values) => {
        const [marketplacesRsp, currenciesRsp, localesRsp] = values;
        setMarketplaces(marketplacesRsp);
        setCurrencies(currenciesRsp);
        setLocales(localesRsp);
        setApiState(API_STATE.SUCCESS);
      }
    );
  };

  useEffect(() => {
    loadFormOptions();
  }, []);

  if (apiState === API_STATE.LOADING) {
    return <Loader />;
  }

  return (
    <>
      <IntegrationsSideMenu />
      <SectionHeaderLayout title={`ebay Integration Settings`} />
      <div className="ebay-integration-view__container">
        <EbayErrorComponent errors={errors} />
        <EbayProvisioningForm
          marketplaces={marketplaces}
          currencies={currencies}
          locales={locales}
          handleSubmit={handleSubmit}
        />
      </div>
    </>
  );
}

export default ProvisioningContainer;
