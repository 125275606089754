import React from "react";
import { AdditionalInfoModel } from "./additional-info.model";
import { Item } from "./item.model";
import ButtonComponent from "../generic/ButtonComponent";
import Modal from "../generic/Modal";
import "./AdditionalInfo.scss";

interface AdditionalInfoProps {
  addItem: () => unknown;
  addItemWithAdditionalInfo: (
    event: React.FormEvent<HTMLFormElement>
  ) => unknown;
  form: React.LegacyRef<HTMLFormElement>;
  item: Item;
  close: () => void;
}

function AdditionalInfo(props: AdditionalInfoProps) {
  const { addItem, form, addItemWithAdditionalInfo, item, close } = props;
  return (
    <Modal onClose={close}>
      <Modal.Header>Event additional information</Modal.Header>
      <Modal.Content>
        <form
          className="AdditionalInfo"
          id="additionalInfoForm"
          ref={form}
          onSubmit={(e) => addItemWithAdditionalInfo(e)}
          noValidate
        >
          <p>
            Attach additional information to events, these will only be applied
            if a customer is selected.
          </p>
          <div className="AdditionalInfo__fields">
            {item.event.additionalInfo.map(
              (additionalInfo: AdditionalInfoModel, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <label>{additionalInfo.header}</label>
                    {additionalInfo.description &&
                    additionalInfo.description.length > 0 ? (
                      <p>{additionalInfo.description}</p>
                    ) : null}
                    <input
                      className="input"
                      type="text"
                      id={"additionalInfo" + additionalInfo.id}
                    />
                  </React.Fragment>
                );
              }
            )}
          </div>
        </form>
      </Modal.Content>
      <Modal.Actions>
        <ButtonComponent secondary onClick={addItem}>
          Skip
        </ButtonComponent>
        <ButtonComponent primary type="submit" form="additionalInfoForm">
          Add info
        </ButtonComponent>
      </Modal.Actions>
    </Modal>
  );
}

export default AdditionalInfo;
