import { Formik, Form, Field, ErrorMessage } from "formik";
import { useHistory } from "react-router-dom";
import SunEditor from "suneditor-react";
import Input from "../form/Input";
import ButtonComponent from "../generic/ButtonComponent";
import Card from "../generic/Card";
import SectionHeaderLayout from "../layout/SectionHeaderLayout";
// import ProductMedia from "./ProductMedia";
import ProductDeleteButton from "./ProductDeleteButton";
import ProductTags from "./ProductTags";
import ProductTypeInput from "./ProductTypeInput";
import ProductVariants from "./ProductVariants";
import ProductVendorInput from "./ProductVendorInput";
import {
  Product,
  useCreateProductMutation,
  useUpdateProductMutation,
  useCreateProductVariantMutation,
  CreateProductMutation,
  UpdateProductMutation,
} from "../../generated/graphql";
import { buttonList, plugins } from "../../helpers/editorHelpers";
import { notify } from "../../helpers/notificationHelpers";
import { useShowError } from "../../hooks/errorHooks";
import { productEditSchema } from "../../schemas/product";
import {
  mapCreateProductData,
  mapCreateVariantData,
  mapUpdateProductData,
} from "../../transformers/productEdit";
import { ProductEditData } from "../../types/productEdit";
import "./ProductEditForm.scss";

const defaultProduct = {
  title: "",
  productType: "",
  vendor: "",
  ProductDescription: {
    descriptionHtml: "",
  },
  ProductTag: [],
  ProductImage: [],
  ProductVariant: [],
  newProductImages: [],
} as ProductEditData;

interface ProductEditFormProps {
  isNew: boolean;
  product?: Product;
}

const ProductEditForm = (props: ProductEditFormProps) => {
  const { isNew, product } = props;
  const showError = useShowError();
  const history = useHistory();
  const { mutateAsync: createProduct } = useCreateProductMutation();
  const { mutateAsync: updateProduct } = useUpdateProductMutation();
  const { mutateAsync: createVariant } = useCreateProductVariantMutation();

  const handleSave = (data: ProductEditData) => {
    const { ...productData } = data;
    const newVariants = productData.ProductVariant.filter(
      (variant) => !variant.id
    );
    const save = isNew
      ? () => createProduct(mapCreateProductData(productData))
      : () => updateProduct(mapUpdateProductData(productData));
    let redirectToProductId: number;
    return (
      save()
        // if update, create new variants
        .then(
          async (response: CreateProductMutation | UpdateProductMutation) => {
            if ("CreateProduct" in response) {
              redirectToProductId = response.CreateProduct.id;
            }
            if (!isNew) {
              return Promise.all(
                newVariants.map((variant) =>
                  createVariant({
                    productId: product.id,
                    variant: mapCreateVariantData(variant),
                  })
                )
              );
            } else {
              return Promise.resolve();
            }
          }
        )
        // TODO:: upload new images
        .then(() => {})
        .then(() => {
          const confirmationText = isNew
            ? "Product created"
            : "Product updated";
          notify.info(confirmationText);
          if (redirectToProductId) {
            history.push(`/products/edit/${redirectToProductId}`);
          }
        })
        .catch((error) => showError(error))
    );
  };

  return (
    <Formik
      initialValues={
        product
          ? {
              ...productEditSchema.cast(product, { assert: false }),
              newProductImages: [],
            }
          : defaultProduct
      }
      validationSchema={productEditSchema}
      enableReinitialize
      onSubmit={(values) => handleSave(values)}
    >
      {({
        values,
        isSubmitting,
        setFieldValue,
        isValid,
        dirty,
        handleReset,
      }) => (
        <div className="product-edit-form">
          <Form>
            <SectionHeaderLayout
              title={product ? "Edit Product" : "New Product"}
            >
              <ProductDeleteButton id={values.id} />
            </SectionHeaderLayout>
            <div className="product-edit-form__product-details">
              <div className="product-edit-form__product-details--left">
                <Card>
                  <Field
                    label="Title"
                    name="title"
                    type="text"
                    required
                    component={Input}
                  />
                  <label htmlFor="description" className="label">
                    Description
                  </label>
                  <SunEditor
                    name="description"
                    height="auto"
                    setContents={values.ProductDescription?.descriptionHtml}
                    onChange={(event: any) =>
                      setFieldValue("ProductDescription.descriptionHtml", event)
                    }
                    setOptions={{
                      imageFileInput: false,
                      tabDisable: true,
                      plugins,
                      buttonList,
                    }}
                  />
                  <ErrorMessage
                    name="ProductDescription.descriptionHtml"
                    className="input-error"
                    component="div"
                  />
                </Card>
                {/* <ProductMedia
                    productMedia={values.ProductImage}
                    newProductMedia={values.newProductImages}
                    setFieldValue={setFieldValue}
                  /> */}
              </div>
              <div className="product-edit-form__product-details--right">
                <Card title="Classification" headingLevel="h2">
                  {!isNew ? (
                    <Field
                      component={Input}
                      name="id"
                      label="Product ID"
                      type="string"
                      disabled
                    />
                  ) : null}
                  <ProductTypeInput name="productType" />
                  <ProductVendorInput name="vendor" />
                </Card>
                <Card title="Tags" headingLevel="h2">
                  <ProductTags
                    tags={values.ProductTag}
                    setFieldValue={setFieldValue}
                  />
                </Card>
              </div>
            </div>
            <ProductVariants
              variants={values.ProductVariant}
              setFieldValue={setFieldValue}
            />
            <div>
              <ButtonComponent halfWidth onClick={handleReset}>
                Cancel
              </ButtonComponent>
              <ButtonComponent
                primary
                halfWidth
                type="submit"
                disabled={!isValid || !dirty || isSubmitting}
              >
                Save
              </ButtonComponent>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default ProductEditForm;
