import { useEffect, useRef, useState, ChangeEvent } from "react";
import { validateFile } from "../../utils/file";
import { fetchStoreLogoUrl, saveStoreLogoUrl } from "../../api/rest/settings";
import { notify } from "../../helpers/notificationHelpers";
import { segmentAnalytics } from "../services/Analytics";
import { STORE_LOGO_CHANGED } from "../../constants/eventsTracked";
import ButtonComponent from "../generic/ButtonComponent";
import "./StoreLogo.scss";

function StoreLogo() {
  const [currentLogo, setCurrentLogo] = useState<string | null>(null);
  const [fileError, setFileError] = useState<string | null>(null);
  const logoInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    fetchLogo();
  }, []);

  const fetchLogo = async () => {
    try {
      const data = await fetchStoreLogoUrl();
      if (data?.publicUrl) {
        setCurrentLogo(data.publicUrl);
      }
    } catch (error: any) {
      console.warn(error);
      notify.warn("Unable to load store logo");
    }
  };

  const handleLogoChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setFileError(null);
    segmentAnalytics.track(STORE_LOGO_CHANGED);
    const file = event.target.files?.[0];

    if (!file) return;

    try {
      const headerBytes = file.slice(0, 4);
      await validateFile(headerBytes);

      const reader = new FileReader();
      reader.onload = async () => {
        try {
          const result = reader.result as string;

          await saveStoreLogoUrl(file);
          notify.info("Store logo updated");
          setCurrentLogo(result);
        } catch (uploadError: any) {
          setFileError(uploadError.toString());
        }
      };
      reader.readAsDataURL(file);
    } catch (validationError: any) {
      setFileError(validationError.toString());
      if (event.target) {
        event.target.value = "";
      }
    }
  };

  return (
    <div className="StoreLogo">
      <div>
        <p>Store logo</p>
        <label htmlFor="logoUpload">
          <ButtonComponent
            primary
            className="StoreLogo__upload-button"
            onClick={() => logoInputRef.current?.click()}
          >
            Select a logo
          </ButtonComponent>
          <input
            id="logoUpload"
            type="file"
            accept=".jpg,.jpeg,.png,.gif, image/jpeg, image/gif, image/png"
            ref={logoInputRef}
            onChange={handleLogoChange}
          />
        </label>
        <p className="StoreLogo__description">
          2MB max file size.
          <br />
          Permitted file types are jpg, jpeg, png, and gif.
        </p>
      </div>
      <div>
        {fileError ? (
          <div className="StoreLogo__file-error">{fileError}</div>
        ) : currentLogo ? (
          <div
            className="StoreLogo__image"
            style={{ backgroundImage: `url(${currentLogo})` }}
          />
        ) : null}
      </div>
    </div>
  );
}

export default StoreLogo;
