import { UseQueryOptions, QueryKey } from "react-query";
import { Cart_By_PkQuery, useCart_By_PkQuery } from "../generated/graphql";
import { mapOrderDetails } from "../transformers/orderDetails";
import { OrderData } from "../types/order";

export const useFetchCartById = (
  cartId: number,
  options: UseQueryOptions<Cart_By_PkQuery, unknown, OrderData, QueryKey> = {}
) =>
  useCart_By_PkQuery(
    { cartByPkId: Number(cartId) },
    {
      staleTime: 5 * 300_000,
      select: ({ Cart_by_pk }) => mapOrderDetails(Cart_by_pk),
      ...options,
    }
  );
