type Option = {
  id: string;
  value: string;
};

export const buildSelectOptionsList = (listToConvert?: string[] | Option[]) => {
  const options = [];
  listToConvert?.forEach((item: string | Option) => {
    if (typeof item === "string") {
      if (item?.length > 0) {
        options.push({ value: item, label: item });
      }
    } else if (item?.value?.length > 0) {
      options.push({ value: item.id, label: item.value });
    }
  });
  return options;
};
