import { Field } from "formik";
import { useStoreDetails, useFormatCurrency } from "../../hooks/storeHooks";
import { RarityFloor } from "../../types/settings";
import { capitalizeFirstLetter } from "../../utils/formatting";
import Input from "../form/Input";
import Tooltip from "../generic/Tooltip";
import "./GamePriceFloor.scss";

interface GamePriceFloorProps {
  rarityFloor: RarityFloor;
  gameName: string;
  gameCode: string;
  enableEditMode: boolean;
  index: number;
}

function GamePriceFloor(props: GamePriceFloorProps) {
  const { rarityFloor, gameCode, enableEditMode, index } = props;
  const { rarity, price: initialPrice } = rarityFloor;
  const { currencySymbol } = useStoreDetails();
  const formatCurrency = useFormatCurrency();

  return (
    <tr className="game-price-floor">
      <td className="game-price-floor__rarity">
        <label htmlFor={`rarityFloors.${index}.price`}>
          {capitalizeFirstLetter(rarity)}
        </label>
        {gameCode === "mtg" && rarity === "Special" ? (
          <Tooltip>
            <>
              This affects purple times-shifted cards from{" "}
              <strong>Time Spiral Remastered</strong> only.
            </>
          </Tooltip>
        ) : null}
      </td>
      <td className="game-price-floor__price">
        {enableEditMode ? (
          <Field
            label="Price Floor"
            component={Input}
            name={`rarityFloors.${index}.price`}
            type="text"
            adornment={currencySymbol}
            adornmentPosition="start"
            optionalLabel
          />
        ) : (
          formatCurrency(initialPrice || "")
        )}
      </td>
      <td role="presentation" className="game-price-floor__spacing" />
    </tr>
  );
}

export default GamePriceFloor;
