import { Pagination, ReportControls } from "../../components/reports";
import {
  CurrencyRenderer,
  TextInputFilter,
} from "../../components/reports/CellTypes";
import { API_STATE } from "../../constants/api";
import { ReportsMenuStructure } from "../../menuStructures";
import React, { Fragment, useEffect } from "react";
import { Table, Column, Cell } from "rsuite-table";
import "rsuite-table/dist/css/rsuite-table.css";
import "../../../css/rstable-overwrites.scss";
import CustomHeaderCell from "../../components/reports/CustomHeader";
import SetActiveMenu from "../../menuStructures/SetActiveMenu";
import { stockValueCSVHeaders } from "../../constants/reportCSVHeaders";
import { rowPerPageOptions } from "../../constants/reports";
import { useStoreDetails } from "../../hooks/storeHooks";
import { useShowError } from "../../hooks/errorHooks";
import { useReportsDetails } from "../../hooks/reportsHooks";
import { useFetchStockValueReport } from "../../hooks/reports/useFetchStockValueReport";

function StockValue() {
  const {
    apiState,
    currentPage,
    filterValues,
    overrideTableHeight,
    rowsPerPage,
    sortColumn,
    tableData,
    computedTableData,
    reset,
    setApiState,
    setCurrentPage,
    setTableRowsPerPage,
    setFilters,
    setSortingColumn,
    setSourceDataDetails,
    updateFilterValue,
  } = useReportsDetails();
  const { currencyCode } = useStoreDetails();
  const showError = useShowError();

  const { refetch } = useFetchStockValueReport({
    enabled: false,
  });

  useEffect(() => {
    document.title = "Reports | BinderPOS";
    reset();
    setFilters(filters);
  }, []);

  const filters = [
    {
      name: "product type",
      condition: (filterValues) => filterValues?.productType?.length,
      filterFunction: (row, filterValues) =>
        row.productType &&
        row.productType
          .toLowerCase()
          .includes(filterValues?.productType?.toLowerCase()),
    },
  ];

  const onLoadDataClick = () => {
    setApiState(API_STATE.LOADING);
    refetch()
      .then(({ data }) => {
        setSourceDataDetails(data);
        setApiState(API_STATE.SUCCESS);
      })
      .catch((error) => {
        setApiState(API_STATE.ERROR);
        showError(
          error,
          "Failed to load report",
          "There was an error retrieving your stock value report. Please try again"
        );
      });
  };

  const { productType = "" } = filterValues;
  const csvFilename = "Stock Inventory Value Report.csv";
  const data = computedTableData();

  return (
    <Fragment>
      <SetActiveMenu menuStructure={ReportsMenuStructure} />
      <div className="app-header">
        <h1 className="title">
          <span className="header-text">Stock Inventory Value</span>
        </h1>
      </div>
      <div className="report">
        <ReportControls
          loadData={onLoadDataClick}
          csvHeaders={stockValueCSVHeaders}
          csvFilename={csvFilename}
        />
        {apiState !== API_STATE.INITIAL && (
          <div className="data-table">
            <div className="table-container">
              <Table
                data={data}
                headerHeight={80}
                renderEmpty={() => <div>No data to display</div>}
                loading={apiState == API_STATE.LOADING}
                onSortColumn={setSortingColumn}
                sortColumn={sortColumn.key}
                sortType={sortColumn.order}
                className="rs-table--multi-header"
                height={overrideTableHeight}
                fillHeight={overrideTableHeight === 0}
              >
                <Column key="col_productType" flexGrow={1} sortable>
                  <CustomHeaderCell title="Product Type">
                    <TextInputFilter
                      title="Product type"
                      aria-label="Filter by product type"
                      value={productType}
                      setValue={(value) =>
                        updateFilterValue("productType", value)
                      }
                    />
                  </CustomHeaderCell>
                  <Cell dataKey="productType" />
                </Column>
                <Column key="col_count" width={100} sortable resizable>
                  <CustomHeaderCell title="Count" />
                  <Cell dataKey="count" />
                </Column>
                <Column
                  key="col_value"
                  width={150}
                  align="right"
                  sortable
                  resizable
                >
                  <CustomHeaderCell title="Value">
                    {CurrencyRenderer({
                      currency: currencyCode,
                      cellData: tableData?.reduce(
                        (sum, row) => sum + row.totalValue,
                        0
                      ),
                    })}
                  </CustomHeaderCell>
                  <Cell dataKey="totalValue">
                    {(rowData) =>
                      CurrencyRenderer({
                        cellData: rowData.totalValue,
                        currency: currencyCode,
                      })
                    }
                  </Cell>
                </Column>
                <Column
                  key="col_cost"
                  width={150}
                  align="right"
                  sortable
                  resizable
                >
                  <CustomHeaderCell title="Cost">
                    {CurrencyRenderer({
                      currency: currencyCode,
                      cellData: tableData?.reduce(
                        (sum, row) => sum + row.totalCost,
                        0
                      ),
                      style: { textAlign: "right", paddingRight: "0.8em" },
                    })}
                  </CustomHeaderCell>
                  <Cell dataKey="totalCost">
                    {(rowData) =>
                      CurrencyRenderer({
                        cellData: rowData.totalCost,
                        currency: currencyCode,
                        style: { textAlign: "right", paddingRight: "0.8em" },
                      })
                    }
                  </Cell>
                </Column>
              </Table>
            </div>
            <Pagination
              apiState={apiState}
              currentPage={currentPage}
              totalRowCount={tableData.length}
              rowsPerPage={rowsPerPage}
              rowPerPageOptions={rowPerPageOptions}
              setCurrentPage={setCurrentPage}
              setRowsPerPage={setTableRowsPerPage}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default StockValue;
