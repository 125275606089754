import "./PriceRounding.scss";
import { ReactNode, useEffect, useState } from "react";
import { useStoreDetails } from "../../hooks/storeHooks";
import { isANumber, isAPositiveWholeNumber } from "../../helpers/valueHelpers";
import { GamePriceRuleStrict } from "../../types/settings";
import { Field, useFormikContext } from "formik";
import RadioButton from "../form/RadioButton";
import Input from "../form/Input";
import Select from "../form/Select";
import Tooltip from "../generic/Tooltip";

export const ROUNDING_OPTIONS = {
  NO_ROUNDING: "No game-specific rounding",
  ENDING: "Round to nearest end",
  MULTIPLE: "Round to nearest multiple of...",
};

export const MULTIPLE_ROUNDING_OPTIONS = [
  { value: 0.05, label: "0.05" },
  { value: 0.1, label: "0.10" },
  { value: 0.25, label: "0.25" },
  { value: 0.5, label: "0.50" },
  { value: 1, label: "1" },
  { value: 5, label: "5" },
  { value: 10, label: "10" },
];

export const multipleRoundingOptVals = MULTIPLE_ROUNDING_OPTIONS.map(
  (opt) => opt.value
);

const getSelectedRoundingOption = (
  roundToNearest: number | string,
  currencyIsFractional: boolean,
  isGlobal: boolean
) => {
  if (
    (roundToNearest === null ||
      roundToNearest === "" ||
      String(roundToNearest) === "0") &&
    !isGlobal
  ) {
    return ROUNDING_OPTIONS.NO_ROUNDING;
  }

  if (currencyIsFractional) {
    if (multipleRoundingOptVals.includes(Number(roundToNearest))) {
      return ROUNDING_OPTIONS.MULTIPLE;
    }

    return ROUNDING_OPTIONS.ENDING;
  } else {
    if (Number(roundToNearest) === 9 || Number(roundToNearest) === 99) {
      return ROUNDING_OPTIONS.ENDING;
    }
    return ROUNDING_OPTIONS.MULTIPLE;
  }
};
export interface PriceRoundingProps {
  isGlobal?: boolean;
  game?: string;
  isEditing: boolean;
  hideTitle?: boolean;
}

const PriceRounding = ({
  isGlobal = false,
  game,
  isEditing = true,
  hideTitle = false,
}: PriceRoundingProps) => {
  const { currencyIsFractional } = useStoreDetails();
  const { setFieldValue, values } =
    useFormikContext<Partial<GamePriceRuleStrict>>();
  const [selectedRoundingOption, setSelectedRoundingOption] = useState(
    getSelectedRoundingOption(
      values.roundToNearest,
      currencyIsFractional,
      isGlobal
    )
  );

  useEffect(() => {
    if (!isEditing) {
      const currentSelection = getSelectedRoundingOption(
        values.roundToNearest,
        currencyIsFractional,
        isGlobal
      );
      if (currentSelection !== selectedRoundingOption) {
        setSelectedRoundingOption(currentSelection);
      }
    }
  }, [isEditing]);

  const tooltipText = game
    ? "Price rounding allows your automatic pricing to round based on the value set. If no game-specific rounding is configured, the global setting will apply."
    : "Any per-product-line settings configured will override global settings.";

  const renderWithHeader = (content: ReactNode) => (
    <div className="PriceRounding input-group">
      {hideTitle ? null : (
        <div className="PriceRounding__heading">
          Price Rounding
          <Tooltip>{tooltipText}</Tooltip>
        </div>
      )}
      {content}
    </div>
  );

  if (!currencyIsFractional && isGlobal) {
    return renderWithHeader(
      <Field
        component={Input}
        name="roundToNearest"
        label="Round to nearest multiple of..."
        type="text"
        editing={isEditing}
        placeholder="E.g. 5"
        inputMode="numeric"
        helperText="Enter any whole number"
        validate={(value: string) => {
          if (!isANumber(value)) {
            return "Must be a valid number";
          } else if (!isAPositiveWholeNumber(value)) {
            return "Must be a whole number greater than 0";
          }
        }}
      />
    );
  }

  if (!currencyIsFractional && !isGlobal) {
    return renderWithHeader(
      <>
        <RadioButton
          editing={isEditing}
          label="No game-specific rounding"
          compact
          checked={selectedRoundingOption === ROUNDING_OPTIONS.NO_ROUNDING}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedRoundingOption(ROUNDING_OPTIONS.NO_ROUNDING);
              setFieldValue("roundToNearest", null);
            }
          }}
        />
        <RadioButton
          editing={isEditing}
          label="Round to nearest multiple of..."
          compact
          checked={selectedRoundingOption === ROUNDING_OPTIONS.MULTIPLE}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedRoundingOption(ROUNDING_OPTIONS.MULTIPLE);
              setFieldValue("roundToNearest", "");
            }
          }}
        />
        {selectedRoundingOption === ROUNDING_OPTIONS.MULTIPLE ? (
          <Field
            component={Input}
            editing={isEditing}
            name="roundToNearest"
            type="text"
            placeholder="E.g. 5"
            inputMode="numeric"
            helperText="Enter any whole number"
            validate={(value: string) => {
              if (value === null) return;
              if (!isANumber(value)) {
                return "Must be a valid number";
              } else if (!isAPositiveWholeNumber(value)) {
                return "Must be a whole number greater than 0";
              }
            }}
          />
        ) : null}
      </>
    );
  }

  if (currencyIsFractional && isGlobal) {
    return renderWithHeader(
      <>
        <RadioButton
          editing={isEditing}
          label="Round to nearest ending in .99"
          compact
          checked={selectedRoundingOption === ROUNDING_OPTIONS.ENDING}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedRoundingOption(ROUNDING_OPTIONS.ENDING);
              setFieldValue("roundToNearest", "0.99");
            }
          }}
        />
        <RadioButton
          editing={isEditing}
          label="Round to nearest multiple of..."
          compact
          checked={selectedRoundingOption === ROUNDING_OPTIONS.MULTIPLE}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedRoundingOption(ROUNDING_OPTIONS.MULTIPLE);
              if (
                !multipleRoundingOptVals.includes(Number(values.roundToNearest))
              ) {
                setFieldValue(
                  "roundToNearest",
                  String(multipleRoundingOptVals[0])
                );
              }
            }
          }}
        />
        {selectedRoundingOption === ROUNDING_OPTIONS.MULTIPLE ? (
          <Field component={Select} editing={isEditing} name="roundToNearest">
            {MULTIPLE_ROUNDING_OPTIONS.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Field>
        ) : null}
      </>
    );
  }

  return renderWithHeader(
    <>
      <RadioButton
        editing={isEditing}
        label="No game-specific rounding"
        compact
        checked={selectedRoundingOption === ROUNDING_OPTIONS.NO_ROUNDING}
        onChange={(e) => {
          if (e.target.checked) {
            setSelectedRoundingOption(ROUNDING_OPTIONS.NO_ROUNDING);
            setFieldValue("roundToNearest", null);
          }
        }}
      />

      <RadioButton
        editing={isEditing}
        label="Round to nearest ending in .99"
        compact
        checked={selectedRoundingOption === ROUNDING_OPTIONS.ENDING}
        onChange={(e) => {
          if (e.target.checked) {
            setSelectedRoundingOption(ROUNDING_OPTIONS.ENDING);
            setFieldValue("roundToNearest", "0.99");
          }
        }}
      />

      <RadioButton
        editing={isEditing}
        label="Round to nearest multiple of..."
        compact
        checked={selectedRoundingOption === ROUNDING_OPTIONS.MULTIPLE}
        onChange={(e) => {
          if (e.target.checked) {
            setSelectedRoundingOption(ROUNDING_OPTIONS.MULTIPLE);
            if (
              !multipleRoundingOptVals.includes(Number(values.roundToNearest))
            ) {
              setFieldValue(
                "roundToNearest",
                String(multipleRoundingOptVals[0])
              );
            }
          }
        }}
      />

      {selectedRoundingOption === ROUNDING_OPTIONS.MULTIPLE ? (
        <Field component={Select} editing={isEditing} name="roundToNearest">
          {MULTIPLE_ROUNDING_OPTIONS.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Field>
      ) : null}
    </>
  );
};

export default PriceRounding;
