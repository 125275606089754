import { UseQueryOptions, QueryKey } from "react-query";
import {
  EndOfDayReportsQuery,
  EndOfDayReportsQueryVariables,
  useEndOfDayReportsQuery,
} from "../../generated/reporting";
import { formatEODReport } from "../../transformers/reports/formatEODReport";
import { EODData } from "../../types/reports";

export const useFetchEndOfDayReports = (
  variables: EndOfDayReportsQueryVariables,
  options: UseQueryOptions<
    EndOfDayReportsQuery,
    unknown,
    EODData,
    QueryKey
  > = {}
) =>
  useEndOfDayReportsQuery(variables, {
    select: (report) => formatEODReport(report),
    refetchOnMount: "always",
    keepPreviousData: true,
    staleTime: 60_000,
    ...options,
  });
