import React from "react";
import { inject, observer } from "mobx-react";
import { POSMenuStructure } from "../../menuStructures";
import { ItemList } from "../../pos/ItemStore";
import { RootStore } from "../../store";
import CartView from "./CartView";
import { StoreInfo } from "../../types/order";

type CartViewWrapperProps = {
  posStore?: ItemList;
  store?: RootStore;
};

function CartViewWrapper({ posStore, store }: CartViewWrapperProps) {
  return (
    <CartView
      storeInfo={posStore.storeInfo as StoreInfo}
      setSideMenu={() => store.MenuStore.setSideMenuToDisplay(POSMenuStructure)}
      posEnabled={store.AuthStore.screenSettings.pos}
    />
  );
}

export default inject("store", "posStore")(observer(CartViewWrapper));
