import React, { Fragment, useState, useEffect, useRef } from "react";
import { Table, Column, Cell } from "rsuite-table";
import "rsuite-table/dist/css/rsuite-table.css";
import "../../../css/rstable-overwrites.scss";
import "../../../css/react-select-overwrites.scss";
import { API_STATE } from "../../constants/api";
import { ReportsMenuStructure } from "../../menuStructures";
import AsyncSelect from "react-select/async";
import { fetchCustomers } from "../../api/rest/customers";
import {
  Pagination,
  ReportControls,
  ViewNotes,
} from "../../components/reports";
import {
  CurrencyRenderer,
  TextInputFilter,
} from "../../components/reports/CellTypes";
import { useReportsDetails } from "../../hooks/reportsHooks";
import { useStoreDetails } from "../../hooks/storeHooks";
import { useShowError } from "../../hooks/errorHooks";
import { storeCreditByCustomerCSVHeaders } from "../../constants/reportCSVHeaders";
import { rowPerPageOptions } from "../../constants/reports";
import { useFetchStoreCreditByCustomer } from "../../hooks/reports/useFetchStoreCreditByCustomer";
import SetActiveMenu from "../../menuStructures/SetActiveMenu";
import { addLocalizedDate } from "../../transformers/helpers/addLocalizedDate";
import CustomHeaderCell from "../../components/reports/CustomHeader";
import { notify } from "../../helpers/notificationHelpers";

function StoreCreditByCustomer() {
  const {
    apiState,
    currentPage,
    filterValues,
    overrideTableHeight,
    rowsPerPage,
    sortColumn,
    tableData,
    computedTableData,
    reset,
    setApiState,
    setCurrentPage,
    setTableRowsPerPage,
    setFilters,
    setSortingColumn,
    setSourceDataDetails,
    updateFilterValue,
  } = useReportsDetails();
  const modalRef = useRef<HTMLElement>();
  const tableRef = useRef<HTMLElement>();
  const { currencyCode } = useStoreDetails();
  const showError = useShowError();
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [timeZone, setTimeZone] = useState<string>(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [selectedCustomerId, setSelectedCustomerId] = useState<number>();
  const [showNotes, setShowNotes] = useState<string>(undefined);

  const {
    data: sourceData,
    isFetching,
    error,
  } = useFetchStoreCreditByCustomer(
    {
      startDate,
      endDate,
      customerId: selectedCustomerId,
    },
    {
      enabled: !!startDate && !!endDate && !!selectedCustomerId,
    }
  );

  useEffect(() => {
    document.title = "Reports | BinderPOS";
    reset();
    setFilters(filters);
  }, []);

  useEffect(() => {
    if (showNotes) {
      modalRef.current?.focus();
    } else {
      tableRef.current?.focus();
    }
  }, [showNotes]);

  useEffect(() => {
    if (isFetching) {
      setApiState(API_STATE.LOADING);
    } else if (error) {
      showError(
        error as any,
        "Failed to load report",
        "There was an error retrieving your store credit report. Please try again"
      );
      setApiState(API_STATE.ERROR);
    } else if (sourceData) {
      setSourceDataDetails(sourceData);
      setApiState(API_STATE.SUCCESS);
    }
  }, [isFetching, sourceData, error]);

  const filters = [
    {
      name: "actioned by",
      condition: (filterValues) => filterValues?.actionedBy?.length,
      filterFunction: (transaction, filterValues) =>
        transaction.actionedBy &&
        String(transaction.actionedBy).includes(filterValues?.actionedBy),
    },
    {
      name: "invoice number",
      condition: (filterValues) => filterValues?.invoiceNumber?.length,
      filterFunction: (transaction, filterValues) =>
        transaction.invoiceNumber &&
        String(transaction.invoiceNumber).includes(filterValues?.invoiceNumber),
    },
    {
      name: "cart ID",
      condition: (filterValues) => filterValues?.cartId?.length,
      filterFunction: (transaction, filterValues) =>
        transaction.cartId &&
        String(transaction.cartId).includes(filterValues?.cartId),
    },
    {
      name: "shopify customer buylist ID",
      condition: (filterValues) =>
        filterValues?.shopifyCustomerBuylistId?.length,
      filterFunction: (transaction, filterValues) =>
        transaction.shopifyCustomerBuylistId &&
        String(transaction.shopifyCustomerBuylistId).includes(
          filterValues?.shopifyCustomerBuylistId
        ),
    },
  ];

  const searchCustomers = (keyword) => {
    return fetchCustomers(keyword).catch((error) => {
      console.error(error);
      notify.error("Unable to search customers. Please try again");
    });
  };

  const handleSetDateRange = (fromDate, toDate, timeZone) => {
    setTimeZone(timeZone);
    setStartDate(fromDate);
    setEndDate(toDate);
  };

  const {
    actionedBy = "",
    invoiceNumber = "",
    cartId = "",
    shopifyCustomerBuylistId = "",
  } = filterValues;

  const csvFilename = "Customer Store Credit Transactions Report.csv";

  const data = addLocalizedDate(computedTableData(), timeZone, "updatedDate");

  return (
    <Fragment>
      <SetActiveMenu menuStructure={ReportsMenuStructure} />
      <div className="app-header">
        <h1 className="title">
          <span className="header-text">
            Customer Store Credit Transactions Report
          </span>
        </h1>
      </div>
      <div className="report">
        <div className="row">
          <div className="col-sm-3" id="selectCustomer">
            Select Customer
          </div>
          <div className="col-sm-9">
            <AsyncSelect
              aria-labelledby="selectCustomer"
              classNamePrefix="react-select"
              cacheOptions
              defaultOptions
              loadOptions={searchCustomers}
              onChange={(customer) => setSelectedCustomerId(customer.value)}
              placeholder="Type customer name or email"
              noOptionsMessage={() => "No matches found"}
            />
          </div>
        </div>
        <br />
        {!!selectedCustomerId && (
          <ReportControls
            setDateRange={handleSetDateRange}
            csvHeaders={storeCreditByCustomerCSVHeaders}
            csvFilename={csvFilename}
          />
        )}
        {apiState !== API_STATE.INITIAL && (
          <div className="data-table">
            <div className="table-container">
              <Table
                data={data}
                headerHeight={80}
                loading={apiState == API_STATE.LOADING}
                sortColumn={sortColumn.key}
                sortType={sortColumn.order}
                onSortColumn={setSortingColumn}
                renderEmpty={() => <div>No data to display</div>}
                className="rs-table--multi-header"
                height={overrideTableHeight}
                fillHeight={overrideTableHeight === 0}
              >
                <Column key="col_updatedDate" width={150} sortable resizable>
                  <CustomHeaderCell title="Date" />
                  <Cell dataKey="updatedDate">
                    {(rowData) => rowData.updatedDate_localized}
                  </Cell>
                </Column>
                <Column
                  key="col_actionedBy"
                  width={140}
                  flexGrow={1}
                  sortable
                  resizable
                >
                  <CustomHeaderCell title="Actioned By">
                    <TextInputFilter
                      title="Actioned by"
                      aria-label="Filter by actionee"
                      value={actionedBy}
                      setValue={(value) =>
                        updateFilterValue("actionedBy", value)
                      }
                    />
                  </CustomHeaderCell>
                  <Cell dataKey="actionedBy" />
                </Column>
                <Column
                  key="col_amountBeforeChange"
                  width={110}
                  align="right"
                  sortable
                  resizable
                >
                  <CustomHeaderCell title="Credit Before" />
                  <Cell dataKey="amountBeforeChange">
                    {(rowData) =>
                      CurrencyRenderer({
                        cellData: rowData.amountBeforeChange,
                        currency: currencyCode,
                      })
                    }
                  </Cell>
                </Column>
                <Column
                  key="col_amountAfterChange"
                  width={110}
                  align="right"
                  sortable
                  resizable
                >
                  <CustomHeaderCell title="Credit After" />
                  <Cell dataKey="amountAfterChange">
                    {(rowData) =>
                      CurrencyRenderer({
                        cellData: rowData.amountAfterChange,
                        currency: currencyCode,
                      })
                    }
                  </Cell>
                </Column>
                <Column
                  key="col_amountChanged"
                  width={110}
                  align="right"
                  sortable
                  resizable
                >
                  <CustomHeaderCell title="Credit Difference" />
                  <Cell dataKey="amountChanged">
                    {(rowData) =>
                      CurrencyRenderer({
                        cellData: rowData.amountChanged,
                        currency: currencyCode,
                      })
                    }
                  </Cell>
                </Column>
                <Column
                  key="col_invoiceNumber"
                  width={120}
                  align="right"
                  sortable
                  resizable
                >
                  <CustomHeaderCell title="Shopify Order">
                    <TextInputFilter
                      title="Invoice number"
                      aria-label="Filter by invoice number"
                      value={invoiceNumber}
                      setValue={(value) =>
                        updateFilterValue("invoiceNumber", value)
                      }
                    />
                  </CustomHeaderCell>
                  <Cell dataKey="invoiceNumber" />
                </Column>
                <Column
                  key="col_cartId"
                  width={120}
                  align="right"
                  sortable
                  resizable
                >
                  <CustomHeaderCell title="BinderPOS Cart">
                    <TextInputFilter
                      title="Cart Id"
                      aria-label="Filter by cart ID"
                      value={cartId}
                      setValue={(value) => updateFilterValue("cartId", value)}
                    />
                  </CustomHeaderCell>
                  <Cell dataKey="cartId" />
                </Column>
                <Column
                  key="col_shopifyCustomerBuylistId"
                  width={120}
                  align="right"
                  sortable
                  resizable
                >
                  <CustomHeaderCell title="BinderPOS Buylist">
                    <TextInputFilter
                      title="Shopify Customer Buylist Id"
                      aria-label="Filter by customer buylist ID"
                      value={shopifyCustomerBuylistId}
                      setValue={(value) =>
                        updateFilterValue("shopifyCustomerBuylistId", value)
                      }
                    />
                  </CustomHeaderCell>
                  <Cell dataKey="shopifyCustomerBuylistId" />
                </Column>
                <Column
                  key="col_actionNotes"
                  width={100}
                  align="center"
                  resizable
                >
                  <CustomHeaderCell title="Action Notes" />
                  <Cell dataKey="actionNotes">
                    {(rowData) => {
                      if (rowData.actionNotes)
                        return (
                          <button
                            onClick={(evt) => {
                              tableRef.current = evt.currentTarget;
                              setShowNotes(rowData.actionNotes);
                            }}
                            className="button"
                          >
                            View
                          </button>
                        );
                    }}
                  </Cell>
                </Column>
                <Column
                  key="col_publicNotes"
                  width={100}
                  align="center"
                  resizable
                >
                  <CustomHeaderCell title="Public Notes" />
                  <Cell dataKey="publicNotes">
                    {(rowData) => {
                      if (rowData.publicNotes)
                        return (
                          <button
                            onClick={(evt) => {
                              tableRef.current = evt.currentTarget;
                              setShowNotes(rowData.publicNotes);
                            }}
                            className="button"
                          >
                            View
                          </button>
                        );
                    }}
                  </Cell>
                </Column>
                <Column
                  key="col_privateNotes"
                  width={100}
                  align="center"
                  resizable
                >
                  <CustomHeaderCell title="Private Notes" />
                  <Cell dataKey="privateNotes">
                    {(rowData) => {
                      if (rowData.privateNotes)
                        return (
                          <button
                            onClick={(evt) => {
                              tableRef.current = evt.currentTarget;
                              setShowNotes(rowData.privateNotes);
                            }}
                            className="button"
                          >
                            View
                          </button>
                        );
                    }}
                  </Cell>
                </Column>
              </Table>
            </div>
            <Pagination
              apiState={apiState}
              currentPage={currentPage}
              totalRowCount={tableData.length}
              rowsPerPage={rowsPerPage}
              rowPerPageOptions={rowPerPageOptions}
              setCurrentPage={setCurrentPage}
              setRowsPerPage={setTableRowsPerPage}
            />
          </div>
        )}
      </div>
      {!!showNotes && (
        <ViewNotes
          ref={modalRef}
          notes={showNotes}
          onClose={() => setShowNotes(undefined)}
        />
      )}
    </Fragment>
  );
}

export default StoreCreditByCustomer;
