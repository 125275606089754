import { isNil } from "lodash";
import { useQuery } from "react-query";
import {
  fetchProductQueuedJobs,
  fetchProductQueuedJobStatus,
  QueuedJobsParams,
  QueuedJobStatusParams,
} from "../api/rest/jobQueue";
import { ProductQueuedJob } from "../types/queuedJobs";
import { useShowError } from "./errorHooks";

export const useFetchProductQueuedJobs = (params?: QueuedJobsParams) => {
  const showError = useShowError();
  return useQuery<ProductQueuedJob[]>(
    ["productQueuedJobs", params],
    () => fetchProductQueuedJobs(params),
    {
      enabled: !isNil(params?.type),
      staleTime: 30_000,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      refetchOnMount: "always",
      onError: (error: DetailedError) => {
        showError(
          error,
          "Failed to get queued jobs",
          "There was an error retrieving the list of queued jobs. Please refresh and try again"
        );
      },
    }
  );
};

export const useFetchProductQueuedJobStatus = (params: QueuedJobStatusParams) =>
  useQuery<ProductQueuedJob>(
    ["productQueueJobStatus", params],
    () => fetchProductQueuedJobStatus(params),
    {
      enabled: false,
      staleTime: 5_000,
      refetchOnWindowFocus: false,
      refetchOnMount: "always",
    }
  );
