import {
  GRAPHQL_ENDPOINT,
  REPORTING_GRAPHQL_ENDPOINT,
  DEVBLOG_GRAPHQL_ENDPOINT,
} from "../constants/api";
import { getUserToken } from "../helpers/authHelpers";
import { GraphQLError } from "../types/error";

type GraphQlResponse<TData> = {
  errors?: GraphQLError[];
  data: TData;
};

async function execute<TData, TVariables = {}>(
  query: string,
  endpoint: string,
  variables: TVariables,
  isRetry = false
) {
  const currentToken = await getUserToken(isRetry);

  const response = await fetch(endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${currentToken}`,
    },
    body: JSON.stringify({
      query,
      variables,
    }),
  });
  const result = await response.json();
  return result as GraphQlResponse<TData>;
}

export class GraphQLFetcherError extends Error {
  data: GraphQLError[];

  constructor({
    message,
    errors,
  }: {
    message: string;
    errors: GraphQLError[];
  }) {
    super(message);
    Object.setPrototypeOf(this, GraphQLFetcherError.prototype);
    this.data = errors;
  }
}

const createCustomFetcher = <TData, TVariables = {}>(
  query: string,
  endpoint: string,
  variables?: TVariables
) => {
  async function innerFetcher() {
    let response = await execute<TData, TVariables>(query, endpoint, variables);
    if (response.errors) {
      if (
        response.errors.some(
          (error) => error?.extensions?.code === "UNAUTHENTICATED"
        )
      ) {
        response = await execute(query, endpoint, variables, true);
        if (response.errors) {
          throw new Error(response.errors[0].message);
        }
      } else {
        throw new GraphQLFetcherError({
          message: response.errors.map((error) => error.message).join(),
          errors: response.errors,
        });
      }
    }
    return response.data;
  }

  return innerFetcher;
};

export const graphQlFetcher = <TData, TVariables>(
  query: string,
  variables?: TVariables
): (() => Promise<TData>) =>
  createCustomFetcher(query, GRAPHQL_ENDPOINT, variables);

export const reportingGraphQlFetcher = <TData, TVariables>(
  query: string,
  variables?: TVariables
): (() => Promise<TData>) =>
  createCustomFetcher(query, REPORTING_GRAPHQL_ENDPOINT, variables);

export const devBlogGraphQlFetcher =
  <TData, TVariables>(
    query: string,
    variables?: TVariables
  ): (() => Promise<TData>) =>
  async () => {
    const response = await fetch(DEVBLOG_GRAPHQL_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    });
    const result = await response.json();
    if (result.errors) {
      throw new GraphQLFetcherError({
        message: result.errors.map((error) => error.message).join(),
        errors: result.errors,
      });
    }
    return result.data;
  };
