export const roundCents = (amount: string | number) => {
  const theAmount = Number(amount);
  return (Math.round(Math.round(theAmount * 10000000) / 100000) / 100).toFixed(
    2
  );
  // return parseFloat(String(amount)).toFixed(2);
};

export const formatCurrency = (amount: string | number, currency: string) => {
  if (amount) {
    const theAmount = parseFloat(String(amount));
    if (theAmount >= 0) {
      return currency + roundCents(theAmount);
    } else {
      return "-" + currency + roundCents(Math.abs(theAmount));
    }
  }
  return roundCents(amount);
};
