import React, { useState } from "react";
import moment from "moment";
import {
  SHOPIFY_SUBSCRIPTION_FORCED_DATE,
  SHOPIFY_SUBSCRIPTION_LAST_DISMISSED_DATE,
} from "../../constants/settings";
import {
  useLocalSetting,
  useLocalSettingUpdate,
} from "../../hooks/localSettingsHooks";
import ButtonComponent from "../generic/ButtonComponent";
import IconButton from "../generic/IconButton";
import "./ShopifySubscriptionAlert.scss";
import Panel from "../layout/Panel";
import {
  SHOPIFY_SUBSCRIPTION_APPROVAL_BUTTON_CLICKED,
  SHOPIFY_SUBSCRIPTION_BANNER_DIMISSED,
} from "../../constants/eventsTracked";

interface ShopifySubscriptionAlertProps {
  confirmationUrl: string;
  alertIsDismissible: boolean;
}

export function ShopifySubscriptionAlert(props: ShopifySubscriptionAlertProps) {
  const { confirmationUrl, alertIsDismissible } = props;
  const [lastDismissedDate, setLastDismissedDate] = useState<
    string | undefined
  >(useLocalSetting(SHOPIFY_SUBSCRIPTION_LAST_DISMISSED_DATE));
  const cannotDismissAlertAfterDate = useLocalSetting(
    SHOPIFY_SUBSCRIPTION_FORCED_DATE
  );
  const localSettingUpdate = useLocalSettingUpdate();

  const hideNotification = () => {
    const NOW = moment().toISOString();
    localSettingUpdate(SHOPIFY_SUBSCRIPTION_LAST_DISMISSED_DATE, NOW);
    setLastDismissedDate(NOW);
  };

  const showNotification =
    !lastDismissedDate || moment(lastDismissedDate).add(1, "day") < moment();

  if (!showNotification && alertIsDismissible) return null;

  return (
    <Panel emphasised>
      <div className="ShopifySubscriptionAlert">
        {alertIsDismissible ? (
          <div className="ShopifySubscriptionAlert__header">
            <IconButton
              aria-label="Hide Notification"
              icon="far fa-times"
              className="ShopifySubscriptionAlert__dismissButton"
              onClick={() => hideNotification()}
              segmentEventName={SHOPIFY_SUBSCRIPTION_BANNER_DIMISSED}
            />
          </div>
        ) : null}
        <div className="ShopifySubscriptionAlert__content">
          <h2 className="ShopifySubscriptionAlert__title">
            Your Shopify subscription is pending confirmation. In order to
            continue using BinderPOS, this subscription must be approved.
          </h2>
          <p>
            Additional details, including information on commission fees, have
            been sent via email. Please proceed to your{" "}
            <a
              href={confirmationUrl}
              title="Shopify Subscription Acceptance Page"
              rel="noreferrer"
              target="_blank"
            >
              MyShopify
            </a>{" "}
            to approve this subscription.
          </p>
          <ButtonComponent
            primary
            fullWidth
            type="submit"
            className="ShopifySubscriptionAlert__button"
            href={confirmationUrl}
            rel="noreferrer"
            title="Shopify Subscription Acceptance Page"
            target="_blank"
            isAnchor
            segmentEventName={SHOPIFY_SUBSCRIPTION_APPROVAL_BUTTON_CLICKED}
          >
            Approve Subscription Now
          </ButtonComponent>
          {alertIsDismissible ? (
            <p>
              Dismissing this notification will hide it for 24 hours. After{" "}
              <b>
                {moment(cannotDismissAlertAfterDate).diff(moment(), "days")}
              </b>{" "}
              days, this notification can no longer be dismissed.
            </p>
          ) : null}
        </div>
      </div>
    </Panel>
  );
}

export default ShopifySubscriptionAlert;
