import React, { ReactChild } from "react";
import "./SectionHeaderLayout.scss";

interface SectionHeaderLayoutProps {
  title: string | ReactChild;
  children?: React.ReactChild;
}

function SectionHeaderLayout(props: SectionHeaderLayoutProps) {
  const { title, children } = props;

  return (
    <header className="SectionHeaderLayout">
      <h1>{title}</h1>
      <div>{children}</div>
    </header>
  );
}

export default SectionHeaderLayout;
