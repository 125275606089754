import React from "react";
import DevBlog from "../../components/dashboard/DevBlog";
import SalesBarChart from "../../components/dashboard/SalesBarChart";
import SalesBarChartBreakDown from "../../components/dashboard/SalesBarChartBreakDown";
import Thanksgiving from "../../components/dashboard/Thanksgiving";
import Panel from "../../components/layout/Panel";
import useTitle from "../../hooks/useTitle";

function Dashboard() {
  useTitle("Dashboard");

  return (
    <section className="Dashboard">
      <Thanksgiving />
      <h1>Here's whats happening with your store...</h1>
      <div className="row">
        <div className="col-lg-6">
          <Panel>
            <SalesBarChartBreakDown />
          </Panel>
        </div>
        <div className="col-lg-6">
          <Panel>
            <SalesBarChart />
          </Panel>
        </div>
      </div>
      <DevBlog />
    </section>
  );
}

export default Dashboard;
