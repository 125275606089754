import { inject, observer } from "mobx-react";
import { runInAction } from "mobx";
import React, { createRef, Component, RefObject } from "react";
import { _fetch } from "../../api";
import { BASE_URL } from "../../constants/api";
import { notify } from "../../helpers/notificationHelpers";
import ButtonComponent from "../generic/ButtonComponent";
import Modal from "../generic/Modal";
import { Customer } from "./CustomerNote";
import "./EditCustomerNote.scss";

interface EditCustomerSelectProps {
  onClose: () => void;
  posStore?: {
    customerToEdit: Customer | null;
  };
}

type EditCustomerNoteState = {
  isSaving: boolean;
};

@inject("posStore")
@observer
class EditCustomerNote extends Component<
  EditCustomerSelectProps,
  EditCustomerNoteState
> {
  notesRef: RefObject<HTMLTextAreaElement>;

  constructor(props) {
    super(props);
    this.state = { isSaving: false };
    this.notesRef = createRef<HTMLTextAreaElement>();
  }

  handleSave = () => {
    this.setState({ isSaving: true });
    const payload = {
      ...this.props.posStore.customerToEdit,
      notes: this.notesRef.current.value,
    };

    _fetch({
      method: "PUT",
      endpoint: `${BASE_URL}/customers/update`,
      payload,
    })
      .then(() => {
        runInAction(() => {
          this.props.posStore.customerToEdit.notes =
            this.notesRef.current.value;
        });
        this.props.onClose();
      })
      .catch((error) => {
        console.error(error);
        notify.error("Failed to save settings");
      });
  };

  render() {
    const { onClose } = this.props;
    const { customerToEdit } = this.props.posStore;

    if (!customerToEdit) return null;

    return (
      <Modal onClose={onClose}>
        <Modal.Header>Customer Notes</Modal.Header>
        <Modal.Content>
          <textarea
            ref={this.notesRef}
            defaultValue={customerToEdit.notes}
            className="EditCustomerNote"
          />
        </Modal.Content>
        <Modal.Actions>
          <ButtonComponent secondary onClick={() => onClose()}>
            Cancel
          </ButtonComponent>
          <ButtonComponent
            primary
            onClick={() => this.handleSave()}
            disabled={this.state.isSaving}
          >
            Save Notes
          </ButtonComponent>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default EditCustomerNote;
