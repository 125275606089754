import React from "react";
import { EventInfo } from "../../../types/events";
import { TimeUtils } from "../../../utils";
import NoBanner from "../../../../assets/img/noBanner.png";
import { getChildByType } from "../../../helpers/componentHelpers";
import EventCardLinks from "./EventCardLinks";
import EventCardActions from "./EventCardActions";
import Panel from "../../layout/Panel";
import "./EventCard.scss";

interface EventCardProps {
  event: EventInfo;
  children: React.ReactNode;
}

interface EventCardComposition {
  Links: typeof EventCardLinks;
  Actions: typeof EventCardActions;
}

const EventCard: React.FunctionComponent<EventCardProps> &
  EventCardComposition = (props: EventCardProps) => {
  const { event, children } = props;
  const { calendarIcon, banner, title, game, type, date, time, recurring } =
    event;

  const eventCardLinks = getChildByType("isLinks", children);
  const eventCardActions = getChildByType("isActions", children);

  return (
    <Panel className="EventCard">
      <div className="EventCard__header">
        <span className="EventCard__ticketIcon">
          <img src={calendarIcon} alt={`${title} icon`} />
        </span>
        <img src={banner ?? NoBanner} role="presentation" />
      </div>
      <div className="EventCard__contents">
        <span className="EventCard__title">{title}</span>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <p className="EventCard__types">
                Game Type:<em>{game}</em>
              </p>
            </div>
            <div className="col-md-6">
              <p className="EventCard__types">
                Event Type:<em>{type}</em>
              </p>
            </div>
            <div className="col-md-6">
              <p className="EventCard__info" data-testid="eventDate">
                <i className="far fa-calendar-alt" />
                {recurring
                  ? `Recurring`
                  : `${TimeUtils.convertEventDate(date)}`}
              </p>
            </div>
            <div className="col-md-6">
              <p className="EventCard__info">
                <i className="far fa-clock" />
                {TimeUtils.convertTime(time)}
              </p>
            </div>
            {eventCardLinks}
          </div>
        </div>
      </div>
      {eventCardActions}
    </Panel>
  );
};

EventCard.Links = EventCardLinks;
EventCard.Actions = EventCardActions;

export default EventCard;
