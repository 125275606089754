import * as Yup from "yup";

export const kioskSettingsSchema = Yup.object().shape({
  kioskBuyModeEnabled: Yup.bool().nullable(),
  kioskBuyModeLabel: Yup.string().nullable(),
  kioskSellModeLabel: Yup.string().nullable(),
});

export const paxSettingsSchema = Yup.object().shape({
  paxSerial: Yup.string().required("Serial number cannot be empty"),
  paxIP: Yup.string(),
  dnsResolver: Yup.bool().nullable(),
  paxEnabled: Yup.bool().nullable(),
});

export const paymentGatewayApiSettingsSchema = Yup.object().shape({
  apiKey: Yup.string().required("API key cannot be empty"),
  apiPin: Yup.string()
    .optional()
    .when("paymentProvider", {
      is: "USAePay",
      then: Yup.string()
        .matches(/[0-9]+/gi)
        .required("API pin cannot be empty"),
    }),
  apiSecret: Yup.string().when("paymentProvider", {
    is: "mxMerchant",
    then: Yup.string().required("API secret cannot be empty"),
  }),
  merchantId: Yup.number().when("paymentProvider", {
    is: "mxMerchant",
    then: Yup.number().required("Merchant ID cannot be empty"),
  }),
  paymentProvider: Yup.string(),
});

export const paymentGatewayPairDeviceSettingsSchema = Yup.object().shape({
  name: Yup.string().required("Each device must have a name"),
  description: Yup.string(),
});
