import React, { useState } from "react";
import Modal from "../generic/Modal";
import "./UploadCsvHeadersModal.scss";

interface UploadCsvHeadersModalProps {
  message: string;
  optionalHeaders: string[];
  providedHeaders: string[];
  requiredHeaders: {
    csvType: string;
    headers: string[];
  }[];
  onClose: () => void;
}

function UploadCsvHeadersModal(props: UploadCsvHeadersModalProps) {
  const { optionalHeaders, providedHeaders, requiredHeaders, onClose } = props;
  const [selectedType, setSelectedType] = useState(
    requiredHeaders?.[0].csvType
  );

  const typeRequiredHeaders =
    requiredHeaders.find((headers) => headers.csvType === selectedType)
      ?.headers || [];

  const typeOptionalHeaders = optionalHeaders.filter(
    (header) => !typeRequiredHeaders.includes(header)
  );

  const invalidHeaders = providedHeaders.filter(
    (header) =>
      !typeRequiredHeaders.includes(header) && !optionalHeaders.includes(header)
  );

  return (
    <Modal onClose={onClose}>
      <Modal.Header>Unable to process CSV file</Modal.Header>
      <Modal.Content>
        <div className="UploadCsvHeadersModal">
          <h3 className="UploadCsvHeadersModal__heading">
            Sorry, we were unable to process your uploaded CSV file as it did
            not contain the correct headers.
          </h3>
          <p>
            To view details of the headers, please select from the list below
            the type of CSV file you are trying to upload.
          </p>
          <fieldset>
            <label htmlFor="csvType">Select CSV type:</label>
            <select
              id="csvType"
              className="UploadCsvHeadersModal__select"
              onChange={(event) => setSelectedType(event.target.value)}
            >
              {requiredHeaders.map((requiredHeaderList) => (
                <option
                  key={requiredHeaderList.csvType}
                  value={requiredHeaderList.csvType}
                >
                  {requiredHeaderList.csvType}
                </option>
              ))}
            </select>
          </fieldset>
          <div
            className="UploadCsvHeadersModal__headers"
            data-testid="header-details"
          >
            <p className="UploadCsvHeadersModal__headers--title">
              Your uploaded file
            </p>
            <p className="UploadCsvHeadersModal__headers--title">
              {selectedType}
            </p>
            <p className="UploadCsvHeadersModal__headers--title">Status</p>
            {invalidHeaders.map((header) => (
              <React.Fragment key={header}>
                <p className="UploadCsvHeadersModal__headers--invalid">
                  {header}
                </p>
                <p className="UploadCsvHeadersModal__headers--informational">
                  -
                </p>
                <p className="UploadCsvHeadersModal__headers--invalid">
                  Not recognized
                </p>
              </React.Fragment>
            ))}
            {typeRequiredHeaders.map((header) => (
              <React.Fragment key={header}>
                {providedHeaders.includes(header) ? (
                  <>
                    <p>{header}</p>
                    <p>
                      {header}
                      <span className="UploadCsvHeadersModal__headers--required">
                        *
                      </span>
                    </p>
                    <p className="UploadCsvHeadersModal__headers--success">
                      &#10003;
                    </p>
                  </>
                ) : (
                  <>
                    <p className="UploadCsvHeadersModal__headers--invalid">-</p>
                    <p>
                      {header}
                      <span className="UploadCsvHeadersModal__headers--required">
                        *
                      </span>
                    </p>
                    <p className="UploadCsvHeadersModal__headers--invalid">
                      Required field missing
                    </p>
                  </>
                )}
              </React.Fragment>
            ))}
            {typeOptionalHeaders.map((header) => (
              <React.Fragment key={header}>
                {providedHeaders.includes(header) ? (
                  <>
                    <p>{header} </p>
                    <p>{header} </p>
                    <p className="UploadCsvHeadersModal__headers--success">
                      &#10003;
                    </p>
                  </>
                ) : (
                  <>
                    <p>-</p>
                    <p>{header}</p>
                    <p className="UploadCsvHeadersModal__headers--informational">
                      Optional field not present
                    </p>
                  </>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
}

export default UploadCsvHeadersModal;
