import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { remapTenderName } from "../../utils/tenders";
import ButtonComponent from "../generic/ButtonComponent";
import Modal from "../generic/Modal";
import "./TenderPayment.scss";
import { ItemList } from "../../pos/ItemStore";

interface TenderPaymentProps {
  posStore?: ItemList;
  tenderType: string;
  remainingBalance: number;
  remainingStoreCredit: number;
  onAddTender: (tenderType: string, balance: number) => void;
  onClose: () => void;
}

@inject("posStore")
@observer
class TenderPayment extends Component<
  TenderPaymentProps,
  { paymentAmount: any }
> {
  constructor(props) {
    super(props);
    this.state = {
      paymentAmount: "",
    };
  }

  setPaymentAmount = (value) => {
    this.setState({ paymentAmount: value });
  };

  addPaymentAmount = (value) => {
    this.setState({
      paymentAmount: Number(this.state.paymentAmount) + Number(value),
    });
  };

  renderCashDenominations = () => {
    const { tenderType } = this.props;
    const store = this.props.posStore;

    if (tenderType.toLowerCase() !== "cash") return null;

    return (
      <div className="tenderPayment__cashDenominations">
        {store.cashDenoms.map((denomination) => (
          <button
            className="tenderPayment__cashDenomination"
            key={denomination}
            onClick={() => this.addPaymentAmount(denomination)}
          >
            {store.fCurr(denomination)}
          </button>
        ))}
      </div>
    );
  };

  getPayBalanceButton = () => {
    const { tenderType, remainingBalance, remainingStoreCredit, onAddTender } =
      this.props;
    if (remainingBalance === 0) return null;
    const { fCurr } = this.props.posStore;
    if (
      tenderType.toLowerCase() === "store credit" &&
      remainingStoreCredit < remainingBalance
    ) {
      return (
        <button
          className="tenderPayment__payRemainingButton"
          onClick={() => onAddTender(tenderType, remainingStoreCredit)}
        >
          Use all available store credit: {fCurr(remainingStoreCredit)}
        </button>
      );
    }
    return (
      <button
        className="tenderPayment__payRemainingButton"
        onClick={() => onAddTender(tenderType, remainingBalance)}
      >
        Pay remaining balance: {fCurr(remainingBalance)}
      </button>
    );
  };

  getPaymentErrors = () => {
    const { tenderType, remainingStoreCredit, remainingBalance } = this.props;
    if (tenderType.toLowerCase() === "store credit") {
      if (this.state.paymentAmount > remainingStoreCredit) {
        return "Amount exceeds the store credit available";
      }
      if (this.state.paymentAmount > remainingBalance) {
        return "Amount exceeds the balance left to pay";
      }
    }
    return null;
  };

  render() {
    const { tenderType, onAddTender, onClose } = this.props;

    const paymentErrors = this.getPaymentErrors();

    return (
      <Modal small onClose={onClose}>
        <Modal.Header>{remapTenderName(tenderType)}</Modal.Header>
        <Modal.Content>
          <div className="tenderPayment__remaining">
            {this.getPayBalanceButton()}
          </div>
          <div className="tenderPayment__other">
            <label htmlFor="paymentAmount">Pay other amount:</label>
            <input
              id="paymentAmount"
              value={this.state.paymentAmount}
              onChange={(event) => this.setPaymentAmount(event.target.value)}
            />
            {this.renderCashDenominations()}
          </div>
          {paymentErrors !== null ? (
            <div className="tenderPayment__error">{paymentErrors}</div>
          ) : null}
        </Modal.Content>
        <Modal.Actions>
          <ButtonComponent
            secondary
            onClick={onClose}
            icon="fal fa-times"
            iconPosition="left"
          >
            Cancel
          </ButtonComponent>
          <ButtonComponent
            primary
            icon="fas fa-caret-right"
            iconPosition="right"
            disabled={paymentErrors !== null}
            onClick={() =>
              onAddTender(tenderType, Number(this.state.paymentAmount))
            }
          >
            Continue
          </ButtonComponent>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default TenderPayment;
