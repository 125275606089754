import firebase from "firebase/app";
import "firebase/auth";
import "firebase/remote-config";
import { deleteUserToken } from "../helpers/authHelpers";
import { getFirebaseConfig } from "../utils/ConfigUtils";

const firebaseConfig = getFirebaseConfig();

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app();
}

export const auth = firebase.auth();

const provider = new firebase.auth.GoogleAuthProvider();

export const signInWithGoogle = () => {
  auth.signInWithPopup(provider);
};

export const signOut = () => auth.signOut().then(deleteUserToken);

export const getRemoteConfig = async (flag: string) => {
  const remoteConfig = firebase.remoteConfig();
  if (firebaseConfig.projectId === "binderpos-dev") {
    // During development, it's recommended to set a low minimum fetch interval
    remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
  }
  await remoteConfig.fetchAndActivate();
  return remoteConfig.getValue(flag);
};
