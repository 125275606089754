import { StoreSettings } from "../../types/settings";
import SettingsSection from "./SettingsSection";
import ReadOnlySettingField from "./ReadOnlySettingField";
import "./GeneralPricingSection.scss";

interface GeneralPricingSectionProps {
  settings: StoreSettings;
}

const GeneralPricingSection = ({ settings }: GeneralPricingSectionProps) => {
  const { siteUrl, email, currency, priceMultiplier } = settings || {};

  return (
    <SettingsSection title="General Settings">
      <div className="GeneralPricingSection">
        <div className="GeneralPricingSection__row">
          <ReadOnlySettingField title="Shopify URL" value={siteUrl} />
          <ReadOnlySettingField title="Main Email" value={email} />
        </div>
        <div className="GeneralPricingSection__row">
          <ReadOnlySettingField title="Store Currency" value={currency} />
          {priceMultiplier ? (
            <ReadOnlySettingField
              title="Price Markup"
              value={priceMultiplier}
            />
          ) : null}
        </div>
      </div>
    </SettingsSection>
  );
};

export default GeneralPricingSection;
