import React from "react";
import Big from "big.js";

import "./ReceiptComponent.scss";
import TimeUtils from "../../utils/TimeUtils";
import { CartWithTotals, ShopifyCustomer } from "../../types/order";
import {
  ReceiptFields,
  ReceiptLanguage,
  ReceiptSettings,
} from "../../types/settings";
import { USE_NEW_RECEIPT_STYLING } from "../../constants/featureFlags";
import { RECEIPT_FIELDS } from "../../constants/settings";
import { useFeatureFlag } from "../../hooks/globalSettingsHooks";
import { useFormatCurrency, useStoreDetails } from "../../hooks/storeHooks";

interface ReceiptComponentProps {
  receiptLanguage: ReceiptLanguage;
  receiptSettings: ReceiptSettings;
  customerSettings: CustomerSettings;
  cart: CartWithTotals;
  selectedCustomer: ShopifyCustomer;
  completedReceipt: boolean;
  changeDue?: string;
  giftReceipt?: boolean;
}

const getDefaultLabelValues = (fields: {
  [key: string]: { placeholder: string };
}) => {
  const entries = Object.entries(fields);
  return Object.fromEntries(
    entries.map(([key, value]) => [key, value.placeholder])
  ) as ReceiptFields;
};

const defaultLabelValues = getDefaultLabelValues(RECEIPT_FIELDS);

const getSelectedLabelValues = (
  selectedLanguage: ReceiptLanguage,
  receiptSettings: ReceiptSettings
) => {
  if (!receiptSettings) {
    return {};
  }
  const customLabels =
    selectedLanguage === ReceiptLanguage.Second
      ? receiptSettings.additionalLanguageFields
      : receiptSettings.defaultLanguageFields;
  return Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(customLabels).filter(([_, value]) => value != "")
  );
};
const getLabelValues = (
  selectedLanguage: ReceiptLanguage,
  receiptSettings: ReceiptSettings,
  taxWording: string
) => {
  const selectedLangValues = getSelectedLabelValues(
    selectedLanguage,
    receiptSettings
  );
  return {
    ...defaultLabelValues,
    taxWording,
    ...selectedLangValues,
  };
};

const getCustomMessage = (
  selectedLanguage: ReceiptLanguage,
  receiptSettings: ReceiptSettings
) => {
  if (selectedLanguage === ReceiptLanguage.Second) {
    return (
      receiptSettings?.additionalLanguageFields?.customMessage || undefined
    );
  }
  return receiptSettings?.defaultLanguageFields?.customMessage || undefined;
};

const getLabelForTenderType = (tenderType: string, labels: ReceiptFields) => {
  if (tenderType == "Credit") {
    return labels.creditCard;
  }
  if (tenderType === "Store Credit") {
    return labels.storeCredit;
  }
  if (tenderType === "EFTPOS") {
    return labels.eftpos;
  }
  return tenderType === "Cash" ? labels.cash : tenderType;
};

const ReceiptComponent = ({
  customerSettings,
  receiptLanguage,
  receiptSettings,
  selectedCustomer,
  completedReceipt,
  changeDue,
  cart,
  giftReceipt,
}: ReceiptComponentProps) => {
  const formatCurrency = useFormatCurrency();
  const { storeDetails } = useStoreDetails();
  const customMessage = getCustomMessage(receiptLanguage, receiptSettings);
  const labelValues = getLabelValues(
    receiptLanguage,
    receiptSettings,
    customerSettings?.taxWording ?? "Tax"
  );

  const useNewReceiptStyling = useFeatureFlag(USE_NEW_RECEIPT_STYLING);
  const baseClass = useNewReceiptStyling
    ? "receipt-component"
    : "receipt-component-legacy";

  const receiptTime = cart?.dateSubmitted || TimeUtils.nowTime();

  return (
    <div
      className={`${baseClass} ${giftReceipt ? ` ${baseClass}--gift` : ""}`}
      data-testid="receipt-component"
    >
      <div className={`${baseClass}__wrapper`}>
        <span className={`${baseClass}__name`}>{storeDetails?.storeName}</span>
        <span className={`${baseClass}__store-details`}>
          {labelValues.tel}: {storeDetails?.phoneNumber}
        </span>
        <span className={`${baseClass}__store-details`}>
          {storeDetails?.addressLine1}
        </span>
        <span className={`${baseClass}__store-details`}>
          {storeDetails?.addressLine2}
        </span>
        <span className={`${baseClass}__store-details`}>
          {storeDetails?.city} {storeDetails?.state}{" "}
        </span>
        <span className={`${baseClass}__store-details`}>
          {storeDetails?.country} {storeDetails?.zipCode}{" "}
        </span>
        {customerSettings?.taxNumber ? (
          <div className={`${baseClass}__store-details tax-number`}>
            {labelValues.taxWording + " " + customerSettings.taxNumber}
          </div>
        ) : null}
        {giftReceipt ? (
          <span className={`${baseClass}__gift-label`}>
            {labelValues.giftReceipt}
          </span>
        ) : null}
        {selectedCustomer && !giftReceipt ? (
          <div
            className={`${baseClass}__transaction-details selected-customer`}
          >
            <span>
              {selectedCustomer.firstName} {selectedCustomer.lastName}
            </span>
            <em>
              {labelValues.credit}
              {": "}
              {formatCurrency(selectedCustomer.storeCredit)}
            </em>
          </div>
        ) : null}
        <div className={`${baseClass}__transaction-details sale-id`}>
          <span>
            {labelValues.sale} {cart.id}
          </span>
          <em>
            {labelValues.till}: {cart.till.name}
          </em>
        </div>
        {completedReceipt ? (
          <div className={`${baseClass}__transaction-details dateTime`}>
            <span>
              {TimeUtils.convertDateToHumanReadableNoTime(receiptTime)}
            </span>
            <em>{TimeUtils.convertTime(receiptTime)}</em>
          </div>
        ) : (
          <div className={`${baseClass}__transaction-details dateTime`}>
            <span>CART NOT YET SUBMITTED</span>
          </div>
        )}
        <hr className={`${baseClass}__divider`} />
        <table className={`${baseClass}__table`}>
          <tbody>
            {cart.cartItems.map((item, index) => (
              <tr
                key={index}
                data-testid="receipt-item"
                className={`${baseClass}__item-row`}
              >
                <td className="item__qty qty">{item.quantity}</td>
                <td className="item__title">
                  <span>{item.productTitle}</span>
                  <em>
                    {["-", "default title"].includes(
                      item?.variantTitle?.toLowerCase()
                    )
                      ? ""
                      : " - [" + item.variantTitle + "]"}
                  </em>
                </td>
                {!giftReceipt ? (
                  <td className="item__price price">
                    {item.discountAmount ? (
                      <del>{formatCurrency(item.actualPrice)}</del>
                    ) : null}
                    <span>{formatCurrency(item.price / item.quantity)}</span>
                    <em className="lineTotal">{formatCurrency(item.price)}</em>
                  </td>
                ) : null}
              </tr>
            ))}
          </tbody>
        </table>
        {completedReceipt ? (
          <span className={`${baseClass}__item-count`}>
            ** {cart.totalItems} {labelValues.itemsPurchased} **
          </span>
        ) : (
          <span className={`${baseClass}__item-count`}>
            ** {cart.totalItems} Items on this draft **
          </span>
        )}

        {!giftReceipt ? (
          <>
            <hr className={`${baseClass}__divider inverse`} />
            {cart.globalDiscount.amount ? (
              <div className={`${baseClass}__transaction-details discount`}>
                <span>
                  {labelValues.discount}{" "}
                  {cart.globalDiscount.type == "percentage"
                    ? "(-" + cart.globalDiscount.value + "%)"
                    : "(-" + customerSettings?.currencySymbol + ")"}
                </span>
                <em>{formatCurrency(cart.discountAmount)}</em>
              </div>
            ) : null}
            <div className={`${baseClass}__transaction-details sub-total`}>
              <span>{labelValues.subTotal}</span>
              <em>
                {formatCurrency(Number(cart.totals.cartSubTotalWithDiscount))}
              </em>
            </div>

            <div className={`${baseClass}__transaction-details tax`}>
              <span>
                {labelValues.taxWording} ({customerSettings?.taxRate}%)
              </span>
              <em>{formatCurrency(Number(cart.totalTax))}</em>
            </div>
            {
              <React.Fragment>
                {cart.cartIntegratedPayments
                  .filter((card) => card?.surchargeFee != null)
                  .map((card) => (
                    <div
                      className="receipt-component__transaction-details surcharge"
                      key={card.id}
                    >
                      <span>{labelValues.creditCardSurcharge}</span>
                      <em>{formatCurrency(card?.surchargeFee)}</em>
                    </div>
                  ))}
                <div className="receipt-component__transaction-details total">
                  <span>{labelValues.total}:</span>
                  <em>
                    {cart.totals?.cartTotalWithDiscount
                      ? formatCurrency(
                          cart?.cartIntegratedPayments
                            .reduce(
                              (bin, card) =>
                                Big(bin).plus(Big(card.surchargeFee || 0)),
                              Big(0)
                            )
                            .plus(Big(cart.totals.cartTotalWithDiscount))
                        )
                      : null}
                  </em>
                </div>
              </React.Fragment>
            }
            <hr className={`${baseClass}__divider`} />
            {cart.tenders.map((tender, index) => (
              <React.Fragment key={index}>
                {tender.amount != 0 ? (
                  <div
                    className={`${baseClass}__transaction-details payment smaller`}
                  >
                    <span>
                      {getLabelForTenderType(tender.type, labelValues)}
                    </span>
                    <em>{formatCurrency(tender.amount)}</em>
                  </div>
                ) : null}
              </React.Fragment>
            ))}
            {changeDue ? (
              <span className={`${baseClass}__transaction-details change`}>
                {labelValues.change}: <em>{formatCurrency(changeDue)}</em>
              </span>
            ) : null}
          </>
        ) : null}
        {customMessage ? (
          <div className={`${baseClass}__custom-message`}>{customMessage}</div>
        ) : null}
      </div>
    </div>
  );
};

ReceiptComponent.defaultProps = {
  receiptLanguage: ReceiptLanguage.First,
  changeDue: undefined,
  giftReceipt: false,
};

export default ReceiptComponent;
