import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { ReportsMenuStructure } from "../../menuStructures";
import {
  CartNoteRenderer,
  NumberInputFilter,
  TextInputFilter,
} from "../../components/reports/CellTypes";
import { API_STATE } from "../../constants/api";
import { Pagination, ReportControls } from "../../components/reports";
import SetActiveMenu from "../../menuStructures/SetActiveMenu";
import { useReportsDetails } from "../../hooks/reportsHooks";
import { useShowError } from "../../hooks/errorHooks";
import { cartNotesCSVHeaders } from "../../constants/reportCSVHeaders";
import { rowPerPageOptions } from "../../constants/reports";
import { addLocalizedDate } from "../../transformers/helpers/addLocalizedDate";
import { useFetchSetting } from "../../hooks/settingHooks";
import { useFetchCartNotes } from "../../hooks/reports/useFetchCartNotes";
import { Table, Column, Cell } from "rsuite-table";
import "rsuite-table/dist/css/rsuite-table.css";
import "../../../css/rstable-overwrites.scss";
import CustomHeaderCell from "../../components/reports/CustomHeader";

function CartNotes(props) {
  const { location, history } = props;
  const query = new URLSearchParams(location.search);
  const defaultFromDate = query.get("fromDate");
  const defaultToDate = query.get("toDate");
  const {
    apiState,
    currentPage,
    filterValues,
    overrideTableHeight,
    rowsPerPage,
    sortColumn,
    tableData,
    computedTableData,
    reset,
    setApiState,
    setCurrentPage,
    setTableRowsPerPage,
    setFilters,
    setSortingColumn,
    setSourceDataDetails,
    updateFilterValue,
  } = useReportsDetails();
  const showError = useShowError();
  const [customFields, setCustomFields] = useState<string[]>([]);

  useFetchSetting("customFields", {
    onSuccess: (data: any) => {
      try {
        const settingValue = JSON.parse(data);
        if (settingValue !== null && settingValue !== undefined) {
          setCustomFields(settingValue);
        }
      } catch (err) {
        showError(err, "Failed to load custom note field names");
      }
    },
  });

  const {
    data: sourceData,
    isFetching,
    error,
  } = useFetchCartNotes(
    {
      startDate: query.get("fromDate"),
      toDate: query.get("toDate"),
      limit: 999999,
    },
    {
      enabled: !!query.get("fromDate") && !!query.get("toDate"),
    }
  );

  useEffect(() => {
    document.title = "Point of Sale Cart Notes | BinderPOS";
    reset();
    setFilters(filters);
  }, []);

  useEffect(() => {
    if (query.has("fromDate")) {
      handleDateChange(
        query.get("fromDate"),
        query.get("toDate"),
        query.get("timezone")
      );
    }
  }, [location]);

  useEffect(() => {
    if (isFetching) {
      setApiState(API_STATE.LOADING);
    } else if (error) {
      showError(
        error as any,
        "Failed to load report",
        "There was an error retrieving your cart notes report. Please try again"
      );
      setApiState(API_STATE.ERROR);
    } else if (sourceData) {
      const cartsWithNotes = sourceData.filter((row) => row.cartNotes !== null);
      setSourceDataDetails(cartsWithNotes);
      setApiState(API_STATE.SUCCESS);
    }
  }, [isFetching, sourceData, error]);

  const filters = [
    {
      name: "id",
      condition: (filterValues) => filterValues?.id,
      filterFunction: (row, filterValues) =>
        row.id && String(row.id).includes(filterValues?.id),
    },
    {
      name: "shopifyOrderId",
      condition: (filterValues) => filterValues?.shopifyOrderId,
      filterFunction: (row, filterValues) =>
        row.shopifyOrderId &&
        String(row.shopifyOrderId).includes(filterValues?.shopifyOrderId),
    },
    {
      name: "customerName",
      condition: (filterValues) => filterValues?.customerName,
      filterFunction: (row, filterValues) =>
        row.customerName?.includes(filterValues?.customerName),
    },
    {
      name: "cartNotes",
      condition: (filterValues) => filterValues?.cartNotes,
      filterFunction: (row, filterValues) =>
        row.cartNotes?.includes(filterValues?.cartNotes),
    },
  ];

  const handleDateChange = (fromDate, toDate, tz) => {
    const params = new URLSearchParams();
    if (fromDate) params.set("fromDate", fromDate);
    if (toDate) params.set("toDate", toDate);
    if (tz) params.set("timezone", tz);
    history.push({
      search: "?" + params.toString(),
    });
  };

  const {
    id = "",
    shopifyOrderId = "",
    customerName = "",
    cartNotes = "",
  } = filterValues;

  const csvHeaders = cartNotesCSVHeaders(customFields);
  const csvFilename = "Carts Notes Report.csv";

  const data = addLocalizedDate(
    computedTableData(),
    query.get("timezone"),
    "dateSubmitted"
  );

  return (
    <>
      <SetActiveMenu menuStructure={ReportsMenuStructure} />
      <div className="app-header">
        <h1 className="title">
          <span className="header-text">Cart Notes Reports</span>
        </h1>
      </div>
      <div className="report">
        <ReportControls
          setDateRange={handleDateChange}
          csvHeaders={csvHeaders}
          csvFilename={csvFilename}
          defaultFromDate={defaultFromDate}
          defaultToDate={defaultToDate}
        />
        {apiState !== API_STATE.INITIAL && (
          <div className="data-table">
            <div className="table-container">
              <Table
                data={data}
                headerHeight={80}
                renderEmpty={() => <div>No data to display</div>}
                loading={apiState == API_STATE.LOADING}
                onSortColumn={setSortingColumn}
                sortColumn={sortColumn.key}
                sortType={sortColumn.order}
                className="rs-table--multi-header"
                height={overrideTableHeight}
                fillHeight={overrideTableHeight === 0}
              >
                <Column key="id" width={110} sortable resizable>
                  <CustomHeaderCell title="Cart #">
                    <NumberInputFilter
                      value={id}
                      setValue={(value) => updateFilterValue("id", value)}
                      title="Filter by cart ID"
                      aria-label="Filter by cart ID"
                    />
                  </CustomHeaderCell>
                  <Cell dataKey="id">
                    {(rowData) => (
                      <Link
                        to={`/pointOfSale/carts/${rowData.id}`}
                        className="viewCart"
                      >
                        {rowData.id}
                      </Link>
                    )}
                  </Cell>
                </Column>
                <Column key="shopifyOrderId" width={140} sortable resizable>
                  <CustomHeaderCell title="Shopify Order #">
                    <NumberInputFilter
                      value={shopifyOrderId}
                      setValue={(value) =>
                        updateFilterValue("shopifyOrderId", value)
                      }
                      title="Filter by order ID"
                      aria-label="Filter by order ID"
                    />
                  </CustomHeaderCell>
                  <Cell dataKey="shopifyOrderId" />
                </Column>
                <Column
                  key="dateSubmitted_localized"
                  width={160}
                  sortable
                  resizable
                >
                  <CustomHeaderCell title="Date Submitted" />
                  <Cell dataKey="dateSubmitted">
                    {(rowData) => rowData.dateSubmitted_localized}
                  </Cell>
                </Column>
                <Column key="customer" width={120} sortable resizable>
                  <CustomHeaderCell title="Customer">
                    <TextInputFilter
                      value={customerName}
                      setValue={(value) =>
                        updateFilterValue("customerName", value)
                      }
                      title="Filter by customer"
                      aria-label="Filter by customer"
                    />
                  </CustomHeaderCell>
                  <Cell dataKey="customerName" />
                </Column>
                <Column key="cartNotes" width={240} flexGrow={1} resizable>
                  <CustomHeaderCell title="Cart Notes">
                    <TextInputFilter
                      value={cartNotes}
                      setValue={(value) =>
                        updateFilterValue("cartNotes", value)
                      }
                      title="Filter by notes"
                      aria-label="Filter by notes"
                    />
                  </CustomHeaderCell>
                  <Cell dataKey="cartNotes">
                    {(rowData) =>
                      CartNoteRenderer({
                        cellData: rowData?.cartNotes,
                        noteField: "note",
                      })
                    }
                  </Cell>
                </Column>
                {[...customFields].map((customFieldName) => (
                  <Column
                    key={`cartNotes_customField_${customFieldName}`}
                    width={150}
                    flexGrow={1}
                    resizable
                  >
                    <CustomHeaderCell title={customFieldName} />
                    <Cell dataKey="cartNotes">
                      {(rowData) =>
                        CartNoteRenderer({
                          cellData: rowData[customFieldName],
                          noteField: customFieldName,
                        })
                      }
                    </Cell>
                  </Column>
                ))}
              </Table>
            </div>
            <Pagination
              apiState={apiState}
              currentPage={currentPage}
              totalRowCount={tableData.length}
              rowsPerPage={rowsPerPage}
              rowPerPageOptions={rowPerPageOptions}
              setCurrentPage={setCurrentPage}
              setRowsPerPage={setTableRowsPerPage}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default withRouter(CartNotes);
