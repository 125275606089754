import React from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { EbayPayment } from "../../api/rest/ebay";
import ButtonComponent from "../../components/generic/ButtonComponent";
import CheckboxComponent from "../generic/CheckboxComponent";
import { validateRequiredFields } from "../../utils/forms";
import "./EbayPaymentMethodsForm.scss";

const initialValues = {
  name: "",
  immediatePay: true,
  paymentPolicyId: undefined,
  policyUrl: "",
} as EbayPayment;

const requiredFields = ["name"];
const validate = (values: EbayPayment) =>
  validateRequiredFields(requiredFields, values);

const generateInput = (
  fieldName: string,
  fieldLabel: string,
  autoFocus?: boolean
) => (
  <>
    <label id={fieldName}>
      {fieldLabel}
      {requiredFields.includes(fieldName) ? (
        <span className="ebay-payment-methods-form__required">*</span>
      ) : (
        ""
      )}
    </label>
    <Field name={fieldName} aria-labelledby={fieldName} autoFocus={autoFocus} />
    <ErrorMessage
      name={fieldName}
      className="ebay-payment-methods-form__field-error"
      component="div"
    />
  </>
);

interface EbayPaymentMethodFormProps {
  paymentSetting?: EbayPayment;
  setEditSetting: Function;
  saveSetting: Function;
}

function EbayPaymentMethodsForm(props: EbayPaymentMethodFormProps) {
  const { paymentSetting, setEditSetting, saveSetting } = props;

  return (
    <div className="ebay-payment-methods-form">
      <Formik
        initialValues={paymentSetting || initialValues}
        validate={validate}
        onSubmit={(values, { setSubmitting }) =>
          saveSetting(values, setSubmitting)
        }
      >
        {({ values, isSubmitting, setFieldValue }) => (
          <Form>
            <div className="ebay-payment-methods-form__inputs">
              {generateInput("name", "Payment Setting Name", true)}
              <label htmlFor="immediatePay">Immediate Pay</label>
              <CheckboxComponent
                name="immediatePay"
                label=""
                id="immediatePay"
                checked={values.immediatePay}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue("immediatePay", event.target.checked);
                }}
              />
              <ErrorMessage
                name="immediatePay"
                className="ebay-payment-methods-form__field-error"
                component="div"
              />
              {generateInput("policyUrl", "Policy URL")}
            </div>
            <div className="ebay-payment-methods-form__actions">
              <ButtonComponent onClick={() => setEditSetting()}>
                Cancel
              </ButtonComponent>
              <ButtonComponent primary type="submit" disabled={isSubmitting}>
                Save
              </ButtonComponent>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default EbayPaymentMethodsForm;
