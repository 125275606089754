import { UseQueryOptions, QueryKey } from "react-query";
import {
  GetSalesByOrderQuery,
  GetSalesByOrderQueryVariables,
  useGetSalesByOrderQuery,
} from "../../generated/reporting";
import { formatSalesByOrder } from "../../transformers/reports/formatSalesByOrder";
import { SalesByOrderData } from "../../types/reports";

export const useFetchSalesByOrder = (
  variables: GetSalesByOrderQueryVariables,
  options: UseQueryOptions<
    GetSalesByOrderQuery,
    unknown,
    SalesByOrderData,
    QueryKey
  > = {}
) =>
  useGetSalesByOrderQuery(variables, {
    select: (report) => formatSalesByOrder(report),
    keepPreviousData: true,
    staleTime: 60_000,
    ...options,
  });
