import { useMutation, useQueryClient } from "react-query";
import {
  updateGamePriceRule,
  updateGamePriceFloor,
  createGamePriceFloor,
  deleteGamePriceFloor,
  updateGlobalPriceRules,
} from "../api/rest/pricing";
import { updateMyVariant } from "../api/rest/settings";

import { GamePriceRule, RarityFloor } from "../types/settings";

export const useUpdateMyVariant = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    ({ variantId, multiplier }: { variantId: number; multiplier: number }) =>
      updateMyVariant(variantId, multiplier),
    {
      onSuccess: (response) => {
        const { id, multiplier } = response;
        queryClient.setQueryData(["customerVariants"], (oldData: any = []) => {
          const newData = oldData.map((old: any) => {
            if (old.id === id) {
              return {
                ...old,
                multiplier,
              };
            }
            return old;
          });
          return newData;
        });
      },
    }
  );
  return { isMutating, ...rest };
};

export const useUpdateGamePriceRule = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    (gamePriceRule: Omit<GamePriceRule, "gameName" | "rarityFloors">) =>
      updateGamePriceRule(gamePriceRule),
    {
      onSuccess: (response) => {
        const { pricing } = response;
        queryClient.setQueryData(
          ["priceRulesSettings", 2],
          (oldData: any = []) => {
            const newData = oldData.map((old: any) => {
              if (old.game === pricing.game) {
                return {
                  ...old,
                  ...pricing,
                };
              }
              return old;
            });
            return newData;
          }
        );
      },
    }
  );
  return { isMutating, ...rest };
};

export const useUpdateGlobalPriceRules = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    (priceRules: { roundToNearest: number }) =>
      updateGlobalPriceRules(priceRules),
    {
      onSuccess: (response) => {
        queryClient.setQueryData(
          ["defaultPriceRulesSettings", 3],
          (prevData: any = []) => {
            return { ...prevData, ...response };
          }
        );
      },
    }
  );
  return { isMutating, ...rest };
};

export const useUpdateRarityPriceFloor = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    ({
      rarityFloor,
      gameCode,
    }: {
      rarityFloor: RarityFloor;
      gameCode: string;
    }) => {
      const { id, price } = rarityFloor;

      if (price === 0 && (id !== null || id !== undefined)) {
        return deleteGamePriceFloor({
          gameCode,
          rarityFloorId: rarityFloor.id,
        });
      }

      if ((id === null || id === undefined) && price !== 0) {
        return createGamePriceFloor({
          gameCode,
          rarityFloor,
        });
      }

      return updateGamePriceFloor({
        gameCode,
        rarityFloor,
      });
    },
    {
      onSuccess: (response, variables) => {
        const { gameCode, rarityFloor } = variables;
        const { id, price } = rarityFloor;
        queryClient.setQueryData(["priceRulesSettings", 2], (oldData: any) => {
          const newData = oldData.map((old: any) => {
            if (old.game === gameCode) {
              if (old.rarityFloors.some((v: any) => v.id === id)) {
                // Rarity floor already exists, so we modify it
                return {
                  ...old,
                  rarityFloors: old.rarityFloors
                    .map((floor: any) => {
                      if (floor.id === id) {
                        return {
                          ...floor,
                          price,
                        };
                      }
                      return floor;
                    })
                    .filter((floor: any) => floor.price !== 0),
                };
              } else {
                // Rarity floor does not exist, so we add it to the cache array
                return {
                  ...old,
                  rarityFloors: [...old.rarityFloors, response],
                };
              }
            }
            return old;
          });
          return newData;
        });
      },
    }
  );
  return { isMutating, ...rest };
};
