import { observable, action } from "mobx";
import {
  fetchBuylists,
  fetchBuylistDetails,
  fetchFailedToSyncProducts,
  fetchBuylistRules,
} from "../api/rest/buylist";

class BuylistRulesStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable pendingBuylists;

  @action setPendingBuylists = (pendingBuylists) => {
    this.pendingBuylists = pendingBuylists;
  };

  @observable approvedBuylists;

  @action setApprovedBuylists = (approvedBuylists) => {
    this.approvedBuylists = approvedBuylists;
  };

  @observable completedBuylists;

  @action setCompletedBuylists = (completedBuylists) => {
    this.completedBuylists = completedBuylists;
  };

  @observable buylistRule;

  @action setBuylistRule = (buylistRule) => {
    this.buylistRule = buylistRule;
  };

  @observable buylistRules;

  @action setBuylistRules = (buylistRules) => {
    this.buylistRules = buylistRules;
  };

  @observable buylistDetails;

  @action setBuylistDetails = (buylistDetails) => {
    this.buylistDetails = buylistDetails;
  };

  @observable failedToSyncList;

  @action setFailedToSyncList = (failedToSyncList) => {
    this.failedToSyncList = failedToSyncList;
  };

  rehydrate = () => {
    this.setBuylistRules(null);
    return fetchBuylistRules()
      .then((result) => {
        this.setBuylistRules(result);
      })
      .catch((error) => {
        this.rootStore.MainStore.setError(
          error,
          "Failed to load buylist rules",
          "There was an error retrieving your buylist rules. Please refresh and try again"
        );
      });
  };

  rehydratePendingBuylists = (offset, limit) => {
    if (!limit) {
      limit = 20;
    }
    if (!offset) {
      offset = 0;
    }
    this.setPendingBuylists(null);
    return fetchBuylists("pending")
      .then((result) => {
        this.setPendingBuylists(result);
      })
      .catch((error) => {
        this.rootStore.MainStore.setError(
          error,
          "Failed to load buylists",
          "There was an error retrieving your pending buylists. Please refresh and try again"
        );
      });
  };

  rehydrateApprovedBuylists = (offset, limit) => {
    if (!limit) {
      limit = 20;
    }
    if (!offset) {
      offset = 0;
    }
    this.setApprovedBuylists(null);
    return fetchBuylists("approved")
      .then((result) => {
        this.setApprovedBuylists(result);
      })
      .catch((error) => {
        this.rootStore.MainStore.setError(
          error,
          "Failed to load buylists",
          "There was an error retrieving your approved buylists. Please refresh and try again"
        );
      });
  };

  rehydrateCompletedBuylists = (offset, limit) => {
    if (!limit) {
      limit = 20;
    }
    if (!offset) {
      offset = 0;
    }
    this.setCompletedBuylists(null);
    return fetchBuylists("completed")
      .then((result) => {
        this.setCompletedBuylists(result);
      })
      .catch((error) => {
        this.rootStore.MainStore.setError(
          error,
          "Failed to load buylists",
          "There was an error retrieving your completed buylists. Please refresh and try again"
        );
      });
  };

  rehydrateFailedToSync = (offset, limit) => {
    this.setFailedToSyncList(null);
    return fetchFailedToSyncProducts(offset, limit)
      .then((result) => {
        this.setFailedToSyncList(result);
      })
      .catch((error) => {
        this.rootStore.MainStore.setError(
          error,
          "Failed to load buylists",
          "There was an error retrieving your buylists that have failed to sync. Please refresh and try again"
        );
      });
  };

  hydrateBuylistDetails = (id) => {
    this.setBuylistDetails(null);
    return fetchBuylistDetails(id)
      .then((result) => {
        this.setBuylistDetails(result);
      })
      .catch((error) => {
        this.rootStore.MainStore.setError(
          error,
          "Failed to load buylist details",
          "There was an error retrieving this particular buylist. Please refresh and try again"
        );
      });
  };
}

export default BuylistRulesStore;
