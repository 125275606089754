import "./ReadOnlySettingField.scss";

interface ReadOnlySettingFieldProps {
  title: string;
  value: string;
}

const ReadOnlySettingField = ({ title, value }: ReadOnlySettingFieldProps) => {
  return (
    <div className="ReadOnlySettingField">
      <label>{title}</label>
      <input
        name={title}
        disabled={true}
        className="ReadOnlySettingField__input"
        type="text"
        placeholder="BinderPOS"
        value={value}
      />
    </div>
  );
};

export default ReadOnlySettingField;
