import React, { MutableRefObject, useState } from "react";
import { useHistory } from "react-router-dom";
import { doApproveBuylist, doCompleteBuylist } from "../../api/rest/buylist";
import { useShowError } from "../../hooks/errorHooks";
import {
  useLocalSetting,
  useLocalSettingUpdate,
} from "../../hooks/localSettingsHooks";
import {
  BUYLIST_APPROVED,
  BUYLIST_COMPLETED,
} from "../../constants/eventsTracked";
import { segmentAnalytics } from "../services/Analytics";
import { BuylistDetails } from "../../types/buylist";
import ButtonComponent from "../generic/ButtonComponent";
import CheckboxComponent from "../generic/CheckboxComponent";
import Modal from "../generic/Modal";
import "./ApproveBuylistModal.scss";

const APPLY_CREDIT_SETTING = "buylistApplyStoreCredit";
const PUSH_STOCK_SETTING = "buylistPushStock";

enum ModalType {
  Approve = "approve",
  Complete = "complete",
}

interface ApproveBuylistModalProps {
  buylistType: string;
  zeroQuantityDetected: boolean;
  paymentType: string;
  buylistDetail: BuylistDetails;
  handleClose: () => void;
}

type ShopifyCustomer = {
  firstName: string;
  lastName: string;
};

const formatConfirmationMessage = (
  modalType: ModalType,
  shopifyCustomer?: ShopifyCustomer
) => {
  const action =
    modalType === ModalType.Complete ? "approved and completed" : "approved";
  const customer = shopifyCustomer
    ? `from ${[shopifyCustomer.firstName, shopifyCustomer.lastName].join(" ")}`
    : "";
  return `You have ${action} the buylist ${customer}`;
};

function ApproveBuylistModal(
  props: ApproveBuylistModalProps,
  ref: MutableRefObject<any>
) {
  const {
    buylistType,
    zeroQuantityDetected,
    paymentType,
    buylistDetail,
    handleClose,
  } = props;
  const history = useHistory();
  const showError = useShowError();
  const defaultApplyCreditSetting = Boolean(
    useLocalSetting(APPLY_CREDIT_SETTING)
  );
  const defaultPushStockSetting = Boolean(useLocalSetting(PUSH_STOCK_SETTING));

  const updateLocalSetting = useLocalSettingUpdate();
  const [isLoading, setIsLoading] = useState(false);
  const [pushProducts, setPushProducts] = useState(defaultPushStockSetting);
  const [applyStoreCredit, setApplyStoreCredit] = useState(
    defaultApplyCreditSetting
  );
  const [approvedNotes, setApprovedNotes] = useState("");
  const [showModal, setShowModal] = useState<ModalType | undefined>();

  const handleChangePushProducts = () => {
    const _pushProducts = !pushProducts;
    setPushProducts(_pushProducts);
    updateLocalSetting(PUSH_STOCK_SETTING, _pushProducts);
  };

  const handleChangeApplyStoreCredit = () => {
    const _applyStoreCredit = !applyStoreCredit;
    setApplyStoreCredit(_applyStoreCredit);
    updateLocalSetting(APPLY_CREDIT_SETTING, _applyStoreCredit);
  };

  const handleApprove = () => {
    setIsLoading(true);
    const _buylistDetail = JSON.parse(JSON.stringify(buylistDetail));
    _buylistDetail.approvedNotes = approvedNotes;
    _buylistDetail.pushProducts = pushProducts;
    if (paymentType === "Store Credit") {
      _buylistDetail.applyStoreCredit = applyStoreCredit;
    }

    let buylistAction: Function;
    let modal: ModalType;
    let buylistEventName: string;
    if (pushProducts && applyStoreCredit) {
      buylistAction = doCompleteBuylist;
      modal = ModalType.Complete;
      buylistEventName = BUYLIST_COMPLETED;
    } else {
      buylistAction = doApproveBuylist;
      modal = ModalType.Approve;
      buylistEventName = BUYLIST_APPROVED;
    }

    buylistAction(_buylistDetail)
      .then(() => {
        segmentAnalytics.track(buylistEventName, { buylist_type: buylistType });
        setShowModal(modal);
      })
      .catch((error: DetailedError) => {
        showError(
          error,
          `Failed to approve buylist`,
          "There was an error submitting your buylist. Please try again"
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleConfirm = () => {
    history.push("/buylists/pending");
    handleClose();
  };

  if (showModal) {
    return (
      <Modal onClose={handleConfirm} ref={ref} small>
        <Modal.Header>
          {showModal === ModalType.Complete
            ? "Buylist Approved and Completed!"
            : "Buylist Approved"}
        </Modal.Header>
        <Modal.Content>
          {formatConfirmationMessage(showModal, buylistDetail?.shopifyCustomer)}
        </Modal.Content>
      </Modal>
    );
  }

  return (
    <>
      <Modal ref={ref} onClose={handleClose} isLoading={isLoading}>
        <Modal.Header>Approve buylist request</Modal.Header>
        <Modal.Content>
          <form noValidate>
            {zeroQuantityDetected ? (
              <div className="warning">
                <i className="fas fa-exclamation-triangle" />
                One of more cards in this buylist have no quantity set. To go
                back and correct this, click <em>Cancel</em>.
              </div>
            ) : null}
            <div className="ApproveBuylistModal">
              <label className="label">
                Do you want to add any notes? These will be sent to the customer
                once approved.
              </label>
              <div className="control">
                <textarea
                  className="ApproveBuylistModal__notes-field"
                  placeholder="E.g. Most cards are in great condition! Thank you."
                  value={approvedNotes}
                  onChange={(event) => setApprovedNotes(event.target.value)}
                />
              </div>
              <p>
                Checking both 'Push stock automatically' and 'Apply store
                credit' will mark the buylist as completed
              </p>
              <CheckboxComponent
                name="pushProducts"
                label="Push stock automatically"
                checked={pushProducts ?? false}
                onChange={handleChangePushProducts}
              />
              <br />
              {paymentType === "Store Credit" ? (
                <CheckboxComponent
                  name="storeCredit"
                  label="Apply store credit"
                  checked={applyStoreCredit ?? false}
                  onChange={handleChangeApplyStoreCredit}
                />
              ) : null}
            </div>
          </form>
        </Modal.Content>
        <Modal.Actions>
          <ButtonComponent secondary onClick={handleClose} disabled={isLoading}>
            Cancel
          </ButtonComponent>
          <ButtonComponent
            primary
            disabled={isLoading}
            onClick={() => handleApprove()}
          >
            {isLoading ? "Processing" : "Approve"}
          </ButtonComponent>
        </Modal.Actions>
      </Modal>
    </>
  );
}

export default React.forwardRef(ApproveBuylistModal);
