import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import moment from "moment";
import "moment-timezone";

import { FetchEbayStockChangeInfoParams } from "../../api/rest/ebay";
import { useFetchConditions } from "../../hooks/ebayHooks";
import Input from "../../components/form/Input";
import Select from "../../components/form/Select";
import Modal from "../generic/Modal";
import ButtonComponent from "../generic/ButtonComponent";
import { replaceNullWithDefault } from "../../helpers/formHelpers";
import CategoryContainer from "../../views/integrations/ebay/CategoryContainer";
import "./EbayStockChangeFilters.scss";

const formatDateTime = (dateTime: string) => moment(dateTime).toISOString();

const dateFields = [
  "lastUpdatedAfter",
  "lastUpdatedBefore",
  "confirmedOnEbayAfter",
  "confirmedOnEbayBefore",
];

export const defaultStockChangeValues: FetchEbayStockChangeInfoParams = {
  productNameLike: "",
  priceGreaterThan: "",
  priceLessThan: "",
  qtyGreaterThan: "",
  qtyLessThan: "",
  lastUpdatedAfter: "",
  lastUpdatedBefore: "",
  confirmedOnEbayAfter: "",
  confirmedOnEbayBefore: "",
  hasError: "",
  location: "",
  categoryName: "",
  ebayCondition: "",
};

interface EbayStockChangeFiltersProps {
  visible?: boolean;
  filters: Partial<FetchEbayStockChangeInfoParams>;
  setFilters: (newFilters: Partial<FetchEbayStockChangeInfoParams>) => void;
}

function EbayStockChangeFilters(props: EbayStockChangeFiltersProps) {
  const { visible, filters, setFilters } = props;
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const { data: conditions } = useFetchConditions();

  if (!visible) return null;

  const handleSubmit = (values: Partial<FetchEbayStockChangeInfoParams>) =>
    setFilters(
      Object.fromEntries(
        Object.entries(values)
          // Remove empty fields
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .filter(
            ([_fieldName, value]) => value !== "" && value !== "undefined"
          )
          // Convert dates to datetimes for API
          .map(([fieldName, value]) => [
            fieldName,
            dateFields.includes(fieldName)
              ? formatDateTime(value as string)
              : value,
          ])
      )
    );

  return (
    <div className="EbayStockChangeFilters">
      <Formik
        initialValues={replaceNullWithDefault(
          filters,
          defaultStockChangeValues
        )}
        onSubmit={handleSubmit}
      >
        {({ dirty, touched, values }) => (
          <Form>
            <div className="row">
              <div className="col-lg-12">
                <Field
                  component={Input}
                  name="productNameLike"
                  label="Product title"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-lg-3">
                <Field
                  component={Input}
                  name="priceGreaterThan"
                  type="number"
                  label="Price greater than"
                  min={0}
                  step={0.01}
                />
              </div>
              <div className="col-sm-6 col-lg-3">
                <Field
                  component={Input}
                  name="priceLessThan"
                  type="number"
                  label="Price less than"
                  min={0}
                  step={0.01}
                />
              </div>
              <div className="col-sm-6 col-lg-3">
                <Field
                  component={Input}
                  name="qtyGreaterThan"
                  type="number"
                  label="Quantity greater than"
                  min={0}
                />
              </div>
              <div className="col-sm-6 col-lg-3">
                <Field
                  component={Input}
                  name="qtyLessThan"
                  type="number"
                  label="Quantity less than"
                  min={0}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-lg-3">
                <Field
                  component={Input}
                  type="date"
                  name="lastUpdatedAfter"
                  label="Last updated after"
                />
              </div>
              <div className="col-sm-6 col-lg-3">
                <Field
                  component={Input}
                  type="date"
                  name="lastUpdatedBefore"
                  label="Last updated before"
                />
              </div>
              <div className="col-sm-6 col-lg-3">
                <Field
                  component={Input}
                  type="date"
                  name="confirmedOnEbayAfter"
                  label="Confirmed on eBay after"
                />
              </div>
              <div className="col-sm-6 col-lg-3">
                <Field
                  component={Input}
                  type="date"
                  name="confirmedOnEbayBefore"
                  label="Confirmed on eBay before"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-lg-3">
                <Field component={Select} name="hasError" label="Status">
                  <option value="">All</option>
                  <option value="false">Success</option>
                  <option value="true">Error</option>
                </Field>
              </div>
              <div className="col-sm-6 col-lg-3">
                <Field
                  component={Select}
                  name="ebayCondition"
                  label="Condition"
                >
                  <option key="">Any condition</option>
                  {conditions?.map((condition) => (
                    <option key={condition.code} value={condition.code}>
                      {condition.description}
                    </option>
                  ))}
                </Field>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="EbayStockChangeFilters__category">
                  <label className="label">
                    {values.categoryName
                      ? `Category: ${values.categoryName}`
                      : "Category"}
                  </label>
                  <ButtonComponent
                    primary
                    onClick={() => setShowCategoryModal(true)}
                  >
                    Select Category
                  </ButtonComponent>
                </div>
              </div>
            </div>
            <ButtonComponent
              primary
              fullWidth
              disabled={!Object.keys(touched).length && !dirty}
              type="submit"
            >
              Filter
            </ButtonComponent>
            {showCategoryModal ? (
              <Modal onClose={() => setShowCategoryModal(false)}>
                <Modal.Header>Select Category</Modal.Header>
                <Modal.Content>
                  <CategoryContainer categoryIdOverride="ebayCategoryId" />
                </Modal.Content>
              </Modal>
            ) : null}
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default EbayStockChangeFilters;
