import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { SignInForm } from "../../components";
import FullLogo from "../../../assets/img/fullLogo.png";
import Loader from "../../components/generic/Loader";
import SignInServiceStatus from "../../components/auth/SignInServiceStatus";
import { getCurrentUser } from "../../helpers/authHelpers";
import { signOut } from "../../services/firebase";
import "./LoginPage.scss";
import CookieSettings from "../../components/settings/CookieSettings";

@inject("store")
@observer
class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = { showDoNotTrack: false, showCookiePrefs: false };
  }

  render() {
    if (!this.props.store.AuthStore.user) {
      return (
        <div className="LoginPage">
          <img
            className="LoginPage__logo"
            src={FullLogo}
            alt="BinderPOS Logo"
          />
          <div className="LoginPage__wrapper">
            <SignInForm />
          </div>
          <div className="LoginPage___links">
            <a
              className="LoginPage__link"
              href="https://www.binderpos.com/binderpos-privacy-policy"
              target="_blank"
              rel="noopener"
            >
              Privacy Policy
            </a>
            {" |"}
            <button
              className="LoginPage__link"
              onClick={() => this.setState({ showCookiePrefs: true })}
            >
              Cookie Preferences
            </button>
            {"|"}
            <button
              className="LoginPage__link"
              onClick={() => this.setState({ showDoNotTrack: true })}
            >
              Do Not Sell or Share my Personal Information
            </button>
          </div>
          {this.state.showDoNotTrack ? (
            <CookieSettings
              onClose={() => this.setState({ showDoNotTrack: false })}
            />
          ) : null}
          {this.state.showCookiePrefs ? (
            <CookieSettings
              titleOverride="Cookie Preferences"
              onClose={() => this.setState({ showCookiePrefs: false })}
            />
          ) : null}
        </div>
      );
    }

    const currentUser = getCurrentUser();

    return (
      <div className="LoginPage">
        <img className="LoginPage__logo" src={FullLogo} alt="BinderPOS Logo" />
        <div className="LoginPage__wrapper">
          {this.props.store.AuthStore.screenSettings == "No account" ? (
            <>
              <p className="title recover">
                Not a<em>BinderPOS</em> account
              </p>
              <p className="wrongAccount">
                {currentUser.email} does not have an account with BinderPOS.
              </p>
              <button
                className="signin"
                onClick={() => {
                  signOut();
                  this.props.store.AuthStore.logUserOut();
                }}
              >
                Go back
              </button>
            </>
          ) : null}
          {this.props.store.AuthStore.screenSettings === "Error" ? (
            <SignInServiceStatus
              signOut={() => {
                signOut();
                this.props.store.AuthStore.logUserOut();
              }}
            />
          ) : null}
          {this.props.store.AuthStore.screenSettings !== "No account" &&
          this.props.store.AuthStore.screenSettings !== "Error" ? (
            <>
              <Loader />
            </>
          ) : null}
        </div>
      </div>
    );
  }
}

export default LoginPage;
