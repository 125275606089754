import { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import IconButton from "../generic/IconButton";
import "./SupportMenu.scss";
import { SUPPORT_URL, SUPPORT_REDIRECT_URL } from "../../constants/support";
import { ADABOT_BINDER_PORTAL } from "../../constants/featureFlags";
import { useFeatureFlag } from "../../hooks/globalSettingsHooks";
import { useStoreDetails } from "../../hooks/storeHooks";
import { segmentAnalytics } from "../services/Analytics";
import { SUPPORT_CLICKED } from "../../constants/eventsTracked";
import {
  handleHelpbotLaunch,
  handleReportABugLaunch,
} from "../../helpers/adaBotHelpers";

const SupportMenu = () => {
  const [showModal, setShowModal] = useState(false);
  const adaBotBinderPortal = useFeatureFlag(ADABOT_BINDER_PORTAL);
  const customerDetails = useStoreDetails();

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setShowModal(false);
      }
    };
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  // Feature flag support for ADABOT_BINDER_PORTAL not being enabled.
  const legacySupportButtonAction = () => {
    segmentAnalytics.track(SUPPORT_CLICKED, {
      support_type: "TCGPlayer Helpbot",
    });
    window.open(SUPPORT_REDIRECT_URL, "_blank");
  };

  if (!adaBotBinderPortal) {
    return (
      <IconButton
        aria-label="Support"
        icon="fas fa-user-headset"
        className="DesktopHeader__navIcon"
        onClick={legacySupportButtonAction}
        title="Support"
        data-testid="legacySupportIconButton"
      />
    );
  }

  // Feature flag support for ADABOT_BINDER_PORTAL being enabled.
  return (
    <OutsideClickHandler
      onOutsideClick={() => setShowModal(false)}
      display={"inline-block"}
    >
      <div
        className="SupportMenu__container"
        data-testid="supportMenuContainer"
      >
        <IconButton
          icon="fas fa-question-circle"
          className="DesktopHeader__navIcon"
          onClick={() => setShowModal(!showModal)}
          aria-label="Support"
          title="Support"
          role="button"
        />
        <div
          className={
            showModal
              ? "SupportMenu__modal SupportMenu__modal--open"
              : "SupportMenu__modal"
          }
        >
          {/* Freshdesk */}
          <a
            href={SUPPORT_URL}
            target="_blank"
            rel="noopner"
            className="SupportMenu__modal-item"
            tabIndex={showModal ? 0 : -1}
            aria-label="Help Files"
            title="Help Files"
            role="link"
            onClick={() =>
              segmentAnalytics.track(SUPPORT_CLICKED, {
                support_type: "Freshdesk Help Files",
              })
            }
          >
            <i className="fas fa-question-circle SupportMenu__icon" />
            Help Files
            <i className="fas fa-external-link SupportMenu__icon-secondary" />
          </a>
          {/* Ada at the general "start" */}
          <div
            className="SupportMenu__modal-item"
            tabIndex={showModal ? 0 : -1}
            onClick={() => handleHelpbotLaunch(customerDetails)}
            onKeyDown={(event) =>
              event.key === "Enter"
                ? handleHelpbotLaunch(customerDetails)
                : null
            }
            aria-label="Helpbot"
            title="Helpbot"
            role="button"
          >
            <i className="fas fa-comments SupportMenu__icon" />
            Helpbot
          </div>
          {/* Ada with bug-reporting context */}
          <div
            className="SupportMenu__modal-item"
            tabIndex={showModal ? 0 : -1}
            onClick={() => handleReportABugLaunch(customerDetails)}
            onKeyDown={(event) =>
              event.key === "Enter"
                ? handleReportABugLaunch(customerDetails)
                : null
            }
            aria-label="Report Bug"
            title="Report Bug"
            role="button"
          >
            <i className="fas fa-bug SupportMenu__icon" />
            Report Bug
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default SupportMenu;
