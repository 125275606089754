import { determineLineItemTaxRate } from "../../utils/pos/tax";

/**
 * @param cart cart
 * @param taxIncluded from customer settings
 * @param customerTaxRate from customer settings
 * @returns object containing calculated totals
 */
export function calculateCartTotals(cart, taxIncluded, taxRateSetting: number) {
  const rawCustomerTaxRate =
    cart.taxRate !== null ? Number(cart.taxRate) : taxRateSetting;
  const customerTaxRate = rawCustomerTaxRate ? rawCustomerTaxRate / 100 : 0;

  const lineTotals = cart.CartItem.map((item) => {
    // override taxrate from tags (AT{}val)
    const taxRate = item.tags
      ? determineLineItemTaxRate(item.tags, customerTaxRate)
      : customerTaxRate;
    const isBuying = !!item.buying;
    const actualPrice = Number(item.actualPrice);

    const discountValue = Math.abs(Number(item.discountValue));
    const quantity = Math.abs(Number(item.quantity));

    const linePrice = actualPrice * quantity - discountValue;
    if (isBuying) {
      return { isBuying, total: linePrice };
    }
    const exlPrice =
      taxIncluded && taxRate ? linePrice / (1 + taxRate) : linePrice;
    return { isBuying, total: exlPrice };
  }).reduce((total, lineTotal) => {
    return total + lineTotal.total;
  }, 0);

  const cartDiscount = Math.abs(Number(cart.discountAmount) ?? 0);
  const cartTax = Number(cart.totalTax) ?? 0;
  const allLinesTotals = lineTotals - cartDiscount;
  const tax = allLinesTotals * (1 + customerTaxRate) - allLinesTotals;
  const totalTax = tax < 0 ? 0 : tax;
  const grandTotal = roundCents(allLinesTotals + totalTax);
  const cartTotalWithDiscount =
    cart.totalPrice !== null ? cart.totalPrice - cartDiscount : grandTotal;
  return {
    totalTax: roundCents(totalTax),
    subTotal: roundCents(allLinesTotals),
    grandTotal,
    cartSubTotalWithDiscount: roundCents(cartTotalWithDiscount - cartTax),
    cartTotalWithDiscount: roundCents(cartTotalWithDiscount),
  };
}

function roundCents(amount: number) {
  return Number(amount.toFixed(2));
}
