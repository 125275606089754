import { deployThemeVersion } from "../api/rest/themes";
import { useMutation } from "react-query";

type DeployThemeVersionVariables = {
  theme: string;
  version: string;
};

export const useDeployThemeVersion = () =>
  useMutation(({ theme, version }: DeployThemeVersionVariables) =>
    deployThemeVersion(theme, version)
  );
