import React from "react";

const getDetailedMessage = (error: DetailedError) => {
  if (error.error === "Not Found") {
    return "Unable to find the resource you are looking for.";
  }
  return error.detailedMessage;
};

interface DetailedErrorProps {
  errorDetails: DetailedError;
}

function DetailedError(props: DetailedErrorProps) {
  const { errorDetails } = props;

  const hasValidMessage = errorDetails?.error !== undefined;

  if (hasValidMessage) {
    return <div className="field">{getDetailedMessage(errorDetails)}</div>;
  }

  return (
    <div className="field">
      An unknown error has occurred, please go back and try again. If the
      problem persists, contact BinderPOS support.
    </div>
  );
}

export default DetailedError;
