import * as Yup from "yup";

export const priceRuleSchema = Yup.object().shape({
  priceMarkup: Yup.number().typeError("Value must be a multiplier."),
  priceMultiplier: Yup.number()
    .positive("Value must be greater than 0")
    .nullable()
    .typeError("Value must be a multiplier."),
  updatePricing: Yup.boolean(),
  tcgPlayerPriceType: Yup.string(),
  useTCGPlayerPricing: Yup.string(),
});

export const rarityFloorsSchema = Yup.object({
  rarityFloors: Yup.array().of(
    Yup.object({
      id: Yup.number().optional().nullable(),
      price: Yup.number()
        .typeError("Value must be a number")
        .transform((value) => Number(value) || 0),
      rarity: Yup.string().required(),
    })
  ),
});
