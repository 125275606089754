import React from "react";
import "./Card.scss";

type HeadingLevel = "h1" | "h2" | "h3" | "h4" | "h5";
interface CardProps {
  title?: any;
  children?: any;
  style?: any;
  headingLevel?: HeadingLevel;
}

function Card(props: CardProps) {
  const { title, children, style = {}, headingLevel = "h5" } = props;
  const Heading = headingLevel;
  return (
    <div className="card" style={{ marginBottom: "1rem", ...style }}>
      <div className="card-body">
        {title && <Heading className="card-title">{title}</Heading>}
        {children && <div className="card-text">{children}</div>}
      </div>
    </div>
  );
}

export default Card;
