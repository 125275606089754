import React from "react";

interface CartTypeSelectProps {
  value: string;
  setValue: (value: string) => void;
  title: string;
}

function CartTypeSelect({
  setValue,
  value,
  title,
  ...props
}: CartTypeSelectProps) {
  const cartTypes = [
    { value: "pos", text: "POS" },
    { value: "kiosk", text: "Kiosk" },
    { value: "return", text: "Return" },
  ];
  return (
    <select
      onChange={(event) => setValue(event.target.value)}
      value={value}
      title={title}
      {...props}
    >
      <option value="">- All -</option>
      {cartTypes.map((till) => (
        <option value={till.value} key={till.value}>
          {till.text}
        </option>
      ))}
    </select>
  );
}

export default CartTypeSelect;
