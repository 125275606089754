import { action } from "mobx";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import BuyLimitWarning from "./BuyLimitWarning";
import LineDiscount from "./LineDiscount";
import { isKioskMode } from "../../helpers/posModeHelpers";
import NoImage from "../../../assets/img/noimage.png";
import BuyIcon from "../../../assets/img/buy.svg";
import SellIcon from "../../../assets/img/sell.svg";
import { roundCents } from "../../utils/currencyHelpers";
import { ItemList } from "../../pos/ItemStore";
import { LineItem } from "../../types/pos";
import QuantitySelector from "../generic/QuantitySelector";

interface CartLineProps {
  posStore?: ItemList;
  item: LineItem;
}
@inject("posStore")
@observer
class CartLine extends Component<CartLineProps, { discountModal: Boolean }> {
  selfRef: HTMLDivElement;
  qtyInput: HTMLInputElement;
  discountInput: HTMLInputElement;

  constructor(props) {
    super(props);
    this.state = { discountModal: false };
  }

  componentDidMount() {
    if (typeof this.selfRef.scrollIntoView !== "function") return;
    this.selfRef.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }

  @action.bound
  updateQty(e) {
    if (this.props.item.qty == e.target.value) return;
    const qty = e.target.value;
    if (qty > this.maxAvailableQuantity()) {
      this.qtyInput.value = this.props.item.qty.toString();
      return;
    }
    const delta = Number(qty) - this.props.item.qty;
    if (Number.isFinite(delta)) {
      this.updateInventory(delta);
    }
    if (qty <= 0) {
      this.deleteLine();
    } else {
      this.props.item.qty = e.target.value;
      this.props.posStore.validateCartNoRefresh();
    }
  }

  @action
  changeQty(increase) {
    clearTimeout(this.props.posStore.timer);
    if (increase) {
      this.props.item.qty = this.props.item.qty + 1;
      this.updateInventory(1);
    } else {
      this.updateInventory(-1);
      if (this.props.item.qty - 1 <= 0) {
        return this.deleteLine();
      } else {
        this.props.item.qty = this.props.item.qty - 1;
      }
    }
    this.props.posStore.validateCartNoRefresh();
  }

  @action updateInventory = (quantity) => {
    const item = this.props.posStore.items.find((_item) =>
      _item.variants.some((variant) => variant.id == this.props.item.variantId)
    );
    if (!item) return;
    if (this.props.item.buyItem && item.selectedBuyVariant > -1) {
      item.variants[item.selectedBuyVariant].quantity += quantity;
    }
    if (!this.props.item.buyItem && item.selectedVariant > -1) {
      item.variants[item.selectedVariant].quantity -= quantity;
    }
  };

  @action
  deleteLine = () => {
    const originalQty = this.props.item.qty;
    this.props.posStore.removeFromCart(this.props.item);
    const item = this.props.posStore.items.find((_item) =>
      _item.variants.some((variant) => variant.id == this.props.item.variantId)
    );
    if (!item) return;
    if (this.props.item.buyItem && item.selectedBuyVariant > -1) {
      item.variants[item.selectedBuyVariant].quantity -= originalQty;
    }
    if (!this.props.item.buyItem && item.selectedVariant > -1) {
      item.variants[item.selectedVariant].quantity += originalQty;
    }
  };

  @action
  toggleTax = () => {
    if (isKioskMode()) {
      return;
    }
    if (this.props.item.taxDisabledUI === false) {
      this.props.item.taxDisabledUI = true;
    } else {
      this.props.item.taxDisabledUI = false;
    }
    this.props.posStore.validateCartNoRefresh();
  };

  openDiscountModal = () => {
    this.setState({ discountModal: !this.state.discountModal });
    this.toggled = true;
  };

  closeDiscountModal = () => {
    this.setState({ discountModal: false });
    // this.toggleTax = false;
    this.props.posStore.validateCartNoRefresh();
  };

  @action
  updatePrice = (e) => {
    if (this.props.item.actualPrice == e.target.value) return;
    this.props.item.actualPrice = parseFloat(e.target.value);
    if (isNaN(this.props.item.actualPrice)) {
      this.props.item.actualPrice = 0.0;
    }
    if (this.props.item.buyItem && this.props.item.actualPrice > 0) {
      this.props.item.actualPrice = -1 * this.props.item.actualPrice;
    }
    if (!this.props.item.buyItem && this.props.item.actualPrice < 0) {
      this.props.item.actualPrice = -1 * this.props.item.actualPrice;
    }
    this.props.posStore.validateCartNoRefresh();
  };

  @action
  updateTotal = (e) => {
    if (this.props.item.actualPrice == e.target.value / this.props.item.qty)
      return;
    this.props.item.actualPrice = e.target.value / this.props.item.qty;
    if (isNaN(this.props.item.actualPrice)) {
      this.props.item.actualPrice = 0.0;
    }
    if (this.props.item.buyItem && this.props.item.actualPrice > 0) {
      this.props.item.actualPrice = -1 * this.props.item.actualPrice;
    }
    this.props.posStore.validateCartNoRefresh();
  };

  blurOnEnter = (e) => {
    if (e.key === "Enter") {
      e.target.blur();
    }
  };
  toggled = false;

  setDiscountInput = (x) => {
    this.discountInput = x;
    if (this.toggled && this.discountInput) {
      this.discountInput.focus();
      this.discountInput.select();
      this.toggled = false;
    }
  };

  maxAvailableQuantity = () => {
    if (!isKioskMode() || this.props.item.buyItem) {
      return Number.MAX_SAFE_INTEGER;
    }
    return this.props.posStore.getAvailableQuantity(this.props.item);
  };

  canBuyMore = () => {
    if (!isKioskMode() || this.props.item.buyItem) {
      return true;
    }
    const availableQuantity = this.props.posStore.getAvailableQuantity(
      this.props.item
    );
    return availableQuantity > this.props.item.qty;
  };

  render() {
    const item = this.props.item;
    const fC = this.props.posStore.fCurr;
    let itemName: string;
    let setName: string;
    if (item.title?.match(/(.+) \[(.+)\]/)) {
      itemName = item.title.match(/(.+) \[(.+)\]/)[1];
      setName = item.title.match(/(.+) \[(.+)\]/)[2];
    } else {
      itemName = item.title;
    }

    const removeButton = (
      <button className="remove" onClick={this.deleteLine}>
        <i className="fad fa-trash-alt" /> Remove
      </button>
    );

    return (
      <React.Fragment>
        <div
          role="listitem"
          className={
            (!item.buyItem ? "cartItem buy" : "cartItem sell") +
            (this.props.posStore.disableLineItems ||
            this.props.posStore.cartLoading ||
            this.props.posStore.isDeletingCartItem
              ? " disabled"
              : "")
          }
          ref={(el) => {
            this.selfRef = el;
          }}
        >
          <img
            className="img"
            src={item.imageUrl ? item.imageUrl : NoImage}
            role="presentation"
          />
          {!item.buyItem ? (
            <img className="cartBadge" src={BuyIcon} role="presentation" />
          ) : (
            <img className="cartBadge" src={SellIcon} role="presentation" />
          )}
          <div className="itemWrapper">
            <div className="data">
              <div className="titles">
                {itemName} <br />
                <strong>{setName}</strong>{" "}
                <small>
                  {item.variantTitle != "-"
                    ? "- [" + item.variantTitle + "]"
                    : ""}
                </small>
                {!item.variantId ? "[Custom Item]" : ""}
              </div>
              {!isKioskMode() ? removeButton : null}
              <div className="inputs">
                {isKioskMode() ? (
                  <div className="kioskItemPrice">
                    {parseFloat(String(item.tax.displayPrice)).toFixed(2)}
                  </div>
                ) : (
                  <input
                    aria-label="Tax value"
                    type="number"
                    onBlur={this.updatePrice}
                    onKeyPress={this.blurOnEnter}
                    defaultValue={parseFloat(
                      String(item.tax.displayPrice)
                    ).toFixed(2)}
                    key={`displayPrice-${item.tax.displayPrice}`}
                    data-testid="tax-input"
                  />
                )}
                {isKioskMode() ? (
                  <div className="kioskItemPrice">
                    {parseFloat(String(item.displayTotal)).toFixed(2)}
                  </div>
                ) : (
                  <input
                    aria-label="Total value"
                    className="lineTotal"
                    type="number"
                    onBlur={this.updateTotal}
                    onKeyPress={this.blurOnEnter}
                    defaultValue={item.displayTotal.toFixed(2)}
                    key={`displayTotal-${item.displayTotal}`}
                    data-testid="total-input"
                  />
                )}
              </div>
            </div>
            <div className="actions">
              <QuantitySelector
                secondary={item.buyItem}
                onMinusClick={() => this.changeQty(false)}
                onPlusClick={() => this.changeQty(true)}
                plusDisabled={!this.canBuyMore()}
                disabled={this.props.posStore.cartLoading}
                defaultValue={item.qty}
                key={item.qty}
                onBlur={this.updateQty}
                onKeyPress={this.blurOnEnter}
                ref={(input) => {
                  this.qtyInput = input;
                }}
              />
              {isKioskMode() ? (
                <div className="kiosk-remove-wrapper">{removeButton}</div>
              ) : (
                <button
                  className="lineDiscount"
                  onClick={this.openDiscountModal}
                >
                  {item.discountValue ? (
                    <span
                      className="ItemDiscount"
                      onClick={this.openDiscountModal}
                      role="button"
                    >
                      {item.discountType == "percentage"
                        ? item.discountAmount + "% "
                        : ""}
                      {"Discount: " + fC(roundCents(item.discountValue))}
                    </span>
                  ) : (
                    <span
                      className="ItemDiscount"
                      onClick={this.openDiscountModal}
                    >
                      {" "}
                      Add a Discount
                    </span>
                  )}
                </button>
              )}
              <div className="greybox">
                {!item.buyItem ? (
                  <button className="tax" onClick={this.toggleTax}>
                    {item.taxDisabledShopify
                      ? "Tax Free"
                      : item.taxDisabledUI
                      ? "Tax Disabled"
                      : item.specialTax
                      ? item.specialTax + " TAX"
                      : "Standard TAX"}
                    {}
                  </button>
                ) : (
                  ""
                )}
              </div>
              <a className="hidden">{item.displayTotal}</a>
              <a className="hidden">{item.qty}</a>
              <a className="hidden">{item.tax.displayPrice}</a>
            </div>
            {this.state.discountModal ? (
              <LineDiscount
                item={item}
                close={this.closeDiscountModal}
                discountInput={this.setDiscountInput}
              />
            ) : (
              ""
            )}
          </div>
          <BuyLimitWarning
            item={item}
            cashPrice={this.props.posStore.cashPrice}
            isKioskMode={isKioskMode()}
            showBuyLimitWarnings={this.props.posStore.showBuyLimitWarnings}
            cartItemBuyLimit={this.props.posStore.getCartItemBuyLimit(
              item.variantId
            )}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default CartLine;
