import { useGetAllCartsQuery } from "../generated/graphql";
import { mapOrderDetails } from "../transformers/orderDetails";

export interface OrderHistoryParams {
  cartId?: string;
  shopifyOrderId?: string;
  firstName?: string;
  lastName?: string;
  containsNote?: string;
  startDate?: string;
  toDate?: string;
  timeZone?: string;
  offset?: number;
  limit?: number;
  tillId?: number;
  sortKey?: string;
  oldestFirst?: boolean;
  sortOrder?: "asc" | "desc";
}

export const useFetchAllCarts = (params: OrderHistoryParams) => {
  const {
    offset: skip = 0,
    limit: take = 20,
    timeZone: _timeZone,
    toDate: endDate,
    sortKey: orderBy = "dateSubmitted",
    sortOrder = "desc",
    ...rest
  } = params;
  return useGetAllCartsQuery(
    {
      orderBy: [
        {
          [orderBy]: sortOrder,
        },
      ],
      skip,
      take,
      where: {
        ...rest,
        endDate,
      },
    },
    {
      staleTime: 60_000,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      select: ({ getAllCarts: orders }) =>
        orders.map((order) => {
          const { cart, selectedCustomer: customer } = mapOrderDetails(order);
          return {
            ...cart,
            customer,
          };
        }),
    }
  );
};
