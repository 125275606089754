import { _fetch } from "..";
import { API_V2_BASE_URL } from "../../constants/api";
import {
  PaymentProvider,
  TerminalResponse,
  TerminalSettings,
} from "../../types/paymentTerminal";

export const registerDevice = (
  tillId: number,
  name: string,
  description?: string,
  entryModes?: Record<string, boolean>,
  paymentMethods?: Record<string, boolean>,
  providerKey?: string,
  key?: Record<string, string>
) =>
  _fetch({
    method: "POST",
    endpoint: `${API_V2_BASE_URL}/paymentGateway/till/${tillId}/devices`,
    payload: {
      name,
      description,
      entryModes,
      paymentMethods,
      providerKey,
      key,
    },
  });

type DeviceListResponse = {
  type: string;
  limit: number;
  offset: number;
  data: TerminalResponse[];
};

export const fetchDevices = (
  tillId: number,
  offset = 0,
  limit = 20
): Promise<DeviceListResponse> =>
  _fetch({
    method: "GET",
    endpoint: `${API_V2_BASE_URL}/paymentGateway/till/${tillId}/devices?limit=${limit}&offset=${offset}`,
  });

export const fetchDeviceDetails = (
  tillId: number,
  deviceKey: string
): Promise<TerminalResponse> =>
  _fetch({
    method: "GET",
    endpoint: `${API_V2_BASE_URL}/paymentGateway/till/${tillId}/devices/${deviceKey}`,
  });

export const deleteDevice = (tillId: number, deviceKey: string) =>
  _fetch({
    method: "DELETE",
    endpoint: `${API_V2_BASE_URL}/paymentGateway/till/${tillId}/devices/${deviceKey}`,
  });

export const createTerminalSettings = (
  tillId: number,
  settings: TerminalSettings
) =>
  _fetch({
    method: "POST",
    endpoint: `${API_V2_BASE_URL}/paymentGateway/till/${tillId}/terminalSettings`,
    payload: settings,
  });

export const updateActiveDevice = (
  tillId: number,
  paymentProvider: PaymentProvider,
  deviceKey: string
) =>
  _fetch({
    method: "PUT",
    endpoint: `${API_V2_BASE_URL}/paymentGateway/till/${tillId}/activeDevice`,
    payload: {
      deviceKey,
      paymentProvider,
    },
  });
