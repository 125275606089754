import React from "react";
import Panel from "../layout/Panel";
import "./BuylistAddCardButton.scss";

interface BuylistAddCardButtonProps {
  onAddCardClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

function BuylistAddCardButton(props: BuylistAddCardButtonProps) {
  const { onAddCardClick } = props;

  return (
    <Panel className="BuylistAddCardButton">
      <button
        type="button"
        className="BuylistAddCardButton__button"
        onClick={onAddCardClick}
      >
        <i className="fal fa-plus"></i> Add a Card
      </button>
    </Panel>
  );
}

export default BuylistAddCardButton;
