import React, { ReactNode } from "react";
import { segmentAnalytics, SegmentEventProps } from "../services/Analytics";
import "./FilterTabButton.scss";
import { Link } from "react-router-dom";

type LinkProps =
  | {
      isLink: true;
      to: string;
    }
  | {
      isLink?: false;
      to?: string;
    };

interface FilterTabButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  tabName: string;
  selectedTab?: string;
}

type FilterTabButtonComponentProps = LinkProps &
  FilterTabButtonProps &
  SegmentEventProps;

function FilterTabButton(props: FilterTabButtonComponentProps) {
  const {
    children,
    tabName,
    selectedTab,
    isLink,
    to,
    segmentEventName,
    segmentEventProperties,
    onClick,
    ...rest
  } = props;

  const clickHandler = (
    event: React.MouseEvent<HTMLButtonElement & HTMLAnchorElement>
  ) => {
    if (segmentEventName) {
      segmentAnalytics.track(segmentEventName, segmentEventProperties);
    }
    onClick?.(event);
  };

  const isActive = selectedTab == tabName;

  if (isLink)
    return (
      <Link
        to={to}
        className="FilterTabButton"
        onClick={clickHandler}
        {...(rest as any)}
      >
        {children}
      </Link>
    );

  return (
    <button
      className={isActive ? "FilterTabButton active" : "FilterTabButton"}
      onClick={clickHandler}
      {...rest}
    >
      {children}{" "}
      {isActive ? (
        <i className="fas fa-sort-down" />
      ) : (
        <i className="fas fa-sort-up" />
      )}
    </button>
  );
}

export default FilterTabButton;
