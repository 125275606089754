import React, { useEffect, useRef } from "react";
import { UseQueryResult } from "react-query";
import { QueuedJobStatusParams } from "../../api/rest/jobQueue";
import { GenericQueuedJob } from "../../types/queuedJobs";

interface JobStatusProgressProps {
  jobData: GenericQueuedJob;
  updateQueuedJobStatusHandler: (jobData: GenericQueuedJob) => void;
  jobStatusUpdater: (
    params: QueuedJobStatusParams
  ) => UseQueryResult<GenericQueuedJob, unknown>;
}

const JobStatusProgress = ({
  jobData,
  updateQueuedJobStatusHandler,
  jobStatusUpdater,
}: JobStatusProgressProps) => {
  const pollInterval = useRef<NodeJS.Timer>();
  const { refetch } = jobStatusUpdater({
    jobId: jobData?.jobId,
    type: jobData?.type,
  });

  useEffect(() => {
    keepPollingForStatus();
    return () => clearInterval(pollInterval.current);
  }, []);

  const keepPollingForStatus = () => {
    if (jobData.status !== "COMPLETED" && !pollInterval.current) {
      const interval = 10_000 + jobData.id;
      pollInterval.current = setInterval(() => {
        refetch().then(({ data }) => {
          if (data?.status === "COMPLETED") {
            clearInterval(pollInterval.current);
            updateQueuedJobStatusHandler(data);
          }
        });
      }, interval);
    }
  };

  return (
    <div className="jobQueueList__progress">
      <div className="progress-bar">
        <div
          className="progress-bar-fill"
          style={{
            width: jobData.jobQueueStatus
              ? jobData.jobQueueStatus.progress + "%"
              : "100.00%",
          }}
        ></div>
      </div>
      {jobData.jobQueueStatus
        ? `${jobData.jobQueueStatus.progress}% Loaded`
        : "100% Loaded"}
    </div>
  );
};

export default JobStatusProgress;
