import React, { useEffect, useState } from "react";
import { EbayOrderDetails, fetchOrderDetails } from "../../../api/rest/ebay";
import Loader from "../../../components/generic/Loader";
import { useStoreDetails } from "../../../hooks/storeHooks";
import { formatLocalCurrency } from "../../../utils/formatting";
import "./EbayOrderDetailsContainer.scss";
import TimeUtils from "../../../utils/TimeUtils";

const parseError = (err: { data: string }, setErrorText: Function) => {
  try {
    const errObj = JSON.parse(err.data);
    if (errObj.message) setErrorText(errObj.message);
  } catch (e) {
    // no-op
  }
};

interface EbayOrderDetailsContainerProps {
  id: number;
}

function EbayOrderDetailsContainer(props: EbayOrderDetailsContainerProps) {
  const { id } = props;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState<string>();
  const [order, setOrder] = useState<EbayOrderDetails>();
  const { currencyCode = "USD" } = useStoreDetails();

  useEffect(() => {
    fetchOrderDetails(id)
      .then((data: EbayOrderDetails) => setOrder(data))
      .catch((err) => {
        setError(true);
        parseError(err, setErrorText);
      })
      .finally(() => setLoading(false));
  }, []);

  if (loading) return <Loader />;

  if (error) return <p>{errorText ? errorText : "Unable to load details"}</p>;

  return (
    <div className="ebay-order-details">
      <div className="ebay-order-details__section-header">
        Order {order?.orderId}
      </div>
      <div className="ebay-order-details__section">
        <div className="ebay-order-details__group">
          <div className="ebay-order-details__label">Payment Status:</div>
          <div className="ebay-order-details__value">
            {order?.orderPaymentStatus}
          </div>
        </div>
        <div className="ebay-order-details__group">
          <div className="ebay-order-details__label">Fulfillment Status:</div>
          <div className="ebay-order-details__value">
            {order?.orderFulfillmentStatus}
          </div>
        </div>
      </div>
      <div className="ebay-order-details__section">
        <div className="ebay-order-details__group">
          <div className="ebay-order-details__label">Username:</div>
          <div className="ebay-order-details__value">
            {order?.buyer?.username}
          </div>
        </div>
      </div>
      <div className="ebay-order-details__section">
        <div className="ebay-order-details__group">
          <div className="ebay-order-details__label">Order Created:</div>
          <div className="ebay-order-details__value">
            {TimeUtils.convertDateToHumanReadableNoTime(
              order?.creationDate * 1000
            )}{" "}
            {TimeUtils.convertTime(order?.creationDate * 1000)}
          </div>
        </div>
        <div className="ebay-order-details__group">
          <div className="ebay-order-details__label">Last Updated:</div>
          <div className="ebay-order-details__value">
            {TimeUtils.convertDateToHumanReadableNoTime(
              order?.lastModifiedDate * 1000
            )}{" "}
            {TimeUtils.convertTime(order?.lastModifiedDate * 1000)}
          </div>
        </div>
      </div>
      <div className="ebay-order-details__section">
        <table>
          <thead>
            <tr>
              <th>Title</th>
              <th>Sku</th>
              <th>Quantity</th>
              <th>Price</th>
              <th>Fulfillment Status</th>
            </tr>
          </thead>
          <tbody>
            {order?.lineItems
              ? order.lineItems.map((lineItem) => (
                  <tr key={lineItem.lineItemId}>
                    <td>{lineItem.title}</td>
                    <td>{lineItem.sku}</td>
                    <td>{lineItem.quantity}</td>
                    <td>
                      {formatLocalCurrency(
                        Number(lineItem.total?.value),
                        currencyCode
                      )}
                    </td>
                    <td>{lineItem.lineItemFulfillmentStatus}</td>
                  </tr>
                ))
              : null}
          </tbody>
        </table>
      </div>
      <div className="ebay-order-details__section">
        <div className="ebay-order-details__group">
          <div className="ebay-order-details__label">Delivery cost:</div>
          <div className="ebay-order-details__value">
            {formatLocalCurrency(
              order?.pricingSummary?.deliveryCost?.value
                ? Number(order.pricingSummary.deliveryCost.value)
                : 0,
              currencyCode
            )}
          </div>
        </div>
        <div className="ebay-order-details__group">
          <div className="ebay-order-details__label">Subtotal:</div>
          <div className="ebay-order-details__value">
            {formatLocalCurrency(
              order?.pricingSummary?.priceSubtotal?.value
                ? Number(order.pricingSummary.priceSubtotal.value)
                : 0,
              currencyCode
            )}
          </div>
        </div>
        <div className="ebay-order-details__group">
          <div className="ebay-order-details__label">Tax:</div>
          <div className="ebay-order-details__value">
            {formatLocalCurrency(
              order?.pricingSummary?.tax?.value
                ? Number(order.pricingSummary.tax.value)
                : 0,
              currencyCode
            )}
          </div>
        </div>
        <div className="ebay-order-details__group">
          <div className="ebay-order-details__label">Total:</div>
          <div className="ebay-order-details__value">
            {formatLocalCurrency(
              order?.pricingSummary?.total?.value
                ? Number(order.pricingSummary.total.value)
                : 0,
              currencyCode
            )}
          </div>
        </div>
        <div className="ebay-order-details__group">
          <div className="ebay-order-details__label">Fees:</div>
          <div className="ebay-order-details__value">
            {formatLocalCurrency(
              order?.totalMarketplaceFee?.value
                ? Number(order.totalMarketplaceFee.value)
                : 0,
              currencyCode
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EbayOrderDetailsContainer;
