import React, { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { MenuStructure } from "../../types/nav";
import "./MobileNavItem.scss";
import { segmentAnalytics } from "../services/Analytics";
import { LEGACY_MOBILE_MENU_CLICKED } from "../../constants/eventsTracked";

interface MobileNavItemProps {
  menuLink: string;
  menuIcon: string;
  menuHeaderText: string;
  menuStructure?: MenuStructure | null;
  handleClick: (menuStructure?: MenuStructure | null | undefined) => void;
}

function MobileNavItem(props: MobileNavItemProps) {
  const { menuLink, menuIcon, menuHeaderText, menuStructure, handleClick } =
    props;

  const navItemRef = useRef<HTMLDivElement>();

  const [isNavItemOpen, setNavItemOpen] = useState(false);

  if (!menuStructure || menuStructure.links.length < 1) {
    return (
      <NavLink
        className="MobileNavItem__header"
        activeClassName="active"
        to={menuLink}
        onClick={() => {
          segmentAnalytics.track(LEGACY_MOBILE_MENU_CLICKED, {
            menu_title: menuHeaderText,
          });
          handleClick(null);
        }}
      >
        <i className={menuIcon} /> {menuHeaderText}
      </NavLink>
    );
  }

  return (
    <>
      <li onClick={() => setNavItemOpen(!isNavItemOpen)}>
        <div className="MobileNavItem__header">
          <i className={menuIcon} /> {menuHeaderText}
          {isNavItemOpen ? (
            <i className="MobileNavItem__chevron fas fa-chevron-up" />
          ) : (
            <i className="MobileNavItem__chevron fas fa-chevron-down" />
          )}
        </div>
        <div
          className="MobileNavItem__wrapper"
          ref={navItemRef}
          style={
            isNavItemOpen
              ? { height: navItemRef.current?.scrollHeight }
              : { height: 0 }
          }
        >
          {menuStructure?.links?.map((link) => (
            <div className="MobileNavItem__subItem" key={link.href}>
              <NavLink
                className="MobileNavItem__subItemLink"
                activeClassName="active"
                to={`/${link.href}`}
                onClick={() => {
                  segmentAnalytics.track(LEGACY_MOBILE_MENU_CLICKED, {
                    menu_title: link.title,
                  });
                  handleClick(menuStructure);
                }}
              >
                <span title={link.title}>{link.title}</span>
              </NavLink>
            </div>
          ))}
        </div>
      </li>
    </>
  );
}

export default MobileNavItem;
