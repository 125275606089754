import React from "react";
import ButtonComponent from "../generic/ButtonComponent";
import Loader from "../generic/Loader";
import Modal from "../generic/Modal";
import {
  useFetchEventParticipant,
  useRemoveEventParticipant,
} from "../../hooks/eventHooks";
import { useHistory, useParams } from "react-router-dom";

type RouteParams = {
  eventId: string;
  participantId: string;
};

function RemoveParticipantModal() {
  const { eventId, participantId } = useParams<RouteParams>();
  const history = useHistory();
  const { isLoading, data: participant } = useFetchEventParticipant({
    eventId,
    participantId,
  });
  const { isMutating, mutateAsync } = useRemoveEventParticipant();

  const onClose = () => {
    history.push(`/events/participants/${eventId}`);
  };

  const handleRemove = () =>
    mutateAsync(
      { eventId, participantId },
      {
        onSuccess: onClose,
      }
    );

  if (isLoading) {
    return (
      <Modal onClose={onClose}>
        <Modal.Header>Remove participant</Modal.Header>
        <Modal.Content>
          <Loader />
        </Modal.Content>
      </Modal>
    );
  }

  return (
    <Modal onClose={onClose} isLoading={isMutating}>
      <Modal.Header>Remove participant</Modal.Header>
      <Modal.Content>
        <p>
          Are you sure you want to remove{" "}
          {participant?.participantName ?? "the participant"} from this event?
        </p>
        <p>
          Please note that removing a participant will not refund any ticket
          purchase payment.
        </p>
      </Modal.Content>
      <Modal.Actions>
        <ButtonComponent
          icon="fal fa-times"
          iconPosition="right"
          onClick={onClose}
        >
          Cancel
        </ButtonComponent>
        <ButtonComponent
          disabled={isMutating}
          danger
          onClick={handleRemove}
          icon="fas fa-caret-right"
          iconPosition="right"
        >
          Remove Participant
        </ButtonComponent>
      </Modal.Actions>
    </Modal>
  );
}

export default RemoveParticipantModal;
