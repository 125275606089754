import React from "react";
import { Route, Switch } from "react-router-dom";
import AdvancedSettings from "../views/integrations/AdvancedSettings";
import BasicSettings from "../views/integrations/BasicSettings";
import CfbMarketplaceConfigureView from "../views/integrations/cfbmarket/CfbMarketplaceConfigureView";
import EbayIntegrationView from "../views/integrations/ebay/EbayIntegrationView";
import EbayLogsView from "../views/integrations/ebay/EbayLogsView";
import EbayPoliciesContext from "../views/integrations/ebay/EbayPoliciesContext";
import ReviewVariants from "../views/integrations/ReviewVariants";

function IntegtrationPlatformRouter() {
  return (
    <Switch>
      <Route exact path="/integrations/:integration">
        <EbayPoliciesContext>
          <BasicSettings />
        </EbayPoliciesContext>
      </Route>
      <Route exact path="/integrations/:integration/settings/:settingId?">
        <EbayPoliciesContext>
          <BasicSettings />
        </EbayPoliciesContext>
      </Route>
      <Route exact path="/integrations/:integration/advanced">
        <EbayPoliciesContext>
          <AdvancedSettings />
        </EbayPoliciesContext>
      </Route>
      <Route exact path="/integrations/:integration/variants">
        <EbayPoliciesContext>
          <ReviewVariants />
        </EbayPoliciesContext>
      </Route>
      <Route path="/integrations/ebay/policies">
        <EbayIntegrationView />
      </Route>
      <Route path="/integrations/ebay/logs">
        <EbayLogsView />
      </Route>
      <Route path="/integrations/cfbmarket/configure">
        <CfbMarketplaceConfigureView />
      </Route>
    </Switch>
  );
}

export default IntegtrationPlatformRouter;
