import { ErrorMessage, Field } from "formik";
import React, { useEffect, useState } from "react";
import { EbayFulfillment, EbayPayment, EbayReturn } from "../../api/rest/ebay";
import CategoryContainer from "../../views/integrations/ebay/CategoryContainer";
import { useEbayPolicies } from "../../views/integrations/ebay/EbayPoliciesContext";
import { useStoreDetails } from "../../hooks/storeHooks";
import ListingTemplateContainer from "../../views/integrations/ebay/ListingTemplateContainer";
import EbayCustomAttributes from "./EbayCustomAttributes";
import EbayDescriptionEditor from "./EbayDescriptionEditor";
import "./EbayListingForm.scss";
import { useFeatureFlag } from "../../hooks/globalSettingsHooks";
import { EBAY_CONDITION_MAPPING } from "../../constants/featureFlags";
import EbayConditionMappingModal from "./EbayConditionMappingModal";
import ButtonComponent from "../generic/ButtonComponent";

type PolicyType = EbayFulfillment | EbayLocation | EbayReturn | EbayPayment;

export type ListingType = "sealed" | "single" | "other";

const CCG_INDIVIDUAL_CARDS_CATEGORY_ID = "183454";
const CCG_INDIVIDUAL_CARDS_CATEGORY_NAME = "CCG Individual Cards";

const getPolicies = (policies: PolicyType[]) =>
  policies.map((policy: PolicyType) => (
    <option key={policy.id} value={policy.id}>
      {policy.name}
    </option>
  ));

const getOptions = (options: EbayParam[]) =>
  options.map((option: EbayParam) => (
    <option key={option.code} value={option.code}>
      {option.description}
    </option>
  ));

interface EbayListingFormProps {
  integration: string;
  game: string;
  vatPercentage?: string | number;
  applyTax?: boolean;
  setFieldValue: Function;
  listingType: ListingType;
}

function EbayListingForm(props: EbayListingFormProps) {
  const {
    integration,
    game,
    listingType,
    vatPercentage,
    applyTax,
    setFieldValue,
  } = props;
  const { taxRate } = useStoreDetails();
  const [showConditionModal, setShowConditionModal] = useState(false);
  const autoConditionMappingEnabled = useFeatureFlag(EBAY_CONDITION_MAPPING);

  if (integration !== "ebay") return null;

  const {
    locationPolicies,
    paymentPolicies,
    fulfillmentPolicies,
    returnsPolicies,
    conditions,
    defaultMarketplace,
    // weightMeasurementUnits,
    // lengthMeasurementUnits,
    // packageTypes,
  } = useEbayPolicies();

  const presetCategoryId = (listingType) => {
    if (listingType === "single") {
      setFieldValue("categoryId", CCG_INDIVIDUAL_CARDS_CATEGORY_ID);
      setFieldValue("categoryName", CCG_INDIVIDUAL_CARDS_CATEGORY_NAME);
      return;
    }
    setFieldValue("categoryId", "");
    setFieldValue("categoryName", "");
  };

  useEffect(() => {
    if (!vatPercentage || String(vatPercentage).length < 1) {
      setFieldValue("vatPercentage", taxRate);
    }
  }, [taxRate]);

  useEffect(() => {
    // Set default policies
    if (locationPolicies && locationPolicies.length === 1) {
      setFieldValue("merchantLocationKey", locationPolicies[0].id);
    }
    if (paymentPolicies && paymentPolicies.length === 1) {
      setFieldValue("paymentPolicyId", paymentPolicies[0].id);
    }
    if (fulfillmentPolicies && fulfillmentPolicies.length === 1) {
      setFieldValue("fulfilmentPolicyId", fulfillmentPolicies[0].id);
    }
    if (returnsPolicies && returnsPolicies.length === 1) {
      setFieldValue("returnPolicyId", returnsPolicies[0].id);
    }
  }, [locationPolicies, paymentPolicies, fulfillmentPolicies, returnsPolicies]);

  useEffect(() => {
    setFieldValue("condition", "default condition");
    if (autoConditionMappingEnabled) presetCategoryId(listingType);
  }, [listingType]);

  return (
    <>
      <br />
      <h2 className="ebay-listing-form__subheading">Listing</h2>
      <div className="ebay-listing-form__setting">
        <label htmlFor="ebayTitleTemplate" className="ebay-listing-form__label">
          Title
        </label>
        <Field name="ebayTitleTemplate">
          {({ field }: any) => (
            <EbayDescriptionEditor
              title
              game={game}
              className="ebay-listing-form__editor"
              name={field.name}
              value={field.value}
              setFieldValue={setFieldValue}
              onChange={field.onChange(field.name)}
            />
          )}
        </Field>
      </div>
      <ErrorMessage
        name="ebayTitleTemplate"
        className="ebay-listing-form__field-error"
        component="div"
      />
      <div className="ebay-listing-form__setting">
        <label
          htmlFor="ebayMobileTemplate"
          className="ebay-listing-form__label"
        >
          Mobile Description
        </label>
        <Field name="ebayMobileTemplate">
          {({ field }: any) => (
            <EbayDescriptionEditor
              mobile
              game={game}
              className="ebay-listing-form__editor"
              name={field.name}
              value={field.value}
              setFieldValue={setFieldValue}
              onChange={field.onChange(field.name)}
            />
          )}
        </Field>
        <ErrorMessage
          name="ebayMobileTemplate"
          className="ebay-listing-form__field-error"
          component="div"
        />
      </div>
      <ListingTemplateContainer
        integration={integration}
        game={game}
        setFieldValue={setFieldValue}
      />
      <ErrorMessage
        name="ebayTemplate"
        className="ebay-listing-form__field-error"
        component="div"
      />
      <div className="ebay-listing-form__setting">
        <Field name="ebayAttributes">
          {({ field }: any) => (
            <EbayCustomAttributes
              ebayCustomAttributes={field.value}
              setFieldValue={setFieldValue}
            />
          )}
        </Field>
      </div>
      <hr />
      <div className="ebay-listing-form__inputs">
        {autoConditionMappingEnabled && listingType === "single" ? (
          <>
            <label>Condition</label>
            <div>
              BinderPOS conditions are automatically mapped to eBay conditions.
              <br />
              Items with "Damaged" condition have no mapping and cannot list to
              eBay.
              <div>
                <ButtonComponent
                  className="ebay-listing-form__modal-opener"
                  onClick={() => setShowConditionModal(true)}
                >
                  View condition mapping
                </ButtonComponent>
              </div>
              {showConditionModal ? (
                <EbayConditionMappingModal
                  showModal={showConditionModal}
                  closeModal={() => setShowConditionModal(false)}
                />
              ) : null}
            </div>
          </>
        ) : null}
        {!autoConditionMappingEnabled || listingType === "other" ? (
          <>
            <label htmlFor="ebayCondition">Condition</label>
            <Field
              as="select"
              name="ebayCondition"
              id="ebayCondition"
              className="ebay-listing-form__select-input"
            >
              <option value="default condition">Default Condition</option>
              {getOptions(conditions)}
            </Field>
          </>
        ) : null}
      </div>
      {autoConditionMappingEnabled && listingType !== "sealed" ? <hr /> : null}
      <div className="ebay-listing-form__inputs">
        {/* <h5>Package</h5>
        <span />
        <label htmlFor="packageType">Package Type</label>
        <Field
          as="select"
          name="packageType"
          className="ebay-listing-form__select-input"
        >
          <option value={null}>-- Select Package Type --</option>
          {getOptions(packageTypes)}
        </Field>
        <ErrorMessage
          name="packageType"
          className="ebay-listing-form__field-error"
          component="div"
        />
        <label htmlFor="weight">Weight</label>
        <span className="ebay-listing-form__compound-input">
          <Field name="weight" />
          <Field
            as="select"
            name="weightUnit"
            className="ebay-listing-form__select-input"
          >
            <option value={null}>-- Select Measurement Unit --</option>
            {getOptions(weightMeasurementUnits)}
          </Field>
        </span>
        <ErrorMessage
          name="weight"
          className="ebay-listing-form__field-error"
          component="div"
        />
        <ErrorMessage
          name="weightUnit"
          className="ebay-listing-form__field-error"
          component="div"
        />
        <label htmlFor="packageType">Width</label>
        <span className="ebay-listing-form__compound-input">
          <Field name="width" />
          <Field
            as="select"
            name="widthUnit"
            className="ebay-listing-form__select-input"
          >
            <option value={null}>-- Select Measurement Unit --</option>
            {getOptions(lengthMeasurementUnits)}
          </Field>
        </span>
        <ErrorMessage
          name="width"
          className="ebay-listing-form__field-error"
          component="div"
        />
        <label htmlFor="packageType">Height</label>
        <span className="ebay-listing-form__compound-input">
          <Field name="height" />
          <Field
            as="select"
            name="heightUnit"
            className="ebay-listing-form__select-input"
          >
            <option value={null}>-- Select Measurement Unit --</option>
            {getOptions(lengthMeasurementUnits)}
          </Field>
        </span>
        <ErrorMessage
          name="height"
          className="ebay-listing-form__field-error"
          component="div"
        />
        <label htmlFor="packageType">Depth</label>
        <span className="ebay-listing-form__compound-input">
          <Field name="depth" />
          <Field
            as="select"
            name="depthUnit"
            className="ebay-listing-form__select-input"
          >
            <option value={null}>-- Select Measurement Unit --</option>
            {getOptions(lengthMeasurementUnits)}
          </Field>
        </span>
        <ErrorMessage
          name="depth"
          className="ebay-listing-form__field-error"
          component="div"
        />
        <hr />
        <span /> */}
        <span>Category</span>
        {autoConditionMappingEnabled && listingType === "single" ? (
          <div>{CCG_INDIVIDUAL_CARDS_CATEGORY_NAME}</div>
        ) : (
          <CategoryContainer />
        )}
        {defaultMarketplace !== "EBAY_US" ? (
          <>
            <h5>Tax</h5>
            <span />
            <label htmlFor="applyTax">Apply Tax</label>
            <Field
              type="checkbox"
              name="applyTax"
              style={{ justifySelf: "left" }}
            />
            <label htmlFor="vatPercentage">Tax % (included in price)</label>
            <Field name="vatPercentage" disabled={!applyTax} />
          </>
        ) : null}
        <h2 className="ebay-listing-form__subheading">Policies</h2>
        <span />
        <label htmlFor="paymentPolicyId">Payment Policy</label>
        <Field
          as="select"
          name="paymentPolicyId"
          id="paymentPolicyId"
          className="ebay-listing-form__select-input"
        >
          <option value="">-- Set Payment Policy --</option>
          {getPolicies(paymentPolicies)}
        </Field>
        <ErrorMessage
          name="paymentPolicyId"
          className="ebay-listing-form__field-error"
          component="div"
        />
        <label htmlFor="fulfilmentPolicyId">Fulfilment Policy</label>
        <Field
          as="select"
          id="fulfilmentPolicyId"
          name="fulfilmentPolicyId"
          className="ebay-listing-form__select-input"
        >
          <option value="">-- Set Fulfilment Policy --</option>
          {getPolicies(fulfillmentPolicies)}
        </Field>
        <ErrorMessage
          name="fulfilmentPolicyId"
          className="ebay-listing-form__field-error"
          component="div"
        />
        <label htmlFor="returnPolicyId">Return Policy</label>
        <Field
          as="select"
          id="returnPolicyId"
          name="returnPolicyId"
          className="ebay-listing-form__select-input"
        >
          <option value="">-- Set Return Policy --</option>
          {getPolicies(returnsPolicies)}
        </Field>
        <ErrorMessage
          name="returnPolicyId"
          className="ebay-listing-form__field-error"
          component="div"
        />
        <label htmlFor="merchantLocationKey">Location Policy</label>
        <Field
          as="select"
          id="merchantLocationKey"
          name="merchantLocationKey"
          className="ebay-listing-form__select-input"
        >
          <option value="">-- Set Location Policy --</option>
          {getPolicies(locationPolicies)}
        </Field>
        <ErrorMessage
          name="merchantLocationKey"
          className="ebay-listing-form__field-error"
          component="div"
        />
      </div>
    </>
  );
}

export default EbayListingForm;
