import { DateTime } from "luxon";

function convertDateToHumanReadable(
  date: Date | string | number,
  zone?: string
) {
  return getDate(date, zone).toLocaleString(
    DateTime.DATETIME_SHORT_WITH_SECONDS
  );
}

function getDate(date: Date | string | number, zone?: string) {
  if (typeof date === "number")
    return DateTime.fromMillis(date, {
      zone,
    });
  if (typeof date === "string")
    return DateTime.fromISO(date, {
      zone,
    });
  return DateTime.fromJSDate(date, { zone });
}

function convertEventDate(date: Date | string | number) {
  return getDate(date, "Etc/GMT").toLocaleString(DateTime.DATE_SHORT);
}

function convertDateToHumanReadableNoTime(date: Date | string | number) {
  return getDate(date).toLocaleString(DateTime.DATE_SHORT);
}

function convertDateToHumanReadbleNoTimeZ(date) {
  return getDate(date, "Etc/GMT").toLocaleString(DateTime.DATE_SHORT);
}

function convertTime(time) {
  return getDate(time).toLocaleString(DateTime.TIME_WITH_SECONDS);
}

function dateInput(date) {
  return getDate(date, "Etc/GMT").toFormat("yyyy-MM-dd");
}

function nowDate() {
  return DateTime.now().toLocaleString(DateTime.DATE_SHORT);
}

function nowTime() {
  return DateTime.now().toLocaleString(DateTime.TIME_SIMPLE);
}

export default {
  convertDateToHumanReadable,
  convertDateToHumanReadableNoTime,
  convertDateToHumanReadbleNoTimeZ,
  convertTime,
  dateInput,
  nowDate,
  nowTime,
  convertEventDate,
};
