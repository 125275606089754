import React, { useState } from "react";
import { ApexOptions } from "apexcharts";
import moment from "moment";
import "moment-timezone";
import ReactApexChart from "react-apexcharts";
import Select from "react-select";
import {
  defaultChartOptions,
  rangeOptions,
  rangeOptionToDates,
} from "../../constants/charts";
import { useDailyPosSalesDataQuery } from "../../generated/reporting";
import { useFormatCurrency } from "../../hooks/storeHooks";
import "./SalesBarChart.scss";
import { segmentAnalytics } from "../services/Analytics";
import { CHART_DATE_RANGE_SELECTED } from "../../constants/eventsTracked";

const LONG_LIFE_DATA_STALE_TIME = 60 * 1000; // ms

function SalesBarChartBreakDown() {
  const timeZoneOffset = moment.tz(moment.tz.guess()).format("Z");
  const defaultRange = rangeOptionToDates.thisMonth;
  const [startDate, setStartDate] = useState(defaultRange.startDate);
  const [endDate, setEndDate] = useState(defaultRange.endDate);
  const [binSize, setBinSize] = useState(defaultRange.binSize);
  const [selectedLabel, setSelectedLabel] = useState("This Month");
  const formatCurrency = useFormatCurrency();
  const { data } = useDailyPosSalesDataQuery(
    {
      startDate,
      endDate,
      binSize,
      timeZoneOffset,
    },
    {
      keepPreviousData: true,
      staleTime: LONG_LIFE_DATA_STALE_TIME,
    }
  );

  type OptionType = { label: string; value: string };

  const labelMap = {
    Cash_In: "Cash Received",
    Cash_Out: "Cash Given",
    Credit_In: "Credit Received",
    Credit_Out: "Credit Refunded",
    EFTPOS_In: "EFTPOS Received",
    EFTPOS_Out: "EFTPOS Refunds",
    Store_Credit_In: "Store Credit Used",
    Store_Credit_Out: "Store Credit Issued",
  };

  const updateRange = ({ label, value }: OptionType) => {
    segmentAnalytics.track(CHART_DATE_RANGE_SELECTED, {
      range_title: label,
      chart_type: "Sales by Tender",
    });
    setSelectedLabel(label);
    const selectedDates = rangeOptionToDates[value];
    setStartDate(selectedDates.startDate);
    setEndDate(selectedDates.endDate);
    setBinSize(selectedDates.binSize);
  };

  const chartOptions: ApexOptions = {
    ...defaultChartOptions,
    xaxis: {
      type: "category",
      labels: {
        show: true,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        formatter: function (val) {
          return "$" + val;
        },
      },
    },
  };

  const chartSeries = [
    {
      name: "Sales by Tender",
      data: Object.entries(data?.Metrics.Sales || {})
        .map(([tenderType, value]) => ({
          x: labelMap[tenderType] ?? tenderType,
          y: value,
        }))
        .filter((data) => data.x !== "Total_In"),
    },
  ];

  const totalSales = data?.Metrics.Sales.Total_In || 0;

  return (
    <div className="SalesBarChart">
      <div className="row">
        <div className="col-sm-6">
          <label className="sr-only" htmlFor="breakdownSource">
            Tenders Source
          </label>
          <Select
            inputId="breakdownSource"
            aria-label="Sales breakdown source"
            className="SalesBarChart__selectInput"
            classNamePrefix="SalesBarChart__selectInput"
            options={[{ label: "Point of Sale", value: "pos" }]}
            defaultValue={{ label: "Point of Sale", value: "pos" }}
          />
        </div>
        <div className="col-sm-6">
          <label className="sr-only" htmlFor="breakdownRange">
            Range
          </label>
          <Select
            inputId="breakdownRange"
            aria-label="Sales breakdown source"
            className="SalesBarChart__selectInput"
            classNamePrefix="SalesBarChart__selectInput"
            options={rangeOptions}
            onChange={updateRange}
            defaultValue={{ label: "This Month", value: "" }}
          />
        </div>
      </div>
      <div className="SalesBarChart__header">
        <h2 className="SalesBarChart__title">{selectedLabel}'s tenders</h2>
        <span className="SalesBarChart__period">{selectedLabel}</span>
      </div>
      <p className="SalesBarChart__total">{formatCurrency(totalSales)}</p>
      <ReactApexChart
        aria-label="Chart of sales from a selected source in the selected time window, broken down by tender type"
        options={chartOptions}
        series={chartSeries}
        type="bar"
        height="250"
      />
    </div>
  );
}

export default SalesBarChartBreakDown;
