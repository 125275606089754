import React from "react";
import { Route, Switch } from "react-router-dom";
import EbayOrdersView from "../views/integrations/ebay/EbayOrdersView";
import LogsMenu from "../views/integrations/ebay/LogsMenu";
import StockChangeInfoContainer from "../views/integrations/ebay/StockChangeInfoView";

function EbayLogsRouter() {
  return (
    <Switch>
      <Route exact path="/integrations/ebay/logs">
        <LogsMenu />
      </Route>
      <Route path="/integrations/ebay/logs/history">
        <StockChangeInfoContainer />
      </Route>
      <Route path="/integrations/ebay/logs/orders">
        <EbayOrdersView />
      </Route>
    </Switch>
  );
}

export default EbayLogsRouter;
