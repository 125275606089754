import React from "react";
import { notify } from "../../helpers/notificationHelpers";
import { useShowError } from "../../hooks/errorHooks";
import { useEnableEvent } from "../../hooks/eventHooks";
import ButtonComponent from "../generic/ButtonComponent";
import Modal from "../generic/Modal";

interface EnableEventModalProps {
  id: number;
  title: string;
  parentEventId: number;
  handleClose: () => void;
}

function EnableEventModal(props: EnableEventModalProps) {
  const { id, title, parentEventId, handleClose } = props;
  const showError = useShowError();
  const { mutateAsync: enableEvent, isMutating: isEnabling } = useEnableEvent();

  const handleEnableEvent = (eventId: number) =>
    enableEvent({ eventId, parentEventId })
      .then(() => {
        notify.success("Event enabled");
        handleClose();
      })
      .catch((error) =>
        showError(
          error,
          "Failed to enable event",
          "There was an error updating the child event details. Please try again"
        )
      );

  return (
    <Modal
      isLoading={isEnabling}
      onClose={handleClose}
      testId="EnableEventModal"
    >
      <Modal.Header as="h3">Are you sure?</Modal.Header>
      <Modal.Content>
        <p>
          Are you sure you want to enable the event
          <strong> {title}</strong>?
        </p>
      </Modal.Content>
      <Modal.Actions>
        <ButtonComponent secondary onClick={handleClose}>
          Cancel
        </ButtonComponent>
        <ButtonComponent
          primary
          disabled={isEnabling}
          onClick={() => handleEnableEvent(id)}
        >
          Enable
        </ButtonComponent>
      </Modal.Actions>
    </Modal>
  );
}

export default EnableEventModal;
