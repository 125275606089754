import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { EbayReturn } from "../../api/rest/ebay";
import ButtonComponent from "../../components/generic/ButtonComponent";
import { validateRequiredFields } from "../../utils/forms";
import "./EbayReturnsForm.scss";

const initialValues = {
  name: "",
  description: "",
  refundMethod: undefined,
  returnsAccepted: true,
  returnShippingCostPayer: "SELLER",
  returnPeriod: {
    unit: "DAY",
    value: 30,
  },
  internationalOverride_returnsAccepted: false,
  internationalOverride_returnShippingCostPayer: "BUYER",
  internationalOverride_returnPeriod: {
    unit: "DAY",
    value: undefined,
  },
} as EbayReturn;

const requiredFields = ["name"];

const validate = (values: EbayReturn) =>
  validateRequiredFields(requiredFields, values);

const generateInput = (
  fieldName: string,
  fieldLabel: string,
  autoFocus?: boolean
) => (
  <>
    <label id={fieldName}>
      {fieldLabel}
      {requiredFields.includes(fieldName) ? (
        <span className="ebay-returns-form__required">*</span>
      ) : (
        ""
      )}
    </label>
    <Field name={fieldName} aria-labelledby={fieldName} autoFocus={autoFocus} />
    <ErrorMessage
      name={fieldName}
      className="ebay-address-form__field-error"
      component="div"
    />
  </>
);

const getOptions = (options: EbayParam[]) =>
  options.map((option) => (
    <option key={option.code} value={option.code}>
      {option.description}
    </option>
  ));

const supportedDurations = ["YEAR", "MONTH", "DAY", "CALENDAR_DAY"];
const filterDurations = (durations: EbayParam[]) =>
  durations.filter((duration: EbayParam) =>
    supportedDurations.includes(duration.code)
  );

interface EbayReturnsFormProps {
  returnSetting?: EbayReturn;
  refundMethods: EbayParam[];
  shippingCostPayers: EbayParam[];
  timeDurations: EbayParam[];
  setEditSetting: Function;
  saveSetting: Function;
}

function EbayReturnsForm(props: EbayReturnsFormProps) {
  const {
    returnSetting,
    refundMethods,
    shippingCostPayers,
    timeDurations,
    setEditSetting,
    saveSetting,
  } = props;

  return (
    <div className="ebay-returns-form">
      <Formik
        initialValues={returnSetting || initialValues}
        validate={validate}
        onSubmit={(values, { setSubmitting }) =>
          saveSetting(values, setSubmitting)
        }
      >
        {({ values, isSubmitting }) => (
          <Form>
            <div className="ebay-returns-form__inputs">
              {generateInput("name", "Returns Policy Name", true)}
              {generateInput("description", "Description")}
              <label id="refundMethods">Refund Method</label>
              <Field
                as="select"
                name="refundMethods"
                aria-labelledby="refundMethods"
              >
                {getOptions(refundMethods)}
              </Field>
              <ErrorMessage
                name="refundMethods"
                className="ebay-returns-form__field-error"
                component="div"
              />
              <label id="returnsAccepted">Returns accepted</label>
              <Field
                type="checkbox"
                name="returnsAccepted"
                aria-labelledby="returnsAccepted"
              />
              <ErrorMessage
                name="returnsAccepted"
                className="ebay-returns-form__field-error"
                component="div"
              />
              {values.returnsAccepted ? (
                <>
                  <label id="returnShippingCostPayer">
                    Refund shipping cost payer
                  </label>
                  <Field
                    as="select"
                    name="returnShippingCostPayer"
                    aria-labelledby="returnShippingCostPayer"
                  >
                    {getOptions(shippingCostPayers)}
                  </Field>
                  <ErrorMessage
                    name="returnShippingCostPayer"
                    className="ebay-returns-form__field-error"
                    component="div"
                  />
                  <label id="refundPeriod">
                    Refund period
                    <span className="ebay-returns-form__required">*</span>
                  </label>
                  <span>
                    <Field
                      name="returnPeriod.value"
                      type="number"
                      aria-labelledby="refundPeriod"
                    />
                    <Field
                      name="returnPeriod.unit"
                      aria-labelledby="refundPeriod"
                      className="ebay-returns-form__unit-select"
                      as="select"
                    >
                      {getOptions(filterDurations(timeDurations))}
                    </Field>
                  </span>
                  <label id="internationalOverride_returnsAccepted">
                    International returns accepted
                  </label>
                  <Field
                    type="checkbox"
                    name="internationalOverride_returnsAccepted"
                    aria-labelledby="internationalOverride_returnsAccepted"
                  />
                  <ErrorMessage
                    name="internationalOverride_returnsAccepted"
                    className="ebay-returns-form__field-error"
                    component="div"
                  />
                  {values.internationalOverride_returnsAccepted ? (
                    <>
                      <label id="returnShippingCostPayer">
                        Inernational shipping cost payer
                      </label>
                      <Field
                        as="select"
                        name="internationalOverride_returnShippingCostPayer"
                        aria-labelledby="returnShippingCostPayer"
                      >
                        {getOptions(shippingCostPayers)}
                      </Field>
                      <ErrorMessage
                        name="internationalOverride_returnShippingCostPayer"
                        className="ebay-returns-form__field-error"
                        component="div"
                      />
                      <label id="internationalRefundPeriod">
                        International refund period
                        <span className="ebay-returns-form__required">*</span>
                      </label>
                      <span>
                        <Field
                          name="internationalOverride_returnPeriod.value"
                          type="number"
                          aria-labelledby="internationalRefundPeriod"
                        />
                        <Field
                          name="internationalOverride_returnPeriod.unit"
                          aria-labelledby="internationalRefundPeriod"
                          className="ebay-returns-form__unit-select"
                          as="select"
                        >
                          {getOptions(filterDurations(timeDurations))}
                        </Field>
                      </span>
                    </>
                  ) : null}
                </>
              ) : null}
            </div>
            <div className="ebay-payment-methods-form__actions">
              <ButtonComponent onClick={() => setEditSetting()}>
                Cancel
              </ButtonComponent>
              <ButtonComponent primary type="submit" disabled={isSubmitting}>
                Save
              </ButtonComponent>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default EbayReturnsForm;
