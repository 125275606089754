import {
  GetTillSettingsDocument,
  GetTillSettingsQuery,
  GetTillSettingsQueryVariables,
} from "../../generated/graphql";
import { graphQlFetcher } from "../../services/fetchers";
import { TillSetting } from "../../types/settings";

export async function getTillSettings(tillId: number): Promise<TillSetting[]> {
  const fetcher = graphQlFetcher<
    GetTillSettingsQuery,
    GetTillSettingsQueryVariables
  >(GetTillSettingsDocument, {
    tillId,
  });
  const result = await fetcher();
  return result?.BinderCustomerTillSettings;
}
