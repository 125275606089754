import React from "react";
import MobileMenu from "./MobileMenu";
import NewMobileMenu from "./NewMobileMenu";
import UserAvatar from "./UserAvatar";
import "./MobileHeader.scss";
import Watermark from "../../../assets/img/watermark.png";
import { MenuStructure } from "../../types/nav";
import Logo from "../../../assets/img/logo.png";
import { useFeatureFlag } from "../../hooks/globalSettingsHooks";
import { POS_NEW_MOBILE_MENU } from "../../constants/featureFlags";

interface MobileHeaderProps {
  screenSettings: ScreenSettings;
  mobileMenuOpen: boolean;
  setMobileMenuOpen: (open: boolean) => void;
  handleClick: (menu: MenuStructure | null | undefined) => void;
  handleLogout: () => void;
}

function MobileHeader(props: MobileHeaderProps) {
  const {
    screenSettings,
    mobileMenuOpen,
    setMobileMenuOpen,
    handleClick,
    handleLogout,
  } = props;

  const showNewMobileMenu = useFeatureFlag(POS_NEW_MOBILE_MENU);

  return (
    <>
      <aside className="watermark">
        <img src={Watermark} role="presentation" />
      </aside>
      <header>
        <div className="topCnrBg" />
        <div className="brand">
          <img src={Logo} alt="Binder POS logo" />
        </div>
      </header>
      <nav
        id="main-nav"
        className="navbar is-light mobile"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="MobileHeader__topNav">
          <UserAvatar />
        </div>
        {showNewMobileMenu ? (
          <NewMobileMenu
            mobileMenuOpen={mobileMenuOpen}
            handleClick={handleClick}
            setMobileMenuOpen={setMobileMenuOpen}
            handleLogout={handleLogout}
          />
        ) : (
          <MobileMenu
            mobileMenuOpen={mobileMenuOpen}
            screenSettings={screenSettings}
            handleClick={handleClick}
            setMobileMenuOpen={setMobileMenuOpen}
            handleLogout={handleLogout}
          />
        )}
      </nav>
    </>
  );
}

export default MobileHeader;
