export const forceDownloadFromURL = (url: string, filename?: string): void => {
  const downloadLink = document.createElement("a");
  if (filename) downloadLink.download = filename;
  downloadLink.href = url;
  downloadLink.style.display = "none";
  document.body.appendChild(downloadLink);
  downloadLink.click();
};

export const forceDownloadFromData = (
  data: any,
  filename: string,
  mimeType: string
) => {
  const file = new File([data], filename, { type: mimeType });
  const exportUrl = URL.createObjectURL(file);
  forceDownloadFromURL(exportUrl, filename);
  URL.revokeObjectURL(exportUrl);
};

// TODO:: Move convertToCsv here
