import { useEffect } from "react";
import { getCurrentUser } from "../../helpers/authHelpers";

const scriptSrc = () => `
window.fwSettings = {
  'widget_id': 43000002735,
};
!function () { if ("function" != typeof window.FreshworksWidget) { var n = function () { n.q.push(arguments) }; n.q = [], window.FreshworksWidget = n } }();
`;

const loadScript = (user: CurrentUser, binderUser: any) => {
  const scriptTag = document.createElement("script");
  let defaultName: string | null = user?.displayName;
  if (!defaultName?.trim()) {
    defaultName = user?.email;
  }
  scriptTag.innerHTML = scriptSrc();
  scriptTag.type = "text/javascript";
  scriptTag.async = true;
  document.body.appendChild(scriptTag);

  const scriptTag2 = document.createElement("script");
  scriptTag2.src = `https://widget.freshworks.com/widgets/43000002735.js`;
  scriptTag2.type = "text/javascript";
  scriptTag2.async = true;
  document.body.appendChild(scriptTag2);

  window.FreshworksWidget("hide", "launcher");
  window.FreshworksWidget("identify", "ticketForm", {
    name: defaultName,
    email: user?.email,
  });
  window.FreshworksWidget("prefill", "ticketForm", {
    custom_fields: {
      cf_store_name: binderUser?.name,
      cf_shopify_url: binderUser?.siteUrl,
    },
  });

  console.log("freshworks loaded");
};

function FreshWorks(props) {
  const currentUser = getCurrentUser();
  const { storeSettings } = props;

  useEffect(() => {
    loadScript(currentUser, storeSettings);
  }, []);

  return null;
}

export default FreshWorks;
