import React from "react";
import { BuylistMenuStructure } from "../../menuStructures";
import BuylistConfirmationSettings from "../../components/settings/BuylistConfirmationSettings";
import useTitle from "../../hooks/useTitle";
import SetActiveMenu from "../../menuStructures/SetActiveMenu";

function BuylistSettings() {
  useTitle("Buylist settings | BinderPOS");

  return (
    <>
      <SetActiveMenu menuStructure={BuylistMenuStructure} />
      <div className="header">
        <h1>Buylist settings</h1>
      </div>
      <BuylistConfirmationSettings />
    </>
  );
}

export default BuylistSettings;
