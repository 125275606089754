import { useRef, useState, useEffect } from "react";
import { segmentAnalytics } from "../services/Analytics";
import { SAVE_VARIANTS_PRICE_SETTINGS_CLICKED } from "../../constants/eventsTracked";
import mapValues from "lodash/mapValues";
import { notify } from "../../helpers/notificationHelpers";
import {
  normalizeVariantTypes,
  convertMultiplierToPercentage,
} from "../../helpers/pricingHelpers";
import { convertToSnakeCase } from "../../helpers/valueHelpers";
import { useUpdateMyVariant } from "../../hooks/pricingHooks";
import { useShowError } from "../../hooks/errorHooks";
import NavigationPromptWatcher from "../nav/NavigationPromptWatcher";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import SettingsSection from "./SettingsSection";
import Input from "../form/Input";
import Tooltip from "../generic/Tooltip";
import "./MyVariantsPricingSection.scss";

interface MyVariantsPricingSectionProps {
  variantTypes: VariantType[];
}

const MyVariantsPricingSection = ({
  variantTypes,
}: MyVariantsPricingSectionProps) => {
  const formikRef = useRef<any>();

  const [normalizedVariantTypes, setNormalizedVariantTypes] = useState(
    normalizeVariantTypes(variantTypes)
  );
  const [isEditable, setIsEditable] = useState(false);

  const { mutateAsync, isMutating, error } = useUpdateMyVariant();
  const showError = useShowError();

  useEffect(() => {
    if (error) {
      showError(error as DetailedError);
    }
  }, [error]);

  useEffect(() => {
    setNormalizedVariantTypes(
      convertMultiplierToPercentage(normalizeVariantTypes(variantTypes), null)
    );
  }, [variantTypes]);

  const handleToggleEdit = (value) => {
    setIsEditable(value);
    if (value === false) {
      setNormalizedVariantTypes(
        convertMultiplierToPercentage(normalizeVariantTypes(variantTypes), null)
      );
      // This resets the Formik form with the default values
      if (formikRef && formikRef.current) {
        formikRef.current.resetForm();
      }
    }
  };

  const handleOnSubmit = (value: any) => {
    const hydrated = Object.keys(value).map((name) => ({
      multiplier: parseFloat(value[name]) / 100,
      name,
      id: value[name].id,
    }));
    const variantNumMultipliers = Object.entries(value).reduce(
      (acc, [key, val]) => {
        acc[convertToSnakeCase(key)] = Number(val);
        return acc;
      },
      {}
    );
    segmentAnalytics.track(
      SAVE_VARIANTS_PRICE_SETTINGS_CLICKED,
      variantNumMultipliers
    );
    hydrated.forEach((variant: VariantType) => {
      if (
        variant.multiplier !==
        Number(parseFloat(normalizedVariantTypes[variant.name]))
      ) {
        const multiplier = variant.multiplier;
        const variantId = variantTypes.find(
          (v: any) => v.name === variant.name
        ).id;

        mutateAsync(
          {
            variantId,
            multiplier,
          },
          {
            onSuccess: () => {
              notify.info(
                "Your pricing adjustments have been queued and will take effect in about 12 hours."
              );
              setIsEditable(false);
            },
          }
        ).catch((error) => {
          console.log(error);
        });
      }
    });
  };

  const VariantsSchema = Yup.object(
    mapValues(normalizedVariantTypes, () => {
      return Yup.string()
        .required()
        .matches(
          /^(?:0{0,})((?:[0-9]{1,3}|100)?(?:\.\d+)?)?$/,
          "Value must be a multiplier."
        );
    })
  );

  const isEditing = isEditable && !isMutating;

  return (
    <Formik
      onSubmit={handleOnSubmit}
      initialValues={normalizedVariantTypes}
      enableReinitialize
      innerRef={formikRef}
      validationSchema={VariantsSchema}
    >
      {({ errors, values, dirty, submitForm }) => (
        <SettingsSection
          title={
            <>
              My Variants
              <Tooltip iconTheme="light">
                <aside>
                  <p>
                    The percentage you wish to charge based on variant
                    condition. 100% will set it to the full price you configured
                    for each product type below.
                  </p>
                  <p>
                    NOTE: this could bring prices below any rarity floors you
                    set.
                  </p>
                </aside>
              </Tooltip>
            </>
          }
          subtitle="Base Price Per Variant"
          isEditing={isEditable}
          isMutating={isMutating}
          enableEditMode
          toggleEditMode={handleToggleEdit}
          toggleTextOn="Edit Variants Settings"
          submitHandler={submitForm}
          formHasErrors={Object.keys(errors).length > 0}
        >
          <NavigationPromptWatcher
            dirty={dirty}
            formName="MyVariantsPricingSection-section"
          >
            <Form
              className="MyVariantsPricingSection__form"
              aria-label={JSON.stringify(values)}
            >
              {normalizedVariantTypes &&
              Object.keys(normalizedVariantTypes).length > 0
                ? Object.keys(normalizedVariantTypes).map((name, index) => (
                    <div key={name}>
                      <Field
                        component={Input}
                        editing={isEditing}
                        label={name}
                        name={name}
                        id={name}
                        type="text"
                        maxLength={3}
                        required
                        placeholder={`E.g. ${100 - 10 * index}%`}
                        adornment="%"
                      />
                    </div>
                  ))
                : null}
            </Form>
          </NavigationPromptWatcher>
        </SettingsSection>
      )}
    </Formik>
  );
};

export default MyVariantsPricingSection;
