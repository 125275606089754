import { useQuery, useMutation, useQueryClient } from "react-query";
import { fetchCustomerList, refreshCustomerList } from "../api/rest/customers";

const LONG_LIFE_DATA_STALE_TIME = 5 * 60 * 1000; // ms

export const useFetchCustomerList = (
  offset: number,
  limit: number,
  search?: string
) =>
  useQuery(
    ["customerList", offset, limit, search],
    () => fetchCustomerList(offset, limit, search),
    {
      staleTime: LONG_LIFE_DATA_STALE_TIME,
      keepPreviousData: true,
    }
  );

export const useRefreshCustomerList = () => {
  const queryClient = useQueryClient();
  return useMutation(refreshCustomerList, {
    onSettled: () =>
      queryClient.invalidateQueries("customerList", { refetchInactive: true }),
  });
};
