import React, { useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import ButtonComponent from "../../components/generic/ButtonComponent";
import Loader from "../../components/generic/Loader";
import ActionButtonsLayout from "../../components/layout/ActionButtonsLayout";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";
import { useShowError } from "../../hooks/errorHooks";
import {
  useFetchEventAdditionalInfo,
  useFetchAllEventParticipants,
} from "../../hooks/eventHooks";
import EventMenuStructure from "../../menuStructures/EventMenuStructure";
import SetActiveMenu from "../../menuStructures/SetActiveMenu";
import { reformatParticipantAdditionalInfo } from "../../helpers/eventHelpers";
import EventParticipantPOSTooltip from "./EventParticipantPOSTooltip";
import EventParticipantOnlineTooltip from "./EventParticipantOnlineTooltip";
import { forceDownloadFromData } from "../../helpers/fileHelpers";
import TableComponent from "../../components/table/TableComponent";
import {
  getOnlineParticipantsColumns,
  getPoSParticipantsColumns,
} from "./EventParticipantTableColumns";
import SubSectionHeaderLayout from "../../components/layout/SubSectionHeaderLayout";
import useTitle from "../../hooks/useTitle";

type RouteParams = {
  eventId: string;
};

function EventParticipants() {
  const { eventId } = useParams<RouteParams>();
  const { location } = useHistory();
  const listRef = useRef<string>();
  const showError = useShowError();
  useTitle("Event Participants");

  const {
    data: participants,
    isLoading: isLoadingParticipants,
    error: participantsError,
  } = useFetchAllEventParticipants(Number(eventId));

  const {
    data: additionalInfo,
    isLoading: isLoadingAdditionalInfo,
    error: additionalInfoError,
  } = useFetchEventAdditionalInfo(Number(eventId));

  useEffect(() => {
    if (location.pathname?.includes("add")) {
      listRef.current = "add";
    } else if (location.pathname?.includes("update")) {
      listRef.current = location.pathname?.replace(
        `/events/participants/${eventId}/`,
        ""
      );
    } else if (location.pathname?.includes("remove")) {
      listRef.current = location.pathname?.replace(
        `/events/participants/${eventId}/`,
        ""
      );
    } else {
      document.getElementById(listRef.current)?.focus();
    }
  }, [location]);

  useEffect(() => {
    if (participantsError) {
      showError(
        null,
        "Failed to load event details",
        "There was an error retrieving your event participants. Please refresh and try again"
      );
    } else if (additionalInfoError) {
      showError(
        null,
        "Failed to load event details",
        "There was an error retrieving your event details. Please refresh and try again"
      );
    }
  }, [participantsError, additionalInfoError]);

  const downloadCSV = (csv: string, filename: string) =>
    forceDownloadFromData(csv, filename, "text/csv");

  const exportTableToCsv = () => {
    const csv = [];
    const pushRow = (row: string[]) => csv.push(row.join(","));
    const header = [
      "Name",
      "Email",
      ...additionalInfo.map((field) => field.header),
    ];
    pushRow(header);
    participants.participants.forEach((participant) =>
      pushRow([
        participant.participantName,
        participant.participantEmail,
        ...reformatParticipantAdditionalInfo(participant.additionalInfo).map(
          (info) => info.value
        ),
      ])
    );
    downloadCSV(csv.join("\n"), "eventParticipants.csv");
  };

  if (isLoadingAdditionalInfo || isLoadingParticipants) {
    return <Loader />;
  }

  if (participantsError || additionalInfoError) {
    return null;
  }

  const onlineParticipantsColumns =
    getOnlineParticipantsColumns(additionalInfo);
  const posParticipantsColumns = getPoSParticipantsColumns(additionalInfo);

  return (
    <>
      <SetActiveMenu menuStructure={EventMenuStructure} />
      <SectionHeaderLayout title="Event Participants">
        <ActionButtonsLayout>
          <ButtonComponent
            primary
            isLink={true}
            to={`/events/participants/${eventId}/add`}
            icon="fa fa-plus"
            iconPosition="left"
            id="add"
          >
            Add Participant
          </ButtonComponent>
          <ButtonComponent
            primary
            onClick={exportTableToCsv}
            icon="far fa-download"
            iconPosition="left"
          >
            Download participant list
          </ButtonComponent>
        </ActionButtonsLayout>
      </SectionHeaderLayout>
      <SubSectionHeaderLayout
        title="Registered participants from online"
        tooltip={<EventParticipantOnlineTooltip />}
      />
      <TableComponent
        data={participants.participants}
        columns={onlineParticipantsColumns}
        eventId={eventId}
      />
      <SubSectionHeaderLayout
        title="Participants from POS"
        tooltip={<EventParticipantPOSTooltip />}
      />
      <TableComponent
        data={participants.customers}
        columns={posParticipantsColumns}
        eventId={eventId}
      />
    </>
  );
}

export default EventParticipants;
