import "./ProductPricingInfo.scss";
import { useFetchProductPricingInfo } from "../../hooks/productHooks";
import { logError } from "../../helpers/loggingHelpers";
import { transformPricingDataForVariantRow } from "../../transformers/productPricing";
import Loader from "../generic/Loader";
import TableComponent from "../table/TableComponent";
import ProductPricingInfoColumns from "./ProductPricingInfoColumns";
import ButtonComponent from "../generic/ButtonComponent";
import { useFormatCurrency } from "../../hooks/storeHooks";

interface ProductPricingInfoProps {
  productId: number;
}

function ProductPricingInfo({ productId }: ProductPricingInfoProps) {
  const { data, isLoading, error, refetch, isRefetching } =
    useFetchProductPricingInfo(productId);
  const formatCurrency = useFormatCurrency();
  if (isLoading || isRefetching) {
    return <Loader />;
  }

  if (error) {
    logError(error);
    return <p>Unable to fetch product pricing info, please try again.</p>;
  }

  const tableData = data?.map((data) =>
    transformPricingDataForVariantRow(data, formatCurrency)
  );

  return (
    <div className="ProductPricingInfo">
      <div className="ProductPricingInfo__content">
        <div className="ProductPricingInfo__content-top-row">
          <a
            target="_blank"
            href="https://portal.binderpos.com/#/settings/pricing"
          >
            View price settings
          </a>

          <ButtonComponent icon="fas fa-sync" onClick={() => refetch()}>
            Refresh
          </ButtonComponent>
        </div>
        <div className="ProductPricingInfo__content-bottom-row">
          <TableComponent
            data={tableData}
            columns={ProductPricingInfoColumns}
          />
        </div>
      </div>
    </div>
  );
}

export default ProductPricingInfo;
