import {
  EventInfo,
  EventParticipantAdditionalInfo,
  EventParticipantAdditionalInfoBasic,
} from "../types/events";
import { commaSplitWithAnd, getOrdinal } from "../utils/formatting";
import { TimeUtils } from "../utils";

export const reformatParticipantAdditionalInfo = (
  additionalInfo: (
    | EventParticipantAdditionalInfo
    | EventParticipantAdditionalInfoBasic
  )[]
): EventParticipantAdditionalInfoBasic[] => {
  if (additionalInfo.length < 1) return [];
  if ("value" in additionalInfo[0]) {
    return additionalInfo as EventParticipantAdditionalInfoBasic[];
  }
  return (additionalInfo as EventParticipantAdditionalInfo[]).map((info) => ({
    id: info.id,
    value: info.info?.value,
  }));
};

export const getRecurringDescription = (event?: EventInfo) => {
  if (event?.recurring !== true) {
    return "This is a single event";
  }
  const {
    recurringType,
    recurringFrequency,
    recurringDays,
    recurringEndDate,
    monthlyRecurringType,
    monthlyRecurringDayNumber,
    monthlyRecurringDay,
  } = event;
  let typeName = `${recurringType}s`;
  if (recurringType && recurringFrequency == 1) {
    if (recurringType?.toLowerCase() == "day") {
      typeName = "daily";
    } else {
      typeName = `${recurringType}ly`;
    }
  }
  let value = "Event scheduled ";
  if (recurringFrequency !== 1) {
    value += `for every ${recurringFrequency} `;
  }
  value += typeName;
  if (recurringType === "week") {
    const days = recurringDays.split(",");
    if (days.length === 1) {
      value += ` on ${days[0]}s`;
    } else {
      value += ` on ${commaSplitWithAnd(recurringDays)}`;
    }
  }
  if (event.recurringType === "month") {
    if (monthlyRecurringDayNumber) {
      value += ` on the ${getOrdinal(
        monthlyRecurringDayNumber
      )} of every month`;
    } else {
      value += ` on the ${monthlyRecurringType} ${monthlyRecurringDay} of every month`;
    }
  }
  if (event.recurringEndDate) {
    value += ` and ends on ${TimeUtils.convertDateToHumanReadableNoTime(
      recurringEndDate
    )}`;
  }
  return value;
};
