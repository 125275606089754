import React from "react";
import TableHeader from "./TableHeader";
import TableRow from "./TableRow";
import { AdditionalTableProps, Column } from "../../types/table";
import "./TableComponent.scss";

interface TableComponentProps extends AdditionalTableProps {
  data: Array<Record<string, any>>;
  columns: Column[];
  getTrProps?: (rowData: unknown) => object;
}

function TableComponent(props: TableComponentProps) {
  const { data, columns, getTrProps, ...additionalTableProps } = props;

  if (!data) return null;

  return (
    <table className="TableComponent">
      <TableHeader columns={columns} />
      <tbody>
        {data.map((dataRow, index) => (
          <TableRow
            key={"key" in dataRow ? (dataRow.key as string) : index}
            rowData={dataRow}
            columns={columns}
            rowIndex={index}
            additionalTableProps={additionalTableProps}
            getTrProps={getTrProps}
          />
        ))}
      </tbody>
    </table>
  );
}

export default TableComponent;
