import { ALLOW_CATALOG_CHANGES } from "../constants/settings";

export const SettingsMenuStructure = {
  title: "Settings",
  links: [
    {
      title: "Store Settings",
      href: "settings/store",
    },
    {
      title: "Catalog settings",
      href: "settings/catalog",
      screenSetting: ALLOW_CATALOG_CHANGES,
    },
    {
      title: "Pricing Settings",
      href: "settings/pricing",
    },
    {
      title: "Timezone Settings",
      href: "settings/timezone",
    },
    {
      title: "Report Settings",
      href: "settings/reports",
    },
    {
      title: "Users",
      href: "settings/users/list",
    },
    {
      title: "Add User",
      href: "settings/users/add",
    },
    {
      title: "Website Data Collection",
      href: "settings/cookies",
    },
  ],
};

const legacySettingsMenuStructure = {
  ...SettingsMenuStructure,
  links: SettingsMenuStructure.links.filter(
    (menuStructure) => !Object.keys(menuStructure).includes("screenSetting")
  ),
};

export default legacySettingsMenuStructure;
