import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import CheckPermission from "../components/auth/CheckPermission";
import EventList from "../views/events/EventList";
import ChildEventList from "../views/events/ChildEventList";
import Event from "../views/events/Event";
import EventParticipants from "../views/events/EventParticipants";
import EventFailedToCreate from "../views/events/EventFailedToCreate";
import EventFailedToDelete from "../views/events/EventFailedToDelete";
import RemoveParticipantModal from "../components/events/RemoveParticipantModal";
import UpdateParticipantModal from "../components/events/UpdateParticipantModal";
import EventSettings from "../views/events/EventSettings";
import ViewEvent from "../views/events/ViewEvent";

function EventRouter() {
  return (
    <CheckPermission screenName="events">
      <Switch>
        <Route exact path="/events">
          <Redirect to="/events/list" />
        </Route>
        <Route exact path="/events/list">
          <EventList />
        </Route>
        <Route exact path="/events/view/:eventId">
          <ChildEventList />
        </Route>
        <Route exact path="/events/add">
          <Event />
        </Route>
        <Route exact path="/events/update/:eventId">
          <Event />
        </Route>
        <Route path="/events/participants/:eventId">
          <EventParticipants />
        </Route>
        <Route exact path="/events/failedToCreate">
          <EventFailedToCreate />
        </Route>
        <Route exact path="/events/failedToDelete">
          <EventFailedToDelete />
        </Route>
      </Switch>
      <Route exact path="/events/participants/:eventId/remove/:participantId">
        <RemoveParticipantModal />
      </Route>
      <Route exact path="/events/participants/:eventId/update/:participantId">
        <UpdateParticipantModal />
      </Route>
      <Route exact path="/events/participants/:eventId/add">
        <UpdateParticipantModal />
      </Route>
      <Route exact path="/events/settings">
        <EventSettings />
      </Route>
      <Route exact path="/events/details/:eventId">
        <ViewEvent />
      </Route>
    </CheckPermission>
  );
}

export default EventRouter;
