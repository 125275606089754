import { _fetch, defaultParams } from "../../api";
import { BASE_URL } from "../../constants/api";
import { DEFAULT_TILLS_PER_PAGE } from "../../constants/pos";
import { ApiActionResponse } from "../../types/api";
import { Till } from "../../types/pos";

export const fetchTills = (params = {}): Promise<Array<Till>> =>
  _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/pos/tills`,
    query: defaultParams(params, DEFAULT_TILLS_PER_PAGE),
  });

type AddTillParams = Pick<Till, "name" | "description">;

export const addNewTill = (till: AddTillParams): Promise<ApiActionResponse> =>
  _fetch({
    method: "POST",
    endpoint: `${BASE_URL}/pos/tills`,
    payload: till,
  });

export const disableTill = (tillId: number): Promise<ApiActionResponse> =>
  _fetch({
    method: "POST",
    endpoint: `${BASE_URL}/pos/tills/byId/${tillId}/disable`,
  });

export const archiveTill = (tillId: number): Promise<ApiActionResponse> =>
  _fetch({
    method: "POST",
    endpoint: `${BASE_URL}/pos/tills/byId/${tillId}/archive`,
  });

export const enableTill = (tillId: number): Promise<ApiActionResponse> =>
  _fetch({
    method: "POST",
    endpoint: `${BASE_URL}/pos/tills/byId/${tillId}/enable`,
  });
