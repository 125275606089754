import React from "react";
import { Column } from "../../types/table";
import PreviousImportsDownloadButtons from "./PreviousImportsDownloadButtons";
import PreviousImportsSupportButton from "./PreviousImportsSupportButton";

const PreviousImportsTableColumns: Column[] = [
  {
    header: "Id",
    accessorKey: "id",
  },
  {
    header: "Date Uploaded",
    accessorKey: "createdAt",
  },
  {
    header: "Status",
    accessorKey: "processing",
    cell: (processing: string, _, rowData: unknown) =>
      processing ? "Processing" : rowData["status"] ?? "Queued",
  },
  {
    header: "Type",
    accessorKey: "fileType",
  },
  {
    header: () => <div style={{ textAlign: "right" }}>Total Rows</div>,
    headerLabel: "Total Rows",
    accessorKey: "totalRows",
    cell: (value) => <div style={{ textAlign: "right" }}>{value}</div>,
  },
  {
    header: () => <div style={{ textAlign: "right" }}>Failed Rows</div>,
    headerLabel: "Failed Rows",
    accessorKey: "failedRows",
    cell: (value) => <div style={{ textAlign: "right" }}>{value}</div>,
  },
  {
    header: "Last Updated",
    accessorKey: "updatedAt",
  },
  {
    header: "Download",
    accessorKey: "id",
    cell: (id: string, _, rowData: unknown) => (
      <PreviousImportsDownloadButtons id={id} status={rowData["status"]} />
    ),
  },
  {
    header: "Actions",
    accessorKey: "id",
    cell: (id: string, _, rowData: unknown) => (
      <PreviousImportsSupportButton id={id} fileType={rowData["fileType"]} />
    ),
  },
];

export default PreviousImportsTableColumns;
