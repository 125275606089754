import React from "react";
import LinkComponent from "../../../components/generic/LinkComponent";
import "./SettingsMenu.scss";
import { segmentAnalytics } from "../../../components/services/Analytics";
import {
  EBAY_ORDERS_CLICKED,
  EBAY_STOCK_CHANGE_HISTORY_CLICKED,
} from "../../../constants/eventsTracked";

function SettingsMenu() {
  return (
    <div className="ebay-settings">
      <div className="ebay-settings__navigation">
        <div className="ebay-settings__navigation-item">
          <h2 className="ebay-settings__navigation-title">
            Stock Change History
          </h2>
          <p className="ebay-settings__navigation-description">
            View details of changes made to stock levels, pricing and details of
            any errors returned by Ebay.
          </p>
          <LinkComponent
            to="/integrations/ebay/logs/history"
            onClick={() =>
              segmentAnalytics.track(EBAY_STOCK_CHANGE_HISTORY_CLICKED)
            }
          >
            Stock Change History &gt;
          </LinkComponent>
        </div>
        <div className="ebay-settings__navigation-item">
          <h2 className="ebay-settings__navigation-title">Orders</h2>
          <p className="ebay-settings__navigation-description">
            View incoming orders from Ebay.
          </p>
          <LinkComponent
            to="/integrations/ebay/logs/orders"
            onClick={() => segmentAnalytics.track(EBAY_ORDERS_CLICKED)}
          >
            Orders &gt;
          </LinkComponent>
        </div>
      </div>
    </div>
  );
}

export default SettingsMenu;
