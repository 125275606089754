import { useGetActiveCartsQuery } from "../generated/graphql";
import { ActiveCarts } from "../types/activeCarts";

export interface ActiveCartsQueryParams {
  cartId?: string;
  cartType?: string;
  cartName?: string;
  firstName?: string;
  lastName?: string;
  offset?: number;
  limit?: number;
  sortKey?: string;
  sortOrder?: "asc" | "desc";
}

function getOrderByField(orderBy: string, sortOrder: string) {
  switch (orderBy) {
    case "firstName":
    case "lastName":
      return [
        {
          ShopifyCustomer: {
            [orderBy]: sortOrder,
          },
        },
      ];
    default:
      return [
        {
          [orderBy]: sortOrder,
        },
      ];
  }
}

export const useFetchActiveCarts = (params: ActiveCartsQueryParams) => {
  const {
    offset: skip = 0,
    limit: take = 20,
    sortKey = "lastUpdated",
    sortOrder = "desc",
    ...rest
  } = params;
  const orderByField = getOrderByField(sortKey, sortOrder);
  return useGetActiveCartsQuery(
    {
      orderBy: orderByField,
      skip,
      take,
      where: {
        ...rest,
      },
    },
    {
      staleTime: 60_000,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      select: ({ getActiveCarts: { totalRows, carts } }): ActiveCarts => {
        return {
          totalRows: Number(totalRows),
          carts: carts.map((cart) => {
            return {
              id: cart.id,
              cartName: cart.cartName,
              cartType: cart.cartType,
              customer: cart.ShopifyCustomer && {
                firstName: cart.ShopifyCustomer.firstName,
                lastName: cart.ShopifyCustomer.lastName,
              },
              lastUpdated: cart.lastUpdated,
              lineItemCount: cart.itemCount || 0,
              savedCart: !!cart.savedCart,
            };
          }),
        };
      },
    }
  );
};
