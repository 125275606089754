import React, { Fragment, useEffect, useState } from "react";
import { ReportsMenuStructure } from "../../menuStructures";
import { Table, Column, Cell } from "rsuite-table";
import "rsuite-table/dist/css/rsuite-table.css";
import "../../../css/rstable-overwrites.scss";
import CustomHeaderCell from "../../components/reports/CustomHeader";
import { API_STATE } from "../../constants/api";
import { Pagination, ReportControls } from "../../components/reports";
import { CurrencyRenderer } from "../../components/reports/CellTypes";
import { useReportsDetails } from "../../hooks/reportsHooks";
import { useStoreDetails } from "../../hooks/storeHooks";
import { useShowError } from "../../hooks/errorHooks";
import { useFetchEndOfDayReports } from "../../hooks/reports/useFetchEndOfDayReports";
import { EndOfDayCSVHeaders } from "../../constants/reportCSVHeaders";
import { rowPerPageOptions } from "../../constants/reports";
import SetActiveMenu from "../../menuStructures/SetActiveMenu";
import { addMultipleLocalizedDates } from "../../transformers/helpers/addLocalizedDate";

function EODReports() {
  const {
    apiState,
    currentPage,
    rowsPerPage,
    sortColumn,
    tableData,
    computedTableData,
    reset,
    setApiState,
    setCurrentPage,
    setTableRowsPerPage,
    setSortingColumn,
    setSourceDataDetails,
  } = useReportsDetails();
  const { currencyCode } = useStoreDetails();
  const showError = useShowError();
  const [startDate, setStartDate] = useState<string>("2022-01-01");
  const [endDate, setEndDate] = useState<string>("2099-12-31");
  const [availableTenders, setAvailableTenders] = useState([]);
  const [timeZone, setTimeZone] = useState<string>(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const {
    data: sourceData,
    error,
    isFetching,
  } = useFetchEndOfDayReports({
    startDate,
    endDate,
  });

  useEffect(() => {
    document.title = "End of Day Reports | BinderPOS";
    reset();
    setSortingColumn("openedDate", "desc");
  }, []);

  useEffect(() => {
    if (isFetching) {
      setApiState(API_STATE.LOADING);
    } else if (error) {
      showError(
        error as any,
        "Failed to load report",
        "There was an error retrieving your end of day report. Please refresh and try again"
      );
      setApiState(API_STATE.ERROR);
    } else if (sourceData) {
      setSourceDataDetails(sourceData?.report);
      setAvailableTenders(sourceData?.tenders);
      setApiState(API_STATE.SUCCESS);
    }
  }, [isFetching, sourceData, error]);

  const handleSetDateRange = (fromDate, toDate, tz) => {
    setStartDate(fromDate);
    setEndDate(toDate);
    setTimeZone(tz);
  };

  const csvFilename = "End of Day Report.csv";

  const data = addMultipleLocalizedDates(computedTableData(), timeZone, [
    "openedDate",
    "closedDate",
  ]);

  return (
    <Fragment>
      <SetActiveMenu menuStructure={ReportsMenuStructure} />
      <div className="app-header">
        <h1 className="title">
          <span className="header-text">End of Day Reports</span>
        </h1>
      </div>
      <div className="report">
        <ReportControls
          setDateRange={handleSetDateRange}
          csvHeaders={EndOfDayCSVHeaders(availableTenders)}
          csvFilename={csvFilename}
        />
        {apiState !== API_STATE.INITIAL && (
          <div className="data-table">
            <div className="table-container">
              <Table
                data={data}
                headerHeight={35}
                sortColumn={sortColumn.key}
                sortType={sortColumn.order}
                onSortColumn={setSortingColumn}
                renderEmpty={() => <div>No data to display</div>}
                loading={apiState == API_STATE.LOADING}
                fillHeight
              >
                <Column key="col_name" width={200} sortable resizable>
                  <CustomHeaderCell title="Till" />
                  <Cell dataKey="name" />
                </Column>
                <Column key="col_opened_date" width={200} sortable resizable>
                  <CustomHeaderCell title="Opening Time" />
                  <Cell dataKey="openedDate">
                    {(rowData) => rowData.openedDate_localized}
                  </Cell>
                </Column>
                <Column key="col_closed_date" width={200} sortable resizable>
                  <CustomHeaderCell title="Closing Time" />
                  <Cell dataKey="closedDate">
                    {(rowData) => rowData.closedDate_localized}
                  </Cell>
                </Column>
                {availableTenders?.map((tender) => (
                  <>
                    {tender !== "Store_Credit" && (
                      <Column
                        key={`col_${tender}_opening`}
                        width={150}
                        align="right"
                        sortable
                        resizable
                      >
                        <CustomHeaderCell title={`${tender} Opening`} />
                        <Cell dataKey={`${tender}.openingAmount`}>
                          {(rowData) =>
                            CurrencyRenderer({
                              cellData: rowData?.[tender]?.openingAmount,
                              currency: currencyCode,
                            })
                          }
                        </Cell>
                      </Column>
                    )}
                    <Column
                      key={`col_${tender}_current`}
                      width={150}
                      align="right"
                      sortable
                      resizable
                    >
                      <CustomHeaderCell title={`${tender} Current`} />
                      <Cell dataKey={`${tender}.currentAmount`}>
                        {(rowData) =>
                          CurrencyRenderer({
                            cellData: rowData?.[tender]?.currentAmount,
                            currency: currencyCode,
                          })
                        }
                      </Cell>
                    </Column>
                    {tender !== "Store_Credit" && (
                      <>
                        <Column
                          key={`col_${tender}_diff`}
                          width={150}
                          align="right"
                          sortable
                          resizable
                        >
                          <CustomHeaderCell title={`${tender} Diff`} />
                          <Cell dataKey={`${tender}.difference`}>
                            {(rowData) =>
                              CurrencyRenderer({
                                cellData: rowData?.[tender]?.difference,
                                currency: currencyCode,
                              })
                            }
                          </Cell>
                        </Column>
                        <Column
                          key={`col_${tender}_closing`}
                          width={150}
                          align="right"
                          sortable
                          resizable
                        >
                          <CustomHeaderCell title={`${tender} Closing`} />
                          <Cell dataKey={`${tender}.closingAmount`}>
                            {(rowData) =>
                              CurrencyRenderer({
                                cellData: rowData?.[tender]?.closingAmount,
                                currency: currencyCode,
                              })
                            }
                          </Cell>
                        </Column>
                      </>
                    )}
                  </>
                ))}
              </Table>
            </div>
            <Pagination
              apiState={apiState}
              currentPage={currentPage}
              totalRowCount={tableData.length}
              rowsPerPage={rowsPerPage}
              rowPerPageOptions={rowPerPageOptions}
              setCurrentPage={setCurrentPage}
              setRowsPerPage={setTableRowsPerPage}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default EODReports;
