import React, { createContext, useState } from "react";
import { ErrorModal } from "../components/generic/ErrorModal";
import { CaughtError, GraphQLError } from "../types/error";

interface ErrorContextProps {
  setError: (
    message: string | DetailedError | CaughtError<GraphQLError[]>
  ) => void;
}

export const ErrorContext = createContext<ErrorContextProps>(null);

interface ErrorProviderProps {
  children: React.ReactNode;
}

function ErrorProvider(props: ErrorProviderProps) {
  const { children } = props;
  const [error, setError] = useState<
    string | DetailedError | CaughtError<GraphQLError[]>
  >(undefined);

  const handleDismiss = () => setError(undefined);

  return (
    <ErrorContext.Provider value={{ setError }}>
      {children}
      {error !== undefined ? (
        <ErrorModal errorDetails={error} handleDismiss={handleDismiss} />
      ) : null}
    </ErrorContext.Provider>
  );
}

export default ErrorProvider;
