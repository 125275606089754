import React, { Fragment } from "react";
import { useFormatCurrency, useStoreDetails } from "../../hooks/storeHooks";
import { BuylistCard } from "../../types/buylist";
import Panel from "../layout/Panel";
import "./BuylistItem.scss";

interface BuylistItemProps {
  index: number;
  card: BuylistCard;
  isBuylistCompleted: boolean;
  paymentType: string;
  customerVariants: VariantType[];
  onVariantQuantityChange: (
    cardName: string,
    cardId: number,
    setName: string,
    type: string,
    isOverStock: boolean,
    variantId: number,
    value: string
  ) => void;
  onVariantBuyPriceChange: (
    cardName: string,
    cardId: number,
    setName: string,
    type: string,
    isOverStock: boolean | undefined,
    variantId: number,
    isPaidInCash: boolean,
    value: string
  ) => void;
  removeLine: (index: number) => void;
}

const BuylistItem = React.forwardRef<HTMLElement, BuylistItemProps>(
  (props, viewRef) => {
    const {
      index,
      card,
      isBuylistCompleted,
      paymentType,
      customerVariants,
      removeLine,
      onVariantQuantityChange,
      onVariantBuyPriceChange,
    } = props;
    const {
      imageUrl,
      cardName,
      cardId,
      type,
      setName,
      rarity,
      variants,
      isOverStock,
    } = card;
    const isPaidInCash = paymentType === "Cash";

    const formatCurrency = useFormatCurrency();
    const { currencySymbol } = useStoreDetails();

    const hasZeroCards =
      Object.values(variants).reduce(
        (count, variant) => (count += variant.quantity),
        0
      ) === 0;

    const totalBuyPrice = Object.values(variants).reduce(
      (sum, variant) =>
        sum +
        Number(variant.quantity) *
          Number(
            isPaidInCash ? variant.cashBuyPrice : variant.storeCreditBuyPrice
          ),
      0
    );

    const totalSellPrice = Object.values(variants).reduce(
      (sum, variant) =>
        sum +
        (variant.quantity > 0
          ? Number(variant.quantity) * Number(variant.storeSellPrice)
          : 0),
      0
    );

    const handleVariantQuantityChange =
      (variantId: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        onVariantQuantityChange(
          cardName,
          cardId,
          setName,
          type,
          isOverStock,
          variantId,
          event.target.value
        );
        if (viewRef != null && typeof viewRef !== "function") {
          viewRef.current = event.target;
        }
      };

    const handleVariantBuyPriceChange =
      (variantId: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        onVariantBuyPriceChange(
          cardName,
          cardId,
          setName,
          type,
          isOverStock,
          variantId,
          isPaidInCash,
          event.target.value
        );
        if (viewRef != null && typeof viewRef !== "function") {
          viewRef.current = event.target;
        }
      };

    return (
      <Panel className="BuylistItem">
        {isOverStock && (
          <div className="BuylistItem__overstock">
            <p>Buy Limit</p>
          </div>
        )}
        {!isBuylistCompleted && (
          <button
            type="button"
            className="BuylistItem__removeButton"
            onClick={() => removeLine(index)}
          >
            Remove <i className="fas fa-times-circle" />
          </button>
        )}
        <div>
          <div className="BuylistItem__image">
            <img src={imageUrl} role="presentation" />
          </div>
          <div>
            <div className="BuylistItem__details">{`${cardName} ${
              type ? "- " + type : ""
            }`}</div>
            <div className="BuylistItem__details">{setName}</div>
            {rarity ? (
              <div className="BuylistItem__details">{rarity}</div>
            ) : null}
          </div>
        </div>
        <hr />
        <div
          className={`BuylistItem__variants ${
            hasZeroCards ? "BuylistItem__variants--error" : ""
          }`}
        >
          <span className="BuylistItem__variantsHeading">Condition</span>
          <span className="BuylistItem__variantsHeading">Qty</span>
          <span className="BuylistItem__variantsHeading">Buy Price</span>
          {customerVariants.map((variant) => {
            let quantity = 0;
            let buyPrice = 0;
            if (Object.keys(variants).includes(String(variant.id))) {
              const _variant = variants[variant.id];
              quantity = _variant.quantity;
              buyPrice = Number(
                isPaidInCash
                  ? _variant.cashBuyPrice
                  : _variant.storeCreditBuyPrice
              );
            }
            return (
              <Fragment key={variant.id}>
                <span>{variant.name}</span>
                <span>
                  {isBuylistCompleted ? (
                    quantity
                  ) : (
                    <input
                      aria-label={`${variant.name} quantity`}
                      className="BuylistItem__quantityInput"
                      type="number"
                      min={0}
                      step={1}
                      value={quantity ?? 0}
                      onChange={handleVariantQuantityChange(variant.id)}
                    />
                  )}
                </span>
                <span>
                  {currencySymbol}
                  {isBuylistCompleted ? (
                    buyPrice
                  ) : (
                    <input
                      aria-label={`${variant.name} buy price`}
                      className="BuylistItem__priceInput"
                      type="number"
                      min={0}
                      step={0.01}
                      value={buyPrice}
                      onChange={handleVariantBuyPriceChange(variant.id)}
                    />
                  )}
                </span>
              </Fragment>
            );
          })}
        </div>
        <hr />
        <div className="BuylistItem__totals">
          <span>Total Buy Price</span>
          <span data-testid="totalBuyPrice">
            {formatCurrency(totalBuyPrice)}
          </span>
        </div>
        <div className="BuylistItem__totals">
          <span>Total Sell Price</span>
          <span>{formatCurrency(totalSellPrice) || "N/A"}</span>
        </div>
      </Panel>
    );
  }
);

export default BuylistItem;
