import { useContext } from "react";
import { GlobalSettingsContext } from "../providers/GlobalSettingsProvider";

export const useNavigationPrompt = () => {
  const context = useContext(GlobalSettingsContext);

  return {
    isPromptEnabled: context.isPromptEnabled,
    setFormIsEditing: context.setFormIsEditing,
  };
};
