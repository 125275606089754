import React from "react";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import SettingsMenu from "../../menuStructures/SettingsMenu";
import Select from "react-select";
import { ButtonComponent, Loader } from "../../components";
import "../../../css/react-select-overwrites.scss";
import { UPDATE_TIMEZONE_CLICKED } from "../../constants/eventsTracked";
import Modal from "../../components/generic/Modal";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";

@inject("store")
@observer
class TimezoneSettings extends React.Component {
  @observable updating;

  @action setUpdating(updating) {
    this.updating = updating;
  }

  @observable selectedTimezone;

  @action setSelectedTimezone(selectedTimezone) {
    this.selectedTimezone = selectedTimezone;
  }

  handleSelectedTimezoneChange = (selectedOptions) => {
    this.setSelectedTimezone(selectedOptions.value);
  };

  @observable showConfirmation = undefined;
  @action setShowConfirmation = (timezone) =>
    (this.showConfirmation = timezone);

  constructor(props) {
    super(props);
    this.taxRateRef = React.createRef();
    this.taxIncludedRef = React.createRef();
    this.taxNumberRef = React.createRef();
    this.tradeInTaxRef = React.createRef();
    this.taxWordingRef = React.createRef();
  }

  componentDidMount() {
    document.title = "Timezone Settings | BinderPOS";
    this.props.store.SettingsStore.getCustomerTimezone();
    this.props.store.SettingsStore.getTimezones();
  }

  buildOptionsList(listToConvert) {
    const options = [];
    listToConvert.forEach((item) => {
      if (item.zoneName && item.zoneName.length > 0) {
        options.push({ value: item.zoneName, label: item.zoneName });
      }
    });
    return options;
  }

  updateTimezone() {
    this.props.store.SettingsStore.setCustomerTimezone(null);
    this.setUpdating(true);
    const timezoneUpdate = {
      timezone: this.selectedTimezone,
    };
    this.props.store.SettingsStore.updateCustomerTimezone(timezoneUpdate)
      .then(() => {
        this.setShowConfirmation(this.selectedTimezone);
        this.props.store.SettingsStore.getCustomerTimezone();
        this.setUpdating(false);
      })
      .catch((err) => {
        this.props.store.MainStore.setError(
          err,
          "Failed to load setting",
          "There was an error submitting your new timezone. Please try again"
        );
      });
  }

  render() {
    return (
      <>
        <SettingsMenu />
        <SectionHeaderLayout title="Timezone Settings" />
        {this.props.store.SettingsStore.timezones &&
        this.props.store.SettingsStore.timezones.length > -1 &&
        this.props.store.SettingsStore.customerTimezone &&
        this.props.store.SettingsStore.customerTimezone.length > 0 &&
        !this.updating ? (
          <div className="infoInputPages">
            <div className="row">
              <div className="col-md-12 field">
                <label className="label">
                  Your timezone
                  <Select
                    classNamePrefix="react-select"
                    onChange={this.handleSelectedTimezoneChange}
                    options={this.buildOptionsList(
                      this.props.store.SettingsStore.timezones
                    )}
                    defaultValue={{
                      label: this.props.store.SettingsStore.customerTimezone,
                      value: this.props.store.SettingsStore.customerTimezone,
                    }}
                  />
                </label>
              </div>
              <div className="col-md-12 mt-4">
                <ButtonComponent
                  primary
                  type="button"
                  fullWidth
                  onClick={() => this.updateTimezone()}
                  segmentEventName={UPDATE_TIMEZONE_CLICKED}
                >
                  Update timezone
                </ButtonComponent>
              </div>
            </div>
            {this.showConfirmation ? (
              <Modal small onClose={() => this.setShowConfirmation(undefined)}>
                <Modal.Header>Timezone updated!</Modal.Header>
                <Modal.Content>
                  You have updated your timezone to {this.showConfirmation}
                </Modal.Content>
              </Modal>
            ) : null}
          </div>
        ) : (
          <Loader />
        )}
      </>
    );
  }
}
TimezoneSettings.propTypes = {
  store: PropTypes.object,
};
export default TimezoneSettings;
