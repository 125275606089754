import React from "react";

class SearchBarLocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialState(props);
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.autocomplete = null;
  }

  componentDidMount() {
    // eslint-disable-next-line no-undef
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("autocomplete"),
      {}
    );
    this.autocomplete.addListener("place_changed", this.handlePlaceSelect);
  }

  initialState(props) {
    const { event } = props;
    return {
      building: event?.buildingName || "",
      street_address: event?.streetAddress || "",
      city: event?.city || "",
      state: event?.state || "",
      zip_code: event?.zipCode || "",
    };
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handlePlaceSelect() {
    const addressObject = this.autocomplete.getPlace();
    const address = addressObject.address_components;
    this.setState({
      street_address: `${
        this.getLocationInfoByType(address, "street_number").long_name
      } ${this.getLocationInfoByType(address, "route").long_name}`,
      city: this.getLocationInfoByType(address, "locality").long_name,
      state: this.getLocationInfoByType(address, "administrative_area_level_1")
        .long_name,
      zip_code: this.getLocationInfoByType(address, "postal_code").short_name,
    });
  }

  getLocationInfoByType(address, typeName) {
    for (const element of address) {
      if (element.types.includes(typeName)) {
        return element;
      }
    }
    return {
      long_name: "",
      short_name: "",
    };
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-xl-5">
            <label className="label">Location of event:</label>
            <input
              onKeyPress={(event) => {
                if (event.key === "Enter") event.preventDefault();
              }}
              id="autocomplete"
              className="input-field"
              type="text"
            />
            <p className="locationHelper"> Please search a location above.</p>
          </div>
          <div className="col-lg-12 col-xl-1" />
          <div className="col-lg-12 col-xl-6">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-3">
                  <div className="control">
                    <input
                      id="eventBuilding"
                      name="building"
                      value={this.state.building}
                      placeholder="Building"
                      onChange={this.handleChange}
                      style={{ marginBottom: "8px" }}
                    />
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="control">
                    <input
                      name="street_address"
                      id="eventStreetAddress"
                      value={this.state.street_address}
                      placeholder="Street Address"
                      onChange={this.handleChange}
                      style={{ marginBottom: "8px" }}
                    />
                  </div>
                </div>
                <div className="col-lg-12 col-xl-4">
                  <div className="control">
                    <input
                      name="city"
                      id="eventCity"
                      value={this.state.city}
                      placeholder="City"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-12 col-xl-4">
                  <div className="control">
                    <input
                      name="state"
                      id="eventState"
                      value={this.state.state}
                      placeholder="State"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-12 col-xl-4">
                  <div className="control">
                    <input
                      name="zip_code"
                      id="eventZipCode"
                      value={this.state.zip_code}
                      placeholder="Zipcode"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SearchBarLocation;
