const ReportsMenuStructure = {
  title: "Reports",
  links: [
    {
      title: "Home",
      href: "reports",
      exact: true,
    },
    {
      title: "Sales Reports",
      href: "reports/sales",
    },
    {
      title: "Stock Reports",
      href: "reports/stock",
    },
    {
      title: "Store Credit Reports",
      href: "reports/store-credit",
    },
    {
      title: "Online Buylist Reports",
      href: "reports/buylists",
    },
  ],
};

export default ReportsMenuStructure;
