export const ADA_DEFAULT_CONFIG = {
  handle: "tcgplayer",
  crossWindowPersistance: true,
  subdomain: "https://*.binderpos.com",
  privateMode: true,
  metaFields: {},
};

export const adaBotAnswers = {
  CATALOG_ERROR_REPORT: "647749461a029be9ac5e296d",
  BINDERPOS_CUSTOMER_MENU: "63d1746e57e0da6a3fb8da2f",
  REPORT_A_BUG: "63d17b9db90c710b8f6d9e0f",
  REPORT_CATALOG_ISSUE: "63d17ac2dcc5b9045245d58f",
  REPORT_CSV_IMPORT_ISSUE: "64e4cc1c27641d6f31336e4b",
};

export const adaBotMetaFields = {
  CATALOG_ITEM_TITLE: "bpos_catalog_title",
  CATALOG_ITEM_IMAGE: "bpos_catalog_image",
  CATALOG_ITEM_ID: "bpos_catalog_id",
  CATALOG_ITEM_SHOPIFY_LINK: "bpos_shopify_item_link",
  STORE_NAME: "bpos_store_name",
  STORE_ID: "bpos_store_id",
  STORE_EMAIL: "bpos_store_email",
  STORE_URL: "bpos_shopify_url",
  CSV_IMPORT_ID: "bpos_csv_import_id",
  CSV_IMPORT_TYPE: "bpos_csv_import_type",
};
