import { useEffect } from "react";
import { ADD_USER_CLICKED } from "../../constants/eventsTracked";
import { User } from "../../types/user";
import { useShowError } from "../../hooks/errorHooks";
import { useFetchUsers } from "../../hooks/userHooks";
import useTitle from "../../hooks/useTitle";
import SettingsMenu from "../../menuStructures/SettingsMenu";
import TableComponent from "../../components/table/TableComponent";
import UsersTableColumns from "./UsersTableColumns";
import ButtonComponent from "../../components/generic/ButtonComponent";
import Loader from "../../components/generic/Loader";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";
import "./Users.scss";

const rowProps = (rowData: User) => ({
  className: rowData.enabled ? "Users__row--enabled" : "Users__row--disabled",
});

function Users() {
  useTitle("Users");
  const { data: users, isLoading, error } = useFetchUsers();
  const showError = useShowError();

  useEffect(() => {
    if (error) {
      showError(
        error as DetailedError,
        "Failed to load users",
        "Please refresh and try again."
      );
    }
  }, [error]);

  if (isLoading) {
    return (
      <>
        <SettingsMenu />
        <Loader />
      </>
    );
  }

  return (
    <div className="Users">
      <SettingsMenu />
      <SectionHeaderLayout title="Manage users">
        <ButtonComponent
          primary
          isLink
          to="/settings/users/add"
          icon="fas fa-user-plus"
          segmentEventName={ADD_USER_CLICKED}
        >
          Add user
        </ButtonComponent>
      </SectionHeaderLayout>
      <TableComponent
        data={users || []}
        columns={UsersTableColumns}
        getTrProps={rowProps}
      />
    </div>
  );
}

export default Users;
