import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { DEFAULT_EVENTS_PER_PAGE } from "../../constants/events";
import { EDIT_EVENT_CLICKED } from "../../constants/eventsTracked";
import { useFetchEventInfo } from "../../hooks/eventHooks";
import { useShowError } from "../../hooks/errorHooks";
import useTitle from "../../hooks/useTitle";
import { EventMenuStructure } from "../../menuStructures";
import SetActiveMenu from "../../menuStructures/SetActiveMenu";
import { EventInfo } from "../../types/events";
import { ButtonComponent, Loader, Paging, Spinner } from "../../components";
import {
  EventCard,
  ViewEventModal,
  DeleteEventModal,
  EnableEventModal,
} from "../../components/events";
import GridLayout from "../../components/layout/GridLayout";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";
import SubSectionHeaderLayout from "../../components/layout/SubSectionHeaderLayout";
import TableComponent from "../../components/table/TableComponent";
import ParentEventColumns from "./ParentEventColumns";

interface RouteParams {
  eventId: string;
}

function ChildEventList() {
  const [offset, setOffset] = useState(0);
  const [eventToView, setEventToView] = useState<EventInfo>();
  const [eventToDelete, setEventToDelete] = useState<EventInfo>();
  const [eventToEnable, setEventToEnable] = useState<EventInfo>();
  const { eventId } = useParams<RouteParams>();

  useTitle("Events");
  const showError = useShowError();

  const {
    data: eventWithChildren,
    isLoading,
    error,
  } = useFetchEventInfo(Number(eventId));

  useEffect(() => {
    if (error) {
      showError(
        error as DetailedError,
        "Failed to load events",
        "There was an error retrieving the child events. Please refresh and try again"
      );
    }
  }, [error]);

  const prevPage = () =>
    setOffset((prev) => Math.min(0, prev - DEFAULT_EVENTS_PER_PAGE));

  const nextPage = () => setOffset((prev) => prev + DEFAULT_EVENTS_PER_PAGE);

  if (isLoading) {
    return <Loader />;
  }

  if (!eventWithChildren) {
    return <p>Unable to display details for this event</p>;
  }

  return (
    <>
      <SetActiveMenu menuStructure={EventMenuStructure} />
      <SectionHeaderLayout title={` Viewing ${eventWithChildren.title}`} />
      <SubSectionHeaderLayout title="Parent Event" />
      <TableComponent
        columns={ParentEventColumns}
        data={[eventWithChildren]}
        setEventToView={setEventToView}
        setEventToDelete={setEventToDelete}
      />
      <SubSectionHeaderLayout title="Children Events" />
      <Paging
        pageOffset={offset}
        currentPageItemCount={
          eventWithChildren?.childEvents &&
          eventWithChildren.childEvents.length - offset
        }
        maxItemsPerPage={DEFAULT_EVENTS_PER_PAGE}
        getPrevPage={prevPage}
        getNextPage={nextPage}
      />
      <Spinner isLoading={isLoading}>
        <GridLayout>
          {eventWithChildren.childEvents
            .slice(offset, offset + DEFAULT_EVENTS_PER_PAGE)
            .map((event) => (
              <EventCard key={event.id} event={event}>
                <EventCard.Links>
                  <div className="col-md-12">
                    <p className="EventCard__participants">
                      <em>Participants: </em>
                      <Link
                        aria-label={`View the entrants for ${event.title}`}
                        to={`/events/participants/${event.id}`}
                      >
                        View the entrants
                      </Link>
                    </p>
                  </div>
                </EventCard.Links>
                <EventCard.Actions>
                  <ButtonComponent
                    aria-label={`Edit event ${event.title}`}
                    isLink
                    to={`/events/update/${event.id}`}
                    primary
                    halfWidth
                    icon="far fa-calendar-edit"
                    iconPosition="left"
                    segmentEventName={EDIT_EVENT_CLICKED}
                    segmentEventProperties={{
                      event_type: event.type,
                      game_type: event.game,
                    }}
                  >
                    Edit event
                  </ButtonComponent>
                  {!event.isDisabled ? (
                    <ButtonComponent
                      aria-label={`Pause event ${event.title}`}
                      primary
                      halfWidth
                      icon="fa fa-pause pause"
                      iconPosition="right"
                      onClick={() => setEventToDelete(event)}
                    >
                      Pause
                    </ButtonComponent>
                  ) : (
                    <ButtonComponent
                      aria-label={`Resume event ${event.title}`}
                      primary
                      halfWidth
                      icon="fa fa-play pause green"
                      iconPosition="right"
                      onClick={() => setEventToEnable(event)}
                    >
                      Resume
                    </ButtonComponent>
                  )}
                </EventCard.Actions>
              </EventCard>
            ))}
        </GridLayout>
      </Spinner>
      {eventToView ? (
        <ViewEventModal
          event={eventToView}
          handleClose={() => setEventToView(undefined)}
        />
      ) : null}
      {eventToDelete ? (
        <DeleteEventModal
          id={eventToDelete.id}
          title={eventToDelete.title}
          parentEventId={eventToDelete.parentEventId}
          handleClose={() => setEventToDelete(undefined)}
          isRecurring={!eventToDelete.parentEventId}
        />
      ) : null}
      {eventToEnable ? (
        <EnableEventModal
          id={eventToEnable.id}
          title={eventToEnable.title}
          parentEventId={eventToEnable.parentEventId}
          handleClose={() => setEventToEnable(undefined)}
        />
      ) : null}
    </>
  );
}

export default ChildEventList;
