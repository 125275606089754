import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  createIntegrationSyncSetting,
  deleteIntegrationSyncSetting,
  fetchCardGames,
  fetchCardSetNames,
  fetchIntegrationSyncSettings,
  fetchProductTypesExcludingSupportedCatalogs,
  Game,
  Setting,
  updateIntegrationSyncSetting,
} from "../../api/rest/integrations";
import Loader from "../../components/generic/Loader";
import Spinner from "../../components/generic/Spinner";
import EbayResyncInventoryButton from "../../components/integrations/EbayResyncInventoryButton";
import ForceReauthoriseButton from "../../components/integrations/ForceReauthoriseButton";
import IntegrationSettingForm from "../../components/integrations/IntegrationSettingForm";
import TabNavigation from "../../components/integrations/TabNavigation";
import ViewSettingModal from "../../components/integrations/ViewSettingModal";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";
import IntegrationsSideMenu from "../../menuStructures/IntegrationsSideMenu";
import { useStoreDetails } from "../../hooks/storeHooks";
import { useQueryState } from "../../hooks/stateHooks";
import { useIntegrationFriendlyName } from "../../hooks/integrationHooks";
import IconButton from "../../components/generic/IconButton";
import { segmentAnalytics } from "../../components/services/Analytics";
import {
  CREATE_BULK_SETTING_CLICKED,
  SAVE_BULK_SETTING_RULE_CLICKED,
} from "../../constants/eventsTracked";
import { notify } from "../../helpers/notificationHelpers";
import { useShowError } from "../../hooks/errorHooks";
import { ConfirmationModal } from "../../components";

const limit = 25;

function BasicSettings() {
  const { integration, settingId } = useParams<{
    integration: string;
    settingId: string;
  }>();
  const syncFriendlyName = useIntegrationFriendlyName(integration);
  const history = useHistory();
  const { customerId } = useStoreDetails();
  const listRef = useRef<HTMLElement>();
  const modalRef = useRef<HTMLElement>();
  const [offset, setOffset] = useQueryState("offset", 0);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [viewingSetting, setViewingSetting] = useState<number>();
  const [deletingSetting, setDeletingSetting] = useState<number>();
  const [showDeleteMultipleConfirmation, setShowDeleteMultipleConfirmation] =
    useState(false);
  const [selectedSettings, setSelectedSettings] = useState<number[]>([]);

  const [games, setGames] = useState<Game[]>([]);
  const [setNames, setSetNames] = useState<string[]>([]);
  const [productTypes, setProductTypes] = useState<string[]>([]);
  const [settings, setSettings] = useState<Setting[]>([]);
  const [totalSettings, setTotalSettings] = useState(0);
  const showError = useShowError();

  useEffect(() => {
    if (viewingSetting || deletingSetting || showDeleteMultipleConfirmation) {
      modalRef.current?.focus();
    } else {
      listRef.current?.focus();
    }
  }, [viewingSetting, deletingSetting, showDeleteMultipleConfirmation]);

  const loadGames = () =>
    fetchCardGames()
      .then((data) => setGames(data))
      .catch((error: DetailedError) => showError(error));

  const loadProductTypes = () =>
    fetchProductTypesExcludingSupportedCatalogs()
      .then((data) => setProductTypes(data))
      .catch((error: DetailedError) => showError(error));

  const loadSettings = (newOffset?: number) => {
    setIsLoading(true);
    fetchIntegrationSyncSettings(integration, newOffset ?? offset, limit)
      .then((response) => {
        const { data, total } = response;
        setSettings(data);
        setTotalSettings(total);
      })
      .catch((error: DetailedError) => showError(error))
      .finally(() => setIsLoading(false));
  };

  const saveSetting = (setting: Setting, setSubmitting: Function) => {
    setSubmitting(true);
    setting.syncName = integration;
    setting.customerId = customerId;
    const saveAction = setting.id
      ? updateIntegrationSyncSetting
      : createIntegrationSyncSetting;
    saveAction(setting)
      .then(() => {
        segmentAnalytics.track(SAVE_BULK_SETTING_RULE_CLICKED, {
          integration_type: integration,
        });
        loadSettings();
        history.push(`/integrations/${integration}`);
        notify.info("New setting created");
      })
      .catch((error: DetailedError) => showError(error))
      .finally(() => setSubmitting(false));
  };

  const deleteSetting = (id: number) => {
    setIsDeleting(true);
    deleteIntegrationSyncSetting(id)
      .then(() => {
        loadSettings();
        history.push(`/integrations/${integration}`);
        notify.info("Setting deleting");
      })
      .catch((error: DetailedError) => showError(error))
      .finally(() => {
        setIsDeleting(false);
        setDeletingSetting(undefined);
        setSelectedSettings([]);
      });
  };

  const deleteMultipleSettings = () => {
    setIsDeleting(true);
    const toDelete = selectedSettings.map((id) =>
      deleteIntegrationSyncSetting(id)
    );
    return Promise.all(toDelete)
      .then(() => {
        loadSettings();
        history.push(`/integrations/${integration}`);
        notify.info("Settings deleting");
      })
      .catch((error: DetailedError) => showError(error))
      .finally(() => {
        setIsDeleting(false);
        setShowDeleteMultipleConfirmation(false);
        setSelectedSettings([]);
      });
  };

  const loadSets = (game: string) =>
    fetchCardSetNames(game)
      .then((response) => setSetNames(response))
      .catch((error: DetailedError) => showError(error))
      .finally(() => setIsLoading(false));

  const checkDuplicates = (
    id: number,
    game: string,
    setName: string,
    productType: string
  ) => {
    const gameFixed = game === "" || game === undefined ? null : game;
    const setNameFixed = setName === "" || game === undefined ? null : setName;
    return settings.find(
      (setting) =>
        setting.game === gameFixed &&
        setting.setName === setNameFixed &&
        setting.productType === productType &&
        setting.id !== id
    );
  };

  const handleOnChangeForCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const { checked } = event.target;
    if (checked) {
      if (!selectedSettings.includes(id)) {
        setSelectedSettings([...selectedSettings, id]);
      }
    } else {
      setSelectedSettings(selectedSettings.filter((val) => val !== id));
    }
  };

  const handleChangeAllCheckboxes = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = event.target;
    if (checked) {
      setSelectedSettings(
        settings.filter((setting) => setting.game).map((setting) => setting.id)
      );
    } else {
      setSelectedSettings([]);
    }
  };

  const handleOnNextPage = () => {
    setOffset(offset + limit);
    loadSettings(offset + limit);
  };

  const handleOnPrevPage = () => {
    setOffset(offset - limit);
    loadSettings(offset - limit);
  };

  useEffect(() => {
    document.title = "Integration Sync | BinderPOS";
    loadSettings();
    loadGames();
    loadProductTypes();
  }, [integration]);

  if (isLoading) {
    return <Loader />;
  }

  if (settingId) {
    return (
      <IntegrationSettingForm
        integration={integration}
        integrationSyncFriendlyName={integration}
        games={games}
        sets={setNames}
        productTypes={productTypes}
        loadSets={loadSets}
        checkDuplicates={checkDuplicates}
        saveSetting={saveSetting}
        setting={settings.find((setting) => setting.id === Number(settingId))}
      />
    );
  }

  return (
    <>
      <IntegrationsSideMenu />
      <SectionHeaderLayout title={`${syncFriendlyName} Integration Settings`}>
        <>
          <EbayResyncInventoryButton integration={integration} />
          <ForceReauthoriseButton integration={integration} />
        </>
      </SectionHeaderLayout>
      <div className="integration-settings-list">
        <TabNavigation integrationId={integration} />
        <Spinner isLoading={isLoading}>
          <table>
            <thead>
              <tr>
                <td colSpan={2}>
                  {totalSettings > 1 ? (
                    <span className="multipleSelect">
                      <label className="checkbox">
                        <span className="sr-only">Select all</span>
                        <input
                          type="checkbox"
                          onChange={handleChangeAllCheckboxes}
                        />
                        <span className="checkmark"></span>
                      </label>
                      <span className="text">
                        {selectedSettings.length} selected{" "}
                        <IconButton
                          className="icon-button"
                          aria-label={`Delete ${selectedSettings.length} selected settings`}
                          icon="far fa-trash-alt"
                          onClick={(event) => {
                            listRef.current = event.currentTarget;
                            setShowDeleteMultipleConfirmation(true);
                          }}
                        />
                      </span>
                    </span>
                  ) : null}
                </td>
                <td>
                  <Link
                    to={`/integrations/${integration}/settings/new`}
                    className="tableLink"
                    onClick={() =>
                      segmentAnalytics.track(CREATE_BULK_SETTING_CLICKED, {
                        integration_type: integration,
                      })
                    }
                  >
                    Create new setting <i className="fal fa-plus-square" />
                  </Link>
                </td>
              </tr>
            </thead>
            <tbody>
              {totalSettings === 0 && (
                <tr>
                  <td className="no-settings">
                    You current have no settings saved for this integration.
                    &nbsp;
                    <Link
                      to={`/integrations/${integration}/settings/new`}
                      onClick={() =>
                        segmentAnalytics.track(CREATE_BULK_SETTING_CLICKED, {
                          integration_type: integration,
                        })
                      }
                    >
                      Create a new setting
                    </Link>
                  </td>
                </tr>
              )}
              {settings &&
                settings.map((setting, i) => {
                  const gameName = games.filter(
                    (game) => game.gameId === setting.game
                  )[0]?.gameName;
                  return (
                    <tr key={i}>
                      <td>
                        {setting.game || setting.productType ? (
                          <label className="checkbox">
                            <input
                              aria-labelledby={`settingName-${i}`}
                              id={`additionalInfoRequired-${i}`}
                              type="checkbox"
                              checked={selectedSettings.includes(setting.id)}
                              onChange={(event) =>
                                handleOnChangeForCheckbox(event, setting.id)
                              }
                            />
                            <span className="checkmark"></span>
                          </label>
                        ) : null}
                      </td>
                      <td className="text-bold" id={`settingName-${i}`}>
                        {gameName || setting.productType}
                        {setting.setName && ` - ${setting.setName}`}
                      </td>
                      <td className="text-right">
                        <IconButton
                          aria-label={`View setting ${
                            gameName || setting.productType
                          } details`}
                          icon="far fa-eye"
                          className="userActions"
                          onClick={(event) => {
                            listRef.current = event.currentTarget;
                            setViewingSetting(setting.id);
                          }}
                        />
                        <IconButton
                          aria-label={`Update setting ${
                            gameName || setting.productType
                          } details`}
                          icon="far fa-edit"
                          className="userActions"
                          isLink
                          to={`/integrations/${integration}/settings/${setting.id}`}
                        />
                        {setting.game || setting.productType ? (
                          <IconButton
                            aria-label={`Delete ${
                              gameName || setting.productType
                            } setting`}
                            icon="far fa-trash-alt"
                            className="userActions"
                            onClick={(event) => {
                              listRef.current = event.currentTarget;
                              setDeletingSetting(setting.id);
                            }}
                          />
                        ) : null}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
            {totalSettings > offset ? (
              <tfoot>
                <tr>
                  <td colSpan={3}>
                    <span className="top-pagination">
                      <span>
                        Showing {offset + 1} to{" "}
                        {Math.min(limit + offset, totalSettings)} of{" "}
                        {totalSettings}
                      </span>
                      <button
                        aria-label="previous page"
                        disabled={offset == 0}
                        className="paging-nav small"
                        onClick={handleOnPrevPage}
                      >
                        <i className="fas fa-caret-left" />
                      </button>
                      <button
                        aria-label="next page"
                        disabled={limit > settings.length}
                        className="paging-nav small"
                        onClick={handleOnNextPage}
                      >
                        <i className="fas fa-caret-right" />
                      </button>
                    </span>
                  </td>
                </tr>
              </tfoot>
            ) : null}
          </table>
        </Spinner>
      </div>
      <ViewSettingModal
        visible={viewingSetting !== undefined}
        setting={settings?.find((setting) => setting.id === viewingSetting)}
        games={games}
        integration={integration}
        integrationSyncFriendlyName={integration}
        handleClose={() => setViewingSetting(undefined)}
        ref={modalRef}
      />
      {deletingSetting ? (
        <ConfirmationModal
          ref={modalRef}
          cancelAction={() => setDeletingSetting(undefined)}
          confirmWord="Delete"
          confirmAction={() => deleteSetting(deletingSetting)}
        >
          <p>
            If you proceed with deleting this setting, you will not be able to
            restore it.
          </p>
          <br />
          <p>Are you sure you want to delete this integration sync setting?</p>
        </ConfirmationModal>
      ) : null}
      {showDeleteMultipleConfirmation ? (
        <ConfirmationModal
          cancelAction={() => setShowDeleteMultipleConfirmation(undefined)}
          confirmWord="Delete"
          confirmAction={deleteMultipleSettings}
          isLoading={isDeleting}
          ref={modalRef}
        >
          <p>
            If you proceed with deleting your settings, you will not be able to
            restore it.
          </p>
          <br />
          <p>Are you sure you want to delete your integration sync settings?</p>
        </ConfirmationModal>
      ) : null}
    </>
  );
}

export default BasicSettings;
