import React from "react";
import { THANKSGIVING_FLAG } from "../../constants/featureFlags";
import { useFeatureFlag } from "../../hooks/globalSettingsHooks";
import "./Thanksgiving.scss";

function Thanksgiving() {
  const isThanksgivingFlagEnabled = useFeatureFlag(THANKSGIVING_FLAG);
  if (!isThanksgivingFlagEnabled) return null;
  return (
    <div className="Thanksgiving">
      <p className="Thanksgiving__greeting">Hello Everyone,</p>
      <p>
        We will have limited support this week due to the Thanksgiving Holiday
        in the US. Our team will be away for a long weekend. If you need our
        support please send us your inquiry to{" "}
        <a href="mailto:support@binderpos.com" className="Thanksgiving__link">
          support@binderpos.com
        </a>{" "}
        and we will get back to you as soon as possible.
      </p>
      <p>Best,</p>
      <p className="Thanksgiving__binderpos">BinderPOS</p>
    </div>
  );
}

export default Thanksgiving;
