import { TerminalResponse } from "../types/paymentTerminal";

export const getTerminalId = (terminalDetails: TerminalResponse) => {
  if ("key" in terminalDetails) {
    return terminalDetails.key;
  }
  if ("id" in terminalDetails) {
    return terminalDetails.id;
  }
};
