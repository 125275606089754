import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { action } from "mobx";
import ButtonComponent from "../generic/ButtonComponent";
import Modal from "../generic/Modal";
import { ItemList } from "../../pos/ItemStore";

interface POSErrorModalProps {
  posStore?: ItemList;
}

@inject("posStore")
@observer
class POSErrorModal extends Component<POSErrorModalProps> {
  @action clearError = () => {
    this.props.posStore.errorMessage = "";
    this.props.posStore.setCartInvalid(false);
  };

  @action newCart = () => {
    this.props.posStore.errorMessage = "";
    this.props.posStore.setCartInvalid(false);
    this.props.posStore.newCart();
  };

  render() {
    const store = this.props.posStore;

    if (!store.errorMessage || store.errorMessage === "") {
      return null;
    }

    return (
      <Modal small onClose={this.clearError}>
        <Modal.Header>{store.errorHeader}</Modal.Header>
        <Modal.Content>
          {store.errorMessage ? (
            <div>{store.errorMessage.toString()}</div>
          ) : null}
          <div>
            {store.externalUrl ? (
              <a href={store.externalUrl} target="_blank" rel="noreferrer">
                {store.externalUrl}
              </a>
            ) : (
              ""
            )}
          </div>
          {this.props.posStore.cartInvalid ? (
            <>
              <br />
              <ButtonComponent primary onClick={this.newCart}>
                Create a new cart
              </ButtonComponent>
            </>
          ) : null}
          <div>
            {store.errorTraceId ? "Trace ID: " + store.errorTraceId : ""}
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

export default POSErrorModal;
