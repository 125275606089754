export const DEFAULT_BUYLISTS_PER_PAGE = 20;

export const buylistCSVHeaders = [
  { name: "Card", key: "cardName" },
  { name: "Game", key: "game" },
  { name: "Set", key: "setName" },
  { name: "Printing", key: "type" },
  { name: "Condition", key: "variantName" },
  { name: "Quantity", key: "quantity" },
];

export const importerCompatibleBuylistCSVHeaders = [
  {
    name: "Game Type",
    key: "gameId",
  },
  { name: "Binder Id", key: "cardId" },
  { name: "Variant Id", key: "variantId" },
  { name: "Card Name", key: "cardName" },
  { name: "Set Name", key: "setName" },
  { name: "Add Stock", key: "quantity" },
  {
    name: "Current Price",
    key: "storeSellPrice",
  },
  {
    name: "Price Override",
    key: "priceOverride",
  },
  {
    name: "Cash Buy Price",
    key: "cashBuyPriceValue",
  },
  {
    name: "Cash Buy Percent",
    key: "cashBuyPercent",
  },
  {
    name: "Store Credit Buy Price",
    key: "storeCreditBuyPriceValue",
  },
  {
    name: "Store Credit Buy Percent",
    key: "storeCreditBuyPercent",
  },
  {
    name: "Buy Limit",
    key: "buyLimit",
  },
  {
    name: "Overstocked Cash Buy Price",
    key: "overstockedCashPriceValue",
  },
  {
    name: "Overstocked Cash Buy Percentage",
    key: "overstockedCashBuyPercentage",
  },
  {
    name: "Overstocked Store Credit Buy Price",
    key: "overstockedStoreCreditBuyPriceValue",
  },
  {
    name: "Overstocked Store Credit Buy Percentage",
    key: "overstockedStoreCreditBuyPercentage",
  },
  {
    name: "Reserve Quantity",
    key: "reserveQuantity",
  },
];
