import React, { useEffect } from "react";
import { FormikProps } from "formik";
import { WeightUnit } from "../../generated/graphql";
import {
  useLocalSetting,
  useLocalSettingUpdate,
} from "../../hooks/localSettingsHooks";
import { ProductEditData } from "../../types/productEdit";

export const PRODUCT_WEIGHT_UNIT_SETTING = "defaultProductWeightUnit";

interface ProductWeightUnitInputProps {
  field: HTMLInputElement;
  form: FormikProps<ProductEditData>;
}

function ProductWeightUnitInput(props: ProductWeightUnitInputProps) {
  const { field, form } = props;
  const { name, value } = field;
  const { setFieldValue } = form;
  const defaultWeight = useLocalSetting(PRODUCT_WEIGHT_UNIT_SETTING);
  const updateWeightSetting = useLocalSettingUpdate();
  useEffect(() => {
    setFieldValue(name, defaultWeight ?? Object.values(WeightUnit)[0]);
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    setFieldValue(name, value);
    updateWeightSetting(PRODUCT_WEIGHT_UNIT_SETTING, value);
  };

  return (
    <div className="ProductWeightUnitInput">
      <label id="label">Weight unit</label>
      <select
        aria-labelledby="label"
        name={name}
        onChange={handleChange}
        value={value}
        className="product-variants__weight-unit input"
      >
        {Object.values(WeightUnit).map((value) => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </select>
    </div>
  );
}

export default ProductWeightUnitInput;
