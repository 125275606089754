import React from "react";
import { Field } from "formik";
import { EventAdditionalInfo, EventInfo } from "../../types/events";
import Input from "../form/Input";
import TextArea from "../form/TextArea";
import "./AdditionalInfoForm.scss";

interface AdditionalInfoFormProps {
  position: number;
  values: EventInfo;
  additionalInfo: EventAdditionalInfo;
  setFieldValue: SetFieldValue;
}

function AdditionalInfoForm(props: AdditionalInfoFormProps) {
  const { position, additionalInfo, values, setFieldValue } = props;

  return (
    <div className="AdditionalInfoForm">
      {additionalInfo.id ? (
        <input type="hidden" value={additionalInfo.id} />
      ) : null}
      {!additionalInfo.id ? (
        <button
          title="Remove additional info"
          className="AdditionalInfoForm__delete"
          type="button"
          onClick={() => {
            const additionalInfos = [...values.additionalInfo];
            additionalInfos.splice(position, 1);
            setFieldValue("additionalInfo", additionalInfos);
          }}
        >
          <i className="fal fa-trash-alt" />
        </button>
      ) : null}
      <Field
        component={Input}
        name={`additionalInfo.${position}.header`}
        type="text"
        label="Additional info header"
        placeholder="E.g Please Enter your DCI number"
        requiredLabel
      />
      <p className="Event__helpText">
        This is the text that will be displayed for the customer.
      </p>

      <Field
        component={TextArea}
        name={`additionalInfo.${position}.headerDescription`}
        type="text"
        label="Additional info description"
        placeholder="E.g You can find this number by contacting the store
          owner or looking on your register card"
      />
      <p className="Event__helpText">
        This is the helper text if needed that will be displayed for the
        customer.
      </p>
    </div>
  );
}

export default AdditionalInfoForm;
