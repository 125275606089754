import React, { ReactChild } from "react";
import { Link } from "react-router-dom";
import "./LinkComponent.scss";

interface LinkComponentProps {
  children: ReactChild;
  className?: string;
  to?: string;
  onClick?: VoidFunction;
  external?: boolean;
}

const getClassName = (className: string) =>
  ["link-component", className ? className : null].filter(Boolean).join(" ");

function LinkComponent(props: LinkComponentProps) {
  const { children, to, onClick, className, external = false } = props;
  const mergedClassName = getClassName(className);

  if (external) {
    return (
      <a href={to} className={mergedClassName} target="_blank" rel="noopener">
        {children}
      </a>
    );
  }

  if (to) {
    return (
      <Link to={to} className={mergedClassName} onClick={onClick}>
        {children}
      </Link>
    );
  }

  return (
    <button className={mergedClassName} onClick={onClick}>
      {children}
    </button>
  );
}

export default LinkComponent;
