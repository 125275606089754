import React, { useRef } from "react";
import { Transition } from "react-transition-group";
import { joinClasses } from "../../utils/styling";
import "./Overlay.scss";

interface OverlayProps {
  children: React.ReactElement;
  fade?: boolean;
  showOverlay: boolean;
  dismissHandler: VoidFunction;
  noAppear?: boolean;
}

function Overlay({
  children,
  fade,
  showOverlay,
  dismissHandler,
  noAppear = false,
}: OverlayProps) {
  const nodeRef = useRef<HTMLDivElement>();
  return (
    <Transition
      nodeRef={nodeRef}
      in={showOverlay}
      appear={!noAppear}
      unmountOnExit
      timeout={200}
    >
      {(state) => {
        const overlayClasses = joinClasses([
          "Overlay",
          fade ? `Overlay--${state}` : null,
        ]);
        return (
          <div ref={nodeRef} className={overlayClasses}>
            <div
              className="Overlay__background"
              onClick={dismissHandler}
              data-testid="overlay-bg"
            />
            <div className="Overlay__content">{children}</div>
          </div>
        );
      }}
    </Transition>
  );
}

export default Overlay;
