import { inject, observer } from "mobx-react";
import React, { Component, useState } from "react";
import ButtonComponent from "../../components/generic/ButtonComponent";
import { listRequests } from "../../utils/api";
import DetailedError from "../error/DetailedError";
import GraphQLError from "../error/GraphQLError";
import SimpleError from "../error/SimpleError";
import Modal from "./Modal";

const getTraceID = (error) => {
  if (error?.traceId) {
    return "Trace ID: " + error.traceId;
  }
  return null;
};

const getErrorComponent = (error) => {
  if (typeof error === "string") {
    return SimpleError;
  }
  if (Array.isArray(error?.data)) {
    return GraphQLError;
  }
  return DetailedError;
};

const getTitle = (errorDetails) => {
  if (errorDetails && errorDetails.data && errorDetails.data.length > 0) {
    if (errorDetails.data?.length > 1) {
      return "Multiple errors ocurred";
    }
    return errorDetails.data[0].extensions.title || "Unknown error";
  }
  if (errorDetails && errorDetails.error) {
    return errorDetails.error;
  }
  return "Unknown error";
};

export function ErrorModal(props) {
  const { errorDetails, handleDismiss } = props;
  const [showDetails, setShowDetails] = useState(false);

  const ErrorComponent = getErrorComponent(errorDetails);

  return (
    <Modal onClose={handleDismiss}>
      <Modal.Header>{getTitle(errorDetails)}</Modal.Header>
      <Modal.Content>
        <ErrorComponent errorDetails={errorDetails} />
        <p>{getTraceID(errorDetails)}</p>
        {showDetails ? (
          <>
            <b>Error Details</b>
            <ul>
              <li>Current route {window.location.hash}</li>
              {listRequests().map((request, index) => (
                <li key={index}>
                  [{request.method}] {String(request.endpoint)}:{" "}
                  {request.status}
                </li>
              ))}
            </ul>
          </>
        ) : null}
        <ButtonComponent primary onClick={() => setShowDetails(!showDetails)}>
          {showDetails ? "Hide Details" : "Show Full Details"}
        </ButtonComponent>
      </Modal.Content>
    </Modal>
  );
}

@inject("posStore", "store")
@observer
class ErrorModalWrapper extends Component {
  clearErrorMessage = () => {
    this.props.store.MainStore.setError(null);
    this.props.posStore.setError(null);
  };

  render() {
    const error = this.props.store.MainStore.error || this.props.posStore.error;
    return (
      <ErrorModal errorDetails={error} handleDismiss={this.clearErrorMessage} />
    );
  }
}

export default ErrorModalWrapper;
