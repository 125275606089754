import React, { Component, Fragment } from "react";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";
import { BuylistMenuStructure } from "../../menuStructures";
import { ButtonComponent, ConfirmationModal, Loader } from "../../components";
import {
  fetchBuylistRules,
  doDeleteBuylistRule,
  doDeleteBuylistRules,
} from "../../api/rest/buylist";
import IconButton from "../../components/generic/IconButton";
import Modal from "../../components/generic/Modal";
import {
  CREATE_BUYLIST_RULE_CLICKED,
  DELETE_BUYLIST_RULE_CLICKED,
  EDIT_BUYLIST_RULE_CLICKED,
  VIEW_BUYLIST_RULE_CLICKED,
} from "../../constants/eventsTracked";
import { segmentAnalytics } from "../../components/services/Analytics";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";
import ActionButtonsLayout from "../../components/layout/ActionButtonsLayout";

@inject("store")
@observer
export default class BuylistRuleList extends Component {
  @observable selectedBuylistRules = [];

  @action setSelectedBuylistRules(selectedBuylistRules) {
    this.selectedBuylistRules = selectedBuylistRules;
  }

  @observable buylistRule;

  @action setBuylistRule(buylistRule) {
    this.buylistRule = buylistRule;
  }

  @observable modalVisible = false;

  @action setModalVisible(bool) {
    this.modalVisible = bool;
  }

  @observable deleteModalVisible = false;

  @action setDeleteModalVisible(bool) {
    this.deleteModalVisible = bool;
  }

  @observable deleteSelectedModalVisible = false;

  @action setDeleteSelectedModalVisible(bool) {
    this.deleteSelectedModalVisible = bool;
  }

  @observable deleteModalBuylistRule;

  @action setDeleteModalBuylistRule(buylistRule) {
    this.deleteModalBuylistRule = buylistRule;
  }

  @observable deleteModalLoading;

  @action setDeleteModalLoading(loading) {
    this.deleteModalLoading = loading;
  }

  @observable deleteSelectedModalLoading;

  @action setDeleteSelectedModalLoading(loading) {
    this.deleteSelectedModalLoading = loading;
  }

  constructor(props) {
    super(props);
    this.listRef = React.createRef();
    this.modalRef = React.createRef();
  }

  componentDidMount() {
    document.title = "Buylist Rules | BinderPOS";
    this.props.store.MenuStore.setSideMenuToDisplay(BuylistMenuStructure);

    fetchBuylistRules()
      .then((result) => {
        this.props.store.BuylistRulesStore.setBuylistRules(result);
      })
      .catch((error) => {
        this.props.store.MainStore.setError(
          error,
          "Failed to load buylist rules",
          "There was an error retrieving your store's buylist rules. Please refresh and try again"
        );
      });
  }

  setBuylistRuleInStore(buylistRule) {
    this.props.store.BuylistRulesStore.setBuylistRule(buylistRule);
  }

  @action
  pushToBuylistRulesList(id) {
    const newList = this.selectedBuylistRules.filter((item) => item !== id);
    newList.push(id);
    this.setSelectedBuylistRules(newList);
  }

  @action
  removeFromBuylistRulesList(id) {
    const newList = this.selectedBuylistRules.filter((item) => item !== id);
    this.setSelectedBuylistRules(newList);
  }

  handleOnChangeForCheckbox(index, buylistId) {
    const checkbox = document.querySelector(`#additionalInfoRequired${index}`);
    if (checkbox.checked) {
      this.pushToBuylistRulesList(buylistId);
    } else {
      this.removeFromBuylistRulesList(buylistId);
    }
  }

  showDeleteModalForSelectedBuylistRules() {
    this.setDeleteSelectedModalVisible(true);
  }

  deleteSelectedBuylistRules() {
    this.setDeleteSelectedModalLoading(true);
    const buylistsRulesToDelete = {
      buylistIds: this.selectedBuylistRules,
    };
    doDeleteBuylistRules(buylistsRulesToDelete)
      .then(() => {
        this.setDeleteSelectedModalVisible(false);
        this.setDeleteSelectedModalLoading(false);
        this.setSelectedBuylistRules([]);
        this.props.store.BuylistRulesStore.rehydrate();
      })
      .catch((error) => {
        this.setDeleteSelectedModalVisible(false);
        this.setDeleteSelectedModalLoading(false);
        this.props.store.MainStore.setError(
          error,
          "Failed to delete buylist rules",
          "There was an error deleting your selected rules. Please try again"
        );
      });
  }

  showViewingModal(buylistRule) {
    this.setBuylistRule(buylistRule);
    this.setModalVisible(true);
  }

  deleteBuylistRule(buylistRuleId) {
    this.setDeleteModalLoading(true);
    doDeleteBuylistRule(buylistRuleId)
      .then(() => {
        this.setDeleteModalVisible(false);
        this.setDeleteModalBuylistRule(null);
        this.setDeleteModalLoading(false);
        this.props.store.BuylistRulesStore.rehydrate();
      })
      .catch((error) => {
        this.setDeleteModalVisible(false);
        this.setDeleteModalBuylistRule(null);
        this.setDeleteModalLoading(false);
        this.props.store.MainStore.setError(
          error,
          "Failed to delete buylist rule",
          "There was an error deleting your buylist rule. Please try again"
        );
      });
  }

  showDeleteModalFor(buylistRule) {
    this.setDeleteModalVisible(true);
    this.setDeleteModalBuylistRule(buylistRule);
  }

  generateBuylistRuleDescription(buylistRule) {
    let ruleWording;
    if (buylistRule.priceRule === "EqualTo") {
      ruleWording = "Equal to";
    } else if (buylistRule.priceRule == "lessThanOrEqualTo") {
      ruleWording = "Less than or Equal to";
    } else if (buylistRule.priceRule == "lessThan") {
      ruleWording = "Less than";
    } else if (buylistRule.priceRule == "greaterThanOrEqualTo") {
      ruleWording = "Greater than or Equal to";
    } else if (buylistRule.priceRule == "greaterThan") {
      ruleWording = "Greater than";
    }
    let priceWording;
    if (ruleWording) {
      if (buylistRule.price && buylistRule.price > 0) {
        priceWording = ` with a price ${ruleWording} $${buylistRule.price}`;
      }
    } else {
      priceWording = " with any price";
    }

    if (buylistRule.game && buylistRule.game != "all") {
      if (buylistRule.cardName) {
        return `Applied to card '${buylistRule.cardName}' for '${buylistRule.gameName}'${priceWording}`;
      }
      if (buylistRule.setName) {
        return `Applied across cards within '${buylistRule.setName}' for '${buylistRule.gameName}'${priceWording}`;
      }

      return `Applied to all '${buylistRule.gameName}' cards${priceWording}`;
    }

    return `Applied across all cards${priceWording}`;
  }

  componentDidUpdate() {
    if (
      this.modalVisible ||
      this.deleteModalVisible ||
      this.deleteSelectedModalVisible
    ) {
      this.modalRef.current?.focus();
    } else {
      this.listRef.current?.focus();
    }
  }

  render() {
    return (
      <>
        {this.props.store.BuylistRulesStore.buylistRules ? (
          <>
            <SectionHeaderLayout title="Buylist Rules">
              <ActionButtonsLayout>
                <ButtonComponent
                  primary
                  icon="fal fa-plus-square"
                  iconPosition="right"
                  isLink
                  to="/buylists/rules/add"
                  onClick={() => {
                    segmentAnalytics.track(CREATE_BUYLIST_RULE_CLICKED);
                    this.setBuylistRuleInStore.bind(this, null);
                  }}
                >
                  Create new rule
                </ButtonComponent>
              </ActionButtonsLayout>
            </SectionHeaderLayout>
            <div className="buylistRules">
              <table>
                <thead>
                  <tr>
                    <td>
                      {this.selectedBuylistRules &&
                      this.selectedBuylistRules.length > 0 ? (
                        <IconButton
                          icon="far fa-trash-alt"
                          light
                          className="deleteButton"
                          aria-label={`Delete ${this.selectedBuylistRules.length} selected rules`}
                          onClick={(event) => {
                            this.listRef.current = event.currentTarget;
                            this.showDeleteModalForSelectedBuylistRules();
                          }}
                          title="Delete selected"
                        />
                      ) : null}
                    </td>
                    <td>
                      {this.selectedBuylistRules &&
                      this.selectedBuylistRules.length > 0 ? (
                        <span className="text">
                          {this.selectedBuylistRules.length} selected
                        </span>
                      ) : null}
                    </td>
                    <td>Description</td>
                    <td className="text-center">View</td>
                    <td className="text-center">Edit</td>
                    <td className="text-center">Delete</td>
                  </tr>
                </thead>
                <tbody>
                  {this.props.store.BuylistRulesStore.buylistRules.map(
                    (buylistRule, i) => (
                      <tr key={i}>
                        <td>
                          <label className="checkbox">
                            <input
                              id={`additionalInfoRequired${i}`}
                              type="checkbox"
                              onChange={(event) => {
                                this.listRef.current = event.currentTarget;
                                this.handleOnChangeForCheckbox(
                                  i,
                                  buylistRule.id
                                );
                              }}
                              aria-label={`${buylistRule.name} input`}
                            />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td className="text-bold">{buylistRule.name}</td>
                        <td>
                          {this.generateBuylistRuleDescription(buylistRule)}
                        </td>
                        <td className="text-center">
                          <IconButton
                            className="userActions"
                            icon="far fa-eye"
                            title="View rule"
                            onClick={(event) => {
                              this.listRef.current = event.currentTarget;
                              this.showViewingModal(buylistRule);
                            }}
                            segmentEventName={VIEW_BUYLIST_RULE_CLICKED}
                          />
                        </td>
                        <td className="text-center">
                          <IconButton
                            className="userActions"
                            icon="far fa-edit"
                            title="Edit rule"
                            to={`/buylists/rules/update/${buylistRule.id}`}
                            isLink
                            segmentEventName={EDIT_BUYLIST_RULE_CLICKED}
                          />
                        </td>
                        <td className="text-center">
                          <IconButton
                            className="userActions"
                            icon="far fa-trash-alt"
                            title="Delete rule"
                            onClick={(event) => {
                              this.listRef.current = event.currentTarget;
                              this.showDeleteModalFor(buylistRule);
                            }}
                            segmentEventName={DELETE_BUYLIST_RULE_CLICKED}
                          />
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
            {this.modalVisible ? (
              <Modal
                ref={this.modalRef}
                onClose={() => this.setModalVisible(false)}
                large
              >
                <Modal.Header>View rule: {this.buylistRule.name}</Modal.Header>
                <Modal.Content>
                  <h3 className="title">Rule description</h3>
                  <p className="desc">
                    {this.generateBuylistRuleDescription(this.buylistRule)}
                  </p>
                  <div className="wrapper">
                    {this.buylistRule.buylistVariants.map(
                      (buylistVariant, i) => (
                        <Fragment key={i}>
                          <h3 className="title1">
                            {buylistVariant.variantName}
                          </h3>
                          <div className="variant" key={i}>
                            <table className="table">
                              <thead>
                                <tr>
                                  <td
                                    style={{
                                      textAlign: "right",
                                      minWidth: "214px",
                                      width: "214px",
                                    }}
                                  />
                                  <td>
                                    <strong>Default</strong>
                                  </td>
                                  {buylistVariant &&
                                  buylistVariant.buylistVariantRarityRules
                                    .length > 0 ? (
                                    <>
                                      {buylistVariant.buylistVariantRarityRules.map(
                                        (buylistVariantRarityRule, j) => (
                                          <td key={`${j}-rarity`}>
                                            <strong>
                                              {buylistVariantRarityRule.rarity}
                                            </strong>
                                          </td>
                                        )
                                      )}
                                    </>
                                  ) : null}
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <strong>Allows purchase</strong>
                                  </td>
                                  <td className="info">
                                    {buylistVariant &&
                                    buylistVariant.allowPurchase
                                      ? "Yes"
                                      : "No"}
                                  </td>
                                  {buylistVariant &&
                                  buylistVariant.buylistVariantRarityRules
                                    .length > 0 ? (
                                    <>
                                      {buylistVariant.buylistVariantRarityRules.map(
                                        (buylistVariantRarityRule, j) => (
                                          <td
                                            className="info"
                                            key={`${j}-allowPurchase`}
                                          >
                                            {buylistVariantRarityRule &&
                                            buylistVariantRarityRule.allowPurchase
                                              ? "Yes"
                                              : "No"}
                                          </td>
                                        )
                                      )}
                                    </>
                                  ) : null}
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Cash percent</strong>
                                  </td>
                                  <td className="info">
                                    {buylistVariant &&
                                    buylistVariant.cashPercent
                                      ? `${buylistVariant.cashPercent}%`
                                      : "N/A"}
                                  </td>
                                  {buylistVariant &&
                                  buylistVariant.buylistVariantRarityRules
                                    .length > 0 ? (
                                    <>
                                      {buylistVariant.buylistVariantRarityRules.map(
                                        (buylistVariantRarityRule, j) => (
                                          <td
                                            className="info"
                                            key={`${j}-cashPercent`}
                                          >
                                            {buylistVariantRarityRule &&
                                            buylistVariantRarityRule.cashPercent
                                              ? `${buylistVariantRarityRule.cashPercent}%`
                                              : "N/A"}
                                          </td>
                                        )
                                      )}
                                    </>
                                  ) : null}
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Store credit percent</strong>
                                  </td>
                                  <td className="info">
                                    {buylistVariant &&
                                    buylistVariant.storeCreditPercent
                                      ? `${buylistVariant.storeCreditPercent}%`
                                      : "N/A"}
                                  </td>
                                  {buylistVariant &&
                                  buylistVariant.buylistVariantRarityRules
                                    .length > 0 ? (
                                    <>
                                      {buylistVariant.buylistVariantRarityRules.map(
                                        (buylistVariantRarityRule, j) => (
                                          <td
                                            className="info"
                                            key={`${j}-storeCreditPercent`}
                                          >
                                            {buylistVariantRarityRule &&
                                            buylistVariantRarityRule &&
                                            buylistVariantRarityRule.storeCreditPercent
                                              ? `${buylistVariantRarityRule.storeCreditPercent}%`
                                              : "N/A"}
                                          </td>
                                        )
                                      )}
                                    </>
                                  ) : null}
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Overstock quantity</strong>
                                  </td>
                                  <td className="info">
                                    {buylistVariant && buylistVariant.quantity
                                      ? buylistVariant.quantity
                                      : "N/A"}
                                  </td>
                                  {buylistVariant &&
                                  buylistVariant.buylistVariantRarityRules
                                    .length > 0 ? (
                                    <>
                                      {buylistVariant.buylistVariantRarityRules.map(
                                        (buylistVariantRarityRule, j) => (
                                          <td
                                            className="info"
                                            key={`${j}-quantity`}
                                          >
                                            {buylistVariantRarityRule &&
                                            buylistVariantRarityRule.quantity
                                              ? buylistVariantRarityRule.quantity
                                              : "N/A"}
                                          </td>
                                        )
                                      )}
                                    </>
                                  ) : null}
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Allows overstock purchase</strong>
                                  </td>
                                  <td className="info">
                                    {buylistVariant &&
                                    buylistVariant.overQuantityAllowPurchase
                                      ? "Yes"
                                      : "No"}
                                  </td>
                                  {buylistVariant &&
                                  buylistVariant.buylistVariantRarityRules
                                    .length > 0 ? (
                                    <>
                                      {buylistVariant.buylistVariantRarityRules.map(
                                        (buylistVariantRarityRule, j) => (
                                          <td
                                            className="info"
                                            key={`${j}-overQuantityAllowPurchase`}
                                          >
                                            {buylistVariantRarityRule &&
                                            buylistVariantRarityRule.overQuantityAllowPurchase
                                              ? "Yes"
                                              : "No"}
                                          </td>
                                        )
                                      )}
                                    </>
                                  ) : null}
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Overstock cash percent</strong>
                                  </td>
                                  <td className="info">
                                    {buylistVariant &&
                                    buylistVariant.overQuantityCashPercent
                                      ? `${buylistVariant.overQuantityCashPercent}%`
                                      : "N/A"}
                                  </td>
                                  {buylistVariant &&
                                  buylistVariant.buylistVariantRarityRules
                                    .length > 0 ? (
                                    <>
                                      {buylistVariant.buylistVariantRarityRules.map(
                                        (buylistVariantRarityRule, j) => (
                                          <td
                                            className="info"
                                            key={`${j}-overQuantityCashPercent`}
                                          >
                                            {buylistVariantRarityRule &&
                                            buylistVariantRarityRule.overQuantityCashPercent
                                              ? `${buylistVariantRarityRule.overQuantityCashPercent}%`
                                              : "N/A"}
                                          </td>
                                        )
                                      )}
                                    </>
                                  ) : null}
                                </tr>
                                <tr>
                                  <td>
                                    <strong>
                                      Overstock store credit percent
                                    </strong>
                                  </td>
                                  <td className="info">
                                    {buylistVariant &&
                                    buylistVariant.overQuantityStoreCreditPercent
                                      ? `${buylistVariant.overQuantityStoreCreditPercent}%`
                                      : "N/A"}
                                  </td>
                                  {buylistVariant &&
                                  buylistVariant.buylistVariantRarityRules
                                    .length > 0 ? (
                                    <>
                                      {buylistVariant.buylistVariantRarityRules.map(
                                        (buylistVariantRarityRule, j) => (
                                          <td
                                            className="info"
                                            key={`${j}-overQuantityStoreCreditPercent`}
                                          >
                                            {buylistVariantRarityRule &&
                                            buylistVariantRarityRule.overQuantityStoreCreditPercent
                                              ? `${buylistVariantRarityRule.overQuantityStoreCreditPercent}%`
                                              : "N/A"}
                                          </td>
                                        )
                                      )}
                                    </>
                                  ) : null}
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </Fragment>
                      )
                    )}
                  </div>
                </Modal.Content>
              </Modal>
            ) : null}
            {this.deleteModalVisible ? (
              <ConfirmationModal
                confirmWord="Delete"
                confirmAction={() =>
                  this.deleteBuylistRule(this.deleteModalBuylistRule.id)
                }
                cancelAction={() => this.setDeleteModalVisible(false)}
                ref={this.modalRef}
              >
                <p>
                  If you proceed with deleting your buylist rule:{" "}
                  <strong>{this.deleteModalBuylistRule.name}</strong>, you will
                  not be able to restore it.
                </p>
                <br />
                <p>Are you sure you want to delete the buylist rule?</p>
              </ConfirmationModal>
            ) : null}
            {this.deleteSelectedModalVisible ? (
              <ConfirmationModal
                confirmWord="Delete"
                confirmAction={() => this.deleteSelectedBuylistRules()}
                cancelAction={() => this.setDeleteSelectedModalVisible(false)}
                ref={this.modalRef}
              >
                <p>
                  If you proceed with deleting your buylist rules, you will not
                  be able to restore them.
                </p>
                <br />
                <p>
                  Are you sure you want to delete the selected buylist rules?
                </p>
              </ConfirmationModal>
            ) : null}
          </>
        ) : (
          <Loader />
        )}
      </>
    );
  }
}
