import React from "react";
import { sanitize } from "dompurify";

interface HtmlContentProps {
  content: string;
  className?: string;
}

function HtmlContent(props: HtmlContentProps) {
  const { content, className } = props;

  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{
        __html: sanitize(content),
      }}
    />
  );
}

export default HtmlContent;
