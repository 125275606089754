import React, { useRef, useState } from "react";
import debounce from "lodash/debounce";
import { useFormikContext } from "formik";
import { EbayCategoryTree, fetchCategories } from "../../../api/rest/ebay";
import Spinner from "../../../components/generic/Spinner";
import EbayCategoryList from "../../../components/integrations/EbayCategoryList";
import "./CategoryContainer.scss";

interface CategoryContainerProps {
  categoryIdOverride?: string;
}

type ValueType = {
  categoryName: string;
};

function CategoryContainer(props: CategoryContainerProps) {
  const { categoryIdOverride } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [categoryTree, setCategoryTree] = useState<EbayCategoryTree>();
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchTerm(value);
    setIsLoading(true);
    loadCategoriesDebounced.current(value);
  };

  const loadCategories = (query) => {
    fetchCategories(query)
      .then((data: EbayCategoryTree) => setCategoryTree(data))
      .catch((error: Error) => console.log(error))
      .finally(() => setIsLoading(false));
  };

  const loadCategoriesDebounced = useRef(debounce(loadCategories, 500));

  const {
    values: { categoryName },
    errors: { categoryName: categoryNameError },
  } = useFormikContext<ValueType>();

  return (
    <div className="category-container">
      <div className="category-container__top">
        <div className="category-container__current">
          Current category:{" "}
          {categoryName || (
            <span className="ebay-listing-form__field-error">None</span>
          )}
          {categoryNameError ? (
            <p className="ebay-listing-form__field-error">
              <br />
              {categoryNameError}
            </p>
          ) : null}
        </div>
        <div className="category-container__search">
          <label
            className="category-container__search-label"
            htmlFor="searchTerm"
          >
            Search
          </label>
          <input
            id="searchTerm"
            type="text"
            value={searchTerm}
            onChange={handleSearch}
            data-testid="searchCategory"
          />
        </div>
      </div>
      <div className="category-container__categories">
        <Spinner isLoading={isLoading}>
          <EbayCategoryList
            searchTerm={searchTerm}
            categoryTree={categoryTree}
            categoryIdOverride={categoryIdOverride}
          />
        </Spinner>
      </div>
    </div>
  );
}

export default CategoryContainer;
