import React from "react";
import { Field } from "formik";
import Input from "../form/Input";
import CheckboxComponent from "../generic/CheckboxComponent";
import "./MxMerchantTerminalOptions.scss";

interface MxMerchantTerminalOptionsProps {
  values: {
    entryModes?: Record<string, boolean>;
    paymentMethods?: Record<string, boolean>;
    providerKey?: string;
    externalSource: string;
    externalId: string;
    key: {
      activationCode: string;
      authKey: string;
    };
  };
  setFieldValue: Function;
}

function MxMerchantTerminalOptions(props: MxMerchantTerminalOptionsProps) {
  const { values, setFieldValue } = props;

  const entryModes = [
    { name: "swipe", label: "Swipe" },
    { name: "insert", label: "Insert" },
    { name: "tap", label: "Tap" },
    { name: "pinpad", label: "Pinpad" },
  ];

  const paymentMethods = [
    { name: "credit", label: "Credit card" },
    { name: "debit", label: "Debit card" },
  ];

  const renderInput = (group: string, name: string, label: string) => (
    <CheckboxComponent
      key={`${group}:${name}`}
      name={`${group}:${name}`}
      label={label}
      checked={values[group][name]}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue(`${group}.${name}`, event.target.checked);
        values[group][name] = event.target.checked;
      }}
    />
  );

  const handleProviderChange = (event: React.ChangeEvent<HTMLSelectElement>) =>
    setFieldValue("providerKey", event.target.value);

  return (
    <div className="MxMerchantTerminalOptions">
      <Field
        component={Input}
        name="description"
        label="Description"
        required
      />
      <h3>Terminal</h3>
      <select
        name="providerKey"
        value={values.providerKey}
        onChange={handleProviderChange}
        className="MxMerchantTerminalOptions__providerSelect"
      >
        <option value="anywherecommerce">AnywhereCommerce</option>
        <option value="dejavoo">Dejavoo</option>
      </select>
      <h3>Entry Modes</h3>
      <div className="MxMerchantTerminalOptions__optionList">
        {entryModes.map(({ name, label }) =>
          renderInput("entryModes", name, label)
        )}
      </div>
      <h3>Payment Methods</h3>
      <div className="MxMerchantTerminalOptions__optionList">
        {paymentMethods.map(({ name, label }) =>
          renderInput("paymentMethods", name, label)
        )}
      </div>
      {values.providerKey === "dejavoo" ? (
        <>
          <h3>Terminal Settings</h3>
          <Field
            component={Input}
            name="key.activationCode"
            label="Activation code"
            helperText="Found in the Dejavoo Denovo portal, labeled as 'SPIn RegisterID'"
            required
          />
          <Field
            component={Input}
            name="key.authKey"
            label="Auth key"
            helperText="Found in the Dejavoo Denovo portal, labeled as 'SPIn AuthKey'"
            required
          />
          <Field
            component={Input}
            name="externalSource"
            label="External source"
          />
          <Field component={Input} name="externalId" label="External ID" />
        </>
      ) : null}
    </div>
  );
}

export default MxMerchantTerminalOptions;
