import React from "react";

interface DeleteButtonCellProps {
  onClick: () => void;
}

function DeleteButtonCell({ onClick }: DeleteButtonCellProps) {
  return (
    <i className="far fa-trash-alt" onClick={() => onClick()} title="Delete" />
  );
}

export default DeleteButtonCell;
