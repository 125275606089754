import { CartWithTotals } from "../types/order";
import { mapCart } from "./cart";
import { calculateCartTotals } from "./helpers/calculateCartTotals";

export function mapCartWithTotals(
  data: any,
  customerSettings: CustomerSettings
): CartWithTotals {
  const cart = mapCart(data);
  const totals = calculateCartTotals(
    data,
    customerSettings.taxIncluded,
    customerSettings.taxRate
  );
  return {
    ...cart,
    totals,
  };
}
