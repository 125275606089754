import React, { useEffect, useState } from "react";
import {
  fetchSyncSettingsProductCount,
  Setting,
} from "../../api/rest/integrations";
import ButtonComponent from "../../components/generic/ButtonComponent";
import Loader from "../../components/generic/Loader";
import Modal from "../generic/Modal";
import { percentToDecimal } from "../../utils/integrations";

const pluariseListings = (count?: number) =>
  count === 1 ? "listing" : "listings";

interface EbayListingConfirmationComponentProps {
  setting: Setting;
  isExistingSetting: boolean;
  setShowConfirmation: Function;
}

function EbayListingConfirmationComponent(
  props: EbayListingConfirmationComponentProps
) {
  const { setting, isExistingSetting, setShowConfirmation } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [count, setCount] = useState<number>();

  const getCount = () => {
    fetchSyncSettingsProductCount(percentToDecimal(setting))
      .then((data: any) => {
        setCount(data?.data);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => getCount(), []);

  if (isLoading)
    return (
      <Modal small onClose={() => setShowConfirmation(false)}>
        <Modal.Content>
          <Loader />
        </Modal.Content>
      </Modal>
    );

  if (!Number.isInteger(count))
    return (
      <Modal small onClose={() => setShowConfirmation(false)}>
        <Modal.Header>Failed to create setting</Modal.Header>
        <Modal.Content>
          There was an error creating your rule. Please check your settings and
          try again.
        </Modal.Content>
      </Modal>
    );

  return (
    <Modal onClose={() => setShowConfirmation(false)}>
      <Modal.Header>
        {isExistingSetting
          ? `This setting will potentially update ${
              count || "multiple"
            } ${pluariseListings(
              count
            )}, including creating new listings. This is an estimate and the actual number may vary`
          : `This setting will create ${count || "multiple"} ${pluariseListings(
              count
            )}`}
      </Modal.Header>
      <Modal.Content>
        <p>
          Each listing created on eBay has a listing fee associated with it. For
          more details on fees,{" "}
          <a
            href="https://www.ebay.com/help/selling/fees-credits-invoices/store-selling-fees?id=4122"
            target="_blank"
            rel="noreferrer"
          >
            see here
          </a>
        </p>
        <p>
          Are you sure you want to {isExistingSetting ? "update" : "create"}{" "}
          this rule?
        </p>
      </Modal.Content>
      <Modal.Actions>
        <ButtonComponent onClick={() => setShowConfirmation(false)}>
          Cancel
        </ButtonComponent>
        <ButtonComponent
          primary
          type="submit"
          form="integrationSettingForm"
          autoFocus
        >
          Continue
        </ButtonComponent>{" "}
      </Modal.Actions>
    </Modal>
  );
}

export default EbayListingConfirmationComponent;
