import {
  GetStoreCreditTransactionSummaryByCustomerQuery,
  GetStoreCreditTransactionSummaryQuery,
} from "../../generated/reporting";
import { StoreCreditTransactionsData } from "../../types/reports";

export const formatStoreCreditTransactionSummary = (
  report?:
    | GetStoreCreditTransactionSummaryQuery
    | GetStoreCreditTransactionSummaryByCustomerQuery
): StoreCreditTransactionsData[] => {
  const reportData = report?.Metrics?.StoreCreditByRange;
  if (!reportData) {
    return [];
  }
  // Add an ID field so the table can use it as a key
  return reportData.map((row, index) => ({
    ...row,
    email: row?.email ?? "",
    customerName:
      row?.firstName || row?.lastName
        ? `${row?.firstName} ${row?.lastName}`
        : "",
    id: index,
  }));
};
