import { Column } from "../../types/table";

const ProductPricingInfoColumns: Column[] = [
  {
    header: "Variant",
    accessorKey: "variant",
  },
  {
    header: "Current Price",
    accessorKey: "currentPrice",
  },
  {
    header: "Source Price",
    accessorKey: "sourcePrice",
  },
  {
    header: "Target Price",
    accessorKey: "targetPrice",
  },
  {
    header: "Rule Applied",
    accessorKey: "ruleApplied",
  },
  {
    header: "",
    accessorKey: "synced",
  },
];

export default ProductPricingInfoColumns;
