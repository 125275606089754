import React from "react";
import { Field } from "formik";
import { PRODUCT_EDIT_AUTOCOMPLETE } from "../../constants/featureFlags";
import Input from "../form/Input";
import { useProductVendorsQuery } from "../../generated/graphql";
import { useFeatureFlag } from "../../hooks/globalSettingsHooks";

interface ProductVendorInputProps {
  name: string;
}

function ProductVendorInput(props: ProductVendorInputProps) {
  const { name } = props;
  const autocompleteEnabled = useFeatureFlag(PRODUCT_EDIT_AUTOCOMPLETE);
  const { data } = useProductVendorsQuery(
    {},
    {
      enabled: autocompleteEnabled,
      refetchOnWindowFocus: false,
      cacheTime: 60_000,
    }
  );

  return (
    <>
      <Field
        component={Input}
        name={name}
        label="Product Vendor"
        type="string"
        list="productVendorsList"
      />
      <datalist id="productVendorsList">
        {data?.Product?.filter(
          (product) => product.vendor && product.vendor.length > 1
        ).map((product) => (
          <option key={product.vendor} value={product.vendor}>
            {product.vendor}
          </option>
        ))}
      </datalist>
    </>
  );
}

export default ProductVendorInput;
