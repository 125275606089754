export const getChildByType = (type: string, children: React.ReactNode) => {
  if (Array.isArray(children)) {
    return children.find((child) => child.type?.[type]) ?? null;
  }
  if (
    typeof children === "object" &&
    "type" in children &&
    children.type?.[type]
  ) {
    return children;
  }
  return null;
};
