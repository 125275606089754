import React from "react";

const EXAMPLE_CSV_URL = "https://cdn.binderpos.com/csv/binder-sample.csv";
const CSV_HELP_URL =
  "https://binderpos.freshdesk.com/support/solutions/articles/43000674138-importing-csvs-in-binderpos";

function UploadCsvFileTooltip() {
  return (
    <article>
      <h3>Upload CSV File</h3>
      <p>
        CSV Import is an extremely helpful tool for quickly managing large
        quantities of stock, especially concerning TCG singles. Because of this
        ease of use and convenience, many customers use CSVs to manage their
        singles. New BinderPOS customers often use them to move their singles
        inventory over much more quickly. By using a CSV file, you can import or
        export a large number of products and their details at one time.
      </p>
      <p>Tips for Getting Started with Binder CSVs</p>
      <ul>
        <li>File uploads can be as large as 10MB</li>
        <li>Remember to maintain accurately titled columns</li>
        <li>
          In order for the system to regularly send updates, make sure product
          and Shopify IDs are accurate
        </li>
      </ul>
      <p>
        <a href={EXAMPLE_CSV_URL} target="_blank" rel="noopener">
          Download an example CSV file
        </a>
      </p>
      <p>
        Please read our{" "}
        <a href={CSV_HELP_URL} target="_blank" rel="noopener">
          Importing CSVs in BinderPOS help article
        </a>{" "}
        for more information.
      </p>
    </article>
  );
}

export default UploadCsvFileTooltip;
