import React, { FormEvent, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { updateApiKey } from "../../../api/rest/cfbmarket";
import ButtonComponent from "../../../components/generic/ButtonComponent";
import { notify } from "../../../helpers/notificationHelpers";

function ConfigureContainer() {
  const [isUpdating, setIsUpdating] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const keyRef = useRef<HTMLInputElement>();
  const secretRef = useRef<HTMLInputElement>();

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setIsUpdating(true);
    const key = keyRef.current.value;
    const secret = secretRef.current.value;
    updateApiKey(key, secret)
      .then(() => {
        console.log("Saved");
        setShowConfirmation(true);
      })
      .catch((error) => {
        console.error(error);
        notify.error(
          "Failed to update. Please check your API key and secret are correct"
        );
      })
      .finally(() => setIsUpdating(false));
    return false;
  };

  if (showConfirmation) {
    return (
      <div className="centered">
        <p>Your settings have been updated</p>
        <br />
        <Link className="btn2" to="/integrations/cfbmarket">
          Continue
        </Link>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <fieldset>
        <legend>Update API Credentials</legend>
        <p>
          For security reasons any previously entered keys will not be displayed
          here, however will be overwritten.
        </p>
        <div className="cfbMarket-apikeys-view__field">
          <label htmlFor="apiKey" className="cfbMarket-apikeys-view__label">
            API key
          </label>
          <input id="apiKey" ref={keyRef} />
        </div>
        <div className="cfbMarket-apikeys-view__field">
          <label htmlFor="apiSecret" className="cfbMarket-apikeys-view__label">
            API secret
          </label>
          <input id="apiSecret" ref={secretRef} />
        </div>
      </fieldset>
      <ButtonComponent primary disabled={isUpdating} type="submit">
        Save
      </ButtonComponent>
    </form>
  );
}

export default ConfigureContainer;
