import { SubmittedCart } from "../types/order";

export const formatSubmittedCarts = (order): SubmittedCart => ({
  ...order,
  submittedBy:
    order?.BinderCustomerUser_BinderCustomerUserToCart_submittedBy?.email ||
    order.submittedBy,
  till: {
    id: order?.BinderCustomerTill?.id,
    name: order?.BinderCustomerTill?.name || "",
  },
  customer: {
    firstName: order?.ShopifyCustomer?.firstName || "",
    lastName: order?.ShopifyCustomer?.lastName || "",
  },
});
