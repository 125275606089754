import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import ButtonComponent from "../generic/ButtonComponent";
import "./ResultsPagination.scss";
import { ItemList } from "../../pos/ItemStore";

interface ResultsPaginationProps {
  posStore?: ItemList;
}

@inject("posStore")
@observer
class ResultsPagination extends Component<ResultsPaginationProps> {
  render() {
    const {
      moreResultsAvailable,
      searchOffset,
      searchLimit,
      fetchingSearch,
      searchTerm,
      items,
      waitingToSearch,
      changeSearchPage,
    } = this.props.posStore;

    const handlePrevClick = () => {
      const newOffset = searchOffset - searchLimit;
      changeSearchPage(newOffset);
    };

    const handleNextClick = () => {
      const newOffset = searchOffset + searchLimit;
      changeSearchPage(newOffset);
    };

    if (
      items.length === 0 ||
      searchTerm === "" ||
      fetchingSearch ||
      waitingToSearch
    ) {
      return <div className="ResultsPagination--empty" />;
    }

    if (!moreResultsAvailable && searchOffset === 0) {
      return <div className="ResultsPagination--empty" />;
    }

    return (
      <div className="ResultsPagination">
        <div className="ResultsPagination__buttons">
          <ButtonComponent
            disabled={searchOffset === 0}
            onClick={handlePrevClick}
          >
            <i className="fas fa-caret-left" /> Prev Page
          </ButtonComponent>

          <ButtonComponent
            disabled={!moreResultsAvailable}
            onClick={handleNextClick}
          >
            Next Page <i className="fas fa-caret-right" />
          </ButtonComponent>
        </div>
      </div>
    );
  }
}

export default ResultsPagination;
