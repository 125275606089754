import React from "react";
import { useHistory } from "react-router-dom";
import Modal from "../generic/Modal";

interface CustomerConfirmationModalProps {
  isUpdate: boolean;
  customerTitle: string;
  isPos: boolean;
  handleClose: () => void;
}

function CustomerConfirmationModal(props: CustomerConfirmationModalProps) {
  const { isUpdate, customerTitle, isPos, handleClose } = props;
  const history = useHistory();

  const onClose = () => (isPos ? handleClose() : history.push("/customers"));

  return (
    <Modal onClose={onClose}>
      <Modal.Header>
        {isUpdate ? "Customer has been updated" : "Customer has been added"}
      </Modal.Header>
      <Modal.Content>
        {isUpdate
          ? `${customerTitle} has been updated successfully.`
          : `${customerTitle} has been added.`}
      </Modal.Content>
    </Modal>
  );
}

export default CustomerConfirmationModal;
