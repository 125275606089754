import { _fetch } from "..";
import { BASE_URL } from "../../constants/api";
import { User } from "../../types/user";

export const fetchUserById = (userId: string): Promise<User> =>
  _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/users/byId/${userId}`,
  });

export const fetchUsers = (): Promise<User[]> =>
  _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/users/list`,
  });

export const createUser = (user: Partial<User>): Promise<User> =>
  _fetch({
    method: "POST",
    endpoint: `${BASE_URL}/users/create`,
    payload: user,
  });

export const updateUser = (user: Partial<User>): Promise<User> =>
  _fetch({
    method: "PUT",
    endpoint: `${BASE_URL}/users/update`,
    payload: user,
  });

export const disableUser = (userId: number): Promise<User> =>
  _fetch({
    method: "PUT",
    endpoint: `${BASE_URL}/users/${userId}/disable`,
  });

export const enableUser = (userId: number): Promise<User> =>
  _fetch({
    method: "PUT",
    endpoint: `${BASE_URL}/users/${userId}/enable`,
  });

export const sendPasswordReset = (userId: number): Promise<User> =>
  _fetch({
    method: "POST",
    endpoint: `${BASE_URL}/users/${userId}/passwordReset`,
  });
