export const toolbarTitle = ["atrributes", "undo", "redo"];

export const toolbarMobile = ["atrributes", "undo", "redo", "list"];

export const toolbarAll = [
  "atrributes",
  "undo",
  "redo",
  "font",
  "fontSize",
  "formatBlock",
  "paragraphStyle",
  "blockquote",
  "bold",
  "underline",
  "italic",
  "strike",
  "subscript",
  "superscript",
  "fontColor",
  "hiliteColor",
  "textStyle",
  "removeFormat",
  "outdent",
  "indent",
  "align",
  "horizontalRule",
  "list",
  "lineHeight",
  "table",
  "link",
  "image",
  "video",
  "fullScreen",
  "showBlocks",
  "codeView",
  "preview",
];
