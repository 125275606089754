import React from "react";

interface ModalActionsProps {
  children: React.ReactNode;
}

function ModalActions(props: ModalActionsProps) {
  const { children } = props;

  return <footer className="NewModal__actions">{children}</footer>;
}

ModalActions.isModalActions = true;

export default ModalActions;
