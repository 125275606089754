import React, { useEffect, useRef } from "react";
import { Field } from "formik";
import Input from "../form/Input";

const getLocationInfoByType = (
  address: google.maps.GeocoderAddressComponent[],
  typeName: string
) =>
  address.find((line) => line.types.includes(typeName)) || {
    long_name: "",
    short_name: "",
  };

interface EventLocationInputProps {
  setFieldValue: SetFieldValue;
}

function EventLocationInput(props: EventLocationInputProps) {
  const { setFieldValue } = props;
  const addressLookupRef = useRef<HTMLInputElement>(null);

  const populateFields = (autocomplete: google.maps.places.Autocomplete) => {
    const addressObject = autocomplete.getPlace();
    const address = addressObject.address_components;
    setFieldValue(
      "streetAddress",
      `${getLocationInfoByType(address, "street_number").long_name} ${
        getLocationInfoByType(address, "route").long_name
      }`
    );
    const cityKey = [
      "postal_town",
      "locality",
      "sublocality",
      "administrative_area_level_3",
    ].find((key) => getLocationInfoByType(address, key).long_name !== "");
    setFieldValue("city", getLocationInfoByType(address, cityKey).long_name);
    setFieldValue(
      "state",
      getLocationInfoByType(address, "administrative_area_level_1").long_name
    );
    setFieldValue(
      "zipCode",
      getLocationInfoByType(address, "postal_code").short_name
    );
  };

  useEffect(() => {
    const { google } = window;

    if (!google?.maps?.places) return;
    const autocomplete = new google.maps.places.Autocomplete(
      addressLookupRef.current,
      {
        fields: ["address_components"],
        types: ["address"],
      }
    );
    autocomplete.addListener("place_changed", () => {
      populateFields(autocomplete);
      if (addressLookupRef.current) addressLookupRef.current.value = "";
    });
  }, [google]);

  return (
    <div className="container-fluid Event__formSection">
      <div className="col-lg-12 col-xl-12">
        <div className="row">
          <label className="Event__formSectionHeader" htmlFor="autocomplete">
            Location of event
          </label>
        </div>
        <div className="row">
          <input
            onKeyPress={(event) => {
              if (event.key === "Enter") event.preventDefault();
            }}
            id="autocomplete"
            className="input"
            type="text"
            ref={addressLookupRef}
          />

          <p className="Event__helpText">
            Please search a location above or enter manually below
          </p>
        </div>
      </div>
      <div className="row">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4">
              <Field
                component={Input}
                name="buildingName"
                type="text"
                label="Building Name"
              />
            </div>
            <div className="col-lg-8">
              <Field
                component={Input}
                name="streetAddress"
                type="text"
                label="Street Address"
              />
            </div>
            <div className="col-lg-12 col-xl-4">
              <Field component={Input} name="city" type="text" label="City" />
            </div>
            <div className="col-lg-12 col-xl-4">
              <Field component={Input} name="state" type="text" label="State" />
            </div>
            <div className="col-lg-12 col-xl-4">
              <Field
                component={Input}
                name="zipCode"
                type="text"
                label="Zip code"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventLocationInput;
