const POSMenuStructure = {
  title: "POS Settings",
  activeClass: "cyan",
  links: [
    {
      title: "Point of Sale",
      href: "pos",
    },
    {
      title: "Tills / Registers",
      href: "pointOfSale/tills",
    },
    {
      title: "Submitted Carts",
      href: "pointOfSale/carts",
    },
    {
      title: "End of Day Reports",
      href: "pointOfSale/eodReports",
    },
    {
      title: "Tax Settings",
      href: "pointOfSale/settings/tax",
    },
    {
      title: "Failed to Sync from POS",
      href: "pointOfSale/failedToSyncProducts",
    },
    {
      title: "Payment Terminal Settings",
      href: "pointOfSale/paymentTerminal",
    },
    {
      title: "Catalog Update Settings",
      href: "pointOfSale/catalogUpdates",
    },
    {
      title: "Location Settings",
      href: "pointOfSale/locationSettings",
    },
    {
      title: "Misc Settings",
      href: "pointOfSale/miscSettings",
    },
    {
      title: "Till Settings",
      href: "pointOfSale/settings/till",
    },
    {
      title: "Kiosk Settings",
      href: "pointOfSale/kioskSettings",
    },
    {
      title: "Extra Notes Fields",
      href: "pointOfSale/notes",
    },
  ],
};

export default POSMenuStructure;
