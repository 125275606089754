import React, { MutableRefObject } from "react";
import ButtonComponent from "../../components/generic/ButtonComponent";
import LinkComponent from "../../components/generic/LinkComponent";
import "./EbaySettingsList.scss";
import { ADD_NEW_POLICY_CLICKED } from "../../constants/eventsTracked";

interface EbaySettingsListProps {
  settingsTitle: string;
  settings: EbaySettingBase[];
  deletingSetting: number;
  refreshSettings?: Function;
  setEditSetting: Function;
  setDeletingSetting: Function;
  listRef?: MutableRefObject<any>;
}

function EbaySettingsList(props: EbaySettingsListProps) {
  const {
    settingsTitle,
    settings,
    deletingSetting,
    refreshSettings,
    setEditSetting,
    setDeletingSetting,
    listRef,
  } = props;

  return (
    <div className="ebay-settings-list">
      <div className="ebay-settings-list__entries">
        {settings.map((setting: EbaySettingBase) => (
          <React.Fragment key={setting.id}>
            <span key={setting.id} className="ebay-settings-list__entry-name">
              {setting.name}
            </span>
            <ButtonComponent
              primary
              key={`edit_${setting.id}`}
              icon="fas fa-edit"
              onClick={() => {
                listRef.current = `${setting.id}_edit`;
                setEditSetting(setting.id);
              }}
              autoFocus={listRef.current === `${setting.id}_edit`}
            >
              Edit
            </ButtonComponent>
            <ButtonComponent
              key={`delete_${setting.id}`}
              icon="fas fa-trash-alt"
              onClick={() => {
                listRef.current = `${setting.id}_delete`;
                setDeletingSetting(setting.id);
              }}
              disabled={deletingSetting === setting.id}
              autoFocus={listRef.current === `${setting.id}_delete`}
            >
              Delete
            </ButtonComponent>
          </React.Fragment>
        ))}
      </div>
      <div className="ebay-settings-list__general-actions">
        <ButtonComponent
          primary
          icon="fas fa-plus"
          onClick={() => {
            listRef.current = "add";
            setEditSetting(-1);
          }}
          segmentEventName={ADD_NEW_POLICY_CLICKED}
          segmentEventProperties={{
            integration_type: "ebay",
            policy_type: settingsTitle,
          }}
          autoFocus={listRef.current === "add"}
        >
          Add
        </ButtonComponent>
        {refreshSettings ? (
          <ButtonComponent
            primary
            icon="fas fa-sync"
            onClick={() => refreshSettings()}
          >
            Refresh Policies from Ebay
          </ButtonComponent>
        ) : null}
        <div>
          <LinkComponent to="/integrations/ebay/policies">
            Return to policies
          </LinkComponent>
        </div>
      </div>
    </div>
  );
}

export default EbaySettingsList;
