import React, { useEffect, useState } from "react";
import { Paging, Spinner } from "../../components";
import ButtonComponent from "../../components/generic/ButtonComponent";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";
import SubSectionHeaderLayout from "../../components/layout/SubSectionHeaderLayout";
import CSVExportHistoryTableColumns from "../../components/product/CSVExportHistoryTableColumns";
import TableComponent from "../../components/table/TableComponent";
import { useShowError } from "../../hooks/errorHooks";
import { useFetchCSVExportHistory } from "../../hooks/productDataExportHooks";
import "./CSVExportHistory.scss";

export const DEFAULT_PAGE_SIZE = 5;
const ITEMS_PER_PAGE = [5, 10, 20, 50];

const CSVExportHistory = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const showError = useShowError();
  const { data, isLoading, error, isRefetching, refetch } =
    useFetchCSVExportHistory(Number(currentPage * pageSize), pageSize + 1);

  useEffect(() => {
    if (error) {
      showError(
        null,
        "Failed to load CSV export history",
        "There was an error retrieving your CSV export history. Please refresh and try again"
      );
    }
  }, [error]);

  const handlePageSizeChange = (size: number) => {
    setCurrentPage(0);
    setPageSize(size);
  };

  const getPrevPage = () => setCurrentPage((page) => Math.max(page - 1, 0));
  const getNextPage = () => setCurrentPage((page) => page + 1);

  return (
    <div className="CSVExportHistory">
      <SectionHeaderLayout title="My Products > CSV Export History">
        <ButtonComponent
          primary
          isLink
          to="/products"
          icon="fas fa-caret-left"
          iconPosition="left"
        >
          Back to Products
        </ButtonComponent>
      </SectionHeaderLayout>
      <SubSectionHeaderLayout title="Export History">
        <ButtonComponent icon="far fa-sync" onClick={() => refetch()}>
          Reload table
        </ButtonComponent>
      </SubSectionHeaderLayout>
      <Spinner isLoading={isLoading || isRefetching}>
        <TableComponent
          data={data?.slice(0, pageSize) || []}
          columns={CSVExportHistoryTableColumns}
        />
      </Spinner>
      <Paging
        pageOffset={currentPage}
        currentPageItemCount={(data || []).length}
        pageSize={pageSize}
        maxItemsPerPage={pageSize + 1}
        pageSizeOptions={ITEMS_PER_PAGE}
        setPageSize={handlePageSizeChange}
        getPrevPage={getPrevPage}
        getNextPage={getNextPage}
      />
    </div>
  );
};

export default CSVExportHistory;
