import { toNumber } from "lodash";
import {
  Cart_By_PkDocument,
  Cart_By_PkQuery,
  Cart_By_PkQueryVariables,
  GetLatestCartForTillDocument,
  GetLatestCartForTillQuery,
  GetLatestCartForTillQueryVariables,
  KeepCartActiveDocument,
  KeepCartActiveMutation,
  KeepCartActiveMutationVariables,
  SortOrder,
  SubmitCartDocument,
  SubmitCartMutation,
  SubmitCartMutationVariables,
  UpdateCartDocument,
  UpdateCartDto,
  UpdateCartMutation,
  UpdateCartMutationVariables,
} from "../../generated/graphql";
import { graphQlFetcher } from "../../services/fetchers";
import { mapCart } from "../../transformers/cart";
import { Cart } from "../../types/order";
import { logError } from "../../helpers/loggingHelpers";

export async function getCartById(cartId: number): Promise<Cart> {
  const fetcher = graphQlFetcher<Cart_By_PkQuery, Cart_By_PkQueryVariables>(
    Cart_By_PkDocument,
    {
      cartByPkId: Number(cartId),
      orderBy: [
        {
          id: SortOrder.Asc,
        },
      ],
    }
  );
  const result = await fetcher();
  if (!result) {
    logError(new Error("No cart returned"), {
      function: "getCartById",
      cartId,
      result,
    });
  }
  return mapCart(result.Cart_by_pk);
}

export async function updateCart(cart: UpdateCartDto) {
  const fetcher = graphQlFetcher<
    UpdateCartMutation,
    UpdateCartMutationVariables
  >(UpdateCartDocument, {
    orderBy: [
      {
        id: SortOrder.Asc,
      },
    ],
    data: {
      ...cart,
      cartItems: cart.cartItems.map((item) => ({
        ...item,
        quantity: Number(item.quantity),
        price: numberAsStringSafe(item.price),
        shopifyPrice: numberAsStringSafe(item.shopifyPrice),
        actualPrice: numberAsStringSafe(item.actualPrice),
        discountAmount: numberAsStringSafe(item.discountAmount),
      })),
      taxLines: cart.taxLines.map((taxLine) => ({
        ...taxLine,
        price: String(taxLine.price),
      })),
      discountAmount: numberAsStringSafe(cart.discountAmount),
      discountValue: toNumber(cart.discountValue),
    },
  });
  const result = await fetcher();
  return result.UpdateCart && mapCart(result.UpdateCart);
}

export async function submitCart(cart: UpdateCartDto, submit: boolean) {
  const fetcher = graphQlFetcher<
    SubmitCartMutation,
    SubmitCartMutationVariables
  >(SubmitCartDocument, {
    submit,
    data: {
      ...cart,
      cartItems: cart.cartItems.map((item) => ({
        ...item,
        quantity: Number(item.quantity),
        price: numberAsStringSafe(item.price),
        shopifyPrice: numberAsStringSafe(item.shopifyPrice),
        actualPrice: numberAsStringSafe(item.actualPrice),
        discountAmount: numberAsStringSafe(item.discountAmount),
      })),
      taxLines: cart.taxLines.map((taxLine) => ({
        ...taxLine,
        price: String(taxLine.price),
      })),
      discountAmount: numberAsStringSafe(cart.discountAmount),
      discountValue: toNumber(cart.discountValue),
    },
  });
  const result = await fetcher();
  return result.SubmitCart && mapCart(result.SubmitCart);
}

/**
 * For use where graphql expects decimal
 * @param value value to be converted to a number as string
 * @returns value as string if the value was a number
 */
function numberAsStringSafe(value: any) {
  const maybeNumber = Number(value);
  if (Number.isNaN(maybeNumber)) {
    return null;
  }
  if (value === null) {
    return null;
  }
  return String(maybeNumber);
}

export async function getLatestCartForTill(tillId: number) {
  const fetcher = graphQlFetcher<
    GetLatestCartForTillQuery,
    GetLatestCartForTillQueryVariables
  >(GetLatestCartForTillDocument, {
    tillId,
    orderBy: [
      {
        id: SortOrder.Asc,
      },
    ],
  });

  const result = await fetcher();
  return result.GetLatestCartForTill && mapCart(result.GetLatestCartForTill);
}

export async function keepCartActive(cartId: number) {
  const fetcher = graphQlFetcher<
    KeepCartActiveMutation,
    KeepCartActiveMutationVariables
  >(KeepCartActiveDocument, { cartId });
  await fetcher();
}
