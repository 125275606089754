import { roundCents } from "../currencyHelpers";

export const calculateCartTenders = (
  tenders: { type: string; amount: number | string }[],
  changeDue: number
) => {
  const cartTenders: { type: string; amount: number | string }[] = [];
  tenders = JSON.parse(JSON.stringify(tenders));
  let changeFlag = false;
  tenders.reverse().forEach((tender) => {
    if (tender.type.toLowerCase() == "cash" && !changeFlag) {
      const cashTender = tender;
      const cashTenderedMinusChange = Number(cashTender.amount) - changeDue;
      if (cashTenderedMinusChange != 0) {
        changeFlag = true;
        cartTenders.push({
          type: cashTender.type,
          amount: roundCents(cashTenderedMinusChange),
        });
      }
    } else {
      if (tender.amount != 0) {
        cartTenders.push({
          type: tender.type,
          amount: roundCents(tender.amount),
        });
      }
    }
  });
  return cartTenders.reverse();
};
