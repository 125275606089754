import React, { useEffect, useState } from "react";
import {
  useFetchFailedToCreateEvents,
  useRepushFailedEvent,
} from "../../hooks/eventHooks";
import { useShowError } from "../../hooks/errorHooks";
import useTitle from "../../hooks/useTitle";
import { EventMenuStructure } from "../../menuStructures";
import SetActiveMenu from "../../menuStructures/SetActiveMenu";
import EventCard from "../../components/events/EventCard";
import ButtonComponent from "../../components/generic/ButtonComponent";
import ConfirmationModal from "../../components/generic/ConfirmationModal";
import Loader from "../../components/generic/Loader";
import Paging from "../../components/generic/Paging";
import GridLayout from "../../components/layout/GridLayout";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";

const LIMIT = 100;

function EventFailedToCreate() {
  const [offset, setOffset] = useState(0);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const {
    data: failedToCreateEvents = [],
    isLoading,
    error: loadingError,
  } = useFetchFailedToCreateEvents(offset, LIMIT);
  const { mutateAsync: retryPush, isMutating: isRepushing } =
    useRepushFailedEvent();
  const showError = useShowError();
  useTitle("Failed events");

  const handlePrevPageClick = () =>
    setOffset((prev) => Math.max(0, prev - LIMIT));

  const handleNextPageClick = () => setOffset((prev) => prev + LIMIT);

  const handleRetryClick = (id: number) =>
    retryPush(id)
      .then(() => setShowSuccessMessage(true))
      .catch((error) => {
        showError(
          error,
          "Failed to push event",
          "There was an error pushing the event to Shopify. Please try again"
        );
      });

  useEffect(() => {
    if (loadingError) showError(loadingError as DetailedError);
  }, [loadingError]);

  if (isLoading || isRepushing)
    return (
      <>
        <SetActiveMenu menuStructure={EventMenuStructure} />
        <Loader />
      </>
    );

  return (
    <>
      <SetActiveMenu menuStructure={EventMenuStructure} />
      <SectionHeaderLayout title="Event tickets that had issues being created" />
      <Paging
        pageOffset={offset}
        currentPageItemCount={failedToCreateEvents.length}
        maxItemsPerPage={LIMIT}
        getPrevPage={handlePrevPageClick}
        getNextPage={handleNextPageClick}
      />
      <GridLayout>
        {failedToCreateEvents.map((failedToCreate, index) => (
          <EventCard event={failedToCreate} key={index}>
            <EventCard.Actions>
              <ButtonComponent
                primary
                fullWidth
                icon="far fa-calendar-edit"
                iconPosition="left"
                onClick={() => handleRetryClick(failedToCreate.id)}
              >
                Retry event creation
              </ButtonComponent>
            </EventCard.Actions>
          </EventCard>
        ))}
      </GridLayout>
      {showSuccessMessage ? (
        <ConfirmationModal
          title="Success!"
          confirmAction={() => setShowSuccessMessage(false)}
        >
          Event has been repushed to Shopify
        </ConfirmationModal>
      ) : null}
    </>
  );
}

export default EventFailedToCreate;
