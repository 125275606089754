import React from "react";
import { API_STATE } from "../../constants/api";
import ButtonComponent from "../generic/ButtonComponent";
import "./Pagination.scss";

type StateProps =
  | {
      apiState: string;
      isSuccess?: boolean;
    }
  | {
      apiState?: string;
      isSuccess: boolean;
    };

interface PaginationProps {
  currentPage: number;
  totalRowCount: number;
  rowsPerPage: number;
  rowPerPageOptions: number[];
  setCurrentPage: (page: number) => void;
  setRowsPerPage: (page: number) => void;
}

function Pagination(props: PaginationProps & StateProps) {
  const {
    apiState,
    isSuccess,
    currentPage,
    totalRowCount,
    rowsPerPage,
    rowPerPageOptions,
    setCurrentPage,
    setRowsPerPage,
  } = props;

  if (apiState !== API_STATE.SUCCESS && !isSuccess) {
    return null;
  }

  return (
    <div className="Pagination">
      <ButtonComponent
        primary
        aria-label="previous page"
        disabled={currentPage < 1}
        onClick={() => setCurrentPage(currentPage - 1)}
      >
        Previous
      </ButtonComponent>
      <div className="Pagination__pageInfo">
        <label htmlFor="pageSelect">
          Page{" "}
          <input
            id="pageSelect"
            type="number"
            className="input"
            min="1"
            max={Math.ceil(totalRowCount / rowsPerPage)}
            value={currentPage + 1}
            onChange={(event) => {
              setCurrentPage(parseInt(event.target.value, 10) - 1);
            }}
          />{" "}
          of {Math.ceil(totalRowCount / rowsPerPage)}
        </label>
        <label htmlFor="rowsPerPage">
          <select
            id="rowsPerPage"
            className="Pagination__select"
            value={rowsPerPage}
            onChange={(event) => {
              setRowsPerPage(parseInt(event.target.value, 10));
            }}
          >
            {rowPerPageOptions.map((number) => (
              <option key={number} value={number}>
                {number}
              </option>
            ))}
          </select>
          rows per page
        </label>
      </div>
      <ButtonComponent
        primary
        aria-label="next page"
        disabled={(currentPage + 1) * rowsPerPage >= totalRowCount}
        onClick={() => setCurrentPage(currentPage + 1)}
      >
        Next
      </ButtonComponent>
    </div>
  );
}

export default Pagination;
