import { GetSalesByOrderBetaReportQuery } from "../../generated/reporting";
import { SalesByOrderBetaData } from "../../types/reports";
import { formatTenderKind } from "../helpers/formatTenderKind";

export const formatSalesByOrderBeta = (
  report?: GetSalesByOrderBetaReportQuery
): SalesByOrderBetaData => {
  let salesByOrder = report?.Metrics?.SalesByOrderBeta;
  if (!salesByOrder) salesByOrder = [];
  const tenderTypes = new Set<string>();
  const rows = [];
  salesByOrder.forEach((row) => {
    const newRow = { ...row };
    row.tenders?.forEach((tender) => {
      if (!tender) return;
      const kind = formatTenderKind(tender.kind);
      if (kind in newRow) {
        newRow[kind] = newRow[kind] + tender.amount;
      } else {
        newRow[kind] = tender.amount;
      }
      newRow[tender.type] = tender.amount;
      tenderTypes.add(tender.type);
    });
    newRow["beforeTax"] =
      (newRow["sale"] || 0) + (newRow["refund"] || 0) - (newRow.totalTax || 0);
    rows.push(newRow);
  });
  const sources = [...new Set(rows.map((row) => row.source))];
  return {
    rows,
    sources,
    tenders: [...tenderTypes].sort(),
  };
};
