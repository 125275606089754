import { Prompt } from "react-router-dom";
import { useNavigationPrompt } from "../../hooks/useNavigationPrompt";

interface NavigationPromptProps {
  message?: string;
}

function NavigationPrompt({
  message = "You have unsaved changes. Are you sure you want to leave?",
}: NavigationPromptProps) {
  const { isPromptEnabled } = useNavigationPrompt();

  return <Prompt when={isPromptEnabled} message={message} />;
}

export default NavigationPrompt;
