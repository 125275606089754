import { createContext, useEffect, useState } from "react";
import { API_STATE } from "../constants/api";
import { SortOrder } from "../generated/graphql";
import { useQueryState } from "../hooks/stateHooks";
import { mixedSearchCompareFunction, objectDive } from "../utils/data";

interface ReportsContextProps {
  apiState: string;
  totalRowCount: number;
  sourceData: any[];
  tableRowCount: number;
  tableData: any[];
  rowsPerPage: number;
  currentPage: number;
  filters: any[];
  sortColumn: {
    key: string;
    order: SortOrder;
  };
  filterValues: Record<string, any>;
  overrideTableHeight: number;
  setApiState: (state: string) => void;
  reset: VoidFunction;
  setSourceDataDetails: (data: any) => void;
  setFilters: (filters: any[]) => void;
  setTableDataDetails: (data: any) => void;
  setTableRowsPerPage: (rows: number) => void;
  setCurrentPage: (page: number) => void;
  setSortingColumn: (sortKey: any, sortOrder?: any) => void;
  updateFilterValue: (key: string, value: any) => void;
  computedTableData: () => any[];
  setOverrideTableHeight: (height: number) => void;
}

export const ReportsContext = createContext<ReportsContextProps>(null);

interface ReportsProviderProps {
  children: React.ReactNode;
}

function ReportsProvider({ children }: ReportsProviderProps) {
  const [apiState, setApiState] = useState(API_STATE.INITIAL);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [sourceData, setSourceData] = useState([]);
  const [tableRowCount, setTableRowCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pagination, setPagination] = useQueryState("pagination", {
    currentPage: 0,
    rowsPerPage: 20,
  });
  const [filters, setFilters] = useState([]);
  const [filterValues, setFilterValues] = useQueryState<Record<string, any>>(
    "filters",
    {}
  );
  const [sortColumn, setSortColumn] = useState<{
    key: string;
    order: SortOrder;
  }>({
    key: undefined,
    order: undefined,
  });
  const [overrideTableHeight, setOverrideTableHeight] = useState(0);

  useEffect(() => {
    setTableDataDetails(filteredData(sourceData));
    recalculateCurrentPage();
  }, [sourceData, filterValues]);

  const setTableDataDetails = (data) => {
    if (!data) {
      data = [];
    }
    setTableData(data);
    setTableRowCount(data.length);
  };

  const setSourceDataDetails = (data) => {
    if (!data) {
      data = [];
    }
    setSourceData(data);
    setTotalRowCount(data.length);
    setTableDataDetails(data);
  };

  const reset = () => {
    setApiState(API_STATE.INITIAL);
    setSourceDataDetails([]);
    setPagination({ currentPage: 0, rowsPerPage: 20 });
    setFilters([]);
  };

  const setSortingColumn = (key, order) => {
    setSortColumn({
      key,
      order,
    });
  };

  const recalculateCurrentPage = (rows?: number) => {
    const maxPage = Math.ceil(tableRowCount / pagination?.rowsPerPage);
    const newRowsPerPage =
      rows === null || rows === undefined ? pagination?.rowsPerPage : rows;
    if (pagination?.currentPage >= maxPage) {
      const newPage = maxPage > 0 ? maxPage - 1 : 0;
      setPagination({
        currentPage: newPage,
        rowsPerPage: newRowsPerPage ?? pagination?.rowsPerPage,
      });
    } else {
      setPagination({
        currentPage: pagination?.currentPage,
        rowsPerPage: newRowsPerPage ?? pagination?.rowsPerPage,
      });
    }
  };

  const setTableRowsPerPage = (rows) => {
    recalculateCurrentPage(rows);
  };

  const setCurrentPage = (newPage) => {
    setPagination({
      currentPage: newPage,
      rowsPerPage: pagination?.rowsPerPage,
    });
  };

  const sortData = (data) => {
    const { key, order } = sortColumn;
    if (!key) {
      return data;
    }
    const sortedData = data
      ?.slice()
      .sort((a, b) =>
        mixedSearchCompareFunction(objectDive(a, key), objectDive(b, key))
      );
    if (order === SortOrder.Desc) {
      sortedData.reverse();
    }
    return sortedData;
  };

  const filteredData = (data) => {
    let filteredData = data;
    filters.forEach(({ condition, filterFunction }) => {
      if (condition(filterValues)) {
        filteredData = filteredData.filter((row) =>
          filterFunction(row, filterValues)
        );
      }
    });
    return filteredData;
  };

  const paginateData = (data): any[] => {
    if (!data) {
      return [];
    }
    if (
      pagination?.currentPage >= 0 &&
      pagination?.currentPage < Math.ceil(data.length / pagination?.rowsPerPage)
    ) {
      return data.slice(
        pagination?.currentPage * pagination?.rowsPerPage,
        (pagination?.currentPage + 1) * pagination?.rowsPerPage
      );
    }
    return [];
  };

  const updateFilterValue = (key, value) => {
    setFilterValues({ ...filterValues, [key]: value });
  };

  const computedTableData = () => {
    const sortedData = sortData(tableData);
    const paginatedData = paginateData(sortedData);
    return paginatedData;
  };

  const contextValue = {
    apiState,
    totalRowCount,
    sourceData,
    tableRowCount,
    tableData,
    rowsPerPage: pagination?.rowsPerPage,
    currentPage: pagination?.currentPage,
    filters,
    sortColumn,
    filterValues: filterValues || {},
    overrideTableHeight,
    setApiState,
    reset,
    setSourceDataDetails,
    setFilters,
    setTableDataDetails,
    setTableRowsPerPage,
    setCurrentPage,
    setSortingColumn,
    updateFilterValue,
    computedTableData,
    setOverrideTableHeight,
  };

  return (
    <ReportsContext.Provider value={contextValue}>
      {children}
    </ReportsContext.Provider>
  );
}

export default ReportsProvider;
