import { BaseLineItem } from "../../types/pos";

export function calculateTotal(itemList) {
  // Calculate totals for discount calculations purposes
  // VERIFY 1.1: Considering only buy items here cause percentage discount calulations to become inconsistent
  if (itemList.cart.length) {
    return itemList.cart.reduce(
      (reducer: number, lineItem: BaseLineItem) =>
        lineItem.buyItem && itemList.cartType !== "return"
          ? reducer
          : reducer + lineItem.lineTotal,
      0
    );
  }
  return 0;
}

export function calculateSaleTotal(itemList) {
  if (itemList.cart.length) {
    const subtotal = itemList.cart.reduce(
      (reducer: number, lineItem: BaseLineItem) =>
        lineItem.buyItem ? reducer : reducer + lineItem.lineTotal,
      0
    );

    return subtotal + itemList.discountAmount;
  }
  return 0 + itemList.discountAmount;
}

export function calculateBuyTotal(itemList) {
  if (itemList.cart.length) {
    return itemList.cart.reduce(
      (reducer: number, lineItem: BaseLineItem) =>
        !lineItem.buyItem ? reducer : reducer + lineItem.lineTotal,
      0
    );
  }
  return 0;
}
