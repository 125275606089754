import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import React, { ChangeEvent, Component, RefObject } from "react";
import { _fetch } from "../../api";
import { BASE_URL } from "../../constants/api";
import Customer from "../../views/customers/Customer";
import CustomerLine from "./CustomerLine";
import CustomerNote from "./CustomerNote";
import Modal from "../generic/Modal";
import { segmentAnalytics } from "../services/Analytics";
import { SELECT_A_CUSTOMER_CLICKED } from "../../constants/eventsTracked";
import "./CustomerSelect.scss";
import IconButton from "../generic/IconButton";
import { ItemList } from "../../pos/ItemStore";

interface CustomerSelectProps {
  posStore?: ItemList;
}

@inject("posStore")
@observer
class CustomerSelect extends Component<CustomerSelectProps> {
  autocomplete: RefObject<HTMLDivElement>;
  search: string;

  @observable
  lastController: AbortController;

  constructor(props: CustomerSelectProps) {
    super(props);
    this.state = {};
    this.autocomplete = React.createRef();
  }

  handleClick(event: MouseEvent) {
    if (
      !this.autocomplete.current ||
      this.autocomplete.current.contains(event.target as Node)
    )
      return;
    this.props.posStore.addCustomerResults([]);
    this.props.posStore.setCustomerInput("");
  }

  handleKeypress(event: KeyboardEvent) {
    if (event.key !== "Escape") return;
    this.props.posStore.addCustomerResults([]);
    this.props.posStore.setCustomerInput("");
  }

  componentDidMount() {
    document.addEventListener("click", (event) => this.handleClick(event));
    document.addEventListener("keydown", (event) => this.handleKeypress(event));
  }

  componentWillUnmount() {
    document.removeEventListener("click", (event) => this.handleClick(event));
    document.removeEventListener("keydown", (event) =>
      this.handleKeypress(event)
    );
  }

  @observable showAddCustomer = false;
  @action setShowAddCustomer = (showAddCustomer: boolean) => {
    this.showAddCustomer = showAddCustomer;
  };

  closeCustomerModal = (value: boolean) => {
    this.setShowAddCustomer(value);
  };

  @action setSelectedCustomer = (customer: any) => {
    this.props.posStore.selectedCustomer = customer;
    this.props.posStore.validateCart();
  };

  @action
  customerSearch = () => {
    if (!this.props.posStore.customerInput)
      return (this.props.posStore.customerResults = []);

    this.lastController?.abort();
    const controller = new AbortController();
    this.lastController = controller;
    _fetch({
      endpoint:
        `${BASE_URL}/customers?keyword=` +
        encodeURIComponent(this.props.posStore.customerInput),
      method: "GET",
      signal: controller.signal,
    }).then((data) => {
      if (this.props.posStore.customerInput) {
        this.props.posStore.addCustomerResults(data);
      }
    });
  };

  @action
  clearCustomer = () => {
    this.props.posStore.selectedCustomer = null;
    this.props.posStore.customerInput = "";
    //zero the tenders out so store credit doesn't get stuck with non zero value
    this.props.posStore.resetTenders();
    this.props.posStore.validateCartNoRefresh();
  };

  @action
  handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.props.posStore.customerInput = e.target.value;
    this.customerSearch();
  };

  render() {
    const store = this.props.posStore;
    return (
      <React.Fragment>
        <div className="CustomerSelect">
          <i className="CustomerSelect__searchIcon far fa-search" />
          <input
            className="CustomerSelect__searchField"
            onClick={() => {
              this.clearCustomer();
              segmentAnalytics.track(SELECT_A_CUSTOMER_CLICKED);
            }}
            type="search"
            placeholder="Select a customer"
            value={
              store.selectedCustomer
                ? (store.selectedCustomer.firstName &&
                  store.selectedCustomer.firstName.length > 0
                    ? store.selectedCustomer.firstName
                    : "") +
                  " " +
                  (store.selectedCustomer.lastName &&
                  store.selectedCustomer.lastName.length > 0
                    ? store.selectedCustomer.lastName
                    : "")
                : store.customerInput
            }
            onChange={this.handleInputChange}
          />
          <IconButton
            title="Add a new customer"
            className="CustomerSelect__addCustomer"
            icon="fal fa-users-medical"
            onClick={() => this.setShowAddCustomer(true)}
          />
          {store.selectedCustomer ? (
            <div className="CustomerSelect__CustomerInfo">
              <div
                className="CustomerSelect__CustomerInfoRow"
                onClick={this.clearCustomer}
                role="button"
              >
                <span>
                  <strong>Email:</strong> {store.selectedCustomer.email}
                </span>
              </div>
              <div className="CustomerSelect__CustomerInfoRow">
                <CustomerNote customer={store.selectedCustomer} />
                <span>
                  <strong>Credit:</strong>{" "}
                  {store.fCurr(store.selectedCustomer.storeCredit?.toFixed(2))}
                </span>
              </div>
            </div>
          ) : (
            ""
          )}
          <div
            ref={this.autocomplete}
            className={`CustomerSelect__AutocompleteItems
              ${
                store.customerResults.length === 0 || !store.customerResults[0]
                  ? " CustomerSelect__AutocompleteItems--hidden"
                  : ""
              }`}
          >
            {store.customerResults.length === 0 || !store.customerResults[0]
              ? null
              : store.customerResults
                  .slice(0, 10)
                  .map((customer: any) => (
                    <CustomerLine
                      store={store}
                      key={customer.id}
                      customer={customer}
                      onSelect={() => this.lastController?.abort()}
                    />
                  ))}
          </div>
        </div>
        {this.showAddCustomer ? (
          <Modal onClose={() => this.setShowAddCustomer(false)}>
            <Modal.Header>Add new customer</Modal.Header>
            <Modal.Content>
              <Customer
                isPos={true}
                setShowAddCustomer={(value: boolean) =>
                  this.closeCustomerModal(value)
                }
                setSelectedCustomerForPOS={(customer: any) =>
                  this.setSelectedCustomer(customer)
                }
              />
            </Modal.Content>
            <Modal.Actions> </Modal.Actions>
          </Modal>
        ) : null}
      </React.Fragment>
    );
  }
}

export default CustomerSelect;
