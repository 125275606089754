import React from "react";
import "./Panel.scss";
import { joinClasses } from "../../utils/styling";

interface PanelProps {
  emphasised?: boolean;
  className?: string;
  children: React.ReactNode;
}

function Panel(props: PanelProps) {
  const { children, emphasised = false, className = undefined } = props;

  const panelClassName = joinClasses([
    "PanelComponent",
    emphasised ? "PanelComponent--emphasised" : null,
    className,
  ]);

  return <div className={panelClassName}>{children}</div>;
}

export default Panel;
