import TimeUtils from "../../utils/TimeUtils";

export const addLocalizedDate = (
  tableData: Array<any>,
  tz: string,
  dateKey: string
) =>
  tableData?.map((row) => ({
    ...row,
    [`${dateKey}_localized`]: row[dateKey]
      ? TimeUtils.convertDateToHumanReadable(row[dateKey], tz)
      : "-",
  }));

export const addMultipleLocalizedDates = (
  tableData: Array<any>,
  tz: string,
  dateKeys: string[]
) => {
  let appendableTable = tableData;
  dateKeys.forEach((key) => {
    appendableTable = addLocalizedDate(appendableTable, tz, key);
  });
  return appendableTable;
};
