import { _fetch } from "../../api";
import { API_V2_BASE_URL } from "../../constants/api";

export async function deployThemeVersion(
  theme: string,
  version: string
): Promise<unknown> {
  return _fetch({
    method: "POST",
    endpoint: `${API_V2_BASE_URL}/shopify/deployCustomerTheme`,
    payload: {
      theme,
      version,
    },
  });
}
