import React, { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { usePopper } from "react-popper";
import { Placement } from "@popperjs/core";
import "./Tooltip.scss";
import IconButton from "./IconButton";
import { segmentAnalytics } from "../services/Analytics";

const DEFAULT_ICON = "far fa-question-circle";
const ENTER_TIMEOUT = 100; // ms
const EXIT_TIMEOUT = 1_000; // ms

type IconTheme = "primary" | "dark" | "light";

interface TooltipProps {
  buttonStyle?: any;
  icon?: string;
  iconTheme?: IconTheme;
  centered?: boolean;
  children: React.ReactNode;
  segmentEventName?: string;
  placement?: Placement;
  hideCloseButton?: boolean;
}

function Tooltip(props: TooltipProps) {
  const {
    icon,
    centered = false,
    iconTheme = "primary",
    children,
    segmentEventName,
    buttonStyle = {},
    placement = "auto",
    hideCloseButton = false,
  } = props;
  const [showToolTip, setShowToolTip] = useState(false);
  const [opacity, setOpacity] = useState(0);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: "arrow", options: { element: arrowElement } }],
    placement,
  });

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setOpacity(0);
        setTimeout(() => setShowToolTip(false), EXIT_TIMEOUT);
      }
    };
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleToggle = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (showToolTip) {
      handleClose(e);
    } else {
      handleOpen();
    }
  };

  const handleOpen = () => {
    if (!showToolTip) {
      if (segmentEventName) {
        segmentAnalytics.track(segmentEventName);
      }
      setOpacity(0);
      setShowToolTip(true);
      setTimeout(() => setOpacity(1), ENTER_TIMEOUT);
    }
  };

  const handleClose = (e: any) => {
    e.preventDefault();
    if (showToolTip) {
      setOpacity(0);
      setTimeout(() => setShowToolTip(false), EXIT_TIMEOUT);
    }
  };

  const popupStyle = centered ? {} : styles.popper;
  const popupClass = centered
    ? "Tooltip__popup Tooltip__popup--big"
    : "Tooltip__popup";

  return (
    <OutsideClickHandler onOutsideClick={handleClose} display={"inline-block"}>
      <button
        type="button"
        aria-label="What is this?"
        ref={setReferenceElement}
        className={`Tooltip__trigger Tooltip__trigger--${iconTheme}`}
        onClick={handleToggle}
        style={buttonStyle}
      >
        <i className={icon ?? DEFAULT_ICON} data-testid="tooltipTrigger" />
      </button>
      {showToolTip ? (
        <div
          ref={setPopperElement}
          className={popupClass}
          style={{ ...popupStyle, opacity }}
          {...attributes.popper}
        >
          {!hideCloseButton && (
            <IconButton
              className="Tooltip__close"
              onClick={handleClose}
              onMouseDown={handleClose}
              aria-label="Close tooltip text"
              data-testid="popupDismiss"
              title="Close tooltip text"
              icon="fas fa-times"
              tooltip
            />
          )}
          {children}
          <div ref={setArrowElement} style={styles.arrow} />
        </div>
      ) : null}
    </OutsideClickHandler>
  );
}

export default Tooltip;
