import React, { useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { Table, Column, Cell } from "rsuite-table";
import "rsuite-table/dist/css/rsuite-table.css";
import "../../../css/rstable-overwrites.scss";
import CustomHeaderCell from "../../components/reports/CustomHeader";
import { ReportsMenuStructure } from "../../menuStructures";
import {
  NumberInputFilter,
  TextInputFilter,
} from "../../components/reports/CellTypes";
import { API_STATE } from "../../constants/api";
import { Pagination, ReportControls } from "../../components/reports";
import { TillSelect } from "../../components/posSettings";
import { submittedCartsCSVHeaders } from "../../constants/reportCSVHeaders";
import { rowPerPageOptions } from "../../constants/reports";
import { useShowError } from "../../hooks/errorHooks";
import { useReportsDetails } from "../../hooks/reportsHooks";
import { addLocalizedDate } from "../../transformers/helpers/addLocalizedDate";
import SetActiveMenu from "../../menuStructures/SetActiveMenu";
import { useFetchSubmittedCarts } from "../../hooks/useFetchSubmittedCarts";

function SubmittedCarts(props) {
  const { location, history } = props;
  const query = new URLSearchParams(location.search);
  const defaultFromDate = query.get("fromDate");
  const defaultToDate = query.get("toDate");
  const {
    apiState,
    currentPage,
    filterValues,
    overrideTableHeight,
    rowsPerPage,
    sortColumn,
    tableData,
    computedTableData,
    reset,
    setApiState,
    setCurrentPage,
    setTableRowsPerPage,
    setFilters,
    setSortingColumn,
    setSourceDataDetails,
    updateFilterValue,
  } = useReportsDetails();
  const showError = useShowError();

  const {
    data: sourceData,
    isFetching,
    error,
  } = useFetchSubmittedCarts(
    {
      startDate: query.get("fromDate"),
      toDate: query.get("toDate"),
      limit: 999999,
    },
    {
      enabled: !!query.get("fromDate") && !!query.get("toDate"),
    }
  );

  useEffect(() => {
    document.title = "Point of Sale Submitted Carts | BinderPOS";
    reset();
    setFilters(filters);
  }, []);

  useEffect(() => {
    if (query.has("fromDate")) {
      handleDateChange(
        query.get("fromDate"),
        query.get("toDate"),
        query.get("timezone")
      );
    }
  }, [location]);

  useEffect(() => {
    if (isFetching) {
      setApiState(API_STATE.LOADING);
    } else if (error) {
      showError(
        error as any,
        "Failed to load report",
        "There was an error retrieving your submitted carts report. Please try again"
      );
      setApiState(API_STATE.ERROR);
    } else if (sourceData) {
      setSourceDataDetails(sourceData);
      setApiState(API_STATE.SUCCESS);
    }
  }, [isFetching, sourceData, error]);

  const filters = [
    {
      name: "id",
      condition: (filterValues) => filterValues?.id,
      filterFunction: (row, filterValues) =>
        row.id && String(row.id).includes(filterValues?.id),
    },
    {
      name: "shopifyOrderId",
      condition: (filterValues) => filterValues?.shopifyOrderId,
      filterFunction: (row, filterValues) =>
        row.shopifyOrderId &&
        String(row.shopifyOrderId).includes(filterValues?.shopifyOrderId),
    },
    {
      name: "tillId",
      condition: (filterValues) => filterValues?.tillId,
      filterFunction: (row, filterValues) =>
        row.till?.id && row.till.id == filterValues?.tillId,
    },
    {
      name: "firstName",
      condition: (filterValues) => filterValues?.firstName,
      filterFunction: (row, filterValues) =>
        row.customer?.firstName?.includes(filterValues?.firstName),
    },
    {
      name: "lastName",
      condition: (filterValues) => filterValues?.lastName,
      filterFunction: (row, filterValues) =>
        row.customer?.lastName?.includes(filterValues?.lastName),
    },
  ];

  const handleDateChange = (fromDate, toDate, tz) => {
    const params = new URLSearchParams(location.search);
    if (fromDate) params.set("fromDate", fromDate);
    if (toDate) params.set("toDate", toDate);
    if (tz) params.set("timezone", tz);
    history.push({
      search: "?" + params.toString(),
    });
  };

  const {
    id = "",
    shopifyOrderId = "",
    tillId = "",
    firstName = "",
    lastName = "",
  } = filterValues;

  const csvFilename = "Submitted Carts Report.csv";

  const data = addLocalizedDate(
    computedTableData(),
    query.get("timezone"),
    "dateSubmitted"
  );

  return (
    <>
      <SetActiveMenu menuStructure={ReportsMenuStructure} />
      <div className="app-header">
        <h1 className="title">
          <span className="header-text">Submitted Carts Reports</span>
        </h1>
      </div>
      <div className="report">
        <ReportControls
          setDateRange={handleDateChange}
          csvHeaders={submittedCartsCSVHeaders}
          csvFilename={csvFilename}
          defaultFromDate={defaultFromDate}
          defaultToDate={defaultToDate}
        />
        {apiState !== API_STATE.INITIAL && (
          <div className="data-table">
            <div className="table-container">
              <Table
                data={data}
                headerHeight={80}
                loading={apiState == API_STATE.LOADING}
                sortColumn={sortColumn.key}
                sortType={sortColumn.order}
                onSortColumn={setSortingColumn}
                renderEmpty={() => <div>No data to display</div>}
                className="rs-table--multi-header"
                height={overrideTableHeight}
                fillHeight={overrideTableHeight === 0}
              >
                <Column key="id" width={110} sortable resizable>
                  <CustomHeaderCell title="Cart #">
                    <NumberInputFilter
                      value={id}
                      setValue={(value) => updateFilterValue("id", value)}
                      title="Filter by cart ID"
                      aria-label="Filter by cart ID"
                    />
                  </CustomHeaderCell>
                  <Cell dataKey="id" />
                </Column>
                <Column key="shopifyOrderId" width={140} sortable resizable>
                  <CustomHeaderCell title="Shopify Order #">
                    <NumberInputFilter
                      value={shopifyOrderId}
                      setValue={(value) =>
                        updateFilterValue("shopifyOrderId", value)
                      }
                      title="Filter by order ID"
                      aria-label="Filter by order ID"
                    />
                  </CustomHeaderCell>
                  <Cell dataKey="shopifyOrderId" />
                </Column>
                <Column
                  key="dateSubmitted_localized"
                  width={160}
                  sortable
                  resizable
                >
                  <CustomHeaderCell title="Date Submitted" />
                  <Cell dataKey="dateSubmitted">
                    {(rowData) => rowData?.dateSubmitted_localized}
                  </Cell>
                </Column>
                <Column key="tillName" width={120} sortable resizable>
                  <CustomHeaderCell title="Till">
                    <TillSelect
                      value={tillId}
                      setValue={(value) => updateFilterValue("tillId", value)}
                      title="Filter by till"
                      aria-label="Filter by till"
                      allowAll
                    />
                  </CustomHeaderCell>
                  <Cell dataKey="till.name">
                    {(rowData) => rowData?.till?.name}
                  </Cell>
                </Column>
                <Column key="firstName" width={200} sortable resizable>
                  <CustomHeaderCell title="First Name">
                    <TextInputFilter
                      value={firstName}
                      setValue={(value) =>
                        updateFilterValue("firstName", value)
                      }
                      title="Filter by first name"
                      aria-label="Filter by first name"
                    />
                  </CustomHeaderCell>
                  <Cell dataKey="customer.firstName">
                    {(rowData) => rowData?.customer?.firstName || "Guest"}
                  </Cell>
                </Column>
                <Column key="lastName" width={200} sortable resizable>
                  <CustomHeaderCell title="Last Name">
                    <TextInputFilter
                      value={lastName}
                      setValue={(value) => updateFilterValue("lastName", value)}
                      title="Filter by last name"
                      aria-label="Filter by last name"
                    />
                  </CustomHeaderCell>
                  <Cell dataKey="customer.lastName">
                    {(rowData) => rowData?.customer?.lastName}
                  </Cell>
                </Column>
                <Column key="col_processedBy" width={200} sortable resizable>
                  <CustomHeaderCell title="Processed By" />
                  <Cell dataKey="submittedBy" />
                </Column>
                <Column key="col_view" width={100} align="right">
                  <CustomHeaderCell title="" />
                  <Cell>
                    {(rowData) => (
                      <Link
                        to={`/pointOfSale/carts/${rowData.id}`}
                        className="viewCart"
                      >
                        <i className="fal fa-eye" /> View
                      </Link>
                    )}
                  </Cell>
                </Column>
              </Table>
            </div>
            <Pagination
              apiState={apiState}
              currentPage={currentPage}
              totalRowCount={tableData.length}
              rowsPerPage={rowsPerPage}
              rowPerPageOptions={rowPerPageOptions}
              setCurrentPage={setCurrentPage}
              setRowsPerPage={setTableRowsPerPage}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default withRouter(SubmittedCarts);
