import React from "react";
import { ErrorMessage, Field, getIn, FormikProps } from "formik";
import Tooltip from "../generic/Tooltip";
import { isArray } from "lodash";

interface SelectProps {
  field: Partial<HTMLInputElement>;
  form: FormikProps<{}>;
  editing?: boolean;
  label?: string;
  helperText?: string;
  requiredLabel?: boolean;
  optionalLabel?: boolean;
  tooltipContent?: string;
  type?: string;
  children: React.ReactNode;
}

const getInputClass = (editing: boolean, hasError: any) => {
  if (editing) {
    if (hasError) {
      return "input input--error";
    }
    return "input";
  }

  return "input input--display";
};

const getSelectedOptionLabel = (options: any, selected: string) => {
  if (isArray(options)) {
    return options.find(
      (option: React.ReactElement) =>
        String(option.props.value) === String(selected)
    )?.props.children;
  }
};

function Select(props: SelectProps) {
  const { field, form, children, ...rest } = props;
  const { name, ...inputProps } = field;
  const {
    label,
    requiredLabel,
    optionalLabel,
    helperText,
    editing = true,
    tooltipContent,
    ...otherProps
  } = rest;
  const { errors } = form;
  const hasError = getIn(errors, name);

  return (
    <>
      {label ? (
        <label className="label" htmlFor={name}>
          {label}
          {requiredLabel ? <span className="label--required" /> : null}
          {optionalLabel ? (
            <span className="label--optional">(.opt)</span>
          ) : null}
          {tooltipContent && (
            <Tooltip>
              <>{tooltipContent}</>
            </Tooltip>
          )}
        </label>
      ) : null}
      <div className="input-group">
        {editing ? (
          <Field
            id={name}
            name={name}
            style={
              editing
                ? { minHeight: 26 }
                : {
                    minHeight: 26,
                    WebkitAppearance: "none",
                    MozAppearance: "none",
                    textIndent: 1,
                    textOverflow: "",
                  }
            }
            className={getInputClass(editing, hasError)}
            as="select"
            {...inputProps}
            {...otherProps}
          >
            {children}
          </Field>
        ) : (
          <span className={getInputClass(editing, hasError)}>
            {getSelectedOptionLabel(children, field.value)}
          </span>
        )}
        <ErrorMessage name={name} className="input-error">
          {(message) => (
            <div className="input-error">
              <i className="input-error-icon far fa-exclamation-circle" />{" "}
              {message}
            </div>
          )}
        </ErrorMessage>
        {!hasError && !!helperText && editing ? (
          <div className="input__helperText">{helperText}</div>
        ) : null}
      </div>
    </>
  );
}

export default Select;
