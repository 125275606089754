import { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { useFetchShopifySubscriptionStatus } from "../../hooks/shopifySubscriptionHooks";
import { Loader } from "../../components";
import { SubscriptionStatus } from "../../types/subscriptions";
import { useStoreDetails } from "../../hooks/storeHooks";
import { RootStore, SettingsStore } from "../../store";
import { statusMessages } from "../../helpers/ShopifySubscriptionStatusMessages";
import { StoreSettings } from "../../types/settings";

type ShopifySubscriptionWrapperProps = {
  store?: RootStore;
};

type ShopifySubscriptionProps = {
  settingsStore: SettingsStore;
};

export function ShopifySubscription({
  settingsStore,
}: ShopifySubscriptionProps) {
  const { customerId } = useStoreDetails();
  const [isLoading, setIsLoading] = useState(true);
  const { isError, isSuccess, data, error } =
    useFetchShopifySubscriptionStatus(customerId);

  useEffect(() => {
    if (
      isSuccess ||
      (error as any)?.title === "Customer subscriptions not found"
    ) {
      settingsStore.fetchSettings().then((settings: StoreSettings) => {
        settingsStore.setStoreSettings(settings);
        setIsLoading(false);
      });
    }
  }, [isSuccess, error]);

  if (isError) {
    if ((error as any).title === "Customer subscriptions not found") {
      return (
        <div className="ShopifySubscription">
          <h2>
            You currently do not have a subscription. Please contact support to
            get one.
          </h2>
        </div>
      );
    }
    return (
      <div className="ShopifySubscription">
        <h2>
          We were unable to verify your subscription status. Please refresh and
          try again.
        </h2>
      </div>
    );
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="ShopifySubscription">
      {data?.status === SubscriptionStatus.Active ? (
        <h2>Awesome, your Shopify subscription is active!</h2>
      ) : null}
      {data?.status === SubscriptionStatus.Pending ? (
        <h2>
          {
            statusMessages(
              settingsStore?.storeSettings?.shopify?.subscription
                .confirmationUrl
            )["PENDING"]
          }
        </h2>
      ) : null}
    </div>
  );
}

function Wrapper({ store }: ShopifySubscriptionWrapperProps) {
  return <ShopifySubscription settingsStore={store.SettingsStore} />;
}

export default inject("store")(observer(Wrapper));
