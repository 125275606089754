import React from "react";
import DefaultErrorPage from "../../views/error/DefaultErrorPage";

export default class ErrorBoundary extends React.Component<
  {},
  { hasError: boolean; errorMessage?: string }
> {
  constructor(props: {}) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: undefined,
    };
  }

  static getDerivedStateFromError(error: Error) {
    return {
      hasError: true,
      errorMessage: error.message,
    };
  }

  render() {
    if (this.state.hasError) {
      return <DefaultErrorPage message={this.state.errorMessage} />;
    }
    return this.props.children;
  }
}
