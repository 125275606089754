import React from "react";
import { useParams } from "react-router-dom";
import { Loader } from "../../components";
import ProductEditForm from "../../components/product/ProductEditForm";
import { useProductByIdQuery } from "../../generated/graphql";
import useTitle from "../../hooks/useTitle";

interface RouteParams {
  productId: string;
}

function ProductEdit() {
  const { productId } = useParams<RouteParams>();
  const isNew = productId === "new";
  useTitle(isNew ? "Add Product" : "Edit Product");
  const { data, isLoading } = useProductByIdQuery(
    {
      productByPkId: Number(productId),
    },
    { enabled: productId && !isNew }
  );

  if (isLoading) {
    return <Loader />;
  }

  if (!isNew && !data?.Product_by_pk) {
    return <p>No matching Id found</p>;
  }

  return <ProductEditForm product={data?.Product_by_pk} isNew={isNew} />;
}
export default ProductEdit;
