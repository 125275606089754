import React, { useState } from "react";
import { notify } from "../../helpers/notificationHelpers";
import { getTerminalId } from "../../helpers/paymentTerminalHelpers";
import { useShowError } from "../../hooks/errorHooks";
import {
  useUpdateActiveDevice,
  useDeleteDevice,
} from "../../hooks/paymentTerminalHooks";
import { PaymentProvider, TerminalResponse } from "../../types/paymentTerminal";
import ButtonComponent from "../generic/ButtonComponent";
import ConfirmationModal from "../generic/ConfirmationModal";
import "./PaymentGatewayDevice.scss";

interface PaymentGatewayDeviceProps {
  tillId: number;
  currentDevice: string;
  details: TerminalResponse;
  paymentProvider: PaymentProvider;
}

function PaymentGatewayDevice(props: PaymentGatewayDeviceProps) {
  const { tillId, currentDevice, details, paymentProvider } = props;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const showError = useShowError();
  const { mutateAsync: setActiveDevice, isMutating } = useUpdateActiveDevice();
  const { mutateAsync: deleteDevice } = useDeleteDevice();

  const terminalId = getTerminalId(details);
  const isSelected = terminalId === currentDevice;

  const handleSelectedDeviceChange = (deviceKey: string) =>
    setActiveDevice({ tillId, paymentProvider, deviceKey })
      .then(() => notify.info("Selected device updated"))
      .catch((error) => showError(error));

  const handleDelete = () =>
    deleteDevice({ tillId, deviceKey: terminalId })
      .then(() => notify.info("Device deleted"))
      .catch((error) => showError(error))
      .finally(() => setShowDeleteModal(false));

  return (
    <div
      className={`PaymentGatewayDevice ${
        isSelected ? "PaymentGatewayDevice--selected" : ""
      }`}
      key={terminalId}
      data-testid="PaymentGatewayDevice"
    >
      <p>
        {details.name}
        {!isSelected ? (
          <button
            onClick={() => setShowDeleteModal(true)}
            className="PaymentGatewayDevice__delete"
            title="Delete"
          >
            <i className="far fa-trash-alt" />
          </button>
        ) : null}
      </p>
      {"status" in details ? (
        <p className="PaymentGatewayDevice__status">Status: {details.status}</p>
      ) : null}
      {isSelected ? (
        <p className="PaymentGatewayDevice__selectedText">Selected</p>
      ) : (
        <ButtonComponent
          primary
          onClick={() => handleSelectedDeviceChange(terminalId)}
          disabled={isMutating}
          className="PaymentGatewayDevice__select"
        >
          Select
        </ButtonComponent>
      )}
      {showDeleteModal ? (
        <ConfirmationModal
          confirmAction={handleDelete}
          cancelAction={() => setShowDeleteModal(false)}
          titleElement="h3"
        >
          <p>Are you sure you want to remove {details.name}?</p>
          <p>
            Once deleted, the terminal will need to be paired again before it
            can be used.
          </p>
        </ConfirmationModal>
      ) : null}
    </div>
  );
}

export default PaymentGatewayDevice;
