import React, { useEffect, useState } from "react";
import { fetchApiKey } from "../../../api/rest/cfbmarket";
import Loader from "../../../components/generic/Loader";
import TabNavigation from "../../../components/integrations/TabNavigation";
import SectionHeaderLayout from "../../../components/layout/SectionHeaderLayout";
import { API_STATE } from "../../../constants/api";
import IntegrationsSideMenu from "../../../menuStructures/IntegrationsSideMenu";
import { useIntegrationFriendlyName } from "../../../hooks/integrationHooks";
import "./CfbMarketplaceConfigureView.scss";
import ConfigureContainer from "./ConfigureContainer";

function CfbMarketplaceConfigureView() {
  const syncFriendlyName = useIntegrationFriendlyName("cfbmarket");
  const [apiState, setApiState] = useState(API_STATE.LOADING);
  const [hasApiKey, setHasApiKey] = useState(false);

  useEffect(() => {
    fetchApiKey()
      .then(() => {
        setHasApiKey(true);
        setApiState(API_STATE.SUCCESS);
      })
      .catch(() => {
        setApiState(API_STATE.ERROR);
      });
  }, []);

  if (apiState === API_STATE.LOADING) {
    return <Loader />;
  }

  return (
    <>
      <IntegrationsSideMenu />
      <SectionHeaderLayout title={`Configure ${syncFriendlyName}`} />
      {hasApiKey ? <TabNavigation integrationId="cfbmarket" /> : null}
      <div className="cfbMarket-apikeys-view__container">
        <ConfigureContainer />
      </div>
    </>
  );
}

export default CfbMarketplaceConfigureView;
