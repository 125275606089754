import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { ItemList, LineItem } from "../../pos/ItemStore";
import ButtonComponent from "../generic/ButtonComponent";
import Modal from "../generic/Modal";
import "./OutOfStockWarning.scss";

interface OutOfStockWarningProps {
  posStore?: ItemList;
}

@inject("posStore")
@observer
class OutOfStockWarning extends Component<OutOfStockWarningProps> {
  handleCancel = () => this.props.posStore.setShowOutOfStockWarning(false);

  handleProceed = () => {
    const { outOfStockItem, buyMode, allTax } = this.props.posStore;
    this.props.posStore.addToCart(
      new LineItem(outOfStockItem, buyMode, null, allTax)
    );
    this.props.posStore.setShowOutOfStockWarning(false);
  };

  render() {
    const { outOfStockItem } = this.props.posStore;
    const selectedVariant =
      outOfStockItem?.variants[outOfStockItem.selectedVariant];
    const variantTitle = selectedVariant?.title;

    return (
      <Modal small>
        <Modal.Header>Inventory Warning</Modal.Header>
        <Modal.Content>
          {outOfStockItem ? (
            <div className="OutOfStockWarning__product">
              <div className="OutOfStockWarning__productTitle">
                <h5>{outOfStockItem.title}</h5>
                <p>{variantTitle}</p>
                <hr />
                <p>
                  Warning: this item has 0 inventory and adding it to the cart
                  will oversell this item.
                </p>
                <p>Are you sure you want to proceed?</p>
              </div>
              <div className="OutOfStockWarning__productImage">
                <img src={outOfStockItem.img} title={outOfStockItem.title} />
              </div>
            </div>
          ) : (
            <div>
              <p>
                Warning: this item has 0 inventory and adding it to the cart
                will oversell this item.
              </p>
              <p>Are you sure you want to proceed?</p>
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <ButtonComponent secondary onClick={this.handleCancel}>
            Cancel
          </ButtonComponent>
          <ButtonComponent danger onClick={this.handleProceed}>
            Proceed Anyway!
          </ButtonComponent>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default OutOfStockWarning;
