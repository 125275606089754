import { UseQueryOptions, QueryKey } from "react-query";
import {
  GetSalesByCategoryQuery,
  GetSalesByCategoryQueryVariables,
  useGetSalesByCategoryQuery,
} from "../../generated/reporting";
import { formatTotalSalesByCategory } from "../../transformers/reports/formatTotalSalesByCategory";
import { SalesByCategoryData } from "../../types/reports";

export const useFetchTotalSalesByCategory = (
  variables: GetSalesByCategoryQueryVariables,
  options: UseQueryOptions<
    GetSalesByCategoryQuery,
    unknown,
    SalesByCategoryData,
    QueryKey
  > = {}
) =>
  useGetSalesByCategoryQuery(variables, {
    select: (report) => formatTotalSalesByCategory(report),
    keepPreviousData: true,
    staleTime: 60_000,
    ...options,
  });
